import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const SelectedFileList = (props) => {
  const { fileList, handleUnlistItem } = props;
  return (
    <List dense={true}>
      {fileList.map((f) => (
        <ListItem key={f.id}>
          <ListItemIcon>
            {f.isDir ? <FolderIcon /> : <InsertDriveFileIcon />}
          </ListItemIcon>
          <ListItemText primary={f.name} />
          <ListItemSecondaryAction>
            {handleUnlistItem && (
              <IconButton onClick={(e) => handleUnlistItem(e, f.id)}>
                <ClearIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default SelectedFileList;
