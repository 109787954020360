import React, { useState } from 'react';
import { useStyles } from './ModalStyles';
import Modal from '@material-ui/core/Modal';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AmeraSelect from '../../../../components/AmeraSelect';
import RateInput from '../../../../components/RateInput';
import MenuItem from '@material-ui/core/MenuItem';

const AddMileStoneModal = ({
  open,
  handleClose,
  project_id,
  parent_id,
  heading,
  handleSubmit,
}) => {
  const classes = useStyles();

  const currencies = useSelector((state) => state.billing.currencyList);
  const currentId = currencies.find((co) => co.currency_code === 'USD')
    .currency_code_id;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assign_to, setAssign_to] = useState('');
  const [due_date, setDue_date] = useState('');
  const [rate, setRate] = useState('');
  const [currencyId, setCurrencyId] = useState(currentId);

  const projects = useSelector((state) => {
    return state?.project?.projects.filter((o) => o.project_id === +project_id);
  });
  const members = projects[0]?.project_members;

  // eslint-disable-next-line
  const privilegeMember = members.map((member) => {
    if (
      member.privileges.includes('create') ||
      member.privileges.includes('edit')
    ) {
      return member;
    }
  });

  const ableMember = privilegeMember.filter(function (element) {
    return element !== undefined;
  });

  const form = {
    project_id: Number(project_id),
    project_member_id: Number(assign_to) === 0 ? null : Number(assign_to),
    parent_id: heading === 'Epic' ? null : parent_id,
    element_type: 'milestone',
    title: title,
    description: description,
    pay_rate: Number(rate),
    currency_code_id: Number(currencyId ? currencyId : currentId),
    due_date: due_date,
  };

  const submitHandler = () => {
    console.log('Add Milestone > ', form);
    handleSubmit(form);
    setTitle('');
    setDescription('');
    setAssign_to('');
    setRate('');
    setCurrencyId('');
    setDue_date('');
  };
  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <div className={classes.modalTop}>
          <div>
            <h6 className={classes.modalTopText}>Add a MileStone</h6>
          </div>
          <div onClick={handleClose}>
            <IconButton className={classes.modalTopButton} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />

        <form onSubmit={submitHandler}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <FormControl variant="outlined" className={classes.field}>
                <TextField
                  label="Title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  required={true}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl variant="outlined" className={classes.field}>
                <RateInput
                  rate={rate}
                  onRateChange={(e) => setRate(e.target.value)}
                  currencyId={currencyId}
                  label="Total Amount"
                  onCurrencyChange={(e) => setCurrencyId(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.field}>
              <TextareaAutosize
                className={classes.inputDescription}
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rowsMin={5}
                required={true}
                placeholder="Description"
              />
            </FormControl>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.field}>
                <AmeraSelect
                  id={parent_id}
                  label="Assign to"
                  value={assign_to}
                  required={true}
                  onChange={(e) => setAssign_to(e.target.value)}
                >
                  {ableMember &&
                    ableMember.map((member) => (
                      <MenuItem
                        key={member.project_member_id}
                        id={member.project_member_id}
                        value={member.project_member_id}
                      >
                        {member.first_name} {member.last_name}
                      </MenuItem>
                    ))}
                </AmeraSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className={classes.field}>
                <TextField
                  label="Due Date"
                  type="date"
                  value={due_date}
                  onChange={(e) => setDue_date(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  required={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            className={classes.button}
            disabled={!title && true}
            type="submit"
            variant="contained"
            color="primary"
          >
            <span className={classes.buttonText}>Add Milestone</span>
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default AddMileStoneModal;
