import React, { useMemo } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const DepartmentListInput = ({
  department,
  errors,
  onChange,
  departmentsList,
  variant = 'outlined',
  isRequired = true,
}) => {
  const depsList = useMemo(
    () =>
      departmentsList.map((dlo) => (
        <MenuItem key={dlo.department_id} value={dlo.department_id}>
          {dlo.department_name}
        </MenuItem>
      )),
    [departmentsList]
  );

  return (
    <FormControl
      variant={variant}
      fullWidth
      required={isRequired}
      className="register_form_input"
      error={errors ? true : false}
    >
      <InputLabel id="departments-input-label">Department</InputLabel>
      <Select
        labelId="departments-select-label"
        id="departments-select"
        name="department_id"
        value={department}
        onChange={onChange}
      >
        {depsList}
      </Select>
      <FormHelperText error>{errors ? errors : null}</FormHelperText>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  departmentsList: state.member.departmentsList,
});

export default connect(mapStateToProps)(DepartmentListInput);
