import { makeStyles } from '@material-ui/core/styles';
import {
  green,
  error,
  textPurple,
  red,
  textNewGray,
} from '../../../../jss/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 530,
    marginBottom: 50,
  },
  recipsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectInput: {
    width: 'calc(100% - 30px)',
    marginTop: 5,
  },
  rootReply: {
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: 800,
  },
  expand: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(0deg)',
  },
  header: {
    backgroundColor: '#94C03D',
    color: '#FFFFFF',
    height: 43,
    fontSize: 12,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Roboto',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  titleReply: {
    fontSize: 20,
    fontFamily: 'Roboto',
    position: 'relative',
    fontWeight: 'bold',
  },
  headerReply: {
    backgroundColor: '#FFF',
    color: '#000',
    height: 43,
    fontSize: 12,
    textAlign: 'center',
  },
  composeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  composeEntety: {
    width: '100%',
    maxHeight: '50vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  TextField: {
    maxHeight: 380,
    minHeight: 380,
    // overflow: 'hidden',
    border: 'none',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  btngp: {
    // width: 100,
    margin: 20,
    // height: 30,
  },
  btnkc: {
    maxWidth: 23,
    minWidth: 23,
    color: 'white',
  },
  btnbg: {
    // maxWidth: 55,
    // minWidth: 55,
    color: 'white',
    fontSize: 12,
    justifyContent: 'flex-end',
    marginRight: 20,
    borderRadius: 22,
  },
  btnicon: {
    maxWidth: 10,
    minWidth: 10,
    minHeight: 10,
    maxHeight: 10,
    color: textPurple,
    marginLeft: 20,
  },
  btniconlast: {
    maxWidth: 10,
    minWidth: 10,
    minHeight: 10,
    maxHeight: 10,
    color: 'gray',
    margin: 2,
  },
  chip: {
    margin: 5,
  },
  card: {
    height: 50,
    marginLeft: 25,
    marginRight: 25,
    paddingTop: -10,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
    },
  },
  closeButton: { color: '#FFFFFF' },
  closeButtonReply: { color: '#94C03D' },
  snackLayout: {},
  snackContent: {
    color: 'white',
    backgroundColor: error,
  },
  snackContentOK: {
    color: 'white',
    backgroundColor: green,
  },
  deleteButton: {
    color: 'white',
    background: `${red}`,
  },
  containerBCC: {
    paddingLeft: 10,
    marginTop: 10,
  },
  BCC: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  darkButton: {
    color: textNewGray,
    maxHeight: 35,
    minHeight: 35,
    maxWidth: 35,
    minWidth: 35,
    marginRight: 10,
  },
  folderSelect: {
    width: '100%',
    marginTop: 10,
  },
}));
