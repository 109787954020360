import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
// import LocationModeSelector from './LocationModeSelector';
import MemberLocationSelect from './MemberLocationSelect';
import StreetAutocomplete from '../../StreetAutocomplete';
import LocationMap from './LocationMap';
import FindInMap from './FindInMap';
import FormHelperText from '@material-ui/core/FormHelperText';
import useGoogleMap from '../../../hooks/useGoogleMap';
import usePrevious from '../../../hooks/usePrevious';

import { LOCATION_TYPES, GPLACES_TYPES } from '../../../utils/calendar';

const LocationLine = (props) => {
  const {
    locationMode,
    // onModeChange,
    onSavedSelect,
    selectedPlace,
    memberLocation,
    onSelectedPlace,
  } = props;

  const {
    getPlaceDetailsFromPlaceId,
    returnPlaceDetailFromPlaceId,
    getNearbyPlacesByAddress,
    getNearbyPlacesByBounds,
    getPlaceDetailsFromFields,
    convertedPlace,
    mapError,
    places,
  } = useGoogleMap();

  const [error, setError] = useState(null);
  const [googlePlaceType, setGooglePlaceType] = useState(
    GPLACES_TYPES.restaurant
  );
  const [findSuggestionAddress, setFindSuggestionAddress] = useState('');

  const prevSelectedPlace = usePrevious(selectedPlace);

  // reset error on mode change
  useEffect(() => {
    setError(null);
  }, [locationMode]);

  // When accessed through edit mode with lookup, get the place right away at first render
  useEffect(() => {
    if (!!selectedPlace && !prevSelectedPlace) {
      if (locationMode === LOCATION_TYPES.find) {
        console.debug('firing get selected place on load', selectedPlace);
        const { place_id, vendor_formatted_address, street_address_1 } =
          selectedPlace;
        if (!!place_id) {
          getPlaceDetailsFromPlaceId(place_id);
        } else if (!!vendor_formatted_address) {
          getPlaceDetailsFromFields(vendor_formatted_address);
        } else if (!!street_address_1) {
          getPlaceDetailsFromFields(street_address_1);
        }
      } else if (locationMode === LOCATION_TYPES.own) {
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace, locationMode, prevSelectedPlace]);

  // Place rendered by lookup or saved - store it up as selected
  useEffect(() => {
    if (locationMode === LOCATION_TYPES.own && places?.length > 0)
      onSelectedPlace(places[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationMode, places]);

  // Place rendered when selecting from find - pass up
  useEffect(() => {
    if (locationMode === LOCATION_TYPES.find && !!convertedPlace)
      onSelectedPlace(convertedPlace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationMode, convertedPlace]);

  // Generate nearby places
  useEffect(() => {
    if (!!findSuggestionAddress && !!googlePlaceType)
      getNearbyPlacesByAddress(findSuggestionAddress, googlePlaceType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findSuggestionAddress, googlePlaceType]);

  // When passed a saved location, convert it to place as well
  useEffect(() => {
    if (!!memberLocation) {
      const { place_id, vendor_formatted_address, street_address_1 } =
        memberLocation;
      if (!!place_id) {
        getPlaceDetailsFromPlaceId(place_id);
      } else if (!!vendor_formatted_address) {
        getPlaceDetailsFromFields(vendor_formatted_address);
      } else {
        getPlaceDetailsFromFields(street_address_1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberLocation]);

  // In find in map mode, when bounds idled => search nearby by bounds
  const handleBoundsReady = async (bounds) => {
    if (locationMode === LOCATION_TYPES.find && !!bounds) {
      await getNearbyPlacesByBounds(bounds, googlePlaceType);
    }
  };

  const handleLookupInput = async (suggestion) => {
    if (!!suggestion?.place_id) {
      await getPlaceDetailsFromPlaceId(suggestion.place_id);
    }
  };

  const handleFindInput = (suggestion) => {
    console.debug('suggestion', suggestion);
    if (!!suggestion?.description)
      setFindSuggestionAddress(suggestion.description);
  };

  const handleSelectPinClick = async (place) => {
    // Nearby search returns places in a different format, so we have to yield a regular place from it
    await returnPlaceDetailFromPlaceId(place.place_id);
  };

  let addressInput;

  if (locationMode === LOCATION_TYPES.own) {
    addressInput = (
      <MemberLocationSelect
        value={memberLocation}
        handleChange={onSavedSelect}
      />
    );
  } else if (locationMode === LOCATION_TYPES.lookup) {
    addressInput = (
      <StreetAutocomplete
        street={!!selectedPlace ? selectedPlace.formatted_address : ''}
        types={['geocode', 'establishment']}
        handleChange={handleLookupInput}
      />
    );
  } else if (locationMode === LOCATION_TYPES.find) {
    addressInput = (
      <FindInMap
        street={
          !!selectedPlace?.place_id
            ? `${selectedPlace?.name} - ${
                selectedPlace?.vendor_formatted_address ||
                selectedPlace?.formatted_address
              }`
            : findSuggestionAddress
        }
        handleChange={handleFindInput}
        onPlaceTypeChange={(o) => setGooglePlaceType(o)}
        placeType={googlePlaceType}
      />
    );
  }

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      direction="row"
      // wrap="nowrap"
      alignItems="stretch"
      style={{ maxWidth: 'none', height: '100%' }}
    >
      {/* <Grid container item xs={12} alignItems="flex-end"> */}
      {/* <Grid item xs={3}>
        <LocationModeSelector
          value={locationMode}
          handleChange={onModeChange}
        />
      </Grid> */}
      <Grid item xs={12}>
        {addressInput}
      </Grid>
      {/* </Grid> */}
      {!!error && (
        <Grid item xs={12}>
          <FormHelperText error={!!error}>
            {!!error ? error : ''}
          </FormHelperText>
        </Grid>
      )}
      <Grid item xs={12} style={{ height: 'calc(100% - 40px)' }}>
        {!!places && places.length > 0 && (
          <LocationMap
            places={places}
            selectedPlace={selectedPlace}
            onSelectClick={
              locationMode === LOCATION_TYPES.find ? handleSelectPinClick : null
            }
            onBoundsReady={handleBoundsReady}
            error={mapError}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LocationLine;
