import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Button from '../../Dashboard/CardButton';
import IndustriesAutocomplete from '../../IndustriesAutocomplete';
import AddressInputForm from '../../../components/AddressInputForm';
import AmeraAccordion from '../../../components/AmeraAccordion/AmeraAccordion';
import { DEVICE_TYPES } from '../../../utils/contact';
import { destructureAddress, MAP_VENDORS } from '../../../utils/country';
import { useSelector } from 'react-redux';
import LocationMap from '../../../components/Modal/EventModals/LocationMap';
import useGoogleMap from '../../../hooks/useGoogleMap';
import { trimStart, keys, set } from 'lodash';

const CompanyDetailEdit = (props) => {
  const { companyData, onDiscardClick, onApplyClick, isLoading } = props;

  console.debug(companyData);
  const [name, setName] = useState(companyData.name || '');
  const [industries, setIndustries] = useState(companyData.company_industries);
  const [email, setEmail] = useState(companyData.email || '');
  const [primaryUrl, setPrimaryUrl] = useState(companyData.primary_url);
  const [phone, setPhone] = useState(companyData.main_phone);
  const [countryCode, setCountryCode] = useState(companyData.country_code_id);
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    companyData.phone_country_code_id || companyData.country_code_id
  );
  const [streetAddress1, setStreetAddress1] = useState(
    companyData.street_address_1
  );
  const [streetAddress2, setStreetAddress2] = useState(
    companyData.street_address_2
  );
  const [adminArea1, setAdminArea1] = useState(companyData.admin_area_1);
  const [adminArea2, setAdminArea2] = useState(companyData.admin_area_2);
  const [locality, setLocality] = useState(companyData.locality);
  const [sublocality, setSublocality] = useState(companyData.sub_locality);
  const [postal, setPostal] = useState(companyData.postal_code);
  const [placeId, setPlaceId] = useState(companyData.place_id);
  // Errors
  const [errors, setErrors] = useState({});

  const countryList = useSelector((s) => s.member.countryList);
  const countryObj = countryList.find((cli) => cli.id === phoneCountryCode);
  const countryAlpha2 = countryObj?.alpha2.toLowerCase() || 'us';
  const countryPhonePrefix = countryObj?.phone || 1;

  const {
    places,
    getPlaceDetailsFromPlaceId,
    getPlaceDetailsFromFields,
    mapError,
  } = useGoogleMap();
  const firstPlace = !!places?.length > 0 && places[0];

  useEffect(() => {
    if (!!placeId) {
      getPlaceDetailsFromPlaceId(placeId);
    } else {
      // Just the inital state here, only first time, when no place_id available
      // getPlaceDetailsFromFields(companyData);
    }
  }, [
    companyData,
    getPlaceDetailsFromFields,
    getPlaceDetailsFromPlaceId,
    placeId,
  ]);

  useEffect(() => {
    const parsed =
      !!firstPlace && destructureAddress(firstPlace.address_components);
    // console.debug('firstPlace', firstPlace);
    if (!!parsed) {
      setStreetAddress1(parsed?.streetAddress1);
      if (!!parsed?.streetAddress2) setStreetAddress2(parsed.streetAddress2);
      setLocality(parsed?.locality);
      setSublocality(parsed?.sublocality);
      setAdminArea1(parsed?.adminArea1);
      setAdminArea2(parsed?.adminArea2);
      setPostal(parsed?.postal);
    }
  }, [firstPlace]);

  const handleSuggestionClick = (suggestion) => {
    if (suggestion?.place_id) setPlaceId(suggestion.place_id);
  };

  const validateForm = () => {
    let allErrors = {};

    if (!name) set(allErrors, 'name', 'Company name is required');

    // Email is not required, but has to be valid if entered
    if (!!email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
        set(allErrors, 'email', 'Email address is invalid');
    }

    if (phone?.length < 8) set(allErrors, 'phone', 'Phone number too short');

    if (!streetAddress1 || !postal || !adminArea1 || !adminArea2 || !locality)
      set(allErrors, 'location', {
        postal_code: !postal ? 'is required' : '',
        street_address_1: !streetAddress1 ? 'is required' : '',
        locality: !locality ? 'is required' : '',
        admin_area_1: !adminArea1 ? 'is required' : '',
        admin_area_2: !adminArea2 ? 'is required' : '',
      });

    return keys(allErrors).length > 0 ? allErrors : null;
  };

  const handleApplyClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const errors = validateForm();
    if (!!errors) {
      console.debug(errors);
      setErrors(errors);
    } else {
      console.debug('All ok, can sumbit');
      onApplyClick({
        name,
        industries: industries.map((i) => i.industry_id),
        email,
        primaryUrl,
        phone,
        countryCode,
        phoneCountryCode,
        placeId: firstPlace.place_id,
        streetAddress1,
        streetAddress2,
        locality,
        sublocality,
        adminArea1,
        adminArea2,
        postal,
        vendor_formatted_address: firstPlace.formatted_address,
        map_link: firstPlace.url,
        latitude: firstPlace.geometry.location.lat(),
        longitude: firstPlace.geometry.location.lng(),
        map_vendor: MAP_VENDORS.google,
        raw_response: firstPlace,
        location_id: companyData.location_id,
      });
    }
  };

  const handleCountrySelect = (e) => {
    setStreetAddress1('');
    setStreetAddress2('');
    setLocality('');
    setSublocality('');
    setAdminArea1('');
    setAdminArea2('');
    setPostal('');
    setCountryCode(e.target.value);
    setPhoneCountryCode(e.target.value);
  };

  const handleName = (e) => setName(e.target.value);
  const handleIndustries = (e, v) => setIndustries(v);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePrimaryUrl = (e) => setPrimaryUrl(e.target.value);
  const handlePhone = (v, pc) => {
    setPhone(trimStart(v, countryPhonePrefix));
    setPhoneCountryCode(
      countryList.find((cl) => cl.alpha2 === pc.toUpperCase())?.id
    );
  };

  const phoneData = {
    phoneNumber: `${countryPhonePrefix}${phone}`,
    phoneCountry: countryAlpha2,
    phoneType: DEVICE_TYPES.landline,
    variant: 'standard',
    error: errors?.phone,
    handleChange: handlePhone,
  };

  return (
    <AmeraAccordion label="Details" isLoading={isLoading}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12}>
          <TextField
            value={name}
            label="Company name"
            required
            placeholder="ACME Inc"
            fullWidth
            error={!!errors?.name}
            helperText={!!errors?.name ? errors?.name : ''}
            onChange={handleName}
          />
        </Grid>
        <Grid item xs={12}>
          <IndustriesAutocomplete
            onChange={handleIndustries}
            selected={industries}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={email}
            label="E-mail"
            error={!!errors?.email}
            helperText={!!errors?.email ? errors?.email : ''}
            placeholder="example@example.com"
            fullWidth
            onChange={handleEmail}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={primaryUrl}
            label="Website"
            placeholder="www.facebook.com"
            fullWidth
            onChange={handlePrimaryUrl}
          />
        </Grid>

        <AddressInputForm
          countryCode={countryCode}
          streetAddress1={streetAddress1}
          streetAddress2={streetAddress2}
          adminArea1={adminArea1}
          adminArea2={adminArea2}
          locality={locality}
          postal={postal}
          onStreetAddress2={(e) => setStreetAddress2(e.target.value)}
          onLocality={(e) => setLocality(e.target.value)}
          onAdminArea1={(e) => setAdminArea1(e.target.value)}
          onAdminArea2={(e) => setAdminArea2(e.target.value)}
          onPostal={(e) => setPostal(e.target.value)}
          onCountrySelected={handleCountrySelect}
          onSuggestionClick={handleSuggestionClick}
          phone={phoneData}
          error={errors?.location}
        />

        <Grid item xs={12}>
          {!!places && places.length > 0 && (
            <LocationMap
              defaultZoom={13}
              places={places}
              selectedPlace={places[0]}
              onSelectClick={null}
              onBoundsReady={null}
              error={mapError}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: '15rem' }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          )}
        </Grid>
        {
          <Grid item xs={12} container spacing={1} justify="flex-end">
            <Grid item>
              <Button variant="info" text="Cancel" onClick={onDiscardClick} />
            </Grid>
            <Grid item>
              <Button
                variant="okFilled"
                text={!isLoading ? 'Save' : 'Saving..'}
                onClick={(e) => handleApplyClick(e)}
              />
            </Grid>
          </Grid>
        }
      </Grid>
    </AmeraAccordion>
  );
};

export default CompanyDetailEdit;
