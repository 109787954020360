import grey from '@material-ui/core/colors/grey';
import { borderDark, textGrey } from '../../jss/colors';

const passUpdateStyle = (theme) => ({
  authPage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  formSection: {
    padding: '4% 4% 5% 7%',
    backgroundColor: grey[100],
    color: textGrey,
    width: '47%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      fontSize: '2em',
      color: textGrey,
    },
    '& .MuiInputBase-input': {
      height: '1.5em',
    },
    '& .MuiInputLabel-formControl': {
      top: '-0.2em',
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${borderDark}`,
    },
  },
  logoImg: {
    width: '34%',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  emailInput: {
    marginTop: '3em',
    marginBottom: '0.3em',
  },
  passwordInput: {
    marginTop: '3em',
    marginBottom: '0.3em',
  },
  currentPasswordInput: {
    marginTop: '1em',
    marginBottom: '0.3em',
  },
  newPasswordInput: {
    marginTop: '1.5em',
    marginBottom: '0.3em',
  },
  confirmPasswordInput: {
    marginTop: '2em',
    marginBottom: '0.3em',
  },
  authWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3em',
    padding: '0% 25% 0% 25%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '2px',
    '& .MuiButton-label': {
      fontSize: '1.3em',
    },
    '& label': {
      fontSize: '1.3em',
      whiteSpace: 'nowrap',

      '& a': {
        textDecoration: 'none',
        color: textGrey,
        '&:hover, &:active': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

export default passUpdateStyle;
