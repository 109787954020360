import {
  SET_ENCRYPTION_KEY,
  SET_VIDEO_CURRENT_KEY,
} from '../actionTypes/VideoCallRedux';

export const setEncKey = (key) => {
  return function (dispatch) {
    dispatch({
      type: SET_ENCRYPTION_KEY,
      payload: key,
    });
  };
};

export const setGroupCallCurrentKey = (currentKey) => ({
  type: SET_VIDEO_CURRENT_KEY,
  payload: {
    currentKey,
  },
});
