import React from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import drawerProfileStyles from '../../../jss/views/drawerProfileStyles';
import { addressTypeText } from '../../../utils/calendar';

const Filter = ({ types, selected, onChange }) => {
  const classes = drawerProfileStyles();
  return (
    <Grid item container className={classes.margin} spacing={1}>
      {[...types, 'All'].sort().map((t) => (
        <Grid key={t} item>
          <Chip
            size="small"
            label={addressTypeText(t)}
            clickable
            className={clsx({
              [classes.selectedChip]: t === selected,
            })}
            onClick={() => onChange(t)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Filter;
