import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core';
import SVG from '../../../components/Icons/SVG';
import { changeLocale } from '../../../redux/actions/app';
import PropTypes from 'prop-types';
import { countryCodes, languageCodes } from '../../../utils/language';
import ReactFlagsSelect from 'react-flags-select';
import styles from '../../../jss/views/guestHeaderStyle';

const useStyles = makeStyles(styles);

export const messages = {
  aboutUs: {
    id: 'app.guest.header.aboutUs',
    defaultMessage: 'About Us',
  },
  pricing: {
    id: 'app.guest.header.pricing',
    defaultMessage: 'Plans & Pricing',
  },
  features: {
    id: 'app.guest.header.features',
    defaultMessage: 'Features',
  },
  demo: {
    id: 'app.guest.header.demo',
    defaultMessage: 'Demo',
  },
  search: {
    id: 'app.guest.header.help',
    defaultMessage: 'Help',
  },
  login: {
    id: 'app.guest.header.login',
    defaultMessage: 'LogIn',
  },
};

const Header = (props) => {
  const classes = useStyles();

  const {
    intl: { formatMessage },
  } = props;

  const changeLanguage = (countryCode) => {
    const index = countryCodes.findIndex((x) => x === countryCode);
    props.changeLocale(languageCodes[index]);
  };

  const country =
    countryCodes[languageCodes.findIndex((x) => x === props.locale)];

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <SVG name="logo" />
      </div>
      <div className={classes.navBar}>
        <div className={classes.leftGroup}>
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <span>{formatMessage(messages.aboutUs)}</span>
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <span>{formatMessage(messages.pricing)}</span>
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <span>{formatMessage(messages.features)}</span>
          </Link>
        </div>
        <div className={classes.rightGroup}>
          <ReactFlagsSelect
            countries={countryCodes}
            customLabels={{
              US: 'English',
              JP: 'Japanese',
              KR: 'Korean',
              CN: 'Chinese',
              DE: 'German',
              ES: 'Spanish',
            }}
            defaultCountry={country}
            alignOptions="left"
            onSelect={changeLanguage}
          />
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <span>{formatMessage(messages.search)}</span>
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/login`}>
            <span>{formatMessage(messages.login)}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  changeLocale: PropTypes.func,
  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locale: state.global.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeLocale }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
