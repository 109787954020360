import React from 'react';
import { Typography } from '@material-ui/core';

const AchievementItem = (props) => {
  const { achievementItem } = props;
  const { entity, description } = achievementItem;
  return (
    <Typography className="text-content">{`${entity} - ${description}`}</Typography>
  );
};

export default AchievementItem;
