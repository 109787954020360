import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import VideocamIcon from '@material-ui/icons/Videocam';
import GroupIcon from '@material-ui/icons/Group';
import TimerIcon from '@material-ui/icons/Timer';
import ViewStreamIcon from '@material-ui/icons/ViewStream';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottom: '1px solid #D4E2F0',
    gap: 8,
    padding: `31px 42px 19px 48px`,
    marginLeft: -20,
    backgroundColor: 'white',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
  },
  search: {
    height: 38,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    marginRight: 50,
    '& input': {
      color: '#8DA3C5',
    },
    '& svg': {
      marginLeft: 20,
      marginRight: 8,
      color: '#8DA3C5',
    },
  },
  button: {
    background: 'transparent !important',
    boxShadow: 'none !important',
    color: '#8EA4C6',
    font: 18,
    textTransform: 'capitalize',
  },
}));

const Toolbar = (props) => {
  const { searchKey, onSearch, handleWidget, activeWidget } = props;
  const classes = useStyles();

  const leftButtons = [
    {
      name: 'Videos',
      color: 'default',
      onClick: () => {},
      icon: <VideocamIcon />,
    },
    {
      name: 'Discussions',
      color: 'default',
      onClick: () => {},
      icon: <GroupIcon />,
    },
    {
      name: activeWidget ? 'Scheduler' : 'Schedule',
      color: 'default',
      onClick: () => handleWidget(),
      icon: <TimerIcon />,
    },
    {
      name: 'Now Streaming',
      color: 'default',
      onClick: () => {},
      icon: <ViewStreamIcon />,
    },
  ];

  return (
    <div className={classes.toolbar}>
      <div className={classes.search}>
        <SearchIcon />
        <InputBase
          placeholder="Search..."
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={searchKey}
          onChange={onSearch}
        />
      </div>
      <div className={classes.buttons}>
        {leftButtons.map((button) => {
          return (
            <Button
              variant="contained"
              className={classes.button}
              onClick={button.onClick}
              color={button.color}
              key={button.name}
              startIcon={button.icon}
            >
              {button.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Toolbar;
