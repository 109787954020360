import React, { useMemo } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

const JobTitleInput = ({
  jobTitle,
  errors,
  onChange,
  jobTitleList,
  variant = 'outlined',
  isRequired = true,
}) => {
  const jobList = useMemo(
    () =>
      jobTitleList.map((jto) => (
        <MenuItem key={jto.job_title_id} value={jto.job_title_id}>
          {jto.job_title}
        </MenuItem>
      )),
    [jobTitleList]
  );

  return (
    <FormControl
      variant={variant}
      fullWidth
      required={isRequired}
      className="register_form_input"
      error={errors ? true : false}
    >
      <InputLabel id="job-title-input-label">Job Title</InputLabel>
      <Select
        labelId="job-title-select-label"
        id="job-title-select"
        name="job_title_id"
        // displayEmpty
        // defaultValue="Select title"
        value={jobTitle}
        onChange={onChange}
      >
        {jobList}
      </Select>
      <FormHelperText error>{errors ? errors : null}</FormHelperText>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  jobTitleList: state.member.jobTitleList,
});

export default connect(mapStateToProps)(JobTitleInput);
