import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const AllDayCheckbox = (props) => {
  const { checked, onChange, ...passProps } = props;
  return (
    <FormControlLabel
      value="end"
      control={
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange}
          name="allDayEvent"
        />
      }
      label="All Day Event"
      labelPlacement="end"
      {...passProps}
    />
  );
};

export default AllDayCheckbox;
