import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

const MainBinSwitch = (props) => {
  const { mode, handleClick, isGroup } = props;
  return (
    <ToggleButtonGroup
      className="mode-toggle"
      exclusive
      value={mode}
      onChange={handleClick}
    >
      <ToggleButton value="main">
        <FolderIcon /> {isGroup ? 'Group Files' : 'My Files'}
      </ToggleButton>
      <ToggleButton value="bin">
        <DeleteIcon /> Trash
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default MainBinSwitch;
