import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { includes } from 'lodash';

const FullNameInput = (props) => {
  const {
    errors,
    values,
    onInputChange,
    include,
    direction,
    textFieldClass,
  } = props;
  const inputFields = {
    memberName: [
      {
        fieldName: 'first_name',
        label: 'First Name',
      },
      { fieldName: 'middle_name', label: 'Middle Name' },
      {
        fieldName: 'last_name',
        label: 'Last Name',
      },
    ],
  };

  return (
    <Grid container direction={direction} justify="space-between">
      {inputFields.memberName
        .filter((name) => includes(include, name.fieldName))
        .map((name) => (
          <Grid
            item
            key={name.fieldName}
            // xs={direction === 'column' ? 12 : 4}
            className="namefield-container"
          >
            <TextField
              id={`outlined-${name.fieldName}`}
              label={name.label}
              placeholder={name.label}
              required={name.fieldName !== 'middle_name'}
              type="text"
              variant="outlined"
              name={name.fieldName}
              className={textFieldClass}
              autoComplete="off"
              value={values[name.fieldName] || ''}
              onChange={onInputChange}
              error={errors[name.fieldName] ? true : false}
              helperText={errors[name.fieldName] ? errors[name.fieldName] : ''}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default FullNameInput;
