import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DenseTabs from '../components/DenseTabs/DenseTabs';
import DenseTab from '../components/DenseTabs/DenseTab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';

import { CALENDAR_VIEWS } from '../utils/calendar';

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  filter: {
    marginLeft: 20,
    width: 150,
  },
  createEventButton: {
    alignSelf: 'center',
    '& button': {
      borderRadius: 20,
    },
  },
  pageSettingsIcon: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: 'auto',
    '& svg:hover': {
      cursor: 'pointer',
      color: '#868282',
    },
  },
  collaborativeBtn: {
    paddingRight: 0,
    marginRight: 0,
  },
  settingButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

const FunctionalToolbar = (props) => {
  const {
    view,
    label,
    // localizer,
    onNavigate,
    onView,
    handleViewChange,
    classes,
    handleCreateEventClick,
    setShowPageSettingsModal,
  } = props;

  const handleViewClick = (e, v) => {
    if (v === CALENDAR_VIEWS.collaborative) handleViewChange(v);
    else onView(v);
  };

  return (
    <Grid container alignItems="center" justify="space-between" spacing={0}>
      <Grid
        item
        container
        wrap="nowrap"
        justify="space-between"
        xs={12}
        className={classes.root}
        spacing={0}
      >
        <Grid item xs={2} className={classes.createEventButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateEventClick}
            startIcon={<AddIcon />}
          >
            Create Event
          </Button>
        </Grid>

        <Grid
          item
          container
          justify="center"
          alignItems="center"
          direction="row"
          wrap="nowrap"
          xs={3}
        >
          <Tooltip title="Show previous">
            <IconButton onClick={() => onNavigate('PREV')}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>

          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Tooltip title="Show current">
                <Button
                  onClick={() => onNavigate('TODAY')}
                  style={{ textTransform: 'none' }}
                >
                  <Typography variant="h5" style={{ minWidth: 160 }}>
                    {label}
                  </Typography>
                </Button>
              </Tooltip>
            </Grid>
          </Grid>

          <Tooltip title="Show next">
            <IconButton onClick={() => onNavigate('NEXT')}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={4} className={classes.settingButtons}>
          <DenseTabs
            value={view}
            onChange={handleViewClick}
            indicatorColor="primary"
            style={{ minWidth: 330 }}
          >
            <DenseTab value={CALENDAR_VIEWS.month} label="Month" />
            <DenseTab value={CALENDAR_VIEWS.week} label="Week" />
            <DenseTab value={CALENDAR_VIEWS.day} label="Day" />
            <DenseTab
              value={CALENDAR_VIEWS.collaborative}
              label="Collaborative"
              className={classes.collaborativeBtn}
            />
          </DenseTabs>
          <div className={classes.pageSettingsIcon}>
            <Tooltip title="Settings" arrow>
              <SettingsIcon onClick={() => setShowPageSettingsModal(true)} />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(useStyles)(FunctionalToolbar);
