import * as api from '../../config/api';

import {
  GET_ACTIVITY_ACTIVITY_SUCCEEDED,
  GET_ACTIVITY_ACTIVITY_FAILED,
  GET_ACTIVITY_SECURITY_SUCCEEDED,
  GET_ACTIVITY_SECURITY_FAILED,
  GET_ACTIVITY_MESSAGE_SUCCEEDED,
  GET_ACTIVITY_MESSAGE_FAILED,
  GET_ACTIVITY_GROUP_SUCCEEDED,
  GET_ACTIVITY_GROUP_FAILED,
  GET_ACTIVITY_INVITATION_SUCCEEDED,
  GET_ACTIVITY_INVITATION_FAILED,
} from '../actionTypes/activity.js';

const apiUrl = api.apiUrl;
const activity_endpoint = '/system/activity/activity';
const security_endpoint = '/system/activity/security';
const message_endpoint = '/system/activity/message';
const group_endpoint = '/system/activity/group';
const invitations_endpoint = '/system/activity/invitations';

export const getActivity = (params = {}) => {
  return (dispatch) => {
    const url = `${apiUrl}${activity_endpoint}?${new URLSearchParams(
      params
    ).toString()}`;
    api
      .GET(url)
      .then((res) => {
        if (res.success && res.data.activities) {
          dispatch({
            type: GET_ACTIVITY_ACTIVITY_SUCCEEDED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_ACTIVITY_ACTIVITY_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_ACTIVITY_ACTIVITY_FAILED, payload: error });
      });
  };
};

export const getSecurity = (params = {}) => {
  return (dispatch) => {
    const url = `${apiUrl}${security_endpoint}?${new URLSearchParams(
      params
    ).toString()}`;

    api
      .GET(url)
      .then((res) => {
        if (res.success && res.data.activities) {
          dispatch({
            type: GET_ACTIVITY_SECURITY_SUCCEEDED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_ACTIVITY_SECURITY_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_ACTIVITY_SECURITY_FAILED, payload: error });
      });
  };
};

export const getMessage = (params = {}) => {
  return (dispatch) => {
    const url = `${apiUrl}${message_endpoint}?${new URLSearchParams(
      params
    ).toString()}`;

    api
      .GET(url)
      .then((res) => {
        if (res.success && res.data.activities) {
          dispatch({
            type: GET_ACTIVITY_MESSAGE_SUCCEEDED,
            payload: res.data,
            videoOnly: !!params.videoOnly,
          });
        } else {
          dispatch({
            type: GET_ACTIVITY_MESSAGE_FAILED,
            payload: res.description,
            videoOnly: !!params.videoOnly,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_ACTIVITY_MESSAGE_FAILED, payload: error });
      });
  };
};

export const getGroup = (params = {}) => {
  return (dispatch) => {
    const url = `${apiUrl}${group_endpoint}?${new URLSearchParams(
      params
    ).toString()}`;

    api
      .GET(url)
      .then((res) => {
        if (res.success && res.data.group_invitations) {
          dispatch({ type: GET_ACTIVITY_GROUP_SUCCEEDED, payload: res.data });
        } else {
          dispatch({
            type: GET_ACTIVITY_GROUP_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_ACTIVITY_GROUP_FAILED, payload: error });
      });
  };
};

export const getInvitation = (params = {}) => {
  return (dispatch) => {
    const url = `${apiUrl}${invitations_endpoint}?${new URLSearchParams(
      params
    ).toString()}`;

    api
      .GET(url)
      .then((res) => {
        if (res.success && res.data.invitations) {
          dispatch({
            type: GET_ACTIVITY_INVITATION_SUCCEEDED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_ACTIVITY_INVITATION_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_ACTIVITY_INVITATION_FAILED, payload: error });
      });
  };
};
