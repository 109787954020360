import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Drawer } from '@material-ui/core';
import { RightIcon } from '../../../assets/svg';
import { closeDrawer } from '../../../redux/actions/drawer';
import NewsFeed from '../../../components/Drawer/NewsFeed';
import GroupCall from '../../../components/Drawer/GroupCall';
import ContactCall from '../../../components/Drawer/ContactCall';
import ContactProfile from '../../../components/Drawer/ContactProfile';
import MyProfileInfo from '../../../components/Drawer/MyProfileInfo';
import MyProfileEdit from '../../../components/Drawer/MyProfileEdit';
import CompanyProfile from '../../../components/Drawer/CompanyProfile';
import CompanyCreate from '../../../components/Drawer/CompanyCreate';
import SecurityInformation from '../../Profile/SecurityInformation';
import PasswordAndLoginManager from '../../../components/Drawer/PasswordAndLoginManager';
import Uploadcontent from '../../../components/Drawer/UploadContent';
import Broadcast from '../../../components/Drawer/Broadcast';
import ConfirmDialog from '../../../components/ConfirmDialog';
import GroupProfile from '../../../components/Drawer/GroupProfile';
import LocationManager from '../../../components/Drawer/LocationManager';
import Event from '../../../components/Drawer/Event';
import { DRAWER_CONTENT_TYPE, CALL_TARGET } from '../../../utils/drawer';

const useDrawerStyles = makeStyles((theme) => ({
  drawer: {
    width: ({ drawer_width }) => drawer_width,
    flexShrink: 0,
  },
  drawerPaper: {
    width: ({ drawer_width }) => drawer_width,
    overflowY: 'unset',
  },
  closeDrawer: {
    '& .MuiIconButton-root': {
      border: '1px solid #E0E0E0',
      position: 'absolute',
      top: '16px',
      left: 0,
      background: 'white',
      transform: 'translateX(-50%)',
      zIndex: 9999,
    },
  },
  contentDrawer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    background: '#fff',
    zIndex: 10,
  },
}));

const useBackDropStyles = makeStyles((theme) => ({
  root: { opacity: '0.1 !important' },
}));

const AmeraDrawer = (props) => {
  const {
    isOpen,
    type,
    contentProps,
    drawerVariant,
    isEditing,
    dispatch,
    width,
  } = props;

  const classes = useDrawerStyles({ drawer_width: width });
  const backdropClasses = useBackDropStyles();

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    dispatch(closeDrawer());
  };

  const handleCloseAttempt = () => {
    // if (isEditing) {
    //   setConfirmOpen(true);
    // } else {
    console.debug(`Is Editing: ${isEditing}`);
    handleCloseConfirm();
    // }
  };

  const content = useMemo(() => {
    if (type === DRAWER_CONTENT_TYPE.newsfeeds) {
      return <NewsFeed />;
    } else if (
      type === DRAWER_CONTENT_TYPE.audioCall ||
      type === DRAWER_CONTENT_TYPE.videoCall
    ) {
      if (contentProps?.callTarget === CALL_TARGET.group) {
        return <GroupCall />;
      } else if (contentProps?.callTarget === CALL_TARGET.contact) {
        return <ContactCall />;
      }
    } else if (type === DRAWER_CONTENT_TYPE.contactProfile) {
      return <ContactProfile />;
    } else if (type === DRAWER_CONTENT_TYPE.locationManager) {
      return <LocationManager />;
    } else if (type === DRAWER_CONTENT_TYPE.companyProfile) {
      return <CompanyProfile />;
    } else if (type === DRAWER_CONTENT_TYPE.companyCreate) {
      return <CompanyCreate />;
    } else if (type === DRAWER_CONTENT_TYPE.securitySettings) {
      return <SecurityInformation />;
    } else if (type === DRAWER_CONTENT_TYPE.myProfileInfo) {
      return <MyProfileInfo />;
    } else if (type === DRAWER_CONTENT_TYPE.myProfileEdit) {
      return <MyProfileEdit />;
    } else if (type === DRAWER_CONTENT_TYPE.passwordAndLoginManager) {
      return <PasswordAndLoginManager />;
    } else if (type === DRAWER_CONTENT_TYPE.uploadcontent) {
      return <Uploadcontent {...contentProps} />;
    } else if (type === DRAWER_CONTENT_TYPE.broadcast) {
      return <Broadcast {...contentProps} />;
    } else if (type === DRAWER_CONTENT_TYPE.groupProfile) {
      return <GroupProfile />;
    } else if (type === DRAWER_CONTENT_TYPE.event) {
      return <Event {...contentProps} />;
    }
  }, [contentProps, type]);

  return (
    <>
      {isConfirmOpen && (
        <ConfirmDialog
          open={isConfirmOpen}
          title="Cancel changes"
          onOk={handleCloseConfirm}
          onCancel={() => setConfirmOpen(false)}
          content="Are you sure you want to close and cancel changes?"
        />
      )}
      <Drawer
        variant={drawerVariant}
        BackdropProps={{
          classes: backdropClasses,
        }}
        ModalProps={{
          onEscapeKeyDown: handleCloseAttempt,
          onBackdropClick: handleCloseAttempt,
        }}
        className={classes.drawer}
        anchor="right"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.contentDrawer}>
          <div className={classes.closeDrawer}>
            <IconButton onClick={handleCloseAttempt}>
              <RightIcon />
            </IconButton>
          </div>
          {isOpen && content}
        </div>
      </Drawer>
    </>
  );
};

const mapStateToPtops = (state) => ({
  isOpen: state.drawer.openDrawer,
  drawerVariant: state.drawer.variant,
  type: state.drawer.type,
  contentProps: state.drawer.contentProps,
  isEditing: state.drawer.isEditing,
  width: state.drawer.width,
});

export default connect(mapStateToPtops)(AmeraDrawer);
