import React, { useState, useMemo } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { apiUrl } from '../config/api';
import { withStyles } from '@material-ui/core/styles';

import { gray, sushi, green, white } from '../jss/colors';

const MaterialAvatar = ({
  member_id,
  classes = undefined,
  firstName,
  lastName,
  src = undefined,
  onClick = undefined,
  isHoverName = undefined,
  badgeType = undefined,
  badge2Type = undefined,
  badgeVariant = undefined,
  badge2Variant = undefined,
  isBasicAvatar = undefined,
  style = undefined,
  variant = undefined,
  badgeAnchorOrigin = undefined,
  badge2AnchorOrigin = undefined,
  ...otherProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isBrokenUrl, setIsBrokenUrl] = useState(false);

  //   Initials used as fallback only

  const handlePopoverOpen = (
    /** @type {React.MouseEvent<HTMLDivElement, MouseEvent>} */ event
  ) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  let badgeIcon,
    bgColor,
    baseRem,
    color,
    badge2Icon,
    bgColor2,
    baseRem2,
    color2;
  switch (badgeType) {
    case 'settings':
      baseRem = 1.5;
      badgeIcon = <SettingsIcon style={{ fontSize: `${baseRem * 0.75}rem` }} />;
      bgColor = 'black';
      color = 'white';
      break;
    case 'edit':
      baseRem = 2;
      badgeIcon = (
        <Tooltip title="Edit Picture" arrow>
          <EditIcon style={{ fontSize: `${baseRem * 0.75}rem` }} />
        </Tooltip>
      );
      bgColor = 'white';
      color = sushi;
      break;
    case 'schedule':
      baseRem = 2;
      badgeIcon = (
        <Tooltip title="Schedule a meeting" arrow>
          <DateRangeIcon style={{ fontSize: `${baseRem * 0.75}rem` }} />
        </Tooltip>
      );
      bgColor = 'white';
      color = sushi;
      break;
    case 'online':
      baseRem = 0.5;
      badgeIcon = '';
      bgColor = '#3DBA4E';
      color = 'white';
      break;
    case 'offline':
      baseRem = 0.5;
      badgeIcon = null;
      bgColor = gray;
      color = 'white';
      break;
    default:
      badgeIcon = null;
  }
  switch (badge2Type) {
    case 'online':
      baseRem2 = 0.7;
      badge2Icon = '';
      bgColor2 = green;
      color2 = white;
      break;
    case 'offline':
      baseRem2 = 0.7;
      badge2Icon = '';
      bgColor2 = gray;
      color2 = white;
      break;
    default:
      badge2Icon = null;
  }

  const IconBadge = withStyles((theme) => ({
    badge: {
      padding: 0,
      zIndex: 999,
      cursor: 'pointer',
      backgroundColor: bgColor,
      color: color,
      height: `${baseRem}rem`,
      width: `${baseRem}rem`,
      minWidth: `${baseRem}rem`,
      borderRadius: `${baseRem / 2}rem`,
      border: `1px solid ${color}`,
      boxShadow:
        badgeType === 'online' || badgeType === 'offline'
          ? `0 0 0 2px ${theme.palette.background.paper}`
          : '',
    },
    anchorOriginTopRightCircle: {
      top: '5%',
      right: '5%',
    },
  }))(Badge);

  const IconBadge2 = withStyles((theme) => ({
    badge: {
      padding: 0,
      zIndex: 999,
      cursor: 'pointer',
      backgroundColor: bgColor2,
      color: color2,
      height: `${baseRem2}rem`,
      width: `${baseRem2}rem`,
      minWidth: `${baseRem2}rem`,
      borderRadius: `${baseRem2 / 2}rem`,
      border: `1px solid ${color2}`,
      boxShadow:
        badge2Type === 'online' || badge2Type === 'offline'
          ? `0 0 0 2px ${theme.palette.background.paper}`
          : '',
    },
  }))(Badge);

  const format = (/** @type {string} */ name) => {
    if (name) {
      return name.charAt(0).toUpperCase();
    } else {
      return '';
    }
  };

  const source = (
    /** @type {string} */ srcProp,
    /** @type {number} */ member_id = null
  ) => {
    if (member_id) {
      return `${apiUrl}/member/${member_id}/avatar`;
    }
    if (srcProp) {
      const isURL = srcProp && srcProp.includes('http');
      const isDataURL = srcProp && srcProp.startsWith('data:');
      const isStaticURL = srcProp && srcProp.startsWith('/static');
      // const isDataURL = RegExp(
      //   /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i
      // ).test(srcProp);
      if (isURL || isDataURL || isStaticURL) {
        return srcProp;
      } else {
        return `${apiUrl}${srcProp}`;
      }
    } else {
      return null;
    }
  };

  const body = useMemo(() => {
    if (isBasicAvatar) {
      return (
        <Avatar
          src={isBrokenUrl ? null : source(src)}
          alt={`${firstName} ${lastName}`}
          {...otherProps}
          imgProps={{
            onError: () => {
              setIsBrokenUrl(true);
            },
          }}
        />
      );
    } else {
      return (
        <IconBadge
          badgeContent={badgeIcon}
          overlap="circle"
          color="secondary"
          anchorOrigin={badgeAnchorOrigin}
          variant={badgeVariant}
        >
          <IconBadge2
            badgeContent={badge2Icon}
            overlap="circle"
            color="secondary"
            anchorOrigin={badge2AnchorOrigin}
            variant={badge2Variant}
          >
            <Avatar
              src={isBrokenUrl ? null : source(src, member_id)}
              alt={`${firstName} ${lastName}`}
              className={`material-avatar ${classes}`}
              style={style}
              onMouseEnter={isHoverName ? (e) => handlePopoverOpen(e) : null}
              onMouseLeave={isHoverName ? handlePopoverClose : null}
              variant={variant}
              imgProps={{
                onError: () => {
                  setIsBrokenUrl(true);
                },
              }}
            >
              {`${format(firstName)}${format(lastName)}`}
            </Avatar>
            {isHoverName ? (
              <Popover
                id={`${classes}_${firstName}_${lastName}`}
                open={Boolean(anchorEl)}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                disableRestoreFocus
              >
                <Typography>{`${firstName} ${lastName}`}</Typography>
              </Popover>
            ) : null}
          </IconBadge2>
        </IconBadge>
      );
    }
  }, [
    member_id,
    src,
    classes,
    firstName,
    lastName,
    isHoverName,
    badgeVariant,
    badge2Variant,
    isBasicAvatar,
    badgeIcon,
    badge2Icon,
    style,
    variant,
    badgeAnchorOrigin,
    badge2AnchorOrigin,
    otherProps,
    anchorEl,
    isBrokenUrl,
  ]);

  return onClick !== undefined && onClick !== null ? (
    isBasicAvatar ? (
      body
    ) : (
      <IconButton onClick={onClick} style={{ cursor: 'pointer' }}>
        {body}
      </IconButton>
    )
  ) : (
    <>{body}</>
  );
};

MaterialAvatar.defaultProps = {
  badgeVariant: 'standard',
  badge2Variant: 'standard',
  variant: 'rounded',
  isBasicAvatar: false,
  badgeAnchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  badge2AnchorOrigin: { horizontal: 'right', vertical: 'top' },
};

export default React.memo(MaterialAvatar);
