import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getGroupDriveActivity } from '../../redux/actions/group';
import ContactPreview from '../../components/ContactPreview';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  activityWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  contactPreviewWrapper: {
    width: '285px',
  },
  action: {
    width: '150px',
    fontSize: '1.2em',
  },
  createDate: {
    marginRight: theme.spacing(3),
  },
}));

const DriveActivity = (props) => {
  const { group_id } = props;
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const dispatch = useDispatch();
  const { groupData, groupLeaderData, groupDriveActivity } = useSelector(
    (state) => ({
      groupData: state.group.groupData,
      groupLeaderData: state.group.groupLeaderData,
      groupDriveActivity: state.group.groupDriveActivity,
    })
  );

  useEffect(() => {
    if (group_id) dispatch(getGroupDriveActivity(group_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      {groupDriveActivity &&
        groupDriveActivity.activities.length > 0 &&
        groupDriveActivity.activities
          .slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage
          )
          .map((activity) => {
            let contactData =
              activity.member_id === groupData.group_leader_id
                ? groupLeaderData
                : groupData.members.find(
                    (member) => member.member_id === activity.member_id
                  );
            let actionLabel = '';
            let objectName = 'files';
            switch (activity.event_type) {
              case 'create_group_folder':
                if (activity.request_data.folder_name) {
                  actionLabel = 'Created';
                  objectName = activity.request_data.folder_name;
                } else {
                  actionLabel = 'Uploaded';
                }
                break;
              case 'delete_group_file':
                actionLabel = 'Deleted';
                objectName = activity.display_name
                  ? activity.display_name
                  : 'a file';
                break;
              case 'move_group_file':
                actionLabel = 'Moved';
                objectName = activity.request_params.changes[0].name;
                break;
              default:
                break;
            }

            return (
              <div
                key={activity.create_date}
                className={classes.activityWrapper}
              >
                <div className={classes.createDate}>
                  {format(new Date(activity.create_date), 'MM/dd/yyyy HH:MM')}
                </div>
                <div className={classes.contactPreviewWrapper}>
                  <ContactPreview
                    contactData={contactData}
                    isShowAvatar={true}
                  />
                </div>
                <div className={classes.action}>{actionLabel}</div>
                <div>{objectName}</div>
              </div>
            );
          })}
      {groupDriveActivity && groupDriveActivity.activities.length > 25 && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(
              Math.ceil(groupDriveActivity.activities.length / rowsPerPage)
            )}
            page={page}
            onChange={handleChangePage}
            defaultPage={1}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </div>
  );
};

export default DriveActivity;
