import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Divider } from '@material-ui/core';
import ReactJson from 'react-json-view';

import Modal from './modal';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    margin: '0 auto',
  },
  closeBtn: {
    margin: theme.spacing(1),
    width: 140,
  },
  //   modalContent: {
  //     minHeight: 300,
  //   },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const JSONViewModal = (props) => {
  const { open, onClose, title, jsonArray } = props;

  const classes = useStyles();

  const ModalContent = () => (
    <div className={classes.modalContent}>
      {jsonArray.length > 0 &&
        jsonArray.map((jsonObj, i) => {
          return (
            <div key={i}>
              <Typography variant="subtitle1" gutterBottom>
                {jsonObj.title}
              </Typography>
              <ReactJson
                theme="rjv-default"
                displayObjectSize
                collapsed={jsonObj.collapseLevel}
                src={jsonObj.data}
              />
              {i + 1 !== jsonArray.length && (
                <Divider className={classes.divider} orientation="horizontal" />
              )}
            </div>
          );
        })}
    </div>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.closeBtn}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="json-view-modal"
    ></Modal>
  );
};

JSONViewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  jsonArray: PropTypes.array,
};

JSONViewModal.defaultProps = {
  open: false,
  onClose: null,
  title: 'JSON View',
  jsonArray: [],
};

export default JSONViewModal;
