import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import styles from '../ProjectManagement.module.scss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import MaterialAvatar from '../../../components/MaterialAvatar';
import AmeraModal from '../../../components/Modal/modal';
import AttributeSelectionGroup from './AttributeSelectionGroup';
import AttributePills from './AttributePills';
import { useDispatch } from 'react-redux';
import { ProjectsApi } from '../../../redux/actions/project';

import { Details } from './RateModal';

const AssignAttribute = (props) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [openRolesModal, setOpenRolesModal] = useState(false);
  const [memberRoles, setMemberRoles] = useState([]);
  // let memberRoles = []
  const [activeMember, setActiveMember] = useState(null);
  const [checkBoxes, setCheckBoxes] = useState([
    { role_id: '', name: '', checked: 'false' },
  ]);

  const { type, contacts, data } = props;
  useEffect(() => {
    let memRoleArray = [];
    //replace with promise when fetching from API
    // dispatch(ProjectsApi.getProjectRoles())
    contacts.forEach((c) => {
      memRoleArray.push({
        member_id: c.member_id,
        roles: c.project_roles,
        project_member_id: c.project_member_id,
      });
    });

    setMemberRoles(memRoleArray);
    populateCheckboxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateCheckboxes = (member_id = null, memRoles = memberRoles) => {
    const roleArray = data;
    let checkBoxArray;
    if ((activeMember || member_id) === null) {
      checkBoxArray = roleArray.map((role) => {
        return { attr_id: role.id, name: role.name, checked: false };
      });
    } else {
      const member = memRoles.find((m) => m.member_id === member_id);
      checkBoxArray = roleArray.map((role) => {
        const isRoleSet = member?.roles.includes(role.id);
        return { attr_id: role.id, name: role.name, checked: isRoleSet };
      });
    }

    setCheckBoxes(checkBoxArray);
  };

  const handleAddRole = (contact_id) => {
    setActiveMember(contact_id);
    setOpenRolesModal(true);
    populateCheckboxes(contact_id);
  };

  const handleRoleSelect = (e) => {
    const memRolesArray = memberRoles.map((member) => {
      if (member.member_id === activeMember) {
        const role = +e.target.value;
        const checked = e.target.checked;
        let roles = [...member.roles];
        if (roles.indexOf(role) < 0) {
          if (checked === true) {
            roles.push(role);
          }
        }
        if (roles.indexOf(role) > -1) {
          if (checked === false) {
            const filterRoles = roles.filter((item) => item !== role);
            roles = filterRoles;
          }
        }
        return { ...member, roles: roles };
      } else {
        return member;
      }
    });

    setMemberRoles(memRolesArray);
    populateCheckboxes(activeMember, memRolesArray);
  };

  const handleRoleModalClose = () => {
    setOpenRolesModal(false);
  };

  const handleRoleApply = (e) => {
    //find active member and POST roles to API
    const payload = {
      project_id: +projectId,
      roles_data: [
        ...memberRoles.map((mr) => ({
          project_member_id: mr.project_member_id,
          role_ids: mr.roles,
        })),
      ],
    };
    dispatch(ProjectsApi.postProjectMemberRoles(payload));
    setOpenRolesModal(false);
  };

  const ApplyRoles = () => {
    return (
      <div>
        <Button onClick={handleRoleApply} variant="contained" color="primary">
          Apply {type}s
        </Button>
      </div>
    );
  };

  const ModalContent = () => (
    <AttributeSelectionGroup
      checkBoxes={checkBoxes}
      handleChange={handleRoleSelect}
    />
  );

  return (
    <div>
      <AmeraModal
        open={openRolesModal}
        onClose={handleRoleModalClose}
        ModalContent={ModalContent}
        ModalAction={ApplyRoles}
        title={`Select ${type}s`}
        maxWidth="sm"
      />

      <Grid
        container
        className="contact-container"
        style={{ marginTop: '40px' }}
      >
        {contacts.map((contact) => {
          // *** remove it later when contact status property available

          return (
            <Grid
              item
              md={6}
              sm={12}
              lg={4}
              key={contact.member_id}
              className="contact-card"
            >
              <Card>
                <div className="custom-card_header">
                  <MaterialAvatar
                    classes="contact-avatar"
                    member_id={contact.contact_member_id}
                    src={contact.amera_avatar_url}
                    firstName={contact.first_name}
                    lastName={contact.last_name}
                  />
                  <Details
                    projectId={projectId}
                    id={contact.id}
                    contact={contact}
                  />
                </div>
                <CardContent className={styles.assignRoleBlock}>
                  <div className={styles.topRow}>
                    <h5>Project {type}s</h5>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddRole(contact.member_id)}
                    >
                      Add {type}
                    </Button>
                  </div>
                  <div className={styles.rolesGrid}>
                    <AttributePills
                      memberRoles={memberRoles}
                      member_id={contact.member_id}
                      type={type}
                      data={data}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default AssignAttribute;
