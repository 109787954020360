import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#707070',
    height: '54px',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // textAlign: 'center'
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span>© Copyright 2020 AMERA IoT Inc. - All Rights Reserved</span>
    </div>
  );
};

export default Footer;
