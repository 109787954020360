import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';

import { getStreamVideos } from '../../redux/actions/stream';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 32,
    '& span': {
      fontSize: 23,
    },
  },
  right: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  setting: {
    marginTop: 4,
    marginLeft: 8,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: '#868282',
    },
  },
}));

const Header = (props) => {
  const { title, id, handleSettingOpen, category, setCategroy } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const streamTypes = useSelector((state) => state.stream.streamTypes);

  const handleChange = (e) => {
    const value = e.target.value;
    setCategroy(value);
    dispatch(getStreamVideos({ [id]: value }, false));
  };

  return (
    <div className={classes.header}>
      <span>{title}</span>
      <div className={classes.right}>
        <FormControl variant="outlined" margin="dense">
          <Select
            native
            value={category}
            onChange={handleChange}
            inputProps={{
              name: 'video-type',
              id: 'video-type',
            }}
          >
            {streamTypes
              .sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              )
              .map((type) => {
                return (
                  <option aria-label={type.name} value={type.id} key={type.id}>
                    {type.name}
                  </option>
                );
              })}
          </Select>
        </FormControl>
        <Tooltip title="Settings" arrow>
          <SettingsIcon
            onClick={() => handleSettingOpen(id, title)}
            className={classes.setting}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
