import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { isEmail } from '../../utils/validator';
import ameralogoPng from '../../assets/img/logo-login.png';

import styles from '../../jss/views/passUpdateStyle';
import FormHeader from '../../components/AuthFormHeader';

import { sendForgotEmail } from '../../redux/actions/member';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);

const ForgotPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { member } = props;
  const isLoading = member.isLoading;
  const isSuccess = member.isSuccess;
  const sendEmailError = member.error;
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const emailRef = React.useRef();

  const handleChange = (evt) => {
    setEmail(evt.target.value);
    setEmailErr('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { email };

    if (!email) {
      setEmailErr('Please Provide Email Address!');
      return;
    }
    if (!isEmail(email)) {
      setEmailErr('Wrong Email Address!');
      return;
    }
    props.sendForgotEmail(JSON.stringify(formData));
  };

  useEffect(() => {
    emailRef && emailRef.current && emailRef.current.focus();
  }, [emailRef]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setTimeout(() => {
        member.isSuccess = false;
        history.push('/login');
      }, 3000);
    }
  });

  function renderSendEmailForm() {
    return (
      <div className={classes.authPage}>
        <FormHeader classes={classes} />
        <form
          className={classes.formSection}
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <div className={ameralogoPng.logoImg}>
            <img src={ameralogoPng} alt="logo" />
          </div>
          <div className={classes.emailInput}>
            <TextField
              name="email"
              error={!!emailErr}
              inputRef={emailRef}
              helperText={emailErr}
              label="Email Address"
              fullWidth
              color="primary"
              value={email}
              required
            />
          </div>
          <div className={classes.authWrapper}>
            <Button
              type="submit"
              variant="outlined"
              disabled={isLoading}
              color="primary"
              fullWidth
            >
              Send Confirmation
            </Button>
          </div>
          <div style={{ marginTop: 20 }}>
            {(!!sendEmailError || isSuccess) && (
              <Typography color={isSuccess ? 'primary' : 'error'}>
                {isSuccess
                  ? 'Confirmation Email Sent Successfully.  Redirecting you to the login page.'
                  : sendEmailError}
              </Typography>
            )}
          </div>
        </form>
      </div>
    );
  }
  return renderSendEmailForm();
};

const mapStateToProps = (state) => ({
  member: state.member,
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendForgotEmail: (formData) => {
      dispatch(sendForgotEmail(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
