import React from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 125,
    margin: theme.spacing(1),
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
  },
}));

const useSelectStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.8rem',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: theme.palette.text.secondary,
  },
}));

const ContactRoleSelect = (props) => {
  const { currentRoleId, onChange } = props;
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const roles = useSelector((s) => s.member.roles);
  return (
    <FormControl
      variant="standard"
      margin="dense"
      size="small"
      className={classes.root}
    >
      <Select
        disableUnderline={true}
        classes={selectClasses}
        id="contact-role-select"
        value={
          !!currentRoleId
            ? currentRoleId
            : roles.find((r) => r.name === 'Not Available').id
        }
        onChange={onChange}
        label="Type"
      >
        {roles.length > 0 &&
          roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ContactRoleSelect;
