import { makeStyles } from '@material-ui/core/styles';
import { green, textPurple } from '../../../../../jss/colors';

export const useMailTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    cursor: 'pointer',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    maxWidth: '100%',
  },
  tableRow: {
    width: '100%',
    minHeight: 65,
    cursor: 'pointer',
    '&:hover': {
      boxShadow:
        'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 90%), 0 1px 3px 1px rgb(60 64 67 / 45%)',
      backgroundColor: '#c2dbff !important',
    },
    backgroundColor: 'rgba(255,255,255,0.902)',
  },
  cellAvatar: {
    Width: 135,
    maxWidth: 150,
  },
  cellPreview: {
    maxHeight: 65,
    minWidth: '20wh',
  },
  cellSubject: {
    minWidth: 230,
    minHeight: 65,
  },
  cellDate: {
    minWidth: 140,
    minHeight: 65,
  },
  mailPreview: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: textPurple,
    fontWeight: 'medium',
    fontSize: 13,
    minHeight: 40,
    maxHeight: 40,
    paddingTop: 3,
  },
  sender: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: textPurple,
    fontSize: 15,
    display: 'block',
    verticalAlign: 'middle',
  },
  time: {
    color: '#888888',
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 16px 0 16px',
    width: '100%',
  },
  draftMessage: {
    display: ' block',
    fontStyle: ' normal',
    fontWeight: ' normal',
    fontSize: 12,
    lineHeight: 14,
    color: '#F59B4D',
  },

  subject: {
    width: 200,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: textPurple,
    // fontWeight: 'bold',
    fontSize: 15,
  },
  greenButton: {
    '& svg': {
      fill: green,
    },
  },
  textOverflowToTypography: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },
  customAvatarGroup: {
    '& .MuiAvatarGroup-avatar': {
      height: '3.75rem',
      width: '3rem',
      marginLeft: 0,
      borderRadius: 9,
      boxShadow: '1px 1px 9px 1px #c2c3c7',
      border: '3px solid white',
    },
  },
  customAvatarGroupDraft: {
    '& .MuiAvatarGroup-avatar': {
      height: '2.5rem',
      width: '2.5rem',
      marginLeft: '1px',
      borderRadius: '7px',
      boxShadow: '1px 1px 9px 1px #c2c3c7',
      border: '2px solid white',
    },
  },
  previewTime: {
    color: '#94c03d',
    fontSize: 13,
    fontFamily: 'Roboto',
  },
  fullScreenPreview: {
    boxShadow: ' 0 5px 5px #D0D6E3',
    borderRadius: 10,
    marginBottom: 16,
  },
  tableRowActive: {
    backgroundColor: 'rgba(242,245,245,0.8)',
    '& *': {
      fontWeight: 'bold',
    },
    '&:hover': {
      boxShadow:
        'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 90%), 0 1px 3px 1px rgb(60 64 67 / 45%)',
      backgroundColor: '#c2dbff !important',
    },
  },
  tableRowSelected: {
    backgroundColor: '#c2dbff !important',
    '&:hover': {
      backgroundColor: '#c2dbff !important',
    },
  },
}));
