import React from 'react';
import List from '@material-ui/core/List';

import Participant from './Participant';
import { useSelector } from 'react-redux';

function Participants() {
  const { selectedGroupID, groupConversations } = useSelector((s) => ({
    selectedGroupID: s.chat.selectedGroupID,
    groupConversations: s.chat.groupConversations,
  }));
  const selectedConversation =
    (selectedGroupID &&
      groupConversations &&
      groupConversations.filter((c) => c.chatID === selectedGroupID)?.[0]) ||
    null;

  const groupRosters =
    (selectedConversation && selectedConversation?.groupRosters) || [];

  let participantItems = null;
  participantItems = groupRosters.map((participant, index) => (
    <Participant key={index} participant={participant} />
  ));
  return <List className="contacts-list">{participantItems}</List>;
}

export default Participants;
