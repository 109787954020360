import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { includes, orderBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Chip } from '@material-ui/core';
import { formatPhoneNumber } from '../../utils/contact';
import DoneIcon from '@material-ui/icons/Done';
import VerificationModal from './VerificationModal';
import { setTwilioVerificationModalStatus } from '../../redux/actions/event';
import AmeraSelect from '../AmeraSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1),
    '& .MuiSelect-root': {
      minWidth: '150px',
    },
    '& .MuiInputBase-root': {
      marginRight: '16px',
    },
    alignItems: 'center',
    '& .MuiChip-root': {
      marginTop: '16px',
    },
  },
}));

const OutgoingCallerSetting = (props) => {
  const { contactInformation, countryList, twilioVerificationModalStatus } =
    useSelector((state) => ({
      contactInformation: state.member.memberInfo.contact_information,
      countryList: state.member.countryList,
      twilioVerificationModalStatus: state.event.twilioVerificationModalStatus,
    }));

  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = React.useState();

  const { outgoingCallerContact, setOutgoingCallerContact, error } = props;

  const classes = useStyles();

  const phones = React.useMemo(
    () =>
      orderBy(
        contactInformation.filter((cd) =>
          includes(['cell', 'TDD', 'landline'], cd.device_type)
        ),
        ['display_order']
      ),
    [contactInformation]
  );

  React.useEffect(() => {
    if (outgoingCallerContact) {
      const countryPhone = countryList.find(
        (cl) => cl.id === outgoingCallerContact.device_country
      ).phone;
      setPhoneNumber(`+${countryPhone}${outgoingCallerContact.device}`);
    }
  }, [outgoingCallerContact, countryList]);

  return (
    <div className={classes.root}>
      <AmeraSelect
        label="Phone"
        fullWidth={false}
        variant="standard"
        value={outgoingCallerContact ? outgoingCallerContact.id : ''}
        onChange={(event) => {
          event.persist();
          const p = phones.find((p) => p.id === event.target.value);
          setOutgoingCallerContact(p);
        }}
        error={error}
      >
        {phones &&
          phones?.length > 0 &&
          phones.map((phone) => (
            <MenuItem
              key={phone.id}
              selected={phone.id === outgoingCallerContact?.id}
              value={phone.id}
            >
              {formatPhoneNumber(
                countryList.find((cl) => cl.id === phone.device_country).phone,
                phone.device
              )}
            </MenuItem>
          ))}
      </AmeraSelect>

      {outgoingCallerContact &&
        outgoingCallerContact.outgoing_caller_verified && (
          <Chip
            size="small"
            label="Verified for outbound call"
            onClick={() => {}}
            color="primary"
            onDelete={() => {}}
            deleteIcon={<DoneIcon />}
          />
        )}
      {outgoingCallerContact &&
        !outgoingCallerContact.outgoing_caller_verified && (
          <Chip
            size="small"
            label="Verify number"
            color="primary"
            onClick={() => {
              dispatch(setTwilioVerificationModalStatus(true));
            }}
          />
        )}
      {twilioVerificationModalStatus && (
        <VerificationModal
          show={twilioVerificationModalStatus}
          close={() => {
            dispatch(setTwilioVerificationModalStatus(false));
          }}
          outgoingCallerContact={outgoingCallerContact}
          setOutgoingCallerContact={setOutgoingCallerContact}
          phoneNumber={phoneNumber}
        />
      )}
    </div>
  );
};

export default OutgoingCallerSetting;
