import { includes, isEqual, isArray, trimEnd } from 'lodash';
// import { addMinutes, differenceInMinutes, isBefore } from 'date-fns';

export const defaultCountryCode = {
  id: 840,
  alpha2: 'us',
};

export const geoApi = 'https://chromium-i18n.appspot.com/ssl-address/data';

export const MAP_VENDORS = {
  google: 'google',
  mapquest: 'mapquest',
  bing: 'bing',
};

export const shortAddress = (data) => {
  return `${data.locality}, ${data.adminArea1}`;
};

export const destructureAddress = (components) => {
  // console.debug(components);

  const countryAlpha2 =
    components.find((c) => includes(c.types, 'country'))?.short_name || 'US';

  if (countryAlpha2 === 'JP') {
    let streetAddress1,
      premise = [],
      premiseName,
      sublocality_3 = [],
      sublocality_4 = [],
      // streetAddress2 = [],
      sublocality = '',
      locality = '',
      adminArea1 = '',
      adminArea2 = '',
      postal,
      countryAlpha2,
      countryFull;

    components.forEach((c, idx) => {
      if (includes(c.types, 'sublocality_level_3')) {
        sublocality_3.push(c.long_name);
      }

      if (includes(c.types, 'sublocality_level_4')) {
        sublocality_4.push(c.long_name);
      }

      if (includes(c.types, 'premise')) {
        if (idx === 0) {
          premiseName = !!c ? `${c.long_name.trim()},` : '';
        } else {
          premise.push(c.long_name);
        }
      }

      if (includes(c.types, 'sublocality_level_2')) {
        streetAddress1 = c.long_name;
      }

      if (includes(c.types, 'sublocality_level_1')) {
        locality = c.long_name;
      }

      if (includes(c.types, 'administrative_area_level_1')) {
        adminArea1 = c.long_name;
      }

      if (includes(c.types, 'locality')) {
        adminArea2 = c.long_name;
      }

      if (includes(c.types, 'postal_code')) {
        postal = c.long_name;
      }

      if (includes(c.types, 'country')) {
        countryAlpha2 = c.short_name;
      }

      if (includes(c.types, 'country')) {
        countryFull = c.long_name;
      }
    });

    return {
      streetAddress1,
      streetAddress2: trimEnd(
        [premiseName, ...sublocality_3, ...sublocality_4, ...premise].join(' '),
        ','
      ),
      sublocality,
      locality,
      adminArea1,
      adminArea2,
      postal,
      countryAlpha2,
      countryFull,
    };
  } else {
    let streetAddress1 = [],
      streetAddress2 = [],
      sublocality = '',
      locality = '',
      adminArea1 = '',
      adminArea2 = '',
      postal,
      countryAlpha2,
      countryFull;

    components.forEach((c) => {
      if (includes(c.types, 'room')) {
        streetAddress2.push(c.long_name);
      }

      if (includes(c.types, 'subpremise')) {
        streetAddress2.push(c.long_name);
      }

      if (includes(c.types, 'premise')) {
        streetAddress2.push(c.long_name);
      }

      if (includes(c.types, 'street_number')) {
        streetAddress1.push(c.long_name);
      }

      if (includes(c.types, 'route') || includes(c.types, 'street_address')) {
        streetAddress1.push(c.long_name);
      }

      if (
        includes(c.types, 'neighborhood') ||
        includes(c.types, 'sublocality')
      ) {
        // Sublocality
        sublocality = c.long_name;
      }

      // City
      if (includes(c.types, 'locality')) {
        locality = c.long_name;
      }

      // County
      if (includes(c.types, 'administrative_area_level_2')) {
        adminArea2 = c.long_name;
      }

      // State
      if (includes(c.types, 'administrative_area_level_1')) {
        adminArea1 = c.long_name;
      }

      if (includes(c.types, 'postal_code')) {
        postal = c.long_name;
      }

      if (includes(c.types, 'country')) {
        countryAlpha2 = c.short_name;
      }

      if (includes(c.types, 'country')) {
        countryFull = c.long_name;
      }
    });
    return {
      streetAddress1: streetAddress1.join(' ').trim(),
      streetAddress2: trimEnd(streetAddress2.join(','), ','),
      sublocality,
      locality,
      adminArea1,
      adminArea2,
      postal,
      countryAlpha2,
      countryFull,
    };
  }
};

export function extractAddress(res) {
  let city = '';
  let state = '';
  let street = '';
  let postal = '';
  let province = '';
  let country = '';
  let cityShort = '';
  let stateShort = '';
  let provinceShort = '';
  let countryShort = '';

  if (res.results.length > 0) {
    const address = res.results[0];
    const arrComponents = address.address_components;
    const countryObj = arrComponents.find(
      (comp) => comp.types[0] === 'country'
    );
    country = countryObj.long_name.trim();
    countryShort = countryObj.short_name.trim();
    arrComponents.forEach((component) => {
      const types = component.types;
      if (
        includes(types, 'sublocality_level_1') ||
        includes(types, 'locality')
      ) {
        city = component.long_name.trim();
        cityShort = component.short_name.trim();
      }

      if (includes(types, 'sublocality_level_2') || includes(types, 'route')) {
        street = component.long_name.trim();
      }

      if (includes(types, 'administrative_area_level_1')) {
        if (includes(['US', 'MX', 'CA'], countryShort)) {
          state = component.long_name.trim();
          stateShort = component.short_name.trim();
          province = null;
          provinceShort = null;
        } else {
          state = null;
          stateShort = null;
          province = component.long_name.trim();
          provinceShort = component.short_name.trim();
        }
      }

      if (includes(types, 'postal_code')) {
        postal = component.long_name;
      }
    });

    return {
      city: {
        long: city,
        short: cityShort,
      },
      state: {
        long: state,
        short: stateShort,
      },
      province: {
        long: province,
        short: provinceShort,
      },
      country: {
        long: country,
        short: countryShort,
      },
      postal: postal,
      street: street,
      // address_2: takeWhile(
      //   address.results[0].formatted_address.split(',').map((el) => trim(el)),
      //   (el) => el !== city
      // ) /*.join(', ')*/,
      address_2: res.name,
      formatted_address: address.formatted_address,
      location: address.geometry.location,
    };
  }
}

export function getAddress(zip, key, callback) {
  if (!zip) {
    throw Error('Zip is required');
  }
  if (!key) {
    throw Error('Key is required');
  }
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&sensor=true&language=en&key=${key}`
  )
    .then(function (response) {
      if (response.status !== 200) {
        console.log(
          'Looks like there was a problem. Status Code: ' + response.status
        );
        return;
      }

      // Examine the text in the response
      response.json().then(function (data) {
        console.log('Gmaps', data);
        callback(null, extractAddress(data));
      });
    })
    .catch(function (err) {
      callback(err);
    });
}

export const locationObjectToString = (locationObject) => {
  const {
    address_1,
    address_2,
    city,
    country,
    postal,
    state,
    province,
    street,
  } = locationObject;
  return [
    address_2,
    address_1,
    street,
    city,
    province,
    state,
    postal,
    country,
  ].join(', ');
};

export const locationStringToGoogleQuery = (string) => {
  // https://developers.google.com/maps/documentation/urls/url-encoding#special-characters
  string = string || '';
  const prohibitedCharactes = [
    '!',
    '%',
    '(',
    ')',
    ';',
    ':',
    '@',
    '&',
    '=',
    '$',
    '/',
    '?',
    '#',
    '[',
    ']',
  ];

  const res = string
    .replaceAll(',', '+')
    .replaceAll(' ', '+')
    .replaceAll(/./gm, (m) => (includes(prohibitedCharactes, m) ? '' : m));

  // console.debug('in', string, 'out', res);
  return res;
};

export function getBoundsAtLatLngWithZoom(map, center, zoom) {
  var p = map.getProjection();
  if (!p) {
    return null;
  }
  var el = map;
  var zf = Math.pow(2, zoom) * 2;
  var dw = (el.clientWidth | 0) / zf;
  var dh = (el.clientHeight | 0) / zf;
  var cpx = p.fromLatLngToPoint(center);
  return new window.google.maps.LatLngBounds(
    p.fromPointToLatLng(new window.google.maps.Point(cpx.x - dw, cpx.y + dh)),
    p.fromPointToLatLng(new window.google.maps.Point(cpx.x + dw, cpx.y - dh))
  );
}

// Filters places to have
// business_status: "OPERATIONAL"
// open for the event duration

// FIXME: Unfortunately, won't work for most places since there's not utc-offset for most of them
// https://developers.google.com/maps/documentation/javascript/reference/3.42/places-service#PlaceOpeningHours.isOpen

// Will check if the location is open for every X mins from event start time to  event end time
// start and end are dates
// const MINUTES_RATE_TO_CHECK = 30;
// const isOpenDuringEvent = (place, start, end) => {
//   if (!!place) {
//     let datesToCheck = [start, end];
//     if (differenceInMinutes(end, start) >= MINUTES_RATE_TO_CHECK) {
//       let nextDate = addMinutes(start, MINUTES_RATE_TO_CHECK);
//       while (isBefore(nextDate, end)) {
//         datesToCheck.push(nextDate);
//         nextDate = addMinutes(nextDate, MINUTES_RATE_TO_CHECK);
//       }
//     }

//     const isOpen = datesToCheck.map((date) => place.opening_hours.isOpen(date));
//     // .some((isOpen) => isOpen === false);
//     console.log(
//       place.vicinity,
//       'Dates to check',
//       datesToCheck,
//       'isOpen',
//       isOpen
//     );
//   }
// };

export const filterOperationalOpenPlaces = (places) => {
  return places.filter(
    (place) => place.business_status === 'OPERATIONAL'
    // isOpenDuringEvent(place, start, end)
  );
};

export const isSamePlaces = (prev, curr) => {
  if (isArray(prev) && isArray(curr) && prev.length === curr.length) {
    return isEqual(
      prev.map((p) => p.place_id),
      curr.map((p) => p.place_id)
    );
  } else {
    return false;
  }
};

export const isPlaceInList = (places, place) =>
  !!place &&
  includes(
    places.map((p) => p.place_id),
    place.place_id
  );

export const placeToString = (place) =>
  `${place.name}, ${
    !!place.formatted_address ? place.formatted_address : place.vicinity
  }`;

export const makeDirectionsUrl = (destination, origin = null) => {
  let params = [];
  params.push(
    !!origin
      ? `&origin=${
          !!origin.name
            ? origin.name
            : locationStringToGoogleQuery(origin.formatted_address)
        }&origin_place_id=${origin.place_id}`
      : ''
  );

  params.push(
    !!destination
      ? `&destination=${
          !!destination.name
            ? destination.name
            : locationStringToGoogleQuery(destination.formatted_address)
        }&destination_place_id=${destination.place_id}`
      : ''
  );

  const url = `https://www.google.com/maps/dir/?api=1${params.join(
    ''
  )}&travelmode=driving&dir_action=navigate`;
  return url;
};

export const maneuverClass = (maneuver) => {
  if (!!!maneuver || maneuver === 'keep-right' || maneuver === 'keep-left') {
    return 'no-image';
  } else {
    return maneuver;
  }
};
