import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MaterialAvatar from '../MaterialAvatar';
import { addNewComment } from '../../redux/actions/forum';
import CommentEditor from '../CommentEditor';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  editorWrapper: {
    flex: 1,
    marginLeft: theme.spacing(2),
    display: 'flex',
  },
  avatar: {
    width: '40px',
    height: '40px',
  },
  left: {
    '& span': {
      padding: theme.spacing(1),
      '& svg': {
        fill: '#8DA3C5 !important',
      },
    },
  },
  textarea: {
    border: 'none',
    flex: 1,
    fontSize: '16px',
    resize: 'none',
  },
  right: {
    '& .MuiButtonBase-root': {
      width: '56px',
      height: '44px',
      borderRadius: '14px',
    },
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '44px',
    '& .MuiButtonBase-root': {
      minWidth: '55px',
      marginLeft: theme.spacing(2),
    },
  },
}));

const TopicPostEditor = (props) => {
  const { type, topic_id, post_id, handleReply } = props;

  const dispatch = useDispatch();
  const member = useSelector((state) => state.member.member);
  const classes = useStyles();

  const [content, setContent] = React.useState('');

  const [attachments, setAttachments] = React.useState([]);

  const handleAttachments = (event) => {
    setAttachments(Object.values(event.target.files));
  };

  const deleteAttachment = (index) => {
    setAttachments((prev) =>
      prev.filter((item, itemIndex) => itemIndex !== index)
    );
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('topic_id', topic_id);
    if (post_id) formData.append('post_id', post_id);
    formData.append('content', content);
    formData.append('attachments', attachments.length);
    attachments.forEach((attachment, index) => {
      formData.append(`file_${index}`, attachment);
    });
    setContent('');
    setAttachments([]);

    dispatch(addNewComment(formData));
    if (handleReply) {
      handleReply(null);
    }
  };

  return (
    <div className={classes.root}>
      <MaterialAvatar
        member_id={member.member_id}
        firstName={member.first_name}
        lastName={member.last_name}
        src={member.amera_avatar_url}
        classes={classes.avatar}
        variant="circular"
      />
      <div className={classes.editorWrapper}>
        <CommentEditor
          topic_id={topic_id}
          content={content}
          setContent={setContent}
          attachments={attachments}
          handleAttachments={handleAttachments}
          deleteAttachment={deleteAttachment}
          handleSubmit={handleSubmit}
          allowLink={type === 'reply' ? false : true}
          allowAt={type === 'reply' ? false : true}
          allowEmoji={true}
          placeholder={
            type === 'post' ? 'Your post...' : 'Type comment here...'
          }
        />
      </div>
      {/*<div className={classes.editorWrapper}>
        { <div className={classes.left}>
          <span>
            <LinkIcon />
          </span>
          <span>
            <AttachmentIcon />
          </span>
          <span>
            <AtIcon />
          </span>
        </div>
        <textarea
          className={classes.textarea}
          placeholder={`Type here to ${type}`}
          onChange={handleContent}
          value={content}
        />
        <div className={classes.right}>
          <div className={classes.flexAlignCenter}>
            <EmojiIcon />
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              <SendIcon />
            </Button>
          </div>
        </div> 
  </div>*/}
    </div>
  );
};

TopicPostEditor.defaultProps = {
  type: 'comment',
};

export default TopicPostEditor;
