import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Filter from './NewsFeedFilter';
import ContactFeedCard from './ContactFeedCard';
import ContactFeedCommentEditor from './ContactFeedCommentEditor';
import { useDispatch, useSelector } from 'react-redux';
import { getContactNewsfeeds } from '../../redux/actions/drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '4px',
      borderRadius: '8px',
      backgroundColor: '#d3d3d380',
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
  description: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#000000',
  },
  feeds: {
    padding: '15px 10px 9px 10px',
  },
  feedWrapper: {
    padding: '10px 10px 0px 10px',
  },
  postWrapper: {
    padding: '0px 10px 10px 10px',
  },
}));

const NewsFeed = (props) => {
  const classes = useStyles();

  const [selectedFeeds, setSelectedFeeds] = React.useState([]);
  const [replyId, setReplyId] = React.useState();
  const editorRef = React.useRef();

  const dispatch = useDispatch();
  const topics = useSelector((state) =>
    (state.drawer.contentProps?.topics || []).filter((el) => !!el)
  );

  React.useEffect(() => {
    dispatch(getContactNewsfeeds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (editorRef.current) scrollToRef(editorRef);
  }, [editorRef]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  const handleSelectFeed = (feed) => {
    setSelectedFeeds((prev) => {
      if (prev.includes(feed))
        return prev.filter((prevFeed) => prevFeed !== feed);
      else return [...prev, feed];
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <h3 className={classes.title}>NewsFeeds</h3>
          <h3 className={classes.description}>
            Here are highlights of the recent feeds which are in discussion
          </h3>
        </div>
        <Filter
          selectedFeeds={selectedFeeds}
          handleSelectFeed={handleSelectFeed}
        />
      </div>
      <div className={classes.feeds}>
        {topics &&
          topics.length > 0 &&
          topics.map((topic) => (
            <div key={topic.topic_id}>
              {true ? (
                <>
                  <div className={classes.feedWrapper}>
                    <ContactFeedCard feed={topic} setReplyId={setReplyId} />
                  </div>
                  {replyId === topic.topic_id && (
                    <div ref={editorRef}>
                      {' '}
                      <ContactFeedCommentEditor
                        type="reply"
                        topic_id={topic.topic_id}
                        handleReply={setReplyId}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div />
              )}
            </div>
          ))}
      </div>
      <div className={classes.postWrapper}>
        <ContactFeedCommentEditor type="post" />
      </div>
    </div>
  );
};

export default NewsFeed;
