import clsx from 'clsx';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import React, { PureComponent } from 'react';
import { startOfWeek, endOfWeek, addDays } from 'date-fns';
import isWithinInterval from 'date-fns/isWithinInterval';
// import { DatePicker } from "@material-ui/pickers";
import { createStyles } from '@material-ui/styles';
// this guy required only on the docs site to work with dynamic date library
import { IconButton, withStyles } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const makeJSDateObject = (date) => {
  return new Date(date);
};

const customStartOfWeek = (date) => {
  return addDays(startOfWeek(date), 1);
};

const customEndOfWeek = (date) => {
  return addDays(endOfWeek(date), 1);
};

class CustomElements extends PureComponent {
  handleWeekChange = (date) => {
    this.props.setState(customStartOfWeek(makeJSDateObject(date)));
  };

  formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = makeJSDateObject(date);

    const start = customStartOfWeek(dateClone);
    const end = customEndOfWeek(dateClone);

    return dateClone && isValid(dateClone)
      ? `${format(start, 'MMM d')} - ${format(end, 'MMM d yyyy')}`
      : invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props;
    let dateClone = makeJSDateObject(date);
    let selectedDateClone = makeJSDateObject(selectedDate);

    const start = customStartOfWeek(selectedDateClone);
    const end = customEndOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { selectedDate } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputVariant="outlined"
          inputProps={{
            readOnly: true,
          }}
          value={selectedDate}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          onChange={this.handleWeekChange}
          renderDay={this.renderWrappedWeekDay}
          labelFunc={this.formatWeekSelectLabel}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = createStyles((theme) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}));

export default withStyles(styles)(CustomElements);
