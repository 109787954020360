import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#A4B8D1"
        d="M4 3h5.104l2.972 7.604L15.086 3h4.97l-7.483 18H7.572l2.048-4.77L4 3z"
      ></path>
    </svg>
  );
}
export default Icon;
