import React, { useEffect, useRef, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TimerIcon from '@material-ui/icons/Timer';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { Fade, Tooltip, IconButton } from '@material-ui/core';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// import { format } from 'date-fns';

import Card from '../Card';
import CardButtons from '../CardButtons';

import { displayableFullName } from '../../../utils/contact';
import { apiUrl } from '../../../config/api';
import { useCardStyles } from './CardStyle';
import { defaultDateTimeZone } from '../../../utils/calendar';

const MediaMailCard = ({
  type,
  data,
  setVideoEmailId,
  setShowVideoEmailModal,
  handleReadVideoMail,
  messages,
  formatMessage,
}) => {
  const classes = useCardStyles();

  const playerRef = useRef(null);
  const [play, setPlay] = useState(false);
  const [buttonShow, setButtonShow] = useState(true);

  let subject;
  let title = formatMessage(messages.mediaMailTitle);
  let name = !!data.first_name ? `${displayableFullName(data)}` : null;

  subject = data.subject.replace(/<[^>]+>/g, '');
  if (data.mail_type === 'group') {
    title = `New Group ${
      data.media_type === 'audio' ? 'Audio' : 'Video'
    } Mail (${data.group_name})`;
  } else if (data.mail_type === 'reply') {
    title = `New Reply ${data.media_type === 'audio' ? 'Audio' : 'Video'} Mail`;
  } else {
    title = `New ${data.media_type === 'audio' ? 'Audio' : 'Video'} Mail`;
  }

  const handleOpen = () => {
    let identifier = data.id;
    setShowVideoEmailModal(true);
    setVideoEmailId(identifier);
  };

  const action = <></>;

  const avatar = {
    first_name: data.first_name,
    last_name: data.last_name,
    url: data.amera_avatar_url,
  };

  useEffect(() => {
    if (playerRef && playerRef.current) {
      playerRef.current.onended = () => {
        setPlay(false);
        setButtonShow(true);
      };
    }
  }, [playerRef]);

  const buttons = (
    <CardButtons
      type={type}
      okText={formatMessage(messages.watch)}
      okHandle={handleOpen}
    />
  );

  const handleVideoPlayer = () => {
    if (playerRef.current && playerRef.current.src) {
      if (!play) {
        playerRef.current.play();
        setPlay(true);
        setButtonShow(false);
        handleReadVideoMail(data);
      } else {
        playerRef.current.pause();
        setPlay(false);
      }
      // setPlay((ps) => !ps);
    }
  };

  const toggleFullScreen = () => {
    if (playerRef.current) {
      let player = playerRef.current;
      if (player.requestFullscreen) {
        player.requestFullscreen();
      } else if (player.msRequestFullscreen) {
        player.msRequestFullscreen();
      } else if (player.mozRequestFullScreen) {
        player.mozRequestFullScreen();
      } else if (player.webkitRequestFullscreen) {
        player.webkitRequestFullscreen();
      }
    }
  };

  const content = (
    <List className={classes.content}>
      <ListItem className={classes.playerContent}>
        {data.media_type === 'video' && (
          <div
            className={classes.player}
            onMouseOver={() => play && setButtonShow(true)}
            onMouseOut={() => play && setButtonShow(false)}
          >
            <div className={classes.videoWrapper}>
              <video
                ref={playerRef}
                playsInline
                src={`${apiUrl}${data.mail_url}`} //"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                className={classes.video}
                me="true"
              />
            </div>
            <Fade in={buttonShow} timeout={{ enter: 500, exit: 1000 }}>
              <div>
                <div className={classes.playBtnWrapper}>
                  <IconButton onClick={handleVideoPlayer}>
                    {play ? <PauseIcon /> : <PlayArrowIcon />}
                  </IconButton>
                </div>
                {play && (
                  <div className={classes.fullScreenBtnWrapper}>
                    <Tooltip title="Full Screen" placement="top" arrow>
                      <IconButton onClick={() => toggleFullScreen()}>
                        <FullscreenIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Fade>
          </div>
        )}
        {data.media_type === 'audio' && (
          <div className={classes.audioWrapper}>
            <AudioPlayer
              ref={playerRef}
              playsInline
              src={`${apiUrl}${data.mail_url}`} //"https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_5MG.mp3"
              className={classes.audio}
              me="true"
            />
          </div>
        )}
      </ListItem>
      <ListItem className={classes.eventDateAndTime}>
        <List>
          <ListItem className={classes.eventDate}>
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                (data.create_date &&
                  defaultDateTimeZone(data.create_date, 'date')) ||
                ''
              }
            />
          </ListItem>
          <ListItem className={classes.eventTime}>
            <ListItemIcon>
              <TimerIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                (data.create_date &&
                  defaultDateTimeZone(data.create_date, 'time')) ||
                ''
              }
            />
          </ListItem>
        </List>
      </ListItem>
    </List>
  );

  return (
    <>
      <Card
        title={title}
        action={action}
        avatar={avatar}
        subTitle={name}
        subHeader={subject}
        content={content}
        buttons={buttons}
      />
    </>
  );
};

export default MediaMailCard;
