import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { ApiEmailService } from '../../../../config/apiService';
import { MailBottomButtonGroup } from './EmailButtonGroups/MailBottomButtonGroup';

import { Folders } from './Folders';
import { MailList } from './MailList';
import { MailTable } from './MailTable';
import { EmailControls } from './EmailControls';
import { EnhancedTableToolbar } from './MailTable/EnhancedTableToolbar';
import { MailDetailsPrintWrapper } from './EmailDetails';
import { useEmailPageStyles } from './EmailPageStyles';

export const EmailPageWrapper = ({
  view,
  children,
  section,
  emails,
  totalCount,
  selectEmail,
  selectedEmail,
  selectedEmails,
  sortControls,
  filterControls,
  topButtonsHandlers,
  bottomButtonsHandlers,
  mailPreviewHandlers,
  lazyLoading,
  showFolders,
  getFolders,
  folders,
  handleEditFolder,
  handleRemoveFolder,
  selectedFolder,
  setSelectedFolder,
  selectedXref,
}) => {
  const classes = useEmailPageStyles();

  const { member } = useSelector((state) => state.member);
  const isOwnEmail =
    selectedEmail && selectedEmail.sender_mail === member.email;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const createFolder = async (folderName) => {
    const res = await ApiEmailService.postEmail('postFolder', {
      folder_name: folderName,
    });
    if (res.success) getFolders();
  };

  return (
    <>
      {view === 'table' && (
        <Grid container className={classes.root}>
          {EmailControls({
            section,
            selectedEmail,
            sortControls,
            filterControls,
            topButtonsHandlers,
            handlePrint,
            showFolders,
            createFolder,
            selectedFolder,
          })}
          <Grid container className={classes.mailSection}>
            <Grid
              item
              className={classes.mailListSection}
              xs={section === 'Draft' ? 12 : showFolders ? 3 : 4}
            >
              <Grid container className={classes.scroll}>
                <MailList
                  list={emails}
                  member={member}
                  section={section}
                  hasNextPage={true}
                  selectedXref={selectedXref}
                  fetchData={lazyLoading}
                  selectEmail={selectEmail}
                  selectedEmail={selectedEmail}
                  handlers={mailPreviewHandlers}
                />
              </Grid>
            </Grid>
            {section !== 'Draft' && (
              <Grid
                item
                className={classes.mailDetailSection}
                xs={showFolders ? 6 : 8}
              >
                {selectedEmail && (
                  <>
                    <MailDetailsPrintWrapper
                      member={member}
                      ref={componentRef}
                      email={selectedEmail}
                      isOwnEmail={section === 'Sent' ? true : isOwnEmail}
                    />
                    <Grid className={classes.groupWrapper}>
                      {section === 'Starred' &&
                      isOwnEmail &&
                      !selectedEmail ? null : (
                        <MailBottomButtonGroup
                          isOwnEmail={isOwnEmail}
                          handlers={bottomButtonsHandlers}
                          email={selectedEmail}
                          member={member}
                        />
                      )}
                    </Grid>
                    {children}
                  </>
                )}
              </Grid>
            )}
            {showFolders ? (
              <Grid item xs={3} className={classes.foldersLayout}>
                <Folders
                  folders={folders}
                  getFolders={getFolders}
                  handleEditFolder={handleEditFolder}
                  handleRemoveFolder={handleRemoveFolder}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      )}

      {view === 'list' && (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <EnhancedTableToolbar
              title={section}
              selectEmail={selectEmail}
              selectedEmail={selectedEmail}
              selectedEmails={selectedEmails}
              showFolders={showFolders}
              createFolder={createFolder}
              selectedFolder={selectedFolder}
              section={section}
              sortControls={sortControls}
              filterControls={filterControls}
              topButtonsHandlers={topButtonsHandlers}
              handlePrint={handlePrint}
              selectedXref={selectedXref}
            />
          </Grid>

          <Grid
            item
            xs={showFolders ? 9 : 12}
            className={classes.tableMailSection}
          >
            <Grid container className={classes.tableMailListContainer}>
              <Grid item xs={12} className={classes.scroll}>
                <MailTable
                  list={emails}
                  totalCount={totalCount}
                  member={member}
                  section={section}
                  hasNextPage={true}
                  fetchData={lazyLoading}
                  selectEmail={selectEmail}
                  selectedEmail={selectedEmail}
                  selectedEmails={selectedEmails}
                  selectedXref={selectedXref}
                  handlers={mailPreviewHandlers}
                />
              </Grid>
            </Grid>

            <div id="selected-mail-details">
              {selectedEmail && (
                <Grid item xs={12} className={classes.tableMailDetailContainer}>
                  <MailDetailsPrintWrapper
                    ref={componentRef}
                    email={selectedEmail}
                    member={member}
                    isOwnEmail={section === 'Sent' ? true : isOwnEmail}
                  />
                  <Grid className={classes.groupWrapper}>
                    {section === 'Starred' &&
                    isOwnEmail &&
                    !selectedEmail ? null : (
                      <MailBottomButtonGroup
                        isOwnEmail={isOwnEmail}
                        handlers={bottomButtonsHandlers}
                        email={selectedEmail}
                        member={member}
                      />
                    )}
                  </Grid>
                  {children}
                </Grid>
              )}
            </div>
          </Grid>

          {showFolders ? (
            <Grid item xs={3}>
              <Grid item xs={12} className={classes.foldersLayout}>
                <Folders
                  folders={folders}
                  getFolders={getFolders}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  handleEditFolder={handleEditFolder}
                  handleRemoveFolder={handleRemoveFolder}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      )}
    </>
  );
};
