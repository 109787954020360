import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import AmeraAccordionControllable from '../../AmeraAccordion/AmeraAccordionControllable';

import Button from '../../Dashboard/CardButton';
import LocationItem from '../MyProfileEdit/LocationItem';
import ViewCard from './ViewCard';
import Filter from './Filter';
import ConfirmDialog from '../../../components/ConfirmDialog';
import drawerProfileStyles from '../../../jss/views/drawerProfileStyles';
import { MY_LOCATION_SECTIONS, ADDRESS_TYPES } from '../../../utils/calendar';
import { uniq } from 'lodash';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';

const COLLAPSE_MESSAGE =
  'Collapsing this section will cancel all changes. Are you sure you want to proceed?';
// const DISCARD_MESSAGE = 'Are you sure you want to cancel all changes?';

const Section = (props) => {
  const {
    locations,
    label,
    isLoading,
    onSetLocation,
    isHasFilter,
    onAddClick,
    onSaveClick,
    onDiscardInput,
    errors,
  } = props;

  const [isExpanded, setExpanded] = useState(true);
  const [filter, setFilter] = useState('All');

  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    title: 'Confirm Action',
    content: COLLAPSE_MESSAGE,
    cancelText: 'Go Back',
    okText: 'Approve',
    onOk: () => null,
  });

  const handleToggle = () =>
    // Disabling this due to Chris request ¯\_(ツ)_/¯

    // isEditing
    //   ? setDialog((ps) => ({
    //       ...ps,
    //       open: true,
    //       content: COLLAPSE_MESSAGE,
    //       onOk: () => {
    //         closeDialog();
    //         stopEditing();
    //       },
    //       onCancel: closeDialog,
    //     }))
    //   : setExpanded((ps) => !ps);
    setExpanded((ps) => !ps);

  const edit = useCallback(
    (id, value, field) => {
      onSetLocation({
        type: PROFILE_EDIT_ACTIONS.locationEdit,
        payload: {
          location_id: id,
          field,
          value: value,
        },
      });
    },
    [onSetLocation]
  );

  const handleEditClick = (id) => {
    setExpanded(true);
    edit(id, true, 'edit');
  };
  const handleAddClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // We pass this up to create a dummy record in the right section bucket
    const type = () => {
      switch (label) {
        case MY_LOCATION_SECTIONS.home:
          return ADDRESS_TYPES.home;
        case MY_LOCATION_SECTIONS.work:
          return ADDRESS_TYPES.work;
        case MY_LOCATION_SECTIONS.events:
          return ADDRESS_TYPES.barsRestraunts;
        case MY_LOCATION_SECTIONS.other:
          return ADDRESS_TYPES.other;
        default:
          return ADDRESS_TYPES.other;
      }
    };

    onAddClick(type());
  };
  const handleApplyClick = (e, id) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    filteredLocations
      .filter((location) => location.id === id)
      .map((location) => {
        return location.place_id && edit(location.id, false, 'edit');
      });
    editableLocations
      .filter((location) => location.id === id)
      .map((location) => {
        return location.place_id && edit(location.id, false, 'edit');
      });
    onSaveClick(id);
  };
  const handleCancelClick = (e, id) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    filteredLocations
      .filter((location) => location.id === id)
      .map((location) => {
        return location.place_id && edit(location.id, false, 'edit');
      });
    editableLocations
      .filter((location) => location.id === id)
      .map((location) => {
        return location.place_id && edit(location.id, false, 'edit');
      });
    onDiscardInput(id);
    // Disabling this due to Chris request ¯\_(ツ)_/¯

    // setDialog((ps) => ({
    //   ...ps,
    //   open: true,
    //   content: DISCARD_MESSAGE,
    //   cancelText: 'Go Back',
    //   okText: 'Approve',
    //   onOk: () => {
    //     closeDialog();
    //     stopEditing();
    //     onDiscardInput();
    //   },
    //   onCancel: closeDialog,
    // }));
  };

  const closeDialog = () => setDialog((ps) => ({ ...ps, open: false }));

  const classes = drawerProfileStyles();

  const existingTypes =
    locations?.length > 0 ? uniq(locations.map((l) => l.location_type)) : [];

  const filteredLocations =
    locations?.length > 0
      ? locations.filter((l) => {
          if (filter === 'All') {
            return true;
          } else if (l.location_type === filter) {
            return true;
          } else {
            return false;
          }
        })
      : [];

  const editableLocations =
    filteredLocations?.length > 0 &&
    filteredLocations.filter((l) => l.editable_by_member)?.length > 0
      ? filteredLocations.filter((l) => l.editable_by_member)
      : [];

  const handleFiler = (type) => setFilter(type);

  return (
    <>
      <ConfirmDialog {...dialog} onCancel={closeDialog} />
      {
        <AmeraAccordionControllable
          label={label}
          expanded={isExpanded}
          onChange={handleToggle}
          button={
            <div>
              <Button text="Add" variant="okFilled" onClick={handleAddClick} />
            </div>
          }
        >
          <div className={classes.cardArray}>
            {filteredLocations?.length > 0 && (
              <>
                {isHasFilter && (
                  <Filter
                    types={existingTypes}
                    selected={filter}
                    onChange={handleFiler}
                  />
                )}
              </>
            )}
            {editableLocations?.length > 0 ? (
              editableLocations.map((location) =>
                location.edit ? (
                  <LocationItem
                    key={location.id}
                    isLoading={isLoading}
                    onCancel={handleCancelClick}
                    onSave={handleApplyClick}
                    data={location}
                    setLocations={onSetLocation}
                    error={errors[location.id]}
                  />
                ) : (
                  <ViewCard
                    key={location.id}
                    data={location}
                    onEdit={handleEditClick}
                    setLocations={onSetLocation}
                  />
                )
              )
            ) : (
              <div className={classes.emptyFallback}>No locations</div>
            )}
          </div>
        </AmeraAccordionControllable>
      }
    </>
  );
};

const mapStatetoProps = (state, ownProps) => {
  // const isLoading = () => {
  //   switch (ownProps.label) {
  //     case MY_LOCATION_SECTIONS.home:
  //       return state.member.isLoadingHomeLocations;
  //     case MY_LOCATION_SECTIONS.work:
  //       return state.member.isLoadingWorkLocations;
  //     case MY_LOCATION_SECTIONS.events:
  //       return state.member.isLoadingEventsLocations;
  //     case MY_LOCATION_SECTIONS.other:
  //       return state.member.isLoadingOtherLocations;
  //     default:
  //       return false;
  //   }
  // };

  // return { isLoading: isLoading() };

  return { isLoading: state.member.isLoadingLocations };
};

export default connect(mapStatetoProps)(Section);
