import {
  SET_SNACKBAR,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
  ADD_STATIC_NOTIFICATION,
  REMOVE_STATIC_NOTIFICATION
} from '../actionTypes/snackbar';

export const setSnackbarData = (options) => {
  return function (dispatch) {
    dispatch({
      type: SET_SNACKBAR,
      payload: options,
    });
  };
};

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  payload: id,
});

export const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});

export const addStaticNotification = (notification) => ({
  type: ADD_STATIC_NOTIFICATION,
  payload: notification
})

export const removeStaticNotification = () => ({
  type: REMOVE_STATIC_NOTIFICATION
})