import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Popover, Checkbox } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ControlPointIcon from '@material-ui/icons/ControlPoint';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    marginRight: '18px',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      height: '38px',
      background: 'white',
      borderRadius: '7px',
      textTransform: 'none',
      color: '#999999',
    },
  },
  filterLabel: {
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    whiteSpace: 'nowrap',
    color: '#999999',
  },
  panel: {
    padding: '10px 16px',
    width: '236px',
  },
  feed: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addNewFeed: {
    cursor: 'pointer',
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  addNewFeedLabel: {
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: theme.spacing(2),
  },
}));

const newsfeeds = ['Contacts', 'Github', 'Coinbase'];

const Filter = (props) => {
  const { selectedFeeds, handleSelectFeed } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const openFilter = Boolean(anchorEl);

  return (
    <div className={classes.filter}>
      <Button onClick={handleFilterClick}>
        <span className={classes.filterLabel}>Filter newsfeed</span>
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={openFilter}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.panel}>
          {newsfeeds.map((feed) => (
            <div
              className={classes.feed}
              key={feed}
              onClick={() => {
                handleSelectFeed(feed);
              }}
            >
              <span>from {feed}</span>
              <Checkbox
                color="primary"
                checked={selectedFeeds.includes(feed)}
              />
            </div>
          ))}
          <div className={classes.addNewFeed}>
            <ControlPointIcon color="primary" />
            <span className={classes.addNewFeedLabel}>Add a Newsfeed</span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default Filter;
