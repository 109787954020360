import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, useParams, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import styles from '../ProjectManagement.module.scss';
import { Button, TextField } from '@material-ui/core';
import CustomComposeEditor from '../../Email/SharedComponents/Compose/CustomComposeEditor';
import DateInput from '../../../components/Modal/EventModals/DateInput';
import Breadcrumbs from '../../../components/AmeraBreadCrumbs';
import CompanySelect from '../CompanySelect';
import ProjectTypeSelect from '../ProjectTypeSelect';
import ProjectOwnerSelect from '../ProjectOwnerSelect';
import { SnackBarMessage } from '../../../components/Modal/CustomSnackBar';

// components

import EditModeButtons from './EditModeButtons';

// react redux
import { useSelector, useDispatch } from 'react-redux';

// actions
import {
  projectCreationStart,
  updateProjectDefinition,
  submitProjectDefinition,
} from '../../../redux/actions/projects';
import { setSnackbarData } from '../../../redux/actions/snackbar';

// api calls
import { ProjectsApi } from '../../../redux/actions/project';

// util
import { projecCreationDateFormat } from '../../../utils/dateFormat';
import { routeUtils } from '../../../utils/routeUtils';
import { validatePostProjectCreateForm } from '../../../utils/validator/projectValidator/postProjectCreateForm';

const CreateProject = () => {
  // state
  const [isSubmitNext, setSubmitNext] = useState(false);
  const routerLocation = useLocation();
  const history = useHistory();

  // use isn EditModeButtons.js component
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);

  // selectors
  let projectId = useParams().projectId || null; // Edit mode
  let storeProjectId = useSelector((state) => {
    if (projectId) {
      return projectId;
    }
    return state.project.projectCreation.currentProjectId;
  });

  const queryString = routeUtils.useQuery(routerLocation.search);
  const pageMode = queryString.get('mode');

  const loggedInUserInfo = useSelector((state) => {
    return state.member.member;
  });

  const dispatch = useDispatch();

  const companyList = useSelector((state) => {
    return state.company.companyList;
  });
  const companyId =
    (companyList &&
      (
        companyList.filter((cl) => cl.name === loggedInUserInfo.company)[0] ||
        {}
      ).id) ||
    0;

  const projectDefinition = useSelector((state) => {
    if (projectId) {
      let project = state.project.projects.filter(
        (o) => o.project_id === +projectId
      )[0];
      return {
        ourRole: [1],
        company: {
          id: project.company.company_id,
          name: project.company.name,
        },
        projectOwner: {
          id: loggedInUserInfo.member_id,
          name: `${loggedInUserInfo.first_name}${
            loggedInUserInfo.middle_name
              ? ' ' + loggedInUserInfo.middle_name
              : ''
          } ${loggedInUserInfo.last_name}`,
        },
        projectName: project.project_title,
        projectType: project.project_type,
        projectDetails: project.project_description,
        projectRoles: [],
        projectStartDate: project.start_date,
        projectEstimatedDays: project.estimated_days.split(' ')[0],
      };
    }

    return {
      ourRole: [1],
      company: {
        id: companyId,
        name: loggedInUserInfo.company,
      },
      projectOwner: {
        id: loggedInUserInfo.member_id,
        name: `${loggedInUserInfo.first_name}${
          loggedInUserInfo.middle_name ? ' ' + loggedInUserInfo.middle_name : ''
        } ${loggedInUserInfo.last_name}`,
      },
      projectName: [],
      projectType: '',
      projectDetails: '',
      projectRoles: [],
      projectStartDate: Date.now(),
      projectEstimatedDays: '',
    };
  });

  useEffect(() => {
    if (!projectDefinition && !isSubmitNext) {
      dispatch(projectCreationStart());
      dispatch(ProjectsApi.getProjects());
    }
  }, [dispatch, projectDefinition, isSubmitNext]);
  //state
  const [formData, setFormData] = useState(projectDefinition);

  useEffect(() => {
    isError && setErrors(validatePostProjectCreateForm(formData));

    if (!projectDefinition && !isSubmitNext) {
      dispatch(projectCreationStart());
    }
  }, [dispatch, projectDefinition, isSubmitNext, isError, formData]);

  useEffect(() => {
    return () => {
      dispatch(projectCreationStart());
    };
  }, [dispatch]);

  const handleCompanySelect = (e) => {
    setIsSaveDisabled(false);
    const conpanyId = e.target.value;
    const company = {
      id: conpanyId,
      name: companyList.filter((cl) => cl.id === conpanyId)[0].name,
    };

    setFormData((ps) => {
      const formData = { ...ps, company };
      dispatch(updateProjectDefinition(formData));
      return formData;
    });
  };

  const handleProjectTypeSelect = (e) => {
    setIsSaveDisabled(false);
    setFormData((ps) => {
      const formData = { ...ps, projectType: e.target.value };
      dispatch(updateProjectDefinition(formData));
      return formData;
    });
  };

  const handleProjectNameChange = (e) => {
    setIsSaveDisabled(false);
    const projectName = e.target.value;
    setFormData((ps) => {
      const formData = { ...ps, projectName: projectName };
      dispatch(updateProjectDefinition(formData));
      return formData;
    });
  };

  const handleEstimatedDaysChange = (e) => {
    setIsSaveDisabled(false);
    const estDays = e.target.value;
    setFormData((ps) => {
      const formData = { ...ps, projectEstimatedDays: estDays };
      dispatch(updateProjectDefinition(formData));
      return formData;
    });
  };

  const handleProjectOwnerSelect = (e) => {
    setIsSaveDisabled(false);
    const ownerId = e.target.value;
    const owner = companyList
      .map((cl) => [...cl.members])
      .flat()
      .filter((m) => m.id === ownerId)[0];

    setFormData((ps) => {
      const formData = {
        ...ps,
        projectOwner: {
          id: e.target.value,
          name: `${owner.first_name}${
            owner.middle_name ? ' ' + owner.middle_name : ''
          } ${owner.last_name}`,
        },
      };
      dispatch(updateProjectDefinition(formData));
      return formData;
    });
  };

  const handleChangebd = (html) => {
    setIsSaveDisabled(false);
    setFormData({
      ...formData,
      projectDetails: html !== '<p><br></p>' ? html : '',
    });
    dispatch(
      updateProjectDefinition({
        ...formData,
        projectDetails: html !== '<p><br></p>' ? html : '',
      })
    );
  };

  const handleStartDateChange = (date) => {
    setIsSaveDisabled(false);
    //setSelectedDate(date);
    setFormData((ps) => {
      const formData = { ...ps, projectStartDate: date };
      dispatch(updateProjectDefinition(formData));
      return formData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validatePostProjectCreateForm(formData);
    if (formData && Object.keys(formErrors).length === 0) {
      dispatch(submitProjectDefinition(formData));
      /**
       * if projectDefinition is null than we need to create new project,
       * otherwise it will create on every next
       * Note: Here if projectDefinition is update we should call updateProject
       * which is underdevelopment
       */
      if (!pageMode && !projectId) {
        createProject();
      } else {
        saveProject();
      }

      setSubmitNext(true);
    } else {
      setIsError(true);
      setErrors(formErrors);
      return false;
    }
  };

  function handleExit() {
    setIsSaveDisabled(true);
    history.push(`${process.env.PUBLIC_URL}/projects/my-projects`);
  }

  function handleSave(e) {
    e.preventDefault();
    saveProject();
    handleExit();
  }

  function createProject() {
    const payload = {
      // project_id: projectId, // todo
      company_id: formData.company.id,
      creator_project_role_id: formData.ourRole[0],
      owner_member_id: formData.projectOwner.id,
      project_title: formData.projectName,
      project_type: formData.projectType,
      project_description: formData.projectDetails,
      project_start_date: projecCreationDateFormat(formData.projectStartDate),
      project_estimated_days: +formData.projectEstimatedDays,
    };

    dispatch(ProjectsApi.postProjectCreate(payload));
  }

  function saveProject() {
    const payload = {
      company_id: formData.company.id,
      owner_member_id: formData.projectOwner.id, //member_id here
      project_title: formData.projectName,
      project_type: formData.projectType,
      project_description: formData.projectDetails,
      project_start_date: projecCreationDateFormat(formData.projectStartDate),
      project_estimated_days: +formData.projectEstimatedDays,
    };
    dispatch(ProjectsApi.updateProject(payload, projectId, callBackToast));
  }
  /**
   * 
   * @param {*} message response description
   * @param {*} options {
        open: true,
        message,
        type: 'success',
      }
   */
  function callBackToast(options) {
    dispatch(setSnackbarData(options));
  }

  /**
   * ON NEXT button the redirection logic
   */
  if (isSubmitNext && storeProjectId) {
    let url = `${process.env.PUBLIC_URL}/projects/select-team${
      storeProjectId ? `/${storeProjectId}` : ''
    }`;
    return <Redirect to={url} />;
  }

  return (
    <div className={styles.midContainer}>
      <SnackBarMessage />
      <Breadcrumbs
        parent="Create Projects"
        current_directory="Project Definition"
      />
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid
            container
            item
            spacing={3}
            lg={7}
            sm={12}
            xs={12}
            style={{ marginTop: '40px' }}
          >
            <Grid item xs={12} sm={12} className={styles.mt20}>
              <h5>Company Details</h5>
              <span>
                Which company does this project belong to, and who is the
                ultimate authority of this project?
              </span>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CompanySelect
                company={formData.company}
                handleChange={handleCompanySelect}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ProjectOwnerSelect
                company={formData.company}
                projectOwner={formData.projectOwner}
                handleChange={handleProjectOwnerSelect}
              />
            </Grid>

            <Grid item xs={12} sm={12} className={styles.mt20}>
              <h5>Project Details</h5>
              <span>
                What are the details of this project? You can write or paste a
                description.
              </span>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id={'outlined-email'}
                label={'Project Name'}
                fullWidth
                required
                variant="outlined"
                name={'project_name'}
                value={formData.projectName}
                onChange={handleProjectNameChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ProjectTypeSelect
                projectType={formData.projectType}
                handleChange={handleProjectTypeSelect}
                // projectType is define in postProjectCreateForm.js
                error={errors['projectType']}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <CustomComposeEditor
                handleChangebd={handleChangebd}
                multiline
                rowsMax={20}
                placeholder="Write or paste your project definition here."
                value={formData.projectDetails}
              />
            </Grid>

            <Grid item xs={12} sm={12} className={styles.mt20}>
              <h5>Estimated Duration</h5>
              <span>
                When will the project start, and how many days do you think it
                will take to complete?
              </span>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DateInput
                label="Start Date"
                value={formData.projectStartDate}
                inputVariant="outlined"
                onChange={handleStartDateChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id={'est-days'}
                label={'Estimated Days'}
                fullWidth
                required
                variant="outlined"
                type="number"
                name={'estimated_days'}
                value={formData.projectEstimatedDays}
                onChange={handleEstimatedDaysChange}
                // projectEstimatedDays is define in postProjectCreateForm.js
                error={errors['projectEstimatedDays'] ? true : false}
                helperText={
                  errors['projectEstimatedDays']
                    ? errors['projectEstimatedDays']
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} className={styles.submitBtn}>
              {pageMode === 'EDIT' ? (
                <EditModeButtons
                  isSaveDisabled={isSaveDisabled}
                  handleExit={handleExit}
                  handleSave={handleSave}
                />
              ) : (
                <Button variant="contained" color="primary" type="submit">
                  Next
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CreateProject;
