import {
  SET_SNACKBAR,
  REMOVE_NOTIFICATION,
  ADD_NOTIFICATION,
  REMOVE_STATIC_NOTIFICATION,
  ADD_STATIC_NOTIFICATION,
} from '../actionTypes/snackbar';

import { APP_RESET } from '../actionTypes/app';

export const initialState = {
  snackbarData: {
    open: false,
    message: '',
    type: '',
  },
  notifications: [],
  staticNotification: {}
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case SET_SNACKBAR:
      return {
        ...state,
        snackbarData: { ...action.payload },
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.id !== action.payload
        ),
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case ADD_STATIC_NOTIFICATION:
      return {
        ...state,
        staticNotification: action.payload
      }
    case REMOVE_STATIC_NOTIFICATION:
      return {
        ...state,
        staticNotification: {}
      }
    default:
      return state;
  }
};

export default snackbar;