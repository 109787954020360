import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import ProjectStatusSelect from './ProjectStatusSelect';
import ProjectTypeSelect from './ProjectTypeSelect';
import CompanySelect from './CompanySelect';
import CreateButton from './CreateButton';
import ProjectSearch from './ProjectSearch';
import { ProjectsApi } from '../../redux/actions/project';
import styles from './ProjectManagement.module.scss';
// import { projectCreationStart } from '../../redux/actions/projects';
import { loadContacts } from '../../redux/actions/contact';
import { projectCreationStart } from '../../redux/actions/projects';

const ProjectsDashboard = () => {
  const defaultFilter = {
    types: [],
    statuses: [],
    companies: [],
    input: '',
  };

  const history = useHistory();

  const handleCreateClick = (e) => {
    history.push(`${process.env.PUBLIC_URL}/projects/new-project`);
  };

  const [filter, setFilter] = useState(defaultFilter);

  const dispatch = useDispatch();
  // TODO: for reference might be needed later
  // selectors
  // const loggedInUserInfo = useSelector((state) => {
  //   return state.member.member;
  // });

  useEffect(() => {
    // dispatch(ProjectsApi.getProjects());
    // dispatch(ProjectsApi.postProjectMemberPrivileges());
    // dispatch(projectCreationStart());
    dispatch(ProjectsApi.getProjects());
    dispatch(loadContacts());
    dispatch(projectCreationStart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ProjectsApi.getProjectRoles());
  }, [dispatch]);

  const handleTypeSelect = (e) => {
    setFilter((ps) => ({ ...ps, types: e.target.value }));
  };
  const handleStatusSelect = (e) => {
    setFilter((ps) => ({ ...ps, statuses: e.target.value }));
  };
  const handleCompanySelect = (e) => {
    setFilter((ps) => ({ ...ps, companies: e.target.value }));
  };
  const handleSearchInput = (input) => {
    setFilter((ps) => ({ ...ps, input }));
  };

  return (
    <div
      style={{ padding: '30px 20px' }}
      className={(styles, styles.midContainer)}
    >
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <ProjectSearch
              searchInput={filter.input}
              handleChange={handleSearchInput}
            />
          </Grid>
          <Grid item xs={2}>
            <CompanySelect
              multiple
              company={{}}
              handleChange={handleCompanySelect}
            />
          </Grid>
          <Grid item xs={2}>
            <ProjectTypeSelect
              multiple
              projectType={filter.types}
              handleChange={handleTypeSelect}
            />
          </Grid>
          <Grid item xs={2}>
            <ProjectStatusSelect
              multiple
              projectStatus={filter.statuses}
              handleChange={handleStatusSelect}
            />
          </Grid>
          <Grid item xs={2}>
            <CreateButton onClick={handleCreateClick} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProjectsTable
            filter={filter}
            onCreateClick={handleCreateClick}
            onClearFilter={() => setFilter(defaultFilter)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectsDashboard;
