import React from 'react';

const MailIdkIcon = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.5" y="4.5" width="13" height="10" stroke="#9390A6" />
    <path d="M11.6923 1L14 4H2L4.30769 1H11.6923Z" stroke="#9390A6" />
    <rect
      x="4.5"
      y="7.5"
      width="13"
      height="10"
      fill="#9390A6"
      stroke="#9390A6"
    />
    <path d="M14.6923 10L17 7H5L7.30769 10H14.6923Z" stroke="#F8FAFF" />
    <path d="M3.5 14.5V13.5V15.75" stroke="#F8FAFF" />
  </svg>
);

export default MailIdkIcon;
