import { GET_BADGE_COUNT, SET_BADGE_COUNT } from '../actionTypes/mail';

import {
  APP_RESET
} from '../actionTypes/app';

// The initial state of the App
export const initialState = {
  mailBadgeCount: 0,
  mailBadgeCountLoading: false,
};

const mail = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case GET_BADGE_COUNT:
      return {
        ...state,
        mailBadgeCountLoading: true,
      };
    case SET_BADGE_COUNT:
      return {
        ...state,
        mailBadgeCount: action.payload,
        mailBadgeCountLoading: false,
      };
    default:
      return state;
  }
};

export default mail;
