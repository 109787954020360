import React from 'react';
import clsx from 'clsx';
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten, makeStyles } from '@material-ui/core/styles';

import { PopoverWithIcon } from '../../../../../components/Modal/PopoverWithIcon';

import { mailButtonGroup } from '../../../../../utils/email';
import { MailTopButtonGroup } from '../EmailButtonGroups/MailTopButtonGroup';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    paddingTop: 7,
  },
  titleSelected: {
    paddingTop: 17,
  },
  filterContainer: {
    borderBottom: '1px solid #8DA3C5',
  },
  filterContainerTable: {
    height: 64,
  },
  ddControl: {
    float: 'right',
    marginTop: 4,
    marginRight: 7,
  },
  rControl: {
    textAlign: 'right',
    paddingTop: 7,
    paddingRight: 5,
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    title,
    selectedEmail,
    selectedEmails,
    showFolders,
    createFolder,

    section,
    sortControls,
    filterControls,
    topButtonsHandlers,
    handlePrint,
    selectedFolder,
    selectedXref,
  } = props;
  const numSelected = selectedXref
    ? selectedXref.length
    : selectedEmails.length;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Grid container className={classes.filterContainerTable}>
        <Grid container item xs={showFolders ? 9 : 12}>
          {numSelected > 0 && (
            <Grid container xs={12} item>
              <Grid item xs={4}>
                <Typography
                  className={classes.titleSelected}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {numSelected} mails selected
                </Typography>
              </Grid>

              <Grid item container xs={8} className={classes.rControl}>
                {!filterControls ? null : (
                  <Grid item xs={4}>
                    <FormControl size="small" variant="outlined">
                      <Select
                        displayEmpty
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={filterControls.value}
                        onChange={(e) => {
                          filterControls.changeValue(e);
                        }}
                      >
                        <MenuItem value={0} disabled>
                          Filter
                        </MenuItem>
                        <MenuItem value={1}>Option 1</MenuItem>
                        <MenuItem value={2}>Option 2</MenuItem>
                        <MenuItem value={3}>Option 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {selectedEmail && topButtonsHandlers && (
                  <Grid
                    item
                    xs={filterControls ? 8 : 12}
                    container
                    direction="row"
                    className={classes.rControl}
                  >
                    <MailTopButtonGroup
                      selectedEmail={selectedEmails}
                      handlers={topButtonsHandlers}
                      executeFrom={mailButtonGroup.mailStarred.name}
                      handlePrint={handlePrint}
                      selectedFolder={selectedFolder}
                      section={section}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {numSelected === 0 && (
            <Grid item xs={6}>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                {title}
              </Typography>
            </Grid>
          )}

          {numSelected === 0 && sortControls && (
            <Grid item xs={6}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.ddControl}
              >
                <Select
                  displayEmpty
                  aria-label="sort-selection"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={sortControls.value}
                  onChange={(e) => {
                    sortControls.changeValue(e);
                  }}
                >
                  <MenuItem value={0} disabled>
                    {section}
                  </MenuItem>
                  <MenuItem value={1}>Date</MenuItem>
                  {section === 'Sent' ? null : (
                    <MenuItem value={2}>Read</MenuItem>
                  )}
                  {section === 'Sent' ? null : (
                    <MenuItem value={3}>Unread</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        {showFolders ? (
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}
            className={classes.filterContainer}
          >
            <PopoverWithIcon
              icon="addControl"
              handleSubmit={createFolder}
              tooltip="Add new folder"
            />
          </Grid>
        ) : null}
      </Grid>
    </Toolbar>
  );
};
