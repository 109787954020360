import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import { textNewGray } from '../../../../../jss/colors';
import { mailButtonGroup } from '../../../../../utils/email';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& .MuiButton-outlinedPrimary': {
      color: textNewGray,
    },
  },
  button: {
    marginLeft: theme.spacing(1),
    borderRadius: 20,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

export const MailBottomButtonGroup = ({
  handlers,
  isOwnEmail,
  email,
  member,
}) => {
  const classes = useStyles();

  if (isOwnEmail && handlers && handlers.mailMoveToAtchive) {
    delete handlers.mailMoveToAtchive;
  }
  const receivers = email.receivers || {
    amera: [],
  };

  let buttonArray = handlers ? Object.keys(handlers) : [];

  return (
    <div aria-label="bottom-action-buttons" className={classes.root}>
      {buttonArray.map((el, index) => {
        return (
          <div key={`button-bottom-action-${index}-conta`}>
            <Button
              key={`button-bottom-action-${index}`}
              variant={
                index === buttonArray.length - 1 ? 'contained' : 'outlined'
              }
              color="primary"
              className={classes.button}
              startIcon={<SVG name={el} />}
              onClick={() => {
                handlers[el]();
              }}
            >
              {el === mailButtonGroup.mailMoveToOrigin.name
                ? mailButtonGroup[el].text(isOwnEmail ? 'Sent' : 'Inbox')
                : mailButtonGroup[el].text}
            </Button>
            {el === 'mailReply' && receivers.amera.length > 1 ? (
              <Button
                key={`button-bottom-action-${index}-replyALl`}
                variant={
                  index === buttonArray.length - 1 ? 'contained' : 'outlined'
                }
                color="primary"
                className={classes.button}
                startIcon={<SVG name={el} />}
                onClick={() => {
                  let newRecievers = receivers.amera;
                  newRecievers.splice(
                    receivers.amera.findIndex((el) => el === member.member_id),
                    1
                  );
                  let replyEmail = {
                    ...email,
                    receivers: {
                      amera: newRecievers,
                    },
                  };
                  handlers[el](replyEmail);
                }}
              >
                {mailButtonGroup[el].text} All
              </Button>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
