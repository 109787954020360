import React from 'react';
import { Button, withStyles, Switch } from '@material-ui/core';

const DeleteButton = withStyles({
  root: {
    background: '#EE346B 0% 0% no-repeat padding-box',
    borderRadius: '22px',
    opacity: 1,
    color: 'white',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 12px',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: '#EE346B 0% 0% no-repeat padding-box',
      color: 'white',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#EE346B',
      border: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const EditButton = withStyles({
  root: {
    background: '#94C03D 0% 0% no-repeat padding-box',
    borderRadius: '22px',
    opacity: 1,
    color: 'white',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '6px 12px',
    border: 'none',
    '&:hover': {
      border: 'none',
      background: '#94C03D 0% 0% no-repeat padding-box',
      color: 'white',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#94C03D',
      border: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const CustomSwitch = withStyles({
  switchBase: {
    color: '#CECECE',
    '& + $track': {
      backgroundColor: '#CECECE',
    },
    '&$checked': {
      color: '#9DBD53',
      '& + $track': {
        backgroundColor: '#9DBD53',
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    transform: 'translateY(-2px) translateX(4px)',
  },
  root: {
    width: 70,
    height: 40,
  },
  checked: {},
  track: {},
  focusVisible: {},
})(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple={true}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export { DeleteButton, EditButton, CustomSwitch };
