import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '../Modal/modal';
import RecordVideo from '../RecordVideo';

const RecordVideoModal = (props) => {
  const { open, onClose, onSend } = props;
  const [mediaType, setMediaType] = useState('video');
  const title = 'Broadcast Record';

  const closeModal = () => {
    onClose();
  };

  const ModalContent = () => (
    <RecordVideo
      show={open}
      send={onSend}
      mediaType={mediaType}
      setMediaType={setMediaType}
    />
  );

  const ModalAction = () => (
    <Button variant="contained" color="secondary" onClick={closeModal}>
      Close
    </Button>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        maxWidth="lg"
        disableBackdropClick={true}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

RecordVideoModal.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
};

RecordVideoModal.defaultProps = {
  open: false,
  type: 'broadcast',
};

export default RecordVideoModal;
