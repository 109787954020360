import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const TermsAndConditonsCheckBox = (props) => {
  const {
    onOpenTermsAndConditons,
    isAccepted,
    onCheckBoxToggle,
    errors,
  } = props;

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAccepted}
              onChange={onCheckBoxToggle}
              name="terms-and-conditions"
            />
          }
          label={
            <span>
              I have read and fully accept AMERA&nbsp;
              <span
                style={{ zIndex: 1000, borderBottom: '1px dotted' }}
                onClick={onOpenTermsAndConditons}
              >
                terms and conditions
              </span>
            </span>
          }
        />
      </FormGroup>
      <FormHelperText error>{errors ? errors : null}</FormHelperText>
    </>
  );

  //   return <div onClick={onOpenTermsAndConditons}>some div</div>;
};

export default TermsAndConditonsCheckBox;
