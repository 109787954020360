import React from 'react';

const LogoAmeraShare = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="325.57"
      // height="82.941"
      {...props}
      viewBox="0 0 325.57 82.941"
    >
      <g
        id="Group_7"
        data-name="Group 7"
        transform="translate(-2040.645 -1449.673)"
      >
        <g
          id="Group_3"
          data-name="Group 3"
          transform="translate(2040.645 1449.673)"
        >
          <g id="Group_2" data-name="Group 2" transform="translate(0)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M2084.98,1492.155h-26.054l-4.972,12.026h-13.309l25.016-56.12h12.827l25.092,56.12h-13.628Zm-4.09-9.861-8.9-21.484-8.9,21.484Z"
              transform="translate(-2040.646 -1448.06)"
              fill="#649f30"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M2139.634,1504.18l-.078-33.672-16.517,27.74h-5.852l-16.436-27.018v32.95h-12.186v-56.12h10.744l21,34.875L2141,1448.06h10.662l.163,56.12Z"
              transform="translate(-2019.772 -1448.06)"
              fill="#649f30"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M2185.358,1493.759v10.422H2141.9v-56.12h42.413v10.423h-29.5v12.185h26.058v10.1H2154.81v12.99Z"
              transform="translate(-1996.539 -1448.06)"
              fill="#649f30"
            />
            <path
              id="Path_6"
              data-name="Path 6"
              d="M2215.136,1504.18l-10.824-15.634h-11.946v15.634h-12.987v-56.12h24.291c14.992,0,24.372,7.776,24.372,20.364,0,8.417-4.25,14.593-11.545,17.717l12.587,18.039Zm-12.186-45.54h-10.584v19.564h10.584c7.936,0,11.945-3.687,11.945-9.781C2214.895,1462.25,2210.885,1458.641,2202.95,1458.641Z"
              transform="translate(-1980.216 -1448.06)"
              fill="#649f30"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M2259.627,1492.155h-26.059l-4.971,12.026H2215.29l25.015-56.12h12.826l25.1,56.12H2264.6Zm-4.092-9.861-8.9-21.484-8.9,21.484Z"
              transform="translate(-1964.573 -1448.06)"
              fill="#649f30"
            />
          </g>
        </g>
        <g
          id="Group_4"
          data-name="Group 4"
          transform="translate(2258.175 1515.422)"
        >
          <path
            id="Path_8"
            data-name="Path 8"
            d="M2192.173,1509.129l1.307-2.9a9.775,9.775,0,0,0,5.484,1.733c2.278,0,3.2-.759,3.2-1.78,0-3.112-9.664-.975-9.664-7.148,0-2.827,2.278-5.177,7.005-5.177a11.151,11.151,0,0,1,5.77,1.47l-1.187,2.921a9.412,9.412,0,0,0-4.608-1.3c-2.28,0-3.159.854-3.159,1.9,0,3.063,9.667.951,9.667,7.053,0,2.778-2.3,5.152-7.054,5.152A11.862,11.862,0,0,1,2192.173,1509.129Z"
            transform="translate(-2192.173 -1493.86)"
            fill="#737677"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M2220.531,1494.058v16.621h-3.845v-6.812h-7.552v6.812h-3.846v-16.621h3.846v6.555h7.552v-6.555Z"
            transform="translate(-2186.46 -1493.773)"
            fill="#737677"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            d="M2231.914,1507.119H2224.2l-1.473,3.56h-3.94l7.407-16.621h3.8l7.43,16.621h-4.036Zm-1.21-2.922-2.636-6.362-2.635,6.362Z"
            transform="translate(-2180.582 -1493.773)"
            fill="#737677"
          />
          <path
            id="Path_11"
            data-name="Path 11"
            d="M2245.219,1510.679l-3.209-4.628h-3.533v4.628h-3.848v-16.621h7.194c4.44,0,7.219,2.3,7.219,6.032a5.4,5.4,0,0,1-3.417,5.248l3.728,5.341Zm-3.612-13.486h-3.131v5.793h3.131c2.351,0,3.54-1.092,3.54-2.9C2245.147,1498.262,2243.958,1497.193,2241.608,1497.193Z"
            transform="translate(-2173.68 -1493.773)"
            fill="#737677"
          />
          <path
            id="Path_12"
            data-name="Path 12"
            d="M2261.31,1507.593v3.085h-12.872v-16.621H2261v3.086h-8.739v3.609h7.718v2.993h-7.718v3.847Z"
            transform="translate(-2167.664 -1493.773)"
            fill="#737677"
          />
        </g>
        <g
          id="Group_5"
          data-name="Group 5"
          transform="translate(2354.366 1515.901)"
        >
          <path
            id="Path_13"
            data-name="Path 13"
            d="M2258.256,1440.4h-1.786v-1.052h4.863v1.052h-1.785v4.528h-1.292Z"
            transform="translate(-2256.47 -1439.345)"
            fill="#737677"
          />
          <path
            id="Path_14"
            data-name="Path 14"
            d="M2269.859,1444.925l-.008-3.348-1.643,2.759h-.582l-1.633-2.687v3.276h-1.212v-5.58h1.068l2.089,3.468,2.057-3.468h1.06l.016,5.58Z"
            transform="translate(-2259.221 -1439.345)"
            fill="#737677"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoAmeraShare;
