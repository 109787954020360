import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Card from '../Card';
import CardButtons from '../CardButtons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BorderLinearProgress from '../../BorderLinearProgressBar';
import { displayableFullName } from '../../../utils/contact';
import { acceptGroupInvitation } from '../../../redux/actions/event';
import { setSnackbarData } from '../../../redux/actions/snackbar';
import { textCapitalized } from '../../../utils/general';
import { useCardStyles } from './CardStyle';

import SecurityDetailModal from '../../../components/Modal/SecurityDetailModal';

import { defaultDateTimeZone } from '../../../utils/calendar';

const GroupCard = ({ data, type, messages, formatMessage }) => {
  const classes = useCardStyles();
  const dispatch = useDispatch();

  const [isShowSecurityModal, setShowSecurityModal] = useState(false);

  const title = formatMessage(messages.groupTitle);
  const name = !!data.first_name ? `${displayableFullName(data)}` : null;
  const subject = `${name} added you to the "${data.group_name}" group.`;

  // const group_create_datetime = format(
  //   new Date(data.group_create_date || data.group_create_date),
  //   'Pp'
  // ).replace(',', '');
  const group_create_datetime =
    (data.group_create_date && defaultDateTimeZone(data.group_create_date)) ||
    '';
  const invite_datetime =
    (data.create_date && defaultDateTimeZone(data.create_date)) || '';
  // const invite_datetime = format(
  //   new Date(data.create_date || data.create_date),
  //   'Pp'
  // ).replace(',', '');

  const handleResponse = async (status) => {
    const result = await dispatch(acceptGroupInvitation(data.id, status));
    const accept_decline = status === 'active' ? 'accepted' : 'declined';
    if (result) {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Successfully ${accept_decline} request`,
          type: 'success',
        })
      );
    } else {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Request failed to be ${accept_decline}`,
          type: 'error',
        })
      );
    }
  };

  const showGroupSecurity = async () => {
    setShowSecurityModal(true);
  };

  const action = <></>;

  const avatar = {
    first_name: data.first_name,
    last_name: data.last_name,
    url: data.amera_avatar_url,
  };

  const buttons = (
    <CardButtons
      type={type}
      cancelText={formatMessage(messages.decline)}
      okText={formatMessage(messages.accept)}
      cancelHandle={() => handleResponse('declined')}
      okHandle={() => handleResponse('active')}
    />
  );

  const content = (
    <List className={classes.contentSecondary}>
      <ListItem className={classes.securityPercentageContainer}>
        <div>
          <BorderLinearProgress
            variant="determinate"
            value={data?.group_exchange_option ? data.group_exchange_option : 0}
            onClick={() => {
              showGroupSecurity(data.group_id, data.group_leader_id);
            }}
          />
          <p className={classes.securityPercentage}>
            {data?.group_exchange_option ? data.group_exchange_option : 0}%
          </p>
          <p
            className={classes.securityLabel}
            onClick={() =>
              showGroupSecurity(data.group_id, data.group_leader_id)
            }
          >
            Security Level
          </p>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.infoTitle}>
          <label>{formatMessage(messages.groupDetails)}:</label>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupName)}:</label>
          <span>&nbsp;{data.group_name}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupStatus)}:</label>
          <span>&nbsp;{textCapitalized(data.group_status)}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupLeader)}:</label>
          <span>
            &nbsp;
            {textCapitalized(
              `${data.first_name || ''} ${data.last_name || ''}`
            )}
          </span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupFiles)}:</label>
          <span>&nbsp;{data.total_files || 0}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupMembers)}:</label>
          <span>&nbsp;{data.total_member || 0}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupVideos)}:</label>
          <span>&nbsp; {data.total_videos || 0}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.groupCreateDate)}:</label>
          <span>&nbsp;{group_create_datetime}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.inviteDate)}:</label>
          <span>&nbsp;{invite_datetime}</span>
        </div>
      </ListItem>
    </List>
  );

  return (
    <>
      <Card
        title={title}
        action={action}
        avatar={avatar}
        subTitle={name}
        subHeader={subject}
        content={content}
        buttons={buttons}
      />
      <SecurityDetailModal
        open={isShowSecurityModal}
        disabled={true}
        onClose={() => setShowSecurityModal(false)}
        group_id={data.id}
        group_leader_id={data.create_user_id}
      />
    </>
  );
};

export default GroupCard;
