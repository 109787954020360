import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconVideoQualityHD } from '../icons';
import { injectIntl } from 'react-intl';
const ManageQuality = (props) => {
  const {
    intl: { formatMessage },
  } = props;
  const messages = {
    callquality: {
      id: 'app.home.callquality',
      defaultMessage: 'Manage video quality',
    },
  };
  return (
    <Dropdown.Item eventKey={props.eveneKey} onClick={props.trigger}>
      <div className="amera-icon">
        <IconVideoQualityHD />
      </div>
      <span> {formatMessage(messages.callquality)} </span>
    </Dropdown.Item>
  );
};

export default injectIntl(ManageQuality);
