import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M18.688 6.688h6.625v6.625h-2.625v-4h-4V6.688zm4 16v-4h2.625v6.625h-6.625v-2.625h4zm-16-9.375V6.688h6.625v2.625h-4v4H6.688zm2.625 5.375v4h4v2.625H6.688v-6.625h2.625z"></path>
    </svg>
  );
}
export default Icon;
