import { useLocation } from 'react-router-dom';
/**
 * useQuery is a custom hook which take query params
 * and return query param value
 */
const useQuery = (qParam) => {
  const routerLocation = useLocation().search;
  const qString = new URLSearchParams(routerLocation);
  let queryString = qString.get(qParam);

  return {
    queryString,
  };
};

export default useQuery;
