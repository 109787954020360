import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { isEqual, roundToNearestMinutes } from 'date-fns';

import { defaultDateTimeZone, getTimeLimits } from '../../../utils/calendar';

const DropDownTImeInput = (props) => {
  const {
    id,
    date,
    onChange,
    label,
    stepMinutes = 15,
    isFreeSolo = false,
    onInputChange = null,
  } = props;

  const options = getTimeLimits(date, stepMinutes);

  return (
    <Autocomplete
      getOptionSelected={(o, v) => {
        return isEqual(
          roundToNearestMinutes(o.value),
          roundToNearestMinutes(v.value)
        );
      }}
      id={id}
      fullWidth
      freeSolo={isFreeSolo}
      disableClearable
      options={options}
      forcePopupIcon
      getOptionLabel={(o) => {
        return o.str;
      }}
      renderOption={(o) => <Typography variant="body2">{o.str}</Typography>}
      onChange={(e, o) => onChange(o.value)}
      onBlur={onInputChange ? onInputChange : () => {}}
      value={{ value: date, str: defaultDateTimeZone(date, 'time') }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          margin="dense"
          style={{ margin: 0 }}
        />
      )}
    />
  );
};

export default DropDownTImeInput;
