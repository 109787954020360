import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';

import { deleteStreamVideo } from '../../redux/actions/stream';
import { openDrawer } from '../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';

const MoreActions = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (data && data.id) dispatch(deleteStreamVideo(data.id));
    handleClose();
  };

  const handleEdit = () => {
    if (data && data.id)
      dispatch(
        openDrawer(DRAWER_CONTENT_TYPE.uploadcontent, {
          uploadContent: { isEdit: true, data: data },
        })
      );
    handleClose();
  };

  const isDisabled = data.id ? false : true;

  return (
    <div>
      <IconButton
        aria-label="settings"
        aria-controls="more-actions"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id="more-actions"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete} disabled={isDisabled}>
          Delete
        </MenuItem>
        <MenuItem onClick={handleEdit} disabled={isDisabled}>
          Edit
        </MenuItem>
      </Popover>
    </div>
  );
};

export default MoreActions;
