import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import AmeraPagination from '../../components/AmeraPagination';
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
  Tooltip,
  TextField,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ConfirmDialog from '../../components/ConfirmDialog';
import Modal from '../../components/Modal/modal';
import {
  getUnregisteredCompanies,
  deleteUnregisteredCompany,
  updateUnregisteredCompany,
  createCompanyFromName,
} from '../../redux/actions/company';

const headCells = [
  {
    id: 'company_name',
    label: 'Company Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'total_members',
    label: 'Total Members',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    sortDisabled: true,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.sortDisabled ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const UnregisteredCompanyTable = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState('company_name');
  const [order, setOrder] = useState('asc');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmTextType, setConfirmTextType] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { classes } = props;

  const dispatch = useDispatch();
  let { companies, totalCount } = useSelector((state) => ({
    companies: state.company.unregisteredCompanies,
    totalCount: state.company.unregisteredCount,
  }));

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedCompanies(null, null, newPage - 1);
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(parseInt(pageSize, 10));
    setPageNumber(1);
    loadSortedCompanies(null, null, 0, pageSize);
  };

  useEffect(() => {
    dispatch(getUnregisteredCompanies({ page_number: 0, page_size: 25 }));
  }, [dispatch]);

  const loadSortedCompanies = (property, value, page_number, page_size) => {
    let sort = '';
    if (!property) {
      property = orderBy;
    }
    if (!value) {
      value = order;
    }

    sort = value === 'desc' ? `-${property}` : `${property}`;

    let params = {};

    params.sort = sort;
    params.page_number = page_number >= 0 ? page_number : pageNumber - 1;
    params.page_size = page_size ? page_size : pageSize;

    dispatch(getUnregisteredCompanies(params));
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedCompanies(property, newOrder);
  };

  const ModalContent = () => (
    <div>
      <TextField
        variant="standard"
        value={newCompanyName}
        fullWidth
        onChange={(event) => {
          setNewCompanyName(event.target.value);
        }}
      />
    </div>
  );

  const ModalAction = () => (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={async () => {
          await dispatch(
            updateUnregisteredCompany(companyName, newCompanyName)
          );
          setShowUpdateModal(false);
        }}
      >
        Update
      </Button>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setShowUpdateModal(false);
        }}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <>
      <TableContainer className={classes.skinnyTable}>
        <Table
          stickyHeader
          aria-labelledby="activityTable"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {companies &&
              companies.map((company) => {
                return (
                  <TableRow hover key={company.company_name}>
                    <TableCell align="left">{company.company_name}</TableCell>
                    <TableCell align="left">{company.total_members}</TableCell>
                    <TableCell align="center" className={classes.actions}>
                      <Tooltip title="Create a new company">
                        <AddCircleIcon
                          onClick={() => {
                            setShowConfirmDialog(true);
                            setConfirmTextType(0);
                            setCompanyName(company.company_name);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Edit">
                        <EditIcon
                          onClick={() => {
                            setCompanyName(company.company_name);
                            setNewCompanyName(company.company_name);
                            setShowUpdateModal(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Clear">
                        <DeleteIcon
                          onClick={() => {
                            setShowConfirmDialog(true);
                            setConfirmTextType(1);
                            setCompanyName(company.company_name);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalCount > 25 && (
        <AmeraPagination
          itemTypeLabel="Items"
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          page={pageNumber}
          handleChangePage={handleChangePage}
        />
      )}

      <ConfirmDialog
        open={showConfirmDialog}
        content={
          confirmTextType === 0
            ? 'Are you sure you want to create a new company?'
            : 'Are you sure you want to delete this'
        }
        onCancel={() => {
          setShowConfirmDialog(false);
        }}
        onOk={async () => {
          if (confirmTextType === 0) {
            await dispatch(createCompanyFromName(companyName));
            setShowConfirmDialog(false);
          } else {
            await dispatch(deleteUnregisteredCompany(companyName));
            setShowConfirmDialog(false);
          }
        }}
      />
      <Modal
        open={showUpdateModal}
        title="Update company name"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

export default UnregisteredCompanyTable;
