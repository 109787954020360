import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const PlaceInfo = (props) => {
  const { place, isSelected } = props;
  console.log('place', place);
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {isSelected ? (
            <LocationOnIcon size="inherit" color="primary" />
          ) : null}
          {place.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {!!place.formatted_address ? place.formatted_address : place.vicinity}
        </Typography>
      </Grid>
      {!!place.photos && place.photos.length > 0 && (
        <Grid item xs={12}>
          <img
            width="100%"
            src={place.photos[0].getUrl({ maxWidth: 250, maxHeight: 300 })}
            alt={place.photos[0].name}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlaceInfo;
