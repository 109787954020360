import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M16 20.688c2.563 0 4.688-2.125 4.688-4.688S18.563 11.312 16 11.312 11.312 13.437 11.312 16s2.125 4.688 4.688 4.688zm9.938-3.375l2.813 2.188c.25.188.313.563.125.875l-2.688 4.625c-.188.313-.5.375-.813.25l-3.313-1.313c-.688.5-1.438 1-2.25 1.313l-.5 3.5c-.063.313-.313.563-.625.563h-5.375c-.313 0-.563-.25-.625-.563l-.5-3.5a8.148 8.148 0 01-2.25-1.313l-3.313 1.313c-.313.125-.625.063-.813-.25l-2.688-4.625c-.188-.313-.125-.688.125-.875l2.813-2.188c-.063-.438-.063-.875-.063-1.313s0-.875.063-1.313l-2.813-2.188c-.25-.188-.313-.563-.125-.875l2.688-4.625c.188-.313.5-.375.813-.25l3.313 1.313c.688-.5 1.438-1 2.25-1.313l.5-3.5c.063-.313.313-.563.625-.563h5.375c.313 0 .563.25.625.563l.5 3.5c.813.313 1.563.75 2.25 1.313l3.313-1.313c.313-.125.625-.063.813.25l2.688 4.625c.188.313.125.688-.125.875l-2.813 2.188c.063.438.063.875.063 1.313s0 .875-.063 1.313z"></path>
    </svg>
  );
}
export default Icon;
