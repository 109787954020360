import React, { useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { startsWith, toLower, orderBy } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import Button from '../../Dashboard/CardButton';
import LabeledValue from '../../../components/LabeledValue';
import CompanyMember from './CompanyMember';
import AmeraSearch from '../../../components/AmeraSearch';
import AmeraAccordion from '../../../components/AmeraAccordion/AmeraAccordion';
import drawerProfileStyles from '../../../jss/views/drawerProfileStyles';
import { COMPANY_ROLES, DEPARTMENT_ROLES } from '../../../utils/company';
import AddMemberModal from '../../../components/Company/AddMemberModal';
import {
  addCompanyMember,
  removeCompanyMember,
} from '../../../redux/actions/company';
import ConfirmDialog from '../../../components/ConfirmDialog';

const CompanyMembersView = (props) => {
  const { companyData, onEditClick, isCanEdit } = props;

  const [scopedDep, setScopedDep] = useState('all');
  const [memberSearch, setMemberSearch] = useState('');
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [deleteMemberId, setDeleteMemberId] = useState(0);

  const handleDeleteConfirmDialogClose = () => {
    setDeleteConfirmDialogOpen(false);
  };

  const handleDeleteConfirmDialogOpen = (memberId) => {
    setDeleteConfirmDialogOpen(true);
    setDeleteMemberId(memberId);
  };

  const removeMemberFromCompany = () => {
    let formData = new FormData();
    formData.set('company_member_id', deleteMemberId);
    formData.set('company_id', companyData.id);
    dispatch(removeCompanyMember(formData));
    handleDeleteConfirmDialogClose();
  };

  const departments = [
    ...companyData.departments,
    { company_department_id: null, department_name: 'Not Specified' },
    { company_department_id: 'all', department_name: 'All Members' },
  ];

  const filteredMembers = useMemo(
    () =>
      orderBy(
        companyData.company_members
          .filter((cmo) =>
            scopedDep === 'all' ? true : cmo.company_department_id === scopedDep
          )
          .filter(
            (cmo) =>
              startsWith(toLower(cmo.first_name), toLower(memberSearch)) ||
              startsWith(toLower(cmo.last_name), toLower(memberSearch))
          ),
        [
          (cmo) => cmo.company_role === COMPANY_ROLES.owner,
          (cmo) => cmo.company_role === COMPANY_ROLES.admin,
          (cmo) => cmo.department_status === DEPARTMENT_ROLES.leader,
          (cmo) => toLower(cmo.first_name),
        ],
        ['desc', 'desc', 'desc', 'asc']
      ),
    [companyData.company_members, memberSearch, scopedDep]
  );

  const classes = drawerProfileStyles();
  const dispatch = useDispatch();

  const handleDepartmentClick = (departmentId) => setScopedDep(departmentId);

  const handleAddMemberModalClose = () => {
    setAddMemberModalOpen(false);
  };

  const handleAddMemberModal = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setAddMemberModalOpen(true);
  };

  const handleAddMember = (role, memberInfo) => {
    let formData = new FormData();

    formData.set('company_id', companyData.id);
    formData.set('company_role', role);
    formData.set('member_id', memberInfo.member_id);

    dispatch(addCompanyMember(formData));
    handleAddMemberModalClose();
  };

  return (
    <AmeraAccordion
      label="Company Members"
      button={
        <Button variant="okFilled" text="Add" onClick={handleAddMemberModal} />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LabeledValue
            label="Departments"
            value={
              <Grid item container className={classes.margin} spacing={1}>
                {departments.map((d) => (
                  <Grid key={d.company_department_id} item>
                    <Chip
                      size="small"
                      label={d.department_name}
                      clickable
                      className={clsx({
                        [classes.selectedChip]:
                          scopedDep === d.company_department_id,
                      })}
                      onClick={() =>
                        handleDepartmentClick(d.company_department_id)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <AmeraSearch
            searchInput={memberSearch}
            handleChange={(v) => setMemberSearch(v)}
            variant="standard"
            label="Search by name"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          className={classes.membersContainer}
        >
          {filteredMembers?.length > 0 ? (
            filteredMembers.map((m) => (
              <div className={classes.memberRow}>
                <CompanyMember key={m.company_member_id} contactData={m} />
                <IconButton
                  onClick={() =>
                    handleDeleteConfirmDialogOpen(m.company_member_id)
                  }
                  className={classes.clearButton}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            ))
          ) : (
            <div>No members</div>
          )}
        </Grid>
        {isCanEdit && (
          <Grid item xs={12} className={classes.alignRight}>
            <Button variant="okFilled" text="Edit" onClick={onEditClick} />
          </Grid>
        )}
      </Grid>
      {addMemberModalOpen && (
        <AddMemberModal
          title="Add Member"
          open={addMemberModalOpen}
          onClose={handleAddMemberModalClose}
          onSave={handleAddMember}
          members={companyData.company_members}
        />
      )}
      {deleteConfirmDialogOpen && (
        <ConfirmDialog
          open={deleteConfirmDialogOpen}
          title="Confirm Delete"
          onOk={removeMemberFromCompany}
          onCancel={handleDeleteConfirmDialogClose}
        />
      )}
    </AmeraAccordion>
  );
};

export default CompanyMembersView;
