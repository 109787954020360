import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO, addDays } from 'date-fns';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';

import { ProjectsApi } from '../../../redux/actions/project';
import { displayableFullName } from '../../../utils/contact';
import { useCardStyles } from './CardStyle';

import ProjectInviteModal from '../../../pages/ProjectManagement/ProjectDetails/Modals/ProjectInviteModal';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CardButton from './../CardButton';
import Card from '../Card';

import { unescape } from 'lodash';
import parse from 'html-react-parser';
import { defaultDateTimeZone } from '../../../utils/calendar';

const RATE_TYPE = {
  hourly: 'hourly',
  fixed: 'fixed',
};

const JobCard = (props) => {
  const { data, messages, formatMessage } = props;
  const classes = useCardStyles();
  const roles = useSelector((s) => s.project.projectRoles);

  const {
    invite_id,
    // inviter_id,
    project_id,
    company_name,
    contract_id,
    contract_rate_type,
    currency_code,
    project_title,
    project_description,
    project_start_date,
    project_owner,
    project_estimated_days,
    proposed_roles,
    invite_date,
    first_name,
    // middle_name,
    last_name,
    // title,
    // department,
    pay_rate,
    amera_avatar_url,
    scoped_element,
  } = data;

  const dispatch = useDispatch();
  const handleConfirm = (isAccept) => {
    dispatch(
      ProjectsApi.contractInviteReaction(
        { isAccept, project_id, contract_id },
        invite_id
      )
    );
    setConfirm(defaultConfirmState);
  };

  const defaultConfirmState = {
    isOpen: false,
    title: 'Confirmation',
    actionHandler: () => handleConfirm(true),
    content: 'Are you sure you want to accept this contract?',
  };
  // const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(defaultConfirmState);
  const [isShowDetails, setShowDetails] = useState(false);

  // Fixed contracts will show info for body of work, hourly contract will show info for project
  let scopeTitle, headerLine, scopeDescription;
  const inviterName = displayableFullName(data, false);
  const startDate = parseISO(project_start_date);
  const estimatedEndDate = addDays(startDate, Number(project_estimated_days));
  if (!!scoped_element) {
    const { element_title, element_description, element_type } = scoped_element;
    scopeTitle = element_title;
    headerLine = `${element_type} description`;
    scopeDescription = element_description;
  } else {
    scopeTitle = project_title;
    headerLine = formatMessage(messages.projectDescription);
    scopeDescription = project_description;
  }
  const formattedRate = `${currency_code} ${pay_rate}${
    contract_rate_type === RATE_TYPE.hourly ? '/hr' : ''
  }`;

  const proposed_roles_display = proposed_roles.map((rId) =>
    roles.find((r) => r.id === rId)
  );

  const handleAcceptClick = () => {
    setConfirm({
      isOpen: true,
      title: 'Confirmation',
      actionHandler: () => handleConfirm(true),
      content: 'Are you sure you want to accept this contract?',
    });
    setShowDetails(false);
  };

  const handleDetailsClick = () => setShowDetails(true);
  const handleDeclineClick = () => {
    setConfirm({
      isOpen: true,
      title: 'Confirmation',
      actionHandler: () => handleConfirm(false),
      content: 'Are you sure you want to decline this contract?',
    });
    setShowDetails(false);
  };

  const title = scopeTitle;
  const action = (
    <Typography
      className={classes.contractInviteDate}
      variant="caption"
      color="initial"
    >
      {(invite_date && defaultDateTimeZone(invite_date, 'date')) || ''}
    </Typography>
  );
  const avatar = {
    first_name: first_name,
    last_name: last_name,
    url: amera_avatar_url,
  };

  const subTitle = (
    <Typography variant="body2">
      {formatMessage(messages.invitedBy)}: <b>{inviterName}</b>
    </Typography>
  );

  const subHeader = (
    <div className={classes.subHeaderWrapper}>
      <Typography variant="body2">
        {formatMessage(messages.startDate)}:{' '}
        <b>{(startDate && defaultDateTimeZone(startDate, 'date')) || ''}</b>
      </Typography>
      <Typography variant="body2">
        {formatMessage(messages.proposedRate)}: <b>{formattedRate}</b>
      </Typography>
    </div>
  );

  const content = (
    <List className={classes.contentSecondary}>
      <ListItem>
        <div className={classes.infoTitle}>
          <label>{formatMessage(messages.projectDetails)}:</label>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.company)}:</label>
          <span>&nbsp;{company_name}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.projectOwner)}:</label>
          <span>&nbsp;{displayableFullName(project_owner, false)}</span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label className={classes.contractRolesLabel}>
            {formatMessage(messages.yourRoles)}:
          </label>
          <span>
            &nbsp;
            {proposed_roles_display.map(
              (role) =>
                role && (
                  <Chip
                    className={classes.rolesChip}
                    size="small"
                    label={role.name}
                  />
                )
            )}
          </span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.info}>
          <label>{formatMessage(messages.estimatedEndDate)}:</label>
          {/* <span>&nbsp;{format(estimatedEndDate, 'LL/dd/yyyy')}</span> */}
          <span>
            &nbsp;
            {(estimatedEndDate &&
              defaultDateTimeZone(estimatedEndDate, 'date')) ||
              ''}
          </span>
        </div>
      </ListItem>
      <ListItem>
        <div className={classes.contractContent}>
          <Typography className={classes.infoTitle}>{headerLine}:</Typography>
          <Box className={classes.contractContentBox}>
            <Typography variant="body2">
              {parse(unescape(scopeDescription))}
            </Typography>
          </Box>
        </div>
      </ListItem>
    </List>
  );

  const buttons = (
    <div className={classes.contractCardActions}>
      <CardButton
        variant="cancel"
        text={formatMessage(messages.decline)}
        onClick={handleDeclineClick}
      />
      <CardButton
        variant="middle"
        text={formatMessage(messages.details)}
        onClick={handleDetailsClick}
      />
      <CardButton
        startIcon={<CheckCircleIcon />}
        variant="ok"
        text={formatMessage(messages.accept)}
        onClick={handleAcceptClick}
      />
    </div>
  );

  return (
    <>
      <Card
        title={title}
        action={action}
        avatar={avatar}
        subTitle={subTitle}
        subHeader={subHeader}
        content={content}
        buttons={buttons}
      />

      {!!confirm && confirm?.isOpen && (
        <ConfirmDialog
          open={confirm.isOpen}
          title={confirm.title}
          onOk={confirm.actionHandler}
          content={confirm.content}
          onCancel={() => setConfirm((ps) => ({ ...ps, isOpen: false }))}
        />
      )}

      {isShowDetails && (
        <ProjectInviteModal
          isOpen={isShowDetails}
          onClose={() => setShowDetails(false)}
          data={{
            startDate,
            estimatedEndDate,
            company_name,
            inviterName,
            project_title,
            project_owner,
            proposed_roles,
            formattedRate,
            scopeDescription,
          }}
          onAcceptClick={handleAcceptClick}
          onDeclineClick={handleDeclineClick}
        />
      )}
    </>
  );
};

export default JobCard;
