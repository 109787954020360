import React from 'react';
import { Grid, Button } from '@material-ui/core';

const EditModeButtons = (props) => {
  const {
    handleExit,
    handleSave,
    isSaveDisabled,
    primaryBtnLabel = 'save',
  } = props;
  return (
    <div>
      <Grid container style={{ padding: '40px', textAlign: 'right' }}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleExit}
            style={{ marginRight: '10px' }}
          >
            Exit
          </Button>

          <Button
            disabled={isSaveDisabled}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {primaryBtnLabel}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditModeButtons;
