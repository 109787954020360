import React, { useState, useEffect } from 'react';
import styles from '../ProjectDetails/ProjectDetails.module.scss';
import EditTaskModal from './EditTaskModal';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MaterialAvatar from '../../../../components/MaterialAvatar';
import { ProjectsApi } from '../../../../redux/actions/project';
import { useDispatch, useSelector } from 'react-redux';

import { defaultDateTimeZone } from '../../../../utils/calendar';
import { format } from 'date-fns';

const ViewTaskSidePane = ({
  projectId,
  element_id,
  state,
  handleClose,
  members,
}) => {
  const {
    parent_id,
    title: task_title,
    description: task_description,
    project_member_id: member_id,
    element_time: time_tracking,
    element_notes: comments,
    status_history: element_status,
    est_hours,
  } = useSelector((state) => {
    let project = state.project.projects.filter(
      (p) => p.project_id === +projectId
    )[0];
    let task = project.project_elements.filter(
      (pe) => pe.project_element_id === element_id
    )[0];
    return task;
  });

  const dispatch = useDispatch();

  const [expanded1, setExpanded1] = React.useState(true);
  const handleChange1 = (panel) => (event, isExpanded1) => {
    setExpanded1(isExpanded1 ? panel : false);
  };

  const [expanded2, setExpanded2] = React.useState(true);
  const handleChange2 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const handleOpenEditTask = () => {
    setOpen(true);
  };
  const handleCloseEditTask = () => {
    setOpen(false);
  };

  const [summary, setSummary] = useState('');
  const [time, setTime] = useState('');
  const formattedTime = moment(time, 'HH:mm').format('hh:mm:ss');
  const formTime = {
    element_id: element_id,
    element_summary: summary,
    element_time: formattedTime,
  };
  const submitHandlerTime = () => {
    dispatch(ProjectsApi.postTime(formTime));
    setSummary('');
    setTime('');
  };

  const [note, setNote] = useState('');
  const formNote = {
    element_id: element_id,
    element_note: note,
  };
  const submitHandlerNote = () => {
    dispatch(ProjectsApi.postNote(formNote));
    setNote('');
  };

  const handleSubmit = (form, element_id) => {
    dispatch(ProjectsApi.updateProjectElement(form, element_id));
    setOpen(false);
  };

  const lastItem = element_status && element_status[element_status.length - 1];
  const status = lastItem && lastItem.element_status;
  const mark = status === 'complete' ? 'in progress' : 'complete';

  const markComplete = () => {
    // the respone for "|| null" when est_hours?.split(':')[0] evaluate to 0 the api fails with 500
    const estHours = est_hours && (+est_hours?.split(':')[0] || null);
    const formCompleted = {
      project_id: projectId,
      parent_id: parent_id,
      element_type: 'task',
      title: task_title,
      description: task_description,
      est_hours: estHours,
      element_status: mark,
      contract_id: member_id,
    };
    dispatch(ProjectsApi.updateProjectElement(formCompleted, element_id));
  };

  // console.log('element =====>', element_status && element_status);
  // console.log('last =====>', lastItem);
  // console.log('stat =====>', status);
  // console.log('mark =====>', mark);

  let tracking = time_tracking && time_tracking;
  //console.log('tracking', tracking, time_tracking);
  const getTime =
    tracking &&
    tracking.map((track) => {
      const strTime = track.element_time.substr(0, 5);
      return moment(strTime, 'HH.mm').format('hh.mm');
    });

  const totalTime =
    getTime &&
    getTime.reduce(
      (prevValue, currentValue) => prevValue + Number(currentValue),
      0
    );
  const convStr = String(totalTime);
  const finalizeTime = convStr.replace('.', ':');

  useEffect(() => {
    dispatch(ProjectsApi.getProjects());
  }, [dispatch]);

  return (
    <>
      <Drawer anchor="right" open={state}>
        <div className={styles.sidePanel}>
          <div className={styles.sidePanelTop}>
            <div onClick={handleClose}>
              <IconButton
                className={styles.sidePanelTopButton}
                aria-label="close"
              >
                <ArrowForwardIcon />
              </IconButton>
            </div>
            <div className={styles.sidePanelTopHeading}>
              <div>
                <h6>Task: {task_title}</h6>
              </div>
              <div>
                <Button
                  onClick={handleOpenEditTask}
                  variant="contained"
                  color="primary"
                >
                  EDIT TASK
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.middle}>
            <Grid container>
              <Grid item xs={8}>
                <div className={styles.gridLeft}>
                  <section className={styles.gridSection}>
                    <strong className={styles.gridStrong}>Assigned to: </strong>
                    {members.map((member, index) => (
                      <div key={index} value={index}>
                        {member.project_member_id === member_id && (
                          <p>{member.first_name}</p>
                        )}
                      </div>
                    ))}
                  </section>
                  <section className={styles.gridSection}>
                    <strong className={styles.gridStrong}>
                      Estimated Hours:{' '}
                    </strong>
                    <p>
                      {est_hours ? (
                        <span>{est_hours.substr(0, 5)} Hrs</span>
                      ) : (
                        <span>00:00 Hrs</span>
                      )}
                    </p>
                  </section>
                </div>
              </Grid>
              <Grid item xs={4}>
                <section className={styles.markButton}>
                  <Button
                    onClick={markComplete}
                    variant="outlined"
                    color="primary"
                  >
                    {status === 'complete' ? (
                      <span>Completed</span>
                    ) : (
                      <span>Mark Complete</span>
                    )}
                  </Button>
                </section>
              </Grid>
            </Grid>
            <hr />
            <Grid item xs={12}>
              <div className={styles.gridRight}>
                <p>{task_description}</p>
              </div>
            </Grid>
            <hr />
            <div>
              <Accordion
                className={styles.accor}
                expanded={expanded1}
                onChange={handleChange1('panel1')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={styles.accorHeading}>
                    Time Tracking
                  </Typography>
                </AccordionSummary>
                <hr className={styles.timeTrackerHr1} />
                <div className={styles.accorDetails}>
                  {time_tracking ? (
                    time_tracking.map((track, index) => (
                      <div id={index} key={index}>
                        <Grid container spacing={3}>
                          <Grid item xs={7}>
                            <strong>{track.element_summary}</strong>
                          </Grid>
                          <Grid item xs={2}>
                            {track.element_time.substr(0, 5)} Hrs
                          </Grid>
                          <Grid item xs={3}>
                            <p>
                              {/* {moment(track.create_date).format(
                                'hh:mm A MMM DD'
                              )} */}
                              {track.create_date &&
                                format(
                                  defaultDateTimeZone(
                                    track.create_date,
                                    '',
                                    false
                                  ),
                                  'hh:mm a MMM dd'
                                )}
                            </p>
                          </Grid>
                        </Grid>
                        <hr className={styles.timeTrackerHr} />
                      </div>
                    ))
                  ) : (
                    <>
                      <p className={styles.timeTrackerPara}>No Elements</p>
                      <hr className={styles.timeTrackerHr} />
                    </>
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={7}>
                      <strong>Total Hours</strong>
                    </Grid>
                    <Grid item xs={2}>
                      {totalTime ? <>{finalizeTime} Hrs</> : <>00 Hrs</>}
                    </Grid>
                  </Grid>
                  <Grid container className={styles.grid1}>
                    <Grid item xs={7}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <TextField
                          placeholder="Summary of work done in this period."
                          type="text"
                          value={summary}
                          onChange={(e) => setSummary(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            className: styles.inputDescript,
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl variant="outlined" style={{ width: '90%' }}>
                        <TextField
                          placeholder="Hours"
                          type="text"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            className: styles.inputDescript,
                          }}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        onClick={submitHandlerTime}
                        className={styles.inputDescript}
                        variant="contained"
                        color="primary"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Accordion>
            </div>
            <hr />

            <div className={styles.QNADiv}>
              <Accordion
                className={styles.accor}
                expanded={expanded2}
                onChange={handleChange2('panel2')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={styles.accorHeading}>
                    Comments and Questions
                  </Typography>
                </AccordionSummary>
                <hr className={styles.timeTrackerHr1} />

                {comments ? (
                  <>
                    {comments.map((comment, index) => (
                      <div key={index} id={index} className={styles.QNASection}>
                        <div className={styles.QNASection1}>
                          <Grid container>
                            <Grid item xs={5}>
                              {members.map((member, index) => (
                                <div key={index} id={index}>
                                  {comment.create_by ===
                                    member.project_member_id && (
                                    <>
                                      <MaterialAvatar
                                        member_id={member.contact_member_id}
                                        src={member.amera_avatar_url}
                                        classes="task-row"
                                        firstName={member.first_name}
                                        lastName={member.last_name}
                                      />
                                      <strong
                                        className={styles.QNASectionStrong}
                                      >
                                        {member.first_name} {member.last_name}
                                      </strong>
                                    </>
                                  )}
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={3}>
                              <p className={styles.QNASectionPara1}>
                                {/* {moment(comment.create_date).format(
                                  'hh:mm A MMM DD'
                                )} */}
                                {comment.create_date &&
                                  format(
                                    defaultDateTimeZone(
                                      comment.create_date,
                                      '',
                                      false
                                    ),
                                    'hh:mm a MMM dd'
                                  )}
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                        <p className={styles.QNASectionPara2}>
                          {comment.element_note}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <h6 className={styles.QNASectionPara2}>No Comments</h6>
                )}
              </Accordion>
            </div>

            <Grid item xs={12}>
              <FormControl variant="outlined" className={styles.field}>
                <TextareaAutosize
                  className={styles.inputDescription}
                  type="text"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  rowsMin={5}
                  placeholder="Add a Comment"
                />
              </FormControl>
            </Grid>

            <Button
              onClick={submitHandlerNote}
              className={styles.button}
              variant="contained"
              color="primary"
            >
              Add Comment
            </Button>
          </div>
        </div>
      </Drawer>

      <div>
        <EditTaskModal
          type="task"
          project_id={projectId}
          parent_id={parent_id}
          element_id={element_id}
          member_id={member_id}
          open={open}
          handleClose={handleCloseEditTask}
          name={task_title}
          text={task_description}
          members={members}
          preEst_hours={est_hours}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default ViewTaskSidePane;
