import React from 'react';
import AddressLine from './AddressLine';

const MemberLocationPreview = (props) => {
  const { data } = props;

  return (
    <div>
      <AddressLine data={data} />
    </div>
  );
};

export default MemberLocationPreview;
