import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import clsx from 'clsx';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import MaterialAvatar from '../../../../../components/MaterialAvatar';
import {
  getAvatarGroupStyles,
  getMembersNamesFromIds,
} from '../../../../../utils/email';
import { defaultDateTimeZone } from '../../../../../utils/calendar';
import { useMailListStyles } from './MailListStyles';

const MailPreview = ({
  email,
  section,
  selectedEmail,
  isOwnEmail,
  selectedXref,
}) => {
  const classes = useMailListStyles();
  const selected = selectedEmail ? selectedEmail.mail_id : selectedEmail;
  const receivers = email.receivers || {
    amera: [],
  };

  const iconsBySection = (section) => {
    switch (section) {
      case 'Starred':
        return (
          <>
            <div aria-label="star-icon" className={classes.greenButton}>
              <SVG name="mailStarred" />
            </div>
          </>
        );
      case 'Trash':
        return (
          <>
            <div aria-label="trash-icon" className={classes.greenButton}>
              <SVG name="mailTrash" />
            </div>
          </>
        );
      case 'Archive':
        return (
          <>
            <div />
            <div aria-label="archive-icon" className={classes.greenButton}>
              <SVG name="mailArchive" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      className={clsx(classes.root, {
        [classes.unread]: !selected !== email.mail_id && !email.read,
        [classes.normal]: !selected !== email.mail_id && email.read,
        [classes.selected]:
          selected &&
          selected === email.mail_id &&
          (selectedXref ? selectedXref.indexOf(email.xref_id) !== -1 : true),
      })}
    >
      <CardHeader
        avatar={
          isOwnEmail ? (
            <AvatarGroup max={2} className={classes.customAvatarGroup}>
              {receivers.amera.map((el, index) => (
                <MaterialAvatar
                  key={`preview-avatar-${index}`}
                  classes={'email-preview'}
                  style={getAvatarGroupStyles(index)}
                  src={email.member_details[el].amera_avatar_url}
                  alt={`${email.member_details[el].first_name} ${email.member_details[el].last_name}`}
                  firstName={email.member_details[el].first_name}
                  lastName={email.member_details[el].last_name}
                />
              ))}
            </AvatarGroup>
          ) : (
            <MaterialAvatar
              classes={'email-preview'}
              src={email.profile_url}
              alt={`${email.first_name} ${email.last_name}`}
              firstName={email.first_name}
              lastName={email.last_name}
            />
          )
        }
        title={
          <span className={classes.sender}>
            {isOwnEmail
              ? getMembersNamesFromIds(receivers.amera, email.member_details)
              : `${email.first_name} ${email.last_name}`}
          </span>
        }
        subheader={<div className={classes.subject}>{email.subject}</div>}
      />
      <CardContent className={classes.content}>
        <div className={classes.preview}>{email.noStyleBody}</div>
      </CardContent>
      <div className={classes.footer}>
        <span className={classes.previewTime}>
          {(email.time && defaultDateTimeZone(email.time)) || ''}
        </span>
        {iconsBySection(section)}
      </div>
    </Card>
  );
};

export default MailPreview;
