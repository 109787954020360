import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import VideocamIcon from '@material-ui/icons/Videocam';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getThumbnails } from 'video-metadata-thumbnails';

import CustomComposeEditor from '../../pages/Email/SharedComponents/Compose/CustomComposeEditor';
import AmeraAccordion from '../AmeraAccordion/AmeraAccordion';
import { ProgressBar } from '../ProgressBar/index';
import { closeDrawer } from '../../redux/actions/drawer';
import RecordVideoModal from '../../components/MediaStreaming/RecordVideoModal';
import {
  uploadVideo,
  uploadContents,
  editVideoInfo,
} from '../../redux/actions/stream';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    alignItems: 'center',
    '& h3': {
      fontSize: 20,
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
  },
  name: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& button': {
      textTransform: 'capitalize',
      marginRight: theme.spacing(2),
    },
  },
  format: {
    marginTop: theme.spacing(1),
  },
  formatError: {
    color: '#dd1e42',
  },
  upload: {
    height: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
    '& label': {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
    '& input': {
      display: 'none',
    },
    '& span': {
      textTransform: 'capitalize',
    },
  },
  selectFile: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& video': {
      width: '100%',
      height: 300,
      marginBottom: 20,
      outline: 'none',
    },
  },
  uploadButton: {
    '& input': {
      display: 'none',
    },
  },
  colorWhite: {
    '& svg': {
      color: '#FFFFFF',
    },
  },
}));

const allowVideoFormat = ['video/mp4', 'video/webm'];

const Broadcast = (props) => {
  const { uploadContent } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  let { streamCategories, streamTypes } = useSelector((state) => ({
    streamCategories: state.stream.streamCategories,
    streamTypes: state.stream.streamTypes,
  }));

  if (streamTypes && streamTypes.length) {
    streamTypes = streamTypes.filter((type) => type.name !== 'All');
  }

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressShow, setUploadProgressShow] = useState(false);
  const videoRef = useRef(null);
  const [videoData, setVideoData] = useState(null);
  const [showRecordVideoModal, setShowRecordVideoModal] = useState(false);

  const [contents, setContents] = React.useState({
    title: uploadContent.isEdit
      ? uploadContent.data
        ? uploadContent.data.title
        : ''
      : '',
    description: uploadContent.isEdit
      ? uploadContent.data
        ? uploadContent.data.description
        : ''
      : '',
    category: uploadContent.isEdit
      ? uploadContent.data
        ? uploadContent.data.category
        : 1
      : streamCategories && streamCategories.length
      ? streamCategories[0].id
      : 1,
    type: uploadContent.isEdit
      ? uploadContent.data && uploadContent.data.type.length
        ? uploadContent.data.type[0]
        : 1
      : streamTypes && streamTypes.length
      ? streamTypes[0].id
      : 1,
  });
  const [videoInfo, setVideoInfo] = React.useState({
    video: null,
    thumbnail: null,
  });
  const [errors, setErrors] = React.useState({
    video: false,
    title: false,
    category: false,
    type: false,
  });

  useEffect(() => {
    if (videoRef && videoRef.current && videoData) {
      videoRef.current.srcObject = null;
      videoRef.current.src = window.URL.createObjectURL(videoData);
      videoRef.current.muted = false;
    }
  }, [videoRef, videoData]);

  const onChangeTitle = (e) => {
    const title = e.target.value;

    if (title) {
      setErrors({
        ...errors,
        title: false,
      });
    }

    setContents({
      ...contents,
      title: title,
    });
  };

  const onChangeDescription = (value) => {
    setContents({
      ...contents,
      description: value,
    });
  };

  const onChangeType = (e) => {
    setContents({
      ...contents,
      type: e.target.value,
    });
  };

  const onChangeCategory = (e) => {
    setContents({
      ...contents,
      category: e.target.value,
    });
  };

  const handleClose = () => {
    dispatch(closeDrawer());
  };

  const openRecordModal = () => {
    setShowRecordVideoModal(true);
  };

  const closeRecordModal = () => {
    setShowRecordVideoModal(false);
  };

  const handleUpload = async (file) => {
    if (!file || !allowVideoFormat.includes(file.type)) {
      return false;
    }

    let formData = new FormData();

    //thumbnail
    const thumbnails = await getThumbnails(file, {
      start: 1,
      end: 1,
      quality: 1,
    });
    if (!thumbnails || !thumbnails.length) return;
    const blobData = thumbnails[0];
    const thumbnail_file = new File([blobData.blob], `thumbnail.jpg`, {
      type: 'image/jpeg',
    });

    const records = new File([file], `record.webm`, {
      type: 'video/webm',
    });

    formData.set('video', records);
    formData.set('thumbnail', thumbnail_file);

    setVideoData(records);
    closeRecordModal();

    // upload video
    const upload_data = await dispatch(
      uploadVideo(formData, setUploadProgress, setUploadProgressShow)
    );

    if (!upload_data) return;

    setErrors({
      ...errors,
      video: false,
    });

    setVideoInfo({
      video: upload_data.video || null,
      thumbnail: upload_data.thumbnail || null,
    });
  };

  const handleSave = async () => {
    const data = { ...contents, ...videoInfo };

    //validate
    let isError = false;
    for (let key in errors) {
      if (uploadContent.isEdit && key === 'video') continue;

      if (data[key]) {
        setErrors({
          ...errors,
          [key]: false,
        });
      } else {
        setErrors({
          ...errors,
          [key]: true,
        });

        isError = true;
        break;
      }
    }

    if (isError) return;

    let formData = new FormData();

    Object.keys(data).map((key) => {
      formData.set(key, data[key]);
      return true;
    });

    if (uploadContent.isEdit) {
      if (uploadContent.data && uploadContent.data.id)
        await dispatch(editVideoInfo(formData, uploadContent.data.id));
    } else {
      // get duration streamed records
      while (videoRef.current.duration === Infinity) {
        await new Promise((r) => setTimeout(r, 1000));
        videoRef.current.currentTime = 10000000 * Math.random();
      }

      //duration
      formData.set('duration', videoRef.current.duration);
      //upload contents
      await dispatch(uploadContents(formData, setUploadProgress));
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>Broadcast</h3>
      </div>
      <div className={classes.content}>
        {!uploadContent.isEdit && (
          <AmeraAccordion label="Upload">
            <div className={classes.upload}>
              <div className={classes.selectFile}>
                {videoData && (
                  <video
                    id="videoContainer"
                    className={classes.video}
                    ref={videoRef}
                    controls
                  ></video>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.uploadButton}
                  onClick={openRecordModal}
                  startIcon={
                    uploadProgressShow ? (
                      <CircularProgress
                        className={classes.colorWhite}
                        size={20}
                      />
                    ) : (
                      <VideocamIcon />
                    )
                  }
                  name="upload-video-button"
                >
                  {uploadProgressShow ? 'Uploading ...' : 'Record'}
                </Button>
                {errors.video && (
                  <span className={classes.formatError}>
                    {uploadProgressShow
                      ? 'Please wait for uploading'
                      : 'Please record'}
                  </span>
                )}
              </div>
            </div>
            {uploadProgressShow && (
              <div>
                <ProgressBar value={uploadProgress} />
              </div>
            )}
          </AmeraAccordion>
        )}
        <AmeraAccordion label="Title">
          <div className={classes.name}>
            <TextField
              label="Video Title"
              fullWidth
              value={contents.title}
              placeholder={'title'}
              onChange={onChangeTitle}
              error={errors.title}
              helperText={errors.title ? 'Please input video title' : ''}
            />
          </div>
        </AmeraAccordion>
        <AmeraAccordion label="Category">
          <FormControl
            variant="outlined"
            margin="dense"
            error={errors.category}
          >
            <Select
              native
              value={contents.category}
              onChange={onChangeCategory}
              inputProps={{
                name: 'streaming-category',
                id: 'streaming-category',
              }}
            >
              {streamCategories
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
                .map((type) => {
                  return (
                    <option
                      aria-label={type.name}
                      value={type.id}
                      key={type.id}
                    >
                      {type.name}
                    </option>
                  );
                })}
            </Select>
            {errors.category && (
              <FormHelperText>Please select video category</FormHelperText>
            )}
          </FormControl>
        </AmeraAccordion>
        <AmeraAccordion label="Type">
          <FormControl variant="outlined" margin="dense" error={errors.type}>
            <Select
              native
              value={contents.type}
              onChange={onChangeType}
              inputProps={{
                name: 'streaming-type',
                id: 'streaming-type',
              }}
            >
              {streamTypes
                .sort((a, b) =>
                  a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                )
                .map((type) => {
                  return (
                    <option
                      aria-label={type.name}
                      value={type.id}
                      key={type.id}
                    >
                      {type.name}
                    </option>
                  );
                })}
            </Select>
            {errors.type && (
              <FormHelperText>Please select video type</FormHelperText>
            )}
          </FormControl>
        </AmeraAccordion>
        <AmeraAccordion label="Description">
          <div className={classes.description}>
            <CustomComposeEditor
              multiline
              rowsMax={10}
              value={contents.description}
              handleChangebd={onChangeDescription}
              placeholder={'description'}
            />
          </div>
        </AmeraAccordion>
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <RecordVideoModal
        open={showRecordVideoModal}
        onClose={closeRecordModal}
        onSend={handleUpload}
      />
    </div>
  );
};

export default Broadcast;
