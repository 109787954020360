import React from 'react';
import Layout from '../Sections/Layout';
import ProjectsDashboard from './ProjectsDashboard';
import { Switch, Route, Redirect } from 'react-router-dom';
import CreateProject from './CreateProject/CreateProject';
import TeamSelect from './CreateProject/TeamSelect';
import SetProjectSecurity from './CreateProject/SetProjectSecurity';
import AssignTeamRoles from './CreateProject/AssignTeamRoles';
import ProjectDetails from './ProjectDetails//ProjectDetails/ProjectDetails';

const Project = (props) => {
  return (
    <Layout {...props}>
      <Switch>
        <Redirect
          from={`${process.env.PUBLIC_URL}/projects`}
          to={`${process.env.PUBLIC_URL}/projects/my-projects`}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/my-projects`}
          component={ProjectsDashboard}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/new-project`}
          component={CreateProject}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/project-definition/:projectId`}
          component={CreateProject}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/select-team/:projectId`}
          component={TeamSelect}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/assign-team-roles/:projectId`}
          component={AssignTeamRoles}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/set-security/:projectId`}
          component={SetProjectSecurity}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/projects/project-details/:projectId`}
          component={ProjectDetails}
        />
      </Switch>
    </Layout>
  );
};

export default Project;
