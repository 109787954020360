export const COMPANY_ROLES = {
  owner: 'owner',
  admin: 'administrator',
  standard: 'standard',
};

export const DEPARTMENT_ROLES = {
  standard: 'standard',
  leader: 'leader',
};

export const getManagement = (members, role) =>
  !!members && members?.find((m) => m.company_role === role);
