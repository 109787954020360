export const ADD_COMPANY_MEMBER = 'ADD_COMPANY_MEMBER';
export const REMOVE_COMPANY_MEMBER = 'REMOVE_COMPANY_MEMBER';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const LOAD_COMPANIES_START = 'LOAD_COMPANIES_START';
export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_COMPANIES_FAILED = 'LOAD_COMPANIES_FAILED';
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';
export const LOAD_UNREGISTERED_COMPANIES = 'LOAD_UNREGISTERED_COMPANIES';
export const UPDATE_UNREGISTERED_COMPANY = 'UPDATE_UNREGISTERED_COMPANY';
export const DELETE_UNREGISTERED_COMPANY = 'DELETE_UNREGISTERED_COMPANY';
export const CREATE_COMPANY_FROM_NAME = 'CREATE_COMPANY_FROM_NAME';

export const SET_COMPANY_ALERT = 'SET_COMPANY_ALERT';

export const UPDATE_COMPANY_PIC_START = 'UPDATE_COMPANY_PIC_START';
export const UPDATE_COMPANY_PIC_SUCCESS = 'UPDATE_COMPANY_PIC_SUCCESS';
export const UPDATE_COMPANY_PIC_ERROR = 'UPDATE_COMPANY_PIC_ERROR';

export const UPDATE_COMPANY_DETAILS_START = 'UPDATE_COMPANY_DETAILS_START';
export const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS';
export const UPDATE_COMPANY_DETAILS_ERROR = 'UPDATE_COMPANY_DETAILS_ERROR';

export const UPDATE_COMPANY_MEMBERS_START = 'UPDATE_COMPANY_MEMBERS_START';
export const UPDATE_COMPANY_MEMBERS_SUCCESS = 'UPDATE_COMPANY_MEMBERS_SUCCESS';
export const UPDATE_COMPANY_MEMBERS_ERROR = 'UPDATE_COMPANY_MEMBERS_ERROR';
