import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Avatar from '../Avatar'
import MaterialAvatar from '../MaterialAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
  },
  avatar: {
    width: '46px',
    height: '46px',
    marginRight: theme.spacing(3),
  },
  name: {
    color: '#0A1629',
    fontSize: '16px',
    fontWeight: 700,
    marginRight: theme.spacing(3),
  },
  date: {
    color: '#7D8592',
    fontSize: '14px',
    fontWeight: 600,
  },
  biography: {
    marginTop: theme.spacing(1),
  },
}));

const TopicWriterPreview = (props) => {
  const {
    member_id,
    avatar_url,
    firstName,
    lastName,
    biography,
    create_date,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MaterialAvatar
        src={avatar_url}
        member_id={member_id}
        firstName={firstName}
        lastName={lastName}
        classes={classes.avatar}
        variant="circular"
      />
      <div>
        <p>
          <span className={classes.name}>
            {firstName} {lastName}
          </span>
          <span className={classes.date}>{create_date}</span>
        </p>
        <p className={classes.biography}>{biography}</p>
      </div>
    </div>
  );
};

export default TopicWriterPreview;
