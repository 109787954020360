import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';

import {
  setChatConversationID,
  setChatUnreadMessagesCount,
} from '../../../../../../redux/actions/chat';
import { updateContactProperty } from '../../../../../../redux/actions/contact';
import Conversation from './Conversation';
import ConversationSkeleton from './Skeleton';
import { enableE2EEncryption } from '../../../../../../redux/actions/security';

function Conversations(props) {
  const dispatch = useDispatch();
  const { chatConversations, selectedChatID, loadingConversations } = props;
  let conversationItems = null;
  const securityContactId = useSelector((store) => store.security.contact_id);

  const loadConversation = (contactData) => {
    const conversationID = parseInt(contactData.contact_member_id);
    const conversation = chatConversations?.find(
      (c) => parseInt(c.chatID) === conversationID
    );
    if (conversationID !== securityContactId) {
      dispatch(enableE2EEncryption(false));
    }
    dispatch(setChatConversationID(conversationID.toString()));

    if (conversation.unreadCount > 0) {
      const unreadCount = 0;
      const payload = {
        last_message_id:
          conversation?.messages?.[conversation.messages.length - 1]?.id,
        id: contactData.id,
        name: 'last_message_id',
      };
      dispatch(updateContactProperty(payload, false));
      dispatch(setChatUnreadMessagesCount(conversationID, unreadCount));
    }
  };

  React.useEffect(() => {
    return () => dispatch(setChatConversationID(''));
  }, [dispatch]);

  if (loadingConversations) {
    conversationItems = [...Array(10).keys()].map((num) => (
      <ConversationSkeleton key={num} num={num} />
    ));
  } else {
    conversationItems =
      chatConversations?.length > 0 &&
      chatConversations.map((conversation) => (
        <Conversation
          key={conversation.chatID}
          conversation={conversation}
          selectedChatID={selectedChatID}
          loadConversation={loadConversation}
        />
      ));
  }

  return <List className="conversation-list">{conversationItems}</List>;
}

const mapStateToProps = (state) => ({
  chatConversations: state.chat.chatConversations,
  selectedChatID: state.chat.selectedChatID,
  loadingConversations: state.chat.loadingConversations,
});

export default connect(mapStateToProps)(Conversations);
