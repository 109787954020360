import React from 'react';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import SVG from '../Icons/SVG';

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      background: '#F3F6FA',
    },
    closeModal: {
      backgroundColor: theme.palette.common.white,
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      color: '#848ac1',
    },
  });

const DialogTitleWithNoPadding = withStyles((theme) => ({
  root: {
    padding: '0 !important',
    background: '#F3F6FA',
  },
}))(MuiDialogTitle);

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    disabled,
    customTitle,
    noPadding,
    ...other
  } = props;

  const content = () => {
    return customTitle ? (
      <>{children}</>
    ) : (
      <div className="modal-header-container">
        <div className="header-left">
          <SVG name="logo" />
          <Typography variant="h6" className="header_title">
            {children}
          </Typography>
        </div>
        <div className="header-right">
          {onClose && (
            <IconButton
              aria-label="close"
              className="close_button"
              onClick={onClose}
              disabled={disabled === true}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  };

  return noPadding ? (
    <DialogTitleWithNoPadding>{content()}</DialogTitleWithNoPadding>
  ) : (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {content()}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogContentWithNoPadding = withStyles((theme) => ({
  root: {
    padding: '0 !important',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    background: '#F3F6FA',
  },
}))(MuiDialogActions);

const DialogActionsWithNoPadding = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '0 !important',
    background: '#F3F6FA',
  },
}))(MuiDialogActions);

const Modal = (props) => {
  const {
    open,
    onClose,
    onEnter,
    disabled,
    title,
    ModalContent,
    ModalAction,
    className,
    maxWidth,
    fullWidth,
    fullScreen = false,
    disableBackdropClick,
    transition,
    transitionDuration,
    transitionProps,
    customTitle,
    noPadding,
    style,
  } = props;
  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        onClose={onClose}
        onEnter={onEnter}
        fullScreen={fullScreen}
        TransitionComponent={transition}
        transitionDuration={transitionDuration}
        TransitionProps={transitionProps}
        style={{ transformOrigin: '0 0 0', ...style }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={fullWidth}
        className={clsx(className, 'default-modal')}
        disableBackdropClick={disableBackdropClick}
        data-html2canvas-ignore
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          disabled={disabled}
          customTitle={customTitle}
          noPadding={noPadding}
        >
          {customTitle ? title() : title}
        </DialogTitle>
        {noPadding ? (
          <>
            <DialogContentWithNoPadding>
              {ModalContent && ModalContent()}
            </DialogContentWithNoPadding>
            <DialogActionsWithNoPadding>
              {ModalAction && ModalAction()}
            </DialogActionsWithNoPadding>
          </>
        ) : (
          <>
            <DialogContent dividers>
              {ModalContent && ModalContent()}
            </DialogContent>
            <DialogActions>{ModalAction && ModalAction()}</DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
  ModalContent: PropTypes.func,
  ModalAction: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableBackdropClick: PropTypes.bool,
  transition: PropTypes.elementType,
  transitionDuration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]),
  transitionProps: PropTypes.object,
  customTitle: PropTypes.bool,
  noPadding: PropTypes.bool,
};

Modal.defaultProps = {
  open: false,
  onClose: null,
  title: 'Modal Title',
  ModalContent: null,
  ModalAction: null,
  className: '',
  maxWidth: 'sm',
  fullWidth: false,
  transition: Fade,
  customTitle: false,
  noPadding: false,
};

export default Modal;
