import React from 'react';
import TextField from '@material-ui/core/TextField';

const PINInput = (props) => {
  const {
    onPINChange,
    PIN,
    errors,
    isConfirm,
    hidePinPicture,
    disabled,
    variant,
  } = props;

  let cls;
  if (PIN.length === 0) {
    cls = 'disabled';
  } else if (PIN.length < 6 || PIN.length > 12) {
    cls = 'error';
  } else if (PIN.length >= 6 && PIN.length <= 12) {
    cls = 'success';
  }
  return (
    <>
      {!hidePinPicture && (
        <TextField
          id={isConfirm ? 'outlined-confirm-pin' : 'outlined-pin'}
          fullWidth
          autoFocus={!isConfirm}
          label={
            isConfirm
              ? 'Verify Personal Identification Number'
              : 'Personal Identification Number'
          }
          type="number"
          variant={variant}
          required
          name={isConfirm ? 'confirmPin' : 'pin'}
          placeholder={isConfirm ? 'Verify PIN (Min: 6, Max:12)' : '######'}
          className="register_form_input"
          onChange={onPINChange}
          onPaste={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          value={PIN || ''}
          disabled={disabled}
          error={errors ? true : false}
          helperText={
            <span className="helper-container">
              <span>
                {errors ? 'PINs dont match' : 'Min 6 Digits - Max 12 Digits'}
              </span>
              <span className={`pin-counter ${cls}`}>{PIN.length}/12</span>
            </span>
          }
        />
      )}
    </>
  );
};

PINInput.defaultProps = {
  variant: 'outlined',
};

export default PINInput;
