import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TextField, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddressInputForm from '../../components/AddressInputForm';
import { ADDRESS_TYPES } from '../../utils/calendar';
import { destructureAddress, MAP_VENDORS } from '../../utils/country';
import Modal from '../../components/Modal/modal';
import {
  putPaymentSetting,
  getMemberSetting,
} from '../../redux/actions/member';
import { validateLocation } from '../../utils/validator/location';
import useGoogleMap from '../../hooks/useGoogleMap';

const shortid = require('shortid');

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
}));

const Payments = (props) => {
  const { open, onClose, billing_location, home_location, dispatch } = props;
  const title = 'Payment Settings';
  const classes = useStyles();

  const [isGoogleBilling, setGoogleBilling] = useState(true);
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [cardCVC, setCardCVC] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [copyAddress, setCopyAddress] = useState(false);
  const [homeLocation, setHomeLocation] = useState(home_location);

  const [homeLocationError, setHomeLocationError] = useState({});
  const [billingLocation, setBillingLocation] = useState(billing_location);

  const [billingLocationError, setBillingLocationError] = useState({});

  const { getPlaceDetailsFromPlaceId, places } = useGoogleMap();

  const handleCopyAddress = (event) => {
    event.persist();
    setCopyAddress(event.target.checked);
    if (event.target.checked) {
      setHomeLocation((ps) => ({
        ...billingLocation,
        id: ps.id,
        location_type: ps.location_type,
      }));
    }
  };

  const handleSubmit = () => {
    const hError = validateLocation(homeLocation);
    const bError = validateLocation(billingLocation);
    if (Object.keys(hError).length === 0 && Object.keys(bError).length === 0) {
      console.debug('Ok, can submit');
      setHomeLocationError(null);
      setBillingLocationError(null);
      const formData = {
        member_location: [billingLocation, homeLocation],
      };
      console.debug(formData);

      dispatch(putPaymentSetting(formData));
    } else {
      console.debug('Had errors', hError, bError);
      setHomeLocationError(hError);
      setBillingLocationError(bError);
    }
  };

  const handleCardNumber = (event) => {
    event.persist();
    let length = event.target.value.length;
    if (length > 5 * 3 + 4) return;
    if ((length - 4) % 5 === 0 && length < 5 * 3 + 4) {
      setCardNumber(`${event.target.value}-`);
    } else {
      if (event.target.value[length - 1] === '-')
        setCardNumber(event.target.value.substr(0, length - 1));
      else setCardNumber(event.target.value);
    }
  };

  const handleCardExpiration = (event) => {
    event.persist();
    let length = event.target.value.length;
    if (length > 5) return;
    if (length === 2) {
      setCardExpiration(`${event.target.value}/`);
    } else {
      if (length === 3) setCardExpiration(event.target.value.substr(0, 2));
      else setCardExpiration(event.target.value);
    }
  };

  const handleCardCVC = (event) => {
    event.persist();
    if (event.target.value.length > 3) return;
    setCardCVC(event.target.value);
  };

  const handleCardHolder = (event) => {
    event.persist();
    setCardHolder(event.target.value);
  };

  const handleBillingSuggestionClick = (suggestion) => {
    if (suggestion?.place_id) {
      setGoogleBilling(true);
      getPlaceDetailsFromPlaceId(suggestion.place_id);
    }
  };
  const handleHomeSuggestionClick = (suggestion) => {
    if (suggestion?.place_id) {
      setGoogleBilling(false);
      getPlaceDetailsFromPlaceId(suggestion.place_id);
    }
  };

  useEffect(() => {
    dispatch(getMemberSetting());
  }, [dispatch]);

  useEffect(() => {
    if (!!places && !!places[0]) {
      const firstPlace = places[0];
      const parsed = destructureAddress(firstPlace.address_components);
      const data = {
        street_address_1: parsed?.streetAddress1,
        street_address_2: parsed?.streetAddress2,
        locality: parsed?.locality,
        sub_locality: parsed?.sublocality,
        admin_area_1: parsed?.adminArea1,
        admin_area_2: parsed?.adminArea2,
        postal_code: parsed?.postal,
        place_id: firstPlace?.place_id,
        map_link: firstPlace?.url,
        latitude: firstPlace?.geometry.location.lat(),
        longitude: firstPlace?.geometry.location.lng(),
        map_vendor: MAP_VENDORS.google,
        vendor_formatted_address: firstPlace?.formatted_address,
      };

      isGoogleBilling
        ? setBillingLocation((ps) => ({
            ...data,
            id: ps.id,
            country_code_id: ps.country_code_id,
            location_type: ps.location_type,
            description: ps.description,
          }))
        : setHomeLocation((ps) => ({
            ...data,
            id: ps.id,
            country_code_id: ps.country_code_id,
            location_type: ps.location_type,
            description: ps.description,
          }));
    }
  }, [isGoogleBilling, places]);

  const handleInput = (e, locationType, field) => {
    e.persist();
    const value = e.target.value;
    if (locationType === ADDRESS_TYPES.billing) {
      setBillingLocation((ps) => ({ ...ps, [field]: value }));
    } else if (locationType === ADDRESS_TYPES.home) {
      setHomeLocation((ps) => ({ ...ps, [field]: value }));
    }
  };

  const ModalContent = () => (
    <div className="profile_page-body">
      <div className="profile_page-section">
        <div className="page_section-row flex-column">
          <div className="section-row_label">Credit Card</div>
          <div className="page_section-row-content">
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  placeholder="Cardholder Name"
                  label="Name on card"
                  fullWidth
                  value={cardHolder}
                  onChange={handleCardHolder}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <TextField
                  placeholder="1111-2222-3333-4444"
                  label="Card Number"
                  value={cardNumber}
                  onChange={handleCardNumber}
                  onKeyPress={(event) => {
                    if (event.key < '0' || event.key > '9') {
                      event.preventDefault();
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  placeholder="12/21"
                  label="Expiration"
                  fullWidth
                  value={cardExpiration}
                  onChange={handleCardExpiration}
                  onKeyPress={(event) => {
                    if (event.key < '0' || event.key > '9') {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <TextField
                  placeholder="123"
                  label="CVC"
                  fullWidth
                  value={cardCVC}
                  onChange={handleCardCVC}
                  onKeyPress={(event) => {
                    if (event.key < '0' || event.key > '9') {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="profile_page-section">
        <div className="page_section-row flex-column">
          <div className="section-row_label">Billing Address</div>
        </div>
        <div className="page_section-row-content">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Description"
                value={billingLocation?.description || ''}
                fullWidth
                error={!!billingLocationError?.description}
                helperText={
                  !!billingLocationError?.description
                    ? billingLocationError?.description
                    : ''
                }
                onChange={(e) =>
                  handleInput(e, ADDRESS_TYPES.billing, 'description')
                }
              />
            </Grid>
            <AddressInputForm
              countryCode={billingLocation.country_code_id}
              streetAddress1={billingLocation.street_address_1}
              streetAddress2={billingLocation.street_address_2}
              adminArea1={billingLocation.admin_area_1}
              adminArea2={billingLocation.admin_area_2}
              locality={billingLocation.locality}
              postal={billingLocation.postal_code}
              onStreetAddress2={(e) =>
                handleInput(e, ADDRESS_TYPES.billing, 'street_address_2')
              }
              onLocality={(e) =>
                handleInput(e, ADDRESS_TYPES.billing, 'locality')
              }
              onAdminArea1={(e) =>
                handleInput(e, ADDRESS_TYPES.billing, 'admin_area_1')
              }
              onAdminArea2={(e) =>
                handleInput(e, ADDRESS_TYPES.billing, 'admin_area_2')
              }
              onPostal={(e) =>
                handleInput(e, ADDRESS_TYPES.billing, 'postal_code')
              }
              onCountrySelected={(e) =>
                handleInput(e, ADDRESS_TYPES.billing, 'country_code_id')
              }
              onSuggestionClick={handleBillingSuggestionClick}
              error={billingLocationError}
            />
          </Grid>
        </div>
      </div>
      <div className="profile_page-section">
        <div className="page_section-row flex-column">
          <div className="section-row_label">Home Address</div>
        </div>
        <div className="page_section-row-content">
          <FormControlLabel
            control={
              <Checkbox checked={copyAddress} onChange={handleCopyAddress} />
            }
            label="Copy Billing Address"
          />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Description"
                value={homeLocation.description || ''}
                fullWidth
                error={!!homeLocationError?.description}
                helperText={
                  !!homeLocationError?.description
                    ? homeLocationError?.description
                    : ''
                }
                disabled={copyAddress}
                onChange={(e) =>
                  handleInput(e, ADDRESS_TYPES.home, 'description')
                }
              />
            </Grid>
            <AddressInputForm
              countryCode={homeLocation.country_code_id}
              streetAddress1={homeLocation.street_address_1}
              streetAddress2={homeLocation.street_address_2}
              adminArea1={homeLocation.admin_area_1}
              adminArea2={homeLocation.admin_area_2}
              locality={homeLocation.locality}
              postal={homeLocation.postal_code || ''}
              onStreetAddress2={(e) =>
                handleInput(e, ADDRESS_TYPES.home, 'street_address_2')
              }
              onLocality={(e) => handleInput(e, ADDRESS_TYPES.home, 'locality')}
              onAdminArea1={(e) =>
                handleInput(e, ADDRESS_TYPES.home, 'admin_area_1')
              }
              onAdminArea2={(e) =>
                handleInput(e, ADDRESS_TYPES.home, 'admin_area_2')
              }
              onPostal={(e) =>
                handleInput(e, ADDRESS_TYPES.home, 'postal_code')
              }
              onCountrySelected={(e) =>
                handleInput(e, ADDRESS_TYPES.home, 'country_code_id')
              }
              onSuggestionClick={handleHomeSuggestionClick}
              error={homeLocationError}
              disabled={copyAddress}
            />
          </Grid>
        </div>
      </div>
    </div>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.actionBtn}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  billing_location:
    !!state.member?.memberSetting?.location_information &&
    !!state.member?.memberSetting?.location_information.find(
      (lio) => lio.location_type === ADDRESS_TYPES.billing
    )
      ? state.member?.memberSetting?.location_information.find(
          (lio) => lio.location_type === ADDRESS_TYPES.billing
        )
      : {
          id: shortid.generate(),
          location_type: ADDRESS_TYPES.billing,
          country_code_id: 840,
          description: '',
        },
  home_location:
    !!state.member?.memberSetting?.location_information &&
    !!state.member?.memberSetting?.location_information.find(
      (lio) => lio.location_type === ADDRESS_TYPES.home
    )
      ? state.member?.memberSetting?.location_information.find(
          (lio) => lio.location_type === ADDRESS_TYPES.home
        )
      : {
          id: shortid.generate(),
          location_type: ADDRESS_TYPES.billing,
          country_code_id: 840,
          description: '',
        },
});

export default connect(mapStateToProps)(Payments);
