import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Alert } from 'react-bootstrap';
// import Alert from '@material-ui/lab/Alert';
import Modal from './modal';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';

import { logout } from '../../redux/actions/member';
import { clearSecurity, forceReload } from '../../config/ipc';

// Non Browser Features
import { debugFileDownloadtoMain } from '../../utils/electron';

const Debug = (props) => {
  const { show, close, dispatch } = props;
  const { member } = props;

  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [isLoading] = useState(false);
  const prevState = usePrevious({ isLoading });

  const resetAlert = () => {
    setShowAlert(false);
    setAlertStatus('');
    setAlertVariant('');
    setAlertMessage('');
  };

  const showErrorAlert = (message) => {
    setShowAlert(true);
    setAlertStatus('Error');
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const showSuccessAlert = (message) => {
    setShowAlert(true);
    setAlertStatus('Success');
    setAlertVariant('success');
    setAlertMessage(message);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        resetAlert();
      }, 3000);
    }
  }, [showAlert]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (prevState && isLoading === false && prevState.isLoading === true) {
      // close();
    }
  }, [isLoading, prevState, close, dispatch]);

  const clearPictureAndPin = (e) => {
    resetAlert();
    console.log('clearPictureAndPin: Initialized');
    clearSecurity()
      .then(() => {
        showSuccessAlert(
          'Picture and PIN Cleared, restarting application in 3 seconds'
        );
        setTimeout(() => {
          forceReload();
        }, 3000);
      })
      .catch((e) => {
        showErrorAlert(`Error resetting Picture and PIN: ${e}`);
      });
  };
  const clearSessionState = (e) => {
    console.log('clearSessionState: Initialized');
    (async () => {
      props.logout(member.member_id, member.session_id);
      dispatch({ type: 'APP_RESET' });
    })()
      .then(() => {
        showSuccessAlert(
          'Session State Reset, restarting application in 3 seconds'
        );
        setTimeout(() => {
          forceReload();
        }, 3000);
      })
      .catch((e) => {
        // debugger;
        showErrorAlert(`Error resetting Picture and PIN: ${e}`);
      });
  };
  const handleDownloadClick = (e) => {
    // type = either keyJson // keyPicture // mainLog // rendererLog
    const type = e.currentTarget.id;
    // console.log(type);
    debugFileDownloadtoMain(type);
  };

  const ModalContent = () => (
    <>
      {showAlert ? (
        // Material
        // Material UI to be implemented
        //
        // <Alert variant="filled" severity={alertVariant}>
        //   {alertMessage}
        // </Alert>
        <Alert
          variant={alertVariant}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{alertStatus}</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>
      ) : null}
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => clearPictureAndPin(e)}
            >
              Clear Picture and PIN
            </Button>
          </Grid>
          <Grid item xs={6}>
            This will clear the Picture and PIN set when the application was
            launched. Use carefully and only for testing. Any files encrypted
            with this Picture and PIN, will require you to put in the same
            Picture and PIN again
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => clearSessionState(e)}
            >
              Clear Session State
            </Button>
          </Grid>
          <Grid item xs={6}>
            This will clear the session state within the AMERA Application, and
            force you to log in again. Sometimes this is necessary if you're not
            seeing expected behavior.
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <ButtonGroup
              variant="contained"
              color="primary"
              orientation="vertical"
            >
              <Button id="mainLog" onClick={(e) => handleDownloadClick(e)}>
                Main Log
              </Button>
              <Button id="rendererLog" onClick={(e) => handleDownloadClick(e)}>
                Renderer Log
              </Button>
              <Button id="keyJson" onClick={(e) => handleDownloadClick(e)}>
                Config key
              </Button>
              <Button id="keyPicture" onClick={(e) => handleDownloadClick(e)}>
                Key picture
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={6}>
            Download the logs and config files to a place of your choosing, this
            allows you to send the logs to the AMERA Technical Team for
            debugging purposes
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Modal
      title="AMERA Debug Help"
      open={show}
      onClose={close}
      ModalContent={ModalContent}
      ModalAction={null}
      className="member-invite-modal"
    ></Modal>
  );
};

Debug.propTypes = {
  dispatch: PropTypes.func,
  show: PropTypes.bool,
  close: PropTypes.func,
  member: PropTypes.object,
};

Debug.defaultProps = {
  dispatch: null,
  show: false,
  close: null,
  member: {},
};

const mapStateToProps = (state) => ({
  app: state.app,
  member: state.member.member,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      dispatch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Debug);
