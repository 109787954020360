import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import Carousel from '../../components/Carousel';
import EventCard from '../../components/Dashboard/InviteCards/EventCard';
import MediaMailCard from '../../components/Dashboard/InviteCards/MediaMailCard';
import GroupCard from '../../components/Dashboard/InviteCards/GroupCard';
import ContactCard from '../../components/Dashboard/InviteCards/ContactCard';
import JobCard from '../../components/Dashboard/InviteCards/JobCard';
import EmptyCard from '../../components/Dashboard/EmptyCard';
import VideoEmailModal from '../../components/Modal/VideoEmailModal';

import { readVideoMail } from '../../redux/actions/member';

const CARD_TYPES = {
  market: 'market',
  eventInvite: 'event_invitation',
  mediaMail: 'media_mail',
  groupInvite: 'group_invitation',
  contactInvite: 'contact_invitation',
  contractInvite: 'contract_invitation',
};

const messages = {
  eventTitle: {
    id: 'app.eventInvite.card.title',
    defaultMessage: 'Event Invite',
  },
  contactTitle: {
    id: 'app.contactInvite.card.title',
    defaultMessage: 'Contact Invite',
  },
  groupTitle: {
    id: 'app.groupInvite.card.title',
    defaultMessage: 'Group Invite',
  },
  mediaMailTitle: {
    id: 'app.newMediaMail.card.title',
    defaultMessage: 'New Media Mail',
  },
  accept: {
    id: 'app.labels.accept',
    defaultMessage: 'Accept',
  },
  decline: {
    id: 'app.labels.decline',
    defaultMessage: 'Decline',
  },
  details: {
    id: 'app.labels.details',
    defaultMessage: 'Details',
  },
  viewDetails: {
    id: 'app.labels.viewDetails',
    defaultMessage: 'View Details',
  },
  noMarketingData: {
    id: 'app.noMaretingData.title',
    defaultMessage: 'No Marketing Data',
  },
  contactDetails: {
    id: 'app.contactDetails.title',
    defaultMessage: 'Contact Details',
  },
  groupDetails: {
    id: 'app.groupDetails.title',
    defaultMessage: 'Group Details',
  },
  projectDetails: {
    id: 'app.projectDetails.title',
    defaultMessage: 'Project Details',
  },
  projectDescription: {
    id: 'app.projectDescription.title',
    defaultMessage: 'Project Description',
  },
  groupName: {
    id: 'app.labels.groupName',
    defaultMessage: 'Group Name',
  },
  groupStatus: {
    id: 'app.labels.groupStatus',
    defaultMessage: 'Group Status',
  },
  groupLeader: {
    id: 'app.labels.groupLeader',
    defaultMessage: 'Group Leader',
  },
  groupFiles: {
    id: 'app.labels.groupFiles',
    defaultMessage: 'Group Files',
  },
  groupMembers: {
    id: 'app.labels.groupMembers',
    defaultMessage: 'Group Members',
  },
  groupVideos: {
    id: 'app.labels.groupVideos',
    defaultMessage: 'Group Videos',
  },
  groupCreateDate: {
    id: 'app.labels.groupCreateDate',
    defaultMessage: 'Group Create Date',
  },
  department: {
    id: 'app.labels.department',
    defaultMessage: 'Department',
  },
  jobTitle: {
    id: 'app.labels.jobTitle',
    defaultMessage: 'Job Title',
  },
  address: {
    id: 'app.labels.address',
    defaultMessage: 'Address',
  },
  company: {
    id: 'app.labels.company',
    defaultMessage: 'Company',
  },
  inviteDate: {
    id: 'app.labels.inviteDate',
    defaultMessage: 'Invite Date',
  },
  invitedBy: {
    id: 'app.labels.invitedBy',
    defaultMessage: 'Invited by',
  },
  startDate: {
    id: 'app.labels.startDate',
    defaultMessage: 'Start Date',
  },
  proposedRate: {
    id: 'app.labels.proposedRate',
    defaultMessage: 'Proposed Rate',
  },
  projectOwner: {
    id: 'app.labels.projectOwner',
    defaultMessage: 'Project Owner',
  },
  yourRoles: {
    id: 'app.labels.yourRoles',
    defaultMessage: 'Your Roles',
  },
  estimatedEndDate: {
    id: 'app.labels.estimatedEndDate',
    defaultMessage: 'Estimated End Date',
  },
  watch: {
    id: 'app.labels.watch',
    defaultMessage: 'Watch',
  },
};

const Invitations = (props) => {
  const dispatch = useDispatch();
  const member_id = useSelector((state) => state.member.member.member_id);
  const {
    invitations,
    showDetail,
    intl: { formatMessage },
  } = props;

  const [showVideoEmailModal, setShowVideoEmailModal] = useState(false);
  const [videoMailId, setVideoEmailId] = useState(null);

  const card_count = 3;

  if (!invitations || invitations.length < card_count) {
    const loop_count = !invitations
      ? card_count
      : card_count - invitations.length;
    for (let index = 0; index < loop_count; index++) {
      invitations.push({
        id: `${CARD_TYPES.market}${index}`,
        type: CARD_TYPES.market,
        create_date: '',
      });
    }
  }

  const handleReadVideoMail = (data) => {
    dispatch(readVideoMail(data, member_id, false));
  };

  return (
    <>
      <Carousel>
        {_.orderBy(invitations, ['create_date'], ['desc']).map((invitation) =>
          invitation.type === CARD_TYPES.market ? (
            <EmptyCard
              key={`-empty-card-${invitation.id}`}
              type={CARD_TYPES.market}
              message={formatMessage(messages.noMarketingData)}
            />
          ) : invitation.invitation_type === CARD_TYPES.eventInvite ? (
            <EventCard
              key={`-event-card-${invitation.id}`}
              // type={CARD_TYPES.eventInvite}
              data={invitation}
              showDetail={showDetail}
              messages={messages}
              formatMessage={formatMessage}
            />
          ) : invitation.invitation_type === CARD_TYPES.mediaMail ? (
            <MediaMailCard
              key={`-media-mail-card-${invitation.id}`}
              type={CARD_TYPES.mediaMail}
              data={invitation}
              setShowVideoEmailModal={setShowVideoEmailModal}
              setVideoEmailId={setVideoEmailId}
              handleReadVideoMail={handleReadVideoMail}
              messages={messages}
              formatMessage={formatMessage}
            />
          ) : invitation.invitation_type === CARD_TYPES.groupInvite ? (
            <GroupCard
              key={`-group-card-${invitation.id}`}
              type={invitation.invitation_type}
              data={invitation}
              messages={messages}
              formatMessage={formatMessage}
            />
          ) : invitation.invitation_type === CARD_TYPES.contactInvite ? (
            <ContactCard
              key={`-contact-card-${invitation.id}`}
              type={invitation.invitation_type}
              data={invitation}
              messages={messages}
              formatMessage={formatMessage}
            />
          ) : invitation.invitation_type === CARD_TYPES.contractInvite ? (
            <JobCard
              key={`-job-card-${invitation.id}`}
              type={invitation.invitation_type}
              data={invitation}
              messages={messages}
              formatMessage={formatMessage}
            />
          ) : null
        )}
      </Carousel>
      {showVideoEmailModal && (
        <VideoEmailModal
          open={showVideoEmailModal}
          mail_id={videoMailId}
          onClose={() => {
            setShowVideoEmailModal(false);
          }}
        />
      )}
    </>
  );
};

Invitations.propTypes = {
  invitations: PropTypes.arrayOf(PropTypes.object),
};

Invitations.defaultProps = {
  invitations: [],
};

const mapStateToProps = (state) => ({
  invitations: state.event.invitations || [],
});

export default connect(mapStateToProps)(injectIntl(Invitations));
