import React, { useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import { useDispatch } from 'react-redux';

import Button from '../../Dashboard/CardButton';
import { addressTypeText } from '../../../utils/calendar';
import AddressLine from '../../AddressLine';
import AmeraDataCard from '../../AmeraDataCard';
import StaticMap from '../../StaticMap';
import { deleteLocation } from '../../../redux/actions/member';
import ConfirmDialog from '../../ConfirmDialog';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';

const ViewCard = ({ data, onEdit, setLocations }) => {
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleMapClick = () => {
    const newWindow = window.open(
      data.map_link,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  const delete_location = useCallback(
    (id) => {
      setLocations({
        type: PROFILE_EDIT_ACTIONS.locationDelete,
        payload: {
          location_id: id,
        },
      });
    },
    [setLocations]
  );

  const handleEdit = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onEdit(data.id);
  };

  const handleDeleteLocation = () => {
    let formData = new FormData();
    formData.set('id', data.id);

    dispatch(deleteLocation(formData));
    handleDeleteConfirmDialogClose();
    delete_location(data.id);
  };

  const handleDeleteConfirmDialogClose = () => {
    setDeleteConfirmDialogOpen(false);
  };

  const handleDeleteConfirmDialogOpen = () => {
    setDeleteConfirmDialogOpen(true);
  };

  const text = (
    <AmeraDataCard
      key={data.id}
      title={
        <>
          {data.description || data.street_address_1}{' '}
          {!data.editable_by_member && (
            <Tooltip title="This Location is derived from your Company's details and cannot be edited here.">
              <LockIcon fontSize="inherit" />
            </Tooltip>
          )}
        </>
      }
      subTitle={addressTypeText(data.location_type)}
      content={<AddressLine data={data} />}
    />
  );

  return (
    <Grid container>
      {!!data.longitude && !!data.latitude ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {text}
            </Grid>
            <Grid
              item
              xs={6}
              onClick={handleMapClick}
              style={{ cursor: 'pointer' }}
            >
              <StaticMap
                lat={data.latitude}
                lng={data.longitude}
                size="200x118"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1} justify="flex-end">
            <Grid item>
              <Button
                variant="info"
                text="Delete"
                onClick={handleDeleteConfirmDialogOpen}
              />
            </Grid>
            <Grid item>
              <Button variant="okFilled" text="Edit" onClick={handleEdit} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {text}
        </Grid>
      )}
      {deleteConfirmDialogOpen && (
        <ConfirmDialog
          open={deleteConfirmDialogOpen}
          title="Confirm Delete"
          onOk={handleDeleteLocation}
          onCancel={handleDeleteConfirmDialogClose}
        />
      )}
    </Grid>
  );
};

export default ViewCard;
