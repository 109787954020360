import React from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import NotApplicableCheck from './NotApplicableCheck';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { isString } from 'lodash';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    position: 'relative',
    '& .MuiAutocomplete-noOptions': {
      display: 'none',
    },
  },
  adornment: {
    position: 'absolute',
    top: '7px',
    right: '22px',
  },
}));

const StyledAutocomplete = withStyles({
  noOptions: {
    display: 'none',
  },
})(Autocomplete);

const CompanyNameInput = ({
  company,
  error,
  onCompanySelect,
  onToggle,
  required = true,
  isApplicable,
  disableNotApplicable = false,
  variant = 'outlined',
  label = 'Company',
  disableAddCompany = false,
  // disabled = false,
}) => {
  const classes = useStyles();

  const companiesLoading = useSelector((s) => s?.company?.loading);
  const companyList = useSelector((s) => s?.company?.companyList);

  let options = null;
  if (companyList?.length !== 0) {
    options = companyList
      ?.filter((c) => c.name && c.id)
      .map((c) => ({
        id: c.id,
        name: c.name,
      }));
  } else if (company && company.name) {
    options = [company];
  }

  return (
    <div className={classes.root}>
      <StyledAutocomplete
        id="company-name"
        debug
        disabled={
          companiesLoading || (!!onToggle && !isApplicable)
          // || (disableAddCompany && companyList?.length === 0)
        }
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        onChange={(event, newValue) => {
          console.debug('onChange value', newValue);
          // handleChangeCompanyId(newValue);
          if (isString(newValue)) {
            onCompanySelect({ id: null, name: newValue });
          } else if (newValue && newValue.inputValue) {
            onCompanySelect({ id: null, name: newValue.inputValue });
          } else {
            onCompanySelect(newValue);
          }
        }}
        filterOptions={(o, p) => {
          console.debug('Option:');
          console.debug(o);
          console.debug('Pwhatev:');
          console.debug(p);
          if (!o || !p) {
            return;
          }
          const filtered = filter(o, p);

          // Suggest the creation of a new value
          if (p.inputValue !== '' && !disableAddCompany) {
            filtered.push({
              inputValue: p.inputValue,
              name: `Add "${p.inputValue}"`,
            });
          }
          return filtered;
        }}
        disableClearable
        options={options || []}
        name="company_name"
        getOptionLabel={(o) => {
          // Value selected with enter, right from the input
          if (isString(o)) {
            return o;
          }

          // Add "xxx" option created dynamically
          if (o.inputValue) {
            return o.inputValue;
          }
          // Regular option
          return o.name;
        }}
        renderOption={(o) => <Typography variant="body2">{o.name}</Typography>}
        value={company}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={
              disableAddCompany &&
              options &&
              options?.length === 0 &&
              !companiesLoading
                ? 'Company not found'
                : label
            }
            inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
            required={required}
            placeholder="Company"
            error={error ? true : false}
            helperText={error ? error : ''}
          />
        )}
      />
      {!!!disableNotApplicable && (
        <div className={classes.adornment}>
          {!!onToggle && (
            <NotApplicableCheck isChecked={!isApplicable} onChange={onToggle} />
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyNameInput;
