import { store } from './store';
import {
  zonedTimeToUtc,
  utcToZonedTime,
  // getTimezoneOffset,
  toDate,
  format as formatTZ,
} from 'date-fns-tz';
import {
  isValid,
  formatISO9075,
  format,
  formatDuration,
  isBefore,
  add,
  getHours,
  parseISO,
  isSameDay,
  // addMinutes,
  set,
  differenceInSeconds,
  differenceInMinutes,
  getDay,
  max,
  min,
  setHours,
  endOfMonth,
  startOfMonth,
  // formatISO,
} from 'date-fns';

import { range, intersection, includes, capitalize, isArray } from 'lodash';
import { displayableFullName } from './contact';
import { destructureAddress, MAP_VENDORS } from './country';
import invert from 'invert-color';
import memoize from 'fast-memoize';
const shortid = require('shortid');

// const memoize = require('fast-memoize');

export const SNACK_TIMEOUT = 1000;
export const DRAWER_WIDTH = 740;

export const INVITE_MODE = {
  contacts: 'contacts',
  group: 'group',
};

export const ADDRESS_TYPES = {
  barsRestraunts: 'bar_restaurant',
  billing: 'billing',
  coffeeShops: 'coffee_shop',
  home: 'home',
  meetingPlace: 'meeting_place',
  bank: 'bank',
  city_hall: 'city_hall',
  embassy: 'embassy',
  park: 'park',
  other: 'other',
  work: 'work',
};

export const MY_LOCATION_SECTIONS = {
  home: 'Home',
  work: 'Work',
  events: 'Events',
  other: 'Other',
};

export const addressTypeText = (type) => {
  switch (type) {
    case ADDRESS_TYPES.home:
      return 'Home Address';
    case ADDRESS_TYPES.work:
      return 'Work Address';
    case ADDRESS_TYPES.billing:
      return 'Billing Address';
    case ADDRESS_TYPES.other:
      return 'Other';
    case ADDRESS_TYPES.barsRestraunts:
      return 'Bars & Restaurants';
    case ADDRESS_TYPES.coffeeShops:
      return 'Coffee Shops';
    case ADDRESS_TYPES.meetingPlace:
      return 'Meeting Place';
    case ADDRESS_TYPES.city_hall:
      return 'City Hall';
    case ADDRESS_TYPES.embassy:
      return 'Embassy';
    case ADDRESS_TYPES.park:
      return 'Park';
    case ADDRESS_TYPES.bank:
      return 'Bank';
    default:
      return type;
  }
};

export const LOCATION_TYPES = {
  own: 'my_locations',
  lookup: 'lookup',
  find: 'find',
  url: 'url',
  none: 'none',
};

export const EVENT_TYPES = {
  meeting: 'Meeting',
  chat: 'Chat',
  audio: 'Audio',
  video: 'Video',
  //   webinar: 'Webinar',
  //   personal: 'Personal',
  breakfast: 'Breakfast',
  lunch: 'Lunch',
  dinner: 'Dinner',
  party: 'Party',
};

export const eventTypeText = (type) => {
  switch (type) {
    case EVENT_TYPES.meeting:
      return 'Meeting';
    case EVENT_TYPES.chat:
      return 'Chat';
    case EVENT_TYPES.audio:
      return 'Phone Call';
    case EVENT_TYPES.video:
      return 'Video Conference';
    case EVENT_TYPES.webinar:
      return 'Webinar';
    case EVENT_TYPES.personal:
      return 'Personal event';
    case EVENT_TYPES.breakfast:
      return 'Breakfast';
    case EVENT_TYPES.lunch:
      return 'Lunch';
    case EVENT_TYPES.dinner:
      return 'Dinner';
    case EVENT_TYPES.party:
      return 'Party';
    default:
      return 'Unsupported event type';
  }
};

export const CALENDAR_VIEWS = {
  month: 'month',
  week: 'week',
  day: 'day',
  agenda: 'agenda',
  collaborative: 'collaborative',
};

/* For Recurrence, need to be sure that there are options for
Daily, Weekly, Monthly, Twice a Month, Quarterly, Yearly.
Plus options to select specific days of the week.
 From my perspective most meetings are not Daily,
 but twice a week on Tues and Thurs, or Mon-Wed-Fri
 or some such set up.*/

export const RECURRENCE_FREQ = {
  daily: 'Daily',
  weekly: 'Weekly',
  weekdays: 'Specific days',
  monthly: 'Monthly',
  biMonthly: 'Bi-monthly',
  quarterly: 'Quarterly',
  yearly: 'Yearly',
  none: 'No recurrence',
};

export const RECURRENCE_END = {
  date: 'End date',
  repeats: 'times',
};

export const WEEKDAYS = {
  monday: { id: 1, str: 'Mo' },
  tuesday: { id: 2, str: 'Tu' },
  wednesday: { id: 3, str: 'We' },
  thursday: { id: 4, str: 'Th' },
  friday: { id: 5, str: 'Fr' },
  saturday: { id: 6, str: 'Sa' },
  sunday: { id: 0, str: 'Su' },
};

export const RECURRING_CANCEL_OPTIONS = {
  onlyThis: 'onlyThis',
  allUpcoming: 'allUpcoming',
};

export const EVENT_INVITE_STATUS = {
  accepted: 'Accepted',
  declined: 'Declined',
  tentative: 'Tentative',
  recurring: 'Recurring',
};

export const EVENT_FILTER_TYPE = {
  all: 'All events',
  contacts: 'Contacts',
  group: 'Group',
  noInvites: 'No invitations',
};

export const isEditableEvent = (e, memberId) => {
  if (!!e.group_info) {
    if (e?.group_info?.group_leader_id === memberId) {
      return true;
    } else {
      return false;
    }
  } else {
    if (e?.host_member_info?.host_member_id === memberId) {
      return true;
    } else {
      return false;
    }
  }
};

const getDayString = (wdid) =>
  Object.entries(WEEKDAYS)
    .map((kv) => kv[1])
    .find((v) => v.id === wdid).str;

const recurrenceEndString = (endCondition, endDate, repeatTimes) => {
  let endString;

  if (endCondition === RECURRENCE_END.date) {
    endString = `until ${format(endDate, 'MMM Lo yyyy')}.`;
  } else if (endCondition === RECURRENCE_END.repeats) {
    endString = `after ${repeatTimes} events.`;
  }

  return `, ends ${endString}`;
};

export const recurrenceText = (
  frequency,
  endCondition,
  endDate,
  repeatTimes,
  repeatWkDays
) => {
  if (frequency === RECURRENCE_FREQ.none) {
    return `Not a recurring event`;
  } else {
    if (frequency === RECURRENCE_FREQ.weekdays) {
      console.log('repeatWkDays', repeatWkDays);

      const weekDaysParsed = isArray(repeatWkDays)
        ? repeatWkDays
        : JSON.parse(repeatWkDays);

      return `Repeats weekly on ${weekDaysParsed
        .map((wdid) => getDayString(wdid))
        .join(', ')} ${recurrenceEndString(
        endCondition,
        endDate,
        repeatTimes
      )}`;
    } else {
      return `Repeats ${frequency} ${recurrenceEndString(
        endCondition,
        endDate,
        repeatTimes
      )}`;
    }
  }
};

// Takes a date with some timezone (provided by picker)
// Converts is to a date with another target timezone (which is usually selected by user)
export const changeTz = (date, targetTz) => {
  const newTime = utcToZonedTime(date, targetTz);
  console.log('picked time', date);
  console.log('target zone', targetTz);
  console.log('new time', newTime);
  console.log('new time to UTC', zonedTimeToUtc(newTime, targetTz));
};

export const makeUtcDate = (date) => {
  // const systemTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Strip off timezone
  console.log('Input date', date);
  const ISOstr = formatISO9075(date);
  console.log('TZ stripped string', ISOstr);
  // Parse again
  console.log('UTC something', toDate(ISOstr, { timeZone: 'Etc/UTC' }));
};

const frequencyToIncrement = (option) => {
  if (option === RECURRENCE_FREQ.daily) {
    return { days: 1 };
  } else if (option === RECURRENCE_FREQ.weekly) {
    return { weeks: 1 };
  } else if (option === RECURRENCE_FREQ.monthly) {
    return { months: 1 };
  } else if (option === RECURRENCE_FREQ.biMonthly) {
    return { weeks: 2 };
  } else if (option === RECURRENCE_FREQ.quarterly) {
    return { months: 3 };
  } else if (option === RECURRENCE_FREQ.yearly) {
    return { years: 1 };
  }
};

const isSelectedWeekday = (date, repeatWeekDays) => {
  const weekday = getDay(date);
  return !!repeatWeekDays.find((wdid) => wdid === weekday);
};

// Gets a string in UTC given a date and a timezone
export const formatInTimeZone = (date, tz) => {
  console.debug(`Date: ${date}`);
  console.debug(`TZ: ${tz}`);
  try {
    return formatTZ(utcToZonedTime(date, tz), 'yyyy-MM-dd HH:mm:ss', {
      timeZone: tz,
    });
  } catch (e) {
    console.debug(`UTC: ${utcToZonedTime(date, tz)}`);
    // console.exception();
    console.error(e);
    return date;
  }
};

export const parseFromStr = (dateStr, tz) => toDate(dateStr, { timeZone: tz });

// const getUTCOffset = (tz) => {
//   let offset = '';
//   if (tz.utc_offset.startsWith('-1 day')) {
//     offset = `-${String(
//       -1 * (parseInt(tz.utc_offset.substring(8, 10)) - 24)
//     ).padStart(2, '0')}00`;
//   } else {
//     if (tz.utc_offset.substring(1, 2) === ':') {
//       offset = `+0${tz.utc_offset.substring(0, 1)}00`;
//     } else {
//       offset = `+${tz.utc_offset.substring(0, 2)}00`;
//     }
//   }
//   return offset;
// };

// calculate/re-calculate only on params change.
const memoizedDateTimeZone = memoize(
  (date_time_str, view, date_format, time_format, timeZone, isFormat) => {
    //whatever datetime format provided convert it into ISO (which also converts to zero timezone)
    let dateTimeStr = new Date(date_time_str).toISOString();

    //replace Z with +00:00 otherwise utcToZonedTime() will not work properly
    dateTimeStr = dateTimeStr.replace('Z', '+00:00');

    if (!date_time_str || !isValid(parseISO(dateTimeStr))) {
      console.error('date time not valid', date_time_str);
      return date_time_str;
    }

    // console.debug(`Get UTC Offset: ${getUTCOffset(timezone)}`)
    // console.debug(`Get getTimezoneOffset: ${getTimezoneOffset(getUTCOffset(timezone))}`);
    // console.debug(`Get getTimezoneOffset Date: ${getTimezoneOffset(getUTCOffset(timezone))}`);

    let parsedDateTime = parseISO(dateTimeStr);
    // if (timezone && timezone.utc_offset) {
    //   parsedDateTime = utcToZonedTime(dateTimeStr, getUTCOffset(timezone));
    // } else {
    //   parsedDateTime =
    // }

    const timeFormat =
      time_format && time_format.includes('24') ? 'HH:mm' : 'hh:mm a';
    const dateFormat =
      (date_format &&
        date_format.replace(/D|Y/g, (m) =>
          m === 'D' ? 'd' : m === 'Y' ? 'y' : ''
        )) ||
      'MM/dd/yyyy';

    if (!isFormat) {
      return parsedDateTime;
    }
    let formattedDateTime = '';
    if (timeZone) {
      formattedDateTime = formatTZ(
        utcToZonedTime(parsedDateTime, timeZone),
        `${
          view === 'date'
            ? dateFormat
            : view === 'time'
            ? timeFormat
            : `${dateFormat} ${timeFormat}`
        }`,
        {
          timeZone,
        }
      );
    } else {
      formattedDateTime = format(
        parsedDateTime,
        `${
          view === 'date'
            ? dateFormat
            : view === 'time'
            ? timeFormat
            : view === 'weekday-date'
            ? `eeee, ${dateFormat}`
            : `${dateFormat} ${timeFormat}`
        }`
      );
    }
    return formattedDateTime;
  }
);

// called every time whenever component using it rerenders
export const defaultDateTimeZone = (
  date_time_str,
  view,
  isFormat = true,
  timeZone = null
) => {
  const { memberSetting } = store.getState().member;
  const { date_format, time_format } = memberSetting;
  // const timezone = timezoneList.find((tz) => tz.tz_id === timezone_id);

  return memoizedDateTimeZone(
    date_time_str,
    view,
    date_format,
    time_format,
    timeZone,
    isFormat
  );
};

export const defaultEventName = (metadata) => {
  const { type } = metadata;
  const typePortion = eventTypeText(type);
  return `${typePortion}${eventTypePortion(metadata)}`;
};

export const getLocation = (
  originalLocation,
  type = undefined,
  memberCountryId = undefined,
  name = undefined
) => {
  let location = { ...originalLocation };
  if (location.address_components) {
    const parsed = destructureAddress(location.address_components);
    let photo_url =
      location &&
      location.photos &&
      location.photos.length &&
      location.photos[0].getUrl({ maxWidth: 600 });

    if (!!parsed) {
      location = {
        street_address_1: parsed?.streetAddress1,
        street_address_2: parsed?.streetAddress2,
        locality: parsed?.locality,
        sub_locality: parsed?.sublocality,
        admin_area_1: parsed?.adminArea1,
        admin_area_2: parsed?.adminArea2,
        postal_code: parsed?.postal,
        place_id: location?.place_id,
        map_link: location?.url,
        latitude: location?.geometry.location.lat(),
        longitude: location?.geometry.location.lng(),
        map_vendor: MAP_VENDORS.google,
        vendor_formatted_address: location?.formatted_address,
        country_alpha2: parsed?.countryAlpha2,
        photo_url,
        name: location?.name,
      };
    }
  }
  if (
    !location.photo_url &&
    originalLocation.raw_response &&
    originalLocation.raw_response.photos &&
    originalLocation.raw_response.photos.length &&
    'getUrl' in originalLocation.raw_response.photos[0]
  ) {
    location.photo_url = originalLocation.raw_response.photos[0].getUrl({
      maxWidth: 600,
    });
  }
  location.id = location.place_id;
  location.name = location?.name;
  location.location_type = type || location?.location_type;
  location.country_code_id = memberCountryId || location?.country_code_id;
  location.description = name || location?.name;
  location.raw_response = originalLocation;

  return location;
};

export const prepareForm = (state, attachments, changeName) => {
  const {
    start,
    colorId,
    end,
    recurrence,
    endCondition,
    repeatWeekDays,
    endDate,
    selectedPlace,
    repeatTimes,
  } = state;

  let metadata = { ...state, attachments, recurringCopies: [] };
  let recurringCopies = [];
  if (recurrence !== RECURRENCE_FREQ.none) {
    // Differrnece in seconds between
    const diffSecs = differenceInSeconds(end, start);
    // Find put the start and end dates for all recurring events
    if (endCondition === RECURRENCE_END.date) {
      if (recurrence !== RECURRENCE_FREQ.weekdays) {
        let maxStart = start;
        let maxEnd = end;
        while (isBefore(maxStart, endDate)) {
          const scopedStart = add(maxStart, frequencyToIncrement(recurrence));
          const scopedEnd = add(maxEnd, frequencyToIncrement(recurrence));
          recurringCopies.push({
            start: formatInTimeZone(scopedStart, 'UTC'),
            end: formatInTimeZone(scopedEnd, 'UTC'),
          });
          maxStart = scopedStart;
          maxEnd = scopedEnd;
        }
      } else {
        // iterate over days
        let maxStart = add(start, { days: 1 });
        while (isBefore(maxStart, endDate)) {
          const scopedStart = maxStart;
          console.log('Scope start', scopedStart);
          const scopedEnd = add(scopedStart, { seconds: diffSecs });
          if (isSelectedWeekday(scopedStart, repeatWeekDays)) {
            // If the day is our type of weekday
            recurringCopies.push({
              start: formatInTimeZone(scopedStart, 'UTC'),
              end: formatInTimeZone(scopedEnd, 'UTC'),
            });
          }
          maxStart = add(scopedStart, { days: 1 });
        }
      }
    } else if (endCondition === RECURRENCE_END.repeats) {
      let eventCount = 1;
      let maxStart = start;
      let maxEnd = end;
      if (recurrence !== RECURRENCE_FREQ.weekdays) {
        while (eventCount < repeatTimes) {
          const scopedStart = add(maxStart, frequencyToIncrement(recurrence));
          const scopedEnd = add(maxEnd, frequencyToIncrement(recurrence));
          recurringCopies.push({
            start: formatInTimeZone(scopedStart, 'UTC'),
            end: formatInTimeZone(scopedEnd, 'UTC'),
          });
          maxStart = scopedStart;
          maxEnd = scopedEnd;
          eventCount += 1;
        }
      } else {
        let maxStart = add(start, { days: 1 });
        while (eventCount < repeatTimes) {
          const scopedStart = maxStart;
          const scopedEnd = add(scopedStart, { seconds: diffSecs });
          if (isSelectedWeekday(scopedStart, repeatWeekDays)) {
            // If the day is our type of weekday
            recurringCopies.push({
              start: formatInTimeZone(scopedStart, 'UTC'),
              end: formatInTimeZone(scopedEnd, 'UTC'),
            });
            // here we update the counter only on hits
            eventCount += 1;
          }
          maxStart = add(scopedStart, { days: 1 });
        }
      }
    }
  }

  let location = getLocation(selectedPlace);

  metadata = {
    ...metadata,
    name: changeName ? metadata.name : '',
    /* 2021-01-29 -- Now we don't synthetize the event name to store.
    Instead we synthetize it when we display it (and it is defferent for each invitee) */
    colorId: colorId === 'no-color' ? null : colorId,
    start: formatInTimeZone(metadata.start, 'UTC'),
    end: formatInTimeZone(metadata.end, 'UTC'),
    endDate: !!metadata.endDate
      ? formatInTimeZone(metadata.endDate, 'UTC')
      : null,
    recurringCopies,
    memberLocationId: !!metadata?.memberLocation
      ? metadata.memberLocation.id
      : null,
    location,
    locationMode: metadata.locationMode,
    /*
      If group mode => group id,
      if contacts mode => null
    */
    invitedGroup:
      metadata.inviteMode === INVITE_MODE.group
        ? metadata.invitedGroup.group_id
        : null,
    /*
      If contacts mode => contacts id
      If group mode => group ids for memebers with isShouldInvite
    */
    invitedMembers:
      metadata.inviteMode === INVITE_MODE.contacts
        ? metadata.invitedMembers.map((imo) => imo.contact_member_id)
        : metadata?.invitedGroup?.members.length > 0
        ? metadata.invitedGroup.members
            .filter((m) => m.isShouldInvite)
            .map((m) => m.contact_member_id)
        : null,
    attachments:
      metadata.attachments.length > 0
        ? metadata.attachments.map((ao) => ao.member_file_id)
        : [],
  };

  console.debug('event data send object', metadata);

  let formData = new FormData();
  formData.set('event_data', JSON.stringify(metadata));
  return formData;
};

export const prepareChangesForms = (state, attachments, changeName) => {
  const {
    start,
    end,
    recurrence,
    endCondition,
    repeatWeekDays,
    endDate,
    selectedPlace,
    repeatTimes,
  } = state;

  let recurringCopies = [];
  if (recurrence !== RECURRENCE_FREQ.none) {
    // Differrnece in seconds between
    const diffSecs = differenceInSeconds(end, start);
    // Find put the start and end dates for all recurring events
    if (endCondition === RECURRENCE_END.date) {
      if (recurrence !== RECURRENCE_FREQ.weekdays) {
        let maxStart = start;
        let maxEnd = end;
        while (isBefore(maxStart, endDate)) {
          const scopedStart = add(maxStart, frequencyToIncrement(recurrence));
          const scopedEnd = add(maxEnd, frequencyToIncrement(recurrence));
          recurringCopies.push({
            start: formatInTimeZone(scopedStart, 'UTC'),
            end: formatInTimeZone(scopedEnd, 'UTC'),
          });
          maxStart = scopedStart;
          maxEnd = scopedEnd;
        }
      } else {
        // iterate over days
        let maxStart = add(start, { days: 1 });
        while (isBefore(maxStart, endDate)) {
          const scopedStart = maxStart;
          console.log('Scope start', scopedStart);
          const scopedEnd = add(scopedStart, { seconds: diffSecs });
          if (isSelectedWeekday(scopedStart, repeatWeekDays)) {
            // If the day is our type of weekday
            recurringCopies.push({
              start: formatInTimeZone(scopedStart, 'UTC'),
              end: formatInTimeZone(scopedEnd, 'UTC'),
            });
          }
          maxStart = add(scopedStart, { days: 1 });
        }
      }
    } else if (endCondition === RECURRENCE_END.repeats) {
      let eventCount = 1;
      let maxStart = start;
      let maxEnd = end;
      if (recurrence !== RECURRENCE_FREQ.weekdays) {
        while (eventCount < repeatTimes) {
          const scopedStart = add(maxStart, frequencyToIncrement(recurrence));
          const scopedEnd = add(maxEnd, frequencyToIncrement(recurrence));
          recurringCopies.push({
            start: formatInTimeZone(scopedStart, 'UTC'),
            end: formatInTimeZone(scopedEnd, 'UTC'),
          });
          maxStart = scopedStart;
          maxEnd = scopedEnd;
          eventCount += 1;
        }
      } else {
        let maxStart = add(start, { days: 1 });
        while (eventCount < repeatTimes) {
          const scopedStart = maxStart;
          const scopedEnd = add(scopedStart, { seconds: diffSecs });
          if (isSelectedWeekday(scopedStart, repeatWeekDays)) {
            // If the day is our type of weekday
            recurringCopies.push({
              start: formatInTimeZone(scopedStart, 'UTC'),
              end: formatInTimeZone(scopedEnd, 'UTC'),
            });
            // here we update the counter only on hits
            eventCount += 1;
          }
          maxStart = add(scopedStart, { days: 1 });
        }
      }
    }
  }

  let location = getLocation(selectedPlace);

  const metadata = {
    ...state,
    name: changeName ? state.name : '',
    recurringCopies,
    colorId: state.colorId === 'no-color' ? null : state.colorId,
    memberLocationId: !!state?.memberLocation ? state.memberLocation.id : null,
    location,
    locationMode: state.locationMode,
    attachments,
    invitations:
      state.inviteMode === INVITE_MODE.contacts
        ? state.invitations
        : eventChangeInvitationHelper(
            state.invitations,
            state.invitedGroup.members
          ),
    start: formatInTimeZone(state.start, 'UTC'),
    end: formatInTimeZone(state.end, 'UTC'),
  };
  console.log('changes form data content', metadata);
  let formData = new FormData();
  formData.set('event_data', JSON.stringify(metadata));
  return formData;
};

export const eventPropGetter = (
  event,
  start,
  end,
  isSelected,
  colors,
  view
) => {
  const { event_color_id } = event;
  const eventColor = !!event_color_id
    ? colors.find((co) => co.id === event_color_id)?.color
    : '#F8FAFF';

  const eventProps = {
    className: '',
  };
  if (view !== CALENDAR_VIEWS.agenda) {
    eventProps.style = {
      backgroundColor: eventColor,
      color: invert(eventColor, true),
    };
  }
  return eventProps;
};

// https://app.clubhouse.io/amera-sharing/story/90/fetch-from-api-events-based-on-view-constraints
// Returns UTC ISO string to make a call
export const getParams = (view, range) => {
  let start, end;
  console.debug(`Calendar View: ${view}`);
  console.debug(`Calendar Range: ${JSON.stringify(range)}`);

  if (view === CALENDAR_VIEWS.month) {
    start = formatInTimeZone(range?.start, 'UTC');
    end = formatInTimeZone(range?.end, 'UTC');
    // console.log('month', start, end);
  } else if (view === CALENDAR_VIEWS.week) {
    start = formatInTimeZone(min(range), 'UTC');
    end = formatInTimeZone(max(range), 'UTC');
    // console.log('week', start, end);
  } else if (view === CALENDAR_VIEWS.day) {
    // console.log('day', range[0]);
    start = formatInTimeZone(setHours(range[0], 0), 'UTC');
    end = formatInTimeZone(setHours(range[0], 24), 'UTC');
  } else if (view === CALENDAR_VIEWS.agenda) {
    start = formatInTimeZone(range?.start, 'UTC');
    end = formatInTimeZone(range?.end, 'UTC');
    // console.log('agenda', start, end);
  }

  // console.log('UTC strings', start, end);
  return { start, end };
};

export const getMonthLimits = (current = null) => {
  if (!current) current = new Date();
  return {
    start: startOfMonth(current),
    end: endOfMonth(current),
  };
};

// As per request for a fixed list
export const durationOptions = [
  { value: 15, str: '0:15' },
  { value: 30, str: '0:30' },
  { value: 60, str: '1:00' },
  { value: 90, str: '1:30' },
  { value: 120, str: '2:00' },
  { value: 150, str: '2:30' },
  { value: 180, str: '3:00' },
  { value: 210, str: '3:30' },
  { value: 240, str: '4:00' },
  { value: 480, str: '8:00' },
  { value: 'custom', str: 'Not Applicable' },
];

// This was used in 2020 to render a list of options with a given minutes step and max duration
export const getDurationOptions = (stepMinutes, MAX_DURATION_HOURS) => {
  let minutesRange = range(stepMinutes, MAX_DURATION_HOURS * 60, stepMinutes);
  if (!includes(minutesRange, MAX_DURATION_HOURS * 60)) {
    minutesRange.push(MAX_DURATION_HOURS * 60);
  }

  let options = minutesRange.map((m) => ({
    value: m,
    str: minutesToHumanReadableDuration(m),
  }));
  // @ts-ignore
  options.push({ value: 'custom', str: 'Custom' });
  // @ts-ignore
  options.push({ value: 'tooHigh', str: '> 8:00' });

  return options;
};

const minutesToHumanReadableDuration = (minutes) => {
  if (minutes < 60) {
    return `0:${minutes}`;
  } else if (minutes === 60) {
    return '1:00';
  } else if (minutes > 60 && minutes < 120) {
    return `1:${minutes % 60}`;
  } else if (includes([120, 180, 240, 300, 360, 420, 480], minutes)) {
    return `${minutes / 60}:00`;
  } else {
    return `${Math.floor(minutes / 60)}:${minutes % 60}`;
  }
};

// We take the current date 00:00, iterate + X minutes until = 24:00{
export const getTimeLimits = (date, stepMinutes) => {
  const rangeStartDate = set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  let scopedDate = rangeStartDate;

  let results = [];

  while (isBefore(scopedDate, add(rangeStartDate, { days: 1 }))) {
    results.push({
      value: scopedDate,
      str: defaultDateTimeZone(scopedDate, 'time'),
    });

    scopedDate = add(scopedDate, { minutes: stepMinutes });
  }

  return results;
};

export const roundToNextHour = (date) => {
  const hours = getHours(date) + 1;
  return set(date, { hours, minutes: 0, seconds: 0, milliseconds: 0 });
};

// Pretty event dates and duration line for event info modal
export const eventTimeText = (start, end, isFullDay) => {
  const date_format = 'weekday-date',
    time_format = 'time';

  if (isSameDay(start, end)) {
    if (isFullDay) {
      return `${defaultDateTimeZone(start, date_format)}`;
    } else {
      return `${defaultDateTimeZone(
        start,
        date_format
      )}  -  ${defaultDateTimeZone(
        start,
        time_format
      )} to ${defaultDateTimeZone(end, time_format)}`;
    }
  } else {
    if (isFullDay) {
      return `${defaultDateTimeZone(
        start,
        date_format
      )} to ${defaultDateTimeZone(end, date_format)}`;
    } else {
      return `${defaultDateTimeZone(start, date_format)} ${defaultDateTimeZone(
        start,
        time_format
      )} to ${defaultDateTimeZone(end, date_format)} ${defaultDateTimeZone(
        end,
        time_format
      )}`;
    }
  }
};

// Group invite helpers
export const parseGroupToState = (
  groupObject,
  memberId,
  isCreateModal = true,
  invitations = null
) => {
  if (!!groupObject) {
    const { group_id, group_name } = groupObject;
    return {
      group_id,
      group_name,
      members:
        !!groupObject.members && groupObject.members.length > 0
          ? groupObject.members
              .filter((mo) => mo.id !== memberId) // Filter oneself out
              .map((mo) => ({
                contact_member_id: mo.id,
                isShouldInvite: invitationHelper(
                  mo.id,
                  invitations,
                  isCreateModal
                ),
                // inviteStatus: EVENT_INVITE_STATUS.unsent,
                contact_name: displayableFullName(mo),
              }))
          : [],
    };
  } else {
    return null;
  }
};

const invitationHelper = (groupMemberId, invitations, isCreateModal) => {
  if (isCreateModal) {
    return true;
  } else {
    if (!!invitations) {
      const invitedMemberIds = invitations.map((io) => io.invite_member_id);
      if (includes(invitedMemberIds, groupMemberId)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

//  This will format the state to make an invitations object that webapi can process
const eventChangeInvitationHelper = (invitations, groupMembers) => {
  // Keep only the invitations for checked group members
  const shouldInviteMemberIds = groupMembers
    .filter((gmo) => gmo.isShouldInvite)
    .map((gmo) => gmo.contact_member_id);
  const previouslyInvitedMemberIds = !!invitations
    ? invitations.map((io) => io.invite_member_id)
    : [];

  return shouldInviteMemberIds.map((id) => {
    if (includes(previouslyInvitedMemberIds, id)) {
      // Was invited, stays invoited
      return invitations.find((io) => io.invite_member_id === id);
    } else {
      // Was not invited, will be
      return { invite_member_id: id, invite_id: shortid.generate() };
    }
  });
};

// Filter events based on focused contact OR group
export const filterEvents = (events, eventsFilter) => {
  const { filterType, contacts, group } = eventsFilter;

  if (filterType === EVENT_FILTER_TYPE.all) {
    return events;
  } else if (
    filterType === EVENT_FILTER_TYPE.contacts &&
    !!contacts &&
    contacts.length > 0
  ) {
    return events.filter(
      (eo) =>
        eo.group_info === null &&
        !!eo.invitations &&
        intersection(
          contacts.map((co) => co.contact_member_id),
          // This will include host member to filter
          [
            ...eo.invitations.map((io) => io.invite_member_id),
            eo.host_member_info.host_member_id,
          ]
        ).length > 0
    );
  } else if (filterType === EVENT_FILTER_TYPE.group && !!group) {
    return events.filter(
      (eo) => !!eo.group_info && eo.group_info.group_id === group.group_id
    );
  } else if (filterType === EVENT_FILTER_TYPE.noInvites) {
    return events.filter((eo) => eo.invitations === null);
  } else {
    // Fallback
    return events;
  }
};

export const filterDescription = (eventsFilter) => {
  const { filterType, contacts, group } = eventsFilter;
  if (filterType === EVENT_FILTER_TYPE.all) {
    return 'All events';
  } else if (
    filterType === EVENT_FILTER_TYPE.contacts &&
    !!contacts &&
    contacts.length > 0
  ) {
    return `Events with ${contacts
      .map((co) => displayableFullName(co))
      .join(', ')}`;
  } else if (filterType === EVENT_FILTER_TYPE.group && !!group) {
    return `${group.group_name} events`;
  } else if (filterType === EVENT_FILTER_TYPE.noInvites) {
    return 'Events with no invites';
  } else {
    return '';
  }
};

// calculate event duration with start/end date
export const calcEventDuration = (start, end) => {
  const diff_mis = differenceInMinutes(end, start);
  const oneDayMins = 24 * 60;

  if (diff_mis >= oneDayMins) {
    const days = Math.floor(diff_mis / oneDayMins);

    return formatDuration({ days });
  } else {
    return formatDuration({
      hours: Math.floor(diff_mis / 60),
      minutes: diff_mis % 60,
    });
  }
};

// https://developers.google.com/places/supported_types?hl=ru#table1
export const GPLACES_TYPES = {
  airport: 'airport',
  bar: 'bar',
  cafe: 'cafe',
  gas_station: 'gas_station',
  lodging: 'lodging',
  shopping_mall: 'shopping_mall',
  movie_theater: 'movie_theater',
  park: 'park',
  restaurant: 'restaurant',
};

export const GPLACES_TYPE_LABELS = {
  airport: 'Airport',
  bar: 'Bar',
  cafe: 'Cafe',
  gas_station: 'Gas station',
  lodging: 'Hotel',
  shopping_mall: 'Mall',
  movie_theater: 'Movie theater',
  park: 'Park',
  restaurant: 'Restaurant',
};

export const EAT_GPLACES = [
  GPLACES_TYPES.restaurant,
  GPLACES_TYPES.bar,
  GPLACES_TYPES.cafe,
];

export const googlePlaceToReadable = (place) => {
  return capitalize(place.replace('_', ' '));
};

const eventTypePortion = (state) => {
  const { inviteMode, invitedMembers, invitations, invitedGroup } = state;
  let invitePortion = '';
  const invitedContact = invitedMembers || invitations;

  if (
    inviteMode === INVITE_MODE.contacts &&
    invitedContact &&
    invitedContact.length > 0
  ) {
    invitePortion = ` with ${generateEventInviteListText(invitedContact)}`;
  } else if (inviteMode === INVITE_MODE.group && !!invitedGroup) {
    invitePortion = ` with ${invitedGroup.group_name}`;
  }
  return invitePortion;
};
export const eventTitle = (state) => {
  return `Create event${eventTypePortion(state)}`;
};

export const getInvitedListFilterLoggedInMember = (event, loggedMemberId) => {
  event.invitations = event.invitations || [];

  return [...event.invitations, event.host_member_info].filter(
    (mo) =>
      mo.invite_member_id !== loggedMemberId &&
      mo.host_member_id !== loggedMemberId
  );
};

export const generateEventInviteListText = (inviteList) => {
  const inviteListDisplay = inviteList
    .slice(0, 3)
    .map((imo) => displayableFullName(imo))
    .join(', ');
  return `${inviteListDisplay}${
    inviteList && inviteList.length > 3
      ? ` and ${inviteList.length - 3} Other${
          inviteList.length - 3 > 1 ? 's' : ''
        }`
      : ''
  }`;
};

export const eventDisplayName = (event, loggedMemberId) => {
  if (!!event.name) {
    return event.name;
  } else if (!!event.event_name) {
    return event.event_name;
  } else {
    const typePortion = eventTypeText(event.event_type);
    let invitePortion = '';
    if (!!event.group_info) {
      invitePortion = ` with ${event.group_info.group_name}`;
    } else {
      if (!!event.invitations && event.invitations.length > 0) {
        const involvedMembersButLogged = getInvitedListFilterLoggedInMember(
          event,
          loggedMemberId
        );

        invitePortion = ` with ${generateEventInviteListText(
          involvedMembersButLogged
        )}`;

        return `${typePortion}${invitePortion}`;
      }
    }
    return `${typePortion}${invitePortion}`;
  }
};

// get date from hh:mm format time
export const getDateByTime = (time, date = null) => {
  const current = date || new Date();

  if (time) {
    const splits = time.split(':');

    if (splits.length === 2) {
      const hours = splits[0];
      const minutes = splits[1];

      return set(current, {
        hours: hours,
        minutes: minutes,
        seconds: 0,
        milliseconds: 0,
      });
    } else {
      return current;
    }
  } else {
    return current;
  }
};

export const defaultSetting = {
  Breakfast: {
    start: '08:00',
    end: '09:00',
  },
  Lunch: {
    start: '12:00',
    end: '13:00',
  },
  Dinner: {
    start: '19:00',
    end: '21:00',
  },
  Party: {
    start: '21:00',
    end: '24:00',
  },
};
