import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import TableChartIcon from '@material-ui/icons/TableChart';
import SettingsIcon from '@material-ui/icons/Settings';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { find, debounce } from 'lodash';

import CardContainer from './CardContainer';
import ListContainer from './ListContainer';
import NavigationGroup from './NavigationGroup';
import PageSettingsModal from '../../components/Modal/PageSettingsModal';

import { getGroupMemberShip } from '../../redux/actions/group';

const useStyles = makeStyles((theme) => ({
  groupTool: {
    marginTop: '20px',
  },
  sortGroup: {
    float: 'right',
    paddingRight: '20px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
}));

const GroupMembership = (props) => {
  const {
    loading,
    dispatch,
    groupMembershipList,
    membershipsCount,
    location,
    pageSettings,
  } = props;
  const pageType = 'Groups';
  const groupSettings = find(pageSettings, (o) => o.page_type === pageType);
  const defaultView = (groupSettings && groupSettings.view_type) || null;
  const defaultPageSize = (groupSettings && groupSettings.page_size) || 25;
  const defaultSortOrder = (groupSettings && groupSettings.sort_order) || null;

  let defaultOrderBy = 'group_name';
  let defaultOrder = 'asc';

  if (defaultSortOrder && defaultSortOrder[0]) {
    if (defaultSortOrder[0].charAt(0) === '-') {
      defaultOrderBy = defaultSortOrder[0].substring(1);
      defaultOrder = 'desc';
    } else {
      defaultOrderBy = defaultSortOrder[0];
    }
  }

  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize || 25);
  const [searchKey, setSearchKey] = useState('');

  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [view, setView] = useState(defaultView || 'tile');
  const [showGroupSortSetting, setShowGroupSortSetting] = useState(false);

  const toggleCard = (e, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  useEffect(() => {
    const sort =
      defaultOrder === 'desc' ? `-${defaultSortOrder}` : `${defaultSortOrder}`;
    const params = {
      sort,
      searchKey,
      pageNumber: pageNumber - 1,
      pageSize,
    };
    dispatch(getGroupMemberShip(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loadSortedGroupMemberShip = (
    property,
    value,
    filterByItems,
    search_key,
    page_number,
    page_size
  ) => {
    let sort = '';
    if (!property) {
      property = orderBy;
    }
    if (!value) {
      value = order;
    }
    if (property) {
      sort = value === 'desc' ? `-${property}` : `${property}`;
    }

    let params = {};
    params = { sort };
    // filterByItems = filterByItems ? filterByItems : filterBy;
    // if (Object.keys(filterByItems).length > 0) {
    //   //consider user recent filter options as well if exist
    //   params = { sort, filter: new URLSearchParams(filterByItems) };
    // } else {
    //     params = { sort };
    // }
    params.searchKey = search_key ? search_key : searchKey;
    params.pageNumber =
      page_number === null || page_number === undefined
        ? pageNumber - 1
        : page_number;
    params.pageSize = page_size ? page_size : pageSize;

    dispatch(getGroupMemberShip(params));
    setOrderBy(property);
    // setFilterBy(filterByItems);
  };

  const debouncedLoadSortedGroupMemberShip = useCallback(
    debounce(loadSortedGroupMemberShip, 500),
    []
  );

  const handleSearch = (e) => {
    const value = e.target.value;
    debouncedLoadSortedGroupMemberShip(null, null, null, value, null, null);
    setSearchKey(value);
  };

  return (
    <React.Fragment>
      <div className="group-section view-section">
        <div className="toggle-button-group">
          <NavigationGroup
            type="group-memberships"
            searchKey={searchKey}
            handleSearch={handleSearch}
          />
          <ToggleButtonGroup value={view} exclusive onChange={toggleCard}>
            <ToggleButton value="tile" aria-label="tile">
              <Tooltip title="Group Tile">
                <ViewModuleIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="table" aria-label="table">
              <Tooltip title="Group Table">
                <TableChartIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="page-setting-button">
            <Tooltip title="Settings" arrow>
              <SettingsIcon
                className={classes.groupSettingSVG}
                onClick={() => setShowGroupSortSetting(true)}
              />
            </Tooltip>
          </div>
        </div>
        {view === 'tile' ? (
          <CardContainer
            type="membership"
            // orderBy={orderBy}
            // order={order}
            pageSize={pageSize}
            pageNumber={pageNumber}
            // setOrderBy={(property) => setOrderBy(property)}
            // setOrder={setOrder}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            groups={groupMembershipList}
            totalCount={membershipsCount}
            loadSortedGroups={loadSortedGroupMemberShip}
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
            defaultSortOrder={defaultSortOrder}
          ></CardContainer>
        ) : (
          <ListContainer
            type="membership"
            orderBy={orderBy}
            order={order}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setOrderBy={(property) => setOrderBy(property)}
            setOrder={setOrder}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            groups={groupMembershipList}
            totalCount={membershipsCount}
            loadSortedGroups={loadSortedGroupMemberShip}
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
            defaultSortOrder={defaultSortOrder}
          ></ListContainer>
        )}
      </div>
      <PageSettingsModal
        pageType={pageType}
        loading={loading}
        open={showGroupSortSetting}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setPageSize={setPageSize}
        setView={(view) => toggleCard(null, view)}
        onSave={loadSortedGroupMemberShip}
        onClose={() => setShowGroupSortSetting(false)}
      />
    </React.Fragment>
  );
};

GroupMembership.propTypes = {
  dispatch: PropTypes.func,
  groupMembershipList: PropTypes.arrayOf(PropTypes.object),
};

GroupMembership.defaultProps = {
  dispatch: null,
  groupMembershipList: [],
};

const mapStateToProps = (state) => ({
  loading: state.group.loadingGroupMemberships,
  groupMembershipList: state.group.groupMembershipList,
  membershipsCount: state.group.membershipsCount,
  pageSettings: state.member.pageSettings,
});

export default connect(mapStateToProps)(GroupMembership);
