import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewListIcon from '@material-ui/icons/ViewList';

import TimezoneList from '../../pages/Profile/InputElements/TimezoneList';
import DateFormat from '../../pages/Profile/InputElements/DateFormat';
import TimeFormat from '../../pages/Profile/InputElements/TimeFormat';

import {
  PAGE_TYPES,
  PAGE_VIEWS,
  DEFAULT_PAGE_SIZES,
} from '../../utils/pageSettings.js';
import { CALENDAR_VIEWS } from '../../utils/calendar';
import { textCapitalized } from '../../utils/general';

import { postPageSettings, putPageSettings } from '../../redux/actions/member';

import { find } from 'lodash';

import Modal from './modal';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    marginBottom: 10,
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: 144,
  },
  marginTop_8: {
    marginTop: theme.spacing(1),
  },
  marginTop_16: {
    marginTop: theme.spacing(2),
  },
  radioGroupContainer1: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  radioGroupContainer2: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '0 15px',
  },
  inputRadioContainer: {
    marginTop: theme.spacing(1),
    width: '120px',
    margin: 0,
  },
  iconsContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginLeft: 15,
    padding: '0 15px',
  },
  iconTile: {
    fontSize: '100px',
    cursor: 'pointer',
  },
  iconTable: {
    fontSize: '90px',
    cursor: 'pointer',
  },
  iconList: {
    fontSize: '97px',
    cursor: 'pointer',
  },
  settingsFields: {
    marginTop: -25,
  },
}));

export const messages = {
  sortBy: {
    id: 'app.labels.sortBy',
    defaultMessage: 'Sort By',
  },
  orderBy: {
    id: 'app.labels.orderBy',
    defaultMessage: 'Order By',
  },
  orderAsc: {
    id: 'app.labels.orderAsc',
    defaultMessage: 'Ascending',
  },
  orderDesc: {
    id: 'app.labels.orderDesc',
    defaultMessage: 'Descending',
  },
  default: {
    id: 'app.labels.default',
    defaultMessage: 'Default',
  },
  view: {
    id: 'app.labels.view',
    defaultMessage: 'View',
  },
  cards: {
    id: 'app.labels.cards',
    defaultMessage: 'Cards',
  },
  rows: {
    id: 'app.labels.rows',
    defaultMessage: 'Rows',
  },
  items: {
    id: 'app.labels.items',
    defaultMessage: 'Items',
  },
  per: {
    id: 'app.labels.per',
    defaultMessage: 'Per',
  },
  page: {
    id: 'app.labels.page',
    defaultMessage: 'Page',
  },
  saveChanges: {
    id: 'app.home.savechange',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'app.labels.cancel',
    defaultMessage: 'Cancel',
  },
  contacts: {
    id: 'app.labels.contacts',
    defaultMessage: 'Contacts',
  },
  projects: {
    id: 'app.labels.projects',
    defaultMessage: 'Projects',
  },
  groups: {
    id: 'app.labels.groups',
    defaultMessage: 'Groups',
  },
  calendar: {
    id: 'app.labels.calendar',
    defaultMessage: 'Calendar',
  },
  drive: {
    id: 'app.labels.drive',
    defaultMessage: 'Drive',
  },
  mail: {
    id: 'app.labels.mail',
    defaultMessage: 'Mail',
  },
  table: {
    id: 'app.labels.table',
    defaultMessage: 'Table',
  },
  tile: {
    id: 'app.labels.tile',
    defaultMessage: 'Tile',
  },
  list: {
    id: 'app.labels.list',
    defaultMessage: 'List',
  },
  settings: {
    id: 'app.settings.profile.title',
    defaultMessage: 'Settings',
  },
};

const PageSettingsModal = (props) => {
  const {
    open,
    loading,
    pageType,
    setOrderBy,
    setPageSize,
    setView,
    setOrder,
    onSave,
    onClose,
    intl: { formatMessage },
    calendarState,
    setCalendarState,
  } = props;

  const { allPageSettings } = useSelector((state) => ({
    allPageSettings: state.member.pageSettings,
  }));

  const dispatch = useDispatch();
  const classes = useStyles();

  let pageSettings =
    find(allPageSettings, (o) => o.page_type === pageType) || {};
  const defaultView = (pageSettings && pageSettings.view_type) || null;
  const defaultPageSize = (pageSettings && pageSettings.page_size) || null;
  const defaultSortOrder = (pageSettings && pageSettings.sort_order) || null;

  let defaultOrderBy = '';
  let defaultOrder = 'asc';

  if (defaultSortOrder && defaultSortOrder[0]) {
    if (defaultSortOrder[0].charAt(0) === '-') {
      defaultOrderBy = defaultSortOrder[0].substring(1);
      defaultOrder = 'desc';
    } else {
      defaultOrderBy = defaultSortOrder[0];
    }
  }

  const [orderByNew, setOrderByNew] = useState(defaultOrderBy);
  const [isAsc, setIsAsc] = useState(defaultOrder === 'asc' ? 'true' : 'false');
  const [viewTypeNew, setViewTypeNew] = useState(defaultView || '');
  const [pageSizeNew, setPageSizeNew] = useState(defaultPageSize || 25);

  const options_contact = [
    {
      id: 0,
      name: 'Full Name',
      value: 'member_name',
    },
    {
      id: 1,
      name: 'First Name',
      value: 'first_name',
    },
    {
      id: 2,
      name: 'Last Name',
      value: 'last_name',
    },
    {
      id: 3,
      name: 'Job Title',
      value: 'title',
    },
    // {
    //   id: 4,
    //   name: 'Office Phone',
    //   value: 'office_phone'
    // },
    // {
    //   id: 5,
    //   name: 'Cell Phone',
    //   value: 'cell_phone'
    // },
    {
      id: 6,
      name: 'City',
      value: 'city',
    },
    {
      id: 7,
      name: 'Country',
      value: 'country',
    },
    {
      id: 8,
      name: 'Create Date',
      value: 'create_date',
    },
    {
      id: 9,
      name: 'Relationship',
      value: 'role',
    },
  ];
  const options_group = [
    {
      id: 0,
      name: 'Group Name',
      value: 'group_name',
    },
    {
      id: 1,
      name: 'Members Count',
      value: 'total_member',
    },
    {
      id: 2,
      name: 'Files Count',
      value: 'total_files',
    },
    {
      id: 3,
      name: 'Create Date',
      value: 'group_create_date',
    },
  ];
  // const options_group_memberships = [
  //   {
  //     id: 0,
  //     name: 'Group Name',
  //     value: 'group_name',
  //   },
  //   {
  //     id: 1,
  //     name: 'Group Leader First Name',
  //     value: 'group_leader_first_name',
  //   },
  //   {
  //     id: 2,
  //     name: 'Group Leader Last Name',
  //     value: 'group_leader_last_name',
  //   },
  //   {
  //     id: 3,
  //     name: 'Members Count',
  //     value: 'total_member',
  //   },
  //   {
  //     id: 4,
  //     name: 'Files Count',
  //     value: 'total_files',
  //   },
  //   {
  //     id: 5,
  //     name: 'Create Date',
  //     value: 'group_create_date',
  //   },
  // ];
  const options_drive = [
    {
      id: 0,
      name: 'File Name',
      value: 'file_name',
    },
    {
      id: 2,
      name: 'File Size',
      value: 'file_size',
    },
    {
      id: 1,
      name: 'Create Date',
      value: 'create_date',
    },
  ];

  const renderSortOptions = () => {
    const options =
      pageType === PAGE_TYPES.contacts
        ? options_contact
        : pageType === PAGE_TYPES.groups
        ? options_group
        : pageType === PAGE_TYPES.drive
        ? options_drive
        : [];
    return options.map((item) => (
      <option key={item.id} value={item.value}>
        {item.name}
      </option>
    ));
  };

  const renderPageSizeOptions = () =>
    React.Children.toArray(
      DEFAULT_PAGE_SIZES.map((item) => <option value={item}>{item}</option>)
    );

  const handleChangeOptions = (e) => {
    const { name, value } = e.target;
    if (name === 'sort') {
      setOrderByNew(value);
    } else if (name === 'page-size') {
      setPageSizeNew(parseInt(value, 10));
    } else {
      console.log('unknown change!');
    }
  };

  const closeModal = () => {
    onClose();
  };

  const handleSubmit = () => {
    let sort = '';

    //sorting and pagination only available for contacts or groups pages
    if (pageType === PAGE_TYPES.contacts || pageType === PAGE_TYPES.groups) {
      if (orderByNew === 'member_name') {
        sort =
          isAsc === 'true' ? `first_name,last_name` : `-first_name,-last_name`;
      } else {
        sort = isAsc === 'true' ? orderByNew : `-${orderByNew}`;
      }
      pageSettings['sort_order'] = sort;

      const orderNew = isAsc === 'true' ? 'asc' : 'desc';
      setOrderBy(orderByNew);
      setOrder(orderNew);
      setPageSize(pageSizeNew);
      onSave(orderByNew, orderNew, null, null, 0, pageSizeNew);
    } else if (pageType === PAGE_TYPES.drive) {
      sort = isAsc === 'true' ? orderByNew : `-${orderByNew}`;
      pageSettings['sort_order'] = sort;
      setOrderBy(orderByNew);
    } else {
      pageSettings['sort_order'] = '';
      onSave();
    }

    // set default if page settings empty
    pageSettings['view_type'] = viewTypeNew;
    pageSettings['page_size'] = pageSizeNew;
    pageSettings['page_type'] = pageType;

    let formData = new FormData();
    Object.keys(pageSettings).map((key) =>
      formData.set(key, pageSettings[key])
    );

    if (!pageSettings['id']) {
      dispatch(postPageSettings(formData));
    } else {
      dispatch(putPageSettings(formData));
    }

    setView(viewTypeNew);
    closeModal();
  };

  const renderSettingFields = () => {
    if (
      pageType === PAGE_TYPES.contacts ||
      pageType === PAGE_TYPES.groups ||
      pageType === PAGE_TYPES.drive
    ) {
      return (
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="sort-native-simple">
              {formatMessage(messages.sortBy)}
            </InputLabel>
            <Select
              native
              value={orderByNew}
              onChange={handleChangeOptions}
              inputProps={{
                name: 'sort',
                id: 'sort-native-simple',
              }}
            >
              <option aria-label="None" value="" />
              {renderSortOptions()}
            </Select>
          </FormControl>
          {/*no proper method to get/set order https://github.com/TimboKZ/Chonky/issues/33 */}
          <FormControl
            disabled={pageType === PAGE_TYPES.drive}
            component="fieldset"
            className={classes.marginTop_16}
          >
            <FormLabel>{formatMessage(messages.orderBy)}</FormLabel>
            <RadioGroup
              aria-label="order_by"
              name="order_by"
              value={isAsc}
              onChange={(e) => setIsAsc(e.target.value)}
              className={classes.radioGroupContainer1}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio disableRipple />}
                label={formatMessage(messages.orderAsc)}
              />
              <FormControlLabel
                value={'false'}
                control={<Radio disableRipple />}
                label={formatMessage(messages.orderDesc)}
              />
            </RadioGroup>
          </FormControl>
          {pageType !== PAGE_TYPES.drive && (
            <FormControl fullWidth className={classes.marginTop_8}>
              <InputLabel htmlFor="page-size-native-simple">
                {(viewTypeNew === 'tile'
                  ? formatMessage(messages.cards)
                  : viewTypeNew === 'table'
                  ? formatMessage(messages.rows)
                  : formatMessage(messages.items)) +
                  ` ${formatMessage(messages.per)} ${formatMessage(
                    messages.page
                  )}`}
              </InputLabel>
              <Select
                native
                value={pageSizeNew}
                onChange={handleChangeOptions}
                inputProps={{
                  name: 'page-size',
                  id: 'page-size-native-simple',
                }}
              >
                {renderPageSizeOptions()}
              </Select>
            </FormControl>
          )}
        </>
      );
    } else if (pageType === PAGE_TYPES.calendar) {
      return (
        <div className={`profile_page-section ${classes.settingsFields}`}>
          <TimezoneList
            timezone_id={calendarState.timezone_id}
            onChange={(tz) => {
              setCalendarState((ps) => ({
                ...ps,
                timezone_id: tz ? tz.tz_id : null,
              }));
            }}
          />
          <Grid container>
            <Grid item xs={6}>
              <DateFormat
                value={calendarState.date_format}
                onChange={(e) => {
                  e.persist();
                  setCalendarState((ps) => ({
                    ...ps,
                    date_format: e.target.value,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TimeFormat
                value={calendarState.time_format}
                onChange={(e) => {
                  e.persist();
                  if (!e.target.checked)
                    setCalendarState((ps) => ({ ...ps, time_format: 'AM/PM' }));
                  else
                    setCalendarState((ps) => ({ ...ps, time_format: '24Hr' }));
                }}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  const renderViewTypeRadioButtons = () => {
    if (pageType === PAGE_TYPES.contacts || pageType === PAGE_TYPES.groups) {
      return (
        <>
          <FormControlLabel
            value="tile"
            control={<Radio color="primary" />}
            label={`${formatMessage(messages.tile)} ${formatMessage(
              messages.view
            )}`}
            labelPlacement="end"
            className={classes.inputRadioContainer}
          />
          <FormControlLabel
            value="table"
            control={<Radio color="primary" />}
            label={`${formatMessage(messages.table)} ${formatMessage(
              messages.view
            )}`}
            labelPlacement="end"
            className={classes.inputRadioContainer}
          />
        </>
      );
    } else if (pageType === PAGE_TYPES.mail) {
      return (
        <>
          <FormControlLabel
            value="list"
            control={<Radio color="primary" />}
            label={`${formatMessage(messages.list)} ${formatMessage(
              messages.view
            )}`}
            labelPlacement="end"
            className={classes.inputRadioContainer}
          />
          <FormControlLabel
            value="table"
            control={<Radio color="primary" />}
            label={`${formatMessage(messages.table)} ${formatMessage(
              messages.view
            )}`}
            labelPlacement="end"
            className={classes.inputRadioContainer}
          />
        </>
      );
    } else if (pageType === PAGE_TYPES.drive) {
      return (
        <>
          <FormControlLabel
            value="list"
            control={<Radio color="primary" />}
            label={`${formatMessage(messages.list)} ${formatMessage(
              messages.view
            )}`}
            labelPlacement="end"
            className={classes.inputRadioContainer}
          />
          <FormControlLabel
            value="tile"
            control={<Radio color="primary" />}
            label={`${formatMessage(messages.tile)} ${formatMessage(
              messages.view
            )}`}
            labelPlacement="end"
            className={classes.inputRadioContainer}
          />
        </>
      );
    } else if (pageType === PAGE_TYPES.calendar) {
      return (
        <>
          <FormControlLabel
            value={CALENDAR_VIEWS.month}
            control={<Radio color="primary" />}
            label={textCapitalized(CALENDAR_VIEWS.month)}
          />
          <FormControlLabel
            value={CALENDAR_VIEWS.week}
            control={<Radio color="primary" />}
            label={textCapitalized(CALENDAR_VIEWS.week)}
          />
          <FormControlLabel
            value={CALENDAR_VIEWS.day}
            control={<Radio color="primary" />}
            label={textCapitalized(CALENDAR_VIEWS.day)}
          />
        </>
      );
    }
  };
  const renderViewTypeIcons = () => {
    if (pageType === PAGE_TYPES.contacts || pageType === PAGE_TYPES.groups) {
      return (
        <>
          <ViewModuleIcon
            onClick={() => setViewTypeNew(PAGE_VIEWS.tile)}
            className={classes.iconTile}
            color={viewTypeNew === PAGE_VIEWS.tile ? 'primary' : 'secondary'}
          />
          <TableChartIcon
            onClick={() => setViewTypeNew(PAGE_VIEWS.table)}
            className={classes.iconTable}
            color={viewTypeNew === PAGE_VIEWS.table ? 'primary' : 'secondary'}
          />
        </>
      );
    } else if (pageType === PAGE_TYPES.mail) {
      return (
        <>
          <ViewListIcon
            onClick={() => setViewTypeNew(PAGE_VIEWS.list)}
            className={classes.iconList}
            color={viewTypeNew === PAGE_VIEWS.list ? 'primary' : 'secondary'}
          />
          <TableChartIcon
            onClick={() => setViewTypeNew(PAGE_VIEWS.table)}
            className={classes.iconTable}
            color={viewTypeNew === PAGE_VIEWS.table ? 'primary' : 'secondary'}
          />
        </>
      );
    } else if (pageType === PAGE_TYPES.drive) {
      return (
        <>
          <ViewListIcon
            onClick={() => setViewTypeNew(PAGE_VIEWS.list)}
            className={classes.iconList}
            color={viewTypeNew === PAGE_VIEWS.list ? 'primary' : 'secondary'}
          />
          <ViewModuleIcon
            onClick={() => setViewTypeNew(PAGE_VIEWS.tile)}
            className={classes.iconTile}
            color={viewTypeNew === PAGE_VIEWS.tile ? 'primary' : 'secondary'}
          />
        </>
      );
    }
  };

  const ModalContent = () => (
    <div className={classes.inputContainer}>
      {renderSettingFields()}
      <FormControl
        component="fieldset"
        className={classes.marginTop_16}
        fullWidth
      >
        <FormLabel>{`${formatMessage(messages.default)} ${formatMessage(
          messages.view
        )}`}</FormLabel>
        <RadioGroup
          row
          onChange={(e) => setViewTypeNew(e.target.value)}
          aria-label="position"
          name="position"
          value={viewTypeNew}
          defaultValue={viewTypeNew}
          className={`${classes.radioGroupContainer2} ${
            pageType === PAGE_TYPES.calendar ? classes.marginTop_16 : ''
          }`}
        >
          {renderViewTypeRadioButtons()}
        </RadioGroup>
        <div className={classes.iconsContainer}>{renderViewTypeIcons()}</div>
      </FormControl>
    </div>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={() => closeModal()}
        >
          {formatMessage(messages.cancel)}
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={handleSubmit}
          >
            {formatMessage(messages.saveChanges)}
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        disabled={loading}
        // title={type === 'contacts' ? 'Contact Settings' : 'Sort Groups'}
        title={`${formatMessage(
          messages[pageType.toLowerCase()]
        )} ${formatMessage(messages.page)} ${formatMessage(messages.settings)}`}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

PageSettingsModal.propTypes = {
  pageType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  setOrderBy: PropTypes.func,
  setOrder: PropTypes.func,
  setPageSize: PropTypes.func,
  setView: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

PageSettingsModal.defaultProps = {
  open: false,
  loading: false,
  setOrderBy: () => null,
  setOrder: () => null,
  setPageSize: () => null,
};

export default injectIntl(PageSettingsModal);
