import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { PopoverWithIcon } from '../../../../components/Modal/PopoverWithIcon';
import { useStyles } from './Compose.style';

export const FolderSelection = ({
  folders = [],
  createFolder,
  selected,
  setSelected,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center">
      <Grid item xs={10}>
        <FormControl
          size="small"
          variant="outlined"
          className={classes.folderSelect}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            Select Folder
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value);
            }}
            label="Select Folder"
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {folders.map((el, index) => (
              <MenuItem
                key={`menu-item-${index}-folder-selection`}
                value={el.id}
              >
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ paddingTop: 10 }}
        >
          <PopoverWithIcon
            icon="addControl"
            handleSubmit={createFolder}
            tooltip="Add new folder"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
