import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import MemberAutocomplete from '../../../../components/MemberAutocomplete';
import { useStyles } from './Compose.style';

export const Recipients = ({
  recips,
  setSelects,
  cc,
  bcc,
  setSecretRecips,
}) => {
  const classes = useStyles();
  const [additionalFields, setAditionalFields] = React.useState({
    cc: cc.length !== 0,
    bcc: bcc.length !== 0,
  });

  const setAdditionalState = (control) => {
    setAditionalFields({
      ...additionalFields,
      [control]: !additionalFields[control],
    });
  };

  return (
    <Grid container item xs={6} direction="column">
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'start' }}>
        <Grid item xs={10}>
          <MemberAutocomplete
            label="Select contacts"
            variant="outlined"
            style={{ width: '100%', marginTop: 10 }}
            values={recips}
            size="small"
            disablerFunction={() => {}}
            handleChange={(event, value) => {
              setSelects(value);
            }}
            showLoggedMember={true}
          />
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="column"
          justify="space-between"
          className={classes.containerBCC}
        >
          {!additionalFields.cc && (
            <div
              onClick={() => {
                setAdditionalState('cc');
              }}
              className={classes.BCC}
            >
              CC
            </div>
          )}
          {!additionalFields.bcc && (
            <div
              onClick={() => {
                setAdditionalState('bcc');
              }}
              className={classes.BCC}
            >
              BCC
            </div>
          )}
        </Grid>
      </Grid>
      {additionalFields.cc && (
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid className={classes.containerBCC} item xs={2}>
            CC :
          </Grid>
          <Grid item xs={8}>
            <MemberAutocomplete
              label="Select contacts"
              variant="outlined"
              style={{ width: '100%', marginTop: 10 }}
              values={cc}
              size="small"
              disablerFunction={() => {}}
              handleChange={(event, value) => {
                setSecretRecips('cc', value);
              }}
              showLoggedMember={true}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              style={{ padding: '7px 12px', marginTop: 10 }}
              onClick={() => {
                setAdditionalState('cc');
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {additionalFields.bcc && (
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid className={classes.containerBCC} item xs={2}>
            BCC :
          </Grid>
          <Grid item xs={8}>
            <MemberAutocomplete
              label="Select contacts"
              variant="outlined"
              style={{ width: '100%', marginTop: 10 }}
              values={bcc}
              size="small"
              disablerFunction={() => {}}
              handleChange={(event, value) => {
                setSecretRecips('bcc', value);
              }}
              showLoggedMember={true}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              style={{ padding: '7px 12px', marginTop: 10 }}
              onClick={() => {
                setAdditionalState('bcc');
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
