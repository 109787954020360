import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';
import ForumIcon from '@material-ui/icons/Forum';
import VideocamIcon from '@material-ui/icons/Videocam';
import CallIcon from '@material-ui/icons/Call';
import MovieIcon from '@material-ui/icons/Movie';
import MailIcon from '@material-ui/icons/Mail';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ShareIcon from '@material-ui/icons/Share';
// import SecurityIcon from '@material-ui/icons/Security';
import HistoryIcon from '@material-ui/icons/History';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import { useHistory } from 'react-router-dom';

import BorderLinearProgress from '../../components/BorderLinearProgressBar';
import GroupDetailModal from './GroupDetailModal';
import GroupDriveModal from './GroupDriveModal';
import MemberShipDetailModal from './MemberShipDetailModal';
import MemberShipDriveModal from './MemberShipDriveModal';
import SaveData from '../../components/Modal/SaveData';
import GroupActivityModal from './GroupActivityModal';
import {
  getGroupDetail,
  getGroupLeaderDetail,
  setShowGroupDetailModal,
  deleteGroups,
  removeGroupMember,
  setReduxGroupAlert,
  setGroupData,
} from '../../redux/actions/group';
import VideoEmailEditorModal from '../../components/Modal/VideoEmailEditorModal';

import { setCallGroupId } from '../../redux/actions/event';
import { openDrawer } from '../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';

import {
  textCapitalized,
  establishGroupChat,
  establishGroupVideoCall,
  establishGroupVideoConference,
} from '../../utils/general';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import SecurityDetailModal from '../../components/Modal/SecurityDetailModal';
import AmeraPagination from '../../components/AmeraPagination';
import { defaultDateTimeZone } from '../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: '1px solid #D4E2F0',
    boxShadow: `0px 2px 1px -1px #D4E2F0, 0px 1px 1px 0px #D4E2F0, 0px 1px 3px 0px #D4E2F0`,
    color: '#5F5F5F',
    // fontFamily: 'Lato, Helvetica Neue, sans-serif',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '32px 40px 23px 23px',
  },
  groupName: {
    fontSize: 18,
    fontWeight: 700,
  },
  securityPercentage: {
    marginTop: -19,
    marginRight: 10,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 700,
    position: 'relative',
    color: 'black',
  },
  securityLabel: {
    fontSize: 12,
    fontWeight: 700,
    paddingLeft: 13,
    marginTop: 5,
    color: 'black',
  },
  selectBox: {
    position: 'absolute',
    right: 0,
    top: 0,
    '& span': {
      color: '#8DA3C5',
    },
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 23px',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: 12,
      color: '#8DA3C5',
      fontWeight: 700,
    },
    '& span': {
      fontSize: 16,
      color: '#5F5F5F',
      fontWeight: 500,
    },
  },
  infoNumber: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: 12,
      color: '#8DA3C5',
      fontWeight: 700,
    },
    '& span': {
      fontSize: 16,
      color: '#5F5F5F',
      fontWeight: 500,
      textAlign: 'center',
    },
  },
  services: {
    margin: '20px 23px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 77,
    height: 69,
    borderRadius: 4,
    border: '1px solid #E1E6F0',
    marginTop: 4,
    marginBottom: 4,
    '& button': {
      color: '#8DA3C5',
    },
    '& label': {
      fontSize: 13,
      color: '#5F5F5F',
    },
    padding: '0 3px',
  },
  buttons: {
    padding: '0 23px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 8,
    '& button': {
      padding: 10,
      background: '#8DA3C5',
      color: '#FFFFFF',
      textTransform: 'capitalize',
      fontSize: 13,
      fontWeight: 400,
      '&:hover': {
        background: '#546682',
      },
    },
  },
  viewDetail: {
    padding: '35px 23px 37px 0px',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      fontSize: 15,
      padding: '8px 30px',
      borderRadius: 7,
    },
  },
}));

const CardContainer = (props) => {
  const {
    type,
    dispatch,
    // ameraGroupSecurity,
    onScheduleClick = () => null,
    openDetailsFor,
    showGroupDetailModal,
    isDrive,
    member,
    groupAlert,

    groups,
    totalCount,
    // orderBy,
    // order,
    pageSize,
    pageNumber,
    // setOrderBy,
    // setOrder,
    setPageSize,
    setPageNumber,
    loadSortedGroups,
  } = props;
  let history = useHistory();
  const classes = useStyles();

  const memberId = member && member.member && member.member.member_id;

  const [isShowSecurityModal, setShowSecurityModal] = useState(false);
  const [showVideoEmailEditor, setShowVideoEmailEditor] = useState(false);

  const [membershipDetail, setMembershipDetail] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showSaveDataModal, setShowSaveDataModal] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [group_id, setGroupId] = useState();
  const [group_leader_id, setGroupLeaderId] = useState();
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    if (groupAlert.show) {
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: groupAlert.variant ? groupAlert.variant : 'success',
          message: '',
        };
        dispatch(setReduxGroupAlert(alertOption));
      }, 5000);
    }
  }, [dispatch, groupAlert]);

  const viewGroup = (group) => {
    if (type === 'group') dispatch(getGroupDetail(group.group_id));
    if (type === 'membership') dispatch(setGroupData(group));
    dispatch(openDrawer(DRAWER_CONTENT_TYPE.groupProfile, {}));
  };

  const showGroupDetail = useCallback(
    (group, isDrive = false) => {
      if (type === 'group') dispatch(getGroupDetail(group.group_id));
      if (type === 'membership') setMembershipDetail(group);
      dispatch(setShowGroupDetailModal(true, isDrive));

      // const isGroupSecurity = filterAmeraGroupSecurity(group.group_id);
      // if (!isGroupSecurity) {
      //   setShowSaveDataModal(true);
      // } else {
      //   dispatch(setShowGroupDetailModal(true));
      // }
    },
    [dispatch, type]
  );

  useEffect(() => {
    if (!!openDetailsFor) {
      showGroupDetail(groups.find((go) => go.group_id === openDetailsFor));
    }
  }, [openDetailsFor, groups, showGroupDetail]);

  // const filterAmeraGroupSecurity = useCallback(
  //   (groupId) => {
  //     for (var i = 0; i < ameraGroupSecurity.length; i++) {
  //       if (ameraGroupSecurity[i].groupId === groupId) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [ameraGroupSecurity]
  // );

  const handleGroupFilesClick = (group) => {
    // Now that the Group files live in that modal
    showGroupDetail(group, true);
  };

  const showGroupSecurity = async (group_id, group_leader_id) => {
    await setGroupId(group_id);
    await setGroupLeaderId(group_leader_id);
    setShowSecurityModal(true);
  };

  const handleSelect = (event, name) => {
    const selectedIndex = selectedGroups.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGroups, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGroups.slice(1));
    } else if (selectedIndex === selectedGroups.length - 1) {
      newSelected = newSelected.concat(selectedGroups.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGroups.slice(0, selectedIndex),
        selectedGroups.slice(selectedIndex + 1)
      );
    }
    setSelectedGroups(newSelected);
  };

  const deleteGroupsFunc = async (group_id) => {
    let formData = new FormData();
    if (group_id) {
      let groupIds = [];
      groupIds.push(group_id);
      formData.set('groupIds', groupIds);
      props.deleteGroups(formData);
    } else {
      formData.set('groupIds', selectedGroups);
      let success = await props.deleteGroups(formData);
      if (success) {
        setSelectedGroups([]);
      }
    }
  };

  const deleteMembershipsFunc = async (group_id) => {
    let formData = new FormData();
    formData.set('groupMemberId', memberId);
    if (group_id) {
      formData.set('groupIds', group_id);
    } else {
      formData.set('groupIds', selectedGroups);
    }
    const success = await dispatch(removeGroupMember(formData, 'membership'));
    if (success) {
      setSelectedGroups([]);
    }
  };

  const openGroupCallAndNotify = async (group, member) => {
    await establishGroupVideoConference(group, member.member);
    await dispatch(setCallGroupId(group.group_id));
    // history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openGroupVideoAndNotify = async (group, member) => {
    const conferenceUrl = await establishGroupVideoCall(group, member.member);
    await dispatch(setCallGroupId(group.group_id));
    history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openGroupChat = async (group, member) => {
    const conferenceUrl = await establishGroupChat(group, member.member);
    // await dispatch(setCallGroupId(group.group_id));
    history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openActivityModal = (group) => {
    setGroupId(group.group_id);
    dispatch(getGroupDetail(group.group_id));
    dispatch(getGroupLeaderDetail(group.group_leader_id));
    setShowActivityModal(true);
  };

  const openVideoEmailEditModal = (group) => {
    setShowVideoEmailEditor(true);
    setGroupId(group.group_id);
    setGroupName(group.group_name);
  };

  const handleSnackbarClose = () => {
    if (groupAlert.show) {
      const alertOption = {
        show: false,
        variant: groupAlert.variant,
        message: '',
      };
      setReduxGroupAlert(alertOption);
    }
  };

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedGroups(null, null, null, null, newPage - 1, null);
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const newPageSize = e.target.value;
    setPageSize(parseInt(newPageSize, 10));
    setPageNumber(1);
    loadSortedGroups(null, null, null, null, 0, newPageSize);
  };

  return (
    <React.Fragment>
      <Collapse in={selectedGroups.length > 0}>
        <EnhancedTableToolbar
          title=""
          type={type}
          numSelected={selectedGroups.length}
          deleteFunc={
            type === 'membership' ? deleteMembershipsFunc : deleteGroupsFunc
          }
        />
      </Collapse>
      <Grid container className="group-container">
        {groups.map((group, index) => (
          <Grid item md={6} sm={12} lg={4} key={index} className="group-card">
            <Card key={group.group_id} className={classes.cardContainer}>
              <div className={classes.header}>
                <div className={classes.leftTitle}>
                  <p className={classes.groupName}>
                    {textCapitalized(group.group_name)}
                  </p>
                </div>
                <div className={classes.rightTitle}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      group?.group_exchange_option
                        ? group.group_exchange_option
                        : 0
                    }
                    onClick={() => {
                      showGroupSecurity(group.group_id, group.group_leader_id);
                    }}
                  />
                  <p className={classes.securityPercentage}>
                    {group?.group_exchange_option
                      ? group.group_exchange_option
                      : 0}
                    %
                  </p>
                  <p
                    className={classes.securityLabel}
                    onClick={() =>
                      showGroupSecurity(group.group_id, group.group_leader_id)
                    }
                  >
                    Security Level
                  </p>
                </div>
                <div className={classes.selectBox}>
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onClick={(e) => handleSelect(e, group.group_id)}
                  />
                </div>
              </div>

              <div className={classes.infoContainer}>
                <div className={classes.info}>
                  <label>Group Leader</label>
                  <span>{group.group_leader_name}</span>
                </div>
                <div className={classes.info}>
                  <label>Department</label>
                  <span>{group.department || ''}</span>
                </div>
                <div className={classes.infoNumber}>
                  <label>Group Members</label>
                  <span>{group.total_member}</span>
                </div>
                <div className={classes.info}>
                  <label>Date Created</label>
                  <span>
                    {(group.group_create_date &&
                      defaultDateTimeZone(group.group_create_date, 'date')) ||
                      ''}
                  </span>
                </div>
              </div>

              <div className={classes.services}>
                <div className={classes.iconContainer}>
                  <IconButton
                    onClick={() => openGroupChat(group, member)}
                    aria-label="Chat"
                  >
                    <ChatIcon />
                  </IconButton>
                  <label>Chat</label>
                </div>
                <div className={classes.iconContainer}>
                  <IconButton aria-label="Email">
                    <MailIcon />
                  </IconButton>
                  <label>Email</label>
                </div>
                <div className={classes.iconContainer}>
                  <IconButton
                    onClick={() => openGroupVideoAndNotify(group, member)}
                    aria-label="Video call"
                  >
                    <VideocamIcon />
                  </IconButton>
                  <label>Video Call</label>
                </div>
                <div className={classes.iconContainer}>
                  <IconButton
                    onClick={() => openGroupCallAndNotify(group, member)}
                    aria-label="Call"
                  >
                    <CallIcon />
                  </IconButton>
                  <label>Call</label>
                </div>
                <div className={classes.iconContainer}>
                  <IconButton
                    aria-label="Schedule"
                    onClick={() => onScheduleClick(group)}
                  >
                    <DateRangeIcon />
                  </IconButton>
                  <label>Schedule</label>
                </div>
                <div className={classes.iconContainer}>
                  <IconButton
                    aria-label="Video Mail"
                    onClick={() => {
                      openVideoEmailEditModal(group);
                    }}
                  >
                    <AlternateEmailIcon />
                  </IconButton>
                  <label>Video Mail</label>
                </div>
              </div>

              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  aria-label="Shared Files"
                  startIcon={<ShareIcon />}
                  onClick={() => handleGroupFilesClick(group)}
                >
                  Shared Files ({group.total_files ? group.total_files : 0})
                </Button>

                <Button
                  variant="contained"
                  aria-label="Videos"
                  startIcon={<MovieIcon />}
                  onClick={() => handleGroupFilesClick(group)}
                >
                  videos ({group.total_files ? group.total_videos : 0})
                </Button>

                {/* <Button
                    variant="contained"
                    aria-label="Group Security Audit"
                    startIcon={<SecurityIcon />}
                    onClick={() => {
                      openActivityModal(group);
                    }}
                  >
                    Security Audit
                  </Button> */}

                <Button
                  variant="contained"
                  aria-label="History"
                  startIcon={<HistoryIcon />}
                  onClick={() => {
                    openActivityModal(group);
                  }}
                >
                  History
                </Button>
                <Button
                  variant="contained"
                  aria-label="Forum"
                  startIcon={<ForumIcon />}
                  onClick={() => {
                    history.push(
                      `${process.env.PUBLIC_URL}/groups/${group.group_id}/forum`
                    );
                  }}
                >
                  Forum
                </Button>
              </div>

              <CardActions disableSpacing className={classes.viewDetail}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => viewGroup(group)}
                >
                  view group
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <SaveData
        show={showSaveDataModal}
        close={() => setShowSaveDataModal(false)}
      ></SaveData>
      {type === 'group' && !isDrive && (
        <GroupDetailModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/my-groups`);
          }}
        ></GroupDetailModal>
      )}
      {type === 'group' && isDrive && (
        <GroupDriveModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/my-groups`);
          }}
        ></GroupDriveModal>
      )}

      {type === 'membership' && !isDrive && (
        <MemberShipDetailModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/membership`);
          }}
          membershipDetail={membershipDetail}
        ></MemberShipDetailModal>
      )}
      {type === 'membership' && isDrive && (
        <MemberShipDriveModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/membership`);
          }}
          membershipDetail={membershipDetail}
        ></MemberShipDriveModal>
      )}

      <SecurityDetailModal
        type="group"
        open={isShowSecurityModal}
        disabled={type === 'membership' ? true : false}
        onClose={() => setShowSecurityModal(false)}
        group_id={group_id}
        group_leader_id={group_leader_id}
      />
      <GroupActivityModal
        show={showActivityModal}
        group_id={group_id}
        onClose={() => setShowActivityModal(false)}
      />
      <AmeraPagination
        totalCount={totalCount}
        rowsPerPage={pageSize}
        page={pageNumber}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
      />
      <VideoEmailEditorModal
        open={showVideoEmailEditor}
        receiver={group_id}
        onClose={() => {
          setShowVideoEmailEditor(false);
        }}
        name={groupName}
        type="group"
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={groupAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={groupAlert.variant}>{groupAlert.message}</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

CardContainer.propTypes = {
  dispatch: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  // ameraGroupSecurity: PropTypes.arrayOf(PropTypes.object),
  groupData: PropTypes.object,
  showGroupDetailModal: PropTypes.bool,
  isDrive: PropTypes.bool,
  type: PropTypes.string,
};

CardContainer.defaultProps = {
  dispatch: null,
  groupList: [],
  // ameraGroupSecurity: [],
  groupData: {},
  showGroupDetailModal: false,
  isDrive: false,
  type: '',
};

const mapStateToProps = (state) => ({
  // ameraGroupSecurity: state.group.ameraGroupSecurity,
  member: state.member,
  groupData: state.group.groupData,
  showGroupDetailModal: state.group.showGroupDetailModal,
  isDrive: state.group.isDrive,
  groupAlert: state.group.groupAlert,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        deleteGroups,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
