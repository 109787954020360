import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialAvatar from '../MaterialAvatar';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '28px 35px 0px',
  },
  closeButton: {
    position: 'absolute',
    right: '35px',
    top: '33px',
    color: '#8083A3',
    border: '0.5px solid #9e9e9e',
  },
  titleWrapper: {
    borderBottom: '1px solid #94C03D',
    paddingBottom: '28px',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.titleWrapper}>
        <Typography variant="h6">{children}</Typography>
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="small"
          elevation={3}
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: '38px',
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root: {},
  contentWrapper: {
    width: '995px',
  },
  header: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000',
    paddingBottom: '22px',
    borderBottom: '1px solid #D5D5D5',
  },
  milestoneHeaderCol: {
    width: '24%',
  },
  milestoneCol: {
    width: '24%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  col: {
    width: '19%',
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    padding: '14px 0px 11px 0px',
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333333',
    borderBottom: '1px solid #D5D5D5',
  },
  avatar: {
    width: '40px',
    height: '40px',
    marginRight: '20px',
  },
  name: {
    fontSize: '15px',
    color: '#363C45',
    fontWeight: 'bold',
  },
  role: {
    fontSize: '15px',
    color: '#657683',
  },
  statusComplete: {
    color: '#94C03D !important',
  },
  status: {
    color: '#363C45',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  milestoneName: {
    fontWeight: 'normal',
  },
}));

export default function MilestoneModal(props) {
  const { open, onClose, project } = props;

  const classes = useStyles();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Project Details &nbsp;&gt;&nbsp; {project.name}
      </DialogTitle>
      <DialogContent>
        <div className={classes.contentWrapper}>
          <div className={classes.header}>
            <div className={classes.milestoneHeaderCol}>Milestone</div>
            <div className={classes.col}>Member</div>
            <div className={classes.col}>Status</div>
            <div className={classes.col}>Date Started</div>
            <div className={classes.col}>Date Completed</div>
          </div>
          {project.milestones &&
            project.milestones.length > 0 &&
            project.milestones.map((milestone, index) => (
              <div className={classes.row} key={milestone.id}>
                <div className={classes.milestoneCol}>
                  {index + 1} &nbsp;
                  <span className={classes.milestoneName}>
                    {milestone.name}
                  </span>
                </div>
                <div className={classes.col}>
                  <MaterialAvatar
                    classes={classes.avatar}
                    member_id={milestone.member_id}
                    firstName={milestone.first_name}
                    lastName={milestone.last_name}
                  />
                  <div>
                    <div className={classes.name}>
                      {milestone.first_name} {milestone.last_name}
                    </div>
                    <div className={classes.role}>{milestone.role}</div>
                  </div>
                </div>
                <div className={classes.col}>
                  <span
                    className={clsx(
                      classes.status,
                      milestone.status === 'complete' && classes.statusComplete
                    )}
                  >
                    {milestone.status === 'complete' ? 'complete' : 'due'}
                  </span>
                </div>
                <div className={classes.col}>{milestone.started}</div>
                <div className={classes.col}>
                  {`${milestone.completed ? milestone.completed : '-'}`}
                </div>
              </div>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
