import React from 'react';
import TextField from '@material-ui/core/TextField';

const PostalInput = (props) => {
  const { onPostalChange, postalCode, errors, addressString } = props;

  let helperText = '';

  if (errors) {
    helperText = errors;
  } else if (!!addressString) {
    helperText = addressString;
  }

  return (
    <TextField
      id="outlined-postal_code"
      label={'Postal Code'}
      type="text"
      required
      variant="outlined"
      name="postal_code"
      placeholder="Postal / ZIP code"
      className="register_form_input"
      onChange={onPostalChange}
      autoComplete="off"
      value={postalCode || ''}
      error={errors ? true : false}
      helperText={helperText}
    />
  );
};

export default PostalInput;
