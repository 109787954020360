import React from 'react';
import Chip from '@material-ui/core/Chip';

const AttributePills = (props) => {
  const { memberRoles, member_id, type, data } = props;
  const member = memberRoles.find(
    (m) => m.member_id.toString() === member_id.toString()
  );

  const attribute = type === 'role' ? member?.roles : member?.privileges;
  if (member && attribute.length > 0) {
    return attribute.map((role_id) => (
      <Chip
        color="secondary"
        key={`${member_id}-${Math.random()}`}
        label={data.find((r) => r.id.toString() === role_id.toString())?.name}
        variant="outlined"
        style={{ margin: '5px 3px 3px 0' }}
      />
    ));
  } else {
    return <p>Currently no {type}s assigned</p>;
  }
};

export default AttributePills;
