import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CommentIcon from '@material-ui/icons/Comment';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import ContactsIcon from '@material-ui/icons/Contacts';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';

import { dark, green, borderDark } from '../../../../../jss/colors';
import Participants from './Participants/index';

const AntTabs = withStyles({
  indicator: {
    backgroundColor: borderDark,
    height: 0,
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: 10,
    minWidth: 68,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 15,
    padding: 0,
    '&:hover': {
      color: dark,
      opacity: 1,
    },
    '&$selected': {
      color: green,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      simple="true"
      hidden={value !== index}
      id={`chat-tabpanel-${index}`}
      aria-labelledby={`chat-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

function TabsPanel(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="chat-tabs-panel">
      <AntTabs value={value} onChange={handleChange} aria-label="ant-chat-tabs">
        <AntTab
          icon={
            value === 0 ? (
              <ContactsIcon fontSize="large" />
            ) : (
              <ContactsOutlinedIcon fontSize="large" />
            )
          }
          label="Participants"
        />
        <AntTab
          icon={
            value === 1 ? (
              <CommentIcon fontSize="large" />
            ) : (
              <CommentOutlinedIcon fontSize="large" />
            )
          }
          label="Private Chats"
        />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <Participants />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="private-conversations">No Private Chats</div>
      </TabPanel>
    </div>
  );
}

export default TabsPanel;
