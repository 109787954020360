import React from 'react';

const FileShareSmallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.282"
    height="25.264"
    viewBox="0 0 25.282 25.264"
  >
    <path d="M2.611,25.265a.533.533,0,0,1-.53-.454L.006,11.984a.536.536,0,0,1,.53-.616h24.21a.535.535,0,0,1,.53.617L23.2,24.809a.533.533,0,0,1-.53.455Zm1.61-14.735V8.843H21.1v1.686Zm17.759,0V4.632H3.413v5.895H1.725V.745A.746.746,0,0,1,2.471,0H8.294a.749.749,0,0,1,.639.362L9.983,2.106H22.923a.746.746,0,0,1,.746.745v7.677ZM4.645,8.42V7.157H20.678V8.42Zm.417-1.681V5.476H20.255V6.739Z" />
  </svg>
);

export default FileShareSmallIcon;
