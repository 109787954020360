import { set } from 'lodash';
import { EVENT_FILTER_TYPE } from '../../utils/calendar';
// const validUrl = require('valid-url');

export const validateScheduleEvent = (formState) => {
  let errors = {};

  // Event subject is not required anymore
  // if (!formState.name) {
  //   set(errors, 'name', 'Event name is required');
  // }

  if (formState.start === formState.end) {
    set(errors, 'datetime', 'Start and end date times match');
  }

  return errors;
};

export const validateEventFilterForm = (filterState) => {
  const { filterType, contacts, group } = filterState;

  if (
    filterType === EVENT_FILTER_TYPE.contacts &&
    (!contacts || contacts.length === 0)
  ) {
    return 'You must select at least one contact';
  }

  if (
    filterType === EVENT_FILTER_TYPE.group &&
    (!group || Object.keys(group).length === 0)
  ) {
    return 'You must select a group';
  }

  return null;
};
