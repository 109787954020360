import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottom: '1px solid #D4E2F0',
    gap: 8,
    padding: 20,
    marginLeft: -20,
    backgroundColor: 'white',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
  },
  search: {
    height: 38,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    '& svg': {
      marginLeft: 20,
      marginRight: 8,
      color: '#8DA3C5',
    },
  },
}));

const Toolbar = (props) => {
  const { buttons, searchKey, onSearch } = props;
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <div className={classes.search}>
        <SearchIcon />
        <InputBase
          placeholder="Search..."
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={searchKey}
          onChange={onSearch}
        />
      </div>
      <div className={classes.buttons}>
        {buttons.map((button) => {
          return (
            <Button
              variant="contained"
              className={classNames(button.class)}
              onClick={button.onClick}
              color={button.color}
              key={button.name}
            >
              {button.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Toolbar;
