import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import SnackBar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Modal from '../../modal';
import usePrevious from '../../../../hooks/usePrevious';
import { deleteCancelEvent } from '../../../../redux/actions/schedule_event';
import {
  RECURRENCE_FREQ,
  RECURRING_CANCEL_OPTIONS,
} from '../../../../utils/calendar';
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

const defaultSnack = { isShow: false, variant: 'success', message: '' };
const SNACK_TIMEOUT = 1000;
const CLOSE_TIMEOUT = SNACK_TIMEOUT + 500;

const EventCancelModal = (props) => {
  const { dispatch, show, onClose, error, isCancelling, selectedEvent } = props;
  const prevCancelling = usePrevious(isCancelling);

  const [snackState, setSnackState] = useState(defaultSnack);
  const [deleteOption, setOption] = useState(RECURRING_CANCEL_OPTIONS.onlyThis);

  const classes = useStyles();

  useEffect(() => {
    if (prevCancelling === true && isCancelling === false) {
      if (!error) {
        setSnackState({
          isShow: true,
          variant: 'success',
          message: 'Event cancelled successfully',
        });
        setTimeout(() => onClose(), [CLOSE_TIMEOUT]);
      } else {
        // console.log('error from redux', error);
        setSnackState({
          isShow: true,
          variant: 'error',
          message: error,
        });
      }
    }
  }, [error, prevCancelling, isCancelling, onClose]);

  const handleYesClick = () => {
    dispatch(deleteCancelEvent({ option: deleteOption, event: selectedEvent }));
  };

  const isRecurring = !(
    selectedEvent.event_recurrence_freq === RECURRENCE_FREQ.none
  );

  const ModalContent = () => {
    if (!isRecurring) {
      return (
        <Typography>Are you sure you want to cancel this event?</Typography>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item>
            <Typography>Please select an option:</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                name="recurrence_cancel"
                value={deleteOption}
                onChange={(e) => setOption(e.target.value)}
              >
                <FormControlLabel
                  value={RECURRING_CANCEL_OPTIONS.onlyThis}
                  control={<Radio />}
                  label={`Only cancel the instance scheduled to start on ${format(
                    parseISO(selectedEvent.start),
                    'MM/dd/yyyy'
                  )}`}
                />
                <FormControlLabel
                  value={RECURRING_CANCEL_OPTIONS.allUpcoming}
                  control={<Radio />}
                  label="Cancel selected and all upcoming instances"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      );
    }
  };

  const ModalAction = () => (
    <div className="create-event-footer">
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={onClose}
        >
          No
        </Button>
        {isCancelling ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            Cancelling ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={handleYesClick}
          >
            Yes
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <SnackBar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackState.isShow}
        autoHideDuration={SNACK_TIMEOUT}
        onClose={() => setSnackState((ps) => ({ ...ps, isShow: false }))}
      >
        <Alert style={{ minHeight: 'unset' }} severity={snackState.variant}>
          <div>{snackState.message}</div>
        </Alert>
      </SnackBar>
      <Modal
        open={show}
        onClose={onClose}
        title={isRecurring ? 'Cancel recurring event' : 'Cancel event'}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        // maxWidth="sm"
        className="delete-event-modal"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isCancelling: state.scheduleEvents.isCancelling,
  error: state.scheduleEvents.error,
});

export default connect(mapStateToProps)(EventCancelModal);
