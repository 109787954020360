export const GET_STREAM_VIDEO_FAILED = 'GET_STREAM_VIDEO_FAILED';
export const GET_STREAM_VIDEO_SUCCEEDED = 'GET_STREAM_VIDEO_SUCCEEDED';

export const POST_STREAM_CONTENT_SUCCEEDED = 'POST_STREAM_CONTENT_SUCCEEDED';
export const POST_STREAM_CONTENT_FAILED = 'POST_STREAM_CONTENT_FAILED';

export const GET_STREAM_TYPE_FAILED = 'GET_STREAM_TYPE_FAILED';
export const GET_STREAM_TYPE_SUCCEEDED = 'GET_STREAM_TYPE_SUCCEEDED';

export const GET_STREAM_CATEGORY_FAILED = 'GET_STREAM_CATEGORY_FAILED';
export const GET_STREAM_CATEGORY_SUCCEEDED = 'GET_STREAM_CATEGORY_SUCCEEDED';

export const DELETE_STREAM_VIDEO_SUCCEEDED = 'DELETE_STREAM_VIDEO_SUCCEEDED';
export const DELETE_STREAM_VIDEO_FAILED = 'DELETE_STREAM_VIDEO_FAILED';

export const UPDATE_STREAM_VIDEO_SUCCEEDED = 'UPDATE_STREAM_VIDEO_SUCCEEDED';
export const UPDATE_STREAM_VIDEO_FAILED = 'UPDATE_STREAM_VIDEO_FAILED';
