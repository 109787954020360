import React, { useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import DepartmentSelect from '../../DepartmentSelect';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import DepartmentMembersListItem from './DepartmentMembersListItem';
import { includes } from 'lodash';
// import makeStyles from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: { width: '100%' },
// }));

const noDepartment = {
  company_department_id: 'none',
  department_id: 'none',
  department_name: 'Not Specified',
};

const MemberDepartmentsTransfer = (props) => {
  const { departments, companyMembers, onLeadershipClick, onMembersTransfer } =
    props;
  const [leftDepartment, setLeftDepartment] = useState(noDepartment);
  const [leftSelected, setLeftSelected] = useState([]);
  const [rightDepartment, setRightDepartment] = useState(departments[0]);
  const [rightSelected, setRightSelected] = useState([]);

  const renderedDepartments = [...departments, noDepartment];

  // For the above - not specified will have department_name === null
  const leftMembers = useMemo(() => {
    return (
      companyMembers.filter((cmo) =>
        leftDepartment.department_name === 'Not Specified'
          ? cmo.department_name === null
          : cmo.department_name === leftDepartment.department_name
      ) || []
    );
  }, [companyMembers, leftDepartment.department_name]);

  const rightMembers = useMemo(() => {
    return (
      companyMembers.filter((cmo) =>
        rightDepartment.department_name === 'Not Specified'
          ? cmo.department_name === null
          : cmo.department_name === rightDepartment.department_name
      ) || []
    );
  }, [companyMembers, rightDepartment.department_name]);

  const handleLeftSelect = (cmo) =>
    setLeftSelected((ps) => {
      if (
        includes(
          ps.map((m) => m.member_id),
          cmo.member_id
        )
      ) {
        return ps.filter((m) => m.member_id !== cmo.member_id);
      } else {
        return [...ps, cmo];
      }
    });

  const handleRightSelect = (cmo) =>
    setRightSelected((ps) => {
      if (
        includes(
          ps.map((m) => m.member_id),
          cmo.member_id
        )
      ) {
        return ps.filter((m) => m.member_id !== cmo.member_id);
      } else {
        return [...ps, cmo];
      }
    });

  const handleLeftRightClick = () => {
    onMembersTransfer(leftSelected, rightDepartment);
  };

  const handleRightLeftClick = () => {
    onMembersTransfer(rightSelected, leftDepartment);
  };

  const handleLeftDepartment = (e) => setLeftDepartment(e.target.value);
  const handleRightDepartment = (e) => setRightDepartment(e.target.value);

  return (
    <Grid item container xs={12} spacing={1} direction="row">
      <Grid item xs={6}>
        <DepartmentSelect
          companyDepartments={renderedDepartments}
          selected={leftDepartment}
          disabledDepartment={rightDepartment}
          onChange={handleLeftDepartment}
        />
      </Grid>
      <Grid item xs={6}>
        <DepartmentSelect
          companyDepartments={renderedDepartments}
          selected={rightDepartment}
          disabledDepartment={leftDepartment}
          onChange={handleRightDepartment}
        />
      </Grid>
      <Grid item xs={5}>
        <List dense>
          {leftMembers?.length > 0 ? (
            leftMembers.map((cmo) => (
              <DepartmentMembersListItem
                key={cmo.company_member_id}
                data={cmo}
                isSelected={
                  !!leftSelected.find(
                    (smo) => smo.company_member_id === cmo.company_member_id
                  )
                }
                onLeaderShipClick={onLeadershipClick}
                onClick={handleLeftSelect}
              />
            ))
          ) : (
            <div>No members</div>
          )}
        </List>
      </Grid>
      <Grid
        item
        xs={2}
        container
        spacing={1}
        direction="column"
        justify="center"
      >
        <Grid item xs={1}>
          <Tooltip title="Transfer to the right list">
            <span>
              <IconButton
                onClick={handleLeftRightClick}
                disabled={leftSelected?.length === 0}
              >
                <ChevronRightIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title="Transfer to the left list">
            <span>
              <IconButton
                onClick={handleRightLeftClick}
                disabled={rightSelected?.length === 0}
              >
                <ChevronLeftIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <List dense>
          {rightMembers?.length > 0 ? (
            rightMembers.map((cmo) => (
              <DepartmentMembersListItem
                key={cmo.company_member_id}
                data={cmo}
                isSelected={
                  !!rightSelected.find(
                    (smo) => smo.company_member_id === cmo.company_member_id
                  )
                }
                onLeaderShipClick={onLeadershipClick}
                onClick={handleRightSelect}
              />
            ))
          ) : (
            <div>No members</div>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default MemberDepartmentsTransfer;
