import React from 'react';
import Grid from '@material-ui/core/Grid';
import { parseISO } from 'date-fns';
import { connect, useSelector } from 'react-redux';
import ColorSelectSample from '../ColorSelectSample';
import Typography from '@material-ui/core/Typography';
import {
  eventTimeText,
  recurrenceText,
  RECURRENCE_FREQ,
  calcEventDuration,
  eventDisplayName,
} from '../../../../utils/calendar';

const TitleAndDatetime = (props) => {
  const { colors, event } = props;
  const {
    event_color_id,
    start,
    end,
    is_full_day,
    event_recurrence_freq,
    end_condition,
    end_date_datetime,
    repeat_times,
    repeat_weekdays,
  } = event;
  const isRecurring = event_recurrence_freq !== RECURRENCE_FREQ.none;

  const memberId = useSelector((state) => state.member.member.member_id);

  const color = !!event_color_id
    ? colors.find((co) => co.id === event_color_id).color
    : 'rgba(0,0,0,0)';

  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <ColorSelectSample
            color={color}
            border={!!event_color_id ? null : '2px solid gray'}
          />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item xs={12}>
            <Typography variant="h6">
              {eventDisplayName(event, memberId)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {eventTimeText(parseISO(start), parseISO(end), is_full_day)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Duration: {calcEventDuration(parseISO(start), parseISO(end))}
            </Typography>
          </Grid>
          {isRecurring && (
            <Grid item xs={12}>
              <Typography variant="body2">
                {recurrenceText(
                  event_recurrence_freq,
                  end_condition,
                  parseISO(end_date_datetime),
                  repeat_times,
                  JSON.parse(repeat_weekdays)
                )}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  colors: state.scheduleEvents.colors,
});

export default connect(mapStateToProps)(TitleAndDatetime);
