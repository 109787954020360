import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, Switch, Redirect, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Layout from '../Sections/Layout';
import InviteActivity from './InviteActivity';
import SessionActivity from './SessionActivity';
import GlobalUserBehaviour from './GlobalUserBehaviour';
import GlobalBugReports from './GlobalBugReports';
import GlobalCompanyAdmin from './GlobalCompanyAdmin';
import GlobalRegisteredMembers from './GlobalRegisteredMembers';
import styles from '../../jss/views/activityStyle';
import { useSelector } from 'react-redux';

function TabPanel({ children, value, active, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== active}
      id={`tabs-router-${index}`}
      aria-labelledby={`tabs-router-${index}`}
      {...other}
    >
      {value === active && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  active: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tabs-router-${index}`,
    'aria-controls': `tabs-router-${index}`,
  };
}

const useStyles = makeStyles(styles);

const ActivityTabs = (props) => {
  const classes = useStyles();
  const isAdmin = useSelector(
    (state) => state.member.member.user_type === 'administrator'
  );

  const location = useLocation();
  let currentTab = location.pathname;

  const [timeZone, setTimeZone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const tabOptions = [
    {
      id: 0,
      label: 'My Invites',
      value: '/statistic/my-invites',
      visible: true,
      component: (
        <InviteActivity timeZone={timeZone} setTimeZone={setTimeZone} />
      ),
    },
    {
      id: 1,
      label: 'My Session',
      value: '/statistic/my-sessions',
      visible: true,
      component: (
        <SessionActivity timeZone={timeZone} setTimeZone={setTimeZone} />
      ),
    },
    {
      id: 2,
      label: 'Global Invites',
      value: '/statistic/global-invites',
      visible: isAdmin,
      component: (
        <InviteActivity
          isCanSeeAllInvites
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      ),
    },
    {
      id: 3,
      label: 'Global Session',
      value: '/statistic/global-sessions',
      visible: isAdmin,
      component: (
        <SessionActivity
          isCanSeeAllSessions
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      ),
    },
    {
      id: 4,
      label: 'User Behavior',
      value: '/statistic/user-behavior',
      visible: isAdmin,
      component: (
        <GlobalUserBehaviour
          isCanSeeAll={false}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          {...props}
        />
      ),
    },
    {
      id: 5,
      label: 'Bug Reports',
      value: '/statistic/bug-reports',
      visible: isAdmin,
      component: (
        <GlobalBugReports
          isCanSeeAll={isAdmin}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          {...props}
        />
      ),
    },
    {
      id: 6,
      label: 'Company Admin',
      value: '/statistic/admin-company',
      visible: isAdmin,
      component: (
        <GlobalCompanyAdmin
          isAdmin={isAdmin}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      ),
    },
    {
      id: 7,
      label: 'Registrations',
      value: '/statistic/registrations',
      visible: isAdmin,
      component: (
        <GlobalRegisteredMembers
          isAdmin={isAdmin}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
      ),
    },
  ];

  return (
    <Layout {...props} location={location}>
      <div className={classes.activity}>
        <AppBar position="static" color="default">
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            aria-label="activity tabs"
          >
            {tabOptions.map((tab) => {
              const { id, label, value, visible } = tab;
              return visible ? (
                <Tab
                  key={`tab-bar-${id}`}
                  label={label}
                  component={Link}
                  value={value}
                  to={value}
                  {...a11yProps(id)}
                />
              ) : null;
            })}
          </Tabs>
        </AppBar>
        {tabOptions.map((tab) => {
          const { id, value, component } = tab;
          return (
            <TabPanel
              key={`tab-panel-${id}`}
              className={classes.tabPanel}
              value={currentTab}
              active={value}
              index={id}
            >
              {component}
            </TabPanel>
          );
        })}
      </div>
    </Layout>
  );
};

export default function Activity() {
  return (
    <div>
      <Switch>
        <Route exact path="/statistic">
          <Redirect to="/statistic/my-invites" />
        </Route>
        <ActivityTabs />
      </Switch>
    </div>
  );
}
