import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Sound from 'react-sound';

import Layout from '../Sections/Layout';

import { videoResolutionConstraints } from '../../utils/webcam/webrtc';
import AmeraWebrtcClient from '../../utils/webcam/client';
import usePrevious from '../../hooks/usePrevious';
import {
  setLocalStream,
  setLocalDevices,
  setLocalSelected,
  setHasEchoCancellation,
  setLocalVideoEnabled,
  setLocalAudioEnabled,
  setClearCall,
  setCallEndedByRemote,
  getMemberInfo,
} from '../../redux/actions/one2onevcall';

import FirstScreenMode from './FirstScreenMode';
import SecondScreenMode from './SecondScreenMode';
import ThirdScreenMode from './ThirdScreenMode';
import { sendNotification, clearReplyMessage } from '../../redux/actions/event';
// import { clearReplyMessage } from '../../redux/actions/event';
import withLanguageDrawer from '../Sections/LanguageDrawer/withLanguageDrawer';
import { messages } from '../Sections/Layout/index';

import ringtone from '../../assets/media/ringback tone.mp3';
import { stopStream } from '../../utils/webcam/webrtc';
import { setSnackbarData } from '../../redux/actions/snackbar';

const drawerMessages = { ...messages };

let awc = null,
  worker,
  streamRef = null;

const One2OneVCall = (props) => {
  const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(true);
  const [remoteAudioEnabled, setRemoteAudioEnabled] = useState(true);
  const [audioOutput, setAudioOutput] = useState(undefined);
  const [remoteSelected, setRemoteSelected] = useState(null);
  const [showCallStatusModal, setShowCallStatusModal] = useState(false);
  const [ameraWebrtcClient, setAmeraWebrtcClient] = useState(null);
  const [viewMode, setViewMode] = React.useState('second_mode');
  const [connectionType, setConnectionType] = useState(null);
  const [playStatus, setPlayStatus] = useState('PLAYING');

  let localVideoRef = useRef(null);
  let remoteVideoRef = useRef(null);
  const previousViewMode = usePrevious(viewMode);
  const history = useHistory();

  const handleViewMode = (event, newAlignment) => {
    setViewMode(newAlignment);
  };

  const {
    memberInfo,
    localStream,
    remoteStream,
    localDevices,
    remoteDevices,
    // ameraWebrtcClient,
    localSelected,
    localAudioEnabled,
    localVideoEnabled,
    hasEchoCancellation,
    encryptEnabled,
    remoteEncryptEnabled,
    binaryString,
    replyMessage,
    login,
    partnerInfo,
    callReply,
    member_id,
    callStarted,
    callEndedByRemote,
    callType,
    audioOnly,
    dispatch,
  } = props;

  let previousEncryptEnabled = usePrevious(encryptEnabled);
  let previousRemoteEncryptEnabled = usePrevious(remoteEncryptEnabled);
  const previousHasEchoCancellation = usePrevious(hasEchoCancellation);

  useEffect(() => {
    /**
     * check if devices are available and browser supports Insertable Streams.
     * get devices, stream, also send local stream
     */

    const firstTime = async (user) => {
      worker = new Worker(`${process.env.PUBLIC_URL}/worker.js`);
      await start();
      awc = new AmeraWebrtcClient('contact', worker);
      await awc.connectWebsocket(user);
      setAmeraWebrtcClient(awc);
    };

    if (
      memberInfo &&
      memberInfo.email &&
      memberInfo.member_id &&
      memberInfo.first_name &&
      memberInfo.last_name
    ) {
      firstTime({
        username: memberInfo.email,
        member_id: memberInfo.member_id,
        first_name: memberInfo.first_name,
        last_name: memberInfo.last_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberInfo.email, memberInfo.first_name, memberInfo.last_name]);

  useEffect(() => {
    if (ameraWebrtcClient && ameraWebrtcClient.connection && localStream)
      getTrackChange();
    getConnectionType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ameraWebrtcClient, localStream]);

  const getStream = async (constraints) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      stopStream(streamRef);
      dispatch(setLocalStream(stream));
      streamRef = stream;
      dispatch(
        setLocalSelected({
          changeByUser: false,
          audioinput: stream.getAudioTracks()[0].getSettings().deviceId,
          videoinput: stream.getVideoTracks()[0].getSettings().deviceId,
        })
      );
    } catch (error) {
      console.log('media error');
      handleMediaError(error);
    }
  };

  const reload = async () => {
    await start();
  };

  const reloadByEncryption = async () => {
    // if (localVideoRef.current) localVideoRef.current.srcObject = null;
    if (remoteVideoRef.current && remoteStream)
      remoteVideoRef.current.srcObject = null;
    // await sleepy(1000);
    // await start();
    if (remoteVideoRef.current && remoteStream)
      remoteVideoRef.current.srcObject = remoteStream;
  };

  useEffect(() => {
    if (
      previousEncryptEnabled === undefined &&
      previousRemoteEncryptEnabled === undefined
    )
      return;
    reloadByEncryption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptEnabled, remoteEncryptEnabled]);

  useEffect(() => {
    if (viewMode && previousViewMode !== undefined) reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  useEffect(() => {
    if (localSelected && localSelected.changeByUser) {
      // device changed by user
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSelected]);

  useEffect(() => {
    if (previousHasEchoCancellation !== undefined) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEchoCancellation]);

  useEffect(() => {
    if (
      !ameraWebrtcClient ||
      !localSelected ||
      !localDevices ||
      !ameraWebrtcClient.connect
    )
      return;
    const myDevices = localDevices.map((device) => ({
      kind: device.kind,
      label: device.label,
      deviceId: device.deviceId,
      selected: localSelected[device.kind] === device.deviceId,
    }));

    const myoptions = {
      type: 'devices',
      devices: myDevices,
    };
    ameraWebrtcClient.sendUserMessage(myoptions);
  }, [ameraWebrtcClient, localSelected, localDevices]);

  useEffect(() => {
    if (login && replyMessage) {
      // debugger;
      sendNotification(replyMessage);
      dispatch(clearReplyMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, replyMessage]);

  const start = async () => {
    let constraints = {
      audio: {
        deviceId:
          localSelected && localSelected.audioinput
            ? localSelected.audioinput
            : undefined,
      },
      echoCancellation: {
        exact: hasEchoCancellation,
      },
    };
    // start with normal device selected, add in resolution that might be requested
    // the request will be an object with potentially multiple key values, like
    let videoConstraints = {
      deviceId:
        localSelected && localSelected.videoinput
          ? localSelected.videoinput
          : undefined,
    };
    if (localSelected && localSelected.videores) {
      videoConstraints = {
        ...videoConstraints,
        ...videoResolutionConstraints[localSelected.videores],
      };
    }

    constraints.video = videoConstraints;
    if (audioOnly) constraints.video = false;

    await getStream(constraints);
    await getDevices();
    navigator.mediaDevices.addEventListener('devicechange', reload);
  };

  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      dispatch(setLocalDevices(devices));
    } catch (error) {
      console.log('error error', error);
    }
  };

  const getTrackChange = () => {
    if (!localStream || !ameraWebrtcClient) return;
    // https://stackoverflow.com/questions/57235211/webrtcs-replacetrack-suddenly-not-working-in-firefox
    if (ameraWebrtcClient.connection) {
      ameraWebrtcClient.connection.getSenders().forEach((sender) => {
        sender.replaceTrack(
          localStream.getTracks().find(function (track) {
            return sender.track.kind ? track.kind === sender.track.kind : false;
          })
        );
      });
    }
  };

  const getConnectionType = () => {
    if (!localStream || !ameraWebrtcClient) return;

    if (
      ameraWebrtcClient.connection &&
      ameraWebrtcClient.connection.connectionType
    ) {
      setConnectionType(ameraWebrtcClient.connection.connectionType);
    }
  };

  const handleMediaError = (error) => {
    console.log('error', error);
    console.log(`start(): : ${error.name}, ${error.message} ${error.stack}`);
    dispatch(
      setSnackbarData({
        open: true,
        message: 'Check your webcam and microphone!',
        type: 'error',
      })
    );
    // restartOnError();
    // alert(`getUserMedia ${error.name}:\n${error.message}`);
  };

  // const restartOnError = async () => {
  //   console.log('restartOnError');
  //   await sleepy(250);
  //   start();
  // };

  const clearAll = () => {
    if (awc) {
      awc.handleClose();
      if (awc.ws) {
        awc.ws.close();
        awc.ws = null;
      }
    }
    stopStream(streamRef);
    streamRef = null;
    dispatch(setClearCall());
  };

  useEffect(() => {
    window.addEventListener('beforeunload', clearAll);
    setPlayStatus('PLAYING');

    return () => {
      window.removeEventListener('beforeunload', clearAll);
      setPlayStatus('STOPPED');
      clearAll();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStream && localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
      // if (localStream) localVideoRef.current.setAttribute('controls', true);
      muteAudio(localAudioEnabled);
      muteVideo(localVideoEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStream, localVideoRef.current]);

  useEffect(() => {
    if (remoteVideoRef.current) {
      remoteVideoRef.current.srcObject = remoteStream;
      // if (remoteStream) remoteVideoRef.current.setAttribute('controls', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteStream, remoteVideoRef.current]);

  const handleLocalVideo = () => {
    dispatch(setLocalVideoEnabled(!localVideoEnabled));
  };

  const handleLocalAudio = () => {
    dispatch(setLocalAudioEnabled(!localAudioEnabled));
  };

  useEffect(() => {
    muteAudio(localAudioEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAudioEnabled]);

  useEffect(() => {
    muteVideo(localVideoEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localVideoEnabled]);

  const muteAudio = (onoff = null) => {
    if (localStream)
      localStream
        .getAudioTracks()
        .forEach((t) => (t.enabled = onoff != null ? onoff : !t.enabled));
  };
  const muteVideo = (onoff = null) => {
    if (localStream)
      localStream
        .getVideoTracks()
        .forEach((t) => (t.enabled = onoff != null ? onoff : !t.enabled));
  };

  const handleLocalAudioChange = (event) => {
    dispatch(
      setLocalSelected({
        ...localSelected,
        changeByUser: true,
        audioinput: event.target.value,
      })
    );
  };

  const handleLocalVideoChange = (event) => {
    dispatch(
      setLocalSelected({
        ...localSelected,
        changeByUser: true,
        videoinput: event.target.value,
      })
    );
    reload();
  };

  const handleEchoCancellation = () => {
    dispatch(setHasEchoCancellation(!hasEchoCancellation));
  };
  const changeAudioDestination = async (event) => {
    event.persist();
    const result = await attachSinkId(
      localVideoRef.current,
      event.target.value
    );
    if (result) setAudioOutput(event.target.value);
    else setAudioOutput('default');
  };

  /** Attach audio output device to video element using device/sink ID. event handler for change audio dest
   *
   * @param {*} element
   * @param {*} sinkId
   */
  const attachSinkId = (element, sinkId) => {
    return new Promise((resolve, reject) => {
      if (typeof element.sinkId !== 'undefined') {
        element
          .setSinkId(sinkId)
          .then(() => {
            console.log(`Success, audio output device attached: ${sinkId}`);
            resolve(true);
          })
          .catch((error) => {
            let errorMessage = error;
            if (error.name === 'SecurityError') {
              errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
            }
            console.error(errorMessage);
            // Jump back to first output device in the list as it's the default.
            resolve(false);
          });
      } else {
        console.warn('Browser does not support output device selection.');
        resolve(false);
      }
    });
  };

  const handleRemoteVideo = () => {
    if (!ameraWebrtcClient) return;
    const mytoggle = {
      kind: 'videoinput',
      enabled: !remoteVideoEnabled,
    };

    ameraWebrtcClient.sendUserMessage({
      type: 'toggletrack',
      toggle: mytoggle,
    });
    setRemoteVideoEnabled((prev) => !prev);
  };

  const handleRemoteAudio = () => {
    if (!ameraWebrtcClient) return;
    const mytoggle = {
      kind: 'audioinput',
      enabled: !remoteAudioEnabled,
    };

    ameraWebrtcClient.sendUserMessage({
      type: 'toggletrack',
      toggle: mytoggle,
    });
    setRemoteAudioEnabled((prev) => !prev);
  };

  const handleRemoteAudioChange = (event) => {
    event.persist();
    setRemoteSelected((prev) =>
      prev
        ? { audioinput: event.target.value }
        : { ...prev, audioinput: event.target.value }
    );
  };

  const handleRemoteVideoChange = (event) => {
    event.persist();
    setRemoteSelected((prev) =>
      prev
        ? { videoinput: event.target.value }
        : { ...prev, videoinput: event.target.value }
    );
  };

  const handleRemoteResolutionChange = (event) => {
    event.persist();
    setRemoteSelected((prev) =>
      prev
        ? { videores: event.target.value }
        : { ...prev, videores: event.target.value }
    );
  };

  useEffect(() => {
    if (remoteSelected) {
      if (ameraWebrtcClient)
        ameraWebrtcClient.sendUserMessage({
          type: 'changedevices',
          select: {
            constraints: { video: true, audio: true },
            devices: remoteSelected,
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteSelected]);

  const handleCloseCall = () => {
    setPlayStatus('STOPPED');
    if (ameraWebrtcClient) {
      ameraWebrtcClient.handleClose();
      if (ameraWebrtcClient.ws) {
        awc.ws.close();
        awc.ws = null;
      }
    }
    history.goBack();
  };
  const handleCloseCallFromModal = () => {
    setPlayStatus('STOPPED');
    if (showCallStatusModal) {
      dispatch({ type: 'CALL_REPLY', payload: null });
      setShowCallStatusModal(false);
    } else {
      dispatch(setCallEndedByRemote(false));
    }
    history.goBack();
  };

  const makeCall = () => {
    if (ameraWebrtcClient) {
      dispatch({ type: 'CALL_REPLY', payload: null });
      console.log('makeCall!!', partnerInfo);
      ameraWebrtcClient.call(partnerInfo.email);
    }
  };

  useEffect(() => {
    if (callReply) {
      if (partnerInfo && partnerInfo.member_id === callReply.sender) {
        if (callReply.response === 'decline') {
          setShowCallStatusModal(true);
        } else {
          makeCall();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callReply]);

  useEffect(() => {
    if (member_id) {
      dispatch(getMemberInfo(member_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member_id]);

  const childProps = {
    localAudioEnabled,
    localVideoEnabled,
    localSelected,
    localDevices,
    audioOutput,
    hasEchoCancellation,
    binaryString,
    handleLocalVideo,
    handleLocalAudio,
    handleEchoCancellation,
    handleLocalAudioChange,
    changeAudioDestination,
    handleLocalVideoChange,
    handleRemoteVideoChange,
    handleRemoteAudioChange,
    handleRemoteVideo,
    handleRemoteAudio,
    localVideoRef,
    remoteVideoRef,
    remoteDevices,
    handleRemoteResolutionChange,
    handleViewMode,
    viewMode,
    showCallStatusModal,
    setShowCallStatusModal,
    handleCloseCall,
    makeCall,
    handleCloseCallFromModal,
    localStream,
    remoteStream,
    partnerInfo,
    ameraWebrtcClient,
    callStarted,
    connectionType,
    callEndedByRemote,
  };

  return (
    <Layout {...props}>
      {callType === 'call' && (
        <Sound
          url={ringtone}
          playStatus={
            playStatus === 'PLAYING' &&
            callType === 'call' &&
            !callStarted &&
            !callEndedByRemote &&
            !(callReply && partnerInfo.member_id === callReply.sender)
              ? 'PLAYING'
              : 'STOPPED'
          }
          volume={75}
          onFinishedPlaying={() => setPlayStatus('STOPPED')}
        />
      )}
      {viewMode === 'first_mode' ? (
        <FirstScreenMode {...childProps} />
      ) : viewMode === 'second_mode' ? (
        <SecondScreenMode {...childProps} />
      ) : (
        <ThirdScreenMode {...childProps} />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  partner: state.one2onevcall.partner,
  localStream: state.one2onevcall.localStream,
  remoteStream: state.one2onevcall.remoteStream,
  localDevices: state.one2onevcall.localDevices,
  remoteDevices: state.one2onevcall.remoteDevices,
  // ameraWebrtcClient: state.one2onevcall.ameraWebrtcClient,
  localSelected: state.one2onevcall.localSelected,
  hasEchoCancellation: state.one2onevcall.hasEchoCancellation,
  localAudioEnabled: state.one2onevcall.localAudioEnabled,
  localVideoEnabled: state.one2onevcall.localVideoEnabled,
  encryptEnabled: state.one2onevcall.encryptEnabled,
  remoteEncryptEnabled: state.one2onevcall.remoteEncryptEnabled,
  binaryString: state.one2onevcall.binaryString,
  login: state.one2onevcall.login,
  partnerInfo: state.one2onevcall.partnerInfo,
  replyMessage: state.event.replyMessage,
  callReply: state.event.callReply,
  member_id: state.one2onevcall.member_id,
  memberInfo: state.member.memberInfo,
  callEndedByRemote: state.one2onevcall.callEndedByRemote,
  callStarted: state.one2onevcall.callStarted,
  callType: state.one2onevcall.callType,
  audioOnly: state.one2onevcall.audioOnly,
});

export default connect(mapStateToProps)(
  withLanguageDrawer(One2OneVCall, drawerMessages)
);
