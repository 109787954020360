import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const ViewProgress = withStyles((theme) => ({
  root: {
    width: '100%',
    background: '#8DA3C5',
    border: '1px solid #97ACC1',
    height: 11,
    borderRadius: 30,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bar: {
    backgroundColor: '#FFCC00',
    width: '100%',
    borderRadius: 30,
  },
}))(LinearProgress);

export default ViewProgress;
