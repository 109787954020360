import 'date-fns';
import React, { useEffect, useState } from 'react';

import Carousel from '../../components/Carousel';
import EmptyCard from '../../components/Dashboard/EmptyCard';
import TextMailCard from '../../components/Dashboard/TextMailCard';

import 'react-multi-carousel/lib/styles.css';

import * as api from '../../config/api';

const OTHER_EMAIL_TYPE = {
  empty: 'empty',
};

const TextMails = () => {
  const [emails, setEmails] = useState([]);
  /* eslint-disable-next-line no-unused-vars */
  const [error, setError] = useState(null);

  const CARD_COUNT = 3;

  if (!emails || emails.length < CARD_COUNT) {
    const loop_count = !emails ? CARD_COUNT : CARD_COUNT - emails.length;
    for (let index = 0; index < loop_count; index++) {
      emails.push({
        id: `empty${index}`,
        type: OTHER_EMAIL_TYPE.empty,
      });
    }
  }

  useEffect(() => {
    const getTextMails = () => {
      const apiUrl = api.apiUrl;
      const url = `${apiUrl}/member/text-mails`;
      api
        .GET(url)
        .then((res) => {
          if (res.success) {
            setEmails(res.data);
            setError(null);
            console.log('dashboard fetched text emails => ', res.data);
          } else {
            setEmails([]);
            setError(res.description);
          }
        })
        .catch((error) => {
          setEmails([]);
          setError(error);
        });
    };
    getTextMails();
  }, []);

  const selectEmailCard = (email) => {
    if (email.type === OTHER_EMAIL_TYPE.empty) {
      return (
        <EmptyCard key={email.id} type="market" message="No Marketing Data" />
      );
    } else {
      return <TextMailCard key={email.id} email={email} />;
    }
  };

  return (
    <>
      <Carousel>{emails.map((email) => selectEmailCard(email))}</Carousel>
    </>
  );
};

export default TextMails;
