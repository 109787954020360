import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';

import Modal from '../../components/Modal/modal';
import { putNotificationsSetting } from '../../redux/actions/member';

const newTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#888b8c',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
  actionBtnSave: {
    margin: theme.spacing(1),
    width: '30%',
  },
  selectBtn: {
    margin: theme.spacing(1),
  },
}));

const Notifications = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, isLoading } = props;
  const title = 'Notifications';
  const classes = useStyles();

  let default_notifications_setting = {
    email: {
      Contact: false,
      AcceptFriend: false,
      EventReminder: false,
      GroupJoin: false,
      RequestContact: false,
      RequestFriendship: false,
      RequestToJoinGroup: false,
      AmeraMail: false,
      ChatMessage: false,
      Invoice: false,
      Payment: false,
      Message: false,
    },
    sms: {
      Contact: false,
      AcceptFriend: false,
      EventReminder: false,
      GroupJoin: false,
      RequestContact: false,
      RequestFriendship: false,
      RequestToJoinGroup: false,
      AmeraMail: false,
      ChatMessage: false,
      Invoice: false,
      Payment: false,
      Message: false,
    },
    browser: {
      Contact: false,
      AcceptFriend: false,
      EventReminder: false,
      GroupJoin: false,
      RequestContact: false,
      RequestFriendship: false,
      RequestToJoinGroup: false,
      AmeraMail: false,
      ChatMessage: false,
      Invoice: false,
      Payment: false,
      Message: false,
    },
  };

  const [notificationsSetting, setNotificationsSetting] = useState(
    default_notifications_setting
  );
  const [isDefaultValues, setIsDefaultValues] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(props.notificationsSetting) && isDefaultValues) {
      setNotificationsSetting(props.notificationsSetting);
      setIsDefaultValues(false);
    }
  }, [props.notificationsSetting, isDefaultValues, setIsDefaultValues]);

  const unsubscribeAll = () => {
    let updatedNotificationsSetting = {};
    Object.keys(notificationsSetting).forEach((masterKey) => {
      let settings = {};
      Object.keys(notificationsSetting[masterKey]).forEach((subKey) => {
        settings[subKey] = false;
      });
      updatedNotificationsSetting[masterKey] = settings;
    });
    setNotificationsSetting(updatedNotificationsSetting);
  };

  const subscribeAll = () => {
    let updatedNotificationsSetting = {};
    Object.keys(notificationsSetting).forEach((masterKey) => {
      let settings = {};
      Object.keys(notificationsSetting[masterKey]).forEach((subKey) => {
        settings[subKey] = true;
      });
      updatedNotificationsSetting[masterKey] = settings;
    });
    setNotificationsSetting(updatedNotificationsSetting);
  };

  const handleChange = (type, field) => {
    let settings = { ...notificationsSetting };
    settings[type][field] = !settings[type][field];
    setNotificationsSetting(settings);
  };

  const handleSubmit = async () => {
    let result = await dispatch(
      putNotificationsSetting({ notifications_setting: notificationsSetting })
    );
    console.log('response from api', result);
    if (result) {
      onClose();
    }
  };

  const ModalContent = () => (
    <div className="profile_page-body">
      <ThemeProvider theme={newTheme}>
        <div className="profile_page-notifications">
          <div className="profile_page-section">
            {/* EMAILS */}
            <div className="page_section-row">
              <div className="section-row_label">
                Send me an email when someone...
              </div>
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].Contact}
                    onChange={() => handleChange('email', 'Contact')}
                    color="primary"
                  />
                }
                label="Accepts me as a Contact"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].AcceptFriend}
                    onChange={() => handleChange('email', 'AcceptFriend')}
                    color="primary"
                  />
                }
                label="Accepts me as a Friend"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].EventReminder}
                    onChange={() => handleChange('email', 'EventReminder')}
                    color="primary"
                  />
                }
                label="Calendar event reminders"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].GroupJoin}
                    onChange={() => handleChange('email', 'GroupJoin')}
                    color="primary"
                  />
                }
                label="Joins my group"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].RequestContact}
                    onChange={() => handleChange('email', 'RequestContact')}
                    color="primary"
                  />
                }
                label="Requests me as a contact"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].RequestFriendship}
                    onChange={() => handleChange('email', 'RequestFriendship')}
                    color="primary"
                  />
                }
                label="Requests me as a friend"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].RequestToJoinGroup}
                    onChange={() => handleChange('email', 'RequestToJoinGroup')}
                    color="primary"
                  />
                }
                label="Requests me to join a group"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].AmeraMail}
                    onChange={() => handleChange('email', 'AmeraMail')}
                    color="primary"
                  />
                }
                label="Sends AmeraMail message"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].ChatMessage}
                    onChange={() => handleChange('email', 'ChatMessage')}
                    color="primary"
                  />
                }
                label="Sends Chat message"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].Invoice}
                    onChange={() => handleChange('email', 'Invoice')}
                    color="primary"
                  />
                }
                label="Sends Invoice"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].Payment}
                    onChange={() => handleChange('email', 'Payment')}
                    color="primary"
                  />
                }
                label="Sends Payment"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['email'].Message}
                    onChange={() => handleChange('email', 'Message')}
                    color="primary"
                  />
                }
                label="Sends Voice or Video message"
              />
            </div>

            {/* SMS */}
            <div className="page_section-row">
              <div className="section-row_label">
                Send me an SMS when someone...
              </div>
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].Contact}
                    onChange={() => handleChange('sms', 'Contact')}
                    color="primary"
                  />
                }
                label="Accepts me as a Contact"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].AcceptFriend}
                    onChange={() => handleChange('sms', 'AcceptFriend')}
                    color="primary"
                  />
                }
                label="Accepts me as a Friend"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].EventReminder}
                    onChange={() => handleChange('sms', 'EventReminder')}
                    color="primary"
                  />
                }
                label="Calendar event reminders"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].GroupJoin}
                    onChange={() => handleChange('sms', 'GroupJoin')}
                    color="primary"
                  />
                }
                label="Joins my group"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].RequestContact}
                    onChange={() => handleChange('sms', 'RequestContact')}
                    color="primary"
                  />
                }
                label="Requests me as a contact"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].RequestFriendship}
                    onChange={() => handleChange('sms', 'RequestFriendship')}
                    color="primary"
                  />
                }
                label="Requests me as a friend"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].RequestToJoinGroup}
                    onChange={() => handleChange('sms', 'RequestToJoinGroup')}
                    color="primary"
                  />
                }
                label="Requests me to join a group"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].AmeraMail}
                    onChange={() => handleChange('sms', 'AmeraMail')}
                    color="primary"
                  />
                }
                label="Sends AmeraMail message"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].ChatMessage}
                    onChange={() => handleChange('sms', 'ChatMessage')}
                    color="primary"
                  />
                }
                label="Sends Chat message"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].Invoice}
                    onChange={() => handleChange('sms', 'Invoice')}
                    color="primary"
                  />
                }
                label="Sends Invoice"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].Payment}
                    onChange={() => handleChange('sms', 'Payment')}
                    color="primary"
                  />
                }
                label="Sends Payment"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['sms'].Message}
                    onChange={() => handleChange('sms', 'Message')}
                    color="primary"
                  />
                }
                label="Sends Voice or Video message"
              />
            </div>

            {/* Browser Alert */}
            <div className="page_section-row">
              <div className="section-row_label">
                Send me a Browser Alert when someone…
              </div>
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].Contact}
                    onChange={() => handleChange('browser', 'Contact')}
                    color="primary"
                  />
                }
                label="Accepts me as a Contact"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].AcceptFriend}
                    onChange={() => handleChange('browser', 'AcceptFriend')}
                    color="primary"
                  />
                }
                label="Accepts me as a Friend"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].EventReminder}
                    onChange={() => handleChange('browser', 'EventReminder')}
                    color="primary"
                  />
                }
                label="Calendar event reminders"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].GroupJoin}
                    onChange={() => handleChange('browser', 'GroupJoin')}
                    color="primary"
                  />
                }
                label="Joins my group"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].RequestContact}
                    onChange={() => handleChange('browser', 'RequestContact')}
                    color="primary"
                  />
                }
                label="Requests me as a contact"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].RequestFriendship}
                    onChange={() =>
                      handleChange('browser', 'RequestFriendship')
                    }
                    color="primary"
                  />
                }
                label="Requests me as a friend"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].RequestToJoinGroup}
                    onChange={() =>
                      handleChange('browser', 'RequestToJoinGroup')
                    }
                    color="primary"
                  />
                }
                label="Requests me to join a group"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].AmeraMail}
                    onChange={() => handleChange('browser', 'AmeraMail')}
                    color="primary"
                  />
                }
                label="Sends AmeraMail message"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].ChatMessage}
                    onChange={() => handleChange('browser', 'ChatMessage')}
                    color="primary"
                  />
                }
                label="Sends Chat message"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].Invoice}
                    onChange={() => handleChange('browser', 'Invoice')}
                    color="primary"
                  />
                }
                label="Sends Invoice"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].Payment}
                    onChange={() => handleChange('browser', 'Payment')}
                    color="primary"
                  />
                }
                label="Sends Payment"
              />
            </div>
            <div className="page_section-row--sub">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notificationsSetting['browser'].Message}
                    onChange={() => handleChange('browser', 'Message')}
                    color="primary"
                  />
                }
                label="Sends Voice or Video message"
              />
            </div>

            <div className="page_section-row">
              <Button
                variant="contained"
                disableRipple
                className={classes.selectBtn}
                onClick={() => unsubscribeAll()}
              >
                Unselect All
              </Button>
              <Button
                variant="contained"
                disableRipple
                className={classes.selectBtn}
                onClick={() => subscribeAll()}
              >
                Select All
              </Button>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.actionBtn}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtnSave}
          disableRipple
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  notificationsSetting: state.member.notificationsSetting,
  isLoading: state.member.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
