import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { capitalize } from 'lodash';

const EMPLOYMENT_TYPE = {
  fullTime: 'full-time',
  partTime: 'part-time',
  selfEmployeed: 'self-employeed',
  contract: 'contract',
  freelance: 'freelance',
  internship: 'internship',
  apprenticeshipL: 'apprenticeship',
  seasonal: 'seasonal',
};

const EmploymentTypeSelect = (props) => {
  const { value, onChange } = props;
  return (
    <FormControl variant="standard">
      <InputLabel shrink id="empoyment-type-select-label">
        Type
      </InputLabel>
      <Select
        disableUnderline={true}
        id="empoyment-type-select"
        value={value}
        onChange={onChange}
        label="Type"
      >
        {Object.values(EMPLOYMENT_TYPE)
          .sort()
          .map((type) => (
            <MenuItem key={type} value={type}>
              {capitalize(type)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default EmploymentTypeSelect;
