/* global APP  , */
import React, { Component } from 'react';
import Emoji from 'react-emoji-render';
import UIEvents from '../service/UI/UIEvents';
import SmileysPanel from './SmileysPanel';
/**
 * Chat
 */
type State = {
  /**
   * User provided nickname when the input text is provided in the view.
   */
  message: string,

  /**
   * Whether or not the smiley selector is visible.
   */
  showSmileysPanel: boolean,
};

export class Chat extends Component<State> {
  _textArea: ?HTMLTextAreaElement;

  constructor(props: Props) {
    super(props);

    this.displayData = [];
    this._textArea = null;
    this.state = {
      showdata: this.displayData,
      postVal: '',
      message: '',
      showSmileysPanel: false,
    };
    // Bind event handlers so they are only bound once for every instance.
    this._onDetectSubmit = this._onDetectSubmit.bind(this);
    this._onMessageChange = this._onMessageChange.bind(this);
    this._onToggleSmileysPanel = this._onToggleSmileysPanel.bind(this);
    this._onSmileySelect = this._onSmileySelect.bind(this);
  }
  appendData() {
    this.displayData.push(
      <div id="display-data">
        <pre>{this.state.postVal}</pre>
      </div>
    );
    this.setState({
      showdata: this.displayData,
      postVal: '',
    });
  }
  componentDidMount() {
    APP.UI.addListener(UIEvents.MESSAGE_RECEIVED, (id, message, timestamp) => {
      let display_name = APP.vacall.isLocalId(id)
        ? APP.vacall.localParticipant._displayName
        : APP.vacall.room.getParticipantById(id)._displayName;
      let local = APP.vacall.isLocalId(id) ? 'local' : 'remote';
      let classname = message.privateMessag ? 'privatemessage' : '';
      this.displayData.push(
        <div key={timestamp} className={`chat-message-group ${local}`}>
          {' '}
          <div className="chatmessage-wrapper">
            <div className={`chatmessage ${classname}`}>
              <div className="replywrapper">
                <div className="messagecontent">
                  {display_name}
                  <div className="usermessage">
                    <Emoji text={message} />
                  </div>
                </div>
              </div>
            </div>
            <div class="timestamp">{timestamp}</div>
          </div>
        </div>
      );
      this.setState({
        showdata: this.displayData,
        postVal: '',
      });
      //$('#chatconversation').append(html);
    });
  }
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const smileysPanelClassName = `${
      this.state.showSmileysPanel ? 'show-smileys' : 'hide-smileys'
    } smileys-panel`;
    return (
      <div
        className={
          this.props.enableChat
            ? 'sideToolbarContainer slideInExt'
            : 'sideToolbarContainer'
        }
        id="sideToolbarContainer"
      >
        <div className="chat-header">
          Chat
          <div className="jitsi-icon " onClick={this.props.toggleChat}>
            <svg height="24" width="24" viewBox="0 0 24 24">
              <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
            </svg>
          </div>
        </div>
        <div id="chatconversation">{this.displayData}</div>
        <div id="chat-input">
          <div className="smiley-input">
            <div id="smileysarea">
              <div id="smileys">
                <Emoji onClick={this._onToggleSmileysPanel} text=":)" />
              </div>
            </div>
            <div className={smileysPanelClassName}>
              <SmileysPanel onSmileySelect={this._onSmileySelect} />
            </div>
          </div>
          <div className="usrmsg-form">
            <textarea
              id="usermsg"
              placeholder="Type a message"
              autoComplete="off"
              className=""
              onChange={this._onMessageChange}
              onKeyDown={this._onDetectSubmit}
              value={this.state.message}
            />
          </div>
        </div>
      </div>
    );
  }
  _focus() {
    this._textArea && this._textArea.focus();
  }
  _onDetectSubmit: (Object) => void;

  /**
   * Detects if enter has been pressed. If so, submit the message in the chat
   * window.
   *
   * @param {string} event - Keyboard event.
   * @private
   * @returns {void}
   */
  _onDetectSubmit(event) {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();

      const trimmed = this.state.message.trim();

      if (trimmed) {
        // this.props.onSend(trimmed);
        APP.vacall.room.sendTextMessage(trimmed);
        this.setState({ message: '' });
      }
    }
  }

  _onMessageChange: (Object) => void;

  /**
   * Updates the known message the user is drafting.
   *
   * @param {string} event - Keyboard event.
   * @private
   * @returns {void}
   */
  _onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  _getMessagesGroupedBySender: () => Array<Array<Object>>;
  _onToggleSmileysPanel: () => void;

  /**
   * Callback invoked to hide or show the smileys selector.
   *
   * @private
   * @returns {void}
   */
  _onToggleSmileysPanel() {
    this.setState({ showSmileysPanel: !this.state.showSmileysPanel });

    this._focus();
  }
  _onSmileySelect: (string) => void;

  /**
   * Appends a selected smileys to the chat message draft.
   *
   * @param {string} smileyText - The value of the smiley to append to the
   * chat message.
   * @private
   * @returns {void}
   */
  _onSmileySelect(smileyText) {
    this.setState({
      message: `${this.state.message} ${smileyText}`,
      showSmileysPanel: false,
    });

    this._focus();
  }
}

export default Chat;
