import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-.958-12v-7.994h-1.696v3.224h-3.65v-3.224H8V20h1.696v-3.341h3.65V20h1.696zm1.966-8v8h3.19C22.624 20 24 18.543 24 15.945 24 13.441 22.607 12 20.198 12h-3.19zm1.696 1.429v5.141h1.224c1.499 0 2.342-.909 2.342-2.598 0-1.618-.87-2.543-2.342-2.543h-1.224z"></path>
    </svg>
  );
}
export default Icon;
