// import { apiUrl } from '../config/api';

export const extractFileInfo = (file) => {
  return {
    name: file.name,
    path: file.path,
    size: file.size,
    type: file.type,
  };
};

export const extractFileName = (url) => {
  const aws_file_key = url.substring(url.lastIndexOf('/') + 1);
  const start = aws_file_key.indexOf('_') + 1;
  const fileName = aws_file_key.substring(start);
  return fileName;
};

export const fileSize = (size) => {
  size = parseInt(size);
  const gb = Math.pow(2, 10) * Math.pow(2, 10) * Math.pow(2, 10);
  const mb = Math.pow(2, 10) * Math.pow(2, 10);
  const kb = Math.pow(2, 10);
  if (size > gb) return `${Math.floor((size / parseInt(gb)) * 100) / 100} GB`;
  else if (size > mb)
    return `${Math.floor((size / parseInt(mb)) * 100) / 100} MB`;
  else if (size > kb)
    return `${Math.floor((size / parseInt(kb)) * 100) / 100} KB`;
  else return `${size} Byte`;
};

export const toArrayBuffer = (buf) => {
  var ab = new ArrayBuffer(buf.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buf.length; ++i) {
    view[i] = buf[i];
  }
  return ab;
};

/**
 *
 * @param {*} file - a custom object from the main process
 */
export const convertToFile = (file) => {
  let fileArrayBuffer = toArrayBuffer(file.encodedBuffer);
  let newfile = new File([fileArrayBuffer], file.name, {
    type: file.type,
  });
  newfile.iv = file.iv;
  return newfile;
};

/**
 * @param {*} canvas - HTMLCanvasElement
 **/
export const getCanvasBlob = (canvas, fileName) => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          reject(new Error('Canvas is empty'));
        }
        blob.name = fileName;
        resolve(blob);
      },
      'image/jpeg',
      1
    );
  });
};

export const readFileAsBlob = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = function () {
      reject(reader.error);
    };
  });
};

// export const fileDownload = (key, fileName) => {
//   const url = `${apiUrl}/static/${key}`;
//   const keyv = key.split('.').pop();
//   var xhr = new XMLHttpRequest();
//   xhr.open('GET', url, true);
//   xhr.responseType = 'blob';
//   xhr.onload = function () {
//     var urlCreator = window.URL || window.webkitURL;
//     var fileUrl = urlCreator.createObjectURL(this.response);
//     var tag = document.createElement('a');
//     tag.href = `${fileUrl}#${keyv}`;
//     tag.download = fileName;
//     document.body.appendChild(tag);
//     tag.click();
//     document.body.removeChild(tag);
//   };
//   xhr.send();
// };

export const MIME = {
  jpeg: 'image/jpeg',
  png: 'image/png',
};

export const RASTER_MIME = [MIME.jpeg, MIME.png];

export const STROPHE_CHUNK_SIZE = 40 * 1024;
