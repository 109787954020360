import PropTypes from 'prop-types';
import React from 'react';
import SVG from '../../../../components/Icons/SVG';
import { getDownloadFile } from '../../../../redux/actions/fileshare';
import { apiUrl } from '../../../../config/api';
import { dark } from '../../../../jss/colors';
import { useEmailPageStyles } from './EmailPageStyles';

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function niceBytes(x) {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  //include a decimal point and a tenths-place digit if presenting
  //less than ten of KB or greater units
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

const attachFileColors = [
  '#00D0FF',
  '#F59B4D',
  '#49F2CA',
  '#E36CDC',
  '#87F8A9',
  '#00DCF9',
];

const MailAttach = (props) => {
  const { attachFiles } = props;

  const classes = useEmailPageStyles();

  if (!attachFiles || attachFiles.length < 1) return <div />;

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
    >
      {attachFiles.map((attachment, index) => (
        <div
          onClick={() => getDownloadFile(attachment.url, attachment.name)}
          key={index}
          className={classes.attachment}
        >
          <div
            className={classes.iconWrapper}
            style={{
              backgroundColor:
                attachFileColors[index % attachFileColors.length],
            }}
          >
            <SVG name="attachment" />
          </div>
          <a
            className={classes.description}
            href={`${apiUrl}${attachment.url}`}
            download
          >
            <div>{attachment.name}</div>
            <div style={{ color: dark }}>{niceBytes(attachment.size)}</div>
          </a>
        </div>
      ))}
    </div>
  );
};

MailAttach.propTypes = {
  attachFiles: PropTypes.array,
};

export default MailAttach;
