import 'date-fns';
import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles } from '@material-ui/styles';

import Header from './header';
import Carousel from '../../components/Carousel';
import StreamingLargeCard from '../../components/MediaStreaming/streamingLargeCard';

const mockdata = [];

for (let i = 0; i <= 10; i++) {
  mockdata.push({
    video_url: '',
    title: 'Coming Soon',
    update_date: '2021-04-10T13:52:50.298294+00:00',
  });
}

const useStyles = makeStyles((theme) => ({
  body: {
    height: 400,
  },
}));

const breakpoints = [
  {
    count: 4,
    width: 1440,
  },
  {
    count: 3,
    width: 1200,
  },
  {
    count: 2,
    width: 992,
  },
  {
    count: 2,
    width: 768,
  },
  {
    count: 1,
    width: 576,
  },
];

const StreamingLarge = (props) => {
  const {
    title,
    id,
    data,
    handleSettingOpen,
    defaultStreamType,
    handleVideoPlayerOpen,
  } = props;
  const classes = useStyles();

  const [category, setCategroy] = React.useState(defaultStreamType);

  let videoItmes = mockdata;

  if (data && data.length) {
    videoItmes = (+category > 0
      ? data.filter((video) => video.type.includes(+category))
      : data
    ).concat(mockdata);
  }

  return (
    <>
      <Header
        id={id}
        title={title}
        handleSettingOpen={handleSettingOpen}
        category={category}
        setCategroy={setCategroy}
      />
      <div className={classes.body}>
        <Carousel items={breakpoints}>
          {videoItmes.map((data, index) => {
            return (
              <StreamingLargeCard
                data={data}
                key={index}
                handleVideoPlayerOpen={handleVideoPlayerOpen}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default StreamingLarge;
