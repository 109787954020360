import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import SettingsIcon from '@material-ui/icons/Settings';
// import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import AntSwitch from '../../components/AntSwitch';
import FaceLearnModal from '../../components/Modal/FaceLearnModal';
import CardButton from '../../components/Dashboard/CardButton';
import Keyen from '../../components/Keygen';
import Modal from '../../components/Modal/modal';

import { closeDrawer } from '../../redux/actions/drawer';

import { DRAWER_WIDTH } from '../../utils/drawer';
import { IconButton } from '@material-ui/core';
import { changePassword, changeUsername } from '../../redux/actions/member';
import { debounce } from 'lodash';
import * as api from '../../config/api';

// const PREFERENCE_TYPES = {
//   chat: 'chat',
//   group: 'group',
//   email: 'email',
// };

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: DRAWER_WIDTH,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    padding: '28px 20px 28px 30px',
  },
  title: {
    marginTop: theme.spacing(1),
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000',
  },
  body: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 10px',
  },
  rowLabel: {
    fontSize: 18,
    fontWeight: 500,
  },
  rowDetals: {
    padding: theme.spacing(1.25),
  },
  checkboxesDIV: {
    paddingLeft: theme.spacing(1.25),
  },
  // checkboxLabel: {
  //   marginRight: theme.spacing(5),
  // },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
  subActionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  actionBtnCancel: {
    marginRight: theme.spacing(1),
  },
  labelDiv1: {
    marginTop: 0,
    paddingBottom: 0,
  },
  iconsDiv: {
    display: 'flex',
  },
  iconButton: {
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.75),
  },
  passwordErr: {
    margin: theme.spacing(0.5),
    color: '#868e9a',
  },
}));

const SecurityInformation = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [picPin, setPicPin] = useState(false);
  const [facialRecognition, setFacialRecognition] = useState(false);
  const [smsMultiFactor, setSmsMultiFactor] = useState(false);
  const [dongle, setDongle] = useState(false);
  const [browser, setBrowser] = useState(false);
  const [regionAndCarrierData, setRegionAndCarrierData] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPasswordErr, setCurrentPasswordErr] = useState('');
  const [newPasswordErr, setNewPasswordErr] = useState('');
  const [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');

  // const [preferenceType, setPreferenceType] = useState('');

  const [showFaceLearn, setShowFaceLearn] = useState(false);
  const [securityQuestion, setSecurityQuestion] = useState(false);
  const [showKeygen, setShowKeygen] = useState(false);

  const checkAvailability = useCallback(
    debounce((name) => {
      if (!name) return;
      const url = `${api.apiUrl}/member/search/username/${encodeURIComponent(
        name
      )}`;
      api
        .GET(url)
        .then((res) => {
          if (res.success && res.unique) {
            setUsernameAvailable(true);
          } else {
            setUsernameAvailable(false);
          }
        })
        .catch(() => {
          setUsernameAvailable(false);
        });
    }, 500),
    [dispatch]
  );

  const handleSubmit = () => {
    console.log('submit form data.');
  };

  const handleCancelClick = () => {
    dispatch(closeDrawer());
  };

  const handleUsernameChange = (event) => {
    setNewUsername(event.target.value);
    checkAvailability(event.target.value);
  };

  const submitUsername = () => {
    dispatch(changeUsername(newUsername));
  };

  const openFaceLearn = () => {
    setShowFaceLearn(true);
  };

  const openKeygen = () => {
    setShowKeygen(true);
  };

  const closeKeygen = () => {
    setShowKeygen(false);
  };

  const submitPassword = () => {
    if (!currentPassword) {
      setCurrentPasswordErr('Please provide your current password!');
      return;
    }
    if (!newPassword) {
      setNewPasswordErr('Please provide a new password');
      return;
    }
    if (!confirmNewPassword) {
      setNewPasswordErr('Please confirm your new password');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordErr('Passwords do not match!');
      return;
    }
    setCurrentPasswordErr('');
    setNewPasswordErr('');
    setConfirmNewPasswordErr('');
    const formData = { currentPassword, newPassword };
    dispatch(changePassword(formData, true));
  };

  const handleCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPassword = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <h3 className={classes.title}>Security Preferences</h3>
          <h4 className={classes.subTitle}>
            Here are our options to give your account some additional security
          </h4>
        </div>
        <div className={classes.body}>
          <div className={classes.row} style={{ marginTop: 0 }}>
            <span className={classes.rowLabel}>Change your username</span>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            <div>
              <TextField
                value={newUsername}
                label="Username"
                fullWidth
                onChange={handleUsernameChange}
                error={!usernameAvailable}
                helperText={
                  usernameAvailable
                    ? ''
                    : 'This username is currently not available'
                }
              />
            </div>
            <div className={classes.subActionsWrapper}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitUsername}
                disabled={newUsername === '' || !usernameAvailable}
              >
                Update
              </Button>
            </div>
          </div>
          <div className={classes.row}>
            <span className={classes.rowLabel}>Change your password</span>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            <div>
              <TextField
                type="password"
                error={!!currentPasswordErr}
                helperText={currentPasswordErr}
                label="Current Password"
                fullWidth
                onChange={handleCurrentPassword}
                color="primary"
                value={currentPassword}
                required
                inputProps={{
                  minLength: 6,
                  maxLength: 12,
                }}
              />
            </div>
            <div>
              <TextField
                type="password"
                error={!!newPasswordErr}
                helperText={newPasswordErr}
                label="New Password"
                fullWidth
                color="primary"
                onChange={handleNewPassword}
                value={newPassword}
                required
                inputProps={{
                  minLength: 6,
                  maxLength: 12,
                }}
              />
            </div>
            <div>
              <TextField
                type="password"
                error={!!confirmNewPasswordErr}
                helperText={confirmNewPasswordErr}
                label="Confirm New Password"
                fullWidth
                color="primary"
                value={confirmNewPassword}
                onChange={handleConfirmNewPassword}
                required
                inputProps={{
                  minLength: 6,
                  maxLength: 12,
                }}
              />
            </div>
            <div className={classes.subActionsWrapper}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitPassword}
              >
                Update
              </Button>
            </div>
          </div>

          <div className={classes.row}>
            <span className={classes.rowLabel}>
              Picture and PIN Device Authentication
            </span>
            <div className={classes.iconsDiv}>
              <IconButton className={classes.iconButton} onClick={openKeygen}>
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={picPin}
                onChange={(event) => setPicPin(event.target.checked)}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            Picture and PIN Device authentication if enabled will require you to
            enter a separate Picture and PIN that will be used on any device you
            want to access AmeraShare with.
          </div>

          <div className={classes.row}>
            <span className={classes.rowLabel}>Facial Recognition</span>
            <div className={classes.iconsDiv}>
              <IconButton
                className={classes.iconButton}
                onClick={openFaceLearn}
              >
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={facialRecognition}
                onChange={(event) => {
                  setFacialRecognition(event.target.checked);
                }}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            In training I want user to look up, left, right and down and center
            along with arrows (right now just two) we will make sound after each
            look is captured. We are not only using pictures for facial
            recognition we are building Picture and Pin based key based on all
            of the picture data for additional authentication.
          </div>

          {/* <div className={`${classes.row} ${classes.labelDiv1}`}>
            <span className={classes.rowLabel}>Use For:</span>
          </div>
          <div className={classes.checkboxesDIV}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferenceType === PREFERENCE_TYPES.chat}
                  onChange={() => setPreferenceType(PREFERENCE_TYPES.chat)}
                  name="Chat"
                  color="primary"
                  disableRipple
                />
              }
              label="Chat"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferenceType === PREFERENCE_TYPES.group}
                  onChange={() => setPreferenceType(PREFERENCE_TYPES.group)}
                  name="Groups"
                  color="primary"
                  disableRipple
                />
              }
              label="Groups"
              className={classes.checkboxLabel}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferenceType === PREFERENCE_TYPES.email}
                  onChange={() => setPreferenceType(PREFERENCE_TYPES.email)}
                  name="Email"
                  color="primary"
                  disableRipple
                />
              }
              label="Email"
              className={classes.checkboxLabel}
            />
          </div> */}

          <div className={classes.row}>
            <span className={classes.rowLabel}>SMS Multi Factor</span>
            <div className={classes.iconsDiv}>
              <IconButton className={classes.iconButton}>
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={smsMultiFactor}
                onChange={(event) => setSmsMultiFactor(event.target.checked)}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            System requests PIN code sent to users smartphone for Sign IN and
            other processes. Should have check box in here for user to check
            when required
          </div>

          <div className={classes.row}>
            <span className={classes.rowLabel}>Dongle</span>
            <div className={classes.iconsDiv}>
              <IconButton className={classes.iconButton}>
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={dongle}
                onChange={(event) => setDongle(event.target.checked)}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            USB Memory with Key generated by AmeraShare
          </div>
          <div className={classes.row}>
            <span className={classes.rowLabel}>Browser</span>
            <div className={classes.iconsDiv}>
              <IconButton className={classes.iconButton}>
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={browser}
                onChange={(event) => setBrowser(event.target.checked)}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            Will send notifications to users browser even when application is
            not open with a checklist
          </div>

          <div className={classes.row}>
            <span className={classes.rowLabel}>Region and Carrier Data</span>
            <div className={classes.iconsDiv}>
              <IconButton className={classes.iconButton}>
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={regionAndCarrierData}
                onChange={(event) =>
                  setRegionAndCarrierData(event.target.checked)
                }
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            Restricts use to only areas user allows
          </div>

          <div className={classes.row}>
            <span className={classes.rowLabel}>Security Questions</span>
            <div className={classes.iconsDiv}>
              <IconButton className={classes.iconButton}>
                <SettingsIcon color="secondary" size="medium" />
              </IconButton>
              <AntSwitch
                checked={securityQuestion}
                onChange={(event) => setSecurityQuestion(event.target.checked)}
              />
            </div>
          </div>
          <Divider />
          <div className={classes.rowDetals}>
            Security questions will be used for Username and Password recovery
            if you forget your username and/or password and cannot access your
            email.
          </div>
          <div className={classes.actionsWrapper}>
            <div className={classes.actionBtnCancel}>
              <CardButton
                variant="info"
                text="Cancel"
                onClick={handleCancelClick}
              />
            </div>
            <div>
              <CardButton
                variant="okFilled"
                text="Save Preferences"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
      <FaceLearnModal
        open={showFaceLearn}
        onClose={() => {
          setShowFaceLearn(false);
        }}
      />
      <Modal
        open={showKeygen}
        onClose={closeKeygen}
        title="Keygen Demo"
        ModalContent={() => <Keyen />}
        ModalAction={() => <></>}
        maxWidth="lg"
        className="modal-keygen-demo"
      />
    </>
  );
};

export default SecurityInformation;
