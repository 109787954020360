import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../SearchComponent';
import {
  closeDrawer,
  getGroups,
  selectGroup,
} from '../../redux/actions/drawer';
import { debounce } from 'lodash';

import MaterialAvatar from '../MaterialAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '4px',
      borderRadius: '8px',
      backgroundColor: '#d3d3d380',
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000',
  },
  contacts: {
    padding: '28px 22px 22px 22px',
  },
  wrapper: {
    border: '2px solid #D4E2F0',
    padding: '17px 0px 15px 0px',
    borderRadius: '8px',
    minHeight: '500px',
  },
  innerTitle: {
    fontSize: '23px',
    fontWeight: 600,
  },
  innerSearch: {
    width: '182px',
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 13px 0px 19px',
  },
  list: {
    marginTop: '8px',
  },
  listItem: {
    padding: '14px 19px 14px 19px',
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    marginTop: '23px',
    '& .MuiButton-root': {
      width: '124px',
      height: '40px',
      border: '1px solid #8DA3C5',
      borderRadius: '7px',
      color: '#8DA3C5',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  avatar: {
    width: '44px',
    height: '41px',
    background: '#D4E2F0 !important',
    color: '#8DA3C5 !important',
    marginRight: '11px',
  },
  groupInfo: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#363C45',
    cursor: 'pointer',
  },
}));

const GroupList = (props) => {
  const classes = useStyles();

  const { type, groups } = useSelector((state) => ({
    type: state.drawer.type,
    groups: state.drawer.contentProps.groups,
  }));

  const [searchKey, setSearchKey] = React.useState('');

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getGroups(''));
  }, [dispatch]);

  const debouncedGetGroups = useCallback(
    debounce((nextValue) => dispatch(getGroups(nextValue)), 500),
    []
  );

  const title =
    type === 'video_call' ? 'Video Conference' : 'Audio Call your groups';

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    debouncedGetGroups(event.target.value);
  };

  const goGroupMemberList = (group_id) => {
    dispatch(selectGroup(group_id));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
      </div>
      <div className={classes.contacts}>
        <div className={classes.wrapper}>
          <div className={classes.listHeader}>
            <div className={classes.innerTitle}>Select Group</div>
            <div className={classes.innerSearch}>
              <Search searchKey={searchKey} handleSearch={handleSearch} />
            </div>
          </div>
          <div className={classes.list}>
            {groups &&
              groups.length > 0 &&
              groups.map((group) => (
                <div
                  key={group.group_id}
                  className={classes.listItem}
                  onClick={() => {
                    goGroupMemberList(group.group_id);
                  }}
                >
                  <div className={classes.groupInfo}>
                    <MaterialAvatar
                      firstName={group.group_name}
                      lastName="group"
                      classes={classes.avatar}
                    />
                    {group.group_name} group
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={classes.cancelBtn}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(closeDrawer());
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GroupList;
