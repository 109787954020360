import React, { Component } from 'react';
import DirectionListItem from './DirectionListItem';
import Grid from '@material-ui/core/Grid';
import SVG from '../../../../Icons/SVG';
import Typography from '@material-ui/core/Typography';
// import LocationMap from '../../LocationMap';
import { displayableFullName } from '../../../../../utils/contact';
import {
  eventTimeText,
  calcEventDuration,
} from '../../../../../utils/calendar';
import { parseISO } from 'date-fns';

class PrintableDirections extends Component {
  render() {
    const { directions, event, destination } = this.props;
    const {
      event_name,
      host_member_info,
      invitations,
      start,
      end,
      is_full_day,
    } = event;
    const steps = directions.routes[0].legs[0].steps;
    const { distance, duration } = directions.routes[0].legs[0];
    return (
      <div style={{ padding: '2rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={2}>
              <SVG name="logo" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h3">
                Driving directions to '{event_name}'
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Destination: {destination.formatted_address}
            </Typography>
            <Typography variant="h5" gutterBottom>
              Driving time {duration.text}, distance {distance.text}
            </Typography>

            <Typography variant="h5">
              {eventTimeText(parseISO(start), parseISO(end), is_full_day)}
            </Typography>
            <Typography variant="h5" gutterBottom>
              Duration: {calcEventDuration(parseISO(start), parseISO(end))}
            </Typography>

            {!!invitations && invitations.length > 0 && (
              <Typography variant="h5">
                Attendees:{' '}
                {invitations.map((i) => displayableFullName(i)).join(', ')}
              </Typography>
            )}
            <Typography variant="h5" gutterBottom>
              Scheduled by: {displayableFullName(host_member_info)}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} style={{ margin: '0.5rem 0' }}>
            <LocationMap
              places={[destination]}
              isTeleportToBounds={false}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `15rem` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Grid> */}
          <Grid item xs={12} style={{ fontSize: '1.3rem' }}>
            {steps.map((s, idx) => (
              <DirectionListItem key={idx} step={s} />
            ))}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PrintableDirections;
