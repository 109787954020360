import { setSnackbarData } from './snackbar';
import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  FETCH_CONTACT_NEWSFEEDS_SUCCESS,
  SEND_CONTACT_NEWSFEEDS_TOPIC_SUCCESS,
  SEND_CONTACT_NEWSFEEDS_POST_SUCCESS,
  FETCH_GROUP_LIST_SUCCESS,
  SELECT_CALL_GROUP,
  CREATE_PASSWORD,
  UPDATE_PASSWORD,
  DELETE_PASSWORD,
  LOAD_PASSWORD,
  SET_IS_EDITING,
} from '../actionTypes/drawer';

import { DRAWER_VARIANTS } from '../../utils/drawer';
// import { subscribeMembers } from './chat';

import * as api from '../../config/api';
import { uniqBy } from 'lodash';

export const getContactNewsfeeds = () => {
  const url = `${api.apiUrl}/newsfeeds`;

  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({
            type: FETCH_CONTACT_NEWSFEEDS_SUCCESS,
            payload: res.topics,
          });
        }
      })
      .catch((error) => {});
  };
};

export const addNewTopic = (formData) => {
  const url = `${api.apiUrl}/newsfeeds`;

  return function (dispatch) {
    api
      .POST(url, formData, {}, false)
      .then((res) => {
        if (res.success) {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Successfully created a topic',
              type: 'success',
            })
          );
          dispatch({
            type: SEND_CONTACT_NEWSFEEDS_TOPIC_SUCCESS,
            payload: res.topic,
          });
        } else {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'A post was not created. Try some later!',
              type: 'error',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'A post was not created. Try some later!',
            type: 'error',
          })
        );
      });
  };
};

export const addNewReply = (formData, topic_id) => {
  const url = `${api.apiUrl}/newsfeeds/${topic_id}`;

  return function (dispatch) {
    api
      .POST(url, formData, {}, false)
      .then((res) => {
        if (res.success) {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Successfully created a comment',
              type: 'success',
            })
          );
          dispatch({
            type: SEND_CONTACT_NEWSFEEDS_POST_SUCCESS,
            payload: res.post,
          });
        } else {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'A comment was not created. Try some later!',
              type: 'error',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'A post was not created. Try some later!',
            type: 'error',
          })
        );
      });
  };
};
export const openDrawer = (
  type,
  contentProps,
  variant = DRAWER_VARIANTS.temporary,
  size
) => ({
  type: OPEN_DRAWER,
  payload: {
    type,
    contentProps,
    variant,
    size,
  },
});

export const getGroups = (searchKey) => {
  return async (dispatch) => {
    try {
      const params = { search_key: searchKey ? searchKey : '' };
      let url = `${api.apiUrl}/groups?${new URLSearchParams(
        params
      ).toString()}`;
      let res = await api.GET(url);
      let groups = [...res.data];
      url = `${api.apiUrl}/groups/membership?${new URLSearchParams(
        params
      ).toString()}`;
      res = await api.GET(url);
      groups = [...groups, ...res.data];
      dispatch({ type: FETCH_GROUP_LIST_SUCCESS, payload: groups });

      let allMembers = [];
      if (groups?.length > 0) {
        groups.forEach((g) => (allMembers = [...allMembers, ...g.members]));
        allMembers = uniqBy(allMembers, 'id');
      }
      // dispatch(subscribeMembers(allMembers, 'group'));
    } catch (error) {}
  };
};

export const loadPasswords = (search_key = '') => {
  return async (dispatch) => {
    try {
      let url = `${api.apiUrl}/password?search_key=${search_key}`;
      const res = await api.GET(url);
      if (res.success) {
        dispatch({ type: LOAD_PASSWORD, payload: res.data });
      }
    } catch (error) {}
  };
};

export const createPassword = (formData) => {
  return async (dispatch) => {
    try {
      let url = `${api.apiUrl}/password`;
      const res = await api.POST(url, formData, {}, false);
      if (res.success) {
        dispatch({ type: CREATE_PASSWORD, payload: res.data });
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Password was saved successfully',
            type: 'success',
          })
        );
      } else {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Password was not saved. Try some later!',
            type: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Password was not saved. Try some later!',
          type: 'error',
        })
      );
    }
  };
};

export const updatePassword = (passwordId, formData) => {
  return async (dispatch) => {
    try {
      let url = `${api.apiUrl}/password/${passwordId}`;
      const res = await api.PUT(url, formData, {}, false);
      if (res.success) {
        dispatch({ type: UPDATE_PASSWORD, payload: res.data });
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Password was updated successfully',
            type: 'success',
          })
        );
      } else {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Password was not updated. Try some later!',
            type: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Password was not updated. Try some later!',
          type: 'error',
        })
      );
    }
  };
};

export const deletePassword = (passwordId) => {
  return async (dispatch) => {
    try {
      let url = `${api.apiUrl}/password/${passwordId}`;
      const res = await api.DELETE(url);
      if (res.success) {
        dispatch({ type: DELETE_PASSWORD, payload: passwordId });
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Password was deleted successfully',
            type: 'success',
          })
        );
      } else {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Password was not deleted. Try some later!',
            type: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Password was not deleted. Try some later!',
          type: 'error',
        })
      );
    }
  };
};

export const selectGroup = (group_id) => ({
  type: SELECT_CALL_GROUP,
  payload: group_id,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export const setEditing = (isEditing) => ({
  type: SET_IS_EDITING,
  payload: isEditing,
});
