import { isValid, addDays, max } from 'date-fns';
import { flattenDeep, max as maxNumber } from 'lodash';
// import { GET_TERMS_AND_CONDITIONS_SUCCESS } from '../redux/actionTypes/member';
import { ADDRESS_TYPES } from './calendar';
import { DEVICE_TYPES, DEVICE_METHOD } from './contact';

const shortid = require('shortid');

export const DRAWER_WIDTH = 480;

export const DRAWER_TYPE = {
  small: 300,
  default: 480,
  large: 740,
};

export const DRAWER_VARIANTS = {
  persistent: 'persistent',
  temporary: 'temporary',
};

export const DRAWER_CONTENT_TYPE = {
  newsfeeds: 'newsfeeds',
  audioCall: 'audio_call',
  videoCall: 'video_call',
  contactProfile: 'contact_profile',
  locationManager: 'location_manager',
  myProfileInfo: 'my_profile_info',
  myProfileEdit: 'my_profile_edit',
  companyProfile: 'company_profile',
  companyCreate: 'company_create',
  securitySettings: 'security_settings',
  passwordAndLoginManager: 'passwordAndLoginManager',
  uploadcontent: 'upload_content',
  broadcast: 'broadcast',
  groupProfile: 'group_profile',
  event: 'event',
};

export const SITE_TYPE = [
  {
    label: 'Banks',
    key: 'bank',
  },
  {
    label: 'Blogs and Forums',
    key: 'blog/forum',
  },
  {
    label: 'Company',
    key: 'company',
  },
  {
    label: 'Credit card',
    key: 'credit card',
  },
  {
    label: 'Crypto Currency',
    key: 'crypto currency',
  },
  {
    label: 'Financial Services',
    key: 'financial service',
  },
  {
    label: 'Loyalty',
    key: 'loyalty card',
  },
  {
    label: 'Social Media',
    key: 'social media',
  },
  {
    label: 'Other',
    key: 'other',
  },
];

export const CALL_TARGET = {
  group: 'group',
  contact: 'contact',
};

export const PROFILE_EDIT_ACTIONS = {
  locationAdd: 'LOCATION_ADD',
  locationDelete: 'LOCATION_DELETE',
  locationEdit: 'LOCATION_EDIT',
  locationAutocomplete: 'LOCATION_AUTOCOMPLETE',
  locationClearInput: 'LOCATION_CLEAR',
  locationIdChange: 'LOCATION_ID_CHANGE',
  // education
  educationAdd: 'EDUCATION_ADD',
  educationDelete: 'EDUCATION_DELETE',
  educationEditItem: 'EDUCATION_EDIT_ITEM',
  // work
  workAdd: 'WORK_ADD',
  workDelete: 'WORK_DELETE',
  workEditItem: 'WORK_EDIT_ITEM',
  // certificate
  certificateAdd: 'CERT_ADD',
  certificateDelete: 'CERT_DELETE',
  certificateEdit: 'CERT_EDIT',
  //achievements
  achievementAdd: 'ACHIEVE_ADD',
  achievementDelete: 'ACHIEVE_DELETE',
  achievementEdit: 'ACHIEVE_EDIT',
  // phone
  phoneAdd: 'PHONE_ADD',
  phoneDelete: 'PHONE_DELETE',
  phoneEdit: 'PHONE_EDIT',
  phoneNumberEdit: 'PHONE_NUMBER_EDIT',
  // email
  emailAdd: 'EMAIL_ADD',
  emailDelete: 'EMAIL_DELETE',
  emailEdit: 'EMAIL_EDIT',
};

export const phoneReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.phoneAdd:
      return [
        ...state,
        {
          id: shortid.generate(),
          device_country: action.payload.device_country_id,
          description: '',
          device: '',
          device_type: DEVICE_TYPES.cell,
          method_type: DEVICE_METHOD.voice,
          display_order: action.payload.display_order,
          primary_contact: false,
          device_confirm_date: null,
        },
      ];
    case PROFILE_EDIT_ACTIONS.phoneDelete:
      return state.filter((s) => s.id !== action.payload.phone_id);
    case PROFILE_EDIT_ACTIONS.phoneEdit:
      return state.map((p) =>
        p.id === action.payload.phone_id
          ? { ...p, [action.payload.field]: action.payload.value }
          : p
      );
    case PROFILE_EDIT_ACTIONS.phoneNumberEdit:
      return state.map((p) =>
        p.id === action.payload.phone_id
          ? {
              ...p,
              device: action.payload.device,
              device_country: action.payload.device_country,
              device_confirm_date: null,
            }
          : p
      );
    default:
      return state;
  }
};

export const emailReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.emailAdd:
      return [
        ...state,
        {
          id: shortid.generate(),
          device_country: action.payload.device_country_id,
          description: '',
          device: '',
          device_type: DEVICE_TYPES.email,
          method_type: DEVICE_METHOD.html,
          display_order: action.payload.display_order,
          primary_contact: false,
          device_confirm_date: null,
        },
      ];
    case PROFILE_EDIT_ACTIONS.emailDelete:
      return state.filter((s) => s.id !== action.payload.email_id);
    case PROFILE_EDIT_ACTIONS.emailEdit:
      return state.map((e) =>
        e.id === action.payload.email_id
          ? { ...e, [action.payload.field]: action.payload.value }
          : e
      );
    default:
      return state;
  }
};

export const locationReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.locationIdChange:
      const updates = state.map((l) => {
        if (l.id === action.payload.oldId) l.id = action.payload.newId;
        return l;
      });

      return updates;
    case PROFILE_EDIT_ACTIONS.locationClearInput: // This will clear unsubmitted changes
      return state.filter((l) => l.id !== action.payload.id);

    case PROFILE_EDIT_ACTIONS.locationEdit:
      return state.map((l) =>
        l.id === action.payload.location_id
          ? { ...l, [action.payload.field]: action.payload.value }
          : l
      );
    case PROFILE_EDIT_ACTIONS.locationDelete:
      return state.filter((l) => l.id !== action.payload.location_id);
    case PROFILE_EDIT_ACTIONS.locationAdd:
      return [
        ...state,
        {
          id: shortid.generate(),
          location_type: !!action.payload.location_type
            ? action.payload.location_type
            : ADDRESS_TYPES.other,
          editable_by_member: true,
          country_code_id: action.payload.country_code_id,
          country_name: action.payload.country,
          street: '',
          city: '',
          province: '',
          state: '',
          address_1: '',
          address_2: '',
          postal: '',
          description: '',
          place_id: null,
          edit: true,
          name: '',
        },
      ];
    case PROFILE_EDIT_ACTIONS.locationAutocomplete:
      return state.map((l) =>
        l.id === action.payload.location_id
          ? {
              ...l,
              city: !!action.payload.city ? action.payload.city : '',
              province: !!action.payload.province
                ? action.payload.province
                : '',
              state: !!action.payload.state ? action.payload.state : '',
              address_1: !!action.payload.address_1
                ? action.payload.address_1
                : '',
              address_2: !!action.payload.address_2
                ? action.payload.address_2
                : '',
              postal: !!action.payload.postal ? action.payload.postal : '',
              // description: !!action.payload.description
              //   ? action.payload.description
              //   : '',
              place_id: !!action.payload.place_id
                ? action.payload.place_id
                : null,
              name: '',
            }
          : l
      );

    default:
      return state;
  }
};

export const educationReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.educationAdd:
      return [
        ...state,
        {
          id: shortid.generate(),
          school_name: '',
          school_location: '',
          degree: '',
          field_of_study: '',
          start_date: addDays(
            max(
              flattenDeep(
                state.map((ei) => [ei.start_date, ei.end_date])
              ).filter((d) => isValid(d))
            ),
            1
          ), // Make the latest date + 1day
          end_date: null,
          activity_text: null,
        },
      ];
    case PROFILE_EDIT_ACTIONS.educationDelete:
      return state.filter((e) => e.id !== action.payload.education_id);
    case PROFILE_EDIT_ACTIONS.educationEditItem:
      return state.map((e) =>
        e.id === action.payload.education_id
          ? { ...e, [action.payload.field]: action.payload.value }
          : e
      );
    default:
      return state;
  }
};

export const workReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.workEditItem:
      return state.map((e) =>
        e.id === action.payload.work_id
          ? { ...e, [action.payload.field]: action.payload.value }
          : e
      );
    case PROFILE_EDIT_ACTIONS.workAdd:
      return [
        ...state,
        {
          id: shortid.generate(),
          job_title: '',
          employment_type: '',
          company_name: '',
          company_location: '',
          start_date: addDays(
            max(
              flattenDeep(
                state.map((wi) => [wi.start_date, wi.end_date])
              ).filter((d) => isValid(d))
            ),
            1
          ), // Make the latest date + 1day
          end_date: null,
        },
      ];
    case PROFILE_EDIT_ACTIONS.workDelete:
      return state.filter((w) => w.id !== action.payload.work_id);
    default:
      return state;
  }
};

export const certificatesReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.certificateAdd: {
      return [
        ...state,
        {
          id: shortid.generate(),
          title: '',
          description: '',
          date_received: new Date(),
        },
      ];
    }
    case PROFILE_EDIT_ACTIONS.certificateDelete: {
      return state.filter((c) => c.id !== action.payload.certificate_id);
    }
    case PROFILE_EDIT_ACTIONS.certificateEdit: {
      return state.map((c) =>
        c.id === action.payload.certificate_id
          ? { ...c, [action.payload.field]: action.payload.value }
          : c
      );
    }
    default:
      return state;
  }
};

export const achievementsReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_EDIT_ACTIONS.achievementAdd: {
      return [
        ...state,
        {
          id: shortid.generate(),
          entity: '',
          description: '',
          display_order: maxNumber(state.map((s) => s.display_order)),
        },
      ];
    }
    case PROFILE_EDIT_ACTIONS.achievementDelete: {
      return state.filter((s) => s.id !== action.payload.achievement_id);
    }
    case PROFILE_EDIT_ACTIONS.achievementEdit: {
      return state.map((a) =>
        a.id === action.payload.achievement_id
          ? { ...a, [action.payload.field]: action.payload.value }
          : a
      );
    }
    default:
      return state;
  }
};
