import * as api from '../../config/api';
import { store } from '../../utils/store';
import { fetchTodos } from '../../config/apiService/Fakeapi';
import { NOTIFICATION_EVENT_TYPES, openCallWindow } from '../../utils/general';
import { formatInTimeZone } from '../../utils/calendar';
import { push } from 'connected-react-router';

import {
  GET_MEMBER_EVENTS,
  FETCH_ACTIVITIES_START,
  UPDATE_ACTIVITIES,
  FETCH_ACTIVITIES_FAILED,
  GET_COMPOSE,
  ANSWER_CALL,
  INCOMING_CALL,
  CALL_REPLY,
  GROUP_CALL_REPLY,
  SET_REPLY_MESSAGE,
  SET_CALL_GROUP_ID,
  GET_UPCOMING_EVENTS_SUCCEEDED,
  GET_UPCOMING_EVENTS_FAILED,
  GET_EVENT_INVITATIONS_SUCCEEDED,
  GET_EVENT_INVITATIONS_FAILED,
  REMOVE_NEW_VIDEO_MAIL_NOTIFICATION,
  SET_TWILIO_CALL,
  SET_TWILIO_DEVICE,
  SET_TWILIO_CONNECTION,
  OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL,
  SET_TWILIO_VERIFICATION_MODAL_STATUS,
  // GET_OTHER_INVITATIONS_FAILED,
  // GET_OTHER_INVITATIONS_SUCCEEDED,
} from '../actionTypes/event';

import { getGroup, getInvitation } from './activity';

import { setAudioOnly, setCallPartnerMemberId } from './one2onevcall';
import { OUTGOING_PHONE_VERIFICATION_STATUS } from '../actionTypes/member';
import { setSnackbarData } from './snackbar';

const apiUrl = api.apiUrl;
const notificationsUrl = `${apiUrl}/web-notifications`;

/*
  START SSE API
*/

let es = null; // Need reference to EventSource to remove eventListeners on logout
const pingHandler = async (msg) => {
  console.debug(`Received PING ${msg.data}`);
};
const notifyHandler = async (msg) => {
  // debugger;
  console.debug(`Received NOTIFY`);

  const data = JSON.parse(msg.data);
  const state = await store.getState();
  if (data.event_type === 'OUTGOING_CALLER_VERIFICATION') {
    return twilioCallerVerification(data, state);
  }
  console.debug(`Notify Handler: data.type: ${data.type}`);
  switch (data.type) {
    case NOTIFICATION_EVENT_TYPES.RESPOND.GROUP.VIDEO_CALL:
    case NOTIFICATION_EVENT_TYPES.RESPOND.GROUP.VIDEO_CONFERENCE:
      store.dispatch({
        type: GROUP_CALL_REPLY,
        payload: [...state.event.groupCallReplies, data],
      });
      break;
    case NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT.AUDIO_CALL:
    case NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT.VIDEO_CALL:
      store.dispatch({ type: CALL_REPLY, payload: data });
      break;
    case NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT.VIDEO_CONFERENCE:
      store.dispatch({
        type: GROUP_CALL_REPLY,
        payload: [...state.event.groupCallReplies, data],
      });
      break;
    case NOTIFICATION_EVENT_TYPES.REQUEST.GROUP.VIDEO_CALL:
    case NOTIFICATION_EVENT_TYPES.REQUEST.GROUP.VIDEO_CONFERENCE:
    case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.AUDIO_CALL:
    case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.VIDEO_CALL:
    case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.VIDEO_CONFERENCE:
      store.dispatch({ type: INCOMING_CALL, payload: data });
      break;
    default:
      break;
  }
};

export const isEventContact = (event_type) => {
  const requestEvents = Object.values(NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT);
  const respondEvents = Object.values(NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT);
  return (
    requestEvents.concat(respondEvents).find((v) => v === event_type) !==
    undefined
  );
};

export const isEventGroup = (event_type) => {
  const requestEvents = Object.values(NOTIFICATION_EVENT_TYPES.REQUEST.GROUP);
  const respondEvents = Object.values(NOTIFICATION_EVENT_TYPES.RESPOND.GROUP);
  return (
    requestEvents.concat(respondEvents).find((v) => v === event_type) !==
    undefined
  );
};
// Got activity

export const sendNotification = (data) => {
  console.debug('notify', data);
  // const url = `${notificationsUrl}/notify`;
  const url = `${apiUrl}/notification/event`;
  api
    .POST(url, JSON.stringify(data), { 'Content-Type': 'application/json' })
    .then((res) => {
      if (res === undefined) {
        return;
      }
      res.status === '200'
        ? console.debug('Notification sent')
        : console.debug('Something went wrong when sending notifications');
    })
    .catch((err) => {
      // console.exception();
      console.error(err);
    });
};

export const subscribeToSSE = (memberId) => {
  es = new EventSource(`${notificationsUrl}/subscribe/${memberId}`, {
    withCredentials: true,
  });
  es.onopen = () => {
    // console.debug('The ready state is', es.readyState);
    // console.debug(es);
    es.addEventListener('PING', pingHandler);
    es.addEventListener('NOTIFY', notifyHandler);
  };

  es.onerror = (err) => {
    es.removeEventListener('PING', pingHandler);
    es.removeEventListener('NOTIFY', notifyHandler);
    console.debug(err);
  };
};

export const unsubscribeSSE = () => {
  console.debug('Unsubscribing from SSE');
  es.removeEventListener('PING', pingHandler);
  es.removeEventListener('NOTIFY', notifyHandler);
  es.close();
};

/*
  END SSE API
*/

export const answerCall = (callData) => {
  return function (dispatch) {
    console.debug('Firing ANSWER_CALL', callData.call_url);

    openCallWindow(callData.call_url);
    dispatch({ type: ANSWER_CALL, payload: callData });
  };
};

export const answerContactVideoAudioRequest = (callData, answerData) => {
  return function (dispatch) {
    console.debug('Firing ANSWER_CALL', callData.call_url);
    dispatch({ type: ANSWER_CALL, payload: callData });
    let eventType;
    let audioOnly = false;
    switch (callData.type) {
      case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.VIDEO_CONFERENCE:
        eventType = NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT.VIDEO_CONFERENCE;
        break;
      case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.VIDEO_CALL:
        eventType = NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT.VIDEO_CALL;
        break;
      case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.AUDIO_CALL:
        eventType = NOTIFICATION_EVENT_TYPES.RESPOND.CONTACT.AUDIO_CALL;
        audioOnly = true;
        break;
      default:
        console.debug(callData);
        throw Error('invalid callData.type');
    }

    let { sender, receiver, ...event } = callData;
    const notificationBody = {
      ...event,
      type: eventType,
      response: answerData.reply_type,
    };
    console.debug(notificationBody);
    sendNotification(notificationBody);
    if (answerData.reply_type !== 'decline') {
      let finalURL;
      if (process && process.env && process.env.PUBLIC_URL) {
        finalURL = new URL(
          callData.call_url,
          process.env.PUBLIC_URL || undefined
        );
      } else {
        finalURL = callData.call_url;
      }
      dispatch({
        type: SET_REPLY_MESSAGE,
        payload: {
          ...notificationBody,
          reply_type: answerData.reply_type,
          callee_id: answerData.member_id,
        },
      });
      dispatch(setAudioOnly(audioOnly));
      dispatch(setCallPartnerMemberId(callData.caller_id, 'reply'));
      dispatch(push(finalURL));
    }
  };
};

export const answerGroupVideoConference = (callData, answerData) => {
  return async function (dispatch) {
    console.log('Firing ANSWER_CALL', callData.call_url);
    await dispatch({ type: ANSWER_CALL, payload: callData });
    const notificationBody = {
      ...callData,
      type: NOTIFICATION_EVENT_TYPES.RESPOND.GROUP.VIDEO_CONFERENCE,
      response: answerData.reply_type,
      callee_id: answerData.member_id,
    };
    console.debug(notificationBody);
    sendNotification(notificationBody);
    if (answerData.reply_type !== 'decline') {
      await dispatch({
        type: SET_REPLY_MESSAGE,
        payload: {
          ...callData,
          reply_type: answerData.reply_type,
          response: answerData.reply_type,
          callee_id: answerData.member_id,
        },
      });

      await dispatch({ type: SET_CALL_GROUP_ID, payload: callData.group_id });
      await dispatch(openCallWindow(callData.call_url));
    }
  };
};

export const answerGroupVideoCall = (callData, answerData) => {
  return async function (dispatch) {
    console.log('Firing ANSWER_CALL', callData.call_url);
    await dispatch({ type: ANSWER_CALL, payload: callData });
    const notificationBody = {
      ...callData,
      type: NOTIFICATION_EVENT_TYPES.RESPOND.GROUP.VIDEO_CALL,
      response: answerData.reply_type,
      callee_id: answerData.member_id,
    };
    console.debug(notificationBody);
    sendNotification(notificationBody);
    if (answerData.reply_type !== 'decline') {
      await dispatch({
        type: SET_REPLY_MESSAGE,
        payload: {
          ...callData,
          reply_type: answerData.reply_type,
          response: answerData.reply_type,
          callee_id: answerData.member_id,
        },
      });
      await dispatch({ type: SET_CALL_GROUP_ID, payload: callData.group_id });
      await dispatch(push(`${process.env.PUBLIC_URL}${callData.call_url}`));
    }
  };
};

// FIXME: Here we'll hadnle voicemail
export const callToVoiceMail = (callData) => {
  return function (dispatch) {
    console.debug(
      `Will initiate recording call ${callData.call_id} to voice mail`
    );
    dispatch({ type: ANSWER_CALL, payload: callData });
  };
};

export const getMemberEvents = () => {
  const url = 'member-event';
  return function (dispatch) {
    fetchTodos(url).then((res) => {
      dispatch({
        type: GET_MEMBER_EVENTS,
        payload: res,
      });
    });
  };
};

export const getNotifications = () => (dispatch) => {
  const url = `${apiUrl}/system/activity/activity`;
  dispatch(fetchActivitiesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.activities) {
        if (res.activities.invitations && res.activities.invitations.data) {
          dispatch(
            updateActivities({
              type: 'invitations',
              data: res.activities.invitations.data,
            })
          );
        }
        if (res.activities.mails && res.activities.mails.data) {
          dispatch(
            updateActivities({
              type: 'mails',
              data: res.activities.mails.data,
            })
          );
        }
      } else {
        dispatch(fetchActivitiesRequestFailed(null));
      }
    })
    .catch((error) => {
      console.debug(error);
      dispatch(fetchActivitiesRequestFailed(error));
    });
};

export const getSessions = () => (dispatch) => {
  let isCanSeeAllSessions = false;
  let uri = `?get_all=${isCanSeeAllSessions}&page_number=0&page_size=10&sort=-create_date`;
  const url = `${api.apiUrl}/system/activity/session${uri}`;
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.activities) {
        let data = {
          type: 'sessions',
          data: res.activities,
        };
        dispatch(updateActivities(data));
      } else {
        dispatch(fetchActivitiesRequestFailed(null));
      }
    })
    .catch((error) => {
      console.debug(error);
    });
};

export const getThreats = () => (dispatch) => {
  let isCanSeeAllSessions = false;
  let uri = `?get_all=${isCanSeeAllSessions}&page_number=0&page_size=10&sort=-create_date`;
  const url = `${api.apiUrl}/system/activity/threat${uri}`;
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.activities) {
        let data = {
          type: 'alerts',
          data: res.activities,
        };
        dispatch(updateActivities(data));
      } else {
        dispatch(fetchActivitiesRequestFailed(null));
      }
    })
    .catch((error) => {
      console.debug(error);
    });
};

export const getCompose = (compose) => {
  return function (dispatch) {
    dispatch({
      type: GET_COMPOSE,
      payload: compose,
    });
  };
};

export const clearReplyMessage = () => ({
  type: SET_REPLY_MESSAGE,
  payload: null,
});

export const fetchActivitiesRequestStart = () => ({
  type: FETCH_ACTIVITIES_START,
});
export const updateActivities = (payload) => ({
  type: UPDATE_ACTIVITIES,
  payload,
});
export const fetchActivitiesRequestFailed = (payload) => ({
  type: FETCH_ACTIVITIES_FAILED,
  payload,
});

export const acceptInvitation = (contact_member_id, status) => {
  const url = `${apiUrl}/member/contact/request/${contact_member_id}`;
  const data = JSON.stringify({ status });
  return (dispatch) => {
    return api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.success) {
          dispatch(getInvitationsAndMediaMails());
          dispatch(getInvitation());
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  };
};

export const setGroupCallReplies = (groupCallReplies) => ({
  type: GROUP_CALL_REPLY,
  payload: groupCallReplies,
});

export const getUpcomingEvents = () => {
  console.debug('Upcoming events');
  const current = formatInTimeZone(new Date(), 'UTC');
  const limit = 10;

  return (dispatch) => {
    const url = `${apiUrl}/member/event/upcoming?current=${current}&limit=${limit}`;

    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: GET_UPCOMING_EVENTS_SUCCEEDED, payload: res.data });
        } else {
          dispatch({
            type: GET_UPCOMING_EVENTS_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_UPCOMING_EVENTS_FAILED, payload: error });
      });
  };
};

export const getInvitationsAndMediaMails = () => {
  console.debug('Invite events');

  return (dispatch) => {
    const url = `${apiUrl}/member/event/invite`;

    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_EVENT_INVITATIONS_SUCCEEDED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: GET_EVENT_INVITATIONS_FAILED,
            payload: res.description,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_EVENT_INVITATIONS_FAILED, payload: error });
      });
  };
};

// export const getOtherInvitations = () => {
//   return (dispatch) => {
//     const url = `${apiUrl}/member/other-invitations`;

//     api
//       .GET(url)
//       .then((res) => {
//         if (res.success) {
//           dispatch({
//             type: GET_OTHER_INVITATIONS_SUCCEEDED,
//             payload: res.data,
//           });
//         } else {
//           dispatch({
//             type: GET_OTHER_INVITATIONS_FAILED,
//             payload: res.description,
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({ type: GET_OTHER_INVITATIONS_FAILED, payload: error });
//       });
//   };
// };

export const setEventInviteStatus = (event_invite_id, status, comment) => {
  console.debug('set event invitation status');

  return (dispatch) => {
    const url = `${apiUrl}/member/event/invite/${event_invite_id}`;
    const data = JSON.stringify({ status, comment });

    return api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.success) {
          dispatch(getUpcomingEvents());
          dispatch(getInvitationsAndMediaMails());
          dispatch(getGroup());
          dispatch(getInvitation());
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        return false;
      });
  };
};

export const acceptGroupInvitation = (groupId, status) => {
  const url = `${apiUrl}/member/group/membership/request/${groupId}`;
  const data = JSON.stringify({ status });
  return (dispatch) => {
    return api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.success) {
          dispatch(getInvitationsAndMediaMails());
          dispatch(getGroup());
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {});
  };
};

export const setCallGroupId = (call_group_id) => ({
  type: SET_CALL_GROUP_ID,
  payload: call_group_id,
});

export const removeNotificationCard = (mail_id) => ({
  type: REMOVE_NEW_VIDEO_MAIL_NOTIFICATION,
  payload: mail_id,
});

export const setTwilioCall = (status) => {
  return (dispatch) => {
    dispatch({ type: SET_TWILIO_CALL, payload: status });
  };
};

export const setTwilioDevice = (device) => {
  return (dispatch) => {
    dispatch({ type: SET_TWILIO_DEVICE, payload: device });
  };
};

export const setTwilioConnection = (conn) => {
  return (dispatch) => {
    dispatch({ type: SET_TWILIO_CONNECTION, payload: conn });
  };
};

export const setTwilioVerificationModalStatus = (status) => {
  return (dispatch) => {
    dispatch({ type: SET_TWILIO_VERIFICATION_MODAL_STATUS, payload: status });
  };
};

const twilioCallerVerification = (data, state) => {
  console.log(
    'outgoing caller verification notification ::::: ==========',
    data
  );
  if (!state.event.twilioVerificationModalStatus) {
    store.dispatch(
      setSnackbarData({
        open: true,
        message: `Your number ${data.number} was ${
          data.status === 'success' ? '' : 'not'
        } verified`,
        type: data.status === 'success' ? 'success' : 'error',
      })
    );
  }
  store.dispatch({
    type: OUTGOING_PHONE_VERIFICATION_STATUS,
    payload: {
      contact_id: data.contact_id,
      status: data.verification_status,
    },
  });
  if (state.event.twilioVerificationModalStatus) {
    store.dispatch({
      type: OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL,
      payload: data,
    });
  }
};
