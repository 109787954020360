import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { debounce } from 'lodash';

import TimeZoneAutocomplete from '../../components/Modal/EventModals/TimeZoneAutocomplete';
import styles from '../../jss/views/activityTableStyle';
import * as api from '../../config/api';
import useQuery from '../../hooks/useQuery';
import { defaultDateTimeZone } from '../../utils/calendar';

const headCells = [
  {
    id: 'user',
    label: 'User',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'page',
    label: 'Page',
    minWidth: 170,
  },
  {
    id: 'activities',
    label: 'Activities',
    minWidth: 170,
  },
  {
    id: 'create_date',
    label: 'Create Date and Time',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(styles);

const GlobalUserBehaviour = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();

  const { isCanSeeAll, timeZone, setTimeZone } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [activityUsers, setActivityUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const [searchKey, setSearchKey] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const isMountedRef = useRef(false);

  const query_member_id = query.get('id') || '';

  const search = (event) => {
    let value = event.target.value;
    getList({
      sortBy: orderBy,
      searchKey: value,
      pageNumber: page,
      pageSize: rowsPerPage,
    });
    setSearchKey(value);
  };

  const filterByUser = (member_id) => {
    history.push({
      pathname: '/statistic/user-behavior',
      search: member_id ? `?id=${member_id}` : '',
    });
    setPage(0);
    setSearchKey('');
  };

  const getList = useCallback(
    debounce(({ sortBy, searchKey, pageNumber, pageSize, member_id }) => {
      let uri = `get_all=${isCanSeeAll}&search_key=${searchKey}&page_number=${pageNumber}&page_size=${pageSize}&sort=${sortBy}&member_id=${member_id}`;
      const url = `${api.apiUrl}/system/activity/behaviour?${uri}`;
      api
        .GET(url)
        .then((res) => {
          if (isMountedRef.current && res?.success) {
            setData(res.activities);
            setTotalRows(res.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    [isCanSeeAll]
  );

  const getActivityUsers = useCallback(() => {
    const url = `${api.apiUrl}/system/activity/users?get_all=${isCanSeeAll}`;
    api
      .GET(url)
      .then((res) => {
        if (isMountedRef.current && res.success) {
          setActivityUsers(res.users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isCanSeeAll]);

  useEffect(() => {
    getList({
      sortBy: orderBy,
      searchKey: '',
      pageNumber: page,
      pageSize: rowsPerPage,
      member_id: query_member_id,
    });
    setSelectedUser(query_member_id);

    // if (member_id) {
    //   query.delete('member_id');
    //   history.replace({
    //     search: query.toString(),
    //   });
    // }
    //eslint-disable-next-line
  }, [getList, query_member_id]);

  useEffect(() => {
    getActivityUsers();

    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, [getActivityUsers]);

  const loadSortedActivities = (property, order, pageNumber, pageSize) => {
    let sortBy = property;
    if (property === 'user') {
      sortBy = 'first_name';
    } else if (property === 'page') {
      sortBy = 'referer_url';
    }
    sortBy = order === 'desc' ? `-${sortBy}` : `${sortBy}`;
    getList({
      sortBy,
      searchKey,
      pageNumber: pageNumber >= 0 ? pageNumber : page,
      pageSize: pageSize ? pageSize : rowsPerPage,
      member_id: selectedUser,
    });
    setOrderBy(property);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedActivities(property, newOrder);
  };

  const handleChangePage = (newPage) => {
    if (newPage !== page) {
      loadSortedActivities(orderBy, order, newPage, null);
      setPage(newPage);
      console.log(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    const pageNumber = 0;
    loadSortedActivities(orderBy, order, pageNumber, pageSize);
    setRowsPerPage(pageSize);
    setPage(pageNumber);
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar variant="dense" className={classes.searchRoot}>
        <div className={classes.search}>
          <div aria-label="search-icon" className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchKey}
            onChange={search}
          />
        </div>
        <div className={classes.settingsWrapper}>
          <TimeZoneAutocomplete
            variant="outlined"
            value={timeZone}
            onChange={(e, value) => setTimeZone(value)}
          />
          <FormControl variant="outlined" className={classes.filerDropDown}>
            <InputLabel id="demo-simple-select-outlined-label">
              Filter By
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={activityUsers.length > 0 ? selectedUser : ''}
              onChange={(e) => filterByUser(e.target.value)}
              label="Filter By"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {activityUsers.map((user) => (
                <MenuItem key={user.member_id} value={user.member_id}>
                  {user.first_name} {user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Toolbar>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="activityTable"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data.map((row, index) => {
              let el = document.createElement('a');
              el.href = row.referer_url;
              const uri = el.pathname + el.search,
                fullName = `${row.first_name || ''} ${row.last_name || ''}`,
                selected = parseInt(selectedUser) === row.member_id;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`user_behaviour_activity_${index}`}
                >
                  <TableCell className={classes.tableCellUser}>
                    {selected ? (
                      fullName
                    ) : fullName.trim() ? (
                      <Tooltip
                        title={`Filter list by ${fullName}`}
                        placement="top"
                      >
                        <Link
                          className={classes.tableCellLink}
                          component="button"
                          onClick={() => filterByUser(row.member_id)}
                        >
                          {fullName}
                        </Link>
                      </Tooltip>
                    ) : (
                      'Unkonwn User'
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCellURL}>{uri}</TableCell>
                  <TableCell className={classes.tableCellEvents}>
                    {row.event_types ? row.event_types.join(',\n') : ''}
                  </TableCell>
                  <TableCell className={classes.tableCellDate}>
                    {row.create_date &&
                      defaultDateTimeZone(row.create_date, '', true, timeZone)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalRows > 25 && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(Math.ceil(totalRows / rowsPerPage))}
            page={page + 1}
            onChange={(e, newPage) => handleChangePage(newPage - 1)}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </Paper>
  );
};

export default GlobalUserBehaviour;
