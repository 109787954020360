import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import TableChartIcon from '@material-ui/icons/TableChart';
import SettingsIcon from '@material-ui/icons/Settings';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import { fade, makeStyles } from '@material-ui/core/styles';

import CardContainer from './CardContainer';
import ListContainer from './ListContainer';
import NavigationGroup from './NavigationGroup';
import PageSettingsModal from '../../components/Modal/PageSettingsModal';

import { getGroupList } from '../../redux/actions/group';
import { useHistory } from 'react-router-dom';
import { find, debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  groupTool: {
    marginTop: '20px',
  },
  // sortGroup: {
  //   float: 'right',
  //   paddingRight: '20px',
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginTop: '20px',
    marginRight: theme.spacing(2),
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // sortGroup: {
  //   float: 'right',
  //   paddingRight: '20px',
  // },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
}));

const MyGroup = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { loading, dispatch, groupList, groupsCount, location, pageSettings } =
    props;

  const pageType = 'Groups';
  const groupSettings = find(pageSettings, (o) => o.page_type === pageType);
  const defaultView = (groupSettings && groupSettings.view_type) || null;
  const defaultPageSize = (groupSettings && groupSettings.page_size) || 25;
  const defaultSortOrder = (groupSettings && groupSettings.sort_order) || null;

  let defaultOrderBy = 'group_name';
  let defaultOrder = 'asc';

  if (defaultSortOrder && defaultSortOrder[0]) {
    if (defaultSortOrder[0].charAt(0) === '-') {
      defaultOrderBy = defaultSortOrder[0].substring(1);
      defaultOrder = 'desc';
    } else {
      defaultOrderBy = defaultSortOrder[0];
    }
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize || 25);
  const [searchKey, setSearchKey] = useState('');

  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [view, setView] = useState(defaultView || 'tile');
  const [showGroupSortSetting, setShowGroupSortSetting] = useState(false);
  const toggleCard = (e, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };

  useEffect(() => {
    dispatch(
      getGroupList(false, {
        sort: defaultSortOrder,
        searchKey,
        pageNumber: pageNumber - 1,
        pageSize,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const loadSortedGroups = (
    property,
    value,
    filterByItems,
    search_key,
    page_number,
    page_size
  ) => {
    let sort = '';
    if (!property) {
      property = orderBy;
    }
    if (!value) {
      value = order;
    }
    if (property) {
      sort = value === 'desc' ? `-${property}` : `${property}`;
    }

    let params = {};
    params = { sort };
    // filterByItems = filterByItems ? filterByItems : filterBy;
    // if (Object.keys(filterByItems).length > 0) {
    //   //consider user recent filter options as well if exist
    //   params = { sort, filter: new URLSearchParams(filterByItems) };
    // } else {
    //     params = { sort };
    // }
    params.searchKey = search_key ? search_key : searchKey;
    params.pageNumber =
      page_number === null || page_number === undefined
        ? pageNumber - 1
        : page_number;
    params.pageSize = page_size ? page_size : pageSize;

    dispatch(getGroupList(false, params));
    setOrderBy(property);
    // setFilterBy(filterByItems);
  };

  const debouncedLoadSortedGroups = useCallback(
    debounce(loadSortedGroups, 500),
    []
  );

  const handleScheduleClick = (group) => {
    if (!!group && typeof group === 'object') {
      console.log('Group to be pushed', group);
      history.push({
        pathname: `${process.env.PUBLIC_URL}/calendar`,
        search: `?group_id=${group.group_id}`,
      });
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    debouncedLoadSortedGroups(null, null, null, value, null, null);
    setSearchKey(value);
  };

  return (
    <React.Fragment>
      <div className="group-section view-section">
        <div className="toggle-button-group">
          <NavigationGroup
            type="my-groups"
            searchKey={searchKey}
            handleSearch={handleSearch}
          />
          <ToggleButtonGroup value={view} exclusive onChange={toggleCard}>
            <ToggleButton value="tile" aria-label="tile">
              <Tooltip title="Group Tile">
                <ViewModuleIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="table" aria-label="table">
              <Tooltip title="Group Table">
                <TableChartIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="page-setting-button">
            <Tooltip title="Settings" arrow>
              <SettingsIcon
                className={classes.groupSettingSVG}
                onClick={() => setShowGroupSortSetting(true)}
              />
            </Tooltip>
          </div>
        </div>
        {view === 'tile' ? (
          <CardContainer
            type="group"
            // orderBy={orderBy}
            // order={order}
            pageSize={pageSize}
            pageNumber={pageNumber}
            // setOrderBy={(property) => setOrderBy(property)}
            // setOrder={setOrder}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            groups={groupList}
            totalCount={groupsCount}
            loadSortedGroups={loadSortedGroups}
            onScheduleClick={handleScheduleClick}
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
          ></CardContainer>
        ) : (
          <ListContainer
            type="group"
            orderBy={orderBy}
            order={order}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setOrderBy={(property) => setOrderBy(property)}
            setOrder={setOrder}
            setPageSize={setPageSize}
            setPageNumber={setPageNumber}
            groups={groupList}
            totalCount={groupsCount}
            loadSortedGroups={loadSortedGroups}
            openDetailsFor={
              !!location.state ? location.state.openDetailsFor : null
            }
          ></ListContainer>
        )}
        <PageSettingsModal
          pageType={pageType}
          loading={loading}
          open={showGroupSortSetting}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          setPageSize={setPageSize}
          setView={(view) => toggleCard(null, view)}
          onSave={loadSortedGroups}
          onClose={() => setShowGroupSortSetting(false)}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  groupList: state.group.groupList,
  groupsCount: state.group.groupsCount,
  loading: state.group.getGroupListLoading,
  pageSettings: state.member.pageSettings,
});

export default connect(mapStateToProps)(MyGroup);
