import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { updateGroupName } from '../../redux/actions/group';

import { defaultDateTimeZone } from '../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    height: '23px !important',
  },
  iconButtonEdit: {
    marginTop: theme.spacing(-0.5),
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  iconButtonCheck: {
    padding: 0,
    textTransform: 'capitalize',
  },
}));

const GroupDetail = (props) => {
  const dispatch = useDispatch();
  const { isLeader } = props;
  const styles = useStyles();

  const { groupData } = props;

  const [editField, setEditField] = useState('');
  const [newGroupData, setNewGroupData] = useState({
    group_name: groupData.group_name || '',
  });
  const [errors, setErrors] = useState({});

  const groupDetail = isLeader
    ? [
        {
          key: 'group_name',
          label: 'Name',
          value: groupData.group_name,
          edit: true,
          required: true,
        },
        {
          key: 'create_date',
          label: 'Date Created',
          value:
            (groupData.create_date &&
              defaultDateTimeZone(groupData.create_date, 'date')) ||
            '',
          edit: false,
          required: false,
        },
        {
          key: 'total_members',
          label: 'Total Members',
          value: groupData.total_member,
          edit: false,
          required: false,
        },
      ]
    : [
        {
          key: 'group_name',
          label: 'Name',
          value: groupData.group_name,
          edit: false,
          required: false,
        },
        {
          key: 'group_leader_name',
          label: 'Leader',
          value: groupData.group_leader_name,
          edit: false,
          required: false,
        },
        {
          key: 'group_create_date',
          label: 'Date Created',
          value:
            (groupData.group_create_date &&
              defaultDateTimeZone(groupData.group_create_date, 'date')) ||
            '',
          edit: false,
          required: false,
        },
        {
          key: 'create_date',
          label: 'Date Joined',
          value:
            (groupData.group_join_date &&
              defaultDateTimeZone(groupData.group_join_date, 'date')) ||
            '',
          edit: false,
          required: false,
        },
        {
          key: 'total_member',
          label: 'Total Members',
          value: groupData.total_member,
          edit: false,
          required: false,
        },
      ];

  const validateInput = () => {
    let isErr = false;
    if (!newGroupData.group_name) {
      setErrors((prevState) => ({
        ...prevState,
        group_name: 'Please Enter Group Name!',
      }));
      isErr = true;
    }
    return isErr;
  };

  const handleUpdate = () => {
    let formData = new FormData();
    if (!validateInput()) {
      Object.keys(newGroupData).forEach((key) =>
        formData.append(key, newGroupData[key])
      );
      dispatch(updateGroupName(groupData.group_id, formData));
      setErrors({});
      setEditField('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewGroupData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {groupDetail &&
          groupDetail.map((row, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <b>{row.label}</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {editField === row.key ? (
                    <Grid container direction="row" alignItems="center">
                      <TextField
                        autoFocus
                        required={row.required}
                        color="primary"
                        defaultValue={row.value}
                        name={row.key}
                        label={row.label}
                        onChange={handleChange}
                        error={!!errors[row.key]}
                        helperText={errors[row.key] ? errors[row.key] : ''}
                        InputProps={{
                          endAdornment: (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className={styles.iconButtonCheck}
                              onClick={() => handleUpdate(row)}
                            >
                              save
                            </Button>
                          ),
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography variant="body1">{row.value}</Typography>
                      </Grid>
                      {row.edit && (
                        <Grid className={styles.iconWrapper} item>
                          <IconButton
                            className={styles.iconButtonEdit}
                            onClick={() => setEditField(row.key)}
                          >
                            <Tooltip
                              title={`Edit Group ${row.label}`}
                              placement="top"
                              arrow
                            >
                              <EditOutlinedIcon color="primary" size="small" />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default GroupDetail;
