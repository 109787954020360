import dayjs from 'dayjs';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import MailAttach from '../MailAttach';
import MailBody from '../EmailDetails/MailBody';
import { replyStyles } from './ReplyStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAvatar from '../../../../../components/MaterialAvatar';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Typography } from '@material-ui/core';

export const ReplyMail = ({ email, member }) => {
  const classes = replyStyles();

  return (
    <div style={{ marginTop: 20 }}>
      <Typography>Reply</Typography>
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <div className={classes.root}>
            <div className={classes.avatarArea}>
              <MaterialAvatar
                classes={'email-preview'}
                src={member.amera_avatar_url}
                alt={`${member.first_name} ${member.last_name}`}
                firstName={member.first_name}
                lastName={member.last_name}
              />
              <div className={classes.greenButton}>
                <SVG name="mailReply" />
              </div>
              <span className={classes.subText}>
                From {email.first_name + ' ' + email.last_name}
              </span>
            </div>
            <div className={classes.avatarArea}>
              <div className={classes.composeTime}>
                {dayjs(email.time).format('MMM D YY H:M')}
              </div>
              <div
                className={
                  email.is_stared ? classes.greenButton : classes.darkButton
                }
              >
                <SVG name="mailStarred" />
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <MailBody body={email.body} id={email.id} />
          <MailAttach attachFiles={email.attachments} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
