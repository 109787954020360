import React from 'react';
import Grid from '@material-ui/core/Grid';
import ContactPreview from '../../../components/ContactPreview';
import Chip from '@material-ui/core/Chip';
import { COMPANY_ROLES, DEPARTMENT_ROLES } from '../../../utils/company';
import Tooltip from '@material-ui/core/Tooltip';

const CompanyMember = ({ contactData }) => {
  const isOwner = contactData.company_role === COMPANY_ROLES.owner;
  const isAdmin = contactData.company_role === COMPANY_ROLES.admin;
  const isDepLeader = contactData.department_status === DEPARTMENT_ROLES.leader;
  return (
    <Grid item container justify="space-between" alignItems="center">
      <Grid item xs={8}>
        <ContactPreview contactData={contactData} isShowAvatar />
      </Grid>
      <Grid container item xs={4} spacing={1}>
        {isOwner && (
          <Grid item>
            <Chip size="small" label="Owner" />{' '}
          </Grid>
        )}
        {isAdmin && (
          <Grid item>
            <Chip size="small" label="Administrator" />
          </Grid>
        )}
        {isDepLeader && (
          <Grid item>
            <Tooltip title={contactData.department_name}>
              <Chip size="small" label="Leader" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CompanyMember;
