import React from 'react';
import Modal from '../../../../components/Modal/modal';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import CardButton from '../../../../components/Dashboard/CardButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmailIcon from '@material-ui/icons/Email';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { displayableFullName } from '../../../../utils/contact';
// import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { defaultDateTimeZone } from '../../../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  box: {
    border: `1px solid ${theme.palette.secondary.main}`,
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  content: {
    width: '59rem',
    maxWidth: 'unset',
  },
  descriptionContainer: {
    height: '7rem',
    overflowX: 'hidden',
  },
  description: {
    paddingRight: theme.spacing(1),
    textAlign: 'justify',
  },
  footer: {
    padding: theme.spacing(2),
  },
  chip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));

const ProjectInviteModal = (props) => {
  const { isOpen, onClose, data, onAcceptClick, onDeclineClick } = props;
  const {
    startDate,
    estimatedEndDate,
    company_name,
    inviterName,
    project_title,
    project_owner,
    proposed_roles,
    formattedRate,
    scopeDescription,
  } = data;
  const classes = useStyles();
  const roles = useSelector((s) => s.project.projectRoles);

  const content = () => (
    <Grid container spacing={2} className={classes.content}>
      <Grid item xs={12} container spacing={2} className={classes.box}>
        <Grid item xs={12}>
          <Typography variant="h6" color="initial" gutterBottom>
            Project Details
          </Typography>
        </Grid>

        <Grid item container xs={4}>
          <Grid item xs={6}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Project Name</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap variant="body2" gutterBottom>
              {project_title}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Start Date</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap variant="body2" gutterBottom>
              {startDate && defaultDateTimeZone(startDate, 'date')}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Estimated End Date</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap variant="body2" gutterBottom>
              {estimatedEndDate &&
                defaultDateTimeZone(estimatedEndDate, 'date')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={4} alignItems="baseline">
          <Grid item xs={5}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Invited by</b>
            </Typography>
          </Grid>
          <Grid item container xs={7} justify="flex-start" alignItems="center">
            <Grid item>
              <Typography noWrap variant="body2" gutterBottom>
                {inviterName}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Project Owner</b>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography noWrap variant="body2" gutterBottom>
              {displayableFullName(project_owner, false)}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Company</b>
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography noWrap variant="body2" gutterBottom>
              {company_name}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={4}>
          <Grid item xs={6}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Your Roles</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {proposed_roles.map((rId) => (
              <Chip
                className={classes.chip}
                size="small"
                label={roles.find((r) => r.id === rId).name}
              />
            ))}
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap variant="body2" color="secondary" gutterBottom>
              <b>Your Proposed Rate</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography noWrap variant="body2">
              {formattedRate}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2} className={classes.box}>
        <Grid item xs={12}>
          <Typography variant="h6" color="initial">
            More Info and Terms
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.descriptionContainer}>
            <Typography variant="body2" className={classes.description}>
              {scopeDescription}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
  const action = () => (
    <Grid
      className={classes.footer}
      container
      direction="row"
      justify="space-between"
      wrap="nowrap"
    >
      <Grid item>
        <CardButton variant="cancel" text="Decline" onClick={onDeclineClick} />
      </Grid>
      <Grid container item wrap="nowrap" justify="flex-end">
        <div style={{ marginRight: '1rem' }}>
          <CardButton variant="info" text="Cancel" onClick={onClose} />
        </div>
        <div>
          <CardButton
            variant="okFilled"
            startIcon={<CheckCircleOutlineIcon />}
            text="Accept Contract"
            onClick={onAcceptClick}
          />
        </div>
      </Grid>
    </Grid>
  );

  const handleEmailClick = () => {};
  const handleVideoCallClick = () => {};

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">New Project Invite</Typography>
          </Grid>
          {/* TODO: find another way to layout this */}
          <Grid item style={{ marginLeft: '34rem' }}>
            <Tooltip title="Send email to the person who invited you">
              <IconButton onClick={handleEmailClick}>
                <EmailIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Initiate a video call with a person who invited you">
              <IconButton onClick={handleVideoCallClick}>
                <VideoCallIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      }
      ModalContent={content}
      ModalAction={action}
      maxWidth={false}
    />
  );
};

export default ProjectInviteModal;
