import { set } from 'lodash';

export const validateLocation = (values) => {
  let errors = {};
  // Must have postal
  const { postal_code } = values;
  if (!postal_code) {
    set(errors, `postal_code`, 'Postal code is required');
  }
  return errors;
};
