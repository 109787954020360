import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from '../../jss/views/authFormHeaderStyle';

const useStyles = makeStyles(styles);

const FormHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.welcomeSection}>
      <div className={classes.messageContainer}>
        <div className={classes.largeMessage}>
          <div>Hey</div>
          <div>Glad to see you</div>
        </div>
        <div className={classes.mediumMessage}>
          <div>Welcome to secure collaboration with</div>
          <div>AmeraShare™.</div>
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
