// import React from 'react';
// import { Dropdown } from 'react-bootstrap';
// import { IconMuteEveryone } from '../icons';

// const LiveStream = (props) => (
//   <Dropdown.Item eventKey={props.eveneKey}>
//     <div className="amera-icon">
//       <IconMuteEveryone />
//     </div>
//     <span>Mute Everyone </span>
//   </Dropdown.Item>
// );

// export default LiveStream;

/* global APP */

import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconMuteEveryone } from '../icons';
import { injectIntl } from 'react-intl';
/**
 * FullScreen
 */

export class MuteEveryone extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      everyonemuted: 'mute',
    };
  }
  _muteEveryone = () => {
    //  alert(this.state.everyonemuted);

    let state = this.state.everyonemuted;
    APP.vacall.room.removeCommand('Mute_everyone');
    APP.vacall.room.sendCommand('Mute_everyone', {
      value: state,
      attributes: {
        state,
      },
    });
    if (state === 'mute') {
      this.setState({ everyonemuted: 'unmute' });
    } else {
      this.setState({ everyonemuted: 'mute' });
    }
  };
  render() {
    console.log(APP);
    const { intl } = this.props;
    const messages = {
      muteevery: {
        id: 'app.home.muteevery',
        defaultMessage: 'Mute Everyone',
      },
      unmuteevery: {
        id: 'app.home.unmuteevery',
        defaultMessage: 'Unmute Everyone',
      },
    };

    return (
      <Dropdown.Item
        text={this.props.text}
        eventKey={this.props.eveneKey}
        onClick={this._muteEveryone}
      >
        <div className="amera-icon">
          <IconMuteEveryone />
        </div>
        <span>
          {this.state.everyonemuted === 'unmute'
            ? intl.formatMessage(messages.unmuteevery)
            : intl.formatMessage(messages.muteevery)}
        </span>
      </Dropdown.Item>
    );
  }
}
//export default MuteEveryone;
export default injectIntl(MuteEveryone);
