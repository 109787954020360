import * as api from '../../config/api';

import {
  IS_LOADING_CREATE_FILESHARE,
  ADD_FILESHARE_DATA,
  SET_SHARE_ALERT,
  IS_LOADING_DECRYPT_FORM,
  DECRYPTED_FILE_DATA,
  IS_LOADING_ENCRYPT,
  ENCRYPT_SUCCESS,
  FETCH_FILE_GROUPS_START,
  FETCH_FILE_GROUPS_SUCCESS,
  FETCH_FILE_GROUPS_FAILED,
  ATTACHMENT_DATA,
  FILETREE_DATA,
  GROUPTREE_DATA,
} from '../actionTypes/fileshare.js';

import {
  processSelectedFilesInMain,
  processSelectedFilesLocalInMain,
  decryptSelectedFilesLocalInMain,
} from '../../utils/electron';

import { prepareTree } from '../../utils/fileshare';

import { setFileShareAlert } from '../../utils/alert';

const apiUrl = api.apiUrl;

// Downloads the file to default directory
export const getDownloadFile = (ameraFileUrl, name) => {
  const url = `${apiUrl}${ameraFileUrl}`;
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((downloadUrl) => {
      // console.log('url', downloadUrl);
      let tag = document.createElement('a');
      tag.href = downloadUrl;
      tag.download = name;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    });
};

export const postAttachFiles = (formData) => {
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_CREATE_FILESHARE,
    });
    const url = `${apiUrl}/mail/attach`;
    api
      .POST(url, formData, {}, false)
      .then((res) => {
        console.log(res.data);
        dispatch({
          /* 
            Because we have overwriting logic now (and some files might be deleted after we upload),
            back returns the whole filelist, not just an addition
          */
          type: ATTACHMENT_DATA,
          payload: res.data,
        });
        const payload = setFileShareAlert(res, true);
        dispatch(setShareAlert(payload));
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: ADD_FILESHARE_DATA,
          payload: [],
        });
        const payload = setFileShareAlert(error, false);
        dispatch(setShareAlert(payload));
      });
  };
};

/*
  New share file API
*/

export const shareFile = (data) => {
  const url = `${apiUrl}/drive/member/files/sharing`;
  const options = {
    'Content-Type': 'application/json',
  };

  return function (dispatch) {
    api
      .POST(url, JSON.stringify(data), options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

export const deleteShare = (form) => {
  const url = `${apiUrl}/drive/member/files/sharing`;
  const options = {
    'Content-Type': 'application/json',
  };

  return function (dispatch) {
    api
      .DELETE(url, form, options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

export const putCopySharedFile = (form) => {
  const url = `${apiUrl}/drive/member/files/sharing`;
  const options = {
    'Content-Type': 'application/json',
  };

  return function (dispatch) {
    api
      .PUT(url, form, options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

/*
  MEMBER FILES API
*/

export const getMemberFiles = () => {
  return function (dispatch) {
    console.log('Request file trees');
    const url = `${apiUrl}/drive/member/files`;
    return api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

export const postUploadFiles = (formData, path) => {
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_CREATE_FILESHARE,
    });
    const url = `${apiUrl}${path}`;
    return api
      .POST(url, formData, {}, false)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          if (path === '/drive/member/files') {
            dispatch({
              type: FILETREE_DATA,
              payload: {
                main_tree: prepareTree(res.main_tree),
                bin_tree: prepareTree(res.bin_tree),
              },
            });
          } else {
            dispatch({
              type: GROUPTREE_DATA,
              payload: {
                groupId: parseInt(
                  path.replace(/\/drive\/group\/|\/files/gi, '')
                ),
                main_tree: prepareTree(res.main_tree),
                bin_tree: prepareTree(res.bin_tree),
              },
            });
          }
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

export const deleteFile = (fileIds) => {
  const url = `${apiUrl}/drive/member/files`;
  const data = JSON.stringify({
    node_ids_list: fileIds,
  });
  const options = {
    'Content-Type': 'application/json',
  };

  // console.log('Deleting the following ids', fileIds);

  return function (dispatch) {
    return api
      .DELETE(url, data, options)
      .then((res) => {
        console.log(res);
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

export const putModifyNodes = (form) => {
  const data = JSON.stringify({
    changes: form,
  });

  return function (dispatch) {
    const url = `${apiUrl}/drive/member/files`;
    api
      .PUT(url, data, { 'Content-Type': 'application/json' })
      .then((res) => {
        console.log(res);
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

/*
  MEMBER BIN API
*/
export const deleteFromBin = (fileIds) => {
  const url = `${apiUrl}/drive/member/files/bin`;
  const data = JSON.stringify({
    node_ids_list: fileIds,
  });
  const options = {
    'Content-Type': 'application/json',
  };

  // console.log('Deleting the following ids', fileIds);

  return function (dispatch) {
    return api
      .DELETE(url, data, options)
      .then((res) => {
        console.log(res);
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

export const putRestoreFile = (fileIds) => {
  const url = `${apiUrl}/drive/member/files/bin`;
  const data = JSON.stringify({
    node_ids_list: fileIds,
  });
  const options = {
    'Content-Type': 'application/json',
  };

  console.log('Restoring following ids', fileIds);

  return function (dispatch) {
    return api
      .PUT(url, data, options)
      .then((res) => {
        console.log(res);
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: FILETREE_DATA,
            payload: {
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
      });
  };
};

// End Member File API

/*
  GROUP TREE API
*/

export const getGroupFiles = (groupId) => {
  return function (dispatch) {
    console.log('Request group file trees');
    const url = `${apiUrl}/drive/group/${groupId}/files`;
    return api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: GROUPTREE_DATA,
            payload: {
              groupId: groupId,
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

export const createGroupFolder = (groupId, folderName, currentFolderId) => {
  return function (dispatch) {
    let formData = new FormData();
    formData.set('folder_name', folderName);
    formData.set('tagetFolderId', currentFolderId);
    console.log(`Creating folder "${folderName}" for group "${groupId}"`);
    const url = `${apiUrl}/drive/group/${groupId}/files`;
    return api
      .POST(url, formData, {})
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: GROUPTREE_DATA,
            payload: {
              groupId: groupId,
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

export const deleteGroupNodes = (groupId, fileIds) => {
  return function (dispatch) {
    const url = `${apiUrl}/drive/group/${groupId}/files`;
    const data = JSON.stringify({
      node_ids_list: fileIds,
    });
    const options = {
      'Content-Type': 'application/json',
    };
    return api
      .DELETE(url, data, options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: GROUPTREE_DATA,
            payload: {
              groupId: groupId,
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

export const putMoveGroupNodes = (groupId, changes) => {
  return function (dispatch) {
    const url = `${apiUrl}/drive/group/${groupId}/files`;
    const data = JSON.stringify({
      changes: changes,
    });
    const options = {
      'Content-Type': 'application/json',
    };
    return api
      .PUT(url, data, options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: GROUPTREE_DATA,
            payload: {
              groupId: groupId,
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

/*
  GROUP BIN API
*/

export const deleteFromGroupBin = (groupId, fileIds) => {
  return function (dispatch) {
    const url = `${apiUrl}/drive/group/${groupId}/files/bin`;
    const data = JSON.stringify({
      node_ids_list: fileIds,
    });
    const options = {
      'Content-Type': 'application/json',
    };
    return api
      .DELETE(url, data, options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: GROUPTREE_DATA,
            payload: {
              groupId: groupId,
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

export const restoreFromGroupBin = (groupId, fileIds) => {
  return function (dispatch) {
    const url = `${apiUrl}/drive/group/${groupId}/files/bin`;
    const data = JSON.stringify({
      node_ids_list: fileIds,
    });
    const options = {
      'Content-Type': 'application/json',
    };
    return api
      .PUT(url, data, options)
      .then((res) => {
        if (res.success) {
          console.log('main tree', res.main_tree);
          console.log('bin tree', res.bin_tree);
          dispatch({
            type: GROUPTREE_DATA,
            payload: {
              groupId: groupId,
              main_tree: prepareTree(res.main_tree),
              bin_tree: prepareTree(res.bin_tree),
            },
          });
        } else {
          console.error(res.description);
        }
      })
      .finally(() => true)
      .catch((error) => {
        console.error(error);
        // dispatch({
        //   type: ADD_FILESHARE_DATA,
        //   payload: [],
        // });
      });
  };
};

// End Shared File API

export const setShareAlert = (payload) => ({
  type: SET_SHARE_ALERT,
  payload,
});

// Encrypt File API

export const createLocalFile = (destination, files) => {
  return (dispatch) => {
    dispatch({
      type: IS_LOADING_ENCRYPT,
    });
    processSelectedFilesLocalInMain(destination, files)
      .then((finalFiles) => {
        console.log(`Final Files ${finalFiles}`);
        dispatch({
          type: ENCRYPT_SUCCESS,
          payload: [],
        });
        const payload = setFileShareAlert(
          {
            description: `Files encrypted locally to: ${destination}`,
          },
          true
        );
        dispatch(setShareAlert(payload));
      })
      .catch((error) => {
        dispatch({
          type: ADD_FILESHARE_DATA, //Mayve change ths later
          payload: [],
        });
        const payload = setFileShareAlert(error, false);
        dispatch(setShareAlert(payload));
      });
  };
};

export const encryptFileToSend = (files) => {
  return (dispatch) => {
    dispatch({
      type: IS_LOADING_ENCRYPT,
    });
    return processSelectedFilesInMain(files)
      .then((encryptedFiles) => {
        dispatch({
          type: ENCRYPT_SUCCESS,
          payload: [],
        });
        return encryptedFiles;
      })
      .catch((error) => {
        dispatch({
          type: ADD_FILESHARE_DATA, //Mayve change ths later
          payload: [],
        });
        const payload = setFileShareAlert(error, false);
        dispatch(setShareAlert(payload));
      });
  };
};

export const decryptLocalFile = (destination, files) => {
  console.log('We are going to decrypt locally through apiService in main');
  return async (dispatch) => {
    dispatch({
      type: IS_LOADING_DECRYPT_FORM,
    });
    try {
      console.log('We are going to decrypt after dispatch');
      let finalFiles = await decryptSelectedFilesLocalInMain(
        destination,
        files
      );
      console.log(`Final Files ${finalFiles}`);
      dispatch({
        type: DECRYPTED_FILE_DATA,
        payload: [],
      });
      const payload = setFileShareAlert(
        {
          description: `Files decrypted to: ${destination}`,
        },
        true
      );
      dispatch(setShareAlert(payload));
    } catch (error) {
      dispatch({
        type: DECRYPTED_FILE_DATA,
        payload: [],
        error: error,
      });
    }
  };
};

// End Encrypt File API

// Get list of groups having files
export const getFileGroups = () => (dispatch) => {
  const url = `${apiUrl}//drive/groups`;
  dispatch({ type: FETCH_FILE_GROUPS_START });
  api
    .GET(url)
    .then((res) => {
      dispatch({
        type: FETCH_FILE_GROUPS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FILE_GROUPS_FAILED,
        payload: [],
      });
      const payload = setFileShareAlert(error, false);
      dispatch(setShareAlert(payload));
    });
};
