import React from 'react';
import { MailDetails } from './MailDetails';

class MailDetailsPrintWrapper extends React.Component {
  render() {
    return (
      <MailDetails
        email={this.props.email}
        member={this.props.member}
        isOwnEmail={this.props.isOwnEmail}
      />
    );
  }
}

export { MailDetailsPrintWrapper };
