import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ameralogoPng from '../../assets/img/logo-login.png';

import styles from '../../jss/views/passUpdateStyle';
import FormHeader from '../../components/AuthFormHeader';

import { validateForgotKey, resetPassword } from '../../redux/actions/member';
import { validateResetPassword } from '../../utils/validator/Register';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);

const ResetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { member, validateForgotKey } = props;
  const isLoading = member.isLoading;
  const isUpdated = member.isUpdated;
  const error = member.error;
  const [values, setValues] = useState({ password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });

  // const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  // const [passwordErr, setPasswordErr] = useState('');
  // const [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  const passwordRef = React.useRef();
  const confirmPasswordRef = React.useRef();
  const {
    match: {
      params: { forgot_key },
    },
  } = props;

  useEffect(() => {
    validateForgotKey(forgot_key);
  }, [forgot_key, validateForgotKey]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setValues((ps) => ({ ...ps, [name]: value }));
    setErrors({ password: '', confirmPassword: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const foundErrors = validateResetPassword(values);

    if (!!values && Object.keys(foundErrors).length === 0) {
      setErrors({ password: '', confirmPassword: '' });
      const formData = { forgot_key, password: values.password };
      props.resetPassword(formData);
    } else {
      setErrors(foundErrors);
    }
  };

  useEffect(() => {
    passwordRef && passwordRef.current && passwordRef.current.focus();
  }, [passwordRef]);

  useEffect(() => {
    if (isUpdated && !isLoading) {
      setTimeout(() => {
        member.isUpdated = false;
        history.push('/login');
      }, 3000);
    }
  });

  console.log('values', values);

  function renderResetForm() {
    return (
      <div className={classes.authPage}>
        <FormHeader classes={classes} />
        <form
          className={classes.formSection}
          onSubmit={handleSubmit}
          onChange={handleChange}
          noValidate
        >
          <div className={ameralogoPng.logoImg}>
            <img src={ameralogoPng} alt="logo" />
          </div>
          <div className={classes.passwordInput}>
            <TextField
              type="password"
              name="password"
              error={!!errors.password}
              inputRef={passwordRef}
              helperText={!!errors.password ? errors.password : ''}
              label="Password"
              fullWidth
              color="primary"
              value={values.password}
              required
              inputProps={{
                minLength: 6,
                maxLength: 12,
              }}
              disabled={!!error || isUpdated}
            />
          </div>
          <div className={classes.confirmPasswordInput}>
            <TextField
              type="password"
              name="confirmPassword"
              error={!!errors.confirmPassword}
              inputRef={confirmPasswordRef}
              helperText={
                !!errors.confirmPassword ? errors.confirmPassword : ''
              }
              label="Confirm Password"
              fullWidth
              color="primary"
              value={values.confirmPassword}
              required
              inputProps={{
                minLength: 6,
                maxLength: 12,
              }}
              disabled={!!error || isUpdated}
            />
          </div>
          <div className={classes.authWrapper}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              fullWidth
              disabled={!!error || isLoading || isUpdated}
            >
              {isLoading ? 'Updating...' : 'Submit'}
            </Button>
          </div>
          <div style={{ marginTop: 20 }}>
            {(!!error || isUpdated) && (
              <Typography color={isUpdated ? 'primary' : 'error'}>
                {isUpdated
                  ? 'Password Updated Successfully. Please Login again.'
                  : error}
              </Typography>
            )}
          </div>
        </form>
      </div>
    );
  }
  return renderResetForm();
};

const mapStateToProps = (state) => ({
  member: state.member,
});

const mapDispatchToProps = (dispatch) => {
  return {
    validateForgotKey: (formData) => {
      dispatch(validateForgotKey(formData));
    },
    resetPassword: (formData) => {
      dispatch(resetPassword(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
