import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import MemberCard from './MemberCard';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const SharedWithMembersList = ({
  sharedWithMembers,
  handleUnshareClick,
  handleMemberClick,
}) => {
  return sharedWithMembers.map((m) => {
    const {
      share_id,
      shared_date,
      consumer_id,
      // consumer_email,
      consumer_first_name,
      consumer_last_name,
      consumer_company_name,
      job_title,
      department,
    } = m;

    return (
      <ListItem key={share_id} className="member_item">
        <ListItemText>
          <MemberCard
            member_id={consumer_id}
            first_name={consumer_first_name}
            last_name={consumer_last_name}
            shared_date={shared_date}
            job_title={job_title}
            department={department}
            company_name={consumer_company_name}
            handleMemberClick={handleMemberClick}
          />
        </ListItemText>
        <ListItemSecondaryAction>
          <Button onClick={() => handleUnshareClick(share_id)}>Unshare</Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
};

export default SharedWithMembersList;
