import { makeStyles } from '@material-ui/core/styles';
import { green, dark, gray } from '../../../../../jss/colors';

export const replyStyles = makeStyles((theme) => ({
  replyContainer: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  fromTo: {
    marginLeft: 10,
    fontSize: '15px',
    fontFamily: 'bold',
  },
  composeTime: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    fontFamily: 'Roboto',
    color: dark,
    minHeight: 20,
  },
  to: {
    color: dark,
  },
  avatarArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  avatar: {
    width: 50,
    height: 50,
    border: '3px solid white',
    borderRadius: 7.5,
    boxShadow: '1px 1px 9px 1px #c2c3c7',
  },
  title: {
    fontSize: '30px',
    fontFamily: 'bold',
  },
  subText: {
    color: gray,
    fontWeight: ' bold',
    fontSize: '0.8rem',
    lineHeight: ' 24px',
    letterSpacing: '0.06em',
    fontFamily: 'Roboto',

    marginRight: 5,
  },
  darkButton: {
    '& svg': {
      fill: dark,
    },
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 10,
  },
  greenButton: {
    '& svg': {
      fill: green,
    },
    maxHeight: 35,
    maxWidth: 35,
    marginLeft: 10,
  },
  replyImage: {
    width: 13,
  },
}));
