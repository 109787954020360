import React from 'react';
import TextField from '@material-ui/core/TextField';
import EventDetailMap from './EventDetailMap';

export default function EventDetailLocation(props) {
  const [eventDetail_Address, setEventDetail_Address] = React.useState(
    props.eventDetail_Location.Address
  );
  const [eventDetail_Zip, setEventDetail_Zip] = React.useState(
    props.eventDetail_Location.Zip
  );

  const setEventDetail_Location = () => {
    let location_data = { Address: eventDetail_Address, Zip: eventDetail_Zip };
    props.setEventDetail_Location(location_data);
  };

  return (
    <div className="EventLocation">
      <div>
        <TextField
          required
          className="width_75"
          value={eventDetail_Address}
          label="Event Location"
          variant="outlined"
          onChange={(event) => {
            setEventDetail_Address(event.target.value);
            setEventDetail_Location();
          }}
        />
        <TextField
          required
          className="width_25"
          value={eventDetail_Zip}
          label="Zip"
          variant="outlined"
          onChange={(event) => {
            setEventDetail_Zip(event.target.value);
            setEventDetail_Location();
          }}
        />
      </div>
      <div className="TextBorder">
        <span>Map to the location</span>
      </div>
      <div>
        <EventDetailMap
          id="myMap"
          options={{
            center: { lat: 41.0082, lng: 28.9784 },
            zoom: 8,
          }}
          onMapLoad={(map) => {
            /*
            var marker = new window.google.maps.Marker({
              position: { lat: 41.0082, lng: 28.9784 },
              map: map,
              title: 'Hello Istanbul!',
            });
            */
          }}
        />
      </div>
    </div>
  );
}
