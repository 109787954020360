import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
// import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';

// Modals and Dialogs
import EventInfoModal from '../../components/Modal/EventModals/EventInfoModal/EventInfoModal';
import EventCancelModal from '../../components/Modal/EventModals/EventCancelModal/EventCancelModal';
import EventFilterDialog from '../../components/Modal/EventModals/EventFilterDialog/EventFilterDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import FunctionalToolbar from '../../components/FunctionalToolbar';
import EventSettingsModal from '../../components/Modal/EventModals/EventSettingsModal';

// import CustomEvent from './CustomEvent';
import EventTitle from './EventTitle';
import PageSettingsModal from '../../components/Modal/PageSettingsModal';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { find } from 'lodash';
import {
  getScheduleEvent,
  putModifyEvent,
} from '../../redux/actions/schedule_event';

import { loadContacts } from '../../redux/actions/contact';
import { getGroupList } from '../../redux/actions/group';
import { putMemberSetting, getMemberSetting } from '../../redux/actions/member';
import { openDrawer } from '../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';

import CollaborativeCalendar from './CollaborativeCalendar';

import {
  CALENDAR_VIEWS,
  EVENT_FILTER_TYPE,
  parseFromStr,
  getMonthLimits,
  formatInTimeZone,
  eventPropGetter,
  getParams,
  filterEvents,
  filterDescription,
  isEditableEvent,
} from '../../utils/calendar';

import { includes } from 'lodash';
const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const DnDCalendar = withDragAndDrop(Calendar);

const defaulCreateModalState = {
  isShow: false,
  startDateTime: null, // null or Date
  endDateTime: null, // null or Date
};

const defaultEventFilterState = {
  filterType: EVENT_FILTER_TYPE.all,
  contacts: [],
  group: {},
};

const NewCalendar = (props) => {
  const {
    events,
    colors,
    dispatch,
    group_id,
    member_ids,
    memberId,
    location,
    groupList,
    contactList,
    pageSettings,
    memberSetting,
  } = props;
  const { date_format, time_format } = memberSetting;

  const pageType = 'Calendar';
  const calendarSettings = find(pageSettings, (o) => o.page_type === pageType);
  const defaultView = (calendarSettings && calendarSettings.view_type) || null;

  const [createModalOptions, setCreateModalOptions] = useState(
    defaulCreateModalState
  );

  // Filter for events related to contact or group
  const [eventsFilter, setEventsFilter] = useState(defaultEventFilterState);
  const [isShowFilterSelector, setShowFilterSelector] = useState(false);

  // Date change on drag
  const [dropped, setDropped] = useState(null);
  const [isShowDateChangeConfirm, setShowDateChangeConfirm] = useState(false);

  // Selected event for details, edit and cancel
  const [scoped, setScoped] = useState(null);
  const [isShowInfo, setShowInfo] = useState(false);
  const [isShowCancelModal, setShowCancelModal] = useState(false);
  const [isShowEditModal, setShowEditModal] = useState(false);
  const [isShowEventSettingModal, setShowEventSettingModal] = useState(false);

  // Not used to control the calendar, but to make the right API calls
  const [view, setView] = useState(defaultView || CALENDAR_VIEWS.month);
  const [range, setRange] = useState(null);
  const [showPageSettingsModal, setShowPageSettingsModal] = useState(false);

  const [state, setState] = useState({
    date_format: date_format ? date_format : 'MM/DD/YYYY',
    time_format: time_format ? time_format : 'AM/PM',
    timezone_id: null,
  });

  let formats = {
    dateFormat: 'dd',
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(
        date,
        time_format && time_format.includes('24') ? 'HH:mm' : 'hh:mm a',
        culture
      ),

    monthHeaderFormat: 'MMMM yyyy',
    dayHeaderFormat: 'EEEE MMMM dd',
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'MMMM dd', culture) +
      ' - ' +
      localizer.format(end, 'MMMM dd', culture),
  };
  // This fixes an issue with the calendar component where
  // setRange gets called twice
  // https://github.com/jquense/react-big-calendar/issues/1275
  // To fix: View is not changing
  const handleDrillDownChange = function (date, view) {
    setRange([date]);
    setView(view);
  };
  const handleVisibleRangeChange = (range) => setRange(range);
  const handleViewChange = (view) => setView(view);

  useEffect(() => {
    dispatch(getMemberSetting());
  }, [dispatch]);

  useEffect(() => {
    if (memberSetting) {
      setState((ps) => ({ ...ps, ...memberSetting }));
    }
  }, [memberSetting]);

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGroupList(true));
  }, [dispatch]);

  useEffect(() => {
    if (view === CALENDAR_VIEWS.collaborative) {
      return;
    }
    if (!!range) {
      const params = getParams(view, range);
      dispatch(getScheduleEvent(params));
    }
  }, [view, range, dispatch]);

  // Fetch on load
  useEffect(() => {
    const params = getParams(CALENDAR_VIEWS.month, getMonthLimits());
    dispatch(getScheduleEvent(params));
  }, [dispatch]);

  // If schedule is initated from Contacts window
  useEffect(() => {
    if (!!group_id) {
      const scopedGroup = groupList.find(
        (glo) => glo.group_id === parseInt(group_id)
      );

      if (!!location && !!location.search) {
        setCreateModalOptions({
          isShow: true,
          group: scopedGroup,
        });
      }

      setEventsFilter((ps) => ({
        ...ps,
        filterType: EVENT_FILTER_TYPE.group,
        group: scopedGroup,
      }));
    } else if (!!member_ids) {
      const parsed_member_ids = member_ids.split(',').map((id) => parseInt(id));

      const scopedContacts = contactList.filter((clo) =>
        includes(parsed_member_ids, clo.contact_member_id)
      );

      if (!!location && !!location.search) {
        setCreateModalOptions({
          isShow: true,
          contacts: scopedContacts,
        });
      }

      setEventsFilter((ps) => ({
        ...ps,
        filterType: EVENT_FILTER_TYPE.contacts,
        contacts: scopedContacts,
      }));
    }
  }, [contactList, groupList, group_id, location, member_ids, dispatch]);

  // const params = useParams();

  const handleEventDblClick = () => console.log('dbl click');

  const handleSlotSelect = async (obj) => {
    const { start, end } = obj;
    console.log('Events filter we get', eventsFilter);
    setCreateModalOptions((ps) => ({
      ...ps,
      isShow: true,
      startDateTime: start,
      endDateTime: end,
      contacts:
        eventsFilter.filterType === EVENT_FILTER_TYPE.contacts
          ? eventsFilter.contacts
          : [],
      group:
        eventsFilter.filterType === EVENT_FILTER_TYPE.group
          ? eventsFilter.group
          : null,
    }));
  };

  const handleSelectEvent = (event) => {
    setScoped(event);
    setShowInfo(true);
  };

  const handleEventDrop = (obj) => {
    const { start, end, event } = obj;
    const changes = {
      start: formatInTimeZone(start, 'UTC'),
      end: formatInTimeZone(end, 'UTC'),
      event_id: event.event_id,
    };
    setDropped(changes);
    setShowDateChangeConfirm(true);
    // dispatch(putModifyEvent(changes));
  };

  const handleDateChangeConfirm = () => {
    setShowDateChangeConfirm(false);
    if (!!dropped) {
      let formData = new FormData();
      // console.log('dropped', dropped);
      formData.set('event_data', JSON.stringify(dropped));
      formData.set('mode', 'date');

      dispatch(putModifyEvent(formData));
    }
    setDropped(null);
  };

  const handleDateChangeCancel = () => {
    setShowDateChangeConfirm(false);
    setDropped(null);
  };

  const handleEventResize = (obj) => console.log(obj);

  const handleCloseEventInfoClick = () => {
    setShowInfo(false);
    setScoped(null);
  };

  const handleEditClick = () => {
    console.log('Edit event clicked');
    setShowInfo(false);
    setShowEditModal(true);
    // setScoped(null);
  };

  const handleCancelClick = () => {
    setShowCancelModal(true);
    setShowInfo(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setScoped(null);
  };

  const handleCloseEventSettingModal = () => {
    setShowEventSettingModal(false);
  };

  const handleApplyEventFilter = (filter) => {
    setEventsFilter(filter);
    // if (filter.filterType !== EVENT_FILTER_TYPE.all) {
    //   setCreateModalOptions((ps) => ({
    //     ...ps,
    //     contacts: filter.contacts,
    //     group: filter.group,
    //   }));
    // }
  };

  const handleCreateEventClick = async () => {
    console.log('Events filter we get', eventsFilter);
    setCreateModalOptions({
      startDateTime: null,
      endDateTime: null,
      isShow: true,
      contacts:
        eventsFilter.filterType === EVENT_FILTER_TYPE.contacts
          ? eventsFilter.contacts
          : [],
      group:
        eventsFilter.filterType === EVENT_FILTER_TYPE.group
          ? eventsFilter.group
          : null,
    });
  };

  const saveMemberSetting = () => {
    const formattedForm = {
      member_profile: { ...state },
    };
    dispatch(putMemberSetting(formattedForm));
  };

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const drawer_type = useSelector((state) => state.drawer.openDrawer);

  useEffect(() => {
    if (!drawer_type) {
      setCreateModalOptions((ps) => ({ ...ps, isShow: false }));
      setShowEditModal(false);
      setScoped(null);
    }
  }, [drawer_type]);

  useEffect(() => {
    const openEventDrawer = async () =>
      dispatch(
        openDrawer(
          DRAWER_CONTENT_TYPE.event,
          {
            event: {
              inputs: isShowEditModal ? null : createModalOptions,
              selectedEvent: isShowEditModal ? scoped : null,
            },
          },
          null,
          'large'
        )
      );

    createModalOptions.isShow
      ? openEventDrawer()
      : !!scoped && isShowEditModal && openEventDrawer();
  }, [createModalOptions, dispatch, scoped, isShowEditModal]);

  return (
    <>
      {view === CALENDAR_VIEWS.collaborative ? (
        <CollaborativeCalendar
          view={view}
          handleViewChange={handleViewChange}
        />
      ) : (
        <>
          {!!scoped && isShowInfo && (
            <EventInfoModal
              show={isShowInfo}
              onEditClick={handleEditClick}
              onCancelClick={handleCancelClick}
              onClose={handleCloseEventInfoClick}
              selectedEvent={scoped}
              isEditable={isEditableEvent(scoped, memberId)}
            />
          )}

          {!!scoped && isShowCancelModal && (
            <EventCancelModal
              show={isShowCancelModal}
              onClose={handleCloseCancelModal}
              selectedEvent={scoped}
            />
          )}

          <ConfirmDialog
            title="Date change confirmation"
            open={isShowDateChangeConfirm}
            onCancel={handleDateChangeCancel}
            content="Are you sure you want to change dates for this event?"
            onOk={handleDateChangeConfirm}
          />

          {isShowFilterSelector && (
            <EventFilterDialog
              open={isShowFilterSelector}
              currentFilter={eventsFilter}
              onClose={() => setShowFilterSelector(false)}
              onApply={handleApplyEventFilter}
            />
          )}

          {isShowEventSettingModal && (
            <EventSettingsModal
              open={isShowEventSettingModal}
              onClose={handleCloseEventSettingModal}
              data={calendarSettings}
            />
          )}

          <Grid container className="h-100">
            {/* Disabled for now */}
            <Fab
              variant="extended"
              color="primary"
              size="medium"
              style={{
                position: 'fixed',
                zIndex: 1000,
                bottom: '2rem',
                right: '2rem',
                textTransform: 'none',
              }}
              // color="secondary"
              onClick={() => setShowFilterSelector(true)}
            >
              <FilterListIcon fontSize="small" />
              <Typography variant="body2">
                {filterDescription(eventsFilter)}
              </Typography>
            </Fab>

            <Grid item xs={12} className="h-100">
              <div className="big_calendar h-100">
                <DnDCalendar
                  startAccessor={(e) => e && parseFromStr(e.start, tz)}
                  endAccessor={(e) => e && parseFromStr(e.end, tz)}
                  titleAccessor={(e) => (
                    <EventTitle event={e} memberId={memberId} />
                  )}
                  draggableAccessor={(e) => isEditableEvent(e, memberId)}
                  resizableAccessor={(e) => isEditableEvent(e, memberId)}
                  allDayAccessor={(e) => e && e.is_full_day}
                  defaultView={view}
                  view={view}
                  onRangeChange={handleVisibleRangeChange}
                  onView={handleViewChange}
                  // resources={EVENT_TYPES}
                  // resourceAccessor={(e) => e.event_type}
                  localizer={localizer}
                  formats={formats}
                  onDrillDown={handleDrillDownChange}
                  popup={false}
                  events={filterEvents(events, eventsFilter)}
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSlotSelect}
                  selectable
                  eventPropGetter={(e, start, end, isSelected) =>
                    eventPropGetter(e, start, end, isSelected, colors, view)
                  }
                  onEventDrop={handleEventDrop}
                  onEventResize={handleEventResize}
                  // resizable
                  className="h-100"
                  // step={30}
                  components={{
                    toolbar: (props) => (
                      <FunctionalToolbar
                        {...props}
                        handleViewChange={handleViewChange}
                        handleCreateEventClick={handleCreateEventClick}
                        setShowPageSettingsModal={setShowPageSettingsModal}
                      />
                    ),
                  }}
                  // style={{ height: 800 }}
                  onDoubleClickEvent={handleEventDblClick}
                />
              </div>
            </Grid>
            <PageSettingsModal
              pageType={pageType}
              loading={false}
              open={showPageSettingsModal}
              setOrderBy={() => {}}
              setOrder={() => {}}
              setPageSize={() => {}}
              setView={handleViewChange}
              onSave={saveMemberSetting}
              onClose={() => setShowPageSettingsModal(false)}
              calendarState={state}
              setCalendarState={setState}
            />
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  events: state.scheduleEvents.data,
  colors: state.scheduleEvents.colors,
  groupList: state.group.groupList,
  contactList: state.contact.contacts,
  memberId: state.member.member.member_id,
  pageSettings: state.member.pageSettings,
  memberSetting: state.member.memberSetting,
  tzList: state.member.timezoneList,
});

NewCalendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(NewCalendar);
