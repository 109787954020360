import * as api from '../../config/api';

import {
  SET_SECURITY,
  SAVE_SECURITY_KEY,
  ENABLE_E2E_ENCRYPTION,
  ON_SECURITY_API_REQUEST,
  START_DECRYPTING,
} from '../actionTypes/security';

const apiUrl = api.apiUrl;

export const updateContactSecurity = (formData, contact_member_id) => {
  const url = `${apiUrl}/member/contact/security/${contact_member_id}`;
  return function (dispatch) {
    dispatch({ type: ON_SECURITY_API_REQUEST, payload: true });
    return api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({ type: ON_SECURITY_API_REQUEST, payload: false });
        if (res.success) {
          return res.data;
        }
        return null;
      })
      .catch((error) => {
        return null;
      });
  };
};

export const getContactSecurity = (contact_member_id) => {
  console.log('contact_member_id', contact_member_id);
  const url = `${apiUrl}/member/contact/security/${contact_member_id}`;
  return function (dispatch) {
    dispatch({ type: ON_SECURITY_API_REQUEST, payload: true });
    return api
      .GET(url)
      .then((res) => {
        dispatch({
          type: SET_SECURITY,
          payload: {
            security: res.data,
            contact_id: contact_member_id,
            type: 'contact',
          },
        });
        dispatch({ type: ON_SECURITY_API_REQUEST, payload: false });
        return res.data;
      })
      .catch((error) => {
        return null;
      });
  };
};

export const updateGroupSecurity = (formData, group_id, callBackToast) => {
  const url = `${apiUrl}/member/group/security/${group_id}`;
  return function (dispatch) {
    dispatch({ type: ON_SECURITY_API_REQUEST, payload: true });
    return api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({ type: ON_SECURITY_API_REQUEST, payload: false });

        if (res.success) {
          const snackbarOptions = {
            open: true,
            message: res.description,
            type: 'success',
          };
          callBackToast(snackbarOptions);
          return res.data;
        }
        return null;
      })
      .catch((error) => {});
  };
};

export const getGroupSecurity = (group_id) => {
  const url = `${apiUrl}/member/group/security/${group_id}`;
  return function (dispatch) {
    dispatch({ type: ON_SECURITY_API_REQUEST, payload: true });
    return api
      .GET(url)
      .then((res) => {
        dispatch({ type: ON_SECURITY_API_REQUEST, payload: false });

        dispatch({
          type: SET_SECURITY,
          payload: { security: res.data, group_id, type: 'group' },
        });
        return res.data;
      })
      .catch((error) => {});
  };
};

export const storeSecurityKey = (key) => ({
  type: SAVE_SECURITY_KEY,
  payload: key,
});

export const enableE2EEncryption = (enabled) => ({
  type: ENABLE_E2E_ENCRYPTION,
  payload: enabled,
});

export const startDecrypting = (started) => ({
  type: START_DECRYPTING,
  payload: started,
});
