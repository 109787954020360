import React from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const DepartmentAutocomplete = (props) => {
  const {
    departments,
    onChange,
    selected,
    variant = 'standard',
    multiple = true,
    label = null,
  } = props;
  return (
    <Autocomplete
      getOptionSelected={(o, v) => o.department_id === v.department_id}
      disableCloseOnSelect
      multiple={multiple}
      id="departments"
      options={departments}
      getOptionLabel={(o) => o.department_name}
      onChange={onChange}
      value={selected}
      ChipProps={{ size: 'small' }}
      noOptionsText={<Typography variant="body2">No matches</Typography>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          multiline
          margin="dense"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  departments: state.member.departmentsList,
});
export default connect(mapStateToProps)(DepartmentAutocomplete);
