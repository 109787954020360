import { makeStyles } from '@material-ui/core/styles';
// import { green, red, textPurple } from '../../../../../jss/colors';
import { green, textPurple } from '../../../../../jss/colors';

export const useMailListStyles = makeStyles((theme) => ({
  mailsContainerPanel: {
    width: '100%',
    '&::-webkit-scrollbar': {
      width: '0.8em',
      background: '#E3E3E3',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundColor: 'rgba(0,0,0,.1)',
    },
  },
  root: {
    cursor: 'pointer',
    marginBottom: '23px',
    position: 'relative',
    overflow: 'visible',
    background: '#FFFFFF',
    boxShadow: '0px 10px 10px #D0D6E3',
    borderRadius: 10,
    paddingBottom: 6,
    '&:hover, &:focus': {
      boxShadow: '0px 10px 10px #C4D3F3',
    },
  },
  normal: {
    background: '#F8F8F8',
    boxShadow: '0px 10px 10px #E7EDFC',
    borderColor: '#E7EDFC',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  unread: {
    // '&::after': {
    //   position: 'absolute',
    //   content: 'close-quote',
    //   width: '3px',
    //   height: '21px',
    //   display: 'block',
    //   top: '25%',
    //   right: 0,
    //   transform: 'translate(50%, -50%)',
    //   background: red,
    // },
    // borderColor: '#E1E9FF',
    // borderStyle: 'solid',
    // borderWidth: '2px',
    boxShadow: '0px 0px 4px 2px rgb(255 0 0 / 50%)',
    '&:hover': {
      boxShadow: '0px 0px 1px 4px rgb(255 0 0 / 70%)',
    },
  },
  selected: {
    background: '#FFFFFF',
    borderColor: '#94C03D',
    borderStyle: 'solid',
    borderWidth: '0',
    borderLeftWidth: '4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: '0px 0px 0px #D6E8B2',
    '&:hover, &:focus': {
      boxShadow: '0px 0px 0px #D6E8B2',
    },
  },
  preview: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    color: textPurple,
    fontWeight: 'medium',
    fontSize: 14,
    lineSpacing: 18,
    wordBreak: 'break-word',
  },
  sender: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textTransform: 'capitalize',
    color: textPurple,
    fontSize: 18,
    wordBreak: 'break-word',
  },
  time: {
    color: '#888888',
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 16px 0px 16px',
    width: '100%',
  },
  draftMessage: {
    display: ' block',
    fontStyle: ' normal',
    fontWeight: ' normal',
    fontSize: ' 12px',
    lineHeight: ' 14px',
    color: '#F59B4D',
  },
  headerRightContainer: {
    paddingInlineStart: '10px',
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  subject: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    color: textPurple,
    fontWeight: 'normal',
    fontSize: 14,
    wordBreak: 'break-word',
  },
  greenButton: {
    '& svg': {
      fill: green,
    },
  },
  textOverflowToTypography: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
  },
  customAvatarGroup: {
    '& .MuiAvatarGroup-avatar': {
      height: '3.75rem',
      width: '2.3rem',
      marginLeft: '0px',
      borderRadius: '9px',
      boxShadow: '1px 1px 9px 1px #c2c3c7',
      border: '3px solid white',
    },
  },
  customAvatarGroupDraft: {
    '& .MuiAvatarGroup-avatar': {
      height: '2.5rem',
      width: '2.5rem',
      marginLeft: '10px',
      borderRadius: '7px',
      boxShadow: '1px 1px 9px 1px #c2c3c7',
      border: '2px solid white',
    },
  },
  previewTime: {
    color: '#94c03d',
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
  },
  fullScreenPreview: {
    boxShadow: ' 0px 5px 5px #D0D6E3',
    borderRadius: ' 10px',
    marginBottom: 16,
  },
}));
