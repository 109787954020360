import React from 'react';
import { useDispatch } from 'react-redux';
import CommentEditor from '../CommentEditor';

import { makeStyles } from '@material-ui/core/styles';
import { addNewTopic, addNewReply } from '../../redux/actions/drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '72px',
  },
}));

const ContactFeedCommentEditor = (props) => {
  const classes = useStyles();

  const { type, topic_id, handleReply } = props;
  const [content, setContent] = React.useState('');
  const [attachments, setAttachments] = React.useState([]);

  const dispatch = useDispatch();

  const handleAttachments = (event) => {
    setAttachments(Object.values(event.target.files));
  };

  const deleteAttachment = (index) => {
    setAttachments((prev) =>
      prev.filter((item, itemIndex) => itemIndex !== index)
    );
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('content', content);
    formData.append('attachments', attachments.length);
    attachments.forEach((attachment, index) => {
      formData.append(`file_${index}`, attachment);
    });
    if (type === 'post') dispatch(addNewTopic(formData));
    else dispatch(addNewReply(formData, topic_id));

    setContent('');
    setAttachments([]);
    if (handleReply) {
      handleReply(null);
    }
  };

  return (
    <div className={classes.root}>
      <CommentEditor
        topic_id={topic_id}
        content={content}
        setContent={setContent}
        attachments={attachments}
        handleAttachments={handleAttachments}
        deleteAttachment={deleteAttachment}
        handleSubmit={handleSubmit}
        allowLink={type === 'reply' ? false : true}
        allowAt={type === 'reply' ? false : true}
        allowEmoji={true}
        placeholder={type === 'post' ? 'Your post...' : 'Type comment here...'}
      />
    </div>
  );
};

export default ContactFeedCommentEditor;
