import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import { throttle } from 'lodash';

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const StreetAutocomplete = (props) => {
  const classes = useStyles();
  //   const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const {
    street = undefined,
    handleChange,
    types = ['geocode'],
    countryAlpha2,
    label = 'Street',
    variant = 'outlined',
    isShowSearchIcon = false,
    isShowFullAddress = true,
    error = null,
    disabled = false,
  } = props;
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(street ? [street] : []);
      return undefined;
    }
    fetch(
      {
        input: inputValue,
        types,
        // types: ['establishment'],
        componentRestrictions: { country: countryAlpha2 },
      },
      (results) => {
        // console.log('Gmaps', results);
        if (active) {
          let newOptions = [];

          if (street) {
            newOptions = [street];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [street, inputValue, fetch, countryAlpha2]);

  const onChange = (event, newValue) => {
    handleChange(newValue);
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      fullWidth
      variant={variant}
      margin="dense"
      style={{ margin: 0 }}
      error={!!error}
      inputProps={{
        ...params.inputProps,
        // Seems to be a bug with material-UI. but works this way
        autoComplete: variant === 'outlined' ? 'off' : 'new-password',
      }}
      InputProps={{
        ...params.InputProps,
        autoComplete: variant === 'outlined' ? 'off' : 'new-password',
        startAdornment: isShowSearchIcon && (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      helperText={!!error ? error : ''}
    />
  );

  const renderOption = (option) => {
    if (typeof option === 'string') {
      return option;
    } else if (typeof option === 'object' && option !== null) {
      // console.log('option', option);
      const matches = option.structured_formatting.main_text_matched_substrings;
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match) => [match.offset, match.offset + match.length])
      );
      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}

            <Typography variant="body2" color="textSecondary">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <Autocomplete
      id="street-autocomplete"
      getOptionLabel={(option) => {
        // console.debug('option', option);
        return typeof option === 'string'
          ? option
          : isShowFullAddress
          ? option?.description
          : option?.structured_formatting?.main_text;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      autoSelect
      includeInputInList
      disabled={disabled}
      filterSelectedOptions
      value={street}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  );
};

export default StreetAutocomplete;
