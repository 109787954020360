import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import AmeraSelect from '../../../../components/AmeraSelect';
import { projectfilterOptions } from '../../../../utils/project';

const FilterSelect = ({ filter, label, handleChange }) => {
  return (
    <AmeraSelect
      labelWidth={0}
      value={filter}
      onChange={handleChange}
      label={label}
    >
      {projectfilterOptions.map((o) => {
        return (
          <MenuItem value={o.key} key={o.key}>
            {o.value}
          </MenuItem>
        );
      })}
    </AmeraSelect>
  );
};

export default FilterSelect;
