import { defaultDateTimeZone } from './calendar';

const dateFormat = (d) => {
  // US Date Format
  return defaultDateTimeZone(new Date(d));
};

export default dateFormat;

export const formatDuration = (secs) => {
  if (secs < 3600) {
    return `${Math.floor(secs / 60)
      .toString()
      .padStart(2, '0')} : ${(secs % 60).toString().padStart(2, '0')}`;
  }
  return `${Math.floor(secs / 3600)
    .toString()
    .padStart(2, '0')} : ${Math.floor((secs % 3600) / 60)
    .toString()
    .padStart(2, '0')} : ${((secs % 3600) % 60).toString().padStart(2, '0')}`;
};

export const chatDateGroupingFormat = (date) => {
  return defaultDateTimeZone(date, 'date');
};

export const projecCreationDateFormat = (date) => {
  return defaultDateTimeZone(date, 'date');
};

export const formatAMPM = (date) => {
  let newDate = new Date(date);
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // @ts-ignore
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};
