import React from 'react';

import { StaticGoogleMap, Marker } from 'react-static-google-map';

const StaticMap = ({ lat, lng, size }) => {
  return (
    <StaticGoogleMap
      size={size}
      className="img-fluid"
      zoom="15"
      apiKey={process.env.REACT_APP_GMAPS_API_KEY}
    >
      <Marker location={`${lat}, ${lng}`} color="red" label="P" />
    </StaticGoogleMap>
  );
};

export default StaticMap;
