import React from 'react';
import TextField from '@material-ui/core/TextField';
import DateInput from '../../Modal/EventModals/DateInput';
import ListDeleteButton from '../../../pages/Profile/ProfileEdit/ListDeleteButton';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';
import Grid from '@material-ui/core/Grid';

const CertificateItem = (props) => {
  const { setCertificates, data, error } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          value={data.title}
          label="Title"
          error={!!error?.title}
          helperText={!!error?.title ? error?.title : ''}
          required
          fullWidth
          placeholder="Title of your Certificate"
          onChange={(e) =>
            setCertificates({
              type: PROFILE_EDIT_ACTIONS.certificateEdit,
              payload: {
                certificate_id: data.id,
                field: 'title',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={data.description}
          label="Description"
          placeholder="Put in some details"
          multiline
          fullWidth
          rowsMax={3}
          onChange={(e) =>
            setCertificates({
              type: PROFILE_EDIT_ACTIONS.certificateEdit,
              payload: {
                certificate_id: data.id,
                field: 'description',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          value={data.date_received}
          label="Date Received"
          inputVariant="standard"
          onChange={(d) =>
            setCertificates({
              type: PROFILE_EDIT_ACTIONS.certificateEdit,
              payload: {
                certificate_id: data.id,
                field: 'date_received',
                value: d,
              },
            })
          }
        />
      </Grid>
      <Grid item xs>
        <ListDeleteButton
          id={data.id}
          handleDelete={() =>
            setCertificates({
              type: PROFILE_EDIT_ACTIONS.certificateDelete,
              payload: { certificate_id: data.id },
            })
          }
          listContext="certificate item"
        >
          Delete Item
        </ListDeleteButton>
      </Grid>
    </Grid>
  );
};

export default CertificateItem;
