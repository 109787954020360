import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import NoOptionsFallback from './NoOptionsFallback';
import { makeStyles } from '@material-ui/core';
import ProjectRow from './ProjectRow';
import Paper from '@material-ui/core/Paper';
// import { dummyProjects } from '../../../utils/project';
import { connect } from 'react-redux';
import { filterProjects } from '../../../utils/project';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FAFAFA',
  },
}));

const ProjectsTable = (props) => {
  const classes = useStyles();
  const { projects, filter, onCreateClick, onClearFilter } = props;

  const filteredProjects = filterProjects(projects, filter);
  return (
    <Paper elevation={2}>
      <Typography variant="h6" style={{ padding: '10px 15px' }}>
        My Projects
      </Typography>
      <TableContainer>
        <Table aria-label="table" component="table">
          {/* <caption>A basic table example with a caption</caption> */}
          <TableHead classes={classes}>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell align="left">Project Name</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Owner</TableCell>
              <TableCell align="left">Estimated Duration</TableCell>
              <TableCell align="left">Progress</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredProjects?.length > 0 ? (
              filteredProjects.map((p) => (
                <ProjectRow key={p.project_id} project={p} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7}>
                  <NoOptionsFallback
                    totalProjects={projects?.length}
                    onCreateClick={onCreateClick}
                    onClearFilterClick={onClearFilter}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  projects: state.project.projects,
});

export default connect(mapStateToProps)(ProjectsTable);
