import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../SearchComponent';
import { AudioCallRingIcon, VideoCallIcon } from '../../assets/svg';
import VideoEmailEditorModal from '../../components/Modal/VideoEmailEditorModal';
import { loadContacts } from '../../redux/actions/contact';
import {
  setCallPartner,
  setCallPartnerMemberId,
  setAudioOnly,
} from '../../redux/actions/one2onevcall';
import { establishContactVideoCall } from '../../utils/general';
import { useHistory } from 'react-router-dom';
import { closeDrawer } from '../../redux/actions/drawer';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import MaterialAvatar from '../MaterialAvatar';
import { getTwilioAccessToken } from '../../redux/actions/member';
import { Device } from 'twilio-client';
import {
  setTwilioCall,
  setTwilioDevice,
  setTwilioConnection,
} from '../../redux/actions/event';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import { debounce } from 'lodash';
import { getContactOnlineStatus } from '../../utils/strophe/user';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '4px',
      borderRadius: '8px',
      backgroundColor: '#d3d3d380',
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
  description: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#000000',
  },
  contacts: {
    padding: '28px 22px 22px 22px',
  },
  wrapper: {
    border: '2px solid #D4E2F0',
    padding: '17px 0px 15px 0px',
    borderRadius: '8px',
    minHeight: '500px',
  },
  innerTitle: {
    fontSize: '23px',
    fontWeight: 600,
  },
  innerSearch: {
    width: '182px',
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 13px 0px 19px',
  },
  list: {
    marginTop: '8px',
  },
  listItem: {
    padding: '14px 19px 14px 19px',
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    marginTop: '23px',
    '& .MuiButton-root': {
      width: '124px',
      height: '40px',
      border: '1px solid #8DA3C5',
      borderRadius: '7px',
      color: '#8DA3C5',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  member: {
    display: 'flex',
  },
  info: {
    marginLeft: '13px',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#363C45',
    marginBottom: '3px',
  },
  desc: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#5F5F5F',
    marginBottom: '0px',
  },
  avatar: {
    width: '40px',
    height: '40px',
  },
  action: {
    cursor: 'pointer',
    marginLeft: '8px',
    '& .MuiSvgIcon-root': {
      fill: '#8DA3C5',
    },
  },
  actionList: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ContactCall = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    type,
    contacts,
    rosterItems,
    member,
    contactInformation,
    countryList,
    twilioDevice,
  } = useSelector((state) => ({
    type: state.drawer.type,
    contacts: state.contact.contacts,
    rosterItems: state?.chat?.rosterItems,
    member: state.member.member,
    contactInformation: state.member.memberInfo.contact_information,
    countryList: state.member.countryList,
    twilioDevice: state.event.twilioDevice,
  }));

  const [searchKey, setSearchKey] = React.useState('');
  const [showVideoEmailEditor, setShowVideoEmailEditor] = React.useState(false);
  const [contactDetailData, setContactDetailData] = React.useState({});
  const [phoneCallAvailable, setPhoneCallAvailable] = React.useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contactOfMenu, setContactOfMenu] = React.useState(null);

  const getE164Phone = React.useCallback(
    (contact) => {
      const countryPhone = countryList.find(
        (cl) => cl.id === contact.device_country
      ).phone;
      return `+${countryPhone}${contact.device}`;
    },
    [countryList]
  );

  const getOutgoingNumberFromContactList = React.useCallback(
    (cis) => {
      const outgoing_caller = cis.find((ci) => !!ci.outgoing_caller);
      if (outgoing_caller) return getE164Phone(outgoing_caller);
      return undefined;
    },
    [getE164Phone]
  );

  const outgoingNumber = React.useMemo(() => {
    return getOutgoingNumberFromContactList(contactInformation);
  }, [getOutgoingNumberFromContactList, contactInformation]);

  React.useEffect(() => {
    dispatch(loadContacts({ searchKey: '' }));
  }, [dispatch]);

  const debouncedLoadContacts = useCallback(
    debounce(
      (nextValue) => dispatch(loadContacts({ searchKey: nextValue })),
      500
    ),
    []
  );

  const getToken = React.useCallback(async () => {
    const result = await dispatch(getTwilioAccessToken());
    if (result.success) {
      const device = new Device(result.token, {
        debug: true,
      });

      device.on('ready', (device) => {
        // console.log('device is ready::', device);
        setPhoneCallAvailable(true);
      });
      device.on('error', (event) => {
        // console.log('error', event);
      });
      device.on('offline', (event) => {
        // console.log('offline event', event);
      });
      device.on('connect', (conn) => {
        // console.log('connection conn', conn);
      });
      device.on('disconnect', (conn) => {
        // console.log('device was disconnected', conn);
        setPhoneCallAvailable(true);
        dispatch(setTwilioCall(false));
      });
      dispatch(setTwilioDevice(device));
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (twilioDevice === null) getToken();
    else setPhoneCallAvailable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioDevice]);

  const title =
    type === 'video_call'
      ? 'Video Call your Contacts'
      : 'Audio Call your Contacts';
  const description =
    type === 'video_call'
      ? 'You can Video call you contacts, or leave them a message if they are offline.'
      : 'You can call or message your contacts. The call will go to their phone if they are offline';

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    debouncedLoadContacts(event.target.value);
  };

  const goOne2OneVideoCall = (partner, notificationData, audioOnly = false) => {
    dispatch(setCallPartner(partner));
    if (audioOnly) {
      dispatch(setAudioOnly(true));
    } else {
      dispatch(setAudioOnly(false));
    }
    dispatch(setCallPartnerMemberId(partner.member_id, 'call'));
    establishContactVideoCall(
      notificationData.member,
      notificationData.contact,
      audioOnly
    ).then((callUrl) => {
      history.push(callUrl);
    });
    dispatch(closeDrawer());
  };

  const openVideoEmailEditModal = (contact) => {
    setShowVideoEmailEditor(true);
    setContactDetailData(contact);
  };

  const handleCallAction = (phone, contact) => {
    const params = {
      from: outgoingNumber,
      to: phone,
    };
    setAnchorEl(null);
    if (twilioDevice) {
      setPhoneCallAvailable(false);
      const connection = twilioDevice.connect(params);
      dispatch(setTwilioConnection(connection));
      dispatch(setTwilioCall(`${contact.first_name} ${contact.last_name}`));
      dispatch(closeDrawer());
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <h3 className={classes.title}>{title}</h3>
          <h3 className={classes.description}>{description}</h3>
        </div>
      </div>
      <div className={classes.contacts}>
        <div className={classes.wrapper}>
          <div className={classes.listHeader}>
            <div className={classes.innerTitle}>Contacts</div>
            <div className={classes.innerSearch}>
              <Search searchKey={searchKey} handleSearch={handleSearch} />
            </div>
          </div>
          <div className={classes.list}>
            {contacts &&
              contacts.length > 0 &&
              contacts.map((contact) => {
                const onlineStatus = getContactOnlineStatus(
                  contact,
                  rosterItems
                );
                return (
                  <div
                    key={contact.contact_member_id}
                    className={classes.listItem}
                  >
                    <div className={classes.member}>
                      <MaterialAvatar
                        member_id={contact.contact_member_id}
                        first_name={contact.first_name}
                        last_name={contact.last_name}
                        src={contact.amera_avatar_url}
                        badgeType={onlineStatus}
                        classes={classes.avatar}
                        badgeAnchorOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                      />
                      <div className={classes.info}>
                        <h3 className={classes.name}>
                          {contact.first_name} {contact.last_name}
                        </h3>
                        <h3 className={classes.desc}>{contact.title}</h3>
                      </div>
                    </div>
                    <div className={classes.actionList}>
                      {onlineStatus === 'online' && type === 'video_call' && (
                        <span
                          className={classes.action}
                          onClick={() => {
                            goOne2OneVideoCall(
                              {
                                email: contact.email,
                                member_id: contact.contact_member_id,
                                first_name: contact.first_name,
                                last_name: contact.last_name,
                              },
                              { member, contact },
                              false
                            );
                          }}
                        >
                          <VideoCallIcon />
                        </span>
                      )}
                      {onlineStatus === 'online' && type === 'audio_call' && (
                        <span
                          className={classes.action}
                          onClick={() => {
                            goOne2OneVideoCall(
                              {
                                email: contact.email,
                                member_id: contact.contact_member_id,
                                first_name: contact.first_name,
                                last_name: contact.last_name,
                              },
                              { member, contact },
                              true
                            );
                          }}
                        >
                          <HeadsetMicIcon />
                        </span>
                      )}
                      {
                        <span
                          className={classes.action}
                          onClick={() => {
                            openVideoEmailEditModal(contact);
                          }}
                        >
                          {type === 'video_call' ? (
                            <VoiceChatIcon />
                          ) : (
                            <PermPhoneMsgIcon />
                          )}
                        </span>
                      }
                      {outgoingNumber &&
                        phoneCallAvailable &&
                        type === 'audio_call' && (
                          <span
                            className={classes.action}
                            onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setContactOfMenu(contact);
                            }}
                          >
                            <AudioCallRingIcon />
                          </span>
                        )}
                    </div>
                  </div>
                );
              })}
            {contactOfMenu && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {contactOfMenu.contact_information &&
                  contactOfMenu.contact_information.length > 0 &&
                  contactOfMenu.contact_information
                    .filter(
                      (ci) =>
                        ci.device_type === 'cell' ||
                        ci.device_type === 'landline' ||
                        ci.device_type === 'TDD'
                    )
                    .map((ci) => {
                      const phone = getE164Phone(ci);
                      return (
                        <MenuItem
                          id={phone}
                          key={phone}
                          onClick={() => {
                            handleCallAction(phone, contactOfMenu);
                          }}
                        >
                          {phone}
                        </MenuItem>
                      );
                    })}
              </Menu>
            )}
          </div>
        </div>
        <VideoEmailEditorModal
          open={showVideoEmailEditor}
          receiver={contactDetailData.contact_member_id}
          type="contact"
          name={`${contactDetailData.first_name} ${contactDetailData.last_name}`}
          onClose={() => {
            setShowVideoEmailEditor(false);
          }}
        />

        <div className={classes.cancelBtn}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(closeDrawer());
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactCall;
