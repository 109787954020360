import React from 'react';
import ContactItem from './ContactItem';
import Typography from '@material-ui/core/Typography';
import { includes, orderBy } from 'lodash';

const ContactItemList = (props) => {
  const { contactData, country_code } = props;

  const phones = () =>
    orderBy(
      contactData
        .filter((cd) => includes(['cell', 'landline', 'TDD'], cd.device_type))
        .map((cd) =>
          cd ? (
            <ContactItem
              key={cd.id}
              contactInformation={cd}
              country_code={country_code}
              isPhone={true}
            />
          ) : null
        ),
      ['display_order']
    );
  const emails = () =>
    orderBy(
      contactData
        .filter((cd) => includes(['email', 'other'], cd.device_type))
        .map((cd) =>
          cd ? (
            <ContactItem key={cd.id} contactInformation={cd} isPhone={false} />
          ) : null
        ),
      ['display_order']
    );

  return (
    <>
      <p className="title">Contact Information:</p>

      {Boolean(contactData[0]) ? (
        <div className="list-items-inner">
          {/* Phones first */}
          {phones()}
          {/*Then emails */}
          {emails()}
        </div>
      ) : (
        <Typography>N/A</Typography>
      )}
    </>
  );
};

export default ContactItemList;
