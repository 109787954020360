/* eslint-disable no-unused-vars */
import { GET, apiUrl, POST, PUT, DELETE } from '../../config/api';
import {
  PROJECT_PRIVILEGES,
  PROJECT_ELEMENTS,
  PROJECT_STATUS,
  PROJECT_TYPES,
} from '../../utils/project';
import { getInvitationsAndMediaMails } from './event';

import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECT_ROLES_START,
  FETCH_PROJECT_ROLES_SUCCESS,
  FETCH_PROJECT_ROLES_ERROR,
  // PROJECT
  SEND_CREATE_PROJECT_FORM_START,
  SEND_CREATE_PROJECT_FORM_SUCCESS,
  SEND_CREATE_PROJECT_FORM_ERROR,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  HARD_DELETE_PROJECT_START,
  HARD_DELETE_PROJECT_SUCCESS,
  HARD_DELETE_PROJECT_ERROR,
  SOFT_DELETE_PROJECT_START,
  SOFT_DELETE_PROJECT_SUCCESS,
  SOFT_DELETE_PROJECT_ERROR,
  RESTORE_PROJECT_START,
  RESTORE_PROJECT_SUCCESS,
  RESTORE_PROJECT_ERROR,
  SUSPEND_PROJECT_START,
  SUSPEND_PROJECT_SUCCESS,
  SUSPEND_PROJECT_ERROR,
  UNSUSPEND_PROJECT_START,
  UNSUSPEND_PROJECT_SUCCESS,
  UNSUSPEND_PROJECT_ERROR,
  // TEAM ASSIGNMENT
  SEND_PROJECT_MEMBERS_START,
  SEND_PROJECT_MEMBERS_SUCCESS,
  SEND_PROJECT_MEMBERS_ERROR,
  // PROJECT ROLES
  SEND_PROJECT_MEMBER_ROLES_START,
  SEND_PROJECT_MEMBER_ROLES_SUCCESS,
  SEND_PROJECT_MEMBER_ROLES_ERROR,
  // MEMBER PRIVILEGES
  SEND_PROJECT_MEMBER_PRIVILEGES_START,
  SEND_PROJECT_MEMBER_PRIVILEGES_SUCCESS,
  SEND_PROJECT_MEMBER_PRIVILEGES_ERROR,
  SET_CURRENT_PROJECT_ID,
  // ELEMENT
  CREATE_ELEMENT_START,
  CREATE_ELEMENT_SUCCESS,
  CREATE_ELEMENT_ERROR,
  UPDATE_ELEMENT_START,
  UPDATE_ELEMENT_SUCCESS,
  UPDATE_ELEMENT_ERROR,
  SOFT_DELETE_ELEMENT_START,
  SOFT_DELETE_ELEMENT_SUCCESS,
  SOFT_DELETE_ELEMENT_ERROR,
  RESTORE_ELEMENT_START,
  RESTORE_ELEMENT_SUCCESS,
  RESTORE_ELEMENT_ERROR,
  SUSPEND_PROJECT_ELEMENT_START,
  SUSPEND_PROJECT_ELEMENT_SUCCESS,
  SUSPEND_PROJECT_ELEMENT_ERROR,
  UNSUSPEND_PROJECT_ELEMENT_START,
  UNSUSPEND_PROJECT_ELEMENT_SUCCESS,
  UNSUSPEND_PROJECT_ELEMENT_ERROR,
  // NOTE
  CREATE_ELEMENT_NOTE_START,
  CREATE_ELEMENT_NOTE_SUCCESS,
  CREATE_ELEMENT_NOTE_ERROR,
  UPDATE_ELEMENT_NOTE_START,
  UPDATE_ELEMENT_NOTE_SUCCESS,
  UPDATE_ELEMENT_NOTE_ERROR,
  DELETE_ELEMENT_NOTE_START,
  DELETE_ELEMENT_NOTE_SUCCESS,
  DELETE_ELEMENT_NOTE_ERROR,
  // TIME
  CREATE_ELEMENT_TIME_START,
  CREATE_ELEMENT_TIME_SUCCESS,
  CREATE_ELEMENT_TIME_ERROR,
  UPDATE_ELEMENT_TIME_START,
  UPDATE_ELEMENT_TIME_SUCCESS,
  UPDATE_ELEMENT_TIME_ERROR,
  DELETE_ELEMENT_TIME_START,
  DELETE_ELEMENT_TIME_SUCCESS,
  DELETE_ELEMENT_TIME_ERROR,
  // invite
  CONTRACT_INVITE_REACTION_START,
  CONTRACT_INVITE_REACTION_SUCCESS,
  CONTRACT_INVITE_REACTION_FAILED,
  // Milestones
  CREATE_MILESTONE_START,
  CREATE_MILESTONE_SUCCESS,
  CREATE_MILESTONE_ERROR,
  UPDATE_MILESTONE_START,
  UPDATE_MILESTONE_SUCCESS,
  UPDATE_MILESTONE_ERROR,
  // Deactivate
  DEACTIVATE_PROJECT_START,
  DEACTIVATE_PROJECT_SUCCESS,
  DEACTIVATE_PROJECT_ERROR,
  // Update hourly rate
  UPDATE_RATE_START,
  UPDATE_RATE_SUCCESS,
  UPDATE_RATE_ERROR,
} from '../actionTypes/project';

const endpoints = {
  project: `${apiUrl}/project`,
  projectDeactivate: `${apiUrl}/project/deactivate`,
  projectRoles: `${apiUrl}/project/roles`,
  projectMembers: `${apiUrl}/project/member`,
  projectMemberRoles: `${apiUrl}/project/member/roles`,
  projectMemberPrivileges: `${apiUrl}/project/member/privilege`,
  projectElement: `${apiUrl}/project/element`,
  projectElementNote: `${apiUrl}/project/element/note`,
  projectElementTime: `${apiUrl}/project/element/time`,
  contractInviteAccept: `${apiUrl}/project/invite_reaction`,
  projectMilestone: `${apiUrl}/project/milestone`,
  contractRate: `${apiUrl}/project/contract-rate`,
};

class AmeraProjectsApi {
  catchErrorHandler = (type, error) => (dispatch) => {
    dispatch({ type, payload: error?.message ? error.message : error });
  };

  getProjects = (projectCreation = false) => (dispatch) => {
    dispatch({ type: FETCH_PROJECTS_START });

    const success_res_example = {
      success: true,
      description: 'Projects data fetched successfully',
      data: [
        {
          project_id: 1,
          company: { company_id: 1, name: 'Coriander' },
          project_title: 'Software development',
          project_type: 'software',
          project_description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et diam vestibulum, lobortis ipsum quis, luctus leo. Nam at imperdiet eros, ac facilisis ante. Nullam id cursus dolor, id dapibus tellus. Ut sed arcu suscipit, placerat arcu nec, pellentesque ipsum. Vivamus dictum turpis non lectus convallis, et varius risus iaculis. Nunc non dolor volutpat, lobortis ipsum eu, cursus dui. Donec sem nibh, ornare id laoreet vel, pretium eu ligula. Donec massa tortor, sollicitudin vitae lobortis quis, lobortis quis lacus. In tempor augue bibendum euismod suscipit. Proin sodales sapien quis odio ultricies, eu faucibus nibh iaculis. Pellentesque a fermentum erat, non condimentum arcu. Nulla eu porta ante, ac malesuada ipsum. Cras molestie ullamcorper urna a elementum. Nunc quis massa urna. Ut tortor ipsum, finibus id commodo nec, tristique quis est. Suspendisse potenti.',
          group_id: 47,
          group_name: '1st project group',
          group_exchange_option: 'MOST_SECURE',
          ownership: {
            current_owner_id: 1,
            owner_since_date: '2021-03-14T13:44:27.587524+00:00',
          },
          project_elements: [
            {
              project_element_id: 1,
              parent_id: null,
              element_type: 'epic',
              title: 'Backend design',
              description: 'Design the endpointes',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.590925+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.590925+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 2,
              parent_id: null,
              element_type: 'epic',
              title: 'Frontend design',
              description: 'React front-end code',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.590925+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.590925+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 5,
              parent_id: 1,
              element_type: 'tremor',
              title: 'AWS infrastructure',
              description: '',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.592979+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.592979+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 6,
              parent_id: 1,
              element_type: 'tremor',
              title: 'Fixed tremor',
              description: '',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.592979+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.592979+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 7,
              parent_id: 5,
              element_type: 'story',
              title: 'Some story',
              description: 'Some cool title',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.594699+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.594699+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 8,
              parent_id: 5,
              element_type: 'story',
              title: 'Another story',
              description: 'Not so cool title',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.594699+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.594699+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 9,
              parent_id: 5,
              element_type: 'story',
              title: 'Fixed story',
              description: 'Some description',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.594699+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.594699+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 10,
              parent_id: 8,
              element_type: 'task',
              title: 'Task 1',
              description: 'lorem ipsum',
              contract_id: 1,
              project_member_id: 1,
              est_hours: '06:00:00',
              status_history: [
                {
                  status_change_id: 1,
                  element_status: 'define',
                  update_date: '2021-03-14T08:44:27.601061+00:00',
                  update_by: 1,
                },
                {
                  status_change_id: 2,
                  element_status: 'in progress',
                  update_date: '2021-03-14T10:44:27.601061+00:00',
                  update_by: 2,
                },
                {
                  status_change_id: 3,
                  element_status: 'complete',
                  update_date: '2021-03-14T11:44:27.601061+00:00',
                  update_by: 2,
                },
              ],
              create_date: '2021-03-14T13:44:27.599798+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.599798+00:00',
              update_by: 1,
              element_notes: [
                {
                  element_note_id: 1,
                  element_note: 'Hey, how is it going with the task?',
                  create_date: '2021-03-14T13:44:27.604459+00:00',
                  create_by: 1,
                  update_date: '2021-03-14T13:44:27.604459+00:00',
                  update_by: 1,
                },
                {
                  element_note_id: 2,
                  element_note: 'Expecting it to be finalized soon',
                  create_date: '2021-03-14T13:44:27.604459+00:00',
                  create_by: 2,
                  update_date: '2021-03-14T13:44:27.604459+00:00',
                  update_by: 2,
                },
              ],
              element_time: [
                {
                  element_time_id: 1,
                  element_summary: 'Started the job',
                  element_time: '03:00:00',
                  create_date: '2021-03-14T13:44:27.602825+00:00',
                  create_by: 2,
                  update_date: '2021-03-14T13:44:27.602825+00:00',
                  update_by: 2,
                },
                {
                  element_time_id: 2,
                  element_summary: 'Finalized it ',
                  element_time: '03:00:00',
                  create_date: '2021-03-14T13:44:27.602825+00:00',
                  create_by: 2,
                  update_date: '2021-03-14T13:44:27.602825+00:00',
                  update_by: 2,
                },
              ],
            },
            {
              project_element_id: 11,
              parent_id: 8,
              element_type: 'task',
              title: 'Task 2',
              description: 'lorem ipsum',
              contract_id: 2,
              project_member_id: 2,
              est_hours: '03:00:00',
              status_history: [
                {
                  status_change_id: 4,
                  element_status: 'define',
                  update_date: '2021-03-14T03:44:27.601061+00:00',
                  update_by: 1,
                },
                {
                  status_change_id: 5,
                  element_status: 'in progress',
                  update_date: '2021-03-14T05:44:27.601061+00:00',
                  update_by: 2,
                },
                {
                  status_change_id: 6,
                  element_status: 'suspend',
                  update_date: '2021-03-14T07:44:27.601061+00:00',
                  update_by: 1,
                },
                {
                  status_change_id: 7,
                  element_status: 'in progress',
                  update_date: '2021-03-14T09:44:27.601061+00:00',
                  update_by: 2,
                },
              ],
              create_date: '2021-03-14T13:44:27.599798+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.599798+00:00',
              update_by: 1,
              element_notes: [
                {
                  element_note_id: 3,
                  element_note:
                    'Hey Test, I seem to need more than the expected time, it that ok?',
                  create_date: '2021-03-14T13:44:27.604459+00:00',
                  create_by: 4,
                  update_date: '2021-03-14T13:44:27.604459+00:00',
                  update_by: 4,
                },
                {
                  element_note_id: 4,
                  element_note:
                    'Sure, take your time, but we are not going to pay you more',
                  create_date: '2021-03-14T13:44:27.604459+00:00',
                  create_by: 4,
                  update_date: '2021-03-14T13:44:27.604459+00:00',
                  update_by: 4,
                },
              ],
              element_time: [
                {
                  element_time_id: 3,
                  element_summary:
                    'It was a difficult task and I needed more time',
                  element_time: '04:00:00',
                  create_date: '2021-03-14T13:44:27.602825+00:00',
                  create_by: 4,
                  update_date: '2021-03-14T13:44:27.602825+00:00',
                  update_by: 4,
                },
              ],
            },
            {
              project_element_id: 12,
              parent_id: 7,
              element_type: 'task',
              title: 'Task 3',
              description: 'lorem ipsum',
              contract_id: 3,
              project_member_id: 3,
              est_hours: null,
              status_history: [
                {
                  status_change_id: 8,
                  element_status: 'define',
                  update_date: '2021-03-14T09:44:27.601061+00:00',
                  update_by: 1,
                },
                {
                  status_change_id: 9,
                  element_status: 'in progress',
                  update_date: '2021-03-14T10:44:27.601061+00:00',
                  update_by: 4,
                },
              ],
              create_date: '2021-03-14T13:44:27.599798+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.599798+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
          ],
          start_date: '2021-02-10',
          estimated_days: '90 days',
          update_by: 1,
          update_date: '2021-03-14T13:44:27.580863+00:00',
          project_members: [
            {
              project_member_id: 2,
              member_id: 2,
              first_name: 'adrian',
              middle_name: null,
              last_name: 'thomas',
              company_name: 'Coriander',
              title: 'Board Member',
              department: 'Accounting',
              amera_avatar_url: '/member/file/ana.jpg',
              project_roles: [3],
              privileges: ['create', 'view'],
            },
            {
              project_member_id: 3,
              member_id: 10,
              first_name: 'Emilia',
              middle_name: null,
              last_name: 'Davis',
              company_name: 'Firewax',
              title: 'Advisor',
              department: 'Executive',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [4],
              privileges: ['view'],
            },
            {
              project_member_id: 1,
              member_id: 1,
              first_name: 'test',
              middle_name: null,
              last_name: 'user',
              company_name: 'Coriander',
              title: 'Board Member',
              department: 'Accounting',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [1, 4],
              privileges: ['approve', 'create', 'view', 'edit'],
            },
          ],
          contracts: [
            {
              contract_id: 1,
              project_member_id: 1,
              pay_rate: 40.0,
              rate_type: 'hourly',
              currency_code_id: 132,
              status_history: [
                {
                  id: 1,
                  contract_status: 'pending',
                  update_date: '2021-02-21T13:44:27.5977+00:00',
                  update_by: 1,
                },
                {
                  id: 2,
                  contract_status: 'active',
                  update_date: '2021-03-07T13:44:27.5977+00:00',
                  update_by: 1,
                },
              ],
              create_date: '2021-03-14T13:44:27.595601+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.595601+00:00',
              update_by: 1,
            },
            {
              contract_id: 3,
              project_member_id: 3,
              pay_rate: 500.0,
              rate_type: 'fixed',
              currency_code_id: 132,
              status_history: [
                {
                  id: 5,
                  contract_status: 'pending',
                  update_date: '2021-02-21T13:44:27.5977+00:00',
                  update_by: 1,
                },
                {
                  id: 6,
                  contract_status: 'active',
                  update_date: '2021-03-07T13:44:27.5977+00:00',
                  update_by: 1,
                },
              ],
              create_date: '2021-03-14T13:44:27.595601+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.595601+00:00',
              update_by: 1,
            },
            {
              contract_id: 2,
              project_member_id: 2,
              pay_rate: 30.0,
              rate_type: 'hourly',
              currency_code_id: 132,
              status_history: [
                {
                  id: 3,
                  contract_status: 'pending',
                  update_date: '2021-02-21T13:44:27.5977+00:00',
                  update_by: 1,
                },
                {
                  id: 4,
                  contract_status: 'active',
                  update_date: '2021-03-07T13:44:27.5977+00:00',
                  update_by: 1,
                },
              ],
              create_date: '2021-03-14T13:44:27.595601+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.595601+00:00',
              update_by: 1,
            },
          ],
          defined_tasks: 0,
          in_progress_tasks: 2,
          completed_tasks: 1,
          cancelled_tasks: 0,
          suspended_tasks: 0,
          deleted_tasks: 0,
          active_tasks: 3,
        },
        {
          project_id: 2,
          company: { company_id: 1, name: 'Coriander' },
          project_title: 'Flyer design',
          project_type: 'marketing',
          project_description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus et diam vestibulum, lobortis ipsum quis, luctus leo. Nam at imperdiet eros, ac facilisis ante. Nullam id cursus dolor, id dapibus tellus. Ut sed arcu suscipit, placerat arcu nec, pellentesque ipsum. Vivamus dictum turpis non lectus convallis, et varius risus iaculis. Nunc non dolor volutpat, lobortis ipsum eu, cursus dui. Donec sem nibh, ornare id laoreet vel, pretium eu ligula. Donec massa tortor, sollicitudin vitae lobortis quis, lobortis quis lacus. In tempor augue bibendum euismod suscipit. Proin sodales sapien quis odio ultricies, eu faucibus nibh iaculis. Pellentesque a fermentum erat, non condimentum arcu. Nulla eu porta ante, ac malesuada ipsum. Cras molestie ullamcorper urna a elementum. Nunc quis massa urna. Ut tortor ipsum, finibus id commodo nec, tristique quis est. Suspendisse potenti.',
          group_id: 48,
          group_name: '2nd project group',
          group_exchange_option: 'MOST_SECURE',
          ownership: {
            current_owner_id: 4,
            owner_since_date: '2021-03-14T13:44:27.587524+00:00',
          },
          project_elements: [
            {
              project_element_id: 3,
              parent_id: null,
              element_type: 'epic',
              title: 'Design',
              description: 'Get PDF designs',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.590925+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.590925+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
            {
              project_element_id: 4,
              parent_id: null,
              element_type: 'epic',
              title: 'Publishing',
              description: 'Print 1000 copies',
              contract_id: null,
              project_member_id: null,
              est_hours: null,
              status_history: null,
              create_date: '2021-03-14T13:44:27.590925+00:00',
              create_by: 1,
              update_date: '2021-03-14T13:44:27.590925+00:00',
              update_by: 1,
              element_notes: null,
              element_time: null,
            },
          ],
          start_date: '2021-02-11',
          estimated_days: '45 days',
          update_by: 1,
          update_date: '2021-03-14T13:44:27.580863+00:00',
          project_members: [
            {
              project_member_id: 5,
              member_id: 5,
              first_name: 'adrian',
              middle_name: null,
              last_name: 'thomas4',
              company_name: 'Coriander',
              title: 'Board Member',
              department: 'Accounting',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [4],
              privileges: ['create', 'view'],
            },
            {
              project_member_id: 6,
              member_id: 20,
              first_name: 'Fletcher',
              middle_name: null,
              last_name: 'Christian',
              company_name: 'Boilcat',
              title: 'Advisor',
              department: 'Executive',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [3],
              privileges: ['view'],
            },
            {
              project_member_id: 4,
              member_id: 1,
              first_name: 'test',
              middle_name: null,
              last_name: 'user',
              company_name: 'Coriander',
              title: 'Board Member',
              department: 'Accounting',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [1, 2],
              privileges: ['approve', 'create', 'view', 'edit'],
            },
          ],
          contracts: null,
          defined_tasks: 0,
          in_progress_tasks: 0,
          completed_tasks: 0,
          cancelled_tasks: 0,
          suspended_tasks: 0,
          deleted_tasks: 0,
          active_tasks: 0,
        },
        {
          project_id: 3,
          company: { company_id: 1, name: 'Coriander' },
          project_title: 'Test project',
          project_type: 'software',
          project_description: 'A lot of text',
          group_id: 49,
          group_name: 'Test project',
          group_exchange_option: 'NO_ENCRYPTION',
          ownership: {
            current_owner_id: 7,
            owner_since_date: '2021-03-14T13:51:33.782854+00:00',
          },
          project_elements: null,
          start_date: '2021-02-19',
          estimated_days: '18 days',
          update_by: null,
          update_date: '2021-03-14T13:51:33.773055+00:00',
          project_members: [
            {
              project_member_id: 7,
              member_id: 1,
              first_name: 'test',
              middle_name: null,
              last_name: 'user',
              company_name: 'Coriander',
              title: 'Board Member',
              department: 'Accounting',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [1],
              privileges: ['approve', 'create', 'view', 'edit'],
            },
          ],
          contracts: null,
          defined_tasks: 0,
          in_progress_tasks: 0,
          completed_tasks: 0,
          cancelled_tasks: 0,
          suspended_tasks: 0,
          deleted_tasks: 0,
          active_tasks: 0,
        },
        {
          project_id: 4,
          company: { company_id: 1, name: 'Coriander' },
          project_title: 'Test project',
          project_type: 'software',
          project_description: 'A lot of text',
          group_id: 50,
          group_name: 'Test project',
          group_exchange_option: 'NO_ENCRYPTION',
          ownership: {
            current_owner_id: 8,
            owner_since_date: '2021-03-14T13:51:50.774639+00:00',
          },
          project_elements: null,
          start_date: '2021-02-19',
          estimated_days: '18 days',
          update_by: null,
          update_date: '2021-03-14T13:51:50.764868+00:00',
          project_members: [
            {
              project_member_id: 8,
              member_id: 1,
              first_name: 'test',
              middle_name: null,
              last_name: 'user',
              company_name: 'Coriander',
              title: 'Board Member',
              department: 'Accounting',
              amera_avatar_url: '/member/file/jen.jpg',
              project_roles: [1],
              privileges: ['approve', 'create', 'view', 'edit'],
            },
          ],
          contracts: null,
          defined_tasks: 0,
          in_progress_tasks: 0,
          completed_tasks: 0,
          cancelled_tasks: 0,
          suspended_tasks: 0,
          deleted_tasks: 0,
          active_tasks: 0,
        },
      ],
    };

    GET(endpoints.project)
      .then((res) => {
        if (res.success) {
          dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: res.data });
          if (projectCreation) {
            const recentCreatedProject =
              res.data[res.data.length - 1].project_id;
            const payload = {
              projectId: recentCreatedProject,
            };
            dispatch({ type: SET_CURRENT_PROJECT_ID, payload });
          }
        } else {
          this.catchErrorHandler(FETCH_PROJECTS_ERROR, res.description);
        }
      })
      .catch((error) => this.catchErrorHandler(FETCH_PROJECTS_ERROR, error));
  };

  getProjectRoles = () => (dispatch) => {
    dispatch({ type: FETCH_PROJECT_ROLES_START });

    const success_res_example = {
      success: true,
      description: 'Project roles fetched sucessfully',
      data: [
        { id: 1, name: 'Team Lead' },
        { id: 2, name: 'Designer' },
        { id: 3, name: 'Frontend Developer' },
        { id: 4, name: 'Backend Developer' },
      ],
    };

    GET(endpoints.projectRoles)
      .then((res) => {
        if (res.success) {
          dispatch({ type: FETCH_PROJECT_ROLES_SUCCESS, payload: res.data });
        } else {
          dispatch(
            this.catchErrorHandler(FETCH_PROJECT_ROLES_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(FETCH_PROJECT_ROLES_ERROR, error))
      );
  };

  // Create project
  postProjectCreate = (form) => (dispatch) => {
    const request_example = {
      company_id: 1,
      creator_project_role_id: 1, //Project role id here
      owner_member_id: 1, //member_id here
      project_title: 'Test project',
      project_type: 'software',
      project_description: 'A lot of text',
      project_start_date: '2021-02-19',
      project_estimated_days: 18,
    };

    const success_res_example = {
      success: true,
      data: { project_id: 1 },
      description: 'Project created successfully',
    };

    dispatch({ type: SEND_CREATE_PROJECT_FORM_START });
    POST(endpoints.project, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch(this.getProjects());
          dispatch({ type: SEND_CREATE_PROJECT_FORM_SUCCESS });
          const payload = {
            projectId: res.data.project_id,
          };
          dispatch({ type: SET_CURRENT_PROJECT_ID, payload });
        } else {
          dispatch(
            this.catchErrorHandler(
              SEND_CREATE_PROJECT_FORM_ERROR,
              res.description
            )
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SEND_CREATE_PROJECT_FORM_ERROR, error))
      );
  };

  // Update project description
  // Overwrites previous
  updateProject = (form, projectId, callBackToast) => (dispatch) => {
    const request_example = {
      company_id: 1,
      owner_member_id: 1, //member_id here
      project_title: 'Test project',
      project_type: PROJECT_TYPES.software,
      project_description: 'A lot of text',
      project_start_date: '2021-02-19',
      project_estimated_days: 18,
    };

    const success_res_example = {
      success: true,
      description: 'Project updated successfully',
    };

    dispatch({ type: UPDATE_PROJECT_START });
    PUT(`${endpoints.project}/${Number(projectId)}`, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          const snackbarOptions = {
            open: true,
            message: res.description,
            type: 'success',
          };
          callBackToast(snackbarOptions);
          dispatch({ type: UPDATE_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(UPDATE_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UPDATE_PROJECT_ERROR, error))
      );
  };

  hardDeleteProject = (projectId) => (dispatch) => {
    dispatch({ type: HARD_DELETE_PROJECT_START });
    DELETE(`${endpoints.project}/${Number(projectId)}`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: HARD_DELETE_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(HARD_DELETE_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(HARD_DELETE_PROJECT_ERROR, error))
      );
  };

  // soft delete project
  softDeleteProject = (projectId) => (dispatch) => {
    dispatch({ type: SOFT_DELETE_PROJECT_START });
    POST(`${endpoints.project}/${Number(projectId)}/soft_delete`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: SOFT_DELETE_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(SOFT_DELETE_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SOFT_DELETE_PROJECT_ERROR, error))
      );
  };

  restoreProject = (projectId) => (dispatch) => {
    dispatch({ type: RESTORE_PROJECT_START });
    POST(`${endpoints.project}/${Number(projectId)}/restore`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: RESTORE_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(RESTORE_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(RESTORE_PROJECT_ERROR, error))
      );
  };

  suspendProject = (projectId) => (dispatch) => {
    dispatch({ type: SUSPEND_PROJECT_START });
    POST(`${endpoints.project}/${Number(projectId)}/suspend`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: SUSPEND_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(SUSPEND_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SUSPEND_PROJECT_ERROR, error))
      );
  };

  unsuspendProject = (projectId) => (dispatch) => {
    dispatch({ type: UNSUSPEND_PROJECT_START });
    POST(`${endpoints.project}/${Number(projectId)}/unsuspend`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: UNSUSPEND_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(UNSUSPEND_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UNSUSPEND_PROJECT_ERROR, error))
      );
  };

  //
  // Project task
  //

  // Assign team
  postMembersList = (payload, callBackToast) => (dispatch) => {
    // Example data

    /*
      Will overwrite exisitng members.
      Backend will make sure the user who is sending this form is also persistent as project_member
    */
    const request_example = {
      project_id: 1,
      members: [2, 5, 11, 33, 34], // member_ids here
    };

    const success_res_example = {
      success: true,
      description: 'Team successfully assigned',
    };

    dispatch({ type: SEND_PROJECT_MEMBERS_START });
    POST(endpoints.projectMembers, JSON.stringify(payload), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: SEND_PROJECT_MEMBERS_SUCCESS });
          const snackbarOptions = {
            open: true,
            message: res.description,
            type: 'success',
          };
          callBackToast(snackbarOptions);
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(SEND_PROJECT_MEMBERS_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SEND_PROJECT_MEMBERS_ERROR, error))
      );
  };

  // Assign project roles to team members
  postProjectMemberRoles = (payload) => (dispatch) => {
    // Example data

    /*
      Will overwrite roles for mentioned members
    */

    const request_example = {
      project_id: 1,
      roles_data: [
        { project_member_id: 2, role_ids: [1, 2, 3] },
        { project_member_id: 4, role_ids: [2, 3, 4] },
        { project_member_id: 5, role_ids: [] },
      ],
    };

    const success_res_example = {
      success: true,
      description: 'Roles assigned successfully',
    };

    dispatch({ type: SEND_PROJECT_MEMBER_ROLES_START });
    POST(endpoints.projectMemberRoles, JSON.stringify(payload), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: SEND_PROJECT_MEMBER_ROLES_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(
              SEND_PROJECT_MEMBER_ROLES_ERROR,
              res.description
            )
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SEND_PROJECT_MEMBER_ROLES_ERROR, error))
      );
  };

  // Assign project privilges
  postProjectMemberPrivileges = (form) => (dispatch) => {
    /*
      Will overwrite privileges for mentioned members
    */

    // Example data
    //
    const request_example = {
      project_id: 1,
      privileges_data: [
        {
          project_member_id: 2,
          privileges: [PROJECT_PRIVILEGES.view],
        },
        { project_member_id: 3, privileges: [] },
        {
          project_member_id: 5,
          privileges: [PROJECT_PRIVILEGES.create, PROJECT_PRIVILEGES.edit],
        },
      ],
    };

    const success_res_example = {
      success: true,
      description: 'Privileges assigned successfully',
    };

    dispatch({ type: SEND_PROJECT_MEMBER_PRIVILEGES_START });
    POST(endpoints.projectMemberPrivileges, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: SEND_PROJECT_MEMBER_PRIVILEGES_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(
              SEND_PROJECT_MEMBER_PRIVILEGES_ERROR,
              res.description
            )
          );
        }
      })
      .catch((error) =>
        dispatch(
          this.catchErrorHandler(SEND_PROJECT_MEMBER_PRIVILEGES_ERROR, error)
        )
      );
  };

  //
  // Project element
  //

  // Create project element
  postProjectElement = (form) => (dispatch) => {
    const request_example = {
      project_id: 1,
      parent_id: null, // null for epics, int for everything else
      contract_id: 1,
      element_type: PROJECT_ELEMENTS.epic,
      title: 'Some title1',
      description: 'Some description1',
      est_hours: null, // null for everithing except hourly tasks.  integer hours for everything else
    };

    const success_res_example = {
      success: true,
      description: 'Element type created sucessfully',
    };

    dispatch({ type: CREATE_ELEMENT_START });
    POST(endpoints.projectElement, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: CREATE_ELEMENT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(CREATE_ELEMENT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(CREATE_ELEMENT_ERROR, error))
      );
  };

  // Modify project element
  updateProjectElement = (form, elementId) => (dispatch) => {
    const request_example = {
      project_id: 1,
      parent_id: 4, // null for epics, int for everything else
      element_type: PROJECT_ELEMENTS.task,
      title: 'Some title',
      description: 'Some description',
      est_hours: null, // null for everithing except hourly tasks.  integer hours for everything else
      element_status: PROJECT_STATUS.complete, // Should be null for everything except tasks or fixed-value elements that we manually check
    };

    const success_res_example = {
      success: true,
      description: 'Element type updated sucessfully',
    };

    dispatch({ type: UPDATE_ELEMENT_START });
    PUT(
      `${endpoints.projectElement}/${Number(elementId)}`,
      JSON.stringify(form),
      {
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        if (res.success) {
          dispatch({ type: UPDATE_ELEMENT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(UPDATE_ELEMENT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UPDATE_ELEMENT_ERROR, error))
      );
  };

  // Delete project element (soft). Will mark all children deleted as well
  deleteProjectElement = (elementId) => (dispatch) => {
    const success_res_example = {
      success: true,
      description: 'Items deleted successfully',
    };
    dispatch({ type: SOFT_DELETE_ELEMENT_START });
    DELETE(`${endpoints.projectElement}/${Number(elementId)}`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: SOFT_DELETE_ELEMENT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(SOFT_DELETE_ELEMENT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SOFT_DELETE_ELEMENT_ERROR, error))
      );
  };

  // Restore element from soft delete
  restoreProjectElement = (elementId) => (dispatch) => {
    const success_res_example = {
      success: true,
      description: 'Items restored successfully',
    };
    dispatch({ type: RESTORE_ELEMENT_START });
    POST(`${endpoints.projectElement}/${Number(elementId)}/restore`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: RESTORE_ELEMENT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(RESTORE_ELEMENT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(RESTORE_ELEMENT_ERROR, error))
      );
  };

  // Suspend element
  suspendProjectElement = (elementId) => (dispatch) => {
    const success_res_example = {
      success: true,
      description: 'Items suspended successfully',
    };
    dispatch({ type: SUSPEND_PROJECT_ELEMENT_START });
    POST(`${endpoints.projectElement}/${Number(elementId)}/suspend`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: SUSPEND_PROJECT_ELEMENT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(
              SUSPEND_PROJECT_ELEMENT_ERROR,
              res.description
            )
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(SUSPEND_PROJECT_ELEMENT_ERROR, error))
      );
  };

  // Unsuspend element
  unsuspendProjectElement = (elementId) => (dispatch) => {
    const success_res_example = {
      success: true,
      description: 'Items unsuspended successfully',
    };
    dispatch({ type: UNSUSPEND_PROJECT_ELEMENT_START });
    POST(`${endpoints.projectElement}/${Number(elementId)}/unsuspend`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: UNSUSPEND_PROJECT_ELEMENT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(
              UNSUSPEND_PROJECT_ELEMENT_ERROR,
              res.description
            )
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UNSUSPEND_PROJECT_ELEMENT_ERROR, error))
      );
  };

  //
  // Note
  //

  postNote = (form) => (dispatch) => {
    const request_example = { element_note: 'Some note', element_id: 1 };
    dispatch({ type: CREATE_ELEMENT_NOTE_START });
    POST(endpoints.projectElementNote, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: CREATE_ELEMENT_NOTE_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(CREATE_ELEMENT_NOTE_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(CREATE_ELEMENT_NOTE_ERROR, error))
      );
  };

  updateNote = (form, noteId) => (dispatch) => {
    const request_example = { element_note: 'Some note' };
    dispatch({ type: UPDATE_ELEMENT_NOTE_START });
    PUT(
      `${endpoints.projectElementNote}/${Number(noteId)}`,
      JSON.stringify(form),
      {
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        if (res.success) {
          dispatch({ type: UPDATE_ELEMENT_NOTE_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(UPDATE_ELEMENT_NOTE_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UPDATE_ELEMENT_NOTE_ERROR, error))
      );
  };

  deleteNote = (noteId) => (dispatch) => {
    dispatch({ type: DELETE_ELEMENT_NOTE_START });
    DELETE(`${endpoints.projectElementNote}/${Number(noteId)}`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: DELETE_ELEMENT_NOTE_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(DELETE_ELEMENT_NOTE_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(DELETE_ELEMENT_NOTE_ERROR, error))
      );
  };

  //
  // Time
  //

  postTime = (form) => (dispatch) => {
    const request_example = {
      element_id: 1,
      element_summary: 'Some text',
      element_time: '2:45',
    };
    dispatch({ type: CREATE_ELEMENT_TIME_START });
    POST(endpoints.projectElementTime, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: CREATE_ELEMENT_TIME_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(CREATE_ELEMENT_TIME_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(CREATE_ELEMENT_TIME_ERROR, error))
      );
  };

  updateTime = (form, timeId) => (dispatch) => {
    const request_example = {
      element_summary: 'Some text',
      element_time: '2:45',
    };
    dispatch({ type: CREATE_ELEMENT_TIME_START });
    PUT(
      `${endpoints.projectElementTime}/${Number(timeId)}`,
      JSON.stringify(form),
      {
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        if (res.success) {
          dispatch({ type: CREATE_ELEMENT_TIME_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(CREATE_ELEMENT_TIME_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(CREATE_ELEMENT_TIME_ERROR, error))
      );
  };

  deleteTime = (timeId) => (dispatch) => {
    dispatch({ type: DELETE_ELEMENT_TIME_START });
    DELETE(`${endpoints.projectElementTime}/${Number(timeId)}`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: DELETE_ELEMENT_TIME_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(DELETE_ELEMENT_TIME_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(DELETE_ELEMENT_TIME_ERROR, error))
      );
  };

  // Invite
  contractInviteReaction = (data, invite_id) => (dispatch) => {
    dispatch({ type: CONTRACT_INVITE_REACTION_START });
    POST(
      `${endpoints.contractInviteAccept}/${Number(invite_id)}`,
      JSON.stringify(data),
      {
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        if (res.success) {
          dispatch({ type: CONTRACT_INVITE_REACTION_SUCCESS });
          dispatch(getInvitationsAndMediaMails());
        } else {
          dispatch(
            this.catchErrorHandler(
              CONTRACT_INVITE_REACTION_FAILED,
              res.description
            )
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(CONTRACT_INVITE_REACTION_FAILED, error))
      );
  };

  //  Milestone
  postProjectMilestone = (form) => (dispatch) => {
    const request_example = {
      project_id: 1,
      project_member_id: 1, // can be null
      parent_id: 10,
      title: 'Some title1',
      description: 'Some description1',
      pay_rate: 100,
      currency_code_id: 132,
      due_date: '2021-04-10', // "YYYY-MM-DD"
    };

    const success_res_example = {
      success: true,
      description: 'Milestone created sucessfully',
    };

    dispatch({ type: CREATE_MILESTONE_START });
    POST(endpoints.projectMilestone, JSON.stringify(form), {
      'Content-Type': 'application/json',
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: CREATE_MILESTONE_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(CREATE_MILESTONE_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(CREATE_MILESTONE_ERROR, error))
      );
  };

  updateProjectMilestone = (form, milestoneId) => (dispatch) => {
    const request_example = {
      project_id: 1,
      project_member_id: 1, // can be null
      parent_id: 10,
      title: 'Some title1',
      description: 'Some description1',
      pay_rate: 100,
      currency_code_id: 132,
      due_date: '2021-04-10', // "YYYY-MM-DD"
      status: PROJECT_STATUS.cancel,
    };

    const success_res_example = {
      success: true,
      description: 'Element type updated sucessfully',
    };

    dispatch({ type: UPDATE_MILESTONE_START });
    PUT(
      `${endpoints.projectMilestone}/${Number(milestoneId)}`,
      JSON.stringify(form),
      {
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        if (res.success) {
          dispatch({ type: UPDATE_MILESTONE_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(UPDATE_MILESTONE_ERROR, res.description)
          );
        }
        console.log('project.js > updateProjectMilestone', res);
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UPDATE_ELEMENT_ERROR, error))
      );
  };

  // Deactivate project

  // Will mark project inactive. Inactive projects can't have new elements / notes /times added

  deactivateProject = (projectId) => (dispatch) => {
    dispatch({ type: DEACTIVATE_PROJECT_START });
    POST(`${endpoints.projectDeactivate}/${Number(projectId)}`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: DEACTIVATE_PROJECT_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(
            this.catchErrorHandler(DEACTIVATE_PROJECT_ERROR, res.description)
          );
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(DEACTIVATE_PROJECT_ERROR, error))
      );
  };

  // Update rate for a contract
  updateRate = (form, contractId) => (dispatch) => {
    const request_example = {
      pay_rate: 100,
      currency_code_id: 132,
      project_id: 1,
    };

    dispatch({ type: UPDATE_RATE_START });
    PUT(
      `${endpoints.contractRate}/${Number(contractId)}`,
      JSON.stringify(form),
      {
        'Content-Type': 'application/json',
      }
    )
      .then((res) => {
        if (res.success) {
          dispatch({ type: UPDATE_RATE_SUCCESS });
          dispatch(this.getProjects());
        } else {
          dispatch(this.catchErrorHandler(UPDATE_RATE_ERROR, res.description));
        }
      })
      .catch((error) =>
        dispatch(this.catchErrorHandler(UPDATE_RATE_ERROR, error))
      );
  };
}

const ProjectsApi = new AmeraProjectsApi();
Object.freeze(ProjectsApi);
export { ProjectsApi };
