import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import DateInput from './DateInput';

import { RECURRENCE_END } from '../../../utils/calendar';

const RecurrenceEnd = ({
  earliest,
  mode,
  repeatTimes,
  endDate,
  handleChangeMode,
  handleChangeRepeatTimes,
  handleChangeEndDate,
}) => {
  const dateOptionContent = (
    <DateInput
      value={endDate}
      label="End before"
      onChange={handleChangeEndDate}
      minDate={earliest}
      disabled={mode !== RECURRENCE_END.date}
    />
  );

  const timesOptionContent = (
    <TextField
      id="recurrence-times"
      label="Repeat times"
      type="number"
      // variant="outlined"
      value={repeatTimes}
      onChange={handleChangeRepeatTimes}
      disabled={mode !== RECURRENCE_END.repeats}
    />
  );

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">End condition</FormLabel>
      <RadioGroup
        name="recurrence-end"
        value={mode}
        onChange={handleChangeMode}
      >
        <FormControlLabel
          value={RECURRENCE_END.date}
          control={<Radio />}
          label={dateOptionContent}
        />
        <FormControlLabel
          value={RECURRENCE_END.repeats}
          control={<Radio />}
          label={timesOptionContent}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RecurrenceEnd;
