export const getAllSettings = (data, categories) => {
  const options = {};

  categories.map((category) => {
    const { id } = category;
    return (options[id] = data && data[id] > -1 ? data[id] : 1);
  });

  return options;
};

export const convertSecondsToHHMMSSFF = (seconds, fps) => {
  const pad = function (seconds) {
    return seconds < 10 ? '0' + seconds : seconds;
  };
  fps = typeof fps !== 'undefined' ? fps : 24;
  return [
    pad(Math.floor(seconds / 3600)),
    pad(Math.floor((seconds % 3600) / 60)),
    pad(Math.floor(seconds % 60)),
    pad(Math.floor((seconds * fps) % fps)),
  ].join(':');
};
