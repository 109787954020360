import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import MaterialAvatar from '../../../../../../components/MaterialAvatar';
import { textCapitalized } from '../../../../../../utils/general';

function Participant({ participant }) {
  return (
    <ListItem
      key={participant.jid}
      button
      disableRipple
      alignItems="flex-start"
      className="contact-item"
    >
      <ListItemAvatar>
        <MaterialAvatar
          badgeType={participant.status || ''}
          badgeVariant="dot"
          classes="contact-profile-avatar"
          src={participant.avatarUrl || ''}
          firstName={participant.fullName}
          lastName={''}
        />
      </ListItemAvatar>
      <ListItemText
        primary={textCapitalized(participant.fullName || '')}
        // secondary={contact.latestMessageText}
      />
    </ListItem>
  );
}

export default Participant;
