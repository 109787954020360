import PropTypes from 'prop-types';
import React from 'react';
import { useEmailDetailsStyles } from './EmailDetailsStyles';

const MailBody = (props) => {
  const classes = useEmailDetailsStyles();

  const removeItems = () => {
    let _div = document.getElementById(`email_body${props.id}`);
    if (_div) {
      _div.remove();
    }
  };

  const createItems = () => {
    if (props.body) {
      const body = document.createElement('div');
      body.setAttribute('style', 'padding : 0px 20px');
      body.id = `email_body${props.id}`;
      body.innerHTML = props.body;
      let _div = document.getElementById(
        `email_body_for_preview_big${props.id}`
      );
      _div.appendChild(body);
    }
  };

  React.useEffect(() => {
    createItems();
    return () => {
      removeItems();
    };
    // eslint-disable-next-line
  }, [props.body]);

  return (
    <div
      className={classes.mailBodyMain}
      id={`email_body_for_preview_big` + props.id}
    ></div>
  );
};

MailBody.propTypes = {
  body: PropTypes.string,
};

export default MailBody;
