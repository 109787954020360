import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import MaterialAvatar from '../../../../../../components/MaterialAvatar';
import { textCapitalized } from '../../../../../../utils/general';
import { getContactOnlineStatus } from '../../../../../../utils/strophe/user';

function Contact({
  contact,
  rosterItems,
  selectedContactMemberID,
  setSelectedContactMemberID,
}) {
  return (
    <ListItem
      key={contact.id}
      button
      disableRipple
      alignItems="flex-start"
      className="contact-item"
      selected={contact.contact_member_id === selectedContactMemberID}
      onClick={() =>
        setSelectedContactMemberID(contact.contact_member_id.toString())
      }
    >
      <ListItemAvatar>
        <MaterialAvatar
          badgeType={getContactOnlineStatus(contact, rosterItems)}
          badgeVariant="dot"
          classes="contact-profile-avatar"
          member_id={contact.contact_member_id}
          src={contact.amera_avatar_url}
          firstName={contact.first_name}
          lastName={contact.last_name}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          textCapitalized(contact.first_name) +
          ' ' +
          textCapitalized(contact.last_name)
        }
        // secondary={contact.latestMessageText}
      />
    </ListItem>
  );
}

export default Contact;
