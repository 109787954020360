import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CountryInput from '../../pages/Registration/InputElements/CountryInput';

import Modal from './modal';

import { validateOutsideMemberForm } from '../../utils/validator/Group';

import {
  addOutsideMember,
  updateOutsideMember,
} from '../../redux/actions/group';

const defaultCountryCode = 'us';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  submitBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
  alert: {
    margin: theme.spacing(1),
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  memberInviteFormFields: {
    border: 0,
  },
}));

const defaultValues = {
  country: 840,
  confirmPhoneRequired: false,
  // isApplicable: true,
};

const AddOutsideMemberModal = (props) => {
  const {
    dispatch,
    open,
    onClose,
    group_id,
    loading,
    countryList,
    outsideMember,
    update = false,
  } = props;
  // const [role, setRole] = React.useState('');

  // const handleChangeRole = (event) => {
  //   setRole(event.target.value);
  // };

  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);
  const title =
    update === true ? 'Update outside member' : 'Add outside member';
  const classes = useStyles();

  const closeModal = () => {
    setIsError(false);
    setErrors({});
    // setRole('');
    onClose();
  };

  useEffect(() => {
    if (open) {
      if (outsideMember && outsideMember.member_id) {
        setValues({
          ...defaultValues,
          firstName: outsideMember.first_name,
          lastName: outsideMember.last_name,
          country: outsideMember.phone_country,
          phoneNumber: outsideMember.phone_code + outsideMember.phone_number,
        });
      } else {
        setValues(defaultValues);
      }
    }
  }, [dispatch, open, outsideMember]);

  useEffect(() => {
    const cellRegExp = (countryList || []).find(
      (cl) => cl.id === values.country
    )?.cell_regexp;
    isError && setErrors(validateOutsideMemberForm(values, cellRegExp));
  }, [isError, values, countryList]);

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhoneNumber = (value, country, e, formattedValue) => {
    setValues({
      ...values,
      phoneNumber: value,
    });
  };

  const setFormData = (values) => {
    let formData = new FormData();
    Object.keys(values).map((key) => {
      let value = values[key];
      if (key === 'phoneNumber') {
        const dialCode = countryList.find(
          (clo) => clo.id === values.country
        ).phone;
        value = values.phoneNumber.replace(dialCode, '');
      }
      return formData.set(key, value);
    });
    return formData;
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const cellRegExp = countryList.find(
      (cl) => cl.id === values.country
    ).cell_regexp;
    const formErrors = validateOutsideMemberForm(values, cellRegExp);
    if (values && Object.keys(formErrors).length === 0) {
      setIsError(false);
      const formData = setFormData(values);
      if (group_id) {
        formData.set('groupId', group_id);
      }
      if (outsideMember && outsideMember.member_id) {
        dispatch(updateOutsideMember(formData, outsideMember.member_id));
      } else {
        dispatch(addOutsideMember(formData));
      }
      setValues(defaultValues);
      closeModal();
    } else {
      setIsError(true);
      setErrors(formErrors);
      return false;
    }
  };

  const getPhoneCountryValue = (country) => {
    const countryFind = countryList.find((clo) => clo.id === country);
    if (countryFind) {
      return countryFind.alpha2.toLowerCase();
    }
    return defaultCountryCode;
  };

  const ModalContent = () => (
    <form className={classes.root} noValidate autoComplete="off">
      <fieldset disabled={loading} className={classes.memberInviteFormFields}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="outlined-first_name"
              label="First Name"
              placeholder="John"
              required
              type="text"
              variant="outlined"
              name="firstName"
              value={values.firstName || ''}
              onChange={(e) => handleChange(e)}
              error={errors.firstName ? true : false}
              helperText={errors.firstName ? errors.firstName : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-last_name"
              label="Last Name"
              placeholder="Doe"
              required
              type="text"
              variant="outlined"
              name="lastName"
              value={values.lastName || ''}
              onChange={(e) => handleChange(e)}
              error={errors.lastName ? true : false}
              helperText={errors.lastName ? errors.lastName : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <CountryInput
              country={values.country}
              handleChangeCountry={handleChange}
              errors={errors.country}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              country={getPhoneCountryValue(values.country)}
              value={values.phoneNumber}
              specialLabel={values.confirmPhoneRequired ? 'Phone *' : 'Phone'}
              onChange={handleChangePhoneNumber}
              inputClass={`phone-number-input ${
                errors.phoneNumber ? 'phone-number-error' : ''
              }`}
              containerClass="phone-number-input-field"
              disableDropdown={true}
              countryCodeEditable={false}
              inputProps={{
                required: values.confirmPhoneRequired,
              }}
            />
            {errors.phoneNumber && (
              <p className="input-error-custom-style">{errors.phoneNumber}</p>
            )}
          </Grid>
        </Grid>
      </fieldset>
    </form>
  );

  const ModalAction = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitBtn}
          onClick={() => closeModal()}
          disabled={loading}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            disabled
          >
            Sending ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            onClick={(e) => handleSubmit(e)}
          >
            {update === true ? 'Update' : 'Add'}
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      disabled={loading}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="member-invite-modal"
    ></Modal>
  );
};

AddOutsideMemberModal.propTypes = {
  dispatch: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  group_id: PropTypes.number,
  loading: PropTypes.bool,
};

AddOutsideMemberModal.defaultProps = {
  dispatch: null,
  open: false,
  onClose: null,
  group_id: null,
  loading: false,
};

const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(AddOutsideMemberModal);
