import { isValid } from 'date-fns';
export const validateRegisterForm = (values, dialCode, codeRule) => {
  let errors = {};

  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }

  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }

  if (!values.country) {
    errors.country = 'Country is required';
  }

  if (values.isApplicable && !values?.company) {
    errors.company_name = 'Company is required or not applicable';
  }

  if (values.job_title_id === '') {
    errors.job_title_id = 'Required input';
  }

  if (values.department_id === '') {
    errors.department_id = 'Required input';
  }

  if (!values.pin) {
    errors.pin = 'PIN is required';
  } else if (
    isNaN(values.pin) ||
    values.pin.length < 6 ||
    values.pin.length > 12
  ) {
    errors.pin = 'PIN is invalid';
  }

  if (!values.confirmPin) {
    errors.confirmPin = 'Confirmation PIN is required';
  } else if (
    isNaN(values.confirmPin) ||
    values.confirmPin.length < 6 ||
    values.confirmPin.length > 12
  ) {
    errors.confirmPin = 'Confirmation PIN is invalid';
  } else if (values.pin !== values.confirmPin) {
    errors.confirmPin = 'PINs do not match';
  }

  if (!values.email) {
    errors.email = 'E-mail address is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = 'E-mail address is invalid';
  } else if (values.email && !values.isEmailConfirmed) {
    errors.email = 'E-mail is not verified';
  }

  if (!values.cell) {
    errors.cell = 'Phone number is required';
  } else if (values.cell === dialCode) {
    errors.cell = 'Phone number is required';
  } else if (values.cell && !values.isCellConfirmed) {
    errors.cell = 'Phone number is not verified';
  }

  if ('uniqueEmail' in values && values.uniqueEmail === false) {
    errors.email = 'E-mail is already registered';
  }

  if (!values.croppedPFP) {
    errors.croppedPFP =
      'Initial encryption is based upon your profile picture and PIN.';
  }

  if (!values.dob) {
    errors.dob = 'Date of Birth is required';
  } else if (!isValid(values.dob)) {
    // There's already a message there
    errors.dob = '';
  }

  // if (!values.gender) {
  //   errors.gender = 'Gender is required';
  // }

  // if (!values.street) {
  //   errors.street = 'Street is required';
  // }

  if (!values.postal_code) {
    errors.postal_code = 'Postal Code is required';
  }

  // if (values.postal_code) {
  //   let flg = true;
  //   for (let i = 0; i < codeRule.length; i++) {
  //     let reg = RegExp(codeRule[i], 'g');
  //     let result = reg.test(values.postal_code);
  //     if (result === false) {
  //       flg = false;
  //       break;
  //     }
  //   }
  //   if (flg === false) errors.postal_code = 'Postal Code is not corrct';
  // }

  // if (!values.city) {
  //   errors.city = 'City is required';
  // }

  // if (!values.state) {
  //   errors.state = 'State is required';
  // }

  // if (!values.userName) {
  //   errors.userName = 'Username is required';
  // }

  if (!values.password) {
    errors.password = 'Password is required';
  }

  if (!values.confirm_password) {
    errors.confirm_password = 'Password is required';
  } else if (values.confirm_password !== values.password) {
    errors.confirm_password = 'Password confirmation does not match!';
  }

  if (!values.isOpenedTerms) {
    errors.toc = 'Please read the Terms and Conditions';
  } else if (values.isOpenedTerms && !values.isAcceptedTerms) {
    errors.toc = 'It is mandatory to accept AMERA terms and conditions';
  }

  return errors;
};

export const validateResetPassword = (values) => {
  let errors = {};

  const { password, confirmPassword } = values;

  if (!password) {
    errors.password = 'Password is required';
  }

  if (!confirmPassword) {
    errors.confirmPassword = 'Confirmation password is required';
  }

  if (password !== confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }

  return errors;
};
