import React from 'react';
import { useSelector } from 'react-redux';
import AmeraBanner from '../Banner';

const AmeraNotification = () => {
  const notifications = useSelector(
    // @ts-ignore
    (state) => state.snackbar.notifications
  );

  return (
    <div className="notification-groups">
      {notifications &&
        notifications.map((notification, index) => (
          <AmeraBanner key={index} {...notification} />
        ))}
    </div>
  );
};

export default AmeraNotification;
