import stripHtml from 'string-strip-html';

export const mailButtonGroup = {
  mailStarred: {
    name: 'mailStarred',
    tooltip: 'Star',
  },
  mailDrafts: {
    name: 'mailDrafts',
    tooltip: 'Draft',
  },
  mailTrash: {
    name: 'mailTrash',
    tooltip: 'Trash',
  },
  mailArchive: {
    name: 'mailArchive',
    tooltip: 'Archive',
  },
  mailFolder: {
    name: 'mailFolder',
    tooltip: 'Move to Folder',
  },
  mailReply: {
    name: 'mailReply',
    text: 'Reply',
  },
  mailReplyAll: {
    name: 'mailReplyAll',
    text: 'Reply All',
  },
  mailForward: {
    name: 'mailForward',
    text: 'Forward',
  },
  mailMoveToTrash: {
    name: 'mailMoveToTrash',
    text: 'Move To Trash',
  },
  mailMoveToAtchive: {
    name: 'mailMoveToAtchive',
    text: 'Move To Archive',
  },
  mailMoveToOrigin: {
    name: 'mailMoveToOrigin',
    text: (origin) => `Move To ${origin}`,
  },
};

export const emailsCountByRequest = 100;

export const filterAndSortingUrlparams = [
  // dateASC:
  'sr=rec_date&or=-1',
  // dateDESC:
  'sr=rec_date&or=1',
  // read:
  'sr=read&or=1',
  // unread:
  'sr=read&or=-1',
];

export const getEmailQueryParams = (
  start_id,
  filter,
  search,
  selectedFolder,
  selectedContact
) => {
  let output = [`size=${emailsCountByRequest}`];
  if (selectedFolder) output.push(`folder_id=${selectedFolder}`);
  if (selectedContact) output.push(`member_filter=${selectedContact}`);
  if (start_id && isFinite(start_id)) output.push(`start=${start_id}`);
  if (search.length !== 0) output.push(`se=${search}`);
  if (filter !== undefined) output.push(filterAndSortingUrlparams[filter]);

  return output;
};

export const getUnstyledHtmlBody = (html) => {
  let output = stripHtml(html).result;
  // let input = `${html}`;
  // let srtArr = input.split('>');
  // srtArr.forEach((str) => {
  //   let newArr = str.split('<');
  //   output = output + newArr[0];
  // });
  return output;
};

export const getAvatarGroupStyles = (index) => {
  switch (index) {
    case 0:
      return {
        zIndex: 3,
      };
    case 1:
      return {
        marginLeft: -34,
        zIndex: 2,
      };
    case 2:
      return {
        marginLeft: -20,
        zIndex: 1,
      };

    default:
      return {};
  }
};

export const isTodaysDate = (time) => {
  const today = new Date();
  const emailTime = new Date(time);
  return (
    today.getFullYear() === emailTime.getFullYear() &&
    today.getMonth() === emailTime.getMonth() &&
    today.getDate() === emailTime.getDate()
  );
};

export const getMembersNamesFromIds = (idArray, members) => {
  let output = [];
  for (let id of idArray) {
    output.push(members[id].first_name + ' ' + members[id].last_name);
  }

  return output.join(', ');
};

export const compareObjects = (mail1, mail2) => {
  return JSON.stringify(mail1) === JSON.stringify(mail2);
};
