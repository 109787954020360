import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GPLACES_TYPES, GPLACES_TYPE_LABELS } from '../../../utils/calendar';

const GooglePlaceTypeAutocomplete = (props) => {
  const { placeType, onSelectPlaceType, variant = 'outlined' } = props;
  const placeTypes = Object.values(GPLACES_TYPES).sort();
  const getPlaceTypeLabel = (o) => GPLACES_TYPE_LABELS[o];
  const getOptionSelected = (o, v) => o === v;
  const renderInput = (params) => (
    <TextField
      {...params}
      variant={variant}
      label="Place type"
      placeholder="Place type"
      margin="dense"
      style={{ margin: 0 }}
    />
  );
  //   console.log('GPLACES_TYPES', Object.values(GPLACES_TYPES));
  return (
    <Autocomplete
      multiple={false}
      fullWidth
      id="google-place-types"
      options={placeTypes}
      disableClearable
      getOptionSelected={getOptionSelected}
      getOptionLabel={getPlaceTypeLabel}
      renderOption={getPlaceTypeLabel}
      onChange={onSelectPlaceType}
      value={placeType}
      renderInput={renderInput}
    />
  );
};

export default GooglePlaceTypeAutocomplete;
