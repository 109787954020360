import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const ConfirmDialog = (props) => {
  const {
    open,
    loading,
    title,
    content,
    cancelText,
    onCancel,
    okText,
    onOk,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      id={`alert-dialog-title-${title}`}
      aria-labelledby={`alert-dialog-title-${title}`}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id={`alert-dialog-title-${title}`}>
        {title || 'Confirm'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content || 'Are you sure you want to delete this?'}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel} color="secondary">
          {cancelText || 'No'}
        </Button>
        <Button variant="contained" onClick={onOk} color="primary" autoFocus>
          {loading ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            okText || 'Yes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  loading: false,
};

export default ConfirmDialog;
