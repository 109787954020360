import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialAvatar from '../../components/MaterialAvatar';

import { textCapitalized } from '../../utils/general';

const getDistinctArray = (array) => {
  return [...new Set(array)];
};

const getDistinctArrayOfObjectsByKey = (contactsList, key) => {
  return [
    ...new Map(contactsList.map((contact) => [contact[key], contact])).values(),
  ];
};

const headCells = [
  {
    id: 'first_name',
    label: 'Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company',
    label: 'Company',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cell_phone',
    label: 'Cell Phone',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    label: 'Relationship',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = ({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const createSortHandler = (property) => () => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    // padding: '8px 16px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  sortTool: {
    marginBottom: '16px',
    minWidth: '120px',
  },
}));

const EnhancedTableToolbar = ({ numSelected, addedMembersToGroup }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected.length > 0,
      })}
    >
      {numSelected.length > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected.length} {numSelected.length > 1 ? 'members' : 'member'}{' '}
          selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Select Group Members
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.arrayOf(PropTypes.number),
  addedMembersToGroup: PropTypes.arrayOf(PropTypes.object),
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const GroupMembersTable = (props) => {
  const {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setSelected,
    selected,
    contactsList,
    contactsCount,
    loadSortedContacts,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
  } = props;
  const classes = useStyles();

  const { addedMembersToGroup } = useSelector((state) => ({
    addedMembersToGroup: state.group.addedMembersToGroup,
  }));

  useEffect(() => {
    setSelected(addedMembersToGroup.map((el) => el.contact_member_id));
  }, [addedMembersToGroup, setSelected]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedContacts(property, newOrder);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = contactsList.map((n) => n.contact_member_id);
      const uniqueMembers = getDistinctArray(newSelecteds);
      setSelected(uniqueMembers);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, name) => {
    let newSelected = [];

    if (selected.includes(name)) {
      newSelected = selected.filter((el) => el !== name);
    } else {
      newSelected = [...selected, name];
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    loadSortedContacts(null, null, null, null, null, null, newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(parseInt(pageSize, 10));
    setPageNumber(0);
    loadSortedContacts(null, null, null, null, 0, pageSize);
  };

  const isSelected = (name) => selected.includes(name);

  return (
    <div className="contact-table">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected}
          addedMembersToGroup={addedMembersToGroup}
        />
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAll}
              onRequestSort={handleRequestSort}
              rowCount={
                getDistinctArrayOfObjectsByKey(
                  contactsList,
                  'contact_member_id'
                ).length
              }
            />
            <TableBody>
              {contactsList.map((row, index) => {
                const isItemSelected = isSelected(row.contact_member_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleSelect(event, row.contact_member_id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.contact_member_id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div className="contact-member-name">
                        <MaterialAvatar
                          firstName={row.first_name}
                          lastName={row.last_name}
                        />
                        <p>
                          {row.first_name && textCapitalized(row.first_name)}{' '}
                          {row.last_name && textCapitalized(row.last_name)}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="left">{row.company}</TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="left">{row.department}</TableCell>
                    <TableCell align="left">{row.cell_phone}</TableCell>
                    <TableCell align="left">{row.city}</TableCell>
                    <TableCell align="left">{row.country}</TableCell>
                    <TableCell align="left">{row.role}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={contactsCount ? contactsCount : 0}
          rowsPerPage={pageSize}
          page={pageNumber}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

GroupMembersTable.propTypes = {
  contactsList: PropTypes.arrayOf(PropTypes.object),
  contactsCount: PropTypes.number,
};

export default GroupMembersTable;
