import React from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

const RateInput = (props) => {
  const {
    rate,
    currencyId,
    onRateChange,
    onCurrencyChange,
    isCanChangeCurrency = true,
    isCanChangeRate = true,
    required,
    label = 'Currency',
    variant = 'outlined',
    error,
  } = props;

  const currencies = useSelector((state) => state.billing.currencyList);

  const value = !!currencies.find((co) => co.currency_code_id === currencyId)
    ? currencyId
    : currencies.find((co) => co.currency_code === 'USD').currency_code_id;

  const renderValue = (id) =>
    currencies.find((co) => co.currency_code_id === id).currency_code;

  return (
    <TextField
      id="rate-input"
      value={rate}
      type="number"
      error={!!error}
      helperText={!!error ? error : ''}
      disabled={!isCanChangeRate}
      variant={variant}
      label={label}
      inputProps={{ min: '0', step: '1', pattern: '[0-9]*' }}
      onChange={onRateChange}
      required={required}
      // style={{ width: '8rem' }}
      // variant="standard"
      // label="Hourly Rate"
      // onChange={(e) => onRateChange(Number(e.target.value))}

      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Select
              autoWidth={true}
              label={label}
              disabled={!isCanChangeRate || !isCanChangeCurrency}
              onChange={onCurrencyChange}
              renderValue={renderValue}
              value={value}
            >
              {currencies.map((co) => (
                <MenuItem value={co.currency_code_id} key={co.currency_code_id}>
                  {co.currency_code} - {co.currency_name}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default RateInput;
