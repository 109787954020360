import {
  SET_ENCRYPTION_KEY,
  SET_VIDEO_CURRENT_KEY,
} from '../actionTypes/VideoCallRedux';

import {
  APP_RESET
} from '../actionTypes/app';

import { AmeraKey } from '../../utils/ameraWebCrypto';

import { str2ab } from '../../utils/webcam/webrtc';

// The initial state of the videocall
const initialState = {
  encryptionKey: null,
  currentKey: str2ab(AmeraKey),
};

const VideoCallRedux = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case SET_ENCRYPTION_KEY:
      return {
        ...state,
        encryptionKey: action.payload.encryptionKey,
      };
    case SET_VIDEO_CURRENT_KEY:
      return {
        ...state,
        currentKey: action.payload.currentKey,
      };
    default:
      return state;
  }
};

export default VideoCallRedux;
