import React, { useMemo, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import BusinessIcon from '@material-ui/icons/Business';
import { useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';

import LocationMap from '../../../components/Modal/EventModals/LocationMap';
import LabeledValue from '../../../components/LabeledValue';
import AddressLine from '../../../components/AddressLine';
import drawerProfileStyles from '../../../jss/views/drawerProfileStyles';
import Button from '../../Dashboard/CardButton';
import AmeraAccordion from '../../../components/AmeraAccordion/AmeraAccordion';
import { displayableFullName, formatPhoneNumber } from '../../../utils/contact';
import { getManagement, COMPANY_ROLES } from '../../../utils/company';
import useGoogleMap from '../../../hooks/useGoogleMap';

const CompanyDetailsView = (props) => {
  const { companyData, onChildClick, isCanEdit, onEditClick } = props;

  const classes = drawerProfileStyles();
  const countryList = useSelector((s) => s.member.countryList);
  const owner = getManagement(companyData.company_members, COMPANY_ROLES.owner);
  const admin = getManagement(companyData.company_members, COMPANY_ROLES.admin);
  const industriesString = useMemo(
    () => companyData.company_industries.map((i) => i.industry_name).join(', '),
    [companyData.company_industries]
  );

  const country = useMemo(
    () => countryList.find((c) => c.id === companyData.country_code_id),
    [countryList, companyData.country_code_id]
  );

  const phone_country_code_id =
    companyData.phone_country_code_id || companyData.country_code_id;

  const phoneCountry = useMemo(
    () => countryList.find((c) => c.id === phone_country_code_id),
    [countryList, phone_country_code_id]
  );

  const phoneCode = useMemo(() => {
    return phoneCountry ? phoneCountry.phone : 1;
  }, [phoneCountry]);

  const {
    places,
    getPlaceDetailsFromPlaceId,
    getPlaceDetailsFromFields,
    mapError,
  } = useGoogleMap();

  useEffect(() => {
    if (!!companyData.place_id) {
      getPlaceDetailsFromPlaceId(companyData.place_id);
    } else {
      getPlaceDetailsFromFields(companyData);
    }
  }, [
    companyData,
    country,
    getPlaceDetailsFromFields,
    getPlaceDetailsFromPlaceId,
  ]);

  const handleUrlClick = () => {
    const newWindow = window.open(
      companyData.primary_url,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  };

  return (
    <AmeraAccordion label="Details">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <LabeledValue
            label="Company Owner"
            value={!!owner ? displayableFullName(owner) : 'Not Specified'}
            gutterBottom
          />
          <LabeledValue
            label="Administrator"
            value={!!admin ? displayableFullName(admin) : 'Not Specified'}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label="Industries"
            value={industriesString || 'Not Specified'}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label="Phone"
            value={
              (companyData.main_phone &&
                formatPhoneNumber(phoneCode, companyData.main_phone)) ||
              'Not Available'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label="Email"
            wordBreak
            value={!!companyData.email ? companyData.email : 'Not specified'}
          />
        </Grid>
        <Grid item xs={6}>
          <LabeledValue
            label="Website"
            value={
              !!companyData.primary_url ? (
                <Link
                  variant="inherit"
                  color="initial"
                  underline="hover"
                  onClick={handleUrlClick}
                >
                  {companyData.primary_url}
                </Link>
              ) : (
                'Not specified'
              )
            }
          />
        </Grid>
        {companyData.child_companies?.length > 0 && (
          <Grid item xs={12}>
            <LabeledValue
              label="Subsidiary Companies"
              value={
                <div className={classes.chipArray}>
                  {companyData.child_companies.map((c) => (
                    <Chip
                      size="small"
                      icon={<BusinessIcon />}
                      key={c.id}
                      label={c.name}
                      clickable
                      onClick={() => onChildClick(c.id)}
                    />
                  ))}
                </div>
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LabeledValue
            label="Main Address"
            value={
              !!companyData.location_id ? (
                <AddressLine data={companyData} />
              ) : (
                <span>Not Specified</span>
              )
            }
          />
        </Grid>
        <Grid item xs={12}>
          {!!places && places.length > 0 && (
            <LocationMap
              defaultZoom={15}
              places={places}
              selectedPlace={places[0]}
              onSelectClick={null}
              onBoundsReady={null}
              error={mapError}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: '15rem' }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          )}
        </Grid>
        {isCanEdit && (
          <Grid item xs={12} className={classes.alignRight}>
            <Button variant="okFilled" text="Edit" onClick={onEditClick} />
          </Grid>
        )}
      </Grid>
    </AmeraAccordion>
  );
};

export default CompanyDetailsView;
