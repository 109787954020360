import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { intersection } from 'lodash';

import Modal from '../../modal';
import TitleAndDatetime from './TitleAndDatetime';
import EventLocation from './EventLocation';
import InvitedMembers from './InvitedMembers';
import EventDetails from './EventDetails';
import Attachments from './Attachments';
import EventLink from './EventLink';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import HostInfo from './HostInfo';
import EventCover from './EventCover';
import {
  eventTypeText,
  EVENT_TYPES,
  EAT_GPLACES,
  eventDisplayName,
} from '../../../../utils/calendar';
import { apiUrl } from '../../../../config/api';
import { makeDirectionsUrl } from '../../../../utils/country';
import { sendDrivingDirectionsSms } from '../../../../redux/actions/schedule_event';

const EventInfoModal = (props) => {
  const {
    show,
    onClose,
    selectedEvent,
    onEditClick,
    onCancelClick,
    isEditable,
  } = props;

  const dispatch = useDispatch();
  const [placePhoto, setPlacePhoto] = useState(null);
  const [destinationPlace, setDestinationPlace] = useState(null);
  const [smsSuccess, setSMSSuccess] = useState(null);

  console.debug('selectedEvent', selectedEvent);
  const {
    event_type,
    event_location,
    event_url,
    host_member_info,
    invitations,
    attachments,
    event_description,
    group_info,
    cover_attachment_id,
  } = selectedEvent;

  const memberId = useSelector((s) => s.member.member.member_id);
  const isMyEvent = memberId === host_member_info.host_member_id;

  const isHasLocation =
    (event_type === EVENT_TYPES.meeting ||
      event_type === EVENT_TYPES.personal ||
      event_type === EVENT_TYPES.breakfast ||
      event_type === EVENT_TYPES.lunch ||
      event_type === EVENT_TYPES.dinner ||
      event_type === EVENT_TYPES.party) &&
    !!event_location &&
    !!event_location.place_id;
  const isHasLink =
    (event_type === EVENT_TYPES.chat ||
      event_type === EVENT_TYPES.video ||
      event_type === EVENT_TYPES.webinar) &&
    !!event_url;
  const isHasMembers =
    !!invitations && invitations.length > 0 && !!invitations[0];
  const isHasAttachment =
    !!attachments && attachments.length > 0 && !!attachments[0];

  // Cover image is either provided when scheduled OR restraunt / bar image
  let coverImage = null;
  if (!!cover_attachment_id && attachments?.length > 0) {
    const coverAttachmentObj = attachments.find(
      (afo) => afo.member_file_id === cover_attachment_id
    );
    coverImage = `${apiUrl}/${coverAttachmentObj?.file_url}`;
  } else if (event_location && event_location.location_profile_picture_url) {
    coverImage = `${apiUrl}/${event_location.location_profile_picture_url}`;
  } else if (!!placePhoto) {
    coverImage = placePhoto;
  }

  const handlePlaceData = async (place) => {
    if (intersection(place.types, EAT_GPLACES).length > 0) {
      const picUrl = await place.photos[0].getUrl({ maxWidth: 600 });
      setPlacePhoto(picUrl);
    }

    setDestinationPlace(place);
  };

  const handleSendClick = (memberIds) => {
    const link = makeDirectionsUrl(destinationPlace);
    // console.debug(link);
    handleInitSMS(link, memberIds);
  };

  const handleSelfYourselfClick = (link) => {
    const memberIds = [memberId];
    handleInitSMS(link, memberIds);
  };

  const handleInitSMS = async (link, memberIds) => {
    const payload = {
      link,
      destinationAddress: destinationPlace.formatted_address,
      destinationName: destinationPlace.name,
      memberIds,
      event_name: eventDisplayName(selectedEvent, memberId),
    };
    const success = await dispatch(sendDrivingDirectionsSms(payload));

    setSMSSuccess(success);
  };

  const ModalContent = () => (
    <Grid container spacing={2}>
      {!!coverImage && <EventCover imageUrl={coverImage} />}
      <TitleAndDatetime event={selectedEvent} />
      {isHasLocation && (
        <EventLocation
          event={selectedEvent}
          onPlaceData={handlePlaceData}
          onSendYourself={handleSelfYourselfClick}
          isSMSSuccess={!!smsSuccess ? smsSuccess[String(memberId)] : false}
        />
      )}
      {isHasLink && <EventLink link={event_url} />}
      {isHasMembers && (
        <InvitedMembers
          groupInfo={group_info}
          invitations={invitations}
          onSendClick={isHasLocation && isMyEvent ? handleSendClick : null}
          smsSuccess={smsSuccess}
        />
      )}
      {!!event_description && <EventDetails details={event_description} />}
      {isHasAttachment && <Attachments attachments={attachments} />}
      <HostInfo hostInfo={host_member_info} />
    </Grid>
  );
  const ModalAction = () => (
    <Grid
      container
      spacing={2}
      style={{ padding: '0.5rem 1.5rem' }}
      justify="space-between"
    >
      <Grid item>
        {isEditable && (
          <>
            <Button startIcon={<EditIcon />} onClick={onEditClick}>
              Edit
            </Button>
            <Button startIcon={<ClearIcon />} onClick={onCancelClick}>
              Cancel {eventTypeText(event_type)}
            </Button>
          </>
        )}
      </Grid>
      <Grid item>
        <Button onClick={onClose}>Close</Button>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={`${eventTypeText(event_type)} details`}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      // maxWidth="sm"
      className="event-info-modal"
    ></Modal>
  );
};

export default EventInfoModal;
