import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';

const StyledEpicTreeItem = (props) => {
  const {
    element_type,
    projectId,
    element_id,
    // title,
    // labelIcon: LabelIcon,
    labelInfo,
    // color,
    // bgColor,
    taskRow,
    rate_type,
    // ...other
  } = props;

  const element = useSelector((state) => {
    let project = state.project.projects.filter(
      (p) => p.project_id === +projectId
    )[0];
    let projectElement = project.project_elements.filter(
      (pe) => pe.project_element_id === element_id
    )[0];
    return projectElement;
  });
  const title = element?.title;

  return (
    <div className={`${element_type}-row element-row`}>
      {element_type === 'task' || element_type === 'milestone' ? (
        taskRow
      ) : (
        <>
          <Typography variant="body2" className="row-title">
            {title}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {rate_type === 'fixed' && (
            <Typography variant="caption" className="fixed-pill">
              Fixed Price
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default StyledEpicTreeItem;
