import Axios from 'axios';
import { setSnackbarData } from '../../redux/actions/snackbar';
import { store } from '../../utils/store';
import { GET, POST, DELETE } from '../api';
import { apiPaths } from '../apiPaths';
class AmeraEmailApi {
  catchErrorHandler = (error) => {
    store.dispatch(
      setSnackbarData({
        open: true,
        type: 'error',
        message: error.message,
      })
    );
  };

  getEmailUnreadCount = async () => {
    const params = ['sr=read&or=-1'];
    return (await this.getEmails('getInbox', params))?.data?.length;
  };

  getEmails = async (action, params) => {
    if (!action) return [];
    let url = apiPaths.mail[action];
    if (params.length > 0) {
      url = url + '?' + params.join('&');
    }
    let res = { data: [], length: 0 };
    try {
      res = await GET(url);
    } catch (error) {
      this.catchErrorHandler(error);
    }
    return res;
  };

  getEmailDetails = async (action, id) => {
    if (!action) return [];
    let url = apiPaths.mail[action](id);

    let res = {};
    try {
      res = await GET(url);
    } catch (error) {
      this.catchErrorHandler(error);
    }
    return res;
  };

  postEmail = async (action, email) => {
    if (!action) return [];
    let url = apiPaths.mail[action];
    let res = { success: false };
    try {
      let response = await POST(url, JSON.stringify({ ...email }), {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      });
      res = {
        success: true,
        ...response,
      };
    } catch (error) {
      this.catchErrorHandler(error);
    }
    return res;
  };

  uploadAttachment = async (body, config) => {
    try {
      return Axios.post(apiPaths.mail.postAttach, body, config);
    } catch (error) {
      this.catchErrorHandler(error);
    }
  };

  deleteAttachment = async (email_id, attach_id) => {
    const url = apiPaths.mail.deleteAttach(email_id, attach_id);
    try {
      return DELETE(
        url,
        {},
        {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
      );
    } catch (error) {
      this.catchErrorHandler(error);
    }
  };

  deleteEmail = async (action, id) => {
    if (!action) return [];
    let url = apiPaths.mail[action](id);
    let res = { success: false };
    try {
      res = {
        success: true,
        ...(await DELETE(url, {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        })),
      };
      return res;
    } catch (error) {
      this.catchErrorHandler(error);
      return res;
    }
  };

  deleteFolder = async (action, folder_id) => {
    if (!action) return [];
    let url = apiPaths.mail[action];
    let res = { success: false };
    try {
      res = {
        success: true,
        ...(await DELETE(
          url,
          JSON.stringify({
            folder_id,
          }),
          {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }
        )),
      };
      return res;
    } catch (error) {
      this.catchErrorHandler(error);
      return res;
    }
  };

  getSettings = async (action) => {
    if (!action) return {};
    let url = apiPaths.mail[action];
    try {
      return await GET(url);
    } catch (error) {
      this.catchErrorHandler(error);
    }
  };

  updateSettings = async (action, payload) => {
    if (!action) return {};
    let url = apiPaths.mail[action];
    try {
      return await POST(url, JSON.stringify(payload), {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      });
    } catch (error) {
      this.catchErrorHandler(error);
    }
  };

  getSignatures = async (action) => {
    if (!action) return {};
    let url = apiPaths.mail[action];
    try {
      return await GET(url, {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      });
    } catch (error) {
      this.catchErrorHandler(error);
    }
  };

  updateSignature = async (action, payload) => {
    if (!action) return {};
    let url = apiPaths.mail[action];
    try {
      return {
        success: true,
        ...(await POST(url, payload, {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        })),
      };
    } catch (error) {
      this.catchErrorHandler(error);
      return {
        success: false,
      };
    }
  };

  deleteSignature = async (action, sign_id) => {
    if (!action) return {};
    let url = apiPaths.mail[action];
    try {
      return {
        success: true,
        ...(await DELETE(url, JSON.stringify({ sign_id }), {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        })),
      };
    } catch (error) {
      this.catchErrorHandler(error);
      return {
        success: false,
      };
    }
  };
}

const ApiEmailService = new AmeraEmailApi();
Object.freeze(ApiEmailService);
export { ApiEmailService };
