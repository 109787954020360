import { sub, compareAsc, differenceInMilliseconds } from 'date-fns';
import { Strophe } from 'strophe.js';

import * as api from '../../config/api';
import { setMemberAlert } from '../../utils/alert';
import { setSnackbarData } from './snackbar';

import {
  GET_MEMBER_DATA_START,
  GET_MEMBER_DATA_SUCCEEDED,
  GET_MEMBER_DATA_FAILED,
  SAVE_SIGN_UP_SESSION,
  CLEAR_SIGN_UP_SESSION,
  MEMBER_LOGIN_SUCCEEDED,
  MEMBER_LOGIN_FAILED,
  MEMBER_LOGOUT_SUCCEEDED,
  MEMBER_LOGOUT_FAILED,
  SEND_MEMBER_RESET_EMAIL_START,
  SEND_MEMBER_RESET_EMAIL_SUCCEEDED,
  SEND_MEMBER_RESET_EMAIL_FAILED,
  GET_MEMBER_LOCATION_INFO_START,
  GET_MEMBER_LOCATION_INFO_SUCCEEDED,
  GET_MEMBER_LOCATION_INFO_FAILED,
  MEMBERS_LOAD_SUCCESS,
  MEMBERS_LOADING,
  // SIGNUP_SUCCESS,
  VALID_INVITE,
  MEMBER_SAVE_CREDENTIALS,
  FETCH_REQUEST_START,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_FAILED,
  UPDATE_REQUEST_START,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_FAILED,
  // Register
  SEND_REGISTER_START,
  SEND_REGISTER_SUCCEEDED,
  SEND_REGISTER_FAILED,

  // Job title list request
  GET_TITLE_LIST_START,
  GET_TITLE_LIST_SUCCEEDED,
  GET_TITLE_LIST_FAILED,
  GET_DEPS_LIST_START,
  GET_DEPS_LIST_SUCCEEDED,
  GET_DEPS_LIST_FAILED,
  // TOTP
  CELL_VERIFICATION_INIT,
  CELL_VERIFICATION_ATTEMPT,
  CELL_VERIFICATION_RESPONSE,
  CELL_VERIFICATION_ERROR,

  // Country list
  GET_COUNTRY_LIST_START,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILED,

  // tz list
  GET_TZLIST_START,
  GET_TZLIST_SUCCESS,
  GET_TZLIST_FAILED,

  // Skillset
  GET_SKILLS_START,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_FAILED,

  // Industry
  GET_INDUSTRY_LIST,
  GET_INDUSTRY_SUCCESS,
  GET_INDUSTRY_ERROR,

  // member page settings
  GET_PAGE_SETTINGS_SUCCESS,
  PUT_PAGE_SETTINGS_SUCCESS,
  INSERT_PAGE_SETTINGS_SUCCESS,

  // Member info
  GET_MEMBER_INFO_START,
  GET_MEMBER_INFO_SUCCESS,
  GET_MEMBER_INFO_FAILED,
  PUT_MEMBER_INFO_START,
  PUT_MEMBER_INFO_SUCCESS,
  // PUT_MEMBER_INFO_FAILED,
  GET_MEMBER_SETTING_SUCCESS,
  PUT_MEMBER_SETTING_SUCCESS,

  // Avatar update
  PUT_AVATAR_UPDATE_START,
  PUT_AVATAR_UPDATE_SUCCESS,
  PUT_AVATAR_UPDATE_FAILED,
  FETCH_ROLES_START,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,

  //Notifications setting
  FETCH_NOTIFICATIONS_SETTING_START,
  FETCH_NOTIFICATIONS_SETTING_SUCCESS,
  FETCH_NOTIFICATIONS_SETTING_FAILED,
  PUT_NOTIFICATIONS_SETTING_START,
  PUT_NOTIFICATIONS_SETTING_SUCCESS,
  PUT_NOTIFICATIONS_SETTING_FAILED,

  // Terms and conditions
  // GET_TERMS_AND_CONDITIONS_START,
  // GET_TERMS_AND_CONDITIONS_SUCCESS,
  // GET_TERMS_AND_CONDITIONS_FAILED,
  SET_MEMBER_ALERT,
  SHOW_COUNTDOWN,
  SET_COUNTDOWN_TIMER,
  AUTO_LOGOUT,
  CLEAR_AUTO_LOGOUT,
  GET_ALL_MEMBERS,
  FACE_REGISTERED,
  FACE_MATCHED,
  RESET_FACE_MATCH,
  LOAD_SUCCESS_VIDEO_MAILS,
  READ_VIDEO_MAIL,
  DELETE_VIDEO_MAIL,
  REPLY_MESSAGE_SENT_SUCCESS,
  REPLY_MESSAGE_SENT_FAIL,
  OUTGOING_PHONE_VERIFICATION_INIT,
  OUTGOING_PHONE_VERIFICATION_ERROR,
  // Locations update
  UPDATE_LOCATIONS_START,
  UPDATE_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS_FAIL,
  UPDATE_USERNAME_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAIL,
  MEMBER_LOGIN_STARTED,
  MEMBER_LOGIN_COMPLETED,
} from '../actionTypes/member.js';

import { contactMessageSuccess, contactMessageFail } from './contact';
import { groupMessageSuccess, groupMessageFail } from './group';
import { setBadgeCount } from './mail';
import { stropheConnect, stropheDisconnect, loadContactsArchive } from './chat';
import { loadContacts } from './contact';

import {
  subscribeToSSE,
  unsubscribeSSE,
  removeNotificationCard,
} from './event';
import { getCalendarColors } from './schedule_event';
import { BillingApi } from './billing';

const apiUrl = api.apiUrl;
const sessionExpirationThreshold =
  process.env.REACT_APP_SESSION_EXPIRATION_THRESHOLD_MINUTES;

export const saveSignUpSession = (payload) => ({
  type: SAVE_SIGN_UP_SESSION,
  payload,
});
export const clearSignUpSession = (payload) => ({
  type: CLEAR_SIGN_UP_SESSION,
  payload,
});
export const forgotPasswordRequestStart = () => ({
  type: SEND_MEMBER_RESET_EMAIL_START,
});
export const forgotPasswordRequestSucceeded = () => ({
  type: SEND_MEMBER_RESET_EMAIL_SUCCEEDED,
});
export const forgotPasswordRequestFailed = (payload) => ({
  type: SEND_MEMBER_RESET_EMAIL_FAILED,
  payload,
});
export const getMemberLocationInfoStart = (payload) => ({
  type: GET_MEMBER_LOCATION_INFO_START,
  payload,
});
export const getMemberLocationInfoSucceeded = (payload) => ({
  type: GET_MEMBER_LOCATION_INFO_SUCCEEDED,
  payload,
});
export const getMemberLocationInfoFailed = (payload) => ({
  type: GET_MEMBER_LOCATION_INFO_FAILED,
});

export const login = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/login`;
  dispatch({ type: RESET_FACE_MATCH });
  dispatch({ type: MEMBER_LOGIN_STARTED });
  api
    .POST(url, formData, { 'Content-Type': 'application/json' })
    .then((res) => {
      console.log(res);
      if (res && res.session_id) {
        dispatch(memberLoginSucceeded(res));
      } else {
        dispatch(
          memberLoginFailed(
            `Either the Email or password you have entered are not correct!`
          )
        );
      }
    })
    .catch((err) => {
      let errMsg = 'An unexpected error occurred, please try again later.';
      if (err && err.res && err.res.status === 403) {
        errMsg = 'Your account is disabled';
      }
      dispatch(memberLoginFailed(errMsg));
    });
};

export const logout = (member_id, session_id) => (dispatch) => {
  const url = `${apiUrl}/member/${member_id}/logout/${session_id}`;
  console.debug('Logout action fired');
  api
    .POST(url, { 'Content-Type': 'application/json' })
    .then((res) => {
      // Close connection to SSE
      dispatch(memberLogoutSucceeded(res));
      dispatch(disconnectMember());
    })
    .catch((err) => {
      dispatch(memberLogoutFailed());
    });
};

export const sendForgotEmail = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/forgot`;
  dispatch(forgotPasswordRequestStart());
  api
    .POST(url, formData, { 'Content-Type': 'application/json' })
    .then((res) => {
      if (res.success) {
        dispatch(forgotPasswordRequestSucceeded());
      } else {
        dispatch(forgotPasswordRequestFailed(res));
      }
    })
    .catch((err) => {
      const status = (err && err.res && err.res.status) || null;
      let message = '';
      if (status === 400) {
        message = 'Please Provide Email Address!';
      } else if (status === 404) {
        message = 'Email not Found!';
      } else if (status === 409) {
        message = 'Email already sent. Please check your Email.';
      } else if (status === 500) {
        message = 'Something went wrong when sending Email!';
      }
      dispatch(forgotPasswordRequestFailed(message));
    });
};
export const validateForgotKey = (forgotKey) => (dispatch) => {
  const url = `${apiUrl}/member/reset-password/${forgotKey}`;
  api
    .GET(url)
    .then((res) => {
      if (res.member_id) {
        dispatch(fetchRequestSuccess());
      } else {
        dispatch(fetchRequestFailed('Unable to Validate Key.'));
      }
    })
    .catch((err) => {
      const status = (err && err.res && err.res.status) || null;
      let message = '';
      if (status === 400) {
        message = 'Please provide forgot key!';
      } else if (status === 404) {
        message = 'Invalid key check email!';
      } else if (status === 410) {
        message = 'Reset link expired!';
      } else if (status === 500) {
        message = 'Internal Server Error!';
      }
      dispatch(fetchRequestFailed(message));
    });
};

export const resetPassword = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/reset-password/${formData.forgot_key}`;
  dispatch(updateRequestStart());
  const formDataString = JSON.stringify(formData);
  api
    .POST(url, formDataString, { 'Content-Type': 'application/json' })
    .then((res) => {
      if (res.success) {
        dispatch(updateRequestSuccess());
      } else {
        dispatch(updateRequestFailed('Failed to Update Password.'));
      }
    })
    .catch((err) => {
      const status = (err && err.res && err.res.status) || null;
      let message = '';

      if (status === 410) {
        message = 'Link Expired!';
      } else if (status === 500) {
        message = 'Something went wrong.';
      }
      dispatch(updateRequestFailed(message));
    });
};

export const changePassword =
  (formData, popupSnackbar = false) =>
  (dispatch) => {
    const url = `${apiUrl}/member/change-password`;
    dispatch(updateRequestStart());
    const formDataString = JSON.stringify(formData);
    api
      .POST(url, formDataString, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res.success) {
          dispatch(updateRequestSuccess());
          if (popupSnackbar) {
            dispatch(
              setSnackbarData({
                open: true,
                message: 'Password updated',
                type: 'success',
              })
            );
          }
        } else {
          dispatch(updateRequestFailed('Failed to Update Password.'));
          if (popupSnackbar) {
            dispatch(
              setSnackbarData({
                open: true,
                message: 'Failed to Update Password.',
                type: 'error',
              })
            );
          }
        }
      })
      .catch((err) => {
        const status = (err && err.res && err.res.status) || null;
        let message = '';
        if (status === 400) {
          message = 'Please fill all fields!';
        } else if (status === 409) {
          message = 'New password should must be different!';
        } else if (status === 404) {
          message = 'Current password is incorrect!';
        } else if (status === 500) {
          message = 'Something went wrong!';
        }
        dispatch(updateRequestFailed(message));
        if (popupSnackbar) {
          dispatch(
            setSnackbarData({
              open: true,
              message,
              type: 'error',
            })
          );
        }
      });
  };

const setCountdown = (dispatch, expiration_date) => {
  let now = new Date();
  const threshold = sub(expiration_date, {
    minutes: parseInt(sessionExpirationThreshold) || 2,
  });
  console.debug(`Exp Date: ${expiration_date}`);
  console.debug(`Threshold: ${threshold}`);
  console.debug(`Now Date: ${now}`);
  console.debug(`Compare ASC: ${compareAsc(now, threshold)}`);
  console.debug(
    `Difference in MS: ${differenceInMilliseconds(now, threshold)}`
  );
  console.debug(
    `Difference in MS: ${differenceInMilliseconds(threshold, now)}`
  );
  let later = 0;
  if (compareAsc(now, threshold) < 0) {
    later = differenceInMilliseconds(threshold, now);
  }
  console.debug(`Later: ${later}`);
  dispatch({ type: SHOW_COUNTDOWN, payload: false });
  const countdownTimer = setTimeout(() => {
    dispatch({ type: SHOW_COUNTDOWN, payload: true });
  }, later);
  dispatch({ type: SET_COUNTDOWN_TIMER, payload: countdownTimer });
};

export const getSession =
  (newSession = false) =>
  (dispatch) => {
    // console.log('Get member fired');
    const url = `${apiUrl}/valid-session`;
    dispatch({ type: GET_MEMBER_DATA_START });
    let call = api.GET;
    if (newSession === true) {
      call = api.PUT;
    }

    call(url)
      .then((res) => {
        if (res.member_id) {
          dispatch({ type: GET_MEMBER_DATA_SUCCEEDED, payload: res });
          setCountdown(dispatch, new Date(res.expiration_date));
        } else {
          dispatch({ type: GET_MEMBER_DATA_FAILED });
        }
        return res;
      })
      .then((member) => {
        return dispatch(connectMember(member.member_id));
      })
      .then(() => {
        return dispatch(getMember());
      })
      .then(() => {
        return dispatch(getMemberContactChatArchive());
      })
      .then(() => {
        return dispatch({ type: MEMBER_LOGIN_COMPLETED });
      })
      .catch((error) => {
        dispatch({ type: GET_MEMBER_DATA_FAILED });
        console.error(error);
      });
  };

export const connectMember = (member_id) => (dispatch) => {
  // Here we subscribe to event source
  subscribeToSSE(member_id);

  // XMPP Connect
  dispatch(stropheConnect(member_id));

  //cleanup resources before app leave
  window.addEventListener('beforeunload', () => dispatch(stropheDisconnect()));
};

export const getMemberContactChatArchive = (connectionStatus) => (dispatch) => {
  console.debug(
    `getMemberContactChatArchive: Connection Status: ${connectionStatus}`
  );
  if (connectionStatus === Strophe.Status.CONNECTED) {
    console.debug(
      `getMemberContactChatArchive: Loading contacts and then archive`
    );
    // load chat history
    dispatch(loadContacts())
      .then((contacts) => {
        dispatch(loadContactsArchive(contacts));
      })
      .catch((e) => {
        console.error('failed to load archives.', e);
      });
  }
};

export const disconnectMember = () => (dispatch) => {
  // Here we unsubscribe to event source
  unsubscribeSSE();

  // Disconnect from XMPP
  dispatch(stropheDisconnect(true));
};

export const getMember = () => (dispatch) => {
  // Request member details
  return (
    dispatch(getMemberInfo())
      // Load member page default settings
      .then(() => {
        return dispatch(getPageSettings());
      })
      .then(() => {
        return dispatch(getMemberSetting());
      })
      //get unread email
      .then(() => {
        return dispatch(setBadgeCount());
      })
  );
};

export const getMetadata = () => (dispatch) => {
  dispatch(getCalendarColors());

  dispatch(BillingApi.getCurrencyList());

  // Load the job title list
  dispatch(getTitleList());

  // Load the departments list
  dispatch(getDepartmentList());

  // Load the active country list
  dispatch(getCountryList());

  // Load timezones data
  dispatch(getTZList());

  // Load roles list
  dispatch(getRoleList());

  // Load skills list
  dispatch(getSkills());

  // Load department list
  dispatch(getIndustries());
};

// Get Member Location Information
export const getMemberLocationInfo = () => (dispatch) => {
  const url = `${apiUrl}/api/client/info`;
  dispatch(getMemberLocationInfoStart());
  api
    .GET(url)
    .then((res) => {
      dispatch(getMemberLocationInfoSucceeded(res));
    })
    .catch((error) => {
      dispatch(getMemberLocationInfoFailed(error));
      console.error(error);
    });
};

export const getMembers = (data) => (dispatch) => {
  let url = `${apiUrl}/member/search?search_key=${data.search_key}`;
  if (data.group_id) url = `${url}&exclude_group_id=${data.group_id}`;
  dispatch({ type: MEMBERS_LOADING });
  api
    .GET(url)
    .then((res) => {
      // console.log('Get Members from shareprocess modal returned', res.members);
      dispatch(loadMemebersSuccess(res.members));
    })
    .catch((error) => {
      console.log(error);
      dispatch(loadMemebersSuccess([]));
    });
};

export const getAllMembers = (data) => async (dispatch) => {
  let url = `${apiUrl}/member/search?search_key=`;
  try {
    const res = await api.GET(url);
    dispatch({ type: GET_ALL_MEMBERS, payload: res.members });
  } catch (e) {}
};

export const getMemberInfo = () => {
  return (dispatch) => {
    console.log('Requestng member info');
    dispatch({ type: GET_MEMBER_INFO_START });
    const url = `${apiUrl}/member/info`;
    return api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('Response from member info endpoint', res.data);
          dispatch({ type: GET_MEMBER_INFO_SUCCESS, payload: res.data });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_MEMBER_INFO_FAILED, payload: error });
      });
  };
};

export const getMemberSetting = () => (dispatch) => {
  console.log('Requestng member setting');
  dispatch({ type: GET_MEMBER_INFO_START });
  const url = `${apiUrl}/member/setting`;
  return api
    .GET(url)
    .then((res) => {
      if (res.success) {
        console.log('Response from member info endpoint', res.data);
        dispatch({
          type: GET_MEMBER_SETTING_SUCCESS,
          payload: res.data || {},
        });
      }
    })
    .catch((error) => {
      dispatch({ type: GET_MEMBER_INFO_FAILED, payload: error });
    });
};

export const putMemberInfo = (formData, language) => {
  return (dispatch) => {
    console.log('Submitting member info');
    dispatch({ type: PUT_MEMBER_INFO_START });
    const url = `${apiUrl}/member/info`;
    return api
      .PUT(url, JSON.stringify(formData), {
        'Content-Type': 'application/json',
      })
      .then((res) => {
        if (res.success) {
          console.log('Response after submitting profile edit form', res.data);
          dispatch({ type: PUT_MEMBER_INFO_SUCCESS, payload: res.data || {} });
          // return true;
          const message = 'Profile information updated successfully';
          dispatch(
            setReduxMemberAlert({ open: true, message, severity: 'success' })
          );
        } else {
          const message = 'Failed to update profile information!';
          dispatch(
            setReduxMemberAlert({ open: true, message, severity: 'error' })
          );
        }
      })
      .catch((error) => {
        // dispatch({ type: PUT_MEMBER_INFO_FAILED, payload: error });
        const payload = setMemberAlert(error, false);
        dispatch(setReduxMemberAlert(payload));
        // return false;
      });
  };
};

export const putMemberSetting = (formData) => {
  return (dispatch) => {
    console.log('Submitting member setting');
    dispatch({ type: PUT_MEMBER_INFO_START });
    const url = `${apiUrl}/member/setting`;
    return api
      .PUT(url, JSON.stringify(formData), {
        'Content-Type': 'application/json',
      })
      .then(async (res) => {
        if (res.success) {
          console.log(
            'Response after submitting profile setting form',
            res.data
          );
          dispatch({ type: PUT_MEMBER_SETTING_SUCCESS, payload: res.data });
          dispatch(getMemberInfo());
          // return true;
          const message = 'Profile setting updated successfully';
          const payload = setMemberAlert(res, true, message);
          return dispatch(setReduxMemberAlert(payload));
        } else {
          const message = 'Failed to update profile setting!';
          const payload = setMemberAlert(null, false, message);
          return dispatch(setReduxMemberAlert(payload));
        }
      })
      .catch((error) => {
        // dispatch({ type: PUT_MEMBER_INFO_FAILED, payload: error });
        const payload = setMemberAlert(error, false);
        return dispatch(setReduxMemberAlert(payload));
        // return false;
      });
  };
};

export const putPaymentSetting = (formData) => {
  return (dispatch) => {
    dispatch({ type: PUT_MEMBER_INFO_START });
    const url = `${apiUrl}/member/payment-setting`;
    return api
      .PUT(url, JSON.stringify(formData), {
        'Content-Type': 'application/json',
      })
      .then((res) => {
        if (res.success) {
          console.log(
            'Response after submitting profile setting form',
            res.data
          );
          dispatch({ type: PUT_MEMBER_SETTING_SUCCESS, payload: res.data });
          // return true;
          const message = 'Profile payment setting updated successfully';
          const payload = setMemberAlert(res, true, message);
          dispatch(setReduxMemberAlert(payload));
        } else {
          const message = 'Failed to update profile payment setting!';
          const payload = setMemberAlert(null, false, message);
          dispatch(setReduxMemberAlert(payload));
        }
      })
      .catch((error) => {
        // dispatch({ type: PUT_MEMBER_INFO_FAILED, payload: error });
        const payload = setMemberAlert(error, false);
        dispatch(setReduxMemberAlert(payload));
        // return false;
      });
  };
};
export const ValidInvite = (inviteKey) => {
  const url = `${apiUrl}/member/invite/${inviteKey}`;
  return api.GET(url);
};

export const RegisterMember = (data, inviteKey) => {
  if (inviteKey) {
    inviteKey = `/${inviteKey}`;
  }
  const url = `${apiUrl}/member/register${inviteKey}`;
  // const newData = {
  //   ...data,
  //   state: data.sub_local0 ? data.sub_local0 : '',
  // };
  return api.POST(url, data, {});
};

export const memberLoginSucceeded = (payload) => ({
  type: MEMBER_LOGIN_SUCCEEDED,
  payload,
});
export const memberLoginFailed = (loginErr) => ({
  type: MEMBER_LOGIN_FAILED,
  payload: {
    loginErr,
  },
});
export const memberLogoutSucceeded = (payload) => ({
  type: MEMBER_LOGOUT_SUCCEEDED,
  payload,
});
export const memberLogoutFailed = (payload) => ({
  type: MEMBER_LOGOUT_FAILED,
  payload,
});
export const getMemberDataSucceeded = (payload) => ({
  type: GET_MEMBER_DATA_SUCCEEDED,
  payload,
});

export const loadMemebersSuccess = (payload) => ({
  type: MEMBERS_LOAD_SUCCESS,
  payload,
});

export const loadInvite = (payload) => ({
  type: VALID_INVITE,
  payload,
});

export const saveCredentials = (payload) => ({
  type: MEMBER_SAVE_CREDENTIALS,
  payload,
});

export const fetchRequestStart = () => ({
  type: FETCH_REQUEST_START,
});
export const fetchRequestSuccess = () => ({
  type: FETCH_REQUEST_SUCCESS,
});
export const fetchRequestFailed = (payload) => ({
  type: FETCH_REQUEST_FAILED,
  payload,
});

export const updateRequestStart = () => ({
  type: UPDATE_REQUEST_START,
});
export const updateRequestSuccess = () => ({
  type: UPDATE_REQUEST_SUCCESS,
});
export const updateRequestFailed = (payload) => ({
  type: UPDATE_REQUEST_FAILED,
  payload,
});

// Registration
export const postRegisterMemeber = (data, inviteKey) => {
  return (dispatch) => {
    dispatch({ type: SEND_REGISTER_START });
    if (inviteKey) {
      inviteKey = `/${inviteKey}`;
    }
    const url = `${apiUrl}/member/register${inviteKey}`;

    return api
      .POST(url, data)
      .then((res) => {
        if (res && res.success) {
          const message =
            'Member registered successfully! Redirecting to Login page...';
          const payload = setMemberAlert(
            res,
            res.success,
            message,
            'bottom',
            true
          );
          dispatch(setReduxMemberAlert(payload));
          dispatch({ type: SEND_REGISTER_SUCCEEDED });
          return true;
        } else {
          const payload = setMemberAlert(null, false, '', 'bottom', true);
          dispatch(setReduxMemberAlert(payload));
          dispatch({ type: SEND_REGISTER_FAILED });
          return false;
        }
      })
      .catch((err) => {
        const payload = setMemberAlert(err, false, '', 'bottom', true);
        dispatch(setReduxMemberAlert(payload));
        dispatch({ type: SEND_REGISTER_FAILED });
        return false;
      });
  };
};

// Get Job title list
export const getTitleList = () => {
  return (dispatch) => {
    dispatch({ type: GET_TITLE_LIST_START });
    console.log('Requesting job title list and departments');
    const url = `${apiUrl}/member/register/job-title`;
    api
      .GET(url)
      .then((res) => {
        console.log('Response from job-title list endpoint', res);
        if (res.success) {
          dispatch({ type: GET_TITLE_LIST_SUCCEEDED, payload: res.data });
        } else {
          dispatch({ type: GET_TITLE_LIST_FAILED, payload: res.description });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_TITLE_LIST_FAILED, payload: error });
      });
  };
};

export const getDepartmentList = () => {
  return (dispatch) => {
    dispatch({ type: GET_DEPS_LIST_START });
    console.log('Requesting departments list');
    const url = `${apiUrl}/member/register/departments`;
    api
      .GET(url)
      .then((res) => {
        console.log('Response from department list endpoint', res);
        if (res.success) {
          dispatch({ type: GET_DEPS_LIST_SUCCEEDED, payload: res.data });
        } else {
          dispatch({ type: GET_DEPS_LIST_FAILED, payload: res.description });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_DEPS_LIST_FAILED, payload: error });
      });
  };
};

// Get list of active countries
export const getCountryList = () => (dispatch) => {
  console.log('Requesting country list');
  dispatch({ type: GET_COUNTRY_LIST_START });
  const url = `${apiUrl}/member/register/country`;
  return new Promise((resolve) => {
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('Response from country list endpoint', res.data);
          dispatch({ type: GET_COUNTRY_LIST_SUCCESS, payload: res.data });
        }
        resolve(res.data || null);
      })
      .catch(() => {
        dispatch({ type: GET_COUNTRY_LIST_FAILED });
        resolve(null);
      });
  });
};

// GET list of roles
export const getRoleList = () => (dispatch) => {
  const url = `${apiUrl}/member/role`;
  dispatch(fetchRolesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.role) {
        dispatch(fetchRolesRequestSuccess(res.role));
      } else {
        dispatch(fetchRolesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchRolesRequestFailed(error));
    });
};

export const getTZList = () => {
  return (dispatch) => {
    console.log('Requesting TZ list');
    dispatch({ type: GET_TZLIST_START });
    const url = `${apiUrl}/member/register/tzlist`;
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('Response from TZ list endpoint', res.data);
          dispatch({ type: GET_TZLIST_SUCCESS, payload: res.data });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_TZLIST_FAILED });
      });
  };
};

export const getSkills = () => {
  return (dispatch) => {
    dispatch({ type: GET_SKILLS_START });
    const url = `${apiUrl}/member/register/skills`;
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: GET_SKILLS_SUCCESS, payload: res.data });
        } else {
          dispatch({ type: GET_SKILLS_FAILED, payload: res.description });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_SKILLS_FAILED, payload: error.message });
      });
  };
};

export const getIndustries = () => {
  return (dispatch) => {
    dispatch({ type: GET_INDUSTRY_LIST });
    const url = `${apiUrl}/member/register/industries`;
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: GET_INDUSTRY_SUCCESS, payload: res.data });
        } else {
          dispatch({ type: GET_INDUSTRY_ERROR, payload: res.description });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_INDUSTRY_ERROR, payload: error.message });
      });
  };
};

export const getPageSettings = () => (dispatch) => {
  const url = `${apiUrl}/member/page/settings`;
  api
    .GET(url)
    .then((res) => {
      if (res.success) {
        console.log('Response from page settings endpoint', res.data);
        dispatch({ type: GET_PAGE_SETTINGS_SUCCESS, payload: res.data });
      }
    })
    .catch((error) => {
      console.log('failed to load member default page settings', error);
    });
};

export const postPageSettings = (formData) => async (dispatch) => {
  const url = `${apiUrl}/member/page/settings`;
  api
    .POST(url, formData)
    .then((res) => {
      if (res.success && res.data) {
        dispatch({ type: INSERT_PAGE_SETTINGS_SUCCESS, payload: res.data });
      }
    })
    .catch((error) => {
      console.log('failed to create member default page settings', error);
    });
};

export const putPageSettings = (formData) => async (dispatch) => {
  const url = `${apiUrl}/member/page/settings`;
  api
    .PUT(url, formData)
    .then((res) => {
      if (res.success && res.data) {
        dispatch({ type: PUT_PAGE_SETTINGS_SUCCESS, payload: res.data });
      }
    })
    .catch((error) => {
      console.log('failed to update member default page settings', error);
    });
};

// Locations
export const postLocations = (data) => async (dispatch) => {
  const url = `${apiUrl}/member/info/location`;
  dispatch({ type: UPDATE_LOCATIONS_START });

  return api
    .POST(url, data, { 'Content-Type': 'application/json' })
    .then((res) => {
      if (res.success && !!res.data) {
        dispatch({ type: UPDATE_LOCATIONS_SUCCESS, payload: res.data });
        return {
          id: res.id,
          data: res.data,
        };
      } else {
        dispatch({ type: UPDATE_LOCATIONS_FAIL, payload: res.description });
        return false;
      }
    })
    .catch((error) => {
      dispatch({ type: UPDATE_LOCATIONS_FAIL, payload: error.message });
    });
};

export const deleteLocation = (data) => async (dispatch) => {
  const url = `${apiUrl}/member/info/location`;

  api
    .DELETE(url, data, {})
    .then((res) => {
      if (res.success && !!res.data) {
        dispatch({ type: DELETE_LOCATION_SUCCESS, payload: res.data });
      } else {
        dispatch({ type: DELETE_LOCATION_FAIL, payload: res.description });
      }
    })
    .catch((error) => {
      dispatch({ type: DELETE_LOCATION_FAIL, payload: error.message });
    });
};

// Make back-end send SMS
export const postInitVerification = (contact, type) => {
  return (dispatch) => {
    dispatch({ type: CELL_VERIFICATION_INIT });
    console.log('Requesting verification');
    const url = `${apiUrl}/member/register/verification`;
    const formData = new FormData();
    formData.set('contact_type', type); // type is either email or cell
    formData.set('value', contact); // type is either email or cell

    return api
      .POST(url, formData)
      .then((res) => {
        if (res.success) {
          return {
            success: true,
            totpLength: res.totp_digits_count,
            totpLifetimeSeconds: res.totp_lifetime_seconds,
          };
        } else {
          dispatch({
            type: CELL_VERIFICATION_ERROR,
            payload:
              "An error ocurred. Please verify that the cell phone number you've entered is valid.",
          });
          return {
            success: false,
            description:
              "An error ocurred. Please verify that the cell phone number you've entered is valid.",
          };
        }
      })
      .catch((err) => {
        dispatch({ type: CELL_VERIFICATION_ERROR, payload: err });
        return {
          success: false,
          description: err,
        };
      });
  };
};

export const putVerificationData = (type, contact, token) => {
  return (dispatch) => {
    dispatch({ type: CELL_VERIFICATION_ATTEMPT });
    console.log(`Sending token ${token}`);
    const url = `${apiUrl}/member/register/verification`;
    const formData = new FormData();
    formData.set('contact_type', type);
    formData.set('value', contact);
    formData.set('token', token);

    return api
      .PUT(url, formData)
      .then((res) => {
        dispatch({ type: CELL_VERIFICATION_RESPONSE });
        return res.result;
      })
      .catch((err) =>
        dispatch({ type: CELL_VERIFICATION_ERROR, payload: err })
      );
  };
};

export const postOutgoingCallerVerification = (contact_id, phone_number) => {
  return (dispatch) => {
    dispatch({ type: OUTGOING_PHONE_VERIFICATION_INIT });
    console.log('Requesting verification');
    const url = `${apiUrl}/member/register/verify-outgoing-cell`;
    const formData = new FormData();
    formData.set('contact_id', contact_id); // type is either email or cell
    formData.set('phone_number', phone_number);
    return api
      .POST(url, formData)
      .then((res) => {
        if (res.success) {
          return {
            success: true,
            validation_code: res.validation_code,
          };
        } else {
          dispatch({
            type: OUTGOING_PHONE_VERIFICATION_ERROR,
            payload:
              "An error ocurred. Please verify that the cell phone number you've entered is valid.",
          });
          return {
            success: false,
            description:
              "An error ocurred. Please verify that the cell phone number you've entered is valid.",
          };
        }
      })
      .catch((err) => {
        dispatch({ type: OUTGOING_PHONE_VERIFICATION_ERROR, payload: err });
        return {
          success: false,
          description: err,
        };
      });
  };
};

export const getTwilioAccessToken = () => {
  return (dispatch) => {
    const url = `${apiUrl}/twilio/get-token`;
    return api
      .GET(url)
      .then((res) => {
        if (res.success) {
          return {
            success: true,
            token: res.token,
          };
        } else {
          return {
            success: false,
            description:
              "An error ocurred. Please verify that the cell phone number you've entered is valid.",
          };
        }
      })
      .catch((err) => {
        return {
          success: false,
          description: err,
        };
      });
  };
};

// Get terms and Conditions
// We get T&C for all countries early, and then just swap them when the user changes his/her country
export const getTermsAndConditions = () => {
  // return async (dispatch) =>  {
  // dispatch({ type: GET_TERMS_AND_CONDITIONS_START });

  const url = `${apiUrl}/member/register/terms`;

  return api
    .GET(url)
    .then((res) => {
      console.log('Terms', res.data);
      // dispatch({ type: GET_TERMS_AND_CONDITIONS_SUCCESS, payload: res.data });
      return res.data;
    })
    .catch((err) => console.log(err));
  // };
};

// Promo codes
export const getValidatePromoCode = (promoInput) => {
  return (dispatch) => {
    // dispatch({ type: PROMO_CHECK });
    console.log('Checking promo code', promoInput);
    const url = `${apiUrl}/member/register/promo-code`;
    const formData = new FormData();
    formData.set('promoCode', promoInput);
    return api
      .POST(url, formData)
      .then((res) => {
        if (res.success) {
          return {
            success: true,
            description: res.description,
            promo_code_id: res.promo_code_id,
          };
        } else {
          return {
            success: false,
          };
        }
      })
      .catch((err) => err);
  };
};

// Avatar
export const putAvatar = (formData) => {
  return (dispatch) => {
    console.log('Sending avatar to server');
    const url = `${apiUrl}/member/avatar`;
    dispatch({ type: PUT_AVATAR_UPDATE_START });
    return api
      .PUT(url, formData)
      .then((res) => {
        console.log('Acatar response is', res.data);
        dispatch({ type: PUT_AVATAR_UPDATE_SUCCESS, payload: res.data });
      })
      .catch((error) =>
        dispatch({
          type: PUT_AVATAR_UPDATE_FAILED,
        })
      );
  };
};

export const getNotificationsSetting = () => (dispatch) => {
  const url = `${apiUrl}/member/notifications/setting`;
  dispatch(fetchNotificationsSettingRequestStart());
  return new Promise((resolve) => {
    api
      .GET(url)
      .then((res) => {
        if (res && res.success) {
          dispatch(fetchNotificationsSettingRequestSuccess(res.data.data));
          // const payload = setMemberAlert(res, true, res.message);
          // dispatch(setReduxMemberAlert(payload));
          resolve(true);
        } else {
          const payload = setMemberAlert(res, false);
          dispatch(setReduxMemberAlert(payload));
          dispatch(
            fetchNotificationsSettingRequestFailed({ error: res.message })
          );
          resolve(false);
        }
      })
      .catch((error) => {
        const payload = setMemberAlert(error, false);
        dispatch(setReduxMemberAlert(payload));
        dispatch(fetchNotificationsSettingRequestFailed(error));
        resolve(false);
      });
  });
};

export const putNotificationsSetting = (data) => (dispatch) => {
  const url = `${apiUrl}/member/notifications/setting`;
  const dataString = JSON.stringify(data);
  dispatch(updateNotificationsSettingRequestStart());
  return new Promise((resolve) => {
    api
      .PUT(url, dataString, {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        if (res && res.success) {
          dispatch(updateNotificationsSettingRequestSuccess());
          const payload = setMemberAlert(res, true, res.message);
          dispatch(setReduxMemberAlert(payload));
          resolve(true);
        } else {
          const payload = setMemberAlert(res, false);
          dispatch(setReduxMemberAlert(payload));
          dispatch(
            updateNotificationsSettingRequestFailed({ error: res.message })
          );
          resolve(false);
        }
      })
      .catch((error) => {
        const payload = setMemberAlert(error, false);
        dispatch(setReduxMemberAlert(payload));
        dispatch(updateNotificationsSettingRequestFailed(error));
        resolve(false);
      });
  });
};

export const saveFaces = (data) => {
  return (dispatch) => {
    const url = `${apiUrl}/facial-recognition?training-data=true`;
    return api
      .POST(url, data, {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        if (res.success) {
          dispatch({ type: FACE_REGISTERED });
        }
      })
      .catch((error) => {});
  };
};

export const faceMatch = (data) => {
  return (dispatch) => {
    const url = `${apiUrl}/facial-recognition`;
    return api
      .POST(url, data, {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        if (res.success) {
          dispatch({ type: FACE_MATCHED });
        }
      })
      .catch((error) => {});
  };
};

export const loadVideoMails = (searchKey) => {
  return (dispatch) => {
    searchKey = searchKey ? searchKey : '';

    const url = `${apiUrl}/mail/video/all?search_key=${searchKey}`;
    return api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: LOAD_SUCCESS_VIDEO_MAILS, payload: res.data });
        }
      })
      .catch((error) => {});
  };
};

export const sendVideoEmail = (formData, receiver) => (dispatch) => {
  const type = formData.get('type');
  const url = `${apiUrl}/mail/video/${
    type === 'group' ? 'group' : 'contact'
  }/${receiver}`;
  return api
    .POST(url, formData)
    .then((res) => {
      if (res.success) {
        if (type === 'contact') dispatch(contactMessageSuccess());
        else if (type === 'group') dispatch(groupMessageSuccess());
        else dispatch({ type: REPLY_MESSAGE_SENT_SUCCESS });
      } else {
        if (type === 'contact') dispatch(contactMessageFail());
        else if (type === 'group') dispatch(groupMessageFail());
        else dispatch({ type: REPLY_MESSAGE_SENT_FAIL });
      }
    })
    .catch((error) => {
      if (type === 'contact') dispatch(contactMessageFail());
      else if (type === 'group') dispatch(groupMessageFail());
      else dispatch({ type: REPLY_MESSAGE_SENT_FAIL });
    });
};

export const readVideoMail = (mail, member_id, removeCard = true) => {
  return (dispatch) => {
    const url = `${apiUrl}/mail/video/${
      mail.type === 'group' ? 'group' : 'contact'
    }/${mail.type === 'group' ? mail.group_id : member_id}/video_mail/${
      mail.id
    }`;
    dispatch({ type: READ_VIDEO_MAIL, payload: mail.id });
    if (removeCard) {
      dispatch(removeNotificationCard(mail.id));
    }
    return api
      .GET(url)
      .then((res) => {
        if (res.success) {
          // dispatch({ type: LOAD_SUCCESS_VIDEO_MAILS, payload: res.data });
        }
      })
      .catch((error) => {});
  };
};

export const deleteVideoMail = (mail, member_id) => {
  return (dispatch) => {
    const url = `${apiUrl}/mail/video/${
      mail.type === 'group' ? 'group' : 'contact'
    }/${mail.type === 'group' ? mail.group_id : member_id}/video_mail/${
      mail.id
    }`;

    dispatch({ type: DELETE_VIDEO_MAIL, payload: mail.id });
    dispatch(removeNotificationCard(mail.id));
    return api
      .DELETE(url)
      .then((res) => {
        if (res.success) {
          // dispatch({ type: LOAD_SUCCESS_VIDEO_MAILS, payload: res.data });
        }
      })
      .catch((error) => {});
  };
};

export const changeUsername = (username) => {
  return (dispatch) => {
    const url = `${apiUrl}/member/change-username`;
    api
      .PUT(url, JSON.stringify({ username }), {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      })
      .then((res) => {
        if (res && res.success) {
          dispatch({ type: UPDATE_USERNAME_SUCCESS, payload: username });
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Username was updated successfully',
              type: 'success',
            })
          );
        } else {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Something went wrong! Try it later',
              type: 'error',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Something went wrong! Try it later',
            type: 'error',
          })
        );
      });
  };
};

export const fetchRolesRequestStart = () => ({
  type: FETCH_ROLES_START,
});
export const fetchRolesRequestSuccess = (payload) => ({
  type: FETCH_ROLES_SUCCESS,
  payload,
});

export const fetchRolesRequestFailed = (payload) => ({
  type: FETCH_ROLES_FAILED,
  payload,
});

export const fetchNotificationsSettingRequestStart = () => ({
  type: FETCH_NOTIFICATIONS_SETTING_START,
});
export const fetchNotificationsSettingRequestSuccess = (payload) => ({
  type: FETCH_NOTIFICATIONS_SETTING_SUCCESS,
  payload,
});
export const fetchNotificationsSettingRequestFailed = (payload) => ({
  type: FETCH_NOTIFICATIONS_SETTING_FAILED,
  payload,
});
export const updateNotificationsSettingRequestStart = () => ({
  type: PUT_NOTIFICATIONS_SETTING_START,
});
export const updateNotificationsSettingRequestSuccess = (payload) => ({
  type: PUT_NOTIFICATIONS_SETTING_SUCCESS,
  payload,
});
export const updateNotificationsSettingRequestFailed = (payload) => ({
  type: PUT_NOTIFICATIONS_SETTING_FAILED,
  payload,
});

export const setReduxMemberAlert = (payload) => ({
  type: SET_MEMBER_ALERT,
  payload,
});

export const autoLogout = () => ({
  type: AUTO_LOGOUT,
});

export const clearAutoLogout = () => ({
  type: CLEAR_AUTO_LOGOUT,
});
