import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import Group from '../../components/Group';
import GroupFiles from '../FileShare/GroupFiles';
import Modal from '../../components/Modal/modal';

import {
  switchAddMemberForm,
  setInitAlert,
  setReduxGroupAlert,
  deleteGroups,
} from '../../redux/actions/group';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
  },
  closeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionsLeft: {
    width: '33%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actionsRight: {
    width: '66%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    width: '120px',
    margin: theme.spacing(1),
  },
}));

const MemberShipDriveModal = (props) => {
  const { dispatch, show, close, membershipDetail } = props;

  const title = membershipDetail.group_name;
  const classes = useStyles();

  const modalHide = () => {
    dispatch(setInitAlert());
    dispatch(switchAddMemberForm());
    close();
  };

  const ModalContent = () => (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12}>
        <GroupFiles
          key={membershipDetail.group_id}
          groupId={membershipDetail.group_id}
        />
      </Grid>
    </Grid>
  );

  const ModalAction = () => {
    return (
      <div className={classes.closeWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.cancelBtn}
          onClick={() => close()}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onClose={modalHide}
        title={title}
        ModalContent={ModalContent}
        className="group-detail-modal"
        ModalAction={ModalAction}
      />
    </>
  );
};

MemberShipDriveModal.propTypes = {
  dispatch: PropTypes.func,
  membershipDetail: PropTypes.object,
  show: PropTypes.bool,
  close: PropTypes.func,
};

MemberShipDriveModal.defaultProps = {
  dispatch: null,
  membershipDetail: {},
  show: false,
  close: null,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ deleteGroups, setReduxGroupAlert }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberShipDriveModal);
