import { red } from '../colors';
const inviteActivityStyle = (theme) => ({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
  searchRoot: {
    // justifyContent: 'flex-end',
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '250px',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  filerDropDown: {
    marginLeft: theme.spacing(2),
    // position: 'absolute',
    // right: 0,
    // top: 0,
    minWidth: 150,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCellUser: {
    textTransform: 'capitalize',
    width: '20%',
    '& button': {
      textTransform: 'capitalize',
      fontSize: '14px',
      textAlign: 'left',
    },
  },
  tableCellAvatar: {
    marginRight: theme.spacing(1),
  },
  tableCellURL: {
    width: '30%',
  },
  tableCellEvents: {
    width: '30',
  },
  tableCellDate: {
    width: '20%',
  },
  deleteUserIcon: {
    color: red,
  },
  tableCellScreenshot: {
    textAlign: 'center',
  },
});

export default inviteActivityStyle;
