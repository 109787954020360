import React from 'react';

const ColorSelectSample = ({ color, border = null }) => {
  return (
    <div
      style={{
        borderRadius: '50%',
        boxSizing: 'border-box',
        backgroundColor: color,
        border: border,
        height: 18,
        width: 18,
      }}
    ></div>
  );
};

export default ColorSelectSample;
