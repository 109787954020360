import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEmailFolderStyles } from './FolderItemStyles';
import dayjs from 'dayjs';
import SVG from '../../../../../components/Icons/SVG';
import Modal from '../../../../../components/Modal/newDesignModal';

export const FolderItem = ({
  item,
  index,
  handleEditFolder,
  handleRemoveFolder,
  selectedFolder,
  setSelectedFolder,
}) => {
  const classes = useEmailFolderStyles();

  const [actionButtons, setActionButtons] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [folderName, setFolderName] = useState(item.name);

  const [openConfirm, setComfirmOpen] = useState(false);

  const deleteFolder = () => {
    handleRemoveFolder(item.id);
    setComfirmOpen(false);
  };

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <Paper
        className={
          selectedFolder === item.id
            ? classes.selectedfolderItem
            : classes.folderItem
        }
        style={
          item.id === -1 ? { paddingTop: 8, justifyContent: 'center' } : {}
        }
        onMouseOver={(e) => {
          setActionButtons(true);
        }}
        onMouseLeave={(e) => {
          setActionButtons(false);
        }}
        onClick={(e) => {
          if (selectedFolder === item.id) {
            setSelectedFolder(null);
          } else {
            setSelectedFolder(item.id);
          }
        }}
      >
        {item.id === -1 ? (
          <Typography className={classes.folderName}>{item.name}</Typography>
        ) : (
          <>
            <div className={classes.rightSlash} />
            <div className={classes.darkCircle} />
            <div style={{ marginLeft: 10 }}>
              {editMode ? (
                <TextField
                  focused={editMode}
                  value={folderName}
                  onChange={(e) => {
                    setFolderName(e.target.value);
                  }}
                  color="primary"
                  className={classes.folderName}
                />
              ) : (
                <Typography className={classes.folderName}>
                  {folderName}
                </Typography>
              )}

              <Typography className={classes.time}>
                {dayjs(item.time).format('MM/DD/YY hh:mm a')}
              </Typography>
            </div>
            {actionButtons && (
              <Typography className={classes.controlsLayout}>
                <IconButton
                  onClick={(e) => {
                    if (editMode) handleEditFolder(item.id, folderName);
                    setEditMode(!editMode);
                  }}
                  className={classes.darkButton}
                >
                  <SVG name={editMode ? 'check' : 'edit'} size="small" />
                </IconButton>
                {editMode && (
                  <IconButton
                    onClick={(e) => {
                      setFolderName(item.name);
                      setEditMode(false);
                    }}
                    className={classes.darkButton}
                  >
                    <SVG name="cancel" size="small" />
                  </IconButton>
                )}
                <IconButton
                  onClick={(e) => {
                    setComfirmOpen(true);
                  }}
                  className={classes.darkButton}
                >
                  <SVG name="mailTrash" size="small" />
                </IconButton>
              </Typography>
            )}
          </>
        )}
      </Paper>
      <Modal
        open={openConfirm}
        onClose={() => setComfirmOpen(false)}
        title="Confirm"
        maxWidth="sm"
        fullWidth={true}
        ModalContent={() => {
          return (
            <Typography variant="h5" align="center">
              Are you sure you want to delete
              <Typography display="inline" variant="h4">
                {item.name}
              </Typography>{' '}
              folder ?
            </Typography>
          );
        }}
        ModalAction={() => {
          return (
            <>
              <Button
                variant="contained"
                className={classes.deleteButton}
                onClick={(e) => deleteFolder()}
              >
                DELETE
              </Button>
              <Button
                onClick={(e) => setComfirmOpen(false)}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </>
          );
        }}
      />
    </Grid>
  );
};
