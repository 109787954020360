import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from '../Sections/Layout';

import Weekly from './Weekly';

const Billing = (props) => {
  return (
    <Layout {...props}>
      <Switch>
        <Redirect
          from={`${process.env.PUBLIC_URL}/billing`}
          to={`${process.env.PUBLIC_URL}/billing/weekly`}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/billing/weekly`}
          component={Weekly}
        />
      </Switch>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
