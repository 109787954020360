import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import {
  getContactSecurity,
  getGroupSecurity,
} from '../../redux/actions/security';
import SecurityDetailModal from '../../components/Modal/SecurityDetailModal';
import {
  storeSecurityKey,
  enableE2EEncryption,
  startDecrypting,
} from '../../redux/actions/security';

import {
  decryptMessage,
  decryptGroupMessage,
  loadContactMessages,
} from '../../redux/actions/chat';
import { apiUrl } from '../../config/api';

import { AmeraAES } from '../../utils/ameraWebCrypto';
import IOSSwitch from '../IOSSwitch';

const CustomBackdrop = withStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))(Backdrop);

const EncryptionToggle = ({
  type,
  contact_id = undefined,
  group_id = undefined,
  group_leader_id = undefined,
  hideMenu,
}) => {
  const dispatch = useDispatch();

  const decrypting = useSelector(
    // @ts-ignore
    (state) => state.security.decrypting
  );
  const e2eEncryptionEnabled = useSelector(
    // @ts-ignore
    (state) => state.security.e2eEncryptionEnabled
  );
  const chatConversations = useSelector(
    // @ts-ignore
    (state) => state.chat.chatConversations
  );
  const groupConversations = useSelector(
    // @ts-ignore
    (state) => state.chat.groupConversations
  );
  const security = useSelector(
    // @ts-ignore
    (state) => state.security.security
  );
  const member_id = useSelector(
    // @ts-ignore
    (state) => state.member.member.member_id
  );

  const selectedGroupID = useSelector(
    // @ts-ignore
    (state) => state.chat.selectedGroupID
  );

  console.debug(`e2eEncryptionEnabled: ${e2eEncryptionEnabled}`);
  // @ts-ignore
  // const securityContactId = useSelector((store) => store.security.contact_id);

  const [showSecurityModal, setShowSecurityModal] = React.useState(false);

  // disabling this check due to this being triggered when the menu is opened in chat
  // React.useEffect(() => {
  //   if (parseInt(contact_id) !== securityContactId) {
  //     dispatch(enableE2EEncryption(false));
  //   }
  // }, [type, contact_id, securityContactId, dispatch]);

  React.useEffect(() => {
    if (selectedGroupID && group_id && type === 'groupchat') {
      dispatch(getGroupSecurity(group_id));
    }
  }, [selectedGroupID, type, group_id, dispatch]);

  if (!contact_id && !group_id) {
    return <></>;
  }

  const getKey = async (picture_id, pin) => {
    const data = new FormData();
    data.append('storage_id', picture_id);
    data.append('pin', pin);
    const url = `${apiUrl}/keygen/storage`;

    return fetch(url, {
      method: 'POST',
      body: data,
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.error('Error fetching key');
        console.error(err);
        return null;
      });
  };

  const getSecurityAndKey = async (type, contact_id, group_id) => {
    let data = null;
    if (type === 'contact') {
      data = await dispatch(getContactSecurity(contact_id));
    } else {
      data = await dispatch(getGroupSecurity(group_id));
    }
    if (data && data.security_picture_storage_id && data.pin) {
      dispatch(startDecrypting(true));
      const secure = await getKey(data.security_picture_storage_id, data.pin);
      if (secure && secure.hexkey) {
        dispatch(storeSecurityKey(secure.hexkey));
        dispatch(enableE2EEncryption(true));
        if (type === 'contact') {
          const newConversations = await decryptConversations(
            chatConversations,
            contact_id,
            secure.hexkey
          );
          dispatch(decryptMessage(newConversations));
        } else {
          const newConversations = await decryptConversations(
            groupConversations,
            selectedGroupID,
            secure.hexkey
          );
          dispatch(decryptGroupMessage(newConversations));
        }
        dispatch(startDecrypting(false));
        if (hideMenu) hideMenu();
      } else setShowSecurityModal(true);
    } else {
      setShowSecurityModal(true);
    }
  };

  const updateSecurityAndGetKey = async () => {
    await getSecurityAndKey(type, contact_id, group_id);
  };

  const handleEncrypt = async () => {
    if (e2eEncryptionEnabled) {
      await dispatch(enableE2EEncryption(false));
      if (type === 'contact') {
        dispatch(loadContactMessages(contact_id));
      }
      if (hideMenu) hideMenu();
    } else {
      getSecurityAndKey(type, contact_id, group_id);
    }
  };

  const handleCloseSecurityModal = () => {
    setShowSecurityModal(false);
    if (hideMenu) hideMenu();
  };

  const decryptConversations = async (conversations, chatID, key) => {
    const ameraAES = new AmeraAES({key, });
    const newConversations = await Promise.all(
      conversations.map(async (c) => {
        if (c.chatID === chatID) {
          c.messages = await Promise.all(
            c.messages.map(async (m) => {
              try {
                const decrypted = await ameraAES.decrypt(m.messageText, {
                  mode: 'utf8',
                  verbose: false,
                  cipher: 'utf8',
                });
                m.messageText = decrypted;
                m.messageHtml = decrypted;
                if(m.messageFiles) {
                  m.messageFiles = await Promise.all(m.messageFiles.map(async (mf) => {
                    let mime = mf.src.split(',')[0], blob = mf.src.split(',')[1];
                    try {
                      blob = await ameraAES.decrypt(blob, {
                        mode: 'utf8',
                        verbose: false,
                        cipher: 'utf8',
                      });
                    } catch(err) {
                      console.debug("decryptIssue");

                    }
                    mf.src = mime+','+blob;
                    return mf;
                  }));
                }
                return m;
              } catch (e) {
                return m;
              }
            })
          );
        }
        return c;
      })
    );

    return newConversations;
  };

  const handleClose = () => {
    dispatch(startDecrypting(false));
  };

  return (
    <>
      <IOSSwitch
        disabled={
          member_id !== group_leader_id &&
          type === 'groupchat' &&
          (!security || !security.security_picture_storage_id || !security.pin)
            ? true
            : false
        }
        checked={!!e2eEncryptionEnabled ? true : false}
        onChange={handleEncrypt}
      />
      <SecurityDetailModal
        open={showSecurityModal}
        preFetched
        onClose={handleCloseSecurityModal}
        contact_id={contact_id}
        group_id={group_id}
        group_leader_id={group_leader_id}
        type={type === 'contact' ? 'contact' : 'group'}
        updateSecurityAndGetKey={updateSecurityAndGetKey}
      />
      <CustomBackdrop open={decrypting} onClick={handleClose}>
        <CircularProgress color="primary" />
      </CustomBackdrop>
    </>
  );
};

export default EncryptionToggle;
