import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useStyles } from './ModalStyles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const ConfirmationModal = (props) => {
  const classes = useStyles();

  const { confirm, open, closeFn, title, bodyMessage, selectedOption } = props;
  return (
    <Modal open={open} onClose={closeFn}>
      <div className={classes.modal}>
        <div className={classes.modalTop}>
          <div>
            <h6 className={classes.modalTopText}>{title}</h6>
          </div>
          <div onClick={closeFn}>
            <IconButton className={classes.modalTopButton} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />
        <p>{bodyMessage}</p>

        <Grid container direction="row" justify="flex-end">
          <Button
            onClick={closeFn}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <span className={classes.buttonText}>cancel</span>
          </Button>
          <Button
            onClick={() => confirm(selectedOption)}
            className={classes.button}
            variant="contained"
            color="secondary"
          >
            <span className={classes.buttonText}>confirm</span>
          </Button>
        </Grid>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
