export const PAGE_TYPES = {
  contacts: 'Contacts',
  groups: 'Groups',
  calendar: 'Calendar',
  drive: 'Drive',
  mail: 'Mail',
  project: 'Projects',
};

export const PAGE_VIEWS = {
  tile: 'tile',
  table: 'table',
  list: 'list',
  master: 'master',
};

export const DEFAULT_PAGE_SIZES = [25, 50, 75, 100];
