import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

import Layout from '../Sections/Layout';
import StreamingLarge from './streamingLarge';
import StreamingSmall from './streamingSmall';
import Toolbar from './toolbar';
import SettingModal from './setting';
import VideoPlayerModal from './video';
import { getAllSettings } from '../../utils/streaming';
import { openDrawer } from '../../redux/actions/drawer';
import {
  getStreamVideos,
  getStreamTypes,
  getStreamCategories,
} from '../../redux/actions/stream';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';

const useStyles = makeStyles((theme) => ({
  greyButton: {
    background: '#FFFFFF',
    border: '1px solid #5F5F5F',
    borderRadius: 7,
    color: '#5F5F5F',
    marginRight: 20,
    '&:hover': {
      background: '#5F5F5F',
      color: '#FFFFFF',
    },
  },
  button: {
    borderRadius: 7,
    fontSize: 15,
    textTransform: 'capitalize',
    width: 124,
  },
  content: {
    paddingRight: 20,
  },
  w160: {
    width: 160,
  },
}));

const MediaStreaming = (props) => {
  const { pageSettings, streamVideos, streamCategories, streamTypes } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [searchKey, setSearchKey] = React.useState('');
  const [openSettingModal, setOpenSettingModal] = React.useState(false);
  const [openVideoPlayerModal, setOpenVideoPlayerModal] = React.useState(false);
  const [settingType, setSettingType] = React.useState(null);
  const [settingTitle, setSettingTitle] = React.useState('');
  const [vidoeUrl, setVideoUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const streamingPageSettings = pageSettings.find(
    (setting) => setting.page_type === 'Streaming'
  );
  const data =
    streamingPageSettings && streamingPageSettings.extra_settings
      ? streamingPageSettings.extra_settings
      : null;

  const [defaultSettings, setDefaultSettings] = React.useState({});
  const [settings, setSettings] = React.useState({});

  useEffect(() => {
    dispatch(getStreamTypes());
    dispatch(getStreamCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      streamCategories &&
      streamCategories.length &&
      streamTypes &&
      streamTypes.length &&
      !isLoading
    ) {
      const initSettings = getAllSettings(data, streamCategories);
      setDefaultSettings(initSettings);
      setSettings(initSettings);
      setIsLoading(true);
      dispatch(getStreamVideos(initSettings, true));
    }
    // eslint-disable-next-line
  }, [streamCategories, streamTypes]);

  const handleSettings = (id, setting) => {
    setSettings({ ...settings, [id]: +setting });
  };

  const handleSettingClose = () => {
    setOpenSettingModal(false);
    setSettingType(null);
  };

  const handleSettingOpen = (type, title) => {
    setOpenSettingModal(true);
    setSettingType(type);
    setSettingTitle(title);
  };

  const handleVideoPlayerClose = () => {
    setOpenVideoPlayerModal(false);
    setVideoUrl('');
  };

  const handleVideoPlayerOpen = (url) => {
    setOpenVideoPlayerModal(true);
    setVideoUrl(url);
  };

  const buttons = [
    {
      name: 'Scheduler',
      class: [classes.greyButton, classes.button],
      color: 'default',
      onClick: () => {
        history.push('/media-streaming-scheduler');
      },
    },
    {
      name: 'Upload Content',
      class: [classes.greyButton, classes.button, classes.w160],
      color: 'default',
      onClick: () => {
        dispatch(openDrawer(DRAWER_CONTENT_TYPE.uploadcontent, {}));
      },
    },
    {
      name: 'Subscribed',
      class: [classes.greyButton, classes.button],
      color: 'default',
      onClick: () => {},
    },
    {
      name: 'My Watchlist',
      class: [classes.greyButton, classes.button],
      color: 'default',
      onClick: () => {},
    },
    {
      name: 'Broadcast',
      class: [classes.button],
      color: 'primary',
      onClick: () => {
        dispatch(openDrawer(DRAWER_CONTENT_TYPE.broadcast, {}));
      },
    },
  ];

  const search = (event) => {
    setSearchKey(event.target.value);
  };

  return (
    <Layout {...props}>
      <div className={classes.root}>
        <Toolbar searchKey={searchKey} onSearch={search} buttons={buttons} />
        <div className={classes.content}>
          {isLoading &&
            streamCategories
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((category) => {
                const { id, name } = category;

                const filterData =
                  streamVideos && streamVideos.length
                    ? streamVideos.filter((video) => video.category === +id)
                    : [];

                return name === 'Streaming Now' ? (
                  <StreamingLarge
                    id={id}
                    key={id}
                    title={name}
                    handleSettingOpen={handleSettingOpen}
                    defaultStreamType={defaultSettings[id]}
                    handleVideoPlayerOpen={handleVideoPlayerOpen}
                    data={filterData}
                  />
                ) : (
                  <StreamingSmall
                    id={id}
                    key={id}
                    title={name}
                    defaultStreamType={defaultSettings[id]}
                    handleSettingOpen={handleSettingOpen}
                    handleVideoPlayerOpen={handleVideoPlayerOpen}
                    data={filterData}
                  />
                );
              })}
        </div>
      </div>
      {openSettingModal && (
        <SettingModal
          id={settingType}
          title={settingTitle}
          open={openSettingModal}
          onClose={handleSettingClose}
          settings={settings}
          handleSettings={handleSettings}
          defaultStreamType={defaultSettings[settingType]}
          streamingPageSettings={streamingPageSettings}
        />
      )}
      {openVideoPlayerModal && vidoeUrl && (
        <VideoPlayerModal
          open={openVideoPlayerModal}
          onClose={handleVideoPlayerClose}
          vidoeUrl={vidoeUrl}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  pageSettings: state.member.pageSettings,
  streamVideos: state.stream.streamVideos,
  streamCategories: state.stream.streamCategories,
  streamTypes: state.stream.streamTypes,
});

export default connect(mapStateToProps)(MediaStreaming);
