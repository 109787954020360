import React, { useState, useRef, useCallback } from 'react';
import UploadFile from './Modals/UploadFile';
import DeleteFile from './Modals/DeleteFile';
import AddFolder from './Modals/AddFolder';
import FileDetailModal from './Modals/FileDetail/FileDetail';

import {
  ChonkyActions,
  FileBrowser,
  FileList,
  FileToolbar,
  FileContextMenu,
  FileNavbar,
} from 'chonky';
import {
  useFiles,
  useFolderChain,
  downloadFileAction,
  createFolderAction,
  deleteFileAction,
  showInfoAction,
  uploadFileAction,
} from '../../utils/fileshare';

import {
  createGroupFolder,
  deleteGroupNodes,
  putMoveGroupNodes,
  getDownloadFile,
} from '../../redux/actions/fileshare';

import { connect } from 'react-redux';

const GroupMainTree = (props) => {
  const {
    dispatch,
    tree,
    groupId,
    isLeader,
    memberState,
    defaultViewActionId,
    defaultSortActionId,
  } = props;
  const { member_id } = memberState.memberInfo;

  const [currentFolderId, setCurrentFolderId] = useState(tree.rootFolderId);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showAddFolderModal, setShowAddFolderModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const fileBrowserRef = useRef(null);

  const leaderFileActions = [
    createFolderAction,
    uploadFileAction,
    downloadFileAction,
    showInfoAction,
    deleteFileAction,
  ];

  const memberFileActions = [
    createFolderAction,
    uploadFileAction,
    downloadFileAction,
    showInfoAction,
  ];

  const [fileActions, setFileActions] = useState(
    isLeader ? leaderFileActions : memberFileActions
  );

  const handleFolderCreateConfirm = async (folderName) => {
    await dispatch(createGroupFolder(groupId, folderName, currentFolderId));
  };

  const handleDeleteConfirm = async (nodeIds) => {
    await dispatch(deleteGroupNodes(groupId, nodeIds));
  };

  const handleFileAction = useCallback(
    (data) => {
      const { id, state, payload } = data;
      const { selectedFilesForAction } = state;

      if (id === ChonkyActions.OpenFiles.id) {
        if (payload.targetFile.isDir) {
          setCurrentFolderId(payload.targetFile.id);
        }
      } else if (id === uploadFileAction.id) {
        setShowUploadModal(true);
      } else if (id === createFolderAction.id) {
        setShowAddFolderModal(true);
      } else if (id === deleteFileAction.id) {
        let files = selectedFilesForAction;
        if (!isLeader) {
          files = files.filter((file) => file.create_member === member_id);
          if (!files) return false;
        }
        setSelectedFiles(files);
        setShowDeleteModal(true);
      } else if (id === downloadFileAction.id) {
        const { amera_file_url, name } = selectedFilesForAction[0];
        getDownloadFile(amera_file_url, name);
      } else if (id === ChonkyActions.MoveFiles.id) {
        // We don't check duplicates here
        const targetFolderId = payload.destination.id;
        const changes = selectedFilesForAction.map((f) => {
          return {
            node_id: f.id,
            file_id: f.file_id,
            isDir: f.isDir,
            parentId: targetFolderId,
            name: f.name,
            isKeepOriginal: false, // This let us know what to do with the original node that is located where we copied/cut it in the first place
            deleteId: null,
          };
        });
        dispatch(putMoveGroupNodes(groupId, changes));
      } else if (id === showInfoAction.id) {
        setSelectedFiles(selectedFilesForAction);
        setShowDetailModal(true);
      } else if (id === ChonkyActions.OpenFileContextMenu.id) {
        if (!isLeader && payload.triggerFileId) {
          if (
            !selectedFilesForAction.filter(
              (select) =>
                tree.fileMap[payload.triggerFileId].create_member !== member_id
            ).length
          ) {
            setFileActions(leaderFileActions);
          } else {
            setFileActions(memberFileActions);
          }
        }
      }
    },
    [
      dispatch,
      groupId,
      isLeader,
      member_id,
      tree,
      leaderFileActions,
      memberFileActions,
    ]
  );

  return (
    <>
      {selectedFiles[0] ? (
        <FileDetailModal
          show={showDetailModal}
          isGroupLeader={isLeader}
          groupId={groupId}
          close={() => setShowDetailModal(false)}
          selectedFile={selectedFiles[0]}
        />
      ) : null}
      <AddFolder
        key={`group_${groupId}`}
        show={showAddFolderModal}
        fileTree={tree}
        close={() => setShowAddFolderModal(false)}
        currentFolderId={currentFolderId}
        onNameConfirm={handleFolderCreateConfirm}
      />

      <UploadFile
        show={showUploadModal}
        fileTree={tree}
        path={`/drive/group/${groupId}/files`}
        currentFolderId={currentFolderId}
        close={() => setShowUploadModal(false)}
      />

      <DeleteFile
        show={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        files={selectedFiles}
        onDeleteConfirm={handleDeleteConfirm}
      />
      <FileBrowser
        ref={fileBrowserRef}
        files={useFiles(currentFolderId, tree)}
        enableDragAndDrop
        disableDefaultFileActions={[
          ChonkyActions.ClearSelection.id,
          ChonkyActions.SelectAllFiles.id,
          ChonkyActions.OpenSelection.id,
          ChonkyActions.ToggleHiddenFiles.id,
        ]}
        defaultSortActionId={defaultSortActionId}
        defaultFileViewActionId={defaultViewActionId}
        fileActions={fileActions}
        folderChain={useFolderChain(currentFolderId, tree)}
        onFileAction={handleFileAction}
      >
        <FileNavbar />
        <FileToolbar />
        <FileList />
        <FileContextMenu />
      </FileBrowser>
    </>
  );
};

const mapStateToProps = (state) => ({
  memberState: state.member,
});

export default connect(mapStateToProps)(GroupMainTree);
