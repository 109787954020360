import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../Card';
import CardButtons from '../CardButtons';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {
  displayableFullName,
  getContactLocation,
  getContactDevice,
} from '../../../utils/contact';
import { defaultDateTimeZone } from '../../../utils/calendar';
import { acceptInvitation } from '../../../redux/actions/event';
import {
  authorizeMembers,
  unAuthorizeMembers,
  subscribeMembers,
} from '../../../redux/actions/chat';
import { setSnackbarData } from '../../../redux/actions/snackbar';
import LabeledValue from '../../LabeledValue';
import { useCardStyles } from './CardStyle';
import { Strophe } from 'strophe.js';
// import BorderLinearProgress from '../../components/BorderLinearProgressBar';

// import SecurityDetailModal from '../../pages/Contact/SecurityDetailModal';

const ContactCard = ({ data, type, messages, formatMessage }) => {
  const classes = useCardStyles();
  const dispatch = useDispatch();

  const connectionStatus = useSelector((s) => s.chat.connectionStatus);

  // const [isShowSecurityModal, setShowSecurityModal] = useState(false);

  const title = formatMessage(messages.contactTitle);
  const name = !!data.first_name ? `${displayableFullName(data)}` : null;
  const subject = `${name} added you to their contact list.`;

  let home_location = getContactLocation(data, 'home');
  if (home_location && !home_location.city) {
    home_location = null;
  }

  //Prepare member address
  const memberAddress = home_location
    ? `${home_location.city}, ${home_location.state}`
    : '';

  const invite_date_time =
    (data.create_date && defaultDateTimeZone(data.create_date)) || '';

  const handleResponse = async (status) => {
    const result = await dispatch(
      acceptInvitation(data.create_user_id, status)
    );
    const accept_decline = status === 'active' ? 'accepted' : 'declined';
    if (result) {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Successfully ${accept_decline} request`,
          type: 'success',
        })
      );
      if (accept_decline === 'accepted') {
        // subscribed contact for presence update
        dispatch(authorizeMembers([{ id: data.create_user_id, nick: name }]));
        // also request back him/her for presence update
        dispatch(subscribeMembers([{ id: data.create_user_id, nick: name }]));
      } else {
        dispatch(unAuthorizeMembers([{ id: data.create_user_id, nick: name }]));
      }
    } else {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Request failed to be ${accept_decline}`,
          type: 'error',
        })
      );
    }
  };

  // const showContactSecurity = () => {
  //   setShowSecurityModal(true);
  // };
  // const handleCloseSecurityModal = () => {
  //   setShowSecurityModal(false);
  // };

  const action = <></>;

  const avatar = {
    first_name: data.first_name,
    last_name: data.last_name,
    url: data.amera_avatar_url,
  };

  const buttons = (
    <CardButtons
      okDisabled={connectionStatus !== Strophe.Status.CONNECTED}
      cancelDisabled={connectionStatus !== Strophe.Status.CONNECTED}
      type={type}
      cancelText={formatMessage(messages.decline)}
      okText={formatMessage(messages.accept)}
      cancelHandle={() => handleResponse('declined')}
      okHandle={() => handleResponse('active')}
    />
  );

  const content = (
    <div className="contact-section">
      <List className={classes.contentSecondary}>
        {/* <ListItem className={classes.securityPercentageContainer}>
          <div>
            <BorderLinearProgress
              variant="determinate"
              value={data?.security_exchange_option ? data.security_exchange_option : 0}
              onClick={() => showContactSecurity()}
            />
            <p className={classes.securityPercentage}>
              {data?.security_exchange_option ? data.security_exchange_option : 0}%
            </p>
            <p
              className={classes.securityLabel}
              onClick={() => showContactSecurity()}
            >
              Security Level
            </p>
          </div>
        </ListItem> */}
        <ListItem>
          <div className={classes.infoTitle}>
            <label>{formatMessage(messages.contactDetails)}:</label>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.info}>
            <label>{formatMessage(messages.company)}:</label>
            <span>&nbsp; {data.company || 'Not Available'}</span>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.info}>
            <label>{formatMessage(messages.department)}:</label>
            <span>&nbsp; {data.department_name || 'Not Available'}</span>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.info}>
            <label>{formatMessage(messages.jobTitle)}:</label>
            <span>&nbsp; {data.job_title || 'Not Available'}</span>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.info}>
            <label>{formatMessage(messages.address)}:</label>
            <span>&nbsp; {memberAddress || 'Not Available'}</span>
          </div>
        </ListItem>
        <ListItem>
          <div className={classes.info}>
            <label>{formatMessage(messages.inviteDate)}:</label>
            <span>&nbsp; {invite_date_time}</span>
          </div>
        </ListItem>
      </List>

      <CardContent className={`${classes.contactDetails} contact-card_content`}>
        <LabeledValue
          label="Cell phone"
          value={getContactDevice(data, 'Cell phone') || 'Not Available'}
        />
        <LabeledValue
          label="Office phone"
          value={getContactDevice(data, 'Office phone') || 'Not Available'}
        />

        {data.email && data.personal_email ? (
          <FormControl className="contact-email-list">
            <Select
              value={data.email}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={data.email}>{data.email}</MenuItem>
              <MenuItem value={data.personal_email}>
                {data.personal_email}
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          <LabeledValue label="Office email" value={data.email} />
        )}
      </CardContent>
    </div>
  );

  return (
    <>
      <Card
        title={title}
        action={action}
        avatar={avatar}
        subTitle={name}
        subHeader={subject}
        content={content}
        buttons={buttons}
      />
      {/* <SecurityDetailModal
        open={isShowSecurityModal}
        onClose={() => handleCloseSecurityModal()}
        contact={data}
      /> */}
    </>
  );
};

export default ContactCard;
