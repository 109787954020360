import React from 'react';
import Grid from '@material-ui/core/Grid';
import ContactPreview from './ContactPreview';
import InviteStatusChip from './InviteStatusChip';
import MessageIcon from '@material-ui/icons/Message';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const InviteLine = (props) => {
  const { contactData, inviteData, onSendClick, isSMSSuccess } = props;
  return (
    <Grid container alignItems="center">
      <Grid item xs={!!onSendClick ? 8 : 9}>
        <ContactPreview
          contactData={{
            ...contactData,
            member_id: contactData.invite_member_id,
          }}
          isShowAvatar
        />
      </Grid>
      <Grid item xs={3}>
        <InviteStatusChip inviteData={inviteData} />
      </Grid>
      {!!onSendClick && (
        <Grid item xs={1}>
          <Tooltip
            title={
              isSMSSuccess
                ? 'Resend SMS with driving directions'
                : 'Send SMS with driving directions'
            }
          >
            <IconButton
              onClick={() => onSendClick([contactData.invite_member_id])}
            >
              <MessageIcon color={isSMSSuccess ? 'primary' : 'action'} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default InviteLine;
