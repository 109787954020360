import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import VideoEmail from '../VideoEmail';

const VideoEmailModal = (props) => {
  const { open, mail_id, onClose } = props;
  const [mediaType, setMediaType] = useState('Video');

  const title = useMemo(() => {
    return mediaType === 'audio' ? 'Audio Mail' : 'Video Mail';
  }, [mediaType]);

  const closeModal = () => {
    onClose();
  };

  const ModalContent = () => (
    <VideoEmail
      mail_id={mail_id}
      closeModal={onClose}
      setMediaType={setMediaType}
    />
  );

  const ModalAction = () => <div />;

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        maxWidth="lg"
        disableBackdropClick={true}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </>
  );
};

VideoEmailModal.propTypes = {
  open: PropTypes.bool,
  mail_id: PropTypes.number,
  onClose: PropTypes.func,
};

export default VideoEmailModal;
