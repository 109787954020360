import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  projectEditOptions,
  projectEditOptionKeys,
} from '../../../utils/project';
import { useHistory } from 'react-router-dom';
//Components
import ConfirmationModal from '../ProjectDetails/Modals/ConfirmationModal';

//Actions
import { ProjectsApi } from '../../../redux/actions/project';

const EditButton = ({ project }) => {
  // const editProjectOptions = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (projectStep) => {
    history.push({
      pathname: `${projectStep.path}${project.project_id}`,
      search: '?mode=EDIT',
    });
    handleClose();
  };

  function handleConfirm(selectedOption) {
    switch (selectedOption.key) {
      case projectEditOptionKeys.softDeleteProject:
        // action to delete
        dispatch(ProjectsApi.softDeleteProject(project.project_id));
        setOpenConfirmationModal(false);
        break;
      case projectEditOptionKeys.suspend:
        // action to suspend
        dispatch(ProjectsApi.suspendProject(project.project_id));
        setOpenConfirmationModal(false);
        break;
      case projectEditOptionKeys.restore:
        // action to retore
        dispatch(ProjectsApi.restoreProject(project.project_id));
        setOpenConfirmationModal(false);
        break;

      default:
        // edit project steps
        break;
    }
    // dispatch(ProjectsApi.softDeleteProject(project.project_id));
    setOpenConfirmationModal(false);
  }

  const handleEditOptionClick = (option) => {
    setOpenConfirmationModal(true);
    switch (option.key) {
      case projectEditOptionKeys.softDeleteProject:
        // action to delete

        setSelectedOption(option);
        setOpenConfirmationModal(true);
        break;
      case projectEditOptionKeys.suspend:
        // action to suspend
        setSelectedOption(option);
        setOpenConfirmationModal(true);

        break;
      case projectEditOptionKeys.restore:
        // action to retore
        setSelectedOption(option);
        setOpenConfirmationModal(true);
        break;

      default:
        // edit project steps
        handleMenuClick(option);
        break;
    }
  };

  return (
    <div>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="split button"
      >
        <Button color="primary" onClick={handleClick}>
          Edit
        </Button>
        <Button color="primary" size="small" onClick={handleClick}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {projectEditOptions.map((o) => {
          return (
            <MenuItem
              key={o.name}
              onClick={() => {
                handleEditOptionClick(o);
              }}
            >
              {o.name}
            </MenuItem>
          );
        })}
      </Menu>
      <ConfirmationModal
        title={`${selectedOption && selectedOption.name} "${
          project.project_title
        }"`}
        confirm={handleConfirm}
        selectedOption={selectedOption}
        open={openConfirmationModal}
        bodyMessage={`Are you sure you want to ${
          selectedOption && selectedOption.name.toLowerCase()
        } "${project.project_title}"`}
        closeFn={() => {
          setOpenConfirmationModal(false);
        }}
      />
    </div>
  );
};

export default EditButton;
