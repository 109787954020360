import { Button, Grid, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { connect, useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal/newDesignModal';
import { ProgressBar } from '../../../../components/ProgressBar';
import { ApiEmailService } from '../../../../config/apiService';
import { useDebounce } from '../../../../hooks/useDebounce';
import { getCompose } from '../../../../redux/actions/event';
import { setSnackbarData } from '../../../../redux/actions/snackbar';
import {
  checkFileDuplicates,
  prepareToUploadAttach,
} from '../../../../utils/fileshare';
import { AttachedFiles } from './AttachmentList';
import { useStyles } from './Compose.style';
import CustomComposeEditor from './CustomComposeEditor';
import { FolderSelection } from './FolderSelection';
import { Recipients } from './RecipientsSelection';

const Compose = ({
  open,
  initialComposeState = {},
  data,
  targetFolder,
  member,
  onClose,
}) => {
  const [selectedFolder, setSelectedFolder] = useState(
    initialComposeState.folder_id ? initialComposeState.folder_id : ''
  );
  if (initialComposeState.folder_id) delete initialComposeState.folder_id;
  const classes = useStyles();
  const dispatch = useDispatch();
  const composeInitialState = {
    subject: '',
    body: '',
    receivers: [],
    cc: [],
    bcc: [],
    mail_id: undefined,
    ...initialComposeState,
  };
  const [composeDataState, setComposeData] = useState(composeInitialState);
  const [draftInitialized, setDraftInitialized] = useState(false);
  const [folders, setFolders] = useState([]);

  const getFolders = async (newSelected) => {
    const data = await ApiEmailService.getEmails('getFolders', []);
    if (newSelected)
      setSelectedFolder(data.find((el) => el.name === newSelected).id);
    setFolders([...data]);
  };

  const createFolder = async (folderName) => {
    const res = await ApiEmailService.postEmail('postFolder', {
      folder_name: folderName,
    });
    if (res.success) await getFolders(folderName);
  };

  useEffect(() => {
    let cleanup = false;
    if (!cleanup) {
      getFolders();
    }
    return () => (cleanup = true);
  }, []);

  const [openConfirm, setConfirmOpen] = useState(false);

  const isReadyToSend = () => {
    let email = { ...composeDataState };
    delete email.cc;
    delete email.bcc;
    delete email.body;
    delete email.reply_id;
    delete email.folder_id;

    const values = Object.values(email);
    const missingValues = values.find((el) => !el || el.length === 0);
    return !draftInitialized && missingValues !== undefined;
  };

  const debouncedSearchTerm = useDebounce(composeDataState, 3000);

  useEffect(() => {
    const updateDraft = async () => {
      try {
        const result = await createDraftEmail(debouncedSearchTerm);
        console.debug(`Draft result: ${result}`);
        setDraftInitialized(true);
      } catch (e) {
        console.error('Draft unable to initialize');
        console.error(e);
      }
    };
    updateDraft();
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const getDraftId = async () => {
    const res = await ApiEmailService.postEmail('postDraft', {
      subject: '',
      body: '',
      receivers: null,
      reply_id: composeDataState.reply_id,
    });
    if (res.success) {
      setComposeData({
        ...composeDataState,
        mail_id: res.draft_id,
      });
    }
    return res;
  };

  const saveDraft = async (data) => {
    if (data.subject === '' && data.body === '' && data.receivers.length === 0)
      return;
    return ApiEmailService.postEmail('postDraft', {
      ...data,
      folder_id: selectedFolder !== '' ? selectedFolder : -1,
      receivers: {
        amera: [
          ...composeDataState.receivers.map((el) => el.contact_member_id),
        ],
        external: [],
      },
      cc: {
        amera: [...composeDataState.cc.map((el) => el.contact_member_id)],
        external: [],
      },
      bcc: {
        amera: [...composeDataState.bcc.map((el) => el.contact_member_id)],
        external: [],
      },
    });
  };

  const createDraftEmail = async (email) => {
    if (!email.mail_id) {
      return getDraftId();
    } else {
      return saveDraft(composeDataState);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState(
    initialComposeState.attachments ? initialComposeState.attachments : []
  );
  const [duplicateKeys, setDuplicateKeys] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressSwow, setUploadProgressShow] = useState(false);

  const handleChange = (prop) => (event) => {
    setComposeData({ ...composeDataState, [prop]: event.target.value });
  };

  const handleChangebd = (html) => {
    setComposeData({
      ...composeDataState,
      body: html !== '<p><br></p>' ? html : '',
    });
  };

  const deleteDraftMail = (email) => {
    ApiEmailService.deleteEmail('deleteDraftedEmail', email.mail_id);
  };

  const resetData = async (save = true) => {
    if (
      composeDataState.subject === '' &&
      composeDataState.body === '' &&
      composeDataState.receivers.length === 0
    ) {
      deleteDraftMail({ ...composeDataState });
    } else {
      if (save) {
        await saveDraft({ ...composeDataState });
      }
    }
    setComposeData({
      subject: '',
      body: '',
      receivers: [],
      cc: [],
      bcc: [],
      reply_id: undefined,
      mail_id: undefined,
    });
    setSelectedFiles([]);
    if (onClose) {
      onClose();
    }
  };

  const sendMail = async () => {
    const res = await ApiEmailService.postEmail('postEmail', {
      ...composeDataState,
      folder_id: selectedFolder !== '' ? selectedFolder : -1,
      receivers: {
        amera: [
          ...composeDataState.receivers.map((el) => el.contact_member_id),
        ],
        external: [],
      },
      cc: {
        amera: [...composeDataState.cc.map((el) => el.contact_member_id)],
        external: [],
      },
      bcc: {
        amera: [...composeDataState.bcc.map((el) => el.contact_member_id)],
        external: [],
      },
    });
    if (res.success) {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Successfully sent email',
          type: 'success',
        })
      );
    }
    await resetData(false);
  };

  const attachFile = async (formData) => {
    setUploadProgressShow(true);
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      },
    };
    const res = await ApiEmailService.uploadAttachment(formData, config).catch(
      (error) => {
        let message = error.message;
        dispatch(
          setSnackbarData({
            open: true,
            message,
            type: 'error',
          })
        );
        return { data: null };
      }
    );
    setUploadProgressShow(false);
    setUploadProgress(0);
    return res;
  };

  const upload = async (files) => {
    let formData;
    if (!composeDataState.mail_id) {
      let res = await getDraftId();
      formData = prepareToUploadAttach(
        files,
        member,
        res.draft_id,
        duplicateKeys,
        targetFolder
      );
    } else {
      formData = prepareToUploadAttach(
        files,
        member,
        composeDataState.mail_id,
        duplicateKeys,
        targetFolder
      );
    }
    const { data } = await attachFile(formData);
    if (!data) return;
    files.file_id = data.file_id;
    setSelectedFiles([...selectedFiles, files]);
  };

  const onChangeHandler = async (event) => {
    // We will check if files have duplicate keys here using data from store.
    if (data && data.length > 0) {
      setDuplicateKeys(
        checkFileDuplicates(event.target.files, data, targetFolder)
      );
    }
    upload(event.target.files);
  };

  const ModalContent = () => {
    return (
      <>
        <div className={classes.composeEntety}>
          <Grid container direction="row">
            <Recipients
              setSelects={(value) =>
                setComposeData({ ...composeDataState, receivers: [...value] })
              }
              recips={composeDataState.receivers}
              cc={composeDataState.cc}
              bcc={composeDataState.bcc}
              setSecretRecips={(control, value) => {
                setComposeData({ ...composeDataState, [control]: value });
              }}
            />
            <Grid item xs={6}>
              <FolderSelection
                folders={folders}
                createFolder={createFolder}
                selected={selectedFolder}
                setSelected={setSelectedFolder}
              />
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <TextField
              onChange={handleChange('subject')}
              disabled={!!composeDataState.reply_id}
              value={composeDataState.subject}
              className={classes.title}
              id="outlined-required"
              variant="outlined"
              placeholder="Subject"
              size="small"
              inputProps={{ 'aria-label': 'description' }}
            />
          </Grid>
          <CustomComposeEditor
            className={classes.TextField}
            multiline
            rowsMax={20}
            value={composeDataState.body}
            handleChangebd={handleChangebd}
            placeholder={composeDataState.reply_id ? 'Type something...' : ''}
          />
          <AttachedFiles
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            mail_id={composeDataState.mail_id}
          />
          {uploadProgressSwow ? <ProgressBar value={uploadProgress} /> : null}
        </div>
      </>
    );
  };

  const ModalAction = () => {
    return (
      <div className={classes.btnContainer}>
        <div>
          <label htmlFor="upload-photo">
            <input
              style={{ display: 'none' }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              // multiple="multiple"
              onChange={onChangeHandler}
            />
            <IconButton
              component="span"
              className={classes.btnicon}
              aria-label="format"
            >
              <AttachFileIcon style={{ fontSize: 20 }} />
            </IconButton>
          </label>
        </div>
        <Button
          disabled={isReadyToSend()}
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          onClick={async () => {
            await sendMail();
            if (onClose) {
              onClose();
            } else {
              dispatch(getCompose(false));
            }
          }}
          className={classes.btnbg}
        >
          send
        </Button>
      </div>
    );
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          resetData();
        }}
        title={composeDataState.reply_id ? 'Reply' : 'Compose New Message'}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        maxWidth="md"
        fullWidth={true}
        onBackdropClick={(e) => {
          e.preventDefault();
          setConfirmOpen(true);
        }}
      />
      {!openConfirm ? null : (
        <Modal
          open={true}
          onClose={() => setConfirmOpen(false)}
          title="Confirm"
          maxWidth="sm"
          fullWidth={true}
          ModalContent={() => {
            return (
              <Typography variant="h6" align="center">
                Are you sure you want to close this Compose request?
              </Typography>
            );
          }}
          ModalAction={() => {
            return (
              <>
                <Button
                  variant="contained"
                  className={classes.deleteButton}
                  onClick={(e) => {
                    resetData();
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={(e) => setConfirmOpen(false)}
                  variant="contained"
                  color="primary"
                >
                  Cancel
                </Button>
              </>
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isCreateLoading: state.fileshare.createLoading,
  isLoadingEncrypt: state.fileshare.encryptLoading,
  data: state.fileshare.fileList,
  member: state.member.member,
});

export default connect(mapStateToProps)(Compose);
