import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({}));

const AmeraSelect = (props) => {
  const {
    label,
    value,
    onChange,
    error,
    children,
    required,
    variant = 'outlined',
    disabled = false,
    labelWidth = 150,
    multiple = false,
    onOpen = () => null,
    fullWidth = true,
  } = props;

  const classes = useStyles();
  return (
    <FormControl
      //   margin="dense"
      fullWidth={fullWidth}
      error={!!error}
      variant={variant}
      className={`${classes.formControl} amera-select`}
      required={required}
    >
      <InputLabel htmlFor={`${label}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        variant={variant}
        // fullWidth
        inputProps={{
          name: label,
          id: `${label}-select-label`,
        }}
        labelWidth={labelWidth}
        multiple={multiple}
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        disabled={disabled}
      >
        {children}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default AmeraSelect;
