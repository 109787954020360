import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const ClipBoard = (props) => {
  const { isOpen, onClose, clipboard } = props;

  const content = clipboard.map((f) => (
    <ListItem key={f.id}>
      <ListItemIcon>
        {f.isDir ? <FolderIcon /> : <InsertDriveFileIcon />}
      </ListItemIcon>
      {f.name}
    </ListItem>
  ));
  return (
    <Snackbar
      open={isOpen}
      onClose={onClose}
      //   autoHideDuration={4000}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      message={
        <div className="clipboard-content">
          <h6>Clipboard</h6>
          <List dense>{content}</List>
        </div>
      }
    />
  );
};

export default ClipBoard;
