import React from 'react';
import Rating from '@material-ui/lab/Rating';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { withStyles } from '@material-ui/core/styles';

const securityOptions = {
  // The new API now sends security options like 0,25,50,75,100
  100: 4,
  75: 3,
  50: 2,
  25: 1,
  0: 0,
};

const StyledRating = withStyles({
  iconFilled: {
    color: 'gray',
  },
})(Rating);

const GroupSecurityRating = ({ groupExchangeOption }) => {
  return (
    <StyledRating
      readOnly
      size="small"
      icon={<FiberManualRecordIcon fontSize="small" />}
      max={4}
      value={securityOptions[groupExchangeOption]}
      // value={2}
    />
  );
};

export default GroupSecurityRating;
