import React from 'react';

const StarredIcon = ({ size }) => {
  const width = size === 'big' ? '16.944' : '13';
  const height = size === 'big' ? '16.944' : '13';
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.621 24.961"
    >
      <path
        id="favourites"
        d="M68.135,75.572,62.8,80.191a.844.844,0,0,0-.286.732l.78,7.014a.845.845,0,0,1-1.277.816l-6.042-3.647a.846.846,0,0,0-.785-.046l-6.43,2.91a.845.845,0,0,1-1.172-.962l1.6-6.874a.848.848,0,0,0-.2-.761l-4.754-5.215a.845.845,0,0,1,.552-1.412l7.032-.6a.844.844,0,0,0,.663-.424l3.492-6.133a.845.845,0,0,1,1.513.09l2.745,6.5a.844.844,0,0,0,.608.5l6.911,1.426A.845.845,0,0,1,68.135,75.572Z"
        transform="translate(-47.364 -61.492) rotate(-3)"
      />
    </svg>
  );
};
export default StarredIcon;
