import { without } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  GET_MEMBER_EVENTS,
  FETCH_ACTIVITIES_START,
  UPDATE_ACTIVITIES,
  FETCH_ACTIVITIES_FAILED,
  GET_COMPOSE,
  INCOMING_CALL,
  ANSWER_CALL,
  CALL_REPLY,
  GROUP_CALL_REPLY,
  SET_REPLY_MESSAGE,
  SET_CALL_GROUP_ID,
  GET_UPCOMING_EVENTS_FAILED,
  GET_UPCOMING_EVENTS_SUCCEEDED,
  GET_EVENT_INVITATIONS_FAILED,
  GET_EVENT_INVITATIONS_SUCCEEDED,
  REMOVE_NEW_VIDEO_MAIL_NOTIFICATION,
  SET_TWILIO_CALL,
  SET_TWILIO_DEVICE,
  SET_TWILIO_CONNECTION,
  SET_TWILIO_VERIFICATION_MODAL_STATUS,
  OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL
  // GET_OTHER_INVITATIONS_SUCCEEDED,
} from '../actionTypes/event';

import {
  APP_RESET
} from '../actionTypes/app';

// The initial state of the App
export const initialState = {
  compose: false,
  memberEvents: [],
  //   notifications: [
  //     { id: 0, type: 'sessions', data: [] },
  //     { id: 1, type: 'alerts', data: [] },
  //     { id: 2, type: 'mails', data: [] },
  //     { id: 3, type: 'activities', data: [] },
  //     { id: 4, type: 'invitations', data: [] },
  //   ],
  incomingCalls: [
    //FIXME: Dummy events
    // {
    //   event_name: 'Call from Taylor User',
    //   type: 'person',
    //   call_id: 'TUff0r0001-AT04400004',
    //   group_name: 'AMERA IOT',
    //   call_url: 'https://conference.ameraiot.com/TU00000001-AT00000002',
    //   participants: ['Taylor User'],
    //   caller_id: 3,
    //   caller_name: 'Raul Becerra',
    //   start_time: 1600866775071,
    // },
    // {
    //   event_name: 'Call from Taylor User',
    //   type: 'group',
    //   call_id: 'TUff0r0001-AT04400004',
    //   group_name: 'AMERA IOT',
    //   call_url: 'https://conference.ameraiot.com/TU00000001-AT00000002',
    //   participants: ['Taylor User'],
    //   caller_id: 3,
    //   caller_name: 'Raul Becerra',
    //   start_time: 1600866775076,
    // },
  ],
  callReply: null,
  groupCallReplies: [],
  replyMessage: null,
  loading: false,
  call_group_id: null,
  upcomingEvents: [],
  invitations: [],
  callee: '',
  twilioDevice: null,
  twilioConnection: null,
  twilioVerificationModalStatus: false,
  twilioVerificationModalData: null
  // otherInvitations: [],
};

const event = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case GET_MEMBER_EVENTS:
      return {
        ...state,
        memberEvents: action.payload,
      };

    case FETCH_ACTIVITIES_START:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ACTIVITIES:
      const index = state.notifications.findIndex(
        (notification) => notification.type === action.payload.type
      );
      const newArray = [...state.notifications];
      newArray[index].data = action.payload.data;
      return {
        ...state,
        notifications: newArray,
        loading: false,
      };
    case FETCH_ACTIVITIES_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_UPCOMING_EVENTS_SUCCEEDED:
      return {
        ...state,
        upcomingEvents: action.payload,
        loading: false,
      };
    case GET_UPCOMING_EVENTS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    // case GET_OTHER_INVITATIONS_SUCCEEDED:
    //   return {
    //     ...state,
    //     otherInvitations: action.payload,
    //     loading: false,
    //   };
    case GET_EVENT_INVITATIONS_SUCCEEDED:
      return {
        ...state,
        invitations: action.payload,
        loading: false,
      };
    case GET_EVENT_INVITATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_COMPOSE:
      return {
        ...state,
        compose: action.payload,
      };
    case INCOMING_CALL:
      return {
        ...state,
        incomingCalls: [action.payload], //.concat(state.incomingCalls),
        // incomingCalls: action.payload,
      };
    case ANSWER_CALL:
      return {
        ...state,
        incomingCalls: without(state.incomingCalls, action.payload),
      };
    case CALL_REPLY:
      return {
        ...state,
        callReply: action.payload,
      };
    case GROUP_CALL_REPLY:
      return {
        ...state,
        groupCallReplies: action.payload,
      };
    case SET_REPLY_MESSAGE:
      return {
        ...state,
        replyMessage: action.payload,
      };
    case LOCATION_CHANGE:
      return state;
    case SET_CALL_GROUP_ID:
      return {
        ...state,
        call_group_id: action.payload,
      };
    case REMOVE_NEW_VIDEO_MAIL_NOTIFICATION:
      const invitations = state.invitations.filter((invitation) => {
        if (invitation.invitation_type !== 'media_mail') return true;
        if (invitation && invitation.id === action.payload) {
          return false;
        } else return true;
      });
      return {
        ...state,
        invitations: invitations,
      };
    case SET_TWILIO_CALL:
      return {
        ...state,
        callee: action.payload,
      };
    case SET_TWILIO_DEVICE:
      return {
        ...state,
        twilioDevice: action.payload,
      };
    case SET_TWILIO_CONNECTION:
      return {
        ...state,
        twilioConnection: action.payload,
      };
    case SET_TWILIO_VERIFICATION_MODAL_STATUS:
      return {
        ...state,
        twilioVerificationModalStatus: action.payload
      };
    case OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL:
      return {
        ...state,
        twilioVerificationModalData: action.payload
      }
    default:
      return state;
  }
};

export default event;
