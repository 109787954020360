import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import StarIcon from '@material-ui/icons/Star';
import { parseISO, format } from 'date-fns';
import { capitalize } from 'lodash';

const SharedWithGroupsList = ({
  sharedWithGroups,
  memberId,
  handleUnshareClick,
  handleGroupClick,
}) => {
  return sharedWithGroups.map((g) => {
    const {
      share_id,
      shared_date,
      group_id,
      group_name,
      //   exchange_option,
      leader_id,
      leader_first_name,
      leader_last_name,
      leader_company,
      job_title,
      department,
    } = g;

    const isImLeader = memberId === leader_id;

    return (
      <ListItem key={share_id} className="group-item">
        <ListItemText>
          <div className="group-tab">
            <div className="group-name-line">
              <span
                className="unit link"
                onClick={() => handleGroupClick(group_id)}
              >
                <span>{isImLeader ? <StarIcon color="primary" /> : null}</span>
                <span>{group_name}</span>
              </span>
              <span className="unit"> - </span>
              <span className="unit">{`Date shared: ${format(
                parseISO(shared_date),
                'MM/dd/yyyy'
              )}`}</span>
            </div>
            {!isImLeader && (
              <div className="leader-line">
                {`Leaded by ${capitalize(leader_first_name)} ${capitalize(
                  leader_last_name
                )}, ${job_title} at ${department}, ${leader_company}`}
              </div>
            )}
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          <Button onClick={() => handleUnshareClick(share_id)}>Unshare</Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
};

export default SharedWithGroupsList;
