import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  IconButton,
  InputBase,
  Tooltip,
} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import TableChartSharpIcon from '@material-ui/icons/TableChartSharp';
import MailOutlineIcon from '@material-ui/icons/Email';
import ViewListIcon from '@material-ui/icons/ViewList';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';

import { debounce, find } from 'lodash';
import {
  useEmailPageStyles,
  StyledToggleButton,
} from './SharedComponents/EmaliPageWrapper/EmailPageStyles';

import { SnackBarMessage } from '../../components/Modal/CustomSnackBar';
import { setBadgeCount } from '../../redux/actions/mail';
import Compose from './SharedComponents/Compose';
import Layout from '../Sections/Layout';
import Archived from './Archived';
import Drafts from './Drafts';
import Inbox from './Inbox';
import Sent from './Sent';
import Settings from './Settings';
import Starred from './Starred';
import Trash from './Trash';
import MemberAutocomplete from '../../components/MemberAutocomplete';
import PageSettingsModal from '../../components/Modal/PageSettingsModal';

const getNameOfPage = (path) => {
  const paths = {
    '/share/mail/inbox': 'Inbox',
    '/share/mail/starred': 'Starred',
    '/share/mail/drafts': 'Draft',
    '/share/mail/sent': 'Sent',
    '/share/mail/trash': 'Trash',
    '/share/mail/archive': 'Archive',
    '/share/mail/settings': 'Settings',
  };
  return paths[path] ? paths[path] : '';
};

const Email = (props) => {
  const classes = useEmailPageStyles();

  const dispatch = useDispatch();

  const history = useHistory();
  const path = history.location.pathname;

  const pageType = 'Mail';
  const pageSettings = useSelector((state) => state.member.pageSettings);
  const mailSettings = find(pageSettings, (o) => o.page_type === pageType);
  const defaultView = (mailSettings && mailSettings.view_type) || null;
  // const defaultPageSize = mailSettings.page_size;

  const [view, setView] = useState(defaultView || 'table');
  const [search, setSearch] = useState('');
  const [refreshEmail, setRefreshEmail] = useState(false);
  const [prevState, setPrevState] = useState('/mail/inbox');
  const [getMemberUrl, setGetMemberUrl] = useState(false);

  const [compose, setCompose] = useState({
    open: false,
    initialState: {},
  });
  const [showEmailSetting, setShowEmailSetting] = useState(false);

  useEffect(() => {
    const unregister = history.listen((location, sec) => {
      if (path !== location.pathname) {
        setPrevState(
          path.substring(
            path.startsWith(process.env.PUBLIC_URL)
              ? process.env.PUBLIC_URL.length
              : 0,
            path.length
          )
        );
      }
    });
    return () => {
      unregister();
    };
  }, [dispatch, history, path]);

  const toggleView = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };
  const [selectedContactFilter, setSelectedContactFilter] = useState({});

  let searchKeyDown = (search) => {
    setSearch(search);
  };
  searchKeyDown = debounce(searchKeyDown, 600);

  const handleSettingsClose = () => {
    history.push(`${process.env.PUBLIC_URL}${prevState}`);
  };

  return (
    <div>
      <Layout {...props}>
        <SnackBarMessage />

        <div className={classes.root}>
          <div className={classes.header}>
            {getNameOfPage(path) === 'Settings' ? (
              <div className={classes.title}>Mail Settings</div>
            ) : (
              <>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={5} md={4}>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<MailOutlineIcon />}
                      className={classes.newMessageButton}
                      onClick={(e) => {
                        setCompose({ open: true, initialState: {} });
                      }}
                    >
                      Create New Message
                    </Button>
                    <Tooltip className={classes.refreshButton} title="Refresh">
                      <IconButton
                        size="small"
                        onClick={() => {
                          if (getNameOfPage(path) === 'Inbox') {
                            dispatch(setBadgeCount());
                          }
                          setRefreshEmail(!refreshEmail);
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={2} md={3}>
                    <MemberAutocomplete
                      label="Select a contact"
                      variant="outlined"
                      isAddMember={true}
                      style={{ width: '100%', marginTop: 0 }}
                      values={selectedContactFilter}
                      size="small"
                      disablerFunction={() => {}}
                      handleChange={(event, value) => {
                        setSelectedContactFilter({ ...value });
                      }}
                      memberGetUrl={getMemberUrl}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={3}>
                    <div className={classes.search}>
                      <InputBase
                        inputProps={{ 'aria-label': 'search' }}
                        placeholder={`Search ${getNameOfPage(path)}`}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        renderSuffix={() => (
                          <div className={classes.searchIcon}>
                            <SearchIcon />
                          </div>
                        )}
                        onChange={(e) => {
                          e.persist();
                          searchKeyDown(e.target.value);
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sm={1}
                    md={2}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <ToggleButtonGroup
                        exclusive
                        value={view}
                        onChange={toggleView}
                      >
                        <StyledToggleButton value="list" aria-label="list">
                          <Tooltip title="Mail List">
                            <ViewListIcon />
                          </Tooltip>
                        </StyledToggleButton>
                        <StyledToggleButton value="table" aria-label="table">
                          <Tooltip title="Mail Table">
                            <TableChartSharpIcon />
                          </Tooltip>
                        </StyledToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item className="page-setting-button">
                      <Tooltip title="Settings" arrow>
                        <SettingsIcon
                          onClick={() => setShowEmailSetting(true)}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </div>

          <div>
            <Switch>
              <Redirect
                from={`${process.env.PUBLIC_URL}/mail`}
                to={`${process.env.PUBLIC_URL}/mail/inbox`}
                exact
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/inbox/:mailId?`}
                component={(props) => (
                  <Inbox
                    {...props}
                    view={view}
                    search={search}
                    contact_filter={
                      selectedContactFilter
                        ? selectedContactFilter.contact_member_id
                        : null
                    }
                    refresh={refreshEmail}
                    setCompose={setCompose}
                    setMemberUrl={setGetMemberUrl}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/starred/:mailId?`}
                component={(props) => (
                  <Starred
                    {...props}
                    view={view}
                    search={search}
                    contact_filter={
                      selectedContactFilter
                        ? selectedContactFilter.contact_member_id
                        : null
                    }
                    refresh={refreshEmail}
                    setCompose={setCompose}
                    setMemberUrl={setGetMemberUrl}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/drafts/:mailId?`}
                component={(props) => (
                  <Drafts
                    {...props}
                    view={view}
                    search={search}
                    contact_filter={
                      selectedContactFilter
                        ? selectedContactFilter.contact_member_id
                        : null
                    }
                    refresh={refreshEmail}
                    setCompose={setCompose}
                    setMemberUrl={setGetMemberUrl}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/sent/:mailId?`}
                component={(props) => (
                  <Sent
                    {...props}
                    view={view}
                    search={search}
                    contact_filter={
                      selectedContactFilter
                        ? selectedContactFilter.contact_member_id
                        : null
                    }
                    refresh={refreshEmail}
                    setMemberUrl={setGetMemberUrl}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/trash/:mailId?`}
                component={(props) => (
                  <Trash
                    {...props}
                    view={view}
                    search={search}
                    contact_filter={
                      selectedContactFilter
                        ? selectedContactFilter.contact_member_id
                        : null
                    }
                    refresh={refreshEmail}
                    setMemberUrl={setGetMemberUrl}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/archive/:mailId?`}
                component={(props) => (
                  <Archived
                    {...props}
                    view={view}
                    search={search}
                    contact_filter={
                      selectedContactFilter
                        ? selectedContactFilter.contact_member_id
                        : null
                    }
                    refresh={refreshEmail}
                    setMemberUrl={setGetMemberUrl}
                  />
                )}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/mail/settings/:mailId?`}
                component={(props) => (
                  <Settings
                    {...props}
                    onClose={() => {
                      handleSettingsClose();
                    }}
                    search={search}
                    refresh={refreshEmail}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
        {!compose.open ? null : (
          <Compose
            open={true}
            fullWidth={false}
            initialComposeState={compose.initialState}
            onClose={() => {
              setCompose({ open: false, initialState: {} });
            }}
          />
        )}
        <PageSettingsModal
          pageType={pageType}
          loading={false}
          open={showEmailSetting}
          setOrderBy={() => {}}
          setOrder={() => {}}
          setPageSize={() => {}}
          setView={(view) => toggleView(null, view)}
          onSave={() => {}}
          onClose={() => setShowEmailSetting(false)}
        />
      </Layout>
    </div>
  );
};

export default Email;
