import React from 'react';
import { Grid, Button } from '@material-ui/core';

const ProjectStepsNav = (props) => {
  const { handleBack, handleNext, primaryText = 'Next' } = props;
  return (
    <Grid container style={{ padding: '40px', textAlign: 'right' }}>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBack}
          style={{ marginRight: '10px' }}
        >
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {primaryText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectStepsNav;
