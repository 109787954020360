import React from 'react';
import { useSelector } from 'react-redux';
import GroupList from './GroupList';
import GroupMemberList from './GroupMemberList';

const GroupCall = (props) => {
  const { group_id } = useSelector((state) => ({
    group_id: state.drawer.contentProps.group_id,
  }));

  return (
    <>{group_id ? <GroupMemberList group_id={group_id} /> : <GroupList />}</>
  );
};

export default GroupCall;
