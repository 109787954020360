import { channels } from '../shared/constants';

const { ipcRenderer } = window;

// TODO: There has to be a better way
export const nonBrowserFeatureState = /electron/i.test(navigator.userAgent);

export const ipcInvoke = async (channel, data) => {
  if (!nonBrowserFeatureState) {
    return false;
  }
  return ipcRenderer.invoke(channel);
};

export const clearSecurity = async () => {
  return ipcInvoke(channels.CLEAR_SECURITY);
};

export const forceReload = async () => {
  return ipcInvoke(channels.RELOAD_CONTENTS);
};
