/* global APP  */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../Modal/modal';
import Button from '@material-ui/core/Button';
import { VIDEO_QUALITY_LEVELS } from './constants';
import UIEvents from '../../service/UI/UIEvents';
type Props = {
  openVideodialog: Boolean,
  /**
   * Whether or not the conference is in audio only mode.
   */
  _audioOnly: Boolean,

  /**
   * Whether or not the conference is in peer to peer mode.
   */
  _p2p: Boolean,

  /**
   * The currently configured maximum quality resolution to be sent and
   * received from the remote participants.
   */
  _sendrecvVideoQuality: Number,

  /**
   * Invoked to request toggling of audio only mode.
   */
  dispatch: Dispatch<any>,

  /**
   * Invoked to obtain translated strings.
   */
  t: Function,
};
const { ULTRA, HIGH, STANDARD, LOW } = VIDEO_QUALITY_LEVELS;

const styles = (theme) => ({
  cancelBtn: {
    width: '120px',
    margin: theme.spacing(1),
  },
  closeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
class VideoQuality extends Component<Props> {
  _sliderOptions: Array<Object>;
  constructor() {
    super();
    this._enableAudioOnly = this._enableAudioOnly.bind(this);
    this._enableHighDefinition = this._enableHighDefinition.bind(this);
    this._enableLowDefinition = this._enableLowDefinition.bind(this);
    this._enableStandardDefinition = this._enableStandardDefinition.bind(this);
    this._enableUltraHighDefinition = this._enableUltraHighDefinition.bind(
      this
    );
    this._onSliderChange = this._onSliderChange.bind(this);

    this.state = {
      isVideoDialogOpen: false,
      selectedVideo: null,
      selectedAudio: null,
      selectedSpeaker: null,
      type: 2,
    };
    this._sliderOptions = [
      {
        audioOnly: true,
        onSelect: this._enableAudioOnly,
        textKey: 'Audio Only',
      },
      {
        onSelect: this._enableLowDefinition,
        textKey: 'Low',
        videoQuality: LOW,
      },
      {
        onSelect: this._enableStandardDefinition,
        textKey: 'Standard',
        videoQuality: STANDARD,
      },
      {
        onSelect: this._enableUltraHighDefinition,
        textKey: 'High',
        videoQuality: ULTRA,
      },
    ];
  }
  componentDidUpdate() {}

  openDialog = () => this.setState({ isVideoDialogOpen: true });

  handleClose = () => this.setState({ isVideoDialogOpen: false });
  _mapCurrentQualityToSliderValue() {
    const { _audioOnly, _sendrecvVideoQuality } = this.props;
    const { _sliderOptions } = this;

    if (_audioOnly) {
      const audioOnlyOption = _sliderOptions.find(({ audioOnly }) => audioOnly);

      return _sliderOptions.indexOf(audioOnlyOption);
    }

    for (let i = 0; i < _sliderOptions.length; i++) {
      if (_sliderOptions[i].videoQuality >= _sendrecvVideoQuality) {
        return i;
      }
    }

    return -1;
  }

  _createLabels(activeLabelIndex) {
    const labelsCount = this._sliderOptions.length;
    const maxWidthOfLabel = `${100 / labelsCount}%`;

    return this._sliderOptions.map((sliderOption, index) => {
      const style = {
        maxWidth: maxWidthOfLabel,
        left: `${(index * 100) / (labelsCount - 1)}%`,
      };

      const isActiveClass = activeLabelIndex === index ? 'active' : '';
      const className = `video-quality-dialog-label-container ${isActiveClass}`;

      return (
        <div className={className} key={index} style={style}>
          <div className="video-quality-dialog-label">
            {sliderOption.textKey}
          </div>
        </div>
      );
    });
  }

  _onSliderChange: () => void;

  /**
   * Invokes a callback when the selected video quality changes.
   *
   * @param {Object} event - The slider's change event.
   * @private
   * @returns {void}
   */
  _onSliderChange(event) {
    const { audioOnly, onSelect } = this._sliderOptions[event.target.value];

    this.setState({ type: event.target.value });

    if (audioOnly) {
      this._enableAudioOnly();
      return;
    }

    onSelect();
  }
  _enableAudioOnly: () => void;

  /**
   * Dispatches an action to enable audio only mode.
   *
   * @private
   * @returns {void}
   */
  _enableAudioOnly() {
    console.log('Video quality: audio only enabled');
    APP.UI.emitEvent(UIEvents.AUDIO_ONLY, true);
    //this.props.dispatch(setAudioOnly(true));
  }

  _enableHighDefinition: () => void;

  /**
   * Handles the action of the high definition video being selected.
   * Dispatches an action to receive high quality video from remote
   * participants.
   *
   * @private
   * @returns {void}
   */
  _enableHighDefinition() {
    console.log('Video quality: high enabled');
    this._setPreferredVideoQuality(HIGH);
  }

  _enableLowDefinition: () => void;

  /**
   * Dispatches an action to receive low quality video from remote
   * participants.
   *
   * @private
   * @returns {void}
   */
  _enableLowDefinition() {
    console.log('Video quality: low enabled');
    this._setPreferredVideoQuality(LOW);
  }

  _enableStandardDefinition: () => void;

  /**
   * Dispatches an action to receive standard quality video from remote
   * participants.
   *
   * @private
   * @returns {void}
   */
  _enableStandardDefinition() {
    console.log('Video quality: standard enabled');
    this._setPreferredVideoQuality(STANDARD);
  }

  _enableUltraHighDefinition: () => void;

  /**
   * Dispatches an action to receive ultra HD quality video from remote
   * participants.
   *
   * @private
   * @returns {void}
   */
  _enableUltraHighDefinition() {
    console.log('Video quality: ultra high enabled');
    this._setPreferredVideoQuality(ULTRA);
  }

  _setPreferredVideoQuality(qualityLevel) {
    if (qualityLevel !== false) {
      var desktopResizeConstraints = {
        facingMode: 'user',
        height: {
          ideal: qualityLevel,
          max: qualityLevel,
          min: qualityLevel,
        },
      };
    }
    APP.UI.emitEvent(UIEvents.AUDIO_ONLY, false);
    setTimeout(() => {
      APP.vacall.localTracks[1].track.applyConstraints(
        desktopResizeConstraints
      );
    }, 1000);
  }

  ModalContent = () => {
    return (
      <div className={'video-quality-dialog'}>
        <div className="video-quality-dialog-contents">
          <div className="video-quality-dialog-slider-container">
            {/* FIXME: onChange and onMouseUp are both used for
             * compatibility with IE11. This workaround can be
             * removed after upgrading to React 16.
             */}
            <input
              className="video-quality-dialog-slider"
              max={this._sliderOptions.length - 1}
              min="0"
              onChange={this._onSliderChange}
              onMouseUp={this._onSliderChange}
              step="1"
              type="range"
              value={this.state.type}
            />
          </div>
          <div className="video-quality-dialog-labels">
            {this._createLabels(1)}
          </div>
        </div>
      </div>
    );
  };

  ModalAction = () => {
    const { classes } = this.props;
    return (
      <div className={classes.closeWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.cancelBtn}
          onClick={() => {
            this.props.handleClose('isVideoDialogOpen');
          }}
        >
          Close
        </Button>
      </div>
    );
  };

  render() {
    let { openVideodialog } = this.props;
    return (
      <Modal
        open={openVideodialog}
        title="Call Quality"
        onClose={() => {
          this.props.handleClose('isVideoDialogOpen');
        }}
        ModalContent={this.ModalContent}
        ModalAction={this.ModalAction}
      />
    );
  }
}
export default withStyles(styles, { withTheme: true })(VideoQuality);
