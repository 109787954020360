import React from 'react';
import TextField from '@material-ui/core/TextField';

const PINInput = (props) => {
  const { onPINChange, PIN, errors, isConfirm } = props;
  return (
    <TextField
      id={isConfirm ? 'outlined-confirm-pin' : 'outlined-pin'}
      label={isConfirm ? 'Confirm PIN' : 'PIN'}
      type="number"
      required
      variant="outlined"
      name={isConfirm ? 'confirmPin' : 'pin'}
      placeholder="Personal Identification Number (Min: 6, Max:12)"
      className="register_form_input"
      onChange={onPINChange}
      autoComplete="off"
      value={PIN || ''}
      error={errors ? true : false}
      helperText={errors ? errors : ''}
    />
  );
};

export default PINInput;
