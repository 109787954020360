export const countryCodes = ['CN', 'US', 'DE', 'JP', 'KR', 'RU', 'ES'];
export const languageCodes = ['zh', 'en-US', 'de', 'ja', 'ko', 'ru', 'es'];
export const languageNames = [
  'Chinese',
  'English',
  'German',
  'Japanese',
  'Korean',
  'Russian',
  'Spanish',
];
