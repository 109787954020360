import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Tooltip, IconButton } from '@material-ui/core';
import MaterialAvatar from './MaterialAvatar';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import ReplyIcon from '@material-ui/icons/Reply';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import SearchComponent from './SearchComponent';
import VideoEmailEditorModal from './Modal/VideoEmailEditorModal';

import {
  loadVideoMails,
  readVideoMail,
  deleteVideoMail,
  setReduxMemberAlert,
} from '../redux/actions/member';
import usePrevious from '../hooks/usePrevious';
import { apiUrl } from '../config/api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 350px)',
    flexDirection: 'row',
    display: 'flex',
  },
  listWrapper: {
    padding: theme.spacing(1),
  },
  mailList: {
    width: '300px',
    padding: theme.spacing(1),

    backgroundColor: '#f8faff',
    height: 'calc(100vh - 400px)',
    overflow: 'auto',
  },
  player: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  mailItem: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 8px 8px #E7EDFC',
    position: 'relative',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    '& .MuiAvatar-root': {
      width: '55px',
      height: '55px',
    },
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameWrapper: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowWrapp: 'anywhere',
  },
  subject: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  date: {
    marginTop: theme.spacing(0.2),
    color: theme.palette.secondary.light,
    fontSize: '0.9rem',
  },
  badgeWrapper: {
    position: 'absolute',
    bottom: '70%',
    right: '12%',
  },
  selected: {
    // boxShadow: 'none',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  videoWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    background: 'black',
  },
  video: {
    width: '640px',
    height: '485px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  actionWrapper: {
    background: '#333333',
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'center',
    margingLeft: theme.spacing(2),
    '& .MuiIconButton-root': {
      color: 'white',
      backgroundColor: 'black',
      marginLeft: theme.spacing(1),
    },
    '& .MuiIconButton-colorPrimary': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
  nextActions: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const VideoEmail = (props) => {
  const { mail_id, closeModal, setMediaType } = props;
  const [play, setPlay] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const videoRef = useRef(null);

  const [selectedMail, setSelectedMail] = useState(null);
  const [selectedMailId, setSelectedMailId] = useState();
  const [showVideoEmailEditor, setShowVideoEmailEditor] = useState(false);
  const mails = useSelector((state) => state.member.videoMails);
  const memberAlert = useSelector((state) => state.member.memberAlert);
  const member_id = useSelector((state) => state.member.member.member_id);
  const previousSelectedMail = usePrevious(selectedMail);
  const prevMailId = usePrevious(mail_id);

  useEffect(() => {
    if (selectedMail && previousSelectedMail === undefined) {
      selectedMail.scrollIntoView({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousSelectedMail, selectedMail]);

  useEffect(() => {
    dispatch(loadVideoMails(''));
  }, [dispatch]);

  const debouncedLoadVideoMails = useCallback(
    debounce((nextValue) => dispatch(loadVideoMails(nextValue)), 500),
    []
  );

  const handleSearch = (e) => {
    e.persist();
    setSearchKey(e.target.value);
    debouncedLoadVideoMails(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedLoadVideoMails.cancel();
    };
  }, [debouncedLoadVideoMails]);

  const src = useMemo(() => {
    const mail = mails.find((mail) => mail.id === selectedMailId);
    if (mail) return `${apiUrl}${mail.video_url}`;
    return '';
  }, [selectedMailId, mails]);

  const mailDetail = useMemo(() => {
    const mail = mails.find((mail) => mail.id === selectedMailId);
    return mail;
  }, [selectedMailId, mails]);

  useEffect(() => {
    if (mailDetail) {
      setMediaType(mailDetail.media_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailDetail]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setPlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const handleVideoPlayer = () => {
    if (videoRef.current && videoRef.current.src) {
      if (!play) {
        videoRef.current.play();
        setPlay(true);
        dispatch(readVideoMail(mailDetail, member_id));
      } else {
        videoRef.current.pause();
        setPlay(false);
      }
      // setPlay((ps) => !ps);
    }
  };

  useEffect(() => {
    videoRef.current.addEventListener('ended', () => {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setPlay(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef.current]);

  useEffect(() => {
    if (prevMailId === undefined && mail_id) {
      setSelectedMailId(mail_id);
    }
  }, [prevMailId, mail_id]);

  const handleSnackbarClose = () => {
    if (memberAlert.show) {
      const alertOption = {
        show: false,
        variant: memberAlert.variant,
        message: '',
      };
      setReduxMemberAlert(alertOption);
    }
  };

  const download = () => {
    if (src) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = src;
      const filename = src.substring(src.lastIndexOf('/') + 1);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(src);
      }, 100);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.listWrapper}>
        <SearchComponent handleSearch={handleSearch} searchKey={searchKey} />
        <div className={classes.mailList}>
          {mails.map((mail) => (
            <div
              key={mail.id}
              className={clsx(classes.mailItem, {
                [classes.selected]: mail.id === selectedMailId,
              })}
              ref={(el) => {
                if (mail.id === mail_id) setSelectedMail(el);
              }}
              onClick={() => {
                setSelectedMailId(mail.id);
              }}
            >
              <div className={classes.avatarWrapper}>
                <MaterialAvatar
                  firstName={mail.first_name}
                  lastName={mail.last_name}
                  member_id={mail.member_id}
                  isBasicAvatar={false}
                />
                <div className={classes.nameWrapper}>
                  <div>
                    <b>From:</b> {mail.first_name} {mail.last_name}
                  </div>
                  <div className={classes.subject}>
                    {mail.type === 'reply'
                      ? 'RE: '
                      : mail.type === 'contact'
                      ? 'Contact: '
                      : 'Group: '}
                    {mail.subject}
                  </div>
                </div>
              </div>
              <div className={classes.date}>
                {`${format(new Date(mail.create_date), 'p')} ${format(
                  new Date(mail.create_date),
                  'PPP'
                )} `}
              </div>
              <div className={classes.badgeWrapper}>
                {mail.status === 'unread' && (
                  <Badge badgeContent="new" color="primary" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.player}>
        <div className={classes.videoWrapper}>
          <video
            ref={videoRef}
            playsInline
            src={src}
            className={classes.video}
            // me="true"
          />
        </div>
        <div className={classes.actionWrapper}>
          <Tooltip title={play ? 'Stop' : 'Play'}>
            <span>
              <IconButton onClick={handleVideoPlayer}>
                {play ? <StopIcon /> : <PlayArrowIcon />}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Save">
            <span>
              <IconButton onClick={download}>
                <SaveAltIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <IconButton
                onClick={() => {
                  dispatch(deleteVideoMail(mailDetail, member_id));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Reply">
            <span>
              <IconButton
                onClick={() => {
                  setShowVideoEmailEditor(true);
                }}
              >
                <ReplyIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Close">
            <span>
              <IconButton
                onClick={() => {
                  closeModal();
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      {mailDetail && (
        <VideoEmailEditorModal
          open={showVideoEmailEditor}
          onClose={() => {
            setShowVideoEmailEditor(false);
          }}
          receiver={mailDetail.member_id}
          type="reply"
          subject={mailDetail.subject}
          name={`${mailDetail.first_name} ${mailDetail.last_name}`}
          replied_id={mailDetail.id}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={memberAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={memberAlert.variant}>{memberAlert.message}</Alert>
      </Snackbar>
    </div>
  );
};

VideoEmail.propTypes = {
  show: PropTypes.bool,
  mail_id: PropTypes.number,
  closeModal: PropTypes.func,
  setMediaType: PropTypes.func,
};

export default VideoEmail;
