/* global  $ */

import UIEvents from '../../service/UI/UIEvents';
import SharedVideoManager from './shared_video/SharedVideo';
import EventEmitter from 'events';
import messageHandler from './util/MessageHandler';
const eventEmitter = new EventEmitter();
const UI = {};
UI.eventEmitter = eventEmitter;
UI.messageHandler = messageHandler;

let sharedVideoManager;

const UIListeners = new Map([
  [
    UIEvents.SHARED_VIDEO_CLICKED,
    () => sharedVideoManager && sharedVideoManager.toggleSharedVideo(),
  ],
]);

/**
 * Returns true if there is a shared video which is being shown (?).
 * @returns {boolean} - true if there is a shared video which is being shown.
 */
UI.isSharedVideoShown = function () {
  return Boolean(sharedVideoManager && sharedVideoManager.isSharedVideoShown);
};

/**
 * Returns the shared document manager object.
 * @return {sharedVideoManager} the shared document manager object
 */
UI.getSharedVideoManager = function () {
  return sharedVideoManager;
};

/**
 * Starts the UI module and initializes all related components.
 *
 * @returns {boolean} true if the UI is ready and the conference should be
 * established, false - otherwise (for example in the case of welcome page)
 */
UI.start = function () {
  // Set the defaults for prompt dialogs.
  //    $.prompt.setDefaults({ persistent: false });

  sharedVideoManager = new SharedVideoManager(eventEmitter);
};

/**
 * Setup some UI event listeners.
 */
UI.registerListeners = () =>
  UIListeners.forEach((value, key) => UI.addListener(key, value));

/**
 * Setup some DOM event listeners.
 */
UI.bindEvents = () => {
  /**
   *
   */
  // function onResize() {
  //     VideoLayout.onResize();
  // }
  // Resize and reposition videos in full screen mode.
  // $(document).on(
  //         'webkitfullscreenchange mozfullscreenchange fullscreenchange',
  //         onResize);
  //  $(window).resize(onResize);
};

/**
 * Unbind some DOM event listeners.
 */
UI.unbindEvents = () => {
  $(document).off(
    'webkitfullscreenchange mozfullscreenchange fullscreenchange'
  );

  $(window).off('resize');
};

/**
 * Show user on UI.
 * @param {JitsiParticipant} user
 */
UI.addUser = function (user) {
  const id = user.getId();
  const displayName = user.getDisplayName();
  const status = user.getStatus();

  if (status) {
    // FIXME: move updateUserStatus in participantPresenceChanged action
    UI.updateUserStatus(user, status);
  }

  // set initial display name
  if (displayName) {
    UI.changeDisplayName(id, displayName);
  }
};

/**
 * Adds a listener that would be notified on the given type of event.
 *
 * @param type the type of the event we're listening for
 * @param listener a function that would be called when notified
 */
UI.addListener = function (type, listener) {
  eventEmitter.on(type, listener);
};

/**
 * Removes the all listeners for all events.
 *
 * @returns {void}
 */
UI.removeAllListeners = function () {
  eventEmitter.removeAllListeners();
};

/**
 * Removes the given listener for the given type of event.
 *
 * @param type the type of the event we're listening for
 * @param listener the listener we want to remove
 */
UI.removeListener = function (type, listener) {
  eventEmitter.removeListener(type, listener);
};

/**
 * Emits the event of given type by specifying the parameters in options.
 *
 * @param type the type of the event we're emitting
 * @param options the parameters for the event
 */
UI.emitEvent = (type, ...options) => eventEmitter.emit(type, ...options);

/**
 * Show shared video.
 * @param {string} id the id of the sender of the command
 * @param {string} url video url
 * @param {string} attributes
 */
UI.onSharedVideoStart = function (id, url, attributes) {
  if (sharedVideoManager) {
    sharedVideoManager.onSharedVideoStart(id, url, attributes);
  }
};

/**
 * Update shared video.
 * @param {string} id the id of the sender of the command
 * @param {string} url video url
 * @param {string} attributes
 */
UI.onSharedVideoUpdate = function (id, url, attributes) {
  if (sharedVideoManager) {
    sharedVideoManager.onSharedVideoUpdate(id, url, attributes);
  }
};

/**
 * Stop showing shared video.
 * @param {string} id the id of the sender of the command
 * @param {string} attributes
 */
UI.onSharedVideoStop = function (id, attributes) {
  if (sharedVideoManager) {
    sharedVideoManager.onSharedVideoStop(id, attributes);
  }
};

// TODO: Export every function separately. For now there is no point of doing
// this because we are importing everything.
export default UI;
