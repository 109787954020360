import React from 'react';
import { Link } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    padding: '30px 0 10px',
    marginBottom: '0',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '20px',
  },
}));

const AmeraBreadcrumbs = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.breadcrumbs}>
      <div>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="inherit">{props.parent}</Link>
          <Typography color="textPrimary">{props.current_directory}</Typography>
        </Breadcrumbs>
      </div>
      <div>
        <CancelOutlinedIcon />
      </div>
    </div>
  );
};

export default AmeraBreadcrumbs;
