import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialAvatar from '../../../components/MaterialAvatar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ContactProfile from '../../Profile/Profiles/ContactProfile';
// import PageSettingsModal from '../../../components/Modal/PageSettingsModal';
import AmeraPagination from '../../../components/AmeraPagination';

import { displayableFullName } from '../../../utils/contact';
import { getContactOnlineStatus } from '../../../utils/strophe/user';
// import { PAGE_TYPES } from '../../../utils/pageSettings';

// api call
// import { loadContacts } from '../../../redux/actions/contact';

const headCells = [
  {
    id: 'member_name',
    label: 'Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company',
    label: 'Company',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 85,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    label: 'Type',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 250,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    selectedContacts,
    rowCount,
    onRequestSort,
  } = props;

  const numSelected = selectedContacts.length;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedContacts: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ContactStatus = (props) => {
  let { status, contact } = props;
  let statusStyle = status === 'online' ? 'online-text-color' : '';
  return (
    <p id="status-text" className={statusStyle}>
      {displayableFullName(contact)}
    </p>
  );
};
ContactStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
ContactStatus.defaultPage = {
  status: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const TeamListContainer = (props) => {
  const {
    totalCount,
    // loading,
    contactData,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    pageSize,
    pageNumber,
    handleChangePage,
    handleChangeRowsPerPage,
    loadSortedContacts,
    // showContactsSetting,
    // setShowContactsSetting,
    openDetailsFor,
    handleSelectedContacts,
  } = props;

  const { projectId } = useParams();

  // selectors
  const extractedSelectedContacts = useSelector((state) => {
    const project =
      state.project.projects.filter((p) => p.project_id === +projectId)[0] ||
      null;
    // if project creation store projects state is not updated return []
    if (!project) {
      return [];
    }
    const memberIds = project.project_members.map((pm) => pm.member_id) || [];
    return (
      state.contact.contacts.filter((c) =>
        memberIds.includes(c.contact_member_id)
      ) || []
    );
  });
  const rosterItems = useSelector((s) => s.chat.rosterItems);

  const [selectedContacts, setSelectedContacts] = useState(
    extractedSelectedContacts || []
  );
  useEffect(() => {
    if (!!openDetailsFor) {
      showContactDetail(
        contactData.find((cdo) => cdo.contact_member_id === openDetailsFor)
      );
    }
  }, [contactData, openDetailsFor]);

  const [contactDetailData, setContactDetailData] = useState({});
  const [showDetailModal, setShowDetailModal] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedContacts(property, newOrder);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedContacts(contactData);
      return;
    }
    setSelectedContacts([]);
  };

  const handleSelect = (event, contact_member_id) => {
    let item = contactData.find(
      (el) => el.contact_member_id === contact_member_id
    );
    let findItem = selectedContacts.findIndex(
      (el) => el.contact_member_id === contact_member_id
    );
    if (findItem === -1) {
      handleSelectedContacts([...selectedContacts, item]);
      setSelectedContacts([...selectedContacts, item]);
    } else {
      let newSelected = [...selectedContacts];
      newSelected.splice(findItem, 1);
      handleSelectedContacts([...newSelected]);
      setSelectedContacts([...newSelected]);
    }
  };

  const isSelected = (name) => {
    if (selectedContacts.length !== 0) {
      let el = selectedContacts.find((el) => el.contact_member_id === name);
      return el ? true : false;
    }
    return false;
  };

  const showContactDetail = (contact) => {
    setContactDetailData(contact);
    setShowDetailModal(true);
  };

  return (
    <div className="contact-table">
      <ContactProfile
        open={showDetailModal}
        onClose={() => {
          setShowDetailModal(false);
          // This clears the state causing the Profile modal to show up (if any), when routed from Fileshare
          history.push(`${process.env.PUBLIC_URL}/projects/select-team/`);
        }}
        contact={contactDetailData}
      />
      {/* <PageSettingsModal
        pageType={PAGE_TYPES.project}
        loading={loading}
        open={showContactsSetting}
        orderBy={orderBy}
        onClose={() => setShowContactsSetting(false)}
        setOrderBy={setOrderBy}
        onClick={loadSortedContacts}
      /> */}
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              selectedContacts={selectedContacts}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAll}
              onRequestSort={handleRequestSort}
              rowCount={contactData.length}
            />
            <TableBody>
              {/* { stableSort(contactData, getComparator(order, orderBy)) */}
              {/* need to do pagination on server side to avoid client end processing */}
              {contactData.map((contact, index) => {
                const isItemSelected = isSelected(contact.contact_member_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleSelect(event, contact.contact_member_id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={contact.contact_member_id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div className="contact-member-name">
                        <MaterialAvatar
                          member_id={contact.contact_member_id}
                          src={contact.amera_avatar_url}
                          classes="contact"
                          firstName={contact.first_name}
                          lastName={contact.last_name}
                        />
                        <ContactStatus
                          status={getContactOnlineStatus(contact, rosterItems)}
                          contact={contact}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left">{contact.company}</TableCell>
                    <TableCell align="left">{contact.title}</TableCell>

                    <TableCell align="left">
                      {contact.role || 'Not Available'}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => showContactDetail(contact)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <AmeraPagination
          itemTypeLabel="Items"
          totalCount={totalCount}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={pageSize}
          page={pageNumber}
        />
        {/* {totalCount > 25 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={totalCount}
            page={pageNumber}
            rowsPerPage={pageSize}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )} */}
      </Paper>
    </div>
  );
};

TeamListContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

TeamListContainer.defaultProps = {
  contacts: [],
  orderBy: 'first_name',
  pageNumber: 0,
  pageSize: 25,
};

const mapStateToProps = (state) => ({
  loading: state.contact.loading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamListContainer);
