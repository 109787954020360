import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PhoneField from '../../../pages/Profile/ProfileEdit/PhoneList/PhoneListItem/PhoneInput';
import PhoneTypeSelector from '../../../pages/Profile/ProfileEdit/PhoneList/PhoneListItem/PhoneTypeSelector';
import MethodTypeSelector from '../../../pages/Profile/ProfileEdit/PhoneList/PhoneListItem/MethodTypeSelector';
import ListDeleteButton from '../../../pages/Profile/ProfileEdit/ListDeleteButton';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';
import { useSelector } from 'react-redux';
import { trimStart } from 'lodash';

const PhoneItem = (props) => {
  const { data, setPhones, phonesCount, error } = props;
  const countryList = useSelector((s) => s.member.countryList);
  const deviceCountryObj = countryList.find(
    (cl) => cl.id === data.device_country
  );
  const countryAlpha2 = !!deviceCountryObj
    ? (deviceCountryObj?.alpha2).toLowerCase()
    : 'us';
  const countryPhonePrefix = !!deviceCountryObj ? deviceCountryObj?.phone : '1';

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          disabled={data.primary_contact}
          value={data.description}
          label="Description"
          fullWidth
          error={!!error?.description}
          helperText={!!error?.description ? error?.description : ''}
          required
          onChange={(e) =>
            setPhones({
              type: PROFILE_EDIT_ACTIONS.phoneEdit,
              payload: {
                phone_id: data.id,
                field: 'description',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <PhoneTypeSelector
          disabled={data.primary_contact}
          phoneType={data.device_type}
          handleChange={(e) =>
            setPhones({
              type: PROFILE_EDIT_ACTIONS.phoneEdit,
              payload: {
                phone_id: data.id,
                field: 'device_type',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <MethodTypeSelector
          disabled={data.primary_contact}
          deviceClass={data.device_type}
          method={data.method_type}
          handleChange={(e) =>
            setPhones({
              type: PROFILE_EDIT_ACTIONS.phoneEdit,
              payload: {
                phone_id: data.id,
                field: 'method_type',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={10}>
        <PhoneField
          disabled={data.primary_contact}
          phoneNumber={`${countryPhonePrefix}${data.device}`}
          userCountry={data}
          phoneCountry={countryAlpha2}
          phoneType={data.device_type}
          variant="standard"
          error={error?.phoneInput}
          handleVerificationSuccess={() =>
            setPhones({
              type: PROFILE_EDIT_ACTIONS.phoneEdit,
              payload: {
                phone_id: data.id,
                field: 'device_confirm_date',
                value: new Date(),
              },
            })
          }
          isPhoneVerified={!!data.device_confirm_date}
          handleChange={(v, pc) =>
            setPhones({
              type: PROFILE_EDIT_ACTIONS.phoneNumberEdit,
              payload: {
                phone_id: data.id,
                device: trimStart(v, countryPhonePrefix),
                device_country: countryList.find(
                  (cl) => cl.alpha2 === pc.toUpperCase()
                )?.id,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={2}>
        <ListDeleteButton
          id={data.id}
          isDisabled={data.primary_contact || phonesCount === 1}
          handleDelete={() =>
            setPhones({
              type: PROFILE_EDIT_ACTIONS.phoneDelete,
              payload: { phone_id: data.id },
            })
          }
          listContext="phone contact item"
        >
          Delete Item
        </ListDeleteButton>
      </Grid>
    </Grid>
  );
};

export default PhoneItem;
