import React, { useState, useEffect, useCallback } from 'react';
// import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import MaterialAvatar from '../../components/MaterialAvatar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Pagination from '@material-ui/lab/Pagination';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import AmeraPagination from '../../components/AmeraPagination';

import {
  getContactDevice,
  getContactLocation,
  displayableFullName,
} from '../../utils/contact';

import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';
import { defaultDateTimeZone } from '../../utils/calendar';
import { getContactOnlineStatus } from '../../utils/strophe/user';

// const descendingComparator = (a, b, orderBy) => {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// };

// const getComparator = (order, orderBy) => {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// };

// const stableSort = (array, comparator) => {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// };

const headCells = [
  {
    id: 'member_name',
    label: 'Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company',
    label: 'Company',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 85,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'office_phone',
    label: 'Office Phone',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'cell_phone',
    label: 'Cell Phone',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'create_date',
    label: 'Create Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    label: 'Relationship',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    selectedContacts,
    rowCount,
    onRequestSort,
  } = props;

  const numSelected = selectedContacts.length;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedContacts: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ContactStatus = (props) => {
  let { contact, status } = props;
  let statusStyle = status === 'online' ? 'online-text-color' : '';
  return (
    <p id="status-text" className={statusStyle}>
      {displayableFullName(contact)}
    </p>
  );
};
ContactStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
ContactStatus.defaultPage = {
  status: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ListContainer = (props) => {
  const {
    totalCount,
    contactData,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    pageSize,
    pageNumber,
    handleChangePage,
    handleChangeRowsPerPage,
    loadSortedContacts,
    openDetailsFor,
    onSchedule,
  } = props;

  const dispatch = useDispatch();

  const rosterItems = useSelector((s) => s?.chat?.rosterItems);

  const showContactDetail = useCallback(
    (contactMemberId) => {
      dispatch(
        openDrawer(DRAWER_CONTENT_TYPE.contactProfile, { contactMemberId })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!!openDetailsFor) {
      showContactDetail(openDetailsFor);
    }
  }, [openDetailsFor, showContactDetail]);

  // const [contactDetailData, setContactDetailData] = useState({});
  // const [showDetailModal, setShowDetailModal] = useState(false);

  const classes = useStyles();
  const [selectedContacts, setSelectedContacts] = useState([]);
  // const history = useHistory();

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedContacts(property, newOrder);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedContacts(contactData);
      return;
    }
    setSelectedContacts([]);
  };

  const handleSelect = (event, contact_member_id) => {
    let item = contactData.find(
      (el) => el.contact_member_id === contact_member_id
    );
    let findItem = selectedContacts.findIndex(
      (el) => el.contact_member_id === contact_member_id
    );
    if (findItem === -1) {
      setSelectedContacts([...selectedContacts, item]);
    } else {
      let newSelected = [...selectedContacts];
      newSelected.splice(findItem, 1);
      setSelectedContacts([...newSelected]);
    }
  };

  const isSelected = (name) => {
    if (selectedContacts.length !== 0) {
      let el = selectedContacts.find((el) => el.contact_member_id === name);
      return el ? true : false;
    }
    return false;
  };

  return (
    <div className="contact-table">
      {/* <ContactProfile
        open={showDetailModal}
        onClose={() => {
          setShowDetailModal(false);
          // This clears the state causing the Profile modal to show up (if any), when routed from Fileshare
          history.push(`${process.env.PUBLIC_URL}/contacts/`);
        }}
        contact={contactDetailData}
      /> */}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Contacts"
          selectedContacts={selectedContacts}
          handleScheduleEvent={onSchedule}
          handleCreateGroup={props.goToCreateGroup}
          handleDeleteContacts={(selectedContacts) => {
            props.deleteContactsFunc(selectedContacts);
            setSelectedContacts([]);
          }}
          setCompose={props.setCompose}
        />
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              selectedContacts={selectedContacts}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAll}
              onRequestSort={handleRequestSort}
              rowCount={contactData.length}
            />
            <TableBody>
              {/* { stableSort(contactData, getComparator(order, orderBy)) */}
              {/* need to do pagination on server side to avoid client end processing */}
              {contactData.map((contact, index) => {
                const isItemSelected = isSelected(contact.contact_member_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) =>
                      handleSelect(event, contact.contact_member_id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={contact.contact_member_id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div className="contact-member-name">
                        <MaterialAvatar
                          member_id={contact.contact_member_id}
                          src={contact.amera_avatar_url}
                          classes="contact"
                          firstName={contact.first_name}
                          lastName={contact.last_name}
                        />
                        <ContactStatus
                          contact={contact}
                          status={getContactOnlineStatus(contact, rosterItems)}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {!!contact?.company_membership
                        ? contact.company_membership.name
                        : contact.company}
                    </TableCell>
                    <TableCell align="left">{contact.title}</TableCell>
                    <TableCell align="left">
                      {getContactDevice(contact, 'Cell phone') ||
                        'Not Available'}
                    </TableCell>
                    <TableCell align="left">
                      {getContactDevice(contact, 'Office phone') ||
                        'Not Available'}
                    </TableCell>
                    <TableCell align="left">
                      {getContactLocation(contact, 'home') &&
                        getContactLocation(contact, 'home').city}
                    </TableCell>
                    <TableCell align="left">
                      {getContactLocation(contact, 'home') &&
                        getContactLocation(contact, 'home').country}
                    </TableCell>
                    <TableCell align="left">
                      {contact.create_date
                        ? defaultDateTimeZone(contact.create_date, 'date')
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {contact.role || 'Not Available'}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          showContactDetail(contact.contact_member_id)
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <AmeraPagination
          itemTypeLabel="Items"
          totalCount={totalCount}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={pageSize}
          page={pageNumber}
        />
        {/* {totalCount > 25 && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 75, 100]}
            component="div"
            count={totalCount}
            page={pageNumber}
            rowsPerPage={pageSize}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )} */}
      </Paper>
    </div>
  );
};

ListContainer.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

ListContainer.defaultProps = {
  contacts: [],
  orderBy: 'first_name',
  pageNumber: 0,
  pageSize: 25,
};

export default ListContainer;
