import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { listTimeZones } from 'timezone-support';

const TimeZoneAutocomplete = (props) => {
  const { value, onChange, variant = 'standard' } = props;

  const tzList = useMemo(() => listTimeZones(), []);

  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

  return (
    <Autocomplete
      id="members"
      options={tzList}
      getOptionLabel={(o) => o}
      renderOption={(o) => o}
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label="Timezone"
          placeholder="Timezone"
        />
      )}
      style={{
        minWidth: 290,
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  tzList: state.member.timezoneList,
});

export default connect(mapStateToProps)(TimeZoneAutocomplete);
