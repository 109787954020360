import {
  SCHEDULE_EVENT_CREATE_START,
  SCHEDULE_EVENT_API_CALL_START,
  SCHEDULE_EVENT_API_CALL_FAIL,
  SCHEDULE_EVENT_API_CALL_GET_SUCCESS,
  SCHEDULE_EVENT_API_CALL_ADD_SUCCESS,
  SCHEDULE_EVENT_HIDE_ERROR,
  SCHEDULE_EVENT_GET_COLORS_SUCCESS,
  // Modify
  SCHEDULE_EVENT_MODIFY_START,
  SCHEDULE_EVENT_MODIFY_SUCCESS,
  SCHEDULE_EVENT_MODIFY_FAIL,
  // End Modify
  // Cancel
  SCHEDULE_EVENT_CANCEL_START,
  SCHEDULE_EVENT_CANCEL_SUCCESS,
  SCHEDULE_EVENT_CANCEL_FAIL,
  // End Cancel
  // Create event file attachment
  // EVENT_ATTACH_START,
  // EVENT_ATTACH_PROGRESS,
  // EVENT_ATTACH_SUCCESS,
  // EVENT_ATTACH_FAILED,
} from '../actionTypes/schedule.js';

import {
  APP_RESET
} from '../actionTypes/app';

export const initialState = {
  isLoading: false,
  isModifying: false,
  isCreating: false,
  isCancelling: false,
  data: [],
  newData: [],
  colors: [],
  error: null,
};

const scheduleEvents = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case SCHEDULE_EVENT_MODIFY_START:
      return {
        ...state,
        isModifying: true,
        error: null,
      };

    case SCHEDULE_EVENT_MODIFY_SUCCESS:
      const { updated_data, old_event_id } = action.payload;

      const update_data = state.data.map((event) => {
        if (event.event_id === old_event_id) {
          event = updated_data;
        }

        return event;
      });

      return {
        ...state,
        isModifying: false,
        data: update_data,
        error: null,
      };

    case SCHEDULE_EVENT_MODIFY_FAIL:
      return {
        ...state,
        isModifying: false,
        error: action.payload,
      };

    case SCHEDULE_EVENT_API_CALL_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SCHEDULE_EVENT_CREATE_START:
      return {
        ...state,
        isCreating: true,
        error: null,
      };
    case SCHEDULE_EVENT_API_CALL_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        newData: [],
      };
    case SCHEDULE_EVENT_API_CALL_ADD_SUCCESS:
      return {
        ...state,
        isCreating: false,
        data: state.data.concat(action.payload),
        // newData: state.newData.concat(action.payload),
        error: null,
      };
    case SCHEDULE_EVENT_API_CALL_FAIL:
      return {
        ...state,
        isLoading: false,
        isCreating: false,
        error: action.payload,
      };
    case SCHEDULE_EVENT_HIDE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case SCHEDULE_EVENT_GET_COLORS_SUCCESS:
      return {
        ...state,
        colors: action.payload,
      };
    case SCHEDULE_EVENT_CANCEL_START:
      return {
        ...state,
        isCancelling: true,
        error: null,
      };
    case SCHEDULE_EVENT_CANCEL_SUCCESS:
      /* 
        We replace all the instances of the touched sequence
        If the sequence only had one event in it the action.payload will be [null]
       */

      let updatedEvents;
      const { touchedSequence, eventData } = action.payload;

      if (!!eventData) {
        // If we have something returned - replace the sequence
        updatedEvents = [
          ...state.data.filter(
            (event) => event.sequence_id !== touchedSequence
          ),
          ...eventData,
        ];
      } else {
        // If nothing returned - the sequence
        updatedEvents = state.data.filter(
          (event) => event.sequence_id !== touchedSequence
        );
      }
      return {
        ...state,
        isCancelling: false,
        data: updatedEvents,
        error: null,
      };

    case SCHEDULE_EVENT_CANCEL_FAIL:
      return {
        ...state,
        isCancelling: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default scheduleEvents;
