import React from 'react';
import SnackBar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const EventModalSnack = (props) => {
  const { snackState, timeout = 1000, onClose } = props;
  return (
    <SnackBar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={snackState.isShow}
      autoHideDuration={timeout}
      onClose={onClose}
    >
      <Alert style={{ minHeight: 'unset' }} severity={snackState.variant}>
        <div>{snackState.message}</div>
      </Alert>
    </SnackBar>
  );
};

export default EventModalSnack;
