import React, { useState } from 'react';
import AddProjectElements from './AddProjectElements';
import EditProjectElements from './EditProjectElements';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

const ProjectManagementRow = ({
  projectId,
  parent_id,
  element_id,
  element_title,
  element_description,
  element_type,
  rate_type,
  updateExpanded,
}) => {
  const [show, setShow] = useState(false);

  const showHandler = () => {
    setShow(!show);
  };

  return (
    <>
      <div className="project-row">
        <div id={element_id} className="div2_2 element-actions">
          {show && (
            <>
              <div style={{ marginRight: '5px' }}>
                <AddProjectElements
                  projectId={projectId}
                  element_type={element_type}
                  element_id={element_id}
                  rate_type={rate_type}
                  updateExpanded={updateExpanded}
                />
              </div>
              <div>
                <EditProjectElements
                  projectId={projectId}
                  parent_id={parent_id}
                  element_id={element_id}
                  element_title={element_title}
                  element_description={element_description}
                  pay_type={rate_type}
                  element_type={element_type}
                />
              </div>
            </>
          )}
          <div
            onClick={showHandler}
            style={{ marginRight: '10px', marginLeft: '10px' }}
          >
            {show ? (
              <RemoveCircleOutlineOutlinedIcon color="primary" />
            ) : (
              <AddCircleOutlineIcon color="primary" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectManagementRow;
