import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const TimezoneList = ({ timezone_id, onChange }) => {
  const { memberSetting, remote_timezone_name, timezoneList } = useSelector(
    (state) => ({
      memberSetting: state.member.memberSetting,
      remote_timezone_name: state.member.remote_timezone_name,
      timezoneList: state.member.timezoneList,
    })
  );

  const [defaultTimezone, setDefaultTimezone] = useState(null);

  useEffect(() => {
    for (let i = 0; i < timezoneList.length; i++) {
      if (timezoneList[i].zone_name === remote_timezone_name) {
        setDefaultTimezone(timezoneList[i]);
        break;
      }
    }
  }, [timezoneList, remote_timezone_name]);

  useEffect(() => {
    if (memberSetting.timezone_id) {
      for (let i = 0; i < timezoneList.length; i++) {
        if (timezoneList[i].tz_id === memberSetting.timezone_id) {
          onChange(timezoneList[i]);
          break;
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberSetting]);

  return (
    <>
      <div className="page_section-row flex-column">
        <div className="section-row_label">International Formats</div>
      </div>
      <div>
        <Autocomplete
          id="timezone-select"
          style={{ width: 350 }}
          value={
            timezone_id
              ? timezoneList.find((tz) => tz.tz_id === timezone_id)
              : defaultTimezone
          }
          options={timezoneList}
          autoHighlight
          getOptionLabel={(option) => {
            let offset = '';
            if (option.utc_offset.startsWith('-1 day')) {
              offset = `(UTC-${String(
                -1 * (parseInt(option.utc_offset.substring(8, 10)) - 24)
              ).padStart(2, '0')}:00)`;
            } else {
              if (option.utc_offset.substring(1, 2) === ':') {
                offset = `(UTC+0${option.utc_offset.substring(0, 1)}:00)`;
              } else {
                offset = `(UTC+${option.utc_offset.substring(0, 2)}:00)`;
              }
            }
            return `${offset} ${option.zone_name}`;
          }}
          renderOption={(option) => {
            let offset = '';
            if (option.utc_offset.startsWith('-1 day')) {
              offset = `(UTC-${String(
                -1 * (parseInt(option.utc_offset.substring(8, 10)) - 24)
              ).padStart(2, '0')}:00)`;
            } else {
              if (option.utc_offset.substring(1, 2) === ':') {
                offset = `(UTC+0${option.utc_offset.substring(0, 1)}:00)`;
              } else {
                offset = `(UTC+${option.utc_offset.substring(0, 2)}:00)`;
              }
            }
            return (
              <React.Fragment>
                <span>
                  {offset} {option.zone_name}
                </span>
              </React.Fragment>
            );
          }}
          onChange={(option, value, reason) => {
            onChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a timezone"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-timezone', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default TimezoneList;
