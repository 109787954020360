import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import WeekPicker from '../../components/Billings/WeekPicker';
import HourlySummary from '../../components/Billings/HourlySummary';
import FixedSummary from '../../components/Billings/FixedSummary';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { BillingApi } from '../../redux/actions/billing';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#363C45',
    minWidth: '1260px',
  },
  header: {
    height: '81px',
    marginLeft: '67px',
    paddingRight: '52px',
    borderBottom: '1px solid #8DA3C5',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: '34px',
    fontSize: '23px',
  },
  weekSelector: {
    marginTop: 'auto',
    marginBottom: 'auto',
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      fontSize: '17px',
    },
  },
  cardWrapper: {
    marginTop: '31px',
    marginLeft: '54px',
    marginRight: '52px',
  },
  cardHeader: {
    '& .MuiCardHeader-root': {
      paddingLeft: '33px',
    },
    '& .MuiCardHeader-title': {
      fontSize: '21px',
      fontWeight: 'bold',
      color: '#363C45',
    },
  },
  summary: {
    '& .MuiCardHeader-root': {
      paddingLeft: '33px',
      borderBottom: '1px solid #D5D5D5',
    },
    '& .MuiCardContent-root': {
      padding: '8px 40px 70px 40px',
    },
  },
  summaryItem: {
    display: 'flex',
    paddingTop: '12px',
    paddingBottom: '8px',
    fontSize: '15px',
    justifyContent: 'space-between',
    borderBottom: '1px solid #D5D5D5',
  },
  hourly: {
    marginTop: '51px',
  },
  fixed: {
    marginTop: '51px',
    '& .MuiCardHeader-root': {
      paddingLeft: '33px',
      borderBottom: '1px solid #D5D5D5',
    },
  },
  total: {
    fontSize: '24px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  greenText: {
    color: '#94c03d',
  },
}));

const Weekly = (props) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const start = React.useMemo(() => addDays(startOfWeek(selectedDate), 1), [
    selectedDate,
  ]);
  const end = React.useMemo(() => addDays(endOfWeek(selectedDate), 1), [
    selectedDate,
  ]);

  const defaultEmptyWeeklyBillingData = {
    summary: {},
    fixed: {},
  };

  const weeklyBillingData = useSelector(
    (state) => state.billing.weeklyBillingData || defaultEmptyWeeklyBillingData
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      BillingApi.getWeeklyBilling(
        format(start, 'yyyy-MM-dd'),
        format(addDays(end, 1), 'yyyy-MM-dd')
      )
    );
  }, [dispatch, start, end]);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>Weekly Billing</div>
        <div className={classes.weekSelector}>
          <WeekPicker selectedDate={selectedDate} setState={setSelectedDate} />
        </div>
      </div>
      <div className={classes.cardWrapper}>
        <div className={clsx(classes.summary, classes.cardHeader)}>
          <Card elevation={3}>
            <CardHeader
              title={`Summary for ${format(start, 'MMM d')} - ${format(
                end,
                'MMM d yyyy'
              )}`}
            />
            <CardContent>
              <div className={classes.summaryItem}>
                <span>
                  Hourly &nbsp;
                  <span className={classes.greenText}>
                    ({parseInt(weeklyBillingData.summary.hours / 60)} hours)
                  </span>
                </span>
                <span>${weeklyBillingData.summary.hourly_due}</span>
              </div>
              <div className={classes.summaryItem}>
                <span>Fixed Price Contracts</span>
                <span>${weeklyBillingData.summary.fixed_due}</span>
              </div>
              <div className={classes.total}>
                ${weeklyBillingData.summary.total}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className={clsx(classes.hourly, classes.cardHeader)}>
          <Card elevation={3}>
            <CardHeader title="Hourly"></CardHeader>
            {weeklyBillingData &&
              weeklyBillingData.hourly &&
              weeklyBillingData.hourly.length > 0 &&
              weeklyBillingData.hourly.map((project) => (
                <HourlySummary
                  key={project.project_id}
                  project={project}
                  start={start}
                  end={end}
                />
              ))}
          </Card>
        </div>
        <div className={clsx(classes.fixed, classes.cardHeader)}>
          <Card elevation={3}>
            <CardHeader title="Fixed-price projects and contracts"></CardHeader>
            <FixedSummary fixed={weeklyBillingData.fixed} />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Weekly;
