import React from 'react';
import { makeStyles } from '@material-ui/styles';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
// import { format } from 'date-fns';
import { defaultDateTimeZone } from '../../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    padding: 13,
    background: '#97ACC1',
    borderRadius: 5,
    boxShadow:
      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  videoPlayer: {
    height: 154,
    background: 'grey',
    position: 'relative',
    width: '100%',
  },
  description: {
    background: '#97ACC1',
    paddingTop: 9,
    '& h2': {
      fontSize: 17,
    },
    '& h4': {
      fontSize: 12,
    },
  },
  video: {
    width: '100%',
    height: '100%',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const VideoCard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.videoPlayer}>
        <video playsInline className={classes.video} me="true"></video>
      </div>
      <div className={classes.description}>
        <h2>Marketing Presentation</h2>
        <h4>By: Chris Daly</h4>
        <div className={classes.date}>
          <EventAvailableIcon />
          <span>{defaultDateTimeZone(new Date(), 'date')}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
