import React, { useState } from 'react';
import LanguageDrawer from './index.js';
import { makeStyles } from '@material-ui/core';
import Blue from '@material-ui/core/colors/blue';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import TranslateIcon from '@material-ui/icons/Translate';

const useStyles = makeStyles((theme) => ({
  translate: {
    position: 'fixed',
    right: '2.5em',
    bottom: '3em',
    backgroundColor: Blue[900],
    color: theme.palette.common.white,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: Blue[900],
    },
  },
}));

const withLanguageDrawer = (Component, messages) => (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Component {...props} />
      <IconButton
        aria-label="translate"
        className={classes.translate}
        evaluation={4}
        onClick={() => {
          setOpen(true);
        }}
      >
        <TranslateIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <LanguageDrawer drawerMessages={messages}/>
      </Drawer>
    </React.Fragment>
  );
};

export default withLanguageDrawer;
