import React from 'react';
import Carousel from 'react-multi-carousel';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  w100: {
    width: '100%',
  },
  carousel: {
    height: '100%',
    '& .react-multi-carousel-track': {
      height: '100%',
      position: 'absolute',
      '& .react-multi-carousel-item': {
        padding: '1px 8px',
      },
    },
    '& .react-multiple-carousel__arrow': {
      background: 'white',
      minWidth: 28,
      minHeight: 28,
      boxShadow: '0px 2px 6px #0000002e',
      '&:before': {
        color: '#94C03D',
        fontSize: 14,
        fontWeight: 700,
      },
      '&:hover': {
        '&:before': {
          color: '#649F30',
        },
      },
    },

    '& .react-multiple-carousel__arrow--left': {
      left: 0,
    },
    '& .react-multiple-carousel__arrow--right': {
      right: 0,
    },
  },
}));

const CustomCarousel = (props) => {
  const { children, isEmpty, items } = props;
  const classes = useStyles();

  const responsive =
    !!items && items.length
      ? {}
      : {
          desktop: {
            breakpoint: { max: 10000, min: items ? items.desktop.width : 1520 },
            items: items ? items.desktop.count : 3,
          },
          tablet: {
            breakpoint: {
              max: items ? items.desktop.width : 1520,
              min: items ? items.tablet.width : 768,
            },
            items: items ? items.tablet.count : 2,
          },
          mobile: {
            breakpoint: {
              max: items ? items.tablet.width : 768,
              min: items ? items.mobile.width : 0,
            },
            items: items ? items.mobile.count : 1,
          },
        };

  for (let index in items) {
    const i = +index;
    const max = i === 0 ? 10000 : items[i - 1].width;
    const min = items[i].width;

    responsive[i] = {
      breakpoint: {
        max: max,
        min: min,
      },
      items: items[i].count,
    };

    if (i === items.length - 1) {
      responsive[i + 1] = {
        breakpoint: {
          max: items[i].width,
          min: 0,
        },
        items: 1,
      };
    }
  }

  return (
    <Carousel
      swipeable={false}
      infinite={false}
      draggable={false}
      arrows
      responsive={responsive}
      autoPlay={false}
      keyBoardControl={false}
      containerClass=""
      sliderClass={isEmpty ? classes.w100 : ''}
      className={classes.carousel}
      renderButtonGroupOutside={true}
    >
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
