import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import { Strophe } from 'strophe.js';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, selectedContacts } = props;
  const connectionStatus = useSelector((s) => s.chat.connectionStatus);

  const numSelected = selectedContacts.length;
  if (numSelected > 0) {
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} contacts selected
        </Typography>
        <Tooltip title="Schedule event" placement="top">
          <IconButton
            aria-label="add"
            onClick={() => props.handleScheduleEvent(selectedContacts)}
          >
            <EventIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Create Group" placement="top">
          <IconButton
            aria-label="add"
            onClick={() => props.handleCreateGroup(selectedContacts)}
          >
            <GroupAddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Send email" placement="top">
          <IconButton
            aria-label="add"
            onClick={() =>
              props.setCompose({
                open: true,
                initialState: { receivers: [...selectedContacts] },
              })
            }
          >
            <EmailOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`Delete Item${numSelected > 1 ? 's' : ''}`}
          placement="top"
        >
          <IconButton
            disabled={connectionStatus !== Strophe.Status.CONNECTED}
            aria-label="delete"
            onClick={() => props.handleDeleteContacts(selectedContacts)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        {/*  (
           <Tooltip title="Filter list">
             <IconButton aria-label="filter list">
               <FilterListIcon />
             </IconButton>
           </Tooltip>
         ) */}
      </Toolbar>
    );
  } else {
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      </Toolbar>
    );
  }
};

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  selectedContacts: PropTypes.array.isRequired,
  handleCreateGroup: PropTypes.func.isRequired,
  handleDeleteContacts: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(EnhancedTableToolbar);
