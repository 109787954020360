import React from 'react';
import Button from '@material-ui/core/Button';

const ReportsButton = ({ onClick }) => {
  return (
    <Button variant="outlined" color="primary" onClick={onClick}>
      Reports
    </Button>
  );
};

export default ReportsButton;
