import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { dark, green, light, pink, primary } from './colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: green,
      light: fade(green, 0.2),
      contrastText: primary,
    },
    secondary: {
      main: dark,
      light: fade(dark, 0.2),
      contrastText: light,
    },
    error: {
      main: pink,
      contrastText: '#C70000',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica Neue', 'sans-serif'].join(','),
    fontSize: 14,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          boxSizing: 'border-box',
        },
      },
    },
  },
});

export default theme;
