import React from 'react';

const USAddressLine = ({ data }) => {
  return (
    <>
      {!!data.description && data.description !== data.name && (
        <>
          <strong>{data.description}</strong>
          <br />
        </>
      )}

      {!!data.name && (
        <>
          <strong>{data.name}</strong>
          <br />
        </>
      )}

      {!!data.street_address_1 && (
        <>
          <span>{data.street_address_1}</span>
          <br />
        </>
      )}

      {!!data.street_address_2 && (
        <>
          <span>{data.street_address_2}</span>
          <br />
        </>
      )}

      {(!!data.locality || !!data.admin_area_1 || !!data.postal_code) && (
        <>
          <span>
            {!!data.locality ? `${data.locality},` : ''} {data.admin_area_1}{' '}
            {data.postal_code}
          </span>
          <br />
        </>
      )}

      <span>{data.country_name}</span>
    </>
  );
};

export default USAddressLine;
