import 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Button from '@material-ui/core/Button';
import Calendar from '../../components/Calendar';
import SettingsIcon from '@material-ui/icons/Settings';

import Modal from '../../components/Modal/modal';
import IOSSwitch from '../../components/IOSSwitch';
import SchedulerSetting from './SchedulerSetting';
import EventDetail from './EventDetail';
import { getSchedulerSetting } from '../../redux/actions/scheduler_setting';

import { CALENDAR_VIEWS } from '../../utils/calendar';

const CollaborativeCalendar = (props) => {
  const {
    view,
    schedulerSetting,
    getSchedulerSetting,
    handleViewChange,
  } = props;

  const [date, changeDate] = useState(new Date());
  const [eventType, setEventType] = useState('erase');
  const [settingModalShow, setSettingModalShow] = useState(false);
  const [eventModalShow, setEventModalShow] = useState(false);

  const [eventDetailItem, setEventDetailItem] = useState({
    eventDetail_Name: '',
    eventDetail_Location: { Address: '', Zip: '' },
    eventDetail_Contacts: [],
    eventDetail_StartTime: date,
    eventDetail_EndTime: date,
    eventDetail_Type: 'Video',
    eventDetail_Recurrence: 'None',
    eventDetail_Image: null,
  });

  const [eventDetailData, setEventDetailData] = useState([]);

  const checkEventConflict = (eventItem) => {
    let conflicts = [];
    let conflictsStart, conflictsEnd;

    eventDetailData.map((x, index) => {
      if (
        (x.eventDetail_StartTime.getTime() <
          eventItem.eventDetail_StartTime.getTime() &&
          x.eventDetail_EndTime.getTime() >
            eventItem.eventDetail_StartTime.getTime()) ||
        (eventItem.eventDetail_StartTime.getTime() <
          x.eventDetail_StartTime.getTime() &&
          eventItem.eventDetail_EndTime.getTime() >
            x.eventDetail_StartTime.getTime())
      ) {
        conflictsStart =
          x.eventDetail_StartTime.getTime() >
          eventItem.eventDetail_StartTime.getTime()
            ? x.eventDetail_StartTime
            : eventItem.eventDetail_StartTime;
        conflictsEnd =
          x.eventDetail_EndTime.getTime() <
          eventItem.eventDetail_EndTime.getTime()
            ? x.eventDetail_EndTime
            : eventItem.eventDetail_EndTime;

        conflicts.push({
          From: conflictsStart,
          To: conflictsEnd,
          Title: x.eventDetail_Name,
          Location: x.eventDetail_Location,
          EventImage: x.eventDetail_Image,
        });
      }
      return x;
    });

    return conflicts;
  };

  const addEventDetailItem = (eventItem) => {
    let conflicts = checkEventConflict(eventItem);

    if (conflicts.length === 0) {
      eventDetailData.push(eventItem);
      setEventDetailData(eventDetailData);
    } else {
    }

    return conflicts;
  };

  const onOpenSetting = () => {
    getSchedulerSetting();
    setSettingModalShow(true);
  };
  const onCloseSetting = () => {
    setSettingModalShow(false);
  };

  const onOpenEvent = () => {
    setEventDetailItem({
      ...eventDetailItem,
      eventDetail_Name: eventType === 'erase' ? '' : eventType,
    });
    setEventModalShow(true);
  };

  const loadSchedulerSetting = useCallback(() => {
    getSchedulerSetting();
  }, [getSchedulerSetting]);
  useEffect(() => {
    loadSchedulerSetting();
  }, [loadSchedulerSetting]);

  const ModalContent_SchedulerSetting = () => (
    <div className="calendar-setting-modal">
      <div className="modal-container">
        <SchedulerSetting
          schedulerSetting={schedulerSetting}
          setSettingModalShow={setSettingModalShow}
        />
      </div>
    </div>
  );

  const ModalContent_EventDetail = () => (
    <div className="event-detail-modal">
      <EventDetail
        dateFormatSetting={schedulerSetting.date_format}
        timeFormatSetting={schedulerSetting.time_format}
        addEventDetailItem={addEventDetailItem}
        eventDetailItem={eventDetailItem}
        setEventModalShow={setEventModalShow}
        eventModalShow={eventModalShow}
      />
    </div>
  );

  const onOpenEventFromDragAndDrop = (mDateTime_Start, mDateTime_End) => {
    if (eventType === 'erase') {
      return;
    }

    setEventDetailItem({
      ...eventDetailItem,
      eventDetail_Name: eventType === 'erase' ? '' : eventType,
      eventDetail_StartTime: mDateTime_Start,
      eventDetail_EndTime: mDateTime_End,
    });
    setEventModalShow(true);
  };

  const onCloseEvent = () => {
    setEventModalShow(false);
  };

  const resetEvents = () => {};

  return (
    <div className="calendar_page">
      <div className="calendar_page-switch_view">
        <IOSSwitch
          checked={view === CALENDAR_VIEWS.collaborative ? true : false}
          onChange={() => {
            if (view === CALENDAR_VIEWS.collaborative)
              handleViewChange(CALENDAR_VIEWS.monday);
            else handleViewChange(CALENDAR_VIEWS.monday);
          }}
        />
        <span>Standard View / Collaborative View</span>
      </div>
      <div className="calendar_page-header">
        <div className="calendar_page-header__title">
          Select Week on calendar then choose color to act on by clicking it.
        </div>
        <div className="calendar_page-header__content">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              orientation="landscape"
              variant="static"
              openTo="date"
              disableToolbar={true}
              value={date}
              onChange={changeDate}
            />
          </MuiPickersUtilsProvider>
          <div className="calendar_page-header__boxes">
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area blue-button"
                onClick={() => setEventType('fun')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'fun' && 'selected'
                }`}
              >
                Available For Fun
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area sky-button"
                onClick={() => setEventType('friend')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'friend' && 'selected'
                }`}
              >
                With Friends
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area pink-button"
                onClick={() => setEventType('family')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'family' && 'selected'
                }`}
              >
                With Family
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area brown-button"
                onClick={() => setEventType('working')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'working' && 'selected'
                }`}
              >
                At Work
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area green-button"
                onClick={() => setEventType('work')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'work' && 'selected'
                }`}
              >
                Available For Work
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area red-button"
                onClick={() => setEventType('event')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'event' && 'selected'
                }`}
              >
                Major Event
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area gray-button"
                onClick={() => setEventType('sleep')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'sleep' && 'selected'
                }`}
              >
                I'm A Sleep
              </div>
            </div>
            <div className="calendar_page-header__box">
              <div
                className="calendar_page-header__box-area white-button"
                onClick={() => setEventType('erase')}
              ></div>
              <div
                className={`calendar_page-header__box-title ${
                  eventType === 'erase' && 'selected'
                }`}
              >
                Erase/Reset
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="calendar_page-body">
        <div className="calendar_page-body__title">
          <span>Next, click or paint on the calendar below.</span>
          <SettingsIcon onClick={onOpenSetting} />
        </div>
        <div className="calendar_page-body__content">
          <Calendar
            selectedEventType={eventType}
            date={date}
            schedulerSetting={schedulerSetting}
            setEventDetailItem={setEventDetailItem}
            onOpenEventFromDragAndDrop={onOpenEventFromDragAndDrop}
          />
        </div>
      </div>
      <div className="calendar_page-footer">
        <div className="calendar_page-footer__title"></div>
        <div className="calendar_page-footer__buttons">
          <div className="calendar_page-footer__left">
            <Button variant="contained" color="primary" onClick={onOpenEvent}>
              Add Event
            </Button>
            <Button variant="contained" color="primary" onClick={onOpenEvent}>
              Add Work
            </Button>
            <Button variant="contained" color="primary" onClick={onOpenEvent}>
              Billing
            </Button>
            <Button variant="contained" color="primary" onClick={resetEvents}>
              Reset
            </Button>
          </div>
          <div className="calendar_page-footer__right">
            <Button variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={settingModalShow}
        onClose={onCloseSetting}
        title="Scheduler Setting"
        ModalContent={ModalContent_SchedulerSetting}
      ></Modal>

      <Modal
        open={eventModalShow}
        onClose={onCloseEvent}
        title="Event Detail"
        ModalContent={ModalContent_EventDetail}
        className="event-detail-modal"
        maxWidth={false}
      ></Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  schedulerSetting: state.schedulerSetting.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getSchedulerSetting }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaborativeCalendar);
