import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../components/Modal/modal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { getNodeNames } from '../../../utils/fileshare';
import { putModifyNodes } from '../../../redux/actions/fileshare';
import { makeStyles } from '@material-ui/core/styles';
import { includes } from 'lodash';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Rename = (props) => {
  const {
    show,
    close,
    selectedFile,
    fileTree,
    currentFolderId,
    dispatch,
  } = props;

  const [fileName, setFilename] = useState('');
  const [file, setFile] = useState(null);
  const [isSyncing, setSyncing] = useState(false);
  const [error, setError] = useState(null);

  const prevState = usePrevious({ isSyncing });
  const prevShow = usePrevious(show);

  useEffect(() => {
    // Load only upon opening the modal, ignore updates that happen to file selection afterwards
    console.log('prevShow', prevShow);
    console.log('show', show);
    if (!prevShow && show) {
      console.log('Will set files');
      setFilename(selectedFile.name);
      setFile(selectedFile);
    }
  }, [selectedFile, prevShow, show]);

  useEffect(() => {
    if (prevState && isSyncing === false && prevState.isSyncing === true) {
      close();
    }
  }, [isSyncing, close, prevState]);

  const classes = useStyles();

  const handleRenameClick = async (e) => {
    console.log('rename clicked', file, fileName);
    const { id, isDir, parentId, name, file_id } = file;
    // console.log(selectedFile);
    const siblingNodes = getNodeNames(fileTree, currentFolderId);

    // Do nothing if no changes
    if (fileName === name) {
      setError(null);
      close();
    } else if (fileName === '') {
      setError('Name is required');
    } else if (includes(siblingNodes, fileName)) {
      setError(
        `${
          isDir ? 'Folder' : 'File'
        } with this name already exists, please select another name`
      );
    } else {
      setError(null);
      const form = [
        {
          node_id: id,
          file_id: file_id,
          isDir: isDir,
          parentId: parentId,
          name: fileName,
          isKeepOriginal: false,
          deleteId: null,
        },
      ];
      // console.log(form);
      setSyncing(true);
      await dispatch(putModifyNodes(form));
      setSyncing(false);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleRenameClick();
    }
  };

  const ModalContent = () => (
    <TextField
      fullWidth
      label="Filename"
      variant="outlined"
      color="primary"
      value={fileName}
      autoFocus
      error={Boolean(error)}
      helperText={error ? error : ''}
      onChange={(e) => setFilename(e.target.value)}
      onKeyPress={handleEnterPress}
    />
  );

  const ModalAction = () => (
    <div className={classes.actionsWrapper}>
      <Button
        variant="contained"
        color="secondary"
        className={classes.actionBtn}
        onClick={() => close()}
        disabled={isSyncing}
      >
        Cancel
      </Button>
      {isSyncing ? (
        <Button variant="contained" className={classes.actionBtn} disabled>
          <CircularProgress size={'1.5em'} className={classes.buttonProgress} />
          Renaming...
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          disableRipple
          onClick={handleRenameClick}
        >
          Apply
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      open={show}
      onClose={close}
      title="Rename"
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="add-folder-modal"
    ></Modal>
  );
};

const mapStateToProps = (state) => ({
  fileTree: state.fileshare.main_tree,
});

export default connect(mapStateToProps)(Rename);
