import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ScheduleIcon from './ScheduleIcon';
import PortraitIcon from './PortraitIcon';
import StatisticIcon from './StatisticIcon';
import CommunicationIcon from './CommunicationIcon';
import AddIcon from './AddIcon';
import FileShareSmallIcon from './FileShareSmallIcon';
import SharedIcon from './SharedIcon';
import TrashIcon from './TrashIcon';
import HistoryIcon from './HistoryIcon';
import MailComposeIcon from './MailComposeIcon';
import StarredIcon from './StarredIcon';
import CalendarIcon from './CalendarIcon';
import SmsIcon from '@material-ui/icons/Sms';

import Attachment from '@material-ui/icons/Attachment';
import MailInboxIcon from '@material-ui/icons/Drafts';
import MailStarredIcon from '@material-ui/icons/Stars';
import MailTrashIcon from '@material-ui/icons/Delete';
import MailDraftIcon from './MailDraftIcon';
import MailSentIcon from './MailSent';
import MailSettingsIcon from '@material-ui/icons/SettingsApplications';
import MailArchiveIcon from '@material-ui/icons/Archive';

import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';
import CallIcon from './CallIcon';
// import GroupIcon from './GroupIcon';
import GroupSubMenuIcon from './GroupSubMenuIcon';
import MediaStreamingIcon from './MediaStreamingIcon';
import TelephonyIcon from './TelephonyIcon';
import AddContactIcon from './AddContactIcon';
import LogoIcon from './LogoIcon';
import ComposeIcon from './ComposeIcon';
import MailSendOutIcon from './MailSendOutIcon';
import AttachmentIcon from './AttachmentIcon';
import MailIdkIcon from './MailIdkIcon';
import ArrowDownGray from './ArrowDownGray';
import Dots from './Dots';
import Reply from './Reply';
import ReplyIcon from '@material-ui/icons/Reply';
import ForwardIcon from '@material-ui/icons/Forward';
import MailIcon from '@material-ui/icons/Mail';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PrintIcon from '@material-ui/icons/Print';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
// return svg icon by icon name;

const SVG = ({ name, size }) => {
  switch (name) {
    case 'add':
      return <AddIcon />;
    case 'mailFolder':
      return <FolderOpenIcon />;
    case 'edit':
      return <EditIcon fontSize={size} />;
    case 'check':
      return <CheckIcon fontSize={size} />;
    case 'cancel':
      return <CancelIcon fontSize={size} />;
    case 'addControl':
      return <ControlPointIcon />;
    case 'arrowDownGray':
      return <ArrowDownGray />;
    case 'dots':
      return <Dots />;
    case 'reply':
      return <Reply />;
    case 'fileShareSmall':
      return <FileShareSmallIcon />;
    case 'shared':
      return <SharedIcon />;
    case 'trash':
      return <TrashIcon />;
    case 'history':
      return <HistoryIcon />;
    case 'starred':
      return <StarredIcon size={size} />;
    case 'calendar':
      return <EventAvailableIcon />;
    case 'calendarSubmenu':
      return <CalendarIcon submenu={true} />;
    case 'mailReply':
      return <ReplyIcon />;
    case 'mailForward':
      return <ForwardIcon />;
    case 'print':
      return <PrintIcon />;
    case 'mailInbox':
      return <MailInboxIcon />;
    case 'mailCompose':
      return <MailComposeIcon />;
    case 'mailStarred':
      return <MailStarredIcon />;
    case 'mailDrafts':
      return <MailDraftIcon />;
    case 'mailSent':
      return <MailSentIcon />;
    case 'mailTrash':
      return <MailTrashIcon fontSize={size || 'default'} />;
    case 'mailIdk':
      return <MailIdkIcon />;
    case 'mailArchive':
      return <MailArchiveIcon />;
    case 'mailSettings':
      return <MailSettingsIcon />;
    case 'dashboard':
      return <DashboardIcon />;
    case 'fileShare':
      return <FolderSpecialIcon />;
    case 'mail':
      return <MailIcon />;
    case 'schedule':
      return <ScheduleIcon />;
    case 'transaction':
      return <LocalAtmIcon />;
    case 'video':
      return <VoiceChatIcon />;
    case 'logout':
      return <ExitToAppIcon />;
    case 'portrait':
      return <PortraitIcon />;
    case 'statistic':
      return <StatisticIcon />;
    case 'communication':
      return <CommunicationIcon />;
    case 'communicationSmall':
      return <CommunicationIcon size="small" />;
    case 'telephony':
      return <TelephonyIcon />;
    case 'mediaStreaming':
      return <MediaStreamingIcon />;
    case 'contacts':
      return <PeopleAltIcon />;
    case 'call':
      return <CallIcon />;
    case 'group':
      return <SupervisedUserCircleIcon />;
    case 'groupSubMenu':
      return <GroupSubMenuIcon />;
    case 'chat':
      return <SmsIcon />;
    case 'addContact':
      return <AddContactIcon />;
    case 'logo':
      return <LogoIcon />;
    case 'compose':
      return <ComposeIcon />;
    case 'mailSendOut':
      return <MailSendOutIcon />;
    case 'attachment':
      return <AttachmentIcon />;
    case 'attach':
      return <Attachment />;
    case 'projects':
      return <ListOutlinedIcon />;
    default:
      return '';
  }
};

export default SVG;
