import React, { useState } from 'react';
import FullScreenMailPreview from './FullScreenMailPreview';
import MailPreview from './MailPreview';
import { useMailTableStyles } from './MailTableStyles';

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';

export const MailTable = ({
  list,
  totalCount,
  selectEmail,
  section,
  selectedEmail,
  selectedEmails,
  handlers,
  member,
  selectedXref,
}) => {
  const classes = useMailTableStyles();

  const defaultPerPageCount = 25;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPerPageCount);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (section !== 'Draft') {
      let selectedArr = [];
      let selectedXrefArr = [];
      list
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .forEach((el) => {
          if (selectedArr.indexOf(el.mail_id) === -1) {
            selectedArr.push(el.mail_id);
          }
          selectedXrefArr.push(el.xref_id);
        });
      selectEmail(selectedArr, selectedXrefArr, true);
      return;
    }
  };

  const getItems = () => {
    return list
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((email, index) => {
        const isOwnEmail =
          section === 'Sent' ? true : email.sender_mail === member.email;

        return section === 'Draft' ? (
          <FullScreenMailPreview
            key={`${index}-draft`}
            index={index}
            email={email}
            handlers={handlers}
            section={section}
            selectEmail={selectEmail}
            selectedEmail={selectedEmail}
            selectedEmails={selectedEmails}
            isOwnEmail={isOwnEmail}
          />
        ) : (
          <MailPreview
            key={`${index}-email-table-item`}
            email={email}
            section={section}
            selectEmail={selectEmail}
            selectedEmail={selectedEmail}
            selectedEmails={selectedEmails}
            selectedXref={selectedXref}
            isOwnEmail={isOwnEmail}
          />
        );
      });
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {section !== 'Draft' && (
                  <Checkbox
                    indeterminate={
                      selectedEmails.length > 0 &&
                      selectedEmails.length < rowsPerPage
                    }
                    checked={
                      selectedEmails.length > 0 &&
                      (selectedXref
                        ? selectedXref.length
                        : selectedEmails.length) === rowsPerPage
                    }
                    onChange={handleSelectAll}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                )}
              </TableCell>
              <TableCell>To/From</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>{list && getItems()}</TableBody>
        </Table>
      </TableContainer>

      {totalCount > defaultPerPageCount && (
        <TablePagination
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
