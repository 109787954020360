import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconHelp } from '../icons';
import { injectIntl } from 'react-intl';
const LiveStream = (props) => {
  const {
    intl: { formatMessage },
  } = props;
  const messages = {
    callhelp: {
      id: 'app.home.callhelp',
      defaultMessage: 'Help',
    },
  };
  return (
    <Dropdown.Item eventKey={props.eveneKey}>
      <div className="amera-icon">
        <IconHelp />
      </div>
      <span>{formatMessage(messages.callhelp)}</span>
    </Dropdown.Item>
  );
};

export default injectIntl(LiveStream);
