import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  gutterBottom: { marginBottom: theme.spacing(1) },
  label: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  value: {
    fontSize: theme.typography.body2.fontSize,
  },
  wordBreak: { wordBreak: 'break-all' },
}));
const LabeledValue = (props) => {
  const { label, value, gutterBottom = false, wordBreak = false } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, { [classes.gutterBottom]: gutterBottom })}
    >
      <label className={classes.label}>{label}</label>
      <div className={clsx(classes.value, { [classes.wordBreak]: wordBreak })}>
        {value}
      </div>
    </div>
  );
};

export default LabeledValue;
