import {
  SCHEDULER_SETTING_API_CALL_START,
  SCHEDULER_SETTING_API_CALL_SUCCESS,  
  SCHEDULER_SETTING_API_CALL_FAIL
} from '../actionTypes/schedule.js';

import {
  APP_RESET
} from '../actionTypes/app';

export const initialState = {
  isLoading: false,
  data: {
    date_format: 'MM/DD/YYYY',
    time_format: '24Hr',
    start_time: 9,
    time_interval: 1,
    start_day: 1,
    drag_method: 'Drag_Normal',
  },
};

const schedulerSetting = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case SCHEDULER_SETTING_API_CALL_START:
      return {
        ...state,
        isLoading: true
      };
    case SCHEDULER_SETTING_API_CALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case SCHEDULER_SETTING_API_CALL_FAIL:
      return {
        ...state,
        isLoading: false,
      };          
    default:
      return state;
  }
};

export default schedulerSetting;
