import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import { blue } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { apiUrl } from '../../config/api';
import { loadContacts, setNewRecivers } from '../../redux/actions/contact';
import Modal from './modal';
import { ApiEmailService } from '../../config/apiService';
import ReactQuill from 'react-quill';
import EditorToolbar, {
  formats,
  modules,
} from '../../pages/Email/SharedComponents/Compose/CustomComposeEditor/EditorToolbar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  Dialog: {
    border: 'red',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  btn: {
    marginRight: 20,
    marginLeft: 150,
  },
  btnIconClose: {
    fontSize: 25,
    color: '#94C03D',
  },
  search: {
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: 'rgba(220, 225, 236, 0.46)',
    marginLeft: 20,
    marginRight: 20,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
    opacity: 0.3,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  card: {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    '&:hover': {
      boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.1)',
    },
  },
  cardHeader: {
    width: '100%',
    justifyContent: 'space-between',
  },
}));

function ContactModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose, contacts, loadContacts, forward, mail_id, folder_id } =
    props;
  const [selects, setSelects] = useState([]);
  const [checked, setChecked] = useState(false);
  const [search, setSearch] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [body_note, setBodyNote] = useState('');

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  useEffect(() => {
    setSelects([]);
  }, [props.refresh]);

  const handleClose = () => {
    dispatch(setNewRecivers(selects));
    onClose();
  };

  const handleClickForward = () => {
    if (forward) {
      forwardMail().then(() => {});
    } else {
      dispatch(setNewRecivers(selects));
      props.setNewRecivers(selects);
    }
    onClose();
  };
  const handleClickCard = (item) => {
    let check = selects.find(
      (el) => el.contact_member_id === item.contact_member_id
    );
    if (!check) {
      console.log(item);
      setSelects([
        ...selects,
        {
          email: item.email,
          member: item.member_name,
          contact_member_id: item.contact_member_id,
        },
      ]);
    } else {
      setSelects(
        selects.filter(
          (select) => select.contact_member_id !== item.contact_member_id
        )
      );
    }
  };

  const handleClickCardAll = (event) => {
    if (event.target.checked) {
      let arr = contacts.map((item) => {
        return {
          email: item.email,
          member: item.member_name,
          contact_member_id: item.contact_member_id,
        };
      });
      setSelects(arr);
      setChecked(true);
    } else {
      setSelects([]);
      setChecked(false);
    }
  };

  const forwardMail = async () => {
    if (!mail_id) return;
    const email = {
      mail_id,
      receivers: {
        amera: selects.map((item) => item.contact_member_id),
        external: [],
      },
      body_note,
      folder_id,
    };
    ApiEmailService.postEmail('postForwardEmail', { ...email }).then((res) => {
      setSelects([]);
    });
  };

  const objVals = (data) => {
    let obj = {};
    Object.keys(data).forEach(function (key) {
      let val = data[key];
      obj[val] = val;
    });
    return JSON.stringify(obj);
  };

  const handleSearch = (event) => {
    const search = event.target.value;
    setSearch(search);
    if (!search) {
      setFilteredContacts([]);
      return;
    }

    const filtered = contacts.filter(
      (d) => objVals(d).toLowerCase().indexOf(search.toLowerCase()) !== -1
    );

    if (filtered.length > 0) {
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts([]);
    }
  };

  const handleReactQuillChange = (html, d, s) => {
    setBodyNote(html);
  };

  const _contacts = search ? filteredContacts : contacts;

  const ModalContent = () => {
    return (
      <div>
        <Grid className={classes.header} />
        <Grid>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleSearch}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Grid>
        <Grid>
          <FormControlLabel
            style={{
              flexDirection: 'row-reverse',
              color: '#000000',
              fontSize: 10,
              fontFamily: 'Karla',
              marginLeft: 30,
            }}
            label="Select All"
            control={
              <div>
                <Checkbox
                  checked={checked}
                  color="primary"
                  onClick={(event) => handleClickCardAll(event)}
                  aria-controls="simple-menu"
                />
                <ArrowDropDownIcon
                  style={{ fontSize: 14, marginLeft: -10, marginBottom: -5 }}
                />
              </div>
            }
          />
          <div
            style={{
              color: '#000000',
              fontSize: 12,
              fontFamily: 'Roboto',
              marginLeft: 30,
              marginTop: 10,
            }}
          >
            <Typography>
              {contacts !== undefined ? contacts.length : 0} contacts
            </Typography>
          </div>
        </Grid>
        <List style={{ height: 250, overflowX: 'hidden', overflowY: 'auto' }}>
          {_contacts.map((item) => (
            <Card
              elevation={0}
              variant="outlined"
              className={classes.card}
              key={item.id}
              onClick={(event) => {
                handleClickCard(item);
              }}
            >
              <CardHeader
                className={classes.cardHeader}
                avatar={
                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <Checkbox
                      checked={
                        selects.find(
                          (el) =>
                            el.contact_member_id === item.contact_member_id
                        ) !== undefined
                      }
                      color="primary"
                      aria-controls="simple-menu"
                    />
                    <Avatar
                      className={classes.avatar}
                      src={`${apiUrl}${item.amera_avatar_url}`}
                    />
                  </Grid>
                }
                action={
                  <div
                    style={{
                      opacity: 0.4,
                      marginTop: 20,
                    }}
                  >
                    <Typography>{item.email}</Typography>
                  </div>
                }
                title={<Typography>{item.member_name}</Typography>}
              />
            </Card>
          ))}
        </List>
        {forward && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={'mt-2'}
          >
            <div className="text-editor">
              <div aria-label="editor-toolbar">
                <EditorToolbar />
              </div>
              <ReactQuill
                readOnly={false}
                onChange={handleReactQuillChange}
                modules={modules}
                formats={formats}
              />
            </div>
          </Grid>
        )}
      </div>
    );
  };

  const ModalAction = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{
          margin: 25,
          textTransform: 'none',
          justifyContent: 'flex-start',
        }}
        onClick={() => handleClickForward()}
      >
        <Typography style={{ marginRight: 180, fontSize: 12 }}>
          {selects.length} Select
        </Typography>
        <Typography>{forward ? 'Forward' : 'Submit'}</Typography>
      </Button>
    );
  };
  return (
    <Modal
      open={true}
      onClose={handleClose}
      title={'Select contacts'}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      fullWidth={true}
      maxWidth={'md'}
      // ModalAction={ModalAction}
    />
    // <Dialog
    //   fullWidth={true}
    //   maxWidth={'sm'}
    //   onClose={handleClose}
    //   open={open}
    //   PaperProps={{
    //     style: {
    //       border: '1px solid #94C03D',
    //     },
    //   }}
    // >
    //   <DialogTitle
    //     handleClose={handleClose}
    //     handleClickCardAll={handleClickCardAll}
    //     checked={checked}
    //     contacts={_contacts}
    //     onChange={handleSearch}
    //   />
    //   <List style={{ height: 300, overflow: 'hidden', overflowY: 'scroll' }}>
    //     {_contacts.map((item) => (
    //       <Card
    //         elevation={0}
    //         variant="outlined"
    //         className={classes.card}
    //         key={item.id}
    //       >
    //         <CardHeader
    //           avatar={
    //             <Grid style={{ display: 'flex', flexDirection: 'row' }}>
    //               <Checkbox
    //                 checked={
    //                   selects.filter(
    //                     (select) =>
    //                       select.contact_member_id === item.contact_member_id
    //                   ).length !== 0
    //                     ? true
    //                     : false
    //                 }
    //                 onClick={(event) =>
    //                   handleClickCard(item, event, item.contact_member_id)
    //                 }
    //                 checkedIcon={<MailCheckboxFull />}
    //                 style={{
    //                   color: 'green',
    //                 }}
    //                 icon={<MailCheckbox />}
    //                 aria-controls="simple-menu"
    //               />
    //               <Avatar
    //                 className={classes.avatar}
    //                 src={`${apiUrl}${item.amera_avatar_url}`}
    //               />
    //             </Grid>
    //           }
    //           action={
    //             <div
    //               style={{
    //                 opacity: 0.4,
    //                 marginTop: 20,
    //               }}
    //             >
    //               <Typography>{item.email}</Typography>
    //             </div>
    //           }
    //           title={<Typography>{item.member_name}</Typography>}
    //         />
    //       </Card>
    //     ))}
    //   </List>
    //   <Button
    //     variant="contained"
    //     color="primary"
    //     style={{
    //       margin: 25,
    //       textTransform: 'none',
    //       justifyContent: 'flex-start',
    //     }}
    //     onClick={() => handleClickForward()}
    //   >
    //     <Typography style={{ marginRight: 180, fontSize: 12 }}>
    //       {selects.length} Select
    //     </Typography>
    //     <Typography>{forward ? 'Forward' : 'Submit'}</Typography>
    //   </Button>
    // </Dialog>
  );
}

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadContacts,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
