import React from 'react';
import DenseTabs from '../../../components/DenseTabs/DenseTabs';
import DenseTab from '../../../components/DenseTabs/DenseTab';
import { INVITE_MODE } from '../../../utils/calendar';

const InviteModeSwitch = (props) => {
  const { inviteMode, handleChange } = props;
  return (
    <DenseTabs
      value={inviteMode}
      onChange={handleChange}
      indicatorColor="primary"
    >
      <DenseTab value={INVITE_MODE.contacts} label="Contacts" />
      <DenseTab value={INVITE_MODE.group} label="Groups" />
    </DenseTabs>
  );
};

export default InviteModeSwitch;
