import React, { useState } from 'react';
import Task from './Task';
import MileStone from './MileStone';
import TreeView from '@material-ui/lab/TreeView';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ProjectManagementRow from './ProjectManagementRow';
import Icon from '@material-ui/core/Icon';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import TreeItem from '@material-ui/lab/TreeItem';
import StyledEpicTreeItem from './StyledEpicTreeItem';
import InfoIcon from '@material-ui/icons/Info';

const ProjectTree = ({ projectId, data, members }) => {
  let nodeIds =
    data &&
    data.map((i) => {
      return `tree-item-${i.project_element_id}`;
    });
  const [expanded, setExpanded] = useState(nodeIds);

  if (!data) return null;

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const updateExpanded = (nodeId) => {
    let updatedExpand = [];
    if (expanded && Array.isArray(expanded)) {
      updatedExpand = updatedExpand.concat(expanded);
    }
    updatedExpand.push(`tree-item-${nodeId}`);
    console.log(
      'updateExpanded',
      expanded,
      updatedExpand,
      `tree-item-${nodeId}`
    );
    setExpanded(updatedExpand);
  };

  return (
    <TreeView
      expanded={expanded}
      onNodeToggle={handleToggle}
      defaultCollapseIcon={
        <Icon classes={{ root: 'row-icon' }} color="primary">
          <ArrowDropDownCircleOutlinedIcon />{' '}
        </Icon>
      }
      defaultExpandIcon={
        <Icon classes={{ root: 'row-icon' }} color="primary">
          {' '}
          <PlayCircleOutlineOutlinedIcon />{' '}
        </Icon>
      }
      defaultExpanded={nodeIds}
    >
      {data?.map((item) => {
        return (
          <div
            className={`tree-row tree-row-${item.element_type}`}
            key={item.project_element_id}
          >
            {/*<div><small>{item.project_element_id}</small></div>*/}
            {item.element_type !== 'task' &&
              item.element_type !== 'milestone' && (
                <ProjectManagementRow
                  projectId={projectId}
                  parent_id={item.parent_id}
                  element_id={item.project_element_id}
                  element_title={item.title}
                  element_description={item.description}
                  element_type={item.element_type}
                  rate_type={item.rate_type}
                  updateExpanded={updateExpanded}
                />
              )}

            <TreeItem
              nodeId={`tree-item-${item.project_element_id}`}
              label={
                <StyledEpicTreeItem
                  projectId={projectId}
                  element_id={item.project_element_id}
                  element_type={item.element_type}
                  title={item.title}
                  rate_type={item.rate_type}
                  labelIcon={InfoIcon}
                  taskRow={
                    <>
                      {item.element_type === 'task' && (
                        <Task
                          projectId={projectId}
                          parent_id={item.parent_id}
                          element_id={item.project_element_id}
                          task_title={item.title}
                          task_description={item.description}
                          est_hours={item.est_hours}
                          element_status={item.status_history}
                          member_id={item.project_member_id}
                          members={members}
                          time_tracking={item.element_time}
                          comments={item.element_notes}
                        />
                      )}
                      {item.element_type === 'milestone' && (
                        <MileStone
                          projectId={projectId}
                          parent_id={item.parent_id}
                          element_id={item.project_element_id}
                          milestone_title={item.title}
                          milestone_description={item.description}
                          member_id={item.project_member_id}
                          members={members}
                          rate={item.est_rate}
                          currencyId={item.currency_code_id}
                          due_date={item.due_date}
                          comments={item.element_notes}
                          element_status={item.status_history}
                        />
                      )}
                    </>
                  }
                />
              }
            >
              <ProjectTree
                projectId={projectId}
                data={item.childNodes}
                members={members}
              />
            </TreeItem>
          </div>
        );
      })}
    </TreeView>
  );
};

export default ProjectTree;
