import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.5rem',
    background: '#DBFFE9',
  },
  label: {
    color: '#67C57D',
    lineHeight: 1,
  },
}));

const CompletedChip = () => {
  const classes = useStyles();
  return <Chip label="Completed" classes={classes} />;
};

export default CompletedChip;
