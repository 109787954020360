import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import ContactPreview from '../../components/ContactPreview';

import Toolbar from './Toolbar';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  videoRect: {
    flex: '1',
    backgroundColor: theme.palette.common.black,
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    borderBottomLeftRadius: theme.spacing(2),
  },
  remoteVideoWrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  remoteVideoPlayer: {
    maxWidth: '100%',
    height: '100%',
    minWidth: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  localVideoWrapper: {
    position: 'absolute',
    height: '25%',
    right: '52px',
    top: '0px',
  },
  localVideoPlayer: {
    height: '100%',
  },
  footer: {
    backgroundColor: '#333333',
    height: '64px',
    width: '100%',
    borderBottomLeftRadius: theme.spacing(2),
  },
  contactPreviewWrapper: {
    background: '#000000c2',
    padding: '10px',
    borderRadius: '10px',
    position: 'absolute',
    top: '0px',
    color: 'white',
    width: '350px',
    '& .MuiGrid-item': {
      padding: 0,
    },
  },
  localContactPreviewWrapper: {
    background: '#000000c2',
    padding: '10px',
    borderRadius: '10px',
    position: 'absolute',
    top: '0px',
    left: '0px',
    color: 'white',
    width: '250px',
    '& .MuiGrid-item': {
      padding: 0,
    },
    '& > .MuiGrid-container': {
      '& > .MuiGrid-container': {
        marginLeft: theme.spacing(1),
      },
    },
  },
});

const useStyles = makeStyles(styles);

const SecondScreenMode = (props) => {
  const classes = useStyles();

  const { localVideoRef, remoteVideoRef } = props;

  const remoteMemberInfo = useSelector(
    (state) => state.one2onevcall.memberInfo
  );
  // const memberInfo = useSelector((state) => state.member.member);

  return (
    <div className={classes.root}>
      <Toolbar {...props} />
      <div className={classes.videoRect}>
        <div className={classes.remoteVideoWrapper}>
          <video
            autoPlay
            playsInline
            ref={remoteVideoRef}
            className={classes.remoteVideoPlayer}
            me="true"
          />
          {remoteMemberInfo && (
            <div className={classes.contactPreviewWrapper}>
              <ContactPreview
                contactData={remoteMemberInfo}
                isShowAvatar={true}
              />
            </div>
          )}
          <div className={classes.localVideoWrapper}>
            <video
              autoPlay
              playsInline
              ref={localVideoRef}
              className={classes.localVideoPlayer}
              me="true"
              muted="muted"
            />
            {/* {memberInfo && (
              <div className={classes.localContactPreviewWrapper}>
                <ContactPreview contactData={memberInfo} isShowAvatar={true} />
              </div>
            )} */}
          </div>
        </div>
      </div>
      {/* <div className={classes.footer}></div> */}
    </div>
  );
};

export default injectIntl(SecondScreenMode);
