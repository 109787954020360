import React from 'react';
import { makeStyles } from '@material-ui/core';

import { borderDark } from '../../../../jss/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px',
    opacity: 0.49,
    width: '96%',
    alignSelf: 'center',
    userSelect: 'none',
  },
  border: {
    borderBottom: `2px solid ${borderDark}`,
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 400,
    fontSize: 14,
    color: '#84868B',
  },
}));

const MessageDivider = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};
export default MessageDivider;
