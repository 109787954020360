import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import RegistrationBgImage from '../../assets/img/login-image.png';

const useStyles = makeStyles((theme) => ({
  leftbgSection: {
    textAlign: 'center',
    backgroundImage: `url(${RegistrationBgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%'
  },
  bgTitle: {
    width: '100%',
    height: '100%',
    background: '#94c03d',
    opacity: '90%',
    padding: '50vh 0',
    color: '#fff',
  },
  title: {
    fontWeight: 600
  }
}));

const LeftBackgroundImageContainer = (props) => {
  const classes = useStyles();
  
  return (
    <div className={classes.leftbgSection}>
      <div className={classes.bgTitle}>
        <Typography variant="h4" className={classes.title}>
          Create Account
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          with us
        </Typography>
      </div>
    </div>
  )
};

export default LeftBackgroundImageContainer;