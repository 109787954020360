import React from 'react';
// import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import Card from './Card';
import CardButtons from '../../components/Dashboard/CardButtons';
import { displayableFullName } from '../../utils/contact';
import { defaultDateTimeZone } from '../../utils/calendar';

const useStyles = makeStyles(() => ({
  content: {
    maxHeight: 170,
    overflow: 'auto',
  },
}));

const TextMailCard = ({ email }) => {
  const classes = useStyles();
  const history = useHistory();

  const title = 'New Email';
  const subject = email.subject.replace(/<[^>]+>/g, '');
  const message = email.message.replace(/<[^>]+>/g, '');
  const name = !!email.first_name ? `${displayableFullName(email)}` : null;

  const handleOpen = () => {
    const identifier = email.id;
    if (identifier) {
      history.push({
        pathname: `mail/inbox/${identifier}`,
      });
    }
  };

  const action = (
    <span>
      {/* {`${format(new Date(email.create_date), 'p')} ${format(
        new Date(email.create_date),
        'PPP'
      )}`} */}
      {(email.create_date && defaultDateTimeZone(email.create_date)) || ''}
    </span>
  );

  const avatar = {
    first_name: email.first_name,
    last_name: email.last_name,
    url: email.amera_avatar_url,
  };

  const buttons = (
    <CardButtons
      type="text_mail"
      okText={'Open Email'}
      okHandle={() => handleOpen()}
    />
  );

  const content = (
    <div className={`${classes.content} box-scrollbar-style`}>{message}</div>
  );

  return (
    <Card
      title={title}
      action={action}
      avatar={avatar}
      subTitle={name}
      subHeader={subject}
      content={content}
      buttons={buttons}
    />
  );
};

export default TextMailCard;
