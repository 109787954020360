import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
// import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ListItem from '@material-ui/core/ListItem';
import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
import Modal from '../../components/Modal/modal';
import IOSSwitch from '../../components/IOSSwitch';
// import LocationsList from './ProfileEdit/LocationsList/LocationsList';
import { validateProfileSettingForm } from '../../utils/validator/Profile';
import { putMemberSetting, getMemberSetting } from '../../redux/actions/member';
import { languageNames, languageCodes } from '../../utils/language';
import { changeLocale } from '../../redux/actions/app';
// import { orderBy } from 'lodash';
import OutgoingCallerSetting from '../../components/Setting/OutgoingCallerSetting';
import TimezoneList from './InputElements/TimezoneList';
import DateFormat from './InputElements/DateFormat';
import TimeFormat from './InputElements/TimeFormat';

// const shortid = require('shortid');

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '27%',
  },
  // dateFormats: {
  //   width: '150px',
  //   padding: theme.spacing(1),
  // },
}));

// const dateFormats = [
//   'MM/DD/YYYY',
//   'MM/DD/YY',
//   'YYYY/MM/DD',
//   'YY/MM/DD',
//   'DD/MM/YYYY',
//   'DD/MM/YY',
// ];

// const timeSystemFormats = [
//   'AM/PM',
//   '24Hr'
// ]

const Settings = (props) => {
  const {
    open,
    onClose,
    memberSetting,
    isLoading,
    countryList,
    // remote_timezone_name,
    locale,
    dispatch,
  } = props;

  const title = 'Settings';
  const classes = useStyles();
  const [state, setState] = useState({
    online_status: true,
    view_profile: true,
    add_contact: true,
    join_date: true,
    login_location: true,
    unit_of_measure: 'imperial',
    date_format: 'MM/DD/YYYY',
    time_format: 'AM/PM',
    start_day: 1,
    timezone_id: null,
  });
  // const [yourActivity, setYourActivity] = useState(true);
  // const [otherProfile, setOtherProfile] = useState(true);
  // const [locationData, setLocationData] = useState([]);
  // const [billingLocationData, setBillingLocationData] = useState([]);
  const [outgoingCallerContact, setOutgoingCallerContact] = useState('');

  const [errors, setErrors] = useState({});
  const [language, setLanguage] = useState('');
  const { contactInformation, twilioVerificationModalStatus } = useSelector(
    (state) => ({
      contactInformation: state.member.memberInfo.contact_information,
      twilioVerificationModalStatus: state.event.twilioVerificationModalStatus,
    })
  );

  useEffect(() => {
    if (open) dispatch(getMemberSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setLanguage(locale);
  }, [locale]);

  useEffect(() => {
    if (
      contactInformation &&
      countryList &&
      !twilioVerificationModalStatus &&
      !outgoingCallerContact
    ) {
      const contact = contactInformation.find((ci) => {
        if (ci.outgoing_caller && ci.outgoing_caller_verified) return true;
        return false;
      });
      setOutgoingCallerContact(contact);
    }
  }, [
    contactInformation,
    countryList,
    twilioVerificationModalStatus,
    outgoingCallerContact,
  ]);

  // Parse existing info into state
  useEffect(() => {
    // const { location_information } = memberSetting;

    // if (location_information && location_information[0]) {
    // setLocationData(
    //   orderBy(
    //     location_information
    //       .filter((item) => item.location_type === 'other')
    //       .map((lio, idx) => ({
    //         id: lio.id,
    //         description: lio.description,
    //         address_1: lio.address_1,
    //         address_2: lio.address_2,
    //         location_type: lio.location_type,
    //         postal: lio.postal,
    //         province: lio.province ? lio.province : '',
    //         state: lio.state ? lio.state : '',
    //         city: lio.city,
    //         country: lio.country_code_id,
    //         display_order: idx, //TODO: replace this display_order from database when we have one
    //       })),
    //     ['display_order']
    //   )
    // );
    // setBillingLocationData(
    //   orderBy(
    //     location_information
    //       .filter((item) => item.location_type === 'billing')
    //       .map((lio, idx) => ({
    //         id: lio.id,
    //         description: lio.description,
    //         address_1: lio.address_1,
    //         address_2: lio.address_2,
    //         location_type: lio.location_type,
    //         postal: lio.postal,
    //         province: lio.province ? lio.province : '',
    //         state: lio.state ? lio.state : '',
    //         city: lio.city,
    //         country: lio.country_code_id,
    //         display_order: idx, //TODO: replace this display_order from database when we have one
    //       })),
    //     ['display_order']
    //   )
    // );
    // }
    if (memberSetting) {
      setState((ps) => ({ ...ps, ...memberSetting }));
    }
  }, [memberSetting]);

  // const handleLocationChanges = (type) => (change) => {
  //   const { id, name, value } = change;

  //   if (type === 'other') {
  //     setLocationData((prev) => {
  //       const currentLocationData = prev.find((ldo) => ldo.id === id);
  //       const restLocationData = prev.filter((ldo) => ldo.id !== id);
  //       const newLocationState = orderBy(
  //         restLocationData.concat([{ ...currentLocationData, [name]: value }]),
  //         ['display_order']
  //       );
  //       return newLocationState;
  //     });
  //   } else {
  //     // setBillingLocationData((prev) => {
  //     //   const currentLocationData = prev.find((ldo) => ldo.id === id);
  //     //   const restLocationData = prev.filter((ldo) => ldo.id !== id);
  //     //   const newLocationState = orderBy(
  //     //     restLocationData.concat([{ ...currentLocationData, [name]: value }]),
  //     //     ['display_order']
  //     //   );
  //     //   return newLocationState;
  //     // });
  //   }
  // };

  // const handleAutoCompleteChange = (type) => (change) => {
  //   const { id, city, state, province, street, postal } = change;
  //   // console.log('autocomplete data', id, city, state, province, street, postal);
  //   if (type === 'other') {
  //     setLocationData((prev) => {
  //       const currentLocationData = prev.find((ldo) => ldo.id === id);
  //       const restLocationData = prev.filter((ldo) => ldo.id !== id);
  //       const newLocationState = orderBy(
  //         restLocationData.concat([
  //           {
  //             ...currentLocationData,
  //             city: city,
  //             state: state,
  //             province: province,
  //             postal: postal,
  //             street: street,
  //             address_1: street,
  //           },
  //         ]),
  //         ['display_order']
  //       );
  //       return newLocationState;
  //     });
  //   } else {
  //     // setBillingLocationData((prev) => {
  //     //   const currentLocationData = prev.find((ldo) => ldo.id === id);
  //     //   const restLocationData = prev.filter((ldo) => ldo.id !== id);
  //     //   const newLocationState = orderBy(
  //     //     restLocationData.concat([
  //     //       {
  //     //         ...currentLocationData,
  //     //         city: city,
  //     //         state: state,
  //     //         province: province,
  //     //         postal: postal,
  //     //         street: street,
  //     //         address_1: street,
  //     //       },
  //     //     ]),
  //     //     ['display_order']
  //     //   );
  //     //   return newLocationState;
  //     // });
  //   }
  // };

  // const handleLocationAddlick = (type) => () => {
  //   if (type === 'other') {
  //     setLocationData((prev) => {
  //       if (!prev || prev.length === 0) {
  //         return [
  //           {
  //             id: shortid.generate(),
  //             description: '',
  //             address_1: '',
  //             address_2: '',
  //             location_type: 'other',
  //             postal: '',
  //             province: '',
  //             state: '',
  //             city: '',
  //             display_order: 1,
  //             country: 840,
  //           },
  //         ];
  //       } else {
  //         // Mind the maxiumum ammount of emails

  //         const maxLocations = process.env.REACT_APP_MAX_LOCATIONS | 3;
  //         if (prev.length + 1 <= maxLocations) {
  //           // Put it last in the  list
  //           const displayOrder =
  //             Math.max(prev.map((ldo) => ldo.display_order)) + 1;
  //           return [
  //             ...prev,
  //             {
  //               id: shortid.generate(),
  //               description: '',
  //               address_1: '',
  //               address_2: '',
  //               location_type: 'other',
  //               postal: '',
  //               province: '',
  //               state: '',
  //               city: '',
  //               display_order: displayOrder,
  //               country: 840,
  //             },
  //           ];
  //         }
  //       }
  //     });
  //   } else {
  //     // setBillingLocationData((prev) => {
  //     //   if (!prev || prev.length === 0) {
  //     //     return [
  //     //       {
  //     //         id: shortid.generate(),
  //     //         description: '',
  //     //         address_1: '',
  //     //         address_2: '',
  //     //         location_type: 'other',
  //     //         postal: '',
  //     //         province: '',
  //     //         state: '',
  //     //         city: '',
  //     //         display_order: 1,
  //     //         country: 840,
  //     //       },
  //     //     ];
  //     //   } else {
  //     //     // Mind the maxiumum ammount of emails
  //     //     const maxLocations = 1;
  //     //     if (prev.length + 1 <= maxLocations) {
  //     //       // Put it last in the  list
  //     //       const displayOrder =
  //     //         Math.max(prev.map((ldo) => ldo.display_order)) + 1;
  //     //       return [
  //     //         ...prev,
  //     //         {
  //     //           id: shortid.generate(),
  //     //           description: '',
  //     //           address_1: '',
  //     //           address_2: '',
  //     //           location_type: 'billing',
  //     //           postal: '',
  //     //           province: '',
  //     //           state: '',
  //     //           city: '',
  //     //           display_order: displayOrder,
  //     //           country: 840,
  //     //         },
  //     //       ];
  //     //     }
  //     //   }
  //     // });
  //   }
  // };

  // List handlers
  // const handleListItemDelete = (type) => (itemId, listType, sorter) => {
  //   if (type === 'other') {
  //     setLocationData((prev) => {
  //       const newState = orderBy(
  //         prev.filter((lo) => lo.id !== itemId),
  //         sorter
  //       );
  //       return newState;
  //     });
  //   } else {
  //     // setBillingLocationData((prev) => {
  //     //   const newState = orderBy(
  //     //     prev.filter((lo) => lo.id !== itemId),
  //     //     sorter
  //     //   );
  //     //   return newState;
  //     // });
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const mixedLocationData = [...locationData];

    const formErrors = validateProfileSettingForm({
      ...state,
      // locationData: mixedLocationData,
      outgoingCallerContact,
    });
    if (state && Object.keys(formErrors).length === 0) {
      setErrors({});
      // setSubmitting(true);
      // Values formatted and groupoed by table for easier insert
      const formattedForm = {
        member_profile: {
          ...state,
          // timezone_id: timezone ? timezone.tz_id : null,
        },
        // member_location: mixedLocationData
        //   ? mixedLocationData.map((ldo) => ({
        //       id: ldo.id,
        //       description: ldo.description,
        //       city: ldo.city,
        //       state: ldo.state,
        //       province: ldo.province,
        //       postal: ldo.postal,
        //       address_1: ldo.address_1,
        //       address_2: ldo.address_2,
        //       street: ldo.address_1,
        //       // It can be done in subquery, also can be done here
        //       country: countryList.find((clo) => clo.id === ldo.country).name,
        //       country_code_id: ldo.country,
        //       location_type: ldo.location_type,
        //     }))
        //   : null,
      };
      if (outgoingCallerContact) {
        formattedForm.outgoingCallerContact = outgoingCallerContact.id;
      }
      await dispatch(putMemberSetting(formattedForm));
      dispatch(changeLocale(language));
      // onClose();
    } else {
      setErrors(formErrors);
      return false;
    }
  };

  // const handleBoolChange = (e) => {
  //   e.persist();
  //   setState((ps) => ({
  //     ...ps,
  //     [e.target.name]: e.target.checked,
  //   }));
  // };

  const ModalContent = () => (
    <>
      <div className="profile_page-body">
        <div className="profile_page-settings">
          {/* <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Your Activity</div>
              <ButtonGroup toggle>
                <ToggleButton
                  className="btn-green--bg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  checked={yourActivity}
                  onClick={() => setYourActivity(true)}
                >
                  Visible
                </ToggleButton>
                <ToggleButton
                  className="btn-green--bg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  checked={!yourActivity}
                  onClick={() => setYourActivity(false)}
                >
                  Hidden
                </ToggleButton>
              </ButtonGroup>
            </div>
            <Grid container className="mt-3">
              <Grid item xs={6}>
                <div className="page_section-row align-center">
                  <div className="section-row_label--small">
                    Online Status / LastActiveDate
                  </div>
                  <IOSSwitch
                    name="online_status"
                    checked={state.online_status}
                    onChange={handleBoolChange}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="page_section-row align-center">
                  <div className="section-row_label--small">
                    When You View a Profile
                  </div>
                  <IOSSwitch
                    name="view_profile"
                    checked={state.view_profile}
                    onChange={handleBoolChange}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="page_section-row align-center">
                  <div className="section-row_label--small">
                    When You add a Contact
                  </div>
                  <IOSSwitch
                    name="add_contact"
                    checked={state.add_contact}
                    onChange={handleBoolChange}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Other Profile Information</div>
              <ButtonGroup toggle>
                <ToggleButton
                  className="btn-green--bg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  checked={otherProfile}
                  onClick={() => setOtherProfile(true)}
                >
                  Visible
                </ToggleButton>
                <ToggleButton
                  className="btn-green--bg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  checked={!otherProfile}
                  onClick={() => setOtherProfile(false)}
                >
                  Hidden
                </ToggleButton>
              </ButtonGroup>
            </div>
            <Grid container>
              <Grid item xs={6}>
                <div className="page_section-row align-center">
                  <div className="section-row_label--small">Join Date</div>
                  <IOSSwitch
                    name="join_date"
                    checked={state.join_date}
                    onChange={handleBoolChange}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="page_section-row align-center">
                  <div className="section-row_label--small">
                    Recent Login Location
                  </div>
                  <IOSSwitch
                    name="login_location"
                    checked={state.login_location}
                    onChange={handleBoolChange}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">
                Preferred Measurement System
              </div>
              <ButtonGroup toggle>
                <ToggleButton
                  className="btn-green--bg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  checked={state.unit_of_measure === 'imperial'}
                  onClick={() =>
                    setState((ps) => ({ ...ps, unit_of_measure: 'imperial' }))
                  }
                >
                  Imperial
                </ToggleButton>
                <ToggleButton
                  className="btn-green--bg"
                  type="radio"
                  variant="secondary"
                  name="radio"
                  checked={state.unit_of_measure === 'metric'}
                  onClick={() =>
                    setState((ps) => ({ ...ps, unit_of_measure: 'metric' }))
                  }
                >
                  Metric
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div> */}
          <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Choose language</div>
            </div>
            <div className="language-selector">
              <Select
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value);
                }}
                fullWidth
              >
                {languageCodes.map((code, index) => (
                  <MenuItem value={code} key={code}>
                    {languageNames[index]}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">
                Choose a number to make call in Amerashare
              </div>
            </div>
            <OutgoingCallerSetting
              outgoingCallerContact={outgoingCallerContact}
              setOutgoingCallerContact={setOutgoingCallerContact}
              error={errors.outgoingCallerContact}
            />
          </div>
          {/* <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Location Lists</div>
            </div>
            <LocationsList
              errors={errors}
              locationItems={locationData}
              handleAdd={handleLocationAddlick('other')}
              handleDelete={handleListItemDelete('other')}
              handleChange={handleLocationChanges('other')}
              handleAutocomplete={handleAutoCompleteChange('other')}
              otherTypeOnly={true}
            />
          </div> */}
          <div className="profile_page-section">
            <TimezoneList
              timezone_id={state.timezone_id}
              onChange={(tz) => {
                setState((ps) => ({
                  ...ps,
                  timezone_id: tz ? tz.tz_id : null,
                }));
              }}
            />
            <Grid container>
              <Grid item xs={6}>
                <DateFormat
                  value={state.date_format}
                  onChange={(e) => {
                    e.persist();
                    setState((ps) => ({
                      ...ps,
                      date_format: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TimeFormat
                  value={state.time_format}
                  onChange={(e) => {
                    e.persist();
                    if (!e.target.checked)
                      setState((ps) => ({ ...ps, time_format: 'AM/PM' }));
                    else setState((ps) => ({ ...ps, time_format: '24Hr' }));
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <div className="page_section-row align-center">
                  <div className="section-row_label--small">
                    First Day of Week (Sunday/Monday)
                  </div>
                  <IOSSwitch
                    name="start_day"
                    checked={state.start_day === 1}
                    onChange={(event) => {
                      event.persist();
                      setState((ps) => ({
                        ...ps,
                        start_day: event.target.checked === true ? 1 : 0,
                      }));
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Billing Address</div>
            </div>
            <LocationsList
              errors={errors}
              locationItems={billingLocationData}
              handleAdd={handleLocationAddlick('billing')}
              handleDelete={handleListItemDelete('billing')}
              handleChange={handleLocationChanges('billing')}
              handleAutocomplete={handleAutoCompleteChange('billing')}
              billingOnly={true}
            />
          </div> */}

          {/* <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Banking (ACH and Swift)</div>
            </div>
          </div>
          <div className="profile_page-section">
            <div className="page_section-row flex-column">
              <div className="section-row_label">Payments</div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );

  const ModalAction = () => {
    return (
      <div className="actions-wrapper">
        <Button
          variant="contained"
          color="secondary"
          className="action-button"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="action-button"
          disableRipple
          onClick={(e) => handleSubmit(e)}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress
              size={'1.5em'}
              className={classes.buttonProgress}
            />
          ) : null}
          {isLoading ? 'Updating' : 'Update'}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="profile-modal"
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  memberSetting: state.member.memberSetting,
  // remote_timezone_name: state.member.remote_timezone_name,
  countryList: state.member.countryList,
  isLoading: state.member.isLoading,
  timezoneList: state.member.timezoneList,
  locale: state.global.locale,
});

export default connect(mapStateToProps)(Settings);
