export const routeUtils = (function () {
  // If you use simple key=value query strings and
  // you do not need to support IE 11, you can use
  // the browser's built-in URLSearchParams API.
  // urlQueryString is useLocation().search from react-router-dom
  function useQuery(urlQueryString) {
    return new URLSearchParams(urlQueryString);
  }

  return {
    useQuery,
  };
})();
