import { dark, textBlue, textDark, green } from '../colors';

const headerStyle = (theme) => ({
  navBar: {
    color: textDark,
    fontSize: '22px',
    '& a': {
      color: textDark,
      textDecoration: 'none',
    },
  },
  profileDropdown: {
    '& .MuiButton-label': {
      color: textBlue,
      fontSize: '24px',
      fontFamily: 'Roboto',
      whiteSpace: 'pre-line',
      lineHeight: '100%',
      textTransform: 'capitalize',
      width: 'max-content',
    },
  },
  inviteMemberButton: {
    height: 70,
    marginRight: 30,
    textTransform: 'none',
    borderRadius: '7px',
    padding: '25px',
    color: 'white',
    fontSize: '18px',
    maxWidth: '192px',
    letterSpacing: '0.36px',
    fontWeight: 'bolder',
    boxShadow: '0px 5px 15px #A1ABC699',
  },
  closeDropdown: {
    backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    color: '#848ac1',
  },
  logo: {
    padding: '28px 24.8px',
    borderRight: '1.9px solid #ebeffa',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  popoverWrapper: {
    '& .MuiPopover-paper': {
      borderRadius: '0px 0px 8px 8px',
      borderTop: 'none',
    },
    '& .MuiPaper-elevation8': {
      boxShadow:
        '0px 2px 5px -3px rgba(0,0,0,0.2), 0px 11px 10px 1px rgba(0,0,0,0.14), 0px 14px 14px 2px rgba(0,0,0,0.12)',
    },
  },
  profileDropdownMenu: {
    display: 'flex',
    flexDirection: 'row',
    color: dark,
    padding: '10px',
    flexWrap: 'wrap',
  },
  iconNotifications: {
    cursor: 'pointer',
  },
  profileIconOnline: {
    width: '20%',
    '& svg': {
      fill: green,
    },
  },
  profileIconOffline: {
    width: '20%',
    '& svg': {
      fill: dark,
    },
  },
  menuListWrapper: {
    width: '80%',
    color: dark,
    fontSize: '16px',
    textAlign: 'right',
    '& a': {
      color: dark,
      textDecoration: 'none',
      fontSize: '16px',
      whiteSpace: 'nowrap',
    },
    '& .MuiListItem-root': {
      justifyContent: 'flex-end',
      borderBottom: `1px solid ${dark}`,
    },
    '& .MuiListItem-root:last': {
      borderBottom: 'none',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: theme.spacing(0),
    },
  },
  closeButtonWrapper: {
    width: '100%',
    textAlign: 'end',
  },
  callOptionWrapper: {
    borderRadius: '8px',
    paddingLeft: '5px',
    paddingRight: '5px',
    width: '184px',
    color: '#5F5F5F',
    fontSize: '13px',
    fontWeight: 'normal',
    '& .MuiDivider-root': {
      marginLeft: '16px',
    },
  },
  callOptionPopver: {
    '& .MuiPopover-paper': {
      marginTop: '5px',
    },
  },
});

export default headerStyle;
