import React, { useState, useCallback } from 'react';
import Modal from '../../components/Modal/modal';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
// import CheckIcon from '@material-ui/icons/Check';
// import Input from '@material-ui/core/Input';
// import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
// import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { getValidatePromoCode } from '../../redux/actions/member';

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: theme.spacing(1),
    width: '100%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

const PromoModal = (props) => {
  const { promoCode, setPromoCode } = props;
  const [isEmptySubmit, setIsEmptySubmit] = useState(false);
  const [confirmedCode, setConfirmedCode] = useState({
    description: '',
    promo_code_id: null,
    isConfirmed: false,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const { show, close, onPromoActivation, dispatch } = props;
  const title = 'Do you have a Promo Code?';
  const classes = useStyles();

  // Reset on close
  const handleClose = useCallback(() => {
    // setPromoCode('');
    setAlert({
      open: false,
      severity: 'success',
      message: '',
    });
    close();
  }, [close]);

  const handleCodeInput = (event) => {
    event.preventDefault();
    setPromoCode(event.target.value);
  };
  const handleCheckCode = async () => {
    if (promoCode.length === 0) {
      setIsEmptySubmit(true);
      //   Will show error with helper text
    } else {
      setIsEmptySubmit(false);
      // console.log('Checking code');
      setAlert({ open: false, severity: 'success', message: '' });
      await setIsFetching(true);
      const result = await dispatch(getValidatePromoCode(promoCode));
      await setIsFetching(false);
      if (result.success === true) {
        setConfirmedCode({
          description: result.description,
          promo_code_id: result.promo_code_id,
          isConfirmed: true,
        });
        setAlert({
          open: true,
          severity: 'success',
          message: 'Congratulations, promo code is confirmed',
        });
      } else if (result.success === false) {
        setAlert({
          open: true,
          severity: 'warining',
          message: 'We are sorry, the code you entered is not valid or expired',
        });
      } else if (result instanceof Error) {
        setAlert({
          open: true,
          severity: 'error',
          message: 'Something went wrong, pleae try again later.',
        });
      }
    }
  };

  const handleActivateClick = () => {
    if (confirmedCode.isConfirmed) {
      onPromoActivation(confirmedCode.promo_code_id);
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleDeleteConfirmedClick = () =>
    setConfirmedCode({
      description: '',
      promo_code_id: null,
      isConfirmed: false,
    });

  const handleClearClick = () => {
    setAlert({ open: false });
    setPromoCode('');
  };

  const ModalContent = () => (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          If you have an AMERA Share Promo Code, please enter it into the Input
          field below.
        </Typography>
      </Grid>

      <Grid
        container
        className="input-container"
        spacing={0}
        justify="space-evenly"
        alignItems="center"
      >
        <Grid item>
          <FormControl>
            {/* <InputLabel className htmlFor="promo-code">Promo Code</InputLabel> */}
            <OutlinedInput
              id="promo-code"
              variant="outlined"
              type="text"
              value={promoCode}
              onChange={handleCodeInput}
              autoFocus
              placeholder="Promo code goes here"
              // startAdornment={confirmedCode.isConfirmed ? <CheckIcon /> : null}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClearClick}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {isEmptySubmit && (
            <FormHelperText error>
              'Please enter a value before submitting'
            </FormHelperText>
          )}
        </Grid>
        <Grid item>
          {isFetching ? (
            <Button variant="text" color="primary" disableRipple disabled>
              <CircularProgress
                size={'1.5em'}
                className={classes.buttonProgress}
              />
              Checking..
            </Button>
          ) : (
            <Button
              variant="text"
              color="primary"
              disableRipple
              onClick={handleCheckCode}
            >
              Check
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid className="alert-block">
        {/* Alert */}
        <Collapse in={alert.open}>
          <Alert
            variant="standard"
            severity={alert.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert({ open: false });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert.message}
          </Alert>
        </Collapse>
      </Grid>

      {/* Promo code information */}
      {confirmedCode.isConfirmed ? (
        <>
          <Grid item xs={12} className="applied-typography">
            <Typography>Code applied</Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              className="promo-code-chip"
              variant="outlined"
              label={
                <>
                  <Typography
                    component="span"
                    className="promo-code-name"
                  >{`${promoCode}`}</Typography>
                  <Typography component="span">{`${confirmedCode.description}`}</Typography>
                </>
              }
              onDelete={handleDeleteConfirmedClick}
              color="secondary"
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography>No code applied.</Typography>
        </Grid>
      )}
    </Grid>
  );
  const ModalAction = () => (
    <Button
      variant="contained"
      color="primary"
      className={classes.submitBtn}
      disableRipple
      onClick={handleActivateClick}
    >
      {confirmedCode.isConfirmed ? 'Activate code' : 'Close'}
    </Button>
  );

  return (
    <Modal
      open={show}
      onClose={handleClose}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="promo-modal"
    ></Modal>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PromoModal);
