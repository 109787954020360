import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';

import AchievementItem from './AchievementItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flexStart',
    flexWrap: 'wrap',
    maxHeight: '120px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(148, 192, 61, 1)',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px rgb(220,220,220)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '10px',
    },
  },
}));

const AchievementItemList = (props) => {
  const { achievementData } = props;
  const classes = useStyles();

  const achievements = useMemo(
    () =>
      orderBy(achievementData, ['display_order'], ['asc']).map((ad) =>
        ad ? <AchievementItem key={ad.id} achievementItem={ad} /> : null
      ),
    [achievementData]
  );

  return (
    <>
      <p className="title">Achievements:</p>
      <div className={classes.root}>
        <div className={classes.root}>
          {achievementData.length > 0 ? achievements : 'N/A'}
        </div>
      </div>
    </>
  );
};

export default AchievementItemList;
