import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Strophe } from 'strophe.js';
// import ReactFlagsSelect from 'react-flags-select';
import Settings from '../../Profile/Settings';
import Integrations from '../../Profile/Integrations';
import Notifications from '../../Profile/Notifications';
import Payments from '../../Profile/Payments';
import ReportBugs from '../../Profile/ReportBugs';
import CallNotification from './CallNotification';
import MaterialAvatar from '../../../components/MaterialAvatar';
import MemberInviteModal from '../../../components/Modal/MemberInviteModal';
import { injectIntl } from 'react-intl';
import { logout } from '../../../redux/actions/member';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
// import { countryCodes, languageCodes } from '../../../utils/language';
// import { changeLocale } from '../../../redux/actions/app';
import CommunicationIcon from '../../../components/Icons/CommunicationIcon';
import SVG from '../../../components/Icons/SVG';
import styles from '../../../jss/views/headerStyle';
import ErrorBoundary from '../../../components/ErrorBoundary';

import { getNotificationsSetting } from '../../../redux/actions/member';
import { openDrawer } from '../../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE, CALL_TARGET } from '../../../utils/drawer';

const useStyles = makeStyles(styles);

export const messages = {
  profile: {
    id: 'app.header.menu.my-profile',
    defaultMessage: 'My Profile',
  },
  locations: {
    id: 'app.header.menu.my-locations',
    defaultMessage: 'My Locations',
  },
  inviteMember: {
    id: 'app.header.menu.invite-member',
    defaultMessage: 'Invite User',
  },
  logout: {
    id: 'app.header.menu.logout',
    defaultMessage: 'Log Out',
  },
  upcomingVideoCall: {
    id: 'app.header.upcomingVideoCall',
    defaultMessage: 'Upcoming Video Call',
  },
  profileSettings: {
    id: 'app.settings.profile.title',
    defaultMessage: 'Settings',
  },
  profileIntegration: {
    id: 'app.integration.profile.title',
    defaultMessage: 'Integrations',
  },
  notificationSettings: {
    id: 'app.settings.notification.title',
    defaultMessage: 'Notifications',
  },
  paymentSettings: {
    id: 'app.settings.payment.title',
    defaultMessage: 'Payments',
  },
  securitySettings: {
    id: 'app.settings.security.title',
    defaultMessage: 'Security Preferences',
  },
  passwordManager: {
    id: 'app.password.manager.title',
    defaultMessage: 'Password and Login Manager',
  },
  statistic: {
    id: 'app.statistic.title',
    defaultMessage: 'History and Analytics',
  },
  reportBugs: {
    id: 'app.reportBugs.title',
    defaultMessage: 'Report Bugs',
  },
  help: {
    id: 'app.header.help',
    defaultMessage: 'Help',
  },
};

const Header = (props) => {
  const { memberState, connectionStatus } = useSelector((state) => ({
    memberState: state.member,
    connectionStatus: state.chat.connectionStatus,
    // locale: state.global.locale,
  }));
  const dispatch = useDispatch();

  const { member, memberInfo, session_id } = memberState;
  const {
    intl: { formatMessage },
  } = props;

  const classes = useStyles();

  const MENU_POPOVER = 'MENU_POPOVER';
  const CALL_OPTION_POPOVER = 'CALL_OPTION_POPOVER';
  // const NOTIFICATIONS_POPOVER = 'NOTIFICATIONS_POPOVER';
  const [currentPopoverInfo, setCurrentPopoverInfo] = useState({
    openedPopoverId: null,
    anchorEl: null,
  });

  const [showInviteModal, setShowInviteModal] = useState(false);
  // const [showEventModal, setShowEventModal] = useState(false);
  const [showProfileSettingsModal, setShowProfileSettingsModal] =
    useState(false);
  const [showIntegrationsModal, setShowIntegrationsModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  // const [scopedCallId, setScopedCallId] = useState(null);
  // const [
  //   showSecuritiyInformationModal,
  //   setShowSecuritiyInformationModal,
  // ] = useState(false);
  const [showPaymentSettingModal, setShowPaymentSettingModal] = useState(false);
  const [showReportBugsModal, setShowReportBugsModal] = useState(false);

  const onLogout = () => {
    dispatch(logout(member.member_id, session_id));
  };

  // const changeLanguage = (countryCode) => {
  //   const index = countryCodes.findIndex((x) => x === countryCode);
  //   dispatch(changeLocale(languageCodes[index]));
  // };

  // const country = countryCodes[languageCodes.findIndex((x) => x === locale)];

  if (!session_id || !member) {
    return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
  }

  const handlePopoverOpen = (event, popoverId) => {
    setCurrentPopoverInfo({
      openedPopoverId: popoverId,
      anchorEl: event.target.closest('.header-items'),
    });
  };

  const handleCallPopoverOpen = (event, popoverId, callType) => {
    setCurrentPopoverInfo({
      openedPopoverId: popoverId,
      anchorEl: event.currentTarget,
      callType,
    });
  };

  const handlePopoverClose = () => {
    setCurrentPopoverInfo({
      openedPopoverId: null,
      anchorEl: null,
    });
  };

  const openNotificationsSettingModal = async () => {
    let success = await dispatch(getNotificationsSetting());
    success && setShowNotificationModal(true);
  };

  const openReportBugsModal = (e) => {
    e.preventDefault();
    handlePopoverClose();
    setShowReportBugsModal(true);
  };

  // const handleCallClick = (call_id) => {
  //   console.log('call_id in handker', call_id);
  //   setScopedCallId(call_id);
  //   setShowEventModal(true);
  // };

  const handleDrawer = (type, contentProps = {}) => {
    dispatch(openDrawer(type, contentProps));
  };

  const handleShowProfileClick = () => {
    handleDrawer(DRAWER_CONTENT_TYPE.myProfileInfo);
  };

  const handleShowLocationsClick = () => {
    handleDrawer(DRAWER_CONTENT_TYPE.locationManager);
  };

  return (
    <ErrorBoundary>
      <React.Fragment>
        <div className="header">
          <div className={classes.logo}>
            <SVG name="logo" />
          </div>
          <div className="header-items">
            <CallNotification />
            <div className="drawer-triggers">
              <Button
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  handleCallPopoverOpen(e, CALL_OPTION_POPOVER, 'audio_call');
                }}
              >
                Audio Call
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  handleCallPopoverOpen(e, CALL_OPTION_POPOVER, 'video_call');
                }}
              >
                Video Conference
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  handleDrawer(DRAWER_CONTENT_TYPE.newsfeeds);
                }}
              >
                Newsfeeds
              </Button>
            </div>
            <div className={classes.navBar}>
              {/* <ReactFlagsSelect
                className="select-language"
                countries={countryCodes}
                customLabels={{
                  US: 'English',
                  JP: 'Japanese',
                  KR: 'Korean',
                  CN: 'Chinese',
                  DE: 'German',
                  ES: 'Spanish',
                }}
                defaultCountry={country}
                onSelect={changeLanguage}
              /> */}

              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowInviteModal(true)}
                className={classes.inviteMemberButton}
              >
                Invite Contact
              </Button>

              <MaterialAvatar
                member_id={memberInfo.member_id}
                src={memberInfo.amera_avatar_url}
                classes="header"
                firstName={memberInfo.first_name}
                lastName={memberInfo.last_name}
              />
              <div className={classes.profileDropdown}>
                <Button onClick={(e) => handlePopoverOpen(e, MENU_POPOVER)}>
                  {memberInfo.first_name}
                  <br />
                  {memberInfo.last_name}
                  <ArrowDropDownIcon style={{ color: '#8EA4C6' }} />
                </Button>
              </div>
            </div>
          </div>
          <Popover
            id={MENU_POPOVER}
            open={currentPopoverInfo.openedPopoverId === MENU_POPOVER}
            anchorEl={currentPopoverInfo.anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className={classes.popoverWrapper}
          >
            <div className={classes.profileDropdownMenu}>
              <div
                className={
                  member.user_type === 'administrator' &&
                  connectionStatus === Strophe.Status.CONNECTED
                    ? classes.profileIconOnline
                    : classes.profileIconOffline
                }
              >
                <CommunicationIcon />
              </div>
              <div className={classes.menuListWrapper}>
                <List component="nav" aria-label="secondary mailbox folders">
                  <ListItem button onClick={handleShowProfileClick}>
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.profile)}
                    </Link>
                  </ListItem>

                  <ListItem button onClick={handleShowLocationsClick}>
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.locations)}
                    </Link>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => setShowProfileSettingsModal(true)}
                  >
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.profileSettings)}
                    </Link>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => openNotificationsSettingModal()}
                  >
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.notificationSettings)}
                    </Link>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => setShowPaymentSettingModal(true)}
                  >
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.paymentSettings)}
                    </Link>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      handleDrawer(DRAWER_CONTENT_TYPE.securitySettings);
                    }}
                  >
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.securitySettings)}
                    </Link>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => setShowIntegrationsModal(true)}
                  >
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.profileIntegration)}
                    </Link>
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      handleDrawer(DRAWER_CONTENT_TYPE.passwordAndLoginManager);
                    }}
                  >
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.passwordManager)}
                    </Link>
                  </ListItem>

                  <ListItem button>
                    <Link to={`${process.env.PUBLIC_URL}/statistic`}>
                      {formatMessage(messages.statistic)}
                    </Link>
                  </ListItem>
                  {/* <ListItem button onClick={() => setShowInviteModal(true)}>
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        handlePopoverClose();
                      }}
                    >
                      {formatMessage(messages.inviteMember)}
                    </Link>
                  </ListItem> */}
                  <ListItem
                    button
                    onClick={openReportBugsModal}
                    className={classes.listItembtn}
                  >
                    {formatMessage(messages.reportBugs)}
                  </ListItem>
                  <ListItem button onClick={() => onLogout(true)}>
                    {formatMessage(messages.logout)}
                  </ListItem>
                </List>
              </div>
              <div className={classes.closeButtonWrapper}>
                <IconButton
                  aria-label="close"
                  className={classes.closeDropdown}
                  onClick={handlePopoverClose}
                >
                  <ExpandLessIcon />
                </IconButton>
              </div>
            </div>
          </Popover>
          <Popover
            id={CALL_OPTION_POPOVER}
            open={currentPopoverInfo.openedPopoverId === CALL_OPTION_POPOVER}
            anchorEl={currentPopoverInfo.anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className={classes.callOptionPopver}
          >
            <div className={classes.callOptionWrapper}>
              <List component="nav" aria-label="call options">
                <ListItem
                  button
                  onClick={() => {
                    handleDrawer(currentPopoverInfo.callType, {
                      callTarget: CALL_TARGET.contact,
                    });
                    handlePopoverClose();
                  }}
                >
                  Contact call
                </ListItem>
                <Divider />
                <ListItem
                  button
                  onClick={() => {
                    handleDrawer(currentPopoverInfo.callType, {
                      callTarget: CALL_TARGET.group,
                    });
                    handlePopoverClose();
                  }}
                >
                  Group call
                </ListItem>
              </List>
            </div>
          </Popover>
        </div>

        {showInviteModal && (
          <MemberInviteModal
            open={showInviteModal}
            onClose={() => setShowInviteModal(false)}
          />
        )}
        {/* <UpcomingEvent
        open={showEventModal}
        callId={scopedCallId}
        close={() => {
          setShowEventModal(false);
          setScopedCallId(null);
        }}
      /> */}
        {showProfileSettingsModal && (
          <Settings
            open={showProfileSettingsModal}
            onClose={() => setShowProfileSettingsModal(false)}
          />
        )}
        {showIntegrationsModal && (
          <Integrations
            open={showIntegrationsModal}
            onClose={() => setShowIntegrationsModal(false)}
          />
        )}
        {showNotificationModal && (
          <Notifications
            open={showNotificationModal}
            onClose={() => setShowNotificationModal(false)}
          />
        )}
        {showPaymentSettingModal && (
          <Payments
            open={showPaymentSettingModal}
            onClose={() => setShowPaymentSettingModal(false)}
          ></Payments>
        )}
        {
          <ReportBugs
            open={showReportBugsModal}
            setOpen={setShowReportBugsModal}
          />
        }
      </React.Fragment>
    </ErrorBoundary>
  );
};

Header.propTypes = {
  changeLocale: PropTypes.func,
  // locale: PropTypes.string,
};

export default injectIntl(Header);
