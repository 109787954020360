import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SVG from '../Icons/SVG';
import { textNewGray } from '../../jss/colors';

const useStyles = makeStyles((theme) => ({
  darkButton: {
    color: textNewGray,
    maxHeight: 35,
    minHeight: 35,
    maxWidth: 35,
    minWidth: 35,
    marginRight: 10,
  },
}));

export const PopoverWithIcon = ({ icon, handleSubmit, tooltip }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [folderName, setFolderName] = React.useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFolderName('');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton onClick={handleClick} className={classes.darkButton}>
          <SVG name={icon} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper style={{ padding: 20 }}>
          <Typography>Enter name of new folder :</Typography>
          <TextField
            value={folderName}
            onChange={(e) => {
              setFolderName(e.currentTarget.value);
            }}
            placeholder="Name of folder"
          />
          <Typography style={{ marginTop: 10 }}>
            <Button
              onClick={() => {
                handleSubmit(folderName);
                handleClose();
              }}
              color="primary"
              variant="outlined"
            >
              save
            </Button>
          </Typography>
        </Paper>
      </Popover>
    </>
  );
};
