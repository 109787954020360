import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';

import ShowMoreText from 'react-show-more-text';

import { DEFAULT_PARAMS, PAGE_SIZE_OPTIONS } from '../../../utils/activity';

const headCells = [
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'Message',
    disableSort: true,
  },
  {
    id: 'update_date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    disableSort: false,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    disableSort: true,
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.disableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 45,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  inputInput: {
    paddingLeft: theme.spacing(2.5),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { searchKey, setSearchKey, handleSearch } = props;

  const onSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchKey);
    }
  };

  return (
    <Toolbar className={classes.root}>
      <SearchIcon />
      <InputBase
        placeholder="Search…"
        classes={{
          // root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        onKeyDown={(e) => onSearchKeyPress(e)}
      />
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  container: {
    maxHeight: '100%',
  },
  table: {
    maxWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionButton: {
    padding: theme.spacing(0.5),
  },
}));

const useTableCellStyles = makeStyles((theme) => ({
  body: {
    color: 'rgba(76, 154, 96, 1) !important',
  },
}));

const NotificationTable = (props) => {
  const classes = useStyles();
  const cellClasses = useTableCellStyles();

  const { type, data, totalCount, getCustomizedProperties, loadActivities } =
    props;
  const [order, setOrder] = useState(DEFAULT_PARAMS.order);
  const [orderBy, setOrderBy] = useState(DEFAULT_PARAMS.orderBy);
  const [searchKey, setSearchKey] = useState(DEFAULT_PARAMS.search_key);
  const [selected, setSelected] = useState([]);

  const [pageNumber, setPageNumber] = useState(DEFAULT_PARAMS.page_number);
  const [pageSize, setPageSize] = useState(DEFAULT_PARAMS.page_size);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadActivities(type, {
      order: newOrder,
      orderBy: property,
      searchKey,
      pageNumber,
      pageSize,
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      setPageNumber(newPage);
      loadActivities(type, {
        order,
        orderBy,
        searchKey,
        pageNumber: newPage,
        pageSize,
      });
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const newPageSize = parseInt(e.target.value, 10);
    setPageSize(newPageSize);
    setPageNumber(0);
    loadActivities(type, {
      order,
      orderBy,
      searchKey,
      pageNumber: 0,
      pageSize: newPageSize,
    });
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          handleSearch={(value) =>
            loadActivities(type, {
              order,
              orderBy,
              searchKey: value,
              pageNumber,
              pageSize,
            })
          }
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalCount}
            />
            <TableBody>
              {data.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                const { message, message2, date_time, action_func, isSender } =
                  getCustomizedProperties(type, row);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.row_id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      style={{ minWidth: 400 }}
                      classes={isSender && cellClasses}
                    >
                      <ShowMoreText
                        lines={1}
                        more="more"
                        less="less"
                        expanded={false}
                        width={380}
                      >
                        {`${message} ${message2}`}
                      </ShowMoreText>
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 180 }}>
                      {date_time}
                    </TableCell>
                    <TableCell align="center" style={{ width: 74 }}>
                      <IconButton
                        edge="end"
                        aria-label="action"
                        className={classes.actionButton}
                        onClick={action_func}
                      >
                        <PlayCircleFilledRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          component="div"
          count={totalCount}
          rowsPerPage={pageSize}
          page={pageNumber}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default NotificationTable;
