import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Row, Col } from 'react-bootstrap';

import Layout from '../Sections/Layout';
import withLanguageDrawer from '../Sections/LanguageDrawer/withLanguageDrawer';
import { messages } from '../Sections/Layout/index';
// import queryString from 'query-string';
// import NavigationBlocker from './NavigationBlocker';
import Conference from '../../components/Conference';
const drawerMessages = { ...messages };

const VideoCall = (props) => {
  return (
    <Layout {...props}>
      {/* <NavigationBlocker navigationBlocked={true} /> */}
      <Conference />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLanguageDrawer(VideoCall, drawerMessages));
