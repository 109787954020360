import parsePhoneNumber from 'libphonenumber-js';
import { capitalize } from 'lodash';
import { parseISO, format, isValid, formatDistanceStrict } from 'date-fns';
import { defaultDateTimeZone } from './calendar';

export const DEVICE_TYPES = {
  email: 'email',
  cell: 'cell',
  landline: 'landline',
  TDD: 'TDD',
  other: 'other',
};

export const DEVICE_METHOD = {
  text: 'text',
  html: 'html',
  sms: 'sms',
  voice: 'voice',
  other: 'other',
};

export const formatPhoneNumber = (countryPhonecode, number) => {
  if (!number || String.prototype.trim.call(number) === '') {
    return '';
  }
  const nationalPhoneNumber = parsePhoneNumber(
    `+${countryPhonecode}${number}`
  ).formatNational();
  return `+${countryPhonecode} ${nationalPhoneNumber}`;
};

export const getContactDevice = (contact, device_type) => {
  if (!contact.contact_information) {
    return;
  }

  const device = contact.contact_information
    .filter(Boolean)
    .find((info) => info.description === device_type);

  if (!device) {
    return;
  }

  const country = contact.country_code
    .filter(Boolean)
    .find((info) => info.id === device.device_country);
  return formatPhoneNumber(country.phone, device.device);
};

export const getContactLocation = (contact, location_type) => {
  if (!contact.location_information) {
    return;
  }
  return contact.location_information
    .filter(Boolean)
    .find((info) => info.location_type === location_type);
};

export const displayableFullName = (c, includeMiddle = true) => {
  const middleNamePortion = !!c.middle_name
    ? ` ${capitalize(c.middle_name)}`
    : '';
  return `${capitalize(c.first_name)}${
    includeMiddle ? middleNamePortion : ''
  } ${capitalize(c.last_name)}`;
};

export const formatDuration = (start, end, isAddDuration = false) => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  const getDuration = (startDate, endDate) => {
    const end = isValid(endDate) ? endDate : new Date();
    return formatDistanceStrict(startDate, end, { includeSeconds: false });
  };

  const durationPortion = ` | ${getDuration(startDate, endDate)}`;

  const str = `${format(startDate, 'LLL yyyy')} - ${
    isValid(endDate) ? format(endDate, 'LLL yyyy') : 'Present'
  }${isAddDuration ? durationPortion : ''}`;

  return str;
};

export const formatReceived = (date) =>
  `Received on ${(date && defaultDateTimeZone(date, 'date')) || ''}`;
