import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import InputBase from '@material-ui/core/InputBase';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import MaterialAvatar from '../../../components/MaterialAvatar';
import { textCapitalized } from '../../../utils/general';
import { Tooltip } from '@material-ui/core';
import { getMemberInfo } from '../../../redux/actions/member';
// import AmeraDataCard from '../../../components/AmeraDataCard';
// import AddressLine from '../../../components/AddressLine';

const useStyles = makeStyles((theme) =>
  createStyles({
    settingsButton: {
      padding: theme.spacing(0, 1, 0, 1),
      marginTop: theme.spacing(-1),
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: fade(theme.palette.common.white, 1),
      borderRadius: 5,
    },
    inputSearch: {
      marginLeft: theme.spacing(1),
      flex: 1,
      fontFamily: 'Roboto',
    },
    searchIcon: {
      padding: theme.spacing(1.2, 1.2, 0.5, 1.2),
    },
  })
);

const ProfileTitle = ({ firstName, lastName, companyName, memberTitle }) => (
  <div className="profile_title-container">
    <p className="profile-name">{`${textCapitalized(
      firstName
    )} ${textCapitalized(lastName)}`}</p>
    <p className="profile-company">{companyName}</p>
    <p className="profile-role">{memberTitle}</p>
    {/* <p className="profile-role">{memberInfo.role}</p> */}
    {/* <p className="profile-location">{memberAddress}</p> */}
  </div>
);

function MyProfile() {
  const classes = useStyles();

  // @ts-ignore
  const memberInfo = useSelector((state) => state.member.memberInfo);
  // @ts-ignore
  // const jobTitleList = useSelector((state) => state.member.jobTitleList);

  const dispatch = useDispatch();

  React.useEffect(() => {
    try {
      // load member info
      // @ts-ignore
      dispatch(getMemberInfo());
    } catch (e) {
      console.error('failed to load archives.', e);
    }
  }, [dispatch]);

  const {
    first_name = '',
    last_name = '',
    member_id,
    company_name = '',
    // company_membership,
    job_title = '',
    // location_information,
  } = memberInfo;

  const onSearch = (e) => {
    const searchKey = e.target.value;
    console.log('searching user ==> ', searchKey);
  };

  return (
    <Card className="chat-my-profile">
      <CardHeader
        className="chat-profile-card-header"
        avatar={
          <MaterialAvatar
            badgeType="none"
            classes="chat-my-profile-avatar"
            member_id={member_id}
            firstName={first_name}
            lastName={last_name}
          />
        }
        action={
          <Tooltip title="More">
            <IconButton
              aria-label="settings"
              className={classes.settingsButton}
            >
              <MoreHoriz className="chat-settings-icon" />
            </IconButton>
          </Tooltip>
        }
        title={
          <ProfileTitle
            // memberInfo={memberInfo}
            firstName={first_name}
            lastName={last_name}
            companyName={company_name}
            memberTitle={job_title}
          />
        }
      />
      <CardContent className="chat-profile-card-content">
        <div className={classes.searchContainer}>
          <div className={classes.search}>
            <InputBase
              className={classes.inputSearch}
              placeholder="Search"
              onChange={onSearch}
              inputProps={{ 'aria-label': 'search user chats' }}
            />
            <div aria-label="search-icon" className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default MyProfile;
