import React, { useEffect, useCallback } from 'react';
import Modal from '../../components/Modal/modal';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { postOutgoingCallerVerification } from '../../redux/actions/member';
import { OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL } from '../../redux/actionTypes/event';
import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: theme.spacing(1),
    width: '100%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
  alertWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  retryBtnWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

const VerificationModal = (props) => {
  const {
    show,
    close,
    outgoingCallerContact,
    setOutgoingCallerContact,
    phoneNumber,
  } = props;

  const title = 'Outgoing phone verification';
  const classes = useStyles();

  const dispatch = useDispatch();
  const twilioVerificationModalData = useSelector(
    (state) => state.event.twilioVerificationModalData
  );
  // Reset on close
  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const [validationCode, setValidationCode] = React.useState();
  const [alert, setAlert] = React.useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const getCode = useCallback(async () => {
    const result = await dispatch(
      postOutgoingCallerVerification(outgoingCallerContact.id, phoneNumber)
    );
    console.log('outgoing request', result);
    if (result.success) {
      setValidationCode(result.validation_code);
    } else {
      setValidationCode(undefined);
      setAlert({
        open: true,
        severity: 'error',
        message: 'Something went wrong. Try later!',
      });
    }
  }, [dispatch, outgoingCallerContact, phoneNumber]);

  useEffect(() => {
    if (
      twilioVerificationModalData &&
      outgoingCallerContact.id === twilioVerificationModalData.contact_id
    ) {
      setOutgoingCallerContact((ps) => ({
        ...ps,
        outgoing_caller_verified:
          twilioVerificationModalData.verification_status === 'success'
            ? true
            : false,
      }));
      setAlert({
        open: true,
        message: `Your number ${twilioVerificationModalData.number} was ${
          twilioVerificationModalData.verification_status === 'success'
            ? ''
            : 'not'
        } verified`,
        severity:
          twilioVerificationModalData.verification_status === 'success'
            ? 'success'
            : 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioVerificationModalData]);

  useEffect(() => {
    getCode();
    return () => {
      dispatch({
        type: OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL,
        payload: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ModalContent = () => (
    <Grid container>
      {!alert.open && (
        <>
          {!validationCode ? (
            <>
              <Grid item xs={9}>
                <Typography>You will get verification code soon.</Typography>
              </Grid>
              <Grid item xs={3}>
                <CircularProgress size={'2em'} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} justify="center">
                <Typography align="center">
                  Twilio is calling: {phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} justify="center">
                <Typography align="center">
                  Answer the call and enter this verification code:
                </Typography>
              </Grid>
              <Grid item xs={12} justify="center">
                <Typography variant="h4" color="primary" align="center">
                  {validationCode}
                </Typography>
              </Grid>
            </>
          )}
        </>
      )}
      <div className={classes.alertWrapper}>
        {alert.open && (
          <Alert severity={alert.severity}>
            <AlertTitle>
              {alert.severity === 'success' ? 'Success' : 'Error'}
            </AlertTitle>
            <strong>{alert.message}</strong>
          </Alert>
        )}
      </div>
      {alert.open && alert.severity === 'error' && (
        <div className={classes.retryBtnWrapper}>
          <IconButton
            color="primary"
            onClick={() => {
              setValidationCode('');
              setAlert({
                open: false,
              });
              dispatch({
                type: OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL,
                payload: null,
              });
              getCode();
            }}
          >
            <ReplayIcon />
          </IconButton>
        </div>
      )}
    </Grid>
  );

  const ModalAction = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        disableRipple
        onClick={handleClose}
      >
        Go back
      </Button>
    );
  };
  return (
    <Modal
      open={show}
      onClose={handleClose}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="otp-verification-modal"
    ></Modal>
  );
};

export default VerificationModal;
