export const sleepy = async (milliseconds = 20) => {
  await new Promise((r) => setTimeout(r, milliseconds));
};

export const dumps = (anobj) => {
  return JSON.stringify(anobj, null, 2);
};

// these are video constraints that may be requested.  add or delete as desired

export const videoResolutionConstraints = {
  default: {},
  'very low res': { width: 256, height: 200 },
  'low res': { width: 320, height: 240 },
  'medium res': { width: 1280, height: 720 },
  'high res': { width: 1920, height: 1080 },
  'very high res': { width: 2560, height: 1600 },
};

/**
 * This creates a stream from media options.  The user is prompted to slect screens, windows,
 *  applications or browser tabs to create a stream from.  The user can deny permission, so
 *  the browser ingerity of not supplhying personal info wihout user consent is preserved
 * @param {*} displayMediaOptions
 */
export const startDisplay = async (displayMediaOptions) => {
  if (displayMediaOptions === null)
    displayMediaOptions = { video: {}, audio: true };

  try {
    return await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
  } catch (err) {
    console.error('Error: ' + err);
    return null;
  }
};

export const str2ab = (str) => {
  let buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  let bufView = new Uint16Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

export const toHexString = (byteArray) => {
  return Array.prototype.map
    .call(byteArray, function (byte) {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    })
    .join('');
};

export const ab2str = (buf) => {
  return toHexString(String.fromCharCode.apply(null, new Uint8Array(buf)));
};

export const stopStream = (stream) => {
  if (stream)
    stream.getTracks().forEach((track) => {
      track.stop();
    });
};
