import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import CountryInput from '../../pages/Registration/InputElements/CountryInput';
import CompanyNameInput from '../../pages/Registration/InputElements/CompanyNameInput';

import Modal from './modal';

import { validateInviteMemberForm } from '../../utils/validator/Group';
import { USER_TYPE } from '../../utils/general';

import {
  sendMemberContactInvite,
  setReduxInviteAlert,
  removeInviteAlert,
} from '../../redux/actions/invite';
import { loadCompanies } from '../../redux/actions/company';

const defaultCountryCode = 'us';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  submitBtn: {
    margin: theme.spacing(1),
    width: '25%',
  },
  alert: {
    margin: theme.spacing(1),
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  memberInviteFormFields: {
    border: 0,
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MemberInviteModal = (props) => {
  const {
    member,
    dispatch,
    open,
    onClose,
    roles,
    group_id,
    loading,
    inviteAlert,
    countryList,
  } = props;
  // const [role, setRole] = React.useState('');

  // const handleChangeRole = (event) => {
  //   setRole(event.target.value);
  // };

  const defaultValues = {
    country: 840,
    role: '',
    company: null,
    confirmPhoneRequired: false,
    // isApplicable: true,
  };
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(false);
  const title = 'Send Invite';
  const classes = useStyles();

  const closeModal = () => {
    inviteAlert.show = false;
    dispatch(removeInviteAlert());
    setIsError(false);
    setErrors({});
    // setRole('');
    onClose();
  };

  useEffect(() => {
    if (inviteAlert.show) {
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: inviteAlert.variant ? inviteAlert.variant : 'success',
          message: inviteAlert.message ? inviteAlert.message : '',
        };
        dispatch(setReduxInviteAlert(alertOption));
      }, 5000);
    }
  }, [dispatch, inviteAlert]);

  useEffect(() => {
    const cellRegExp = (countryList || []).find(
      (cl) => cl.id === values.country
    )?.cell_regexp;
    isError && setErrors(validateInviteMemberForm(values, cellRegExp));
  }, [isError, values, countryList]);

  useEffect(() => {
    async function fetchCompanies() {
      let params = {};
      let companyList = [];
      if (member.user_type === USER_TYPE.standard) {
        params.member_id = member.member_id;
        companyList = await dispatch(loadCompanies(params));
      } else {
        companyList = await dispatch(loadCompanies(params));
      }
      if (companyList.length === 0) {
        handleChangeCompany({
          id: null,
          name: member?.company,
        });
      }
    }
    fetchCompanies();
  }, [dispatch, member]);

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhoneNumber = (value, country, e, formattedValue) => {
    setValues({
      ...values,
      phoneNumber: value,
    });
  };

  const handleChangeCompany = (company) => {
    setValues((ps) => ({
      ...ps,
      company,
    }));
  };

  // const handleApplicableToggle = (e) => {
  //   // The field is called Not applicable, so that's why we invert here
  //   const isApplicable = !e.target.checked;
  //   setValues((prevValues) => ({
  //     ...values,
  //     isApplicable,
  //     company: isApplicable ? prevValues.company : null,
  //   }));
  // };

  const setFormData = (values) => {
    let formData = new FormData();
    Object.keys(values).map((key) => {
      let value = values[key];
      if (key === 'phoneNumber') {
        const dialCode = countryList.find(
          (clo) => clo.id === values.country
        ).phone;
        value = values.phoneNumber.replace(dialCode, '');
      } else if (key === 'company') {
        formData.set('company_id', value?.id || null);
        formData.set('company_name', value?.name || null);
        return formData;
      }
      return formData.set(key, value);
    });
    return formData;
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const cellRegExp = countryList.find(
      (cl) => cl.id === values.country
    ).cell_regexp;
    const formErrors = validateInviteMemberForm(values, cellRegExp);
    if (values && Object.keys(formErrors).length === 0) {
      setIsError(false);
      const formData = setFormData(values);
      if (group_id) {
        formData.set('groupId', group_id);
      }
      dispatch(sendMemberContactInvite(formData));
      setValues(defaultValues);
    } else {
      setIsError(true);
      setErrors(formErrors);
      return false;
    }
  };

  const renderRoleOptions = () => {
    if (roles.length > 0) {
      return roles.map((role) => (
        <MenuItem key={role.id} value={role.id}>
          {role.name}
        </MenuItem>
      ));
    } else {
      return null;
    }
  };

  const getPhoneCountryValue = (country) => {
    const countryFind = countryList.find((clo) => clo.id === country);
    if (countryFind) {
      return countryFind.alpha2.toLowerCase();
    }
    return defaultCountryCode;
  };

  const ModalContent = () => (
    <form className={classes.root} noValidate autoComplete="off">
      <fieldset disabled={loading} className={classes.memberInviteFormFields}>
        <FormControl
          className="modal-header-dropdown"
          error={errors.role ? true : false}
        >
          <InputLabel required id="invite-select-role-label">
            Relationship
          </InputLabel>
          <Select
            disableUnderline={true}
            labelId="invite-select-role-label"
            id="invite-select-role"
            required
            name="role"
            value={values.role}
            onChange={handleChange}
          >
            {renderRoleOptions()}
          </Select>
          {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
        </FormControl>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CompanyNameInput
              // companyName={values.company_name}
              company={values.company}
              // onToggle={handleApplicableToggle}
              required={false}
              // isApplicable={values.isApplicable}
              disableNotApplicable={true}
              disableAddCompany={member.user_type !== USER_TYPE.admin}
              onCompanySelect={handleChangeCompany}
              error={errors.companyName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-first_name"
              label="First Name"
              placeholder="John"
              required
              type="text"
              variant="outlined"
              name="firstName"
              value={values.firstName || ''}
              onChange={(e) => handleChange(e)}
              error={errors.firstName ? true : false}
              helperText={errors.firstName ? errors.firstName : ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-last_name"
              label="Last Name"
              placeholder="Doe"
              required
              type="text"
              variant="outlined"
              name="lastName"
              value={values.lastName || ''}
              onChange={(e) => handleChange(e)}
              error={errors.lastName ? true : false}
              helperText={errors.lastName ? errors.lastName : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-email"
              label="Email Address"
              type="email"
              required
              variant="outlined"
              name="groupMemberEmail"
              placeholder="email@example.com"
              onChange={(e) => handleChange(e)}
              value={values.groupMemberEmail || ''}
              error={errors.groupMemberEmail ? true : false}
              helperText={
                errors.groupMemberEmail ? errors.groupMemberEmail : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CountryInput
              country={values.country}
              handleChangeCountry={handleChange}
              errors={errors.country}
            />
          </Grid>
          <Grid item xs={6}>
            <PhoneInput
              country={getPhoneCountryValue(values.country)}
              value={values.phoneNumber}
              specialLabel={values.confirmPhoneRequired ? 'Phone *' : 'Phone'}
              onChange={handleChangePhoneNumber}
              inputClass={`phone-number-input ${
                errors.phoneNumber ? 'phone-number-error' : ''
              }`}
              containerClass="phone-number-input-field"
              disableDropdown={true}
              countryCodeEditable={false}
              inputProps={{
                required: values.confirmPhoneRequired,
              }}
            />
            {errors.phoneNumber && (
              <p className="input-error-custom-style">{errors.phoneNumber}</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <GreenCheckbox
              checked={values.confirmPhoneRequired}
              onChange={() =>
                setValues((prev) => ({
                  ...prev,
                  confirmPhoneRequired: !prev.confirmPhoneRequired,
                }))
              }
            />
            Require phone verification
          </Grid>
          <Grid item xs={12}>
            <Collapse in={inviteAlert.show}>
              <Alert variant="filled" severity={inviteAlert.variant}>
                {inviteAlert.message}
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
      </fieldset>
    </form>
  );

  const ModalAction = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          className={classes.submitBtn}
          onClick={() => closeModal()}
          disabled={loading}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            disabled
          >
            Sending ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            onClick={(e) => handleSubmit(e)}
          >
            Send Invite
          </Button>
        )}
      </>
    );
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      disabled={loading}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="member-invite-modal"
    ></Modal>
  );
};

MemberInviteModal.propTypes = {
  dispatch: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  group_id: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  inviteAlert: PropTypes.object,
};

MemberInviteModal.defaultProps = {
  dispatch: null,
  open: false,
  onClose: null,
  roles: [],
  group_id: null,
  loading: false,
  inviteAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  member: state.member && state.member.member,
  roles: state.member.roles,
  countryList: state.member.countryList,
  loading: state.invite.loading,
  inviteAlert: state.invite.inviteAlert,
});

export default connect(mapStateToProps)(MemberInviteModal);
