import {
  OPEN_DRAWER,
  CLOSE_DRAWER,
  FETCH_CONTACT_NEWSFEEDS_SUCCESS,
  SEND_CONTACT_NEWSFEEDS_TOPIC_SUCCESS,
  SEND_CONTACT_NEWSFEEDS_POST_SUCCESS,
  SET_TEMPORARY_VARIANT,
  SET_PERSISTENT_VARIANT,
  FETCH_GROUP_LIST_SUCCESS,
  SELECT_CALL_GROUP,
  CREATE_PASSWORD,
  DELETE_PASSWORD,
  UPDATE_PASSWORD,
  LOAD_PASSWORD,
  SET_IS_EDITING,
} from '../actionTypes/drawer';

import { APP_RESET } from '../actionTypes/app';

import {
  DRAWER_VARIANTS,
  DRAWER_CONTENT_TYPE,
  DRAWER_TYPE,
} from '../../utils/drawer';
import { includes } from 'lodash';

export const initialState = {
  openDrawer: false,
  variant: DRAWER_VARIANTS.persistent, //Switch this from 'persistent' to 'temporary' to have overlay instead of shrinking the content
  type: DRAWER_CONTENT_TYPE.newsfeeds,
  size: 'default',
  contentProps: {
    topics: [],
    group: [],
    group_id: null,
    passwords: [],
    uploadContent: {},
    event: {},
  },
  isEditing: false,
};

let newPasswords;

const drawer = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case OPEN_DRAWER:
      return {
        ...state,
        openDrawer: true,
        variant: action.payload.variant,
        type: action.payload.type,
        size: action.payload.size ? action.payload.size : 'default',
        width: action.payload.size
          ? DRAWER_TYPE[action.payload.size]
            ? DRAWER_TYPE[action.payload.size]
            : DRAWER_TYPE.default
          : DRAWER_TYPE.default,
        contentProps: { ...state.contentProps, ...action.payload.contentProps },
        isEditing: includes(
          [
            DRAWER_CONTENT_TYPE.myProfileEdit,
            DRAWER_CONTENT_TYPE.uploadcontent,
            DRAWER_CONTENT_TYPE.event,
            DRAWER_CONTENT_TYPE.videoCall,
            DRAWER_CONTENT_TYPE.audioCall,
            DRAWER_CONTENT_TYPE.broadcast,
            DRAWER_CONTENT_TYPE.newsfeeds,
            DRAWER_CONTENT_TYPE.securitySettings,
            DRAWER_CONTENT_TYPE.passwordAndLoginManager,
          ],
          action.payload.type
        ),
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        openDrawer: false,
        contentProps: {
          group_id: null,
          uploadContent: {},
          event: {},
        },
        isEditing: false,
      };
    case FETCH_CONTACT_NEWSFEEDS_SUCCESS:
      return {
        ...state,
        contentProps: { ...state.contentProps, topics: action.payload || [] },
      };
    case SEND_CONTACT_NEWSFEEDS_TOPIC_SUCCESS:
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          topics: [action.payload, ...state.contentProps.topics],
        },
      };
    case SEND_CONTACT_NEWSFEEDS_POST_SUCCESS:
      const newTopics = makeNewTopics(
        state.contentProps.topics,
        action.payload
      );
      return {
        ...state,
        contentProps: { ...state.contentProps, topics: newTopics },
      };
    case SET_TEMPORARY_VARIANT:
      return {
        ...state,
        variant: DRAWER_VARIANTS.temporary,
      };
    case SET_PERSISTENT_VARIANT:
      return {
        ...state,
        variant: DRAWER_VARIANTS.persistent,
      };
    case FETCH_GROUP_LIST_SUCCESS:
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          groups: action.payload,
        },
      };
    case SELECT_CALL_GROUP:
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          group_id: action.payload,
        },
      };
    case CREATE_PASSWORD:
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          passwords: [...state.contentProps.passwords, action.payload],
        },
      };
    case UPDATE_PASSWORD:
      newPasswords = state.contentProps.passwords.map((password) => {
        if (action.payload.id !== password.id) return password;
        return action.payload;
      });
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          passwords: newPasswords,
        },
      };
    case DELETE_PASSWORD:
      newPasswords = state.contentProps.passwords.filter((password) => {
        if (action.payload !== password.id) return true;
        return false;
      });
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          passwords: newPasswords,
        },
      };
    case LOAD_PASSWORD:
      return {
        ...state,
        contentProps: {
          ...state.contentProps,
          passwords: action.payload,
        },
      };

    case SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.payload,
      };
    default:
      return state;
  }
};

const makeNewTopics = (topics, post) => {
  return topics.map((topic) => {
    if (topic.topic_id !== post.news_topic_id) return topic;
    topic.replies.push(post);
    return topic;
  });
};

export default drawer;
