import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './ModalStyles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationModal from './ConfirmationModal';

//Actions
import { ProjectsApi } from '../../../../redux/actions/project';

const EditModal = ({
  projectId,
  parent_id,
  element_id,
  name,
  text,
  element_type,
  pay_type,
  handleSubmitEdit,
  open,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [title, setTitle] = useState(name);
  const [description, setDescription] = useState(text);
  const [payType, setPayType] = useState(pay_type);
  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);

  const form = {
    project_id: Number(projectId),
    parent_id: element_type === 'epic' ? null : parent_id,
    element_type: element_type,
    title: title,
    description: description,
    payType: payType,
    est_hours: null,
  };

  const submitHandler = () => {
    handleSubmitEdit(form, element_id);
    setTitle(name);
    setDescription(text);
    setPayType(pay_type);
  };

  const handleDelete = () => {
    setOpenDeleteConfirmationModal(true);
  };
  const handleDeleteConfirm = () => {
    dispatch(ProjectsApi.deleteProjectElement(element_id));

    handleClose();
  };

  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <div className={classes.modalTop}>
          <div>
            <h6 className={classes.modalTopText}>Edit {name}</h6>
          </div>
          <div onClick={handleClose}>
            <IconButton className={classes.modalTopButton} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.field}>
              <TextField
                label="Title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.field}>
            <TextareaAutosize
              className={classes.inputDescription}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rowsMin={8}
              placeholder="Description"
            />
          </FormControl>
        </Grid>

        <Grid container direction="row" justify="flex-end">
          <Button
            onClick={handleDelete}
            className={classes.button}
            variant="contained"
            color="secondary"
          >
            <span className={classes.buttonText}>Delete</span>
          </Button>
          <Button
            onClick={submitHandler}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <span className={classes.buttonText}>Edit</span>
          </Button>
        </Grid>

        <ConfirmationModal
          title={`Delete Element "${title}"`}
          data={{
            elementId: element_id,
            projectId: projectId,
          }}
          confirm={handleDeleteConfirm}
          open={openDeleteConfirmationModal}
          bodyMessage={`Are you sure you want to delete project element "${title}"`}
          closeFn={() => {
            setOpenDeleteConfirmationModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default EditModal;
