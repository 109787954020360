import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'connected-react-router';
import history from '../../utils/history';

import { debug } from '../constants';

import app from './app.js';
import global from './globalReducer';
import fileshare from './fileshare';
import invite from './invite';
import member from './member';
import group from './group';
import contact from './contact';
import chat from './chat';
import event from './event';
import snackbar from './snackbar';
import mail from './mail';
import schedulerSetting from './scheduler_setting';
import scheduleEvents from './schedule_event';
import one2onevcall from './one2onevcall';
import VideoCallRedux from './VideoCallRedux';
import reportBugs from './reportBugs';
import company from './company';
import project from './project';
import billing from './billing';
import activity from './activity';
import forum from './forum';
import drawer from './drawer';
import stream from './stream';
import security from './security';

import { migrations as memberMigrations } from '../migrations/memberMigrations';
import { migrations as chatMigrations } from '../migrations/chatMigrations';
import { migrations as mailMigrations } from '../migrations/mailMigrations';

const appReducer = combineReducers({
  app,
  activity,
  global,
  fileshare,
  invite,
  member: persistReducer(
    {
      key: 'member',
      storage,
      blacklist: [
        'isLoading',
        'isFetching',
        'isUpdated',
        'isSuccess',
        'error',
        'isSubmittingRegisterForm',
        'isLoadingVerification',
        'notificationsSetting',
        'faceRegistered',
      ],
      version: 0,
      migrate: createMigrate(memberMigrations, { debug }),
    },
    member
  ),
  group,
  contact,
  chat: persistReducer(
    {
      key: 'chat',
      storage,
      blacklist: [
        'connectionStatus',
        'chatConversations',
        'groupConversations',
        'selectedChatID',
        'selectedGroupID',
      ],
      version: 0,
      migrate: createMigrate(chatMigrations, { debug }),
    },
    chat
  ),
  one2onevcall,
  event,
  schedulerSetting,
  scheduleEvents,
  snackbar,
  mail: persistReducer(
    {
      key: 'mail',
      storage,
      blacklist: ['mailBadgeCountLoading'],
      version: 0,
      migrate: createMigrate(mailMigrations, { debug }),
    },
    mail
  ),
  VideoCallRedux,
  reportBugs,
  company,
  project,
  billing,
  forum,
  drawer,
  stream,
  security,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'APP_RESET') {
    localStorage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
