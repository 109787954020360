import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { RECURRENCE_FREQ } from '../../../utils/calendar';

const RecurrenceSelect = ({ value, handleChange }) => {
  const {
    none,
    daily,
    weekly,
    weekdays,
    monthly,
    biMonthly,
    quarterly,
    yearly,
  } = RECURRENCE_FREQ;

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="label-recurring">Recurring</InputLabel>
      <Select
        labelId="label-eventtype"
        id="recurring-select"
        value={value}
        onChange={handleChange}
        label="Recurring"
      >
        <MenuItem value={none}>{none}</MenuItem>
        <MenuItem value={daily}>{daily}</MenuItem>
        <MenuItem value={weekly}>{weekly}</MenuItem>
        <MenuItem value={weekdays}>{weekdays}</MenuItem>
        <MenuItem value={monthly}>{monthly}</MenuItem>
        <MenuItem value={biMonthly}>{biMonthly}</MenuItem>
        <MenuItem value={quarterly}>{quarterly}</MenuItem>
        <MenuItem value={yearly}>{yearly}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default RecurrenceSelect;
