import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import PictureInput from './Forms/PicturePinForm/PictureInput';
import PINInput from './Forms/PicturePinForm/PINInput';
import { apiUrl } from '../config/api';
import { setSnackbarData } from '../redux/actions/snackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 200px)',
    width: '60vw',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginLeft: theme.spacing(3),
  },
  top2: {
    marginTop: theme.spacing(2),
  },
  btnWrapper: {
    marginLeft: theme.spacing(3),
    marginTop: '10px',
  },
  keyContent: {
    width: '100%',
    height: '100%',
    background: '#f4f4f4',
    padding: '10px 24px',
    whiteSpace: 'pre-wrap',
  },
  keyContentWrapper: {
    background: '#f4f4f4',
    width: '100%',
    height: '100%',
    '& .MuiPaper-root': {
      borderRadius: '7px !important',
    },
  },
  keyResult: {
    marginTop: theme.spacing(3),
  },
}));

const keySizes = [256, 512, 1024, 2048, 4096];

const Keygen = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [imageAnalysis, setImageAnalysis] = useState('');
  const [values, setValues] = useState({
    pin: '',
    picture: null, // filelist item
    isValidated: false,
  });
  const [keySize, setKeySize] = useState(2048);
  const [key, setKey] = useState('');
  const [output, setOutput] = useState('');

  const onPINInput = (e) => {
    const pinValue = e.target.value;

    if (pinValue.length <= 12) {
      setValues((prevState) => ({ ...prevState, pin: pinValue }));
    }
  };

  const onPicChange = (picFile) => {
    setValues((prev) => ({ ...prev, picture: picFile }));
    let formData = new FormData();
    formData.append('image', picFile);
    formData.append('pin', '000000');
    const url = `${apiUrl}/demo/image-upload`;
    fetch(url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        let codeValue = '';
        let value;

        for (let x in response.secure) {
          value = response.secure[x];
          if (!isNaN(value) && value % 1 === 0) {
            // value = new Number(response.secure[x]);
            // value = value.formatNumber();
          }
          codeValue += x + ': ' + value + '\n';
        }
        setImageAnalysis(codeValue);
      })
      .catch((error) => {
        console.log('Error', error);
      });

    if (values.pin) {
      generate(picFile, values.pin, keySize);
    }
  };

  const handleKeySize = (event) => {
    setKeySize(event.target.value);
  };

  const generate = (image, pin, keysize) => {
    const data = new FormData();
    data.append('image', image);
    data.append('pin', pin);
    data.append('keysize', keysize);
    const url = `${apiUrl}/keygen/file`;

    fetch(url, {
      method: 'POST',
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        loadKeyBinary(response.saved);
        setOutput(response.output.join('\n'));
      })
      .catch((error) => {
        console.log('Error', error);
        dispatch(
          setSnackbarData({
            open: true,
            message: `There was a problem while generationg key`,
            type: 'error',
          })
        );
      });
  };

  const onGenerate = () => {
    generate(values.picture, values.pin, keySize);
  };

  const loadKeyBinary = function (filename) {
    let oReq = new XMLHttpRequest();
    oReq.open('GET', '/api/demo/keygen?file=' + filename, true);
    oReq.responseType = 'arraybuffer';

    oReq.onload = function (oEvent) {
      let arrayBuffer = oReq.response;

      // if you want to access the bytes:
      let byteArray = new Uint8Array(arrayBuffer);

      let binaryString = '';
      let binaryTemplate = '00000000';
      let binaryTemp = '';
      for (var x = 0; x < byteArray.length; x++) {
        binaryTemp = byteArray[x].toString(2);
        binaryString += binaryTemplate.substr(binaryTemp.length) + binaryTemp;
      }
      // ...new TextDecoder("utf-8").decode(byteArray)
      setKey(binaryString);
    };

    oReq.send();
  };

  return (
    <div className={classes.root}>
      <Grid className="picture-pin-container" container spacing={2}>
        <Grid container>
          <Grid item xs={6}>
            <PictureInput
              changed={onPicChange}
              propSrc={values.security_picture}
              hidePinPicture={false}
              disabled={false}
              className="noborder"
            />
          </Grid>
          <Grid item xs={6}>
            <div className={classes.keyContentWrapper}>
              <Card className={classes.keyContentWrapper}>
                <CardContent>
                  <pre className={classes.keyContent}>
                    <code>{imageAnalysis}</code>
                  </pre>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.top2}>
          <Grid item xs={4} className="ml-3">
            <PINInput
              PIN={values.pin}
              isConfirm={false}
              onPINChange={onPINInput}
              // errors={pinError}
              variant="standard"
              hidePinPicture={false}
              disabled={false}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-keysize-native-simple">
                Key Size
              </InputLabel>
              <Select
                native
                value={keySize}
                onChange={handleKeySize}
                label="Key Size"
                variant="standard"
                inputProps={{
                  name: 'key size',
                  id: 'outlined-keysize-native-simple',
                }}
              >
                {keySizes.map((size, index) => (
                  <option value={size} key={index}>
                    {size}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div className={classes.btnWrapper}>
            <Button
              color="primary"
              variant="contained"
              disabled={!values.pin || !values.picture}
              onClick={onGenerate}
            >
              Generate
            </Button>
          </div>
        </Grid>
      </Grid>
      <div className={classes.keyResult}>
        <h3>Key:</h3>
        <pre className={classes.keyContent}>
          <code>{key}</code>
        </pre>
      </div>
      <div className={classes.keyResult}>
        <h3>Generation Output:</h3>
        <pre className={classes.keyContent}>
          <code>{output}</code>
        </pre>
      </div>
    </div>
  );
};

export default Keygen;
