import * as api from '../../config/api';
import {
  IS_LOADING_CREATE_GROUP,
  IS_LOADING_GET_GROUP_LIST,
  CREATE_GROUP,
  GET_GROUP_LIST,
  GET_GROUP_DETAIL,
  REMOVE_GROUP_MEMBER,
  ADD_GROUP_MEMBER_START,
  ADD_GROUP_MEMBER,
  SET_GROUP_ALERT,
  REMOVE_GROUP_ALERT,
  SET_ADD_MEMBER_FROM,
  SET_AMERA_GROUP_SECURITY,
  SET_SHOW_GROUP_DETAIL_MODAL,
  FETCH_GROUP_MEMBERSHIPS_START,
  FETCH_GROUP_MEMBERSHIPS_SUCCESS,
  FETCH_GROUP_MEMBERSHIPS_FAILED,
  REMOVE_GROUP_MEMBERSHIP,
  GET_CONTACT_GROUP_MEMBERSHIP,
  // IS_LOADING_MEMBER_INVITE,
  IS_DELETING_GROUP,
  DELETED_GROUP,
  FAIL_DELETED_GROUP,
  ADD_MEMBER_TO_NEW_GROUP,
  INITIAL_GROUP_MEMBERS,
  SET_GROUP_DRIVE_ACTIVITY,
  SET_GROUP_CALENDAR_ACTIVITY,
  SET_GROUP_LEADER_DETAIL,
  SET_GROUP_NAME,
  GROUP_MESSAGE_SENT_SUCCESS,
  GROUP_MESSAGE_SENT_FAIL,
  SET_GROUP_DATA,
  ADD_GROUP_OUTSIDE_MEMBER,
  REMOVE_GROUP_OUTSIDE_MEMBER,
  UPDATE_GROUP_OUTSIDE_MEMBER,
} from '../actionTypes/group';
import { createChatRoom, destroyChatRoom } from '../actions/chat';

import { setGroupAlert } from '../../utils/alert';
import { getConferenceID } from '../../utils/general';

const apiUrl = api.apiUrl;

// create new group
export const createGroup = (formData) => {
  const url = `${apiUrl}/group`;
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_CREATE_GROUP,
    });
    api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({
          type: CREATE_GROUP,
          payload: res.data,
        });
        const payload = setGroupAlert(res, true, 'Group');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        dispatch({
          type: CREATE_GROUP,
          payload: [],
        });
        const payload = setGroupAlert(error, false, 'Group');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

// create new group with extended data

export const createExpandedGroup = (formData) => {
  const url = `${apiUrl}/group`;
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_CREATE_GROUP,
    });
    api
      .POST(url, formData, {})
      .then((res) => {
        const group = res?.data?.[0];
        if (group) {
          dispatch({
            type: CREATE_GROUP,
            payload: res.data,
          });
          const payload = setGroupAlert(res, true, 'Group');
          dispatch(setReduxGroupAlert(payload));

          const conferenceID = getConferenceID(group);
          dispatch(
            createChatRoom(conferenceID, JSON.parse(formData.get('members')))
          );
        } else {
          throw new Error('Something went wrong when creating group!');
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_GROUP,
          payload: [],
        });
        const payload = setGroupAlert(error, false, 'Group');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

export const deleteGroups = (data) => (dispatch) => {
  const url = `${apiUrl}/group`;
  dispatch({ type: IS_DELETING_GROUP });
  return new Promise((resolve) => {
    api
      .DELETE(url, data, {})
      .then((res) => {
        if (res.data) {
          // also destroy chat rooms
          dispatch(destroyChatRoom(JSON.parse(data.get('groupIds'))));
          dispatch({
            type: DELETED_GROUP,
            payload: res.data,
          });
        }
        dispatch(setShowGroupDetailModal(false));
        const payload = setGroupAlert(res, true, 'Group');
        dispatch(setReduxGroupAlert(payload));
        resolve(true);
      })
      .catch((error) => {
        dispatch({
          type: FAIL_DELETED_GROUP,
        });
        dispatch(setShowGroupDetailModal(false));
        const payload = setGroupAlert(error, false, 'Group');
        dispatch(setReduxGroupAlert(payload));
        resolve(false);
      });
  });
};

// get group list for logged in member
export const getGroupList = (all, params = {}) => {
  params.get_all = all ? 1 : '';
  const url = `${apiUrl}/groups?${new URLSearchParams(params).toString()}`;
  return function (dispatch) {
    dispatch({
      type: IS_LOADING_GET_GROUP_LIST,
    });
    api
      .GET(url)
      .then((res) => {
        dispatch({
          type: GET_GROUP_LIST,
          payload: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GROUP_LIST,
          payload: {
            data: [],
            count: 0,
          },
        });
      });
  };
};

// get group detail information
export const getGroupDetail = (groupId) => {
  const url = `${apiUrl}/group/${groupId}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({
          type: GET_GROUP_DETAIL,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// update group name
export const updateGroupName = (group_id, formData) => {
  const url = `${apiUrl}/group/${group_id}`;
  return function (dispatch) {
    api
      .PUT(url, formData, {})
      .then((res) => {
        dispatch({
          type: SET_GROUP_NAME,
          payload: {
            ...res.data,
            group_id,
          },
        });
        const payload = setGroupAlert(res, true, 'Member');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        console.error('error setting group name!', error);
        const payload = setGroupAlert(error, false, 'Member');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

export const addGroupMember = (formData) => {
  const url = `${apiUrl}/groups/membership`;
  return function (dispatch) {
    dispatch({ type: ADD_GROUP_MEMBER_START });
    api
      .POST(url, formData, {})
      .then((res) => {
        dispatch({
          type: ADD_GROUP_MEMBER,
          payload: res.data,
        });
        const payload = setGroupAlert(res, true, 'Member');
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        const payload = setGroupAlert(error, false, 'Member');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

// remove member from group
export const removeGroupMember = (formData, type = 'group') => {
  const url = `${apiUrl}/groups/membership`;
  return function (dispatch) {
    api
      .DELETE(url, formData, {})
      .then((res) => {
        let payload = {};
        if (res && res.data && res.data[0]) {
          if (type === 'membership') {
            dispatch({
              type: REMOVE_GROUP_MEMBERSHIP,
              payload: res.data,
            });
            const message = `Removed memberships Successfully `;
            payload = setGroupAlert(res, true, '', message);
          } else {
            dispatch({
              type: REMOVE_GROUP_MEMBER,
              payload: res.data && res.data[0],
            });
            payload = setGroupAlert(res, true, 'Member');
          }
          dispatch(setReduxGroupAlert(payload));
        } else {
          payload = setGroupAlert(null, false, 'Member');
          dispatch(setReduxGroupAlert(payload));
        }
      })
      .catch((error) => {
        const payload = setGroupAlert(error, false, 'Member');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

export const removeGroupOutsideMember = (member_id) => {
  const url = `${apiUrl}/groups/unsecured-members/${member_id}`;
  let payload;
  return function (dispatch) {
    api
      .DELETE(url)
      .then((res) => {
        if (res.success) {
          payload = setGroupAlert(res, true, 'Member');
          dispatch({
            type: REMOVE_GROUP_OUTSIDE_MEMBER,
            payload: { member_id },
          });
        } else {
          payload = setGroupAlert(res, true, 'Member');
        }
        dispatch(setReduxGroupAlert(payload));
      })
      .catch((error) => {
        payload = setGroupAlert(error, false, 'Member');
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

// // add member to group
// export const sendGroupMemberInvite = (formData) => async (dispatch) => {
//   const url = `${apiUrl}/member/group/invite`;

//   dispatch({
//     type: IS_LOADING_MEMBER_INVITE,
//   });
//   try {
//     const res = await api.POST(url, formData, {});
//     dispatch({
//       type: SET_GROUP_ALERT,
//       payload: setGroupAlert(res, true, 'Invite'),
//     });
//   } catch (error) {
//     dispatch({
//       type: SET_GROUP_ALERT,
//       payload: setGroupAlert(error, false, 'Invite'),
//     });
//   }
// };

export const removeGroupAlert = () => {
  return function (dispatch) {
    dispatch({
      type: REMOVE_GROUP_ALERT,
      payload: null,
    });
  };
};

export const switchAddMemberForm = () => {
  return function (dispatch) {
    dispatch({
      type: SET_ADD_MEMBER_FROM,
      payload: true,
    });
  };
};

export const setInitAlert = () => {
  return function (dispatch) {
    const payload = {
      show: false,
      variant: 'success',
      message: 'none',
    };
    dispatch(setReduxGroupAlert(payload));
  };
};

export const setAmeraGroupSecurity = (payload) => {
  return function (dispatch) {
    dispatch({
      type: SET_AMERA_GROUP_SECURITY,
      payload,
    });
  };
};

export const setShowGroupDetailModal = (
  showGroupDetailModal,
  isDrive = false
) => {
  return function (dispatch) {
    dispatch({
      type: SET_SHOW_GROUP_DETAIL_MODAL,
      payload: {
        showGroupDetailModal,
        isDrive,
      },
    });
  };
};

// get group membership with redux
export const getGroupMemberShip = (params) => {
  // const url = `${apiUrl}/groups/membership?sort=${sortBy}&search_key=${searchKey}`;
  const url = `${apiUrl}/groups/membership?${new URLSearchParams(
    params
  ).toString()}`;

  return function (dispatch) {
    dispatch(fetchGroupMembershipsStart());
    api
      .GET(url, {})
      .then((res) => {
        dispatch(fetchGroupMembershipsSuccess(res));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchGroupMembershipsFailed(error));
      });
  };
};

// get contact group membership with redux
export const getContactGroupMemberShip = (params = {}) => {
  const url = `${apiUrl}/groups/membership?${new URLSearchParams(
    params
  ).toString()}`;
  return function (dispatch) {
    api
      .GET(url, {})
      .then((res) => {
        dispatch({
          type: GET_CONTACT_GROUP_MEMBERSHIP,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// get group membership list
// export const getGroupMemberShip = () => {
//   const url = `${apiUrl}/groups/membership`;
//   return api.GET(url, {});
// };

export const setReduxGroupAlert = (payload) => ({
  type: SET_GROUP_ALERT,
  payload,
});

export const addMembersToNewGroup = (payload) => ({
  type: ADD_MEMBER_TO_NEW_GROUP,
  payload,
});

export const initialGroupMembers = (payload) => ({
  type: INITIAL_GROUP_MEMBERS,
  payload,
});

export const fetchGroupMembershipsStart = () => ({
  type: FETCH_GROUP_MEMBERSHIPS_START,
});
export const fetchGroupMembershipsSuccess = (payload) => ({
  type: FETCH_GROUP_MEMBERSHIPS_SUCCESS,
  payload,
});
export const fetchGroupMembershipsFailed = (payload) => ({
  type: FETCH_GROUP_MEMBERSHIPS_FAILED,
  payload,
});

export const getGroupDriveActivity = (group_id) => {
  const url = `${apiUrl}/group/activity/drive/${group_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({ type: SET_GROUP_DRIVE_ACTIVITY, payload: res.data });
      })
      .catch((error) => {});
  };
};

export const getGroupCalendarActivity = (group_id) => {
  const url = `${apiUrl}/group/activity/calendar/${group_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({ type: SET_GROUP_CALENDAR_ACTIVITY, payload: res.data });
      })
      .catch((error) => {});
  };
};

export const getGroupLeaderDetail = (group_leader_id) => {
  const url = `${apiUrl}/member/info/${group_leader_id}`;
  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        dispatch({
          type: SET_GROUP_LEADER_DETAIL,
          payload: {
            title: res.data.job_title,
            company: res.data.company_name,
            amera_avatar_url: res.data.amera_avatar_url,
            email: res.data.email,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const groupMessageSuccess = () => ({
  type: GROUP_MESSAGE_SENT_SUCCESS,
});

export const groupMessageFail = () => ({
  type: GROUP_MESSAGE_SENT_FAIL,
});

export const setGroupData = (payload) => ({
  type: SET_GROUP_DATA,
  payload,
});

export const addOutsideMember = (formData) => {
  const url = `${apiUrl}/groups/unsecured-members`;
  return function (dispatch) {
    dispatch({ type: ADD_GROUP_MEMBER_START });
    api
      .POST(url, formData, {})
      .then((res) => {
        if (res.success) {
          dispatch({
            type: ADD_GROUP_OUTSIDE_MEMBER,
            payload: res.data,
          });
          const payload = setGroupAlert(
            res,
            true,
            'Member',
            'Added member successfully!'
          );
          dispatch(setReduxGroupAlert(payload));
        } else {
          const payload = setGroupAlert(
            res,
            false,
            'Member',
            'Something went wrong, please try again later'
          );
          dispatch(setReduxGroupAlert(payload));
        }
      })
      .catch((error) => {
        const payload = setGroupAlert(
          error,
          false,
          'Member',
          'Something went wrong, please try again later'
        );
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

export const updateOutsideMember = (formData, member_id) => {
  const url = `${apiUrl}/groups/unsecured-members/${member_id}`;
  return function (dispatch) {
    dispatch({ type: ADD_GROUP_MEMBER_START });
    api
      .PUT(url, formData, {})
      .then((res) => {
        if (res.success) {
          dispatch({
            type: UPDATE_GROUP_OUTSIDE_MEMBER,
            payload: res.data,
          });
          const payload = setGroupAlert(
            res,
            true,
            'Member',
            'Updated member successfully!'
          );
          dispatch(setReduxGroupAlert(payload));
        } else {
          const payload = setGroupAlert(
            res,
            false,
            'Member',
            'Something went wrong, please try again later'
          );
          dispatch(setReduxGroupAlert(payload));
        }
      })
      .catch((error) => {
        const payload = setGroupAlert(
          error,
          false,
          'Member',
          'Something went wrong, please try again later'
        );
        dispatch(setReduxGroupAlert(payload));
      });
  };
};

export const sendUnsecuredCallInvite = (group, members) => {
  const conferenceId = `${group.group_leader_id}-${
    group.group_id
  }-${group.group_name.replace(/[\W_]+/g, '')}`;
  const cnid = conferenceId.toLowerCase();

  const params = {
    conference_url: `https://conference.amerashare.com/${cnid}`,
    members: members.join(','),
  };

  const url = `${apiUrl}/groups/unsecured-members/call-invite?${new URLSearchParams(
    params
  ).toString()}`;

  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
        } else {
          const payload = setGroupAlert(
            res,
            false,
            'Member',
            'Something went wrong, please try again later'
          );
          dispatch(setReduxGroupAlert(payload));
        }
      })
      .catch((error) => {
        const payload = setGroupAlert(
          error,
          false,
          'Member',
          'Something went wrong, please try again later'
        );
        dispatch(setReduxGroupAlert(payload));
      });
  };
};
