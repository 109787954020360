import { makeStyles } from '@material-ui/core/styles';
import { green, red, textNewGray } from '../../../../../jss/colors';

export const useEmailFolderStyles = makeStyles((theme) => ({
  folderItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '23px',
    position: 'relative',
    overflow: 'visible',
    background: '#F8F8F8',
    boxShadow: '0px 10px 10px #E7EDFC',
    borderColor: '#E7EDFC',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 10,
    padding: '20px 8px 8px 8px',
    '&:hover, &:focus': {
      boxShadow: '0px 10px 10px #C4D3F3',
    },
  },
  selectedfolderItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '23px',
    position: 'relative',
    overflow: 'visible',
    background: '#FFFFFF',
    padding: '20px 8px 8px 8px',

    borderColor: '#94C03D',
    borderStyle: 'solid',
    borderWidth: '0',
    borderLeftWidth: '4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: '0px 0px 0px #D6E8B2',
    '&:hover, &:focus': {
      boxShadow: '0px 0px 0px #D6E8B2',
    },
  },
  rightSlash: {
    position: 'absolute',
    content: 'close-quote',
    width: '3px',
    height: '10px',
    display: 'block',
    top: '50%',
    right: 1,
    transform: 'translate(50%, -50%)',
    background: textNewGray,
  },
  controlsLayout: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  folderName: {
    color: textNewGray,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  time: {
    color: textNewGray,
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
  },
  darkButton: {
    color: textNewGray,
    maxHeight: 25,
    minHeight: 25,
    maxWidth: 25,
    minWidth: 25,
    marginRight: 10,
  },
  darkCircle: {
    backgroundColor: textNewGray,
    width: 25,
    height: 25,
    borderRadius: 25,
  },
  deleteButton: {
    color: 'white',
    background: `${red}`,
  },
  tabContainer: {
    width: '100%',
    height: '40px',
    marginBottom: '20px',
  },
  tabButton: {
    flex: 1,
    flexDirection: 'row',
    width: '50%',
    height: '40px',
    background: '#FFFFFF',
    textAlign: 'center',
    color: textNewGray,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    paddingTop: '0.5rem',
    paddingBottom: '0.2rem',
    borderRadius: 0,
    textTransform: 'capitalize',
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    borderWidth: '0',
    borderBottomWidth: '2px',
  },
  tabButtonActive: {
    borderColor: '#94C03D',
    color: green,
  },
  tabRadioBtn: {
    display: 'none',
  },
}));
