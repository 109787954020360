import React from 'react';

import USAddressLine from './USAddressLine';
import JPAddressLine from './JPAddressLine';

const AddressLine = ({ data }) => {
  return data.country_code_id === 392 || data.country_alpha_2 === 'JP' ? (
    <JPAddressLine data={{ ...data }} />
  ) : (
    <USAddressLine data={{ ...data }} />
  );
};

export default AddressLine;
