import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { capitalize } from 'lodash';

const DepartmentSelect = (props) => {
  const {
    companyDepartments,
    selected,
    onChange,
    disabledDepartment = null,
  } = props;
  return (
    <Select
      labelId="department-select-label"
      id="department-select"
      fullWidth
      value={selected}
      onChange={onChange}
    >
      {companyDepartments.map((d) => (
        <MenuItem
          key={d.department_id}
          value={d}
          disabled={d.department_id === disabledDepartment.department_id}
        >
          {capitalize(d.department_name)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DepartmentSelect;
