import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import {
  PasswordSaveIcon,
  PasswordDeleteIcon,
  CopyClipboardIcon,
} from '../../assets/svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { SITE_TYPE } from '../../utils/drawer';
import { apiUrl } from '../../config/api';
import { validatePasswordForm } from '../../utils/validator/password';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    'user-select': 'none',
  },
  logoWrapper: {
    width: '40px',
    height: '40px',
  },
  iconWrapper: {
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  summary: {
    flex: 1,
    marginLeft: '13px',
    width: '228px',
  },
  detail: {
    flex: 1,
    marginLeft: '13px',
    width: '228px',
  },
  summaryName: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#363C45',
  },
  name: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#363C45',
    marginBottom: '5px',
  },
  summaryUsername: {
    fontSize: '14px',
    color: '#5F5F5F',
  },
  launchWrapper: {
    '& .MuiButtonBase-root': {
      width: '80px',
      height: '28px',
      marginTop: '8px',
      border: '1px solid #8DA3C5',
      borderRadius: '7px',
      color: '#8DA3C5',
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  label: {
    fontSize: '12px',
    fontWeight: '800',
    color: '#8DA3C5',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '0px',
    },
  },
  editModeValue: {
    display: 'flex',
    alignItems: 'center',
  },
  copy: {
    marginLeft: '8px',
    padding: '6px',
  },
  visibility: {
    color: '#8DA3C5',
  },
  property: {
    marginBottom: '11px',
    '& .MuiSelect-root': {
      minWidth: '100px',
    },
  },
  btnWrapper: {
    '& .MuiButtonBase-root': {
      height: '28px',
      marginBottom: '30px',
      marginRight: '16px',
      border: '1px solid #8DA3C5',
      borderRadius: '7px',
      color: '#8DA3C5',
      fontSize: '10px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  img: {
    width: '100%',
    height: '100%',
  },
}));

const PasswordItem = (props) => {
  const { password, newPassword, handleDelete, handleSave } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const [state, setState] = React.useState({
    id: '',
    icon: '',
    type: 'other',
    name: '',
    website: '',
    username: '',
    password: '',
  });

  const [errors, setErrors] = React.useState({});

  const [showImg, setShowImg] = React.useState(true);

  const passwordRef = React.useRef();
  const usernameRef = React.useRef();

  React.useEffect(() => {
    setState({
      id: password.id ? password.id : '',
      icon: password.icon ? password.icon : '',
      name: password.name ? password.name : '',
      type: password.type ? password.type : 'other',
      website: password.website ? password.website : '',
      password: password.password ? password.password : '',
      username: password.username ? password.username : '',
    });
  }, [password]);

  const handleClickShowPassword = () => {
    // setValues({ ...values, showPassword: !values.showPassword });
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    if (!editMode && !newPassword) return;
    event.persist();

    const newValue =
      event.target.name === 'website'
        ? event.target.value.startsWith('https://')
          ? event.target.value
          : `https://${event.target.value}`
        : event.target.value;

    setState((prev) => ({
      ...prev,
      [event.target.name]: newValue,
    }));
  };

  const generateNewPassword = () => {
    let newPass = '';
    const length = 32;
    const str =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$';

    for (let i = 1; i <= length; i++) {
      newPass += str.charAt(Math.floor(Math.random() * str.length + 1));
    }

    // const newPass = Math.random().toString(36).slice(-8);
    setState((prev) => ({ ...prev, password: newPass }));
  };

  const copy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleSubmit = () => {
    const formErrors = validatePasswordForm(state);
    if (state && Object.keys(formErrors).length === 0) {
      handleSave({ ...state });
    } else {
      setErrors(formErrors);
    }
  };

  const valueCls =
    editMode || newPassword ? classes.editModeValue : classes.value;
  const viewModeUrl = state.website
    ? state.website
        .replace('http://', '')
        .replace('https://', '')
        .split(/[/?#]/)[0]
    : '';

  const accountType =
    state.type && state.type !== ''
      ? `${SITE_TYPE.find((x) => x.key === state.type).label} Account`
      : '';

  return (
    <div
      className={classes.root}
      onDoubleClick={() => {
        setOpen((prev) => !prev);
      }}
    >
      <div className={classes.logoWrapper}>
        {state.id && state.id !== '' && showImg && (
          <img
            src={`${apiUrl}/password/favicon/${state.id}`}
            className={classes.img}
            alt="favicon"
            onError={() => {
              setShowImg(false);
            }}
          />
        )}
      </div>
      {open || newPassword ? (
        <div className={classes.detail}>
          <form autoComplete="off">
            {!editMode && !newPassword && (
              <p className={classes.name}>{state.name}</p>
            )}
            {(editMode || newPassword) && (
              <div className={classes.property}>
                <p className={classes.label}>Site name</p>
                <div className={valueCls}>
                  <TextField
                    value={state.name}
                    name="name"
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      event.stopPropagation();
                    }}
                    onKeyPress={(event) => {
                      event.stopPropagation();
                    }}
                    onDoubleClick={(event) => {
                      event.stopPropagation();
                    }}
                    error={errors.name ? true : false}
                    helperText={errors.name ? errors.name : ''}
                  />
                </div>
              </div>
            )}

            <div className={classes.property}>
              <p className={classes.label}>Username</p>
              <div className={valueCls}>
                <TextField
                  value={state.username}
                  name="username"
                  ref={usernameRef}
                  onChange={handleChange}
                  onDoubleClick={(event) => {
                    event.stopPropagation();
                  }}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onKeyPress={(event) => {
                    event.stopPropagation();
                  }}
                  error={errors.username ? true : false}
                  helperText={errors.username ? errors.username : ''}
                />
                <Tooltip title="copy">
                  <span>
                    <IconButton
                      className={classes.copy}
                      onClick={() => {
                        copy(state.username);
                      }}
                    >
                      <CopyClipboardIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={classes.property}>
              <p className={classes.label}>Password</p>
              <div className={valueCls}>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  ref={passwordRef}
                  value={state.password}
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDoubleClick={(event) => {
                    event.stopPropagation();
                  }}
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  onKeyPress={(event) => {
                    event.stopPropagation();
                  }}
                  error={errors.password ? true : false}
                  helperText={errors.password ? errors.password : ''}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  className={classes.visibility}
                >
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
                <Tooltip title="copy">
                  <span>
                    <IconButton
                      className={classes.copy}
                      onClick={() => {
                        copy(state.password);
                      }}
                    >
                      <CopyClipboardIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={classes.property}>
              <p className={classes.label}>Website</p>
              <div className={valueCls}>
                <TextField
                  value={editMode || newPassword ? state.website : viewModeUrl}
                  type={editMode || newPassword ? 'url' : 'text'}
                  name="website"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onKeyPress={(event) => {
                    event.stopPropagation();
                  }}
                  onDoubleClick={(event) => {
                    event.stopPropagation();
                  }}
                  error={errors.website ? true : false}
                  helperText={errors.website ? errors.website : ''}
                />
              </div>
            </div>
            <div className={classes.property}>
              <p className={classes.label}>Type</p>
              {editMode || newPassword ? (
                <FormControl variant="standard" margin="dense" size="small">
                  <Select
                    id="site-type"
                    value={state.type}
                    name="type"
                    onChange={handleChange}
                    label="Type"
                  >
                    {SITE_TYPE.map((type) => (
                      <MenuItem key={type.key} value={type.key}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <div className={valueCls}>
                  <span>{accountType}</span>
                </div>
              )}
            </div>
            <div className={classes.btnWrapper}>
              <Button onClick={generateNewPassword}>
                Generate New Password
              </Button>
              {(editMode ||
                newPassword ||
                state.password !== password.password) && (
                <Button onClick={handleSubmit}>Save</Button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className={classes.summary}>
          <p className={classes.summaryName}>{password.name}</p>
          <p className={classes.summaryUsername}>{password.username}</p>
        </div>
      )}
      {open || newPassword ? (
        <div className={classes.actions}>
          {!newPassword && (
            <span
              className={classes.iconWrapper}
              onClick={() => {
                setEditMode((prev) => !prev);
              }}
            >
              <PasswordSaveIcon />
            </span>
          )}
          <span className={classes.iconWrapper} onClick={handleDelete}>
            <PasswordDeleteIcon />
          </span>
        </div>
      ) : (
        <div className={classes.launchWrapper}>
          <Button
            onClick={() => {
              window.open(password.website);
            }}
          >
            Launch
          </Button>
        </div>
      )}
    </div>
  );
};

export default PasswordItem;
