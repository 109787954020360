import React, { useState, useRef } from 'react';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from './modal';
import { Image } from 'react-bootstrap';

import CardButton from '../../components/Dashboard/CardButton';

const defaultCrop = {
  unit: '%',
  width: 30,
  aspect: 1 / 1,
};

const ImageCrop = (props) => {
  const { show, close, src, originalFile, handleCropConfirm } = props;
  const [croppedFile, setCroppedFile] = useState(null);
  const [croppedFileUrl, setCroppedFileUrl] = useState(null);

  const [crop, setCrop] = useState(defaultCrop);
  const [enableCrop, setEnableCrop] = useState(false);
  const imageRef = useRef(null);
  const title = 'Crop image';

  React.useEffect(() => {
    if (show) {
      setCroppedFile(null);
      setCroppedFileUrl(null);
      setEnableCrop(false);
      setCrop(defaultCrop);
    }
  }, [show]);

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const { croppedImage, croppedImageURL } = await getCroppedImg(
        imageRef.current,
        crop,
        'Avatar.jpeg'
      );
      setCroppedFile(croppedImage);
      setCroppedFileUrl(croppedImageURL);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          const croppedImageURL = window.URL.createObjectURL(blob);
          resolve({
            croppedImage: blob,
            croppedImageURL,
          });
        },
        'image/jpeg',
        1
      );
    });
  };

  const handleAcceptClick = () => {
    if (croppedFile) {
      handleCropConfirm(new File([croppedFile], croppedFile.name));
    } else {
      handleCropConfirm(originalFile);
    }
    close();
  };

  const handleCrop = (crop) => setCrop(crop);
  const handleCropComplete = (crop) => makeClientCrop(crop);
  const handleCropImageLoaded = (image) => (imageRef.current = image);

  const ModalContent = () => (
    <div className="react-crop-container">
      {enableCrop ? (
        <ReactCrop
          src={src}
          className="react-crop"
          // circularCrop
          keepSelection
          crop={crop}
          onImageLoaded={handleCropImageLoaded}
          onComplete={handleCropComplete}
          onChange={handleCrop}
        />
      ) : (
        <div className="react-crop">
          <Image
            src={croppedFileUrl ? croppedFileUrl : src}
            alt="Preview"
            // onLoad={(e) => validatePicture(e)}
          />
        </div>
      )}
    </div>
  );
  const ModalAction = () => (
    <div className="actions-wrapper">
      <CardButton variant="info" text="Cancel" onClick={close} />
      <CardButton
        variant={enableCrop ? 'okFilled' : 'middleFilled'}
        startIcon={!enableCrop && <CropFreeIcon />}
        text={enableCrop ? 'Done' : croppedFile ? 'Crop Again' : 'Crop Image'}
        onClick={() => setEnableCrop(!enableCrop)}
      />
      {!enableCrop && (
        <CardButton
          variant="okFilled"
          text="Accept"
          onClick={handleAcceptClick}
        />
      )}
    </div>
  );

  return (
    <Modal
      open={show}
      onClose={close}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="crop-modal"
    />
  );
};

export default ImageCrop;
