import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InviteLine from '../../../../components/InviteLine';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Button from '@material-ui/core/Button';

const InvitedMembers = ({
  groupInfo,
  invitations,
  onSendClick,
  smsSuccess,
}) => {
  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <PeopleAltIcon />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid item>
          <Typography>
            {!!groupInfo
              ? `${groupInfo.group_name} invited members`
              : 'Invited contacts'}
          </Typography>
        </Grid>
        <Grid item>
          {invitations.map((invite) => (
            <InviteLine
              key={invite.invite_member_id}
              contactData={invite}
              inviteData={invite}
              onSendClick={onSendClick}
              isSMSSuccess={
                !!smsSuccess
                  ? smsSuccess[String(invite.invite_member_id)]
                  : false
              }
            />
          ))}
        </Grid>
        {!!onSendClick && (
          <Grid item container justify="flex-end">
            <Button
              onClick={() =>
                onSendClick(invitations.map((i) => i.invite_member_id))
              }
            >
              Send directions to all
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  groupList: state.group.groupList,
  memberInfo: state.member.memberInfo,
});

export default connect(mapStateToProps)(InvitedMembers);
