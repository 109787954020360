import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../components/AmeraBreadCrumbs';
// import { dummyContacts, PROJECT_ROLES } from '../../../utils/project';
import { useDispatch, useSelector } from 'react-redux';
import ProjectStepsNav from './ProjectStepsNav';
import AssignAttribute from './AssignAttribute';
import { ProjectsApi } from '../../../redux/actions/project';

// components
import EditModeButtons from './EditModeButtons';

// routes utils
import { routeUtils } from '../../../utils/routeUtils';

const AssignTeamRoles = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const queryString = routeUtils.useQuery(routerLocation.search);
  const pageMode = queryString.get('mode');

  let { projectId } = useParams();
  let projects = [];
  let projectMembers = null;
  let projectRoles = [];

  //local state
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  projects = useSelector((state) => {
    return state.project.projects.filter((o) => o.project_id === +projectId);
  });

  if (projects[0]) {
    projectMembers = projects[0].project_members;
  }

  projectRoles = useSelector((state) => {
    return state.project.projectRoles;
  });

  useEffect(() => {
    dispatch(ProjectsApi.getProjects());

    if (!projectRoles.length) {
      dispatch(ProjectsApi.getProjectRoles());
    }
  }, [dispatch, projectRoles]);

  const handleNext = (e) => {
    e.preventDefault();
    history.push(
      `${process.env.PUBLIC_URL}/projects/set-security/${projectId}`
    );
  };

  const handleBack = (e) => {
    e.preventDefault();
    history.push(`${process.env.PUBLIC_URL}/projects/select-team/${projectId}`);
  };

  function handleExit() {
    setIsSaveDisabled(true);
    history.push(`${process.env.PUBLIC_URL}/projects/my-projects`);
  }

  // TODO: save might be always disabled here in assign team roles page
  function handleSave(e) {
    e.preventDefault();
    handleExit();
  }

  return (
    <div className="contact-section assign-roles">
      <Breadcrumbs
        parent="Create Projects"
        current_directory="Assign Project Roles"
      />
      {projects.length ? (
        <AssignAttribute
          type="role"
          contacts={projectMembers}
          data={projectRoles}
        />
      ) : (
        <div>Loading...</div>
      )}
      {pageMode === 'EDIT' ? (
        <EditModeButtons
          isSaveDisabled={isSaveDisabled}
          handleExit={handleExit}
          handleSave={handleSave}
        />
      ) : (
        <ProjectStepsNav handleBack={handleBack} handleNext={handleNext} />
      )}
    </div>
  );
};

export default AssignTeamRoles;
