import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Modal from '../../components/Modal/modal';
import { AppBar, Tab, Tabs, makeStyles } from '@material-ui/core';
import DriveActivity from './DriveActivity';
import CalendarActivity from './CalendarActivity';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabPanel: {
    height: '550px',
  },
}));

const features = ['drive', 'calendar', 'email'];

const GroupActivityModal = (props) => {
  const { show, onClose, group_id } = props;

  const title = 'Activity';

  const classes = useStyles();
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    console.log('new Tab', newTab);
    setTab(newTab);
  };

  const ModalContent = () => (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="feature-tabs"
        >
          {features.map((feature) => (
            <Tab
              label={feature}
              key={feature}
              disabled={feature === 'email' ? true : false}
              {...a11yProps(feature)}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0} className={classes.tabPanel}>
        <DriveActivity group_id={group_id} />
      </TabPanel>
      <TabPanel value={tab} index={1} className={classes.tabPanel}>
        <CalendarActivity group_id={group_id} />
      </TabPanel>
      <TabPanel value={tab} index={2} className={classes.tabPanel}></TabPanel>
    </div>
  );

  return (
    <>
      <Modal
        open={show}
        onClose={() => {
          onClose();
        }}
        title={title}
        ModalContent={ModalContent}
        className="group-detail-modal"
      />
    </>
  );
};

GroupActivityModal.propTypes = {
  dispatch: PropTypes.func,
  show: PropTypes.bool,
  close: PropTypes.func,
};

GroupActivityModal.defaultProps = {
  dispatch: null,
  show: false,
  close: null,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupActivityModal);
