import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const AttributeSelectionGroup = (props) => {
  const { checkBoxes, handleChange } = props;
  return (
    <FormGroup row>
      {checkBoxes.map((role) => {
        return (
          <FormControlLabel
            key={role.attr_id}
            style={{ width: '100%' }}
            control={
              <Checkbox
                onChange={handleChange}
                name="checkedB"
                color="primary"
                checked={role.checked ? true : false}
                key={role.attr_id}
                value={role.attr_id}
              />
            }
            label={role.name}
          />
        );
      })}
    </FormGroup>
  );
};

export default AttributeSelectionGroup;
