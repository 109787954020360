import React from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import MaterialAvatar from '../../../../components/MaterialAvatar';

function ConversationEmpty({
  isGroupChat = false,
  textPrimary,
  textSecondary = '',
  memberAvatarURL,
  memberFirstName,
  memberLastName,
  contactAvatarURL,
  contactFirstName,
  contactLastName,
}) {
  return (
    <div id="no-coversation-layout">
      <div id="no-conversation-content">
        <h2>{textPrimary || 'No Conversations'}</h2>
        <AvatarGroup max={3} id="no-conversation-avatar-group">
          <MaterialAvatar
            src={memberAvatarURL}
            firstName={memberFirstName}
            lastName={memberLastName}
            isBasicAvatar={true}
          />
          {contactAvatarURL && (
            <MaterialAvatar
              src={contactAvatarURL}
              firstName={contactFirstName}
              lastName={contactLastName}
              isBasicAvatar={true}
            />
          )}
          {isGroupChat && (
            <MaterialAvatar
              src=""
              firstName=""
              lastName=""
              isBasicAvatar={true}
            />
          )}
        </AvatarGroup>
        <p>{textSecondary}</p>
      </div>
    </div>
  );
}

export default ConversationEmpty;
