import React from 'react';
import Button from '@material-ui/core/Button';

const AddActions = ({
  element_type,
  element_id,
  handleClick,
  handleTask,
  handleMileStone,
  rate_type,
}) => {
  const el = element_type;
  const rt = rate_type;
  //console.log('element',element)
  return (
    <div className="addElements">
      {el === 'project' && (
        <Button
          color="default"
          variant="outlined"
          size="small"
          onClick={() => handleClick('epic', element_id)}
        >
          Add an Epic
        </Button>
      )}
      {rt === 'hourly' && el === 'epic' && (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => handleClick('tremor', element_id)}
        >
          + Tremor
        </Button>
      )}
      {rt === 'hourly' && (el === 'epic' || el === 'tremor') && (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => handleClick('story', element_id)}
        >
          + Story
        </Button>
      )}
      {rt === 'hourly' && (el === 'epic' || el === 'tremor' || el === 'story') && (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => handleTask(element_id)}
        >
          + Task
        </Button>
      )}
      {rt === 'fixed' && (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => handleMileStone(element_id)}
        >
          + Milestone
        </Button>
      )}
    </div>
  );
};

export default AddActions;
