import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { EVENT_INVITE_STATUS } from '../utils/calendar';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '30rem',
  },
}));

const InviteStatusChip = (props) => {
  const { inviteData } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  let color, icon, comment;
  if (inviteData.invite_status === EVENT_INVITE_STATUS.accepted) {
    color = 'primary';
    icon = <CheckCircleOutlineIcon />;
    comment = null;
  } else if (inviteData.invite_status === EVENT_INVITE_STATUS.declined) {
    color = 'default';
    icon = <CancelIcon />;
    comment = !!inviteData.invitee_comment
      ? inviteData.invitee_comment
      : 'No feedback provided';
  } else if (inviteData.invite_status === EVENT_INVITE_STATUS.tentative) {
    color = 'default';
    icon = null;
    comment = null;
  }

  const handlePopoverClose = () => setAnchorEl(null);

  return (
    <>
      <Chip
        label={
          inviteData.invite_status !== EVENT_INVITE_STATUS.recurring
            ? inviteData.invite_status
            : EVENT_INVITE_STATUS.tentative
        }
        color={color}
        icon={icon}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={handlePopoverClose}
      />
      {!!comment && (
        <Popover
          id={`invite_${inviteData.invite_id}`}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
          onClose={handlePopoverClose}
        >
          <Typography>{comment}</Typography>
        </Popover>
      )}
    </>
  );
};

export default InviteStatusChip;
