import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MaterialAvatar from './MaterialAvatar';
import { displayableFullName } from '../utils/contact';

const ContactPreview = ({ contactData, isShowAvatar }) => {
  const {
    title,
    company,
    amera_avatar_url,
    first_name,
    last_name,
    member_id,
    extra,
    unsecured = false,
  } = contactData;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      spacing={1}
    >
      {isShowAvatar && (
        <Grid item xs={2} className="contact-preview-avatar-img">
          <MaterialAvatar
            member_id={member_id}
            firstName={first_name}
            lastName={last_name}
            src={amera_avatar_url}
          />
        </Grid>
      )}

      <Grid container item direction="column" className="contactInfo">
        <Grid item>
          <Typography variant="body1">
            {displayableFullName(contactData)}
          </Typography>
        </Grid>
        <Grid item>
          {extra ? (
            <Typography variant="body2">{extra}</Typography>
          ) : (
            <Typography variant="body2">{`${title ? title : ''} ${
              title && company ? 'at' : ''
            } ${company ? company : ''}`}</Typography>
          )}
        </Grid>
        {unsecured === true && <Grid item>(outside member)</Grid>}
      </Grid>
    </Grid>
  );
};

export default ContactPreview;
