import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from './modal';
import PicturePin from '../Forms/PicturePinForm/PicturePin';

import {
  updateContactSecurity,
  getContactSecurity,
  updateGroupSecurity,
  getGroupSecurity,
} from '../../redux/actions/security';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: 130,
  },
}));

const SecurityDetailModal = (props) => {
  const {
    open,
    loading,
    onClose,
    contact_id,
    security,
    preFetched,
    updateSecurityAndGetKey,
    group_id,
    group_leader_id,
    member_id,
    disabled,
    type = 'contact',
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [securityData, setSecurityData] = React.useState();

  const handleChange = (data) => {
    console.log('P&P input changed', data);
    setSecurityData(data);
  };

  useEffect(() => {
    if (!preFetched && open) {
      if (type === 'contact' && contact_id) {
        dispatch(getContactSecurity(contact_id));
      } else if (type === 'group' && group_id) {
        dispatch(getGroupSecurity(group_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFetched, open, contact_id, group_id, type]);

  const handleSubmit = async () => {
    let formData = new FormData();
    if (securityData.picture) formData.append('picture', securityData.picture);
    formData.append('pin', securityData.pin);
    formData.append('exchange_option', securityData.exchangeOption);
    if (type === 'contact') {
      await dispatch(updateContactSecurity(formData, contact_id));
    } else {
      await dispatch(updateGroupSecurity(formData, group_id));
    }
    if (updateSecurityAndGetKey) {
      updateSecurityAndGetKey();
    }
    onClose();
  };

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={onClose}
        >
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled={securityData ? !securityData.isValidated : true}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${type === 'contact' ? 'Contact' : 'Group'} Security Details`}
      ModalContent={() => (
        <PicturePin
          isExchange
          onChange={handleChange}
          security={security}
          disabled={
            disabled ||
            (type === 'group' &&
              group_leader_id &&
              group_leader_id !== member_id)
              ? true
              : false
          }
          hidePinPicture={
            type === 'group' &&
            group_leader_id &&
            group_leader_id !== member_id &&
            security &&
            (security.exchange_option === 'MOST_SECURE' ||
              security.exchange_option === 'VERY_SECURE' ||
              security.exchange_option === 'SECURE')
              ? true
              : false
          }
        />
      )}
      ModalAction={ModalAction}
      className="modal-contact-security-details"
    />
  );
};

SecurityDetailModal.propTypes = {
  open: PropTypes.bool,
  preFetched: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SecurityDetailModal.defaultProps = {
  open: false,
  loading: false,
  preFetched: false,
};

const mapStateToProps = (state) => ({
  loading: state.security.loading,
  security: state.security.security,
  member_id: state.member.member.member_id,
});

export default connect(mapStateToProps)(SecurityDetailModal);
