import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const ColorRadio = (props) => {
  const { color, ...passProps } = props;
  return (
    <Radio
      color="default"
      style={{
        color: color,
      }}
      {...passProps}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    outline: 'auto',
    outlineColor: '#E0E0E0',
    position: 'relative',
    '& li': {
      display: 'flex',
    },
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  color: {
    margin: 0,
    padding: 0,
  },
  colorGroup: {
    justifyContent: 'space-between',
    paddingLeft: 10,
  },
}));

const ColorVerticalSelect = (props) => {
  const { colors, colorId, onChange } = props;
  const classes = useStyles();

  const [value, setValue] = React.useState(colorId);

  const handleChange = (event) => {
    setValue(+event.target.value);
    onChange(event);
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend" className={classes.label}>
        Color
      </FormLabel>
      <RadioGroup
        row
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
        className={classes.colorGroup}
      >
        {colors.map((co) => (
          <FormControlLabel
            value={co.id}
            key={co.id}
            control={<ColorRadio color={co.color} className={classes.color} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  colors: state.scheduleEvents.colors,
});

export default connect(mapStateToProps)(ColorVerticalSelect);
