import React, { useState } from 'react';
import moment from 'moment';

const Calendar = (props) => {
  
  const { schedulerSetting, date, selectedEventType } = props;

  const startTime = schedulerSetting.start_time;
  const timeInterval = schedulerSetting.time_interval;

  const startWeek = schedulerSetting.start_day;

  const currentDate = moment(date);
  let weekStart;

  if (date.getDay() >= schedulerSetting.start_day) {
    weekStart = moment(currentDate.clone().startOf('week')).add(
      schedulerSetting.start_day,
      'days'
    );
  } else {
    weekStart = moment(currentDate.clone().startOf('week')).subtract(
      7 - schedulerSetting.start_day,
      'days'
    );
  }

  const thisWeek = [];
  for (let i = 0; i <= 6; i++) {
    thisWeek.push(
      moment(weekStart)
        .add(i, 'days')
        .format(schedulerSetting.date_format)
    );
  }

  const initialValues = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };

  const [values, setValues] = useState(initialValues);
  const [dragValues, setDragValues] = useState(initialValues);
  const [dragState, setDragState] = useState(false);
  const [dragDayPosition, setDragDayPosition] = useState('Monday');
  const [dragTimePosition, setDragTimePosition] = useState(0);

  let times = [];
  let days = [];
  let weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  if (schedulerSetting.time_format === 'AM/PM') {
    for (let i = startTime; i < 24; i++) {
      if (i < 10) {
        times.push('0' + i + ' AM');
      } else if (i < 12) {
        times.push(i + ' AM');
      } else if (i === 12) {
        times.push(i + ' PM');
      } else if (i < 22) {
        times.push('0' + (i - 12) + ' PM');
      } else {
        times.push(i - 12 + ' PM');
      }
    }
    for (let i = 0; i < startTime; i++) {
      if (i < 10) {
        times.push('0' + i + ' AM');
      } else if (i < 12) {
        times.push(i + ' AM');
      } else if (i === 12) {
        times.push(i + ' PM');
      } else if (i < 22) {
        times.push('0' + (i - 12) + ' PM');
      } else {
        times.push(i - 12 + ' PM');
      }
    }
  } else if (schedulerSetting.time_format === '24Hr') {
    for (let i = startTime; i < 24; i++) {
      times.push(i >= 10 ? i : '0' + i);
    }
    for (let i = 0; i < startTime; i++) {
      times.push(i >= 10 ? i : '0' + i);
    }
  }

  for (let i = startWeek; i < 7; i++) {
    days.push(weekDays[i]);
  }
  for (let i = 0; i < startWeek; i++) {
    days.push(weekDays[i]);
  }

  const compareTime = (dayInd1, time1, dayInd2, time2, startTime) => {
    if (dayInd1 > dayInd2) {
      return true;
    } else if (dayInd1 === dayInd2) {
      if (
        (time1 >= startTime ? time1 : time1 + 24) >=
        (time2 >= startTime ? time2 : time2 + 24)
      ) {
        return true;
      }
    }

    return false;
  };

  const dragStart = (day, time, e) => {
    let tmp = { ...values };
    tmp[day][time] = true;
    setDragDayPosition(day);
    setDragTimePosition(time);
    setDragState(true);
    setValues({ ...tmp });
  };

  const dragOver = (day, time) => {
    // console.log("DragOver", dragState);

    if (!dragState) {
      return;
    }

    const dayIndDrag = days.indexOf(dragDayPosition);
    const dayIndNow = days.indexOf(day);

    let cells = { ...initialValues };
    let startDayInd, endDayInd;
    let startTimeInd, endTimeInd;

    if (schedulerSetting.drag_method === 'Drag_Normal') {
      if (
        compareTime(
          dayIndNow,
          time,
          dayIndDrag,
          dragTimePosition,
          schedulerSetting.start_time
        )
      ) {
        startDayInd = dayIndDrag;
        startTimeInd = dragTimePosition;
        endDayInd = dayIndNow;
        endTimeInd = time;
      } else {
        startDayInd = dayIndNow;
        startTimeInd = time;
        endDayInd = dayIndDrag;
        endTimeInd = dragTimePosition;
      }

      //console.log(startDayInd, startTimeInd, endDayInd, endTimeInd);

      for (let i = startDayInd; i <= endDayInd; i++) {
        for (let j = 0; j < 24; j++) {
          if (
            compareTime(
              i,
              j,
              startDayInd,
              startTimeInd,
              schedulerSetting.start_time
            ) &&
            compareTime(
              endDayInd,
              endTimeInd,
              i,
              j,
              schedulerSetting.start_time
            )
          ) {
            cells[days[i]][j] = selectedEventType;
          }
        }
      }
    } else if (schedulerSetting.drag_method === 'Drag_Group') {
      if (dayIndNow >= dayIndDrag) {
        startDayInd = dayIndDrag;
        endDayInd = dayIndNow;
      } else {
        startDayInd = dayIndNow;
        endDayInd = dayIndDrag;
      }

      if (
        (time >= startTime ? time : time + 24) >=
        (dragTimePosition >= startTime
          ? dragTimePosition
          : dragTimePosition + 24)
      ) {
        startTimeInd = dragTimePosition;
        endTimeInd = startTimeInd <= time ? time : time + 24;
      } else {
        startTimeInd = time;
        endTimeInd =
          startTimeInd <= dragTimePosition
            ? dragTimePosition
            : dragTimePosition + 24;
      }

      // console.log(startDayInd, startTimeInd, endDayInd, endTimeInd);

      for (let i = startDayInd; i <= endDayInd; i++) {
        for (let j = startTimeInd; j <= endTimeInd; j++) {
          cells[days[i]][j % 24] = selectedEventType;
        }
      }
    }
    setDragValues({ ...cells });
    return cells;
  };

  const drop = (day, time) => {
    if (dragState) {
      const temp_cells = dragOver(day, time);
      let dragCells = { ...temp_cells };
      let cells = { ...values };

      for (let i in dragCells) {
        for (let j in dragCells[i]) {
          if (dragCells[i][j]) {
            cells[i][j] = dragCells[i][j];
          }
          dragCells[i][j] = false;
        }
      }

      setDragValues({ ...dragCells });
      setValues({ ...cells });    
    }

    setDragState(false);

    /*
    let mDateTime_Start = new Date(
      thisWeek[days.indexOf(dragDayPosition)] +
        ' ' +
        dragTimePosition +
        ':00:00'
    );
    let mDateTime_End = new Date(
      thisWeek[days.indexOf(day)] + ' ' + time + ':00:00'
    );

    props.setEventDetailItem({
      eventDetail_Name: '',
      eventDetail_Location: { Address: '', Zip: '' },
      eventDetail_Contacts: [],
      eventDetail_StartTime:
        mDateTime_Start.getTime() < mDateTime_End.getTime()
          ? mDateTime_Start
          : mDateTime_End,
      eventDetail_EndTime:
        mDateTime_Start.getTime() >= mDateTime_End.getTime()
          ? mDateTime_Start
          : mDateTime_End,
    });
    props.onOpenEventFromDragAndDrop(
      mDateTime_Start.getTime() < mDateTime_End.getTime()
        ? mDateTime_Start
        : mDateTime_End,
      mDateTime_Start.getTime() >= mDateTime_End.getTime()
        ? mDateTime_Start
        : mDateTime_End
    );
    */
  };

  return (
    <div className="scheduler">
      <div className="scheduler-left">
        <div className="scheduler-head">
          <div className="scheduler-head__row">
            <div className="scheduler-head__title">Week Of The</div>
          </div>
        </div>
        {days.map((day, ind) => (
          <div className="scheduler-body__row" key={ind}>
            <div className="scheduler-body__title">
              {day}
              <br />
              <span>{thisWeek[ind]}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="scheduler-right">
        <div className="scheduler-head">
          <div className="scheduler-head__row">
            <div className="scheduler-head__cells">
              {times.map((time, ind) => {
                const hh =
                  ind >= 24 - startTime
                    ? ind - 24 + startTime
                    : ind + startTime;

                return ((hh - startTime) % timeInterval === 0) ?
                  (
                    <div className="scheduler-head__cell" key={time}>
                      {time}
                    </div>
                  ) : null;
              })}
            </div>
          </div>
        </div>
        <div className="scheduler-body">
          {days.map((day) => (
            <div className="scheduler-body__row" key={day}>
              <div className="scheduler-body__cells">
                {times.map((time, ind) => {
                  const hh =
                    ind >= 24 - startTime
                      ? ind - 24 + startTime
                      : ind + startTime;
                  
                  return ((hh - startTime) % timeInterval === 0) ?
                     (
                      <div
                        className={`scheduler-body__cell color-${
                          dragValues[day][hh]
                            ? dragValues[day][hh]
                            : values[day][hh]
                            ? values[day][hh]
                            : ''
                        }`}
                        onMouseDown={() => dragStart(day, hh)}
                        onMouseMove={() => dragOver(day, hh)}
                        onMouseUp={() => {
                          drop(day, hh);
                        }}
                        key={ind}
                      ></div>
                    ) : null;

                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
