import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const AmeraPagination = (props) => {
  const {
    totalCount,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangeRowsPerPage,
    page,
    handleChangePage,
    defaultPage,
    minRowsPerPage,
    itemTypeLabel,
  } = props;

  return totalCount > minRowsPerPage ? (
    <div className="card-pagination contact-cards-pagination">
      <div className="item-per-page">
        <FormControl>
          <Select
            labelId="item-count-per-page-select-label"
            id="item-count-per-page-select"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            {rowsPerPageOptions.map((unit) => (
              <MenuItem value={unit} key={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <p className="item-count-text">{itemTypeLabel} Per Page</p>
      </div>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={parseInt(Math.ceil(totalCount / rowsPerPage))}
        page={page}
        onChange={handleChangePage}
        defaultPage={defaultPage}
        color="primary"
        showFirstButton
        showLastButton
      />
    </div>
  ) : null;
};

AmeraPagination.defaultProps = {
  itemTypeLabel: 'Cards',
  rowsPerPageOptions: [25, 50, 75, 100],
  totalCount: 0,
  rowsPerPage: 25,
  minRowsPerPage: 25,
  defaultPage: 1,
};

AmeraPagination.propTypes = {
  itemTypeLabel: PropTypes.string,
  minRowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  totalCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  defaultPage: PropTypes.number,
};

export default AmeraPagination;
