import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import ameralogoPng from '../../assets/img/logo-login.png';

import styles from '../../jss/views/passUpdateStyle';
import FormHeader from '../../components/AuthFormHeader';

import { changePassword } from '../../redux/actions/member';

const useStyles = makeStyles(styles);

const ResetPassword = (props) => {
  const classes = useStyles();
  const { member } = props;
  const isLoading = member.isLoading;
  const isUpdated = member.isUpdated;
  const error = member.error;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPasswordErr, setCurrentPasswordErr] = useState('');
  const [newPasswordErr, setNewPasswordErr] = useState('');
  const [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');
  const currentPasswordRef = React.useRef();

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'currentPassword') {
      setCurrentPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmNewPassword') {
      setConfirmNewPassword(value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { currentPassword, newPassword };

    if (!currentPassword) {
      setCurrentPasswordErr('Please provide your current password!');
      return;
    }
    if (!newPassword) {
      setNewPasswordErr('Please provide new password');
      return;
    }
    if (!confirmNewPassword) {
      setNewPasswordErr('Please provide confirmation password');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordErr('new passwords not matching!');
      return;
    }
    setCurrentPasswordErr('');
    setNewPasswordErr('');
    setConfirmNewPasswordErr('');

    props.changePassword(formData);
  };

  useEffect(() => {
    currentPasswordRef &&
      currentPasswordRef.current &&
      currentPasswordRef.current.focus();
  }, [currentPasswordRef]);

  function renderResetForm() {
    return (
      <div className={classes.authPage}>
        <FormHeader classes={classes} />
        <form
          className={classes.formSection}
          onSubmit={handleSubmit}
          onChange={handleChange}
        >
          <div className={ameralogoPng.logoImg}>
            <img src={ameralogoPng} alt="logo" />
          </div>
          <div className={classes.currentPasswordInput}>
            <TextField
              type="password"
              name="currentPassword"
              error={!!currentPasswordErr}
              inputRef={currentPasswordRef}
              helperText={currentPasswordErr}
              label="Current Password"
              fullWidth
              color="primary"
              value={currentPassword}
              required
              inputProps={{
                minLength: 6,
                maxLength: 12,
              }}
              disabled={isUpdated}
            />
          </div>
          <div className={classes.newPasswordInput}>
            <TextField
              type="password"
              name="newPassword"
              error={!!newPasswordErr}
              helperText={newPasswordErr}
              label="New Password"
              fullWidth
              color="primary"
              value={newPassword}
              required
              inputProps={{
                minLength: 6,
                maxLength: 12,
              }}
              disabled={isUpdated}
            />
          </div>
          <div className={classes.newPasswordInput}>
            <TextField
              type="password"
              name="confirmNewPassword"
              error={!!confirmNewPasswordErr}
              helperText={confirmNewPasswordErr}
              label="Confirm New Password"
              fullWidth
              color="primary"
              value={confirmNewPassword}
              required
              inputProps={{
                minLength: 6,
                maxLength: 12,
              }}
              disabled={isUpdated}
            />
          </div>
          <div className={classes.authWrapper}>
            <Button
              type="submit"
              variant="outlined"
              disabled={isLoading || isUpdated}
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </div>
          <div style={{ marginTop: 10 }}>
            {(!!error || isUpdated) && (
              <Typography color={isUpdated ? 'secondary' : 'error'}>
                {isUpdated ? 'Password Updated Successfully.' : error}
              </Typography>
            )}
          </div>
        </form>
      </div>
    );
  }
  return renderResetForm();
};

const mapStateToProps = (state) => ({
  member: state.member,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (formData) => {
      dispatch(changePassword(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
