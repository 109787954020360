import React from 'react';
import Grid from '@material-ui/core/Grid';

import TimeLine from '../../../components/MediaStreaming/Scheduler/timeline';
import VideoList from './videoList';

const ScheduleWidget = (props) => {
  return (
    <Grid container spacing={3}>
      {/* timeline */}
      <Grid item xs={12}>
        <TimeLine />
      </Grid>
      <Grid item xs={12}>
        <VideoList />
      </Grid>
    </Grid>
  );
};

export default ScheduleWidget;
