import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import AmeraSelect from '../../components/AmeraSelect';
import { loadCompanies } from '../../redux/actions/company';
import { useDispatch } from 'react-redux';

const ProjectOwnerSelect = (props) => {
  const {
    error,
    company,
    projectOwner,
    companies,
    handleChange,
    multiple,
  } = props;

  const dispatch = useDispatch();
  const handleOpen = () => dispatch(loadCompanies({ getAll: true }));

  let companyMembers = [];
  if (typeof company !== 'undefined' && company) {
    companyMembers = (
      (companies.filter((c) => c.name === company.name)[0] || {}).members || []
    ).map((m) => {
      return {
        ...m,
        fullName: `${m.first_name} ${m.last_name}`,
      };
    });
  }

  return (
    <AmeraSelect
      label="Project Owner"
      value={projectOwner.id}
      onChange={handleChange}
      onOpen={handleOpen}
      error={error}
      multiple={multiple}
    >
      {companyMembers &&
        companyMembers?.length > 0 &&
        companyMembers.map((m) => (
          <MenuItem key={m.id} selected={m.id === projectOwner.id} value={m.id}>
            {m.fullName}
          </MenuItem>
        ))}

      {/*
      TODO: need to remove verify first
      {projectOwner && !isLoading ? (
        <div
          style={{
            display: 'flex',
            direction: 'row',
            justifyContent: 'space-around',
          }}
        >
          <CircularProgress size={20} color="primary" />
          <Typography variant="body2">Loading..</Typography>
        </div>
      ) : companies?.length > 0 ? (
        companies.map((c) => (
          <MenuItem 
            key={c.id} 
            selected={c.id === projectOwner.id}
            value={c.id}>
            {`${c.first_name} ${c.last_name}`}
          </MenuItem>
        ))
      ) : (
        <Typography variant="body2">Please select a company first</Typography>
      )} */}
    </AmeraSelect>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: state.company.companyList,
    error: state.company.error,
    isLoading: state.company.isLoading,
  };
};

export default connect(mapStateToProps)(ProjectOwnerSelect);
