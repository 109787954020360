import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFFFFF',
  },
  description: {
    height: 275,
    background: '#97ACC1',
    padding: 18,
    borderRadius: 5,
    '& button': {
      border: '1px solid white',
      borderRadius: 7,
      fontSize: 24,
      width: 213,
      height: 74,
      marginTop: 20,
      textTransform: 'capitalize',
    },
  },
  desc: {
    '& p': {
      height: 60,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 3,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    '& select': {
      color: '#FFFFFF',
      border: '1px solid white',
    },
    '& svg': {
      color: '#FFFFFF',
    },
    '& .MuiSelect-select:not([multiple]) option': {
      backgroundColor: '#97ACC1',
    },
  },
  status: {
    fontSize: 24,
  },
}));

const LargeVideo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.description}>
        <div className={classes.toolbar}>
          <div className={classes.status}>Status: Pending</div>
          <div className={classes.select}>
            <FormControl variant="outlined" margin="dense">
              <Select
                native
                // value={state}
                // onChange={handleChange}
                inputProps={{
                  name: 'video-select1',
                  id: 'video-select1',
                }}
              >
                <option>{'Marketing Presentation1'}</option>
                <option>{'Marketing Presentation2'}</option>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.desc}>
          <span>Description:</span>
          <p>
            Marketing Inc. Jan 1999 – Present Avalanche Search Marketing Inc.
            Jan 1999 – Present search Marketing Inc. Jan 1999 – PresentAvalanche
            Search Marketing Inc. Jan Marketing Inc. Jan 1999 – Present
            Description: Marketing Inc. Jan 1999 – Present Avalanche Search
            Marketing Inc. Jan 1999 – Present search Marketing Inc. Jan 1999 –
            PresentAvalanche Search Marketing Inc. Jan Marketing Inc. Jan 1999 –
            Present
          </p>
        </div>
        <Button variant="contained" color="primary">
          Edit Video
        </Button>
      </div>
    </div>
  );
};

export default LargeVideo;
