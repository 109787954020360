import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { languageCodes, languageNames } from '../../../utils/language';
import { updateMessage } from '../../../redux/actions/app';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '320px',
    height: '100%',
    margin: theme.spacing(1, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  messageGroup: {
    marginTop: theme.spacing(3),
  },
}));

const LanguageDrawer = (props) => {
  const classes = useStyles();

  const [language, setLanguage] = useState('en-US');
  const { locale } = props;

  useEffect(() => {
    setLanguage(locale);
  }, [locale]);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const [messages, setMessages] = useState([]);

  const handleMessageChange = (event, id) => {
    event.persist();
    setMessages((prevState) =>
      prevState.map((x) => {
        if (x.id === id) return { ...x, text: event.target.value };
        return x;
      })
    );
  };

  useEffect(() => {
    const initialMessages = Object.entries(props.drawerMessages).map(
      ([key, value]) => ({
        id: value['id'],
        text: props.translations[language][value['id']],
      })
    );
    setMessages(initialMessages);
  }, [language, props.drawerMessages, props.translations]);

  const updateMessage = (id, text) => {
    props.updateMessage({ language, id, text });
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-langauge-label">Language</InputLabel>
        <Select
          labelId="select-langauge-label"
          id="select-language"
          value={language}
          onChange={handleChange}
        >
          {languageCodes.map((x, index) => (
            <MenuItem value={x} key={x}>
              {languageNames[index]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.messageGroup}>
        {messages.map((x, index) => (
          <TextField
            label={`${x.id}`}
            key={x.id}
            color="primary"
            fullWidth={true}
            value={x.text}
            onChange={(event) => {
              handleMessageChange(event, x.id);
            }}
            onKeyPress={(event) => {
              event.persist();
              if (event.charCode === 13) {
                updateMessage(x.id, x.text);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  translations: state.app.translations,
  locale: state.global.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateMessage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDrawer);
