import * as api from '../../config/api';
import { setContactAlert } from '../../utils/alert';

import {
  FETCH_CONTACT_LIST,
  FETCH_CONTACT_LIST_SUCCESS,
  FETCH_CONTACT_LIST_FAILED,
  NEW_CONTACT,
  NEW_CONTACTS,
  SET_CONTACT_ALERT,
  GET_CONTACT_MEMBERS,
  UPDATE_CONTACT_MEMBERS,
  REMOVE_CONTACT_MEMBER,
  REMOVE_CONTACT_MEMBERS,
  DELETE_CONTACT_START,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAILED,
  UPDATE_CONTACT_REQUEST_START,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_FAILED,
  FETCH_CONTACT_COMPANIES_START,
  FETCH_CONTACT_COMPANIES_SUCCESS,
  FETCH_CONTACT_COMPANIES_FAILED,
  FETCH_CONTACT_COUNTRIES_START,
  FETCH_CONTACT_COUNTRIES_SUCCESS,
  FETCH_CONTACT_COUNTRIES_FAILED,
  FETCH_CONTACT_ROLES_START,
  FETCH_CONTACT_ROLES_SUCCESS,
  FETCH_CONTACT_ROLES_FAILED,
  CONTACTS_RECIVERS,
  CONTACT_MESSAGE_SENT_SUCCESS,
  CONTACT_MESSAGE_SENT_FAIL,
} from '../actionTypes/contact';

const apiUrl = api.getAPIUrl();

export const loadContactMembers =
  (params = {}, showAlert = false) =>
  (dispatch) => {
    const url = `${apiUrl}/member-contacts?${new URLSearchParams(
      params
    ).toString()}`;
    dispatch({ type: FETCH_CONTACT_LIST });
    api
      .GET(url)
      .then((res) => {
        let payload;
        if (res.success) {
          dispatch(
            loadMemberContactsSuccess({
              members: res.members,
              count: res.count,
            })
          );
          const message = 'Members Loaded Successfully';
          payload = setContactAlert(res, true, message);
        } else {
          dispatch(loadMemberContactsSuccess({ members: [], count: 0 }));
          payload = setContactAlert(res, false);
        }
        if (showAlert) {
          dispatch(setReduxContactAlert(payload));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadMemberContactsSuccess({ members: [], count: 0 }));
        if (showAlert) {
          const payload = setContactAlert(error, false);
          dispatch(setReduxContactAlert(payload));
        }
      });
  };

export const addMemberToContact =
  (data, showAlert = true) =>
  (dispatch) => {
    const url = `${apiUrl}/member-contacts`;
    console.log('Sending to add contacts', data);
    dispatch({ type: FETCH_CONTACT_LIST });
    api
      .POST(url, data, {})
      .then((res) => {
        console.log(
          'Server response upon add members to contact',
          res.contacts.contacts
        );
        dispatch(addNewContacts(res.contacts.contacts));
        dispatch(removeContactMembers(res.contacts.contacts));

        // update contact filter options
        dispatch(loadContactCompanies());
        dispatch(loadContactCountries());
        dispatch(loadContactRoles());
      })
      .catch((err) => {
        console.log(err);
        if (showAlert) {
          const payload = setContactAlert(err, false);
          dispatch(setReduxContactAlert(payload));
        }
      });
  };

export const loadContacts =
  (params = {}, showAlert = false, contacts = [], get_url = null) =>
  (dispatch) => {
    get_url = get_url ? get_url : '/member/contact';
    const url = `${apiUrl}${get_url}?${new URLSearchParams(params).toString()}`;
    dispatch({ type: FETCH_CONTACT_LIST });
    return api
      .GET(url)
      .then((res) => {
        const count = contacts.length + res.count;
        contacts.push(...res.contacts);
        dispatch(loadContactsSuccess({ contacts, count }));
        if (showAlert) {
          const message = 'Contact list loaded';
          const payload = setContactAlert(res, true, message);
          dispatch(setReduxContactAlert(payload));
        }
        return contacts;
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadContactsError([]));
        if (showAlert) {
          const payload = setContactAlert(error, false);
          dispatch(setReduxContactAlert(payload));
        }
        return Promise.reject(error);
      });
  };

export const loadContactCompanies = () => (dispatch) => {
  const url = `${apiUrl}/member/contacts/companies`;
  dispatch(fetchCompaniesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.companies) {
        dispatch(fetchCompaniesRequestSuccess(res.companies));
      } else {
        dispatch(fetchCompaniesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchCompaniesRequestFailed(error));
    });
};
export const loadContactCountries = () => (dispatch) => {
  const url = `${apiUrl}/member/contacts/countries`;
  dispatch(fetchCountriesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.countries) {
        dispatch(fetchCountriesRequestSuccess(res.countries));
      } else {
        dispatch(fetchCountriesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchCompaniesRequestFailed(error));
    });
};
export const loadContactRoles = () => (dispatch) => {
  const url = `${apiUrl}/member/contacts/roles`;
  dispatch(fetchRolesRequestStart());
  api
    .GET(url)
    .then((res) => {
      if (res.success && res.roles) {
        dispatch(fetchRolesRequestSuccess(res.roles));
      } else {
        dispatch(fetchRolesRequestFailed(res.message));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchRolesRequestFailed(error));
    });
};

export const createNewContact = (data) => (dispatch) => {
  const url = `${apiUrl}/member/contact`;
  dispatch({ type: FETCH_CONTACT_LIST });
  api
    .POST(url, data, {})
    .then((res) => {
      dispatch(addNewContact(res.contact));
      dispatch(removeContactMember(res.contact.contact_member_id));
      const payload = setContactAlert(res, true);
      dispatch(setReduxContactAlert(payload));
    })
    .catch((error) => {
      console.log(error);
      const payload = setContactAlert(error, false);
      dispatch(setReduxContactAlert(payload));
    });
};

export const deleteContacts = (data) => (dispatch) => {
  const url = `${apiUrl}/member-contacts`;
  dispatch({ type: DELETE_CONTACT_START });
  api
    .DELETE(url, data, {})
    .then((res) => {
      dispatch(removeContacts(res.data));
      const message = `Contact's deleted successfully`;
      const payload = setContactAlert(res, true, message);
      dispatch(setReduxContactAlert(payload));
    })
    .catch((error) => {
      console.log(error);
      const payload = setContactAlert(error, false);
      dispatch(setReduxContactAlert(payload));
      dispatch({ type: DELETE_CONTACT_FAILED });
    });
};

export const updateContactProperty =
  (data, showAlert = true) =>
  (dispatch) => {
    const url = `${apiUrl}/member/contact`;

    const dataString = JSON.stringify(data);
    dispatch(updateContactRequestStart());
    api
      .PUT(url, dataString, { 'Content-Type': 'application/json' })
      .then((res) => {
        if (res && res.success) {
          dispatch(updateContactRequestSuccess(data));
          if (showAlert) {
            const payload = setContactAlert(res, true, res.description);
            dispatch(setReduxContactAlert(payload));
          }
        } else {
          if (showAlert) {
            const payload = setContactAlert(res, true, res.description);
            dispatch(setReduxContactAlert(payload));
          }
        }
      })
      .catch((error) => {
        if (showAlert) {
          const payload = setContactAlert(error, false);
          dispatch(setReduxContactAlert(payload));
        }
        dispatch(updateContactRequestFailed(error));
      });
  };

export const loadMemberContactsSuccess = (payload) => ({
  type: GET_CONTACT_MEMBERS,
  payload,
});

export const loadContactsSuccess = (payload) => ({
  type: FETCH_CONTACT_LIST_SUCCESS,
  payload,
});

export const loadContactsError = (payload) => ({
  type: FETCH_CONTACT_LIST_FAILED,
  payload,
});

export const addNewContacts = (payload) => ({
  type: NEW_CONTACTS,
  payload,
});

export const addNewContact = (payload) => ({
  type: NEW_CONTACT,
  payload,
});

export const setReduxContactAlert = (payload) => ({
  type: SET_CONTACT_ALERT,
  payload,
});

export const resetContactMembers = (payload) => ({
  type: UPDATE_CONTACT_MEMBERS,
  payload,
});

export const removeContactMember = (payload) => ({
  type: REMOVE_CONTACT_MEMBER,
  payload,
});

export const removeContactMembers = (payload) => ({
  type: REMOVE_CONTACT_MEMBERS,
  payload,
});

export const updateContactRequestStart = () => ({
  type: UPDATE_CONTACT_REQUEST_START,
});
export const updateContactRequestSuccess = (payload) => ({
  type: UPDATE_CONTACT_REQUEST_SUCCESS,
  payload,
});
export const updateContactRequestFailed = (payload) => ({
  type: UPDATE_CONTACT_REQUEST_FAILED,
  payload,
});

export const fetchCompaniesRequestStart = () => ({
  type: FETCH_CONTACT_COMPANIES_START,
});
export const fetchCompaniesRequestSuccess = (payload) => ({
  type: FETCH_CONTACT_COMPANIES_SUCCESS,
  payload,
});

export const fetchCompaniesRequestFailed = (payload) => ({
  type: FETCH_CONTACT_COMPANIES_FAILED,
  payload,
});

export const fetchCountriesRequestStart = () => ({
  type: FETCH_CONTACT_COUNTRIES_START,
});
export const fetchCountriesRequestSuccess = (payload) => ({
  type: FETCH_CONTACT_COUNTRIES_SUCCESS,
  payload,
});
export const fetchCountriesRequestFailed = (payload) => ({
  type: FETCH_CONTACT_COUNTRIES_FAILED,
  payload,
});

export const fetchRolesRequestStart = () => ({
  type: FETCH_CONTACT_ROLES_START,
});
export const fetchRolesRequestSuccess = (payload) => ({
  type: FETCH_CONTACT_ROLES_SUCCESS,
  payload,
});

export const fetchRolesRequestFailed = (payload) => ({
  type: FETCH_CONTACT_ROLES_FAILED,
  payload,
});

const removeContacts = (payload) => ({
  type: DELETE_CONTACT_SUCCESS,
  payload,
});

export const setNewRecivers = (reciver) => ({
  type: CONTACTS_RECIVERS,
  payload: reciver,
});

export const contactMessageSuccess = () => ({
  type: CONTACT_MESSAGE_SENT_SUCCESS,
});

export const contactMessageFail = () => ({
  type: CONTACT_MESSAGE_SENT_FAIL,
});
