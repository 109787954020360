export const IS_LOADING_FILESHARE_DATA = 'IS_LOADING_FILESHARE_DATA';
export const IS_LOADING_CREATE_FILESHARE = 'IS_LOADING_CREATE_FILESHARE';
export const FILESHARE_DATA = 'FILESHARE_DATA';
export const ADD_FILESHARE_DATA = 'ADD_FILESHARE_DATA';
export const SHAREDFILE_DATA = 'SHAREDFILE_DATA';
export const FEIL_DETAIL_DATA = 'FEIL_DETAIL_DATA';
export const SHARED_FILE_DETAIL_DATA = 'SHARED_FILE_DETAIL_DATA';
export const SET_SHARE_ALERT = 'SET_SHARE_ALERT';
export const DELETE_FILE = 'DELETE_FILE';
export const RENAME_FILE = 'RENAME_FILE'; // File renamed
export const IS_LOADING_RENAME_FILE = 'IS_LOADING_RENAME_FILE'; // Renaming routine in progress
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'; // File downloaded
export const IS_LOADING_DOWNLOAD_FILE = 'IS_LOADING_DOWNLOAD_FILE'; // Downloading routine in progress
export const DELETE_FILE_LOADING = 'DELETE_FILE_LOADING';
export const SHARE_FILE_LOADING = 'SHARE_FILE_LOADING';
export const DELETE_SHARED_FILE = 'DELETE_SHARED_FILE';
export const IS_LOADING_FILESHARE_FORM = 'IS_LOADING_FILESHARE_FORM';
export const IS_LOADING_DECRYPT_FORM = 'IS_LOADING_DECRYPT_FORM';
export const DECRYPTED_FILE_DATA = 'DECRYPTED_FILE_DATA';
export const IS_LOADING_ENCRYPT = 'IS_LOADING_ENCRYPT';
export const ENCRYPT_SUCCESS = 'ENCRYPT_SUCCESS';

export const FETCH_FILE_GROUPS_START = 'FETCH_FILE_GROUPS_START';
export const FETCH_FILE_GROUPS_SUCCESS = 'FETCH_FILE_GROUPS_SUCCESS';
export const FETCH_FILE_GROUPS_FAILED = 'FETCH_FILE_GROUPS_FAILED';
export const ATTACHMENT_DATA = 'ATTACHMENT_DATA';
export const FILETREE_DATA = 'FILETREE_DATA';
export const GROUPTREE_DATA = 'GROUPTREE_DATA';
