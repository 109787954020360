import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/styles';

import MaterialAvatar from '../../components/MaterialAvatar';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    padding: 6,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiCardHeader-title': {
      fontSize: theme.spacing(2),
      maxHeight: theme.spacing(3),
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },
  },

  cardTitle: {
    flexWrap: 'wrap',
    '& .MuiCardHeader-action': {
      color: '#747474',
      paddingTop: 6,
      paddingRight: theme.spacing(1),
    },
  },

  cardHeader: {
    '& .MuiAvatar-root': {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    '& .MuiCardHeader-subheader': {
      color: '#94c03d',
      fontWeight: 600,
      fontSize: theme.spacing(2),
      maxHeight: theme.spacing(6),
      textTransform: 'capitalize',
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
  },

  content: {
    paddingTop: 0,
    paddingBottom: 0,
    flexGrow: 1,
  },
}));

const CustomCard = (props) => {
  const classes = useStyles();
  const {
    title,
    action,
    subTitle,
    subHeader,
    avatar,
    content,
    buttons,
  } = props;

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardTitle} title={title} action={action} />
      <CardHeader
        className={classes.cardHeader}
        avatar={
          avatar ? (
            <MaterialAvatar
              firstName={avatar.first_name}
              lastName={avatar.last_name}
              src={avatar.url}
              isBasicAvatar={false}
            />
          ) : (
            ''
          )
        }
        title={
          subTitle
            ? subTitle // <span>
            : //   <b>{'From:'}</b> {subTitle}
              // </span>
              ''
        }
        subheader={subHeader}
      />
      <CardContent className={classes.content}>{content}</CardContent>
      <CardActions>{buttons}</CardActions>
    </Card>
  );
};

export default CustomCard;
