import { Grid, IconButton } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import { ApiEmailService } from '../../../../../config/apiService';
import { useMailTableStyles } from './MailTableStyles';
import { getMembersNamesFromIds } from '../../../../../utils/email';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const FullScreenMailPreview = ({
  index,
  email,
  handlers,
  section,
  selectEmail,
  selectedEmail,
  selectedEmails,
  isOwnEmail,
}) => {
  const classes = useMailTableStyles();

  const receivers = email.receivers || {
    amera: [],
  };

  const getAttachedFilesForDraft = async (email) => {
    let res = await ApiEmailService.getEmailDetails(
      'getDraftEmailDetails',
      email.mail_id
    );
    selectEmail({
      ...email,
      attachments: res.attachments,
    });
  };

  return (
    <TableRow
      hover
      className={classes.tableRow}
      aria-label={`email-preview-${index}`}
      key={email.mail_id}
      onClick={() => {
        getAttachedFilesForDraft(email);
      }}
    >
      <TableCell padding="checkbox"></TableCell>

      <TableCell padding="none" className={classes.cellAvatar}>
        <Grid container className={classes.cellAvatar}>
          <Grid item xs={12}>
            <span className={classes.sender}>
              {isOwnEmail
                ? getMembersNamesFromIds(receivers.amera, email.member_details)
                : `${email.first_name} ${email.last_name}`}
            </span>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell className={classes.cellSubject}>
        <span className={classes.subject}>{email.subject}</span>
      </TableCell>

      <TableCell component="th" scope="row" className={classes.cellPreview}>
        <span className={classes.mailPreview}>{email.noStyleBody}</span>
      </TableCell>

      <TableCell padding="none" className={classes.cellDate}>
        <span className={classes.previewTime}>
          {dayjs(email.time).format('MM/DD/YY hh:mm a')}
        </span>

        <IconButton
          className={classes.darkButton}
          aria-label="trash-icon"
          onClick={(e) => {
            e.stopPropagation();
            handlers.moveToTrash(email.mail_id);
          }}
        >
          <SVG name="mailTrash" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default FullScreenMailPreview;
