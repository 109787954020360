import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
// import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';

import ProfileInfo from './ProfileInfo';
import Modal from '../../../components/Modal/modal';

import {
  updateContactProperty,
  setReduxContactAlert,
} from '../../../redux/actions/contact';

import { getContactGroupMemberShip } from '../../../redux/actions/group';

import { getContactLocation } from '../../../utils/contact';

const ContactProfile = (props) => {
  const {
    open,
    onClose,
    contact,
    member,
    roles,
    // contactAlert,
    getContactGroupMemberShip,
    contactGroupMemberships,
  } = props;
  const [currentRole, setCurrentRole] = useState({ id: '', name: '' });

  const title = 'Contact Details';

  useEffect(() => {
    const setDefaultRole = () => {
      const role = roles.filter((role) => role.id === contact.role_id)[0];
      if (role && role.id && role.name) {
        setCurrentRole(role);
      } else {
        setCurrentRole({ id: '', name: '' });
      }
    };
    setDefaultRole();
  }, [contact, roles]);

  useEffect(() => {
    if (open) {
      const params = {
        member_id: contact.contact_member_id,
        sort: 'group_name',
      };
      getContactGroupMemberShip(params);
      console.log('getting contact memberships', params);
    }
  }, [getContactGroupMemberShip, contact.contact_member_id, open]);

  let home_location = getContactLocation(contact, 'home');
  if (home_location && !home_location.city) {
    home_location = null;
  }

  //Prepare member address
  const memberAddress = home_location
    ? `${home_location.city}, ${home_location.state}`
    : '';
  // const handleSnackbarClose = () => {
  //   if (contactAlert.show) {
  //     props.setReduxContactAlert({
  //       show: false,
  //       variant: contactAlert.variant,
  //       message: '',
  //     });
  //   }
  // };

  const updateContactRelationship = (name, value) => {
    if (name === 'relationship') {
      //for now updating contact role by name but we should update by role_id
      const relationship = roles.filter((r) => r.id === value)[0];
      const payload = {
        relationship_id: relationship.id,
        relationship: relationship.name,
        id: contact.id,
        name: 'relationship',
      };
      props.updateContactProperty(payload);
      setCurrentRole(relationship);
    }
  };

  const ModalContent = () => (
    <>
      <FormControl fullWidth className="modal-header-dropdown">
        <InputLabel id="contact-role-select-label">Relationship</InputLabel>
        <Select
          disableUnderline={true}
          labelId="contact-role-select-label"
          id="contact-role-select"
          value={currentRole.id}
          onChange={(e) =>
            updateContactRelationship('relationship', e.target.value)
          }
          label="Type"
        >
          {roles.length > 0 &&
            roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <div className="profile_page-body">
        <ProfileInfo
          isContactProfile={true}
          memberInfo={contact}
          member={member}
          setCompose={props.setCompose}
          videoCall={props.videoCall}
          onSchedule={props.onSchedule}
          title={contact.title}
          role={currentRole.name}
          address={memberAddress}
          groupMemberships={contactGroupMemberships}
        />
      </div>
    </>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        className="profile-modal"
      ></Modal>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={contactAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={contactAlert.variant}>{contactAlert.message}</Alert>
      </Snackbar> */}
    </>
  );
};

ContactProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contact: PropTypes.object,
  roles: PropTypes.arrayOf(PropTypes.object),
  contactGroupMemberships: PropTypes.arrayOf(PropTypes.object),
};

ContactProfile.defaultProps = {
  open: false,
  onClose: null,
  contact: [],
  roles: [],
  contactGroupMemberships: [],
};

const mapStateToProps = (state) => ({
  roles: state.member.roles,
  // contactAlert: state.contact.contactAlert,
  contactGroupMemberships: state.group.contactGroupMemberships,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateContactProperty,
      setReduxContactAlert,
      getContactGroupMemberShip,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactProfile);
