import { addChatMessage } from '../actions/chat';
import { getUserIdFromJID } from '../../utils/strophe/user';
import { readFileAsync } from '../../utils/ameraWebCrypto';

import { store } from '../../utils/store';
import { STROPHE_CHUNK_SIZE } from '../../utils/file';
import { setSnackbarData } from '../actions/snackbar';
import worker from 'workerize-loader!../../encryptFileWorker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import PromiseWorker from 'promise-worker';

const workerInstance = new worker();
const promiseWorker = new PromiseWorker(workerInstance);

var filesP2P = {};

const addFileInMessage = (from, id, filesLoading = false, props = {}) => {
  const storeState = store.getState();

  const uids = id.split(',');
  const messageID = uids[0];
  const fileID = uids[1];

  const conversation = storeState?.chat?.chatConversations?.filter(
    (c) => c.chatID === getUserIdFromJID(from)
  )?.[0];
  let messageObjFromStore = conversation?.messages.filter(
    (m) => m.id === messageID
  )?.[0];
  messageObjFromStore.filesLoading = filesLoading;

  let newFileData = {
    from,
    id: props.fileID || fileID,
    stampStart: props.stampStart || new Date().toISOString(),
    stampEnd: props.stampEnd || null,
    ...props,
  };

  messageObjFromStore.messageFiles.push({ ...newFileData });

  store.dispatch(addChatMessage(messageObjFromStore));
};

const updateMessageFileProps = (toJID, sid, filesLoading = false, props) => {
  const to = getUserIdFromJID(toJID);
  const uids = sid.split(',');
  const storeState = store.getState();

  const conversation = storeState?.chat?.chatConversations?.find(
    (c) => parseInt(c.chatID) === parseInt(to)
  );
  let messageObjFromStore = conversation?.messages.find(
    (m) => m.id === uids[0]
  );
  messageObjFromStore.filesLoading = filesLoading;

  messageObjFromStore.messageFiles = messageObjFromStore.messageFiles.map(
    (f) => {
      if (f.id === uids[1]) {
        return { ...f, ...props };
      }
      return f;
    }
  );
  return store.dispatch(addChatMessage(messageObjFromStore));
};

const fileHandler = (from, sid, name, size, mime) => {
  updateMessageFileProps(from, sid, true, {
    seq: 0,
    loading: true,
    size,
    name,
    mime,
    stampStart: new Date().toISOString(),
  });

  // cleanup this object when download complete
  filesP2P[sid] = {
    sid,
    fileParts: [],
  };

  return true;
};

const ibbHandler = async (type, from, sid, data, seq) => {
  const fromID = getUserIdFromJID(from);
  const state = store.getState();
  const securityKey = state?.security?.hexkey;
  const e2eEncryptionEnabled = state?.security?.e2eEncryptionEnabled;
  const contact_id = state?.security?.contact_id;

  switch (type) {
    case 'open':
      // already setting inside fileHandler()
      // updateMessageFileProps(from, sid, true, { seq: 0, loading: true });
      // filesP2P[sid]['fileParts'] = [];
      break;
    case 'data':
      updateMessageFileProps(from, sid, true, { seq });
      filesP2P[sid]['fileParts'].push(data);
      break;
    case 'close':
      const conversation = store
        .getState()
        ?.chat?.chatConversations?.filter(
          (c) => parseInt(c.chatID) === parseInt(getUserIdFromJID(from))
        )?.[0];
      const messageObjFromStore = conversation?.messages.filter(
        (m) => m.id.toString() === sid.split(',')[0]
      )?.[0];
      const fileFromStore = messageObjFromStore?.messageFiles.find(
        (f) => f.id.toString() === sid.split(',')[1]
      );
      if (filesP2P[sid] && fileFromStore) {
        let fileData = '',
          decryptedData = '',
          fileReceived = filesP2P[sid];

        fileData = 'data:' + fileFromStore['mime'] + ';base64,';

        for (let i = 0; i < fileReceived['fileParts'].length; i++) {
          decryptedData += fileReceived['fileParts'][i];
        }

        const dataReceivedSize = decryptedData.length;
        if (parseInt(fileFromStore['size']) === parseInt(dataReceivedSize)) {
          // received all data
          if (
            e2eEncryptionEnabled &&
            securityKey &&
            contact_id &&
            contact_id.toString() === fromID
          ) {
            try {
              decryptedData = await promiseWorker.postMessage({
                type: 'decrypt',
                key: securityKey,
                data: decryptedData,
              });

              console.log('decryptedData', decryptedData);
            } catch (e) {
              store.dispatch(
                setSnackbarData({
                  open: true,
                  message: `Failed to decrypt`,
                  type: 'error',
                })
              );
            }
          }
          fileData += decryptedData;

          // TODO FOR TESTING PURPOSE
          if (!fileFromStore['mime']?.match(/gif|png|jpg|jpeg/)) {
            downloadFile(fileData, fileFromStore['name']);
            updateMessageFileProps(from, sid, false, {
              src: '',
              loading: false,
              stamEnd: new Date().toISOString(),
            });
          } else {
            updateMessageFileProps(from, sid, false, {
              src: fileData,
              loading: false,
              stamEnd: new Date().toISOString(),
            });
          }
        } else {
          console.error('Failed to receive all data', sid);
        }
        delete filesP2P[sid]; //cleanup memory
      }
      break;
    default:
      throw new Error("ibbHandler shouldn't be here.");
  }
};

// TEMP: FOR TESTING PURPOSE
const downloadFile = (file, name) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'Download Btn');
  element.setAttribute('download', `${name}`);
  element.setAttribute('href', file);

  element.style.display = 'none';

  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
};

const closeIBB = (connection, toJID, sid) => {
  return new Promise((resolve, reject) => {
    connection.ibb.close(toJID, sid, function (err) {
      console.debug('ibb connection closed ', toJID, sid);
      if (err) {
        console.error('ibb close error => ', err);
        return reject(err);
      }
      console.debug('DONE: with sending data.');
      return resolve(true);
    });
  });
};

const sendData = (connection, toJID, sid, seq, data) => {
  return new Promise((resolve, reject) => {
    connection.ibb.data(toJID, sid, seq, data, (err) => {
      if (err) {
        console.error('ibb.data: err=', err);
        return reject(err);
      } else {
        return resolve(true);
      }
    });
  });
};

const fileTransferHandler = async (connection, toJID, sid, fileData) => {
  return new Promise((resolve, reject) => {
    // successfully initiated the transfer, now open the band
    connection.ibb.open(toJID, sid, STROPHE_CHUNK_SIZE, async (err) => {
      if (err) {
        console.error('ibb.open: err => ', err);
        return reject(err);
      }

      updateMessageFileProps(toJID, sid, true, {
        seq: 0,
        size: fileData.length,
        loading: true,
      });

      let seq = 0,
        nextslice;

      for (let i = 0; i < fileData.length; i += STROPHE_CHUNK_SIZE) {
        nextslice = fileData.slice(i, i + STROPHE_CHUNK_SIZE);
        seq++;

        console.debug('current sequence no => ', seq);
        updateMessageFileProps(toJID, sid, true, { seq });
        try {
          await sendData(
            connection,
            toJID,
            sid,
            seq,
            nextslice,
            fileData.length
          );
          // close ibb when all chunks sent
          if (seq === Math.ceil(fileData.length / STROPHE_CHUNK_SIZE)) {
            await closeIBB(connection, toJID, sid);
            return resolve(true);
          }
        } catch (err) {
          return reject(err);
        }
      }
    });
  });
};

const getEncryptedDataUrl = (file, toJID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const toID = getUserIdFromJID(toJID);
      const dataURL = await readFileAsync(file);
      const realjuju = dataURL.split(',')[1];
      const state = store.getState();
      const securityKey = state?.security?.hexkey;
      const e2eEncryptionEnabled = state?.security?.e2eEncryptionEnabled;
      const contact_id = state?.security?.contact_id;

      let encryptedData = realjuju;

      if (
        e2eEncryptionEnabled &&
        securityKey &&
        contact_id &&
        contact_id.toString() === toID
      ) {
        try {
          encryptedData = await promiseWorker.postMessage({
            type: 'encrypt',
            key: securityKey,
            data: encryptedData,
          });
          console.log('encryptedData', encryptedData);
          return resolve(encryptedData);
        } catch (err) {
          store.dispatch(
            setSnackbarData({
              open: true,
              message: `Failed to decrypt`,
              type: 'error',
            })
          );
          return reject(err);
        }
      } else {
        return resolve(realjuju);
      }
    } catch (err) {
      return reject(err);
    }
  });
};

const sendFile = (connection, toJID, sid, file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fileData = await getEncryptedDataUrl(file, toJID);
      connection.si_filetransfer.send(
        toJID,
        sid,
        file.name,
        fileData.length, //Get size of file converted into data url
        file.mime,
        async (err) => {
          if (err) {
            return reject(err);
          }
          try {
            await fileTransferHandler(connection, toJID, sid, fileData);
            return resolve(true);
          } catch (err) {
            return reject(err);
          }
        }
      );
    } catch (err) {
      return reject(err);
    }
  });
};

export const sendFiles = async (
  connection,
  toJID,
  messageObj,
  filesInfo,
  files //original files selected by user
) => {
  for (let i = 0; i < files.length; i++) {
    let fileInfo = filesInfo[i];
    const file = files[i],
      sid = messageObj.id + ',' + fileInfo.id; //sid is a combination of msg id and file id

    fileInfo.loading = true;
    fileInfo.stampStart = new Date().toISOString();

    //store images src into redux for viewing purpose
    if (file.type?.match(/gif|png|jpg|jpeg/)) {
      fileInfo.src = await readFileAsync(file);
    }

    addFileInMessage(toJID, sid, true, fileInfo);

    try {
      const success = await sendFile(connection, toJID, sid, file);
      if (success) {
        console.log('is file send success? ', success);
        updateMessageFileProps(toJID, sid, false, {
          error: null,
          loading: false,
          stampEnd: new Date().toISOString(),
        });
      }
    } catch (err) {
      updateMessageFileProps(toJID, sid, false, {
        error: { name: err.name, message: err.message },
        loading: false,
        stampEnd: new Date().toISOString(),
      });
    }
    delete filesP2P[sid]; //cleanup memory after send complete
  }
};

export const fileEventListeners = (connection) => {
  connection.si_filetransfer.addFileHandler(fileHandler);
  connection.ibb.addIBBHandler(ibbHandler);
};
