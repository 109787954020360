import Promise from 'bluebird';

import { channels } from '../shared/constants';
import { convertToFile, extractFileInfo } from './file';

import { nonBrowserFeatureState } from './non-browser-state';

const { ipcRenderer } = window;

export const getVersionFromMain = async () => {
  if (!nonBrowserFeatureState) {
    return false;
  }
  const version = await ipcRenderer.invoke(channels.GET_VERSION);
  return version;
};

export const isSecuritySetInMain = async () => {
  if (!nonBrowserFeatureState) {
    return false;
  }
  const exists = await ipcRenderer.invoke(channels.GET_SECURITY);
  return !exists;
};

export const setSecurityInMain = async (path, pin, member) => {
  if (!nonBrowserFeatureState) {
    return false;
  }
  const setSecurityResult = await ipcRenderer.invoke(channels.SET_SECURITY, {
    picture: path,
    pin: pin,
    member: member,
  });
  return setSecurityResult;
};

export const checkSecurityPictureInMain = async (file) => {
  if (!nonBrowserFeatureState) {
    return false;
  }

  const normalizingFile = extractFileInfo(file);

  try {
    const testResult = await ipcRenderer.invoke(
      channels.TEST_SECURITY_PICTURE,
      normalizingFile
    );
    return testResult;
  } catch (e) {
    console.error('Check Security Picture - IPC Invoke Error');
    console.error(e);
    throw new Error(e.message);
  }
};

export const getDirectoryInMain = async () => {
  if (!nonBrowserFeatureState) {
    return;
  }
  ipcRenderer.send(channels.GET_LOCAL_DIRECTORY);
  return new Promise((resolve, reject) => {
    ipcRenderer.on(channels.GET_LOCAL_DIRECTORY, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.GET_LOCAL_DIRECTORY);
      console.log('GET_LOCAL_DIRECTORY BACK FROM MAIN', arg);
      resolve(arg.directory);
    });
    ipcRenderer.on(channels.GET_LOCAL_DIRECTORY_ERROR, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.GET_LOCAL_DIRECTORY_ERROR);
      console.error('[ERROR] GET_LOCAL_DIRECTORY BACK FROM MAIN', arg);
      reject(arg.error);
    });
  });
};

export const processSelectedFilesInMain = async (selectedFiles) => {
  if (!nonBrowserFeatureState) {
    return selectedFiles;
  }

  console.log('We are going to send to the main process files to encrypt');
  let selectedFilesMap = Array.prototype.slice.call(selectedFiles);
  selectedFilesMap = selectedFilesMap.map(extractFileInfo);

  console.log('SElected files', selectedFilesMap);
  ipcRenderer.send(channels.ENCRYPT_FILES, {
    files: selectedFilesMap,
  });

  return new Promise((resolve, reject) => {
    ipcRenderer.on(channels.ENCRYPT_FILES, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.ENCRYPT_FILES);
      if (arg.queued === false) {
        console.error('ENCRYPT_FILES_INIT_FAIL', arg);
        reject(new Error('Encrypting file failure'));
      }
      console.log('FILES ARE BEING ENCRYPTED');
    });

    ipcRenderer.on(channels.ENCRYPT_FILES_FAILED, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.ENCRYPT_FILES_FAILED);
      console.log('ENCRYPT_FILES_FAILED', arg);
      reject(new Error(arg.error));
    });

    ipcRenderer.on(channels.ENCRYPT_FILES_SUCCESS, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.ENCRYPT_FILES_SUCCESS);
      let files = arg.files.map(convertToFile);
      console.log('ENCRYPT_FILES_SUCCESS', files);
      resolve(files);
    });
  });
};

export const processSelectedFilesLocalInMain = async (
  selectedDirectory,
  selectedFiles
) => {
  if (!nonBrowserFeatureState) {
    return selectedFiles;
  }

  console.log('We are going to send to the main process files to encrypt');
  let selectedFilesMap = Array.prototype.slice.call(selectedFiles);
  selectedFilesMap = selectedFilesMap.map(extractFileInfo);

  console.log('Selected Files', selectedFilesMap);
  console.log('Selected Directory', selectedDirectory);
  ipcRenderer.send(channels.ENCRYPT_LOCAL_DIRECTORY_FILES, {
    files: selectedFilesMap,
    finalDirectory: selectedDirectory,
  });

  return await new Promise((resolve, reject) => {
    ipcRenderer.on(channels.ENCRYPT_FILES, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.ENCRYPT_FILES);
      if (arg.queued === false) {
        console.error('ENCRYPT_FILES_INIT_FAIL', arg);
        reject(new Error('Encrypting file failure'));
      }
      console.log('FILES ARE BEING ENCRYPTED');
    });

    ipcRenderer.on(channels.ENCRYPT_FILES_FAILED, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.ENCRYPT_FILES_FAILED);
      console.log('ENCRYPT_FILES_FAILED', arg);
      reject(new Error(arg.error));
    });

    ipcRenderer.on(channels.ENCRYPT_FILES_SUCCESS, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.ENCRYPT_FILES_SUCCESS);
      console.log('ENCRYPT_FILES_SUCCESS', arg);
      resolve(arg.files);
    });
  });
};

export const decryptSelectedFilesLocalInMain = async (
  selectedDirectory,
  selectedFiles
) => {
  if (!nonBrowserFeatureState) {
    return selectedFiles;
  }

  console.log('We are going to send to the main process files to decrypt');
  let selectedFilesMap = Array.prototype.slice.call(selectedFiles);
  selectedFilesMap = selectedFilesMap.map(extractFileInfo);

  console.log('Decrypt Selected Files', selectedFilesMap);
  console.log('Decrypt Selected Directory', selectedDirectory);
  ipcRenderer.send(channels.DECRYPT_LOCAL_DIRECTORY_FILES, {
    files: selectedFilesMap,
    finalDirectory: selectedDirectory,
  });

  return await new Promise((resolve, reject) => {
    ipcRenderer.on(channels.DECRYPT_FILES, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.DECRYPT_FILES);
      if (arg.queued === false) {
        console.error('DECRYPT_FILES_INIT_FAIL', arg);
        reject(new Error('Decrypting file failure'));
      }
      console.log('FILES ARE BEING DECRYPTED');
    });

    ipcRenderer.on(channels.DECRYPT_FILES_FAILED, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.DECRYPT_FILES_FAILED);
      console.log('DECRYPT_FILES_FAILED', arg);
      reject(new Error(arg.error));
    });

    ipcRenderer.on(channels.DECRYPT_FILES_SUCCESS, (event, arg) => {
      ipcRenderer.removeAllListeners(channels.DECRYPT_FILES_SUCCESS);
      console.log('DECRYPT_FILES_SUCCESS', arg);
      resolve(arg.files);
    });
  });
};

// Type = either keyJson // keyPicture // mainLog // rendererLog
export const debugFileDownloadtoMain = async (type) => {
  if (!nonBrowserFeatureState) {
    console.log('debugFileDownloadtoMain ran in browser');
    return null;
  }
  // If we are in electron
  console.log(
    `We are going to tell main to prompt user to save ${type} in directory of choice`
  );
  ipcRenderer.send(channels.DEBUG_DOWNLOAD, type);
};
