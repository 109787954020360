import React from 'react';

const IconCameraDisabled = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M4.375 2.688l23.625 23.625-1.688 1.688-4.25-4.25c-0.188 0.125-0.5 0.25-0.75 0.25h-16c-0.75 0-1.313-0.563-1.313-1.313v-13.375c0-0.75 0.563-1.313 1.313-1.313h1l-3.625-3.625zM28 8.688v14.25l-14.938-14.938h8.25c0.75 0 1.375 0.563 1.375 1.313v4.688z"></path>
  </svg>
);

export default IconCameraDisabled;
