import React from 'react';

const Reply = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.13243 5.10839L3.41522 4.79667C3.45109 4.80501 3.48712 4.81253 3.52327 4.81923C3.66543 4.84556 3.80942 4.85909 3.95296 4.85909L3.13243 5.10839ZM3.13243 5.10839L3.1498 5.1545L3.13242 5.1084"
      fill="#94C03D"
      stroke="#94C03D"
      strokeWidth="3"
    />
  </svg>
);

export default Reply;
