import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Breadcrumbs from '../../../components/AmeraBreadCrumbs';
import {
  deleteContacts,
  loadContactCompanies,
  loadContactCountries,
  loadContactRoles,
  loadContacts,
  setReduxContactAlert,
} from '../../../redux/actions/contact';
import { debounce } from 'lodash';
import { setContactListFilterSelectOptions } from '../../../utils/general';
import TeamListContainer from './TeamListContainer';
import ProjectStepsNav from './ProjectStepsNav';
import { SnackBarMessage } from '../../../components/Modal/CustomSnackBar';
// route utils
import useQuery from '../../../utils/useQuery';

// action
import { setSnackbarData } from '../../../redux/actions/snackbar';

// api calls
import { ProjectsApi } from '../../../redux/actions/project';

// components
import EditModeButtons from './EditModeButtons';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    // marginTop: '20px',
    marginRight: theme.spacing(2),
    height: '45px',
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortContact: {
    float: 'right',
    paddingRight: '20px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: '11px 8px 8px 0',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  searchToolsContainer: {
    padding: '20px 8px 0 8px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const TeamSelect = (props) => {
  const classes = useStyles();
  const {
    totalCount,
    contacts,
    companies,
    countries,
    roles,
    contactAlert,
    loadContacts,
    loadContactCompanies,
    loadContactCountries,
    loadContactRoles,
    setReduxContactAlert,
  } = props;
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [contactData, setContactData] = useState([]);
  const { queryString: pageMode } = useQuery('mode');
  // TODO: incomplete so far I'm able to get mode from query string to I dentify the mode of
  // a page is in EDIT. Now I have to configure selected team so user can update selected team (add or remove members)

  // use in EditModeButtons.js component
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [showContactsSetting, setShowContactsSetting] = useState(false);
  const [allFitlerOptions, setAllFitlerOptions] = useState([]);
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('first_name');
  const [order, setOrder] = useState('asc');
  const [filterBy, setFilterBy] = useState({});
  let [teamSelectedContacts, setTeamSelectedContacts] = useState([]);

  const handleNext = (e) => {
    e.preventDefault();
    postMembersList(teamSelectedContacts);
    history.push(
      `${process.env.PUBLIC_URL}/projects/assign-team-roles/${projectId}`
    );
  };

  const handleBack = (e) => {
    e.preventDefault();
    history.push(`/projects/project-definition/${projectId}`);
  };

  function postMembersList(teamSelectedContacts, callBackToast) {
    const payload = {
      project_id: projectId,
      members: teamSelectedContacts.map((o) => o.contact_member_id),
    };
    dispatch(ProjectsApi.postMembersList(payload, callBackToast));
  }

  const deleteContactsFunc = (selectedContacts) => {
    let formData = new FormData();
    formData.set(
      'contactIds',
      selectedContacts.map(({ id }) => id)
    );
    props.deleteContacts(formData);
  };

  const search = (e) => {
    const value = e.target.value;
    loadSortedContacts(null, null, null, value);
    setSearchKey(value);
  };

  useEffect(() => {
    loadContacts({ searchKey, pageNumber: pageNumber - 1, pageSize });
    dispatch(ProjectsApi.getProjects());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loadContacts]);

  const loadSortedContacts = useCallback(
    debounce(
      (property, value, filterByItems, search_key, page_number, page_size) => {
        let sort = '';
        if (!property) {
          property = orderBy;
        }
        if (!value) {
          value = order;
        }

        if (property === 'member_name') {
          sort =
            value === 'desc'
              ? `-first_name,-last_name`
              : `first_name,last_name`;
        } else {
          sort = value === 'desc' ? `-${property}` : `${property}`;
        }
        let params = {};
        filterByItems = filterByItems ? filterByItems : filterBy;
        if (Object.keys(filterByItems).length > 0) {
          //consider user recent filter options as well if exist
          params = { sort, filter: new URLSearchParams(filterByItems) };
        } else {
          params = { sort };
        }
        params.searchKey =
          search_key !== undefined && search_key !== null
            ? search_key
            : searchKey;
        params.pageNumber = page_number >= 0 ? page_number : pageNumber - 1;
        params.pageSize = page_size ? page_size : pageSize;

        loadContacts(params, true);
        setOrderBy(property);
        setFilterBy(filterByItems);
      },
      500
    ),
    []
  );

  const loadFilteredContacts = ({ target: { value, name } }) => {
    // const { name, value } = e.target;
    let filterByItems;
    if (!value) {
      filterByItems = { ...filterBy };
      delete filterByItems[name];
    } else {
      filterByItems = {
        ...filterBy,
        [name]: value,
      };
    }
    loadSortedContacts(null, null, filterByItems);
  };

  const handleSnackbarClose = () => {
    if (contactAlert.show) {
      const alertOption = {
        show: false,
        variant: contactAlert.variant,
        message: '',
      };
      setReduxContactAlert(alertOption);
    }
  };

  useEffect(() => {
    if (contactAlert.show) {
      setShowContactsSetting(false);
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: contactAlert.variant ? contactAlert.variant : 'success',
          message: '',
        };
        setReduxContactAlert(alertOption);
      }, 5000);
    }
  }, [contactAlert, setReduxContactAlert]);

  const loadInitialFilterValues = useCallback(() => {
    loadContactCompanies();
    loadContactCountries();
    loadContactRoles();
  }, [loadContactCompanies, loadContactCountries, loadContactRoles]);

  useEffect(() => {
    loadInitialFilterValues();
  }, [loadInitialFilterValues]);

  useEffect(() => {
    setAllFitlerOptions(setContactListFilterSelectOptions(contacts));
    setContactData(contacts);
  }, [contacts]);

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedContacts(null, null, null, null, newPage - 1);
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(parseInt(pageSize, 10));
    setPageNumber(1);
    loadSortedContacts(null, null, null, null, 0, pageSize);
  };

  const filterSelectKeys = [
    {
      id: 'company',
      text: 'Company',
    },
    {
      id: 'title',
      text: 'Title',
      key: 'title',
    },
    {
      id: 'role',
      text: 'Type',
    },
  ];

  const setSelectOptions = (key, data, sub_key) => {
    let optionValues = [];
    let foundFilterOptions = null;

    switch (key) {
      case 'company':
        optionValues = companies.map((company) => {
          return {
            value: company.company_name,
            name: company.company_name,
          };
        });
        break;
      case 'country_code_id':
        optionValues = countries.map((country) => {
          return {
            value: country.id,
            name: country.name,
          };
        });
        break;
      case 'role':
        // Roles come with Identifiers and name
        optionValues = roles.map((role) => {
          return {
            value: role.name,
            name: role.name,
          };
        });
        break;
      default:
        // By default we must create the expected object
        foundFilterOptions = data.find((d) => d.label === key);
        if (foundFilterOptions) {
          optionValues = foundFilterOptions.values.map((filter) => {
            return {
              value: filter,
              name: filter,
            };
          });
        }
        break;
    }
    return (
      <>
        <option aria-label="None" value="" />
        {optionValues.length > 0 &&
          optionValues.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
      </>
    );
  };

  function handleSelectedContacts(selectedContacts) {
    // to enable save button for EDIT mode
    setIsSaveDisabled(false);
    setTeamSelectedContacts(selectedContacts);
    // teamSelectedContacts = selectedContacts;
  }

  function handleExit() {
    setIsSaveDisabled(true);
    history.push(`${process.env.PUBLIC_URL}/projects/my-projects`);
  }

  function handleSave(e) {
    e.preventDefault();
    postMembersList(teamSelectedContacts, callBackToast);
    setIsSaveDisabled(true);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/projects/assign-team-roles/${projectId}`,
      search: '?mode=EDIT',
    });
  }

  /**
   * 
   * @param {*} options {
        open: true,
        message,
        type: 'success',
      }
   */
  function callBackToast(options) {
    dispatch(setSnackbarData(options));
  }

  return (
    <>
      <SnackBarMessage />
      <Breadcrumbs
        parent="Create Project"
        current_directory="Select your Team"
      />
      <div className="view-section contact-section">
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.searchToolsContainer}
        ></Grid>
        <React.Fragment>
          <div className="multi-filter-option">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <div className={classes.search}>
                  <div aria-label="search-icon" className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    value={searchKey}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={search}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              </Grid>

              {filterSelectKeys.map((key) => (
                <Grid item xs={3} key={key.id} className="filter-select-item">
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filled-age-native-simple">
                      {key.text}
                    </InputLabel>
                    <Select
                      native
                      value={
                        filterBy && filterBy[key.id] ? filterBy[key.id] : ''
                      }
                      onChange={(e) => loadFilteredContacts(e)}
                      inputProps={{
                        name: key.id,
                        id: key.id,
                      }}
                    >
                      {setSelectOptions(key.id, allFitlerOptions, key.key)}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </div>
        </React.Fragment>

        <TeamListContainer
          totalCount={totalCount}
          contactData={contactData}
          order={order}
          setOrder={(o) => setOrder(o)}
          orderBy={orderBy}
          setOrderBy={(p) => setOrderBy(p)}
          pageSize={pageSize}
          pageNumber={pageNumber}
          pageMode={pageMode}
          handleChangePage={handleChangePage}
          handleSelectedContacts={handleSelectedContacts}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          showContactsSetting={showContactsSetting}
          setShowContactsSetting={setShowContactsSetting}
          loadSortedContacts={loadSortedContacts}
          deleteContactsFunc={deleteContactsFunc}
        />
        {pageMode && pageMode === 'EDIT' ? (
          <EditModeButtons
            primaryBtnLabel="Save and Continue"
            isSaveDisabled={isSaveDisabled}
            handleExit={handleExit}
            handleSave={handleSave}
          />
        ) : (
          <ProjectStepsNav handleBack={handleBack} handleNext={handleNext} />
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={contactAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={contactAlert.variant}>{contactAlert.message}</Alert>
      </Snackbar>
    </>
  );
};

TeamSelect.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  contactAlert: PropTypes.object,
};

TeamSelect.defaultProps = {
  companies: [],
  countries: [],
  roles: [],
  contactAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
  totalCount: state.contact.totalCount,
  companies: state.contact.companies,
  countries: state.contact.countries,
  roles: state.contact.roles,
  contactAlert: state.contact.contactAlert,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadContacts,
        loadContactCompanies,
        loadContactCountries,
        loadContactRoles,
        deleteContacts,
        setReduxContactAlert,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamSelect);
