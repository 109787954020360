import * as api from '../../config/api';

import {
  FETCH_TOPIC_SUCCESS,
  FETCH_TOPIC_LIST_SUCCESS,
  SEND_TOPIC_SUCCESS,
  SEND_POST_SUCCESS,
  SEND_LIKE_SUCCESS,
} from '../actionTypes/forum';

import { setSnackbarData } from './snackbar';

const apiUrl = api.apiUrl;

export const addNewTopic = (formData, group_id) => {
  const url = `${apiUrl}/groups/${group_id}/forum/topics`;

  return function (dispatch) {
    api
      .POST(url, formData, {}, false)
      .then((res) => {
        if (res.success) {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Successfully created a topic',
              type: 'success',
            })
          );
          dispatch({ type: SEND_TOPIC_SUCCESS, payload: res.topic });
        } else {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'A topic was not created. Try some later!',
              type: 'error',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'A topic was not created. Try some later!',
            type: 'error',
          })
        );
      });
  };
};

export const getTopics = (group_id, search_key, selectedMembers, sortDir) => {
  const members = selectedMembers.join(',');
  console.log('members', members);
  const params = {
    sort: sortDir === 'descending' ? '-create_date' : 'create_date',
  };

  if (search_key) {
    params['search_key'] = search_key;
  }
  if (members) {
    params['members'] = members;
  }

  const url = `${apiUrl}/groups/${group_id}/forum/topics?${new URLSearchParams(
    params
  ).toString()}`;

  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: FETCH_TOPIC_LIST_SUCCESS, payload: res.topics });
        }
      })
      .catch((error) => {});
  };
};

export const getTopic = (topic_id) => {
  const url = `${apiUrl}/forum/topics/${topic_id}`;

  return function (dispatch) {
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          console.log('here');
          dispatch({
            type: FETCH_TOPIC_SUCCESS,
            payload: { topic: res.topic, posts: res.posts },
          });
        }
      })
      .catch((error) => {});
  };
};

export const addNewComment = (formData) => {
  const url = `${apiUrl}/forum/topics/post`;

  return function (dispatch) {
    api
      .POST(url, formData, {}, false)
      .then((res) => {
        if (res.success) {
          dispatch({ type: SEND_POST_SUCCESS, payload: res.post });
        }
      })
      .catch((error) => {});
  };
};

export const like = (post_id, likes) => {
  const url = `${apiUrl}/forum/topics/post/${post_id}/like`;

  return function (dispatch) {
    api
      .PUT(url, null)
      .then((res) => {
        if (res.success) {
          dispatch({
            type: SEND_LIKE_SUCCESS,
            payload: { likes: likes + 1, post_id },
          });
        }
      })
      .catch((error) => {});
  };
};
