export const GET_ACTIVITY_ACTIVITY_SUCCEEDED =
  'GET_ACTIVITY_ACTIVITY_SUCCEEDED';
export const GET_ACTIVITY_ACTIVITY_FAILED = 'GET_ACTIVITY_ACTIVITY_FAILED';
export const GET_ACTIVITY_SECURITY_SUCCEEDED =
  'GET_ACTIVITY_SECURITY_SUCCEEDED';
export const GET_ACTIVITY_SECURITY_FAILED = 'GET_ACTIVITY_SECURITY_FAILED';
export const GET_ACTIVITY_MESSAGE_SUCCEEDED = 'GET_ACTIVITY_MESSAGE_SUCCEEDED';
export const GET_ACTIVITY_MESSAGE_FAILED = 'GET_ACTIVITY_MESSAGE_FAILED';
export const GET_ACTIVITY_GROUP_SUCCEEDED = 'GET_ACTIVITY_GROUP_SUCCEEDED';
export const GET_ACTIVITY_GROUP_FAILED = 'GET_ACTIVITY_GROUP_FAILED';
export const GET_ACTIVITY_INVITATION_SUCCEEDED =
  'GET_ACTIVITY_INVITATION_SUCCEEDED';
export const GET_ACTIVITY_INVITATION_FAILED = 'GET_ACTIVITY_INVITATION_FAILED';
