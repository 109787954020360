import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSchedulerSetting } from '../../redux/actions/scheduler_setting';

import usePrevious from '../../hooks/usePrevious';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '120px',
  },
}));

const SchedulerSetting = (props) => {
  const classes = useStyles();

  const { member_id, isCallingApi } = props;
  const prevCallingApi = usePrevious(isCallingApi);
  const [closedBtnClicked, setClosedBtnClicked] = useState(false);

  const [dateFormat, setDateFormat] = React.useState(
    props.schedulerSetting.date_format
  );
  const [timeFormat, setTimeFormat] = React.useState(
    props.schedulerSetting.time_format
  );
  const [startTime, setStartTime] = React.useState(
    props.schedulerSetting.start_time
  );

  const [timeInterval, setTimeInterval] = React.useState(
    props.schedulerSetting.time_interval
  );

  const [weekStart, setWeekStart] = React.useState(
    props.schedulerSetting.start_day
  );
  const [dragMethod, setDragMethod] = React.useState(
    props.schedulerSetting.drag_method
  );

  useEffect(() => {
    if (prevCallingApi === true && isCallingApi === false && closedBtnClicked) {
      props.setSettingModalShow(false);
    }
  }, [prevCallingApi, isCallingApi, closedBtnClicked, props]);

  const handleClickButtonOK = (event) => {
    setClosedBtnClicked(true);

    props.setSchedulerSetting({
      member_id,
      date_format: dateFormat,
      time_format: timeFormat,
      start_time: startTime,
      time_interval: timeInterval,
      start_day: weekStart,
      drag_method: dragMethod,
    });
  };

  const getTimeFromFormat = () => {
    let arr = [];
    for (let i = 0; i <= 23; i++) {
      arr.push(i);
    }
    return arr.map((item) => (
      <MenuItem value={item} key={item}>
        {timeFormat === 'AM/PM'
          ? item < 12
            ? item + ':00 AM'
            : item === 12
            ? item + ':00 PM'
            : item - 12 + ':00 PM'
          : item + ':00'}
      </MenuItem>
    ));
  };

  const getTimeIntervalList = () => {
    let arr = [1, 2, 3, 4, 6];

    return arr.map((item) => (
      <MenuItem value={item} key={item}>
        {item <= 1 ? item + ' Hr' : item + ' Hrs'}
      </MenuItem>
    ));
  };

  const getWeekDays = () => {
    let weekDays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    let arr = [];
    for (let i = 0; i <= 6; i++) {
      arr.push(i);
    }
    return arr.map((item) => (
      <MenuItem value={item} key={item}>
        {' '}
        {weekDays[item]}{' '}
      </MenuItem>
    ));
  };

  const timeFromFormatList = getTimeFromFormat();
  const weekDaysList = getWeekDays();
  const timeIntervalFromList = getTimeIntervalList();

  return (
    <div>
      <div className="div_ModalItem">
        <FormControl>
          <InputLabel id="label-dateformat">Date Format</InputLabel>
          <Select
            labelId="label-dateformat"
            id="dateformat-select"
            value={dateFormat}
            onChange={(event) => setDateFormat(event.target.value)}
            // style={{width:"300px"}}
          >
            <MenuItem value={'MM/DD/YYYY'}>MM/DD/YYYY</MenuItem>
            <MenuItem value={'MM/DD/YY'}>MM/DD/YY</MenuItem>
            <MenuItem value={'YYYY/MM/DD'}>YYYY/MM/DD</MenuItem>
            <MenuItem value={'YY/MM/DD'}>YY/MM/DD</MenuItem>
            <MenuItem value={'DD/MM/YYYY'}>DD/MM/YYYY</MenuItem>
            <MenuItem value={'DD/MM/YY'}>DD/MM/YY</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="div_ModalItem">
        <FormControl>
          <InputLabel id="label-timeformat">Time Format</InputLabel>
          <Select
            labelId="label-timeformat"
            id="timeformat-select"
            value={timeFormat}
            onChange={(event) => setTimeFormat(event.target.value)}
          >
            <MenuItem value={'AM/PM'}>AM/PM</MenuItem>
            <MenuItem value={'24Hr'}>24 Hr</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="div_ModalItem">
        <FormControl>
          <InputLabel id="label-starttime">Start Time</InputLabel>
          <Select
            labelId="label-starttime"
            id="starttime-select"
            value={startTime}
            onChange={(event) => setStartTime(event.target.value)}
          >
            {timeFromFormatList}
          </Select>
        </FormControl>
      </div>
      <div className="div_ModalItem">
        <FormControl>
          <InputLabel id="label-timeinterval">Time Interval</InputLabel>
          <Select
            labelId="label-timeinterval"
            id="timeinterval-select"
            value={timeInterval}
            onChange={(event) => setTimeInterval(event.target.value)}
          >
            {timeIntervalFromList}
          </Select>
        </FormControl>
      </div>
      <div className="div_ModalItem">
        <FormControl>
          <InputLabel id="label-weekstart">Start Day of the Week</InputLabel>
          <Select
            labelId="label-weekstart"
            id="weekstart-select"
            value={weekStart}
            onChange={(event) => setWeekStart(event.target.value)}
          >
            {weekDaysList}
          </Select>
        </FormControl>
      </div>
      <div className="div_ModalItem">
        <FormControl component="fieldset">
          <FormLabel component="legend">Drag & Drop Method</FormLabel>
          <RadioGroup
            aria-label="dragmethod"
            name="dragmethod"
            value={dragMethod}
            onChange={(event) => setDragMethod(event.target.value)}
          >
            <FormControlLabel
              value="Drag_Normal"
              control={<Radio />}
              label="Normal"
            />
            <FormControlLabel
              value="Drag_Group"
              control={<Radio />}
              label="Group"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.actionBtn}
          onClick={() => props.setSettingModalShow(false)}
          disabled={isCallingApi}
        >
          Cancel
        </Button>
        {isCallingApi ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            disabled
          >
            ...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={handleClickButtonOK}
            disabled={isCallingApi}
          >
            OK
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member_id: state.member.member.member_id,
  isCallingApi: state.schedulerSetting.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSchedulerSetting }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerSetting);
