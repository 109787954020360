import {
  CURRENCY_LIST_FETCH_START,
  CURRENCY_LIST_FETCH_SUCCESS,
  CURRENCY_LIST_FETCH_FAILURE,
  GET_WEEKLY_BILLING,
} from '../actionTypes/billing';

import {
  APP_RESET
} from '../actionTypes/app';

export const initialState = {
  isLoading: false,
  currencyList: [],
  weeklyBillingData: {
    summary: {},
    fixed: {},
  },
  error: null,
};

const billing = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case CURRENCY_LIST_FETCH_START:
      return { ...state, isLoading: true, error: null };
    case CURRENCY_LIST_FETCH_SUCCESS:
      return {
        ...state,
        currencyList: action.payload,
        isLoading: false,
        error: null,
      };
    case CURRENCY_LIST_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_WEEKLY_BILLING:
      return {
        ...state,
        weeklyBillingData: action.payload,
      };
    default:
      return state;
  }
};

export default billing;
