import React from 'react';
import PropTypes from 'prop-types';

const avatarColors = [
  '#49F2CA',
  '#E36CDC',
  '#00B3FF',
  '#FF977C',
  '#87F8A9',
  '#00DCF9',
];

const Avatar = (props) => {
  const { src, alt, className, uuid, name } = props;
  const c = name.charAt(0);
  let ruuid = uuid?uuid: Math.floor(Math.random()*100);
  return src && src !== '' ? (
    <img className={className} src={src} alt={alt} />
  ) : (
    <div
      className={className}
      style={{ backgroundColor: avatarColors[(ruuid%avatarColors.length)] }}
    >
      {c}
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  className: PropTypes.string,
  uuid: PropTypes.number,
  name: PropTypes.string,
};

export default Avatar;
