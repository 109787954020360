import React from 'react';

const MailComposeIcon = () => (
  <svg
    id="Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="37.938"
    height="20.734"
    viewBox="0 0 42.938 20.734"
  >
    <path
      id="XMLID_202_"
      d="M50.48 20.91a3.14 3.14 0 00-2.33-1H22.81a3 3 0 00-3 2.38L17 37.77a2.73 2.73 0 00.68 2.32 3.14 3.14 0 002.33 1h25.33a3 3 0 003-2.38l2.8-15.49a2.73 2.73 0 00-.66-2.31zm-3.74 6.41l-11.08 4.29a4 4 0 01-1.5.28 4 4 0 01-1.72-.38l-10.07-4.63.45-2.51 10.77 5a1.49 1.49 0 001.08 0l12.58-4.88zm3.86-1.79l.14-.82a1.07 1.07 0 01-.14.82zm-35.28-1.09h-4.89a1.52 1.52 0 110-3h4.89a1.52 1.52 0 110 3zm-1.63 6.06h-3.26a1.52 1.52 0 110-3h3.26a1.52 1.52 0 110 3zm-1.63 7.57h-1.63a1.52 1.52 0 110-3h1.63a1.52 1.52 0 110 3z"
      transform="translate(-10 -20)"
    ></path>
  </svg>
);

export default MailComposeIcon;
