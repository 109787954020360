import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';

import VideoCard from '../../../components/MediaStreaming/Scheduler/videoCard';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#8EA4C6',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 32,
    '& span': {
      fontSize: 23,
    },
    '& select, svg': {
      color: '#8EA4C6',
    },
  },
  right: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  setting: {
    marginTop: 4,
    marginLeft: 8,
    cursor: 'pointer',
    color: '#8EA4C6',
    '&:hover': {
      color: '#868282',
    },
  },
}));

const mockdata = [];

for (let i = 0; i <= 10; i++) {
  mockdata.push({
    video_url: '',
    title: 'Coming Soon',
    update_date: '2021-04-10T13:52:50.298294+00:00',
  });
}

const VideoList = (props) => {
  const { streamTypes } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.header}>
          <span>Clips bin</span>
          <div className={classes.right}>
            <FormControl variant="outlined" margin="dense">
              <Select
                native
                inputProps={{
                  name: 'video-type',
                  id: 'video-type',
                }}
              >
                {streamTypes
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((type) => {
                    return (
                      <option
                        aria-label={type.name}
                        value={type.id}
                        key={type.id}
                      >
                        {type.name}
                      </option>
                    );
                  })}
              </Select>
            </FormControl>
            <Tooltip title="Settings" arrow>
              <SettingsIcon onClick={() => {}} className={classes.setting} />
            </Tooltip>
          </div>
        </div>
      </Grid>
      {mockdata.map((data, index) => {
        return (
          <Grid item xs={12} sm={6} md={3} xl={2} key={index}>
            <VideoCard />
          </Grid>
        );
      })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  streamTypes: state.stream.streamTypes,
});

export default connect(mapStateToProps)(VideoList);
