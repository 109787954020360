import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../SearchComponent';
import { closeDrawer, loadPasswords } from '../../redux/actions/drawer';
import Filter from './PasswordAndLoginFilter';
import PasswordItem from './PasswordItem';
import {
  createPassword,
  updatePassword,
  deletePassword,
} from '../../redux/actions/drawer';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000000',
  },
  passwords: {
    padding: '28px 22px 22px 22px',
  },
  wrapper: {
    border: '2px solid #D4E2F0',
    padding: '17px 0px 15px 0px',
    borderRadius: '8px',
  },
  innerFilter: {
    fontSize: '23px',
    fontWeight: 600,
  },
  innerSearch: {
    width: '210px',
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 13px 0px 19px',
  },
  list: {
    marginTop: '8px',
    minHeight: '500px',
    maxHeight: 'calc(100vh - 314px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '4px',
      borderRadius: '8px',
      backgroundColor: '#d3d3d380',
    },
  },
  listItem: {
    padding: '14px 19px 14px 19px',
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    '& .MuiButton-root': {
      width: '124px',
      height: '40px',
      border: '1px solid #8DA3C5',
      borderRadius: '7px',
      color: '#8DA3C5',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  addNew: {
    '& .MuiButton-root': {
      height: '40px',
      borderRadius: '7px',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '23px',
  },
}));

const PasswordAndLoginManager = (props) => {
  const classes = useStyles();

  const { passwords } = useSelector((state) => ({
    passwords: state.drawer.contentProps?.passwords || [],
  }));

  const [searchKey, setSearchKey] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState();
  const [showNew, setShowNew] = React.useState(false);

  const dispatch = useDispatch();

  const newRef = React.useRef();

  React.useEffect(() => {
    dispatch(loadPasswords(''));
  }, [dispatch]);

  const debouncedLoadPasswords = useCallback(
    debounce((nextValue) => dispatch(loadPasswords(nextValue)), 500),
    []
  );

  React.useEffect(() => {
    if (newRef.current) scrollToRef(newRef);
  }, [newRef]);

  const scrollToRef = (ref) => {
    newRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  const title = 'Password and Login Manager';

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    debouncedLoadPasswords(event.target.value);
  };

  const handleSave = (password) => {
    const formData = new FormData();
    formData.set('name', password.name);
    formData.set('website', password.website);
    formData.set('type', password.type);
    formData.set('username', password.username);
    formData.set('password', password.password);

    if (password.id !== '') {
      dispatch(updatePassword(password.id, formData));
    } else dispatch(createPassword(formData));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
      </div>
      <div className={classes.passwords}>
        <div className={classes.wrapper}>
          <div className={classes.listHeader}>
            <div className={classes.innerSearch}>
              <Search searchKey={searchKey} handleSearch={handleSearch} />
            </div>
            <div className={classes.innerFilter}>
              <Filter
                selectedOption={selectedOption}
                handleOption={(option) => {
                  setSelectedOption(option);
                }}
              />
            </div>
          </div>
          <div className={classes.list}>
            {passwords &&
              passwords.length > 0 &&
              passwords.map((password) => (
                <div className={classes.listItem} key={password.id}>
                  <PasswordItem
                    password={password}
                    handleSave={handleSave}
                    handleDelete={() => {
                      dispatch(deletePassword(password.id));
                    }}
                  />
                </div>
              ))}
            {showNew && (
              <div className={classes.listItem} ref={newRef}>
                <PasswordItem
                  password={{}}
                  newPassword
                  handleDelete={() => {
                    setShowNew(false);
                  }}
                  handleSave={handleSave}
                />
              </div>
            )}
          </div>
        </div>
        <div className={classes.btnWrapper}>
          <div
            className={classes.cancelBtn}
            onClick={() => {
              dispatch(closeDrawer());
            }}
          >
            <Button variant="outlined">Cancel</Button>
          </div>
          <div className={classes.addNew} onClick={() => {}}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowNew(true);
              }}
            >
              Add New Credential
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordAndLoginManager;
