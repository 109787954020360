import React from 'react';

const AttachmentIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.379" height="27.611" viewBox="0 0 18.379 27.611">
      <path id="XMLID_97_" d="M18.379,1.029V18.064a9.384,9.384,0,0,1-9.189,9.546A9.384,9.384,0,0,1,0,18.064V1.029A1.01,1.01,0,0,1,.99,0a1.01,1.01,0,0,1,.99,1.029V18.064a7.362,7.362,0,0,0,7.209,7.489A7.361,7.361,0,0,0,16.4,18.064V2.057H7.1V17.366a2.086,2.086,0,1,0,4.169,0V6.687a.991.991,0,1,1,1.98,0V17.366a4.151,4.151,0,0,1-4.065,4.223,4.151,4.151,0,0,1-4.065-4.223V1.029A1.01,1.01,0,0,1,6.114,0H17.388A1.01,1.01,0,0,1,18.379,1.029Z" transform="translate(0 0)" fill="#fff" />
    </svg>
  );
};

export default AttachmentIcon;