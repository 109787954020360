import React, { useState } from 'react';
import { ProjectsApi } from '../../../../redux/actions/project';
import { useDispatch } from 'react-redux';
import AddActions from '../Modals/AddActions';
import AddModal from '../Modals/AddModal';
import AddTaskModal from '../Modals/AddTaskModal';
import AddMileStoneModal from '../Modals/AddMileStoneModal';

const AddProjectElements = ({
  projectId,
  element_id,
  element_type,
  rate_type,
  updateExpanded,
}) => {
  const dispatch = useDispatch();
  const addMod = {
    project_id: projectId,
    heading: '',
    parent_id: null,
  };
  const [addModalData, setAddModalData] = useState(addMod);
  const [modelOpenAddElement, setModalOpenAddElement] = useState(false);
  const [modelOpenTask, setModalOpenTask] = useState(false);
  const [modelOpenMileStone, setModalMileStone] = useState(false);

  //set the element_id of current element as parent_id of element being created
  const handleOpenAddElement = (heading, set_parent_id) => {
    setAddModalData({
      ...addModalData,
      heading: heading,
      parent_id: set_parent_id,
    });
    setModalOpenAddElement(true);
  };

  const handleOpenTask = (set_parent_id) => {
    setAddModalData({
      ...addModalData,
      heading: 'task',
      parent_id: set_parent_id,
    });
    setModalOpenTask(true);
  };

  const handleOpenMileStone = (set_parent_id) => {
    setAddModalData({
      ...addModalData,
      heading: 'milestone',
      parent_id: set_parent_id,
    });
    setModalMileStone(true);
  };

  const handleCloseAddElement = () => {
    setModalOpenAddElement(false);
  };

  const handleCloseTask = () => {
    setModalOpenTask(false);
  };

  const handleCloseMileStone = () => {
    setModalMileStone(false);
  };

  const handleSubmit = (form) => {
    console.log('AddProjectElement.js > Post to create project element', form);
    dispatch(ProjectsApi.postProjectElement(form));
    form.parent_id !== 'null' && updateExpanded(form.parent_id);
    setModalOpenAddElement(false);
    setModalOpenTask(false);
    setModalMileStone(false);
  };

  const handleSubmitMileStone = (form) => {
    dispatch(ProjectsApi.postProjectMilestone(form));
    setModalMileStone(false);
  };

  return (
    <>
      <div>
        <AddActions
          element_type={element_type}
          element_id={element_id}
          rate_type={rate_type}
          handleTask={handleOpenTask}
          handleMileStone={handleOpenMileStone}
          handleClick={handleOpenAddElement}
        />

        <div>
          <AddModal
            heading={addModalData.heading}
            project_id={projectId}
            parent_id={addModalData.parent_id}
            handleSubmit={handleSubmit}
            open={modelOpenAddElement}
            handleClose={handleCloseAddElement}
          />
        </div>
        <div>
          <AddTaskModal
            heading="Task"
            project_id={projectId}
            parent_id={addModalData.parent_id}
            handleSubmit={handleSubmit}
            open={modelOpenTask}
            handleClose={handleCloseTask}
          />
        </div>
        <div>
          <AddMileStoneModal
            heading="MileStone"
            project_id={projectId}
            parent_id={addModalData.parent_id}
            handleSubmit={handleSubmitMileStone}
            open={modelOpenMileStone}
            handleClose={handleCloseMileStone}
          />
        </div>
      </div>
    </>
  );
};

export default AddProjectElements;
