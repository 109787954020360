import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import ReplyIcon from '@material-ui/icons/Reply';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  },

  cancel: {
    background: '#fff',
    border: '1px solid #ee346b',
    color: '#ee346b',
    boxShadow: 'none',
    '&:hover': {
      color: '#fff',
      background: '#ee346b',
    },
  },

  ok: {
    background: '#fff',
    border: '1px solid #94c03d',
    color: '#94c03d',
    boxShadow: 'none',
    '&:hover': {
      color: '#fff',
      background: '#94c03d',
    },
  },
}));

const Buttons = (props) => {
  const classes = useStyles();
  const {
    cancelText,
    okText,
    cancelHandle,
    okHandle,
    type,
    okDisabled = false,
    cancelDisabled = false,
  } = props;

  return (
    <div className={classes.buttons}>
      {type === 'text_mail' || type === 'media_mail' ? (
        <div></div>
      ) : (
        <Button
          disabled={cancelDisabled}
          variant="contained"
          aria-label="Cancel"
          className={classes.cancel}
          startIcon={<CancelIcon />}
          onClick={cancelHandle}
        >
          {cancelText}
        </Button>
      )}
      <Button
        disabled={okDisabled}
        variant="contained"
        aria-label="Ok"
        className={classes.ok}
        startIcon={
          type === 'text_mail' || type === 'media_mail' ? (
            <ReplyIcon />
          ) : (
            <CheckBoxIcon />
          )
        }
        onClick={okHandle}
      >
        {okText}
      </Button>
    </div>
  );
};

export default Buttons;
