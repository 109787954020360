import React from 'react';

import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { useMailTableStyles } from './MailTableStyles';
import SVG from '../../../../../components/Icons/SVG';
import { getMembersNamesFromIds } from '../../../../../utils/email';
import classNames from 'classnames';
import { defaultDateTimeZone } from '../../../../../utils/calendar';

const MailPreview = ({
  email,
  section,
  selectEmail,
  selectedEmail,
  selectedEmails,
  isOwnEmail,
  selectedXref,
}) => {
  const classes = useMailTableStyles();
  const selected = selectedEmail ? selectedEmail.mail_id : selectedEmail;
  const receivers = email.receivers || {
    amera: [],
  };

  const iconsBySection = (section) => {
    switch (section) {
      case 'Starred':
        return (
          <>
            <div aria-label="star-icon" className={classes.greenButton}>
              <SVG name="mailStarred" />
            </div>
          </>
        );
      case 'Trash':
        return (
          <>
            <div aria-label="trash-icon" className={classes.greenButton}>
              <SVG name="mailTrash" />
            </div>
          </>
        );
      case 'Archive':
        return (
          <>
            <div />
            <div aria-label="archive-icon" className={classes.greenButton}>
              <SVG name="mailArchive" />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <TableRow
      hover
      selected={
        selected &&
        selected === email.mail_id &&
        (selectedXref ? selectedXref.indexOf(email.xref_id) !== -1 : true)
      }
      className={classNames({
        [classes.tableRow]: true,
        [classes.tableRowActive]: !email.read,
        [classes.tableRowSelected]:
          selectedEmails.includes(email.mail_id) ||
          (selected && selected === email.mail_id),
      })}
      key={email.mail_id}
      onClick={(e) => {
        selectEmail(
          email.mail_id,
          email.xref_id,
          true,
          String(e.target.tagName).toUpperCase() !== 'INPUT'
        );
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedEmails.some(
            (el) =>
              el === email.mail_id &&
              (selectedXref ? selectedXref.indexOf(email.xref_id) !== -1 : true)
          )}
          onClick={() => selectEmail(email.mail_id, email.xref_id, true)}
        />
      </TableCell>

      <TableCell padding="none" className={classes.cellAvatar}>
        <Grid container className={classes.cellAvatar}>
          <Grid item xs={12}>
            <span className={classes.sender}>
              {isOwnEmail
                ? getMembersNamesFromIds(receivers.amera, email.member_details)
                : `${email.first_name} ${email.last_name}`}
            </span>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell className={classes.cellSubject}>
        <span className={classes.subject}>{email.subject}</span>
      </TableCell>

      <TableCell component="th" scope="row" className={classes.cellPreview}>
        <span className={classes.mailPreview}>{email.noStyleBody}</span>
      </TableCell>

      <TableCell padding="none" className={classes.cellDate}>
        <span className={classes.previewTime}>
          {(email.time && defaultDateTimeZone(email.time)) || ''}
        </span>
        {iconsBySection(section)}
      </TableCell>
    </TableRow>
  );
};

export default MailPreview;
