import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import MaterialAvatar from '../MaterialAvatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AttachmentIcon, DownloadIcon } from '../../assets/svg';
import TopicPostEditor from './TopicPostEditor';
import { format } from 'date-fns';
import { extractFileName, fileSize } from '../../utils/file';
import { like } from '../../redux/actions/forum';
import * as api from '../../config/api';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
  },
  postWrapper: {
    display: 'flex',
  },
  post: {
    flex: 1,
  },
  name: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#0A1629',
    marginRight: theme.spacing(2),
  },
  date: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#7D8592',
    marginRight: theme.spacing(6),
  },
  likes: {
    fontSize: '12px',
    fontWeight: 800,
    color: '#8DA3C5',
    cursor: 'pointer',
  },
  like: {
    marginRight: theme.spacing(2),
  },
  reply: {},
  dot: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  content: {
    marginTop: '12px',
    marginBottom: theme.spacing(3),
  },
  iconWrapper: {
    width: '44px',
    height: '44px',
    borderRadius: '14px',
    backgroundColor: '#F4F9FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '13px',
    cursor: 'pointer',
  },
  attachment: {
    display: 'flex',
    background: 'white',
    padding: '13px',
    width: 'fit-content',
    borderRadius: '14px',
  },
  attachmentIconWrapper: {
    width: '44px',
    height: '44px',
    borderRadius: '14px',
    backgroundColor: '#f1effb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fill: '#6D5DD3 !important',
    },
  },
  fileInfo: {
    marginLeft: '16px',
    minWidth: '312px',
  },
  fileName: {
    fontSize: '14px',
    fontWeight: 700,
  },
  fileSize: {
    color: '#91929E',
    fontSize: '12px',
    fontWeight: 400,
  },
}));

const TopicPostsRenderer = (props) => {
  const { posts, topic_id, selectedMembers, sortDir } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [replyPostId, setReplyPostId] = React.useState();

  const handleReply = (postId) => {
    setReplyPostId(postId);
  };

  const handleLike = (postId, likes) => {
    dispatch(like(postId, likes));
  };

  const renderer = (data) => {
    data = sortDir === 'ascending' ? data.reverse() : data;
    return (
      <div className={classes.root}>
        {data &&
          data.length > 0 &&
          data
            .filter((post) => {
              if (post.parent_post_id === null) {
                if (
                  selectedMembers.length > 0 &&
                  selectedMembers.includes(post.member_id) === false
                )
                  return false;
                return true;
              } else {
                return true;
              }
            })
            .map((post) => (
              <div key={post.post_id} className={classes.postWrapper}>
                <MaterialAvatar
                  src={post.amera_avatar_url}
                  member_id={post.member_id}
                  firstName={post.first_name}
                  lastName={post.last_name}
                  classes={classes.avatar}
                  variant="circular"
                />
                <div className={classes.post}>
                  <div className={classes.header}>
                    <span className={classes.name}>
                      {post.first_name} {post.last_name}
                    </span>
                    <span className={classes.date}>
                      {format(new Date(post.create_date), 'Pp')}
                    </span>
                    <span className={classes.likes}>
                      <span
                        className={classes.like}
                        onClick={() => {
                          handleLike(post.post_id, post.likes);
                        }}
                      >
                        Like
                      </span>
                      <span
                        className={classes.reply}
                        onClick={() => {
                          handleReply(post.post_id);
                        }}
                      >
                        Reply
                      </span>
                      <span className={classes.dot}>.</span>
                      <span>
                        {post.likes} {post.likes > 1 ? 'Likes' : 'Like'}
                      </span>
                    </span>
                  </div>
                  <div
                    className={classes.content}
                    dangerouslySetInnerHTML={{ __html: post.post_content }}
                  />
                  <div className={classes.attachmentsWrapper}>
                    {post.attachments &&
                      post.attachments.length > 0 &&
                      post.attachments.map((attachment) => (
                        <div
                          key={attachment.forum_media_id}
                          className={classes.attachment}
                        >
                          <div className={classes.attachmentIconWrapper}>
                            <AttachmentIcon />
                          </div>
                          <div className={classes.fileInfo}>
                            <h5 className={classes.fileName}>
                              {extractFileName(attachment.file_path)}
                            </h5>
                            <h5 className={classes.fileSize}>
                              {fileSize(attachment.file_size)}
                            </h5>
                          </div>
                          <div className={classes.iconWrapper}>
                            <a
                              href={`${api.apiUrl}${attachment.file_path}`}
                              download
                            >
                              <DownloadIcon />
                            </a>
                          </div>
                          <div className={classes.iconWrapper}>
                            <MoreVertIcon />
                          </div>
                        </div>
                      ))}
                  </div>
                  {post.post_id === replyPostId && (
                    <TopicPostEditor
                      type="reply"
                      topic_id={topic_id}
                      post_id={post.post_id}
                      handleReply={handleReply}
                    />
                  )}
                  {renderer(post.posts)}
                </div>
              </div>
            ))}
      </div>
    );
  };

  return renderer(posts);
};

export default TopicPostsRenderer;
