import { Typography, Button } from '@material-ui/core';
import React from 'react';
import { gray } from '../../../../../jss/colors';
import { FolderItem } from './FolderItem';
import { useHistory, useLocation } from 'react-router-dom';
import { useEmailFolderStyles } from './FolderItemStyles';
import clsx from 'clsx';

export const Folders = ({
  folders = [],
  handleEditFolder,
  handleRemoveFolder,
  selectedFolder,
  setSelectedFolder,
}) => {
  const classes = useEmailFolderStyles();
  const location = useLocation();
  const history = useHistory();
  const goInbox = () => history.push('inbox');
  const goSent = () => history.push('sent');

  return folders.length !== 0 ? (
    <>
      <div className={classes.tabContainer}>
        <Button
          className={clsx(classes.tabButton, {
            [classes.tabButtonActive]:
              location.pathname === '/share/mail/inbox',
          })}
          onClick={goInbox}
        >
          Inbox
        </Button>

        <Button
          className={clsx(classes.tabButton, {
            [classes.tabButtonActive]: location.pathname === '/share/mail/sent',
          })}
          onClick={goSent}
        >
          Sent
        </Button>
      </div>

      <FolderItem
        item={{ id: -1, name: 'All Folders' }}
        handleEditFolder={handleEditFolder}
        handleRemoveFolder={handleRemoveFolder}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
      />
      {folders.map((folder, index) => {
        return (
          <FolderItem
            key={folder.id}
            item={folder}
            handleEditFolder={handleEditFolder}
            handleRemoveFolder={handleRemoveFolder}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
          />
        );
      })}
    </>
  ) : (
    <Typography
      style={{
        color: gray,
        padding: '50px 10px',
        wordBreak: 'break-word',
        textAlign: 'center',
      }}
    >
      You have not created a folder
    </Typography>
  );
};
