import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PasswordStrengthBar from 'react-password-strength-bar';
import PINInput from './PINInput';
// import MaterialAvatar from '../../../components/MaterialAvatar';
const MaterialAvatar = React.lazy(() =>
  import('../../../components/MaterialAvatar')
);

const PictureAndPINInput = (props) => {
  const {
    picture,
    handleAvatarChange,
    PIN,
    pinErrors,
    picErrors,
    onPINChange,
  } = props;

  return (
    <Grid container direction="column" justify="center">
      <Grid item xs={12}>
        <Typography>Profile Picture *</Typography>
        <div className="profile-avatar">
          <label htmlFor="change-avatar-profile-edit">
            <input
              style={{ display: 'none' }}
              id="change-avatar-profile-edit"
              name="change-avatar-mu"
              type="file"
              accept="image/*"
              onChange={handleAvatarChange}
            />
            <React.Suspense fallback={<div></div>}>
              <MaterialAvatar
                classes="profile my"
                src={picture}
                badgeType="online"
              />
            </React.Suspense>
          </label>
        </div>
        <Button
          variant="contained"
          component="label"
          size="small"
          color="primary"
          className="upload-profile-picture-btn"
          startIcon={<CloudUploadIcon />}
          name="upload-image-button"
        >
          Upload
          <input
            type="file"
            accept="image/*"
            id="image-input"
            name="profilePic"
            onChange={handleAvatarChange}
          />
        </Button>
        {picErrors && <FormHelperText error>{picErrors}</FormHelperText>}
      </Grid>
      <Grid item xs={12}>
        <PINInput
          onPINChange={onPINChange}
          PIN={PIN}
          errors={pinErrors}
          isConfirm={false}
        />
        <PasswordStrengthBar
          password={PIN}
          scoreWords={['', '', '', '', '']}
          shortScoreWord=""
        />
      </Grid>
    </Grid>
  );
};

export default PictureAndPINInput;
