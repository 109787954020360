import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';

function ConversationSkeleton({ num }) {
  return (
    <ListItem key={num} className="conversation-skeleton">
      <div className="conversation-avatar-div">
        <Skeleton
          variant="circle"
          animation="wave"
          className="conversation-avatar"
        >
          <Avatar />
        </Skeleton>
      </div>
      <div className="conversation-details-div">
        <Skeleton
          variant="rect"
          animation="wave"
          className="conversation-name"
        />
        <Skeleton
          variant="rect"
          animation="wave"
          className="conversation-last-message"
        />
      </div>
    </ListItem>
  );
}

export default ConversationSkeleton;
