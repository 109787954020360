import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';

const AttachButton = ({ onChange }) => {
  return (
    <label htmlFor="upload-photo">
      <input
        style={{ display: 'none' }}
        id="upload-photo"
        name="upload-photo"
        type="file"
        // multiple="multiple"
        onChange={onChange}
      />
      <Tooltip title="Attach files" placement="top">
        <IconButton component="span" aria-label="format">
          <AttachFileIcon style={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </label>
  );
};

export default AttachButton;
