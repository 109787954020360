import React, { Component } from 'react';
import Modal from '../Modal/modal';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { vacall, VI } from './global';
let $ = window.$;
type Props = {
  isDialogOpen: Boolean,
  selectedAudio: Boolean,
  selectedSpeaker: Boolean,
  selectedVideo: Boolean,
};

const styles = (theme) => ({
  cancelBtn: {
    margin: theme.spacing(1),
  },
  closeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class settingDialog extends Component<Props> {
  constructor() {
    super();
    this.state = {
      selectedVideo: null,
      selectedAudio: null,
      selectedSpeaker: null,
    };
  }
  componentDidUpdate() {
    let { opendialog } = this.props;
    if (opendialog) {
      // navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError);
    }
  }

  // openDialog = () => this.setState({ isDialogOpen: true })

  // handleClose = () => this.setState({ isDialogOpen: false })
  onVideSource = (selected) => {
    console.log(selected.target.value);
    this.setState({ selectedVideo: selected.target.value });
  };
  onAudioSource = (selected) => {
    console.log(selected.target.value);
    this.setState({ selectedAudio: selected.target.value });
  };
  onSpeakerSource = (selected) => {
    console.log(selected.target.value);
    this.setState({ selectedSpeaker: selected.target.value });
  };
  onSave = () => {
    console.log(this.state);
    VI.switchVideoCam(
      this.state.selectedVideo,
      this.state.selectedAudio,
      this.state.selectedSpeaker
    );
    this.props.handleClose();
  };
  gotStream = (stream) => {
    window.stream = stream; // make stream available to console
    //alert(stream);
    const videoElementAg = document.querySelector('#videoElement');

    videoElementAg.srcObject = stream;
    // Refresh button list in case labels have become available
    return navigator.mediaDevices.enumerateDevices();
  };
  handleError(error) {
    console.log(
      'navigator.MediaDevices.getUserMedia error: ',
      error.message,
      error.name
    );
  }
  gotDevices = (deviceInfos) => {
    const audioInputSelect = document.querySelector('select#audioSource');
    const audioOutputSelect = document.querySelector('select#audioOutputs');
    const videoSelect = document.querySelector('select#videoSource');
    const selectors = [audioInputSelect, audioOutputSelect, videoSelect];
    // Handles being called several times to update labels. Preserve values.
    let { selectedAudio, selectedSpeaker, selectedVideo } = this.props;
    const values = selectors.map((select) => select.value);
    selectors.forEach((select) => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      option.id = deviceInfo.deviceId;

      if (deviceInfo.kind === 'audioinput') {
        option.text =
          deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
        if (~option.id.indexOf(selectedAudio)) {
          console.log(option.id);
          option.selected = true;
        }
        audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text =
          deviceInfo.label || `speaker ${audioOutputSelect.length + 1}`;
        if (~option.id.indexOf(selectedSpeaker)) {
          console.log(option.id);
          option.selected = true;
        }
        audioOutputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${videoSelect.length + 1}`;
        if (~option.id.indexOf(selectedVideo)) {
          console.log(option.id);
          option.selected = true;
        }
        videoSelect.appendChild(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    selectors.forEach((select, selectorIndex) => {
      if (
        Array.prototype.slice
          .call(select.childNodes)
          .some((n) => n.value === values[selectorIndex])
      ) {
        select.value = values[selectorIndex];
      }
    });
    if (vacall.room.getLocalTracks()[1].getType() === 'video') {
      vacall.room.getLocalTracks()[1].detach($(`#videoElement`)[0]);
      vacall.room.getLocalTracks()[1].attach($(`#videoElement`)[0]);
      document.getElementById(
        vacall.room.getLocalTracks()[1].deviceId
      ).selected = true;
      // this.setState({selectedVideo:vacall.room.getLocalTracks()[1].deviceId});
    }
    if (vacall.room.getLocalTracks()[0].getType() === 'audio') {
      // this.setState({selectedAudio:vacall.room.getLocalTracks()[0].deviceId});
    }
  };

  ModalContent = () => {
    return (
      <div className="modal-body">
        <div className="row">
          <div className="col-sm-6">
            <div className="video-container">
              <video id="videoElement" autoPlay={true}></video>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="videoSource">Camera: </label>
              <select
                onChange={this.onVideSource}
                className="form-control"
                id="videoSource"
              >
                <option value="">Select</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="audioSource">Mirophone: </label>
              <select
                onChange={this.onAudioSource}
                className="form-control"
                id="audioSource"
              ></select>
            </div>
            <div className="form-group">
              <label htmlFor="audioOutput">Audio output: </label>
              <select
                onChange={this.onSpeakerSource}
                className="form-control"
                id="audioOutputs"
              ></select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  ModalAction = () => {
    const { classes } = this.props;
    return (
      <div className={classes.closeWrapper}>
        <Button
          variant="contained"
          color="secondary"
          disableRipple
          className={classes.cancelBtn}
          onClick={this.props.handleClose}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableRipple
          className={classes.cancelBtn}
          onClick={this.onSave}
        >
          Save changes
        </Button>
      </div>
    );
  };

  render() {
    navigator.mediaDevices
      .enumerateDevices()
      .then(this.gotDevices)
      .catch(this.handleError);
    return (
      <Modal
        title="Settings"
        open={this.props.isDialogOpen}
        onClose={this.props.handleClose}
        ModalContent={this.ModalContent}
        ModalAction={this.ModalAction}
      />
    );
  }
}
export default withStyles(styles, { withTheme: true })(settingDialog);
