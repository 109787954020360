import React, { useState } from 'react';
import EditModal from '../Modals/EditModal';
import { ProjectsApi } from '../../../../redux/actions/project';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

const EditProjectElements = ({
  projectId,
  parent_id,
  element_id,
  element_title,
  element_description,
  pay_type,
  element_type,
}) => {
  const dispatch = useDispatch();

  const [modalOpenEditElement, setModalOpenEditElement] = useState(false);

  const handleOpenEditElement = () => {
    setModalOpenEditElement(true);
  };

  const handleCloseEditElement = () => {
    setModalOpenEditElement(false);
  };

  const handleSubmitEdit = (form, element_id) => {
    dispatch(ProjectsApi.updateProjectElement(form, element_id));
    setModalOpenEditElement(false);
  };

  return (
    <>
      <div>
        <Button
          color="default"
          variant="outlined"
          size="small"
          onClick={handleOpenEditElement}
        >
          Edit
        </Button>
        <div>
          <EditModal
            projectId={projectId}
            parent_id={parent_id}
            element_id={element_id}
            name={element_title}
            text={element_description}
            pay_type={pay_type}
            open={modalOpenEditElement}
            element_type={element_type}
            handleClose={handleCloseEditElement}
            handleSubmitEdit={handleSubmitEdit}
          />
        </div>
      </div>
    </>
  );
};

export default EditProjectElements;
