import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  cancel: {
    background: '#fff',
    border: '1px solid #ee346b',
    color: '#ee346b',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      background: '#ee346b',
    },
  },
  ok: {
    background: '#fff',
    border: '1px solid #94c03d',
    color: '#94c03d',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      background: '#94c03d',
    },
  },
  okFilled: {
    color: '#fff',
    background: '#94c03d',
    border: '1px solid #94c03d',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      color: '#94c03d',
      background: '#fff',
    },
  },
  middle: {
    background: '#fff',
    border: '1px solid #FCB017',
    color: '#FCB017',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      background: '#FCB017',
    },
  },
  middleFilled: {
    background: '#FCB017',
    border: '1px solid #FCB017',
    color: '#fff',
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      color: '#FCB017',
      background: '#fff',
    },
  },
  info: {
    background: '#fff',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    boxShadow: 'none',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      background: theme.palette.secondary.main,
    },
  },
}));

const CardButton = (props) => {
  const { startIcon, variant, onClick, text } = props;
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes[variant]}
      startIcon={startIcon}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CardButton;
