import React, { useState, useReducer } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MaterialAvatar from '../MaterialAvatar';
import drawerProfileStyles from '../../jss/views/drawerProfileStyles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import JobTitleInput from '../../pages/Registration/InputElements/JobTitleInput';
import DepartmentList from '../../pages/Registration/InputElements/DepartmentList';
import CompanyNameInput from '../../pages/Registration/InputElements/CompanyNameInput';
import CountryInput from '../../pages/Registration/InputElements/CountryInput';
import RateInput from '../RateInput';
import AmeraAccordionControllable from '../AmeraAccordion/AmeraAccordionControllable';
import ImageCrop from '../../components/Modal/ImageCrop';
import EducationItem from './MyProfileEdit/EducationItem';
import WorkExperienceItem from './MyProfileEdit/WorkExperienceItem';
import CertificateItem from './MyProfileEdit/CertificateItem';
import AchievementItem from './MyProfileEdit/AchievementItem';
import SkillAutocomplete from '../SkillAtuocomplete';
import ConfirmDialog from '../ConfirmDialog';
import CardButton from '../Dashboard/CardButton';
import {
  PROFILE_EDIT_ACTIONS,
  DRAWER_CONTENT_TYPE,
  workReducer,
  educationReducer,
  certificatesReducer,
  achievementsReducer,
  phoneReducer,
  emailReducer,
} from '../../utils/drawer';
import { openDrawer } from '../../redux/actions/drawer';
import {
  putMemberInfo,
  putAvatar,
  setReduxMemberAlert,
} from '../../redux/actions/member';
import { DEVICE_TYPES } from '../../utils/contact';
import { parseISO, isValid } from 'date-fns';
import { set, orderBy, includes, max, isNumber, keys } from 'lodash';
import PhoneItem from './MyProfileEdit/PhoneItem';
import EmailItem from './MyProfileEdit/EmailItem';
const FileType = require('file-type/browser');

const TIMEOUT = 3000;

const MyProfileEdit = ({ memberInfo, dispatch, countryList, memberAlert }) => {
  const classes = drawerProfileStyles();

  const [isShowCrop, setShowCrop] = useState(false);
  const [src, setSrc] = useState(memberInfo.amera_avatar_url);
  const [originalFile, setOriginalFile] = useState(null);
  const [confirm, setConfirm] = useState({
    open: false,
    title: 'Cancel changes',
    cancelText: 'Go back',
    okText: 'Cancel',
    content: 'Are you sure you want to quit and cancel all changes?',
    onOk: () => {
      dispatch(openDrawer(DRAWER_CONTENT_TYPE.myProfileInfo));
    },
    onCancel: () => setConfirm((ps) => ({ ...ps, isOpen: false })),
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const memberInfoCountryCodeId =
    memberInfo.country_code &&
    memberInfo.country_code.length &&
    memberInfo.country_code[0]
      ? memberInfo.country_code[0].id
      : 0;

  const [isContactExpanded, setContactExpanded] = useState(false);
  const [isSkillsExpanded, setSkillsExpanded] = useState(false);
  const [isBioExpanded, setBioExpanded] = useState(false);
  const [isWorkExpanded, setWorkExpanded] = useState(false);
  const [isEducationExpanded, setEducationExpanded] = useState(false);
  const [isCertExpanded, setCertExpanded] = useState(false);
  const [isAchieveExpanded, setAchieveExpanded] = useState(false);

  const [firstName, setFirstName] = useState(memberInfo.first_name);
  const [middleName, setMiddletName] = useState(memberInfo.middle_name);
  const [lastName, setLastName] = useState(memberInfo.last_name);
  const [company, setCompany] = useState(
    !!memberInfo?.company_membership
      ? {
          id: memberInfo.company_membership.company_id,
          name: memberInfo.company_membership.company_name,
        }
      : { id: null, name: memberInfo?.company_name }
  );
  const [isCompanyApplicable, setCompanyApplicable] = useState(true);
  const [jobTitleId, setJobTitleId] = useState(memberInfo.job_title_id);
  const [departmentId, setDepartmentId] = useState(
    !!memberInfo?.company_membership?.department_id
      ? memberInfo.company_membership.department_id
      : memberInfo?.department_id
  );
  const [bio, setBio] = useState(memberInfo.biography);
  const [countryCode, setCountryCode] = useState(memberInfoCountryCodeId);
  const [payRate, setPayRate] = useState(memberInfo.pay_rate);
  const [payRateCurrencyId, setPayRateCurrencyId] = useState(
    !!memberInfo.currency_code_id
      ? memberInfo.currency_code_id
      : countryList.find((c) => c.alpha2 === 'US').currency_id
  );
  const [skills, setSkills] = useState(memberInfo.skills_information);
  const [phones, setPhones] = useReducer(
    phoneReducer,
    memberInfo.contact_information.filter((ci) =>
      includes(
        [
          DEVICE_TYPES.cell,
          DEVICE_TYPES.landline,
          DEVICE_TYPES.TDD,
          DEVICE_TYPES.other,
        ],
        ci.device_type
      )
    )
  );
  const [emails, setEmails] = useReducer(
    emailReducer,
    memberInfo.contact_information.filter(
      (ci) => ci.device_type === DEVICE_TYPES.email
    )
  );
  const [educations, setEducations] = useReducer(
    educationReducer,
    memberInfo.education_information
      .filter((ei) => ei !== null && ei !== undefined)
      .map((ei) => ({
        ...ei,
        start_date: isValid(parseISO(ei.start_date))
          ? parseISO(ei.start_date)
          : null,
        end_date: isValid(parseISO(ei.end_date)) ? parseISO(ei.end_date) : null,
      }))
  );
  const [work, setWork] = useReducer(
    workReducer,
    // Parse dates
    memberInfo.workhistory_information
      .filter((wi) => wi !== null && wi !== undefined)
      .map((wi) => ({
        ...wi,
        start_date: isValid(parseISO(wi.start_date))
          ? parseISO(wi.start_date)
          : null,
        end_date: isValid(parseISO(wi.end_date)) ? parseISO(wi.end_date) : null,
      }))
  );
  const [certificates, setCertificates] = useReducer(
    certificatesReducer,
    memberInfo.certificate_information
      .filter((ci) => ci !== null && ci !== undefined)
      .map((ci) => ({
        ...ci,
        date_received: isValid(parseISO(ci.date_received))
          ? parseISO(ci.date_received)
          : null,
      }))
  );
  const [achievements, setAchievements] = useReducer(
    achievementsReducer,
    memberInfo.achievement_information
  );

  // Errors
  const [errors, setErrors] = useState({});

  const handleAvatarClick = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
      setOriginalFile(selectedFile);
      setShowCrop(true);
    }
  };

  const validateForm = () => {
    let allErrors = {};
    if (!firstName) set(allErrors, 'firstNameError', 'First name is required');
    if (!lastName) set(allErrors, 'lastNameError', 'Last name is required');

    if (isCompanyApplicable) {
      if (!company)
        set(allErrors, 'companyNameError', 'Company name is required');
    }

    if (payRate < 0 || !isNumber(payRate))
      set(allErrors, 'rateError', 'Invalid Rate');

    if (achievements?.length > 0) {
      const errors = {};
      achievements.forEach((a) => {
        if (!a.entity) {
          set(errors, `[${a.id}].entity`, 'Title is required');
        }
        if (!a.description) {
          set(errors, `[${a.id}].description`, 'Description is required');
        }
      });
      if (keys(errors).length > 0) set(allErrors, 'achieveErrors', errors);
    }
    if (emails?.length > 0) {
      const errors = {};
      emails.forEach((e) => {
        if (!e.primary_contact) {
          if (!e.device) {
            set(errors, `[${e.id}].email`, 'Email address is required');
          }
          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.device)) {
            set(errors, `[${e.id}].email`, 'Email address is invalid');
          }
          if (!e.description) {
            set(errors, `[${e.id}].description`, 'Description is required');
          }
        }
      });
      if (keys(errors).length > 0) set(allErrors, 'emailErrors', errors);
    }
    if (phones?.length > 0) {
      const errors = {};
      phones.forEach((p) => {
        if (!p.primary_contact) {
          if (!p.description) {
            set(errors, `[${p.id}].description`, 'Description is required');
          }

          if (includes([DEVICE_TYPES.cell, DEVICE_TYPES.TDD], p.device_type)) {
            // Has to be verified by TOTP
            if (!!!p.device_confirm_date) {
              set(errors, `[${p.id}].phoneInput`, 'Phone not verified');
            }
          } else if (p.device_type === DEVICE_TYPES.landline) {
            // At least 10 digits
            if (p.device.length < 10) {
              set(errors, `[${p.id}].phoneInput`, 'Number too short');
            }
          }
        }
      });
      if (keys(errors).length > 0) set(allErrors, 'phoneErrors', errors);
    }

    if (work?.length > 0) {
      const errors = {};
      work.forEach((w) => {
        if (!w.job_title) {
          set(errors, `[${w.id}].job_title`, 'Title is required');
        }
        if (!w.company_name) {
          set(errors, `[${w.id}].company_name`, 'Company is required');
        }
      });
      if (keys(errors).length > 0) set(allErrors, 'workErrors', errors);
    }

    if (educations?.length > 0) {
      const errors = {};
      educations.forEach((e) => {
        if (!e.school_name) {
          set(errors, `[${e.id}].school_name`, 'School is required');
        }
        if (!e.field_of_study) {
          set(errors, `[${e.id}].field_of_study`, 'Field of study is required');
        }
      });
      if (keys(errors).length > 0) set(allErrors, 'educationErrors', errors);
    }

    if (certificates.length > 0) {
      const errors = {};
      certificates.forEach((c) => {
        if (!c.title) {
          set(errors, `[${c.id}].title`, 'Title is required');
        }
      });
      if (keys(errors).length > 0) set(allErrors, 'certErrors', errors);
    }

    return keys(allErrors).length > 0 ? allErrors : null;
  };

  const submitForm = async () => {
    const form = {
      member: {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        company_name: company.name,
        job_title_id: jobTitleId,
        department_id: departmentId,
      },
      company,
      member_profile: { biography: bio },
      member_achievement: achievements?.length > 0 ? achievements : null,
      member_contact_2: [...emails, ...phones],
      member_location: [],
      member_rate: { pay_rate: payRate, currency_code_id: payRateCurrencyId },
      work,
      educations,
      certificates,
      skills: skills.filter((s) => !!s).map((s) => s.id),
    };
    // console.debug(form);
    dispatch(putMemberInfo(form));

    if (!!avatarFile) {
      const fileForm = new FormData();
      fileForm.append('avatar', avatarFile);
      const mime = await FileType.fromBlob(avatarFile);
      fileForm.append('mime', mime);
      dispatch(putAvatar(fileForm));
    }

    setTimeout(() => handleQuitForm(), [TIMEOUT]);
  };
  const handleApplyClick = () => {
    // Submit flow
    const errors = validateForm();
    if (!!errors) {
      console.debug(errors);
      setErrors(errors);
      keys(errors).forEach((k) => {
        switch (k) {
          case 'achieveErrors':
            setAchieveExpanded(true);
            break;
          case 'emailErrors':
            setContactExpanded(true);
            break;
          case 'phoneErrors':
            setContactExpanded(true);
            break;
          case 'workErrors':
            setWorkExpanded(true);
            break;
          case 'educationErrors':
            setEducationExpanded(true);
            break;
          case 'certErrors':
            setCertExpanded(true);
            break;

          default:
            return;
        }
      });
    } else {
      setErrors({});
      submitForm();
      console.debug('we cool');
    }
  };

  const handleQuitForm = () => {
    dispatch(openDrawer(DRAWER_CONTENT_TYPE.myProfileInfo));
    dispatch(setReduxMemberAlert({ ...memberAlert, open: false }));
  };

  const handleCancelClick = () =>
    setConfirm({
      open: true,
      title: 'Cancel changes',
      cancelText: 'Go back',
      okText: 'Cancel',
      content: 'Are you sure you want to quit and cancel all changes?',
      onOk: handleQuitForm,
      onCancel: () => setConfirm((ps) => ({ ...ps, open: false })),
    });

  // We will send avatar with the form
  const handleCropConfirm = (file) => {
    // console.log('Blob at file', file);
    const fileForm = new FormData();
    fileForm.append('avatar', file);
    const reader = new FileReader();

    if (file) {
      setAvatarFile(file);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
    }
  };

  const handleChangeCompany = (company) => {
    setCompany(company);
  };

  const handleApplicableToggle = (e) => {
    // The field is called Not applicable, so that's why we invert here
    const isApplicable = !e.target.checked;
    setCompanyApplicable(isApplicable);
    setCompany((ps) => (isApplicable ? ps : null));
  };

  const handleCloseAlert = () => {
    dispatch(setReduxMemberAlert({ ...memberAlert, open: false }));
  };

  const handleFirstName = (e) => setFirstName(e.target.value);
  const handleMiddletName = (e) => setMiddletName(e.target.value);
  const handleLastName = (e) => setLastName(e.target.value);
  const handleChangeCountry = (e) => setCountryCode(e.target.value);
  const handleDepartmentId = (e) => setDepartmentId(e.target.value);
  const handleJobTitleId = (e) => setJobTitleId(e.target.value);
  const onRateChange = (e) => setPayRate(Number(e.target.value));
  const onCurrencyChange = (e) => setPayRateCurrencyId(e.target.value);
  const handleContactExpanded = () => setContactExpanded((ps) => !ps);
  const handleSkillsExpanded = () => setSkillsExpanded((ps) => !ps);
  const handleSkills = (e, v) => setSkills(v);
  const handleBioExpanded = () => setBioExpanded((ps) => !ps);
  const handleBio = (e) => setBio(e.target.value);
  const handleWorkExpanded = () => setWorkExpanded((ps) => !ps);
  const handleEducationExpanded = () => setEducationExpanded((ps) => !ps);
  const handleCertExpanded = () => setCertExpanded((ps) => !ps);
  const handleCertificates = () =>
    setCertificates({
      type: PROFILE_EDIT_ACTIONS.certificateAdd,
    });
  const handleAchieveExpanded = () => setAchieveExpanded((ps) => !ps);
  const handleAchievements = () =>
    setAchievements({
      type: PROFILE_EDIT_ACTIONS.achievementAdd,
    });

  return (
    <>
      <ImageCrop
        show={isShowCrop}
        close={() => setShowCrop(false)}
        src={src}
        originalFile={originalFile}
        handleCropConfirm={handleCropConfirm}
      />
      {memberAlert.open && (
        <Snackbar
          open={memberAlert.open}
          autoHideDuration={TIMEOUT}
          onClose={handleCloseAlert}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseAlert}
            severity={memberAlert.severity}
          >
            {memberAlert.message}
          </Alert>
        </Snackbar>
      )}
      {confirm.open && <ConfirmDialog {...confirm} />}
      <div className={classes.root}>
        <div className={classes.header}>
          <div>
            <h3 className={classes.title}>Edit Profile</h3>
          </div>
        </div>
        <div className={classes.body}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <label htmlFor="change-avatar">
                <input
                  style={{ display: 'none' }}
                  id="change-avatar"
                  name="change-avatar"
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarClick}
                />
                <MaterialAvatar
                  style={{ height: '10rem', width: '10rem' }}
                  // member_id={memberInfo.member_id}
                  src={src}
                  firstName={memberInfo.first_name}
                  lastName={memberInfo.last_name}
                  badgeType="edit"
                  variant="circular"
                  badgeAnchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                />
              </label>
            </Grid>
            <Grid
              item
              xs={7}
              container
              spacing={0}
              alignItems="baseline"
              justify="center"
              direction="column"
            >
              <TextField
                id="First Name"
                value={firstName || ''}
                onChange={handleFirstName}
                label="First Name"
                error={!!errors?.firstNameError}
                helperText={
                  !!errors?.firstNameError ? errors?.firstNameError : ''
                }
                required
              />
              <TextField
                id="Middle Name"
                value={middleName || ''}
                onChange={handleMiddletName}
                label="Middle Name"
              />
              <TextField
                id="Last Name"
                value={lastName || ''}
                onChange={handleLastName}
                label="Last Name"
                error={!!errors?.lastNameError}
                helperText={
                  !!errors?.lastNameError ? errors?.lastNameError : ''
                }
                required
              />
              <CountryInput
                country={countryCode}
                handleChangeCountry={handleChangeCountry}
                variant="standard"
                required={false}
              />
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <CompanyNameInput
                  company={company}
                  onToggle={handleApplicableToggle}
                  isApplicable={isCompanyApplicable}
                  onCompanySelect={handleChangeCompany}
                  error={errors?.companyNameError}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6}>
                <DepartmentList
                  id="Department"
                  department={departmentId}
                  onChange={handleDepartmentId}
                  variant="standard"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <JobTitleInput
                  id="Job Title"
                  jobTitle={jobTitleId}
                  onChange={handleJobTitleId}
                  variant="standard"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <RateInput
                  rate={payRate}
                  currencyId={payRateCurrencyId}
                  label="Hourly rate"
                  onRateChange={onRateChange}
                  onCurrencyChange={onCurrencyChange}
                  error={errors?.rateError}
                  variant="standard"
                  required={false}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.noTopPadding} xs={12}>
              <AmeraAccordionControllable
                label="Contact Information"
                expanded={isContactExpanded}
                onChange={handleContactExpanded}
              >
                <div className={classes.editableContainer}>
                  {phones?.length > 0 ? (
                    orderBy(phones, ['display_order'], ['asc'])
                      .filter((p) => p !== null && p !== undefined)
                      .map((p) => (
                        <div key={p.id} className={classes.editableItem}>
                          <PhoneItem
                            data={p}
                            setPhones={setPhones}
                            phonesCount={phones.length}
                            error={
                              !!errors?.phoneErrors && errors?.phoneErrors[p.id]
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                  <div>
                    <Button
                      startIcon={<AddCircleIcon />}
                      disabled={
                        phones?.length ===
                        Number(process.env.REACT_APP_MAX_PHONES)
                      }
                      onClick={() =>
                        setPhones({
                          type: PROFILE_EDIT_ACTIONS.phoneAdd,
                          payload: {
                            device_country_id: countryCode,
                            display_order:
                              phones.filter(
                                (p) => p !== null && p !== undefined
                              ).length > 0
                                ? max(
                                    phones
                                      .filter(
                                        (p) => p !== null && p !== undefined
                                      )
                                      .map((p) => p.display_order)
                                  ) + 1
                                : 1,
                          },
                        })
                      }
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
                <div className={classes.editableContainer}>
                  {emails?.length > 0 ? (
                    orderBy(emails, ['display_order'], ['asc'])
                      .filter((e) => e !== null && e !== undefined)
                      .map((e) => (
                        <div key={e.id} className={classes.editableItem}>
                          <EmailItem
                            data={e}
                            setEmails={setEmails}
                            emailsCount={emails.length}
                            error={
                              !!errors?.emailErrors && errors?.emailErrors[e.id]
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                  <div>
                    <Button
                      startIcon={<AddCircleIcon />}
                      disabled={
                        emails?.length ===
                        Number(process.env.REACT_APP_MAX_EMAILS)
                      }
                      onClick={() =>
                        setEmails({
                          type: PROFILE_EDIT_ACTIONS.emailAdd,
                          payload: {
                            device_country_id: countryCode,
                            display_order:
                              emails.filter(
                                (e) => e !== null && e !== undefined
                              ).length > 0
                                ? max(
                                    emails
                                      .filter(
                                        (e) => e !== null && e !== undefined
                                      )
                                      .map((e) => e.display_order)
                                  ) + 1
                                : 1,
                          },
                        })
                      }
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
              </AmeraAccordionControllable>
              <AmeraAccordionControllable
                label="Skills"
                expanded={isSkillsExpanded}
                onChange={handleSkillsExpanded}
              >
                <SkillAutocomplete selected={skills} onChange={handleSkills} />
              </AmeraAccordionControllable>
              <AmeraAccordionControllable
                label="Bio"
                expanded={isBioExpanded}
                onChange={handleBioExpanded}
              >
                <TextField
                  fullWidth
                  variant="standard"
                  value={bio}
                  onChange={handleBio}
                  multiline
                  rows={6}
                />
              </AmeraAccordionControllable>
              <AmeraAccordionControllable
                label="Work Experience"
                expanded={isWorkExpanded}
                onChange={handleWorkExpanded}
              >
                <div className={classes.editableContainer}>
                  {work?.length > 0 ? (
                    <>
                      {orderBy(
                        work,
                        ['start_date', 'end_date'],
                        ['desc', 'desc']
                      )
                        .filter((wo) => wo !== null && wo !== undefined)
                        .map((wo) => (
                          <div key={wo.id} className={classes.editableItem}>
                            <WorkExperienceItem
                              data={wo}
                              setWork={setWork}
                              error={
                                !!errors?.workErrors &&
                                errors?.workErrors[wo.id]
                              }
                            />
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                  <div>
                    <Button
                      startIcon={<AddCircleIcon />}
                      onClick={() =>
                        setWork({
                          type: PROFILE_EDIT_ACTIONS.workAdd,
                        })
                      }
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
              </AmeraAccordionControllable>
              <AmeraAccordionControllable
                label="Education"
                expanded={isEducationExpanded}
                onChange={handleEducationExpanded}
              >
                <div className={classes.editableContainer}>
                  {educations?.length > 0 ? (
                    orderBy(
                      educations,
                      ['start_date', 'end_date'],
                      ['desc', 'desc']
                    )
                      .filter((eo) => eo !== null && eo !== undefined)
                      .map((eo) => (
                        <div key={eo.id} className={classes.editableItem}>
                          <EducationItem
                            data={eo}
                            setEducations={setEducations}
                            error={
                              !!errors?.educationErrors &&
                              errors?.educationErrors[eo.id]
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                  <div>
                    <Button
                      startIcon={<AddCircleIcon />}
                      disabled={
                        educations?.length ===
                        Number(process.env.REACT_APP_MAX_EDUCATIONS)
                      }
                      onClick={() =>
                        setEducations({
                          type: PROFILE_EDIT_ACTIONS.educationAdd,
                        })
                      }
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
              </AmeraAccordionControllable>
              <AmeraAccordionControllable
                label="Certification"
                expanded={isCertExpanded}
                onChange={handleCertExpanded}
              >
                <div className={classes.editableContainer}>
                  {certificates?.length > 0 ? (
                    orderBy(certificates, ['date_received'], ['desc'])
                      .filter((ci) => ci !== null && ci !== undefined)
                      .map((ci) => (
                        <div key={ci.id} className={classes.editableItem}>
                          <CertificateItem
                            data={ci}
                            setCertificates={setCertificates}
                            error={
                              !!errors?.certErrors && errors?.certErrors[ci.id]
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                  <div>
                    <Button
                      startIcon={<AddCircleIcon />}
                      disabled={
                        educations?.length ===
                        Number(process.env.REACT_APP_MAX_CERTIFICATES)
                      }
                      onClick={handleCertificates}
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
              </AmeraAccordionControllable>
              <AmeraAccordionControllable
                label="Achievements"
                expanded={isAchieveExpanded}
                onChange={handleAchieveExpanded}
              >
                <div className={classes.editableContainer}>
                  {achievements?.length > 0 ? (
                    orderBy(achievements, ['display_order'], ['asc'])
                      .filter((ai) => ai !== null && ai !== undefined)
                      .map((ai) => (
                        <div key={ai.id} className={classes.editableItem}>
                          <AchievementItem
                            data={ai}
                            setAchievements={setAchievements}
                            error={
                              !!errors?.achieveErrors &&
                              errors?.achieveErrors[ai.id]
                            }
                          />
                        </div>
                      ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                  <div>
                    <Button
                      startIcon={<AddCircleIcon />}
                      disabled={
                        achievements?.length ===
                        Number(process.env.REACT_APP_MAX_ACHIEVEMENTS)
                      }
                      onClick={handleAchievements}
                    >
                      Add Item
                    </Button>
                  </div>
                </div>
              </AmeraAccordionControllable>
            </Grid>
            <Grid
              item
              className={classes.noTopPadding}
              xs={12}
              container
              spacing={1}
              justify="flex-end"
            >
              <Grid item>
                <CardButton
                  variant="info"
                  text="Cancel"
                  onClick={handleCancelClick}
                />
              </Grid>
              <Grid item>
                <CardButton
                  variant="okFilled"
                  text="Save"
                  onClick={handleApplyClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => ({
  countryList: state.member.countryList,
  memberInfo: state.member.memberInfo,
  memberAlert: state.member.memberAlert,
  currencies: state.billing.currencyList,
});

export default connect(mapStatetoProps)(MyProfileEdit);
