import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import ImageIcon from '@material-ui/icons/Image';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { trimStart, keys, set } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import drawerProfileStyles from '../../jss/views/drawerProfileStyles';
import EventCover from '../Modal/EventModals/EventInfoModal/EventCover';
import Button from '../Dashboard/CardButton';
import IndustriesAutocomplete from '../IndustriesAutocomplete';
import AddressInputForm from '../../components/AddressInputForm';
import AmeraAccordion from '../../components/AmeraAccordion/AmeraAccordion';
import { DEVICE_TYPES } from '../../utils/contact';
import { destructureAddress, MAP_VENDORS } from '../../utils/country';
import LocationMap from '../../components/Modal/EventModals/LocationMap';
import useGoogleMap from '../../hooks/useGoogleMap';
import { createCompany } from '../../redux/actions/company';
import { closeDrawer } from '../../redux/actions/drawer';
import AddMemberModal from '../../components/Company/AddMemberModal';
import CompanyMember from './CompanyProfile/CompanyMember';

const CompanyCreate = () => {
  const dispatch = useDispatch();
  const classes = drawerProfileStyles();

  const [avatar, setAvatar] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    place_id: '',
    admin_area_1: '',
    admin_area_2: '',
    street_address_1: '',
    street_address_2: '',
    postal_code: '',
    country_code_id: 840,
    main_phone: '',
    primary_url: '',
    logo: '',
    industries: [],
    email: '',
    phone_country_code_id: 840,
    members: [],
  });
  const [errors, setErrors] = useState({});

  const countryList = useSelector((s) => s.member.countryList);
  const deviceCountryObj = countryList.find(
    (cl) => cl.id === companyInfo.phone_country_code_id
  );
  const countryAlpha2 = !!deviceCountryObj
    ? (deviceCountryObj?.alpha2).toLowerCase()
    : 'us';
  const countryPhonePrefix = !!deviceCountryObj ? deviceCountryObj?.phone : '1';

  const { places, getPlaceDetailsFromPlaceId, mapError } = useGoogleMap();
  const firstPlace = !!places?.length > 0 && places[0];

  useEffect(() => {
    if (!!companyInfo.place_id) {
      getPlaceDetailsFromPlaceId(companyInfo.place_id);
    }
  }, [getPlaceDetailsFromPlaceId, companyInfo.place_id]);

  useEffect(() => {
    const parsed =
      !!firstPlace && destructureAddress(firstPlace.address_components);
    // console.debug('firstPlace', firstPlace);
    if (!!parsed) {
      setCompanyInfo({
        ...companyInfo,
        street_address_1: parsed?.streetAddress1,
        street_address_2: parsed?.streetAddress2,
        locality: parsed?.locality,
        sublocality: parsed?.sublocality,
        admin_area_1: parsed?.adminArea1,
        admin_area_2: parsed?.adminArea2,
        postal_code: parsed?.postal,
        vendor_formatted_address: firstPlace.formatted_address,
        map_link: firstPlace.url,
        latitude: firstPlace.geometry.location.lat(),
        longitude: firstPlace.geometry.location.lng(),
        map_vendor: MAP_VENDORS.google,
      });
    }
    // eslint-disable-next-line
  }, [firstPlace]);

  const handleCloseClick = () => dispatch(closeDrawer());

  const handleSuggestionClick = (suggestion) => {
    if (suggestion?.place_id)
      setCompanyInfo({
        ...companyInfo,
        place_id: suggestion.place_id,
      });
  };

  const handleSelectPicture = (e) => {
    const file = Array.from(e.target.files)[0];

    setAvatar(file);
    setCompanyInfo({
      ...companyInfo,
      logo: file,
    });
  };

  const handleName = (e) => {
    setCompanyInfo({
      ...companyInfo,
      name: e.target.value,
    });
  };

  const handleIndustries = (e, v) => {
    setIndustries(v);
    const ids = [];
    v.map((industry) => {
      ids.push(industry.industry_id);
      return ids;
    });

    setCompanyInfo({
      ...companyInfo,
      industries: ids,
    });
  };

  const handlePrimaryUrl = (e) => {
    setCompanyInfo({
      ...companyInfo,
      primary_url: e.target.value,
    });
  };

  const handleEmail = (e) => {
    setCompanyInfo({
      ...companyInfo,
      email: e.target.value,
    });
  };

  const handleLocality = (e, v) => {
    setCompanyInfo({
      ...companyInfo,
      locality: v,
    });
  };

  const handlePostal = (e) => {
    setCompanyInfo({
      ...companyInfo,
      postal_code: e.target.value,
    });
  };

  const handleStreetAddress2 = (e) => {
    setCompanyInfo({
      ...companyInfo,
      street_address_2: e.target.value,
    });
  };

  const handleAdminArea1 = (e) => {
    setCompanyInfo({
      ...companyInfo,
      admin_area_1: e.target.value,
    });
  };

  const handleAdminArea2 = (e) => {
    setCompanyInfo({
      ...companyInfo,
      admin_area_2: e.target.value,
    });
  };

  const handleCountrySelect = (e) => {
    setCompanyInfo({
      street_address_1: '',
      street_address_2: '',
      locality: '',
      sublocality: '',
      admin_area_1: '',
      admin_area_2: '',
      postal_code: '',
      country_code_id: e.target.value,
      phone_country_code_id: e.target.value,
    });
  };

  const handlePhone = (v, pc) => {
    setCompanyInfo({
      ...companyInfo,
      main_phone: trimStart(v, countryPhonePrefix),
      phone_country_code_id: countryList.find(
        (cl) => cl.alpha2 === pc.toUpperCase()
      )?.id,
    });
  };

  const handleAddMemberModalClose = () => {
    setAddMemberModalOpen(false);
  };

  const validateForm = () => {
    let allErrors = {};

    if (!companyInfo.name) set(allErrors, 'name', 'Company name is required');

    // Email is not required, but has to be valid if entered
    if (!!companyInfo.email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(companyInfo.email))
        set(allErrors, 'email', 'Email address is invalid');
    }

    if (companyInfo.main_phone?.length < 8)
      set(allErrors, 'phone', 'Phone number too short');

    if (
      !companyInfo.street_address_1 ||
      !companyInfo.postal_code ||
      !companyInfo.admin_area_1 ||
      !companyInfo.admin_area_2 ||
      !companyInfo.locality
    )
      set(allErrors, 'location', {
        postal_code: !companyInfo.postal_code ? 'is required' : '',
        street_address_1: !companyInfo.street_address_1 ? 'is required' : '',
        locality: !companyInfo.locality ? 'is required' : '',
        admin_area_1: !companyInfo.admin_area_1 ? 'is required' : '',
        admin_area_2: !companyInfo.admin_area_2 ? 'is required' : '',
      });

    return keys(allErrors).length > 0 ? allErrors : null;
  };

  const handleSave = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const errors = validateForm();
    if (!!errors) {
      console.debug(errors);
      setErrors(errors);
    } else {
      let formData = new FormData();
      let newValues = { ...companyInfo };
      Object.keys(newValues).map((key) => {
        const value =
          key === 'members' ? JSON.stringify(newValues[key]) : newValues[key];
        return formData.append(key, value);
      });

      dispatch(createCompany(formData));
      handleCloseClick();
    }
  };

  const handleAddMemberModal = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setAddMemberModalOpen(true);
  };

  const phoneData = {
    phoneNumber: `${countryPhonePrefix}${companyInfo.main_phone}`,
    phoneCountry: countryAlpha2,
    phoneType: DEVICE_TYPES.landline,
    variant: 'standard',
    error: errors?.phone,
    handleChange: handlePhone,
  };

  const handleAddMember = (role, memberInfo) => {
    setCompanyInfo({
      ...companyInfo,
      members: [...companyInfo.members, { ...memberInfo, company_role: role }],
    });

    handleAddMemberModalClose();
  };

  const handleRemoveMember = (id) => {
    setCompanyInfo({
      ...companyInfo,
      members: companyInfo.members.filter((member) => member.member_id !== id),
    });
  };

  return (
    <div className={classes.root}>
      {/* header */}
      <div className={classes.header}>
        <div>
          <h3 className={classes.title}>Create Company</h3>
        </div>
      </div>

      {/* avatar section */}
      {avatar && <EventCover imageUrl={window.URL.createObjectURL(avatar)} />}
      <label htmlFor="change-pic">
        <input
          style={{ display: 'none' }}
          id="change-pic"
          name="change-pic"
          type="file"
          accept="image/*"
          onChange={handleSelectPicture}
        />
        <Chip
          size="small"
          label="Upload picture"
          icon={<ImageIcon />}
          className={classes.editPicButton}
          clickable
        />
      </label>

      {/* Detail */}
      <div className={classes.body}>
        <Grid container>
          <AmeraAccordion label="Details">
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  value={companyInfo.name}
                  label="Company name"
                  required
                  placeholder="ACME Inc"
                  fullWidth
                  error={!!errors?.name}
                  helperText={!!errors?.name ? errors?.name : ''}
                  onChange={handleName}
                />
              </Grid>
              <Grid item xs={12}>
                <IndustriesAutocomplete
                  onChange={handleIndustries}
                  selected={industries}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={companyInfo.email}
                  label="E-mail"
                  error={!!errors?.email}
                  helperText={!!errors?.email ? errors?.email : ''}
                  placeholder="example@example.com"
                  fullWidth
                  onChange={handleEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={companyInfo.primary_url}
                  label="Website"
                  placeholder="www.facebook.com"
                  fullWidth
                  onChange={handlePrimaryUrl}
                />
              </Grid>

              <AddressInputForm
                countryCode={companyInfo.country_code_id}
                streetAddress1={companyInfo.street_address_1}
                streetAddress2={companyInfo.street_address_2}
                adminArea1={companyInfo.admin_area_1}
                adminArea2={companyInfo.admin_area_2}
                locality={companyInfo.locality}
                postal={companyInfo.postal_code}
                onStreetAddress2={handleStreetAddress2}
                onLocality={handleLocality}
                onAdminArea1={handleAdminArea1}
                onAdminArea2={handleAdminArea2}
                onPostal={handlePostal}
                onCountrySelected={handleCountrySelect}
                onSuggestionClick={handleSuggestionClick}
                error={errors?.location}
                phone={phoneData}
              />

              <Grid item xs={12}>
                {!!places && places.length > 0 && (
                  <LocationMap
                    defaultZoom={13}
                    places={places}
                    selectedPlace={places[0]}
                    onSelectClick={null}
                    onBoundsReady={null}
                    error={mapError}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: '15rem' }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                )}
              </Grid>
            </Grid>
          </AmeraAccordion>

          <AmeraAccordion
            label="Members"
            button={
              <Button
                variant="okFilled"
                text="Add"
                onClick={handleAddMemberModal}
              />
            }
          >
            <Grid
              item
              container
              xs={12}
              spacing={1}
              className={classes.membersContainer}
            >
              {companyInfo.members?.length > 0 ? (
                companyInfo.members.map((m) => (
                  <div className={classes.memberRow}>
                    <CompanyMember key={m.member_id} contactData={m} />
                    <IconButton
                      onClick={() => handleRemoveMember(m.member_id)}
                      className={classes.clearButton}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                ))
              ) : (
                <div>No members</div>
              )}
            </Grid>
            {addMemberModalOpen && (
              <AddMemberModal
                title="Add Member"
                open={addMemberModalOpen}
                onClose={handleAddMemberModalClose}
                onSave={handleAddMember}
                members={companyInfo.members}
              />
            )}
          </AmeraAccordion>

          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button variant="info" text="Cancel" onClick={handleCloseClick} />
            </Grid>
            <Grid item>
              <Button variant="okFilled" text="Save" onClick={handleSave} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CompanyCreate;
