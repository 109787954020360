import { format, differenceInDays } from 'date-fns';
import { GET, apiUrl } from '../../config/api';
import { setSnackbarData } from './snackbar';

import {
  CURRENCY_LIST_FETCH_START,
  CURRENCY_LIST_FETCH_SUCCESS,
  CURRENCY_LIST_FETCH_FAILURE,
  GET_WEEKLY_BILLING,
} from '../actionTypes/billing';

const endpoints = {
  currency: `${apiUrl}/billing/currency`,
  weeklyBilling: `${apiUrl}/billing/weekly`,
};

const generateWeeklyBillingData = (payload, start) => {
  const billing = {
    summary: {
      hourly_due: 0,
      fixed_due: 0,
      hours: 0,
      total: 0,
    },
    fixed: {},
  };

  const start_date = new Date(start);
  billing.hourly = payload.hourly_contracts.map((hourly_contract) => {
    let project_total_hours = 0,
      project_total_due = 0;
    const members = hourly_contract.member_hours.map((member_hour) => {
      let hours = [];
      for (let i = 0; i < 7; i++) hours[i] = 0;
      let member_total_hours = 0,
        member_total_due = 0,
        member_rate = 0;
      member_hour.date_hours.forEach((date_hour) => {
        const index = differenceInDays(
          start_date,
          new Date(date_hour.create_date)
        );
        hours[index] = date_hour.hours;
        member_total_hours += date_hour.hours;
        member_total_due += date_hour.due;
        member_rate = date_hour.rate;
      });
      project_total_hours += member_total_hours;
      project_total_due += member_total_due;
      return {
        ...member_hour,
        hours,
        total_hours: member_total_hours,
        due: member_total_due,
        rate: member_rate,
      };
    });

    billing.summary.hours += project_total_hours;
    billing.summary.hourly_due += project_total_due;

    return {
      project_id: hourly_contract.id,
      project_name: hourly_contract.title,
      members,
      total_hours: project_total_hours,
      total_due: project_total_due,
    };
  });

  let total_paid_this_week = 0,
    total_paid = 0,
    total_cost = 0,
    total_pending = 0;
  billing.fixed.projects = payload.fixed_contracts.map((fixed_contract) => {
    let milestone_done = fixed_contract.milestone_info.filter(
      (milestone) => milestone.status === 'complete'
    ).length;
    total_paid_this_week = fixed_contract.paid_this_week;
    total_paid = fixed_contract.total_paid;
    total_cost = fixed_contract.total_cost;
    total_pending = fixed_contract.pending;
    billing.summary.fixed_due += fixed_contract.this_week_pending;

    const milestones = fixed_contract.milestone_info.map((milestone) => {
      return {
        ...milestone,
        name: milestone.title,
        started: format(new Date(milestone.create_date), 'yyyy-MM-dd'),
        completed:
          milestone.status === 'complete'
            ? format(new Date(milestone.create_date), 'yyyy-MM-dd')
            : '-',
      };
    });

    return {
      ...fixed_contract,
      project_id: fixed_contract.id,
      name: fixed_contract.title,
      milestone_done,
      milestone: fixed_contract.milestone_info.length,
      milestones,
    };
  });
  billing.fixed.total_paid_this_week = total_paid_this_week;
  billing.fixed.total_paid = total_paid;
  billing.fixed.total_cost = total_cost;
  billing.fixed.total_pending = total_pending;
  billing.summary.total =
    billing.summary.hourly_due + billing.summary.fixed_due;
  return billing;
};

class AmeraBillingApi {
  catchErrorHandler = (type, error) => (dispatch) => {
    dispatch({ type, payload: error?.message ? error.message : error });
  };

  // False will fetch all currencies in the database
  getCurrencyList = (isOnlyEnabledCountries = true) => (dispatch) => {
    dispatch({ type: CURRENCY_LIST_FETCH_START });
    GET(`${endpoints.currency}?only_enabled=${isOnlyEnabledCountries}`)
      .then((res) => {
        if (res.success) {
          dispatch({ type: CURRENCY_LIST_FETCH_SUCCESS, payload: res.data });
        } else {
          this.catchErrorHandler(CURRENCY_LIST_FETCH_FAILURE, res.description);
        }
      })
      .catch((error) =>
        this.catchErrorHandler(CURRENCY_LIST_FETCH_FAILURE, error)
      );
  };

  getWeeklyBilling = (start, end) => (dispatch) => {
    GET(`${endpoints.weeklyBilling}?start=${start}&end=${end}`)
      .then((res) => {
        if (res.success) {
          const payload = generateWeeklyBillingData(
            {
              fixed_contracts: res.fixed_contracts,
              hourly_contracts: res.hourly_contracts,
            },
            start
          );
          dispatch({ type: GET_WEEKLY_BILLING, payload });
        } else {
          console.log('hereerererererererererere', res);
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Something went wrong. Try some later!',
              type: 'error',
            })
          );
        }
      })
      .catch((error) => {
        console.log('hereerererererererererere', error);

        dispatch(
          setSnackbarData({
            open: true,
            message: 'Something went wrong. Try some later!',
            type: 'error',
          })
        );
      });
  };
}

const BillingApi = new AmeraBillingApi();
Object.freeze(BillingApi);
export { BillingApi };
