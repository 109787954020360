import grey from '@material-ui/core/colors/grey';
import { textGrey } from '../../jss/colors';

const signupStyle = (theme) => ({
  signupPage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  formSection: {
    padding: '4% 5% 5% 8%',
    backgroundColor: grey[100],
    color: textGrey,
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiFormLabel-root': {
      fontSize: '20px',
      color: textGrey,
    },
    '& .MuiInputBase-input': {
      height: '1.5em',
    },
    '& .MuiInputLabel-formControl': {
      top: '-0.2em',
    },
    '& .MuiInput-underline:before': {
      borderBottom: `2px solid #d4d4d4`,
    },
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
  logoImg: {
    width: '30%',
    paddingBottom: '20px',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  signupInput: {
    margin: 'auto',
    marginTop: '1.5em',
  },
  emailInput: {
    marginTop: '4em',
  },
  passwordInput: {
    marginTop: '2.5em',
  },
  confirmPasswordInput: {
    marginTop: '2.5em',
    marginBottom: '0.3em',
  },
  agreeTerms: {
    '& .MuiFormControlLabel-root': {
      marginRight: '0px',
    },
    '& a': {
      textDecoration: 'none',
      color: textGrey,
    },
  },
  signupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4em',
    padding: '0% 25% 0% 25%',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButton-label': {
      fontSize: '1.3em',
      borderWidth: '2px',
    },
    '& label': {
      marginTop: '0.5em',
      fontSize: '1.3em',
      whiteSpace: 'nowrap',

      '& a': {
        textDecoration: 'none',
        color: textGrey,
        '&:hover, &:active': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

export default signupStyle;
