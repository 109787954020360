import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fillRule="evenodd" clipPath="url(#clip0)" clipRule="evenodd">
        <path d="M6 13.078V15c0 1.4.959 2.576 2.256 2.907A.756.756 0 008.25 18v1.438A4.501 4.501 0 014.5 15 .75.75 0 003 15c0 3.06 2.29 5.584 5.25 5.954v.796a.75.75 0 001.5 0v-.796a5.97 5.97 0 002.92-1.207l2.529 2.53a.762.762 0 101.078-1.078l-2.53-2.53.009-.01-1.073-1.073-.008.011-1.084-1.084.007-.012-1.141-1.141-.004.015L7.5 12.422v-.019l-1.5-1.5v.019L2.801 7.723a.762.762 0 10-1.078 1.078L6 13.078zm1.5 1.5V15a1.5 1.5 0 001.875 1.453L7.5 14.578zm3.013 3.013a2.984 2.984 0 01-.769.316c.004.03.006.061.006.093v1.438a4.476 4.476 0 001.847-.763l-1.084-1.084zM12 14.747l-1.5-1.5V10.5a1.5 1.5 0 00-2.982-.235l-1.17-1.17A3 3 0 0112 10.5v4.247zm1.362 1.362l1.184 1.184A5.983 5.983 0 0015 15a.75.75 0 00-1.5 0c0 .383-.048.754-.138 1.11zM16 4.719V6a2 2 0 001.504 1.938A.508.508 0 0017.5 8v.959A3 3 0 0115 6a.5.5 0 00-1 0 4 4 0 003.5 3.969v.531a.5.5 0 001 0v-.531a3.98 3.98 0 001.946-.804l1.686 1.686a.508.508 0 00.72-.719l-1.687-1.686.006-.007-.716-.715-.005.007-.723-.722.005-.008-.76-.761-.004.01L17 4.28V4.27l-1-1v.012L13.868 1.15a.508.508 0 10-.72.719L16 4.718zm1 1V6a1 1 0 001.25.969L17 5.719zm2.009 2.008c-.159.093-.33.164-.513.211.003.02.004.041.004.062v.959a2.984 2.984 0 001.232-.509l-.723-.723zM20 5.831l-1-1V3a1 1 0 00-1.988-.157l-.78-.78A2 2 0 0120 3v2.831zm.908.908l.79.79A3.99 3.99 0 0022 6a.5.5 0 00-1 0c0 .255-.032.503-.092.74z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
