import React from 'react';
import TextField from '@material-ui/core/TextField';
import DateInput from '../../Modal/EventModals/DateInput';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListDeleteButton from '../../../pages/Profile/ProfileEdit/ListDeleteButton';
import EmploymentTypeSelect from '../../EmploymentTypeSelect';
import StreetAutocomplete from '../../../components/StreetAutocomplete';
import { addDays } from 'date-fns';

const WorkExperienceItem = (props) => {
  const { setWork, data, error } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          label="Job Title"
          required
          fullWidth
          error={!!error?.job_title}
          helperText={!!error?.job_title ? error?.job_title : ''}
          value={data.job_title}
          onChange={(e) =>
            setWork({
              type: PROFILE_EDIT_ACTIONS.workEditItem,
              payload: {
                work_id: data.id,
                field: 'job_title',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs>
        <TextField
          label="Company name"
          value={data.company_name}
          required
          error={!!error?.company_name}
          helperText={!!error?.company_name ? error?.company_name : ''}
          onChange={(e) =>
            setWork({
              type: PROFILE_EDIT_ACTIONS.workEditItem,
              payload: {
                work_id: data.id,
                field: 'company_name',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs>
        <EmploymentTypeSelect
          value={data.employment_type}
          onChange={(e) =>
            setWork({
              type: PROFILE_EDIT_ACTIONS.workEditItem,
              payload: {
                work_id: data.id,
                field: 'employment_type',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={12}>
        <StreetAutocomplete
          street={data.company_location}
          label="Company location"
          variant="standard"
          handleChange={(e) =>
            setWork({
              type: PROFILE_EDIT_ACTIONS.workEditItem,
              payload: {
                work_id: data.id,
                field: 'company_location',
                value: e?.description,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={5}>
        <DateInput
          value={data.start_date}
          label="From"
          inputVariant="standard"
          onChange={(d) =>
            setWork({
              type: PROFILE_EDIT_ACTIONS.workEditItem,
              payload: {
                work_id: data.id,
                field: 'start_date',
                value: d,
              },
            })
          }
        />
      </Grid>

      <Grid item xs={5}>
        <DateInput
          value={data.end_date}
          label="To"
          inputVariant="standard"
          disabled={!!!data.end_date}
          onChange={(d) => {
            setWork({
              type: PROFILE_EDIT_ACTIONS.workEditItem,
              payload: {
                work_id: data.id,
                field: 'end_date',
                value: d,
              },
            });
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!!data.end_date}
              onChange={() =>
                setWork({
                  type: PROFILE_EDIT_ACTIONS.workEditItem,
                  payload: {
                    work_id: data.id,
                    field: 'end_date',
                    value: !!!data.end_date
                      ? addDays(data.start_date, 1)
                      : null,
                  },
                })
              }
              name="end_date_present"
            />
          }
          label="present"
        />
      </Grid>
      <Grid item xs={2}>
        <ListDeleteButton
          id={data.id}
          handleDelete={() =>
            setWork({
              type: PROFILE_EDIT_ACTIONS.workDelete,
              payload: { work_id: data.id },
            })
          }
          listContext="workhistory item"
        >
          Delete Item
        </ListDeleteButton>
      </Grid>
    </Grid>
  );
};

export default WorkExperienceItem;
