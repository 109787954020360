import { Typography } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import PropTypes from 'prop-types';
import React from 'react';
import MaterialAvatar from '../../../../../components/MaterialAvatar';
import { defaultDateTimeZone } from '../../../../../utils/calendar';
import {
  getAvatarGroupStyles,
  getMembersNamesFromIds,
} from '../../../../../utils/email';
import { useEmailDetailsStyles } from './EmailDetailsStyles';

const MailHeader = ({
  email,
  member,
  isOwnEmail,
  senderFirstName,
  senderLastName,
  title,
  composeTime,
  senderAvatar,
  username,
}) => {
  const classes = useEmailDetailsStyles();
  const receivers = email.receivers || {
    amera: [],
  };

  return (
    <div className={classes.root}>
      <div className={classes.avatarArea}>
        <div>
          {isOwnEmail ? (
            <AvatarGroup max={2} className={classes.customAvatarGroup}>
              {receivers.amera.map((el, index) => (
                <MaterialAvatar
                  key={`preview-avatar-${index}`}
                  classes={'header'}
                  style={getAvatarGroupStyles(index)}
                  src={email.member_details[el].amera_avatar_url}
                  member_id={email.member_details[el].id}
                  alt={`${email.member_details[el].first_name} ${email.member_details[el].last_name}`}
                  firstName={email.member_details[el].first_name}
                  lastName={email.member_details[el].last_name}
                />
              ))}
            </AvatarGroup>
          ) : (
            <MaterialAvatar
              classes={'header'}
              src={senderAvatar}
              alt={`${senderFirstName} ${senderLastName}`}
              firstName={senderFirstName}
              lastName={senderLastName}
            />
          )}
        </div>
      </div>
      <div className={classes.headerArea}>
        <div className={classes.fromTime}>
          <div className={classes.fromTo}>
            From: {`${senderFirstName} ${senderLastName}`}
          </div>
          <Typography className={classes.composeTime}>
            {(composeTime && defaultDateTimeZone(composeTime)) || ''}
          </Typography>
        </div>
        <div className={classes.fromTo}>
          To: {getMembersNamesFromIds(receivers.amera, email.member_details)}
        </div>
        <div className={classes.title}>Subject: {title}</div>
      </div>
    </div>
  );
};

MailHeader.propTypes = {
  senderName: PropTypes.string,
  receiverName: PropTypes.string,
  title: PropTypes.string,
  composeTime: PropTypes.string,
  senderAvatar: PropTypes.string,
};

export default MailHeader;
