import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import ListItem from '@material-ui/core/ListItem';

const dateFormats = [
  'MM/DD/YYYY',
  'MM/DD/YY',
  'YYYY/MM/DD',
  'YY/MM/DD',
  'DD/MM/YYYY',
  'DD/MM/YY',
];

const useStyles = makeStyles((theme) => ({
  dateFormats: {
    width: '150px',
    padding: theme.spacing(1),
  },
}));

const DateFormat = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <div className="page_section-row align-center">
      <div className="section-row_label--small">Date Format</div>
      <FormControl className={classes.dateFormats}>
        <Select value={value} onChange={onChange}>
          {dateFormats.map((format) => (
            <ListItem value={format} key={format}>
              {format}
            </ListItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DateFormat;
