import React from 'react';
import { useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import WatchLaterIcon from '@material-ui/icons/WatchLater';

import MaterialAvatar from '../../../../../../components/MaterialAvatar';
import Counter from '../../../../../../components/Counter';
import { textCapitalized } from '../../../../../../utils/general';
import { defaultDateTimeZone } from '../../../../../../utils/calendar';
import { getContactOnlineStatus } from '../../../../../../utils/strophe/user';

function Conversation({ conversation, selectedChatID, loadConversation }) {
  const conversationId = conversation.chatID;
  const messages = conversation.messages;
  let lastMessage = {};
  if (messages?.length > 0) {
    lastMessage = messages[messages.length - 1];
  }

  const { contactData, rosterItems } = useSelector((s) => ({
    contactData: s.contact.contacts.find(
      (c) => c.contact_member_id.toString() === conversationId
    ),
    rosterItems: s?.chat?.rosterItems,
  }));

  return (
    <ListItem
      key={`list-item-${conversationId}`}
      button
      disableRipple
      alignItems="flex-start"
      className="conversation-item"
      selected={conversationId === selectedChatID}
      onClick={() => loadConversation(contactData)}
    >
      <ListItemAvatar>
        <MaterialAvatar
          badgeType={getContactOnlineStatus(contactData, rosterItems)}
          badgeVariant="dot"
          classes="conversation-profile-avatar"
          src={contactData?.amera_avatar_url}
          firstName={contactData?.first_name}
          lastName={contactData?.last_name}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          contactData &&
          textCapitalized(contactData.first_name) +
            ' ' +
            textCapitalized(contactData.last_name)
        }
        secondary={lastMessage?.messageText || ''}
      />
      {lastMessage.stamp && (
        <div className="conversation-time-with-unread-counts">
          <div className="unread-messages-counter">
            {conversation.unreadCount > 0 && (
              <Counter count={conversation.unreadCount} maxCountShow={20} />
            )}
          </div>
          <div className="conversation-time-icon">
            <WatchLaterIcon />
          </div>
          <div className="conversation-time-text">
            {defaultDateTimeZone(lastMessage.stamp, 'date')}
          </div>
        </div>
      )}
    </ListItem>
  );
}

export default Conversation;
