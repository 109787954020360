import React, { useState, useEffect } from 'react';
import drawerProfileStyles from '../../jss/views/drawerProfileStyles';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import {
  closeDrawer,
  openDrawer,
  setEditing,
} from '../../redux/actions/drawer';
import { getCompanyDetail } from '../../redux/actions/company';
import CompanyDetailsView from './CompanyProfile/CompanyDetailsView';
import CompanyDetailEdit from './CompanyProfile/CompanyDetailEdit';
import CompanyMembersView from './CompanyProfile/CompanyMembersView';
import CompanyMembersEdit from './CompanyProfile/CompanyMembersEdit';
import BusinessIcon from '@material-ui/icons/Business';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import EventCover from '../Modal/EventModals/EventInfoModal/EventCover';
import CardButton from '../../components/Dashboard/CardButton';
import ImageIcon from '@material-ui/icons/Image';
import ConfirmDialog from '../ConfirmDialog';
import { apiUrl } from '../../config/api';
import { COMPANY_ROLES } from '../../utils/company';
import { USER_TYPE } from '../../utils/general';
import {
  updateCompanyPicture,
  updateCompanyDetails,
  updateCompanyMembers,
  setReduxCompanyAlert,
} from '../../redux/actions/company';
import usePrevious from '../../hooks/usePrevious';
const FileType = require('file-type/browser');

const CompanyProfile = ({
  dispatch,
  companyData,
  member,
  companyAlert,
  isLoadingPicture,
  isLoadingDetails,
  isLoadingMembers,
}) => {
  const classes = drawerProfileStyles();

  const [isEditingDetails, setEditingDetails] = useState(false);
  const [isEditingMembers, setEditingMembers] = useState(false);
  const [confirm, setConfirm] = useState({
    open: false,
    okText: 'Approve',
    onCancelText: 'Go back',
    content: 'Are you sore you want to cancel these changes?',
    onCancel: () => setConfirm((ps) => ({ ...ps, open: false })),
  });

  // Exit editing gracefully on success
  const prevIsLoadingDetails = usePrevious(isLoadingDetails);
  useEffect(() => {
    if (
      !!prevIsLoadingDetails &&
      prevIsLoadingDetails &&
      !isLoadingDetails &&
      companyAlert.variant === 'success'
    ) {
      setEditingDetails(false);
      dispatch(setEditing(false));
    }
  }, [companyAlert.variant, dispatch, isLoadingDetails, prevIsLoadingDetails]);

  const prevIsLoadingMembers = usePrevious(isLoadingMembers);
  useEffect(() => {
    if (
      !!prevIsLoadingMembers &&
      prevIsLoadingMembers &&
      !isLoadingMembers &&
      companyAlert.variant === 'success'
    ) {
      setEditingMembers(false);
      dispatch(setEditing(false));
    }
  }, [
    companyAlert.variant,
    dispatch,
    isLoadingDetails,
    isLoadingMembers,
    prevIsLoadingDetails,
    prevIsLoadingMembers,
  ]);

  const handleChildClick = async (companyId) => {
    await dispatch(getCompanyDetail(companyId));
    await dispatch(
      openDrawer(DRAWER_CONTENT_TYPE.companyProfile, { companyId })
    );
  };

  const handleCloseClick = () => dispatch(closeDrawer());
  const handleEditDetailsClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setEditingDetails(true);
    dispatch(setEditing(true));
  };
  const handleEditMembersClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setEditingMembers(true);
    dispatch(setEditing(true));
  };

  const handleDiscardDetailsClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setConfirm((ps) => ({
      ...ps,
      open: true,
      onOk: handleDiscrardDetailConfirm,
    }));
  };

  const handleDiscardMembersClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setConfirm((ps) => ({
      ...ps,
      open: true,
      onOk: handleDiscrardMembersConfirm,
    }));
  };

  const handleDiscrardDetailConfirm = () => {
    setEditingDetails(false);
    setConfirm((ps) => ({ ...ps, open: false }));
    dispatch(setEditing(true));
  };

  const handleDiscrardMembersConfirm = () => {
    setEditingMembers(false);
    dispatch(setEditing(false));
    setConfirm((ps) => ({ ...ps, open: false }));
  };

  const handleApplyDetailChanges = (data) => {
    dispatch(updateCompanyDetails(companyData.id, data));
  };

  const handleApplyMembersChanges = (data) => {
    dispatch(updateCompanyMembers(companyData.id, data));
  };

  const handlePictureClick = async (e) => {
    try {
      const file = Array.from(e.target.files)[0];
      const typeParse = await FileType.fromBlob(file);
      console.debug('File result', file, typeParse);
      let formData = new FormData();
      formData.set('file', file);
      formData.set('size', file.size);
      formData.set('mime', !!typeParse ? typeParse.mime : null);
      await dispatch(updateCompanyPicture(companyData.id, formData));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSnackbarClose = () =>
    dispatch(setReduxCompanyAlert({ ...companyAlert, show: false }));
  const myCompanyRole = companyData?.company_members?.find(
    (cmo) => cmo.member_id === member.member_id
  )?.company_role;

  const isAdmin = myCompanyRole === COMPANY_ROLES.admin;
  const isOwner = myCompanyRole === COMPANY_ROLES.owner;
  const isGlobalAdmin = member.user_type === USER_TYPE.admin;
  const isCanEdit = isAdmin || isOwner || isGlobalAdmin;

  return (
    <>
      {companyAlert.show && (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={companyAlert.show}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
        >
          <Alert severity={companyAlert.variant}>{companyAlert.message}</Alert>
        </Snackbar>
      )}
      {confirm.open && <ConfirmDialog {...confirm} />}
      <div className={classes.root}>
        <div className={classes.header}>
          <div>
            <h3 className={classes.title}>Company Profile</h3>
          </div>
        </div>
        {!!companyData && (
          <>
            {!!companyData.logo_url && (
              <EventCover
                imageUrl={`${apiUrl}/${companyData.logo_url}`}
                isLoading={isLoadingPicture}
              />
            )}
            {isCanEdit && (
              <label htmlFor="change-pic">
                <input
                  style={{ display: 'none' }}
                  id="change-pic"
                  name="change-pic"
                  type="file"
                  accept="image/*"
                  onChange={handlePictureClick}
                />
                <Chip
                  size="small"
                  label={
                    !!companyData.logo_url ? 'Change picture' : 'Upload picture'
                  }
                  icon={<ImageIcon />}
                  className={classes.editPicButton}
                  clickable
                />
              </label>
            )}

            <div className={classes.body}>
              <Grid container spacing={5}>
                <Grid
                  item
                  container
                  className={classes.noTopPadding}
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6" color="initial">
                      <b>{companyData.name}</b>
                    </Typography>
                  </Grid>

                  {!!companyData.parent_company_id && (
                    <Grid item>
                      <Typography variant="caption" component="span">
                        Subsidiary of{' '}
                      </Typography>
                      <Chip
                        size="small"
                        icon={<BusinessIcon />}
                        label={companyData.parent_company_name}
                        clickable
                        onClick={() =>
                          handleChildClick(companyData.parent_company_id)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item className={classes.noTopPadding} xs={12}>
                  {!isEditingDetails ? (
                    <CompanyDetailsView
                      companyData={companyData}
                      onChildClick={handleChildClick}
                      onEditClick={handleEditDetailsClick}
                      isCanEdit={isCanEdit}
                    />
                  ) : (
                    <CompanyDetailEdit
                      isLoading={isLoadingDetails}
                      companyData={companyData}
                      onDiscardClick={handleDiscardDetailsClick}
                      onApplyClick={handleApplyDetailChanges}
                    />
                  )}
                  {!isEditingMembers ? (
                    <CompanyMembersView
                      companyData={companyData}
                      onEditClick={handleEditMembersClick}
                      isCanEdit={isCanEdit}
                    />
                  ) : (
                    <CompanyMembersEdit
                      isLoading={isLoadingMembers}
                      companyData={companyData}
                      onDiscardClick={handleDiscardMembersClick}
                      onApplyClick={handleApplyMembersChanges}
                      myCompanyRole={myCompanyRole}
                      isGlobalAdmin={isGlobalAdmin}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  className={classes.noTopPadding}
                  xs={12}
                  container
                  spacing={1}
                  justify="flex-end"
                >
                  <Grid item>
                    <CardButton
                      variant="info"
                      text="Сlose"
                      onClick={handleCloseClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStatetoProps = (state) => ({
  member: state.member.member,
  companyData: state.company.companyData,
  companyAlert: state.company.companyAlert,
  isLoadingPicture: state.company.isLoadingPicture,
  isLoadingDetails: state.company.isLoadingDetails,
  isLoadingMembers: state.company.isLoadingMembers,
});

export default connect(mapStatetoProps)(CompanyProfile);
