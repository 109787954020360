import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import styles from '../ProjectManagement.module.scss';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import MaterialAvatar from '../../../components/MaterialAvatar';
import { displayableFullName } from '../../../utils/contact';
import AmeraModal from '../../../components/Modal/modal';
import AttributeSelectionGroup from './AttributeSelectionGroup';
import AttributePills from './AttributePills';
import { ProjectsApi } from '../../../redux/actions/project';

const AssignPrivilege = (props) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [openPrivilegesModal, setOpenPrivilegesModal] = useState(false);
  const [memberPrivileges, setMemberPrivileges] = useState([]);
  // let memberPrivileges = []
  const [activeMember, setActiveMember] = useState(null);
  const [checkBoxes, setCheckBoxes] = useState([
    { privilege_id: '', name: '', checked: 'false' },
  ]);

  const { type, contacts, data } = props;
  //console.log('Assign Attribute props', props)
  //Initialize the memberPrivileges object on Fetch.
  //Empty array as dependency makes it function as componentDidMount()

  useEffect(() => {
    let memPrivilegeArray = [];
    //replace with promise when fetching from API
    // dispatch(ProjectsApi.getProjectPrivileges())
    contacts.forEach((c) => {
      memPrivilegeArray.push({
        member_id: c.member_id,
        privileges: c.privileges,
        project_member_id: c.project_member_id,
      });
    });
    console.log('mmm', memPrivilegeArray);
    setMemberPrivileges(memPrivilegeArray);
    populateCheckboxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateCheckboxes = (
    member_id = null,
    memPrivileges = memberPrivileges
  ) => {
    const privilegeArray = data;
    let checkBoxArray;
    if ((activeMember || member_id) === null) {
      checkBoxArray = privilegeArray.map((privilege) => {
        return { attr_id: privilege.id, name: privilege.name, checked: false };
      });
    } else {
      const member = memPrivileges.find((m) => m.member_id === member_id);
      checkBoxArray = privilegeArray.map((privilege) => {
        const isPrivilegeSet = member?.privileges.includes(privilege.id);
        return {
          attr_id: privilege.id,
          name: privilege.name,
          checked: isPrivilegeSet,
        };
      });
    }
    setCheckBoxes(checkBoxArray);
  };

  const handleAddPrivilege = (contact_id) => {
    setActiveMember(contact_id);
    setOpenPrivilegesModal(true);
    populateCheckboxes(contact_id);
  };

  const handlePrivilegeSelect = (e) => {
    const memPrivilegesArray = memberPrivileges.map((member) => {
      if (member.member_id === activeMember) {
        const privilege = e.target.value;
        const checked = e.target.checked;
        let privileges = [...member.privileges];
        if (privileges.indexOf(privilege) < 0) {
          if (checked === true) {
            privileges.push(privilege);
          }
        }
        if (privileges.indexOf(privilege) > -1) {
          if (checked === false) {
            const filterPrivileges = privileges.filter(
              (item) => item !== privilege
            );
            privileges = filterPrivileges;
          }
        }
        return { ...member, privileges: privileges };
      } else {
        return member;
      }
    });
    console.log('memberprivileges', memPrivilegesArray);
    setMemberPrivileges(memPrivilegesArray);
    populateCheckboxes(activeMember, memPrivilegesArray);
  };

  const handlePrivilegeModalClose = () => {
    setOpenPrivilegesModal(false);
  };

  const handlePrivilegeApply = (e) => {
    //find active member and POST privileges to API
    const payload = {
      project_id: +projectId,
      privileges_data: [
        ...memberPrivileges.map((mr) => ({
          project_member_id: mr.project_member_id,
          privileges: mr.privileges,
        })),
      ],
    };
    dispatch(ProjectsApi.postProjectMemberPrivileges(payload));
    setOpenPrivilegesModal(false);
  };

  const ApplyPrivileges = () => {
    return (
      <div>
        <Button
          onClick={handlePrivilegeApply}
          variant="contained"
          color="primary"
        >
          Apply {type}s
        </Button>
      </div>
    );
  };

  const ModalContent = () => (
    <AttributeSelectionGroup
      checkBoxes={checkBoxes}
      handleChange={handlePrivilegeSelect}
    />
  );

  return (
    <div>
      <AmeraModal
        open={openPrivilegesModal}
        onClose={handlePrivilegeModalClose}
        ModalContent={ModalContent}
        ModalAction={ApplyPrivileges}
        title={`Select ${type}s`}
        maxWidth="sm"
      />

      <Grid
        container
        className="contact-container"
        style={{ marginTop: '40px' }}
      >
        {contacts.map((contact, index) => {
          // *** remove it later when contact status property available
          return (
            <Grid
              item
              md={6}
              sm={12}
              lg={4}
              key={index}
              className="contact-card"
            >
              <Card>
                <div className="custom-card_header">
                  <MaterialAvatar
                    classes="contact-avatar"
                    member_id={contact.contact_member_id}
                    src={contact.amera_avatar_url}
                    firstName={contact.first_name}
                    lastName={contact.last_name}
                  />
                  <div className="primary-title">
                    <div className="title" style={{ width: '100%' }}>
                      {displayableFullName(contact)}
                    </div>
                    <div className="primary-header">{contact.company}</div>
                    <div className="second-header">{contact.title}</div>
                  </div>
                </div>
                <CardContent className={styles.assignRoleBlock}>
                  <div className={styles.topRow}>
                    <h5>Project {type}s</h5>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddPrivilege(contact.member_id)}
                    >
                      Add {type}
                    </Button>
                  </div>
                  <div className={styles.rolesGrid}>
                    <AttributePills
                      memberRoles={memberPrivileges}
                      member_id={contact.member_id}
                      type={type}
                      data={data}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default AssignPrivilege;
