import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import clsx from 'clsx';
import MilestoneModal from './MilestoneModal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '36px',
  },
  header: {
    padding: '3px 0px 22px 1px',
    borderBottom: '1px solid #D5D5D5',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000',
  },
  row: {
    height: '67px',
    borderBottom: '1px solid #D5D5D5',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000',
  },
  projectCol: {
    width: '12%',
  },
  progressCol: {
    width: '37%',
  },
  paidThisWeekCol: {
    width: '10%',
  },
  totalPaidCol: {
    width: '10%',
  },
  totalCostCol: {
    width: '10%',
  },
  pendingCol: {
    width: '10%',
  },
  actionCol: {
    width: '11%',
    '& .MuiButtonBase-root': {
      textTransform: 'none',
      borderRadius: '8px',
      fontSize: '11px',
      fontWeight: 'bold',
    },
  },
  projectName: {
    fontWeight: 'normal',
    color: '#94C03D',
    textDecoration: 'underline',
  },
  blank: {
    width: '39%',
  },
  total: {
    width: '10%',
    background: '#f3f8e9',
    alignItems: 'center',
    height: '51px',
  },
  totalLabel: {
    justifyContent: 'center',
  },
  totalPending: {
    width: '10%',
    height: '51px',
    alignItems: 'center',
    background: '#ebf0e1',
  },
  totalRow: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#000000',
    '& .MuiGrid-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const FixedSummary = (props) => {
  const { fixed } = props;
  const [showMilestoneModal, setShowMilestoneModal] = React.useState(false);
  const [milestoneProject, setMilestoneProject] = React.useState([]);
  const classes = useStyles();

  const openMilestoneModal = (project) => {
    setShowMilestoneModal(true);
    setMilestoneProject(project);
  };
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={clsx(classes.header)}
          direction="row"
        >
          <Grid item className={classes.projectCol}>
            Project
          </Grid>
          <Grid item className={classes.progressCol}>
            Progress
          </Grid>
          <Grid item className={classes.paidThisWeekCol}>
            Paid this Week
          </Grid>
          <Grid item className={classes.totalPaidCol}>
            Total Paid
          </Grid>
          <Grid item className={classes.totalCostCol}>
            Total Cost
          </Grid>
          <Grid item className={classes.pendingCol}>
            Pending
          </Grid>
          <Grid item className={classes.actionCol}></Grid>
        </Grid>
        {fixed.projects &&
          fixed.projects.length > 0 &&
          fixed.projects.map((project) => (
            <Grid
              container
              alignItems="center"
              className={classes.row}
              key={project.id}
            >
              <Grid item className={classes.projectCol}>
                <span className={classes.projectName}>{project.name}</span>
              </Grid>
              <Grid item className={classes.progressCol}>
                {project.milestone_done}/{project.milestone} milestones
                completed (
                {Math.floor((project.milestone_done / project.milestone) * 100)}
                %)
              </Grid>
              <Grid item className={classes.paidThisWeekCol}>
                ${project.paid_this_week}
              </Grid>
              <Grid item className={classes.totalPaidCol}>
                ${project.total_paid}
              </Grid>
              <Grid item className={classes.totalCostCol}>
                ${project.total_cost}
              </Grid>
              <Grid item className={classes.pendingCol}>
                ${project.pending}
              </Grid>
              <Grid item className={classes.actionCol}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    openMilestoneModal(project);
                  }}
                >
                  Make a payment
                </Button>
              </Grid>
            </Grid>
          ))}
        <Grid
          container
          item
          xs={12}
          className={classes.totalRow}
          direction="row"
          alignItems="center"
        >
          <Grid item className={classes.blank}></Grid>
          <Grid item className={clsx(classes.total, classes.totalLabel)}>
            Total
          </Grid>
          <Grid item className={classes.total}>
            ${fixed.total_paid_this_week}
          </Grid>
          <Grid item className={classes.total}>
            ${fixed.total_paid}
          </Grid>
          <Grid item className={classes.total}>
            ${fixed.total_cost}
          </Grid>
          <Grid item className={classes.totalPending}>
            ${fixed.total_pending}
          </Grid>
        </Grid>
      </Grid>
      <MilestoneModal
        project={milestoneProject}
        onClose={() => {
          setShowMilestoneModal(false);
        }}
        open={showMilestoneModal}
      />
    </div>
  );
};

export default FixedSummary;
