/* global APP */
import React from 'react';
import { Dropdown } from 'react-bootstrap';

const ProfileItem = (props) => (
  <Dropdown.Item eventKey={props.eveneKey} image={props.image}>
    <img
      src={APP.vacall.localParticipant._avatar_url}
      alt="amera"
      className="image-rounded"
    />
    <span>{APP.vacall.localParticipant._displayName}</span>
  </Dropdown.Item>
);

export default ProfileItem;
