import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '../../components/Modal/modal';
import * as api from '../../config/api';
import githubImage from '../../assets/img/GitHub-Mark-120px-plus.png';
import { O365Image } from '../../assets/svg';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '27%',
  },
  dateFormats: {
    width: '150px',
    padding: theme.spacing(1),
  },
  socialIcon: {
    maxWidth: 21,
    marginRight: 20,
  },
  socialButton: {
    marginBottom: 20,
  },
}));

const Integrations = (props) => {
  const { open, onClose } = props;

  const classes = useStyles();
  const title = 'Integrations';
  const apiUrl = api.apiUrl;

  const goToGithub = () => {
    const url1 = `${apiUrl}/github/oauth/login`;
    window.location.href = url1;
  };
  const goToO365 = () => {
    const url1 = `${apiUrl}/o365/oauth/login`;
    window.location.href = url1;
  };
  // const goToTrello = () => {
  //   const url1 = `${apiUrl}/trello/oauth/login`;
  //   window.location.href = url1;
  // };

  const ModalContent = () => (
    <>
      <div className="profile_page-body">
        <div className="profile_page-settings text-center">
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.socialButton}
            onClick={() => goToGithub()}
          >
            <img
              className={classes.socialIcon}
              src={githubImage}
              alt="github logo"
            />{' '}
            Sign in with Github
          </Button>
          <br />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.socialButton}
            onClick={() => goToO365()}
          >
            <img
              className={classes.socialIcon}
              src={O365Image}
              alt="o365 logo"
            />{' '}
            Sign in with O365
          </Button>
          <br />
          {/* <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.socialButton}
            onClick={() => goToTrello()}
          >
            <img
              className={classes.socialIcon}
              src="https://png.pngitem.com/pimgs/s/178-1787661_icon-hd-png-download.png"
              alt="trello logo"
            />{' '}
            Sign in with Trello
          </Button> */}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        ModalContent={ModalContent}
        className="profile-modal"
      ></Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Integrations);
