import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '../../components/Forum/Toolbar';
import TopicCard from '../../components/Forum/TopicCard';
import { getTopics } from '../../redux/actions/forum';
import useToolbar from '../../components/Forum/useToolbar';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listWrapper: {
    paddingRight: '64px',
  },
}));

const TopicList = (props) => {
  const classes = useStyles();

  const {
    searchKey,
    handleSearch,
    searchMemberKey,
    handleSearchMemberKey,
    selectedMembers,
    handleSelectMember,
    sortDir,
    handleSortDir,
  } = useToolbar();

  const dispatch = useDispatch();
  const topics = useSelector((state) => state.forum.topics);
  const { group_id } = useParams();

  const debouncedGetTopics = useCallback(
    debounce(
      (group_id, searchKey, selectedMembers, sortDir) =>
        dispatch(getTopics(group_id, searchKey, selectedMembers, sortDir)),
      500
    ),
    []
  );

  React.useEffect(() => {
    debouncedGetTopics(group_id, searchKey, selectedMembers, sortDir);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id, searchKey, selectedMembers, sortDir]);

  return (
    <div className={classes.root}>
      <Toolbar
        handleSearch={handleSearch}
        searchKey={searchKey}
        searchMemberKey={searchMemberKey}
        handleSearchMemberKey={handleSearchMemberKey}
        selectedMembers={selectedMembers}
        handleSelectMember={handleSelectMember}
        sortDir={sortDir}
        handleSortDir={handleSortDir}
      />
      <div className={classes.listWrapper}>
        {topics &&
          topics.length > 0 &&
          topics.map((topic) => (
            <TopicCard topic={topic} key={topic.topic_id} />
          ))}
      </div>
    </div>
  );
};

export default TopicList;
