import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { getGroupCalendarActivity } from '../../redux/actions/group';
import ContactPreview from '../../components/ContactPreview';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  activityWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  contactPreviewWrapper: {
    width: '285px',
  },
  action: {
    width: '150px',
    fontSize: '1.2em',
  },
  createDate: {
    marginRight: theme.spacing(3),
  },
}));

const CalendarActivity = (props) => {
  const { group_id } = props;
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const dispatch = useDispatch();
  const { groupData, groupLeaderData, groupCalendarActivity } = useSelector(
    (state) => ({
      groupData: state.group.groupData,
      groupLeaderData: state.group.groupLeaderData,
      groupCalendarActivity: state.group.groupCalendarActivity,
    })
  );

  useEffect(() => {
    if (group_id) dispatch(getGroupCalendarActivity(group_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      {groupCalendarActivity &&
        groupCalendarActivity.activities.length > 0 &&
        groupCalendarActivity.activities
          .slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage
          )
          .map((activity) => {
            let contactData =
              activity.member_id === groupData.group_leader_id
                ? groupLeaderData
                : groupData.members.find(
                    (member) => member.member_id === activity.member_id
                  );
            let actionLabel = '';
            let objectName = JSON.parse(activity.request_params.event_data)
              .name;
            switch (activity.event_type) {
              case 'create_event':
                actionLabel = 'Created';
                break;
              case 'edit_event':
                actionLabel = 'Updated';
                break;
              case 'delete_event':
                actionLabel = 'Deleted';
                break;
              default:
                break;
            }

            let eventType = 'Event';
            // TODO: Disable this as API should return proper metadata for this activity
            if (activity.request_params && activity.request_params.event_data) {
              const eventData = JSON.parse(activity.request_params.event_data);
              eventType = eventData.type ? eventData.type : eventType;
            }

            return (
              <div
                key={activity.create_date}
                className={classes.activityWrapper}
              >
                <div className={classes.createDate}>
                  {format(new Date(activity.create_date), 'MM/dd/yyyy HH:MM')}
                </div>
                <div className={classes.contactPreviewWrapper}>
                  <ContactPreview
                    contactData={contactData}
                    isShowAvatar={true}
                  />
                </div>
                <div className={classes.action}>
                  {actionLabel} {eventType}
                </div>
                <div>{objectName}</div>
              </div>
            );
          })}
      {groupCalendarActivity && groupCalendarActivity.activities.length > 25 && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(
              Math.ceil(groupCalendarActivity.activities.length / rowsPerPage)
            )}
            page={page}
            onChange={handleChangePage}
            defaultPage={1}
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </div>
  );
};

export default CalendarActivity;
