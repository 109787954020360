import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
  },
  details: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
    flexDirection: 'column',
  },
}));

const useAccrodionStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
}));

const useSummaryStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    // margin: `${theme.spacing(2)}px 0px`,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const AmeraAccordion = (props) => {
  const {
    label,
    children,
    button = null,
    isLoading = false,
    isElevated = false,
    isDefaultExpanded = true,
  } = props;
  const classes = useStyles();
  const accordionClasses = useAccrodionStyles();
  const summaryStyles = useSummaryStyles();
  return (
    <div className={classes.root}>
      <Accordion
        defaultExpanded={isDefaultExpanded}
        classes={isElevated ? '' : accordionClasses}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownCircleOutlinedIcon color="secondary" />}
          aria-controls={`${label}-content`}
          classes={summaryStyles}
          id={label}
        >
          <Typography className={classes.heading}>{label}</Typography>
          {button}
        </AccordionSummary>
        {isLoading && <LinearProgress />}
        <AccordionDetails className={classes.details}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AmeraAccordion;
