import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const PhoneTypeSelector = (props) => {
  const { phoneType, disabled, handleChange } = props;
  return (
    <FormControl className="phone-type-selector">
      <InputLabel id="phone-type-input-label">Phone type</InputLabel>
      <Select
        labelId="Phone type"
        // id="phone-type-select"
        name="phoneType"
        disabled={disabled}
        value={phoneType}
        onChange={handleChange}
      >
        <MenuItem key="cell" value="cell">
          Cell phone
        </MenuItem>
        <MenuItem key="landline" value="landline">
          Landline
        </MenuItem>
        <MenuItem key="TDD" value="TDD">
          TDD
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default PhoneTypeSelector;
