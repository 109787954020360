import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '10rem',
    width: '100%',
  },
}));

const NoOptionsFallback = (props) => {
  const { totalProjects, onCreateClick, onClearFilterClick } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      direction="column"
      wrap="wrap"
      alignContent="center"
      classes={classes}
    >
      {totalProjects === 0 ? (
        <>
          <Typography variant="body2">No projects created yet</Typography>
          <Button id="fallback-create-project" onClick={onCreateClick}>
            Create
          </Button>
        </>
      ) : (
        <>
          <Typography variant="body2">
            No projects match entered criteria
          </Typography>
          <Button id="fallback-create-project" onClick={onClearFilterClick}>
            Reset filter
          </Button>
        </>
      )}
    </Grid>
  );
};

export default NoOptionsFallback;
