import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RecurrenceSelect from './RecurrenceSelect';
import RecurrenceEnd from './RecurrenceEnd';
import WeekdaysToggleGroup from './WeekdaysToggleGroup';
import { add } from 'date-fns';
import { RECURRENCE_FREQ } from '../../../utils/calendar';

const RecurrenceDialog = (props) => {
  const {
    isOpen,
    start,
    handleClose,
    recurrenceOption,
    handleOptionChange,
    endOption,
    handleChangeMode,
    repeatTimes,
    handleChangeRepeatTimes,
    endDate,
    handleChangeEndDate,
    weekDays,
    handleWeekdayClick,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      // style={{ width: '40rem' }}
      fullWidth
    >
      <DialogTitle id="recurrence">Recurrence options</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select options for event recurrence
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RecurrenceSelect
              value={recurrenceOption}
              handleChange={handleOptionChange}
            />
          </Grid>
          <Grid item xs={12}>
            {recurrenceOption === RECURRENCE_FREQ.weekdays && (
              <WeekdaysToggleGroup
                weekDays={weekDays}
                handleClick={handleWeekdayClick}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {recurrenceOption !== RECURRENCE_FREQ.none && (
              <RecurrenceEnd
                earliest={add(start, { days: 1 })} //recurrence end date cant be past
                mode={endOption}
                handleChangeMode={handleChangeMode}
                repeatTimes={repeatTimes}
                handleChangeRepeatTimes={handleChangeRepeatTimes}
                endDate={endDate}
                handleChangeEndDate={handleChangeEndDate}
              />
            )}
          </Grid>
        </Grid>

        {/* <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurrenceDialog;
