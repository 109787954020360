import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
// import Sidebar, { messages as sidebarMessages } from '../Sidebar';
import SubMenu from '../Sidebar/SubMenu';
import navItems from '../Sidebar/navItems';
import Footer from '../Footer';
import AmeraDrawer from '../AmeraDrawer';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH, DRAWER_VARIANTS } from '../../../utils/drawer';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: DRAWER_WIDTH,
  },
}));

const Content = (props) => {
  const {
    location: { pathname },
    children,
  } = props;
  const [activeNav, setActiveNav] = useState(0);
  const [activeMenu, setActiveMenu] = useState(0);
  const classes = useStyles();
  const isDrawerOpen = useSelector((s) => s.drawer.openDrawer);
  const drawerVariant = useSelector((s) => s.drawer.variant);

  useEffect(() => {
    const navItemIndex = navItems.findIndex((item) =>
      pathname.includes(item.to)
    );
    if (navItemIndex >= 0) {
      setActiveNav(navItemIndex);
    } else {
      setActiveNav(0);
    }
  }, [pathname]);

  let sectionClass = 'content';
  if (pathname === '/dashboard') {
    sectionClass = 'content-dashboard';
  } else if (pathname.includes('/call/video')) {
    sectionClass = 'content video-call';
  }

  return (
    <section className="main-content">
      <ErrorBoundary>
        <div
          className={clsx(classes.content, {
            [classes.contentShift]:
              isDrawerOpen && drawerVariant === DRAWER_VARIANTS.persistent,
          })}
        >
          <div className="page_content">
            <SubMenu
              menuItems={navItems[activeNav].menuItems}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              id={navItems[activeNav].id}
              btnLabel={navItems[activeNav].btnLabel}
              secondBtnLabel={navItems[activeNav].secondBtnLabel}
              filterItems={navItems[activeNav].filterItems}
              hasCount={navItems[activeNav].hasCount ? true : false}
              path={pathname}
            />
            <section className={sectionClass}>{children}</section>
          </div>
          <Footer />
        </div>
        <AmeraDrawer />
      </ErrorBoundary>
    </section>
  );
};

export default Content;
