import { useState, useEffect } from 'react';
import { compareObjects } from '../utils/email';

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!compareObjects(value, debouncedValue)) {
        setDebouncedValue(value);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [value]);

  return debouncedValue;
};
