/* global APP  */
import React, { Component } from 'react';
import UIEvents from '../service/UI/UIEvents';
import { Modal, Dropdown, Button } from 'react-bootstrap';
import { IconOpenInNew } from '../icons';
import { injectIntl } from 'react-intl';
/**
 * The type of the React {@code Component} props of
 * {@link KeyboardShortcutsDialog}.
 */
type Props = {
  /**
   * A Map with keyboard keys as keys and translation keys as values.
   */
  shortcutDescriptions: Object,

  /**
   * Invoked to obtain translated strings.
   */
  t: Function,
};

/**
 * Implements a React {@link Component} which displays a dialog describing
 * registered keyboard shortcuts.
 *
 * @extends Component
 */
class ViewShortcuts extends Component<Props> {
  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  constructor() {
    super();
    this.state = {
      isShortcutDialogOpen: false,
      Shortcutkeys: null,
    };
  }

  componentDidMount() {
    APP.UI.addListener(UIEvents.OPEN_SHORTCUT, (keybarr) => {
      this.setState({ Shortcutkeys: keybarr });
      if (this.state.isShortcutDialogOpen) {
        this.setState({ isShortcutDialogOpen: false });
      } else {
        this.setState({ isShortcutDialogOpen: true });
      }
    });
  }

  showShortCuts = () => {
    APP.keyboardshortcut.openDialog();
    this.setState({ isShortcutDialogOpen: true });
  };
  handleClose = () => {
    this.setState({ isShortcutDialogOpen: false });
  };
  render() {
    const shortcuts = this.state.Shortcutkeys
      ? Array.from(this.state.Shortcutkeys).map((description) =>
          this._renderShortcutsListItem(...description)
        )
      : '';

    const { intl } = this.props;
    const messages = {
      vshort: {
        id: 'app.home.vshort',
        defaultMessage: 'View shortcuts',
      },
      close: {
        id: 'app.home.close',
        defaultMessage: 'Close',
      },
      kshort: {
        id: 'app.home.kshort',
        defaultMessage: 'Keyboard Shortcuts',
      },
    };
    return (
      <React.Fragment>
        <Dropdown.Item
          eventKey={this.props.eveneKey}
          onClick={this.showShortCuts}
        >
          <div className="amera-icon">
            <IconOpenInNew />
          </div>
          <span>{intl.formatMessage(messages.vshort)}</span>
        </Dropdown.Item>

        <div className="container-dialog shortcutKeyboard">
          <Modal
            show={this.state.isShortcutDialogOpen}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{intl.formatMessage(messages.kshort)}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="shortcutkeyboard">
              <div id="keyboard-shortcuts">
                <ul className="shortcuts-list" id="keyboard-shortcuts-list">
                  {shortcuts}
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                {intl.formatMessage(messages.close)}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Creates a {@code ReactElement} for describing a single keyboard shortcut.
   *
   * @param {string} keyboardKey - The keyboard key that triggers an action.
   * @param {string} translationKey - A description of what the action does.
   * @private
   * @returns {ReactElement}
   */
  _renderShortcutsListItem(keyboardKey, translationKey) {
    return (
      <li className="shortcuts-list__item" key={keyboardKey}>
        <span className="shortcuts-list__description">{translationKey}</span>
        <span className="item-action">
          {/* <Lozenge isBold = { true }> */}
          {keyboardKey}
          {/* </Lozenge> */}
        </span>
      </li>
    );
  }
}

//export default ViewShortcuts;
export default injectIntl(ViewShortcuts);
