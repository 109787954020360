import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../../utils/drawer';

const drawerProfileStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: DRAWER_WIDTH,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },

    userSelect: 'none',
  },
  iconButtonCheck: {
    marginTop: theme.spacing(1),
    textTransform: 'capitalize',
  },
  iconButtonEdit: {
    marginTop: theme.spacing(-0.5),
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
  body: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  mainInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  companyName: {
    color: theme.palette.primary.main,
  },
  iconButtonsContainer: {
    display: 'flex',
    padding: `${theme.spacing(2)}px 0px`,
    flexDirection: 'row',
    '& div': {
      flexBasis: '25%',
      flexGrow: 0,
    },
  },
  bio: {
    textAlign: 'justify',
  },
  alignRight: {
    textAlign: 'right',
  },
  chipArray: { width: '100%' },
  cardArray: {
    width: '100%',
  },
  chip: {
    backgroundColor: theme.palette.secondary.light,
    fontWeight: theme.typography.fontWeightBold,
    margin: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    fontSize: 10,
  },
  emptyFallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2rem',
  },
  achievement: {
    marginBottom: theme.spacing(1),
    fontSize: 13,
  },
  noTopPadding: {
    paddingTop: '0px !important',
  },
  editableItem: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: `${theme.spacing(1)}px 0px`,
  },
  editableContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  selectedChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.contrastText,
    },
    '&:focus': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.contrastText,
    },
  },
  margin: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  membersContainer: {
    maxHeight: theme.spacing(40),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-thumb': {
      margin: `${theme.spacing(1)}px 0px`,
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.light,
    },
  },
  accordionEditButton: {
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    marginLeft: 'auto',
  },
  editPicButton: {
    marginTop: theme.spacing(1),
    textTransform: 'none',
    left: '70%',
    fontSize: theme.typography.caption.fontSize,
    // borderRadius: theme.spacing(2),
  },
  memberRow: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '8px 0',
  },
  clearButton: {
    padding: 4,
  },
}));

export default drawerProfileStyles;
