import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import detectBrowserLanguage from 'detect-browser-language';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, CircularProgress, makeStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './App';
import theme from './jss/theme';

import { loadAppLanguage, isAppLoadingOrLogin } from './redux/actions/app';
import {} from './configureStore';
import {
  getMemberContactChatArchive,
  getMetadata,
  getSession,
} from './redux/actions/member';

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'fixed',
    bottom: '50vh',
    left: '50vw',
  },
}));

const AppWrapper = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    appStart,
    // getSession,
    loadingSession,
    loginAttempt,
    loginCompleted,
    redirectingToDashboard,
  } = props;
  const { appState } = props.app;

  const [state] = useState({
    browserLanguage: detectBrowserLanguage(),
    countryCode: '', // countryCode is not matched to a language of browser so there will be another approach
  });

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }

  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&v=3.exp&language=en&libraries=geometry,drawing,places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
  }

  const [languageLoaded, setLanguageLoaded] = useState(false);

  useEffect(() => {
    appStart().then(() => {
      setLanguageLoaded(true);
    });
  }, [appStart]);

  useEffect(() => {
    if (loginCompleted === true) {
      dispatch(getSession());
    }
  }, [dispatch, loginCompleted]);
  useEffect(() => {
    if (loginCompleted === true) {
      dispatch(getMetadata());
    }
  }, [dispatch, loginCompleted]);
  const connectionStatus = useSelector((state) => state.chat.connectionStatus);
  useEffect(() => {
    if (loginCompleted === true) {
      dispatch(getMemberContactChatArchive(connectionStatus));
    }
  }, [dispatch, loginCompleted, connectionStatus]);

  const locale =
    props.global.locale || state.browserLanguage || state.countryCode;

  const showLoader = useMemo(
    isAppLoadingOrLogin(
      appState,
      languageLoaded,
      loadingSession,
      loginAttempt,
      loginCompleted,
      redirectingToDashboard
    ),
    [
      appState,
      languageLoaded,
      loadingSession,
      loginAttempt,
      loginCompleted,
      redirectingToDashboard,
    ]
  );
  console.debug(`ShowLoader: ${showLoader}`);

  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {showLoader ? (
          <CircularProgress
            variant="indeterminate"
            className={classes.loading}
            size={40}
            thickness={4}
            value={60}
          />
        ) : (
          <IntlProvider
            locale={locale}
            key={locale}
            messages={props.app.translations && props.app.translations[locale]}
          >
            <App />
          </IntlProvider>
        )}
      </ThemeProvider>
    </DndProvider>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  global: state.global,
  loadingSession: state.member.loadingSession,
  loginAttempt: state.member.loginAttempt,
  loginCompleted: state.member.loginCompleted,
  redirectingToDashboard: state.member.redirectingToDashboard,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ appStart: loadAppLanguage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
