import React from 'react';
import Chip from '@material-ui/core/Chip';
import StarIcon from '@material-ui/icons/Star';

const GroupItem = (props) => {
  const { groupInfo, isLeader } = props;
  const { group_name } = groupInfo;
  return (
    <Chip
      color="secondary"
      icon={isLeader ? <StarIcon /> : null}
      label={group_name}
      variant="outlined"
    />
  );
};

export default GroupItem;
