import { makeStyles } from '@material-ui/core/styles';
import {
  dark,
  textDark,
  textPurple,
  light,
  textGrey,
} from '../../../../../jss/colors';

export const useEmailDetailsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: theme.spacing(1),
    borderBottom: '1px solid #8DA3C5',
  },
  fromTo: {
    textTransform: 'capitalize',
    color: textPurple,
    fontWeight: 'bold',
    fontSize: 18,
    lineSpacing: 12,
  },
  composeTime: {
    fontSize: '1.1rem',
    fontFamily: 'Roboto',
    color: textDark,
    height: '100%',
    marginLeft: 'auto',
    order: 2,
  },
  to: {
    color: dark,
  },
  avatarArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    maxHeight: 80,
  },
  fromTime: {
    display: 'flex',
  },
  headerArea: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginLeft: 10,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#2E2A60',
  },
  customAvatarGroup: {
    '& .MuiAvatarGroup-avatar': {
      height: '5rem',
      width: '3.3rem',
      borderRadius: '12.5px',
      boxShadow: '1px 1px 9px 1px #c2c3c7',
      border: '3px solid white',
      textAlign: 'right',
    },
  },
  detailsWrapper: {
    display: 'block',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  contentScroll: {
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      'background-color': light,
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      color: 'white',
      'background-color': 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      'background-color': textGrey,
    },
    paddingLeft: '5px',
  },
  replyContainer: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
  },
  mailBodyMain: {
    minHeight: '100px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 5,
    marginRight: 5,
    padding: 5,
    wordBreak: 'break-word',
  },
}));
