import React from 'react';

const AddContactIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.944"
    height="26.723"
    // viewBox="0 0 27.944 19.723"

    // width="45.96"
    // height="45.96"
    viewBox="0 0 45.96 45.96"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-667.02 -9.02)">
      <g id="Group_25" data-name="Group 25">
        <g id="mask-2">
          <g id="Group_24" data-name="Group 24">
            <path
              id="path-1"
              d="M685.621,43.2A14.6,14.6,0,0,1,694.1,30.079,7.07,7.07,0,0,0,695.55,27c.6-.043,1.539-.573,1.816-2.661a1.877,1.877,0,0,0-.8-1.95,8.366,8.366,0,0,0,.893-3.6c0-3.953-1.612-7.325-5.073-7.325,0,0-1.2-2.442-5.073-2.442-7.173,0-10.146,4.429-10.146,9.767a9.417,9.417,0,0,0,.893,3.6,1.877,1.877,0,0,0-.8,1.95c.277,2.088,1.218,2.618,1.816,2.661a8.489,8.489,0,0,0,3.166,4.806v3.255c-1.691,4.884-15.219,1.628-15.219,13.023h19.485A14.093,14.093,0,0,1,685.621,43.2ZM701.273,32A11.493,11.493,0,1,0,712.98,43.49,11.6,11.6,0,0,0,701.273,32Zm5.789,13.542H703.55v3.447a1.756,1.756,0,0,1-3.512,0V45.541h-3.512a1.724,1.724,0,1,1,0-3.447h3.512V38.647a1.756,1.756,0,0,1,3.512,0v3.447h3.512a1.724,1.724,0,1,1,0,3.447Z"
            />
          </g>
        </g>
        <path
          id="Path_33"
          data-name="Path 33"
          d="M694.1,30.079A7.07,7.07,0,0,0,695.55,27c.6-.044,1.539-.573,1.816-2.661a1.877,1.877,0,0,0-.8-1.95,8.366,8.366,0,0,0,.893-3.6c0-3.953-1.612-7.325-5.073-7.325,0,0-1.2-2.442-5.073-2.442-7.174,0-10.146,4.429-10.146,9.767a9.417,9.417,0,0,0,.893,3.6,1.877,1.877,0,0,0-.8,1.95c.277,2.088,1.218,2.617,1.816,2.661a8.484,8.484,0,0,0,3.166,4.806v3.255c-1.691,4.884-15.219,1.628-15.219,13.023h19.485a14.093,14.093,0,0,1-.884-4.883A14.6,14.6,0,0,1,694.1,30.079Z"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M701.273,32A11.493,11.493,0,1,0,712.98,43.49,11.6,11.6,0,0,0,701.273,32Zm5.789,13.542H703.55v3.447a1.756,1.756,0,0,1-3.512,0V45.541h-3.512a1.724,1.724,0,1,1,0-3.447h3.512V38.647a1.756,1.756,0,0,1,3.512,0v3.447h3.512a1.724,1.724,0,1,1,0,3.447Z"
        />
      </g>
    </g>
  </svg>
);

export default AddContactIcon;
