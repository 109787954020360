import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Grid } from '@material-ui/core';
import MaterialAvatar from '../MaterialAvatar';
import { format, addDays } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiAccordionSummary-root': {
      backgroundColor: '#f6f6f6',
      fontSize: '17px',
      fontWeight: 'bold',
      color: '#000000',
      paddingLeft: '32px',
      paddingRight: '32px',
    },
  },
  membersWrapper: {
    padding: '20px 28px 36px 28px',
    width: '100%',
  },
  headerMember: {
    paddingTop: '10px',
  },
  headerDay: {
    paddingTop: '2px',
  },
  headerResult: {
    paddingTop: '10px',
    justifyContent: 'center',
  },
  header: {
    height: '56px !important',
    '& .MuiGrid-item': {
      fontSize: '16px',
      fontWeight: 500,
      color: '#000000',
    },
  },
  row: {
    display: 'flex',
    height: '66px',
    borderBottom: '1px solid #D5D5D5',
    alignItems: 'center',
    '& .MuiGrid-item': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  avatar: {
    width: '40px',
    heigh: '40px',
    marginRight: '21px',
  },
  memberCol: {
    display: 'flex',
    fontSize: '15px',
    padding: '10px 0px 10px 0px',
  },
  name: {
    fontWeight: 'bold',
  },
  role: {
    color: '#657683',
  },
  hours: {
    color: '#646464',
    fontSize: '16px',
  },
  memberTotalHours: {
    borderLeft: '1px solid #D5D5D5',
  },
  colResults: {
    color: '#333333',
    justifyContent: 'center',
    fontSize: '16px',
  },
  due: {
    backgroundColor: '#F7F7F7',
  },
  projectTotalResults: {
    height: '51px',
    backgroundColor: '#f3f8e9',
    color: '#333333',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  projectTotalDue: {
    backgroundColor: '#ecf1e2',
  },
}));

const dayOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const HourlySummary = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const { project, start } = props;
  const handleChange = (event) => {
    setExpanded((prev) => !prev);
  };

  const days = React.useMemo(() => {
    const tmp = [];
    for (let i = 0; i < 7; i++) {
      tmp.push(`${dayOfWeek[i]}/${format(addDays(start, i), 'd')}`);
    }
    return tmp;
  }, [start]);

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary>
          <span>{project.project_name}</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.membersWrapper}>
            <Grid container>
              <Grid item xs={12} className={clsx(classes.row, classes.header)}>
                <Grid item xs={2} className={classes.headerMember}>
                  Member
                </Grid>
                {days.map((day) => (
                  <Grid item xs={1} className={classes.headerDay} key={day}>
                    {day}
                  </Grid>
                ))}
                <Grid item xs={1} className={classes.headerResult}>
                  Hours
                </Grid>
                <Grid item xs={1} className={classes.headerResult}>
                  Rate
                </Grid>
                <Grid item xs={1} className={classes.headerResult}>
                  Due
                </Grid>
              </Grid>
              {project.members &&
                project.members.length &&
                project.members.map((member, index) => (
                  <Grid item xs={12} className={classes.row} key={index}>
                    <Grid item xs={2} className={classes.memberCol}>
                      <MaterialAvatar
                        member_id={member.member_id}
                        firstName={member.first_name}
                        lastName={member.last_name}
                        classes={classes.avatar}
                      />
                      <div>
                        <div className={classes.name}>
                          {member.first_name} {member.last_name}
                        </div>
                        <div className={classes.role}>{member.role}</div>
                      </div>
                    </Grid>
                    {member.hours.map((hour, index) => (
                      <Grid item xs={1} key={index} className={classes.hours}>
                        {Math.floor(hour / 60)}:{hour % 60}
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={1}
                      className={clsx(
                        classes.colResults,
                        classes.memberTotalHours
                      )}
                    >
                      {Math.floor(member.total_hours / 60)}:
                      {member.total_hours % 60}
                    </Grid>
                    <Grid item xs={1} className={classes.colResults}>
                      ${member.rate}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={clsx(classes.colResults, classes.due)}
                    >
                      ${member.due}
                    </Grid>
                  </Grid>
                ))}
              <Grid container item xs={12} direction="row">
                <Grid item xs={8}></Grid>
                <Grid
                  container
                  item
                  xs={4}
                  className={classes.projectTotalResults}
                  direction="row"
                >
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justify="center"
                  >
                    Total
                  </Grid>
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justify="center"
                  >
                    {Math.floor(project.total_hours / 60)}:
                    {`${
                      project.total_hours % 60 > 10
                        ? project.total_hours % 60
                        : '0' + (project.total_hours % 60)
                    }`}
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid
                    container
                    item
                    xs={3}
                    alignItems="center"
                    justify="center"
                    className={classes.projectTotalDue}
                  >
                    ${project.total_due}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default HourlySummary;
