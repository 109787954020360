import React, { useState } from 'react';
import ImageDialog from '../../../ImageDialog';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

const EventCover = ({ imageUrl, height = 240, isLoading = false }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      onClick={() => {
        setOpen(true);
      }}
      wrap="nowrap"
      style={{
        height: height,
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <Grid item xs={12}>
        {isLoading && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        <img
          src={imageUrl}
          alt=""
          style={{
            width: '100%',
            // height: height,
            objectFit: 'cover',
            position: 'relative',
            marginBottom: 8,
          }}
        />
      </Grid>

      {isOpen && (
        <ImageDialog
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          imageUrl={imageUrl}
        />
      )}
    </Grid>
  );
};

export default EventCover;
