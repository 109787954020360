import React from 'react';
import Grid from '@material-ui/core/Grid';
import TodayIcon from '@material-ui/icons/Today';
import Typography from '@material-ui/core/Typography';
import { displayableFullName } from '../../../../utils/contact';
import { useSelector } from 'react-redux';

const HostInfo = ({ hostInfo }) => {
  const memberId = useSelector((state) => state.member.member.member_id);

  let line;

  if (hostInfo.host_member_id === memberId) {
    line = 'me';
  } else {
    line = displayableFullName(hostInfo);
  }

  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <TodayIcon />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1">{`Scheduled by ${line}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default HostInfo;
