import { includes, startsWith, toLower } from 'lodash';
export const filterProjects = (projects, filter) => {
  const { types, companies, input, statuses } = filter;
  if (!!projects && projects?.length > 0) {
    return projects
      .filter((p) =>
        companies?.length > 0 ? includes(companies, p.company.company_id) : true
      )
      .filter((p) =>
        types?.length > 0 ? includes(types, p.project_type) : true
      )
      .filter((p) => {
        if (!statuses.length) {
          return true;
        } else {
          if (statuses.includes('complete')) {
            return p.active_tasks === p.completed_tasks && p.active_tasks > 0;
          }

          if (statuses.includes('in progress')) {
            return (
              p.active_tasks !== p.completed_tasks ||
              p.in_progress_tasks > 0 ||
              !p.active_tasks
            );
          }
        }

        return true;
      })
      .filter((p) =>
        input?.length > 0
          ? startsWith(toLower(p.project_title), toLower(input))
          : true
      );
  } else {
    return [];
  }
};
// Convert flat data into tree object using parent ID relation
export const createDataTree = (dataset) => {
  const hashTable = Object.create(null);
  if (dataset && dataset.length > 0) {
    dataset.forEach(
      (flatData) =>
        (hashTable[flatData.project_element_id] = {
          ...flatData,
          childNodes: [],
        })
    );
    const dataTree = [];
    dataset.forEach((flatData) => {
      if (
        flatData.parent_id &&
        flatData.parent_id !== null &&
        flatData.parent_id !== ''
      ) {
        hashTable[flatData.parent_id] &&
          hashTable[flatData.parent_id].childNodes.push(
            hashTable[flatData.project_element_id]
          );
      } else {
        dataTree.push(hashTable[flatData.project_element_id]);
      }
    });
    return dataTree;
  }
};

export const PROJECT_TYPES = {
  marketing: 'marketing',
  design: 'design',
  software: 'software',
};

export const PROJECT_ELEMENTS = {
  epic: 'epic',
  tremor: 'tremor',
  story: 'story',
  task: 'task',
};

export const PROJECT_STATUS = {
  define: {
    key: 'define',
    displayValue: 'Define',
  },
  inProgress: {
    key: 'in progress',
    displayValue: 'In Progress',
  },
  complete: {
    key: 'complete',
    displayValue: 'Completed',
  },
  cancel: {
    key: 'cancel',
    displayValue: 'Canceled',
  },
  suspend: {
    key: 'suspend',
    displayValue: 'Suspended',
  },
  delete: {
    key: 'delete',
    displayValue: 'Deleted',
  },
};

export const PROJECT_ROLES = [
  { id: 1, name: 'Project Owner' },
  { id: 2, name: 'Team Lead' },
  { id: 3, name: 'Developer' },
  { id: 4, name: 'Designer' },
  { id: 5, name: 'Accountant' },
];

export const PROJECT_PRIVELEGES = [
  { id: 'approve', name: 'Can Approve Billing and Hours' },
  { id: 'create', name: 'Can Manage All' },
  { id: 'edit', name: 'Can Manage Own' },
  { id: 'view', name: 'Can View' },
  { id: 'report', name: 'Can view Reports' },
];

export const MEMBER_ROLES = [
  { id: 1, name: 'Advisor' },
  { id: 2, name: 'Client' },
  { id: 3, name: 'Contractor' },
  { id: 4, name: 'Co-worker' },
  { id: 5, name: 'Customer' },
  { id: 6, name: 'Friend' },
  { id: 7, name: 'Reseller' },
  { id: 8, name: 'Secretary' },
  { id: 9, name: 'Student' },
  { id: 10, name: 'Teacher' },
  { id: 11, name: 'Vendor' },
  { id: 12, name: 'Worker' },
];

export const PROJECT_PRIVILEGES = {
  approve: 'approve',
  create: 'create',
  view: 'view',
  edit: 'edit',
  reports: 'reports',
};

export const privilegeText = (type) => {
  switch (type) {
    case PROJECT_PRIVILEGES.approve:
      return 'Can approve Billing and Hours';
    case PROJECT_PRIVILEGES.create:
      return 'Can Create';
    case PROJECT_PRIVILEGES.view:
      return 'Can View';
    case PROJECT_PRIVILEGES.edit:
      return 'Can Edit';
    default:
      return 'Unnsupported privilege name';
  }
};

export const dummyProjects = [
  {
    id: 1,
    company_name: 'Amera IOT', // FIXME: switch to object when company sql is ready
    project_name: 'Project A',
    project_type: 'Type 1',
    owner: {
      member_id: 2,
      title: 'Manager',
      company: 'Amera',
      amera_avatar_url: '',
      first_name: 'John',
      last_name: 'Wayne',
    },
    duration: {
      start_date: 'date string',
      estimated_end_date: 'date string',
    },
    completed_tasks: 3,
    total_tasks: 10,
    tasks: [],
  },
  {
    id: 2,
    company_name: 'Amera IOT', // FIXME: switch to object when company sql is ready
    project_name: 'Project cool',
    project_type: 'Type 2',
    owner: {
      member_id: 2,
      title: 'Manager',
      company: 'Amera',
      amera_avatar_url: '',
      first_name: 'John',
      last_name: 'Wayne',
    },
    duration: {
      start_date: 'date string',
      estimated_end_date: 'date string',
    },
    completed_tasks: 10,
    total_tasks: 10,
    tasks: [],
  },
];

export const dummyContacts = [
  {
    id: 1,
    contact_member_id: 3,
    first_name: 'adrian',
    middle_name: null,
    last_name: 'thomas2',
    member_name: 'adrian thomas2',
    biography:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent cursus ornare diam in tincidunt. Fusce quis mattis ipsum. Suspendisse eget ligula in augue elementum facilisis quis at lectus. Curabitur at dignissim dolor. Integer eget libero scelerisque, bibendum nunc et, blandit augue. Sed mattis massa sit amet pulvinar tristique. Cras ut dapibus arcu, quis varius arcu. Fusce a gravida eros, nec varius est. Maecenas sagittis risus efficitur tristique facilisis.',
    cell_phone: null,
    office_phone: '',
    home_phone: '',
    hourly_rate: 200,
    email: 'adrian2@email.com',
    personal_email: '',
    company: 'Cincyr2',
    title: 'Board Member',
    company_name: '',
    company_phone: '',
    company_web_site: '',
    company_email: '',
    company_bio: '',
    role: 'Client',
    role_id: 2,
    create_date: '2021-02-13T09:13:27.906144+00:00',
    update_date: '2021-02-13T09:14:10.584085+00:00',
    location_information: [
      {
        id: 3,
        member_id: 3,
        location_type: 'other',
        street: '713 Vandervoort Place',
        city: 'Spokane',
        state: 'MS',
        province: null,
        postal: '35390',
        country: 'United States of America',
        address_1: '713 Vandervoort Place',
        address_2: 'Apt #175',
        state_code_id: null,
        country_code_id: 840,
        update_date: '2021-02-06T18:44:17.911639+00:00',
        description: null,
      },
    ],
    contact_information: [
      {
        id: 7,
        member_id: 3,
        description: 'Cell phone',
        device: '9721713771',
        device_type: 'cell',
        device_country: 840,
        device_confirm_date: null,
        method_type: 'voice',
        display_order: 2,
        enabled: true,
        primary_contact: true,
        create_date: '2021-02-06T18:44:17.914723+00:00',
        update_date: '2021-02-06T18:44:17.914723+00:00',
        member_location_id: null,
      },
      {
        id: 8,
        member_id: 3,
        description: 'Office phone',
        device: '9723343333',
        device_type: 'landline',
        device_country: 840,
        device_confirm_date: null,
        method_type: 'voice',
        display_order: 3,
        enabled: true,
        primary_contact: false,
        create_date: '2021-02-06T18:44:17.914723+00:00',
        update_date: '2021-02-06T18:44:17.914723+00:00',
        member_location_id: null,
      },
      {
        id: 9,
        member_id: 3,
        description: 'Office email',
        device: 'test@email.com',
        device_type: 'email',
        device_country: 840,
        device_confirm_date: null,
        method_type: 'html',
        display_order: 1,
        enabled: true,
        primary_contact: true,
        create_date: '2021-02-06T18:44:17.914723+00:00',
        update_date: '2021-02-06T18:44:17.914723+00:00',
        member_location_id: null,
      },
    ],
    country_code: [
      {
        id: 840,
        alpha2: 'US',
        alpha3: 'USA',
        name: 'United States of America',
        phone: 1,
        currency_code: 'USD',
        currency_name: 'US Dollar',
        currency_id: 840,
        currency_minor_unit: 2,
        domain: '.us',
        display_order: 1,
        is_enabled: true,
        cell_regexp:
          '^((\\+1|1)?( |-)?)?(\\([2-9][0-9]{2}\\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$',
      },
    ],
    amera_avatar_url: '/member/file/ana.jpg',
    security_exchange_option: 0,
    status: 'requested',
    online_status: 'inactive',
  },
  {
    id: 5,
    contact_member_id: 5,
    first_name: 'adrian',
    middle_name: null,
    last_name: 'thomas4',
    member_name: 'adrian thomas4',
    biography:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent cursus ornare diam in tincidunt. Fusce quis mattis ipsum. Suspendisse eget ligula in augue elementum facilisis quis at lectus. Curabitur at dignissim dolor. Integer eget libero scelerisque, bibendum nunc et, blandit augue. Sed mattis massa sit amet pulvinar tristique. Cras ut dapibus arcu, quis varius arcu. Fusce a gravida eros, nec varius est. Maecenas sagittis risus efficitur tristique facilisis.',
    cell_phone: null,
    office_phone: '',
    home_phone: '',
    hourly_rate: 220,
    email: 'adrian4@email.com',
    personal_email: '',
    company: 'Cincyr4',
    title: 'Board Member',
    company_name: '',
    company_phone: '',
    company_web_site: '',
    company_email: '',
    company_bio: '',
    role: null,
    role_id: null,
    create_date: '2021-02-13T09:13:28.065328+00:00',
    update_date: '2021-02-13T09:13:28.065328+00:00',
    location_information: [
      {
        id: 5,
        member_id: 5,
        location_type: 'home',
        street: '913 Moore Place',
        city: 'Elizaville',
        state: 'SD',
        province: null,
        postal: '28714',
        country: 'United States of America',
        address_1: '913 Moore Place',
        address_2: 'Apt #118',
        state_code_id: null,
        country_code_id: 840,
        update_date: '2021-02-06T18:44:17.911639+00:00',
        description: null,
      },
    ],
    amera_avatar_url: '/member/file/jen.jpg',
    security_exchange_option: 0,
    status: 'requested',
    online_status: 'inactive',
  },
  {
    id: 7,
    contact_member_id: 2,
    first_name: 'adrian',
    middle_name: null,
    last_name: 'thomas',
    member_name: 'adrian thomas',
    biography:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent cursus ornare diam in tincidunt. Fusce quis mattis ipsum. Suspendisse eget ligula in augue elementum facilisis quis at lectus. Curabitur at dignissim dolor. Integer eget libero scelerisque, bibendum nunc et, blandit augue. Sed mattis massa sit amet pulvinar tristique. Cras ut dapibus arcu, quis varius arcu. Fusce a gravida eros, nec varius est. Maecenas sagittis risus efficitur tristique facilisis.',
    cell_phone: null,
    office_phone: '',
    home_phone: '',
    hourly_rate: 260,
    email: 'adrian@email.com',
    personal_email: '',
    company: 'Cincyr',
    title: 'Board Member',
    company_name: '',
    company_phone: '',
    company_web_site: '',
    company_email: '',
    company_bio: '',
    role: null,
    role_id: null,
    create_date: '2021-02-13T09:13:28.092833+00:00',
    update_date: '2021-02-13T09:13:28.092833+00:00',
    location_information: [
      {
        id: 2,
        member_id: 2,
        location_type: 'work',
        street: '814 Pine Street',
        city: 'Crown',
        state: 'KS',
        province: null,
        postal: '37986',
        country: 'United States of America',
        address_1: '814 Pine Street',
        address_2: 'Apt #121',
        state_code_id: null,
        country_code_id: 840,
        update_date: '2021-02-06T18:44:17.911639+00:00',
        description: null,
      },
    ],
    contact_information: [
      {
        id: 4,
        member_id: 2,
        description: 'Cell phone',
        device: '9721713771',
        device_type: 'cell',
        device_country: 840,
        device_confirm_date: null,
        method_type: 'voice',
        display_order: 2,
        enabled: true,
        primary_contact: true,
        create_date: '2021-02-06T18:44:17.914723+00:00',
        update_date: '2021-02-06T18:44:17.914723+00:00',
        member_location_id: null,
      },
      {
        id: 5,
        member_id: 2,
        description: 'Office phone',
        device: '9723343333',
        device_type: 'landline',
        device_country: 840,
        device_confirm_date: null,
        method_type: 'voice',
        display_order: 3,
        enabled: true,
        primary_contact: false,
        create_date: '2021-02-06T18:44:17.914723+00:00',
        update_date: '2021-02-06T18:44:17.914723+00:00',
        member_location_id: null,
      },
      {
        id: 6,
        member_id: 2,
        description: 'Office email',
        device: 'test@email.com',
        device_type: 'email',
        device_country: 840,
        device_confirm_date: null,
        method_type: 'html',
        display_order: 1,
        enabled: true,
        primary_contact: true,
        create_date: '2021-02-06T18:44:17.914723+00:00',
        update_date: '2021-02-06T18:44:17.914723+00:00',
        member_location_id: null,
      },
    ],
    country_code: [
      {
        id: 840,
        alpha2: 'US',
        alpha3: 'USA',
        name: 'United States of America',
        phone: 1,
        currency_code: 'USD',
        currency_name: 'US Dollar',
        currency_id: 840,
        currency_minor_unit: 2,
        domain: '.us',
        display_order: 1,
        is_enabled: true,
        cell_regexp:
          '^((\\+1|1)?( |-)?)?(\\([2-9][0-9]{2}\\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$',
      },
    ],
    achievement_information: [
      {
        id: 6,
        member_id: 2,
        entity: 'Best Table Tennis Player',
        description: ' I can play table tennis with both hands.',
        display_order: 5,
        update_date: '2014-10-07T00:00:00+00:00',
      },
      {
        id: 7,
        member_id: 2,
        entity: 'My Own Radio',
        description: 'I have my own radio show.',
        display_order: 4,
        update_date: '2015-10-07T00:00:00+00:00',
      },
      {
        id: 8,
        member_id: 2,
        entity: 'Best Basketball Player',
        description: 'Best Basketball Player.',
        display_order: 3,
        update_date: '2016-10-07T00:00:00+00:00',
      },
      {
        id: 9,
        member_id: 2,
        entity: 'Bicyclist',
        description: 'Very goodin bicycle cards.',
        display_order: 2,
        update_date: '2017-10-07T00:00:00+00:00',
      },
      {
        id: 10,
        member_id: 2,
        entity: 'Good Badminton Player',
        description: 'Very good at Badminton.',
        display_order: 1,
        update_date: '2018-10-07T00:00:00+00:00',
      },
    ],
    amera_avatar_url: '/member/file/ana.jpg',
    security_exchange_option: 0,
    status: 'requested',
    online_status: 'inactive',
  },
];

export const dummyCompanies = [
  { id: 1, name: 'ACME' },
  { id: 2, name: 'Google' },
  { id: 3, name: 'Facebook' },
  { id: 4, name: 'Amera' },
];

export const projectEditOptionKeys = {
  projectDefinition: 'projectDefinition',
  selectTeam: 'selectTeam',
  assignTeamRoles: 'assignTeamRoles',
  assignPrivilege: 'assignPrivilege',
  softDeleteProject: 'softDeleteProject',
  suspend: 'suspend',
  restore: 'restore',
};

export const projectEditOptions = [
  {
    name: 'Project Definition',
    key: 'projectDefinition',
    path: `${process.env.PUBLIC_URL}/projects/project-definition/`,
  },
  {
    name: 'Select Team',
    key: 'selectTeam',
    path: `${process.env.PUBLIC_URL}/projects/select-team/`,
  },
  {
    name: 'Assign Team Roles',
    key: 'assignTeamRoles',
    path: `${process.env.PUBLIC_URL}/projects/assign-team-roles/`,
  },
  {
    name: 'Assign Team Privilege',
    key: 'assignPrivilege',
    path: `${process.env.PUBLIC_URL}/projects/set-security/`,
  },
  {
    name: 'Soft Delete Project',
    key: 'softDeleteProject',
    color: 'secondary',
  },
  {
    name: 'Suspend',
    key: 'suspend',
  },
  {
    name: 'Restore',
    key: 'restore',
  },
];
export const projectfilterOptions = [
  {
    key: 'any',
    value: 'Any',
  },
  {
    key: 'assignToMe',
    value: 'Assigned to Me',
  },
];

export const projectOptionsKeyMap = {
  assignToMe: {
    key: 'assignToMe',
    display: 'Assigned to Me',
  },
  any: {
    key: 'any',
    display: 'Any',
  },
  all: {
    key: 'all',
    display: 'All',
  },
  complete: {
    key: 'complete',
    display: 'Completed',
  },
  'in progress': {
    key: 'in progress',
    display: 'In Progress',
  },
};

export const projectTaskStatusOptions = [
  {
    key: 'all',
    value: 'All',
  },
  {
    key: 'complete',
    value: 'Completed',
  },
  {
    key: 'in progress',
    value: 'In Progress',
  },
];
