import React, { useState } from 'react';
import ContactRolesSelect from '../ContactRoleSelect';
import { connect, useSelector } from 'react-redux';
import PersonIcon from '@material-ui/icons/Person';
import MaterialAvatar from '../MaterialAvatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import {
  displayableFullName,
  getContactDevice,
  formatDuration,
  formatReceived,
} from '../../utils/contact';
import AmeraIconButton from '../../components/AmeraIconButton';
import AmeraAccordion from '../../components/AmeraAccordion/AmeraAccordion';
import AmeraDataCard from '../../components/AmeraDataCard';
import LabeledValue from '../../components/LabeledValue';
import CardButton from '../../components/Dashboard/CardButton';
import Compose from '../../pages/Email/SharedComponents/Compose';
import AddressLine from '../../components/AddressLine';
import {
  ICON_BUTTON_TYPES,
  establishContactVideoCall,
} from '../../utils/general';
import { GROUP_ROLES } from '../../utils/group';
import { orderBy } from 'lodash';
import { closeDrawer, openDrawer } from '../../redux/actions/drawer';
import { useHistory } from 'react-router-dom';
import {
  setCallPartner,
  setCallPartnerMemberId,
} from '../../redux/actions/one2onevcall';
import { updateContactProperty } from '../../redux/actions/contact';
import { getCompanyDetail } from '../../redux/actions/company';
import drawerProfileStyles from '../../jss/views/drawerProfileStyles';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';
import { getContactOnlineStatus } from '../../utils/strophe/user';

const ContactProfile = (props) => {
  const { contactData, member, roles, dispatch } = props;
  const history = useHistory();
  const [compose, setCompose] = useState({
    open: false,
    initialState: {},
  });

  const classes = drawerProfileStyles();

  const {
    biography,
    achievement_information,
    group_memberships,
    skills_information,
    workhistory_information,
    education_information,
    certificate_information,
    role_id,
    role,
    title,
    department,
    first_name,
    last_name,
    contact_member_id,
    company_membership,
  } = contactData;
  const rosterItems = useSelector((s) => s?.chat?.rosterItems);

  const [currentRoleId, setCurrentRoleId] = useState(role_id);
  const [relationship, setRelationship] = useState(role);
  const [isEdit, setIsEdit] = useState(false);

  const companyData = company_membership;
  const departmentName = !!company_membership
    ? company_membership.department_name
    : department;

  const handleCancelClick = () => dispatch(closeDrawer());
  const handleScheduleClick = () => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/calendar`,
      search: `?member_ids=${[contactData.contact_member_id]}`,
    });
    dispatch(closeDrawer());
  };

  const handlGoOne2OneVideoCall = (
    partner,
    notificationData,
    audioOnly = false
  ) => {
    dispatch(setCallPartner(partner));
    dispatch(setCallPartnerMemberId(partner.member_id, 'call'));
    establishContactVideoCall(
      notificationData.member,
      notificationData.contactData,
      audioOnly
    );
    history.push(
      `${process.env.PUBLIC_URL}/contacts/call/video/${partner.member_id}`
    );
    dispatch(closeDrawer());
  };

  const handleRoleChange = (e) => {
    const role_id = e.target.value;
    const role = roles.find((r) => r.id === role_id).name;

    setCurrentRoleId(role_id);
    setRelationship(role);
  };

  const handleSaveRelationship = () => {
    if (!currentRoleId || !relationship) return;
    const payload = {
      relationship_id: currentRoleId,
      relationship,
      id: contactData.id,
      name: 'relationship',
    };

    console.debug('payload', payload);
    dispatch(updateContactProperty(payload));
    setIsEdit(false);
  };

  const handleCompanyClick = async () => {
    await dispatch(getCompanyDetail(companyData.id));
    await dispatch(
      openDrawer(DRAWER_CONTENT_TYPE.companyProfile, {
        companyId: companyData.id,
      })
    );
  };
  const openContactChat = () => {
    history.push(
      `${process.env.PUBLIC_URL}/contacts/chat?chatID=${contactData.contact_member_id}`
    );
  };

  const openContactEmail = () => {
    setCompose({
      open: true,
      initialState: {
        receivers: [
          {
            email: contactData.email,
            member: contactData.member_name,
            first_name: contactData.first_name,
            last_name: contactData.last_name,
            contact_member_id: contactData.contact_member_id,
          },
        ],
      },
    });
  };

  const openContactOne2OneCall = () => {
    handlGoOne2OneVideoCall(
      {
        email: contactData.email,
        member_id: contactData.contact_member_id,
        first_name: contactData.first_name,
        last_name: contactData.last_name,
      },
      { member, contactData }
    );
  };

  const openContactAudioCall = () => {
    return;
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <div>
            <h3 className={classes.title}>Contact Details</h3>
          </div>
        </div>
        <div className={classes.body}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <MaterialAvatar
                style={{ height: '10rem', width: '10rem' }}
                member_id={contact_member_id}
                firstName={first_name}
                lastName={last_name}
                badgeType="schedule"
                badge2Type={getContactOnlineStatus(contactData, rosterItems)}
                variant="circular"
                onClick={handleScheduleClick}
              />
            </Grid>
            <Grid
              item
              xs={7}
              container
              spacing={0}
              justify="center"
              direction="column"
            >
              <Typography variant="h6" color="initial">
                <b>{displayableFullName(contactData, false)}</b>
              </Typography>
              <Typography
                variant="body1"
                className={classes.companyName}
                onClick={
                  companyData?.id ? () => handleCompanyClick() : () => null
                }
              >
                {!!companyData?.name ? companyData.name : contactData.company}
              </Typography>
              <Typography variant="body2">
                <b>Title:</b> {title}
              </Typography>
              <Typography variant="body2">
                <b>Department:</b>{' '}
                {!!departmentName ? departmentName : 'Not specified'}
              </Typography>
              <Typography variant="body2">
                <b>Relationship:</b>{' '}
                {!!relationship ? relationship : 'Not Available'}
                {isEdit ? (
                  <>
                    <ContactRolesSelect
                      currentRoleId={currentRoleId}
                      onChange={handleRoleChange}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.iconButtonCheck}
                      onClick={handleSaveRelationship}
                    >
                      save
                    </Button>
                  </>
                ) : (
                  <IconButton
                    className={classes.iconButtonEdit}
                    onClick={() => setIsEdit(true)}
                  >
                    <Tooltip title={`Edit Relationship`} placement="top" arrow>
                      <EditOutlinedIcon color="primary" size="small" />
                    </Tooltip>
                  </IconButton>
                )}
              </Typography>
              {!!companyData && (
                <Typography variant="body2">
                  {companyData?.city}
                  {!!companyData?.state ? `, ${companyData?.state}` : ''}
                </Typography>
              )}
              <div className={classes.iconButtonsContainer}>
                <AmeraIconButton
                  type={ICON_BUTTON_TYPES.chat}
                  onClick={openContactChat}
                />
                <AmeraIconButton
                  type={ICON_BUTTON_TYPES.email}
                  onClick={openContactEmail}
                />
                <AmeraIconButton
                  type={ICON_BUTTON_TYPES.conference}
                  onClick={openContactOne2OneCall}
                />
                <AmeraIconButton
                  type={ICON_BUTTON_TYPES.call}
                  onClick={openContactAudioCall}
                />
              </div>
            </Grid>
            <Grid item className={classes.noTopPadding} xs={12}>
              <AmeraAccordion label="Contact information">
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <LabeledValue
                      label="Cell phone"
                      value={
                        getContactDevice(contactData, 'Cell phone') ||
                        'Not Available'
                      }
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <LabeledValue
                      label="Office phone"
                      value={
                        getContactDevice(contactData, 'Office phone') ||
                        'Not Available'
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <LabeledValue
                      label="Office email"
                      wordBreak
                      value={contactData.email}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    {!!companyData && (
                      <LabeledValue
                        label="Company name and Address"
                        value={
                          <span>
                            <span>{companyData.name}</span>
                            <br />
                            {!!companyData.location_id ? (
                              <AddressLine data={companyData} />
                            ) : (
                              <span>Address Not Specified</span>
                            )}
                          </span>
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </AmeraAccordion>
              <AmeraAccordion label="Skills">
                <div className={classes.chipArray}>
                  {skills_information?.length > 0 ? (
                    skills_information.map((s) => (
                      <Chip
                        size="small"
                        key={s.id}
                        className={classes.chip}
                        label={s.name}
                      />
                    ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                </div>
              </AmeraAccordion>
              <AmeraAccordion label="Group Memberships">
                <div className={classes.chipArray}>
                  {group_memberships?.length > 0 ? (
                    group_memberships.map((gm) => (
                      <Chip
                        size="small"
                        key={gm.group_id}
                        className={classes.chip}
                        label={gm.group_name}
                        avatar={
                          gm.group_role === GROUP_ROLES.owner ? (
                            <PersonIcon />
                          ) : null
                        }
                      />
                    ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                </div>
              </AmeraAccordion>
              <AmeraAccordion label="Bio">
                <Typography variant="body2" className={classes.bio}>
                  {biography}
                </Typography>
              </AmeraAccordion>
              <AmeraAccordion label="Work Experience">
                <div className={classes.cardArray}>
                  {workhistory_information?.length > 0 ? (
                    workhistory_information.map((wio) => (
                      <AmeraDataCard
                        key={wio.id}
                        title={wio.job_title}
                        subTitle={wio.company_name}
                        content={
                          <>
                            {formatDuration(wio.start_date, wio.end_date, true)}
                            <br />
                            {wio.company_location}
                          </>
                        }
                      />
                    ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                </div>
              </AmeraAccordion>
              <AmeraAccordion label="Education">
                <div className={classes.cardArray}>
                  {education_information?.length > 0 ? (
                    education_information.map((io) => (
                      <AmeraDataCard
                        key={io.id}
                        title={io.school_name}
                        subTitle={`${io.degree}, ${io.field_of_study}`}
                        content={formatDuration(io.start_date, io.end_date)}
                      />
                    ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                </div>
              </AmeraAccordion>
              <AmeraAccordion label="Certification">
                <div className={classes.cardArray}>
                  {certificate_information?.length > 0 ? (
                    certificate_information.map((ci) => (
                      <AmeraDataCard
                        key={ci.id}
                        subTitle={ci.title}
                        content={formatReceived(ci.date_received)}
                      />
                    ))
                  ) : (
                    <div className={classes.emptyFallback}>
                      No information provided
                    </div>
                  )}
                </div>
              </AmeraAccordion>
              <AmeraAccordion label="Achievements">
                {achievement_information?.length > 0 ? (
                  orderBy(achievement_information, ['display_order']).map(
                    (ai) => (
                      <Typography className={classes.achievement} key={ai.id}>
                        <b>{ai.entity}</b> - {ai.description}
                      </Typography>
                    )
                  )
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </AmeraAccordion>
            </Grid>
            <Grid
              item
              className={classes.noTopPadding}
              xs={12}
              container
              spacing={1}
              justify="flex-end"
            >
              <Grid item>
                <CardButton
                  variant="info"
                  text="Cancel"
                  onClick={handleCancelClick}
                />
              </Grid>
              <Grid item>
                <CardButton
                  variant="okFilled"
                  text="Schedule"
                  onClick={handleScheduleClick}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {!compose.open ? null : (
        <Compose
          open={true}
          initialComposeState={compose.initialState}
          fullWidth={false}
          onClose={() => {
            setCompose({ open: false, initialState: {} });
          }}
        />
      )}
    </>
  );
};

const mapStatetoProps = (state) => ({
  roles: state.member.roles,
  member: state.member.member,
  contactGroupMemberships: state.group.contactGroupMemberships,
  contactData: state.contact.contacts.find(
    (c) => c.contact_member_id === state.drawer.contentProps.contactMemberId
  ),
});

export default connect(mapStatetoProps)(ContactProfile);
