import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { EVENT_TYPES, eventTypeText } from '../../../utils/calendar';
import { InputLabel } from '@material-ui/core';

const EventTypeSelect = ({
  value,
  handleChange,
  disabled = false,
  variant = 'outlined',
}) => {
  return (
    <FormControl fullWidth variant={variant} style={{ margin: 0 }}>
      <InputLabel id="label-eventtype">Event Type</InputLabel>
      <Select
        native
        labelId="label-eventtype"
        id="eventtype-select"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        {Object.values(EVENT_TYPES)
          .sort((a, b) =>
            eventTypeText(a)
              .toLowerCase()
              .localeCompare(eventTypeText(b).toLowerCase())
          )
          .map((et) => (
            <option key={et} value={et}>
              {eventTypeText(et)}
            </option>
          ))}
      </Select>
    </FormControl>
  );
};

export default EventTypeSelect;
