import { ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Cached, Done } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { ApiEmailService } from '../../../../config/apiService';
import { setSnackbarData } from '../../../../redux/actions/snackbar';
import EditorToolbar, {
  formats,
  modules,
} from '../Compose/CustomComposeEditor/EditorToolbar';
import { useSettingsStyles } from './SettingsStyles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

const SignatureItem = ({ id, name, content, onUpdate }) => {
  const classes = useSettingsStyles();

  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    id,
    name,
    content,
  });

  useEffect(() => {
    return () => {
      const el = document.getElementById(
        'user_defined_signature_id_' + String(id)
      );
      if (el) {
        while (el.firstChild) {
          el.removeChild(el.lastChild);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSignature = async () => {
    if (loading || edit) {
      return;
    }
    if (!id) {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Signature is not saved!',
          type: 'error',
        })
      );
      return;
    }
    setLoading(true);
    const res = await ApiEmailService.deleteSignature('signatureDelete', id);
    if (res.success)
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Deleted successfully',
          type: 'success',
        })
      );
    setData({
      id: null,
      name: '',
      content: '',
    });
    id = null;
    onUpdate();

    setLoading(false);
  };

  const updateDetail = async () => {
    const payload = data;
    if (!payload.id) {
      delete payload.id;
    }
    if (!payload.name) {
      payload.name = ' ';
    }
    setLoading(true);
    const res = await ApiEmailService.updateSignature(
      'signatureUpdate',
      JSON.stringify(payload)
    );
    if (res.success)
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Saved successfully',
          type: 'success',
        })
      );
    onUpdate();
    setEdit(!edit);
    setLoading(false);
  };

  return (
    <ListItem id={'user_defined_signature_id_' + String(id)} className={'ml-0'}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Button
            disableElevation
            disabled={loading || edit || !id}
            className={'mr-2'}
            startIcon={<ClearIcon />}
            onClick={() => {
              deleteSignature().then();
            }}
          >
            Clear
          </Button>
          {!edit && !loading ? (
            <Button
              disableElevation
              startIcon={<EditIcon />}
              onClick={() => {
                setEdit(!edit);
              }}
            >
              Edit
            </Button>
          ) : !loading ? (
            <Button
              disableElevation
              startIcon={<Done />}
              onClick={() => {
                updateDetail().then();
              }}
            >
              Submit
            </Button>
          ) : (
            <Button
              disableElevation
              disabled={true}
              startIcon={<Cached classes={{ root: classes.rotate }} />}
            >
              Saving...
            </Button>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={'mt-2'}
        >
          <div className="text-editor-signature">
            <div aria-label="editor-toolbar">
              <EditorToolbar className="signature-toolbar" />
            </div>
            <ReactQuill
              readOnly={!edit}
              value={data.content || ''}
              onChange={(html, d, s) => {
                setData({ ...data, content: html });
              }}
              modules={modules}
              formats={formats}
            />
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default SignatureItem;
