import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Image, Alert } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '../../components/Modal/modal';

import {
  setAmeraGroupSecurity,
  setShowGroupDetailModal,
} from '../../redux/actions/group';

// Non Browser Features
import {
  setSecurityInMain,
  checkSecurityPictureInMain,
} from '../../utils/electron';

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const SaveData = (props) => {
  const { dispatch, show, close, groupData, type, member } = props;

  const [file, setFile] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [pin, setPin] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState('');
  const [alertVariant, setAlertVariant] = useState('danger');
  const [alertMessage, setAlertMessage] = useState('');

  const [imageError, setImageError] = useState('');
  const [pinError, setPinError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const prevState = usePrevious({ isLoading });

  const title = 'Save Picture and PIN for Member';
  const classes = useStyles();

  // useEffect(() => {
  //   if (showAlert) {
  //     setTimeout(() => {
  //       setShowAlert(false);
  //     }, 3000);
  //   }
  // }, [showAlert]);
  const resetAlert = () => {
    setShowAlert(false);
    setAlertStatus('');
    setAlertVariant('');
    setAlertMessage('');
  };

  const showErrorAlert = (message) => {
    setShowAlert(true);
    setAlertStatus('Error');
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const showSuccessAlert = (message) => {
    setShowAlert(true);
    setAlertStatus('Success');
    setAlertVariant('success');
    setAlertMessage(message);
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (prevState && isLoading === false && prevState.isLoading === true) {
      close();
      dispatch(setShowGroupDetailModal(true));
    }
  }, [isLoading, prevState, close, dispatch]);

  const isFileImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
  };

  const fileSelectHandler = (e) => {
    resetAlert();
    setFile('');
    setImagePreviewUrl('');
    const selectedFile = e.target.files[0];
    try {
      const imageType = isFileImage(selectedFile);
      if (!imageType) {
        throw new Error('File is not an image, please choose image');
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(selectedFile);
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } catch (e) {
      setFile('');
      setImagePreviewUrl('');
      showErrorAlert(e.message);
    }
  };

  const setPinNumber = (number) => {
    setPinError('');
    setPin(number);
  };

  const validatePicture = async () => {
    try {
      // const image = document.getElementById('file-image');
      // const { naturalWidth, naturalHeight } = image;
      // const area = naturalHeight * naturalWidth;
      // const minArea = 883 * 631;

      // if (area < minArea) {
      //   throw new Error(`Picture dimensions are too low, please choose a picture with higher resolution than: ${naturalWidth}x${naturalHeight}`);
      // }
      await checkSecurityPictureInMain(file);
      showSuccessAlert('Picture validated');
    } catch (e) {
      setFile('');
      // setImagePreviewUrl('');
      showErrorAlert(e.message);
    }
  };

  const setSessionData = (file, pin) => {
    if (type === 'member') {
      setSecurityInMain(file.path, pin, member);

      return true;
    } else {
      var groupSecurity = {
        groupId: groupData.group_id,
        fileName: file.name,
        // fileContent: fileContent,
        filePath: '',
        fileSize: file.size,
        fileType: file.type,
        pin: pin,
      };
      dispatch(setAmeraGroupSecurity(groupSecurity));
      return true;
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (!file) {
      setImageError('Please select a file to upload!');
      return false;
    }
    if (!pin) {
      setPinError('Pin code is required');
      return false;
    }
    if (isNaN(pin) || pin.length < 6 || pin.length > 12) {
      setPinError('Pin code is invalid');
      return false;
    }

    setIsLoading(true);
    const isSaved = setSessionData(file, pin);
    if (isSaved) {
      setTimeout(() => {
        setIsLoading(false);
        showSuccessAlert('Data saved successfully');
        setFile('');
        setImagePreviewUrl('');
        setPin('');
      }, 1500);
    }
  };
  /*
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>
          Save Picture and PIN for {groupData.group_name} Group
        <Button variant="secondary" onClick={close}>
      Close
    </Button>
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Save Picture and PIN for Member</Modal.Title>
      {/* <Button variant="secondary" onClick={close}>
      Close
    </Button> }
  */

  const ModalContent = () => (
    <>
      <div className="image-pin-save-form">
        <Grid container spacing={1}>
          <React.Fragment>
            {showAlert ? (
              <Alert
                className="upload-image-alert-message"
                variant={alertVariant}
                onClose={() => setShowAlert(false)}
                dismissible
              >
                <Alert.Heading>{alertStatus}</Alert.Heading>
                <p>{alertMessage}</p>
              </Alert>
            ) : null}
            <Form
              className="image-upload-form uploader"
              onSubmit={(e) => handleSave(e)}
            >
              <Grid item xs={12}>
                <Form.Group controlId="uploadImage">
                  <Form.File
                    accept="image/*"
                    className="image-upload"
                    name="imageUpload"
                    onChange={(e) => fileSelectHandler(e)}
                  />
                </Form.Group>
                <Form.Group controlId="uploadImage">
                  <Form.Label id="image-drag">
                    {imagePreviewUrl ? (
                      <React.Fragment>
                        <Image
                          id="file-image"
                          src={imagePreviewUrl}
                          alt="Preview"
                          style={{ maxWidth: '100%' }}
                          onLoad={(e) => validatePicture(e)}
                        />
                      </React.Fragment>
                    ) : (
                      <div id="start" className="align-self-center">
                        <i className="fa fa-download" aria-hidden="true"></i>
                        <div id="notimage" className="hidden">
                          Please select an image
                        </div>
                        <span id="file-upload-btn" className="btn btn-success">
                          Select a file
                        </span>
                      </div>
                    )}
                  </Form.Label>
                  {imagePreviewUrl ? (
                    <p>Click image again to choose a different image.</p>
                  ) : imageError ? (
                    <p className="error">{imageError}</p>
                  ) : null}
                </Form.Group>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-pin"
                  label="PIN, min: 6, max: 12"
                  placeholder="123456789123"
                  type="text"
                  variant="outlined"
                  name="pin_code"
                  value={pin || ''}
                  onChange={(e) => setPinNumber(e.target.value)}
                  error={pinError ? true : false}
                  helperText={pinError ? pinError : ''}
                  className="pin_input"
                />
              </Grid>
            </Form>
            <div id="session_image_section" className="hidden"></div>
          </React.Fragment>
        </Grid>
      </div>
    </>
  );

  const ModalAction = () => {
    return isLoading ? (
      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        disableRipple
        disabled
      >
        Saving ...
      </Button>
    ) : (
      <Button
        variant="contained"
        color="primary"
        className={classes.submitBtn}
        disableRipple
        onClick={(e) => handleSave(e)}
      >
        Save
      </Button>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onClose={close}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="save-data-modal"
      ></Modal>
    </>
  );
};

SaveData.propTypes = {
  dispatch: PropTypes.func,
  show: PropTypes.bool,
  close: PropTypes.func,
  groupData: PropTypes.object,
};

SaveData.defaultProps = {
  dispatch: null,
  show: false,
  close: null,
  groupData: {},
};

const mapStateToProps = (state) => ({
  app: state.app,
  groupData: state.group.groupData,
  member: state.member.member,
});

export default connect(mapStateToProps)(SaveData);
