import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import VideocamIcon from '@material-ui/icons/Videocam';
// import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
// import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';
import { ICON_BUTTON_TYPES } from '../utils/general';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    gap: 4,
    '& label': {
      fontSize: 10,
      color: '#5F5F5F',
    },
    padding: '0 3px',
  },
  iconButton: {
    width: '32px',
    height: '32px',
    margin: 'auto',
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    fill: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const AmeraIconButton = (props) => {
  const { type, onClick } = props;

  let icon;
  switch (type) {
    case ICON_BUTTON_TYPES.chat:
      icon = <ChatIcon />;
      break;
    case ICON_BUTTON_TYPES.fileshare:
      icon = <FileCopyIcon />;
      break;
    case ICON_BUTTON_TYPES.email:
      icon = <MailIcon />;
      break;
    case ICON_BUTTON_TYPES.conference:
      icon = <VideocamIcon />;
      break;
    case ICON_BUTTON_TYPES.call:
      icon = <CallIcon />;
      break;
    case ICON_BUTTON_TYPES.schedule:
      icon = <DateRangeIcon />;
      break;
    case ICON_BUTTON_TYPES.videoMail:
      icon = <AlternateEmailIcon />;
      break;
    default:
      icon = null;
  }

  const classes = useStyles();
  return (
    <div className={classes.iconContainer}>
      <IconButton
        aria-label={type}
        className={classes.iconButton}
        onClick={onClick}
      >
        {icon}
      </IconButton>
      <label>{type}</label>
    </div>
  );
};

export default AmeraIconButton;
