import React from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const IndustriesAutocomplete = (props) => {
  const { industries, onChange, selected, variant = 'standard' } = props;
  //   console.debug('industries', selected);
  return (
    <Autocomplete
      getOptionSelected={(o, v) => o.industry_id === v.industry_id}
      disableCloseOnSelect
      multiple
      id="industries"
      options={industries}
      getOptionLabel={(o) => o.industry_name}
      onChange={onChange}
      value={selected}
      noOptionsText={<Typography variant="body2">No matches</Typography>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label="Industries"
          multiline
          margin="dense"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  industries: state.member.industries,
});
export default connect(mapStateToProps)(IndustriesAutocomplete);
