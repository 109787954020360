import React from 'react';

const TelephonyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.712"
    height="44.764"
    viewBox="0 0 47.712 44.764"
  >
    <g
      id="Group_26"
      data-name="Group 26"
      transform="translate(-384.144 -9.618)"
    >
      <path
        id="Path_35"
        data-name="Path 35"
        d="M409.049,17.4a13.91,13.91,0,0,1,12.82,12.82,1.148,1.148,0,0,0,1.152,1.049h1.13a1.143,1.143,0,0,0,1.151-1.248A17.348,17.348,0,0,0,409.248,13.97,1.143,1.143,0,0,0,408,15.121v1.13A1.148,1.148,0,0,0,409.049,17.4Z"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M409,25.06a6.309,6.309,0,0,1,5.211,5.211,1.2,1.2,0,0,0,1.14,1H416.5A1.125,1.125,0,0,0,417.647,30a9.752,9.752,0,0,0-8.375-8.375A1.163,1.163,0,0,0,408,22.92v1A1.2,1.2,0,0,0,409,25.06Z"
      />
      <path
        id="Path_37"
        data-name="Path 37"
        d="M409.922,29.244a1.158,1.158,0,1,0,.106.106l-.053-.054Z"
      />
      <path
        id="Path_38"
        data-name="Path 38"
        d="M389.056,34.03c2.662,3.7,9.921,12.722,19.784,16.7,6.972,2.807,10.529,3.653,12.887,3.653a8.9,8.9,0,0,0,3.068-.547c2.434-.832,5.143-3.706,6.168-6.542l0,0c.828-2.305,1.262-5.309.482-6.6a4.734,4.734,0,0,0-2.218-1.619l-.563-.272c-.062-.031-6.378-3.14-7.634-3.593a3.4,3.4,0,0,0-4.443,1.195l-2.549,3.118a1.6,1.6,0,0,1-1.892.451,26.374,26.374,0,0,1-7.6-4.755,29.626,29.626,0,0,1-5.03-5.942,1.6,1.6,0,0,1,.142-1.93c.2-.233.4-.467.569-.668.179-.217.358-.433.526-.628a7.613,7.613,0,0,0,1.177-1.851l.15-.3a3.619,3.619,0,0,0-.092-3.425c-.18-.365-1.4-3.321-2.216-5.276-.422-1.019-.816-1.97-1.093-2.632-.854-2.026-2.846-3.009-5.934-2.938l-.182,0a6.706,6.706,0,0,0-4.051,1.789,13.516,13.516,0,0,0-4.374,10.269c0,5.5,3.678,10.729,4.795,12.186Z"
      />
    </g>
  </svg>
);

export default TelephonyIcon;
