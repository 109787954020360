import { makeStyles } from '@material-ui/core';
import React from 'react';

// import { Button } from '@material-ui/core';

import Modal from '../../../components/Modal/modal';

import NotificationTable from './NotificationTable';

const useStyles = makeStyles((theme) => ({
  activitiesModal: {
    '& .MuiDialog-paper': {
      minWidth: 750,
    },
  },
}));

const NotificationModal = (props) => {
  const { open, onClose, title, ...rest } = props;

  const classes = useStyles();

  const closeModal = () => {
    onClose();
  };

  const ModalContent = () => <NotificationTable title={title} {...rest} />;

  const ModalAction = () => {
    return <div></div>;
  };
  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className={classes.activitiesModal}
    ></Modal>
  );
};

NotificationModal.defaultProps = {
  open: false,
  onClose: null,
};

export default NotificationModal;
