import React from 'react';
import AddressItem from './AddressItem';
import Typography from '@material-ui/core/Typography';

const AddressItemList = (props) => {
  const { locationData } = props;

  const addresses = () =>
    locationData.map((ldo) =>
      ldo ? <AddressItem key={ldo.id} locationInfo={ldo} /> : null
    );

  return (
    <>
      <p className="title">Addresses:</p>
      <div className='list-items-inner'>
        {locationData[0] ? addresses() : <Typography>N/A</Typography>}
      </div>
    </>
  );
};

export default AddressItemList;
