import React from 'react';
import 'date-fns';
import 'react-multi-carousel/lib/styles.css';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

import Carousel from '../../../components/Carousel';
import SmallVideo from '../../../components/MediaStreaming/Scheduler/smallVideo';

const mockdata = [];

for (let i = 0; i <= 10; i++) {
  mockdata.push({
    video_url: '',
    title: 'Coming Soon',
    update_date: '2021-04-10T13:52:50.298294+00:00',
  });
}

const useStyles = makeStyles((theme) => ({
  body: {
    height: 347,
  },
  toolbar: {
    padding: '0px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      color: '#8EA4C6',
    },
  },
  title: {
    fontSize: 18,
    color: '#8EA4C6',
    fontWeight: 700,
  },
}));

const SmallCarousel = (props) => {
  const { title, data, handleSettingOpen } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.toolbar}>
        <label className={classes.title}>{title}</label>
        <IconButton onClick={() => handleSettingOpen(data.type)}>
          <SettingsIcon />
        </IconButton>
      </div>
      <div className={classes.body}>
        <Carousel items={data.breakpoints}>
          {mockdata.map((data, index) => {
            return <SmallVideo data={data} key={index} />;
          })}
        </Carousel>
      </div>
    </>
  );
};

export default SmallCarousel;
