import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const NotApplicableCheck = (props) => {
  const { isChecked, onChange } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            name="not-applicable"
          />
        }
        label="Not Applicable"
      />
    </FormGroup>
  );
};

export default NotApplicableCheck;
