import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
// import { format } from 'date-fns';
import { capitalize } from 'lodash';

import MoreActions from './MoreActions';
import { apiUrl } from '../../config/api';
import { convertSecondsToHHMMSSFF } from '../../utils/streaming';
import Modal from '../Modal/modal';

import { defaultDateTimeZone } from '../../utils/calendar';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& .MuiCardHeader-root': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
    '& .MuiCardContent-root': {
      paddingTop: 0,
    },
  },
  image: {
    height: 208,
    background: '#5F5F5F',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: '#FFFFFF',
    fontSize: 16,
    marginBottom: 14,
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  title: {
    fontSize: 15,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 13,
    marginBottom: 2,
    color: '#5F5F5F',
  },
  dateTime: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    color: '#5F5F5F',
    '& svg': {
      marginRight: 5,
      color: '#8DA3C5',
    },
  },
  memberName: {
    fontSize: 13,
    color: '#8DA3C5',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    alignItems: 'center',
  },
  description: {
    padding: 2,
    border: '1px solid #5F5F5F',
    borderRadius: 4,
    '&:hover': {
      color: '#FFFFFF',
      background: '#5F5F5F',
    },
  },
  margin0: {
    margin: 0,
  },
});

const StreamingSmallCard = (props) => {
  const { data, handleVideoPlayerOpen } = props;
  const classes = useStyles();
  const [showDescriptionModal, setShowDescriptionModal] = React.useState(false);

  const url = data.video_url ? `${apiUrl}${data.video_url}` : '';
  const thumbnail_url = data.thumbnail_url
    ? `${apiUrl}${data.thumbnail_url}`
    : '';

  const handleShowModal = (e) => {
    e.stopPropagation();
    setShowDescriptionModal(true);
  };

  const handleCloseModal = (e) => {
    setShowDescriptionModal(false);
  };

  const content = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: data.description ? data.description : '',
      }}
    ></div>
  );

  return (
    <Card className={classes.root}>
      <CardHeader action={<MoreActions data={data} />} />
      <CardActionArea>
        <CardContent onClick={() => handleVideoPlayerOpen(url)}>
          <div className={classes.image}>
            {data.video_url ? (
              thumbnail_url ? (
                <img
                  src={thumbnail_url}
                  alt="thumbnail"
                  className={classes.thumbnail}
                />
              ) : (
                'No thumbnail'
              )
            ) : (
              'No Stream'
            )}
          </div>
          <div className={classes.flexRow}>
            <div className={classNames(classes.title, classes.textEllipsis)}>
              {data.title}
            </div>
            <div>
              {data.duration ? convertSecondsToHHMMSSFF(+data.duration) : ''}
            </div>
          </div>
          <div className={classNames(classes.flexRow, classes.margin0)}>
            <div className={classNames(classes.dateTime, classes.textEllipsis)}>
              <span className={classes.textEllipsis}>
                {data.update_date ? (
                  // format(new Date(data.update_date), 'Pp')
                  defaultDateTimeZone(data.update_date)
                ) : (
                  <br />
                )}
              </span>
            </div>
            {data.description && (
              <span className={classes.description} onClick={handleShowModal}>
                Description
              </span>
            )}
          </div>
          <div className={classNames(classes.memberName, classes.textEllipsis)}>
            {data.first_name ? (
              `${capitalize(data.first_name)} ${capitalize(data.last_name)}`
            ) : (
              <br />
            )}
          </div>
        </CardContent>
      </CardActionArea>
      {showDescriptionModal && data.description && (
        <Modal
          open={showDescriptionModal}
          onClose={handleCloseModal}
          title={data.title ? data.title : 'Description'}
          ModalContent={content}
          className={'description-modal'}
        />
      )}
    </Card>
  );
};

export default StreamingSmallCard;
