import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const AmeraSearch = (props) => {
  const {
    searchInput,
    handleChange,
    label = 'Search',
    variant = 'outlined',
  } = props;

  const onChange = (e) => handleChange(e.target.value);

  return (
    <TextField
      label={label}
      fullWidth
      id={label}
      variant={variant}
      InputProps={{
        endAdornment: (
          <InputAdornment postion="end">
            <IconButton onClick={() => handleChange('')} style={{ padding: 4 }}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment postion="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={searchInput}
      onChange={onChange}
    />
  );
};

export default AmeraSearch;
