import React from 'react';
import TextField from '@material-ui/core/TextField';
import DateInput from '../../Modal/EventModals/DateInput';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListDeleteButton from '../../../pages/Profile/ProfileEdit/ListDeleteButton';
import StreetAutocomplete from '../../../components/StreetAutocomplete';
import { addDays } from 'date-fns';

const EducationItem = (props) => {
  const { setEducations, data, error } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          value={data.school_name}
          label="School Name"
          required
          error={!!error?.school_name}
          helperText={!!error?.school_name ? error?.school_name : ''}
          onChange={(e) =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationEditItem,
              payload: {
                education_id: data.id,
                field: 'school_name',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <StreetAutocomplete
          street={data.school_location}
          label="Location"
          variant="standard"
          handleChange={(e) =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationEditItem,
              payload: {
                education_id: data.id,
                field: 'school_location',
                value: e.description,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={data.degree}
          label="Degree"
          placeholder="MBA, MSc.."
          onChange={(e) =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationEditItem,
              payload: {
                education_id: data.id,
                field: 'degree',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          value={data.field_of_study}
          label="Field of Study"
          placeholder="Rocket science, Heart Surgery.."
          fullWidth
          required
          error={!!error?.field_of_study}
          helperText={!!error?.field_of_study ? error?.field_of_study : ''}
          onChange={(e) =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationEditItem,
              payload: {
                education_id: data.id,
                field: 'field_of_study',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={5}>
        <DateInput
          value={data.start_date}
          label="From"
          inputVariant="standard"
          onChange={(d) =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationEditItem,
              payload: {
                education_id: data.id,
                field: 'start_date',
                value: d,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={5}>
        <DateInput
          value={data.end_date}
          label="To"
          inputVariant="standard"
          onChange={(d) =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationEditItem,
              payload: {
                education_id: data.id,
                field: 'end_date',
                value: d,
              },
            })
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!!data.end_date}
              onChange={() =>
                setEducations({
                  type: PROFILE_EDIT_ACTIONS.educationEditItem,
                  payload: {
                    education_id: data.id,
                    field: 'end_date',
                    value: !!!data.end_date
                      ? addDays(data.start_date, 1)
                      : null,
                  },
                })
              }
              name="end_date_present"
            />
          }
          label="present"
        />
      </Grid>
      <Grid item xs={2}>
        <ListDeleteButton
          id={data.id}
          handleDelete={() =>
            setEducations({
              type: PROFILE_EDIT_ACTIONS.educationDelete,
              payload: { education_id: data.id },
            })
          }
          listContext="education history item"
        >
          Delete Item
        </ListDeleteButton>
      </Grid>
    </Grid>
  );
};

export default EducationItem;
