import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from '../Sections/Layout';
import TopicList from './TopicList';
import Topic from './Topic';

const Forum = (props) => {
  return (
    <Layout {...props}>
      <Switch>
        <Redirect
          from={`${process.env.PUBLIC_URL}/groups/:group_id/forum`}
          to={`${process.env.PUBLIC_URL}/groups/:group_id/forum/topics`}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/groups/:group_id/forum/topics`}
          component={TopicList}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/groups/:group_id/forum/topics/:topic_id`}
          component={Topic}
        />
      </Switch>
    </Layout>
  );
};

export default Forum;
