import { ApiEmailService } from '../../config/apiService';
import { GET_BADGE_COUNT, SET_BADGE_COUNT } from '../actionTypes/mail';

export const setBadgeCount = () => (dispatch) => {
  try {
    dispatch({
      type: GET_BADGE_COUNT,
    });
    ApiEmailService.getEmailUnreadCount().then((count) => {
      dispatch({
        type: SET_BADGE_COUNT,
        payload: count,
      });
    });
  } catch (error) {
    dispatch({
      type: SET_BADGE_COUNT,
      payload: 0,
    });
  }
};
