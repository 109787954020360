import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './ProjectDetails.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import TasksSelect from './TasksSelect';
import FilterSelect from './FilterSelect';
// import SortSelect from './SortSelect';
import ProjectTree from './ProjectTree';
import { createDataTree } from '../../../../utils/project';
import AddProjectElements from './AddProjectElements';
import ProjectSearch from './ProjectSearch';
import {
  projectfilterOptions,
  projectOptionsKeyMap,
  projectTaskStatusOptions,
} from '../../../../utils/project';

const ProjectDetails = (props) => {
  const useStyles = makeStyles((theme) => ({
    topSelectDiv: {
      marginLeft: 'auto',
      marginRight: '20px',
    },
    select: {
      width: '170px',
    },
    search: {
      width: '250px !important',
      marginLeft: 'auto',
    },
  }));
  const classes = useStyles();
  let { projectId } = useParams();
  // selectors
  let projects = [];
  projects = useSelector((state) => {
    return state?.project?.projects.filter((o) => o.project_id === +projectId);
  });

  const loggedInUserInfo = useSelector((state) => {
    return state.member.member;
  });
  const projectElements =
    projects[0] &&
    projects[0].project_elements &&
    projects[0].project_elements.filter((e) => {
      if (
        e.status_history &&
        e.status_history[e.status_history.length - 1].element_status ===
          'delete'
      ) {
        return false;
      }
      return true;
    });
  const [flat, setFlat] = useState(projects.length && projectElements);
  useEffect(() => {
    if (projects.length) {
      setFlat(projectElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectElements?.length]);
  const treeData = createDataTree(flat);
  const dataDetails = projects && projects[0];
  const members = dataDetails.project_members;

  const defaultFilter = {
    task: 'All',
    filter: 'Filter',
    sort: '',
  };
  // eslint-disable-next-line
  const [filters, setFilters] = useState(defaultFilter);
  function getAllParentChildIdsFinalForFilter(
    projectElements,
    allChildIdsMap,
    allParentIdsMap,
    taskIds
  ) {
    let parentChildFinalIds = [];
    projectElements
      .filter((c) => taskIds.includes(c.project_element_id))
      .map((o) => o.project_element_id)
      .forEach((o) => {
        parentChildFinalIds = [
          ...parentChildFinalIds,
          ...allParentIdsMap[o],
          ...allChildIdsMap[o],
        ];
      });

    return parentChildFinalIds.map((o) => +o);
  }
  const handleTasksSelect = (e) => {
    let filters = {
      ...defaultFilter,
      task: projectOptionsKeyMap[e.target.value].display,
    };

    setFilters(filters);

    let taskIds = [];

    if (projectTaskStatusOptions[0].key === e.target.value) {
      // key "all"
      setFlat(projects[0].project_elements);
    } else {
      // completed, in progress
      taskIds = getStatusTaskIds(projects[0].project_elements, e.target.value);
      let allChildIdsMap = getAllChildIdsMap(projectElements);
      let allParentIdsMap = getAllParentIdsMap(projectElements);
      let allParentChildIdsFinals = [
        ...new Set(
          getAllParentChildIdsFinalForFilter(
            projectElements,
            allChildIdsMap,
            allParentIdsMap,
            taskIds
          )
        ),
      ];

      const filteredStatusFlat = projectElements.filter((o) => {
        return allParentChildIdsFinals.includes(o.project_element_id);
      });

      setFlat(filteredStatusFlat);
    }
  };
  const handleFilterSelect = (e) => {
    let filters = {
      ...defaultFilter,
      filter: projectOptionsKeyMap[e.target.value].display,
    };
    setFilters(filters);
    let filteredFlat = [];
    if (e.target.value === projectfilterOptions[1].key) {
      // key "assignToMe"
      let taskIds = [];
      taskIds = getFilterTaskIds(projectElements, e.target.value);
      let allChildIdsMap = getAllChildIdsMap(projectElements);
      let allParentIdsMap = getAllParentIdsMap(projectElements);
      let allParentChildIdsFinals = [
        ...new Set(
          getAllParentChildIdsFinalForFilter(
            projectElements,
            allChildIdsMap,
            allParentIdsMap,
            taskIds
          )
        ),
      ];

      filteredFlat = projectElements.filter((o) => {
        return allParentChildIdsFinals.includes(o.project_element_id);
      });
    } else if (e.target.value === projectfilterOptions[0].key) {
      // key "Any"
      filteredFlat = projects[0].project_elements;
    }
    setFlat(filteredFlat);
  };
  // const handleSortSelect = (e) => {
  //   setFilters((event) => ({ ...event, sort: e.target.value }));
  // };

  function maketree(categories, parent) {
    let node = {};
    categories
      .filter((c) => c.parent_id === parent)
      .forEach(
        (c) =>
          (node[c.project_element_id] = maketree(
            categories,
            c.project_element_id
          ))
      );

    return node;
  }

  function getAllChildIdsMap(projectElements) {
    let childMap = {};
    function getChilderns(tree) {
      if (!Object.keys(tree).length) {
        return [];
      }

      let childIds = [];

      Object.keys(tree).forEach((tk) => {
        childMap[tk] = getChilderns(tree[tk]);
      });

      Object.keys(tree).forEach((tk) => {
        childIds = [...childIds, tk, ...childMap[tk]];
      });
      return childIds;
    }
    let tree = maketree(projectElements, null);

    getChilderns(tree);
    return childMap;
  }

  function getAllParentIdsMap(projectElements) {
    let idsParentHashmap = {};
    projectElements.forEach((c) => {
      if (!idsParentHashmap[c.project_element_id]) {
        idsParentHashmap[c.project_element_id] =
          c.parent_id === null
            ? [c.project_element_id]
            : [c.project_element_id, ...idsParentHashmap[c.parent_id]];
      }
    });
    return idsParentHashmap;
  }

  function getAllParentChildIdsFinal(
    projectElements,
    allChildIdsMap,
    allParentIdsMap,
    inputValue
  ) {
    let parentChildFinalIds = [];
    projectElements
      .filter((c) => c.title.toLowerCase().includes(inputValue))
      .map((o) => o.project_element_id)
      .forEach((o) => {
        parentChildFinalIds = [
          ...parentChildFinalIds,
          ...allParentIdsMap[o],
          ...allChildIdsMap[o],
        ];
      });

    return parentChildFinalIds.map((o) => +o);
  }

  function getSearchProjectInput(inputValue) {
    if (inputValue) {
      let allChildIdsMap = getAllChildIdsMap(projectElements);
      let allParentIdsMap = getAllParentIdsMap(projectElements);
      let allParentChildIdsFinals = [
        ...new Set(
          getAllParentChildIdsFinal(
            projectElements,
            allChildIdsMap,
            allParentIdsMap,
            inputValue.toLowerCase()
          )
        ),
      ];

      const searchedFlat = projectElements.filter((o) => {
        return allParentChildIdsFinals.includes(o.project_element_id);
      });

      setFlat(searchedFlat);
    } else {
      setFlat(projectElements);
    }
  }

  function getStatusTaskIds(projectElements, selectFilterValue) {
    let taskIds = [];
    projectElements.forEach((o) => {
      if (
        o.status_history &&
        o.status_history[o.status_history.length - 1].element_status ===
          selectFilterValue
      ) {
        taskIds.push(o.project_element_id);
      }
      if (
        o.element_type === 'task' &&
        o.status_history === null &&
        selectFilterValue === projectOptionsKeyMap[selectFilterValue].key
      ) {
        taskIds.push(o.project_element_id);
      }
    });

    return taskIds;
  }

  function getFilterTaskIds(projectElements, selectFilterValue) {
    let taskIds = [];
    projectElements.forEach((o) => {
      if (o.contract_id && o.contract_id === loggedInUserInfo.member_id) {
        taskIds.push(o.project_element_id);
      }
    });

    return taskIds;
  }

  //console.log('ProjectDetails.js - Flat to Tree', projects);

  return (
    <div className={styles.projectDetails}>
      <div className={styles.topBreadCrumb}>
        <div style={{ marginTop: '5px' }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="inherit" to="/projects">
              {dataDetails?.project_title}
            </Link>
            <Typography color="textPrimary">Project Details</Typography>
          </Breadcrumbs>
        </div>
        <div className={`${classes.topSelectDiv} project-filters`}>
          <Grid container spacing={2}>
            <Grid item className={classes.search}>
              <ProjectSearch getSearchProjectInput={getSearchProjectInput} />
            </Grid>
            <Grid item className={classes.select}>
              <TasksSelect
                label={filters.task}
                task={defaultFilter.task}
                handleChange={handleTasksSelect}
              />
            </Grid>
            <Grid item className={classes.select}>
              <FilterSelect
                label={filters.filter}
                filter={defaultFilter.filter}
                handleChange={handleFilterSelect}
              />
            </Grid>
            {/* <Grid item className={classes.select}>
              <SortSelect
                sort={defaultFilter.sort}
                handleChange={handleSortSelect}
              />
            </Grid> */}
          </Grid>
        </div>
      </div>
      <hr />
      <div className="project-details-container">
        <ProjectTree projectId={projectId} data={treeData} members={members} />
        <div style={{ marginTop: '20px' }}>
          <AddProjectElements
            projectId={projectId}
            element_type="project"
            element_id="null"
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
