import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CommentIcon from '@material-ui/icons/Comment';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import ContactsIcon from '@material-ui/icons/Contacts';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import EmailIcon from '@material-ui/icons/Email';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import CallIcon from '@material-ui/icons/Call';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

import Conversations from './Conversations/index';
import Contacts from './Contacts/index';

import { dark, green, borderDark } from '../../../../../jss/colors';

const AntTabs = withStyles({
  indicator: {
    backgroundColor: borderDark,
    height: 0,
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontSize: 10,
    minWidth: 68,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 0,
    padding: 0,
    '&:hover': {
      color: dark,
      opacity: 1,
    },
    '&$selected': {
      color: green,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`chat-tabpanel-${index}`}
      aria-labelledby={`chat-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};
export default function ChatTabsPanel(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="chat-tabs-panel">
      <AntTabs value={value} onChange={handleChange} aria-label="ant-chat-tabs">
        <AntTab
          icon={
            value === 0 ? (
              <CommentIcon fontSize="default" />
            ) : (
              <CommentOutlinedIcon fontSize="default" />
            )
          }
          label="Conversations"
        />
        <AntTab
          icon={
            value === 1 ? (
              <ContactsIcon fontSize="default" />
            ) : (
              <ContactsOutlinedIcon fontSize="default" />
            )
          }
          label="Contacts"
        />
        <AntTab
          icon={
            value === 2 ? (
              <EmailIcon fontSize="default" />
            ) : (
              <EmailOutlinedIcon fontSize="default" />
            )
          }
          label="Message"
        />
        <AntTab
          icon={
            value === 3 ? (
              <VideocamIcon fontSize="default" />
            ) : (
              <VideocamOutlinedIcon fontSize="default" />
            )
          }
          label="Video"
        />
        <AntTab
          icon={
            value === 4 ? (
              <CallIcon fontSize="default" />
            ) : (
              <CallOutlinedIcon fontSize="default" />
            )
          }
          label="Voice"
        />
      </AntTabs>
      <TabPanel value={value} index={0}>
        <Conversations {...props} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Contacts />
      </TabPanel>
    </div>
  );
}
