import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './ModalStyles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AmeraSelect from '../../../../components/AmeraSelect';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmationModal from '../Modals/ConfirmationModal';
import { ProjectsApi } from '../../../../redux/actions/project';

const EditTaskModal = ({
  open,
  handleClose,
  project_id,
  parent_id,
  element_id,
  name,
  text,
  // members,
  preEst_hours,
  member_id,
  type,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const projects = useSelector((state) => {
    return state?.project?.projects.filter((o) => o.project_id === +project_id);
  });
  const membersMap = projects[0]?.project_members;

  // eslint-disable-next-line
  const privilegeMember = membersMap.map((member) => {
    if (
      member.privileges.includes('create') ||
      member.privileges.includes('edit')
    ) {
      return member;
    }
  });

  const ableMember = privilegeMember.filter(function (element) {
    return element !== undefined;
  });

  const preHours = preEst_hours && preEst_hours.substr(6, 4);

  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);

  const [title, setTitle] = useState(name);
  const [description, setDescription] = useState(text);
  const [assign_to, setAssign_to] = useState(member_id);
  const [est_hours, setEst_hours] = useState(Number(preHours));

  const getContractId = (project_mem_id) => {
    const contractObj = projects[0].contracts.filter((c) => {
      return c.rate_type === 'hourly' && c.project_member_id === project_mem_id;
    });
    return contractObj[0].contract_id;
  };

  const form = {
    project_id: Number(project_id),
    parent_id: type === 'epic' ? null : parent_id,
    element_type: type,
    title: title,
    description: description,
    // assign_to: assign_to,
    contract_id:
      Number(assign_to) === 0 ? null : getContractId(Number(assign_to)),
    est_hours: Number(est_hours),
  };

  const submitHandler = () => {
    handleSubmit(form, element_id);
    setTitle(name);
    setDescription(text);
    setAssign_to(Number(assign_to));
    setEst_hours('');
  };

  function handleDelete() {
    setOpenDeleteConfirmationModal(true);
  }

  function handleDeleteConfirm() {
    dispatch(ProjectsApi.deleteProjectElement(element_id));
    setOpenDeleteConfirmationModal(false);
  }

  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <div className={classes.modalTop}>
          <div>
            <h6 className={classes.modalTopText}>Add {name}</h6>
          </div>
          <div onClick={handleClose}>
            <IconButton className={classes.modalTopButton} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.field}>
            <TextField
              label="Title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.field}>
            <TextareaAutosize
              className={classes.inputDescription}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rowsMin={5}
              placeholder="Description"
            />
          </FormControl>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.field}>
              <AmeraSelect
                id={parent_id}
                label="Assign to"
                value={assign_to}
                onChange={(e) => setAssign_to(e.target.value)}
              >
                {ableMember &&
                  ableMember.map((member) => (
                    <MenuItem
                      key={member.project_member_id}
                      id={member.project_member_id}
                      value={member.project_member_id}
                    >
                      {member.first_name} {member.last_name}
                    </MenuItem>
                  ))}
              </AmeraSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.field}>
              <TextField
                label="Estimated Hours"
                type="Number"
                value={est_hours}
                onChange={(e) => setEst_hours(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          onClick={handleDelete}
          variant="contained"
          color="secondary"
        >
          <span className={classes.buttonText}>Delete</span>
        </Button>
        <Button
          onClick={submitHandler}
          className={classes.button}
          disabled={!title && true}
          variant="contained"
          color="primary"
        >
          <span className={classes.buttonText}>Edit</span>
        </Button>

        <ConfirmationModal
          title={`Delete task "${title}"`}
          confirm={handleDeleteConfirm}
          open={openDeleteConfirmationModal}
          bodyMessage={`Are you sure you want this task "${title}"`}
          closeFn={() => {
            setOpenDeleteConfirmationModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default EditTaskModal;
