import {
  GET_ACTIVITY_ACTIVITY_SUCCEEDED,
  GET_ACTIVITY_ACTIVITY_FAILED,
  GET_ACTIVITY_SECURITY_SUCCEEDED,
  GET_ACTIVITY_SECURITY_FAILED,
  GET_ACTIVITY_MESSAGE_SUCCEEDED,
  GET_ACTIVITY_MESSAGE_FAILED,
  GET_ACTIVITY_GROUP_SUCCEEDED,
  GET_ACTIVITY_GROUP_FAILED,
  GET_ACTIVITY_INVITATION_SUCCEEDED,
  GET_ACTIVITY_INVITATION_FAILED,
} from '../actionTypes/activity';

import { APP_RESET } from '../actionTypes/app';

export const initialState = {
  loading: false,
  activity: [],
  security: [],
  message: [],
  group: [],
  invitation: [],
  activityCount: null,
  securityCount: null,
  messageCount: null,
  groupCount: null,
  invitationCount: null,
};

const activity = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case GET_ACTIVITY_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        activity: action.payload.activities,
        activityCount: action.payload.count,
        loading: false,
      };

    case GET_ACTIVITY_ACTIVITY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACTIVITY_SECURITY_SUCCEEDED:
      return {
        ...state,
        security: action.payload.activities,
        securityCount: action.payload.count,
        loading: false,
      };

    case GET_ACTIVITY_SECURITY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACTIVITY_MESSAGE_SUCCEEDED:
      if (action.videoOnly) {
        return {
          ...state,
          videoMessage: action.payload.activities,
          videoMessageCount: action.payload.count,
          loading: false,
        };
      }
      return {
        ...state,
        message: action.payload.activities,
        messageCount: action.payload.count,
        loading: false,
      };

    case GET_ACTIVITY_MESSAGE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACTIVITY_GROUP_SUCCEEDED:
      return {
        ...state,
        group: action.payload.group_invitations,
        groupCount: action.payload.count,
        loading: false,
      };

    case GET_ACTIVITY_GROUP_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_ACTIVITY_INVITATION_SUCCEEDED:
      return {
        ...state,
        invitation: action.payload.invitations,
        invitationCount: action.payload.count,
        loading: false,
      };

    case GET_ACTIVITY_INVITATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default activity;
