import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './ModalStyles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import AmeraSelect from '../../../../components/AmeraSelect';
import RateInput from '../../../../components/RateInput';
import MenuItem from '@material-ui/core/MenuItem';

import ConfirmationModal from '../Modals/ConfirmationModal';
import { ProjectsApi } from '../../../../redux/actions/project';

const EditTaskModal = ({
  open,
  handleClose,
  heading = 'milestone',
  project_id,
  parent_id,
  element_id,
  name,
  text,
  members,
  member_id,
  preDate,
  preRate,
  preCurrencyId,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const projects = useSelector((state) => {
    return state?.project?.projects.filter((o) => o.project_id === +project_id);
  });
  const membersMap = projects[0]?.project_members;

  // eslint-disable-next-line
  const privilegeMember = membersMap.map((member) => {
    if (
      member.privileges.includes('create') ||
      member.privileges.includes('edit')
    ) {
      return member;
    }
  });

  const ableMember = privilegeMember.filter(function (element) {
    return element !== undefined;
  });

  const [
    openDeleteConfirmationModal,
    setOpenDeleteConfirmationModal,
  ] = useState(false);

  const [title, setTitle] = useState(name);
  const [description, setDescription] = useState(text);
  const [assign_to, setAssign_to] = useState(member_id);
  const [est_date, setEst_date] = useState(
    moment(preDate).format('DD-MM-YYYY')
  );
  const [rate, setRate] = useState(preRate);
  const [currencyId, setCurrencyId] = useState(preCurrencyId);

  const form = {
    project_id: Number(project_id),
    project_member_id: Number(assign_to),
    parent_id: heading === 'Epic' ? null : parent_id,
    element_type: 'milestone',
    title: title,
    description: description,
    pay_rate: Number(rate),
    currency_code_id: Number(currencyId),
    due_date: est_date,
  };

  const submitHandler = () => {
    handleSubmit(form, element_id);
    setTitle(name);
    setDescription(text);
    setAssign_to(Number(assign_to));
    setEst_date(preDate);
    setRate(preRate);
    setCurrencyId(preCurrencyId);
  };

  function handleDelete() {
    setOpenDeleteConfirmationModal(true);
  }

  function handleDeleteConfirm() {
    dispatch(ProjectsApi.deleteProjectElement(element_id));
    setOpenDeleteConfirmationModal(false);
  }

  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <div className={classes.modalTop}>
          <div>
            <h6 className={classes.modalTopText}>Edit {name}</h6>
          </div>
          <div onClick={handleClose}>
            <IconButton className={classes.modalTopButton} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />

        <Grid container spacing={3}>
          <Grid item xs={7}>
            <FormControl variant="outlined" className={classes.field}>
              <TextField
                label="Title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl variant="outlined" className={classes.field}>
              <RateInput
                rate={rate}
                onRateChange={(e) => setRate(e.target.value)}
                currencyId={currencyId}
                label="Total Amount"
                onCurrencyChange={(e) => setCurrencyId(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.field}>
            <TextareaAutosize
              className={classes.inputDescription}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rowsMin={5}
              placeholder="Description"
            />
          </FormControl>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.field}>
              <AmeraSelect
                id={parent_id}
                label="Assign to"
                value={assign_to}
                onChange={(e) => setAssign_to(e.target.value)}
              >
                {ableMember &&
                  ableMember.length > 0 &&
                  ableMember.map((member) => (
                    <MenuItem
                      key={member.project_member_id}
                      id={member.project_member_id}
                      value={member.project_member_id}
                    >
                      {member.first_name} {member.last_name}
                    </MenuItem>
                  ))}
              </AmeraSelect>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.field}>
              <TextField
                label="Estimated Date"
                type="date"
                value={est_date}
                onChange={(e) => setEst_date(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          className={classes.button}
          onClick={handleDelete}
          variant="contained"
          color="secondary"
        >
          <span className={classes.buttonText}>Delete</span>
        </Button>
        <Button
          onClick={submitHandler}
          className={classes.button}
          disabled={!title && true}
          variant="contained"
          color="primary"
        >
          <span className={classes.buttonText}>Edit</span>
        </Button>

        <ConfirmationModal
          title={`Delete milestone "${title}"`}
          confirm={handleDeleteConfirm}
          open={openDeleteConfirmationModal}
          bodyMessage={`Are you sure you want this milestone "${title}"`}
          closeFn={() => {
            setOpenDeleteConfirmationModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default EditTaskModal;
