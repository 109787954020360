import axios from 'axios';

import * as api from '../../config/api';
import { setSnackbarData } from './snackbar';

import {
  GET_STREAM_VIDEO_SUCCEEDED,
  GET_STREAM_VIDEO_FAILED,
  POST_STREAM_CONTENT_SUCCEEDED,
  POST_STREAM_CONTENT_FAILED,
  GET_STREAM_TYPE_FAILED,
  GET_STREAM_TYPE_SUCCEEDED,
  GET_STREAM_CATEGORY_FAILED,
  GET_STREAM_CATEGORY_SUCCEEDED,
  DELETE_STREAM_VIDEO_SUCCEEDED,
  DELETE_STREAM_VIDEO_FAILED,
  UPDATE_STREAM_VIDEO_SUCCEEDED,
  UPDATE_STREAM_VIDEO_FAILED,
} from '../actionTypes/stream.js';

const apiUrl = api.apiUrl;
const cancelToken = axios.CancelToken;
var source;

export const uploadVideo = (formData, setProgress, setUploadProgressShow) => {
  const url = `${api.apiUrl}/streaming/upload-video`;
  const CANCEL_UPLOAD_MESSAGE = 'CANCEL_UPLOADS';

  if (source) source.cancel(CANCEL_UPLOAD_MESSAGE);
  source = cancelToken.source();

  return function (dispatch) {
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
      cancelToken: source.token,
    };

    setUploadProgressShow(true);

    return axios
      .post(url, formData, config)
      .then((res) => {
        setUploadProgressShow(false);
        if (res.data.success) {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Uploaded video successfully',
              type: 'success',
            })
          );

          return res.data.data;
        } else {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Uploading video failed. Try some later!',
              type: 'error',
            })
          );

          return null;
        }
      })
      .catch((error) => {
        if (error.message !== CANCEL_UPLOAD_MESSAGE) {
          setUploadProgressShow(false);
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Uploading video failed. Try some later!',
              type: 'error',
            })
          );
        }

        return null;
      })
      .finally(() => {
        setProgress(0);
      });
  };
};

export const uploadContents = (formData) => {
  const url = `${api.apiUrl}/streaming/upload`;

  return function (dispatch) {
    return api
      .POST(url, formData)
      .then((res) => {
        if (res.data) {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Uploaded Content successfully',
              type: 'success',
            })
          );
          dispatch({
            type: POST_STREAM_CONTENT_SUCCEEDED,
            payload: res.data,
          });
        } else {
          dispatch(
            setSnackbarData({
              open: true,
              message: 'Uploading Content failed. Try some later!',
              type: 'error',
            })
          );
          dispatch({ type: POST_STREAM_CONTENT_FAILED });
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Uploading Content failed. Try some later!',
            type: 'error',
          })
        );
        dispatch({ type: POST_STREAM_CONTENT_FAILED });
      });
  };
};

export const getStreamVideos = (setting, isAll) => (dispatch) => {
  const url = `${apiUrl}/streaming/videos`;
  let params = `?types=${Object.values(
    setting
  ).toString()}&categories=${Object.keys(setting).toString()}`;

  api
    .GET(`${url}${params}`)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: GET_STREAM_VIDEO_SUCCEEDED,
          payload: res.data,
          isAll: isAll,
          category: !isAll ? Object.keys(setting)[0] : '',
        });
      } else {
        dispatch({ type: GET_STREAM_VIDEO_FAILED });
      }
    })
    .catch((error) => {
      dispatch({ type: GET_STREAM_VIDEO_FAILED });
      console.error(error);
    });
};

export const getStreamCategories = () => (dispatch) => {
  const url = `${apiUrl}/streaming/categories`;

  api
    .GET(url)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: GET_STREAM_CATEGORY_SUCCEEDED,
          payload: res.data,
        });
      } else {
        dispatch({ type: GET_STREAM_CATEGORY_FAILED });
      }
    })
    .catch((error) => {
      dispatch({ type: GET_STREAM_CATEGORY_FAILED });
      console.error(error);
    });
};

export const getStreamTypes = () => (dispatch) => {
  const url = `${apiUrl}/streaming/types`;

  api
    .GET(url)
    .then((res) => {
      if (res && res.data) {
        dispatch({
          type: GET_STREAM_TYPE_SUCCEEDED,
          payload: res.data,
        });
      } else {
        dispatch({ type: GET_STREAM_TYPE_FAILED });
      }
    })
    .catch((error) => {
      dispatch({ type: GET_STREAM_TYPE_FAILED });
      console.error(error);
    });
};

export const deleteStreamVideo = (id) => (dispatch) => {
  const url = `${apiUrl}/streaming/video/${id}`;

  api
    .DELETE(url)
    .then((res) => {
      if (res && res.data) {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Deleted video successfully',
            type: 'success',
          })
        );
        dispatch({
          type: DELETE_STREAM_VIDEO_SUCCEEDED,
          payload: res.data,
        });
      } else {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Deleting video failed. Try some later!',
            type: 'error',
          })
        );
        dispatch({ type: DELETE_STREAM_VIDEO_FAILED });
      }
    })
    .catch((error) => {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Deleting video failed. Try some later!',
          type: 'error',
        })
      );
      dispatch({ type: DELETE_STREAM_VIDEO_FAILED });
      console.error(error);
    });
};

export const editVideoInfo = (formData, id) => (dispatch) => {
  const url = `${apiUrl}/streaming/video/${id}`;

  api
    .PUT(url, formData)
    .then((res) => {
      if (res && res.data) {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Saved Content successfully',
            type: 'success',
          })
        );
        dispatch({
          type: UPDATE_STREAM_VIDEO_SUCCEEDED,
          payload: res.data,
        });
      } else {
        dispatch(
          setSnackbarData({
            open: true,
            message: 'Saving Content failed. Try some later!',
            type: 'error',
          })
        );
        dispatch({ type: UPDATE_STREAM_VIDEO_FAILED });
      }
    })
    .catch((error) => {
      dispatch(
        setSnackbarData({
          open: true,
          message: 'Saving Content failed. Try some later!',
          type: 'error',
        })
      );
      dispatch({ type: UPDATE_STREAM_VIDEO_FAILED });
      console.error(error);
    });
};
