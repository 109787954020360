import * as api from '../../config/api';
import {
  SCHEDULER_SETTING_API_CALL_START,
  SCHEDULER_SETTING_API_CALL_SUCCESS,  
  SCHEDULER_SETTING_API_CALL_FAIL
} from '../actionTypes/schedule.js';

const apiUrl = api.apiUrl;

export const getSchedulerSetting = () => (dispatch) => {
  const url = `${apiUrl}/member/scheduler/setting`;
  dispatch(apiSchedulerSettingStart());
  
  api
    .GET(url)
    .then((res) => {
      dispatch(apiSchedulerSettingSuccess(res.data));
    })
    .catch((err) => {
      //console.log(err);
      dispatch(apiSchedulerSettingFail());
    });
}

export const setSchedulerSetting = (formData) => (dispatch) => {
  const url = `${apiUrl}/member/scheduler/setting`;
  dispatch(apiSchedulerSettingStart());
  api
    .POST(
      url,
      JSON.stringify(formData), 
      { 'Content-Type': 'application/json' }
    )
    .then((res) => {
      dispatch(apiSchedulerSettingSuccess(formData));
    })
    .catch((err) => {
      console.log(err);
      dispatch(apiSchedulerSettingFail());
    });
}

const apiSchedulerSettingStart = (payload) => ({
  type: SCHEDULER_SETTING_API_CALL_START
})

const apiSchedulerSettingSuccess = (payload) => ({
  type: SCHEDULER_SETTING_API_CALL_SUCCESS,
  payload,
})

const apiSchedulerSettingFail = () => ({
  type: SCHEDULER_SETTING_API_CALL_FAIL,
})
