import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const ListDeleteButton = (props) => {
  const [isShow, setShow] = useState(false);

  const { handleDelete, isDisabled, id, listContext } = props;

  const listContexts = {
    phone: {
      sorter: ['display_order'],
      listType: 'phoneData',
    },
    email: {
      sorter: ['display_order'],
      listType: 'emailData',
    },
    location: {
      sorter: ['id'],
      listType: 'locationData',
    },
    achievement: {
      sorter: ['display_order'],
      listType: 'achievementData',
    },
  };

  const handleDeleteClick = () => {
    setShow(true);
  };
  const handleCloseDialog = () => {
    setShow(false);
  };

  const handleDeleteConfirm = () => {
    handleDelete(
      id,
      listContexts[listContext]?.listType,
      listContexts[listContext]?.sorter
    );
    handleCloseDialog();
  };

  const handleDeleteCancel = () => handleCloseDialog();

  return (
    <>
      <Dialog open={isShow} onClose={handleCloseDialog}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this item?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleDeleteConfirm}
            color="secondary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <BootstrapTooltip title={`Delete ${listContext}`} placement="top">
        <span>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={handleDeleteClick}
            disabled={isDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </BootstrapTooltip>
    </>
  );
};

export default ListDeleteButton;
