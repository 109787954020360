import React from 'react';
import Grid from '@material-ui/core/Grid';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AttachmentList from '../AttachmentList';

const Attachments = ({ attachments }) => {
  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <AttachmentIcon />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <AttachmentList
          attachedFiles={attachments}
          onClearClick={null}
          onSelectCover={null}
          isEditable={false}
        />
      </Grid>
    </Grid>
  );
};

export default Attachments;
