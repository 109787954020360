import React, { useEffect, useState, createRef } from 'react';
import usePrevious from '../../../hooks/usePrevious';
import { getBoundsAtLatLngWithZoom } from '../../../utils/country';
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from 'react-google-maps';
import LocationMapMarker from './LocationMapMarker';
import DirectionMapMarker from './DirectionMapMarker';
// import { isSamePlaces } from '../../../utils/country';

const LocationMap = withGoogleMap((props) => {
  const {
    defaultZoom,
    places,
    directions = null,
    isTeleportToBounds = true,
    onDblClick,
    selectedPlace,
    onSelectClick,
    focusedStep,
    onBoundsReady,
  } = props;
  const [openedPin, setOpenedPin] = useState(null);
  const [directionMarker, setDirectionsMarker] = useState(null);
  const [bounds, setBounds] = useState(null);
  const mapRef = createRef();

  const prevBounds = usePrevious(bounds);
  const prevFocusedStep = usePrevious(focusedStep);

  // THis would center the map to contatain all places
  useEffect(() => {
    // isTeleportToBounds = false should be used when we just display, it makes sure we can pan the map how we want
    // isTeleportToBounds = true makes sure map reacts to new places props and fits them accrodingly
    if (!!places && places.length > 0 && isTeleportToBounds) {
      let newBounds = new window.google.maps.LatLngBounds();
      if (places.length > 1) {
        places.forEach((p) => {
          newBounds.extend(p.geometry.location);
        });
      } else if (places.length === 1) {
        newBounds = getBoundsAtLatLngWithZoom(
          mapRef.current,
          places[0].geometry.location,
          defaultZoom
        );
      }
      if (!!prevBounds && !newBounds.intersects(prevBounds)) {
        console.log('Fitting to new bounds');
        mapRef.current.fitBounds(newBounds);
      }
    }
  }, [defaultZoom, isTeleportToBounds, mapRef, places, prevBounds]);

  // Will move the map to focus step
  useEffect(() => {
    if (!!mapRef.current && !!focusedStep && focusedStep !== prevFocusedStep) {
      console.debug(focusedStep);
      const { start_point, end_point } = focusedStep;
      let stepBounds = new window.google.maps.LatLngBounds();
      stepBounds.extend(start_point);
      stepBounds.extend(end_point);
      mapRef.current.fitBounds(stepBounds);
      setDirectionsMarker(start_point);
    }
  }, [focusedStep, mapRef, prevFocusedStep]);

  const handleIdle = () => {
    if (openedPin === null) {
      !!onBoundsReady && onBoundsReady(bounds);
    }
  };

  const handleBoundsChange = () => {
    if (isTeleportToBounds) {
      console.debug('Bounds changed');
      setBounds(mapRef.current.getBounds());
    }
  };

  const handleDirectionsMarkerClick = () => {
    // Delete marker, show whole route
    const { start_location, end_location } = directions.routes[0].legs[0];
    let fullBounds = new window.google.maps.LatLngBounds();
    fullBounds.extend(start_location);
    fullBounds.extend(end_location);
    console.debug(fullBounds);
    if (!!mapRef.current) {
      mapRef.current.fitBounds(fullBounds);
    }
    setDirectionsMarker(null);
  };

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={defaultZoom}
      onDblClick={onDblClick}
      // center={places.length === 1 ? places[0].geometry.location : null}
      // center={!!bounds ? bounds.getCenter() : places[0].geometry.location}
      // center={{ lat: lat, lng: lng }}
      defaultCenter={places[0].geometry.location}
      onBoundsChanged={handleBoundsChange}
      onIdle={handleIdle}
      className="gmap"
    >
      {!!directions && <DirectionsRenderer directions={directions} />}
      {!!places &&
        places.length > 0 &&
        places.map((place) => (
          <LocationMapMarker
            key={place.place_id}
            place={place}
            onMarkerClick={() => setOpenedPin(place.place_id)}
            onInfoClose={() => setOpenedPin(null)}
            isInfoOpen={place.place_id === openedPin}
            isSelected={
              !!selectedPlace && place.place_id === selectedPlace.place_id
            }
            onSelectClick={onSelectClick}
          />
        ))}
      {!!directionMarker && (
        <DirectionMapMarker
          position={directionMarker}
          onClick={handleDirectionsMarkerClick}
        />
      )}
    </GoogleMap>
  );
});

LocationMap.defaultProps = {
  defaultZoom: 17,
};

export default LocationMap;
