import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ListDeleteButton from '../../../pages/Profile/ProfileEdit/ListDeleteButton';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';

const AchievementItem = (props) => {
  const { setAchievements, data, error } = props;

  const handleAttachmentTitleEdit = (e) =>
    setAchievements({
      type: PROFILE_EDIT_ACTIONS.achievementEdit,
      payload: {
        achievement_id: data.id,
        field: 'entity',
        value: e.target.value,
      },
    });

  const handleAttachmentDelete = () =>
    setAchievements({
      type: PROFILE_EDIT_ACTIONS.achievementDelete,
      payload: { achievement_id: data.id },
    });

  const handleAttachmentDescriptionEdit = (e) =>
    setAchievements({
      type: PROFILE_EDIT_ACTIONS.achievementEdit,
      payload: {
        achievement_id: data.id,
        field: 'description',
        value: e.target.value,
      },
    });

  return (
    <Grid container spacing={1}>
      <Grid item xs={10}>
        <TextField
          value={data.entity}
          label="Title"
          required
          fullWidth
          error={!!error?.entity}
          helperText={!!error?.entity ? error?.entity : ''}
          onChange={handleAttachmentTitleEdit}
        />
      </Grid>
      <Grid item xs={2}>
        <ListDeleteButton
          id={data.id}
          handleDelete={handleAttachmentDelete}
          listContext="education history item"
        >
          Delete Item
        </ListDeleteButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={data.description}
          label="Description"
          fullWidth
          error={!!error?.description}
          helperText={!!error?.description ? error?.description : ''}
          required
          onChange={handleAttachmentDescriptionEdit}
        />
      </Grid>
    </Grid>
  );
};

export default AchievementItem;
