import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import StreetAutocomplete from '../../StreetAutocomplete';
import GooglePlaceTypeAutocomplete from './GooglePlaceTypeAutocomplete';
// import { GPLACES_TYPES } from '../../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  address: {
    width: 'calc(100% - 250px)',
  },
  filter: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
}));

const FindInMap = (props) => {
  const { street, handleChange, onPlaceTypeChange, placeType } = props;
  const classes = useStyles();

  const handleTypeChange = (e, v) => {
    onPlaceTypeChange(v);
  };

  return (
    <Grid container item xs={12} spacing={1} className={classes.root}>
      <div className={classes.address}>
        <StreetAutocomplete
          variant="standard"
          label="Address/Location/Postal code"
          street={street}
          types={['geocode', 'establishment']}
          handleChange={handleChange}
        />
      </div>
      <div className={classes.filter}>
        <GooglePlaceTypeAutocomplete
          variant="standard"
          onSelectPlaceType={handleTypeChange}
          placeType={placeType}
        />
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  country_code: state.member.memberInfo.country_code,
});

export default connect(mapStateToProps)(FindInMap);
