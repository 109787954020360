import React, { useEffect, useState, useCallback, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { debounce } from 'lodash';
import TimeZoneAutocomplete from '../../components/Modal/EventModals/TimeZoneAutocomplete';
import MaterialAvatar from '../../components/MaterialAvatar';
import { red, orangePeel } from '../../jss/colors';
import styles from '../../jss/views/activityTableStyle';
import * as api from '../../config/api';
import { textCapitalized } from '../../utils/general';
import { setMemberAlert } from '../../utils/alert';
import { setReduxMemberAlert } from '../../redux/actions/member';
import {
  PAGE_SIZE_OPTIONS,
  DEFAULT_PARAMS_MEMBERS,
} from '../../utils/activity';
import { defaultDateTimeZone } from '../../utils/calendar';

const headCells = [
  {
    id: 'user',
    label: 'User',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'company_name',
    label: 'Company',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'department',
    label: 'Department',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'create_date',
    label: 'Joined',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(styles);

const GlobalRegisteredMembers = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { memberAlert } = useSelector((state) => ({
    memberAlert: state.member.memberAlert,
  }));

  const { isAdmin, timeZone, setTimeZone } = props;
  const [page, setPage] = useState(DEFAULT_PARAMS_MEMBERS.page_number);
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_PARAMS_MEMBERS.page_size
  );
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);

  const [searchKey, setSearchKey] = useState('');
  const [order, setOrder] = useState(DEFAULT_PARAMS_MEMBERS.order);
  const [orderBy, setOrderBy] = useState(DEFAULT_PARAMS_MEMBERS.orderBy);

  const isMountedRef = useRef(false);

  const search = (event) => {
    let value = event.target.value;
    setSearchKey(value);
  };

  const getList = useCallback(
    debounce(({ sortBy, searchKey, pageNumber, pageSize }) => {
      let uri = `get_all=${isAdmin}&search_key=${searchKey}&page_number=${pageNumber}&page_size=${pageSize}&sort=${sortBy}`;
      const url = `${api.apiUrl}/system/activity/members/registered?${uri}`;
      api
        .GET(url)
        .then((res) => {
          if (isMountedRef.current && res?.success && res?.data) {
            setData(res.data['members']);
            setTotalRows(res.data['count']);
            const payload = setMemberAlert(res, true);
            dispatch(setReduxMemberAlert(payload));
          } else {
            const payload = setMemberAlert(res, false);
            dispatch(setReduxMemberAlert(payload));
          }
        })
        .catch((error) => {
          console.log(error);
          const payload = setMemberAlert(error, false);
          dispatch(setReduxMemberAlert(payload));
        });
    }, 500),
    [isAdmin, dispatch]
  );

  const loadSortedActivities = (property, order, pageNumber, pageSize) => {
    let sortBy = property;
    if (property === 'user') {
      sortBy = 'first_name';
    }

    sortBy = order === 'desc' ? `-${sortBy}` : `${sortBy}`;
    getList({
      sortBy,
      searchKey,
      pageNumber: pageNumber >= 0 ? pageNumber : page,
      pageSize: pageSize ? pageSize : rowsPerPage,
    });
    setOrderBy(property);
  };

  useEffect(() => {
    loadSortedActivities(orderBy, order, page, rowsPerPage);

    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedActivities(property, newOrder);
  };

  const handleChangePage = (newPage) => {
    if (newPage !== page) {
      loadSortedActivities(orderBy, order, newPage, null);
      setPage(newPage);
      console.log(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    const pageNumber = 0;
    loadSortedActivities(orderBy, order, pageNumber, pageSize);
    setRowsPerPage(pageSize);
    setPage(pageNumber);
  };

  const handleSnackbarClose = useCallback(() => {
    if (memberAlert.show) {
      const alertOption = {
        show: false,
        variant: memberAlert.variant,
        message: '',
      };
      dispatch(setReduxMemberAlert(alertOption));
    }
  }, [dispatch, memberAlert]);

  useEffect(() => {
    if (memberAlert.show) {
      setTimeout(() => {
        handleSnackbarClose();
      }, 4000);
    }
  }, [memberAlert, handleSnackbarClose]);

  return (
    <Paper className={classes.paper}>
      <Toolbar variant="dense" className={classes.searchRoot}>
        <div className={classes.search}>
          <div aria-label="search-icon" className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={searchKey}
            onChange={search}
          />
        </div>
        <div className={classes.settingsWrapper}>
          <TimeZoneAutocomplete
            variant="outlined"
            value={timeZone}
            onChange={(e, value) => setTimeZone(value)}
          />
        </div>
      </Toolbar>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="activityTable"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {data.map((row, index) => {
              const userType = row.user_type;
              let fullName = `${row.first_name || ''} ${row.last_name || ''}`;
              fullName = textCapitalized(fullName);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`registered_user_${index}`}
                >
                  <TableCell>
                    <div
                      style={
                        userType === 'system'
                          ? { color: red }
                          : userType === 'administrator'
                          ? { color: orangePeel }
                          : {}
                      }
                    >
                      <MaterialAvatar
                        member_id={row.id}
                        src={row.amera_avatar_url}
                        firstName={row.first_name}
                        lastName={row.last_name}
                        classes={classes.tableCellAvatar}
                      />
                      {fullName.trim() +
                        ` ${
                          userType !== 'standard' ? '(' + userType + ')' : ''
                        }`}
                    </div>
                  </TableCell>
                  <TableCell>{row.company_name || ''}</TableCell>
                  <TableCell>{row.title || ''}</TableCell>
                  <TableCell>{row.department || ''}</TableCell>
                  <TableCell>{row.email || ''}</TableCell>
                  <TableCell>{row.status || ''}</TableCell>
                  <TableCell>
                    {(row.create_date &&
                      defaultDateTimeZone(
                        row.create_date,
                        '',
                        true,
                        timeZone
                      )) ||
                      ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalRows > 25 && (
        <div className="card-pagination contact-cards-pagination">
          <div className="item-per-page">
            <FormControl>
              <Select
                labelId="item-count-per-page-select-label"
                id="item-count-per-page-select"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                {PAGE_SIZE_OPTIONS.map((option) => (
                  <MenuItem key={`per_page_${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p className="item-count-text">Cards Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={parseInt(Math.ceil(totalRows / rowsPerPage))}
            page={page + 1}
            onChange={(e, newPage) => handleChangePage(newPage - 1)}
            color="primary"
            showFirstButton
            showLastButton
          />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={memberAlert.show}
            autoHideDuration={4000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={memberAlert.variant}>
              {memberAlert.message}
            </Alert>
          </Snackbar>
        </div>
      )}
    </Paper>
  );
};

export default GlobalRegisteredMembers;
