import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import AmeraSelect from '../../../../components/AmeraSelect';
import { projectTaskStatusOptions } from '../../../../utils/project';

const TasksSelect = ({ task, label, handleChange }) => {
  return (
    <div>
      <AmeraSelect
        labelWidth={0}
        label={label}
        value={task}
        onChange={handleChange}
      >
        {projectTaskStatusOptions.map((o) => {
          return (
            <MenuItem value={o.key} key={o.key}>
              {o.value}
            </MenuItem>
          );
        })}
      </AmeraSelect>
    </div>
  );
};

export default TasksSelect;
