import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import Layout from '../../Sections/Layout';
import Toolbar from '../../../components/MediaStreaming/Scheduler/toolbar';
import SettingModal from './setting';
import ScheduleWidget from './schedule';
import SchedulerWidget from './scheduler';
import { getAllSettings } from '../../../utils/streaming';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 20,
  },
}));

const Scheduler = (props) => {
  const { pageSettings } = props;
  const streamingPageSettings = pageSettings.find(
    (setting) => setting.page_type === 'Streaming Scheduler'
  );
  const data =
    streamingPageSettings && streamingPageSettings.extra_settings
      ? streamingPageSettings.extra_settings
      : null;
  const streamCategories = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];

  const defaultSettings = getAllSettings(data, streamCategories);

  const [activeWidget, setActiveWidget] = useState(false);
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [settings, setSettings] = React.useState(defaultSettings);
  const [settingType, setSettingType] = React.useState(null);

  const classes = useStyles();

  const handleWidget = () => {
    setActiveWidget(!activeWidget);
  };

  const handleSettingClose = () => {
    setOpenSettingModal(false);
    setSettingType(null);
  };

  const handleSettingOpen = (type) => {
    setOpenSettingModal(true);
    setSettingType(type);
  };

  const handleSettings = (id, setting) => {
    setSettings({ ...settings, [id]: +setting });
  };

  return (
    <Layout {...props}>
      <Toolbar handleWidget={handleWidget} activeWidget={activeWidget} />
      <div className={classes.content}>
        {activeWidget ? (
          <ScheduleWidget />
        ) : (
          <SchedulerWidget
            handleSettingOpen={handleSettingOpen}
            defaultSettings={defaultSettings}
          />
        )}
      </div>
      {openSettingModal && (
        <SettingModal
          id={settingType}
          title={''}
          open={openSettingModal}
          onClose={handleSettingClose}
          settings={settings}
          handleSettings={handleSettings}
          defaultStreamType={defaultSettings[settingType]}
          streamingPageSettings={streamingPageSettings}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  pageSettings: state.member.pageSettings,
});

export default connect(mapStateToProps)(Scheduler);
