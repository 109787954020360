import React from 'react';

const GroupSubMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.849"
    height="23"
    viewBox="0 0 45.849 40"
  >
    <g id="Group_32" data-name="Group 32" transform="translate(-40 -443)">
      <g id="Group_14" data-name="Group 14" transform="translate(39 439)">
        <path
          id="Path_20"
          data-name="Path 20"
          d="M28.522,30.589a8,8,0,1,0-9.044,0A11.011,11.011,0,0,0,12,41v2a1,1,0,0,0,1,1H35a1,1,0,0,0,1-1V41A11.011,11.011,0,0,0,28.522,30.589ZM18,24a6,6,0,1,1,6,6A6.006,6.006,0,0,1,18,24ZM34,42H14V41a9.01,9.01,0,0,1,9-9h2a9.01,9.01,0,0,1,9,9Z"
        />
        <g id="Group_11" data-name="Group 11">
          <path
            id="Path_18"
            data-name="Path 18"
            d="M24,10A14.015,14.015,0,0,0,10,24a13.847,13.847,0,0,0,1.228,5.691,1,1,0,0,0,1.826-.816A11.854,11.854,0,0,1,12,24a12,12,0,0,1,24,0,11.854,11.854,0,0,1-1.054,4.875,1,1,0,0,0,1.826.816A13.847,13.847,0,0,0,38,24,14.015,14.015,0,0,0,24,10Z"
          />
          <path
            id="Path_19"
            data-name="Path 19"
            d="M24,4A19.955,19.955,0,0,0,7.489,35.228,1,1,0,0,0,9.14,34.1,17.839,17.839,0,0,1,6,24a18,18,0,0,1,36,0,17.839,17.839,0,0,1-3.14,10.1,1,1,0,0,0,1.651,1.129A19.955,19.955,0,0,0,24,4Z"
          />
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="7"
            cy="7"
            r="7"
            transform="translate(17 17)"
          />
          <path
            id="Path_21"
            data-name="Path 21"
            d="M11,0c6.075,0,9.891,2.36,11,6.5S23.252,13,11,13,0,10.09,0,6.5,4.925,0,11,0Z"
            transform="translate(13 31)"
          />
        </g>
      </g>
      <path
        id="Path_31"
        data-name="Path 31"
        d="M20.846,23.811a4.283,4.283,0,1,0-4.842,0,5.9,5.9,0,0,0-4,5.574v1.071a.535.535,0,0,0,.535.535H24.314a.535.535,0,0,0,.535-.535V29.385A5.9,5.9,0,0,0,20.846,23.811Zm-5.633-3.528A3.212,3.212,0,1,1,18.425,23.5,3.216,3.216,0,0,1,15.212,20.283Zm8.566,9.637H13.071v-.535a4.824,4.824,0,0,1,4.818-4.818H18.96a4.824,4.824,0,0,1,4.818,4.818Z"
        transform="translate(28 452)"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="translate(43 469)"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M5.872,0c3.253,0,5.3,1.264,5.889,3.48s.67,3.48-5.889,3.48S-.017,5.4-.017,3.48,2.62,0,5.872,0Z"
        transform="translate(40.552 476.031)"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M20.846,23.811a4.283,4.283,0,1,0-4.842,0,5.9,5.9,0,0,0-4,5.574v1.071a.535.535,0,0,0,.535.535H24.314a.535.535,0,0,0,.535-.535V29.385A5.9,5.9,0,0,0,20.846,23.811Zm-5.633-3.528A3.212,3.212,0,1,1,18.425,23.5,3.216,3.216,0,0,1,15.212,20.283Zm8.566,9.637H13.071v-.535a4.824,4.824,0,0,1,4.818-4.818H18.96a4.824,4.824,0,0,1,4.818,4.818Z"
        transform="translate(61 452)"
      />
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="3.5"
        cy="3.5"
        r="3.5"
        transform="translate(76 469)"
      />
      <path
        id="Path_34"
        data-name="Path 34"
        d="M5.872,0c3.253,0,5.3,1.264,5.889,3.48s.67,3.48-5.889,3.48S-.017,5.4-.017,3.48,2.62,0,5.872,0Z"
        transform="translate(73.552 476.031)"
      />
    </g>
  </svg>
);

export default GroupSubMenuIcon;
