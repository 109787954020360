import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { intersection } from 'lodash';

import { sendDrivingDirectionsSms } from '../../redux/actions/schedule_event';
import { EVENT_TYPES, EAT_GPLACES } from '../../utils/calendar';
import EventLocation from '../../components/Modal/EventModals/EventInfoModal/EventLocation';
import EventCover from '../../components/Modal/EventModals/EventInfoModal/EventCover';
import { apiUrl } from '../../config/api';

const useStyles = makeStyles((theme) => ({
  cover: {
    '& div': {
      margin: '0 !important',
      width: '100%',
    },
  },

  imageWrapper: {
    paddingBottom: 4,
  },

  locationMap: {
    width: '100%',
    '& div': {
      margin: '0 !important',
    },
  },
}));

const LocationPicture = ({
  data,
  showMap = false,
  showCover = false,
  height = 240,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.member.member.member_id);

  const [placePhoto, setPlacePhoto] = useState(null);
  const [destinationPlace, setDestinationPlace] = useState(null);
  const [smsSuccess, setSMSSuccess] = useState(null);

  const {
    event_name,
    event_type,
    event_location,
    attachments,
    cover_attachment_id,
  } = data;

  const isHasLocation =
    (event_type === EVENT_TYPES.meeting ||
      event_type === EVENT_TYPES.personal ||
      event_type === EVENT_TYPES.breakfast ||
      event_type === EVENT_TYPES.lunch ||
      event_type === EVENT_TYPES.dinner ||
      event_type === EVENT_TYPES.party) &&
    !!event_location.location_id;

  // Cover image is either provided when scheduled OR restaurant / bar image
  let coverImage = null;
  if (!!cover_attachment_id && attachments?.length > 0) {
    const coverAttachmentObj = attachments.find(
      (afo) => afo.member_file_id === cover_attachment_id
    );
    coverImage = `${apiUrl}/${coverAttachmentObj?.file_url}`;
  } else if (event_location && event_location.location_profile_picture_url) {
    coverImage = `${apiUrl}/${event_location.location_profile_picture_url}`;
  } else if (!!placePhoto) {
    coverImage = placePhoto;
  }

  const displayMap = React.useMemo(() => {
    return isHasLocation && (showMap === true || (!showCover && !!!coverImage));
  }, [showMap, showCover, isHasLocation, coverImage]);
  const displayCover = React.useMemo(() => {
    return showCover === true || !!coverImage;
  }, [showCover, coverImage]);

  const handlePlaceData = async (place) => {
    if (intersection(place.types, EAT_GPLACES).length > 0) {
      const picUrl = await place.photos[0].getUrl({ maxWidth: 600 });
      setPlacePhoto(picUrl);
    }

    setDestinationPlace(place);
  };

  const handleSelfYourselfClick = (link) => {
    const memberIds = [memberId];
    handleInitSMS(link, memberIds);
  };

  const handleInitSMS = async (link, memberIds) => {
    const payload = {
      link,
      destinationAddress: destinationPlace.formatted_address,
      destinationName: destinationPlace.name,
      memberIds,
      event_name,
    };
    const success = await dispatch(sendDrivingDirectionsSms(payload));

    setSMSSuccess(success);
  };

  return (
    <>
      {displayCover ? (
        <div className={showMap ? classes.imageWrapper : ''}>
          <EventCover imageUrl={coverImage} height={height} />
        </div>
      ) : (
        ''
      )}
      {displayMap && (
        <div className={classes.locationMap}>
          <EventLocation
            event={data}
            onPlaceData={handlePlaceData}
            onSendYourself={handleSelfYourselfClick}
            isSMSSuccess={!!smsSuccess ? smsSuccess[String(memberId)] : false}
            isOnlyMap={true}
            height={height}
          />
        </div>
      )}
    </>
  );
};

export default LocationPicture;
