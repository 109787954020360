import { useMemo } from 'react';
import {
  defineFileAction,
  ChonkyIconName,
  // ChonkyActions,
  FileHelper,
} from 'chonky';

import {
  map,
  intersection,
  includes,
  keys,
  // startsWith,
  trimStart,
  // tail,
  sum,
  set,
} from 'lodash';
// import { apiUrl } from '../config/api';
const path = require('path-browserify');
const pathParse = require('path-parse');
const commondir = require('commondir');
const shortid = require('shortid');

export const removeFile = (fileList, fileIds) => {
  let newFileList = fileList;
  for (let i = 0; i < fileIds.length; i++) {
    newFileList = newFileList.filter((file) => file.file_id !== fileIds[i]);
  }
  return newFileList;
};

export const removeSharedFile = (fileList, sharedKeys) => {
  let newFileList = fileList;
  for (let i = 0; i < sharedKeys.length; i++) {
    newFileList = newFileList.filter(
      (file) => file.shared_key !== sharedKeys[i]
    );
  }
  return newFileList;
};

export const letterToUpper = (key) => {
  return (
    key
      .replace(/[^a-zA-Z ]/g, ' ')
      .charAt(0)
      .toUpperCase() + key.replace(/[^a-zA-Z ]/g, ' ').slice(1)
  );
};

const fileListItemKey = (item, targetFolder) => {
  if (item.webkitRelativePath === '') {
    return `${targetFolder}${item.name}`;
  } else {
    return `${targetFolder}${item.webkitRelativePath}`;
  }
};
//  The way how we rename duplicate files when we want to keep both versions
export const keepBothName = (originalKey) => {
  const pathObj = pathParse(originalKey);
  pathObj.name = `${pathObj.name} (1)`;
  pathObj.base = `${pathObj.name}${pathObj.ext}`;
  return path.format(pathObj);
};

export const makeBlankNonDuplicate = (key) => {
  const keyArr = key.split('/');
  const renameIdx = keyArr.length - 2;

  return keyArr
    .map((key, idx) => {
      if (idx !== renameIdx) {
        return key;
      } else {
        return `new_${key}`;
      }
    })
    .join('/');
};

const isShouldAddressDuplicates = (duplicateKeys, originalKey) =>
  duplicateKeys.length > 0 &&
  includes(
    duplicateKeys.map((o) => keys(o)[0]),
    originalKey
  );

export const displayKey = (originalKey, isShouldShowInRoot) =>
  isShouldShowInRoot ? pathParse(originalKey).base : originalKey;

export const checkBlanksDuplicates = (blankKey, blankFolders, fileList) => {
  const blankKeys = blankFolders.map((bfo) => bfo.key);
  const fileListKeys = fileList.map((flo) => flo.file_name);

  const allKeys = [...blankKeys, ...fileListKeys];
  // console.log('all keys', allKeys);

  if (allKeys.length > 0) {
    // If at least one is duplicate
    const dups = allKeys.filter((existingKey) =>
      existingKey.startsWith(blankKey)
    );
    // console.log(
    //   'The follodwing keys already have that blank folder name',
    //   dups
    // );
    return dups.length > 0;
  } else {
    return false;
  }
};

export const checkFileDuplicates = (
  uploadFileList,
  existingFileList,
  targetFolder
) => {
  if (existingFileList.length > 0) {
    const keysToUpload = map(uploadFileList, (f) =>
      fileListItemKey(f, targetFolder)
    );

    const existingKeys = existingFileList.map((f) => f.file_name);
    // console.log(
    //   'Checking file duplicates, modified key to upload are',
    //   keysToUpload,
    //   'Existing keys are',
    //   existingKeys
    // );
    const duplicateKeys = intersection(existingKeys, keysToUpload);
    /* 
      We return an [ ] where each item is 
      {duplicateKeyString: [file_ids that have the same keys in existingFileList]}
      This is required to make overwriting easier
    */
    return duplicateKeys.map((key) => {
      // console.log('Duplicate keys', key, 'Existing ');
      // console.log(
      //   'existingFileList',
      //   existingFileList.filter((f) => f.file_name === key)
      // );
      return {
        [key]: existingFileList
          .filter((f) => f.file_name === key)
          .map((f) => f.file_id),
      };
    });
  }
};

export const isInDir = (oldFolderKey, fileListItem) => {
  const oldDir = oldFolderKey;
  const dir = `${pathParse(fileListItem.file_name).dir}/`;
  // console.log('The old dir is', oldDir, 'This file dir is', dir);
  const isInDir = dir.startsWith(oldDir);
  return isInDir;
};

export const newFolderRenameKey = (oldFileKey, oldFolderKey, newFolderKey) =>
  oldFileKey.replace(oldFolderKey, newFolderKey);

export const prepareToUpload = (
  fileList,
  member,
  duplicateKeys,
  isOverWriting,
  targetFolder
) => {
  var formData = new FormData();
  for (var x = 0; x < fileList.length; x++) {
    const originalKey = fileListItemKey(fileList[x], targetFolder);
    let fileKeyToSend = originalKey;
    let fileIdstoReplace = [];

    console.log(`isOverWriting is set to ${isOverWriting}`);
    if (isShouldAddressDuplicates(duplicateKeys, originalKey)) {
      if (!isOverWriting) {
        console.log('We should rename and keep both');

        // rename
        fileKeyToSend = keepBothName(originalKey);
        // console.log('The changed key', fileKeyToSend);
      } else if (isOverWriting) {
        console.log('We should overwrite');
        // console.log('duplicateKeys', duplicateKeys);
        // Find the duplicate [file_ids] that exist in the back using our helper object
        fileIdstoReplace = duplicateKeys.filter(
          (obj) => keys(obj)[0] === originalKey
        )[0][originalKey];
      }
    } else {
      console.log('We shouldnt do nothing about duplicates');
    }

    // console.log('original key is', originalKey);
    formData.append(`file${x}`, fileList[x]);
    formData.append(`file${x}_key`, fileKeyToSend);
    formData.append(`file${x}_size`, fileList[x].size);
    formData.append(`file${x}_iv`, fileList[x].iv);
    formData.append(
      `file${x}_replace_file_ids`,
      JSON.stringify(fileIdstoReplace)
    );
    console.log(`file${x}_key`, fileKeyToSend);
    // console.log(`file${x}_replace_file_ids`, fileIdstoReplace);
  }
  formData.set('fileLength', fileList.length);
  formData.set('memberId', member.member_id);
  return formData;
};

export const prepareToUploadAttach = (
  fileList,
  member,
  mail_id,
  duplicateKeys,
  isOverWriting,
  targetFolder
) => {
  var formData = new FormData();

  console.log(mail_id, fileList);
  // for (var x = 0; x < fileList.length; x++) {
  //   const originalKey = fileListItemKey(fileList[x], targetFolder);
  //   let fileKeyToSend = originalKey;
  //   let fileIdstoReplace = [];

  //   console.log(`isOverWriting is set to ${isOverWriting}`);
  //   if (isShouldAddressDuplicates(duplicateKeys, originalKey)) {
  //     if (!isOverWriting) {
  //       console.log('We should rename and keep both');

  //       // rename
  //       fileKeyToSend = keepBothName(originalKey);
  //       // console.log('The changed key', fileKeyToSend);
  //     } else if (isOverWriting) {
  //       console.log('We should overwrite');
  //       // console.log('duplicateKeys', duplicateKeys);
  //       // Find the duplicate [file_ids] that exist in the back using our helper object
  //       fileIdstoReplace = duplicateKeys.filter(
  //         (obj) => keys(obj)[0] === originalKey
  //       )[0][originalKey];
  //     }
  //   } else {
  //     console.log('We shouldnt do nothing about duplicates');
  //   }

  //   // console.log('original key is', originalKey);
  //   formData.append(`file${x}`, fileList[x]);
  //   //formData.append(`file${x}_key`, fileKeyToSend);
  //   //formData.append(`file${x}_size`, fileList[x].size);
  //   //formData.append(`file${x}_iv`, fileList[x].iv);
  //   //formData.append(
  //   // `file${x}_replace_file_ids`,
  //   //  JSON.stringify(fileIdstoReplace)
  //   //);
  //   //console.log(`file${x}_key`, fileKeyToSend);
  //   // console.log(`file${x}_replace_file_ids`, fileIdstoReplace);
  // }
  //formData.set('fileLength', fileList.length);

  formData.append(`file`, fileList[0]);
  formData.set('mail_id', mail_id);

  console.log(formData);
  return formData;
};

// export const extractCommonFolder = (fileKeysArr) => {
//   console.log(
//     'Selected some files and blanks, will try to get the common folder from',
//     fileKeysArr
//   );
// };

const isIdOfFile = (id, fileList) =>
  fileList.find((flo) => flo.file_id === id) !== undefined;

export const defineTargetFolder = (selectedIds, fileList, blankFolders) => {
  // console.log(
  //   'The selected ids in define target folder are',
  //   selectedIds,
  //   'fileList is',
  //   fileList,
  //   'blankFolders are',
  //   blankFolders
  // );
  // Return root if nothing selected
  if (
    selectedIds.length === 0 ||
    selectedIds === undefined ||
    fileList === undefined ||
    blankFolders === undefined
  ) {
    return '';
  } else {
    const selectedKeys = selectedIds
      .map((id) => {
        if (isIdOfFile(id, fileList)) {
          // if this is a real file
          return fileList.find((flo) => flo.file_id === id).file_name;
        } else {
          // If this is a blank or null
          const blank = blankFolders.find((bfo) => bfo.file_id === id);
          if (blank !== undefined) {
            return blank.key;
          } else {
            return null;
          }
        }
      })
      .filter((key) => key !== null);

    let folderToUpload = '';
    //  if only one folder or file is selected
    if (selectedKeys.length === 0) {
      folderToUpload = '';
    } else if (selectedKeys.length === 1) {
      const key = selectedKeys[0];
      const isBlank = blankFolders.find((bfo) => bfo.key === key) !== undefined;
      // Return full key if it's a blank and dir when it's not
      if (isBlank) {
        folderToUpload = key;
      } else if (key === undefined || selectedKeys === undefined) {
        folderToUpload = '';
      } else {
        folderToUpload = `${pathParse(key).dir}/`;
      }
    } else {
      // All file and folder names, then we get their common dir, and if it is root=> we return ''
      // console.log('The selectedKeys just before commondir', selectedKeys);
      folderToUpload = `${commondir('', selectedKeys)}/`;
    }
    // Get rid of that starting slash
    return folderToUpload.startsWith('/')
      ? trimStart(folderToUpload, '/')
      : folderToUpload;
  }
};

export const peerFilter = (fileObj, peerId) => {
  if (peerId === null) {
    // Make it return all items  (do not filter)
    return true;
  } else {
    // Pick all mutual shares with user

    return (
      fileObj.sharer_member_id === parseInt(peerId) ||
      fileObj.consumer_member_id === parseInt(peerId)
    );
  }
};

export const prepareTree = (arr) => {
  let targetObj = { rootFolderId: '', fileMap: {} };

  const getChildren = (parentId) =>
    arr.filter((f) => f.parentId === parentId).map((f) => f.id);

  const getChildrenCount = (parentId) =>
    arr.filter((f) => f.parentId === parentId).length;

  const getChildrenSize = (parentId) =>
    sum(arr.filter((f) => f.parentId === parentId).map((f) => f.size));

  arr.forEach((f) => {
    if (f.parentId) {
      set(targetObj, `fileMap[${f.id}].id`, f.id);
      set(targetObj, `fileMap[${f.id}].name`, f.name);
      set(targetObj, `fileMap[${f.id}].isDir`, f.isDir);
      set(
        targetObj,
        `fileMap[${f.id}].isSymlink`,
        f.sharedWithMembers || f.sharedWithMe || f.sharedWithGroups
          ? true
          : false
      ); //FIXME: fix aftetr chonky PR
      set(targetObj, `fileMap[${f.id}].sharedWithMembers`, f.sharedWithMembers);
      set(targetObj, `fileMap[${f.id}].sharedWithGroups`, f.sharedWithGroups);
      set(targetObj, `fileMap[${f.id}].sharedWithMe`, f.sharedWithMe);
      set(
        targetObj,
        `fileMap[${f.id}].childrenIds`,
        f.isDir ? getChildren(f.id) : null
      );
      set(
        targetObj,
        `fileMap[${f.id}].childrenCount`,
        f.isDir ? getChildrenCount(f.id) : null
      );
      set(targetObj, `fileMap[${f.id}].isEncrypted`, f.isEncrypted);
      set(
        targetObj,
        `fileMap[${f.id}].size`,
        f.isDir ? getChildrenSize(f.id) : f.size
      );
      set(targetObj, `fileMap[${f.id}].parentId`, f.parentId);
      set(targetObj, `fileMap[${f.id}].file_id`, f.file_id);
      set(targetObj, `fileMap[${f.id}].amera_file_url`, f.amera_file_url);
      set(targetObj, `fileMap[${f.id}].modDate`, f.modDate);
      set(targetObj, `fileMap[${f.id}].create_date`, f.create_date);
      set(targetObj, `fileMap[${f.id}].create_member`, f.create_member);
      // set(
      //   targetObj,
      //   `fileMap[${f.id}].thumbnailUrl`,
      //   `${apiUrl}${f.amera_file_url}`
      // );
    } else {
      // It's root
      targetObj.rootFolderId = f.id;
      set(targetObj, `fileMap[${f.id}].id`, f.id);
      set(targetObj, `fileMap[${f.id}].name`, f.name);
      set(targetObj, `fileMap[${f.id}].isDir`, true);
      set(targetObj, `fileMap[${f.id}].childrenIds`, getChildren(f.id));
    }
  });

  return targetObj;
};

export const prepareForm = (fileList, member, targetFolder) => {
  let formData = new FormData();
  // Folder nodes go here
  const fileArr = Array.from(fileList);
  // const relativePaths = fileArr.map((f) => f.webkitRelativePath);
  // console.log(relativePaths);

  // FIles go here
  fileArr.forEach((f, idx) => {
    formData.append(`file${idx}`, f);
    formData.append(`file${idx}_size`, fileList[idx].size);
    formData.append(`file${idx}_iv`, fileList[idx].iv);
  });

  formData.set('fileLength', fileList.length);
  formData.set('folderLength', 0);
  // formData.set('memberId', member.member_id);
  formData.set('parent_node_id', targetFolder);
  return formData;
};

export const getFolderNames = (tree, rootId) =>
  tree.fileMap[rootId].childrenIds
    .map((c) => tree.fileMap[c])
    .filter((f) => f.isDir)
    .map((f) => f.name);

export const getNodeNames = (tree, rootId) =>
  tree.fileMap[rootId].childrenIds
    .map((c) => tree.fileMap[c])
    .map((f) => f.name);

export const getNodes = (tree, rootId) =>
  tree.fileMap[rootId].childrenIds.map((c) => tree.fileMap[c]);

export const fileArrToNodes = (fileList) => {
  // If any of the nodes doesnt have webkitRelativePath => treat as a flat file array
  /* What we want
     
      [{
          node_temp_id: shortid.generate(),
          file: null,
          name: folder name,
          parentId: null
          level: 0
        }, 
       {
          node_temp_id: shortid.generate(),
          file: file,
          name: filename.ext,
          parentId: that folder above,
          level: 1
       },
       {
          node_temp_id: shortid.generate(),
          file: null,
          name: subfolder,
          parentId: that folder above,
          level: 1
       }
       and so on
       ...]


    */

  const fileArr = Array.from(fileList);
  // console.log(fileArr);
  // console.log(fileList);

  const isFlatFileArray = !!fileArr.find(
    (f) => f.webkitRelativePath === '' || !f.webkitRelativePath
  );

  if (isFlatFileArray) {
    // console.log('This is a flat file array');
    return fileArr.map((f) => ({
      node_temp_id: shortid.generate(),
      file: f,
      name: f.name,
      size: f.size,
      parentId: null,
      level: 0,
    }));
  } else {
    // console.log('This is folder with some nesting stuff');

    let nodes = [];
    const paths = fileArr.map((f) => f.webkitRelativePath);
    paths.forEach((p) => {
      const pathParts = p.split('/');

      let thisPathElements = [];

      pathParts.forEach((s, idx) => {
        // This will block files from being added
        const isFile = !!fileArr.find((f) => f.name === s);
        const parent = thisPathElements.find((f) => f.level === idx - 1);
        const parentId = idx === 0 ? null : parent.node_temp_id;

        // const existing = nodes.find((n) => n.name === s && n.level === idx);
        const existingParent = () =>
          nodes.find((n) => n.name === parent.name && n.level === idx - 1);
        const isThere = !!nodes.find((n) => n.name === s && n.level === idx);

        thisPathElements.push({
          node_temp_id: shortid.generate(),
          file: isFile ? fileArr.find((f) => f.name === s) : null,
          name: s,
          size: isFile ? fileArr.find((f) => f.name === s).size : null,
          parentId:
            parent && parent.isThere ? existingParent().node_temp_id : parentId,
          isThere: isThere,
          level: idx,
        });
      });

      nodes.push(...thisPathElements.filter((n) => !n.isThere));
    });

    return nodes;
  }
};

export const checkNodeDuplicates = (nodes, siblingNodes) => {
  const duplicateNodes = nodes
    .filter((n) => n.level === 0)
    .filter((n) => !!siblingNodes.find((s) => s.name === n.name))
    .map((n) => ({
      node_temp_id: n.node_temp_id,
      isDir: n.file ? false : true,
      name: n.name,
      counterpart_node_id: siblingNodes.find((s) => s.name === n.name).id,
    })); // only root level is checked
  console.log(nodes, siblingNodes, duplicateNodes);
  return duplicateNodes;
};
export const getUploadForm = (metaData, files) => {
  let form = new FormData();

  // Files go here
  files.forEach((f) => {
    const { id, file } = f;
    form.append(`file_${id}`, file);
  });
  // Metadata goes here
  form.append('metadata', JSON.stringify(metaData));
  return form;
};

export const useFiles = (currentFolderId, fileTree) => {
  return useMemo(() => {
    const currentFolder = fileTree.fileMap[currentFolderId];
    const files = !currentFolder.childrenIds
      ? []
      : currentFolder.childrenIds.map((fileId) => {
          const file = fileTree.fileMap[fileId];
          return file ? file : null;
        });
    return files;
  }, [currentFolderId, fileTree]);
};

export const useFolderChain = (currentFolderId, fileTree) => {
  return useMemo(() => {
    const currentFolder = fileTree.fileMap[currentFolderId];

    const folderChain = [currentFolder];

    let parentId = currentFolder.parentId;
    while (parentId) {
      const parentFile = fileTree.fileMap[parentId];
      if (parentFile) {
        folderChain.unshift(parentFile);
        parentId = parentFile.parentId;
      } else {
        parentId = null;
      }
    }

    return folderChain;
  }, [currentFolderId, fileTree]);
};

export const uploadFileAction = defineFileAction({
  id: 'upload',
  requiresSelection: false,
  button: {
    name: 'Upload',
    toolbar: true,
    tooltip: 'Upload files and folders',
    icon: ChonkyIconName.upload,
  },
});

export const downloadFileAction = defineFileAction({
  id: 'download',
  requiresSelection: true,
  fileFilter: !FileHelper.isDirectory,
  button: {
    name: 'Download',
    toolbar: true,
    contextMenu: true,
    tooltip: 'Download files from AMERA Cloud',
    icon: ChonkyIconName.download,
  },
});

export const shareFileAction = defineFileAction({
  id: 'share_file',
  requiresSelection: true,
  // Cant share file shared with me
  fileFilter: (f) => f.sharedWithMe === null,
  button: {
    name: 'Share',
    toolbar: true,
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Share files with individuals and AMERA Groups',
    icon: ChonkyIconName.share,
  },
});

export const copySharedFile = defineFileAction({
  id: 'copy_shared_file',
  requiresSelection: true,
  fileFilter: (f) => f.sharedWithMe !== null,
  button: {
    name: 'Copy shared file',
    contextMenu: true,
    tooltip: 'Copy the file shared with you to your AMERA cloud',
    icon: ChonkyIconName.copy,
  },
});

export const createFolderAction = defineFileAction({
  id: 'create_folder',
  requiresSelection: false,
  button: {
    name: 'Add folder',
    toolbar: true,
    tooltip: 'Add a new folder',
    icon: ChonkyIconName.folderCreate,
  },
});

export const renameAction = defineFileAction({
  id: 'rename',
  requiresSelection: true,
  // can't rename files that someone shared with you
  fileFilter: (f) => f.sharedWithMe === null,
  button: {
    name: 'Rename',
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Rename file or folder',
    icon: ChonkyIconName.text,
  },
});

export const cutFileAction = defineFileAction({
  id: 'cut',
  requiresSelection: true,
  hotkeys: ['ctrl+x'],
  button: {
    name: 'Cut',
    toolbar: true,
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Cut file or folder',
    icon: ChonkyIconName.upload,
  },
});

export const copyFileAction = defineFileAction({
  id: 'copy',
  requiresSelection: true,
  fileFilter: (f) => f.sharedWithMe === null,
  hotkeys: ['ctrl+c'],
  button: {
    name: 'Copy',
    toolbar: true,
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Copy own file or folder to clipboard',
    icon: ChonkyIconName.copy,
  },
});

export const pasteFileAction = defineFileAction({
  id: 'paste',
  requiresSelection: false,
  hotkeys: ['ctrl+v'],
  button: {
    name: 'Paste',
    toolbar: true,
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Paste clipboard',
    icon: ChonkyIconName.paste,
  },
});

export const deleteFileAction = defineFileAction({
  id: 'delete',
  requiresSelection: true,
  hotkeys: ['delete'],
  button: {
    name: 'Delete',
    toolbar: true,
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Send file or folder to Bin',
    icon: ChonkyIconName.trash,
  },
});

export const showInfoAction = defineFileAction({
  id: 'show_info',
  requiresSelection: true,
  button: {
    name: 'Show info',
    toolbar: true,
    contextMenu: true,
    group: 'Edit',
    tooltip: 'Show information about the file',
    icon: ChonkyIconName.info,
  },
});

export const restoreAction = defineFileAction({
  id: 'restore',
  requiresSelection: true,
  button: {
    name: 'Restore',
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.upload,
  },
});

export const deleteForeverAction = defineFileAction({
  id: 'delete_forever',
  requiresSelection: true,
  button: {
    name: 'Delete Forever',
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.trash,
  },
});

// Here we can apply i18n configs and modifiy formatting for CHonky
export const customFormat = {
  locale: 'en',
  formatters: {
    formatFileModDate: (intl, file) => {
      const safeModDate = FileHelper.getModDate(file);
      if (safeModDate) {
        return `${intl.formatDate(safeModDate)}, ${intl.formatTime(
          safeModDate
        )}`;
      } else {
        return null;
      }
    },
  },
};
