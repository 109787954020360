import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../../../components/Modal/modal';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

const Duplicates = (props) => {
  const { show, close, duplicates, onSyncClick } = props;
  const [isOverWriting, setOverWriting] = useState(false);
  // const [isSyncing, setSyncing] = useState(false);

  const classes = useStyles();

  const ModalContent = () => (
    <div>
      <div>
        The following items appear to have same names as those that already
        exist in target folder.
      </div>
      <List dense={true}>
        {duplicates.map((d) => (
          <ListItem key={d.id}>
            <ListItemIcon>
              {d.isDir ? <FolderIcon /> : <InsertDriveFileIcon />}
            </ListItemIcon>
            <ListItemText primary={d.name} />
          </ListItem>
        ))}
      </List>
      <div>Would you like to overwrite these files or keep both?</div>
      <div>
        <FormControl size="small">
          <RadioGroup
            onChange={() => setOverWriting(!isOverWriting)}
            value={isOverWriting ? 'overwrite' : 'keep_both'}
          >
            <FormControlLabel
              label="Overwrite all"
              value="overwrite"
              control={<Radio />}
            />
            <FormControlLabel
              label="Keep both (rename)"
              value="keep_both"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );

  const ModalAction = () => (
    <div className={classes.actionsWrapper}>
      <Button
        variant="contained"
        className={classes.actionBtn}
        onClick={() => close()}
        // disabled={isSyncing}
      >
        Cancel
      </Button>

      <Button
        variant="contained"
        color="primary"
        className={classes.actionBtn}
        onClick={() => {
          onSyncClick(isOverWriting);
          close();
        }}
      >
        Synchronize
      </Button>
    </div>
  );

  return (
    <Modal
      open={show}
      onClose={close}
      title="Duplicates found"
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="duplicates-modal"
    ></Modal>
  );
};

export default connect()(Duplicates);
