const activityStyle = (theme) => ({
  activity: {
    padding: '10px',
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiAppBar-root': {
      zIndex: 10,
    },
  },
  tabPanel: {
    backgroundColor: theme.palette.common.white,
    flex: 1,
  },
});

export default activityStyle;
