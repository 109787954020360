import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import TimerIcon from '@material-ui/icons/Timer';
// import { format } from 'date-fns';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import { makeStyles } from '@material-ui/styles';
import { capitalize } from 'lodash';

import VideoEmailModal from '../../../components/Modal/VideoEmailModal';
import { getUnstyledHtmlBody } from '../../../utils/email';
import { DEFAULT_PARAMS, ACTIVITY_TYPES } from '../../../utils/activity';
import { eventDisplayName, defaultDateTimeZone } from '../../../utils/calendar';
import NotificationModal from './NotificationModal';
import {
  getActivity,
  getSecurity,
  getMessage,
  getGroup,
  getInvitation,
} from '../../../redux/actions/activity';

const useStyles = makeStyles((theme) => ({
  header: {
    '& .MuiCardHeader-content': {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: 10,
      alignItems: 'flex-end',
    },
  },
  section: {
    display: 'grid',
    height: theme.spacing(26),
    boxShadow: '0 2px 2px #d1dfea',
    marginBottom: theme.spacing(2),
    borderRadius: 5,
    padding: theme.spacing(1),
    '& .MuiCard-root': {
      background: 'transparent',
      boxShadow: 'none',
      '& .MuiCardHeader-root': {
        padding: `4px 8px 0 8px`,
        '&:hover': {
          cursor: 'pointer',
        },
        '& .MuiCardHeader-title': {
          fontSize: theme.spacing(2.25),
          color: 'white',
          fontWeight: 600,
        },
        '& .MuiCardHeader-subheader': {
          fontSize: theme.spacing(1.75),
          color: 'white',
          fontWeight: 500,
          opacity: 0.8,
        },
      },
      '& .MuiCardContent-root': {
        boxShadow: 'none',
        overflowY: 'auto',
        maxHeight: theme.spacing(17),
        margin: `0 8px`,
        padding: 0,
        marginTop: theme.spacing(0.5),
        // scroll for FF
        scrollbarColor: 'white hsla(0,0%,82.7%,.5)',
        scrollbarWidth: 'thin',
        // scroll for chrome etc
        '&::-webkit-scrollbar': {
          width: theme.spacing(1),
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: theme.spacing(1),
          background: 'white',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'hsla(0,0%,82.7%,.5)',
          borderRadius: theme.spacing(1),
          margin: theme.spacing(0.5),
        },
        '& .MuiListItemIcon-root': {
          minWidth: theme.spacing(2.5),
        },
      },
    },
  },

  [ACTIVITY_TYPES.sessions]: {
    background:
      'transparent linear-gradient( 180deg,#ffc500,#ee9600) 0 0 no-repeat padding-box',
  },

  [ACTIVITY_TYPES.threats]: {
    background:
      'transparent linear-gradient( 180deg,#ec7455,#d3360f) 0 0 no-repeat padding-box',
  },

  [ACTIVITY_TYPES.video_mails]: {
    background:
      'transparent linear-gradient( 180deg,#ffc500,#ee9600) 0 0 no-repeat padding-box',
  },

  [ACTIVITY_TYPES.mails]: {
    background:
      'transparent linear-gradient( 180deg,#94c03d,#76b102) 0 0 no-repeat padding-box',
  },

  [ACTIVITY_TYPES.groups]: {
    background:
      'transparent linear-gradient( 180deg,#00d8ff,#02829a) 0 0 no-repeat padding-box',
  },

  [ACTIVITY_TYPES.invitations]: {
    background:
      'transparent linear-gradient( 180deg,#6a4bd9,#4e2fbf) 0 0 no-repeat padding-box',
  },

  circle: {
    backgroundColor: '#ffb347',
    width: 14,
    height: 14,
    borderRadius: 14,
  },

  history: {
    height: theme.spacing(4.25),
    verticalAlign: 'top',
    '& .MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
  },

  time: {
    width: theme.spacing(10.5),
    minWidth: theme.spacing(10.5),
    marginLeft: theme.spacing(1),
    display: 'inline-flex',
    padding: 0,
    color: '#fff',
    textAlign: 'center',
    marginRight: 5,
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(2.5),
      color: 'white',
      margin: 'auto',
    },
    '& span': {
      fontSize: 11,
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
  },

  action: {
    '& button': {
      padding: theme.spacing(0.5),
      color: 'white',
    },
  },

  message: {
    '& .MuiListItemText-primary, .MuiListItemText-secondary': {
      fontWeight: 400,
      color: ' #fff',
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiListItemText-primary': {
      fontSize: 13,
    },
    '& .MuiListItemText-secondary': {
      fontSize: 12,
      lineHeight: '14px',
    },
  },

  emptyCard: {
    height: theme.spacing(14),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },

  emptyIcon: {
    fontSize: theme.spacing(5),
    color: 'white',
  },
}));

const Notifications = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const {
    activity,
    security,
    message,
    videoMessage,
    group,
    invitation,
    activityCount,
    securityCount,
    messageCount,
    videoMessageCount,
    groupCount,
    invitationCount,
    dispatch,
    member,
  } = props;

  useEffect(() => {
    let params = {
      search_key: DEFAULT_PARAMS.search_key,
      page_number: DEFAULT_PARAMS.page_number,
      page_size: DEFAULT_PARAMS.page_size,
    };
    if (DEFAULT_PARAMS.order === 'desc') {
      params.sort = `-${DEFAULT_PARAMS.orderBy}`;
    } else {
      params.sort = DEFAULT_PARAMS.orderBy;
    }
    // dispatch(getActivity(params));
    dispatch(getSecurity(params));
    dispatch(getMessage(params));
    dispatch(
      getMessage({
        videoOnly: true,
        ...params,
      })
    );
    dispatch(getGroup(params));
    dispatch(getInvitation(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadActivities = (
    type,
    { orderBy, order, searchKey, pageNumber, pageSize }
  ) => {
    let sort = orderBy;
    if (order === 'desc') {
      sort = `-${orderBy}`;
    }
    const params = {
      sort,
      search_key: searchKey,
      page_number: pageNumber,
      page_size: pageSize,
    };
    switch (type) {
      case ACTIVITY_TYPES.sessions:
        dispatch(getActivity(params));
        break;
      case ACTIVITY_TYPES.threats:
        dispatch(getSecurity(params));
        break;
      case ACTIVITY_TYPES.mails:
        dispatch(getMessage(params));
        break;
      case ACTIVITY_TYPES.video_mails:
        dispatch(
          getMessage({
            videoOnly: true,
            ...params,
          })
        );
        break;
      case ACTIVITY_TYPES.groups:
        dispatch(getGroup(params));
        break;
      case ACTIVITY_TYPES.invitations:
        dispatch(getInvitation(params));
        break;
      default:
        console.log('unknown type');
    }
  };

  const [showVideoEmailModal, setShowVideoEmailModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [videoMailId, setVideoMailId] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [modalTitle, setModalTitle] = useState('');

  const handleShowNotificationModalClose = () => {
    setShowNotificationModal(false);
    setSelectedType('');
  };

  const handleShowNotificationModalOpen = (type) => () => {
    if (type) {
      setSelectedType(type);
    }
    if (type && type !== ACTIVITY_TYPES.video_mails) {
      setShowNotificationModal(true);
    } else if (type && type === ACTIVITY_TYPES.video_mails) {
      setShowVideoEmailModal(true);
    }
  };

  const handleClick = (type, item) => {
    if (
      [ACTIVITY_TYPES.mails, ACTIVITY_TYPES.video_mails].indexOf(type) !== -1
    ) {
      const mail_id = (item && item.id) || null;
      if (mail_id) {
        if (
          item &&
          item.invitation_type === 'media_mail' &&
          item.status !== 'deleted'
        ) {
          setShowVideoEmailModal(true);
          setVideoMailId(mail_id);
        } else {
          if (!item.deleted && item.sender_member_id !== member.member_id) {
            history.push({
              pathname: `mail/inbox/${mail_id}`,
            });
          } else if (
            !item.deleted &&
            item.sender_member_id === member.member_id
          ) {
            history.push({
              pathname: `mail/sent/${mail_id}`,
            });
          }
        }
      }
    } else if (
      type === ACTIVITY_TYPES.sessions ||
      type === ACTIVITY_TYPES.threats
    ) {
      history.push({
        pathname: '/statistic/my-sessions',
      });
    }
  };

  const getTitle = (type) => {
    return type === ACTIVITY_TYPES.threats
      ? 'Security Alerts'
      : type === ACTIVITY_TYPES.invitations
      ? 'Invitations'
      : type === ACTIVITY_TYPES.video_mails
      ? 'Video Mail'
      : type === ACTIVITY_TYPES.mails
      ? 'Messages'
      : type === ACTIVITY_TYPES.sessions
      ? 'Your Activity'
      : type === ACTIVITY_TYPES.groups
      ? 'Group Activity'
      : '';
  };

  const getSubTitle = (type) => {
    switch (type) {
      case ACTIVITY_TYPES.sessions:
        return activityCount > 0
          ? `You have ${activityCount} ${
              activityCount > 1 ? 'activities' : 'activity'
            }`
          : 'No activity';
      case ACTIVITY_TYPES.threats:
        return securityCount > 0
          ? `You have ${securityCount} alert${
              securityCount > 1 ? 's' : ''
            } that require action`
          : 'No alerts';
      case ACTIVITY_TYPES.mails:
        return messageCount > 0
          ? `You have ${messageCount} message notification${
              messageCount > 1 ? 's' : ''
            }`
          : 'No messages';
      case ACTIVITY_TYPES.video_mails:
        return messageCount > 0
          ? `You have ${messageCount} video message${
              messageCount > 1 ? 's' : ''
            }`
          : 'No video messages';
      case ACTIVITY_TYPES.groups:
        return groupCount > 0
          ? `You have ${groupCount} group notification${
              groupCount > 1 ? 's' : ''
            }`
          : 'No activity';
      case ACTIVITY_TYPES.invitations:
        return invitationCount > 0
          ? `You have ${invitationCount} invitation${
              invitationCount > 1 ? 's' : ''
            }`
          : 'No invitations';
      default:
        console.log('unknown type');
        return '';
    }
  };

  useEffect(() => {
    switch (selectedType) {
      case ACTIVITY_TYPES.sessions:
        setTableData(activity);
        setTotalCount(activityCount);
        setModalTitle(
          `${getTitle(selectedType)} ${
            activityCount > 0 ? `(${getSubTitle(selectedType)})` : ''
          }`
        );
        break;
      case ACTIVITY_TYPES.threats:
        setTableData(security);
        setTotalCount(securityCount);
        setModalTitle(
          `${getTitle(selectedType)} ${
            securityCount > 0 ? `(${getSubTitle(selectedType)})` : ''
          }`
        );
        break;
      case ACTIVITY_TYPES.mails:
        setTableData(message);
        setTotalCount(messageCount);
        setModalTitle(
          `${getTitle(selectedType)} ${
            messageCount > 0 ? `(${getSubTitle(selectedType)})` : ''
          }`
        );
        break;
      case ACTIVITY_TYPES.video_mails:
        setTableData(videoMessage);
        setTotalCount(videoMessageCount);
        setModalTitle(
          `${getTitle(selectedType)} ${
            messageCount > 0 ? `(${getSubTitle(selectedType)})` : ''
          }`
        );
        break;
      case ACTIVITY_TYPES.groups:
        setTableData(group);
        setTotalCount(groupCount);
        setModalTitle(
          `${getTitle(selectedType)} ${
            groupCount > 0 ? `(${getSubTitle(selectedType)})` : ''
          }`
        );
        break;
      case ACTIVITY_TYPES.invitations:
        setTableData(invitation);
        setTotalCount(invitationCount);
        setModalTitle(
          `${getTitle(selectedType)} ${
            invitationCount > 0 ? `(${getSubTitle(selectedType)})` : ''
          }`
        );
        break;
      default:
        setTableData([]);
        setTotalCount(0);
        setModalTitle('');
        console.log('unknown type', selectedType);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedType,
    activity,
    security,
    message,
    videoMessage,
    group,
    invitation,
    activityCount,
    securityCount,
    messageCount,
    videoMessageCount,
    groupCount,
    invitationCount,
  ]);
  const capitalizeName = (firstName, lastName) =>
    `${capitalize(firstName)} ${capitalize(lastName)}`;

  const getCustomizedProperties = (type, item) => {
    let message = item.message,
      message2 = '',
      date_time = '',
      name = item.first_name
        ? capitalizeName(item.first_name, item.last_name)
        : '';
    const isSender =
      'sender_member_id' in item && item.sender_member_id === member.member_id;
    if (
      [ACTIVITY_TYPES.mails, ACTIVITY_TYPES.video_mails].indexOf(type) !== -1
    ) {
      // {
      //   "id": 10,
      //   "subject": "fsdaf",
      //   "type": null,
      //   "media_type": null,
      //   "xref_id": [
      //     12
      //   ],
      //   "status": null,
      //   "create_date": "2021-08-01T04:31:49.435421+00:00",
      //   "mail_url": null,
      //   "sender_email": "test@email.com",
      //   "sender_first_name": "test",
      //   "sender_last_name": "user",
      //   "sender_member_id": 1,
      //   "group_id": -1,
      //   "group_name": null,
      //   "s3_avatar_url": "/member/1/avatar",
      //   "invitation_type": "text_mail",
      //   "recipients": [
      //     {
      //       "read,": false,
      //       "status": "unread",
      //       "deleted,": false,
      //       "new_mail,": true,
      //       "s3_avatar_url": "/member/2/avatar",
      //       "recipient_type": "TO",
      //       "recipient_email": "adrian@email.com",
      //       "recipient_last_name": "thomas",
      //       "recipient_member_id": 2,
      //       "recipient_first_name": "adrian"
      //     }
      //   ],
      //   "row_id": 2
      // }

      if (isSender === true) {
        name = item.recipients
          .map((r) =>
            capitalizeName(r.recipient_first_name, r.recipient_last_name)
          )
          .join(', ');
        message = `You sent "${getUnstyledHtmlBody(item.subject)}" to ${name}`;
      } else {
        name = capitalizeName(item.sender_first_name, item.sender_last_name);
        message = `${name} sent "${getUnstyledHtmlBody(item.subject)}"`;
      }
      if (item.deleted || item.status === 'deleted') {
        message = `You deleted ${getUnstyledHtmlBody(
          item.subject
        )} sent by ${name}`;
      }
    } else if (type === ACTIVITY_TYPES.invitations) {
      if (item.invitation_type === 'event_invitation') {
        if (item.status === 'Accepted') {
          message = `You accepted ${eventDisplayName(
            item,
            member.member_id
          )} event request.`;
        } else if (item.status === 'Declined') {
          message = `You declined ${eventDisplayName(
            item,
            member.member_id
          )} event request`;
        } else if (item.status === 'Tentative') {
          message = `${name} invited you to ${eventDisplayName(
            item,
            member.member_id
          )} event.`;
        } else if (item.status === 'Recurring') {
          message = `${name} invited you to ${eventDisplayName(
            item,
            member.member_id
          )} recurring event.`;
        }
      } else if (item.invitation_type === 'drive_share') {
        message = `${name} shared file ${item.file_name} to you.`;
      } else if (item.invitation_type === 'contact_invitation') {
        const is_sender = item.id < item.requester_contact_id ? true : false;

        if (item.status === 'active') {
          if (is_sender) {
            message = `${name} accepted your contact request`;
          } else {
            message = `You accepted ${name} contact request`;
          }
        } else if (item.status === 'declined') {
          if (is_sender) {
            message = `${name} declined to be a contact`;
          } else {
            message = `You declined ${name} contact request`;
          }
        } else if (item.status === 'pending') {
          message = `${name} wants to add you as a contact`;
        } else if (item.status === 'requested') {
          message = `Waiting for ${name} to be a contact`;
        }
      }
    } else if (
      type === ACTIVITY_TYPES.sessions ||
      type === ACTIVITY_TYPES.threats
    ) {
      message = `Login from ${item.city ? item.city : ''}${
        item.region ? ', ' + item.region : ''
      }${item.country_code ? ' ' + item.country_code : ''}`;
      message2 = `${item.device ? item.device : ''}${
        item.organization_name ? ', ' + item.organization_name : ''
      }`;
    } else if (type === ACTIVITY_TYPES.groups) {
      if (item.status === 'active') {
        message = `You accepted invitation of group ${item.group_name}.`;
      } else if (item.status === 'invited') {
        message = `${name} added you to the ${item.group_name} group.`;
      } else if (item.status === 'declined') {
        message = `You declined invitation to group ${item.group_name}.`;
      } else if (item.status === 'Accepted') {
        message = `You accepted invitation to ${eventDisplayName(
          item,
          member.member_id
        )}.`;
      } else if (item.status === 'Declined') {
        message = `You declined invitation to ${eventDisplayName(
          item,
          member.member_id
        )}.`;
      } else if (item.status === 'Tentative') {
        message = `${name} added you to the ${eventDisplayName(
          item,
          member.member_id
        )} group event.`;
      }
    }

    date_time =
      ((item.update_date || item.create_date) &&
        defaultDateTimeZone(item.update_date || item.create_date)) ||
      '';

    const action_func = () => handleClick(type, item);

    return { message, message2, date_time, action_func, isSender };
  };

  const NotificationCard = ({ data, type }) => {
    const noOfItems = (data && data.length) || 0;
    return (
      <div className={`${classes.section} ${classes[type]}`}>
        <Card>
          <CardHeader
            className={classes.header}
            title={getTitle(type)}
            subheader={getSubTitle(type)}
            onClick={handleShowNotificationModalOpen(type)}
          />
          {noOfItems > 0 ? (
            <CardContent>
              <List dense={true} disablePadding={true}>
                {data.map((item, index) => {
                  const { message, message2, date_time, action_func } =
                    getCustomizedProperties(type, item);

                  return (
                    <div className={classes.history} key={index}>
                      <ListItem disableGutters={true}>
                        <ListItemIcon>
                          <div className={classes.circle} />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.message}
                          primary={message}
                          secondary={message2 ? message2 : ''}
                        />
                        <div className={classes.time}>
                          <ListItemIcon>
                            <TimerIcon />
                          </ListItemIcon>
                          <ListItemText primary={date_time} />
                        </div>
                        <ListItemSecondaryAction className={classes.action}>
                          <Tooltip title="Open" placement="left" arrow>
                            <span>
                              <IconButton
                                edge="end"
                                aria-label=""
                                disabled={
                                  type === ACTIVITY_TYPES.mails &&
                                  (item.deleted || item.status === 'deleted')
                                }
                                onClick={action_func}
                              >
                                <PlayCircleFilledRoundedIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  );
                })}
              </List>
            </CardContent>
          ) : (
            <div className={classes.emptyCard}>
              <DraftsOutlinedIcon className={classes.emptyIcon} />
            </div>
          )}
        </Card>
      </div>
    );
  };
  return (
    <>
      <NotificationCard key={2} data={security} type={ACTIVITY_TYPES.threats} />
      <NotificationCard
        key={3}
        data={videoMessage}
        type={ACTIVITY_TYPES.video_mails}
      />
      <NotificationCard key={4} data={group} type={ACTIVITY_TYPES.groups} />
      <NotificationCard
        key={5}
        data={invitation}
        type={ACTIVITY_TYPES.invitations}
      />
      <NotificationCard key={1} data={message} type={ACTIVITY_TYPES.mails} />
      <VideoEmailModal
        open={showVideoEmailModal}
        mail_id={videoMailId}
        onClose={() => {
          setShowVideoEmailModal(false);
        }}
      />
      <NotificationModal
        type={selectedType}
        title={modalTitle}
        data={tableData}
        totalCount={totalCount}
        loadActivities={loadActivities}
        getCustomizedProperties={getCustomizedProperties}
        open={showNotificationModal}
        onClose={handleShowNotificationModalClose}
      />
    </>
  );
};

Notifications.propTypes = {
  activity: PropTypes.arrayOf(PropTypes.object),
  security: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.arrayOf(PropTypes.object),
  videoMessage: PropTypes.arrayOf(PropTypes.object),
  group: PropTypes.arrayOf(PropTypes.object),
  invitation: PropTypes.arrayOf(PropTypes.object),
  activityCount: PropTypes.number,
  securityCount: PropTypes.number,
  messageCount: PropTypes.number,
  videoMessageCount: PropTypes.number,
  groupCount: PropTypes.number,
  invitationCount: PropTypes.number,
  member: PropTypes.object,
};

Notifications.defaultProps = {
  activity: [],
  message: [],
  videoMessage: [],
  security: [],
  invitation: [],
  group: [],
  member: {},
};

const mapStateToProps = (state) => ({
  activity: state.activity.activity,
  message: state.activity.message,
  videoMessage: state.activity.videoMessage,
  security: state.activity.security,
  group: state.activity.group,
  invitation: state.activity.invitation,
  activityCount: state.activity.activityCount,
  securityCount: state.activity.securityCount,
  messageCount: state.activity.messageCount,
  videoMessageCount: state.activity.videoMessageCount,
  groupCount: state.activity.groupCount,
  invitationCount: state.activity.invitationCount,
  member: state.member.member,
});

export default connect(mapStateToProps)(Notifications);
