import React from 'react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  invited: {
    margin: theme.spacing(0.5),
  },
  uninvited: {
    margin: theme.spacing(0.5),
    opacity: '50%',
  },
}));

const GroupMembersChips = (props) => {
  const { invitedGroup, handleChipClick } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      {!!invitedGroup &&
      !!invitedGroup.members &&
      invitedGroup.members.length > 0 ? (
        invitedGroup.members.map((data) => {
          return (
            <Chip
              icon={
                data.isShouldInvite ? (
                  <CheckCircleIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
              key={data.contact_member_id}
              className={
                data.isShouldInvite ? classes.invited : classes.uninvited
              }
              onClick={() => handleChipClick(data.contact_member_id)}
              //   icon={icon}
              label={data.contact_name}
              //   onDelete={data.label === 'React' ? undefined : handleDelete(data)}
            />
          );
        })
      ) : (
        <div>No group members except yourself</div>
      )}
    </Grid>
  );
};

export default GroupMembersChips;
