import React, { useMemo } from 'react';
import { ADDRESS_TYPES } from '../../../../../utils/calendar';

const AddressItem = (props) => {
  const { locationInfo } = props;
  const {
    location_type,
    address_1,
    address_2,
    city,
    state,
    postal,
    province,
    country_code_id,
  } = locationInfo;

  const locationName = useMemo(() => {
    if (location_type === ADDRESS_TYPES.home) {
      return 'Home address';
    } else if (location_type === ADDRESS_TYPES.work) {
      return 'Company address';
    } else if (location_type === ADDRESS_TYPES.billing) {
      return 'Billing address';
    } else if (location_type === ADDRESS_TYPES.other) {
      return 'Other address';
    }
  }, [location_type]);

  return (
    <>
      <div className="blank-space"></div>
      <p className="sub-title-no-space">{locationName}</p>
      <span className="text-content">{address_1}</span>
      {address_2 && (
        <>
          <br></br>
          <span className="text-content">{address_2}</span>
        </>
      )}
      <br></br>
      <span className="text-content">{`${city}, ${
        country_code_id === 840 ? state : province
      } ${postal}`}</span>
    </>
  );
  // <span className="text-content">N/A</span>
};

export default AddressItem;
