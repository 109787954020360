import React from 'react';
import ListItem from '@material-ui/core/ListItem';
// import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { DEPARTMENT_ROLES } from '../../../utils/company';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { displayableFullName } from '../../../utils/contact';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    padding: `0px 0px 0px ${theme.spacing(1)}px`,
  },
  selected: {
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    transition: 'border-left 50ms ease-in',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.spacing(3),
  },
}));

const DepartmentMembersListItem = (props) => {
  const { data, onClick, isSelected, onLeaderShipClick } = props;
  const isLeader = data.department_status === DEPARTMENT_ROLES.leader;
  const classes = useStyles();
  return (
    <ListItem
      dense
      disableGutters
      onClick={() => onClick(data)}
      className={clsx(classes.root, { [classes.selected]: isSelected })}
    >
      <div className={classes.content}>
        <Typography variant="body2">{displayableFullName(data)}</Typography>
        {!!data.department_name && (
          <Tooltip title="Toggle department leadership">
            <IconButton onClick={(e) => onLeaderShipClick(e, data)}>
              <AssignmentIndIcon
                fontSize="small"
                color={isLeader ? 'primary' : 'disabled'}
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </ListItem>
  );
};

export default DepartmentMembersListItem;
