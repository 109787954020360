import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Content from '../../pages/Sections/Content';

const ErrorFallback = (props) => {
  // We can add resetErrorBoundary prop as well to trigger some action that would reset state or smth like that
  const { error } = props;
  const [isShow, setShow] = useState(true);

  let content;
  if (isShow) {
    content = (
      <>
        <code id="message">{error.message}</code>
        <code>{error.stack}</code>
      </>
    );
  } else {
    content = null;
  }

  return (
    <Content {...{ ...props, location: { pathname: 'error' } }}>
      <div className="error-fallback" role="alert">
        <h5>
          Something went wrong. Please contact AmeraShare support and provide
          them the following information.
        </h5>
        <Button
          className="hide-btn"
          variant="outlined"
          color="primary"
          onClick={() => setShow((ps) => !ps)}
        >
          {isShow ? 'Hide details' : 'Show details'}
        </Button>
        {content}
        {/* <button onClick={resetErrorBoundary}>Try again</button> */}
      </div>
    </Content>
  );
};

export default ErrorFallback;
