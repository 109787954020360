import { Grid, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import { mailButtonGroup } from '../../../../../utils/email';
import { useEmailPageStyles } from '../EmailPageStyles';
import { MailMoveToFolderElement } from './MailMoveToFolderElement';

export const MailTopButtonGroup = ({
  handlers,
  selectedEmail,
  executeFrom,
  handlePrint,
  selectedFolder,
  section,
}) => {
  const classes = useEmailPageStyles();
  let buttonArray = Object.keys(handlers);
  return (
    <>
      <Grid item xs={10} style={{ paddingLeft: 20 }}>
        {buttonArray.map((el) => {
          return el === mailButtonGroup.mailFolder.name ? (
            <MailMoveToFolderElement
              key={`buttonGroup-${el}`}
              icon={el}
              tooltip={mailButtonGroup.mailFolder.tooltip}
              folders={handlers[el].folders()}
              handleSubmit={handlers[el].moveEmailToFolder}
              selectedFolder={selectedFolder}
              section={section}
              selectedEmail={selectedEmail}
            />
          ) : (
            <Tooltip
              key={`buttonGroup-${el}`}
              title={mailButtonGroup[el].tooltip}
            >
              <IconButton
                onClick={(e) => {
                  selectedEmail && handlers[el] && handlers[el](e);
                }}
                aria-label={`button-${el}`}
                className={
                  selectedEmail && selectedEmail.is_stared && executeFrom === el
                    ? classes.greenButton
                    : classes.darkButton
                }
              >
                <SVG name={el} />
              </IconButton>
            </Tooltip>
          );
        })}
      </Grid>
      <Grid container item xs={2} justify="flex-end">
        <Tooltip title={'Print Email'}>
          <IconButton
            onClick={() => {
              handlePrint();
            }}
            aria-label={`button-print`}
            className={classes.darkButton}
          >
            <SVG name="print" />
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
};
