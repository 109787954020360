import { useRef, useState, useEffect, useCallback } from 'react';
import { isString } from 'lodash';
import {
  locationStringToGoogleQuery,
  locationObjectToString,
  filterOperationalOpenPlaces,
  isPlaceInList,
} from '../utils/country';
import { GPLACES_TYPES } from '../utils/calendar';

function useGoogleMap(props) {
  const [places, setPlaces] = useState(null);
  const [convertedPlace, setConvertedPlace] = useState(null);
  const [mapError, setMapError] = useState(null);
  const placesService = useRef();
  const [googleMap, setGoogleMap] = useState(window.google);

  useEffect(() => {
    if (window.google) {
      setGoogleMap(window.google);
    }
  }, []);

  useEffect(() => {
    if (!placesService.current && googleMap) {
      let map = new googleMap.maps.Map(document.createElement('div'));
      placesService.current = new googleMap.maps.places.PlacesService(map);
    }
  }, [googleMap]);

  const geocodeAddress = (address) =>
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${locationStringToGoogleQuery(
        address
      )}&sensor=true&language=en&key=${process.env.REACT_APP_GMAPS_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'OK') {
          setMapError(null);
          return data.results[0];
        } else {
          setMapError('Could get no location for input');
        }
      })
      .catch((error) => {
        setMapError('Could get no location for input');
        console.error(error);
      });

  const getPlaceDetailsFromPlaceId = useCallback(
    (placeId) =>
      placesService.current.getDetails(
        {
          placeId,
          fields: ['ALL'],
        },
        (place, status) => {
          if (status === googleMap.maps.places.PlacesServiceStatus.OK) {
            console.log('getPlaceDetailsFromPlaceId', place);
            setPlaces([place]);
          } else {
            setMapError(status);
          }
        }
      ),
    [placesService, googleMap.maps.places.PlacesServiceStatus.OK]
  );

  const returnPlaceDetailFromPlaceId = (placeId) =>
    placesService.current.getDetails(
      {
        placeId,
        fields: ['ALL'],
      },
      (place, status) => {
        if (status === googleMap.maps.places.PlacesServiceStatus.OK) {
          setConvertedPlace(place);
        } else {
          setMapError(status);
        }
      }
    );

  const getPlaceDetailsFromFields = useCallback(
    async (input) => {
      try {
        // Can be either object or string
        const locationString = isString(input)
          ? input
          : locationObjectToString(input);
        if (!locationString) {
          return;
        }
        const geocodedAddress = await geocodeAddress(locationString);
        if (!geocodedAddress) {
          return;
        }
        const { place_id } = geocodedAddress;
        if (!!place_id && !!placesService) {
          getPlaceDetailsFromPlaceId(place_id);
        }
      } catch (error) {
        setMapError(error.description);
        console.error(error);
      }
    },
    [getPlaceDetailsFromPlaceId]
  );

  const getNearbyPlacesByAddress = async (
    address,
    type = GPLACES_TYPES.restaurant
  ) => {
    try {
      const geocoded = await geocodeAddress(address);

      if (!!geocoded) {
        const location = geocoded.geometry?.location;
        // let bounds = false;
        // try {
        //   bounds = geocoded.geometry?.viewport;
        //   bounds = new googleMap.maps.LatLngBounds(bounds.northeast, bounds.southwest);

        // } catch(e) {
        //   console.error(e);
        // }
        const search = {
          location,
          type,
          rankBy: googleMap.maps.places.RankBy.DISTANCE,
        };
        // if (!bounds) {
        //   delete search['bounds'];
        //   search['location'] = location;
        //   search['rankBy'] =
        // }

        debugger;
        console.debug('Geocoded location center for nearby', location);
        placesService.current.nearbySearch(search, (places, status) => {
          console.debug('places results', places, status);
          if (status === googleMap.maps.places.PlacesServiceStatus.OK) {
            const filteredPlaces = filterOperationalOpenPlaces(places);
            setPlaces(filteredPlaces);
            // If the previous selected place is still in the places list, we may want to keep it selected
            if (!isPlaceInList(filteredPlaces, convertedPlace)) {
              setConvertedPlace(null);
            }
          } else {
            setPlaces(null);
            setConvertedPlace(null);
            console.error(status);
            setMapError('No places matching request');
          }
        });
      }
    } catch (error) {
      setMapError(error.description);
    }
  };

  const getNearbyPlacesByBounds = async (bounds, type) => {
    try {
      if (!!bounds) {
        placesService.current.nearbySearch(
          {
            bounds,
            type,
          },
          (places, status) => {
            if (status === googleMap.maps.places.PlacesServiceStatus.OK) {
              console.log('Settings place from bounds', places);
              const filteredPlaces = filterOperationalOpenPlaces(places);
              setPlaces(filteredPlaces);
              //If the previous selected place is still in the places list, we may want to keep it selected
              if (!isPlaceInList(filteredPlaces, convertedPlace)) {
                setConvertedPlace(null);
              }
            } else {
              setPlaces(null);
              setConvertedPlace(null);
              console.error(status);
              setMapError('No places matching request');
            }
          }
        );
      }
    } catch (error) {
      setMapError(error.description);
    }
  };

  return {
    places,
    getPlaceDetailsFromPlaceId,
    getPlaceDetailsFromFields,
    returnPlaceDetailFromPlaceId,
    getNearbyPlacesByAddress,
    getNearbyPlacesByBounds,
    convertedPlace,
    mapError,
  };
}
export default useGoogleMap;
