import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import AmeraSelect from '../../components/AmeraSelect';
import { PROJECT_TYPES } from '../../utils/project';

const ProjectTypeSelect = (props) => {
  const { projectType, handleChange, multiple, error = false } = props;
  return (
    <AmeraSelect
      labelWidth={120}
      label="Type"
      value={projectType}
      onChange={handleChange}
      multiple={multiple}
      error={error}
    >
      <MenuItem value={PROJECT_TYPES.design}>Design</MenuItem>
      <MenuItem value={PROJECT_TYPES.marketing}>Marketing</MenuItem>
      <MenuItem value={PROJECT_TYPES.software}>Software</MenuItem>
    </AmeraSelect>
  );
};

export default ProjectTypeSelect;
