import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import { getMemberFiles } from '../../redux/actions/fileshare';
import MainBinSwitch from './MainBinSwitch';
// import FileShareSpinner from './FileShareSpinner';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import { find } from 'lodash';

import MemberMainTree from './MemberMainTree';
import MemberBinTree from './MemberBinTree';
import { ChonkyActions } from 'chonky';

import PageSettingsModal from '../../components/Modal/PageSettingsModal';

const useStyles = makeStyles((theme) => ({
  memberFilesSection: {
    height: 'calc(100% - 40px)',
    marginTop: 50,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const MemberFiles = (props) => {
  const styles = useStyles();
  const { dispatch, binTree, fileTree, pageSettings } = props;

  const pageType = 'Drive';
  const filePageSettings = find(pageSettings, (o) => o.page_type === pageType);

  let defaultView = (filePageSettings && filePageSettings.view_type) || null;
  if (defaultView && defaultView === 'list') {
    defaultView = ChonkyActions.EnableListView.id;
  } else {
    defaultView = ChonkyActions.EnableGridView.id;
  }

  //TODO: unable to get/set ascending/descending order action from chonky
  let defaultSortBy =
    (filePageSettings &&
      filePageSettings.sort_order &&
      filePageSettings.sort_order[0]) ||
    null;
  if (defaultSortBy === 'file_name') {
    defaultSortBy = ChonkyActions.SortFilesByName.id;
  } else if (defaultSortBy === 'file_size') {
    defaultSortBy = ChonkyActions.SortFilesBySize.id;
  } else if (defaultSortBy === 'create_date') {
    defaultSortBy = ChonkyActions.SortFilesByDate.id;
  }

  const [mode, setMode] = useState('main');
  const [showDriveSettings, setShowDriveSettings] = useState(false);
  const [defaultViewActionId, setDefaultViewActionId] = useState(defaultView);
  const [defaultSortActionId, setDefaultSortActionId] = useState(
    defaultSortBy || ChonkyActions.SortFilesBySize.id
  );
  // const [isLoading, setLoading] = useState(false);

  const handleClick = (e, v) => {
    if (v) {
      setMode(v);
    }
  };

  useEffect(() => {
    // async function fetchData() {
    // setLoading(true);
    dispatch(getMemberFiles());
    // setLoading(false);
    // }
    // fetchData();
  }, [dispatch]);

  const handleChangeView = (view) => {
    if (view === 'list') {
      setDefaultViewActionId(ChonkyActions.EnableListView.id);
    } else if (view === 'tile') {
      setDefaultViewActionId(ChonkyActions.EnableGridView.id);
    }
  };

  const handleChangeSort = (sortBy) => {
    if (sortBy === 'file_name') {
      setDefaultSortActionId(ChonkyActions.SortFilesByName.id);
    } else if (sortBy === 'file_size') {
      setDefaultSortActionId(ChonkyActions.SortFilesBySize.id);
    } else if (sortBy === 'create_date') {
      setDefaultSortActionId(ChonkyActions.SortFilesByDate.id);
    }
  };

  return (
    <Col className={styles.memberFilesSection}>
      <div className={styles.toggleContainer}>
        <MainBinSwitch mode={mode} handleClick={handleClick} />
        <div className="page-setting-button">
          <Tooltip title="Settings" arrow>
            <SettingsIcon onClick={() => setShowDriveSettings(true)} />
          </Tooltip>
        </div>
      </div>

      {mode === 'bin' ? (
        !!binTree ? (
          <MemberBinTree
            binTree={binTree}
            defaultViewActionId={defaultViewActionId}
            defaultSortActionId={defaultSortActionId}
          />
        ) : null
      ) : !!fileTree ? (
        <MemberMainTree
          fileTree={fileTree}
          defaultViewActionId={defaultViewActionId}
          defaultSortActionId={defaultSortActionId}
        />
      ) : null}
      {/* <FileShareSpinner isLoading={isLoading} /> */}
      <PageSettingsModal
        pageType={pageType}
        loading={false}
        open={showDriveSettings}
        setOrderBy={handleChangeSort}
        setOrder={() => {}}
        setPageSize={() => {}}
        setView={handleChangeView}
        onSave={() => {}}
        onClose={() => setShowDriveSettings(false)}
      />
    </Col>
  );
};

const mapStateToProps = (state) => ({
  binTree: state.fileshare.bin_tree,
  fileTree: state.fileshare.main_tree,
  pageSettings: state.member.pageSettings,
});

export default connect(mapStateToProps)(MemberFiles);
