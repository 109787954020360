import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import MaterialAvatar from '../MaterialAvatar';
import 'react-multi-carousel/lib/styles.css';
import { AttachmentIcon, DownloadIcon } from '../../assets/svg';
import { extractFileName, fileSize } from '../../utils/file';
import Carousel from '../Carousel';
import * as api from '../../config/api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 5px 0px 5px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
    marginBottom: '15px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  postAvatar: {
    width: '40px',
    height: '40px',
  },
  memberInfo: {
    display: 'inline-grid',
    marginLeft: '10px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#0A1629',
    marginBottom: 0,
  },
  comments: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: 0,
  },
  createDate: {
    fontSize: '11px',
    fontWeight: 600,
    color: '#7D8592',
    marginLeft: '10px',
  },
  oneImage: {
    width: '412px',
    height: '227px',
  },
  carouselImage: {
    width: '173px',
    height: '126px',
  },
  iconWrapper: {
    width: '44px',
    height: '44px',
    borderRadius: '14px',
    backgroundColor: '#F4F9FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '13px',
    cursor: 'pointer',
  },
  imageWrapper: {
    marginTop: '13px',
    marginBottom: '8px',
    height: '248px',
  },
  multiImageWrapper: {
    marginTop: '13px',
    marginBottom: '8px',
    height: '147px',
  },
  content: {
    fontSize: '13px',
    fontWeight: 'normal',
    color: '#0A1629',
    marginTop: '8px',
  },
  commentAvatar: {
    width: '22px',
    height: '22px',
  },
  commentName: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  commentCreateDate: {
    fontSize: '11px',
    fontWeight: 600,
    marginLeft: '11px',
  },
  commentContent: {
    fontSize: '13px',
    fontWeight: 'normal',
    color: '#0A1629',
    paddingLeft: '30px',
    marginTop: '10px',
  },
  noHeight: {
    height: 0,
  },
  commentWrapper: {
    paddingLeft: '22px',
    marginTop: '17px',
  },
  comment: {
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '10px',
    borderTop: '1px solid rgba(0, 0, 0, 0.16)',
  },
  attachment: {
    display: 'flex',
    background: 'white',
    padding: '13px',
    borderRadius: '14px',
  },
  attachmentIconWrapper: {
    width: '44px',
    height: '44px',
    borderRadius: '14px',
    backgroundColor: '#f1effb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fill: '#8DA3C5 !important',
    },
  },
  fileInfo: {
    marginLeft: '16px',
    maxWidth: 'calc(100% - 136px)',
    width: 'calc(100% - 136px)',
  },
  fileName: {
    fontSize: '14px',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileSize: {
    color: '#91929E',
    fontSize: '12px',
    fontWeight: 400,
  },
}));

const ContactFeedCard = (props) => {
  const { feed, member_id, setReplyId } = props;

  const classes = useStyles();

  const images = feed.attachments.filter((attachment) => {
    const isImage = attachment.mime_type.startsWith('image');
    return isImage;
  });

  const imagesCls =
    images.length === 0
      ? classes.noHeight
      : images.length > 1
      ? classes.carouselImage
      : classes.oneImage;
  const imageWrapperCls =
    images.length === 0
      ? classes.noHeight
      : images.length > 1
      ? classes.multiImageWrapper
      : classes.imageWrapper;

  const contentHtml =
    feed.topic_content +
    `<span class='newsfeeds-reply' id='${feed.topic_id}_reply' >Reply</span>`;

  useEffect(() => {
    if (feed) {
      document
        .getElementById(`${feed.topic_id}_reply`)
        .addEventListener('click', () => {
          setReplyId(feed.topic_id);
        });
    }
  }, [feed, setReplyId]);

  const attachmentsRender = (attachments) => (
    <div className={classes.attachmentsWrapper}>
      {attachments &&
        attachments.length > 0 &&
        attachments
          .filter((attachment) => {
            const isImage = attachment.mime_type.startsWith('image');
            return !isImage;
          })
          .map((attachment) => (
            <div key={attachment.file_id} className={classes.attachment}>
              <div className={classes.attachmentIconWrapper}>
                <AttachmentIcon />
              </div>
              <div className={classes.fileInfo}>
                <h5 className={classes.fileName}>
                  {extractFileName(attachment.file_path)}
                </h5>
                <h5 className={classes.fileSize}>
                  {fileSize(attachment.file_size)}
                </h5>
              </div>
              <div className={classes.iconWrapper}>
                <a href={`${api.apiUrl}${attachment.file_path}`} download>
                  <DownloadIcon />
                </a>
              </div>
            </div>
          ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.cardHeader}>
        <MaterialAvatar
          src={feed.amera_avatar_url}
          firstName={feed.first_name}
          lastName={feed.last_name}
          member_id={feed.member_id}
          variant="circular"
          classes={classes.postAvatar}
        />
        <div className={classes.memberInfo}>
          <h3 className={classes.name}>
            {feed.member_id === member_id
              ? 'You'
              : `${feed.first_name} ${feed.last_name}`}
            <span className={classes.createDate}>
              {format(new Date(feed.create_date), 'Pp')}
            </span>
          </h3>
          <h3 className={classes.comments}>
            {`${feed.replies.length} ${
              feed.replies.length > 1 ? 'comments' : 'comment'
            }`}
          </h3>
        </div>
      </div>
      <div className={imageWrapperCls}>
        <Carousel
          items={[
            { count: 2, width: 1520 },
            { width: 768, count: 2 },
            { width: 0, count: 2 },
          ]}
        >
          {images.map((image) => (
            <div key={image.file_id}>
              <img
                key={image.file_id}
                src={`${api.apiUrl}${image.file_path}`}
                alt="cool"
                className={imagesCls}
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      ></div>
      <div className={classes.attachmentsWrapper}>
        {attachmentsRender(feed.attachments)}
      </div>
      <div className={classes.commentWrapper}>
        {feed.replies.map((comment) => {
          const commentImages = comment.attachments.filter((attachment) => {
            const isImage = attachment.mime_type.startsWith('image');
            return isImage;
          });
          const subImagesCls =
            commentImages.length === 0
              ? classes.noHeight
              : commentImages.length > 1
              ? classes.carouselImage
              : classes.oneImage;
          const subImageWrapperCls =
            commentImages.length === 0
              ? classes.noHeight
              : commentImages.length > 1
              ? classes.multiImageWrapper
              : classes.imageWrapper;

          return (
            <div key={comment.post_id} className={classes.comment}>
              <div className={classes.commentMemberInfo}>
                <MaterialAvatar
                  src={comment.amera_avatar_url}
                  firstName={comment.first_name}
                  lastName={comment.last_name}
                  member_id={comment.member_id}
                  variant="circular"
                  classes={classes.commentAvatar}
                />
                <div className={classes.memberInfo}>
                  <h3 className={classes.commentName}>
                    {comment.member_id === member_id
                      ? 'You'
                      : `${comment.first_name} ${comment.last_name}`}
                    <span className={classes.commentCreateDate}>
                      {format(new Date(comment.create_date), 'Pp')}
                    </span>
                  </h3>
                </div>
              </div>
              <div className={subImageWrapperCls}>
                <Carousel
                  items={[
                    { count: 2, width: 1520 },
                    { width: 768, count: 2 },
                    { width: 0, count: 2 },
                  ]}
                >
                  {commentImages.map((image) => (
                    <div key={image.file_id}>
                      <img
                        key={image.file_id}
                        src={`${api.apiUrl}${image.file_path}`}
                        alt="cool"
                        className={subImagesCls}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div
                className={classes.commentContent}
                dangerouslySetInnerHTML={{ __html: comment.post_content }}
              ></div>
              {attachmentsRender(comment.attachments)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactFeedCard;
