import React from 'react';
import { useSelector } from 'react-redux';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import FileShareSmallIcon from '../../components/Icons/FileShareSmallIcon';
import SharedIcon from '../../components/Icons/SharedIcon';
import CircularProgress from '@material-ui/core/CircularProgress';

// import GroupAddIcon from '@material-ui/icons/GroupAdd';

import '../../styles/components/_NavigationGroup.scss';
import SearchComponent from '../../components/SearchComponent';

const useStyles = makeStyles(() => ({
  crateGroupButton: {
    borderRadius: 25,
    // color: 'white !important',
    // height: 48,
    // marginRight: 10,
    // textTransform: 'none',
    // borderRadius: '7px',
    // padding: '20px',
    // fontSize: '18px',
    // maxWidth: '192px',
    // letterSpacing: '0.36px',
    // fontWeight: 'bolder',
    // boxShadow: '0px 5px 15px #A1ABC699',
  },
  searchWrapper: {
    width: '30%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  circularProgress: {
    color: 'white',
  },
}));

const NavigationGroup = (props) => {
  const { type } = props;
  const { getGroupListLoading, loadingGroupMemberships } = useSelector(
    (state) => ({
      getGroupListLoading: state.group.getGroupListLoading,
      loadingGroupMemberships: state.group.loadingGroupMemberships,
    })
  );
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className="container-navigation-group">
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push('new-group')}
          className={classes.crateGroupButton}
        >
          Create new group
        </Button>
      </div>
      <div className={classes.searchWrapper}>
        <SearchComponent {...props} />
      </div>
      <div className="button-group">
        <ButtonGroup disableRipple disableElevation variant="contained">
          <Button
            className="btn-groups"
            color={type === 'my-groups' ? 'primary' : ''}
            onClick={() => history.push('my-groups')}
          >
            {getGroupListLoading ? (
              <div className="btn-icon">
                <CircularProgress
                  className={classes.circularProgress}
                  size={24}
                />
              </div>
            ) : (
              <div className="btn-icon">
                <SharedIcon size={24} />
              </div>
            )}
            My Groups
          </Button>
          <Button
            className="btn-group-memberships"
            color={type === 'group-memberships' ? 'primary' : ''}
            onClick={() => history.push('membership')}
          >
            {loadingGroupMemberships ? (
              <div className="btn-icon">
                <CircularProgress
                  className={classes.circularProgress}
                  size={24}
                />
              </div>
            ) : (
              <div className="btn-icon">
                <FileShareSmallIcon size={24} />
              </div>
            )}
            Group Memberships
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
export default NavigationGroup;
