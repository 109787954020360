import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';

function ContactSkeleton({ num }) {
  return (
    <ListItem key={num} className="contact-skeleton">
      <div className="contact-avatar-div">
        <Skeleton variant="circle" animation="wave" className="contact-avatar">
          <Avatar />
        </Skeleton>
      </div>
      <div className="contact-details-div">
        <Skeleton variant="rect" animation="wave" className="contact-name" />
        <Skeleton
          variant="rect"
          animation="wave"
          className="contact-last-call-date"
        />
      </div>
    </ListItem>
  );
}

export default ContactSkeleton;
