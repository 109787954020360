import React from 'react';
import { makeStyles } from '@material-ui/styles';

import TimerIcon from '@material-ui/icons/Timer';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
// import { format } from 'date-fns';

import ViewProgress from './progress';
import { defaultDateTimeZone } from '../../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    padding: 13,
    background: '#97ACC1',
    borderRadius: 5,
  },
  videoPlayer: {
    height: 251,
    background: 'grey',
    position: 'relative',
    width: '100%',
  },
  videoInfo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  description: {
    background: '#97ACC1',
    paddingTop: 9,
  },
  header: {
    padding: 8,
    '& h2, h4': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& h2': {
      fontSize: 12,
      letterSpacing: '0.39px',
    },
    '& h4': {
      fontSize: 8,
      letterSpacing: '0.24px',
    },
  },
  body: {
    marginTop: 160,
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  time: {
    fontSize: 105,
  },
  live: {
    fontSize: 24,
    fontWeight: 700,
    color: '#FF0000',
    marginTop: -30,
    textAlign: 'right',
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  footer: {
    marginTop: 150,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 18px',
  },
  leftFooter: {
    display: 'flex',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& >div:first-child': {
      marginRight: 10,
    },
  },
  rightFooter: {
    display: 'flex',
    '& svg': {
      color: '#FFFFFF',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  status: {
    fontSize: 17,
  },
  viewship: {
    '& .MuiLinearProgress-root': {
      marginTop: 5,
    },
  },
}));

const SmallVideo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.videoPlayer}>
        <video playsInline className={classes.video} me="true"></video>
        <div className={classes.videoInfo}>
          <div className={classes.header}>
            <h2>Marketing Presentation</h2>
            <h4>By: Chris Daly</h4>
          </div>
          <div className={classes.footer}>
            <div className={classes.leftFooter}>
              <div>
                <EventAvailableIcon />
                <span>{defaultDateTimeZone(new Date(), 'date')}</span>
              </div>
              <div>
                <TimerIcon />
                <span>{`03:15`}</span>
              </div>
            </div>
            <div className={classes.rightFooter}></div>
          </div>
        </div>
      </div>
      <div className={classes.description}>
        <div className={classes.toolbar}>
          <div className={classes.status}>View Status</div>
          <div className={classes.views}>
            <span>146</span>
            <label>Views</label>
          </div>
        </div>
        <div className={classes.viewship}>
          <span>% of target viewership</span>
          <ViewProgress variant="determinate" value={70} />
        </div>
      </div>
    </div>
  );
};

export default SmallVideo;
