import React from 'react';
import Grid from '@material-ui/core/Grid';
import SubjectIcon from '@material-ui/icons/Subject';
import ReactQuill from 'react-quill';

const EventDetails = ({ details }) => {
  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <SubjectIcon />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <ReactQuill
          readOnly
          modules={{ toolbar: false }}
          value={details}
          theme="snow"
        />
      </Grid>
    </Grid>
  );
};

export default EventDetails;
