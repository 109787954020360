import React from 'react';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const CountryInput = (props) => {
  const {
    country,
    errors,
    handleChangeCountry,
    countryList,
    isRequired,
    variant = 'outlined',
  } = props;

  return (
    <FormControl
      variant={variant}
      className="register_form_input"
      fullWidth
      required={isRequired}
      error={errors ? true : false}
    >
      <InputLabel id="country-select-outlined-label">Country</InputLabel>
      <Select
        labelId="country-select-outlined-label"
        id="country-select-outlined"
        name="country"
        placeholder="Select a country"
        value={country}
        onChange={handleChangeCountry}
        label="Country"
        // native
      >
        {countryList.map((country) => (
          <MenuItem key={country.id} value={country.id}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
      {errors && <FormHelperText>{errors}</FormHelperText>}
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(CountryInput);
