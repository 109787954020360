import homeImage from '../../assets/img/home-image.png';
import grey from '@material-ui/core/colors/grey';
import { textDark, borderDark } from '../colors';

const homeStyle = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
  },
  messageWrapper: {
    backgroundColor: grey[100],
    height: '70%',
    maxHeight: '616px',
    width: '50%',
    padding: '6% 5% 5% 5%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& img': {
      width: '60%',
    },
  },
  imageWrapper: {
    height: '70%',
    maxHeight: '616px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url("${homeImage}")`,
  },
  introducing: {
    fontSize: '1.7em',
    color: textDark,
    fontFamily: 'proxima-nova',
    fontWeight: 'bold',
    margin: theme.spacing(1),
  },
  remoteWorkLabel: {
    fontWeight: 'bold',
    fontSize: '2em',
    fontFamily: 'proxima-nova',
    color: textDark,
    marginTop: theme.spacing(2),
  },
  collaborationLabel: {
    fontSize: '1.5em',
    fontFamily: 'proxima-nova',
    color: borderDark,
  },
  buttonGroup: {
    width: '80%',
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'space-between',
    '& a': {
      width: '47%',
      textDecoration: 'none',
      minWidth: '170px',
    },
    '& .MuiButtonBase-root': {
      width: '100%',
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '1em',
    },
  },
});

export default homeStyle;
