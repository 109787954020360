import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0,0)',
    top: '1em',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function FileShareSpinner({ isLoading }) {
  return (
    <>
      <Backdrop
        className={useStyles().backdrop}
        invisible={false}
        open={isLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}

export default FileShareSpinner;
