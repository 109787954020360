import React from 'react';
import Grid from '@material-ui/core/Grid';
import LinkIcon from '@material-ui/icons/Link';

const EventLink = ({ link }) => {
  return (
    <Grid item container alignItems="center" alignContent="center">
      <Grid item container xs={2} justify="center" alignItems="center">
        <Grid item>
          <LinkIcon />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        {link}
      </Grid>
    </Grid>
  );
};

export default EventLink;
