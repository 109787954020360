import React from 'react';
import useStyles from './EventModalStyles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

const EventModalActions = (props) => {
  const {
    closeLabel,
    onClose,
    isLoading,
    loadingLabel,
    actionLabel,
    onAction,
  } = props;
  const classes = useStyles();
  return (
    <div className="create-event-footer">
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classNames(classes.Btn, classes.cancelBtn)}
          onClick={onClose}
        >
          {closeLabel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classNames(classes.Btn, classes.okBtn)}
          disableRipple
          disabled={isLoading ? true : false}
          onClick={onAction}
        >
          {isLoading ? loadingLabel : actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default EventModalActions;
