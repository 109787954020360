import {
  SET_SECURITY,
  SAVE_SECURITY_KEY,
  ENABLE_E2E_ENCRYPTION,
  ON_SECURITY_API_REQUEST,
  START_DECRYPTING,
} from '../actionTypes/security.js';

import { APP_RESET } from '../actionTypes/app';

// The initial state of the App
export const initialState = {
  loading: false,
  security: null,
  e2eEncryptionEnabled: false,
  hexkey: null,
  type: 'contact',
  contact_id: null,
  group_id: null,
  decrypting: false,
};

const security = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case ON_SECURITY_API_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SECURITY: {
      return {
        ...state,
        security: action.payload.security,
        type: action.payload.type,
        group_id: action.payload.group_id || state.group_id,
        contact_id: action.payload.contact_id || state.contact_id,
      };
    }
    case SAVE_SECURITY_KEY:
      return {
        ...state,
        hexkey: action.payload,
      };
    case ENABLE_E2E_ENCRYPTION:
      if (action.payload === false) {
        return {
          ...state,
          security: null,
          e2eEncryptionEnabled: false,
          hexkey: null,
        };
      } else {
        return {
          ...state,
          e2eEncryptionEnabled: true,
        };
      }
    case START_DECRYPTING:
      return {
        ...state,
        decrypting: action.payload,
      };
    default:
      return state;
  }
};

export default security;
