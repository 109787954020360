import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Button from '@material-ui/core/Button';
import UI from './components/Conference/modules/UI/UI';
// import { loadReCaptcha } from 'react-recaptcha-v3';
import { vacall } from './components/Conference/global';
import keyboardshortcut from './components/Conference/modules/keyboardshortcut/keyboardshortcut';
import history from './utils/history';
import MainRouter from './MainRouter';
import './styles/index.scss';

import AmeraNotification from './components/AmeraNotification';
import { nonBrowserFeatureState } from './utils/non-browser-state';
import Debug from './components/Modal/Debug';
import { getVersionFromMain, isSecuritySetInMain } from './utils/electron';
import SaveData from './components/Modal/SaveData';
// import { SnackBarMessage } from './components/Modal/CustomSnackBar';
import { addStaticNotification } from './redux/actions/snackbar';

import packageJson from '../package.json';
global.appVersion = packageJson.version;

let threshold = process.env.REACT_APP_VERSION_CHECK_THRESHOLD_MINUTES || 15;
threshold = parseInt(threshold.toString()) * 1000 * 60;

let version;
if (nonBrowserFeatureState) {
  getVersionFromMain()
    .then(function (v) {
      version = v;
    })
    .catch((e) => {
      console.log(e);
    });
}
// @ts-ignore
window.APP = {
  UI,
  vacall,
  keyboardshortcut,
};

// window.APP_VERSION = process.env.REACT_APP_VERSION;

const App = () => {
  // const callback = () => {
  //   console.log('Captcha loaded');
  // };

  // useEffect(() => {
  //   loadReCaptcha('6LfFIb0ZAAAAAJMPqY5GzAWyjWOrvWg9v1a0754b', callback);
  // }, []);
  const [showDebugModal, setShowDebugModal] = useState(false);
  const [showSaveDataModal, setShowSaveDataModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Checking pathname and browserFeatureState');
    if (
      !showSaveDataModal &&
      nonBrowserFeatureState &&
      window.location &&
      window.location.pathname.indexOf('/login') === -1
    ) {
      console.log('Checking security');
      isSecuritySetInMain().then((res) => {
        if (res === true) {
          console.log(`Security is missing: ${res}`);
          setShowSaveDataModal(true);
        }
      });
    }
  });

  const checkVersionMismatch = (ver1, ver2) => {
    const latestVersion = ver1.split(/\./g);

    const currentVersion = ver2.split(/\./g);
    console.log(latestVersion);
    while (latestVersion.length || currentVersion.length) {
      let a = latestVersion.shift();
      let b = currentVersion.shift();

      const num1 = Number(a);

      const num2 = Number(b);

      // eslint-disable-next-line no-continue
      if (a === b) continue;
      if (isNaN(num1) && isNaN(num2)) return a > b;
      // eslint-disable-next-line no-restricted-globals
      return num1 > num2 || isNaN(b);
    }

    return false;
  };

  // const reloadAction = (
  //   <Button color="inherit" size="small">
  //     RELOAD
  //   </Button>
  // );

  const emptyCacheStorage = async () => {
    if ('caches' in window) {
      // Service worker cache should be cleared with caches.delete()
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key);
          return 0;
        })
      );
    }

    window.location.replace(window.location.href);
  };

  const refresh = useCallback(() => {
    localStorage.clear();
    emptyCacheStorage();
  }, []);

  const checkMetaJson = useCallback(
    () => async () => {
      const res = await fetch('/meta.json');
      const versionRes = await res.json();
      console.log('======================');
      console.log('metajson', versionRes);
      console.log('packagejson', global.appVersion);
      console.log(new Date());
      const shouldForceRefresh = checkVersionMismatch(
        versionRes.version,
        global.appVersion
      );
      console.log('shouldForceReferesh', shouldForceRefresh);
      if (shouldForceRefresh) {
        dispatch(
          addStaticNotification({
            type: 'general-info',
            message: `There's a new version of AmeraShare`,
            buttonLabel: 'RELOAD',
            buttonOnClick: refresh,
          })
        );
      }
    },
    [dispatch, refresh]
  );

  useEffect(() => {
    checkMetaJson();
    const timer = setInterval(() => {
      checkMetaJson();
    }, parseInt(threshold.toString()));
    return () => {
      clearInterval(timer);
    };
  }, [checkMetaJson]);

  return (
    <>
      {/* <SnackBarMessage autoHideDuration={3600000} onClick={refresh} /> refresh={refresh} /> */}
      <AmeraNotification />
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={MainRouter} />
        </Switch>
      </ConnectedRouter>
      {nonBrowserFeatureState && (
        <div className="debug-container" style={{ zIndex: 1200 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => setShowDebugModal(true)}
          >
            Debug {version && ` - Version: ${version}`}
          </Button>
          <Debug
            show={showDebugModal}
            close={() => setShowDebugModal(false)}
          ></Debug>
          <SaveData
            show={showSaveDataModal}
            close={() => setShowSaveDataModal(false)}
            type={'member'}
          ></SaveData>
        </div>
      )}
    </>
  );
};

export default App;
