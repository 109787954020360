import React, { useState, useMemo } from 'react';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import { store } from '../../../../../utils/store';
import TOTPModal from '../../../../../pages/Registration/TOTPModal';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { DEVICE_TYPES } from '../../../../../utils/contact';

const useStyles = makeStyles((t) => ({
  containedPhone: {
    width: '100%',
    '& .special-label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      top: '-0.95rem',
    },
  },
  standardPhone: {
    width: '100%',
    height: 32,
    '& .special-label': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.74rem',
      top: '-0.5rem',
      left: '-0.1rem',
    },
  },
  containedInput: {
    fontSize: '1rem',
    width: 'auto',
  },
  standardInput: {
    fontSize: '1rem',
    width: 'auto !important',
    borderRadius: '0 !important',
    borderTop: 'none !important',
    borderLeft: 'none !important',
    borderRight: 'none !important',
    borderBottom: '1px solid rgba(0,0,0,0.87)',
    '&:focus': {
      borderBottom: `2px solid ${t.palette.primary.main} !important`,
      boxShadow: 'none !important',
      borderRadius: '0 !important',
      borderTop: 'none !important',
      borderLeft: 'none !important',
      borderRight: 'none !important',
    },
    '&:active': { borderBottom: '2px solid rgba(0,0,0,0.87)' },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
      background: 'none',
      borderBottomStyle: 'dotted',
    },
  },
}));

const PhoneField = (props) => {
  const {
    error,
    disabled,
    phoneNumber,
    phoneCountry,
    userCountry,
    handleChange,
    handleVerificationSuccess,
    isPhoneVerified,
    phoneType,
    countryList,
    variant = 'outlined',
    countryCodeEditable = true,
  } = props;

  console.debug('Phone', props);
  const classes = useStyles();

  const [isShouldVerify, setShouldVerify] = useState(false);
  const [isShowTOTP, setShowTOTP] = useState(false);

  const handlePhoneInput = (value, country) => {
    if (
      phoneType === DEVICE_TYPES.cell ||
      (phoneType === DEVICE_TYPES.TDD && !isPhoneVerified)
    ) {
      // console.log(country);
      const alpha2Code = country.countryCode.toUpperCase();
      const cellRegExp = countryList.find((clo) => clo.alpha2 === alpha2Code)
        .cell_regexp;

      //  If no RegExp, then just start when at least 7 symbols present
      let condition;
      if (cellRegExp) {
        condition = RegExp(cellRegExp).test(value);
      } else {
        condition = value.length >= 10;
      }

      // We await for the number to match country specific Regexp before we allow to send confirmation sms
      if (condition) {
        setShouldVerify(true);
      } else {
        setShouldVerify(false);
      }
    }

    handleChange(value, country.countryCode);
  };

  const handleVerifyClick = () => {
    setShowTOTP(true);
  };

  const allowedCountries = useMemo(
    () => countryList.map((clo) => clo.alpha2.toLowerCase()),
    [countryList]
  );

  return (
    <>
      <TOTPModal
        show={isShowTOTP}
        close={() => setShowTOTP(false)}
        phoneNumber={phoneNumber}
        onVerificationSuccess={handleVerificationSuccess}
        dispatch={store.dispatch}
        // isLoading
      />
      <Grid
        container
        spacing={1}
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs>
          <PhoneInput
            error={Boolean(error)}
            country={!!phoneCountry ? phoneCountry : userCountry} // Use user's country as default
            value={phoneNumber}
            disabled={disabled}
            onlyCountries={allowedCountries}
            onChange={handlePhoneInput}
            inputClass={
              variant === 'outlined'
                ? classes.containedInput
                : classes.standardInput
            }
            containerClass={
              variant === 'outlined'
                ? classes.containedPhone
                : classes.standardPhone
            }
            disableDropdown={false}
            countryCodeEditable={countryCodeEditable}
          />
          <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
        </Grid>
        <Grid item xs>
          {isShouldVerify ? (
            <Button onClick={handleVerifyClick}>Verify</Button>
          ) : null}
          {isPhoneVerified && phoneType !== DEVICE_TYPES.landline ? (
            <VerifiedUserIcon color="primary" />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(PhoneField);
