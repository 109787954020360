import React from 'react';

import Card from '@material-ui/core/Card';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    color: '#868e9a',
  },
  iconContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(-8.25),
  },
  icon: {
    fontSize: theme.spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textContainer: {
    padding: theme.spacing(2.75),
  },
  text: {
    textAlign: 'left',
    fontSize: theme.spacing(2),
  },
}));

const EmptyCard = ({ type, message }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.textContainer}>
        {message && <span className={classes.text}>{message}</span>}
      </div>
      <div className={classes.iconContainer}>
        {type === 'mail' && <DraftsOutlinedIcon className={classes.icon} />}
        {type === 'invitation' && (
          <InsertInvitationIcon className={classes.icon} />
        )}
        {type === 'market' && <SlideshowIcon className={classes.icon} />}
      </div>
    </Card>
  );
};

export default EmptyCard;
