import React, { useRef, useState } from 'react';

import {
  FileBrowser,
  FileList,
  FileToolbar,
  FileContextMenu,
  ChonkyActions,
  FileNavbar,
} from 'chonky';

import {
  useFiles,
  useFolderChain,
  deleteForeverAction,
  restoreAction,
} from '../../utils/fileshare';

import {
  deleteFromGroupBin,
  restoreFromGroupBin,
} from '../../redux/actions/fileshare';

import { connect } from 'react-redux';

const GroupBinTree = (props) => {
  const {
    dispatch,
    tree,
    groupId,
    defaultViewActionId,
    defaultSortActionId,
  } = props;
  const [currentFolderId, setCurrentFolderId] = useState(tree.rootFolderId);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const fileBrowserRef = useRef(null);
  const fileActions = [restoreAction, deleteForeverAction];

  const handleFileAction = async (data) => {
    const { id, state, payload } = data;
    const { selectedFilesForAction } = state;
    if (id === restoreAction.id) {
      await dispatch(
        restoreFromGroupBin(
          groupId,
          selectedFilesForAction.map((f) => f.id)
        )
      );
    } else if (id === ChonkyActions.OpenFiles.id) {
      if (payload.targetFile.isDir) {
        setCurrentFolderId(payload.targetFile.id);
      }
    } else if (id === deleteForeverAction.id) {
      await dispatch(
        deleteFromGroupBin(
          groupId,
          selectedFilesForAction.map((f) => f.id)
        )
      );
    }
  };

  return (
    <FileBrowser
      ref={fileBrowserRef}
      files={useFiles(currentFolderId, tree)}
      enableDragAndDrop
      disableDefaultFileActions={[
        ChonkyActions.ClearSelection.id,
        ChonkyActions.SelectAllFiles.id,
        ChonkyActions.OpenSelection.id,
        ChonkyActions.ToggleHiddenFiles.id,
      ]}
      defaultSortActionId={defaultSortActionId}
      defaultFileViewActionId={defaultViewActionId}
      fileActions={fileActions}
      folderChain={useFolderChain(currentFolderId, tree)}
      onFileAction={handleFileAction}
    >
      <FileNavbar />
      <FileToolbar />
      <FileList />
      <FileContextMenu />
    </FileBrowser>
  );
};

export default connect()(GroupBinTree);
