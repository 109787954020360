import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../components/AmeraBreadCrumbs';
import { PROJECT_PRIVELEGES } from '../../../utils/project';
import ProjectStepsNav from './ProjectStepsNav';
import AssignPrivilege from './AssignPrivilege';
import { Grid, Typography } from '@material-ui/core';
import {
  ExchangeInput,
  PicturePin,
} from '../../../components/Forms/PicturePinForm';
import { SnackBarMessage } from '../../../components/Modal/CustomSnackBar';

// import { projecCreationDateFormat } from '../../../utils/dateFormat';

//actions
import { updateGroupSecurity } from '../../../redux/actions/security';
import { ProjectsApi } from '../../../redux/actions/project';
import { setSnackbarData } from '../../../redux/actions/snackbar';

// route utils
import { routeUtils } from '../../../utils/routeUtils';

// components
import EditModeButtons from './EditModeButtons';

const SetProjectSecurity = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const queryString = routeUtils.useQuery(routerLocation.search);
  const pageMode = queryString.get('mode');

  let { projectId } = useParams();
  let projects = [];
  let projectMembers = null;
  let projectPrivileges = [];
  // selectors
  projects = useSelector((state) => {
    return state.project.projects.filter((o) => o.project_id === +projectId);
  });

  if (projects[0]) {
    projectMembers = projects[0].project_members;
  }

  projectPrivileges = PROJECT_PRIVELEGES;

  // const projectCreation = useSelector((state) => {
  //   return state.project.projectCreation;
  // });
  //get projects data
  useEffect(() => {
    if (!projects.length) {
      dispatch(ProjectsApi.getProjects());
    }
  }, [dispatch, projects.length]);

  const [optionValue, setOptionValue] = useState('MOST_SECURE');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  //const [file, setFile] = useState('');
  const [file, setFile] = useState('');
  const [values, setValues] = useState({});

  const handleBack = (e) => {
    e.preventDefault();
    history.push(
      `${process.env.PUBLIC_URL}/projects/assign-team-roles/${+projectId}`
    );
  };
  const handleNext = () => {
    const groupId = projects[0].group_id;
    // const formData = {};

    // // TODO: configure formData in component and utilize here
    // if (false) {
    //   dispatch(updateSecurity(formData, groupId));
    //   // dispatch(updateSecurity(formData, group_id))
    // }
    const formData = new FormData();
    formData.set('name', values.group_name);
    formData.set('picture', optionValue !== 'NO_ENCRYPTION' ? file : '');
    formData.set('pin', optionValue !== 'NO_ENCRYPTION' ? values.pin : '');
    // formData.set('members', JSON.stringify(selected));
    formData.set('exchangeOption', optionValue);
    formData.set('type', 'create-group');

    dispatch(updateGroupSecurity(formData, groupId));

    history.push(
      `${process.env.PUBLIC_URL}/projects/project-details/${projectId}`
    );
  };

  function handleExit() {
    setIsSaveDisabled(true);
    history.push(`${process.env.PUBLIC_URL}/projects/my-projects`);
  }

  // TODO: save might be always disabled here in set project security page
  function handleSave(e) {
    const groupId = projects[0].group_id;
    const formData = new FormData();
    formData.set('name', values.group_name);
    formData.set('picture', optionValue !== 'NO_ENCRYPTION' ? file : '');
    formData.set('pin', optionValue !== 'NO_ENCRYPTION' ? values.pin : '');
    // formData.set('members', JSON.stringify(selected));
    formData.set('exchangeOption', optionValue);
    formData.set('type', 'create-group');

    dispatch(updateGroupSecurity(formData, groupId, callBackToast));
    // let formData = new FormData();
    // // formData.append('picture', {});
    // formData.append('pin', values.pin);
    // formData.append('exchangeOption', optionValue);

    // dispatch(updateSecurity(formData, groupId));
    // e.preventDefault();
    // console.log(values);
    // console.log(optionValue);
    handleExit();
  }

  const handleOptionChange = (ev) => {
    setIsSaveDisabled(false);
    setOptionValue(ev.target.value);
  };

  const handlePinChange = (data) => {
    if (data.isValidated) setValues({ ...values, pin: data.pin });
    setIsSaveDisabled(false);
    if (data.picture) setFile(data.picture);
  };

  /**
   * 
   * @param {*} options {
        open: true,
        message,
        type: 'success',
      }
  */
  function callBackToast(options) {
    dispatch(setSnackbarData(options));
  }

  return (
    <div className="contact-section assign-roles">
      <SnackBarMessage />
      <Breadcrumbs
        parent="Create Projects"
        current_directory="Project Security Settings"
      />
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        className="new-group-form"
        style={{ paddingRight: '40px' }}
      >
        <Grid
          item
          md={optionValue === 'NO_ENCRYPTION' ? 12 : 5}
          xs={12}
          className="exchange-option"
        >
          <ExchangeInput selected={optionValue} onChange={handleOptionChange} />
        </Grid>
        {optionValue !== 'NO_ENCRYPTION' && (
          <Grid item md={6} xs={12}>
            <PicturePin isExchange={false} onChange={handlePinChange} />
          </Grid>
        )}
      </Grid>
      <hr />
      <Typography variant="h6" gutterBottom>
        Assign priveleges to team members
      </Typography>
      <Typography variant="body2" gutterBottom>
        This will control their access and rights within the project.
      </Typography>
      {projects.length ? (
        <AssignPrivilege
          type="privilege"
          contacts={projectMembers}
          data={projectPrivileges}
        />
      ) : (
        <div>Loading...</div>
      )}
      {pageMode === 'EDIT' ? (
        <EditModeButtons
          isSaveDisabled={isSaveDisabled}
          handleExit={handleExit}
          handleSave={handleSave}
        />
      ) : (
        <ProjectStepsNav
          handleBack={handleBack}
          handleNext={handleNext}
          primaryText="Create Project"
        />
      )}
    </div>
  );
};

export default SetProjectSecurity;
