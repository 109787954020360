import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FileShareSpinner from './FileShareSpinner';
import { connect } from 'react-redux';

import {
  FileBrowser,
  FileList,
  FileToolbar,
  FileContextMenu,
  ChonkyActions,
  FileNavbar,
} from 'chonky';

import { deleteFromBin, putRestoreFile } from '../../redux/actions/fileshare';

import {
  useFiles,
  useFolderChain,
  restoreAction,
  deleteForeverAction,
} from '../../utils/fileshare';

const MemberBinTree = (props) => {
  const { dispatch, binTree, defaultViewActionId, defaultSortActionId } = props;
  const [isLoading, setLoading] = useState(false);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(binTree.rootFolderId);

  const handleRestoreClick = async (fileIds) => {
    setLoading(true);
    await dispatch(putRestoreFile(fileIds));
    setLoading(false);
  };

  const handleFileAction = async (data) => {
    const { id, state, payload } = data;
    const { selectedFilesForAction } = state;
    if (id === restoreAction.id) {
      //   console.log('Will restore these files', files);
      handleRestoreClick(selectedFilesForAction.map((f) => f.id));
    } else if (id === ChonkyActions.OpenFiles.id) {
      if (payload.targetFile.isDir) {
        setCurrentFolderId(payload.targetFile.id);
      }
    } else if (id === deleteForeverAction.id) {
      setLoading(true);
      await dispatch(deleteFromBin(selectedFilesForAction.map((f) => f.id)));
      setLoading(false);
    }
  };

  const fileActions = [restoreAction, deleteForeverAction];
  return (
    <div className="member-files-table file-table">
      <Grid item xs={12} className="file-browser-container">
        <FileShareSpinner isLoading={isLoading} />
        <FileBrowser
          files={useFiles(currentFolderId, binTree)}
          disableDefaultFileActions={[
            ChonkyActions.ClearSelection.id,
            ChonkyActions.SelectAllFiles.id,
            ChonkyActions.OpenSelection.id,
            ChonkyActions.ToggleHiddenFiles.id,
          ]}
          defaultSortActionId={defaultSortActionId}
          defaultFileViewActionId={defaultViewActionId}
          fileActions={fileActions}
          folderChain={useFolderChain(currentFolderId, binTree)}
          onFileAction={handleFileAction}
        >
          <FileNavbar />
          <FileToolbar />
          <FileList />
          <FileContextMenu />
        </FileBrowser>
      </Grid>
    </div>
  );
};

export default connect()(MemberBinTree);
