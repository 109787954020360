import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width: 102,
    background: 'transparent',
    border: '1px solid #D4E2F0',
    height: 22,
    borderRadius: 30,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:before': {
      content: '""',
      width: 100,
      borderRadius: 30,
      border: '4px solid #FFFF',
      height: 20,
      display: 'block',
      position: 'absolute',
      zIndex: 1,
    },
  },
  bar: {
    backgroundColor: 'rgb(148, 192, 61, 0.5)',
    width: 92,
    margin: 4,
    borderRadius: 30,
  },
}))(LinearProgress);

export default BorderLinearProgress;
