import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import List from '@material-ui/core/List';

import Contact from './Contact';
import ContactSkeleton from './Skeleton';

import { setChatConversationID } from '../../../../../../redux/actions/chat';
import { enableE2EEncryption } from '../../../../../../redux/actions/security';

function Contacts() {
  const dispatch = useDispatch();

  // @ts-ignore
  const contacts = useSelector((state) => state.contact.contacts);
  // @ts-ignore
  const rosterItems = useSelector((s) => s.chat.rosterItems);
  // @ts-ignore
  const loading = useSelector((state) => state.contact.loading);
  // @ts-ignore

  const selectedChatID = useSelector((state) => state.chat.selectedChatID);
  // @ts-ignore
  const securityContactId = useSelector((store) => store.security.contact_id);

  const loadConversation = (id) => {
    if (parseInt(id) !== securityContactId) {
      dispatch(enableE2EEncryption(false));
    }
    dispatch(setChatConversationID(id));
  };

  let contactItems = null;
  if (loading) {
    contactItems = [...Array(10).keys()].map((num) => (
      <ContactSkeleton key={num} num={num} />
    ));
  } else {
    contactItems = contacts.map((contact) => (
      <Contact
        key={contact.id}
        // loading={true}
        contact={contact}
        rosterItems={rosterItems}
        selectedContactMemberID={selectedChatID}
        setSelectedContactMemberID={loadConversation}
      />
    ));
  }
  return <List className="contacts-list">{contactItems}</List>;
}

export default Contacts;
