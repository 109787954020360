import {
  APP_START,
  APP_ERROR,
  LOAD_TRANSLATIONS,
  APP_INITIALIZATION_END,
  APP_MESSAGE_UPDATE,
  APP_RESET
} from '../actionTypes/app.js';

// The initial state of the App
export const initialState = {
  loading: false,
  error: null,
  appState: 'NOT_STARTED',
  translations: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case APP_START:
      return {
        ...state,
        appState: 'INITIALIZING',
      };
    case LOAD_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
        appState: 'RUNNING',
      };
    case APP_INITIALIZATION_END:
      return {
        ...state,
        appState: 'RUNNING',
      };
    case APP_MESSAGE_UPDATE:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.payload.language]: {
            ...state.translations[action.payload.language],
            [action.payload.id]: action.payload.text,
          },
        },
      };
    case APP_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default user;
