import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import prettyBytes from 'pretty-bytes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { includes } from 'lodash';

import Thumb from '../../../components/Thumb';
import { getDownloadFile } from '../../../redux/actions/fileshare';
import { RASTER_MIME } from '../../../utils/file';

const useStyles = makeStyles((theme) => ({
  root: {
    outline: 'auto',
    outlineColor: '#E0E0E0',
    position: 'relative',
    '& li': {
      display: 'flex',
    },
  },
  label: {
    position: 'absolute',
    top: -10,
    padding: '0 6px',
    left: 10,
    background: '#FFFFFF',
  },
  actions: {
    display: 'flex',
    position: 'relative',
    transform: 'none',
  },
  grayBlue: {
    color: '#8DA3C5',
  },
}));

const AttachmentList = (props) => {
  const { attachedFiles, onClearClick, cover, onSelectCover } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <label className={classes.label}>Attached files </label>
      <List dense={true}>
        {attachedFiles.map((afo, idx) => {
          const isImage = includes(RASTER_MIME, afo.mime_type);
          return (
            <ListItem key={`attachment-${afo.file_id}-${idx}`}>
              <ListItemIcon>
                {isImage ? (
                  <Thumb src={afo.file_url} />
                ) : (
                  <InsertDriveFileIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={afo.file_name}
                secondary={prettyBytes(afo.file_size_bytes)}
              />
              <ListItemSecondaryAction className={classes.actions}>
                {isImage && !!onSelectCover && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.grayBlue}
                        color="primary"
                        checked={cover === afo.member_file_id}
                        onChange={() => onSelectCover(afo.member_file_id)}
                      />
                    }
                    label="Cover"
                  />
                )}
                <Tooltip title="Download">
                  <IconButton
                    className={classes.grayBlue}
                    onClick={() => getDownloadFile(afo.file_url, afo.file_name)}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
                {!!onClearClick && (
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => onClearClick(afo)}
                      className={classes.grayBlue}
                    >
                      <ClearRoundedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default AttachmentList;
