import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import {
  // checkFileDuplicates,
  // prepareToUpload,
  getNodes,
  // prepareForm,
  getUploadForm,
  keepBothName,
  fileArrToNodes,
  checkNodeDuplicates,
} from '../../../utils/fileshare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FolderIcon from '@material-ui/icons/Folder';
import { nonBrowserFeatureState } from '../../../utils/non-browser-state';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

import Modal from '../../../components/Modal/modal';

import {
  postUploadFiles,
  // encryptFileToSend,
} from '../../../redux/actions/fileshare';

import { includes } from 'lodash';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

const UploadFile = (props) => {
  const {
    show,
    close,
    currentFolderId,
    path,
    dispatch,
    fileTree,
    // isCreateLoading,
    isLoadingEncrypt,
  } = props;
  // console.log('This is create new file, targetFolder is', targetFolder);

  const [loading, setLoading] = useState(false);
  const [duplicates, setDuplicates] = useState(null);
  const [encrypting, setEncrypting] = useState(false);
  const prevState = usePrevious({ loading });
  const [error, setError] = useState('');
  // const [categories, setCategories] = useState('');
  const [selectedNodes, setSelectedNodes] = useState(null);
  const [isOverWriting, setOverWriting] = useState(false);
  // Encrypt on by default in Electron
  const [isShouldEncrypt, setShouldEncrypt] = useState(
    nonBrowserFeatureState ? true : false
  );

  const title = 'Upload Files';
  const classes = useStyles();

  const onClose = useCallback(() => {
    setSelectedNodes(null);
    setDuplicates(null);
    setOverWriting(false);
    close();
  }, [close]);

  useEffect(() => {
    setEncrypting(isLoadingEncrypt);
  }, [isLoadingEncrypt]);

  useEffect(() => {
    if (prevState && loading === false && prevState.loading === true) {
      onClose();
    }
  }, [loading, onClose, prevState]);

  const onChangeHandler = (event) => {
    setError('');
    const fileList = event.target.files;
    const nodes = fileArrToNodes(fileList);
    const siblingNodes = getNodes(fileTree, currentFolderId);
    setSelectedNodes(nodes);
    setDuplicates(checkNodeDuplicates(nodes, siblingNodes));
  };

  const upload = async (nodesArray) => {
    // send data away
    setLoading(true);
    let nodesMeta = {
      tagetFolderId: currentFolderId,
      nodesToDelete: [],
      meta: nodesArray.map((n) => ({
        node_temp_id: n.node_temp_id,
        name: n.name,
        isDir: !n.file,
        size: n.size,
        parentId: n.parentId,
        level: n.level,
        iv: null, //FIXME: work on this when debugging electron
      })),
    };

    const files = nodesArray
      .map((n) => ({ id: n.node_temp_id, file: n.file }))
      .filter((f) => !!f.file); // .filter((f) => !!f.file);

    if (duplicates && duplicates.length > 0) {
      if (isOverWriting) {
        console.log('Duplicates - will overwrite');
        // Send existing files to bin"
        nodesMeta = {
          ...nodesMeta,
          nodesToDelete: duplicates.map((d) => d.counterpart_node_id),
        };
      } else {
        console.log('Duplicates - Will rename');
        // Change names for files having duplicates
        const duplicateIds = duplicates.map((d) => d.node_temp_id);
        nodesMeta = {
          ...nodesMeta,
          meta: nodesMeta.meta.map((n) =>
            includes(duplicateIds, n.node_temp_id)
              ? { ...n, name: keepBothName(n.name) }
              : n
          ),
        };
      }
    } else {
      console.log('No duplicates');
    }

    console.log('metadata', nodesMeta);
    console.log('files', files);

    await dispatch(postUploadFiles(getUploadForm(nodesMeta, files), path));
    setLoading(false);
  };

  const handleUploadClick = async (event) => {
    event.preventDefault();
    if (selectedNodes && selectedNodes.length > 0) {
      if (isShouldEncrypt) {
        // encrypt & upload
        // upload(await dispatch(encryptFileToSend(selectedFiles)));
      } else {
        upload(selectedNodes);
      }
    } else {
      setError(
        'This field is required, please choose one or more files to upload.'
      );
      return false;
    }
  };

  const onToggleDuplicateAction = () => {
    setOverWriting(!isOverWriting);
  };

  const encryptionOption = () => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={isShouldEncrypt}
            onChange={() => setShouldEncrypt((prevState) => !prevState)}
          />
        }
        label="Encrypt before uploading"
      />
    </div>
  );

  const ModalContent = () => (
    <>
      <label htmlFor="upload-photo">
        <input
          style={{ display: 'none' }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          multiple="multiple"
          onChange={onChangeHandler}
        />

        <Button
          color="secondary"
          variant="contained"
          component="span"
          className="upload_btn"
        >
          Select File(s)
        </Button>
        {error && <p className="error">{error}</p>}
      </label>
      {/* Add folders */}
      <div>
        <label htmlFor="upload-folder">
          <input
            style={{ display: 'none' }}
            id="upload-folder"
            name="upload-folder"
            type="file"
            multiple="multiple"
            webkitdirectory=""
            mozdirectory=""
            msdirectory=""
            odirectory=""
            directory=""
            onChange={onChangeHandler}
          />

          <Button
            color="secondary"
            variant="contained"
            component="span"
            className="upload_btn"
          >
            Select Folder(s)
          </Button>
          {error && <p className="error">{error}</p>}
        </label>
      </div>

      {selectedNodes && selectedNodes.length > 0 && (
        <span className="selected-file-count">
          {`${selectedNodes.filter((n) => !!n.file).length} ${
            selectedNodes.filter((n) => !!n.file).length > 1 ? 'files' : 'file'
          } selected`}
        </span>
      )}

      {duplicates && duplicates.length > 0 && (
        <div>
          {' '}
          The following items you are attempting to upload already exist{' '}
          <List dense={true}>
            {duplicates.map((d) => (
              <ListItem key={d.node_temp_id}>
                <ListItemIcon>
                  {d.isDir ? <FolderIcon /> : <InsertDriveFileIcon />}
                </ListItemIcon>
                <ListItemText primary={d.name} />
              </ListItem>
            ))}
          </List>
          <div>Would you like to overwrite these files or keep both?</div>
          <div>
            <FormControl size="small">
              <RadioGroup
                onChange={onToggleDuplicateAction}
                value={isOverWriting ? 'overwrite' : 'keep_both'}
              >
                <FormControlLabel
                  label="Overwrite all"
                  value="overwrite"
                  control={<Radio />}
                />
                <FormControlLabel
                  label="Keep both (rename)"
                  value="keep_both"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}

      {nonBrowserFeatureState ? encryptionOption() : null}
    </>
  );

  const ModalSubmitAction = () => {
    if (encrypting) {
      return (
        <Button variant="contained" className={classes.actionBtn} disabled>
          <CircularProgress size={'1.5em'} className={classes.buttonProgress} />
          Encrypting...
        </Button>
      );
    } else if (loading) {
      return (
        <Button variant="contained" className={classes.actionBtn} disabled>
          <CircularProgress size={'1.5em'} className={classes.buttonProgress} />
          Uploading...
        </Button>
      );
    } else {
      return (
        <Button
          startIcon={<CloudUploadIcon />}
          variant="contained"
          color="primary"
          className={classes.actionBtn}
          disableRipple
          onClick={handleUploadClick}
        >
          Upload
        </Button>
      );
    }
  };

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={() => onClose()}
          disabled={loading}
        >
          Cancel
        </Button>
        <ModalSubmitAction />
      </div>
    );
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="upload-file-modal"
    ></Modal>
  );
};

const mapStateToProps = (state) => ({
  // isCreateLoading: state.fileshare.createLoading,
  isLoadingEncrypt: state.fileshare.encryptLoading,
  // fileTree: state.fileshare.main_tree,
  // member: state.member.member,
});

export default connect(mapStateToProps)(UploadFile);
