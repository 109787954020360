module.exports = {
  channels: {
    ENCRYPT_FILES: 'encrypt-files',
    ENCRYPT_FILES_FAILED: 'encrypt-files-failed',
    ENCRYPT_FILES_SUCCESS: 'encrypt-files-success',
    ENCRYPT_LOCAL_DIRECTORY_FILES: 'encrypt-local-directory-files',
    DECRYPT_FILES: 'decrypt-files',
    DECRYPT_FILES_FAILED: 'decrypt-files-failed',
    DECRYPT_FILES_SUCCESS: 'decrypt-files-success',
    DECRYPT_LOCAL_DIRECTORY_FILES: 'decrypt-local-directory-files',
    SET_GROUP_PICTURE_PIN: 'set-group-picture-pin',
    GET_LOCAL_DIRECTORY: 'get-local-directory',
    GET_LOCAL_DIRECTORY_ERROR: 'get-local-directory-error',
    SET_SECURITY: 'set-security',
    GET_SECURITY: 'get-security',
    CLEAR_SECURITY: 'clear-security',
    TEST_SECURITY_PICTURE: 'test-security-picture',
    RELOAD_CONTENTS: 'reload-contents',
    GET_VERSION: 'get-version',
    DEBUG_DOWNLOAD: 'download-file-for-debug',
    DOWNLOAD_SUCCESS: 'download-success',
    DOWNLOAD_ENCRYPTED_SUCCESS: 'download_encrypted_success',
    DOWNLOAD_ERROR: 'download_error',
  },
};
