import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import LocationCountrySelector from './LocationCountrySelector';
import StreetAutocomplete from './StreetAutocomplete';
import PhoneField from '../pages/Profile/ProfileEdit/PhoneList/PhoneListItem/PhoneInput';

const AddressInputForm = (props) => {
  const {
    countryCode,
    streetAddress1,
    streetAddress2,
    adminArea1,
    adminArea2,
    locality,
    postal,
    onStreetAddress2,
    onLocality,
    onAdminArea1,
    onAdminArea2,
    onPostal,
    onCountrySelected,
    onSuggestionClick,
    countryDescription,
    error,
    disabled = false,
    isEstablishment = false,
    phone,
  } = props;

  //   console.debug('countryDescription', countryDescription);

  return (
    <>
      <Grid item xs={12}>
        <LocationCountrySelector
          country={!!countryCode ? countryCode : 840}
          handleChange={onCountrySelected}
          fullWidth
          disabled={disabled}
        />
      </Grid>
      {/* Phone */}
      {phone && (
        <Grid item xs={12}>
          <PhoneField
            phoneNumber={phone.phoneNumber}
            phoneCountry={phone.phoneCountry}
            phoneType={phone.phoneType}
            variant={phone.variant}
            error={phone.error}
            handleChange={phone.handleChange}
          />
        </Grid>
      )}
      {/* Street Address 1 */}
      <Grid item xs={12}>
        <StreetAutocomplete
          id="streetAddress1"
          label={countryDescription.street_address_1_label}
          street={streetAddress1}
          countryAlpha2={countryDescription.alpha2}
          handleChange={onSuggestionClick}
          isShowSearchIcon
          types={isEstablishment ? ['establishment'] : ['geocode']}
          isShowFullAddress={false}
          variant="standard"
          error={
            !!error?.street_address_1
              ? `${countryDescription.street_address_1_label} ${error.street_address_1}`
              : ''
          }
          disabled={disabled}
        />
      </Grid>
      {/* Street Address 2 */}
      <Grid item xs={12}>
        <TextField
          id="streetAddress2"
          label={countryDescription.street_address_2_label}
          value={streetAddress2 || ''}
          inputProps={{ autoComplete: 'new-password' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onStreetAddress2}
          disabled={disabled}
        />
      </Grid>
      {/* Locality */}
      <Grid item xs={6}>
        <TextField
          id="locality"
          error={!!error?.locality}
          label={countryDescription.locality_label}
          value={locality || ''}
          inputProps={{ autoComplete: 'new-password' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onLocality}
          helperText={
            !!error?.locality
              ? `${countryDescription.locality_label} ${error.locality}`
              : ''
          }
          disabled={disabled}
        />
      </Grid>
      {/* City */}
      <Grid item xs={6}>
        <TextField
          id="adminArea2"
          error={!!error?.admin_area_2}
          label={countryDescription.admin_area_2_label}
          value={adminArea2 || ''}
          inputProps={{ autoComplete: 'new-password' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onAdminArea2}
          helperText={
            !!error?.admin_area_2
              ? `${countryDescription.admin_area_2_label} ${error.admin_area_2}`
              : ''
          }
          disabled={disabled}
        />
      </Grid>
      {/* Province */}
      <Grid item xs={6}>
        <TextField
          id="adminArea1"
          error={!!error?.admin_area_1}
          label={countryDescription.admin_area_1_label}
          value={adminArea1 || ''}
          inputProps={{ autoComplete: 'new-password' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onAdminArea1}
          helperText={
            !!error?.admin_area_1
              ? `${countryDescription.admin_area_1_label} ${error.admin_area_1}`
              : ''
          }
          disabled={disabled}
        />
      </Grid>
      {/* Postal */}
      <Grid item xs={6}>
        <TextField
          id="postal"
          error={!!error?.postal_code}
          label={countryDescription.postal_code_label}
          value={postal || ''}
          inputProps={{ autoComplete: 'new-password' }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          onChange={onPostal}
          helperText={
            !!error?.postal_code
              ? `${countryDescription.postal_code_label} ${error.postal_code}`
              : ''
          }
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  countryDescription: !!ownProps.countryCode
    ? state.member.countryList.find((clo) => clo.id === ownProps.countryCode)
    : state.member.countryList.find((clo) => clo.id === 840),
});

export default connect(mapStateToProps)(AddressInputForm);
