import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M18.688 4.313c5.375 1.188 9.313 6 9.313 11.688s-3.938 10.5-9.313 11.688v-2.75c3.875-1.125 6.625-4.688 6.625-8.938s-2.75-7.813-6.625-8.938v-2.75zM22 16a5.936 5.936 0 01-3.313 5.375v-10.75c2 1 3.313 3 3.313 5.375zM4 12h5.313l6.688-6.688v21.375l-6.688-6.688H4v-8z"></path>
    </svg>
  );
}
export default Icon;
