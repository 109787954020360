import React from 'react';
import '../../../../../styles/components/_DrivingDirections.scss';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { maneuverClass } from '../../../../../utils/country';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  distance: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const DirectionListItem = (props) => {
  const { step, onClick } = props;
  const { instructions, maneuver, duration, distance } = step;

  const classes = useStyles();
  return (
    <div className={classes.root} onClick={!!onClick ? onClick : null}>
      <div className={`maneuver ${maneuverClass(maneuver)}`}></div>
      <div className={classes.container}>
        <div dangerouslySetInnerHTML={{ __html: instructions }}></div>
        <div className={classes.distance}>
          <Typography
            variant="overline"
            color="textSecondary"
            gutterBottom
            noWrap
          >
            {distance.text} ({duration.text}){' '}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DirectionListItem;
