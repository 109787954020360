import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import PersonIcon from '@material-ui/icons/Person';
import CardButton from '../Dashboard/CardButton';
import { connect } from 'react-redux';
import { closeDrawer, openDrawer } from '../../redux/actions/drawer';
import { GROUP_ROLES } from '../../utils/group';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';
import { addressTypeText } from '../../utils/calendar';
import { orderBy, includes, uniqBy } from 'lodash';
import drawerProfileStyles from '../../jss/views/drawerProfileStyles';
import MaterialAvatar from '../MaterialAvatar';
import {
  displayableFullName,
  formatPhoneNumber,
  formatDuration,
  formatReceived,
  DEVICE_TYPES,
} from '../../utils/contact';
import { getCompanyDetail } from '../../redux/actions/company';
import { getMemberInfo } from '../../redux/actions/member';
import AmeraAccordion from '../AmeraAccordion/AmeraAccordion';
import AmeraDataCard from '../AmeraDataCard';
import AddressLine from '../AddressLine';

const MyProfileInfo = ({ memberInfo, groups, countryList, dispatch }) => {
  const classes = drawerProfileStyles();

  const {
    member_id,
    first_name,
    last_name,
    company_name,
    company_membership,
    job_title,
    department_name,
    biography,
    skills_information,
    contact_information,
    location_information,
    workhistory_information,
    education_information,
    certificate_information,
    achievement_information,
    country_code,
    pay_rate,
    currency_code,
  } = memberInfo;

  // We need this because location can be updated by payments endpoint
  useEffect(() => {
    dispatch(getMemberInfo());
  }, [dispatch]);

  const handleCancelClick = () => dispatch(closeDrawer());
  const handleEditClick = () =>
    dispatch(openDrawer(DRAWER_CONTENT_TYPE.myProfileEdit));

  const handleCompanyClick = async () => {
    await dispatch(getCompanyDetail(company_membership.company_id));
    await dispatch(
      openDrawer(DRAWER_CONTENT_TYPE.companyProfile, {
        companyId: company_membership.company_id,
      })
    );
  };

  let departmentName, companyName;

  if (!!company_membership) {
    departmentName = company_membership?.department_name;
    companyName = company_membership?.company_name;
  } else {
    departmentName = department_name;
    companyName = company_name;
  }
  let country_code_name =
    country_code && country_code.length && country_code[0]
      ? country_code[0].name
      : '';

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <h3 className={classes.title}>My Profile</h3>
        </div>
      </div>
      <div className={classes.body}>
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <MaterialAvatar
              style={{ height: '10rem', width: '10rem' }}
              member_id={member_id}
              firstName={first_name}
              lastName={last_name}
              badgeType="edit"
              variant="circular"
              badgeAnchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              onClick={handleEditClick}
            />
          </Grid>
          <Grid
            item
            xs={7}
            container
            spacing={0}
            alignItems="baseline"
            justify="center"
            direction="column"
          >
            <Typography variant="h6" color="initial">
              <b>{displayableFullName(memberInfo, false)}</b>
            </Typography>
            <Typography
              variant="body1"
              className={classes.companyName}
              onClick={
                !!company_membership?.company_id
                  ? () => handleCompanyClick()
                  : () => null
              }
            >
              {!!companyName ? companyName : 'Not specified'}
            </Typography>
            {!!job_title && (
              <Typography variant="body2">
                <b>Title:</b> {job_title}
              </Typography>
            )}
            <Typography variant="body2">
              <b>Department:</b>{' '}
              {!!departmentName ? departmentName : 'Not specified'}
            </Typography>
            {pay_rate && (
              <Typography variant="body2">
                <b>Rate:</b> {currency_code} {pay_rate} / hour
              </Typography>
            )}
            <Typography variant="body2">{country_code_name}</Typography>
          </Grid>
          <Grid item className={classes.noTopPadding} xs={12}>
            <AmeraAccordion label="Contact Information">
              <div className={classes.cardArray}>
                {contact_information?.length > 0 ? (
                  orderBy(contact_information, ['display_order'])
                    .filter((cio) => cio !== null && cio !== undefined)
                    .map((cio) => (
                      <AmeraDataCard
                        key={cio.id}
                        title={cio.description}
                        subTitle={cio.primary_contact ? 'Primary' : null}
                        content={
                          includes(
                            [
                              DEVICE_TYPES.cell,
                              DEVICE_TYPES.landline,
                              DEVICE_TYPES.TDD,
                            ],
                            cio.device_type
                          )
                            ? formatPhoneNumber(
                                countryList.find(
                                  (cl) => cl.id === cio.device_country
                                ).phone,
                                cio.device
                              )
                            : cio.device
                        }
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Location Information">
              <div className={classes.cardArray}>
                {location_information?.length > 0 &&
                location_information.find((lio) => !!lio.location_id) ? (
                  location_information
                    .filter((lio) => !!lio.location_id) //Makes sure it is filled by new tables data
                    .map((lio) => (
                      <AmeraDataCard
                        key={lio.id}
                        title={lio.description || lio.street_address_1}
                        subTitle={addressTypeText(lio.location_type)}
                        content={<AddressLine data={lio} />}
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Skills">
              <div className={classes.chipArray}>
                {skills_information?.length > 0 ? (
                  skills_information
                    .filter((s) => s !== null && s !== undefined)
                    .map((s) => (
                      <Chip
                        size="small"
                        key={s.id}
                        className={classes.chip}
                        label={s.name}
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Group Memberships">
              <div className={classes.chipArray}>
                {groups?.length > 0 ? (
                  groups
                    .filter((gm) => gm !== null && gm !== undefined)
                    .map((gm) => (
                      <Chip
                        size="small"
                        key={gm.group_id}
                        className={classes.chip}
                        label={gm.group_name}
                        avatar={
                          gm.group_role === GROUP_ROLES.owner ? (
                            <PersonIcon />
                          ) : null
                        }
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Bio">
              <Typography variant="body2" className={classes.bio}>
                {biography}
              </Typography>
            </AmeraAccordion>
            <AmeraAccordion label="Work Experience">
              <div className={classes.cardArray}>
                {workhistory_information?.length > 0 ? (
                  workhistory_information
                    .filter((wio) => wio !== null && wio !== undefined)
                    .map((wio) => (
                      <AmeraDataCard
                        key={wio.id}
                        title={wio.job_title}
                        subTitle={wio.company_name}
                        content={
                          <>
                            <span>
                              {formatDuration(
                                wio.start_date,
                                wio.end_date,
                                true
                              )}
                            </span>
                            <br />
                            <span>{wio.company_location}</span>
                          </>
                        }
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Education">
              <div className={classes.cardArray}>
                {education_information?.length > 0 ? (
                  education_information
                    .filter((io) => io !== null && io !== undefined)
                    .map((io) => (
                      <AmeraDataCard
                        key={io.id}
                        title={io.school_name}
                        subTitle={`${io.degree}, ${io.field_of_study}`}
                        content={formatDuration(io.start_date, io.end_date)}
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Certification">
              <div className={classes.cardArray}>
                {certificate_information?.length > 0 ? (
                  certificate_information
                    .filter((ci) => ci !== null && ci !== undefined)
                    .map((ci) => (
                      <AmeraDataCard
                        key={ci.id}
                        subTitle={ci.title}
                        content={formatReceived(ci.date_received)}
                      />
                    ))
                ) : (
                  <div className={classes.emptyFallback}>
                    No information provided
                  </div>
                )}
              </div>
            </AmeraAccordion>
            <AmeraAccordion label="Achievements">
              {achievement_information?.length > 0 ? (
                orderBy(achievement_information, ['display_order'])
                  .filter((ai) => ai !== null && ai !== undefined)
                  .map((ai) => (
                    <Typography className={classes.achievement} key={ai.id}>
                      <b>{ai.entity}</b> - {ai.description}
                    </Typography>
                  ))
              ) : (
                <div className={classes.emptyFallback}>
                  No information provided
                </div>
              )}
            </AmeraAccordion>
          </Grid>
          <Grid
            item
            className={classes.noTopPadding}
            xs={12}
            container
            spacing={1}
            justify="flex-end"
          >
            <Grid item>
              <CardButton
                variant="info"
                text="Cancel"
                onClick={handleCancelClick}
              />
            </Grid>
            <Grid item>
              <CardButton
                variant="okFilled"
                text="Edit"
                onClick={handleEditClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  countryList: state.member.countryList,
  memberInfo: state.member.memberInfo,
  groups: uniqBy(
    [...state.group.groupList, ...state.group.groupMembershipList],
    'group_id'
  ),
});

export default connect(mapStatetoProps)(MyProfileInfo);
