import React, { useEffect, useState, useRef } from 'react';
import { ApiEmailService } from '../../config/apiService';
import {
  getEmailQueryParams,
  getUnstyledHtmlBody,
  mailButtonGroup,
} from '../../utils/email';
import { scrollIntoView } from '../../utils/general';
import { EmailPageWrapper } from './SharedComponents/EmaliPageWrapper';
import { apiPaths } from '../../config/apiPaths';

const Archive = (props) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmailXref, setSelectedEmailXref] = useState([]);
  const [emails, setEmails] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  const cleanup = useRef(false);

  const bottomButtonsHandlers = {
    [mailButtonGroup.mailMoveToTrash.name]: async (event) => {
      if (selectedEmail) {
        const res = await ApiEmailService.postEmail(
          'postEmailFromArchiveToTrash',
          {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          }
        );
        if (res.success) getEmails();
      }
    },
    [mailButtonGroup.mailMoveToOrigin.name]: async (event) => {
      if (selectedEmail) {
        const res = await ApiEmailService.postEmail('postMoveArchiveToOrigin', {
          mail_id: selectedEmail.mail_id,
          xref_id: selectedEmail.xref_id,
        });
        if (res.success) getEmails();
      }
    },
  };

  const getEmails = async (start_id) => {
    let params = getEmailQueryParams(
      start_id,
      selectSort,
      props.search,
      null,
      props.contact_filter
    );
    let tmp_data = await ApiEmailService.getEmails('getArchive', params);
    let { data, total } = tmp_data !== undefined ? tmp_data : {};

    if (data !== undefined && !cleanup.current) {
      data.forEach((email) => {
        email.noStyleBody = getUnstyledHtmlBody(email.body);
      });

      setSelectedEmail(null);
      if (start_id) {
        setEmails([...emails, ...data]);
      } else {
        setEmails([...data]);
      }
    }

    if (total && !cleanup.current) {
      setTotalCount(total);
    }
  };

  const getEmailDetails = async (id) => {
    let data = await ApiEmailService.getEmailDetails(
      'getArchiveEmailDetails',
      id
    );
    setSelectedEmail({ ...data, mail_id: id });
  };

  const selectEmail = (id, xref_id, multi = false, is_detail_view = false) => {
    if (is_detail_view) {
      if (Array.isArray(id)) {
        getEmailDetails(id[id.length - 1]).then(() => {
          scrollIntoView('selected-mail-details');
        });
      } else {
        getEmailDetails(id).then(() => {
          scrollIntoView('selected-mail-details');
        });
      }
      return;
    }
    if (Array.isArray(id)) {
      let is_same =
        selectedEmails.length === id.length &&
        selectedEmailXref.length === xref_id.length &&
        selectedEmails.every(function (element, index) {
          return element === id[index];
        }) &&
        selectedEmailXref.every(function (element, index) {
          return element === xref_id[index];
        });
      if (is_same) {
        setSelectedEmails([]);
        setSelectedEmailXref([]);
      } else {
        setSelectedEmails(id);
        setSelectedEmailXref(xref_id);
        const index = emails.findIndex((x) => x.mail_id === id[id.length - 1]);

        if (index !== -1 && emails[index].read === false) {
          const _emails = [...emails];
          _emails[index].read = true;
          setEmails(_emails);
        }
        getEmailDetails(id[id.length - 1]).then(() => {});
      }
    } else {
      const index = emails.findIndex((x) => x.mail_id === id);

      if (index !== -1 && emails[index].read === false) {
        const _emails = [...emails];
        _emails[index].read = true;
        setEmails(_emails);
      }
      getEmailDetails(id).then(() => {});

      let findItem = selectedEmails.findIndex((el) => el === id);
      let findItemXref = selectedEmailXref.findIndex((el) => el === xref_id);
      if (findItem === -1) {
        setSelectedEmails([...selectedEmails, id]);
        if (multi) {
          setSelectedEmailXref([...selectedEmailXref, xref_id]);
        } else {
          setSelectedEmailXref([xref_id]);
        }
      } else {
        if (findItemXref !== -1) {
          // Delete current mail!
          let xrefBackup = [...selectedEmailXref];
          xrefBackup.splice(findItemXref, 1);
          setSelectedEmailXref([...xrefBackup]);
          // -- END --
          // Search if any other emails with same header exists
          let tempEmails = emails
            .filter((el) => {
              return el.mail_id === id;
            })
            .map((el) => {
              return el.xref_id;
            });
          findItemXref = xrefBackup.findIndex((el) => {
            return tempEmails.indexOf(el) > -1;
          });
          if (findItemXref === -1) {
            let newSelected = [...selectedEmails];
            newSelected.splice(findItem, 1);
            setSelectedEmails([...newSelected]);
          }
          // -- END --
        } else {
          if (multi) {
            setSelectedEmailXref([...selectedEmailXref, xref_id]);
          } else {
            setSelectedEmailXref([xref_id]);
          }
        }
      }
    }
  };

  useEffect(() => {
    props.setMemberUrl(apiPaths.mail.getSelectableMembersArchive);
    // eslint-disable-next-line
  }, [props.setMemberUrl]);

  useEffect(() => {
    getEmails();
    return () => {
      cleanup.current = true;
    };
    // eslint-disable-next-line
  }, []);

  const [selectSort, setSelectSort] = React.useState(0);

  const handleChangeSort = (e) => {
    setSelectSort(e.target.value);
    getEmails(null, e.target.value);
  };
  // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
  // const [selectFilter, setSelectFilter] = React.useState(0);
  // const handleChangeFilter = (e) => {
  //   setSelectFilter(e.target.value);
  // };

  return (
    <EmailPageWrapper
      section="Archive"
      view={props.view}
      selectedEmail={selectedEmail}
      selectedEmails={selectedEmails}
      emails={emails}
      totalCount={totalCount}
      sortControls={{
        value: selectSort,
        changeValue: handleChangeSort,
      }}
      // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
      // filterControls={{
      //   value: selectFilter,
      //   changeValue: handleChangeFilter,
      // }}
      bottomButtonsHandlers={bottomButtonsHandlers}
      selectEmail={selectEmail}
    ></EmailPageWrapper>
  );
};

export default Archive;
