import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Modal from '../Modal/modal';
import EventModalActions from '../Modal/EventModals/EventModalActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
}));

const SettingModal = (props) => {
  const classes = useStyles();

  const {
    open,
    title,
    state,
    handleChange,
    streamTypes,
    handleClose,
    className,
    handleSubmit,
  } = props;

  const ModalContent = () => {
    return (
      <Grid container className={classes.root}>
        <FormControl variant="outlined" margin="dense" fullWidth>
          <Select
            native
            value={state}
            onChange={handleChange}
            inputProps={{
              name: 'name-select',
              id: 'name-native-simple',
            }}
          >
            {streamTypes
              .sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              )
              .map((type) => {
                const { id, name } = type;
                return (
                  <option aria-label={name} value={id} key={id}>
                    {name}
                  </option>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${title ? title : 'Streaming'} Settings`}
      ModalContent={ModalContent}
      ModalAction={() => (
        <EventModalActions
          closeLabel="Cancel"
          onClose={handleClose}
          loadingLabel="..."
          actionLabel="Save"
          onAction={handleSubmit}
        />
      )}
      className={className ? className : 'stream-settings-modal'}
    />
  );
};

export default SettingModal;
