import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const ProjectSearch = (props) => {
  const { searchInput, handleChange } = props;
  return (
    <TextField
      label="Search"
      fullWidth
      id="project-search-input"
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment postion="end">
            <IconButton onClick={() => handleChange('')}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment postion="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      value={searchInput}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default ProjectSearch;
