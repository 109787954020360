export const CREATE_GROUP = 'CREATE_GROUP';
export const IS_LOADING_CREATE_GROUP = 'IS_LOADING_CREATE_GROUP';

export const GET_GROUP_LIST = 'GET_GROUP_LIST';
export const IS_LOADING_GET_GROUP_LIST = 'IS_LOADING_GET_GROUP_LIST';

export const GET_GROUP_DETAIL = 'GET_GROUP_DETAIL';
export const IS_LOADING_GET_GROUP_DETAIL = 'IS_LOADING_GET_GROUP_DETAIL';

export const LOAD_GROUP_MEMBERS = 'LOAD_GROUP_MEMBERS';
export const ADD_MEMBER_TO_NEW_GROUP = 'ADD_MEMBER_TO_NEW_GROUP';
export const INITIAL_GROUP_MEMBERS = 'INITIAL_GROUP_MEMBERS';
export const REMOVE_GROUP_MEMBER = 'REMOVE_GROUP_MEMBER';
export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER';

export const ADD_GROUP_MEMBER_START = 'ADD_GROUP_MEMBER_START';
export const SET_GROUP_ALERT = 'SET_GROUP_ALERT';
export const REMOVE_GROUP_ALERT = 'REMOVE_GROUP_ALERT';

export const SET_SHOW_GROUP_DETAIL_MODAL = 'SET_SHOW_GROUP_DETAIL_MODAL';

export const SET_ADD_MEMBER_FROM = 'SET_ADD_MEMBER_FROM';

export const CREATE_GROUP_MEMBERSHIP = 'CREATE_GROUP_MEMBERSHIP';
export const IS_LOADING_CREATE_GROUP_MEMBERSHIP =
  'IS_LOADING_CREATE_GROUP_MEMBERSHIP';

export const FETCH_GROUP_MEMBERSHIPS_START = 'FETCH_GROUP_MEMBERSHIPS_START';
export const FETCH_GROUP_MEMBERSHIPS_SUCCESS =
  'FETCH_GROUP_MEMBERSHIPS_SUCCESS';
export const FETCH_GROUP_MEMBERSHIPS_FAILED = 'FETCH_GROUP_MEMBERSHIPS_FAILED';

export const REMOVE_GROUP_MEMBERSHIP = 'REMOVE_GROUP_MEMBERSHIP';

export const GET_CONTACT_GROUP_MEMBERSHIP = 'GET_CONTACT_GROUP_MEMBERSHIP';
// export const IS_LOADING_GET_GROUP_MEMBERSHIP =
//   'IS_LOADING_GET_GROUP_MEMBERSHIP'; unused

export const SET_AMERA_GROUP_SECURITY = 'SET_AMERA_GROUP_SECURITY';

export const IS_LOADING_MEMBER_INVITE = 'IS_LOADING_MEMBER_INVITE';

export const IS_DELETING_GROUP = 'IS_DELETING_GROUP';

export const DELETED_GROUP = 'DELETED_GROUP';

export const FAIL_DELETED_GROUP = 'FAIL_DELETED_GROUP';

export const SET_GROUP_DRIVE_ACTIVITY = 'SET_GROUP_DRIVE_ACTIVITY';

export const SET_GROUP_CALENDAR_ACTIVITY = 'SET_GROUP_CALENDAR_ACTIVITY';

export const SET_GROUP_LEADER_DETAIL = 'SET_GROUP_LEADER_DETAIL';

export const SET_GROUP_NAME = 'SET_GROUP_NAME';

export const GROUP_MESSAGE_SENT_SUCCESS = 'GROUP_MESSAGE_SENT_SUCCESS';
export const GROUP_MESSAGE_SENT_FAIL = 'GROUP_MESSAGE_SENT_FAIL';

export const SET_GROUP_DATA = 'SET_GROUP_DATA';

export const ADD_GROUP_OUTSIDE_MEMBER = 'ADD_GROUP_OUTSIDE_MEMBER';

export const REMOVE_GROUP_OUTSIDE_MEMBER = 'REMOVE_GROUP_OUTSIDE_MEMBER';

export const UPDATE_GROUP_OUTSIDE_MEMBER = 'UPDATE_GROUP_OUTSIDE_MEMBER';