import {
  GET_STREAM_VIDEO_SUCCEEDED,
  POST_STREAM_CONTENT_SUCCEEDED,
  GET_STREAM_TYPE_SUCCEEDED,
  GET_STREAM_CATEGORY_SUCCEEDED,
  DELETE_STREAM_VIDEO_SUCCEEDED,
  UPDATE_STREAM_VIDEO_SUCCEEDED,
} from '../actionTypes/stream.js';

import {
  APP_RESET
} from '../actionTypes/app';

export const initialState = {
  streamVideos: [],
  streamCategories: [],
  streamTypes: [],
};

const stream = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case GET_STREAM_VIDEO_SUCCEEDED:
      let videos = [];

      if (action.isAll) {
        videos = action.payload;
      } else {
        const filter = state.streamVideos.filter(
          (video) => video.category !== +action.category
        );
        videos = [...filter, ...action.payload];
      }

      return {
        ...state,
        streamVideos: videos,
      };

    case DELETE_STREAM_VIDEO_SUCCEEDED: {
      const filter = state.streamVideos.filter(
        (video) => video.id !== +action.payload
      );

      return {
        ...state,
        streamVideos: filter,
      };
    }

    case POST_STREAM_CONTENT_SUCCEEDED: {
      let videos = [...state.streamVideos];
      if (action.payload) {
        videos = [...state.streamVideos, action.payload];
      }

      return {
        ...state,
        streamVideos: videos,
      };
    }

    case GET_STREAM_TYPE_SUCCEEDED: {
      // add All option
      const types = [
        {
          id: 0,
          name: 'All',
        },
        ...action.payload,
      ];

      return {
        ...state,
        streamTypes: types,
      };
    }

    case GET_STREAM_CATEGORY_SUCCEEDED: {
      return {
        ...state,
        streamCategories: action.payload,
      };
    }

    case UPDATE_STREAM_VIDEO_SUCCEEDED: {
      const videos = state.streamVideos.map((video) => {
        if (video.id === +action.payload.id) {
          Object.keys(action.payload).map((key) => {
            video[key] = action.payload[key];
            return true;
          });
        }
        return video;
      });

      return {
        ...state,
        streamVideos: videos,
      };
    }

    default:
      return state;
  }
};

export default stream;
