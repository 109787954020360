import { APP_START } from '../actionTypes/app';
import {
  CREATE_GROUP,
  IS_LOADING_CREATE_GROUP,
  GET_GROUP_LIST,
  IS_LOADING_GET_GROUP_LIST,
  SET_AMERA_GROUP_SECURITY,
  GET_GROUP_DETAIL,
  REMOVE_GROUP_MEMBER,
  ADD_GROUP_MEMBER_START,
  ADD_GROUP_MEMBER,
  SET_GROUP_ALERT,
  REMOVE_GROUP_ALERT,
  SET_ADD_MEMBER_FROM,
  SET_SHOW_GROUP_DETAIL_MODAL,
  FETCH_GROUP_MEMBERSHIPS_START,
  FETCH_GROUP_MEMBERSHIPS_SUCCESS,
  FETCH_GROUP_MEMBERSHIPS_FAILED,
  REMOVE_GROUP_MEMBERSHIP,
  GET_CONTACT_GROUP_MEMBERSHIP,
  // IS_LOADING_MEMBER_INVITE,
  IS_DELETING_GROUP,
  DELETED_GROUP,
  FAIL_DELETED_GROUP,
  LOAD_GROUP_MEMBERS,
  ADD_MEMBER_TO_NEW_GROUP,
  INITIAL_GROUP_MEMBERS,
  SET_GROUP_LEADER_DETAIL,
  SET_GROUP_CALENDAR_ACTIVITY,
  SET_GROUP_DRIVE_ACTIVITY,
  SET_GROUP_NAME,
  GROUP_MESSAGE_SENT_SUCCESS,
  GROUP_MESSAGE_SENT_FAIL,
  SET_GROUP_DATA,
  REMOVE_GROUP_OUTSIDE_MEMBER,
  ADD_GROUP_OUTSIDE_MEMBER,
  UPDATE_GROUP_OUTSIDE_MEMBER
} from '../actionTypes/group';

import { APP_RESET } from '../actionTypes/app';

// The initial state of the App
export const initialState = {
  createGroupLoading: false,
  getGroupListLoading: false,
  groupList: [],
  groupData: {
    members: [],
  },
  groupMembers: [],
  addedMembersToGroup: [],
  ameraGroupSecurity: [],
  groupAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
  memberForm: true,
  showGroupDetailModal: false,
  isDrive: false,
  groupMembershipList: [],
  contactGroupMemberships: [],
  loading: false,
  deletingGroup: false,
  security: null,
  group_id: 0,
  loadingGroups: false,
  loadingGroupMemberships: false,
  groupLeaderData: null,
  groupDriveActivity: null,
  groupCalendarActivity: null,
  groupsCount: 0,
  membershipsCount: 0,
};

const group = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case APP_START:
      return {
        ...state,
        ...{
          createGroupLoading: false,
          getGroupListLoading: false,
          groupAlert: {
            show: false,
            variant: 'success',
            message: '',
          },
          memberForm: true,
          showGroupDetailModal: false,
          loading: false,
          deletingGroup: false,
        },
      };
    case LOAD_GROUP_MEMBERS:
      return {
        ...state,
        groupMembers: action.payload,
      };
    case ADD_MEMBER_TO_NEW_GROUP:
      return {
        ...state,
        groupMembers: reGroupMembers(state.groupMembers, action.payload),
        addedMembersToGroup: action.payload,
      };
    case INITIAL_GROUP_MEMBERS:
      return {
        ...state,
        addedMembersToGroup: action.payload,
      };
    case IS_LOADING_CREATE_GROUP:
      return {
        ...state,
        createGroupLoading: true,
      };
    case IS_LOADING_GET_GROUP_LIST:
      return {
        ...state,
        getGroupListLoading: true,
      };
    // case IS_LOADING_MEMBER_INVITE:
    //   return {
    //     ...state,
    //     isLoadingMemberInvite: true,
    //   };
    case CREATE_GROUP:
      return {
        ...state,
        createGroupLoading: false,
        groupList: action.payload.concat(state.groupList),
      };
    case GET_GROUP_LIST:
      return {
        ...state,
        getGroupListLoading: false,
        groupList: [...action.payload.data],
        groupsCount: action.payload.count,
      };
    case SET_AMERA_GROUP_SECURITY:
      return {
        ...state,
        ameraGroupSecurity: [...state.ameraGroupSecurity, action.payload],
      };
    case SET_GROUP_DATA:
    case GET_GROUP_DETAIL:
      return {
        ...state,
        groupData: action.payload || initialState.groupData,
      };
    case REMOVE_GROUP_MEMBER:
      return {
        ...state,
        groupData: removeMemberFromGroup(state.groupData, action.payload),
        groupList: reTotalGroupMemberCount(
          state.groupList,
          removeMemberFromGroup(state.groupData, action.payload),
          'remove'
        ),
      };
    case ADD_GROUP_OUTSIDE_MEMBER:
      return {
        ...state,
        groupData: {
          ...state.groupData,
          unsecured_members: [
            ...state.groupData.unsecured_members,
            action.payload,
          ],
        },
      };
    case REMOVE_GROUP_OUTSIDE_MEMBER:
      return {
        ...state,
        groupData: removeOutsideMemberFromGroup(
          state.groupData,
          action.payload
        ),
      };
    case UPDATE_GROUP_OUTSIDE_MEMBER:
      return {
        ...state,
        groupData: updateGroupOutsideMember(
          state.groupData,
          action.payload
        ),
      };
    case ADD_GROUP_MEMBER_START:
      return {
        ...state,
        loading: true,
      };
    case ADD_GROUP_MEMBER:
      return {
        ...state,
        groupData: action.payload || initialState.groupData,
        groupList: reTotalGroupMemberCount(
          state.groupList,
          action.payload,
          'add'
        ),
        loading: false,
      };
    case SET_GROUP_ALERT:
      return {
        ...state,
        loading: false,
        groupAlert: action.payload,
      };
    case REMOVE_GROUP_ALERT:
      return {
        ...state,
        groupAlert: {
          show: false,
          variant: 'success',
          message: '',
        },
        loading: false,
      };
    case SET_ADD_MEMBER_FROM:
      return {
        ...state,
        memberForm: action.payload,
      };
    case SET_SHOW_GROUP_DETAIL_MODAL:
      return {
        ...state,
        showGroupDetailModal: action.payload.showGroupDetailModal,
        isDrive: action.payload.isDrive,
      };
    case FETCH_GROUP_MEMBERSHIPS_START:
      return {
        ...state,
        loadingGroupMemberships: true,
      };
    case FETCH_GROUP_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        loadingGroupMemberships: false,
        groupMembershipList: action.payload.data,
        membershipsCount: action.payload.count,
      };
    case FETCH_GROUP_MEMBERSHIPS_FAILED:
      return {
        ...state,
        loadingGroupMemberships: false,
      };
    case REMOVE_GROUP_MEMBERSHIP:
      return {
        ...state,
        groupMembershipList: removeGroupMemberships(
          state.groupMembershipList,
          action.payload
        ),
      };
    case GET_CONTACT_GROUP_MEMBERSHIP:
      return {
        ...state,
        contactGroupMemberships: action.payload,
      };
    case DELETED_GROUP:
      return {
        ...state,
        groupList: removeGroups(state.groupList, action.payload),
        deletingGroup: false,
      };
    case FAIL_DELETED_GROUP:
      return {
        ...state,
        deletingGroup: false,
      };
    case IS_DELETING_GROUP:
      return {
        ...state,
        deletingGroup: true,
      };
    case SET_GROUP_LEADER_DETAIL:
      return {
        ...state,
        groupLeaderData: action.payload,
      };
    case SET_GROUP_DRIVE_ACTIVITY:
      return {
        ...state,
        groupDriveActivity: action.payload,
      };
    case SET_GROUP_CALENDAR_ACTIVITY:
      return {
        ...state,
        groupCalendarActivity: action.payload,
      };
    case SET_GROUP_NAME:
      const updateGroupName = state.groupList.map((group) => {
        if (group.group_id === action.payload.group_id)
          group.group_name = action.payload.group_name;

        return group;
      });

      return {
        ...state,
        groupList: updateGroupName,
        groupData: {
          ...state.groupData,
          group_name: action.payload.group_name,
        },
      };
    case GROUP_MESSAGE_SENT_SUCCESS:
      return {
        ...state,
        groupAlert: {
          show: true,
          variant: 'success',
          message: 'Mail was sent successfully!',
        },
      };

    case GROUP_MESSAGE_SENT_FAIL:
      return {
        ...state,
        groupAlert: {
          show: true,
          variant: 'error',
          message: 'Mail was not sent. try later!',
        },
      };
    default:
      return state;
  }
};

function reTotalGroupMemberCount(groupList, group, type) {
  for (let i = 0; i < groupList.length; i++) {
    if (groupList[i]['group_id'] === group.group_id)
      if (type === 'remove') {
        groupList[i].total_member--;
      } else if (type === 'add') {
        groupList[i].total_member++;
      }
  }
  return groupList;
}

function reGroupMembers(groupMembers, selectedMemberIdList) {
  let members = groupMembers;
  for (let i = 0; i < selectedMemberIdList.length; i++) {
    members = members.filter(
      (member) => member.member_id !== selectedMemberIdList[i]
    );
  }
  return members;
}

function removeGroups(groupList, deletedGroups) {
  let newGroupsList = [...groupList];
  Object.keys(deletedGroups).map((group_id) => {
    if (deletedGroups[group_id] === true) {
      newGroupsList = [
        ...newGroupsList.filter(
          (x) => x.group_id.toString() !== group_id.toString()
        ),
      ];
    } else {
      console.log('Failed to delete group', group_id);
    }
    return group_id;
  });
  return newGroupsList;
}

function removeMemberFromGroup(group, payload) {
  let groupData = { ...group };
  groupData.members = groupData.members.filter(
    (member) => parseInt(member.member_id) !== parseInt(payload.member_id)
  );

  groupData.total_member = groupData.members.length;
  return groupData;
}

function removeOutsideMemberFromGroup(group, payload) {
  let groupData = { ...group };
  groupData.unsecured_members = groupData.unsecured_members.filter(
    (member) => parseInt(member.member_id) !== parseInt(payload.member_id)
  );

  return groupData;
}

function updateGroupOutsideMember(group, payload) {
  let groupData = { ...group };
  groupData.unsecured_members = groupData.unsecured_members.map(
    (member) => {
      if(parseInt(member.member_id) !== parseInt(payload.member_id)) {
        return member;
      }
      return payload;
    }
  );

  return groupData;
}

function removeGroupMemberships(groups, payload) {
  return groups.filter((el) => {
    if (payload.find((f) => parseInt(f.group_id) === parseInt(el.group_id))) {
      return false;
    } else {
      return true;
    }
  });
}

export default group;
