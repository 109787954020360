import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import Modal from '../../components/Modal/modal';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    width: 800,
    height: 480,
    outline: 'none',
  },
  button: {
    margin: 16,
  },
}));

const VideoModal = (props) => {
  const classes = useStyles();

  const { onClose, open, vidoeUrl } = props;

  const handleClose = () => {
    onClose();
  };

  const ModalContent = () => {
    return (
      <video
        playsInline
        src={vidoeUrl}
        className={classes.videoContainer}
        controls
        autoPlay
        me="true"
      />
    );
  };

  const ModalAction = () => {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClose}
        className={classes.button}
      >
        Cancel
      </Button>
    );
  };

  return (
    <Modal
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      title={`Video Player`}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="video-player-modal"
    />
  );
};

export default VideoModal;
