import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TimerIcon from '@material-ui/icons/Timer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroupIcon from '@material-ui/icons/Group';
import SubjectIcon from '@material-ui/icons/Subject';

import Card from '../Card';
import LocationPicture from '../LocationPicture';
import ConfirmDialog from '../../ConfirmDialog';
import CardButtons from '../CardButtons';
import EventDeclineComments from '../EventDeclineComments';
import { setEventInviteStatus } from '../../../redux/actions/event';
import { getScheduleEvent } from '../../../redux/actions/schedule_event';
import { setSnackbarData } from '../../../redux/actions/snackbar';
import {
  eventDisplayName,
  getMonthLimits,
  EVENT_INVITE_STATUS,
  CALENDAR_VIEWS,
  parseFromStr,
  getParams,
  defaultDateTimeZone,
  generateEventInviteListText,
  getInvitedListFilterLoggedInMember,
} from '../../../utils/calendar';
import { displayableFullName } from '../../../utils/contact';
import { getUnstyledHtmlBody } from '../../../utils/email';
import { useCardStyles } from './CardStyle';
import AddressLine from '../../AddressLine';

const EventCard = ({ data, showDetail, messages, formatMessage }) => {
  const classes = useCardStyles();
  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.member.member.member_id);

  const [open, setOpen] = useState(false);
  const [declineComments, setDeclineComments] = useState('');

  const title = formatMessage(messages.eventTitle);

  const handleDetail = () => {
    showDetail(data);
  };

  const handleClose = () => {
    setOpen(false);
    setDeclineComments('');
  };

  const submit = async (reaction) => {
    let status, comment;

    if (reaction === 'accept') {
      status = EVENT_INVITE_STATUS.accepted;
      comment = null;
    } else if (reaction === 'decline') {
      status = EVENT_INVITE_STATUS.declined;
      comment = declineComments;
    }

    const result = dispatch(setEventInviteStatus(data.id, status, comment));

    if (result) {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Successfully ${status.toLocaleLowerCase()} event invitation`,
          type: 'success',
        })
      );

      // Fetch event for current month
      const params = getParams(
        CALENDAR_VIEWS.month,
        getMonthLimits(parseFromStr(data.start))
      );
      dispatch(getScheduleEvent(params));
    } else {
      dispatch(
        setSnackbarData({
          open: true,
          message: `Failed to ${reaction} event invitation`,
          type: 'error',
        })
      );
    }
    setOpen(false);
  };

  const name = !!data.first_name ? `${displayableFullName(data)}` : null;

  const event_name = `${name} is inviting you to ${eventDisplayName(
    data,
    memberId
  )}`;

  const contact = generateEventInviteListText(
    getInvitedListFilterLoggedInMember(data, memberId)
  );

  const description = getUnstyledHtmlBody(
    data.event_description ? data.event_description : ''
  );

  const action = (
    <Button
      variant="contained"
      color="primary"
      aria-label="viewDetails"
      onClick={handleDetail}
    >
      {formatMessage(messages.viewDetails)}
    </Button>
  );

  const avatar = {
    first_name: data.first_name,
    last_name: data.last_name,
    url: data.amera_avatar_url,
  };

  const buttons = (
    <CardButtons
      cancelText={formatMessage(messages.decline)}
      okText={formatMessage(messages.accept)}
      cancelHandle={() => setOpen(true)}
      okHandle={() => submit('accept')}
    />
  );

  const eventLocation = React.useMemo(() => {
    if (data.event_location && data.event_location.vendor_formatted_address) {
      return <AddressLine data={data.event_location} />;
    }
  }, [data.event_location]);

  const content = (
    <List className={classes.content}>
      <ListItem>
        <LocationPicture data={data} height={140} />
      </ListItem>
      <ListItem className={classes.eventDateAndTime}>
        <List>
          <ListItem
          // className={classes.eventDate}>
          >
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            {/* <ListItemText primary={format(new Date(data.start), 'P')} /> */}
            <ListItemText
              primary={
                (data.start && defaultDateTimeZone(data.start, 'date')) || ''
              }
            />
          </ListItem>
          <ListItem
          // className={classes.eventTime}
          >
            <ListItemIcon>
              <TimerIcon />
            </ListItemIcon>
            {/* <ListItemText
              primary={`${format(new Date(data.start), 'p')} - ${format(
                new Date(data.end),
                'p'
              )}`}
            /> */}
            <ListItemText
              primary={`${
                (data.start && defaultDateTimeZone(data.start, 'time')) || ''
              }
               - 
               ${(data.end && defaultDateTimeZone(data.end, 'time')) || ''}`}
            />
          </ListItem>
        </List>
      </ListItem>
      <ListItem
      // className={classes.eventLocation}
      >
        {eventLocation && (
          <>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary={eventLocation} />
          </>
        )}
      </ListItem>
      <ListItem
      // className={classes.eventContacts}
      >
        {contact && (
          <>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={contact} />
          </>
        )}
      </ListItem>
      <ListItem
      // className={classes.eventDescription}
      >
        {description && (
          <>
            <ListItemIcon>
              <SubjectIcon />
            </ListItemIcon>
            <ListItemText primary={description} />
          </>
        )}
      </ListItem>
    </List>
  );

  return (
    <>
      <Card
        title={title}
        action={action}
        avatar={avatar}
        // subTitle={name}
        subHeader={event_name}
        buttons={buttons}
        content={content}
      />
      {open && (
        <ConfirmDialog
          open={open}
          title="Confirm"
          onOk={() => submit('decline')}
          content="Are you sure you want to decline this event invitation?"
          onCancel={handleClose}
        >
          <EventDeclineComments
            value={declineComments}
            onInput={(v) => setDeclineComments(v)}
          />
        </ConfirmDialog>
      )}
    </>
  );
};

export default EventCard;
