import { Strophe, $msg } from 'strophe.js';

import 'strophejs-plugin-muc'; //list of all other plugins https://github.com/strophe/strophejs-plugins/
import 'strophejs-plugin-mam';
import 'strophejs-plugin-rsm';
import 'strophejs-plugin-disco';
import 'strophejs-plugin-ping';
import 'strophejs-plugin-chatstates';
import 'strophejs-plugin-pubsub';
import 'strophejs-plugin-roster';

// strophe in-bound fileshare
import './strophe-libs/strophe.si-filetransfer.js';
import './strophe-libs/strophe.ibb.js';
import {
  STROPHE_CONNECT,
  STROPHE_DISCONNECT,
  SUBSCRIBE_MEMBERS,
  AUTHORIZE_MEMBERS,
  UNAUTHORIZE_MEMBERS,
  UNSUBSCRIBE_MEMBERS,
  LOAD_CONTACTS_ARCHIVE,
  CREATE_CHAT_ROOM,
  JOIN_CHAT_ROOM,
  LEAVE_CHAT_ROOM,
  DESTROY_CHAT_ROOM,
  SEND_CONTACT_MESSAGE,
  SEND_GROUP_MESSAGE,
  LOAD_CONTACT_MESSAGES,
  STROPHE_SEND_FILES,
} from '../actionTypes/chat';
import {
  setConnectionStatus,
  setGroupConversationID,
  loadRosterItems,
  updateGroupRosters,
  setGroupRosterStatus,
  deleteGroup,
  addChatMessage,
  addGroupMessage,
  setChatUnreadMessagesCount,
  resetChat,
} from '../actions/chat';
import { updateContactProperty } from '../actions/contact.js';
import {
  CHAT_STATUS,
  setUserStatus,
  getUserInfoVCard,
  setUserInfoVCard,
  getUserIdFromJID,
  getResourceFromJID,
  getDomainFromJID,
  getBareJID,
  createBareJIDFromUserID,
} from '../../utils/strophe/user';
import { ping } from '../../utils/strophe/connection';
import {
  subscribeMembersStrophe,
  autorizeMembersStrophe,
  unAutorizeMembersStrophe,
  unSubscribeMembersStrophe,
} from '../../utils/strophe/roster.js';
import {
  createRoom,
  joinRoom,
  leaveRoom,
  destroyRoom,
} from '../../utils/strophe/room';
import { displayableFullName } from '../../utils/contact';
import {
  // getTextFromMessageNode,
  xmlToJson,
} from '../../utils/strophe/chat';

import { sendFiles, fileEventListeners } from './file-share.js';

import { apiUrl } from '../../config/api';
import { AmeraAES } from '../../utils/ameraWebCrypto';
import { setSnackbarData } from '../actions/snackbar';
import { store } from '../../utils/store';

const XMPP_DOMAIN = window.location.hostname.replace('www.', '');
const XMPP_URL = XMPP_DOMAIN;
const XMPP_PROTOCOL =
  window.location.protocol.indexOf('https') !== -1 ? 'wss' : 'ws';
const config = {
  hosts: {
    domain: `${XMPP_DOMAIN}`,
    muc: `muc.${XMPP_DOMAIN}`, // FIXME: use XEP-0030
    focus: `focus.${XMPP_DOMAIN}`,
  },
  serviceUrl: `${XMPP_PROTOCOL}://${XMPP_URL}/xmpp-websocket`,
};

console.debug(`window.location.protocol: ${window.location.protocol}`);
console.debug(`XMPP_DOMAIN: ${XMPP_DOMAIN}`);
console.debug(`XMPP_URL: ${XMPP_URL}`);
console.debug(`XMPP_PROTOCOL: ${XMPP_PROTOCOL}`);
console.debug('config:');
console.debug(config);

const parseMessageFilesInfo = (messageFilesInfo) => {
  console.debug(`Message Files Info: ${messageFilesInfo}`);
  console.debug(messageFilesInfo);
  try {
    return JSON.parse(messageFilesInfo);
  } catch (e) {
    return false;
  }
};

const stropheMiddleware = () => {
  let dispatch = null;
  const connection = new Strophe.Connection(config.serviceUrl, {
    protocol: XMPP_PROTOCOL,
    withCredentials: true,
    mechanisms: [Strophe.SASLExternal],
    worker: '/shared-connection-worker.js',
  }); // Server is configured with SASL ANONYMOUS providing us it's own JID

  let JID = null;
  let selectedGroupID = '';
  let memberInfo = null;
  let ameraAES = null;

  const init = async () => {
    connection.ping.addPingHandler((ping) => {
      connection.ping.pong(ping);
      return true;
    });
    let pingInterval = setInterval(() => {
      if (connection && connection.connected) {
        ping(connection);
      } else {
        clearInterval(pingInterval);
      }
    }, 15000);
  };

  const updateContactLastMessageID = (contactMemberID, messageID) => {
    const contactData = store
      .getState()
      ?.contact?.contacts?.find(
        (c) => c.contact_member_id.toString() === contactMemberID
      );
    if (contactData) {
      const payload = {
        last_message_id: messageID,
        id: contactData.id,
        name: 'last_message_id',
      };
      dispatch(updateContactProperty(payload, false));
    }
  };
  const updateContactChatUnreadMessagesCount = (
    contactMemberID,
    newMessageCount = 0
  ) => {
    let unreadCount = 0;
    const contactData = store
      .getState()
      ?.contact?.contacts?.find(
        (c) => parseInt(c.contact_member_id) === parseInt(contactMemberID)
      );
    const messages = store
      .getState()
      ?.chat?.chatConversations?.find(
        (c) => parseInt(c.chatID) === parseInt(contactMemberID)
      )?.messages;
    const last_message_id = contactData?.last_message_id;

    let otherMessages = messages?.filter((m) => !m.isMyMessage);

    if (otherMessages?.length > 0) {
      if (last_message_id) {
        const lastMessageIndex = otherMessages.findIndex(
          (m) => m.id === last_message_id
        );
        unreadCount = otherMessages.length - (lastMessageIndex + 1);
      } else {
        unreadCount = otherMessages?.length;
      }
    }
    unreadCount += newMessageCount;
    store.dispatch(setChatUnreadMessagesCount(contactMemberID, unreadCount));
  };

  const onConnectionMessage = async (message) => {
    console.debug('got new message => ', xmlToJson(message));
    if (message.querySelector('forwarded')) {
      //don't process archive message
      return true; // return true for keep handler alive
    }

    const elems = message.getElementsByTagName('body');
    if (elems?.length > 0) {
      const id = message.getAttribute('id'),
        type = message.getAttribute('type'),
        from = message.getAttribute('from'),
        to = message.getAttribute('to'),
        fromID = getUserIdFromJID(from),
        toID = getUserIdFromJID(to),
        msgBody = elems[0],
        filesInfo = message.getElementsByTagName('filesInfo')?.[0]?.innerHTML,
        stamp =
          message.getElementsByTagName('delay')?.[0]?.getAttribute('stamp') ||
          new Date().toISOString(); // https://stackoverflow.com/a/11196675/7078805;

      let decryptedMessage = msgBody.innerHTML;
      let decryptedFilesInfo = parseMessageFilesInfo(filesInfo);

      const state = store.getState();
      const securityKey = state?.security?.hexkey;
      const e2eEncryptionEnabled = state?.security?.e2eEncryptionEnabled;
      const contact_id = state?.security?.contact_id;
      const chatID = state?.chat?.selectedGroupID;

      if (
        e2eEncryptionEnabled &&
        securityKey &&
        ((type === 'chat' &&
          contact_id &&
          (contact_id.toString() === fromID || from === JID)) ||
          (type === 'groupchat' && chatID && chatID === fromID))
      ) {
        try {
          ameraAES = new AmeraAES({ key: securityKey });
          decryptedMessage = await ameraAES.decrypt(decryptedMessage, {
            mode: 'utf8',
            verbose: false,
            cipher: 'utf8',
          });

          // if (decryptedFilesInfo) {
          //   decryptedFilesInfo = await Promise.all(
          //     decryptedFilesInfo.map(async (df) => {
          //       if (df.src) {
          //         let mime = '',
          //           blob = '';
          //         try {
          //           mime = df.src.split(',')[0];
          //           blob = df.src.split(',')[1];
          //           blob = await ameraAES.decrypt(blob, {
          //             mode: 'utf8',
          //             verbose: false,
          //             cipher: 'utf8',
          //           });
          //         } catch (err) {
          //           console.debug('undecrypted');
          //         }
          //         df.src = mime + ',' + blob;
          //       }
          //       return df;
          //     })
          //   );
          // }
        } catch (e) {
          dispatch(
            setSnackbarData({
              open: true,
              message: `Failed to decrypt`,
              type: 'error',
            })
          );
        }
      }
      let messageObj = {
        id,
        senderID: fromID,
        receiverID: toID,
        chatID: null,
        // messageText: getTextFromMessageNode(msgBody),
        messageText: decryptedMessage,
        // messageHtml: msgBody.innerHTML,
        messageHtml: decryptedMessage,
        messageType: decryptedFilesInfo ? 'file' : '',
        messageFiles: decryptedFilesInfo,
        isMyMessage: false,
        stamp,
        type,
      };
      if (type === 'chat') {
        if (from === JID) {
          messageObj['isMyMessage'] = true;
          messageObj['chatID'] = toID;
          dispatch(addChatMessage(messageObj));
        } else {
          messageObj['isMyMessage'] = false;
          messageObj['chatID'] = fromID;
          dispatch(addChatMessage(messageObj));

          const chatID = state?.chat?.selectedChatID;

          if (parseInt(chatID) === parseInt(fromID)) {
            updateContactLastMessageID(fromID, id);
          } else {
            updateContactChatUnreadMessagesCount(fromID, 0);
          }
        }
      } else if (type === 'groupchat') {
        messageObj.senderID = getResourceFromJID(from);
        messageObj['chatID'] = getUserIdFromJID(from);
        if (messageObj.senderID === toID) {
          messageObj['isMyMessage'] = true;
        } else {
          messageObj['isMyMessage'] = false;
        }
        dispatch(addGroupMessage(messageObj['chatID'], messageObj));
      }
    }
    return true; //to keep handler alive
  };

  const handleRosterCallback = (items, item, prevItem) => {
    let updatedItems = [...items];
    if (item) {
      console.log('received presence from => ', item.jid);
      if (item.subscription === 'remove') {
        updatedItems = updatedItems.filter((el) => el.jid !== item.jid);
      } else {
        updatedItems = updatedItems.map((rc) => {
          if (rc.jid === item.jid) {
            return item;
          }
          return rc;
        });
      }
    }
    store.dispatch(loadRosterItems(updatedItems));
  };
  const onSubscriptionRequest = (stanza) => {
    if (stanza.getAttribute('type') === 'subscribe') {
      const from = stanza.getAttribute('from');
      const rosterItem = connection.roster.findItem(from);
      console.log('received friend request from => ', from, rosterItem);
      // subscribe only if remote user acceptd contact invite
      if (rosterItem && rosterItem?.subscription === 'to') {
        autorizeMembersStrophe(connection, [{ id: from, nick: '' }]);
      } else {
        console.error('roster item not found!');
      }
    }
    return true;
  };

  // const handleRosterRequestCallback = (from) => {
  // console.log('got friend request from => ', from)
  // connection.roster.get((rcs)=>{
  //   const rosterItem = connection.roster.findItem(from);
  //   // subscribe only if remote user acceptd contact invite
  //   if (rosterItem && rosterItem?.subscription === 'to') {
  //     autorizeMembersStrophe(connection, [{ id: from, nick: '' }]);
  //   } else {
  //     console.error('roster item not found!');
  //   }
  //   setRosterItemsFunc(rcs);
  // })

  // }

  const initUser = async () => {
    //initialize roster plugin
    connection.roster.init(connection);
    connection.roster.get();
    connection.roster.registerCallback(handleRosterCallback);

    // connection.roster.registerRequestCallback(handleRosterRequestCallback);
    // the above handler not working that's why setting it here.
    connection.addHandler(onSubscriptionRequest, null, 'presence', 'subscribe');

    connection.addHandler(onConnectionMessage, null, 'message');
    // connection.addHandler(onPresence, null, 'presence');
    fileEventListeners(connection);

    try {
      console.debug('Set User vCard');
      const vcard = await setUserInfoVCard(
        connection,
        displayableFullName(memberInfo),
        memberInfo.first_name,
        memberInfo.last_name,
        memberInfo.member_id,
        `${apiUrl}/member/${memberInfo.member_id}/avatar`
      );
      console.debug('Done setting vcard');
      console.debug(vcard);
    } catch (e) {
      console.error('Error setting vcard');
      console.debug(e);
    }
    try {
      console.debug('Set user status');
      const userStatus = await setUserStatus(connection, 'chat');
      console.debug('done setting user status');
      console.debug(userStatus);
    } catch (e) {
      console.error('Error setting user status');
      console.debug(e);
    }
  };

  const onConnect = (status, condition) => {
    console.debug('On Connect Called:');
    console.debug(status);
    console.debug(condition);
    console.debug(`status: ${status}`);

    dispatch(setConnectionStatus(status));

    switch (status) {
      case Strophe.Status.CONNECTING:
        console.debug('Strophe is connecting.');
        break;
      case Strophe.Status.AUTHENTICATING:
        console.debug('Strophe is authenticating.');
        break;
      case Strophe.Status.CONNFAIL:
        console.debug('Strophe failed to connect.');
        break;
      case Strophe.Status.DISCONNECTING:
        console.debug('Strophe is disconnecting.');
        break;
      case Strophe.Status.DISCONNECTED:
        console.debug('Strophe is disconnected.');
        break;
      case Strophe.Status.CONNECTED:
        console.debug('Strophe connected successfully.');
        init().then(() => initUser());
        break;
      case Strophe.Status.AUTHFAIL:
        console.debug('Strophe unable to authenticate.');
        disconnect();
        break;
      default:
        console.debug('Strophe unexpected status.');
        break;
    }
  };

  const sendContactMessage = (to, message, filesInfo) => {
    return new Promise(async (resolve) => {
      if (connection && connection.connected) {
        let msgBuilder,
          messageTo = to;
        const stamp = new Date().toISOString();
        const messageId = connection.getUniqueId();
        let encryptedMessage = message;
        let encryptedFilesInfo = filesInfo;

        const state = store.getState();
        const securityKey = state?.security?.hexkey;
        const e2eEncryptionEnabled = state?.security?.e2eEncryptionEnabled;
        const contact_id = state?.security?.contact_id;

        console.log('encryptedFilesInfo', encryptedFilesInfo);

        if (e2eEncryptionEnabled && securityKey && contact_id === to) {
          try {
            ameraAES = new AmeraAES({ key: securityKey });
            encryptedMessage = await ameraAES.encrypt(message, {
              mode: 'utf8',
              verbose: false,
              cipher: 'utf8',
            });
            // if (encryptedFilesInfo) {
            //   encryptedFilesInfo = await Promise.all(encryptedFilesInfo.map(async (df) => {
            //     let mime = '', blob = '';
            //     try {
            //       mime = df.src.split(',')[0];
            //       blob = df.src.split(',')[1];
            //       blob = await ameraAES.encrypt(blob, {
            //         mode: 'utf8',
            //         verbose: false,
            //         cipher: 'utf8',
            //       });
            //     } catch (err) {
            //       console.debug("undecrypted");
            //     }
            //     df.src = mime+','+blob;
            //     return df;
            //   }));
            // }
          } catch (e) {
            dispatch(
              setSnackbarData({
                open: true,
                message: `Failed to encrypt`,
                type: 'error',
              })
            );
          }
        }

        if (encryptedFilesInfo)
          encryptedFilesInfo = JSON.stringify(encryptedFilesInfo);

        messageTo = `${to}@${getDomainFromJID(JID)}/${getResourceFromJID(JID)}`;
        msgBuilder = $msg({
          to: messageTo,
          from: connection.jid,
          type: 'chat',
          id: messageId,
        })
          .c('body')
          .t(encryptedMessage);

        if (encryptedFilesInfo) {
          msgBuilder
            .up()
            .c('filesInfo', {
              xmlns: 'urn:example:filesInfo',
            })
            .t(encryptedFilesInfo);
        }

        msgBuilder.up().c('delay', {
          xmlns: 'urn:xmpp:delay',
          stamp,
        });
        connection.send(msgBuilder.tree());

        const messageObj = {
          id: messageId,
          senderID: getUserIdFromJID(JID),
          receiverID: getUserIdFromJID(messageTo),
          chatID: getUserIdFromJID(messageTo),
          messageText: message, // fix it should only text from markup message
          messageHtml: message,
          messageType: filesInfo?.length > 0 ? 'file' : '',
          messageFiles: [],
          isMyMessage: true,
          type: 'chat',
          stamp,
        };
        dispatch(addChatMessage(messageObj));
        resolve(messageObj);
      } else {
        console.log('sorry server not connected!');
      }
    });
  };
  const sendGroupMessage = async (to, message) => {
    if (connection && connection.connected) {
      let messageTo = `${to}@${config.hosts.muc}`;
      const stamp = new Date().toISOString();
      const messageId = connection.getUniqueId();

      let encryptedMessage = message;

      const state = store.getState();
      const securityKey = state?.security?.hexkey;
      const e2eEncryptionEnabled = state?.security?.e2eEncryptionEnabled;
      const chatID = state?.chat?.selectedGroupID;

      if (e2eEncryptionEnabled && securityKey && chatID === to) {
        try {
          ameraAES = new AmeraAES({ key: securityKey });
          encryptedMessage = await ameraAES.encrypt(message, {
            mode: 'utf8',
            verbose: false,
            cipher: 'utf8',
          });
        } catch (e) {
          dispatch(
            setSnackbarData({
              open: true,
              message: `Failed to encrypt`,
              type: 'error',
            })
          );
        }
      }

      const msgiq = connection.muc.groupchat(
        messageTo, // room - The multi-user chat room name.
        encryptedMessage, // message - The plaintext message to send to the room.
        encryptedMessage, //html_message - The message to send to the room with html markup.
        messageId //msgid - Optional unique ID which will be set as the 'id' attribute of the stanza
      );
      console.log('msgiq => ' + msgiq.toString());

      const messageObj = {
        id: messageId,
        senderID: getUserIdFromJID(JID),
        receiverID: getUserIdFromJID(messageTo),
        chatID: getUserIdFromJID(messageTo),
        messageText: message, // fix it should only text from markup message
        messageHtml: message,
        isMyMessage: true,
        type: 'groupchat',
        stamp,
      };

      dispatch(addGroupMessage(to, messageObj));

      console.debug('message sent to ' + messageTo + ': ', messageObj);
    } else {
      console.log('sorry server not connected!');
    }
  };

  const loadContactArchives = async (contacts) => {
    if (contacts) {
      for (let i = 0; i < contacts.length; i++) {
        await loadArchivedMessages(contacts[i].contact_member_id, { max: 21 }); //for Counter maxCount=20+
      }
    }
  };
  const loadArchivedMessages = (to = null, params = {}) => {
    console.log(
      'fetching archive messages was sent to => ',
      to ? `${to}@${getDomainFromJID(JID)}` : 'all',
      'and sent from ==> ',
      JID
    );
    return new Promise((resolve) => {
      connection.mam.query(getBareJID(JID), {
        with: to ? `${to}@${getDomainFromJID(JID)}` : '',
        max: params?.max || 20, //default load last 20 conversations
        before: params?.before || '',
        onMessage: async (message) => {
          const fwd = message.querySelector('forwarded'),
            stamp = fwd.querySelector('delay').getAttribute('stamp'),
            msg = fwd.querySelector('message'),
            msgID = msg.getAttribute('id'),
            type = msg.getAttribute('type'),
            from = msg.getAttribute('from'),
            fromID = getUserIdFromJID(from),
            to = msg.getAttribute('to'),
            toID = getUserIdFromJID(to),
            msgBody = msg.getElementsByTagName('body')[0],
            filesInfo = msg.getElementsByTagName('filesInfo')?.[0]?.innerHTML;

          let decryptedMessage = msgBody.innerHTML;
          let decryptedFilesInfo = parseMessageFilesInfo(filesInfo);

          console.log('decryptedFilesInfo', decryptedFilesInfo);

          const state = store.getState();
          const securityKey = state?.security?.hexkey;
          const e2eEncryptionEnabled = state?.security?.e2eEncryptionEnabled;
          const contact_id = state?.security?.contact_id;
          const chatID = state?.chat?.selectedGroupID;

          if (
            e2eEncryptionEnabled &&
            securityKey &&
            ((type === 'chat' &&
              contact_id &&
              (contact_id.toString() === fromID || from === JID)) ||
              (type === 'groupchat' && chatID && chatID === fromID))
          ) {
            try {
              ameraAES = new AmeraAES({ key: securityKey });
              decryptedMessage = await ameraAES.decrypt(decryptedMessage, {
                mode: 'utf8',
                verbose: false,
                cipher: 'utf8',
              });
              if (decryptedFilesInfo) {
                decryptedFilesInfo = await Promise.all(
                  decryptedFilesInfo.map(async (df) => {
                    if (df.src) {
                      let mime = '',
                        blob = '';
                      try {
                        mime = df.src.split(',')[0];
                        blob = df.src.split(',')[1];
                        blob = await ameraAES.encrypt(blob, {
                          mode: 'utf8',
                          verbose: false,
                          cipher: 'utf8',
                        });
                      } catch (err) {
                        console.debug('undecrypted');
                      }
                      df.src = mime + ',' + blob;
                    }
                    return df;
                  })
                );
              }
            } catch (e) {
              dispatch(
                setSnackbarData({
                  open: true,
                  message: `Failed to decrypt`,
                  type: 'error',
                })
              );
            }
          }

          if (type === 'chat') {
            let messageObj = {
              id: msgID,
              senderID: fromID,
              receiverID: toID,
              // messageText: Strophe.getText(msgBody),
              // messageHtml: msgBody.innerHTML,
              messageText: decryptedMessage,
              messageHtml: decryptedMessage,
              messageType: decryptedFilesInfo ? 'file' : '',
              messageFiles: decryptedFilesInfo,
              archived: true,
              stamp,
              type,
            };
            if (from === JID) {
              messageObj['isMyMessage'] = true;
              messageObj['chatID'] = toID;
            } else {
              messageObj['isMyMessage'] = false;
              messageObj['chatID'] = fromID;
            }
            dispatch(addChatMessage(messageObj));
          }

          return true;
        },
        onComplete: (response) => {
          updateContactChatUnreadMessagesCount(to, 0);
          console.log('Got all the messages => ', to, response);
          resolve(true);
        },
      });
    });
  };

  const roomMessageHandler = (message) => {
    console.debug('roomMessageHandler from from joinRoom');
    console.debug(message);
    console.debug(xmlToJson(message));
    return true;
  };

  const roomPresenceHandler = async (presence, groupID) => {
    console.log('roomPresenceHandler event');
    console.debug(presence);
    console.debug(xmlToJson(presence));

    const destroyTag = presence.getElementsByTagName('destroy')?.[0];
    if (destroyTag) {
      if (destroyTag.hasAttribute('jid')) {
        const reason =
          destroyTag.getElementsByTagName('reason')?.[0]?.innerHTML;
        const roomID = getUserIdFromJID(destroyTag.getAttribute('jid'));
        dispatch(deleteGroup(roomID, reason));
      }
    }

    const presenceType =
        presence.hasAttribute('type') && presence.getAttribute('type'), // error, unavailable, subscribed, etc...
      fromJID = presence.hasAttribute('from') && presence.getAttribute('from'),
      rosterID = fromJID && getUserIdFromJID(fromJID);

    if (presenceType !== 'error' && rosterID) {
      if (presenceType === 'unavailable') {
        dispatch(setGroupRosterStatus(groupID, rosterID, 'offline'));
      } else {
        const show = presence.querySelector('show')?.textContent; // this is what gives away, dnd, etc.
        if (show === CHAT_STATUS.chat || show === '') {
          dispatch(setGroupRosterStatus(groupID, rosterID, 'online'));
        } else if (show === CHAT_STATUS.away || show === 'disconnected') {
          // We can also set as away
          dispatch(setGroupRosterStatus(groupID, rosterID, 'offline'));
        }
      }
    }
    return true;
  };

  const roomRosterHandler = async (iq) => {
    let groupID = null;
    console.debug('Roster joinRoom');
    console.debug(iq);

    let newRosters = [];
    for (const prop in iq) {
      const jid = iq[prop]['jid'],
        affiliation = iq[prop]['affiliation'],
        role = iq[prop]['role'];

      groupID = getUserIdFromJID(iq[prop].room.name);

      let rosterInfo = {
        rosterID: getUserIdFromJID(jid),
        status: 'online',
        affiliation,
        role,
      };
      let iqCard;
      try {
        iqCard = await getUserInfoVCard(connection, jid);
        console.debug('iqCard');
        console.debug(iqCard);

        const vCardObj = xmlToJson(iqCard.querySelector('vCard'));
        console.log(vCardObj, 'get vcard obj');
        if (vCardObj) {
          rosterInfo.version = vCardObj['VERSION']['#text'];
          rosterInfo.fullName = vCardObj['FN']['#text'];
          rosterInfo.nickName = vCardObj['NICKNAME']['#text'];
          rosterInfo.firstName = vCardObj['FIRSTNAME']['#text'];
          rosterInfo.lastName = vCardObj['LASTNAME']['#text'];
          rosterInfo.ameraID = vCardObj['AMERAID']['#text'];
          rosterInfo.avatarUrl = vCardObj['PHOTO']?.['EXTVAL']?.['#text'];
        }
      } catch (e) {
        iqCard = null;
        console.debug('Exception nation');
        console.debug(e);
      }

      newRosters.push(rosterInfo);
    }
    dispatch(updateGroupRosters(groupID, newRosters));

    connection.addHandler(
      (p) => roomPresenceHandler(p, groupID),
      null,
      'presence'
    );
    return true;
  };
  const createGroup = async (room, selectedMembers) => {
    const members = store
      .getState()
      .contact.contacts?.filter((c) =>
        selectedMembers.some((id) => id === c.contact_member_id)
      )
      ?.map((c) => ({
        bareJID: createBareJIDFromUserID(connection, c.contact_member_id),
        userType: c.user_type,
      }));

    if (room) {
      const ROOM_JID = `${room}@${config.hosts.muc}`;
      try {
        const roomStatus = await createRoom(
          connection,
          ROOM_JID,
          memberInfo.member_id,
          members
        );
        console.debug('Done creating room');
        console.debug(roomStatus);
      } catch (e) {
        console.error('Error creating room');
        console.debug(e);
      }
    }
  };
  const joinGroup = async (selectedGroupID) => {
    // subscribePresence();
    if (selectedGroupID) {
      const ROOM_JID = `${selectedGroupID}@${config.hosts.muc}`;
      dispatch(setGroupConversationID(selectedGroupID));
      try {
        const roomStatus = await joinRoom(
          connection,
          ROOM_JID,
          memberInfo.member_id, // using memberID as nickname to get sender id at receiver end https://stackoverflow.com/a/25308726/7078805
          roomMessageHandler,
          (p) => roomPresenceHandler(p, selectedGroupID),
          roomRosterHandler
        );
        console.debug('Done joining room');
        console.debug(roomStatus);
      } catch (e) {
        console.error('Error joining room');
        console.debug(e);
      }
    }
  };
  const leaveGroup = () => {
    if (selectedGroupID) {
      const ROOM_JID = `${selectedGroupID}@${config.hosts.muc}`;
      leaveRoom(connection, ROOM_JID, memberInfo.member_id);
      dispatch(setGroupConversationID(null));
      selectedGroupID = null;
    }
  };

  const getConferenceID = (group) => {
    return `${group.group_leader_id}-${
      group.group_id
    }-${group.group_name.replace(/[\W_]+/g, '')}`.toLowerCase();
  };

  const destroyGroup = async (data) => {
    const memberInfo = store.getState()?.member?.memberInfo;
    const allGroups = store.getState().group.groupList;

    let groupsBareJID = [];
    if (Number.isInteger(data)) {
      const groupFound = allGroups.find((g) => g.group_id === data);
      groupsBareJID = [`${getConferenceID(groupFound)}@${config.hosts.muc}`];
    } else {
      groupsBareJID = allGroups
        .filter((g) => data.some((group_id) => group_id === g.group_id))
        ?.map((g) => `${getConferenceID(g)}@${config.hosts.muc}`);
    }

    groupsBareJID.forEach((bareJID) => {
      try {
        const reason = `${getUserIdFromJID(
          bareJID
        )} group deleted by ${displayableFullName(memberInfo)}`;
        destroyRoom(connection, bareJID, memberInfo.member_id, reason);
      } catch (err) {
        console.error('something went wrong when destroy room', bareJID, err);
      }
    });
  };

  // const getProsodyServicesList = () => {
  //   let iqBuilder = $iq({
  //     type: 'get',
  //     id : connection.getUniqueId(),
  //     from: JID,
  //     to: getDomainFromJID(JID)
  //   })
  //     .c('query', { xmlns : 'http://jabber.org/protocol/disco#info' });
  //   connection.sendIQ(
  //     iqBuilder.tree(),
  //     function(data){console.log('get services success callback => ', data)},
  //     function(err){console.log('get services error callback => ', err)}
  //   );
  // }

  const connect = async (jid) => {
    if (!connection.connected) {
      connection.connect(jid, null, onConnect);
    }
    return connection;
  };
  const disconnect = () => {
    connection.flush();
    connection.disconnect();
  };
  const cleanup = () => {
    setUserStatus(connection, 'disconnected');
    if (selectedGroupID) {
      leaveGroup();
    }
    disconnect();
  };

  const stropheConnect = (member_id = undefined) => {
    if (member_id) {
      JID = `${member_id}@${config.hosts.domain}/browser`;
      connect(JID);
    } else {
      console.error('strophe member info not found.');
    }
  };
  const stropheDisconnectFunc = (logout = false) => {
    if (logout) {
      dispatch(resetChat()); //clear all chats if member logout
    }
    if (connection && connection.connected) {
      JID = null;
      memberInfo = null;
      cleanup();
    }
  };

  // the middleware part of this function
  return (store) => (next) => (action) => {
    dispatch = store.dispatch;
    const payload = action.payload;
    switch (action.type) {
      case STROPHE_CONNECT:
        stropheConnect(payload.member_id);
        break;
      case STROPHE_DISCONNECT:
        stropheDisconnectFunc(payload.logout);
        break;
      case CREATE_CHAT_ROOM:
        createGroup(payload.room, payload.members);
        break;
      case JOIN_CHAT_ROOM:
        selectedGroupID = payload.room;
        joinGroup(selectedGroupID);
        break;
      case LEAVE_CHAT_ROOM:
        leaveGroup();
        break;
      case DESTROY_CHAT_ROOM:
        destroyGroup(payload.groups);
        break;
      case LOAD_CONTACTS_ARCHIVE:
        loadContactArchives(payload.contacts);
        break;
      case LOAD_CONTACT_MESSAGES:
        loadArchivedMessages(payload.contact_id, {});
        break;
      case SUBSCRIBE_MEMBERS:
        subscribeMembersStrophe(connection, payload.members);
        break;
      case UNSUBSCRIBE_MEMBERS:
        unSubscribeMembersStrophe(connection, payload.members);
        break;
      case AUTHORIZE_MEMBERS:
        autorizeMembersStrophe(connection, payload.members);
        break;
      case UNAUTHORIZE_MEMBERS:
        unAutorizeMembersStrophe(connection, payload.members);
        break;
      case SEND_CONTACT_MESSAGE:
        sendContactMessage(payload.selectedChatID, payload.messageText);
        break;
      case SEND_GROUP_MESSAGE:
        sendGroupMessage(payload.selectedChatID, payload.messageText);
        break;
      case STROPHE_SEND_FILES:
        const toJID = `${payload.to}@${config.hosts.domain}/browser`;
        const filesInfo = payload.files?.map((f) => ({
          id: connection.getUniqueId(),
          src: '',
          name: f.name,
          mime: f.type,
          // stampStart/stampEnd currently not in use
          // we can use it for advance features e.g upload/download time
          stampStart: null,
          stampEnd: null,
        }));
        sendContactMessage(
          getUserIdFromJID(toJID),
          payload.messageText,
          filesInfo
        ).then((messageObj) => {
          sendFiles(connection, toJID, messageObj, filesInfo, payload.files);
        });
        break;
      default:
        return next(action);
    }
  };
};

export default stropheMiddleware();
