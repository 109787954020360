import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadContacts } from '../../redux/actions/contact';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Avatar from '../../components/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel } from '@material-ui/core';
import ProfilePictureCoverImage from '../../assets/img/profile-picture-cover.png';

const EventDetailInvitation = (props) => {
  let [, setState] = useState();

  const [searchKey, setSearchKey] = useState('');
  const [availableContacts, setAvailableContacts] = useState(props.contacts);

  const [selectedContacts, setSelectedContacts] = useState(
    props.eventDetail_ContactList
  );

  const search = (event) => {
    setSearchKey(event.target.value);
    let data = props.contacts.filter(
      (item) =>
        item.first_name.search(event.target.value) >= 0 ||
        item.last_name.search(event.target.value) >= 0
    );

    setAvailableContacts(data);
    setState({});

  };

  const loadContactsAction = props.loadContacts;
  
  useEffect(() => {
    loadContactsAction({ search_key: ''});
  }, [loadContactsAction]);

  //console.log(props.contacts);

  const handleSelectAllContacts = () => {
    availableContacts.map((item) => {
      if (selectedContacts.indexOf(item.member_id) === -1) {
        selectedContacts.push(item.member_id);
      }
      return item;
    });

    setSelectedContacts(selectedContacts);
    props.setEventDetail_ContactList(selectedContacts);
    setState({});
  };

  const handleDeselectAllContacts = () => {
    availableContacts.map((item) => {
      let index = selectedContacts.indexOf(item.member_id);
      if (index !== -1) {
        selectedContacts.splice(index, 1);
      }
      return item;
    });

    setSelectedContacts(selectedContacts);
    props.setEventDetail_ContactList(selectedContacts);
    setState({});
  };

  const selectContact = (bChecked, index, member_id) => {
    var ind = selectedContacts.indexOf(member_id);
    if (bChecked && ind === -1) {
      selectedContacts.push(member_id);
    } else if (!bChecked && ind !== -1) {
      selectedContacts.splice(ind, 1);
    }

    setSelectedContacts(selectedContacts);
    props.setEventDetail_ContactList(selectedContacts);
    setState({});
  };

  const renderBody = () => (
    <Grid container>
      {availableContacts.length > 0 &&
        availableContacts.map((x, index) => (
          <Grid item xs={2} key={index}>
            <div className="ContactItem">
              <div>
                <Avatar
                  className="ProfileAvatar"
                  name={`${x.first_name} ${x.last_name}`}
                  uuid={x.member_id}
                  alt={x.first_name}
                  src={ProfilePictureCoverImage}
                />
              </div>
              <div className="ContactInfo">
                <InputLabel className="NameLabel">{`${x.first_name}.${x.last_name}`}</InputLabel>
                <Checkbox
                  checked={
                    selectedContacts.indexOf(x.member_id) !== -1 ? true : false
                  }
                  onChange={(event) =>
                    selectContact(event.target.checked, index, x.member_id)
                  }
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </div>
            </div>
          </Grid>
        ))}
    </Grid>
  );

  return (
    <div>
      <div className="SearchToolbar">
        <TextField
          label="Search People"
          className="width_50"
          variant="outlined"
          value={searchKey}
          onChange={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div>
          <Button
            variant="outlined"
            className="btn-info"
            onClick={handleSelectAllContacts}
          >
            Select All
          </Button>
          <Button
            variant="outlined"
            className="btn-warning"
            onClick={handleDeselectAllContacts}
          >
            Deselect All
          </Button>
        </div>
      </div>
      <div className="ContactsList">{renderBody()}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contacts: state.contact.contacts,
});

const mapDispatchToProps = (dispatch) => {
  return { dispatch, ...bindActionCreators({ loadContacts }, dispatch) };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailInvitation);
