import React from 'react'; // { useRef, useCallback }
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../../../styles/components/CustomComposeEditorstyles.css';
import EditorToolbar, { modules, formats } from './EditorToolbar';

/*
 * Editor component with custom toolbar and content containers
 */
const CustomComposeEditor = (props) => {
  //logic to disable spellcheck in textarea
  // const quillRef = useRef(null);
  // const handleRef = useCallback((ref) => {
  //   const quill = ref.getEditor();
  //   // disable spellcheck
  //   quill.root.setAttribute('spellcheck', false);

  //   quillRef.current = ref;
  // }, []);

  return (
    <div className="text-editor">
      <div aria-label="editor-toolbar">
        <EditorToolbar />
      </div>
      <ReactQuill
        // ref={handleRef}
        value={props.value}
        onChange={(html) => props.handleChangebd(html)}
        placeholder={props.placeholder}
        theme="snow"
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default CustomComposeEditor;
