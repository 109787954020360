import React from 'react';
import { useSelector } from 'react-redux';
import {
  // DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    '& button': {
      color: '#8DA3C5',
    },
  },
}));

const DateInput = (props) => {
  const classes = useStyles();

  const {
    value,
    label,
    onChange,
    disabled = false,
    minDate = null,
    inputVariant = 'outlined',
  } = props;

  const defaultDateFormat = useSelector(({ member: { memberSetting } }) => {
    return (
      memberSetting &&
      memberSetting.date_format &&
      memberSetting.date_format.replace(/D|Y/g, (m) =>
        m === 'D' ? 'd' : m === 'Y' ? 'y' : ''
      )
    );
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        variant="outlined"
        // disablePast
        inputVariant={inputVariant}
        margin="dense"
        fullWidth
        minDate={minDate}
        label={label}
        format={defaultDateFormat || 'MM/dd/yyyy'}
        value={value}
        className={classes.root}
        // InputAdornmentProps={{ position: 'start' }}
        // onMonthChange={(date) => handleMonthChange(date)}
        onChange={onChange}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;
