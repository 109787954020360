import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GroupItem from './GroupItem';
import { orderBy } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flexStart',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
    maxHeight: '120px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(148, 192, 61, 1)',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px rgb(220,220,220)', 
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '10px'
    }
  },
}));

const GroupItemList = (props) => {
  const { groupMemberships, memberId } = props;
  const groups = useMemo(
    () =>
      orderBy(groupMemberships, [group => group['group_name'].toLowerCase()],['asc']).map((gmo) =>
        gmo ? (
          <GroupItem
            key={gmo.group_id}
            groupInfo={gmo}
            isLeader={gmo.group_leader_id === memberId}
          />
        ) : null
      ),
    [groupMemberships, memberId]
  );

  const classes = useStyles();
  return (
    <>
      <p className="title">Group Memberships:</p>
      <div className={classes.root}>{groupMemberships.length>0?groups:'N/A'}</div>
    </>
  );
};

export default GroupItemList;
