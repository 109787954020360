import {
  Button,
  FormControl,
  FormControlLabel,
  List,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState, useRef } from 'react';
import { ApiEmailService } from '../../config/apiService';
import { CustomSwitch } from './SharedComponents/SettingsItems/StyledComponents';
import { useSettingsStyles } from './SharedComponents/SettingsItems/SettingsStyles';
import SignatureItem from './SharedComponents/SettingsItems/SignatureItem';
import Modal from '../../components/Modal/newDesignModal';

const Settings = ({ onClose }) => {
  const classes = useSettingsStyles();

  const [options, setOptions] = useState({
    grammar: true,
    spelling: true,
    autocorrect: true,
  });
  // eslint-disable-next-line
  const [signatures, setSignatures] = useState([]);

  const cleanup = useRef(false);

  const getSettings = async () => {
    let data = await ApiEmailService.getSettings('getSettings');
    if (data !== undefined && !cleanup.current) {
      setOptions({ ...options, ...data });
    }
  };

  const getSignatures = async () => {
    let data = await ApiEmailService.getSignatures('signatureList');
    if (data !== undefined && !cleanup.current) {
      setSignatures([...data]);
    }
  };

  useEffect(() => {
    async function getUserSettings() {
      await getSettings();
    }
    async function getUserSignatures() {
      await getSignatures();
    }

    getUserSettings().then();
    getUserSignatures().then();

    return () => {
      cleanup.current = true;
    };
    // eslint-disable-next-line
  }, []);

  const updateOptions = async (all_options) => {
    await ApiEmailService.updateSettings('updateSettings', all_options);
  };

  const checkBoxOnClick = (event) => {
    const newData = {
      ...options,
      [event.target.name]: !options[event.target.name],
    };
    setOptions(newData);
    updateOptions(newData).then();
  };

  const ModalAction = () => {
    return (
      <div className={classes.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
          }}
          className={classes.deleteButton}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        onClose={onClose}
        open={true}
        title={'Settings'}
        ModalContent={() => {
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              className={classes.root}
            >
              <FormControl
                size="small"
                variant="outlined"
                className={classes.ddControl + ' pt-4'}
              >
                <FormControlLabel
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.customLabel,
                  }}
                  labelPlacement="top"
                  control={
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <CustomSwitch
                        checked={options['grammar']}
                        name="grammar"
                        onChange={checkBoxOnClick}
                      />
                    </Grid>
                  }
                  label={'Grammar suggestions ON/OFF'}
                />
              </FormControl>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.ddControl + ' pt-4'}
              >
                <FormControlLabel
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.customLabel,
                  }}
                  labelPlacement="top"
                  control={
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <CustomSwitch
                        checked={options['spelling']}
                        name="spelling"
                        onChange={checkBoxOnClick}
                      />
                    </Grid>
                  }
                  label={'Spelling suggestions ON/OFF'}
                />
              </FormControl>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.ddControl + ' pt-4'}
              >
                <FormControlLabel
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.customLabel,
                  }}
                  labelPlacement="top"
                  control={
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <CustomSwitch
                        checked={options['autocorrect']}
                        name="autocorrect"
                        onChange={checkBoxOnClick}
                      />
                    </Grid>
                  }
                  label={'Autocorrect suggestions ON/OFF'}
                />
              </FormControl>
              <Typography
                component="span"
                variant="body1"
                display="block"
                color="textPrimary"
                classes={{
                  root: classes.signatureTitle,
                }}
                className={'mt-4 mb-2'}
              >
                Signature:
              </Typography>
              <List>
                {signatures.length > 0
                  ? [
                      ...signatures.map((sign) => {
                        return (
                          <SignatureItem
                            onUpdate={getSignatures}
                            name={sign.name}
                            key={'user_sign_id' + sign.id}
                            id={sign.id}
                            content={sign.content}
                          />
                        );
                      }),
                    ]
                  : [
                      <SignatureItem
                        key={'sign_first_item_id_temp'}
                        onUpdate={getSignatures}
                        name={' test '} // TODO: REMOVE
                      />,
                    ]}
              </List>
            </Grid>
          );
        }}
        ModalAction={ModalAction}
        maxWidth="md"
        fullWidth={true}
        onBackdropClick={() => {
          onClose();
        }}
      />
    </>
  );
};

export default Settings;
