import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from './Stepper';
import { Alert, AlertTitle } from '@material-ui/lab';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// import { getProperSize } from '../../utils/face.js';
import useSound from 'use-sound';
import beepSfx from '../../assets/media/beep.wav';
import { sleepy } from '../../utils/webcam/webrtc';
import { TRIANGULATION } from '../../utils/triangulation.js';
import { stopStream } from '../../utils/webcam/webrtc';

import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import * as tf from '@tensorflow/tfjs-core';

import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';

let height = window.innerHeight - 350;
let width = (height * 5) / 3;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 350px)',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  progressWrapper: {
    height: '60px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  videoWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  relative: {
    position: 'relative',
    height: `${height}px`,
  },
  video: {
    height: `${height}px`,
    width: `${width}px`,
    maxHeight: 'calc(100vh - 350px)',
    // objectFit: 'cover',
    // objectPosition: 'center',
    // filter: 'blur(5px)',
    zIndex: 100,
    '-webkit-transform': 'scaleX(-1)',
    transform: 'scaleX(-1)',
    visibility: 'hidden',
  },
  videoMask: {
    display: 'none',
    // height: `${height}px`,
    // width: `${width}px`,
    // maxHeight: 'calc(100vh - 350px)',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 200,
    // filter: 'blur(5px)',
    // clipPath: `ellipse(${parseInt(height * 0.45 * 0.8)}px ${parseInt(
    //   height * 0.45
    // )}px at 50% 50%)`,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    height: `${height}px`,
    width: `${width}px`,
    maxHeight: 'calc(100vh - 350px)',
    zIndex: 300,
  },
  alertWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& .MuiPaper-root': {
      width: '350px',
    },
    position: 'absolute',
    left: '50%',
    alignItems: 'center',
  },
  stepperWrapper: {
    position: 'absolute',
    bottom: '0px',
    width: '60%',
    left: '50%',
    zIndex: 300,
    transform: 'translateX(-50%)',
  },
  face: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${parseInt(height * 0.9 * 0.8)}px`,
    height: `${parseInt(height * 0.9)}px`,
    borderRadius: '50%',
    background: 'black',
    zIndex: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const messages = {
  forward: {
    id: 'app.facerecognition.lookForward',
    defaultMessage: 'Look forward',
  },
  up: {
    id: 'app.facerecognition.lookUp',
    defaultMessage: 'Look up',
  },
  left: {
    id: 'app.facerecognition.lookLeft',
    defaultMessage: 'Look left',
  },
  down: {
    id: 'app.facerecognition.lookDown',
    defaultMessage: 'Look down',
  },
  right: {
    id: 'app.facerecognition.lookRight',
    defaultMessage: 'Look right',
  },
  registeredDone: {
    id: 'app.facerecognition.faceRegistered',
    defaultMessage: 'Your face was registered successfully',
  },
};

function cross_normalised(x, y) {
  const cross = [
    x[1] * y[2] - x[2] * y[1],
    x[2] * y[0] - x[0] * y[2],
    x[0] * y[1] - x[1] * y[0],
  ];
  const norm = Math.sqrt(cross[0] ** 2 + cross[1] ** 2 + cross[2] ** 2);
  return [cross[0] / norm, cross[1] / norm, cross[2] / norm];
}

tfjsWasm.setWasmPath(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/tfjs-backend-wasm.wasm`
);

let componentMounted = false,
  comparisionAvailable = true,
  countOfFaceDetection = 0;

let synth = window.speechSynthesis;
let utterThis = new SpeechSynthesisUtterance('');
let detectionModel, canvasContext, videoWidth, videoHeight, video, canvas;

/**
 * =========================================================================================================
 *  configurable settings
 */

const directions = ['forward', 'up', 'left', 'down', 'right'];
const minFaceDetectionScoresByDirection = [0.9, 0.8, 0.8, 0.75, 0.8]; // when tensorflow detects a face, it returns the face's
const delta = [0.2, -0.2, 0.15, -0.2];
const msecondsForTurningHead = 2000;

//==========================================================================================================

function isMobile() {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  return isAndroid || isiOS;
}

const mobile = isMobile();

/**
 *
 * The head direction is estimated by calculating the vectors connecting the centre of the lips to the left and right cheeks.
 * These two vectors lie on a plane that approximates the surface of the face.
 * The cross product of these vectors is normal to this plane and thus points approximately in the direction of the head.
 * Always, the initial head direction is forward and we make the decision of head direction base on the vector of the initial direction.
 */

let initLr, initUd; // initial head direction

const directionCheck = (preds) => {
  let leftCheek = preds['annotations']['leftCheek'][0];
  let rightCheek = preds['annotations']['rightCheek'][0];

  let lipsLowerInner = [0, 0, 0];
  let lipsCoords = preds['annotations']['lipsLowerInner'];
  for (let i = 0; i < lipsCoords.length; i++) {
    for (let j = 0; j < 3; j++) {
      lipsLowerInner[j] += lipsCoords[i][j];
    }
  }
  for (let j = 0; j < 3; j++) {
    lipsLowerInner[j] = lipsLowerInner[j] / lipsCoords.length;
  }

  let leftDiff = [];
  let rightDiff = [];
  for (let i = 0; i < 3; i++) {
    leftDiff.push(leftCheek[i] - lipsLowerInner[i]);
    rightDiff.push(rightCheek[i] - lipsLowerInner[i]);
  }

  let cross = cross_normalised(leftDiff, rightDiff);

  let lr = cross[0];
  let ud = cross[1];
  if (initLr === undefined) {
    initLr = lr;
  }
  if (initUd === undefined) {
    initUd = ud;
  }

  let direction;

  /**
   * At the beginning of the detecting, the user is asked to look straight ahead, giving a reference vector.
   * Subsequent estimated vectors are compared to this reference.
   * If the y coordinate has increased/decreased by a sufficiently significant amount relative to the reference,
   * the direction is classified as up/down respectively.
   * Similarly,if the x coordinate has increased/decreased by a sufficiently significant amount relative to the reference, the direction is classified as left/right respectively.
   * In the case that both x and y coordinates changed significantly, the left/right direction takes precedence,
   * and ‘sufficiently significant’ is a parameter that has to be chosen.)
   */

  if (lr - initLr > delta[0]) {
    direction = 'left';
  } else if (lr - initLr < delta[1]) {
    direction = 'right';
  } else if (ud - initUd > delta[2]) {
    direction = 'down';
  } else if (ud - initUd < delta[3]) {
    direction = 'up';
  } else {
    direction = 'forward';
  }
  return direction;
};

const FaceLearn = (props) => {
  const classes = useStyles();
  const { show, setDescriptions, activeStep, setActiveStep } = props;
  const [camError, setCamError] = useState(false);
  const [localStream, setLocalStream] = useState(null);

  const {
    intl: { formatMessage },
  } = props;

  const locale = useSelector((state) => state.global.locale);

  const [beepPlay] = useSound(beepSfx, { volume: 0.5 });

  useEffect(() => {
    if (show) {
      try {
        main();
        navigator.mediaDevices.addEventListener(
          'devicechange',
          async (event) => {
            main();
          }
        );
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    componentMounted = true;
    comparisionAvailable = false;
    countOfFaceDetection = 0;
    setLocalStream(null);

    return function cleanup() {
      componentMounted = false;
      stopStream(localStream);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!show) {
      stopStream(localStream);
    }
  }, [show, localStream]);

  const setupCamera = async () => {
    video = document.getElementById('faceRegisterVideo');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: 'user',
          // Only setting the video to a specified size in order to accommodate a
          // point cloud, so on mobile devices accept the default size.
          width: mobile ? undefined : width,
          height: mobile ? undefined : height,
        },
      });
      video.srcObject = stream;
      setLocalStream(stream);

      return new Promise((resolve) => {
        video.onloadedmetadata = () => {
          resolve(video);
        };
      });
    } catch (e) {
      console.log('camera error', e);
      setCamError(true);
      throw e;
    }
  };

  const drawPath = (canvasContext, points, closePath) => {
    const region = new Path2D();
    region.moveTo(points[0][0], points[0][1]);
    for (let i = 1; i < points.length; i++) {
      const point = points[i];
      region.lineTo(point[0], point[1]);
    }

    if (closePath) {
      region.closePath();
    }
    canvasContext.stroke(region);
  };

  const renderPrediction = async () => {
    canvas = document.getElementById('faceRegisterOverlay');
    if (!componentMounted) return;
    const predictions = await detectionModel.estimateFaces({
      input: video,
    });
    canvasContext.drawImage(
      video,
      0,
      0,
      videoWidth,
      videoHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    if (predictions.length > 0) {
      predictions.forEach((prediction) => {
        if (
          prediction.faceInViewConfidence <
            minFaceDetectionScoresByDirection[countOfFaceDetection] ||
          prediction.faceInViewConfidence < 0.5
        )
          return;

        const keypoints = prediction.scaledMesh;

        const state = directionCheck(prediction);

        for (let i = 0; i < TRIANGULATION.length / 3; i++) {
          const points = [
            TRIANGULATION[i * 3],
            TRIANGULATION[i * 3 + 1],
            TRIANGULATION[i * 3 + 2],
          ].map((index) => keypoints[index]);

          drawPath(canvasContext, points, true);
        }

        if (
          state === directions[countOfFaceDetection] &&
          comparisionAvailable & (countOfFaceDetection < 5)
        ) {
          // TODO Post photo of angle to new api endpoint with desired state name
          setDescriptions((ps) => [
            ...ps,
            {
              label: 'username',
              // photo: captured_photo,
              angle: state,
              descriptors: Array.from(keypoints),
            },
          ]);
          countOfFaceDetection++;
          comparisionAvailable = false;
          setActiveStep((ps) => ps + 1);
        }
      });
    }
    requestAnimationFrame(renderPrediction);
  };

  const main = async () => {
    await tf.setBackend('wasm');

    try {
      await setupCamera();
    } catch (e) {
      return;
    }

    video.play();
    videoWidth = video.videoWidth;
    videoHeight = video.videoHeight;
    video.width = videoWidth;
    video.height = videoHeight;

    canvas = document.getElementById('faceRegisterOverlay');
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const canvasContainer = document.getElementById('canvasWrapper');
    canvasContainer.style = `width: ${videoWidth}px; height: ${videoHeight}px`;

    canvasContext = canvas.getContext('2d');
    canvasContext.translate(canvas.width, 0);
    canvasContext.scale(-1, 1);
    canvasContext.fillStyle = '#32EEDB';
    canvasContext.strokeStyle = '#32EEDB';
    canvasContext.lineWidth = 0.5;

    detectionModel = await faceLandmarksDetection.load(
      faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
      { maxFaces: 1 }
    );

    renderPrediction();
  };

  useEffect(() => {
    const waitAndSpeak = async (message) => {
      utterThis.lang = locale;
      utterThis.text = message;
      await sleepy(500);
      if (utterThis.text !== undefined) {
        synth.speak(utterThis);
      }
    };

    if (show && activeStep > 0 && activeStep <= 5) {
      beepPlay();
    }
    if (show && activeStep >= 0 && activeStep <= 5) {
      let message = formatMessage(messages.registeredDone);
      if (activeStep < 5)
        message = formatMessage(messages[directions[activeStep]]);

      waitAndSpeak(message);
      if (activeStep < 5)
        setTimeout(() => {
          comparisionAvailable = true;
        }, msecondsForTurningHead);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, activeStep]);

  return (
    <div className={classes.root}>
      <div className={classes.videoWrapper}>
        <div className={classes.relative} id="canvasWrapper">
          <video
            id="faceRegisterVideo"
            className={classes.video}
            // ref={videoRef}
            autoPlay
            muted
          ></video>
          <canvas
            className={classes.canvas}
            id="faceRegisterOverlay"
            // ref={canvasRef}
          />
          <div className={classes.stepperWrapper}>
            <Stepper steps={[1, 2, 3, 4, 5]} activeStep={activeStep} />
          </div>
        </div>
      </div>
      <div className={classes.alertWrapper}>
        {camError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>Please check if your webcam works!</strong>
          </Alert>
        )}
      </div>
    </div>
  );
};

export default injectIntl(FaceLearn);
