import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(1),
  },
  noBorderRoot: {
    width: '100%',
  },
  title: {
    fontSize: 13,
    fontWeight: theme.typography.fontWeightBold,
  },
  subTitle: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.primary.main,
  },
  content: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
  },
}));

const AmeraContactCard = (props) => {
  const { title, subTitle, content, buttons, isBorder = true } = props;
  const classes = useStyles();
  return (
    <div className={isBorder ? classes.root : classes.noBorderRoot}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subTitle}>{subTitle}</Typography>
      <Typography className={classes.content}>{content}</Typography>
      {buttons}
    </div>
  );
};

export default AmeraContactCard;
