import React from 'react';

const ArrowDownGray = () => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 7L11.1962 0.25H0.803848L6 7Z" fill="#C4C4C4" />
  </svg>
);

export default ArrowDownGray;
