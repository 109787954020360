import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';

const ContactItem = (props) => {
  const { contactInformation, countryList, isPhone, country_code } = props;
  const { device, description, device_country } = contactInformation;
  let phoneFormatted = '';
  if (isPhone && countryList.length) {
    const country = country_code
      .filter(Boolean)
      .find((info) => info.id === contactInformation.device_country);
    const phone = countryList.find((clo) => clo.id === device_country).phone;
    phoneFormatted =
      `+${country.phone}` +
      parsePhoneNumber(`+${phone}${device}`).formatNational();
  }
  return (
    <>
      <div className="blank-space"></div>
      <p className="sub-title-no-space">{description}</p>
      <p className="text-content">{isPhone ? phoneFormatted : device}</p>
    </>
  );
};

ContactItem.propTypes = {
  countryList: PropTypes.arrayOf(PropTypes.object),
};

ContactItem.defaultProps = {
  countryList: [],
};
const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(ContactItem);
