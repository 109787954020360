import { createBareJIDFromUserID } from './user';

// subscribe request
export const subscribeMembersStrophe = (connection, members) => {
  members.forEach((m) => {
    const JID = createBareJIDFromUserID(connection, m.id),
      message = `Hi ${m.nick}, please add me in your contact list.`;
    connection.roster.subscribe(JID, message, m.nick);
  });
};

// subscribe response (accept)
export const autorizeMembersStrophe = async (connection, members) => {
  members.forEach((m) => {
    let JID = m.id.toString();
    if (!JID.includes('@')) {
      JID = createBareJIDFromUserID(connection, m.id);
    }
    const message = `${m.nick} accepted your subscribe request.`;
    connection.roster.authorize(JID, message);
  });
};

// subscribe response (decline)
export const unAutorizeMembersStrophe = (connection, members) => {
  members.forEach((m) => {
    const JID = createBareJIDFromUserID(connection, m.id),
      message = `${m.nick} declined accepted your subscribe request.`;
    connection.roster.unauthorize(JID, message);
  });
};

// unsubscribe the authorized contact
export const unSubscribeMembersStrophe = (connection, members) => {
  members.forEach((m) => {
    const JID = createBareJIDFromUserID(connection, m.id),
      message = `${m.nick} has removed you from his/her contact list.`;
    connection.roster.unsubscribe(JID, message);
  });
};
