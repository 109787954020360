import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    '& button': {
      padding: 0,
      '& svg': {
        width: 18,
        height: 18,
      },
    },
  },
}));

const EventName = (props) => {
  const { value, onChange, error, defaultName, variant = 'outlined' } = props;
  const classes = useStyles();

  return (
    <FormControl fullWidth variant={variant} style={{ margin: 0 }}>
      <TextField
        fullWidth
        id="eventname-textfield"
        value={value}
        placeholder={defaultName}
        autoFocus
        variant={variant}
        className={classes.root}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment postion="end">
              <IconButton onClick={() => onChange('')}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!error}
        helperText={!!error ? error : ''}
      />
    </FormControl>
  );
};

export default EventName;
