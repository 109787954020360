import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../SearchComponent';
import { useHistory } from 'react-router-dom';
import { closeDrawer, selectGroup } from '../../redux/actions/drawer';
import { setCallGroupId } from '../../redux/actions/event';
import { establishGroupVideoCall } from '../../utils/general';
import { Strophe } from 'strophe.js';
import { joinChatRoom, leaveChatRoom } from '../../redux/actions/chat';
import {
  getGroupDetail,
  sendUnsecuredCallInvite,
} from '../../redux/actions/group';
import { getConferenceID } from '../../utils/general';
import ContactPreview from '../ContactPreview';
import MaterialAvatar from '../MaterialAvatar';
import SecurityIcon from '@material-ui/icons/Security';
import VideocamIcon from '@material-ui/icons/Videocam';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#FFF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '4px',
      borderRadius: '8px',
      backgroundColor: '#d3d3d380',
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000000',
  },
  description: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#000000',
  },
  contacts: {
    padding: '28px 22px 22px 22px',
  },
  wrapper: {
    border: '2px solid #D4E2F0',
    padding: '17px 0px 15px 0px',
    borderRadius: '8px',
    minHeight: '500px',
  },
  innerTitle: {
    fontSize: '23px',
    fontWeight: 600,
  },
  innerSearch: {
    width: '182px',
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 13px 0px 19px',
  },
  list: {
    marginTop: '8px',
  },
  listItem: {
    padding: '14px 19px 14px 19px',
    borderBottom: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cancelBtn: {
    '& .MuiButton-root': {
      width: '124px',
      height: '40px',
      border: '1px solid #8DA3C5',
      borderRadius: '7px',
      color: '#8DA3C5',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  callBtn: {
    '& .MuiButton-root': {
      height: '40px',
      borderRadius: '7px',
      fontSize: '15px',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  member: {
    display: 'flex',
  },
  info: {
    marginLeft: '13px',
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#363C45',
    marginBottom: '3px',
  },
  desc: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#5F5F5F',
    marginBottom: '0px',
  },
  avatar: {
    width: '40px',
    height: '40px',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '23px',
  },
}));

const GroupMemberList = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { group_id } = props;
  const {
    type,
    // groups,
    member,
    connectionStatus,
    selectedGroupID,
    groupConversations,
    group,
  } = useSelector((state) => ({
    type: state.drawer.type,
    // groups: state.drawer.contentProps.groups,
    member: state.member.member,
    connectionStatus: state.chat.connectionStatus,
    selectedGroupID: state.chat.selectedGroupID,
    groupConversations: state.chat.groupConversations,
    group: state.group.groupData,
  }));

  const [searchKey, setSearchKey] = useState('');
  const [callMembers, setCallMembers] = useState([]);
  const [callUnsecuredMembers, setCallUnsecuredMembers] = useState([]);

  const dispatch = useDispatch();

  // const group = useMemo(() => {
  //   if (group_id && groups && groups.length > 0) {
  //     return groups.find((group) => group.group_id === group_id);
  //   }
  //   return null;
  // }, [group_id, groups]);

  const groupMembers = useMemo(() => {
    return group.members || [];
  }, [group.members]);

  const groupUnsecuredMembers = useMemo(() => {
    let unsecuredMembers = group.unsecured_members || [];
    if (unsecuredMembers.length > 0) {
      unsecuredMembers = unsecuredMembers.map((um) => ({
        ...um,
        member_type: 'unsecured',
      }));
    }
    return unsecuredMembers;
  }, [group.unsecured_members]);

  const members = useMemo(() => {
    let members = [];
    if (member.member_id === group.group_leader_id) {
      members = [...groupMembers, ...groupUnsecuredMembers];
    } else {
      members = [...groupMembers];
    }
    members = members.filter((m) => {
      if (m.member_id === member.member_id && m.member_type !== 'unsecured')
        return false;
      if (searchKey === '') return true;
      return `${m.first_name} ${m.last_name}`.indexOf(searchKey) >= 0;
    });
    return members;
  }, [
    groupMembers,
    groupUnsecuredMembers,
    group.group_leader_id,
    member.member_id,
    searchKey,
  ]);

  React.useEffect(() => {
    dispatch(getGroupDetail(group_id));
  }, [dispatch, group_id]);

  const groupRosters =
    (selectedGroupID &&
      groupConversations &&
      groupConversations.filter((c) => c.chatID === selectedGroupID)?.[0]
        ?.groupRosters) ||
    [];

  React.useEffect(() => {
    if (
      group &&
      group.group_id &&
      connectionStatus === Strophe.Status.CONNECTED
    ) {
      const conferenceID = getConferenceID(group);
      console.log('joining group action', group, conferenceID);
      dispatch(joinChatRoom(conferenceID));
    }
    return () => {
      if (group && connectionStatus === Strophe.Status.CONNECTED) {
        console.log('leaving group action');
        leaveChatRoom();
      }
    };
  }, [dispatch, group, connectionStatus]);

  const title =
    type === 'video_call' ? 'Video Call your Groups' : 'Audio Call your Groups';

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  const handleCallMembers = (id) => {
    setCallMembers((prev) => {
      if (prev.includes(id)) return prev.filter((prevItem) => prevItem !== id);
      return [...prev, id];
    });
  };

  const handleCallUnsecuredMembers = (id) => {
    setCallUnsecuredMembers((prev) => {
      if (prev.includes(id)) return prev.filter((prevItem) => prevItem !== id);
      return [...prev, id];
    });
  };

  const openGroupVideoAndNotify = async () => {
    const conferenceUrl = await establishGroupVideoCall(
      group,
      member,
      callMembers
    );
    await dispatch(setCallGroupId(group.group_id));
    dispatch(closeDrawer());
    history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openUnsecuredCall = async () => {
    dispatch(sendUnsecuredCallInvite(group, callUnsecuredMembers));
    await openGroupVideoAndNotify();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.title}>{title}</h3>
      </div>
      <div className={classes.contacts}>
        <div className={classes.wrapper}>
          <div className={classes.listHeader}>
            <div className={classes.innerTitle}>{group.group_name} group</div>
            <div className={classes.innerSearch}>
              <Search searchKey={searchKey} handleSearch={handleSearch} />
            </div>
          </div>
          <div className={classes.list}>
            {members &&
              members.length > 0 &&
              members.map((groupMember, index) => {
                const memberOnlineStatus = groupRosters.find(
                  (ri) =>
                    parseInt(ri.rosterID) === parseInt(groupMember.member_id)
                )?.status;
                return (
                  <div
                    key={index}
                    className={classes.listItem}
                    onClick={() => {
                      if (groupMember.member_type === 'unsecured') {
                        console.log('here');
                        handleCallUnsecuredMembers(groupMember.member_id);
                      } else if (memberOnlineStatus === 'online')
                        handleCallMembers(groupMember.member_id);
                    }}
                  >
                    {groupMember.member_type === 'unsecured' ? (
                      <ContactPreview
                        contactData={{
                          ...groupMember,
                          member_id: '',
                          extra: `+${groupMember.phone_code}${groupMember.phone_number}`,
                          unsecured: true,
                        }}
                        isShowAvatar={true}
                      />
                    ) : (
                      <div className={classes.member}>
                        <MaterialAvatar
                          member_id={groupMember.member_id}
                          first_name={groupMember.first_name}
                          last_name={groupMember.last_name}
                          badgeType={memberOnlineStatus}
                          classes={classes.avatar}
                          badgeAnchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                          }}
                        />
                        <div className={classes.info}>
                          <h3 className={classes.name}>
                            {groupMember.first_name} {groupMember.last_name}
                          </h3>
                          <h3 className={classes.desc}>{groupMember.title}</h3>
                        </div>
                      </div>
                    )}

                    <div className={classes.actionList}>
                      <Checkbox
                        checked={
                          groupMember.member_type === 'unsecured'
                            ? callUnsecuredMembers.includes(
                                groupMember.member_id
                              )
                            : callMembers.includes(groupMember.member_id)
                        }
                        disabled={
                          memberOnlineStatus !== 'online' &&
                          groupMember.member_type !== 'unsecured'
                        }
                        color="primary"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className={classes.btnWrapper}>
          <div
            className={classes.cancelBtn}
            onClick={() => {
              dispatch(selectGroup(null));
            }}
          >
            <Button variant="outlined">Cancel</Button>
          </div>
          <div className={classes.callBtn} onClick={openUnsecuredCall}>
            <Tooltip title="Call group memebers and outside members">
              <Button
                variant="contained"
                color="primary"
                startIcon={<VideocamIcon />}
              >
                Unsecured Call
              </Button>
            </Tooltip>
          </div>

          <div className={classes.callBtn} onClick={openGroupVideoAndNotify}>
            <Tooltip title="Call only group members">
              <Button
                variant="contained"
                color="primary"
                startIcon={<SecurityIcon />}
              >
                Secured Call
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupMemberList;
