import * as api from '../../config/api';
import {
  IS_LOADING_MEMBER_INVITE,
  REMOVE_INVITE_ALERT,
  SET_INVITE_ALERT,
} from '../actionTypes/invite';
import { setInviteAlert } from '../../utils/alert';

const apiUrl = api.apiUrl;

export const sendMemberContactInvite = (formData) => async (dispatch) => {
  const url = `${apiUrl}/member/invite/contact`;

  dispatch({
    type: IS_LOADING_MEMBER_INVITE,
  });
  try {
    const res = await api.POST(url, formData, {});
    const payload = setInviteAlert(res, true);
    dispatch(setReduxInviteAlert(payload));
  } catch (error) {
    const payload = setInviteAlert(error, false);
    dispatch(setReduxInviteAlert(payload));
  }
};

export const setReduxInviteAlert = (payload) => ({
  type: SET_INVITE_ALERT,
  payload,
});

export const removeInviteAlert = () => (dispatch) =>
  dispatch({ type: REMOVE_INVITE_ALERT });
