import React from 'react';
import { makeStyles } from '@material-ui/styles';
import TimerIcon from '@material-ui/icons/Timer';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AllOutIcon from '@material-ui/icons/AllOut';
// import { format } from 'date-fns';

import Description from './description';
import { defaultDateTimeZone } from '../../../utils/calendar';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFFFFF',
  },
  videoPlayer: {
    height: 509,
    marginBottom: 6,
    background: '#97ACC1',
    position: 'relative',
    width: '100%',
    borderRadius: 5,
  },
  videoInfo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  header: {
    padding: 18,
    '& h2, h4': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& h2': {
      fontSize: 26,
      letterSpacing: '0.39px',
    },
    '& h4': {
      fontSize: 16,
      letterSpacing: '0.24px',
    },
  },
  body: {
    marginTop: 160,
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  time: {
    fontSize: 105,
  },
  live: {
    fontSize: 24,
    fontWeight: 700,
    color: '#FF0000',
    marginTop: -30,
    textAlign: 'right',
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  footer: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 18px',
  },
  leftFooter: {
    display: 'flex',
    '& >div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& >div:first-child': {
      marginRight: 10,
    },
  },
  rightFooter: {
    display: 'flex',
    '& svg': {
      color: '#FFFFFF',
    },
  },
}));

const LargeVideo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.videoPlayer}>
        <video playsInline className={classes.video} me="true"></video>
        <div className={classes.videoInfo}>
          <div className={classes.header}>
            <h2>Marketing Presentation</h2>
            <h4>By: Chris Daly</h4>
          </div>
          <div className={classes.body}>
            <div className={classes.time}>2h : 58m</div>
            <div className={classes.live}>to live</div>
          </div>
          <div className={classes.footer}>
            <div className={classes.leftFooter}>
              <div>
                <EventAvailableIcon />
                <span>{defaultDateTimeZone(new Date(), 'date')}</span>
              </div>
              <div>
                <TimerIcon />
                <span>{`03:15`}</span>
              </div>
            </div>
            <div className={classes.rightFooter}>
              <IconButton onClick={() => {}}>
                <SaveIcon />
              </IconButton>
              <IconButton onClick={() => {}}>
                <AllOutIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <Description />
    </div>
  );
};

export default LargeVideo;
