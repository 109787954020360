import {
  STROPHE_CONNECT,
  STROPHE_DISCONNECT,
  STROPHE_CONNECTION_STATUS,
  SET_CHAT_CONVERSATION_ID,
  SET_GROUP_CONVERSATION_ID,
  LOAD_CONTACTS_ARCHIVE,
  LOAD_ROSTER_ITEMS,
  CREATE_CHAT_ROOM,
  JOIN_CHAT_ROOM,
  LEAVE_CHAT_ROOM,
  DESTROY_CHAT_ROOM,
  DELETE_GROUP,
  UPDATE_GROUP_ROSTERS,
  SET_GROUP_ROSTER_STATUS,
  SEND_CONTACT_MESSAGE,
  SEND_GROUP_MESSAGE,
  ADD_CHAT_MESSAGE,
  ADD_GROUP_MESSAGE,
  SUBSCRIBE_MEMBERS,
  AUTHORIZE_MEMBERS,
  UNAUTHORIZE_MEMBERS,
  UNSUBSCRIBE_MEMBERS,
  DECRYPT_MESSAGE,
  LOAD_CONTACT_MESSAGES,
  SET_CHAT_UNREAD_MESSAGE_COUNT,
  STROPHE_SEND_FILES,
  DECRYPT_GROUP_MESSAGE,
  CHAT_RESET,
} from '../actionTypes/chat';

export const stropheConnect = (member_id) => ({
  type: STROPHE_CONNECT,
  payload: {
    member_id,
  },
});
export const stropheDisconnect = (logout) => ({
  type: STROPHE_DISCONNECT,
  payload: { logout },
});
export const setConnectionStatus = (status) => ({
  type: STROPHE_CONNECTION_STATUS,
  payload: { status },
});

export const setChatConversationID = (ID) => (dispatch) => {
  dispatch({
    type: SET_CHAT_CONVERSATION_ID,
    payload: { ID },
  });
};
export const setGroupConversationID = (ID) => (dispatch) => {
  dispatch({
    type: SET_GROUP_CONVERSATION_ID,
    payload: { ID },
  });
};

export const loadContactsArchive = (contacts) => ({
  type: LOAD_CONTACTS_ARCHIVE,
  payload: { contacts },
});
export const loadRosterItems = (rosterItems) => ({
  type: LOAD_ROSTER_ITEMS,
  payload: { rosterItems },
});

export const createChatRoom = (room, members) => ({
  type: CREATE_CHAT_ROOM,
  payload: { room, members },
});
export const joinChatRoom = (room) => ({
  type: JOIN_CHAT_ROOM,
  payload: { room },
});
export const leaveChatRoom = () => ({
  type: LEAVE_CHAT_ROOM,
});
export const destroyChatRoom = (groups) => ({
  type: DESTROY_CHAT_ROOM,
  payload: { groups },
});
export const deleteGroup = (roomID, message) => ({
  type: DELETE_GROUP,
  payload: { chatID: roomID, message },
});
export const updateGroupRosters = (groupID, groupRosters) => ({
  type: UPDATE_GROUP_ROSTERS,
  payload: { groupID, groupRosters },
});
export const setGroupRosterStatus = (groupID, rosterID, status) => ({
  type: SET_GROUP_ROSTER_STATUS,
  payload: { groupID, rosterID, status },
});

export const addChatMessage = (obj) => (dispatch, getState) => {
  dispatch({
    type: ADD_CHAT_MESSAGE,
    payload: { messageObj: obj },
  });
};
export const addGroupMessage = (chatID, messageObj) => (dispatch) => {
  dispatch({
    type: ADD_GROUP_MESSAGE,
    payload: { chatID, messageObj },
  });
};

export const setChatUnreadMessagesCount = (chatID, unreadCount) => ({
  type: SET_CHAT_UNREAD_MESSAGE_COUNT,
  payload: { chatID, unreadCount },
});

export const sendChatMessage = (selectedChatID, messageText) => ({
  type: SEND_CONTACT_MESSAGE,
  payload: { selectedChatID, messageText },
});
export const sendGroupMessage = (selectedChatID, messageText) => ({
  type: SEND_GROUP_MESSAGE,
  payload: { selectedChatID, messageText },
});

export const subscribeMembers = (members) => ({
  type: SUBSCRIBE_MEMBERS,
  payload: { members },
});
export const authorizeMembers = (members) => ({
  type: AUTHORIZE_MEMBERS,
  payload: { members },
});
export const unAuthorizeMembers = (members) => ({
  type: UNAUTHORIZE_MEMBERS,
  payload: { members },
});
export const unSubscribeMembers = (members) => ({
  type: UNSUBSCRIBE_MEMBERS,
  payload: { members },
});

export const stropheSendFiles = (to, files, messageText) => (dispatch) => {
  dispatch({
    type: STROPHE_SEND_FILES,
    payload: { to, files, messageText },
  });
};

export const decryptMessage = (newConversations) => ({
  type: DECRYPT_MESSAGE,
  payload: newConversations,
});

export const decryptGroupMessage = (newConversations) => ({
  type: DECRYPT_GROUP_MESSAGE,
  payload: newConversations,
});

export const loadContactMessages = (contact_id) => ({
  type: LOAD_CONTACT_MESSAGES,
  payload: {
    contact_id,
  },
});

export const resetChat = () => ({ type: CHAT_RESET });
