import React from 'react';
import { useSelector } from 'react-redux';

import AmeraBanner from './index.js';

const AmeraStaticBanner = () => {

  const staticNotification = useSelector(
    // @ts-ignore
    (state) => state.snackbar.staticNotification
  );

  const { type, message, buttonLabel, buttonOnClick } = staticNotification;

  return (
    <div className="static-banner">
      { type && message &&
        <AmeraBanner
          appBar
          type={type}
          message={message}
          buttonLabel={buttonLabel}
          buttonOnClick={buttonOnClick}
          showDismissButton={false}
        />
      }
    </div>
  );
};

export default AmeraStaticBanner;
