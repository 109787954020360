import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Button from '@material-ui/core/Button';
import TOTPModal from '../TOTPModal';

const EmailInput = (props) => {
  const [isShowTOTP, setShowTOTP] = useState(false);

  const {
    handleEmailChange,
    handleEmailVerified,
    handleThreeTimesWrong,
    email,
    errors,
    isConfirmed,
  } = props;

  const isCanVerify = !!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  let adornment = '';

  if (isConfirmed) {
    adornment = <VerifiedUserIcon color="primary" />;
  } else {
    if (isCanVerify && !errors) {
      adornment = <Button onClick={() => setShowTOTP(true)}>Verify</Button>;
    } else {
      adornment = '';
    }
  }

  return (
    <>
      <TOTPModal
        show={isShowTOTP}
        close={() => setShowTOTP(false)}
        onVerificationSuccess={handleEmailVerified}
        onThreeTimesWrong={handleThreeTimesWrong}
        email={email}
      />
      <TextField
        id={'outlined-email'}
        label={'Email'}
        // Prevent user from pasting confirm email
        type="email"
        required
        variant="outlined"
        name={'email'}
        placeholder="example@example.com"
        className="register_form_input"
        onChange={handleEmailChange}
        value={email || ''}
        error={errors ? true : false}
        helperText={errors ? errors : ''}
        InputProps={{
          autoComplete: 'email',
          endAdornment: (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default EmailInput;
