import { useState, useEffect } from 'react';
import Feedback from 'feedback-screenshot';

function useScreenFeedback() {
  const [feedback, setFeedback] = useState(null);

  const initializeFeedback = () => {
    const feedback = new Feedback({
      //default:#347EF8(blue)
      borderColor: '#000',

      //zIndex of screen shot content
      //default:999
      zIndex: 200,

      //content will append to
      //default:document.body
      // parent: document.querySelector('#root'),

      //html2canvas options
      //default:{}
      html2canvas: {
        logging: false,
        useCROS: true,
        scale: 1,
      },
    });
    setFeedback(feedback);
  };

  useEffect(() => {
    initializeFeedback();
  }, []);

  const openFeedback = () => {
    //start screen shot
    feedback.open();
  };

  //currently not using this feature
  const enablePrivate = (isPrivate = true) => {
    //change rect background to black, cover some private data
    feedback.setBlackMode(isPrivate);
  };
  const closeFeedback = () => {
    return new Promise((resolve, reject) => {
      try {
        //close screen shot and wait for canvas
        feedback.close().then((canvas) => {
          resolve(canvas);
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  const cancelFeedback = () => {
    try {
      if (feedback) {
        console.log('feedback object', feedback);
        //cancel screen shot, will return null
        feedback.close(true);
      }
    } catch (error) {
      console.log('something went wrong when cancel', error);
    }
  };
  return {
    openFeedback,
    enablePrivate,
    closeFeedback,
    cancelFeedback,
  };
}

export default useScreenFeedback;
