import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { startDisplay } from '../../utils/webcam/webrtc';

let displayStream;

const MainFeature = (props) => {
  const [shareDisplay, setShareDisplay] = useState(false);
  const { classes } = props;
  const { callStarted, ameraWebrtcClient, localVideoRef, localStream } = props;

  /**
   *  handler to send files.  break into slices to stay below websocket max length of abou 70k
   *  all files are URI base64 encoded, so we don't actually care if binary of text
   * @param {*} files
   */
  const sendXfiles = async (event) => {
    const files = event.target.files;

    for (let j = 0; j < files.length; ++j) {
      const file = files[j];
      let size = file.size;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        let chunksize = 50000;
        let nextslice, gotmore;
        let realjuju = reader.result.split(',')[1];

        for (let i = 0; i < realjuju.length; i += chunksize) {
          nextslice = realjuju.slice(i, i + chunksize);
          gotmore = nextslice.length === chunksize;
          let filedata = {
            name: file.name,
            type: file.type,
            length: size,
            index: i,
            more: gotmore,
            verbose: !gotmore,
            display: !gotmore, // don't log each packet
            rawbytes: nextslice,
          };
          ameraWebrtcClient.sendUserMessage({ type: 'xferfile', filedata });
        }
      };
      reader.onerror = function () {
        console.log(reader.error);
      };
    }
  };

  const shareDisplayStop = () => {
    if (!displayStream) return;
    let videoTrack = displayStream.getVideoTracks()[0];
    videoTrack.stop();
    if (localVideoRef && localVideoRef.current)
      localVideoRef.current.srcObj = localStream;
    videoTrack = localStream.getVideoTracks()[0];
    let sender = ameraWebrtcClient.connection.getSenders().find(function (s) {
      return s.track.kind === videoTrack.kind;
    });
    sender.replaceTrack(videoTrack);
  };

  useEffect(() => {
    const handleShareDisplay = async () => {
      if (shareDisplay === true) {
        displayStream = await startDisplay({
          video: { cursor: 'always' },
          audio: true,
        });

        if (displayStream !== null) {
          displayStream.addEventListener('inactive', shareDisplayStop, false);
          displayStream.addEventListener(
            'removetrack',
            shareDisplayStop,
            false
          );

          // for the webrtc connection, we are going to replace video track, but leave audio as selected (display just has video)
          localVideoRef.current.srcObject = displayStream;
          let videoTrack = displayStream.getVideoTracks()[0];
          let sender = ameraWebrtcClient.connection
            .getSenders()
            .find(function (s) {
              return s.track.kind === videoTrack.kind;
            });
          sender.replaceTrack(videoTrack);
          // from https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpSender/replaceTrack
        }
      } else {
        shareDisplayStop();
      }
    };
    handleShareDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareDisplay]);

  return (
    <Grid item md={4} sm={12} className="layout-bottom">
      <div className={classes.callControlWrapper}>
        <div>
          <Button
            variant="contained"
            className={classes.shareDisplay}
            component="span"
            disabled={!callStarted}
            onClick={() => {
              setShareDisplay((prev) => !prev);
            }}
          >
            {shareDisplay ? 'Stop Sharing' : 'Share Display'}
          </Button>
        </div>
        <div className={classes.fileInputWrapper}>
          <input
            className={classes.fileInput}
            id="contained-button-file"
            multiple
            onChange={sendXfiles}
            type="file"
            disabled={!callStarted}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              className={classes.sendFile}
              component="span"
              disabled={!callStarted}
            >
              Send file
            </Button>
          </label>
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  localStream: state.one2onevcall.localStream,
  // ameraWebrtcClient: state.one2onevcall.ameraWebrtcClient,
  callStarted: state.one2onevcall.callStarted,
});

export default connect(mapStateToProps)(injectIntl(MainFeature));
