import {
  IS_LOADING_MEMBER_INVITE,
  SET_INVITE_ALERT,
  REMOVE_INVITE_ALERT,
} from '../actionTypes/invite';

// The initial state of the Invite
export const initialState = {
  loading: false,
  inviteAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const invite = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING_MEMBER_INVITE:
      return {
        ...state,
        loading: true,
      };
    case SET_INVITE_ALERT:
      return {
        ...state,
        loading: false,
        inviteAlert: action.payload,
      };
    case REMOVE_INVITE_ALERT:
      return {
        ...state,
        inviteAlert: {
          show: false,
          variant: 'success',
          message: '',
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default invite;
