import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompletedChip from '../CompletedChip';
import { withStyles } from '@material-ui/core/styles';

const AmeraLinearProgress = withStyles(() => ({
  root: {
    borderRadius: 3,
    height: 6,
    background: '#E0E0E0',
  },
  bar: { borderRadius: 3, backgroundColor: 'rgba(40, 195, 69,0.5)' },
  bar1Buffer: { borderRadius: 3, backgroundColor: 'rgba(40, 195, 69,1)' },
  dashed: { display: 'none' },
}))(LinearProgress);

const ProjectProgress = (props) => {
  const { completed, inProgress, total } = props;

  let content;

  if (total === 0) {
    content = <Typography variant="body2">No tasks defined</Typography>;
  } else {
    const completedPercentage = (completed / total) * 100;
    const inProgressPercentage = ((completed + inProgress) / total) * 100;
    if (completedPercentage < 100) {
      content = (
        <>
          <Typography variant="caption">{`${completed}/${total} tasks completed`}</Typography>
          <AmeraLinearProgress
            variant="buffer"
            color="primary"
            value={completedPercentage}
            valueBuffer={inProgressPercentage}
          />
        </>
      );
    } else {
      content = <CompletedChip />;
    }
  }

  return content;
};

export default ProjectProgress;
