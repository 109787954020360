import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ContactPreview from '../../../components/ContactPreview';
import ProjectProgress from './ProjectProgress';
import Grid from '@material-ui/core/Grid';
import ReportsButton from './ReportsButton';
import ViewButton from './ViewButton';
import EditButton from './EditButton';
import { capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';
import { defaultDateTimeZone } from '../../../utils/calendar';

const ProjectRow = (props) => {
  const { project } = props;
  const history = useHistory();

  const {
    company,
    project_title,
    project_type,
    ownership,
    start_date,
    completed_tasks,
    in_progress_tasks,
    project_members,
    active_tasks,
  } = project;

  const handleViewClick = () => {
    history.push(
      `${process.env.PUBLIC_URL}/projects/project-details/${project.project_id}`
    );
  };
  const handleReportsClick = () => {};

  return (
    <TableRow hover>
      <TableCell component="th" scope="row">
        {company.name}
      </TableCell>
      <TableCell align="left">{project_title}</TableCell>
      <TableCell align="left">{capitalize(project_type)}</TableCell>
      <TableCell align="left" className="project-avatar">
        <ContactPreview
          contactData={project_members.find(
            (pmo) => pmo.project_member_id === ownership.current_owner_id
          )}
          isShowAvatar
        />
      </TableCell>
      <TableCell align="left">
        {defaultDateTimeZone(start_date, 'date')}
      </TableCell>
      <TableCell align="left">
        <ProjectProgress
          completed={completed_tasks}
          inProgress={in_progress_tasks}
          total={active_tasks}
        />
      </TableCell>
      <TableCell align="right">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          spacing={1}
          style={{ textAlign: 'right' }}
        >
          <Grid item>
            <ViewButton onClick={handleViewClick} />
          </Grid>
          <Grid item>
            <ReportsButton onClick={handleReportsClick} />
          </Grid>
          <Grid item>
            <EditButton project={project} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default ProjectRow;
