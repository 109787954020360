import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Popover,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  setting: {
    padding: '6px',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  panel: {
    padding: '10px 10px 10px 16px',
    fontSize: '13px',
    fontWeight: 400,
    '& .MuiFormControlLabel-labelPlacementStart': {
      justifyContent: 'space-between',
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const Setting = (props) => {
  const { sortDir, handleSortDir } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleSettingClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        color="primary"
        className={classes.setting}
        onClick={handleSettingClick}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleSettingClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.panel}>
          <RadioGroup
            aria-label="position"
            name="position"
            value={sortDir}
            onChange={handleSortDir}
          >
            <FormControlLabel
              value="ascending"
              control={<Radio color="primary" />}
              label="Sort ascending"
              labelPlacement="start"
            />
            <FormControlLabel
              value="descending"
              control={<Radio color="primary" />}
              label="Sort descending"
              labelPlacement="start"
            />
          </RadioGroup>
        </div>
      </Popover>
    </div>
  );
};

export default Setting;
