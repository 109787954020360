import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '../../components/Forum/Toolbar';
import TopicWriterPreview from '../../components/Forum/TopicWriterPreview';
import { AttachmentIcon } from '../../assets/svg';
import TopicPostEditor from '../../components/Forum/TopicPostEditor';
import TopicPostsRenderer from '../../components/Forum/TopicPostsRenderer';
import { getTopic } from '../../redux/actions/forum';
import * as api from '../../config/api';
import { extractFileName } from '../../utils/file';
import useToolbar from '../../components/Forum/useToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(2),
    color: '#0A1629',
  },
  topicWrapper: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(8),
  },
  title: {
    fontSize: '23px',
    fontWeight: 400,
  },
  main: {
    marginTop: '24px',
  },
  contentWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  content: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#0A1629',
  },
  cover: {
    width: '475px',
    height: '281px',
    marginRight: '16px',
  },
  attachmentWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(8),
  },
  attachment: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 600,
  },
  attachmentButton: {
    width: 'fit-content',
    padding: '6px 12px',
    minWidth: '240px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#eef4ec',
    '& svg': {
      marginRight: theme.spacing(2),
      fill: theme.palette.primary.main,
    },
    marginRight: theme.spacing(2),
  },
  postWrapper: {
    paddingTop: theme.spacing(2),
  },
}));

const Topic = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    searchKey,
    handleSearch,
    searchMemberKey,
    handleSearchMemberKey,
    selectedMembers,
    handleSelectMember,
    sortDir,
    handleSortDir,
  } = useToolbar();

  const { topic_id } = useParams();
  const { topic, posts } = useSelector((state) => ({
    topic: state.forum.topic || {
      attachments: [],
    },
    posts: state.forum.posts || [],
  }));

  useEffect(() => {
    if (topic_id) {
      dispatch(getTopic(topic_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic_id]);

  console.log('topic.topic_content', topic.topic_content);

  return (
    <div className={classes.root}>
      <Toolbar
        handleSearch={handleSearch}
        searchKey={searchKey}
        searchMemberKey={searchMemberKey}
        handleSearchMemberKey={handleSearchMemberKey}
        selectedMembers={selectedMembers}
        handleSelectMember={handleSelectMember}
        sortDir={sortDir}
        handleSortDir={handleSortDir}
      />
      {topic && (
        <div className={classes.topicWrapper}>
          <p className={classes.title}>{topic.topic_title}</p>
          <TopicWriterPreview
            firstName={topic.first_name}
            lastName={topic.last_name}
            member_id={topic.member_id}
            create_date={
              topic.craete_date && format(new Date(topic.create_date), 'Pp')
            }
            biography={topic.biography}
          />
          <div className={classes.main}>
            <div className={classes.contentWrapper}>
              <img
                src={`${api.apiUrl}${topic.cover_image_url}`}
                className={classes.cover}
                alt="cool"
              />
              <div
                className={classes.content}
                dangerouslySetInnerHTML={{ __html: topic.topic_content }}
              />
            </div>
            <div className={classes.attachmentWrapper}>
              {topic.attachments.map((attachment) => (
                <a
                  key={attachment.forum_media_id}
                  className={classes.attachment}
                  href={`${api.apiUrl}${attachment.file_path}`}
                  download
                >
                  <div className={classes.attachmentButton}>
                    <AttachmentIcon />
                    <span>{extractFileName(attachment.file_path)}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <TopicPostEditor type="comment" topic_id={topic_id} />
          <div className={classes.postWrapper}>
            <TopicPostsRenderer
              posts={posts}
              topic_id={topic_id}
              selectedMembers={selectedMembers}
              sortDir={sortDir}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Topic;
