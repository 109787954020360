import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import LargeVideo from '../../../components/MediaStreaming/Scheduler/largeVideo';
import SmallCarousel from './smallCarousel';

const breakpointsSmall = [
  {
    count: 3,
    width: 1440,
  },
  {
    count: 2,
    width: 1280,
  },
  {
    count: 1,
    width: 960,
  },
  {
    count: 2,
    width: 768,
  },
  {
    count: 1,
    width: 576,
  },
];

const breakpointsLarge = [
  {
    count: 4,
    width: 1440,
  },
  {
    count: 3,
    width: 1280,
  },
  {
    count: 2,
    width: 960,
  },
  {
    count: 2,
    width: 768,
  },
  {
    count: 1,
    width: 576,
  },
];

const carousels = [
  {
    breakpoints: breakpointsSmall,
    type: 1,
  },
  {
    breakpoints: breakpointsSmall,
    type: 2,
  },
  {
    breakpoints: breakpointsLarge,
    type: 3,
  },
  {
    breakpoints: breakpointsLarge,
    type: 4,
  },
  {
    breakpoints: breakpointsLarge,
    type: 5,
  },
];

const SchedulerWidget = (props) => {
  const { handleSettingOpen, defaultSettings } = props;
  const streamTypes = useSelector((state) => state.stream.streamTypes);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={8} lg={5}>
        <LargeVideo />
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={7}>
        <SmallCarousel
          title={
            streamTypes && streamTypes.length
              ? streamTypes[defaultSettings[1]]['name']
              : ''
          }
          data={carousels[0]}
          handleSettingOpen={handleSettingOpen}
        />
        <SmallCarousel
          title={
            streamTypes && streamTypes.length
              ? streamTypes[defaultSettings[2]]['name']
              : ''
          }
          data={carousels[1]}
          handleSettingOpen={handleSettingOpen}
        />
      </Grid>
      <Grid item xs={12}>
        <SmallCarousel
          title={
            streamTypes && streamTypes.length
              ? streamTypes[defaultSettings[3]]['name']
              : ''
          }
          data={carousels[2]}
          handleSettingOpen={handleSettingOpen}
        />
      </Grid>
      <Grid item xs={12}>
        <SmallCarousel
          title={
            streamTypes && streamTypes.length
              ? streamTypes[defaultSettings[4]]['name']
              : ''
          }
          data={carousels[3]}
          handleSettingOpen={handleSettingOpen}
        />
      </Grid>
      <Grid item xs={12}>
        <SmallCarousel
          title={
            streamTypes && streamTypes.length
              ? streamTypes[defaultSettings[5]]['name']
              : ''
          }
          data={carousels[4]}
          handleSettingOpen={handleSettingOpen}
        />
      </Grid>
    </Grid>
  );
};

export default SchedulerWidget;
