import { GET, apiUrl } from '../../config/api';

import {
  //FETCH_PROJECTS_START,
  //FETCH_PROJECTS_SUCCESS,
  //FETCH_PROJECTS_ERROR,
  PROJECT_CREATION_START,
  UPDATE_PROJECT_DEFINITION,
  SUBMIT_PROJECT_DEFINITION,
} from '../actionTypes/projects';

const endpoints = {
  projects: `${apiUrl}/projects`,
  project: `${apiUrl}/project`,
};

// TODO: Confirm from hamza and remove this code because its unused
// class AmeraProjectsApi {
//   catchErrorHandler = (error) => (dispatch) => {
//     dispatch({ type: FETCH_PROJECTS_ERROR, payload: error });
//   };

//   getProjects = () => (dispatch) => {
//     dispatch({ type: FETCH_PROJECTS_START });
//     GET(endpoints.projects)
//       .then((res) => {
//         dispatch({ type: FETCH_PROJECTS_SUCCESS, payload: res.data });
//       })
//       .catch((error) => this.catchErrorHandler(error));
//   };

//   projectCreationStart = () => {
//     this.getProjectDefinition()
//   }

// setProjectDefinition = (projectDefinition) => {
//   return {
//     type: SET_PROJECT_DEFINITION,
//     payload: projectDefinition
//   }
// }

// getProjectDefinition = (projectId = null) => {
//   let url = `${endpoints.project}/projectId`
//   return (dispatch) => {
//     if(projectId) {
//       dispatch({
//         type: PROJECT_CREATION_START
//       })
//     }
//     GET(url).then(res => {
//       let projectDefinition = res.data
//       dispatch(this.setProjectDefinition(projectDefinition))
//     })
//   }
// }
// }

// const ProjectsApi = new AmeraProjectsApi();
// Object.freeze(ProjectsApi);
// export { ProjectsApi };

export const getProjectDefinition = (projectId = null) => {
  let url = `${endpoints.project}/projectId`;
  return (dispatch) => {
    if (!projectId) {
      dispatch({
        type: PROJECT_CREATION_START,
      });
    } else {
      GET(url).then((res) => {
        let projectDefinition = res.data;
        dispatch(this.setProjectDefinition(projectDefinition));
      });
    }
  };
};

export const projectCreationStart = () => {
  return (dispatch) => {
    dispatch(getProjectDefinition());
  };
};

export const updateProjectDefinition = (projectDefinition) => {
  return {
    type: UPDATE_PROJECT_DEFINITION,
    payload: projectDefinition,
  };
};

export const submitProjectDefinition = (projectDefinition) => {
  const payload = {
    currentStep: {
      preStep: 'projectDefinition',
      nextStep: 'assignProjectRoles',
      key: 'selectTeam',
      displayName: 'Select Team',
      data: projectDefinition,
    },
    stepIndex: 1,
  };
  return {
    type: SUBMIT_PROJECT_DEFINITION,
    payload: payload,
  };
};
