import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  add,
  isBefore,
  isEqual,
  isValid,
  differenceInMinutes,
  sub,
  format,
} from 'date-fns';

// import moment start 'moment';
import Modal from '../modal';
import EventModalActions from './EventModalActions';
import DropDownTImeInput from './DropDownTImeInput';
import DurationSelect from './DurationSelect';
import {
  getDateByTime,
  eventTypeText,
  defaultSetting,
} from '../../../utils/calendar';
import {
  postPageSettings,
  putPageSettings,
} from '../../../redux/actions/member';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
  },
}));

const EventSettingsModal = (props) => {
  const { onClose, open, data } = props;

  const [settings, setSettings] = useState({});

  const dispatch = useDispatch();
  const classes = useStyles();

  // initiate settings
  useEffect(() => {
    if (!settings || !Object.keys(settings).length) {
      const setting = {};

      Object.keys(defaultSetting).map((key) => {
        const value = defaultSetting[key];
        const events = data && data['extra_settings'];

        setting[key] = {};
        let from, to;

        if (
          events &&
          events[key] &&
          events[key]['start'] &&
          events[key]['end']
        ) {
          from = events[key]['start'];
          to = events[key]['end'];
        } else {
          from = value['start'];
          to = value['end'];
        }

        setting[key]['start'] = getDateByTime(from);
        setting[key]['end'] = getDateByTime(to);

        return true;
      });

      setSettings(setting);
    }
  }, [data, settings]);

  const handleDateChange = (date, type, id) => {
    if (!isValid(date)) return;

    setSettings((ps) => {
      if (id.includes('start')) {
        const prevDifferenceMinutes = differenceInMinutes(
          ps[type]['end'],
          ps[type]['start']
        );

        return {
          ...ps,
          [type]: {
            start: date,
            end: add(date, { minutes: prevDifferenceMinutes }),
          },
        };
      } else if (id.includes('end')) {
        if (
          isBefore(date, ps[type]['start']) ||
          isEqual(date, ps[type]['start'])
        ) {
          const movedStart = sub(date, { hours: 1 });
          return {
            ...ps,
            [type]: {
              start: movedStart,
              end: date,
            },
          };
        } else {
          return {
            ...ps,
            [type]: {
              start: ps[type]['start'],
              end: date,
            },
          };
        }
      }
    });
  };

  const handleDurationChange = (date, type) => {
    setSettings((ps) => ({
      ...ps,
      [type]: {
        start: ps[type]['start'],
        end: date,
      },
    }));
  };

  const handleSubmit = async () => {
    let formData = new FormData();

    if (data && Object.keys(data).length) {
      Object.keys(data).map((key) => {
        formData.set(key, data[key]);
        return true;
      });
    } else {
      formData.set('view_type', 'month');
      formData.set('page_size', 25);
      formData.set('page_type', 'Calendar');
      formData.set('sort_order', '');
    }

    const settingsInfo = {};

    Object.keys(settings).map((key) => {
      settingsInfo[key] = settings[key];
      settingsInfo[key]['start'] = format(settingsInfo[key]['start'], 'HH:mm');
      settingsInfo[key]['end'] = format(settingsInfo[key]['end'], 'HH:mm');
      return true;
    });

    formData.set('extra_settings', JSON.stringify(settingsInfo));

    if (formData.get('id')) {
      dispatch(putPageSettings(formData));
    } else {
      dispatch(postPageSettings(formData));
    }

    onClose();
  };

  const ModalContent = () => (
    <Grid container spacing={3}>
      {Object.keys(settings).map((key, index) => {
        const start = settings[key]['start'];
        const end = settings[key]['end'];
        const startTimeID = `${key}-start`;
        const durationID = `${key}-duration`;
        const endTimeID = `${key}-end`;

        return (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            key={index}
            alignItems="center"
          >
            <Grid item md={3} xs={12}>
              <label className={classes.label}>{eventTypeText(key)}</label>
            </Grid>
            <Grid item md={3} xs={12}>
              <DropDownTImeInput
                date={start}
                id={startTimeID}
                label="start"
                onChange={(value) => handleDateChange(value, key, startTimeID)}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <DurationSelect
                start={start}
                end={end}
                handleChangeDuration={(value) =>
                  handleDurationChange(value, key)
                }
                id={durationID}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <DropDownTImeInput
                date={end}
                id={endTimeID}
                label="end"
                onChange={(value) => handleDateChange(value, key, endTimeID)}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        title="Event Settings"
        ModalContent={ModalContent}
        ModalAction={() => (
          <EventModalActions
            closeLabel="Cancel"
            onClose={onClose}
            //   isLoading={}
            loadingLabel="..."
            actionLabel="Save"
            onAction={handleSubmit}
          />
        )}
        className="event-settings-modal"
      ></Modal>
    </div>
  );
};

export default EventSettingsModal;
