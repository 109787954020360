import loginImg from '../../assets/img/login-image.png';

const authFormHeader = (theme) => ({
  welcomeSection: {
    width: '53%',
    backgroundImage: `url("${loginImg}")`,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  messageContainer: {
    width: '100%',
    height: '100%',
    opacity: '90%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '26% 0% 0% 16%',
  },
  largeMessage: {
    fontSize: '44px',
    fontFamily: 'Roboto-Medium',
  },
  mediumMessage: {
    fontSize: '32px',
    fontFamily: 'Helvetica Neue',
    marginTop: '2em',
  },
});

export default authFormHeader;
