import React from 'react';
import { parseISO, format } from 'date-fns';
import { capitalize } from 'lodash';

const MemberCard = (props) => {
  const {
    member_id,
    first_name,
    last_name,
    shared_date,
    job_title,
    department,
    company_name,
    handleMemberClick,
  } = props;

  return (
    <div className="member-tab">
      <div className="member-name-line">
        <span
          className={`unit ${!!handleMemberClick ? 'link' : ''}`}
          onClick={() =>
            !!handleMemberClick ? handleMemberClick(member_id) : null
          }
        >
          {`${capitalize(first_name)} ${capitalize(last_name)}`}
        </span>
        <span className="unit"> - </span>
        <span className="unit">{`Date shared: ${format(
          parseISO(shared_date),
          'MM/dd/yyyy'
        )}`}</span>
      </div>
      <div className="contact-line">
        {`${job_title} at ${department}, ${company_name}`}
      </div>
    </div>
  );
};

export default MemberCard;
