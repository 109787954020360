import React from 'react';

const JPAddressLine = ({ data }) => {
  // const dummyData = {
  //   admin_area_1: 'Osaka', //Prefecture
  //   admin_area_2: 'Osaka', // Municipality/City
  //   locality: 'Yodogawa Ward', //District
  //   sub_locality: '', // Town/village
  //   street_address_1: 'Miyahara',
  //   street_address_2: 'Shin Osaka Hankyu Building 1-chōme-1',
  //   postal_code: '532-0003',
  //   countryName: 'Japan',
  // };
  // console.debug('data', data);

  return (
    <>
      {!!data.description && data.description !== data.name && (
        <>
          <strong>{data.description}</strong>
          <br />
        </>
      )}

      {!!data.name && (
        <>
          <strong>{data.name}</strong>
          <br />
        </>
      )}

      {(!!data.street_address_2 || !!data.street_address_1) && (
        <>
          <span>
            {!!data.street_address_2 ? `${data.street_address_2}, ` : ''}
            {data.street_address_1}
          </span>
          <br />
        </>
      )}

      {(!!data.locality || data.admin_area_1 || data.postal_code) && (
        <>
          <span>
            {!!data.locality ? `${data.locality},` : ''} {data.admin_area_1}{' '}
            {data.postal_code}
          </span>
          <br />
        </>
      )}
      <span>{data.country_name}</span>
    </>
  );
};

export default JPAddressLine;
