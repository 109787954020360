import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import IconButton from '@material-ui/core/IconButton';
// import clockPng from '../../../assets/img/clock.png';
// import goPng from '../../../assets/img/go.png';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Sound from 'react-sound';
import ringtone from '../../../assets/media/alarm_gentle.mp3';
import { sortBy, last } from 'lodash';
import {
  answerContactVideoAudioRequest,
  answerGroupVideoConference,
  answerGroupVideoCall,
  isEventContact,
  isEventGroup,
} from '../../../redux/actions/event';
import StopIcon from '@material-ui/icons/Stop';
import { AudioCallRingIcon } from '../../../assets/svg';
import { formatDuration } from '../../../utils/dateFormat';
import { NOTIFICATION_EVENT_TYPES } from '../../../utils/general';

let callData,
  interval = null;

const CallNotification = (props) => {
  const { calls, member_id, twilioConnection, callee, dispatch } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [playStatus, setPlayStatus] = useState('PLAYING');

  const [isOnTwilioCall, setIsOnTwilioCall] = useState(false);
  const [callDuration, setCallDuration] = useState(-1);

  const increaseCallDuration = () => {
    setCallDuration((ps) => ps + 1);
  };

  // React.useEffect(() => {
  //   if (twilioDevice) {
  //     twilioDevice.on('connect', (conn) => {

  //       // console.log('header:::   connection conn', conn);
  //       console.log("connection", conn.status());
  //       if (conn.status() === 'open') {
  //         setIsOnTwilioCall(true);
  //         interval = setInterval(() => {
  //           increaseCallDuration();
  //         }, 1000);
  //       }
  //       if (conn.status() === 'closed') {
  //         if (interval) clearInterval(interval);
  //         setCallDuration(-1);
  //       }
  //     });
  //     twilioDevice.on('disconnect', (conn) => {
  //       console.log('header:::   device was disconnected', conn);
  //       if (interval) clearInterval(interval);
  //       setCallDuration(-1);
  //       setIsOnTwilioCall(false);
  //       // setPhoneCallAvailable(true);
  //       // dispatch(setTwilioCall(false));
  //     });
  //   }
  // }, [twilioDevice]);

  React.useEffect(() => {
    if (!twilioConnection) return;
    twilioConnection.on('accept', (conn) => {
      console.log('connection is accepted:::', conn);
      setIsOnTwilioCall(true);
      interval = setInterval(() => {
        increaseCallDuration();
      }, 1000);
    });

    twilioConnection.on('ringing', (conn) => {
      console.log('ringing:::', conn);
      setIsOnTwilioCall(true);
      interval = setInterval(() => {
        increaseCallDuration();
      }, 1000);
    });

    twilioConnection.on('open', (conn) => {
      setIsOnTwilioCall(true);
      interval = setInterval(() => {
        increaseCallDuration();
      }, 1000);
    });

    twilioConnection.on('disconnect', (conn) => {
      if (interval) clearInterval(interval);
      setCallDuration(-1);
      setIsOnTwilioCall(false);
    });
    twilioConnection.on('cancel', (conn) => {
      if (interval) clearInterval(interval);
      setCallDuration(-1);
      setIsOnTwilioCall(false);
    });
    twilioConnection.on('reject', (conn) => {
      if (interval) clearInterval(interval);
      setCallDuration(-1);
      setIsOnTwilioCall(false);
    });
  }, [twilioConnection]);

  const isIncomingCall = calls.length > 0;
  callData = last(sortBy(calls, (c) => c.start_time));

  const handleCallAction = (event, member_id) => {
    const { type } = callData;

    const answerData = {
      reply_type: event.target.id,
      member_id,
    };

    switch (type) {
      case NOTIFICATION_EVENT_TYPES.REQUEST.GROUP.VIDEO_CALL:
        dispatch(answerGroupVideoCall(callData, answerData));
        // dispatch(callToVoiceMail(callData));
        break;
      case NOTIFICATION_EVENT_TYPES.REQUEST.GROUP.VIDEO_CONFERENCE:
        dispatch(answerGroupVideoConference(callData, answerData));
        break;
      case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.AUDIO_CALL:
      case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.VIDEO_CALL:
      case NOTIFICATION_EVENT_TYPES.REQUEST.CONTACT.VIDEO_CONFERENCE:
        dispatch(answerContactVideoAudioRequest(callData, answerData));
        // dispatch(callToVoiceMail(callData));
        break;
      default:
        throw new Error('Invalid event type');
    }

    setAnchorEl(null);
  };

  let label, containerClasses, actionBox;

  if (isIncomingCall) {
    // console.log('Will play');
    // play();
    const { type, caller_name, group_name } = callData;
    if (isEventContact(type)) {
      label = `${caller_name} calling`;
    } else if (isEventGroup(type)) {
      label = `${group_name} conference call`;
    } else {
      throw new Error('Invalid event type');
    }
    containerClasses = 'call-notification upcoming';
    actionBox = (
      <span className="action-box">
        <WatchLaterIcon />
        {/* <img src={clockPng} alt="clock" /> */}
        <span className="time">{format(new Date(), 'KK:mm bb')}</span>
        {/* <img src={goPng} alt="go" /> */}
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <PlayCircleFilledIcon className="go-btn" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          onClick={(event) => {
            handleCallAction(event, member_id);
          }}
        >
          <MenuItem id="accept" key="accept">
            Accept
          </MenuItem>
          <MenuItem id="decline" key="decline">
            Decline
          </MenuItem>
        </Menu>
      </span>
    );
  } else {
    label = 'No notifications';
    containerClasses = 'call-notification no-calls';
    actionBox = null;
  }

  return (
    <div>
      <div className={containerClasses}>
        {isIncomingCall && (
          <Sound
            url={ringtone}
            playStatus={playStatus}
            volume={75}
            onFinishedPlaying={() => setPlayStatus('STOPPED')}
          />
        )}
        <div className="label">{label}</div>
        {actionBox}
      </div>
      {isOnTwilioCall && (
        <div className="phone-call">
          <AudioCallRingIcon className="phone-icon" />
          {/* <span className="callee-name">{`${callDuration>=0?'calling ':'call with'}`} {callee}</span> */}
          <span className="callee-name">call with {callee}</span>

          {callDuration >= 0 && (
            <span className="call-duration">
              {formatDuration(callDuration)}
            </span>
          )}
          <IconButton
            onClick={() => {
              if (twilioConnection) {
                twilioConnection.disconnect();
              }
            }}
          >
            <StopIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

CallNotification.propTypes = {
  dispatch: PropTypes.func,
  calls: PropTypes.array,
  member_id: PropTypes.any,
};

CallNotification.defaultProps = {
  dispatch: null,
  calls: [],
};

const mapStateToProps = (state) => ({
  calls: state.event.incomingCalls,
  callee: state.event.callee,
  member_id: state.member.member.member_id,
  twilioConnection: state.event.twilioConnection,
});

export default connect(mapStateToProps)(CallNotification);
