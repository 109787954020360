import React, { useState, useEffect } from 'react';

import Navigator from './Navigator';

import navItems, { messages as navMessages } from './navItems';

export const messages = { ...navMessages };

const Sidebar = ({ location: { pathname } }) => {
  const defaultNavID = navItems[0].id;
  const [activeNav, setActiveNav] = useState(defaultNavID || '');

  useEffect(() => {
    const navItemsMatched = navItems.filter((ni) => pathname.includes(ni.to));
    if (navItemsMatched.length === 1) {
      setActiveNav(navItemsMatched[0].id);
    } else if (navItemsMatched.length > 1) {
      //if more than one matches then get exact one e.g /contacts or /contacts/chat
      const exactMatchObj = navItemsMatched.filter((ni) => ni.to === pathname);
      setActiveNav(exactMatchObj[0].id);
    } else {
      setActiveNav(defaultNavID);
    }
  }, [pathname, defaultNavID]);

  return (
    <div className="sidebar">
      <Navigator
        navItems={navItems}
        activeNav={activeNav}
        setActiveNav={setActiveNav}
      />
    </div>
  );
};

export default Sidebar;
