import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ViewMileStoneSidePane from '../Modals/ViewMileStoneSidePane';

import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import MaterialAvatar from '../../../../components/MaterialAvatar';
// import moment from 'moment';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { defaultDateTimeZone } from '../../../../utils/calendar';

const MileStone = ({
  projectId,
  parent_id,
  element_id,
  milestone_description,
  members,
  rate,
  currencyId,
  comments,
}) => {
  const {
    title: milestone_title,
    project_member_id: member_id,
    status_history: element_status,
    due_date,
  } = useSelector((state) => {
    const project = state.project.projects.filter(
      (p) => p.project_id === +projectId
    )[0];
    const projectElement = project.project_elements.filter(
      (pe) => pe.project_element_id === element_id
    )[0];

    return projectElement;
  });
  const useStyles = makeStyles((theme) => ({
    milestone1: {
      marginTop: '5px',
      marginBottom: '5px',
      display: 'flex',
      cursor: 'pointer',
      width: '100%',
    },
    checkIconArea: {
      display: 'flex',
    },
    checkIconComplete: {
      color: '#94c03d',
      width: '18px',
      height: '18px',
      top: '3px',
      position: 'relative',
    },
    checkIcon: {
      width: '18px',
      height: '18px',
      top: '3px',
      position: 'relative',
    },
    milestoneName: {
      marginLeft: '5px',
      color: 'gray',
    },
    profileArea: {
      display: 'flex',
    },
    avatarIcon: {
      width: '25px',
      height: '25px',
    },
    avatarName: {
      marginLeft: '5px',
      color: 'gray',
    },
    dateSpan: {
      marginLeft: '5px',
      color: 'gray',
    },
    totalHourSpan: {
      marginLeft: '5px',
      color: 'gray',
    },
  }));
  const classes = useStyles();

  const [state, setState] = useState(false);
  const handleOpenViewMileStone = () => {
    setState(true);
  };
  const handleCloseViewMileStone = () => {
    setState(false);
  };

  const lastItem = element_status && element_status[element_status.length - 1];
  const status = lastItem && lastItem.element_status;
  //console.log('Element status', element_id, status)
  return (
    <>
      <div
        onClick={handleOpenViewMileStone}
        className={`${classes.milestone1} task-row-items ${status.replace(
          ' ',
          ''
        )}`}
      >
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <div className={classes.checkIconArea}>
              {status === 'complete' ? (
                <>
                  <CheckCircleIcon
                    className={`${classes.checkIconComplete} task-check-complete`}
                  />
                </>
              ) : (
                <>
                  <CheckCircleOutlineOutlinedIcon
                    className={`${classes.checkIcon} task-check`}
                  />
                </>
              )}

              <p className={`${classes.milestoneName} task-name`}>
                {' '}
                {milestone_title}
              </p>
            </div>
          </Grid>

          <Grid item xs={2}>
            {members &&
              members.map((member, index) => (
                <div key={index} id={index}>
                  {member_id === member.project_member_id && (
                    <div className="profile-area">
                      {
                        <MaterialAvatar
                          member_id={member.contact_member_id}
                          src={member.amera_avatar_url}
                          classes="task-row"
                          firstName={member.first_name}
                          lastName={member.last_name}
                        />
                      }
                      <p className={`${classes.avatarName} task-avatar`}>
                        {member.first_name} {member.last_name}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </Grid>

          <Grid item xs={2}>
            <div>
              <p className="task-due-date">
                Due:{' '}
                <span>
                  {due_date ? (
                    // moment(due_date).format('DD-MM-YYYY')
                    defaultDateTimeZone(due_date, 'date')
                  ) : (
                    <span>DD_MM_YY</span>
                  )}
                </span>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <div id={element_id}>
        <ViewMileStoneSidePane
          projectId={projectId}
          parent_id={parent_id}
          element_id={element_id}
          state={state}
          handleClose={handleCloseViewMileStone}
          milestone_title={milestone_title}
          milestone_description={milestone_description}
          member_id={member_id}
          members={members}
          due_date={due_date}
          rate={rate}
          currencyId={currencyId}
          comments={comments}
          element_status={element_status}
        />
      </div>
    </>
  );
};

export default MileStone;
