import React, { useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import AmeraSelect from '../../components/AmeraSelect';
import { loadCompanies } from '../../redux/actions/company';
import { useDispatch } from 'react-redux';

const CompanySelect = (props) => {
  const {
    companies,
    error,
    isLoading,
    company,
    handleChange,
    multiple,
  } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCompanies({ getAll: true }));
  }, [dispatch]);

  return (
    <AmeraSelect
      label="Company"
      value={(company || {}).id || []}
      onChange={handleChange}
      error={error}
      multiple={multiple}
    >
      {companies && isLoading?.length ? (
        <div
          style={{
            display: 'flex',
            direction: 'row',
            justifyContent: 'space-around',
          }}
        >
          <CircularProgress size={20} color="primary" />
          <Typography variant="body2">Loading..</Typography>
        </div>
      ) : companies?.length > 0 ? (
        companies.map((c) => (
          <MenuItem
            key={c.id}
            selected={c.id === (company || {}).id}
            value={c.id}
          >
            {c.name}
          </MenuItem>
        ))
      ) : (
        <Typography variant="body2">No companies found</Typography>
      )}
    </AmeraSelect>
  );
};

const mapStateToProps = (state) => ({
  companies: state.company.companyList,
  error: state.company.error,
  isLoading: state.company.isLoading,
});

export default connect(mapStateToProps)(CompanySelect);
