import React, { useState, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDirectoryInMain } from '../../../utils/electron';
import Modal from '../../../components/Modal/modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FolderIcon from '@material-ui/icons/Folder';
import ClearIcon from '@material-ui/icons/Clear';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { decryptLocalFile } from '../../../redux/actions/fileshare';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: theme.spacing(1),
    width: '100%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
  selectedFilesCount: {
    display: 'block',
    margin: '1rem 0',
  },
}));

const DecryptFile = (props) => {
  const { show, close, dispatch, isDecryptLoading } = props;
  const [destinationDirectory, setDestinationDirectory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const prevState = usePrevious({ loading });
  const classes = useStyles();

  const onClose = useCallback(() => {
    setDestinationDirectory(null);
    setSelectedFiles(null);
    setLoading(false);
    close();
  }, [close]);

  useEffect(() => {
    setLoading(isDecryptLoading);
  }, [isDecryptLoading]);

  useEffect(() => {
    if (prevState && loading === false && prevState.loading === true) {
      onClose();
    }
  }, [loading, onClose, prevState]);

  const resetState = () => {
    setLoading(false);
  };
  const handleSelectFiles = (event) => {
    resetState();
    let selectedFiles = event.target.files;
    setSelectedFiles(selectedFiles);
  };

  const handleDecryptClick = (event) => {
    event.preventDefault();
    dispatch(decryptLocalFile(destinationDirectory, selectedFiles));
  };

  const handleSelectDirectory = (event) => {
    event.preventDefault();

    getDirectoryInMain().then(
      (directory) => {
        console.log('WE GOT A DIRECTORY, SETTING IT', directory);
        setDestinationDirectory(directory);
        console.log('DIRECTORY SET', destinationDirectory);
      },
      (err) => {
        console.log(err || err.stack);
      }
    );
  };

  const handleCrearDestinationClick = () => {
    setDestinationDirectory(null);
  };

  const ModalContent = () => (
    <>
      <label htmlFor="select-local-file">
        <input
          style={{ display: 'none' }}
          id="select-local-file"
          name="select-local-file"
          type="file"
          multiple="multiple"
          onChange={handleSelectFiles}
        />
        <Button
          color="secondary"
          variant="contained"
          component="span"
          // className="upload_btn"
        >
          Select Files To Decrypt
        </Button>
      </label>

      {selectedFiles && selectedFiles.length > 0 && (
        <>
          <span className={classes.selectedFilesCount}>
            {`${selectedFiles.length} ${
              selectedFiles.length > 1 ? 'files' : 'file'
            } selected`}
          </span>
          <Button
            color="secondary"
            variant="contained"
            component="span"
            className="upload_btn"
            onClick={handleSelectDirectory}
          >
            {destinationDirectory === null
              ? 'Set Destination..'
              : 'Change Destination'}
          </Button>
        </>
      )}

      {destinationDirectory && (
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={destinationDirectory} />
            <ListItemSecondaryAction>
              <IconButton onClick={handleCrearDestinationClick}>
                <ClearIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
    </>
  );

  const ModalAction = () => {
    return loading ? (
      <>
        <Button variant="contained" className={classes.submitBtn} disabled>
          <CircularProgress size={'1.5em'} className={classes.buttonProgress} />
          Decrypting...
        </Button>
      </>
    ) : (
      <Button
        variant="contained"
        color="primary"
        startIcon={<VpnKeyIcon />}
        disabled={selectedFiles === null || destinationDirectory === null}
        className={classes.submitBtn}
        disableRipple
        onClick={handleDecryptClick}
      >
        Decrypt
      </Button>
    );
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      title="AMERA decrypt Local file"
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="enrypt-local-modal"
    ></Modal>
  );
};

const mapStateToProps = (state) => ({
  isDecryptLoading: state.fileshare.decryptLoading,
});

export default connect(mapStateToProps)(DecryptFile);
