import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TimerIcon from '@material-ui/icons/Timer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SubjectIcon from '@material-ui/icons/Subject';
import GroupIcon from '@material-ui/icons/Group';
import { makeStyles } from '@material-ui/styles';

import Card from './Card';
import LocationPicture from './LocationPicture';
import { getUnstyledHtmlBody } from '../../utils/email';
import {
  defaultDateTimeZone,
  eventDisplayName,
  generateEventInviteListText,
  getInvitedListFilterLoggedInMember,
} from '../../utils/calendar';
import AddressLine from '../AddressLine';

const useStyles = makeStyles((theme) => ({
  // card: {
  //   '& MuiCardHeader-subheader': {
  //     '-webkit-box-orient': 'vertical',
  //     '-webkit-line-clamp': 1,
  //   },
  //   '& MuiCardContent-root': {
  //     overflow: 'hidden'
  //   },

  // },
  upcomingEvent: {
    height: '100%',
    '& .MuiCardHeader-root': {
      paddingBottom: 4,
      paddingTop: 8,
    },

    '& .MuiPaper-root': {
      // @ts-ignore
      paddingTop: theme.spacing(2),
    },
  },

  content: {
    overflow: 'hidden',
    '& li': {
      padding: 0,
    },

    '& .MuiListItem-root': {
      alignItems: 'start',
    },

    '& .MuiListItemText-root >span': {
      fontSize: 14,
      lineHeight: '20px',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
    },

    '& .MuiListItemIcon-root': {
      // @ts-ignore
      minWidth: theme.spacing(3),
      '& svg': {
        // @ts-ignore
        width: theme.spacing(2),
        // @ts-ignore
        height: theme.spacing(2),
        // @ts-ignore
        marginTop: theme.spacing(1),
      },
    },
  },

  eventDateAndTime: {
    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
      padding: 0,
      '& li': {
        width: 'unset',
      },
    },
  },
  button: {
    width: '100%',
    padding: '0px 8px 8px 0',
    textAlign: 'right',
  },
  viewDetail: {
    background: '#fff',
    border: '1px solid #94c03d',
    color: '#94c03d',
    boxShadow: 'none',

    '&:hover': {
      color: '#fff',
      background: '#94c03d',
    },
  },
}));

const UpcomingEventCard = ({ data, member, showDetail }) => {
  const classes = useStyles();

  const title = 'Upcoming Event';

  const handleDetail = () => {
    showDetail(data);
  };

  const event_name = eventDisplayName(data, member.member_id);
  const description = getUnstyledHtmlBody(
    data.event_description ? data.event_description : ''
  );

  const contact = generateEventInviteListText(
    getInvitedListFilterLoggedInMember(data, member.member_id)
  );

  const eventLocation = React.useMemo(() => {
    if (data.event_location && data.event_location.vendor_formatted_address) {
      return <AddressLine data={data.event_location} />;
    }
  }, [data.event_location]);

  const content = (
    <List className={classes.content}>
      <ListItem>
        <LocationPicture data={data} height={50} />
      </ListItem>
      <ListItem className={classes.eventDateAndTime}>
        <List>
          <ListItem
          // className={classes.eventDate}
          >
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
            <ListItemText primary={defaultDateTimeZone(data.start, 'date')} />
          </ListItem>
          <ListItem
          // className={classes.eventTime}
          >
            <ListItemIcon>
              <TimerIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                defaultDateTimeZone(data.start, 'time') +
                ' - ' +
                defaultDateTimeZone(data.end, 'time')
              }
            />
          </ListItem>
        </List>
      </ListItem>
      <ListItem
      // className={classes.eventLocation}
      >
        {eventLocation && (
          <>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary={eventLocation} />
          </>
        )}
      </ListItem>
      <ListItem
      // className={classes.eventContacts}
      >
        {contact && (
          <>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={contact} />
          </>
        )}
      </ListItem>
      <ListItem
      // className={classes.eventDescription}
      >
        {description && (
          <>
            <ListItemIcon>
              <SubjectIcon />
            </ListItemIcon>
            <ListItemText primary={description} />
          </>
        )}
      </ListItem>
    </List>
  );

  const button = (
    <div className={classes.button}>
      <Button
        variant="contained"
        aria-label="detail"
        className={classes.viewDetail}
        onClick={handleDetail}
      >
        View Details
      </Button>
    </div>
  );

  return (
    <div className={classes.upcomingEvent}>
      <Card
        title={title}
        subHeader={event_name}
        content={content}
        buttons={button}
      />
    </div>
  );
};

export default UpcomingEventCard;
