export const GET_MEMBER_EVENTS = 'GET_MEMBER_EVENTS';
// export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const FETCH_ACTIVITIES_START = 'FETCH_ACTIVITIES_START';
export const UPDATE_ACTIVITIES = 'UPDATE_ACTIVITIES';
export const FETCH_ACTIVITIES_FAILED = 'FETCH_ACTIVITIES_FAILED';

export const GET_COMPOSE = 'GET_COMPOSE';
export const INCOMING_CALL = 'INCOMING_CALL';
export const ANSWER_CALL = 'ANSWER_CALL';
export const CALL_REPLY = 'CALL_REPLY';
export const GROUP_CALL_REPLY = 'GROUP_CALL_REPLY';
export const SET_REPLY_MESSAGE = 'SET_REPLY_MESSAGE';
export const SET_CALL_GROUP_ID = 'SET_CALL_GROUP_ID';

export const GET_UPCOMING_EVENTS_SUCCEEDED = 'GET_UPCOMING_EVENTS_SUCCEEDED';
export const GET_UPCOMING_EVENTS_FAILED = 'GET_UPCOMING_EVENTS_FAILED';
export const GET_EVENT_INVITATIONS_SUCCEEDED =
  'GET_EVENT_INVITATIONS_SUCCEEDED';
export const GET_EVENT_INVITATIONS_FAILED = 'GET_EVENT_INVITATIONS_FAILED';

export const REMOVE_NEW_VIDEO_MAIL_NOTIFICATION =
  'REMOVE_NEW_VIDEO_MAIL_NOTIFICATION';
export const SET_TWILIO_CALL = 'SET_TWILIO_CALL';
export const SET_TWILIO_DEVICE = 'SET_TWILIO_DEVICE';
export const SET_TWILIO_CONNECTION = 'SET_TWILIO_CONNECTION';
export const OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL = 'OUTGOING_PHONE_VERIFICATION_STATUS_FOR_MODAL';
export const SET_TWILIO_VERIFICATION_MODAL_STATUS = 'SET_TWILIO_VERIFICATION_MODAL_STATUS';
// export const GET_OTHER_INVITATIONS_SUCCEEDED =
//   'GET_OTHER_INVITATIONS_SUCCEEDED';
// export const GET_OTHER_INVITATIONS_FAILED = 'GET_OTHER_INVITATIONS_FAILED';
