import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
  },
}));
const ViewButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      classes={classes}
    >
      View
    </Button>
  );
};

export default ViewButton;
