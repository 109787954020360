import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Used for both phone and email list
const MethodTypeSelector = (props) => {
  const { deviceClass, method, handleChange, disabled } = props;

  let options;
  switch (deviceClass) {
    case 'cell':
      options = ['voice', 'sms'].map((o) => (
        <MenuItem key={o} value={o}>
          {o === 'voice' ? 'Voice' : 'SMS'}
        </MenuItem>
      ));
      break;
    case 'landline':
      options = (
        <MenuItem key="voice" value="voice">
          Voice
        </MenuItem>
      );
      break;
    case 'TDD':
      options = (
        <MenuItem key="sms" value="sms">
          SMS
        </MenuItem>
      );
      break;
    case 'email':
      options = ['html', 'text'].map((o) => (
        <MenuItem key={o} value={o}>
          {o === 'html' ? 'HTML' : 'Plain text'}
        </MenuItem>
      ));

      break;
    default:
      options = null;
  }

  return (
    <FormControl className="method-type-selector">
      <InputLabel id="phone-method-input-label">Method</InputLabel>
      <Select
        labelId="Method"
        // id="method-select"
        name="method"
        disabled={disabled}
        value={method}
        onChange={handleChange}
      >
        {options}
      </Select>
    </FormControl>
  );
};

export default MethodTypeSelector;
