import { Avatar, Grid, IconButton, ListItem, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import dayjs from 'dayjs';
import React from 'react';
import SVG from '../../../../../components/Icons/SVG';
import MaterialAvatar from '../../../../../components/MaterialAvatar';
import { ApiEmailService } from '../../../../../config/apiService';
import { getAvatarGroupStyles } from '../../../../../utils/email';
import { useMailListStyles } from './MailListStyles';

const FullScreenMailPreview = ({ index, email, handlers, selectEmail }) => {
  const classes = useMailListStyles();

  const getAttachedFilesForDraft = async (email) => {
    let res = await ApiEmailService.getEmailDetails(
      'getDraftEmailDetails',
      email.mail_id
    );
    selectEmail({
      ...email,
      attachments: res.attachments,
    });
  };

  const receivers = email.receivers || {
    amera: [],
  };

  return (
    <ListItem
      button
      aria-label={`email-preview-${index}`}
      key={email.mail_id}
      className={classes.fullScreenPreview}
      onClick={() => {
        getAttachedFilesForDraft(email);
      }}
    >
      <Grid item xs={2}>
        <AvatarGroup
          className={classes.customAvatarGroupDraft}
          spacing="medium"
          max={3}
        >
          {receivers.amera.length !== 0 ? (
            receivers.amera.map((el, index) => {
              return (
                <MaterialAvatar
                  key={`mail-avatar-list-index-${index}-preview`}
                  classes={'email-preview-draft'}
                  style={getAvatarGroupStyles(index)}
                  src={email.member_details[el].amera_avatar_url}
                  alt={`${email.member_details[el].first_name} ${email.member_details[el].last_name}`}
                  firstName={email.member_details[el].first_name}
                  lastName={email.member_details[el].last_name}
                />
              );
            })
          ) : (
            <Avatar alt="mock-avatar" children={<PersonIcon />} />
          )}
        </AvatarGroup>
      </Grid>
      <Grid
        container
        item
        xs={8}
        alignItems="center"
        justify="center"
        style={{ textAlign: 'center', maxWidth: '50vw' }}
      >
        <div className={classes.textOverflowToTypography}>
          <span style={{ fontWeight: 'bold' }}>{email.subject}</span>
          {email.subject !== '' && ' - '}
          <span
            style={{
              wordBreak: 'break-word',
            }}
          >
            {email.noStyleBody}
          </span>
        </div>
        <span>
          {email.attachments_count > 0 ? (
            <Tooltip title="Attachment" aria-label="attachment-icon">
              <IconButton className={classes.darkButton}>
                <SVG name="attach" />
              </IconButton>
            </Tooltip>
          ) : null}
        </span>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <span>{dayjs(email.time).format('MM/DD/YY hh:mm a')}</span>
        <IconButton
          className={classes.darkButton}
          aria-label="trash-icon"
          onClick={(e) => {
            e.stopPropagation();
            handlers.moveToTrash(email.mail_id);
          }}
        >
          <SVG name="mailTrash" />
        </IconButton>
      </Grid>
    </ListItem>
  );
};

export default FullScreenMailPreview;
