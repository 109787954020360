import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../../components/Modal/modal';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import OtpInput from 'react-otp-input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import usePrevious from '../../hooks/usePrevious';
import {
  postInitVerification,
  putVerificationData,
} from '../../redux/actions/member';

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    margin: theme.spacing(1),
    width: '100%',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-0.75em',
    marginLeft: '-0.75em',
  },
}));

const TOTPModal = (props) => {
  const [attemptsCount, setAttemptsCount] = useState(0);
  // The back-end communicates how many digits are in the TOTP code
  const [isLoadingTOTPLength, setLoadingTOTPLength] = useState(true);
  const [isOTPInputError, setOTPInputError] = useState(false);
  const [digitsCount, setDigitsCount] = useState(6);
  const [lifeTimeSecs, setLifeTimeSecs] = useState(null);
  const [isCanRequestNewCode, setIsCanRequestNewCode] = useState(false);
  const [isValid, setValid] = useState(true);
  const [token, setToken] = useState('');
  const [alert, setAlert] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const {
    show,
    close,
    phoneNumber,
    email,
    onVerificationSuccess,
    onThreeTimesWrong,
    dispatch,
    isLoading,
  } = props;

  //
  const isPhone = phoneNumber && !email;

  const title = isPhone ? 'Cell phone confirmation' : 'E-mail confirmation';
  const classes = useStyles();

  const prevState = usePrevious({ show });

  const requestCode = useCallback(
    async (isPhone) => {
      const {
        success,
        totpLength,
        totpLifetimeSeconds,
        description,
      } = await dispatch(
        postInitVerification(
          isPhone ? phoneNumber : email,
          isPhone ? 'cell' : 'email'
        )
      );

      if (success) {
        // Show default digits count if server error
        if (typeof totpLength === 'number') {
          setDigitsCount(totpLength);
          setLifeTimeSecs(totpLifetimeSeconds);
        }

        setLoadingTOTPLength(false);
        // A code request was successful =>
        setIsCanRequestNewCode(false);
      } else {
        setValid(false);
        setLoadingTOTPLength(false);
        setAlert({
          open: true,
          severity: 'error',
          message: description,
        });
      }
    },
    [dispatch, email, phoneNumber]
  );

  // Trigger SMS on show
  useEffect(() => {
    if (prevState && show === true && prevState.show === false) {
      // console.log(
      //   'making request. Current show is',
      //   show,
      //   'previous show is',
      //   prevState.show
      // );
      requestCode(isPhone);
    }
  }, [requestCode, isPhone, prevState, show]);

  // Reset on close
  const handleClose = useCallback(() => {
    setToken('');
    setLoadingTOTPLength(true);
    setAttemptsCount(0);
    close();
    setValid(true);
    setAlert({
      open: false,
      severity: 'success',
      message: '',
    });
  }, [close]);

  // Close if three attempts made
  useEffect(() => {
    if (attemptsCount > 3) {
      // setTimeout(() => handleClose(), 2000);
      setTimeout(() => onThreeTimesWrong(), 2000);
    }
  }, [attemptsCount, onThreeTimesWrong]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // If token length < required => show
    if (token.length < digitsCount) {
      setOTPInputError(true);
    } else {
      setAttemptsCount((prevAttempts) => (prevAttempts += 1));
      const result = await dispatch(
        putVerificationData(
          isPhone ? 'cell' : 'email',
          isPhone ? phoneNumber : email,
          token
        )
      );
      console.log('Result', result);
      switch (result) {
        case 'match':
          onVerificationSuccess();
          setAlert({
            open: true,
            severity: 'success',
            message:
              'Congratulations! Your number has been successfully verified.',
          });
          setTimeout(() => handleClose(), 2000);
          break;
        case 'expired':
          setToken('');
          setIsCanRequestNewCode(true);
          setAlert({
            open: true,
            severity: 'info',
            message:
              'The entered code has expired. Please request and submit another one.',
          });

          break;
        case 'mismatch':
          setToken('');
          setIsCanRequestNewCode(true);
          if (attemptsCount < 3) {
            setAlert({
              open: true,
              severity: 'warning',
              message: `The provided code is incorrect. Please note that you have ${
                3 - attemptsCount
              } ${
                attemptsCount === 2 ? 'attempt' : 'attempts'
              } left before registration is canceled.`,
            });
          } else {
            setAlert({
              open: true,
              severity: 'error',
              message: 'We are sorry, but this validation session is over.',
            });
          }
          break;
        default:
          console.log('Unexpected result');
      }
    }
  };

  const handleInput = (otp) => {
    setToken(otp);
    setOTPInputError(false);
  };

  const handleRequestCode = async (event) => {
    event.preventDefault();
    await requestCode(isPhone);
  };

  const formatLifeTime = (seconds) => {
    if (seconds >= 0 && seconds <= 60) {
      return `${seconds} seconds`;
    } else if (seconds > 60 && seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minutes`;
    } else if (seconds >= 3600) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds - hours * 3600) / 60);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${
        minutes === 0 ? `${''}` : `${minutes} minutes`
      }`;
    }
  };
  // console.log('Expected TOTP length is ', digitsCount);
  const ModalContent = () => (
    <Grid container>
      {isValid && (
        <>
          <Grid item xs={12}>
            <Typography>
              {`Please enter the code send to your ${
                isPhone ? 'phone' : 'e-mail'
              }. The code will expire in ${formatLifeTime(lifeTimeSecs)}`}
              .
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoadingTOTPLength ? (
              <CircularProgress size={'2em'} />
            ) : (
              <Grid className="OTP-section" item>
                <OtpInput
                  value={token}
                  onChange={handleInput}
                  containerStyle="OTP-container"
                  inputStyle="OTP-input"
                  focusStyle="OTP-input-focused"
                  errorStyle="OTP-input-error"
                  hasErrored={isOTPInputError}
                  numInputs={digitsCount}
                  shouldAutoFocus
                  isInputNum
                />
                <FormHelperText error className="OTP-error-helper">
                  {isOTPInputError
                    ? 'Please enter all the digits of the confirmation code.'
                    : ''}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
          {/* Request another code */}
          <Grid container alignItems="center">
            {isCanRequestNewCode ? (
              <>
                <Typography>
                  If you did not receive the code yet, please request another
                  code.
                </Typography>
                <Button color="primary" onClick={handleRequestCode}>
                  Request
                </Button>
              </>
            ) : (
              <>
                <Typography>
                  The code has been sent. You may request another code in
                </Typography>
                <Grid item className="circle-timer">
                  {lifeTimeSecs !== null && (
                    <CountdownCircleTimer
                      isPlaying
                      duration={lifeTimeSecs}
                      size={34}
                      strokeWidth={3}
                      colors="#94c03d"
                      onComplete={() => setIsCanRequestNewCode(true)}
                    >
                      {({ remainingTime }) => remainingTime}
                    </CountdownCircleTimer>
                  )}
                </Grid>

                <Typography> seconds.</Typography>
              </>
            )}
          </Grid>
        </>
      )}
      <Grid className="alert-block">
        {/* Alert */}
        <Collapse in={alert.open}>
          <Alert
            variant="standard"
            severity={alert.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlert({ open: false });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert.message}
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  );
  const ModalAction = () => {
    // On start up - to prevent premature clicks
    if (!isValid) {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          disableRipple
          onClick={handleClose}
        >
          Go back
        </Button>
      );
    } else {
      if (isLoadingTOTPLength) {
        return (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            type="submit"
            disabled
          >
            Validate
          </Button>
        );
      } else if (isLoading) {
        return (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            type="submit"
            disabled
          >
            <CircularProgress
              size={'1.5em'}
              className={classes.buttonProgress}
            />
            Validate
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disableRipple
            type="submit"
            onClick={handleSubmit}
          >
            Validate
          </Button>
        );
      }
    }
  };
  return (
    <Modal
      open={show}
      onClose={handleClose}
      title={title}
      ModalContent={ModalContent}
      ModalAction={ModalAction}
      className="otp-verification-modal"
    ></Modal>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.member.isLoadingVerification,
  memberAlert: state.member.memberAlert,
});

export default connect(mapStateToProps)(TOTPModal);
