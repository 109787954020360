import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import AmeraAccordion from '../../AmeraAccordion/AmeraAccordion';
import LabeledValue from '../../LabeledValue';
import DepartmentAutocomplete from '../../DepartmentAutocomplete';
import MemberDepartmentsTransfer from './MemberDepartmentsTransfer';
import Button from '../../Dashboard/CardButton';
import drawerProfileStyles from '../../../jss/views/drawerProfileStyles';
import AmeraSelect from '../../AmeraSelect';
import { DEPARTMENT_ROLES, COMPANY_ROLES } from '../../../utils/company';
import { includes, orderBy, capitalize } from 'lodash';
import { displayableFullName } from '../../../utils/contact';

const CompanyMembersEdit = (props) => {
  const {
    companyData,
    onDiscardClick,
    onApplyClick,
    isLoading,
    myCompanyRole,
    isGlobalAdmin,
  } = props;
  const [departments, setDepartments] = useState(companyData.departments);
  const [companyMembers, setCompanyMembers] = useState(
    orderBy(companyData.company_members, [
      (cmo) => capitalize(cmo.first_name),
      'asc',
    ])
  );

  const classes = drawerProfileStyles();

  const dummyMember = {
    member_id: null,
    first_name: 'not',
    last_name: 'specified',
  };

  const handleDepartmentsChange = (e, v) => {
    setDepartments((ps) => {
      // If department deleted - move all members to Not specified
      if (v?.length < ps?.length) {
        const deletedDepNames = ps
          ?.filter(
            (d) =>
              !includes(
                v.map((d) => d.department_name),
                d.department_name
              )
          )
          .map((d) => d.department_name);
        console.debug('Deleted dep names', deletedDepNames);
        if (deletedDepNames.length > 0) {
          setCompanyMembers((pcm) =>
            pcm.map((m) =>
              includes(deletedDepNames, m.department_name)
                ? { ...m, department_name: null }
                : m
            )
          );
        }
      }
      setDepartments(v);
    });
  };

  // If was leaeder, toggle off. Keep one leader per dep
  const handleLeaderShipClick = (e, memberData) => {
    e.stopPropagation();
    if (!!memberData.department_status === DEPARTMENT_ROLES.leader) {
      setCompanyMembers((pcm) =>
        pcm.map((m) =>
          m.member_id === memberData.member_id
            ? { ...m, department_status: DEPARTMENT_ROLES.standard }
            : m
        )
      );
    } else {
      setCompanyMembers((pcm) =>
        pcm.map((m) => {
          if (m.member_id === memberData.member_id) {
            return { ...m, department_status: DEPARTMENT_ROLES.leader };
          } else if (m.department_status === DEPARTMENT_ROLES.leader) {
            return { ...m, department_status: DEPARTMENT_ROLES.standard };
          } else {
            return m;
          }
        })
      );
    }
  };

  // Strip off leadership on transfering
  const handleMembersTransfer = (members, targetDepartment) => {
    const transferredMemberIds = members.map((m) => m.member_id);
    setCompanyMembers((pcm) =>
      pcm.map((cmo) => {
        if (includes(transferredMemberIds, cmo.member_id)) {
          return {
            ...cmo,
            department_name:
              targetDepartment.department_name === 'Not Specified'
                ? null
                : targetDepartment.department_name,
            department_status: DEPARTMENT_ROLES.standard,
          };
        } else {
          return cmo;
        }
      })
    );
  };

  const handleOwnerSelect = (e) => {
    const newOwner = e.target.value;
    console.debug('newAdmin', newOwner);

    setCompanyMembers((pcm) =>
      pcm.map((cmo) => {
        if (!!newOwner.member_id) {
          return cmo.member_id === newOwner.member_id
            ? { ...cmo, company_role: COMPANY_ROLES.owner }
            : cmo;
        } else {
          return cmo.company_role === COMPANY_ROLES.owner
            ? { ...cmo, company_role: COMPANY_ROLES.standard }
            : cmo;
        }
      })
    );
  };
  const handleAdminSelect = (e) => {
    const newAdmin = e.target.value;
    console.debug('newAdmin', newAdmin);
    setCompanyMembers((pcm) =>
      pcm.map((cmo) => {
        if (!!newAdmin.member_id) {
          return cmo.member_id === newAdmin.member_id
            ? { ...cmo, company_role: COMPANY_ROLES.admin }
            : cmo;
        } else {
          return cmo.company_role === COMPANY_ROLES.admin
            ? { ...cmo, company_role: COMPANY_ROLES.standard }
            : cmo;
        }
      })
    );
  };

  const handleApplyClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onApplyClick({ companyMembers, departments });
  };

  return (
    <AmeraAccordion label="Edit Company Members" isLoading={isLoading}>
      <Grid container spacing={2}>
        {companyMembers?.length > 0 && (
          <>
            <Grid item xs={6}>
              <AmeraSelect
                variant="standard"
                label="Owner"
                value={
                  companyMembers.find(
                    (cmo) => cmo.company_role === COMPANY_ROLES.owner
                  ) || dummyMember
                }
                onChange={handleOwnerSelect}
                disabled={
                  !isGlobalAdmin && myCompanyRole !== COMPANY_ROLES.owner
                }
              >
                {[...companyMembers, dummyMember].map((cmo) => (
                  <MenuItem key={cmo.member_id} value={cmo}>
                    {displayableFullName(cmo)}
                  </MenuItem>
                ))}
              </AmeraSelect>
            </Grid>
            <Grid item xs={6}>
              <AmeraSelect
                variant="standard"
                label="Administrator"
                value={
                  companyMembers.find(
                    (cmo) => cmo.company_role === COMPANY_ROLES.admin
                  ) || dummyMember
                }
                onChange={handleAdminSelect}
                disabled={
                  !isGlobalAdmin && myCompanyRole === COMPANY_ROLES.standard
                }
              >
                {[...companyMembers, dummyMember].map((cmo) => (
                  <MenuItem key={cmo.member_id} value={cmo}>
                    {displayableFullName(cmo)}
                  </MenuItem>
                ))}
              </AmeraSelect>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <LabeledValue
            label="Departments"
            value={
              <DepartmentAutocomplete
                selected={departments}
                onChange={handleDepartmentsChange}
              />
            }
          />
        </Grid>
        {departments?.length > 0 && companyMembers?.length > 0 && (
          <MemberDepartmentsTransfer
            departments={departments}
            companyMembers={companyMembers}
            onLeadershipClick={handleLeaderShipClick}
            onMembersTransfer={handleMembersTransfer}
          />
        )}
        {
          <Grid item xs={12} container spacing={1} justify="flex-end">
            <Grid item>
              <Button variant="info" text="Cancel" onClick={onDiscardClick} />
            </Grid>
            <Grid item>
              <Button
                className={classes.accordionEditButton}
                variant="okFilled"
                text={!isLoading ? 'Save' : 'Saving..'}
                onClick={(e) => handleApplyClick(e)}
              />
            </Grid>
          </Grid>
        }
      </Grid>
    </AmeraAccordion>
  );
};

export default CompanyMembersEdit;
