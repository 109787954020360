export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const SET_TEMPORARY_VARIANT = 'SET_TEMPORARY_VARIANT';
export const SET_PERSISTENT_VARIANT = 'SET_PERSISTENT_VARIANT';
export const FETCH_CONTACT_NEWSFEEDS_SUCCESS =
  'FETCH_CONTACT_NEWSFEEDS_SUCCESS';
export const SEND_CONTACT_NEWSFEEDS_TOPIC_SUCCESS =
  'SEND_CONTACT_NEWSFEEDS_TOPIC_SUCCESS';
export const SEND_CONTACT_NEWSFEEDS_POST_SUCCESS =
  'SEND_CONTACT_NEWSFEEDS_POST_SUCCESS';
export const FETCH_GROUP_LIST_SUCCESS = 'FETCH_GROUP_LIST_SUCCESS';
export const SELECT_CALL_GROUP = 'SELECT_CALL_GROUP';
export const CREATE_PASSWORD = 'CREATE_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOAD_PASSWORD = 'LOAD_PASSWORD';
export const DELETE_PASSWORD = 'DELETE_PASSWORD';
export const SET_IS_EDITING = 'SET_IS_EDITING';
