import React, { useState, useEffect, useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import PrintIcon from '@material-ui/icons/Print';
import { connect } from 'react-redux';
import { ADDRESS_TYPES } from '../../../../utils/calendar';
import {
  locationStringToGoogleQuery,
  makeDirectionsUrl,
  destructureAddress,
} from '../../../../utils/country';
import AddressLine from '../../../../components/AddressLine';
import MemberLocationSelect from '../MemberLocationSelect';
import LocationMap from '../LocationMap';
import DrivingDirectionBrief from './DrivingDirectionBrief';
// import DrivingDirectionsDetails from './DrivingDirectionsDetails/DrivingDirectionsDetails';
import Button from '@material-ui/core/Button';
import SmsRoundedIcon from '@material-ui/icons/SmsRounded';
// import DirectionsRoundedIcon from '@material-ui/icons/DirectionsRounded';
import PrintableDirections from './DrivingDirectionsDetails/PrintableDirections';
import { useReactToPrint } from 'react-to-print';

const EventLocation = ({
  event,
  locationObjects,
  onPlaceData,
  onSendYourself,
  isSMSSuccess,
  isOnlyMap = false,
  height = 240,
}) => {
  const { event_location } = event;

  const [isShowDirections, setShowDirections] = useState(false);
  const [isShowDirectionsDetails, setShowDirectionsDetails] = useState(false);
  const [destination, setDestination] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [directions, setDirections] = useState(null);
  const [originLocation, setOriginLocation] = useState(
    // locationObjects.length > 0 ? locationObjects[0] : null
    ''
  );
  const [error, setError] = useState(null);
  // const [focusedStep, setFocusedStep] = useState(null);

  const printableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  let map = new window.google.maps.Map(document.createElement('div'));
  let placesService = new window.google.maps.places.PlacesService(map);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const geocodeAddress = (address) =>
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${locationStringToGoogleQuery(
        address
      )}&sensor=true&language=en&key=${process.env.REACT_APP_GMAPS_API_KEY}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'OK') {
          return data.results[0];
        } else {
          setError('Could get no location for input');
        }
      })
      .catch((error) => {
        setError('Could get no location for input');
        console.error(error);
      });

  const getSinglePlaceDetails = useCallback(
    (placeId, bucket) =>
      placesService.getDetails(
        {
          placeId,
          fields: ['ALL'],
        },
        async (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            /*
              if the place if one from the list => Send its image up 
            */
            console.debug('Place', place);
            onPlaceData(place);
            place.name = event_location.name ? event_location.name : null;

            if (bucket === 'destination') {
              setDestination(place);
            } else if (bucket === 'origin') {
              setOrigin(place);
            } else {
              console.error('Unsupported bucket type');
            }

            return place;
          } else {
            setError(status);
            // console.error(status);
          }
        }
      ),
    [onPlaceData, placesService, event_location]
  );

  const addressToPlace = useCallback(
    async (address, bucket) => {
      try {
        const result = await geocodeAddress(address);
        if (result) getSinglePlaceDetails(result.place_id, bucket);
      } catch (error) {
        setError(error);
      }
    },
    [geocodeAddress, getSinglePlaceDetails]
  );

  useEffect(() => {
    if (!!event_location) {
      const { placeId, vendor_formatted_address, street_address_1 } =
        event_location;
      if (!!placeId) {
        getSinglePlaceDetails(placeId, 'destination');
      } else if (!!vendor_formatted_address) {
        addressToPlace(vendor_formatted_address, 'destination');
      } else {
        addressToPlace(street_address_1, 'destination');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_location]);

  useEffect(() => {
    if (!!origin && !!destination) {
      const DirectionsService = new window.google.maps.DirectionsService();
      DirectionsService.route(
        {
          origin: origin.geometry.location,
          destination: destination.geometry.location,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            console.log(result);
            setDirections(result);
          } else {
            setError('Could not obtain directions');
          }
        }
      );
    }
  }, [origin, destination]);

  const handleOriginSelect = async (value) => {
    setError(null);
    const locationObject = locationObjects.find((lio) => lio.id === value.id);

    // geocode origin to an object containing geometry.location
    const { placeId, vendor_formatted_address, street_address_1 } =
      locationObject;
    if (!!placeId) {
      getSinglePlaceDetails(placeId, 'origin');
    } else if (!!vendor_formatted_address) {
      addressToPlace(vendor_formatted_address, 'origin');
    } else {
      addressToPlace(street_address_1, 'origin');
    }
    setOriginLocation(locationObject);
  };

  const handleSendDirectionsMyself = () => {
    // console.debug('destination', destination);
    // console.debug('origin', origin);
    const link = makeDirectionsUrl(destination, origin);
    onSendYourself(link);
  };

  // const handleStepClick = (step) => setFocusedStep(step);

  // For cleaner stuff - we need to move event locations to new tables as well
  const parsed =
    !!destination && destructureAddress(destination.address_components);
  console.debug('PARSED', parsed);

  return (
    <>
      {!!directions && (
        <div style={{ display: 'none' }}>
          <PrintableDirections
            ref={printableRef}
            directions={directions}
            destination={destination}
            event={event}
          />
        </div>
      )}
      <Grid
        item
        container
        alignItems="center"
        alignContent="center"
        justify="flex-start"
      >
        {!isOnlyMap && (
          <>
            <Grid item container xs={2} justify="center" alignItems="center">
              <Grid item>
                <LocationOnIcon />
              </Grid>
            </Grid>
            {!!destination && (
              <Grid item xs={6}>
                <AddressLine
                  data={{
                    admin_area_1: parsed.adminArea1,
                    admin_area_2: parsed.adminArea2,
                    country_alpha_2: parsed.countryAlpha2,
                    locality: parsed.locality,
                    street_address_1: parsed.streetAddress1,
                    street_address_2: parsed.streetAddress2,
                    sub_locality: parsed.sublocality,
                    postal_code: parsed.postal,
                    country_name: parsed.countryFull,
                    name:
                      destination && destination.name ? destination.name : null,
                  }}
                />
              </Grid>
            )}
            {locationObjects.length > 0 && (
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isShowDirections}
                      onChange={() => setShowDirections((ps) => !ps)}
                      name="directions-show"
                      color="primary"
                    />
                  }
                  label="Driving Directions"
                />
              </Grid>
            )}
          </>
        )}

        {/* Directions part */}
        {isShowDirections && (
          <>
            <Grid item container xs={2} justify="center" alignItems="center">
              <Grid item>
                <MyLocationIcon />
              </Grid>
            </Grid>
            <Grid item xs={10} container direction="column">
              <MemberLocationSelect
                value={!!originLocation ? originLocation : null}
                handleChange={handleOriginSelect}
              />
              {!!directions && isShowDirections && (
                <>
                  <DrivingDirectionBrief
                    directions={directions}
                    isShowDirectionsDetails={isShowDirectionsDetails}
                    onClick={() => setShowDirectionsDetails((ps) => !ps)}
                  />
                  <Grid container justify="flex-end">
                    {/* Hidden since March 22nd on Raul's request*/}
                    {/* <Grid item>
                      <Button
                        startIcon={<DirectionsRoundedIcon />}
                        onClick={() => setShowDirectionsDetails((ps) => !ps)}
                      >
                        {isShowDirectionsDetails
                          ? 'Hide Details'
                          : 'Show Details'}
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button startIcon={<PrintIcon />} onClick={handlePrint}>
                        Print directions
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={
                          <SmsRoundedIcon
                            color={isSMSSuccess ? 'primary' : 'action'}
                          />
                        }
                        onClick={handleSendDirectionsMyself}
                      >
                        {isSMSSuccess ? 'Resend directions' : 'SMS directions'}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            {!!error && (
              <FormHelperText error={!!error}>{error}</FormHelperText>
            )}
            {/* Hidden since March 22nd on Raul's request*/}
            {/* {isShowDirectionsDetails && (
              <Grid item xs={12}>
                <DrivingDirectionsDetails
                  directions={directions}
                  onStepClick={handleStepClick}
                />
              </Grid>
            )} */}
          </>
        )}

        {/* End directions part */}
        {/* Map */}
        {!!destination && (
          <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
            <LocationMap
              places={[destination]}
              selectedPlace={destination}
              // focusedStep={!!directions && focusedStep}
              directions={!!directions && isShowDirections ? directions : null}
              isTeleportToBounds={false}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: height }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Grid>
        )}
        {/* End Map */}
        {/* Directions details */}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  locationObjects: state.member.memberInfo.location_information.filter(
    (lo) => lo.location_type !== ADDRESS_TYPES.billing
  ),
});

export default connect(mapStateToProps)(EventLocation);
