import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Settings from '../../components/MediaStreaming/settingModal';
import { postPageSettings, putPageSettings } from '../../redux/actions/member';

const SettingModal = (props) => {
  const dispatch = useDispatch();

  const {
    onClose,
    open,
    id,
    title,
    settings,
    handleSettings,
    streamingPageSettings,
    defaultStreamType,
  } = props;
  const [state, setState] = React.useState(settings[id]);
  const [isChange, setIsChange] = React.useState(false);
  const streamTypes = useSelector((state) => state.stream.streamTypes);

  useEffect(() => {
    if (isChange) {
      handleSettings(id, state);
      setIsChange(false);
    }
  }, [state, id, handleSettings, isChange]);

  const handleChange = (e) => {
    setState(e.target.value);
    setIsChange(true);
  };

  const handleClose = () => {
    setState(defaultStreamType);
    handleSettings(id, defaultStreamType);
    onClose();
  };

  const handleSubmit = () => {
    let formData = new FormData();

    if (streamingPageSettings && Object.keys(streamingPageSettings).length) {
      Object.keys(streamingPageSettings).map((key) => {
        formData.set(key, streamingPageSettings[key]);
        return true;
      });
    } else {
      formData.set('view_type', 'list');
      formData.set('page_size', 10);
      formData.set('page_type', 'Streaming');
      formData.set('sort_order', '');
    }

    formData.set('extra_settings', JSON.stringify(settings));

    if (formData.get('id')) {
      dispatch(putPageSettings(formData));
    } else {
      dispatch(postPageSettings(formData));
    }

    onClose();
  };

  return (
    <Settings
      open={open}
      title={title}
      state={state}
      handleChange={handleChange}
      streamTypes={streamTypes}
      handleClose={handleClose}
      className={'stream-setting-modal'}
      handleSubmit={handleSubmit}
    />
  );
};

export default SettingModal;
