import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { isEmail } from '../../utils/validator';
import logoLoginPng from '../../assets/img/logo-login.png';

import styles from '../../jss/views/signupStyle';
import FormHeader from '../../components/AuthFormHeader';

const useStyles = makeStyles(styles);

const SignUp = (props) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);
  const [confirmPwdErr, setConfirmPwdErr] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  const confirmPasswordRef = React.useRef();

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
    setEmailErr(false);
  };

  const handlePasswordChange = (evt) => {
    setPassword(evt.target.value);
    setPwdErr(false);
  };

  const handleConfirmPasswordChange = (evt) => {
    setConfirmPassword(evt.target.value);
    setConfirmPwdErr(false);
  };

  const handlePasswordKeyChange = (evt) => {
    if (evt.key === 'Enter') {
      confirmPasswordRef.current.focus();
    }
  };

  const handleEmailKeyChange = (evt) => {
    if (evt.key === 'Enter') {
      passwordRef.current.focus();
    }
  };

  const onSignup = async () => {
    if (!isEmail(email)) {
      setEmailErr(true);
      return;
    }

    if (!password) {
      setPwdErr(true);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPwdErr(true);
      return;
    }
  };

  const handleConfirmPasswordKeyChange = (evt) => {
    if (evt.key === 'Enter') {
      onSignup();
    }
  };

  useEffect(() => {
    emailRef && emailRef.current && emailRef.current.focus();
  }, [emailRef]);

  return (
    <div className={classes.signupPage}>
      <FormHeader />
      <div className={classes.formSection}>
        <div className={classes.logoImg}>
          <img src={logoLoginPng} alt="logo" />
        </div>
        <div className={clsx(classes.signupInput, 'emailInput')}>
          <TextField
            error={emailErr}
            inputRef={emailRef}
            helperText={emailErr && 'Wrong Email Address!'}
            label="Email Address"
            fullWidth
            color="primary"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleEmailKeyChange}
          />
        </div>
        <div className={clsx(classes.signupInput, classes.passwordInput)}>
          <TextField
            inputRef={passwordRef}
            error={pwdErr}
            helperText={pwdErr && "Password isn't matched!"}
            label="Password"
            color="primary"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handlePasswordKeyChange}
          />
        </div>
        <div
          className={clsx(classes.signupInput, classes.confirmPasswordInput)}
        >
          <TextField
            inputRef={confirmPasswordRef}
            error={confirmPwdErr}
            helperText={confirmPwdErr && "Password isn't matched!"}
            label="Confirm Password"
            color="primary"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            onKeyDown={handleConfirmPasswordKeyChange}
          />
        </div>
        <div className={classes.agreeTerms}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeTerms}
                onChange={() => {
                  setAgreeTerms((value) => !value);
                }}
                name="agreeTerms"
                color="primary"
              />
            }
          />
          <Link to={`${process.env.PUBLIC_URL}/terms`}>
            Agree to Terms & Conditions
          </Link>
        </div>
        <div className={classes.signupWrapper}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={onSignup}
          >
            Create Account
          </Button>
          <label>
            Already have an account?{' '}
            <Link to={`${process.env.PUBLIC_URL}/login`}>Sign In</Link>
          </label>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
