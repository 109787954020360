import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import MainBinSwitch from './MainBinSwitch';
import GroupBinTree from './GroupBinTree';
import GroupMainTree from './GroupMainTree';
import PageSettingsModal from '../../components/Modal/PageSettingsModal';
// import FileShareSpinner from './FileShareSpinner';
import { getGroupFiles } from '../../redux/actions/fileshare';
import { ChonkyActions } from 'chonky';
import { find } from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  groupFile: {
    height: 'calc(100% - 48px)',
  },
  groupFileWithoutTab: {
    height: '100%',
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const GroupFiles = (props) => {
  const { dispatch, groupId, groupTrees, isLeader, pageSettings } = props;
  const styles = useStyles();

  const pageType = 'Drive';
  const filePageSettings = find(pageSettings, (o) => o.page_type === pageType);

  let defaultView = (filePageSettings && filePageSettings.view_type) || null;
  if (defaultView && defaultView === 'list') {
    defaultView = ChonkyActions.EnableListView.id;
  } else {
    defaultView = ChonkyActions.EnableGridView.id;
  }

  //TODO: later we should add ascending or descending order feature
  let defaultSortBy =
    (filePageSettings &&
      filePageSettings.sort_order &&
      filePageSettings.sort_order[0]) ||
    null;
  if (defaultSortBy === 'name') {
    defaultSortBy = ChonkyActions.SortFilesByName.id;
  } else if (defaultSortBy === 'date') {
    defaultSortBy = ChonkyActions.SortFilesByDate.id;
  } else {
    defaultSortBy = ChonkyActions.SortFilesBySize.id;
  }

  const [showDriveSettings, setShowDriveSettings] = useState(false);
  const [defaultViewActionId, setDefaultViewActionId] = useState(defaultView);
  const [defaultSortActionId, setDefaultSortActionId] = useState(defaultSortBy);

  let mainTree, binTree;
  if (
    groupTrees.length > 0 &&
    !!groupTrees.find((gt) => gt.groupId === groupId)
  ) {
    mainTree = groupTrees.find((gt) => gt.groupId === groupId).main_tree;
    binTree = groupTrees.find((gt) => gt.groupId === groupId).bin_tree;
    console.log('Group trees exist and will be used', mainTree, binTree);
  } else {
    console.log('Group trees didnt exist', mainTree, binTree);
  }

  const [mode, setMode] = useState('main');
  // const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   async function fetchData() {
  //     setLoading(true);
  //     await dispatch(getGroupFiles(groupId));
  //     setLoading(false);
  //   }
  //   fetchData();
  // }, [dispatch, groupId]);

  useEffect(() => {
    dispatch(getGroupFiles(groupId));
  }, [dispatch, groupId]);

  const handleClick = (e, v) => {
    if (v) {
      setMode(v);
    }
  };

  const handleChangeView = (view) => {
    if (view === 'list') {
      setDefaultViewActionId(ChonkyActions.EnableListView.id);
    } else if (view === 'tile') {
      setDefaultViewActionId(ChonkyActions.EnableGridView.id);
    }
  };

  const handleChangeSort = (sortBy) => {
    if (sortBy === 'file_name') {
      setDefaultSortActionId(ChonkyActions.SortFilesByName.id);
    } else if (sortBy === 'file_size') {
      setDefaultSortActionId(ChonkyActions.SortFilesByName.id);
    } else if (sortBy === 'create_date') {
      setDefaultSortActionId(ChonkyActions.SortFilesByDate.id);
    }
  };

  console.log('Groupd', groupId);
  console.log('Main group tree', mainTree);
  console.log('Bin group tree', binTree);
  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.toggleContainer}>
        {isLeader && (
          <MainBinSwitch mode={mode} handleClick={handleClick} isGroup />
        )}
        <div className="page-setting-button">
          <Tooltip title="Settings" arrow>
            <SettingsIcon onClick={() => setShowDriveSettings(true)} />
          </Tooltip>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        className={isLeader ? styles.groupFile : styles.groupFileWithoutTab}
      >
        {mode === 'bin' ? (
          !!binTree ? (
            <GroupBinTree
              tree={binTree}
              groupId={groupId}
              defaultViewActionId={defaultViewActionId}
              defaultSortActionId={defaultSortActionId}
            />
          ) : null
        ) : !!mainTree ? (
          <GroupMainTree
            tree={mainTree}
            groupId={groupId}
            isLeader={isLeader}
            defaultViewActionId={defaultViewActionId}
            defaultSortActionId={defaultSortActionId}
          />
        ) : null}
        {/* <FileShareSpinner isLoading={isLoading} /> */}
      </Grid>
      <PageSettingsModal
        pageType={pageType}
        loading={false}
        open={showDriveSettings}
        setOrderBy={handleChangeSort}
        setOrder={() => {}}
        setPageSize={() => {}}
        setView={handleChangeView}
        onSave={() => {}}
        onClose={() => setShowDriveSettings(false)}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.fileshare.loading,
  isDownloadLoading: state.fileshare.downloadLoading,
  groupTrees: state.fileshare.groupTrees,
  pageSettings: state.member.pageSettings,
});

export default connect(mapStateToProps)(GroupFiles);
