import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';
import StepConnector from '@material-ui/core/StepConnector';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#7d9d3a',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#7d9d3a',
    },
  },
  line: {
    borderColor: '#5f7231',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#7d9d3a',
  },
  circle: {
    color: '#5f7231',
  },
  completed: {
    color: '#7d9d3a',
    zIndex: 1,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, lastStep, completedAllStep } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {lastStep && completedAllStep ? (
        <StepIcon {...props} completed={true} />
      ) : active ? (
        <StepIcon {...props} />
      ) : (
        <FiberManualRecordIcon
          size="medium"
          className={completed ? classes.completed : classes.circle}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const { steps, activeStep } = props;

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <QontoStepIcon
                  {...props}
                  completedAllStep={activeStep > index}
                  lastStep={index === steps.length - 1}
                />
              )}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
