import { Grid, MenuItem, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { fade, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import Alert from '@material-ui/lab/Alert';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { find, debounce } from 'lodash';
// This is temporarily being commented out, the code/layout is important
// so it's being preserved
// [TODO]
// import NewContact from './NewContact';
import MemberInviteModal from '../../components/Modal/MemberInviteModal';
import PageSettingsModal from '../../components/Modal/PageSettingsModal';
// import { fetchTodos } from '../../config/apiService/Fakeapi';
import {
  deleteContacts,
  loadContactCompanies,
  loadContactCountries,
  loadContactMembers,
  loadContactRoles,
  loadContacts,
  setReduxContactAlert,
} from '../../redux/actions/contact';
import { unSubscribeMembers } from '../../redux/actions/chat';
import Compose from '../Email/SharedComponents/Compose';
import Layout from '../Sections/Layout';
import CardContainer from './CardContainer';
import ListContainer from './ListContainer';
import ContactMembersTable from './MemberTable';
import { PAGE_TYPES } from '../../utils/pageSettings';
import { displayableFullName } from '../../utils/contact';

const getDistinctArray = (array) => {
  return [...new Set(array)];
};

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    // marginTop: '20px',
    marginRight: theme.spacing(2),
    width: '100%',
    boxShadow:
      '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 7px 0px rgba(0, 0, 0, 0.12)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sortContact: {
    float: 'right',
    paddingRight: '20px',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    width: '92%',
  },
  searchToolsContainer: {
    padding: '20px 8px 0 8px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const Contact = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    loading,
    contactMembers,
    contacts,
    totalCount,
    companies,
    countries,
    roles,
    contactAlert,
    loadContactMembers,
    loadContacts,
    loadContactCompanies,
    loadContactCountries,
    loadContactRoles,
    setReduxContactAlert,
    location,
    pageSettings,
  } = props;

  const pageType = PAGE_TYPES.contacts;
  const contactSettings = find(pageSettings, (o) => o.page_type === pageType);
  const defaultView = (contactSettings && contactSettings.view_type) || null;
  const defaultPageSize =
    (contactSettings && contactSettings.page_size) || null;
  const defaultSortOrder =
    (contactSettings && contactSettings.sort_order) || null;

  let defaultOrderBy = 'first_name';
  let defaultOrder = 'asc';

  if (defaultSortOrder && defaultSortOrder[0]) {
    if (defaultSortOrder[0].charAt(0) === '-') {
      defaultOrderBy = defaultSortOrder[0].substring(1);
      defaultOrder = 'desc';
    } else {
      defaultOrderBy = defaultSortOrder[0];
    }
  }

  //TODO: currently loading by multiple sort columns
  //but not showing multiple arrows (for both Contacts and Groups)
  // if (defaultSortOrder && defaultSortOrder.length>0){
  //   defaultSortOrder.forEach(item=>{
  //     if (defaultSortOrder.charAt(0)==='-') {
  //       defaultOrderBy = defaultSortOrder.substring(1);
  //       defaultOrder = 'desc';
  //     } else {
  //       defaultOrderBy = defaultSortOrder;
  //     }
  //   })
  // }

  const [compose, setCompose] = useState({
    open: false,
    initialState: {},
  });
  const [contactData, setContactData] = useState([]);
  const [view, setView] = useState(defaultView || 'tile');
  // This is temporarily being commented out, the code/layout is important
  // so it's being preserved
  // [TODO]
  // const [showNewContactModal, setShowNewContactModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showContactsSetting, setShowContactsSetting] = useState(false);

  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize || 25);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [filterBy, setFilterBy] = useState({});
  const [companyFilter, setCompanyFilter] = useState('');
  const [countryFilter, setCountryFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [isGlobalContacts, setIsGlobalContacts] = useState(false);

  // const goToMyGroup = () => {
  //   history.push('groups/my-groups');
  // };

  const isContactExists = (contactMemberId) =>
    !!contacts.find((co) => co.contact_member_id === contactMemberId);

  const goToCreateGroup = (selectedContacts = []) => {
    if (selectedContacts.length > 0) {
      const selectedMembers = getDistinctArray(selectedContacts);
      history.push({
        pathname: 'groups/new-group',
        selectedMembers,
      });
    } else {
      history.push('groups/new-group');
    }
  };

  const goToScheduleEvent = (contacts) => {
    if (!!contacts && contacts.length > 0) {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/calendar`,
        search: `?member_ids=${contacts.map((co) => co.contact_member_id)}`,
      });
    }
  };

  const deleteContactsFunc = (selectedContacts) => {
    let formData = new FormData();
    formData.set(
      'contactIds',
      selectedContacts.map(({ id }) => id)
    );
    props.deleteContacts(formData);

    dispatch(
      unSubscribeMembers(
        selectedContacts.map((c) => ({
          id: c.contact_member_id,
          nick: displayableFullName(c),
        }))
      )
    );
  };

  const search = (e) => {
    const value = e.target.value;
    console.log('value', value);
    loadSortedContacts(null, null, null, value, null, null, isGlobalContacts);
    setSearchKey(value);
  };

  useEffect(() => {
    loadContacts({
      sort: defaultSortOrder,
      searchKey,
      pageNumber: pageNumber - 1,
      pageSize,
    }).catch((error) => {
      console.error('Error loading Contacts');
      console.error(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadContacts]);

  const loadSortedContacts = useCallback(
    debounce(
      (
        property,
        value,
        filterByItems,
        search_key,
        page_number,
        page_size,
        isGlobal = false
      ) => {
        let sort = '';
        if (!property) {
          property = orderBy;
        }
        if (!value) {
          value = order;
        }
        if (property) {
          if (property === 'member_name') {
            sort =
              value === 'desc'
                ? `-first_name,-last_name`
                : `first_name,last_name`;
          } else {
            sort = value === 'desc' ? `-${property}` : `${property}`;
          }
        }

        let params = {};
        filterByItems = filterByItems ? filterByItems : filterBy;
        if (Object.keys(filterByItems).length > 0) {
          //consider user recent filter options as well if exist
          params = { sort, filter: new URLSearchParams(filterByItems) };
        } else {
          params = { sort };
        }
        params.searchKey = search_key !== undefined ? search_key : searchKey;
        params.pageNumber =
          page_number === null || page_number === undefined
            ? pageNumber - 1
            : page_number;
        params.pageSize = page_size ? page_size : pageSize;
        if (isGlobal) {
          loadContactMembers(params, true);
        } else {
          loadContacts(params, true).catch((error) => {
            console.error('Error loading Contacts');
            console.error(error);
          });
        }
        setOrderBy(property);
        setFilterBy(filterByItems);
      },
      500
    ),
    []
  );

  const loadFilteredContacts = ({ target: { value, name } }) => {
    let filterByItems = {};
    if (name === 'company') {
      setCompanyFilter(value);
      filterByItems = {
        company: value.company_name,
        company_id: value.company_id,
        company_profile: value.company_profile,
      };
    } else if (name === 'country_code_id') {
      setCountryFilter(value);
      filterByItems = {
        country_code_id: value.id,
        location_country: value.location_country,
      };
    } else if (name === 'role') {
      setRoleFilter(value);
      filterByItems = {
        role: value.name,
      };
    }

    if (!value || value === '') {
      const filterKeys = Object.keys(filterByItems);
      filterByItems = { ...filterBy };
      filterKeys.forEach((key) => {
        delete filterByItems[key];
      });
    } else {
      filterByItems = {
        ...filterBy,
        ...filterByItems,
      };
    }
    loadSortedContacts(
      null,
      null,
      filterByItems,
      null,
      null,
      null,
      isGlobalContacts
    );
  };

  const handleSnackbarClose = () => {
    if (contactAlert.show) {
      const alertOption = {
        show: false,
        variant: contactAlert.variant,
        message: '',
      };
      setReduxContactAlert(alertOption);
    }
  };

  const handleScheduleClick = (data) => {
    // console.log('schedule for', contactMembersIds);
    goToScheduleEvent(data);
  };
  useEffect(() => {
    if (contactAlert.show) {
      setShowContactsSetting(false);
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: contactAlert.variant ? contactAlert.variant : 'success',
          message: '',
        };
        setReduxContactAlert(alertOption);
      }, 5000);
    }
  }, [contactAlert, setReduxContactAlert]);

  const loadInitialFilterValues = useCallback(() => {
    loadContactCompanies();
    loadContactCountries();
    loadContactRoles();
  }, [loadContactCompanies, loadContactCountries, loadContactRoles]);

  useEffect(() => {
    loadInitialFilterValues();
  }, [loadInitialFilterValues]);

  useEffect(() => {
    setContactData(contacts || []);
  }, [contacts]);

  const toggleCard = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
      setContactData(contacts || []);
      setOrderBy('');
      setFilterBy({});
    }
  };

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedContacts(
        null,
        null,
        null,
        null,
        newPage - 1,
        null,
        isGlobalContacts
      );
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const newPageSize = e.target.value;
    setPageSize(parseInt(newPageSize, 10));
    setPageNumber(1);
    loadSortedContacts(
      null,
      null,
      null,
      null,
      0,
      newPageSize,
      isGlobalContacts
    );
  };

  return (
    <Layout {...props}>
      <div className="view-section contact-section">
        {/*
        // This is temporarily being commented out, the code/layout is important
        // so it's being preserved
        // [TODO]
        <NewContact
          open={showNewContactModal}
          onClose={() => setShowNewContactModal(false)}
        /> */}

        <MemberInviteModal
          open={showInviteModal}
          onClose={() => setShowInviteModal(false)}
        ></MemberInviteModal>

        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.searchToolsContainer}
        >
          <Grid item xs={12} sm={5} md={4}>
            <div className={classes.search}>
              <div aria-label="search-icon" className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                value={searchKey}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={search}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={7}
            md={5}
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <ToggleButtonGroup value={view} exclusive onChange={toggleCard}>
                <ToggleButton value="tile" aria-label="module">
                  <Tooltip title="Contact Tile">
                    <ViewModuleIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="table" aria-label="table">
                  <Tooltip title="Contact Table">
                    <TableChartIcon />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="master" aria-label="master">
                  <Tooltip title="Amera Master Table">
                    <TableChartIcon />
                  </Tooltip>
                  &nbsp;&nbsp;AmeraShare Users
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item className="page-setting-button">
              {view !== 'master' && (
                <Tooltip title="Settings" arrow>
                  <SettingsIcon onClick={() => setShowContactsSetting(true)} />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        {view !== 'master' && (
          <React.Fragment>
            <div className="multi-filter-option">
              <Grid container spacing={2}>
                <Grid item xs={4} key="company">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="company">
                      Filter contacts by company
                    </InputLabel>
                    <Select
                      fullWidth
                      value={companyFilter}
                      onChange={(e) => loadFilteredContacts(e)}
                      inputProps={{
                        name: 'company',
                        id: 'company',
                      }}
                      // renderValue={}
                    >
                      <MenuItem dense aria-label="Clear filter" value="">
                        Clear filter
                      </MenuItem>
                      {companies.map((company) => (
                        <MenuItem
                          dense
                          aria-label={company.company_name}
                          value={company}
                          key={`${company.company_name}-${company.company_profile}-${company.company_id}`}
                        >
                          <Grid container justify="space-between">
                            <Typography inline align="left">
                              {company.company_name}
                            </Typography>
                            <Typography inline align="right">
                              {company.count}
                            </Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} key="country_code_id">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="country_code_id">
                      Filter contacts by country
                    </InputLabel>
                    <Select
                      fullWidth
                      value={countryFilter}
                      onChange={(e) => loadFilteredContacts(e)}
                      inputProps={{
                        name: 'country_code_id',
                        id: 'country_code_id',
                      }}
                    >
                      <MenuItem dense aria-label="Clear filter" value="">
                        Clear filter
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          dense
                          aria-label={country.name}
                          value={country}
                          key={`${country.name}-${country.location_country}-${country.country_code_id}`}
                        >
                          <Grid container justify="space-between">
                            <Typography inline align="left">
                              {country.name}
                            </Typography>
                            <Typography inline align="right">
                              {country.count}
                            </Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} key="role">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="role">
                      Filter contacts by relationship
                    </InputLabel>
                    <Select
                      fullWidth
                      value={roleFilter}
                      onChange={(e) => loadFilteredContacts(e)}
                      inputProps={{
                        name: 'role',
                        id: 'role',
                      }}
                    >
                      <MenuItem dense aria-label="Clear filter" value="">
                        Clear filter
                      </MenuItem>
                      {roles.map((role) => (
                        <MenuItem
                          fullWidth
                          dense
                          aria-label={role.name}
                          value={role}
                          key={role.name}
                        >
                          <Grid container justify="space-between">
                            <Typography inline align="left">
                              {role.name}
                            </Typography>
                            <Typography inline align="right">
                              {role.count}
                            </Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )}
        {view === 'tile' && (
          <CardContainer
            // view={view}
            totalCount={totalCount}
            contactData={contactData}
            // showContactsSetting={showContactsSetting}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loadSortedContacts={loadSortedContacts}
            loadContactCompanies={loadContactCompanies}
            loadContactCountries={loadContactCountries}
            loadContactRoles={loadContactRoles}
            // setShowContactsSetting={setShowContactsSetting}
            goToCreateGroup={goToCreateGroup}
            deleteContactsFunc={deleteContactsFunc}
            // This makes sure an empty modal is not opened if the person of interest is nt in you contacts list
            openDetailsFor={
              !!location.state && isContactExists(location.state.openDetailsFor)
                ? location.state.openDetailsFor
                : null
            }
            setCompose={setCompose}
            onSchedule={handleScheduleClick}
          />
        )}
        {view === 'table' && (
          <ListContainer
            // view={view}
            totalCount={totalCount}
            contactData={contactData}
            order={order}
            setOrder={(o) => setOrder(o)}
            orderBy={orderBy}
            setOrderBy={(p) => setOrderBy(p)}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            // showContactsSetting={showContactsSetting}
            // setShowContactsSetting={setShowContactsSetting}
            goToCreateGroup={goToCreateGroup}
            loadSortedContacts={loadSortedContacts}
            deleteContactsFunc={deleteContactsFunc}
            // This makes sure an empty modal is not opened if the person of interest is nt in you contacts list
            openDetailsFor={
              !!location.state && isContactExists(location.state.openDetailsFor)
                ? location.state.openDetailsFor
                : null
            }
            setCompose={setCompose}
            onSchedule={handleScheduleClick}
          />
        )}
        {view === 'master' && (
          <ContactMembersTable
            loading={loading}
            totalCount={totalCount}
            isGlobalContacts={isGlobalContacts}
            setIsGlobalContacts={setIsGlobalContacts}
            contactMembers={contactMembers}
            contacts={contactData}
            order={order}
            setOrder={(o) => setOrder(o)}
            orderBy={orderBy}
            setOrderBy={(p) => setOrderBy(p)}
            pageSize={pageSize}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            loadSortedContacts={loadSortedContacts}
            deleteContactsFunc={deleteContactsFunc}
            // showContactsSetting={showContactsSetting}
            // setShowContactsSetting={setShowContactsSetting}
          />
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={contactAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={contactAlert.variant}>{contactAlert.message}</Alert>
      </Snackbar>
      {!compose.open ? null : (
        <Compose
          open={true}
          initialComposeState={compose.initialState}
          fullWidth={false}
          onClose={() => {
            setCompose({ open: false, initialState: {} });
          }}
        />
      )}
      <PageSettingsModal
        pageType={pageType}
        loading={loading}
        open={showContactsSetting}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setPageSize={setPageSize}
        setView={setView}
        onSave={loadSortedContacts}
        onClose={() => setShowContactsSetting(false)}
      />
    </Layout>
  );
};

Contact.propTypes = {
  contactMembers: PropTypes.arrayOf(PropTypes.object),
  contacts: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.object),
  contactAlert: PropTypes.object,
};

Contact.defaultProps = {
  contactMembers: [],
  contacts: [],
  companies: [],
  countries: [],
  roles: [],
  contactAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

const mapStateToProps = (state) => ({
  loading: state.contact.loading,
  contactMembers: state.contact.contactMembers,
  contacts: state.contact.contacts,
  totalCount: state.contact.totalCount,
  companies: state.contact.companies,
  countries: state.contact.countries,
  roles: state.contact.roles,
  contactAlert: state.contact.contactAlert,
  pageSettings: state.member.pageSettings,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        loadContactMembers,
        loadContacts,
        loadContactCompanies,
        loadContactCountries,
        loadContactRoles,
        deleteContacts,
        setReduxContactAlert,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
