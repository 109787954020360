import { makeStyles } from '@material-ui/styles';
import { green, white, grayBlue } from '../../../jss/colors';

export const useCardStyles = makeStyles((theme) => ({
  content: {
    '& li': {
      padding: 0,
    },

    '& .MuiListItem-root': {
      alignItems: 'start',
    },

    '& .MuiListItemText-root >span': {
      fontSize: 14,
      lineHeight: '20px',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
    },

    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3),
      '& svg': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginTop: 7,
      },
    },
  },
  contentSecondary: {
    overflow: 'auto',
    '& li': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .MuiListItem-root': {
      paddingTop: '5px !important',
      paddingLeft: '4px !important',
      paddingRight: '4px !important',
    },
  },
  securityPercentageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 0,
  },
  securityPercentage: {
    marginTop: -19,
    marginRight: 10,
    textAlign: 'right',
    fontSize: 11,
    fontWeight: 700,
    position: 'relative',
    color: 'black',
  },
  securityLabel: {
    fontSize: 12,
    fontWeight: 700,
    paddingLeft: 13,
    marginTop: 5,
    color: 'black',
  },
  infoTitle: {
    color: '#94c03d',
    fontWeight: 600,
    fontSize: theme.spacing(2),
    maxHeight: theme.spacing(6),
    textTransform: 'capitalize',
    display: '-webkit-box',
    userSelect: 'none',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    paddingTop: '0px !important',
    paddingLeft: '4px !important',
    paddingRight: '4px !important',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 12,
    '& label': {
      fontSize: 12,
      lineHeight: 1.8,
      color: '#8DA3C5',
      fontWeight: 700,
    },
    '& span': {
      fontSize: 14,
      color: '#5F5F5F',
      fontWeight: 500,
    },
  },
  contactDetails: {
    borderTop: `1px solid ${grayBlue}`,
  },
  playerContent: {
    height: 240,
  },
  player: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 1,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  },
  videoWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    background: 'black',
    position: 'relative',
    height: '100%',
  },
  audioWrapper: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  playBtnWrapper: {
    position: 'absolute',
    top: 'calc(50% - 23.993px)',
    left: 'calc(50% - 23.993px)',
    '& .MuiIconButton-root': {
      color: 'white',
      backgroundColor: green,
    },
    '& .MuiIconButton-colorPrimary': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
  },
  fullScreenBtnWrapper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: 'rgb(245,245,245, 8%)',
    backfaceVisibility: 'hidden',
    '& .MuiSvgIcon-root': {
      color: white,
    },
  },
  eventDateAndTime: {
    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between',
      padding: 0,
      '& li': {
        width: 'unset',
      },
    },
  },
  contractContent: {
    height: '100%',
    alignItems: 'center',
    margin: 0,
    userSelect: 'none',
  },
  subHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  contractInviteDate: {
    fontSize: 13,
  },
  contractContentBox: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxHeight: '4rem',
    paddingLeft: '4px !important',
    paddingRight: '4px !important',
  },
  contractCardActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contractRolesLabel: {
    minWidth: 62,
  },
  rolesChip: {
    margin: 1,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));
