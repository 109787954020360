import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  // Typography,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Modal from './modal';
import FaceLearn from '../FacialRecognition/FaceLearn';
import { ReactDone, ReactHead, ReactClock } from '../../assets/svg';
import SVG from '../Icons/SVG';
import AntSwitch from '../AntSwitch';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import { saveFaces } from '../../redux/actions/member';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    height: '100%',
    padding: '16px 24px',
    backgroundColor: '#f3f6fa',
    display: 'flex',
  },
  titleLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  titleLabel: {
    fontSize: '1.5rem',
  },
  titleMiddle: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    flex: 1,
  },
  secondRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  titleRight: {
    display: 'flex',
    alignItems: 'center',
  },
  settings: {
    '& .MuiButtonBase-root': {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#5f5f5f',
      borderRadius: '8px',
      padding: '2px 8px',
      border: '1px solid #000000',
      background: 'white',
      marginRight: theme.spacing(3),
      '& .MuiSvgIcon-root': {
        marginLeft: theme.spacing(5),
      },
    },
  },
  stepTitle: {
    color: '#8DA3C5',
    fontSize: '12px',
  },
  stepDesc: {
    color: '#000000',
    fontSize: '17px',
  },
  stepLabels: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  activateSwitch: {
    color: '#686869',
    fontSize: '14px',
    marginLeft: theme.spacing(1),
  },
  actionWrapper: {
    width: '100%',
    padding: '32px 24px',
    backgroundColor: '#f3f6fa',
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      textTransform: 'none',
    },
    '& .MuiButton-contained': {
      boxShadow: 'none',
    },
  },
  cancel: {
    '& .MuiButtonBase-root': {
      border: '1px solid #8da3c5',
      color: '#8da3c5',
      background: '#ffffff',
      marginRight: theme.spacing(2),
    },
  },
  settingsPopover: {
    padding: theme.spacing(1),
    '& .MuiFormControlLabel-root': {
      justifyContent: 'space-between',
    },
  },
  clockWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  stepWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
  },
}));

const steps = [
  {
    title: 'Step 1',
    description: 'Look forward',
  },
  {
    title: 'Step 2',
    description: 'Look up',
  },
  {
    title: 'Step 3',
    description: 'Look left',
  },
  {
    title: 'Step 4',
    description: 'Look down',
  },
  {
    title: 'Step 5',
    description: 'Look right',
  },
  {
    title: 'Done',
    description: 'Your face was registered successfully!',
  },
];

const usecases = [
  {
    name: 'login',
    label: 'Use for Login',
  },
  {
    name: 'chat',
    label: 'Use for Chat',
  },
  {
    name: 'groups',
    label: 'Use for Groups',
  },
  {
    name: 'email',
    label: 'Use for Email',
  },
  {
    name: 'pinandpic',
    label: 'Pin and Picture authentication',
  },
];

const FaceLearnModal = (props) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activateRecognition, setActivateRecognition] = useState(false);
  const [descriptions, setDescriptions] = useState([]);
  const [use, setUse] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setActiveStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeModal = () => {
    onClose();
  };

  const handleUseCases = (event) => {
    event.persist();
    setUse((ps) => ({ ...ps, [event.target.name]: event.target.checked }));
  };

  const handleActivateRecognition = (e) => {
    setActivateRecognition(e.target.checked);
  };

  const ModalTitle = () => {
    return (
      <div className={classes.title}>
        <div className={classes.titleLeft}>
          <SVG name="logo" />
        </div>
        <div className={classes.titleMiddle}>
          <div className={classes.titleLabel}>Facial Recognition</div>
          <div
            className={clsx(
              classes.secondRow,
              activeStep === 5 && classes.flexEnd
            )}
          >
            <div className={classes.stepWrapper}>
              {activeStep < 5 ? <ReactHead /> : <ReactDone />}
              <div className={classes.stepLabels}>
                <div className={classes.stepTitle}>
                  {steps[activeStep].title}
                </div>
                <div className={clsx(classes.stepDesc)}>
                  {steps[activeStep].description}
                </div>
              </div>
            </div>
            {activeStep === 5 && (
              <div className={classes.titleRight}>
                <div className={classes.settings}>
                  <Button onClick={handleClick}>
                    Settings <ArrowDropDownIcon />
                  </Button>
                </div>
                <AntSwitch
                  checked={activateRecognition}
                  onChange={handleActivateRecognition}
                />
                <span className={classes.activateSwitch}>
                  Activate Facial Recognition
                </span>
              </div>
            )}
          </div>
        </div>
        <Popover
          open={anchorEl ? true : false}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper elevation={3}>
            <div className={classes.settingsPopover}>
              <FormControl>
                <FormGroup aria-label="position">
                  {usecases.map((usecase) => (
                    <FormControlLabel
                      key={usecase.name}
                      value={usecase.name}
                      control={
                        <Checkbox
                          color="primary"
                          name={usecase.name}
                          checked={use[usecase.name] ? true : false}
                          onChange={handleUseCases}
                        />
                      }
                      label={usecase.label}
                      labelPlacement="start"
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </Paper>
        </Popover>
      </div>
    );
  };

  const ModalAction = () => (
    <div className={classes.actionWrapper}>
      <div className={classes.clockWrapper}>
        <ReactClock />
      </div>
      <div className={classes.cancel}>
        <Button variant="contained" onClick={closeModal}>
          Cancel
        </Button>
      </div>
      {activeStep === 5 && (
        <div
          className={classes.save}
          onClick={() => {
            dispatch(saveFaces(JSON.stringify({ embeddings: descriptions })));
            closeModal();
          }}
        >
          <Button variant="contained" color="primary">
            Save Settings
          </Button>
        </div>
      )}
    </div>
  );
  const ModalContent = () => {
    return (
      <FaceLearn
        show={open}
        setActiveStep={setActiveStep}
        setDescriptions={setDescriptions}
        activeStep={activeStep}
      />
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        maxWidth="lg"
        disableBackdropClick={true}
        title={ModalTitle}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        noPadding={true}
        customTitle={true}
        className="facial-recognition-modal"
      />
    </>
  );
};

export default FaceLearnModal;
