import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import GroupFiles from '../FileShare/GroupFiles';
import Group from '../../components/Group';
import Modal from '../../components/Modal/modal';
// import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Alert from '@material-ui/lab/Alert';
import { bindActionCreators } from 'redux';
import {
  switchAddMemberForm,
  setInitAlert,
  setReduxGroupAlert,
  deleteGroups,
} from '../../redux/actions/group';

import MemberInviteModal from '../../components/Modal/MemberInviteModal';
import ConfirmDialog from '../../components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
  },
  closeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionsLeft: {
    width: '33%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actionsRight: {
    width: '66%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteGroupBtn: {
    width: '140px',
    backgroundColor: '#dc004e',
    margin: theme.spacing(1),
    '&:hover': {
      background: '#9a0036',
    },
  },
  cancelBtn: {
    width: '120px',
    margin: theme.spacing(1),
  },
  inviteMemberBtn: {
    width: '140px',
    margin: theme.spacing(1),
    '&:hover .MuiCircularProgress-circle': {
      color: '#4caf50',
    },
    '& .MuiCircularProgress-circle': {
      color: '#1b5e20',
    },
  },
  groupAlert: {
    '& .MuiAlert-filledSuccess': {
      backgroundColor: '#1b5e20',
    },
  },
}));

const GroupDetailModal = (props) => {
  const {
    dispatch,
    show,
    close,
    groupData,
    // groupAlert,
    member,
    // isDrive,
  } = props;

  const classes = useStyles();
  const title = groupData.group_name;

  const [open, setOpen] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGroupDelete = () => {
    setOpen(false);
    let formData = new FormData();
    let groupIds = [];
    groupIds.push(groupData.group_id);
    formData.set('groupIds', groupIds);
    props.deleteGroups(formData);
  };

  // const hideAlert = () => {
  //   props.setReduxGroupAlert({
  //     show: false,
  //     variant: groupAlert.variant,
  //     message: '',
  //   });
  // };

  const modalHide = () => {
    dispatch(setInitAlert());
    dispatch(switchAddMemberForm());
    close();
  };

  const isLeader = groupData.group_leader_id === member.member_id;
  const ModalContent = () => (
    <Grid container spacing={2} direction="row">
      <Grid item sm={12}>
        <Group isLeader={isLeader} groupData={groupData} />
      </Grid>
    </Grid>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionsWrapper}>
        <div className={classes.actionsLeft}>
          <Button
            variant="contained"
            color="primary"
            className={classes.deleteGroupBtn}
            disableRipple
            onClick={(e) => {
              setOpen(true);
            }}
          >
            {props.deletingGroup ? (
              <CircularProgress size={30} thickness={3} />
            ) : (
              'Delete Group'
            )}
          </Button>
        </div>
        <div className={classes.actionsRight}>
          <Button
            variant="contained"
            color="secondary"
            disableRipple
            className={classes.cancelBtn}
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.inviteMemberBtn}
            disableRipple
            onClick={(e) => {
              setShowInviteModal(true);
            }}
          >
            {props.deletingGroup ? (
              <CircularProgress size={30} thickness={3} />
            ) : (
              'Invite Member'
            )}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* {groupAlert.show && (
        <Snackbar
          open={groupAlert.show}
          autoHideDuration={5000}
          onClose={hideAlert}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <div className={classes.groupAlert}>
            <Alert
              onClose={hideAlert}
              variant="filled"
              severity={groupAlert.variant}
            >
              {groupAlert.message}
            </Alert>
          </div>
        </Snackbar>
      )} */}
      <Modal
        open={show}
        onClose={modalHide}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        // className="group-detail-modal"
      />
      <ConfirmDialog
        open={open}
        title="Confirm Delete"
        onOk={handleGroupDelete}
        onCancel={handleClose}
      />
      <MemberInviteModal
        open={showInviteModal}
        group_id={groupData.group_id}
        onClose={() => setShowInviteModal(false)}
      ></MemberInviteModal>
    </>
  );
};

GroupDetailModal.propTypes = {
  dispatch: PropTypes.func,
  groupData: PropTypes.object,
  show: PropTypes.bool,
  close: PropTypes.func,
};

GroupDetailModal.defaultProps = {
  dispatch: null,
  groupData: {},
  show: false,
  close: null,
};

const mapStateToProps = (state) => ({
  groupAlert: state.group.groupAlert,
  groupData: state.group.groupData,
  member: state.member.member,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ deleteGroups, setReduxGroupAlert }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetailModal);
