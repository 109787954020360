import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { Button, TextField } from '@material-ui/core';
import Search from '../SearchComponent';
import Modal from '../Modal/modal';
import { AttachmentIcon } from '../../assets/svg';
import PublishIcon from '@material-ui/icons/Publish';
import CustomComposeEditor from '../../pages/Email/SharedComponents/Compose/CustomComposeEditor';
import { fileSize } from '../../utils/file';
import { addNewTopic } from '../../redux/actions/forum';
import { getGroupDetail } from '../../redux/actions/group';
import Filter from './Filter';
import Setting from './Setting';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #8DA3C5',
    position: 'relative',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      textTransform: 'none',
      paddingLeft: theme.spacing(1),
    },
  },
  right: {
    display: 'flex',
    position: 'absolute',
    right: theme.spacing(2),
  },
  searchWrapper: {
    width: '436px',
    marginLeft: theme.spacing(2),
  },
  newTopic: {
    padding: '8px 32px',
  },
  attachments: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
  },
  uploadButton: {
    color: '#5F5F5F',
    border: '1px solid #5F5F5F',
    fontSize: '13px',
    fontWeight: 700,
    height: '30px',
    width: '166px',
    padding: '6px 13px',
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  attachment: {
    display: 'flex',
    marginLeft: theme.spacing(2),
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    cursor: 'pointer',
  },
  attachmentIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    cursor: 'pointer',
    '& svg': {
      fill: '#6D5DD3 !important',
    },
  },
  fileInfo: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  fileName: {
    color: '#2D2D2D',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: 3,
  },
  fileSize: {
    fontWeight: 400,
    fontSize: '10px',
    color: '#9C9C9C',
    marginBottom: 0,
  },
  TextField: {
    maxHeight: 380,
    minHeight: 380,
    // overflow: 'hidden',
    border: 'none',
  },
  fileInputWrapper: {
    '& label': {
      display: 'block',
    },
  },
  fileInput: {
    display: 'none',
  },
  files: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const Toolbar = (props) => {
  const classes = useStyles();
  const {
    searchKey,
    handleSearch,
    searchMemberKey,
    handleSearchMemberKey,
    selectedMembers,
    handleSelectMember,
    sortDir,
    handleSortDir,
  } = props;

  const [openNewTopicModal, setOpenNewTopicModal] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [coverImage, setCoverImage] = React.useState();
  const [attachments, setAttachments] = React.useState([]);
  const group_members = useSelector(
    (state) => state.group.groupData.members || []
  );
  const dispatch = useDispatch();
  const { group_id } = useParams('group_id');

  useEffect(() => {
    dispatch(getGroupDetail(group_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id]);

  const handleNewTopic = () => {
    setOpenNewTopicModal(true);
  };

  const handleCloseNewTopicModal = () => {
    setOpenNewTopicModal(false);
  };

  const handleChangebd = (html) => {
    setContent(html !== '<p><br></p>' ? html : '');
  };

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleCoverImage = (event) => {
    setCoverImage(event.target.files[0]);
  };

  const handleAttachments = (event) => {
    setAttachments(Object.values(event.target.files));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('cover_image', coverImage);
    formData.append('attachments', attachments.length);
    attachments.forEach((attachment, index) => {
      formData.append(`file_${index}`, attachment);
    });
    dispatch(addNewTopic(formData, group_id));
    handleCloseNewTopicModal();
  };

  const ModalContent = () => (
    <div className={classes.newTopic}>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        size="small"
        value={title}
        onChange={handleTitle}
      />
      <div className={classes.attachments}>
        <div className={classes.fileInputWrapper}>
          <input
            className={classes.fileInput}
            onChange={handleCoverImage}
            id="coverImage"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
          />
          <label htmlFor="coverImage">
            <div className={classes.uploadButton}>
              <PublishIcon fontSize="small" />
              Upload Cover Photo
            </div>
          </label>
        </div>
        <div className={classes.files}>
          {coverImage && (
            <div className={classes.attachment}>
              <div className={classes.attachmentIconWrapper}>
                <AttachmentIcon />
              </div>
              <div className={classes.fileInfo}>
                <h5 className={classes.fileName}>{coverImage.name}</h5>
                <h5 className={classes.fileSize}>
                  {fileSize(coverImage.size)}
                </h5>
              </div>
              <div className={classes.iconWrapper}>
                <CloseIcon />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.attachments}>
        <div className={classes.fileInputWrapper}>
          <input
            className={classes.fileInput}
            multiple
            id="topicAttachments"
            onChange={handleAttachments}
            type="file"
          />
          <label htmlFor="topicAttachments">
            <div className={classes.uploadButton}>
              <PublishIcon fontSize="small" />
              Attachments
            </div>
          </label>
        </div>
        <div className={classes.files}>
          {attachments.map((attachment) => (
            <div className={classes.attachment} key={attachment.name}>
              <div className={classes.attachmentIconWrapper}>
                <AttachmentIcon />
              </div>
              <div className={classes.fileInfo}>
                <h5 className={classes.fileName}>{attachment.name}</h5>
                <h5 className={classes.fileSize}>
                  {fileSize(attachment.size)}
                </h5>
              </div>
              <div className={classes.iconWrapper}>
                <CloseIcon />
              </div>
            </div>
          ))}
        </div>
      </div>
      <CustomComposeEditor
        className={classes.TextField}
        multiline
        rowsMax={20}
        value={content}
        handleChangebd={handleChangebd}
        placeholder="Type something..."
      />
    </div>
  );

  const ModalAction = () => (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleCloseNewTopicModal}
      >
        Cancel
      </Button>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Add
      </Button>
    </>
  );

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Button color="primary" variant="contained" onClick={handleNewTopic}>
          <AddIcon />
          Add New Topic
        </Button>
        <div className={classes.searchWrapper}>
          <Search searchKey={searchKey} handleSearch={handleSearch} />
        </div>
      </div>
      <div className={classes.right}>
        <Filter
          members={group_members}
          searchKey={searchMemberKey}
          handleSearchKey={handleSearchMemberKey}
          selectedItems={selectedMembers}
          handleSelect={handleSelectMember}
        />
        <Setting sortDir={sortDir} handleSortDir={handleSortDir} />
      </div>
      <Modal
        title="Add New Topic"
        open={openNewTopicModal}
        maxWidth="md"
        fullWidth={true}
        onClose={handleCloseNewTopicModal}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
      />
    </div>
  );
};

export default Toolbar;
