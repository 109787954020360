import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { WEEKDAYS } from '../../../utils/calendar';

const WeekdaysToggleGroup = (props) => {
  const { weekDays, handleClick } = props;
  return (
    <ToggleButtonGroup value={weekDays} onChange={handleClick}>
      <ToggleButton value={WEEKDAYS.monday.id}>
        {WEEKDAYS.monday.str}
      </ToggleButton>
      <ToggleButton value={WEEKDAYS.tuesday.id}>
        {WEEKDAYS.tuesday.str}
      </ToggleButton>
      <ToggleButton value={WEEKDAYS.wednesday.id}>
        {WEEKDAYS.wednesday.str}
      </ToggleButton>
      <ToggleButton value={WEEKDAYS.thursday.id}>
        {WEEKDAYS.thursday.str}
      </ToggleButton>
      <ToggleButton value={WEEKDAYS.friday.id}>
        {WEEKDAYS.friday.str}
      </ToggleButton>
      <ToggleButton value={WEEKDAYS.saturday.id}>
        {WEEKDAYS.saturday.str}
      </ToggleButton>
      <ToggleButton value={WEEKDAYS.sunday.id}>
        {WEEKDAYS.sunday.str}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default WeekdaysToggleGroup;
