import React from 'react';
import { makeStyles } from '@material-ui/core';

import GroupDetail from './GroupDetail';
import GroupMembers from './GroupMembers';

const useStyles = makeStyles((theme) => ({
  groupDetail: {
    marginBottom: theme.spacing(4),
  },
}));

const Group = (props) => {
  const { isLeader, groupData } = props;
  const styles = useStyles();

  return (
    <div className={styles.group}>
      <div className={styles.groupDetail}>
        <GroupDetail isLeader={isLeader} groupData={groupData} />
      </div>
      <div className={styles.groupMembers}>
        <GroupMembers isLeader={isLeader} groupData={groupData} />
      </div>
    </div>
  );
};

export default Group;
