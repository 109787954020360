import React from 'react';

const CallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="40"
    viewBox="0 0 38 40"
  >
    <g id="Group_18" data-name="Group 18" transform="translate(-1403 -61)">
      <path
        id="Shape_1"
        data-name="Shape 1"
        d="M2.169,40A2.1,2.1,0,0,1,0,37.967v-.816C0,30.9,4.682,25.681,10.937,24.393A11.761,11.761,0,0,0,14.2,26.659a11.435,11.435,0,0,0,1.749.648A3.456,3.456,0,0,0,19.2,29.441h4.33a9.5,9.5,0,0,0,7.516-3.593A12.934,12.934,0,0,1,38,37.151v.816A2.1,2.1,0,0,1,35.831,40ZM19.2,27.59a1.392,1.392,0,1,1,0-2.777h4.33a4.225,4.225,0,0,0,4.351-4.077v-.994A2.387,2.387,0,0,1,27.2,19a11.411,11.411,0,0,1-1.083,2.145,4.682,4.682,0,0,1-1.4,1.513,2.552,2.552,0,0,1-1.186.3H19.2a3.515,3.515,0,0,0-2.928,1.517,9.319,9.319,0,0,1-5.437-5.363c0-.008-.006-.016-.01-.025-.012-.028-.022-.056-.033-.085a2.5,2.5,0,0,1-2.164,1.2A2.4,2.4,0,0,1,6.161,17.89V12.1A2.269,2.269,0,0,1,7.187,10.22C7.669,4.509,12.783,0,19,0S30.331,4.509,30.813,10.22A2.27,2.27,0,0,1,31.838,12.1V17.89a2.265,2.265,0,0,1-.988,1.851v.994a7.1,7.1,0,0,1-7.316,6.854Zm7.943-16.508a2.351,2.351,0,0,1,.695-.8A8.7,8.7,0,0,0,19,2.777a8.7,8.7,0,0,0-8.842,7.5,2.347,2.347,0,0,1,.694.8l.006-.014A8.944,8.944,0,0,1,19,5.283,8.938,8.938,0,0,1,27.148,11.082Z"
        transform="translate(1403 61)"
        fill="#8ea4c6"
      />
    </g>
  </svg>
);

export default CallIcon;
