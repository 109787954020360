import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import Layout from '../Sections/Layout';
import CalendarWidget from './Calendar';
import UpcomingEvent from './UpcomingEvent.js';
import Invitations from './Invitations';
import TextMails from './TextMails';
import Notifications from './Notification/index';
import withLanguageDrawer from '../Sections/LanguageDrawer/withLanguageDrawer';
import { messages } from '../Sections/Layout/index';
import {
  getInvitationsAndMediaMails,
  getUpcomingEvents,
  // getOtherInvitations,
} from '../../redux/actions/event';
import { isEditableEvent } from '../../utils/calendar';
import EventInfoModal from '../../components/Modal/EventModals/EventInfoModal/EventInfoModal';
import EventCancelModal from '../../components/Modal/EventModals/EventCancelModal/EventCancelModal';
import { openDrawer } from '../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';

const useStyles = makeStyles((theme) => ({
  textMails: {
    height: 400,
    marginTop: theme.spacing(2),
  },

  invitations: {
    height: theme.spacing(80),
    marginTop: theme.spacing(2),
  },

  calendar: {
    height: theme.spacing(52),
  },

  upcomingEvent: {
    height: theme.spacing(52),
  },

  [theme.breakpoints.down(1080)]: {
    invitations: {
      height: theme.spacing(85),
      '& .MuiCardActions-root': {
        '& svg': {
          display: 'none',
        },
      },
    },
  },
}));

const drawerMessages = { ...messages };

const Dashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUpcomingEvents());
    dispatch(getInvitationsAndMediaMails());
    // dispatch(getOtherInvitations());
  }, [dispatch]);

  const [scoped, setScoped] = useState(null);
  const [isShowInfo, setShowInfo] = useState(false);
  const [isShowCancelModal, setShowCancelModal] = useState(false);
  const [isShowEditModal, setShowEditModal] = useState(false);

  const handleSelectEvent = (event) => {
    setScoped(event);
    setShowInfo(true);
  };

  const handleCloseEventInfoClick = () => {
    setShowInfo(false);
    setScoped(null);
  };

  const handleEditClick = () => {
    setShowInfo(false);
    setShowEditModal(true);
  };

  const handleCancelClick = () => {
    setShowCancelModal(true);
    setShowInfo(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setScoped(null);
  };

  const drawer_type = useSelector((state) => state.drawer.openDrawer);

  useEffect(() => {
    if (!drawer_type) {
      setShowEditModal(false);
      setScoped(null);
    }
  }, [drawer_type]);

  useEffect(() => {
    const openEventDrawer = async () =>
      dispatch(
        openDrawer(
          DRAWER_CONTENT_TYPE.event,
          {
            event: { selectedEvent: scoped },
          },
          null,
          'large'
        )
      );

    !!scoped && isShowEditModal && openEventDrawer();
  }, [dispatch, scoped, isShowEditModal]);

  return (
    <Layout {...props}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={8} className={classes.leftWidget}>
          <Grid container spacing={1} className={classes.leftTop}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={5}
              className={classes.calendar}
            >
              <CalendarWidget
                handleSelectEvent={handleSelectEvent}
              ></CalendarWidget>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={7}
              className={classes.upcomingEvent}
            >
              <UpcomingEvent showDetail={handleSelectEvent} />
            </Grid>
          </Grid>
          <Grid item sm={12} className={classes.invitations}>
            <Invitations showDetail={handleSelectEvent} />
          </Grid>
          <Grid item sm={12} className={classes.textMails}>
            <TextMails />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.notifications}>
          <Notifications />
        </Grid>
      </Grid>
      {!!scoped && isShowInfo && (
        <EventInfoModal
          show={isShowInfo}
          onEditClick={handleEditClick}
          onCancelClick={handleCancelClick}
          onClose={handleCloseEventInfoClick}
          selectedEvent={scoped}
          isEditable={isEditableEvent(scoped, props.memberId)}
        />
      )}
      {!!scoped && isShowCancelModal && (
        <EventCancelModal
          show={isShowCancelModal}
          onClose={handleCloseCancelModal}
          selectedEvent={scoped}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  memberId: state.member.member.member_id,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLanguageDrawer(Dashboard, drawerMessages));
