import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import * as api from '../../config/api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0px`,
    borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
  },
  title: {
    fontWeight: 400,
    fontSize: '23px',
    marginBottom: '8px',
  },
  cover: {
    width: '475px',
    height: '281px',
    marginRight: '16px',
  },
  createAt: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#7d8592',
    marginRight: '16px',
  },
  comments: {
    fontSize: `14px`,
    fontWeight: 600,
  },
  main: {
    marginTop: '24px',
    display: 'flex',
  },
  contentWrapper: {
    padding: `0px 16px`,
  },
  content: {
    marginBottom: `16px`,
    height: `228px`,
    fontWeight: 400,
    fontSize: '16px',
    color: '#0A1629',
  },
  viewTopic: {
    color: '#94C03D',
    fontSize: '16px',
    fontWeight: 400,
  },
}));

const TopicCard = (props) => {
  const { topic } = props;
  const classes = useStyles();
  const { group_id } = useParams();
  return (
    <div className={classes.root}>
      <p className={classes.title}>{topic.topic_title}</p>
      <p>
        <span className={classes.createAt}>
          {format(new Date(topic.create_date), 'Pp')}
        </span>
        <span className={classes.comments}>
          {topic.comments} {topic.comments > 1 ? 'Comments' : 'Comment'}
        </span>
      </p>
      <div className={classes.main}>
        <img
          src={`${api.apiUrl}/${topic.cover_image_url}`}
          className={classes.cover}
          alt="cool"
        />
        <div className={classes.contentWrapper}>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: topic.topic_content }}
          ></div>

          <Link
            to={`${process.env.PUBLIC_URL}/groups/${group_id}/forum/topics/${topic.topic_id}`}
            className={classes.viewTopic}
          >
            View Topic &#62;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopicCard;
