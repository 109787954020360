export const SET_REPORT_BUG_ALERT = 'SET_REPORT_BUG_ALERT';

export const POST_REPORT_START = 'POST_REPORT_START';
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS';

export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
