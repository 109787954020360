import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { IconSettings } from '../icons';
import { injectIntl } from 'react-intl';
const Recording = (props) => {
  const {
    intl: { formatMessage },
  } = props;
  const messages = {
    callsettings: {
      id: 'app.home.callsettings',
      defaultMessage: 'Settings',
    },
  };
  return (
    <Dropdown.Item eventKey={props.eveneKey} onClick={props.trigger}>
      <div className="amera-icon">
        <IconSettings />
      </div>
      <span> {formatMessage(messages.callsettings)} </span>
    </Dropdown.Item>
  );
};

export default injectIntl(Recording);
