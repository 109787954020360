import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import { find } from 'lodash';
import { connect, useSelector } from 'react-redux';
import {
  isBefore,
  isEqual,
  add,
  sub,
  getDay,
  isSameDay,
  set,
  parseISO,
  startOfDay,
  differenceInMinutes,
  endOfDay,
  isValid,
} from 'date-fns';

import AmeraAccordion from '../AmeraAccordion/AmeraAccordion';
import AmeraAccordionControllable from '../AmeraAccordion/AmeraAccordionControllable';
import EventSettingsModal from '../../components/Modal/EventModals/EventSettingsModal';
import OneTimeEventCheckbox from '../../components/Modal/EventModals/OneTimeEventCheckbox';
import AllDayCheckbox from '../../components/Modal/EventModals/AllDayCheckbox';
import MultiDayCheckbox from '../../components/Modal/EventModals/MultiDayCheckbox';
import {
  RecurringEventCheckbox,
  RecurringEventDetail,
} from '../../components/Modal/EventModals/RecurringEventCheckbox';
import EventTypeSelect from '../../components/Modal/EventModals/EventTypeSelect';
import ColorVerticalSelect from '../../components/Modal/EventModals/ColorVerticalSelect';
import InviteModeSwitch from '../../components/Modal/EventModals/InviteModeSwitch';
import MemberAutocomplete from '../../components/MemberAutocomplete';
import DateTimeLine from '../../components/Modal/EventModals/DateTimeLine';
import LocationModal from '../../components/Modal/EventModals/LocationModal';
import SaveLocationModal from '../../components/Modal/EventModals/SaveLocationModal';
import EventURLInput from '../../components/Modal/EventModals/EventURLInput';
import EventName from '../../components/Modal/EventModals/EventName';
import CustomComposeEditor from '../../pages/Email/SharedComponents/Compose/CustomComposeEditor';
import AttachButton from '../../components/Modal/EventModals/AttachButton';
import AttachmentList from '../../components/Modal/EventModals/AttachmentList';
import EventModalSnack from '../../components/Modal/EventModals/EventModalSnack';
import RecurrenceDialog from '../../components/Modal/EventModals/RecurrenceDialog';
import GroupMembersChips from '../../components/Modal/EventModals/GroupMembersChips';
import GroupAutocomplete from '../../components/GroupAutocomplete';
import { ProgressBar } from '../../components/ProgressBar';
import MemberLocationSelect from '../../components/Modal/EventModals/MemberLocationSelect';
import StreetAutocomplete from '../../components/StreetAutocomplete';
import usePrevious from '../../hooks/usePrevious';
import useGoogleMap from '../../hooks/useGoogleMap';
import { validateScheduleEvent } from '../../utils/validator/ScheduleEvent';
import CardButton from '../Dashboard/CardButton';
import {
  INVITE_MODE,
  LOCATION_TYPES,
  EVENT_TYPES,
  RECURRENCE_FREQ,
  RECURRENCE_END,
  ADDRESS_TYPES,
  SNACK_TIMEOUT,
  recurrenceText,
  prepareForm,
  prepareChangesForms,
  roundToNextHour,
  parseGroupToState,
  defaultEventName,
  eventTitle,
  getDateByTime,
  defaultSetting,
  eventTypeText,
  getLocation,
} from '../../utils/calendar';
import {
  addScheduleEvent,
  postEventAttachment,
  putModifyEvent,
} from '../../redux/actions/schedule_event';
import { postLocations } from '../../redux/actions/member';
import { closeDrawer } from '../../redux/actions/drawer';
const FileType = require('file-type/browser');

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h3': {
      fontSize: 20,
    },
  },
  content: {
    padding: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(2),
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 400,
    '& button': {
      padding: 8,
    },
  },
  inviteTab: {
    marginBottom: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& button': {
      textTransform: 'capitalize',
      marginRight: theme.spacing(2),
    },
  },
  datetime: {
    marginBottom: theme.spacing(2),
  },
  gap: {
    gap: theme.spacing(2),
  },
}));

const defaultSnack = { isShow: false, variant: 'success', message: '' };
const CLOSE_TIMEOUT = SNACK_TIMEOUT + 100;
const MOVE_HOURS = 1;

const Event = (props) => {
  const {
    colors,
    pageSettings,
    displayTz = Intl.DateTimeFormat().resolvedOptions().timeZone,
    isCallingApi,
    isModifying,
    error,
    groupList,
    memberId,
    dispatch,
    contactOptions,
    event,
    drawerWidth,
  } = props;

  const { getPlaceDetailsFromPlaceId, places } = useGoogleMap();

  const { inputs, selectedEvent } = event;
  const classes = useStyles();

  const timeFormat = useSelector(({ member: { memberSetting } }) => {
    return memberSetting && memberSetting.time_format;
  });

  const memberCountry = useSelector((s) => s.member.memberInfo.country_code[0]);
  const locationObjects = useSelector((s) =>
    s.member.memberInfo.location_information.filter(
      (lo) => lo.location_type !== ADDRESS_TYPES.billing
    )
  );

  const pageType = 'Calendar';
  const calendarSettings = find(pageSettings, (o) => o.page_type === pageType);

  const [isShowEventSettingModal, setShowEventSettingModal] = useState(false);
  const [isShowEventLocationModal, setShowEventLocationModal] = useState(false);
  const [isShowSaveLocationModal, setShowSaveLocationModal] = useState(false);

  // Uploading files
  const [snackState, setSnackState] = useState(defaultSnack);
  const [errors, setErrors] = useState({});
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressShow, setUploadProgressShow] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [expandAttachment, setExpandAttachment] = useState(true);
  const [showLocationSaveButton, setShowLocationSaveButton] = useState(false);

  // locations
  const [editLoation, setEditLocation] = useState(false);
  const initMyLocation =
    selectedEvent && selectedEvent.location_mode === LOCATION_TYPES.own
      ? locationObjects.find(
          (lo) => lo.id === selectedEvent.event_location.member_location_id
        )
      : undefined;
  const [myLocation, setMyLocation] = useState(initMyLocation);
  const [findLocation, setFindLocation] = useState(
    selectedEvent && selectedEvent.location_mode === LOCATION_TYPES.lookup
      ? selectedEvent.event_location
      : undefined
  );
  const [findLocationByMap, setFindLocationByMap] = useState(
    selectedEvent && selectedEvent.location_mode === LOCATION_TYPES.find
      ? selectedEvent.event_location
      : undefined
  );

  // Recurrence dialog
  const [isShowRecurrence, setShowRecurrence] = useState(false);

  const defaultState = !!!selectedEvent
    ? {
        name: '',
        colorId: 'no-color',
        description: '',
        isShowInviteModeSelector: true,
        inviteMode: INVITE_MODE.contacts,
        invitedMembers: [],
        invitedGroup: undefined,
        eventTimeZone: displayTz,
        start: roundToNextHour(new Date()),
        end: add(roundToNextHour(new Date()), { minutes: 30 }),
        isFullDay: false,
        type: EVENT_TYPES.meeting,
        recurrence: RECURRENCE_FREQ.none,
        endCondition: RECURRENCE_END.date,
        repeatWeekDays: [getDay(new Date())],
        endDate: add(new Date(), { years: 1 }),
        repeatTimes: 2,
        locationMode: LOCATION_TYPES.find,
        selectedPlace: {},
        memberLocation: undefined,
        eventUrl: '',
        coverAttachmentId: null,
      }
    : {
        event_id: selectedEvent.event_id,
        name: selectedEvent.event_name,
        type: selectedEvent.event_type,
        colorId: !!selectedEvent.event_color_id
          ? colors.find((co) => co.id === selectedEvent.event_color_id).id
          : 'no-color',
        description: selectedEvent.event_description,
        invitedGroup: !!selectedEvent.group_info
          ? parseGroupToState(
              groupList.find(
                (glo) => glo.group_id === selectedEvent.group_info.group_id
              ),
              memberId,
              false,
              selectedEvent.invitations
            )
          : {},
        inviteMode: !!selectedEvent.group_info
          ? INVITE_MODE.group
          : INVITE_MODE.contacts,
        invitations: selectedEvent.invitations,
        eventTimeZone: selectedEvent.event_tz,
        start: parseISO(selectedEvent.start),
        end: parseISO(selectedEvent.end),
        recurrence: selectedEvent.event_recurrence_freq,
        endCondition: selectedEvent.end_condition,
        endDate: new Date(selectedEvent.end_date_datetime),
        repeatWeekDays: JSON.parse(selectedEvent.repeat_weekdays),
        repeatTimes: selectedEvent.repeat_times,
        isFullDay: selectedEvent.is_full_day,
        locationMode: selectedEvent.location_mode,
        eventUrl: selectedEvent.event_url,
        sequence_id: selectedEvent.sequence_id,
        coverAttachmentId: selectedEvent.cover_attachment_id,
        selectedPlace: selectedEvent?.event_location,
        member_location_id: !!selectedEvent?.event_location
          ? selectedEvent.event_location.member_location_id
          : undefined,
      };

  const [eventState, setEventState] = useState(defaultState);

  useEffect(() => {
    if (
      !!selectedEvent &&
      !!selectedEvent.attachments &&
      selectedEvent.attachments.length > 0 &&
      !!selectedEvent.attachments[0]
    ) {
      setAttachedFiles((ps) => selectedEvent.attachments);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (eventState.selectedPlace && eventState.selectedPlace.place_id) {
      const flag =
        locationObjects.findIndex(
          (location) => location.place_id === eventState.selectedPlace.place_id
        ) < 0
          ? true
          : false;

      setShowLocationSaveButton(flag);
    }
  }, [eventState, locationObjects]);

  useEffect(() => {
    if (places && places.length) {
      setEventState((ps) => ({
        ...ps,
        selectedPlace: places[0],
        locationMode: LOCATION_TYPES.lookup,
      }));

      setMyLocation(undefined);
      setFindLocation(places[0]);
      setFindLocationByMap(undefined);
      setEditLocation(false);
    }
  }, [places]);

  const prevCallingApi = usePrevious(isCallingApi);
  const prevModyfing = usePrevious(isModifying);

  // Fill state when passed props
  useEffect(() => {
    const startDateTime = inputs?.startDateTime;
    const endDateTime = inputs?.endDateTime;
    const contacts = inputs?.contacts;
    const group = inputs?.group;
    if (!!startDateTime && !!endDateTime) {
      setEventState((ps) => ({
        ...ps,
        start: startDateTime,
        end: endDateTime,
      }));
    }

    if (!!contacts) {
      setEventState((ps) => ({
        ...ps,
        inviteMode: INVITE_MODE.contacts,
        isShowInviteModeSelector: contacts.length === 0,
        invitedMembers: contacts,
      }));
    }

    if (!!group) {
      setEventState((ps) => ({
        ...ps,
        inviteMode: INVITE_MODE.group,
        isShowInviteModeSelector: false,
        invitedGroup: parseGroupToState(group, memberId),
      }));
    }
  }, [groupList, inputs, memberId]);

  const handleClose = useCallback(() => {
    setEventState(defaultState);
    setAttachedFiles([]);
    setUploadProgressShow(false);
    setUploadProgress(0);
    setShowRecurrence(false);
    setErrors({});
    setChangeName(false);
    dispatch(closeDrawer());
  }, [defaultState, dispatch]);

  useEffect(() => {
    if (
      (prevCallingApi === true && isCallingApi === false) ||
      (prevModyfing === true && isModifying === false)
    ) {
      if (!error) {
        setSnackState({
          isShow: true,
          variant: 'success',
          message: `Event ${
            !!selectedEvent ? 'updated' : 'added'
          } successfully`,
        });
        setTimeout(() => handleClose(), [CLOSE_TIMEOUT]);
      } else {
        setSnackState({
          isShow: true,
          variant: 'error',
          message: error.description,
        });
      }
    }
  }, [
    handleClose,
    error,
    prevCallingApi,
    isCallingApi,
    selectedEvent,
    isModifying,
    prevModyfing,
  ]);

  const drawer_type = useSelector((state) => state.drawer.openDrawer);

  useEffect(() => {
    if (!drawer_type) {
      handleClose();
    }
  }, [drawer_type, handleClose]);

  const handleSubmit = () => {
    //TODO: validate event name here
    const formErrors = validateScheduleEvent(eventState);

    if (Object.keys(formErrors).length === 0) {
      setErrors({});

      if (!!selectedEvent) {
        const preparedForm = prepareChangesForms(
          eventState,
          attachedFiles,
          changeName
        );
        preparedForm.set('mode', 'full');
        dispatch(putModifyEvent(preparedForm));
      } else {
        const preparedForm = prepareForm(eventState, attachedFiles, changeName);
        dispatch(addScheduleEvent(preparedForm));
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleFullDayClick = (e) => {
    e.persist();
    if (e.target.checked) {
      // Is full day
      setEventState((ps) => ({
        ...ps,
        isFullDay: true,
        start: startOfDay(ps.start),
        end: endOfDay(ps.end),
      }));
    } else {
      setEventState((ps) => ({
        ...ps,
        isFullDay: false,
        // end: add(ps.start, { hours: MOVE_HOURS }),
      }));
    }
  };

  const handleLocationModeChange = (e) => {
    const mode = e.target.value;

    // Set the mode, if OWN, then make the first one selected
    setEventState((ps) => {
      if (mode === LOCATION_TYPES.own) {
        return {
          ...ps,
          locationMode: mode,
          memberLocation:
            locationObjects?.length > 0 ? locationObjects[0] : undefined,
        };
      } else {
        return { ...ps, locationMode: mode };
      }
    });
  };

  const handleEventTypeChange = (e) => {
    const eventType = e.target.value;

    const current = eventState.start || new Date();
    let start = roundToNextHour(current);
    let end = add(start, { minutes: 30 });

    if (
      eventType === EVENT_TYPES.breakfast ||
      eventType === EVENT_TYPES.lunch ||
      eventType === EVENT_TYPES.dinner ||
      eventType === EVENT_TYPES.party
    ) {
      let from, to;

      if (
        calendarSettings &&
        calendarSettings['extra_settings'] &&
        calendarSettings['extra_settings'][eventType] &&
        calendarSettings['extra_settings'][eventType]['start'] &&
        calendarSettings['extra_settings'][eventType]['end']
      ) {
        from = calendarSettings['extra_settings'][eventType]['start'];
        to = calendarSettings['extra_settings'][eventType]['end'];
      } else {
        from = defaultSetting[eventType]['start'];
        to = defaultSetting[eventType]['end'];
      }

      start = getDateByTime(from, current);
      end = getDateByTime(to, current);
    }

    setChangeName(false);

    setEventState((ps) => ({
      ...ps,
      type: eventType,
      start,
      end,
    }));
  };

  const handleDateInput = (date, type, isFreeSolo = false) => {
    // if user input manually
    if (isFreeSolo && date && date.target && date.target.value) {
      const input = date.target.value;
      // #:## am/AM/pm/PM only
      const matches = input
        .toLowerCase()
        .match(/(\d{1,2}):(\d{2})(\s)*([ap]m)?/);

      if (!matches || !matches.length) return;
      if (!matches[4] && !timeFormat.includes('24')) matches[4] = 'pm';

      const input_hours =
        (parseInt(matches[1], 10) % 12) + (matches[4] === 'pm' ? 12 : 0);
      const input_minites = matches[2];
      date = set(eventState.start || new Date(), {
        hours: input_hours,
        minutes: input_minites,
        seconds: 0,
        milliseconds: 0,
      });
    }

    if (isValid(date)) {
      setEventState((ps) => {
        // Moving start date ALWAYS maintains the initial duration (by means of moving the end date)
        if (type === 'start') {
          const prevDifferenceMinutes = differenceInMinutes(ps.end, ps.start);

          return {
            ...ps,
            start: date,
            end: add(date, { minutes: prevDifferenceMinutes }),
            weekDays: [getDay(date)],
          };
        } else if (
          type === 'end' &&
          (isBefore(date, ps.start) || isEqual(date, ps.start))
        ) {
          // We need to move the start date
          const movedStart = sub(date, { hours: MOVE_HOURS });
          return {
            ...ps,
            start: movedStart,
            end: date,
            weekDays: [getDay(movedStart)],
          };
        } else {
          return {
            ...ps,
            [type]: date,
            weekDays: [getDay(ps.start)],
          };
        }
      });
    }
  };

  const handleClearClick = (fileObject) => {
    //TODO: Delete the uploaded file to don't leave orphans
    // Clear from state
    setAttachedFiles((ps) =>
      ps.filter((afo) => afo.file_id !== fileObject.fileId)
    );

    // If we delete what is meant to be cover
    if (eventState.coverAttachmentId === fileObject.member_file_id) {
      setEventState((ps) => ({ ...ps, coverAttachmentId: null }));
    }
  };

  const handleSelectFileToAttach = async (event) => {
    // event.persist();
    // One file at a time for now
    const file = Array.from(event.target.files)[0];

    const result = await FileType.fromBlob(file);

    let formData = new FormData();
    formData.set('file', file);
    formData.set('fileName', file.name);
    formData.set('size', file.size);
    formData.set('mime', !!result ? result.mime : null);

    setUploadProgressShow(true);
    const fileData = await dispatch(
      postEventAttachment(formData, setUploadProgress)
    );
    setUploadProgressShow(false);
    setAttachedFiles((ps) => [...ps, fileData]);
  };

  const handleRecurrenceClick = () => {
    setShowRecurrence(true);
  };

  const handleRecurrenceCheck = (e) => {
    if (e.target.checked) {
      handleRecurrenceClick();
    } else {
      setEventState((ps) => ({ ...ps, recurrence: RECURRENCE_FREQ.none }));
    }
  };

  const handleOneTimeCheck = (e) => {
    if (e.target.checked) {
      setEventState((ps) => ({ ...ps, recurrence: RECURRENCE_FREQ.none }));
    } else {
      e.target.checked = true;
    }
  };

  const handleWeekDayClick = (e, selected) => {
    // We will set the repeat times = x2 selected days
    setEventState((ps) => {
      return {
        ...ps,
        repeatWeekDays: selected,
        repeatTimes:
          ps.repeatTimes < selected.length
            ? 2 * selected.length
            : ps.repeatTimes,
      };
    });
  };

  const handleChangeRepeatTimes = (e) => {
    e.persist();

    const inputTimes = e.target.value;
    let writeTimes;
    setEventState((ps) => {
      if (ps.recurrence === RECURRENCE_FREQ.weekdays) {
        // if the mode is specific days - don't let have less events then days selected
        writeTimes =
          inputTimes < ps.repeatWeekDays.length
            ? ps.repeatWeekDays.length
            : inputTimes;
      } else {
        writeTimes = inputTimes;
      }
      return { ...ps, repeatTimes: writeTimes };
    });
  };

  const handleDurationSelect = (v) => {
    setEventState((ps) => ({
      ...ps,
      end: v,
      isFullDay: false,
    }));
  };

  const handleMultiDayClick = (e) => {
    e.persist();
    if (e.target.checked) {
      setEventState((ps) => ({ ...ps, end: add(ps.end, { days: 1 }) }));
    } else {
      setEventState((ps) => {
        if (ps.isFullDay) {
          return {
            ...ps,
            start: startOfDay(ps.start),
            end: endOfDay(ps.start),
          };
        } else {
          return {
            ...ps,
            end: add(ps.start, { hours: MOVE_HOURS }),
          };
        }
      });
    }
  };

  const handleSelectGroupToInvite = (e, v) => {
    setEventState((ps) => ({
      ...ps,
      invitedGroup: parseGroupToState(v, memberId),
    }));
  };

  const handleGroupMemberClick = (contactMemberId) => {
    setEventState((ps) => {
      return {
        ...ps,
        invitedGroup: {
          ...ps.invitedGroup,
          members: ps.invitedGroup.members.map((mo) =>
            mo.contact_member_id === contactMemberId
              ? { ...mo, isShouldInvite: !mo.isShouldInvite }
              : mo
          ),
        },
      };
    });
  };

  const handleSelectedPlace =
    (locationMode, isOwnLocation = false) =>
    async (v) => {
      const updateState = {
        selectedPlace: v,
        locationMode: locationMode,
      };
      if (isOwnLocation === true) {
        updateState.memberLocation = v;
      }

      setEventState((ps) => ({
        ...ps,
        ...updateState,
      }));

      setMyLocation(isOwnLocation ? v : undefined);
      setFindLocationByMap(isOwnLocation ? null : v);
      setFindLocation(undefined);
      setEditLocation(false);
    };

  const invitationsToMembersHelper = () =>
    !!eventState.invitations &&
    eventState.invitations.length > 0 &&
    !!eventState.invitations[0]
      ? eventState.invitations
          .map((io) => io.invite_member_id)
          .map((contact_member_id) =>
            contactOptions.find(
              (coo) => coo.contact_member_id === contact_member_id
            )
          )
      : [];

  const getTitle = () => {
    if (!!selectedEvent) {
      return `Update ${eventTypeText(selectedEvent.event_type)}`;
    } else {
      return eventTitle(eventState, memberId);
    }
  };

  const showLocationModal = (e) => {
    e.stopPropagation();
    setShowEventLocationModal(true);
  };

  const closeLocationModal = () => {
    setShowEventLocationModal(false);
  };

  const showSaveLocationModal = (e) => {
    e.stopPropagation();
    setShowSaveLocationModal(true);
  };

  const closeSaveLocationModal = () => {
    setShowSaveLocationModal(false);
  };

  const handleLookupInput = async (suggestion) => {
    if (!!suggestion?.place_id) {
      await getPlaceDetailsFromPlaceId(suggestion.place_id);
    }
  };

  const saveLocation = (type, description) => {
    if (eventState.selectedPlace) {
      let location = getLocation(
        eventState.selectedPlace,
        type,
        memberCountry.id,
        description
      );

      dispatch(postLocations(JSON.stringify({ locations: [location] }))).then(
        (result) => {
          const locations = result?.data?.location_information || [];
          setEventState((ps) => ({
            ...ps,
            memberLocation: locations.find((l) => l.id === result.id),
          }));
        }
      );
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>{getTitle()}</h3>
        <div className={classes.setting}>
          <IconButton onClick={() => setShowEventSettingModal(true)}>
            <SettingsIcon />
          </IconButton>
          <span>SETTINGS</span>
        </div>
      </div>
      <div className={classes.content}>
        <AmeraAccordion label="Event Type/Color">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EventTypeSelect
                variant="standard"
                value={eventState.type}
                handleChange={handleEventTypeChange}
              />
            </Grid>
            <Grid item xs={6}>
              <ColorVerticalSelect
                colorId={eventState.colorId}
                onChange={(e) =>
                  setEventState((ps) => ({
                    ...ps,
                    colorId: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </AmeraAccordion>

        <AmeraAccordion label="Schedule & Recurrence">
          <Grid container spacing={1} className={classes.datetime}>
            <DateTimeLine
              start={eventState.start}
              end={eventState.end}
              isFullDay={eventState.isFullDay}
              onDateChange={handleDateInput}
              onDurationChange={handleDurationSelect}
              error={errors.datetime}
              isFreeSolo={true}
            />
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <OneTimeEventCheckbox
                checked={eventState.recurrence === RECURRENCE_FREQ.none}
                onChange={handleOneTimeCheck}
                className={classes.label}
              />
            </Grid>
            <Grid item xs={3}>
              <AllDayCheckbox
                checked={eventState.isFullDay}
                onChange={handleFullDayClick}
                className={classes.label}
              />
            </Grid>
            <Grid item xs={3}>
              <MultiDayCheckbox
                checked={!isSameDay(eventState.start, eventState.end)}
                onChange={handleMultiDayClick}
                className={classes.label}
              />
            </Grid>
            <Grid item xs={3}>
              <RecurringEventCheckbox
                checked={eventState.recurrence !== RECURRENCE_FREQ.none}
                onChange={handleRecurrenceCheck}
                className={classes.label}
              />
            </Grid>
          </Grid>
        </AmeraAccordion>

        <AmeraAccordion label="Attendees">
          {eventState.isShowInviteModeSelector && (
            <div className={classes.inviteTab}>
              <InviteModeSwitch
                inviteMode={eventState.inviteMode}
                handleChange={(e, v) =>
                  setEventState((ps) => ({
                    ...ps,
                    inviteMode: v,
                  }))
                }
              />
            </div>
          )}
          {eventState.inviteMode === INVITE_MODE.contacts && (
            <MemberAutocomplete
              variant="standard"
              handleChange={(e, v) => {
                setEventState((ps) => ({
                  ...ps,
                  invitedMembers: v,
                }));
              }}
              isAddMember={false}
              label="Invite contacts to event"
              values={
                !!selectedEvent
                  ? invitationsToMembersHelper()
                  : eventState.invitedMembers
              }
            />
          )}
          {eventState.inviteMode === INVITE_MODE.group && (
            <>
              <GroupAutocomplete
                variant="standard"
                handleChange={handleSelectGroupToInvite}
                label="Invite Group members"
                isShowOnlyLeader
                multiple={false}
                disabled={!eventState.isShowInviteModeSelector}
                values={eventState.invitedGroup}
              />
              <GroupMembersChips
                invitedGroup={eventState.invitedGroup}
                handleChipClick={handleGroupMemberClick}
              />
            </>
          )}
        </AmeraAccordion>

        {(eventState.type === EVENT_TYPES.meeting ||
          eventState.type === EVENT_TYPES.personal ||
          eventState.type === EVENT_TYPES.breakfast ||
          eventState.type === EVENT_TYPES.lunch ||
          eventState.type === EVENT_TYPES.dinner ||
          eventState.type === EVENT_TYPES.party) && (
          <AmeraAccordion label="Location">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {eventState?.selectedPlace?.vendor_formatted_address ||
                eventState?.selectedPlace?.formatted_address
                  ? `${
                      eventState?.selectedPlace?.name
                        ? eventState?.selectedPlace?.name + ','
                        : ''
                    } ${
                      eventState?.selectedPlace?.vendor_formatted_address ||
                      eventState?.selectedPlace?.formatted_address
                    }`
                  : ''}
              </Grid>

              {editLoation || !!!eventState?.selectedPlace?.place_id ? (
                <>
                  <Grid item xs={12}>
                    <MemberLocationSelect
                      value={myLocation}
                      handleChange={handleSelectedPlace(
                        LOCATION_TYPES.own,
                        true
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StreetAutocomplete
                      label="Find a location"
                      street={
                        !!findLocation
                          ? findLocation.formatted_address ||
                            findLocation.vendor_formatted_address
                          : ''
                      }
                      types={['geocode', 'establishment']}
                      handleChange={handleLookupInput}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-end"
                    className={classes.gap}
                  >
                    {showLocationSaveButton && (
                      <CardButton
                        variant="okFilled"
                        text="Save Location"
                        onClick={showSaveLocationModal}
                      />
                    )}
                    <CardButton
                      variant="okFilled"
                      text="Search in map"
                      onClick={showLocationModal}
                    />
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  justify="flex-end"
                  className={classes.gap}
                >
                  {showLocationSaveButton && (
                    <CardButton
                      variant="okFilled"
                      text="Save Location"
                      onClick={showSaveLocationModal}
                    />
                  )}
                  <CardButton
                    variant="okFilled"
                    text="Edit"
                    onClick={() => {
                      setEditLocation(true);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </AmeraAccordion>
        )}

        {(eventState.type === EVENT_TYPES.video ||
          eventState.type === EVENT_TYPES.webinar) && (
          <AmeraAccordion label="Event URL">
            <EventURLInput
              variant="standard"
              meetingType={eventState.type}
              locationData={eventState.event_url}
              onInputChange={(v) =>
                setEventState((ps) => ({ ...ps, event_url: v }))
              }
              error={errors.location}
            />
          </AmeraAccordion>
        )}

        <AmeraAccordion label="Title">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EventName
                variant="standard"
                value={eventState.name}
                onChange={(input) => {
                  setChangeName(true);
                  setEventState((ps) => ({ ...ps, name: input }));
                }}
                defaultName={defaultEventName(eventState)}
                error={errors.name}
              />
            </Grid>
          </Grid>
        </AmeraAccordion>

        <AmeraAccordion label="Description">
          <CustomComposeEditor
            multiline
            rowsMax={20}
            value={eventState.description}
            handleChangebd={(v) => {
              setEventState((ps) => ({
                ...ps,
                description: v,
              }));
            }}
            placeholder={'Your event details...'}
          />
        </AmeraAccordion>

        <AmeraAccordionControllable
          label="Attachment"
          expanded={expandAttachment}
          onChange={() => setExpandAttachment(!expandAttachment)}
          button={<AttachButton onChange={handleSelectFileToAttach} />}
        >
          {uploadProgressShow && <ProgressBar value={uploadProgress} />}
          {attachedFiles.length > 0 && (
            <AttachmentList
              attachedFiles={attachedFiles}
              onClearClick={handleClearClick}
              cover={eventState.coverAttachmentId}
              onSelectCover={(v) =>
                setEventState((ps) => ({
                  ...ps,
                  coverAttachmentId: ps.coverAttachmentId === v ? null : v,
                }))
              }
            />
          )}
        </AmeraAccordionControllable>
      </div>

      <div className={classes.footer}>
        <div className={classes.buttons}>
          <CardButton variant="info" text="Cancel" onClick={handleClose} />
          <CardButton
            variant="okFilled"
            text={!!selectedEvent ? 'Update' : 'Save'}
            onClick={handleSubmit}
          />
        </div>
      </div>

      <EventModalSnack
        snackState={snackState}
        timeout={SNACK_TIMEOUT}
        onClose={() => setSnackState((ps) => ({ ...ps, isShow: false }))}
      />
      {isShowRecurrence && (
        <RecurrenceDialog
          start={eventState.start}
          isOpen={isShowRecurrence}
          handleClose={() => setShowRecurrence(false)}
          recurrenceOption={eventState.recurrence}
          handleOptionChange={(e) =>
            setEventState((ps) => ({ ...ps, recurrence: e.target.value }))
          }
          endOption={eventState.endCondition}
          handleChangeMode={(e) =>
            setEventState((ps) => ({ ...ps, endCondition: e.target.value }))
          }
          repeatTimes={eventState.repeatTimes}
          handleChangeRepeatTimes={handleChangeRepeatTimes}
          endDate={eventState.endDate}
          handleChangeEndDate={(v) =>
            setEventState((ps) => ({ ...ps, endDate: v }))
          }
          weekDays={eventState.repeatWeekDays}
          handleWeekdayClick={handleWeekDayClick}
        />
      )}
      {eventState.recurrence !== RECURRENCE_FREQ.none && (
        <RecurringEventDetail
          recurrenceText={recurrenceText(
            eventState.recurrence,
            eventState.endCondition,
            eventState.endDate,
            eventState.repeatTimes,
            eventState.repeatWeekDays
          )}
          onClick={handleRecurrenceClick}
        />
      )}

      {isShowEventSettingModal && (
        <EventSettingsModal
          open={isShowEventSettingModal}
          onClose={() => setShowEventSettingModal(false)}
          data={calendarSettings}
        />
      )}

      {isShowEventLocationModal && (
        <LocationModal
          open={isShowEventLocationModal}
          onClose={closeLocationModal}
          meetingType={eventState.type}
          locationMode={LOCATION_TYPES.find}
          selectedPlace={findLocationByMap}
          onModeChange={handleLocationModeChange}
          onSavedSelect={(v) => {
            setEventState((ps) => ({ ...ps, memberLocation: v }));
          }}
          error={errors.location}
          memberLocation={eventState.memberLocation}
          onSelectedPlace={handleSelectedPlace(LOCATION_TYPES.find, false)}
          drawerWidth={drawerWidth}
        />
      )}
      {isShowSaveLocationModal && (
        <SaveLocationModal
          open={isShowSaveLocationModal}
          locationName={eventState?.selectedPlace?.name}
          onClose={closeSaveLocationModal}
          onSave={saveLocation}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pageSettings: state.member.pageSettings,
  colors: state.scheduleEvents.colors,
  schedulerSetting: state.schedulerSetting.data,
  isCallingApi: state.scheduleEvents.isCreating,
  isModifying: state.scheduleEvents.isModifying,
  memberId: state.member.member.member_id,
  error: state.scheduleEvents.error,
  contactOptions: state.contact.contacts,
  drawerWidth: state.drawer.width,
});

export default connect(mapStateToProps)(Event);
