import React, { useEffect, useState, useRef } from 'react';
import ContactModal from '../../components/Modal/ContactModal';
import { ApiEmailService } from '../../config/apiService';
import {
  getEmailQueryParams,
  getUnstyledHtmlBody,
  mailButtonGroup,
} from '../../utils/email';
import { scrollIntoView } from '../../utils/general';
import { EmailPageWrapper } from './SharedComponents/EmaliPageWrapper';
import { apiPaths } from '../../config/apiPaths';

const Starred = (props) => {
  const [emails, setEmails] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedEmailXref, setSelectedEmailXref] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const cleanup = useRef(false);

  const [forward, setForward] = useState({
    open: false,
    id: null,
    folder_id: null,
  });

  const getEmails = async (start_id) => {
    let params = getEmailQueryParams(
      start_id,
      selectSort,
      props.search,
      null,
      props.contact_filter
    );

    let tmp_data = await ApiEmailService.getEmails('getStarred', params);
    let { data, total } = tmp_data !== undefined ? tmp_data : {};

    if (data !== undefined && !cleanup.current) {
      data.forEach((email) => {
        email.noStyleBody = getUnstyledHtmlBody(email.body);
      });

      setSelectedEmail(null);
      if (start_id) {
        setEmails([...emails, ...data]);
      } else {
        setEmails([...data]);
      }
    }

    if (total && !cleanup.current) {
      setTotalCount(total);
    }
  };

  const getEmailDetails = async (id) => {
    let data = await ApiEmailService.getEmailDetails(
      'getStarredEmailDetails',
      id
    );
    setSelectedEmail({ ...data, mail_id: id });
  };

  const selectEmail = (id, xref_id, multi = false, is_detail_view = false) => {
    if (is_detail_view) {
      if (Array.isArray(id)) {
        getEmailDetails(id[id.length - 1]).then(() => {
          scrollIntoView('selected-mail-details');
        });
      } else {
        getEmailDetails(id).then(() => {
          scrollIntoView('selected-mail-details');
        });
      }
      return;
    }
    if (Array.isArray(id)) {
      let is_same =
        selectedEmails.length === id.length &&
        selectedEmailXref.length === xref_id.length &&
        selectedEmails.every(function (element, index) {
          return element === id[index];
        }) &&
        selectedEmailXref.every(function (element, index) {
          return element === xref_id[index];
        });
      if (is_same) {
        setSelectedEmails([]);
        setSelectedEmailXref([]);
      } else {
        setSelectedEmails(id);
        setSelectedEmailXref(xref_id);
        const index = emails.findIndex((x) => x.mail_id === id[id.length - 1]);

        if (index !== -1 && emails[index].read === false) {
          const _emails = [...emails];
          _emails[index].read = true;
          setEmails(_emails);
        }
        getEmailDetails(id[id.length - 1]).then(() => {});
      }
    } else {
      const index = emails.findIndex((x) => x.mail_id === id);

      if (index !== -1 && emails[index].read === false) {
        const _emails = [...emails];
        _emails[index].read = true;
        setEmails(_emails);
      }
      getEmailDetails(id).then(() => {});

      let findItem = selectedEmails.findIndex((el) => el === id);
      let findItemXref = selectedEmailXref.findIndex((el) => el === xref_id);
      if (findItem === -1) {
        setSelectedEmails([...selectedEmails, id]);
        if (multi) {
          setSelectedEmailXref([...selectedEmailXref, xref_id]);
        } else {
          setSelectedEmailXref([xref_id]);
        }
      } else {
        if (findItemXref !== -1) {
          // Delete current mail!
          let xrefBackup = [...selectedEmailXref];
          xrefBackup.splice(findItemXref, 1);
          setSelectedEmailXref([...xrefBackup]);
          // -- END --
          // Search if any other emails with same header exists
          let tempEmails = emails
            .filter((el) => {
              return el.mail_id === id;
            })
            .map((el) => {
              return el.xref_id;
            });
          findItemXref = xrefBackup.findIndex((el) => {
            return tempEmails.indexOf(el) > -1;
          });
          if (findItemXref === -1) {
            let newSelected = [...selectedEmails];
            newSelected.splice(findItem, 1);
            setSelectedEmails([...newSelected]);
          }
          // -- END --
        } else {
          if (multi) {
            setSelectedEmailXref([...selectedEmailXref, xref_id]);
          } else {
            setSelectedEmailXref([xref_id]);
          }
        }
      }
    }
  };

  const topButtonsHandlers = {
    [mailButtonGroup.mailStarred.name]: async (event) => {
      if (selectedEmail) {
        let res = null;
        if (selectedEmails.length === 1) {
          res = await ApiEmailService.postEmail('postEmailStar', {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
            rm: selectedEmail.is_stared,
          });
        } else {
          res = await ApiEmailService.postEmail('postEmailStar', {
            mail_id: selectedEmails,
            rm: selectedEmail.is_stared,
          });
        }
        if (res.success) {
          await getEmails();
          setSelectedEmail({
            ...selectedEmail,
            is_stared: !selectedEmail.is_stared,
          });
          setSelectedEmails([]);
          setSelectedEmail(null);
        }
      }
    },
    [mailButtonGroup.mailTrash.name]: async (event) => {
      if (selectedEmail) {
        let res = null;
        if (selectedEmails.length === 1) {
          res = await ApiEmailService.postEmail('postEmailToTrash', {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          });
        } else {
          res = await ApiEmailService.postEmail('postEmailToTrash', {
            mail_id: selectedEmails,
          });
        }
        if (res.success) getEmails();
        setSelectedEmails([]);
      }
    },
    [mailButtonGroup.mailArchive.name]: async (event) => {
      if (selectedEmail) {
        let res = null;
        if (selectedEmails.length === 1) {
          res = await ApiEmailService.postEmail('postEmailToArchive', {
            mail_id: selectedEmail.mail_id,
            xref_id: selectedEmail.xref_id,
          });
        } else {
          res = await ApiEmailService.postEmail('postEmailToArchive', {
            mail_id: selectedEmails,
          });
        }
        if (res.success) getEmails();
        setSelectedEmails([]);
      }
    },
  };

  const bottomButtonsHandlers = {
    [mailButtonGroup.mailForward.name]: async (event) => {
      if (selectedEmail) {
        setForward({
          open: true,
          id: selectedEmail.mail_id,
          folder_id: selectedEmail.folder_id,
        });
      }
    },
    [mailButtonGroup.mailReply.name]: async (email) => {
      if (email) {
        const receivers = email.receivers || {
          amera: [],
        };
        const cc = email.cc || {
          amera: [],
        };
        let tmp_receivers = receivers.amera;
        let tmp_member_details = email.member_details;

        if (tmp_receivers.indexOf(email.sender_member_id) === -1) {
          tmp_receivers.push(email.sender_member_id);
        }
        tmp_member_details[email.sender_member_id] = {
          amera_avatar_url: email.profile_url,
          email: email.sender_mail,
          first_name: email.first_name,
          last_name: email.last_name,
          member_id: email.sender_member_id,
        };
        props.setCompose({
          open: true,
          initialState: {
            reply_id: selectedEmail.mail_id,
            subject: selectedEmail.subject,
            receivers: [
              ...tmp_receivers.map((el) => {
                return {
                  ...tmp_member_details[el],
                  contact_member_id: tmp_member_details[el].member_id,
                };
              }),
            ],
            cc: !email.cc
              ? []
              : [
                  ...cc.amera.map((el) => {
                    return {
                      ...email.member_details[el],
                      contact_member_id: email.member_details[el].member_id,
                    };
                  }),
                ],
          },
        });
      } else {
        props.setCompose({
          open: true,
          initialState: {
            reply_id: selectedEmail.mail_id,
            subject: selectedEmail.subject,
            receivers: [
              {
                email: selectedEmail.sender_mail,
                contact_member_id: selectedEmail.sender_member_id,
                first_name: selectedEmail.first_name,
                last_name: selectedEmail.last_name,
              },
            ],
          },
        });
      }
    },
  };

  useEffect(() => {
    props.setMemberUrl(apiPaths.mail.getSelectableMembersStarred);
    // eslint-disable-next-line
  }, [props.setMemberUrl]);

  useEffect(() => {
    getEmails();
    return () => {
      cleanup.current = true;
    };
    // eslint-disable-next-line
  }, []);

  const [selectSort, setSelectSort] = React.useState(0);
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const mail_id = params.get('mail_id');
    if (mail_id) {
      selectEmail(mail_id);
    }
    getEmails();
    // eslint-disable-next-line
  }, [selectSort, props.location.search]);

  const handleChangeSort = (e) => {
    setSelectSort(e.target.value);
  };
  // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
  // const [selectFilter, setSelectFilter] = React.useState(0);
  // const handleChangeFilter = (e) => {
  //   setSelectFilter(e.target.value);
  // };

  return (
    <EmailPageWrapper
      section="Starred"
      view={props.view}
      selectedEmail={selectedEmail}
      selectedEmails={selectedEmails}
      emails={emails}
      totalCount={totalCount}
      sortControls={{
        value: selectSort,
        changeValue: handleChangeSort,
      }}
      // "TODO: this block is not needed anymore, shall we keep this block or remove ?"
      // filterControls={{
      //   value: selectFilter,
      //   changeValue: handleChangeFilter,
      // }}
      topButtonsHandlers={topButtonsHandlers}
      selectEmail={selectEmail}
      bottomButtonsHandlers={bottomButtonsHandlers}
    >
      {forward.open && (
        <ContactModal
          forward
          mail_id={forward.id}
          folder_id={forward.folder_id}
          open={true}
          onClose={() => {
            setForward({ open: false });
          }}
        />
      )}
    </EmailPageWrapper>
  );
};

export default Starred;
