import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Alert from '@material-ui/lab/Alert';
import MemberAutocomplete from '../../../MemberAutocomplete';
import GroupAutocomplete from '../../../GroupAutocomplete';
import { EVENT_FILTER_TYPE } from '../../../../utils/calendar';
import { validateEventFilterForm } from '../../../../utils/validator/ScheduleEvent';

import {
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';

const EventFilterDialog = (props) => {
  const { open, onClose, onApply, currentFilter } = props;

  const [filter, setFilter] = useState({
    filterType: EVENT_FILTER_TYPE.all,
    contacts: [],
    group: {},
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    setFilter(currentFilter);
  }, [currentFilter]);

  const handleFilterTypeChange = (e, v) => {
    setFilter((ps) => ({ ...ps, filterType: v }));
    setError('');
  };

  const handleContactChange = (e, v) => {
    setFilter((ps) => ({ ...ps, contacts: v }));
    setError('');
  };

  const handleGroupChange = (e, v) => {
    setFilter((ps) => ({ ...ps, group: v }));
    setError('');
  };

  const handleApplyClick = () => {
    // Verification
    const foundError = validateEventFilterForm(filter);

    if (!!foundError) {
      setError(foundError);
    } else {
      setError(null);
      onApply(filter);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      id={`event-filter-props`}
      aria-labelledby={`event-filter-props`}
    >
      <DialogTitle id={`event-filter-title`}>Filter events</DialogTitle>
      <DialogContent>
        <Grid container style={{ width: '35rem' }}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Show</FormLabel>
            <RadioGroup
              aria-label="filter type"
              name="filterType"
              value={filter.filterType}
              onChange={handleFilterTypeChange}
            >
              <FormControlLabel
                value={EVENT_FILTER_TYPE.all}
                control={<Radio />}
                label="All events"
              />
              <FormControlLabel
                value={EVENT_FILTER_TYPE.contacts}
                style={{ width: '100%' }}
                control={<Radio />}
                label={
                  <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={1}
                    alignContent="center"
                    alignItems="center"
                    style={{ width: '33rem' }}
                  >
                    <Grid item xs={4}>
                      <Typography variant="body1">
                        With one of contacts
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <MemberAutocomplete
                        values={filter.contacts}
                        label="Invited contacts"
                        handleChange={handleContactChange}
                        disabled={
                          filter.filterType !== EVENT_FILTER_TYPE.contacts
                        }
                      />
                    </Grid>
                  </Grid>
                }
              />
              <FormControlLabel
                value={EVENT_FILTER_TYPE.group}
                control={<Radio />}
                style={{ width: '100%' }}
                label={
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={1}
                    alignContent="center"
                    alignItems="center"
                    style={{ width: '33rem' }}
                  >
                    <Grid item xs={4}>
                      <Typography variant="body1">With group</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <GroupAutocomplete
                        multiple={false}
                        values={filter.group}
                        label="Group"
                        handleChange={handleGroupChange}
                        disabled={filter.filterType !== EVENT_FILTER_TYPE.group}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <FormControlLabel
                value={EVENT_FILTER_TYPE.noInvites}
                control={<Radio />}
                label="Events with no invites"
              />
            </RadioGroup>
          </FormControl>
          {!!error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>

        {/* <DialogContentText id="alert-dialog-description">
          {content || 'Are you sure you want to delete this?'}
        </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleApplyClick}
          color="primary"
          autoFocus
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventFilterDialog;
