import React, { useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { includes } from 'lodash';

import Button from '../../Dashboard/CardButton';
import LocationTypeSelect from '../../../pages/Profile/ProfileEdit/LocationsList/LocationListItem/LocationTypeSelector';
import AddressInputForm from '../../../components/AddressInputForm';
import { destructureAddress, MAP_VENDORS } from '../../../utils/country';
import { ADDRESS_TYPES } from '../../../utils/calendar';
import useGoogleMap from '../../../hooks/useGoogleMap';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';

const LocationItem = (props) => {
  const { setLocations, data, error, onCancel, onSave, isLoading } = props;

  const { places, getPlaceDetailsFromPlaceId } = useGoogleMap();
  const firstPlace = !!places?.length > 0 && places[0];

  const isEstablishment = includes(
    [
      ADDRESS_TYPES.coffeeShops,
      ADDRESS_TYPES.barsRestraunts,
      ADDRESS_TYPES.meetingPlace,
    ],
    data.location_type
  );

  const writeField = useCallback(
    (value, field) => {
      setLocations({
        type: PROFILE_EDIT_ACTIONS.locationEdit,
        payload: {
          location_id: data.id,
          field,
          value: value,
        },
      });
    },
    [data.id, setLocations]
  );

  const handleTextFieldInput = (e, field) => writeField(e.target.value, field);

  useEffect(() => {
    const parsed =
      !!firstPlace && destructureAddress(firstPlace.address_components);
    console.debug('parsed', parsed);
    if (!!parsed) {
      if (isEstablishment && !!firstPlace?.name)
        writeField(firstPlace.name, 'description');
      writeField(parsed?.streetAddress1, 'street_address_1');
      writeField(parsed?.streetAddress2, 'street_address_2');
      writeField(parsed?.locality, 'locality');
      writeField(parsed?.sublocality, 'sub_locality');
      writeField(parsed?.adminArea1, 'admin_area_1');
      writeField(parsed?.adminArea2, 'admin_area_2');
      writeField(parsed?.postal, 'postal_code');
      writeField(firstPlace?.place_id, 'place_id');
      writeField(firstPlace?.url, 'map_link');
      writeField(firstPlace?.geometry.location.lat(), 'latitude');
      writeField(firstPlace?.geometry.location.lng(), 'longitude');
      writeField(MAP_VENDORS.google, 'map_vendor');
      writeField(firstPlace?.formatted_address, 'vendor_formatted_address');
      writeField(firstPlace, 'raw_response');
    }
  }, [firstPlace, isEstablishment, writeField]);

  const handleSuggestionClick = (suggestion) => {
    if (suggestion?.place_id) {
      getPlaceDetailsFromPlaceId(suggestion.place_id);
    }
  };

  const handleCountryChange = (e) => {
    writeField(e.target.value, 'country_code_id');
    writeField('', 'street_address_1');
    writeField('', 'street_address_2');
    writeField('', 'locality');
    writeField('', 'sub_locality');
    writeField('', 'admin_area_1');
    writeField('', 'admin_area_2');
    writeField('', 'postal_code');
    writeField(null, 'place_id');
    writeField(null, 'map_link');
    writeField(null, 'latitude');
    writeField(null, 'longitude');
    writeField(null, 'map_vendor');
    writeField(null, 'vendor_formatted_address');
    writeField(null, 'raw_response');
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={5}>
        <LocationTypeSelect
          location_type={data.location_type}
          handleChange={(e) => handleTextFieldInput(e, 'location_type')}
        />
      </Grid>

      <Grid item xs={7}>
        <TextField
          label="Description"
          value={data.description || ''}
          fullWidth
          InputLabelProps={{ shrink: true }}
          error={!!error?.description}
          helperText={!!error?.description ? error?.description : ''}
          onChange={(e) => handleTextFieldInput(e, 'description')}
        />
      </Grid>

      <AddressInputForm
        countryCode={data.country_code_id}
        streetAddress1={data.street_address_1}
        streetAddress2={data.street_address_2}
        adminArea1={data.admin_area_1}
        adminArea2={data.admin_area_2}
        locality={data.locality}
        postal={data.postal_code}
        onStreetAddress2={(e) => handleTextFieldInput(e, 'street_address_2')}
        onLocality={(e) => handleTextFieldInput(e, 'locality')}
        onAdminArea1={(e) => handleTextFieldInput(e, 'admin_area_1')}
        onAdminArea2={(e) => handleTextFieldInput(e, 'admin_area_2')}
        onPostal={(e) => handleTextFieldInput(e, 'postal_code')}
        onCountrySelected={handleCountryChange}
        onSuggestionClick={handleSuggestionClick}
        isEstablishment={isEstablishment}
        error={error}
      />

      <Grid item container xs={12} spacing={1} justify="flex-end">
        <Grid item>
          <Button
            variant="info"
            text="Cancel"
            onClick={(e) => onCancel(e, data.id)}
          />
        </Grid>
        <Grid item>
          <Button
            variant="okFilled"
            text={!isLoading ? 'Save' : 'Saving..'}
            onClick={(e) => onSave(e, data.id)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocationItem;
