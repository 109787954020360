const fakeDatabase = {
  memberEvents: [
    {
      id: 1,
      first_name: 'Maximus',
      last_name: 'Capf',
      role: 'Team Lead',
      location: 'Main Medical Centre. 22 Satsen Pr, Paris',
      country: 'France',
      event_date: '2020-07-10T13:40:00.000Z',
      image: 'image',
    },
    {
      id: 2,
      first_name: 'Orlan',
      last_name: 'Luca',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-11T21:00:00.000Z',
      image: '',
    },
  ],
  meetingMembers: [
    {
      id: 1,
      first_name: 'Maximus',
      last_name: 'Capf',
      role: 'Team Lead',
      location: 'Main Medical Centre. 22 Satsen Pr, Paris',
      country: 'France',
      event_date: '2020-07-10T13:40:00.000Z',
      image: 'image',
      title: 'Marketing Meeting',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 2,
      first_name: 'Orlan',
      last_name: 'Luca',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-11T21:00:00.000Z',
      image: 'image',
      title: 'Project Planning Session',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 3,
      first_name: 'Martin',
      last_name: 'Martin',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Lunch Meeting',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 4,
      first_name: 'John',
      last_name: 'Doe',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Lunch Meeting',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 5,
      first_name: 'Jane',
      last_name: 'Doe',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Board meetings',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
    {
      id: 6,
      first_name: 'Jane',
      last_name: 'Doe',
      role: 'CEO at ArIt',
      location: 'Marcelo Torcuato de Alvear 636, CABA',
      country: 'Argentina',
      event_date: '2020-07-13T01:00:00.000Z',
      image: '',
      title: 'Board Product launch',
      description: `
        “Hey Chris I would like to speak to you about the marketing 
        initiatives at your earliest convienience.
      `,
    },
  ],
  roleFilterValues: [
    'Advisor',
    'Client',
    'Co-Worker',
    'Customer',
    'Friend',
    'Reseller',
    'Student',
    'Teacher',
    'Vendor',
    'Worker',
  ],
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchTodos = (url, id) =>
  delay(500).then(() => {
    switch (url) {
      case 'member-event':
        return fakeDatabase.memberEvents;
      case 'notification':
        return fakeDatabase.notifications;
      case 'meeting-member':
        return fakeDatabase.meetingMembers;
      case 'filter-role':
        return fakeDatabase.roleFilterValues;
      default:
        throw new Error('Bad request');
    }
  });
