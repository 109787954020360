import React, { useState } from 'react';
import { useStyles } from './ModalStyles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AmeraSelect from '../../../../components/AmeraSelect';
import MenuItem from '@material-ui/core/MenuItem';

const AddModal = ({
  heading,
  open,
  handleClose,
  project_id,
  handleSubmit,
  parent_id,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [payType, setPayType] = useState('hourly');

  const form = {
    project_id: Number(project_id),
    parent_id: heading === 'Epic' ? null : parent_id,
    element_type: heading.toLowerCase(),
    title: title,
    description: description,
    est_hours: null,
    rate_type: payType,
  };

  const submitHandler = () => {
    handleSubmit(form);
    setTitle('');
    setDescription('');
  };

  return (
    <Modal open={open}>
      <div className={classes.modal}>
        <div className={classes.modalTop}>
          <div>
            <h6 className={classes.modalTopText}>
              Add a {heading} {/* {parent_id} */}
            </h6>
          </div>
          <div onClick={handleClose}>
            <IconButton className={classes.modalTopButton} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <hr />
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <FormControl variant="outlined" className={classes.field}>
              <TextField
                label="Title"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.field}>
              <AmeraSelect
                id={project_id}
                label="Pay Type"
                value={payType}
                onChange={(e) => setPayType(e.target.value)}
              >
                <MenuItem value="fixed">Fixed Price</MenuItem>
                <MenuItem value="hourly">Hourly</MenuItem>
              </AmeraSelect>
            </FormControl>
          </Grid>
        </Grid>

        {/* {payType === 'fixed' && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.field}>
                <RateInput
                  rate={rate}
                  onRateChange={(e) => setRate(e.target.value)}
                  currencyId={currencyId}
                  label="Total Amount"
                  onCurrencyChange={(e) => setCurrencyId(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        )} */}

        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.field}>
            <TextareaAutosize
              className={classes.inputDescription}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rowsMin={8}
              placeholder="Description"
            />
          </FormControl>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Button
              onClick={handleClose}
              className={classes.button1}
              variant="outlined"
              color="primary"
            >
              <span>Cancel</span>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={submitHandler}
              className={classes.button2}
              variant="contained"
              color="primary"
              disabled={!title && true}
            >
              <span className={classes.buttonText}>Create {heading}</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default AddModal;
