import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Popover, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MaterialAvatar from '../MaterialAvatar';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    marginRight: '18px',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      height: '28px',
      background: 'white',
      borderRadius: '7px',
      textTransform: 'none',
    },
  },
  filterMember: {
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  panel: {
    padding: '10px 16px',
  },
  member: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '18px',
    color: '#5F5F5F',
    fontSize: '13px',
    fontWeight: 400,
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  avatar: {
    width: '36px',
    height: '36px',
  },
  name: {
    marginLeft: '16px',
  },
}));

const Filter = (props) => {
  const {
    members,
    searchKey,
    handleSearchKey,
    selectedItems,
    handleSelect,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const openFilter = Boolean(anchorEl);

  return (
    <div className={classes.filter}>
      <Button onClick={handleFilterClick}>
        <FilterListIcon />
        <span className={classes.filterMember}>Filter: Member</span>
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={openFilter}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.panel}>
          <TextField
            id="standard-search"
            label="Search member"
            type="search"
            variant="outlined"
            value={searchKey}
            onChange={handleSearchKey}
            size="small"
          />
          <div className={classes.list}>
            {members
              .filter((member) => {
                const name = `${member.first_name} ${member.last_name}`.toLowerCase();
                const lowerKey = searchKey.toLowerCase();
                return name.includes(lowerKey);
              })
              .map((member) => (
                <div
                  key={member.member_id}
                  className={classes.member}
                  onClick={() => {
                    handleSelect(member.member_id);
                  }}
                >
                  <div>
                    <MaterialAvatar
                      member_id={member.member_id}
                      src={member.amera_avatar_url}
                      firstName={member.first_name}
                      lastName={member.last_name}
                      classes={classes.avatar}
                      variant="circular"
                    />
                    <span className={classes.name}>
                      {member.first_name} {member.last_name}
                    </span>
                  </div>
                  {selectedItems.includes(member.member_id) === true && (
                    <div>
                      <CheckIcon />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default Filter;
