import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MethodTypeSelector from '../../../pages/Profile/ProfileEdit/PhoneList/PhoneListItem/MethodTypeSelector';
import ListDeleteButton from '../../../pages/Profile/ProfileEdit/ListDeleteButton';
import { PROFILE_EDIT_ACTIONS } from '../../../utils/drawer';
import { DEVICE_TYPES } from '../../../utils/contact';

const EmailItem = (props) => {
  const { data, setEmails, emailsCount, error } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          disabled={data.primary_contact}
          value={data.description}
          label="Description"
          required
          error={!!error?.description}
          helperText={!!error?.description ? error?.description : ''}
          fullWidth
          onChange={(e) =>
            setEmails({
              type: PROFILE_EDIT_ACTIONS.emailEdit,
              payload: {
                email_id: data.id,
                field: 'description',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          disabled={data.primary_contact}
          value={data.device}
          label="E-mail"
          error={!!error?.email}
          helperText={!!error?.email ? error?.email : ''}
          required
          placeholder="example@example.com"
          fullWidth
          onChange={(e) =>
            setEmails({
              type: PROFILE_EDIT_ACTIONS.emailEdit,
              payload: {
                email_id: data.id,
                field: 'device',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={2}>
        <MethodTypeSelector
          disabled={data.primary_contact}
          deviceClass={DEVICE_TYPES.email}
          method={data.method_type}
          handleChange={(e) =>
            setEmails({
              type: PROFILE_EDIT_ACTIONS.emailEdit,
              payload: {
                email_id: data.id,
                field: 'method_type',
                value: e.target.value,
              },
            })
          }
        />
      </Grid>
      <Grid item xs={2}>
        <ListDeleteButton
          id={data.id}
          isDisabled={data.primary_contact || emailsCount === 1}
          handleDelete={() =>
            setEmails({
              type: PROFILE_EDIT_ACTIONS.emailDelete,
              payload: { email_id: data.id },
            })
          }
          listContext="emailcontact item"
        >
          Delete Item
        </ListDeleteButton>
      </Grid>
    </Grid>
  );
};

export default EmailItem;
