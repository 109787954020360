import React, { useState, useEffect, useRef } from 'react';
import useEventListener from '@use-it/event-listener';
import Typography from '@material-ui/core/Typography';
import SelectedFileList from './SelectedFileList';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';

import Modal from '../../../components/Modal/modal';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const DeleteFile = (props) => {
  const { show, close, files, deleteFileLoading, onDeleteConfirm } = props;
  const title = 'Remove File';

  const [loading, setLoading] = useState(false);
  const prevState = usePrevious({ loading });

  useEffect(() => {
    setLoading(deleteFileLoading);
  }, [deleteFileLoading]);

  useEffect(() => {
    if (prevState && loading === false && prevState.loading === true) {
      close();
    }
  }, [loading, close, prevState]);

  const handleClickDeleteFile = async (event) => {
    event.preventDefault();
    // console.log('will delete this', fileIds);
    setLoading(true);
    await onDeleteConfirm(files.map((f) => f.id));
    setLoading(false);
  };
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleClickDeleteFile(e);
    }
  };
  useEventListener('keydown', handleEnterPress);

  const ModalContent = () => (
    <>
      <Typography variant="body1">
        Are you sure you want to remove these items?
      </Typography>
      <SelectedFileList fileList={files} handleUnlistItem={null} />
    </>
  );

  const ModalAction = () => {
    return (
      <div className="button-wrapper">
        {loading ? (
          <Button variant="contained" className="modal_button" disabled>
            Removing...
          </Button>
        ) : (
          <Button
            variant="contained"
            auto
            color="primary"
            className="modal_button delete_button"
            disableRipple
            onClick={(e) => handleClickDeleteFile(e)}
          >
            Confirm Remove
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onClose={close}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="file-delete-modal"
      ></Modal>
    </>
  );
};

DeleteFile.propTypes = {
  dispatch: PropTypes.func,
  show: PropTypes.bool,
  close: PropTypes.func,
  fileIds: PropTypes.array,
  // deleteFileLoading: PropTypes.bool,
};

DeleteFile.defaultProps = {
  dispatch: null,
  show: false,
  close: null,
  fileIds: [],
  // deleteFileLoading: false,
};

const mapStateToProps = (state) => ({
  deleteFileLoading: state.fileshare.deleteFileLoading,
});

export default connect(mapStateToProps)(DeleteFile);
