import React from 'react';
import { connect } from 'react-redux';

import UpcomingEventCard from '../../components/Dashboard/UpcomingEventCard';
import EmptyCard from '../../components/Dashboard/EmptyCard';
import Carousel from '../../components/Carousel';

const UpcomingEvent = (props) => {
  const { upcomingEvents, member, showDetail } = props;

  if (upcomingEvents && upcomingEvents.length === 1) {
    upcomingEvents.push({ id: 'empty' });
  }

  const isEmpty = upcomingEvents && upcomingEvents.length ? false : true;
  const breakpoints = [
    {
      count: 2,
      width: 1280,
    },
    {
      count: 1,
      width: 960,
    },
    {
      count: 1,
      width: 768,
    },
  ];

  return (
    <Carousel isEmpty={isEmpty} items={breakpoints}>
      {!isEmpty ? (
        upcomingEvents.map((event, index) =>
          event.id === 'empty' ? (
            <EmptyCard
              key={index}
              type="invitation"
              message="No upcoming event."
            />
          ) : (
            <UpcomingEventCard
              key={index}
              data={event}
              member={member}
              showDetail={showDetail}
            />
          )
        )
      ) : (
        <EmptyCard type="invitation" message="No upcoming event." />
      )}
    </Carousel>
  );
};

const mapStateToProps = (state) => ({
  upcomingEvents: state.event.upcomingEvents,
  member: state.member.member,
});

export default connect(mapStateToProps)(UpcomingEvent);
