import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import Feedback from 'feedback-screenshot';

import ErrorFallback from './ErrorFallback';
import { getBrowserDetails } from '../../utils/general';
import { getCanvasBlob } from '../../utils/file';
import { createBugReport } from '../../redux/actions/reportBugs';

const ErrorBoundaryWrapper = (props) => {
  const dispatch = useDispatch();

  const { reduxState } = useSelector((state) => ({
    reduxState: state,
  }));

  const onsSubmitScreenshot = (error) => {
    // convert to inline style
    var svgElements = document.body.querySelectorAll('svg');
    svgElements.forEach(function (item) {
      item.setAttribute('width', item.getBoundingClientRect().width);
      item.setAttribute('height', item.getBoundingClientRect().height);
      item.style.width = null;
      item.style.height = null;
    });

    const feedback = new Feedback({
      borderColor: '#000',
      zIndex: 200,
      html2canvas: {
        logging: false,
        useCROS: true,
        scale: 1,
      },
    });

    try {
      feedback.open();

      //close screen shot and wait for canvas
      feedback.close().then(async (capturedCanvas) => {
        const fileName = 'page_screenshot.jpg';
        const description = 'Auto Report';
        const formData = new FormData();
        formData.append('redux_state', JSON.stringify(reduxState));
        formData.append('description', description);

        // get user browser, system and network details
        const browserInfo = getBrowserDetails();
        formData.append('browser_info', JSON.stringify(browserInfo));

        // get screenshot image
        let blob, file;
        if (capturedCanvas) {
          // convert canvas to binary file
          blob = await getCanvasBlob(capturedCanvas, fileName);
          file = new File([blob], blob.name, { type: 'image/jpeg' });
          formData.append('file', file);
          formData.append('file_name', blob.name);
          formData.append('file_size', file.size);
          formData.append('file_iv', file.iv);
        } else {
          formData.append('file', '');
        }
        dispatch(createBugReport(formData));
      });
    } catch (error) {
      feedback.close(true);
      console.error('something went wrong', error);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      children={props.children}
      onError={onsSubmitScreenshot}
    ></ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
