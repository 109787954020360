import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { PROJECT_STATUS } from '../../utils/project';
import AmeraSelect from '../../components/AmeraSelect';

const ProjectStatusSelect = (props) => {
  const { projectStatus, handleChange, multiple } = props;
  return (
    <AmeraSelect
      label="Status"
      value={projectStatus}
      onChange={handleChange}
      multiple={multiple}
    >
      {Object.keys(PROJECT_STATUS).map((ps) => (
        <MenuItem key={ps} value={PROJECT_STATUS[ps].key}>
          {PROJECT_STATUS[ps].displayValue}
        </MenuItem>
      ))}

      {/* In Progress, Complete, Suspended, Deleted */}
    </AmeraSelect>
  );
};

export default ProjectStatusSelect;
