import {
  // DOWNLOAD_FILE,
  // IS_LOADING_DOWNLOAD_FILE,
  // IS_LOADING_RENAME_FILE,
  // IS_LOADING_FILESHARE_DATA,
  // IS_LOADING_CREATE_FILESHARE,
  IS_LOADING_DECRYPT_FORM,
  IS_LOADING_ENCRYPT,
  // RENAME_FILE,
  // FILESHARE_DATA,
  DECRYPTED_FILE_DATA,
  // ADD_FILESHARE_DATA,
  // SHAREDFILE_DATA,
  FEIL_DETAIL_DATA,
  SHARED_FILE_DETAIL_DATA,
  SET_SHARE_ALERT,
  // DELETE_FILE,
  // SHARE_FILE_LOADING,
  // DELETE_FILE_LOADING,
  // DELETE_SHARED_FILE,
  ENCRYPT_SUCCESS,
  FETCH_FILE_GROUPS_START,
  FETCH_FILE_GROUPS_SUCCESS,
  FETCH_FILE_GROUPS_FAILED,
  FILETREE_DATA,
  GROUPTREE_DATA,
} from '../actionTypes/fileshare.js';

import {
  APP_RESET
} from '../actionTypes/app';

import { MEMBER_LOGOUT_SUCCEEDED } from '../actionTypes/member.js';

// import { removeFile, removeSharedFile } from '../../utils/fileshare';

// The initial state of the App
export const initialState = {
  loading: false,
  createLoading: false,
  decryptLoading: false,
  renameLoading: false,
  downloadLoading: false,
  shareFileLoading: false,
  deleteFileLoading: false,
  error: false,
  fileList: [],
  attachment_id: '',
  main_tree: null,
  bin_tree: null,
  groupTrees: [],
  groupList: [],
  sharedFileList: [],
  fileDetailData: {},
  sharedFileDetailData: {},
  shareAlert: {
    show: false,
    status: 'success',
    message: '',
  },
};

const fileshare = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    // case IS_LOADING_DOWNLOAD_FILE:
    //   // console.log(`Setting ${IS_LOADING_DOWNLOAD_FILE}`);
    //   return {
    //     ...state,
    //     downloadLoading: true,
    //   };

    // case DOWNLOAD_FILE:
    //   // console.log(`Setting ${RENAME_FILE}`);
    //   return {
    //     ...state,
    //     downloadLoading: false,
    //   };
    // case IS_LOADING_RENAME_FILE:
    //   // console.log(`Setting ${IS_LOADING_RENAME_FILE}`);
    //   return {
    //     ...state,
    //     renameLoading: true,
    //   };
    // case RENAME_FILE:
    //   console.log(`Setting ${RENAME_FILE} payload is`, action.payload);
    //   return {
    //     ...state,
    //     renameLoading: false,
    //     fileList: action.payload,
    //   };

    // case IS_LOADING_FILESHARE_DATA:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case IS_LOADING_CREATE_FILESHARE:
    //   return {
    //     ...state,
    //     createLoading: true,
    //   };
    // case SHARE_FILE_LOADING:
    //   return {
    //     ...state,
    //     shareFileLoading: true,
    //   };
    // case DELETE_FILE_LOADING:
    //   return {
    //     ...state,
    //     deleteFileLoading: true,
    //   };
    // case FILESHARE_DATA:
    //   console.log(`Setting ${FILESHARE_DATA} payload is`, action.payload);
    //   return {
    //     ...state,
    //     loading: false,
    //     createLoading: false,
    //     renameLoading: false,
    //     downloadLoading: false,
    //     fileList: action.payload,
    //   };
    // case ADD_FILESHARE_DATA:
    //   return {
    //     ...state,
    //     createLoading: false,
    //     fileList: action.payload.concat(state.fileList),
    //   };
    // case SHAREDFILE_DATA:
    //   return {
    //     ...state,
    //     loading: false,
    //     sharedFileList: [...action.payload],
    //   };
    case FILETREE_DATA:
      return {
        ...state,
        main_tree: action.payload.main_tree,
        bin_tree: action.payload.bin_tree,
      };
    case GROUPTREE_DATA:
      const { groupId, main_tree, bin_tree } = action.payload;
      if (groupId === undefined) {
        return {
          ...state,
          main_tree: action.payload.main_tree,
          bin_tree: action.payload.bin_tree,
        };
      }
      const isInState = !!state.groupTrees.find((gt) => gt.groupId === groupId);
      const incoming = {
        groupId: groupId,
        main_tree: main_tree,
        bin_tree: bin_tree,
      };
      if (isInState) {
        return {
          ...state,
          groupTrees: state.groupTrees.map((gt) =>
            gt.groupId === groupId ? incoming : gt
          ),
        };
      } else {
        return { ...state, groupTrees: [...state.groupTrees, incoming] };
      }

    case FEIL_DETAIL_DATA:
      return {
        ...state,
        fileDetailData: action.payload,
      };
    case SHARED_FILE_DETAIL_DATA:
      return {
        ...state,
        fileDetailData: action.payload,
      };
    case SET_SHARE_ALERT:
      return {
        ...state,
        shareFileLoading: false,
        deleteFileLoading: false,
        renameLoading: false,
        downloadLoading: false,
        shareAlert: action.payload,
      };
    // case DELETE_FILE:
    //   return {
    //     ...state,
    //     deleteFileLoading: false,
    //     fileList: removeFile(state.fileList, action.payload),
    //   };
    // case DELETE_SHARED_FILE:
    //   return {
    //     ...state,
    //     deleteFileLoading: false,
    //     sharedFileList: removeSharedFile(state.sharedFileList, action.payload),
    //   };
    case IS_LOADING_ENCRYPT:
      return {
        ...state,
        encryptLoading: true,
      };
    case ENCRYPT_SUCCESS:
      return {
        ...state,
        encryptLoading: false,
      };
    case IS_LOADING_DECRYPT_FORM:
      return {
        ...state,
        decryptLoading: true,
      };
    case DECRYPTED_FILE_DATA:
      return {
        ...state,
        decryptLoading: false,
        data: [action.payload],
      };
    case FETCH_FILE_GROUPS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FILE_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupList: action.payload,
      };
    case FETCH_FILE_GROUPS_FAILED:
      return {
        ...state,
        loading: false,
        groupList: [],
      };
    case MEMBER_LOGOUT_SUCCEEDED:
      return initialState;
    // ...state,
    // fileshare: {},
    // };

    default:
      return state;
  }
};

export default fileshare;
