import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';

const LocationCountrySelector = (props) => {
  const {
    country,
    handleChange,
    countryList,
    className,
    disabled = false,
  } = props;
  return (
    <FormControl
      className={`country-selector ${className ? className : ''}`}
      fullWidth
    >
      <InputLabel id="country-selector-label">Country</InputLabel>
      <Select
        labelId="country"
        name="location_type"
        value={country}
        onChange={handleChange}
        disabled={disabled}
      >
        {countryList.map((clo) => (
          <MenuItem key={clo.id} value={clo.id}>
            {clo.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  countryList: state.member.countryList,
});

export default connect(mapStateToProps)(LocationCountrySelector);
