import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardContent, Grid, makeStyles } from '@material-ui/core';

import { apiUrl } from '../../config/api';
import { readFileAsBlob } from '../../utils/file';

const colors = {
  blue: '#4284df',
  darkGreen: '#28a745',
  darkRed: '#c82333',
  foggyRed: '#dc3545',
  grey: '#6c757d',
  yellow: '#ffc107',
  teal: '#17a2b8',
  orange: '#ff7f50',
  textDark: '#6c757d',
};

const useStyles = makeStyles((theme) => ({
  encryptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  encryptionControlWrapper: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  encryptionCard: {
    height: '100%',
    '& .MuiCardContent-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  encryptionImage: {
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
    height: '270px',
    minHeight: '270px',
    maxHeight: '270px',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  encryptionImageKey: {
    '& label': {
      fontWeight: 'bold',
    },
  },
  controlGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '& label': {
      fontWeight: 'bold',
    },
    paddingBottom: theme.spacing(1),
  },
  fileInputWrapper: {
    '& .MuiButton-root': {
      background: colors.blue,
    },
    '& .MuiButton-label': {
      whiteSpace: 'nowrap',
    },
    '& label': {
      display: 'block',
    },
  },
  fileInput: {
    display: 'none',
  },
  userPinWrapper: {
    '& input': {
      height: '2.2em',
      width: '100%',
      border: '1px solid #ced4da',
      borderRadius: '0.23em',
      background: theme.palette.common.white,
      paddingLeft: theme.spacing(1),
    },
    marginLeft: theme.spacing(2),
  },
  binaryEncryptionKey: {
    width: '100%',
    '& label': {
      fontWeight: 'bold',
      color: theme.palette.common.black,
    },
    '& textarea': {
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#495057',
      backgroundColor: '#e9ecef',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      height: '150px',
    },
  },
}));

const EncryptionController = (props) => {
  const { security, e2E, toggleEncy, isMyGroup, callType } = props;
  const existingSecurityPictureURL = security.security_picture
    ? `${apiUrl}${security.security_picture}`
    : undefined;
  const [picture, setPicture] = useState(existingSecurityPictureURL);
  const [pictureFile, setPictureFile] = useState(null);
  const [pin, setPin] = useState(security.pin || undefined);
  const [pictureStyle, setPictureStyle] = useState({});
  const [currentKey, setCurrentKey] = useState();

  const classes = useStyles();
  let pictureRef = useRef();

  // // const dispatch = useDispatch();
  // const _arrayBufferToBase64 = (buffer) => {
  //   var binary = '';
  //   var bytes = new Uint8Array(buffer);
  //   var len = bytes.byteLength;
  //   for (var i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return window.btoa(binary);
  // };

  const getKeyFromStorage = async (picture_id, pin) => {
    const data = new FormData();
    data.append('storage_id', picture_id);
    data.append('pin', pin);
    const url = `${apiUrl}/keygen/storage`;

    return fetch(url, {
      method: 'POST',
      body: data,
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.error('Error fetching key');
        console.error(err);
        return null;
      });
  };

  const getKeyFromFile = (image, pin) => {
    const data = new FormData();
    data.append('image', image);
    data.append('pin', pin);
    const url = `${apiUrl}/keygen/file`;

    return fetch(url, {
      method: 'POST',
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => {
        console.error('Error fetching key');
        console.error(err);
        return null;
      });
  };

  const getKey = async (security) => {
    if (security) {
      let sp = pin;
      if (security.pin) sp = security.pin;
      if (security.pin && security.security_picture_storage_id) {
        const secure = await getKeyFromStorage(
          security.security_picture_storage_id,
          security.pin
        );
        if (secure && secure.hexkey) {
          setCurrentKey(secure.hexkey);
        }
      }

      if (
        callType !== 'contact' &&
        !isMyGroup &&
        security.exchange_option === 'MOST_SECURE'
      ) {
        setPin('');
      } else {
        setPin(sp);
      }
    }
  };

  useEffect(() => {
    getKey(security);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [security]);

  const handlePicPinFiles = async (files) => {
    files = files.target.files;
    if (files.length < 1) {
      return;
    }
    if (!files[0].type.startsWith('image/')) {
      return;
    }
    setPictureFile(files[0]);
    const blob = await readFileAsBlob(files[0]);
    setPicture(blob);
  };

  const handleEncrypt = async () => {
    if (pictureFile && pin) {
      const secure = await getKeyFromFile(pictureFile, pin);
      if (secure && secure.hexkey) {
        setCurrentKey(secure.hexkey);
        await toggleEncy(secure.hexkey);
      }
    } else {
      if (currentKey) {
        await toggleEncy(currentKey);
      }
    }
  };

  useEffect(() => {
    if (pictureRef.current) {
      let ps =
        pictureRef.current.offsetHeight > pictureRef.offsetWidth
          ? { width: '100%', height: 'auto' }
          : { width: 'auto', height: '100%' };
      setPictureStyle(ps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureRef.current]);

  const handlePin = (e) => {
    setPin(e.target.value);
  };

  return (
    <Grid item md={12} sm={12}>
      <Card className={classes.encryptionCard} variant="outlined">
        <CardContent className={classes.encryptionWrapper}>
          <div className={classes.encryptionImage}>
            {picture && (
              <img
                ref={pictureRef}
                src={picture}
                style={pictureStyle}
                alt="Preview"
              />
            )}
            {!picture &&
              security &&
              security.security_picture &&
              (isMyGroup ||
                callType === 'contact' ||
                (security.exchange_option !== 'MOST_SECURE' &&
                  security.exchange_option !== 'VERY_SECURE' &&
                  security.exchange_option !== 'SECURE')) && (
                <img
                  ref={pictureRef}
                  src={picture}
                  style={pictureStyle}
                  alt="Preview"
                />
              )}
          </div>
          <div className={classes.encryptionControlWrapper}>
            <div className={classes.encryptionImageKey}>
              <label htmlFor="aeskey">Key</label>
              <br />
              <input
                type="text"
                className="form-control form-control-sm"
                id="aeskey"
                value={currentKey}
                placeholder="current key"
                disabled
              />
              <hr />
            </div>
            <h5>Encryption Controls</h5>
            <div className={classes.controlGroup}>
              <div className={classes.fileInputWrapper}>
                <label>Picture</label>
                <input
                  className={classes.fileInput}
                  id="picfile"
                  type="file"
                  accept=".png, .jpg, .ppm"
                  onChange={handlePicPinFiles}
                />
                <label htmlFor="picfile">
                  <Button
                    variant="contained"
                    size="small"
                    component="span"
                    disabled={e2E}
                  >
                    Choose Picture
                  </Button>
                </label>
              </div>
              <div className={classes.userPinWrapper}>
                <label htmlFor="userpin">PIN</label>
                <input
                  type="text"
                  placeholder="enter pin"
                  value={pin}
                  onChange={handlePin}
                />
              </div>
            </div>
            <Button
              fullWidth={true}
              variant="contained"
              color="primary"
              disabled={!picture && !currentKey ? true : false}
              onClick={handleEncrypt}
            >
              {!e2E ? 'Create key and encrypt' : 'Remove encryption'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  security: state.security.security,
});

export default connect(mapStateToProps)(EncryptionController);
