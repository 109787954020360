import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { Strophe } from 'strophe.js';

import ConfirmDialog from '../../components/ConfirmDialog';
import GroupDetail from '../../components/Group/GroupDetail';
import GroupMembers from '../../components/Group/GroupMembers';
import CardButton from '../../components/Dashboard/CardButton';
import AmeraAccordion from '../AmeraAccordion/AmeraAccordion';
import { closeDrawer } from '../../redux/actions/drawer';
import { deleteGroups } from '../../redux/actions/group';
import AmeraIconButton from '../../components/AmeraIconButton';
import VideoEmailEditorModal from '../../components/Modal/VideoEmailEditorModal';
import {
  ICON_BUTTON_TYPES,
  establishGroupChat,
  establishGroupVideoCall,
  establishGroupVideoConference,
} from '../../utils/general';
import { setCallGroupId } from '../../redux/actions/event';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  header: {
    height: '120px',
    background: '#F3F6FA',
    display: 'flex',
    padding: '28px 20px 28px 30px',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h3': {
      textTransform: 'capitalize',
      fontSize: 20,
      margin: 0,
    },
  },
  content: {
    padding: theme.spacing(4),
  },
  detail: {
    padding: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(4),
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  deleteGroupBtn: {
    border: '1px solid #dc004e',
    background: 'white',
    color: '#dc004e',
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    '&:hover': {
      background: '#dc004e',
      color: 'white',
    },
  },
}));

const GroupProfile = (props) => {
  const { member, groupData } = props;
  const connectionStatus = useSelector((s) => s.chat.connectionStatus);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [showVideoEmailEditor, setShowVideoEmailEditor] = useState(false);
  const [group_id, setGroupId] = useState();
  const [groupName, setGroupName] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleGroupDelete = () => {
    setOpen(false);
    let formData = new FormData();
    let groupIds = [];
    groupIds.push(groupData.group_id);
    formData.set('groupIds', groupIds);
    dispatch(deleteGroups(formData));
    dispatch(closeDrawer());
  };

  const handleScheduleClick = (group) => {
    if (!!group && typeof group === 'object') {
      dispatch(closeDrawer());

      history.push({
        pathname: `${process.env.PUBLIC_URL}/calendar`,
        search: `?group_id=${group.group_id}`,
      });
    }
  };

  const openGroupChat = async (group, member) => {
    const conferenceUrl = await establishGroupChat(group, member);
    history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openGroupCallAndNotify = async (group, member) => {
    await establishGroupVideoConference(group, member);
    await dispatch(setCallGroupId(group.group_id));
    // history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openGroupVideoAndNotify = async (group, member) => {
    const conferenceUrl = await establishGroupVideoCall(group, member);
    await dispatch(setCallGroupId(group.group_id));
    history.push(`${process.env.PUBLIC_URL}${conferenceUrl}`);
  };

  const openVideoEmailEditModal = (group) => {
    setShowVideoEmailEditor(true);
    setGroupId(group.group_id);
    setGroupName(group.group_name);
  };

  const isLeader = groupData.group_leader_id === member.member_id;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>Group Details</h3>
        {isLeader && (
          <Button
            variant="contained"
            color="primary"
            className={classes.deleteGroupBtn}
            disableRipple
            onClick={(e) => {
              setOpen(true);
            }}
            disabled={connectionStatus !== Strophe.Status.CONNECTED}
          >
            Delete Group
          </Button>
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.detail}>
          <GroupDetail isLeader={isLeader} groupData={groupData} />
        </div>
        <div className={classes.actions}>
          <AmeraIconButton
            type={ICON_BUTTON_TYPES.chat}
            onClick={() => openGroupChat(groupData, member)}
          />
          <AmeraIconButton type={ICON_BUTTON_TYPES.email} />
          <AmeraIconButton
            type={ICON_BUTTON_TYPES.conference}
            onClick={() => openGroupVideoAndNotify(groupData, member)}
          />
          <AmeraIconButton
            type={ICON_BUTTON_TYPES.call}
            onClick={() => openGroupCallAndNotify(groupData, member)}
          />
          <AmeraIconButton
            type={ICON_BUTTON_TYPES.schedule}
            onClick={() => handleScheduleClick(groupData)}
          />
          <AmeraIconButton
            type={ICON_BUTTON_TYPES.videoMail}
            onClick={() => {
              openVideoEmailEditModal(groupData);
            }}
          />
        </div>
        <AmeraAccordion label="Group Members">
          <GroupMembers isLeader={isLeader} groupData={groupData} />
        </AmeraAccordion>
      </div>
      <div className={classes.footer}>
        <div className={classes.actionsWrapper}>
          <CardButton
            variant="info"
            text="Close"
            onClick={() => {
              dispatch(closeDrawer());
            }}
          />
        </div>
        {isLeader && (
          <>
            <ConfirmDialog
              open={open}
              title="Confirm Delete"
              onOk={handleGroupDelete}
              onCancel={handleClose}
            />
          </>
        )}
      </div>
      {showVideoEmailEditor && (
        <VideoEmailEditorModal
          open={showVideoEmailEditor}
          receiver={group_id}
          onClose={() => {
            setShowVideoEmailEditor(false);
          }}
          name={groupName}
          type="group"
        />
      )}
    </div>
  );
};

const mapStatetoProps = (state) => ({
  member: state.member.member,
  groupData: state.group.groupData,
});

export default connect(mapStatetoProps)(GroupProfile);
