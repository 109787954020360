export const validatePostProjectCreateForm = (values, dialCode, codeRule) => {
  let errors = {};

  if (!values.company.id) {
    errors.company.id = 'Company is required';
  }

  if (!values.projectOwner.id) {
    errors.projectOwner.id = 'Project owner is required';
  }

  if (!values.projectName) {
    errors.projectName = 'Project name is required';
  }

  if (!values.projectType) {
    errors.projectType = 'Project type is required';
  }

  if (!values.projectStartDate) {
    errors.projectStartDate = 'Project start date is required';
  }

  if (!values.projectEstimatedDays) {
    errors.projectEstimatedDays = 'Project estimated days are Required';
  }

  if (+values.projectEstimatedDays < 1) {
    errors.projectEstimatedDays =
      'Project estimated days should not be 0 or less';
  }

  return errors;
};
