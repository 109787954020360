import React from 'react';

const TrashIcon = (props) => {
  const width = props.width || '14.944';
  const height = props.height || '14.723';
  const className = props.className || '';
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M8.12501 1.625H4.87501C4.57601 1.625 4.33334 1.86712 4.33334 2.16667C4.33334 2.46621 4.57601 2.70833 4.87501 2.70833H8.12501C8.42401 2.70833 8.66668 2.46621 8.66668 2.16667C8.66668 1.86712 8.42401 1.625 8.12501 1.625Z" />
        <path d="M11.9167 3.25H11.375C11.375 1.45763 9.91739 0 8.12502 0H4.87502C3.08265 0 1.62502 1.45763 1.62502 3.25H1.08335C0.784354 3.25 0.541687 3.49213 0.541687 3.79167C0.541687 4.09121 0.784354 4.33333 1.08335 4.33333H1.62502V11.375C1.62502 12.2709 2.3541 13 3.25002 13H9.75002C10.6459 13 11.375 12.2709 11.375 11.375V4.33333H11.9167C12.2157 4.33333 12.4584 4.09121 12.4584 3.79167C12.4584 3.49213 12.2157 3.25 11.9167 3.25ZM4.87502 1.08333H8.12502C9.31994 1.08333 10.2917 2.05508 10.2917 3.25H2.70835C2.70835 2.05508 3.6801 1.08333 4.87502 1.08333Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashIcon;
