import React from 'react';

const MediaStreamingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51.184"
    height="50.287"
    viewBox="0 0 51.184 50.287"
  >
    <g
      id="Group_27"
      data-name="Group 27"
      transform="translate(-288.408 -6.856)"
    >
      <path
        id="Path_39"
        data-name="Path 39"
        d="M339.564,41.831a2.957,2.957,0,0,0-2.958-2.957h-45.24a2.957,2.957,0,0,0-2.958,2.957v12.9a2.417,2.417,0,0,0,4.428,1.34l1-1.5a2.416,2.416,0,0,1,2.011-1.077h36.3a2.416,2.416,0,0,1,2.011,1.077l1,1.5a2.417,2.417,0,0,0,4.428-1.34V51.662h-.028Zm-42.449,6.634a2.255,2.255,0,1,1,2.255-2.255A2.254,2.254,0,0,1,297.115,48.465ZM314.9,46.533a.561.561,0,0,1-.561.562h-.7a.562.562,0,0,1-.562-.562v-.7a.563.563,0,0,1,.562-.562h.7a.562.562,0,0,1,.561.562Zm4.568,0a.562.562,0,0,1-.562.562h-.7a.561.561,0,0,1-.562-.562v-.7a.562.562,0,0,1,.562-.562h.7a.563.563,0,0,1,.562.562Zm4.567,0a.561.561,0,0,1-.561.562h-.7a.562.562,0,0,1-.562-.562v-.7a.563.563,0,0,1,.562-.562h.7a.562.562,0,0,1,.561.562Zm4.568,0a.562.562,0,0,1-.562.562h-.7a.561.561,0,0,1-.561-.562v-.7a.562.562,0,0,1,.561-.562h.7a.562.562,0,0,1,.562.562Zm4.567,0a.561.561,0,0,1-.561.562h-.7a.562.562,0,0,1-.562-.562v-.7a.562.562,0,0,1,.562-.562h.7a.562.562,0,0,1,.561.562Z"
      />
      <path
        id="Path_40"
        data-name="Path 40"
        d="M306.678,33.393a1.827,1.827,0,0,0,1.827,1.827h10.962a1.827,1.827,0,0,0,0-3.654h-.74a1.748,1.748,0,0,1-1.735-1.532l-1.5-12a2.131,2.131,0,0,1,.8-1.915A3.637,3.637,0,0,0,317.6,12.73a3.654,3.654,0,0,0-7.265.566,3.633,3.633,0,0,0,1.364,2.839,2.109,2.109,0,0,1,.784,1.9l-1.5,12a1.748,1.748,0,0,1-1.735,1.532h-.74A1.827,1.827,0,0,0,306.678,33.393Z"
      />
      <path
        id="Path_41"
        data-name="Path 41"
        d="M328.7,30.858l.857.856a1.226,1.226,0,0,0,1.819-.073,18.6,18.6,0,0,0,0-24.343,1.226,1.226,0,0,0-1.819-.072l-.857.856a1.231,1.231,0,0,0-.078,1.669,14.913,14.913,0,0,1,0,19.438A1.231,1.231,0,0,0,328.7,30.858Z"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        d="M322.885,25.043l.869.869a1.205,1.205,0,0,0,1.835-.093,10.456,10.456,0,0,0,0-12.7,1.246,1.246,0,0,0-1.945.018l-.761.76a1.285,1.285,0,0,0-.1,1.621,6.761,6.761,0,0,1,0,7.9A1.286,1.286,0,0,0,322.885,25.043Z"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        d="M320.3,19.55c0-.027,0-.053,0-.08s0-.054,0-.081a1.241,1.241,0,1,0,0,.161Z"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M299.365,8.082l-.856-.856a1.226,1.226,0,0,0-1.819.072,18.6,18.6,0,0,0,0,24.343,1.226,1.226,0,0,0,1.819.073l.856-.856a1.23,1.23,0,0,0,.078-1.669,14.913,14.913,0,0,1,0-19.438A1.23,1.23,0,0,0,299.365,8.082Z"
      />
      <path
        id="Path_45"
        data-name="Path 45"
        d="M305.179,13.9l-.869-.868a1.206,1.206,0,0,0-1.835.092,10.456,10.456,0,0,0,0,12.7,1.247,1.247,0,0,0,1.946-.017l.76-.76a1.285,1.285,0,0,0,.1-1.622,6.761,6.761,0,0,1,0-7.9A1.286,1.286,0,0,0,305.179,13.9Z"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M309.877,20.346a1.241,1.241,0,1,0-2.118-.957c0,.027,0,.054,0,.081s0,.053,0,.08A1.239,1.239,0,0,0,309.877,20.346Z"
      />
    </g>
  </svg>
);

export default MediaStreamingIcon;
