export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR';

export const SEND_CREATE_PROJECT_FORM_START = 'SEND_CREATE_PROJECT_FORM_START';
export const SEND_CREATE_PROJECT_FORM_SUCCESS =
  'SEND_CREATE_PROJECT_FORM_SUCCESS';
export const SEND_CREATE_PROJECT_FORM_ERROR = 'SEND_CREATE_PROJECT_FORM_ERROR';

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';

export const SOFT_DELETE_PROJECT_START = 'SOFT_DELETE_PROJECT_START';
export const SOFT_DELETE_PROJECT_SUCCESS = 'SOFT_DELETE_PROJECT_SUCCESS';
export const SOFT_DELETE_PROJECT_ERROR = 'SOFT_DELETE_PROJECT_ERROR';

export const RESTORE_PROJECT_START = 'RESTORE_PROJECT_START';
export const RESTORE_PROJECT_SUCCESS = 'RESTORE_PROJECT_SUCCESS';
export const RESTORE_PROJECT_ERROR = 'RESTORE_PROJECT_ERROR';

export const SUSPEND_PROJECT_START = 'SUSPEND_PROJECT_START';
export const SUSPEND_PROJECT_SUCCESS = 'SUSPEND_PROJECT_SUCCESS';
export const SUSPEND_PROJECT_ERROR = 'SUSPEND_PROJECT_ERROR';

export const UNSUSPEND_PROJECT_START = 'UNSUSPEND_PROJECT_START';
export const UNSUSPEND_PROJECT_SUCCESS = 'UNSUSPEND_PROJECT_SUCCESS';
export const UNSUSPEND_PROJECT_ERROR = 'UNSUSPEND_PROJECT_ERROR';

export const HARD_DELETE_PROJECT_START = 'HARD_DELETE_PROJECT_START';
export const HARD_DELETE_PROJECT_SUCCESS = 'HARD_DELETE_PROJECT_SUCCESS';
export const HARD_DELETE_PROJECT_ERROR = 'HARD_DELETE_PROJECT_ERROR';

export const FETCH_PROJECT_ROLES_START = 'FETCH_PROJECT_ROLES_START';
export const FETCH_PROJECT_ROLES_SUCCESS = 'FETCH_PROJECT_ROLES_SUCCESS';
export const FETCH_PROJECT_ROLES_ERROR = 'FETCH_PROJECT_ROLES_ERROR';

export const SEND_PROJECT_MEMBERS_START = 'SEND_PROJECT_MEMBERS_START';
export const SEND_PROJECT_MEMBERS_SUCCESS = 'SEND_PROJECT_MEMBERS_SUCCESS';
export const SEND_PROJECT_MEMBERS_ERROR = 'SEND_PROJECT_MEMBERS_ERROR';

export const SEND_PROJECT_MEMBER_ROLES_START =
  'SEND_PROJECT_MEMBER_ROLES_START';
export const SEND_PROJECT_MEMBER_ROLES_SUCCESS =
  'SEND_PROJECT_MEMBER_ROLES_SUCCESS';
export const SEND_PROJECT_MEMBER_ROLES_ERROR =
  'SEND_PROJECT_MEMBER_ROLES_ERROR';

export const SEND_PROJECT_MEMBER_PRIVILEGES_START =
  'SEND_PROJECT_MEMBER_PRIVILEGES_START';
export const SEND_PROJECT_MEMBER_PRIVILEGES_SUCCESS =
  'SEND_PROJECT_MEMBER_PRIVILEGES_SUCCESS';
export const SEND_PROJECT_MEMBER_PRIVILEGES_ERROR =
  'SEND_PROJECT_MEMBER_PRIVILEGES_ERROR';

export const PROJECT_CREATION_START = 'PROJECT_CREATION_START';
export const PROJECT_CREATION_END = 'PROJECT_CREATION_END';
export const UPDATE_PROJECT_DEFINITION = 'UPDATE_PROJECT_DEFINITION';
export const SUBMIT_PROJECT_DEFINITION = 'SUBMIT_PROJECT_DEFINITION';
export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';

export const CREATE_ELEMENT_START = 'CREATE_ELEMENT_START';
export const CREATE_ELEMENT_SUCCESS = 'CREATE_ELEMENT_SUCCESS';
export const CREATE_ELEMENT_ERROR = 'CREATE_ELEMENT_ERROR';

export const UPDATE_ELEMENT_START = 'UPDATE_ELEMENT_START';
export const UPDATE_ELEMENT_SUCCESS = 'UPDATE_ELEMENT_SUCCESS';
export const UPDATE_ELEMENT_ERROR = 'UPDATE_ELEMENT_ERROR';

export const SOFT_DELETE_ELEMENT_START = 'SOFT_DELETE_ELEMENT_START';
export const SOFT_DELETE_ELEMENT_SUCCESS = 'SOFT_DELETE_ELEMENT_SUCCESS';
export const SOFT_DELETE_ELEMENT_ERROR = 'SOFT_DELETE_ELEMENT_ERROR';

export const RESTORE_ELEMENT_START = 'RESTORE_ELEMENT_START';
export const RESTORE_ELEMENT_SUCCESS = 'RESTORE_ELEMENT_SUCCESS';
export const RESTORE_ELEMENT_ERROR = 'RESTORE_ELEMENT_ERROR';

export const SUSPEND_PROJECT_ELEMENT_START = 'SUSPEND_PROJECT_ELEMENT_START';
export const SUSPEND_PROJECT_ELEMENT_SUCCESS =
  'SUSPEND_PROJECT_ELEMENT_SUCCESS';
export const SUSPEND_PROJECT_ELEMENT_ERROR = 'SUSPEND_PROJECT_ELEMENT_ERROR';

export const UNSUSPEND_PROJECT_ELEMENT_START =
  'UNSUSPEND_PROJECT_ELEMENT_START';
export const UNSUSPEND_PROJECT_ELEMENT_SUCCESS =
  'UNSUSPEND_PROJECT_ELEMENT_SUCCESS';
export const UNSUSPEND_PROJECT_ELEMENT_ERROR =
  'UNSUSPEND_PROJECT_ELEMENT_ERROR';

export const CREATE_ELEMENT_RATE_START = 'CREATE_ELEMENT_RATE_START';
export const CREATE_ELEMENT_RATE_SUCCESS = 'CREATE_ELEMENT_RATE_SUCCESS';
export const CREATE_ELEMENT_RATE_ERROR = 'CREATE_ELEMENT_RATE_ERROR';

export const UPDATE_ELEMENT_RATE_START = 'UPDATE_ELEMENT_RATE_START';
export const UPDATE_ELEMENT_RATE_SUCCESS = 'UPDATE_ELEMENT_RATE_SUCCESS';
export const UPDATE_ELEMENT_RATE_ERROR = 'UPDATE_ELEMENT_RATE_ERROR';

export const DELETE_ELEMENT_RATE_START = 'DELETE_ELEMENT_RATE_START';
export const DELETE_ELEMENT_RATE_SUCCESS = 'DELETE_ELEMENT_RATE_SUCCESS';
export const DELETE_ELEMENT_RATE_ERROR = 'DELETE_ELEMENT_RATE_ERROR';

export const CREATE_ELEMENT_NOTE_START = 'CREATE_ELEMENT_NOTE_START';
export const CREATE_ELEMENT_NOTE_SUCCESS = 'CREATE_ELEMENT_NOTE_SUCCESS';
export const CREATE_ELEMENT_NOTE_ERROR = 'CREATE_ELEMENT_NOTE_ERROR';

export const UPDATE_ELEMENT_NOTE_START = 'UPDATE_ELEMENT_NOTE_START';
export const UPDATE_ELEMENT_NOTE_SUCCESS = 'UPDATE_ELEMENT_NOTE_SUCCESS';
export const UPDATE_ELEMENT_NOTE_ERROR = 'UPDATE_ELEMENT_NOTE_ERROR';

export const DELETE_ELEMENT_NOTE_START = 'DELETE_ELEMENT_NOTE_START';
export const DELETE_ELEMENT_NOTE_SUCCESS = 'DELETE_ELEMENT_NOTE_SUCCESS';
export const DELETE_ELEMENT_NOTE_ERROR = 'DELETE_ELEMENT_NOTE_ERROR';

export const CREATE_ELEMENT_TIME_START = 'CREATE_ELEMENT_TIME_START';
export const CREATE_ELEMENT_TIME_SUCCESS = 'CREATE_ELEMENT_TIME_SUCCESS';
export const CREATE_ELEMENT_TIME_ERROR = 'CREATE_ELEMENT_TIME_ERROR';

export const UPDATE_ELEMENT_TIME_START = 'UPDATE_ELEMENT_TIME_START';
export const UPDATE_ELEMENT_TIME_SUCCESS = 'UPDATE_ELEMENT_TIME_SUCCESS';
export const UPDATE_ELEMENT_TIME_ERROR = 'UPDATE_ELEMENT_TIME_ERROR';

export const DELETE_ELEMENT_TIME_START = 'DELETE_ELEMENT_TIME_START';
export const DELETE_ELEMENT_TIME_SUCCESS = 'DELETE_ELEMENT_TIME_SUCCESS';
export const DELETE_ELEMENT_TIME_ERROR = 'DELETE_ELEMENT_TIME_ERROR';

export const CONTRACT_INVITE_REACTION_START = 'CONTRACT_INVITE_REACTION_START';
export const CONTRACT_INVITE_REACTION_SUCCESS =
  'CONTRACT_INVITE_REACTION_SUCCESS';
export const CONTRACT_INVITE_REACTION_FAILED =
  'CONTRACT_INVITE_REACTION_FAILED';

export const CREATE_MILESTONE_START = 'CREATE_MILESTONE_START';
export const CREATE_MILESTONE_SUCCESS = 'CREATE_MILESTONE_SUCCESS';
export const CREATE_MILESTONE_ERROR = 'CREATE_MILESTONE_ERROR';

export const UPDATE_MILESTONE_START = 'UPDATE_MILESTONE_START';
export const UPDATE_MILESTONE_SUCCESS = 'UPDATE_MILESTONE_SUCCESS';
export const UPDATE_MILESTONE_ERROR = 'UPDATE_MILESTONE_ERROR';

export const DEACTIVATE_PROJECT_START = 'DEACTIVATE_PROJECT_START';
export const DEACTIVATE_PROJECT_SUCCESS = 'DEACTIVATE_PROJECT_SUCCESS';
export const DEACTIVATE_PROJECT_ERROR = 'DEACTIVATE_PROJECT_ERROR';

export const UPDATE_RATE_START = 'UPDATE_RATE_START';
export const UPDATE_RATE_SUCCESS = 'UPDATE_RATE_SUCCESS';
export const UPDATE_RATE_ERROR = 'UPDATE_RATE_ERROR';
