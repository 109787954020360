import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    '& span': {
      fontSize: 18,
      color: '#8EA4C6',
    },
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    '& button': {
      color: '#8EA4C6',
      background: 'transparent',
      border: '1px solid #707070',
      boxShadow: 'none',
      borderRadius: 20,
      marginRight: 10,
      '&:hover': {
        background: '#D8D8D8',
        color: '#FFFFFF',
        border: '1px solid #D8D8D8',
      },
    },
  },
  timeGrid: {
    marginTop: 12,
    height: 215,
    background: 'grey',
    width: '100%',
  },
}));

const TimeLIne = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <span>Timeline Scheduler</span>
      </div>
      <div className={classes.buttons}>
        <Button variant="contained" color="default">
          Day
        </Button>
        <Button variant="contained" color="default">
          Week
        </Button>
        <Button variant="contained" color="default">
          Month
        </Button>
      </div>
      <div className={classes.timeGrid}></div>
    </div>
  );
};

export default TimeLIne;
