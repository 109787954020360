import React from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const SkillAutocomplete = (props) => {
  const { skills, onChange, selected, variant = 'standard' } = props;
  return (
    <Autocomplete
      getOptionSelected={(o, v) => o.name === v.name}
      disableCloseOnSelect
      multiple
      id="skills"
      options={skills}
      getOptionLabel={(o) => o.name}
      getOptionDisabled={() =>
        selected?.length === Number(process.env.REACT_APP_MAX_SKILLS)
      } // all options disabled when reached max count
      onChange={onChange}
      value={selected}
      noOptionsText={<Typography variant="body2">No matches</Typography>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label="Skills"
          multiline
          placeholder="Your strong sides"
          margin="dense"
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  skills: state.member.skillsSet,
});

export default connect(mapStateToProps)(SkillAutocomplete);
