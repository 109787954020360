import { Strophe } from 'strophe.js';
import { chatDateGroupingFormat } from '../dateFormat';

export const groupMessagesByDate = (msgs) => {
  let messages = [...msgs];
  // reverse messages as well for date in descending
  let group = messages.reverse().reduce((r, a) => {
    const dateTime = new Date(a.stamp);
    //set time to 0 to get same day
    dateTime.setHours(0);
    dateTime.setMinutes(0);
    dateTime.setSeconds(0, 0);
    r[dateTime.toUTCString()] = [...(r[dateTime.toUTCString()] || []), a];
    return r;
  }, {});
  return group;
};

export const getMessagesDividerDate = (date) => {
  let a = new Date(date), // input date to process
    b = new Date(), // today date time
    c = new Date(Date.now() - 864e5); // Yesterday date time

  a = setTimeToZero(a);
  b = setTimeToZero(b);
  c = setTimeToZero(c);

  if (a.getTime() === b.getTime()) {
    return 'Today';
  } else if (a.getTime() === c.getTime()) {
    return 'Yesterday';
  } else {
    return chatDateGroupingFormat(date);
  }
  // else if ((new Date(2015, 8 - 1, 25 - 7)).getTime() < c.getTime())
  // return "Less than a week";
};

export const isTodayMessage = (date) => {
  let a = new Date(date), // input date to process
    b = new Date(); // today date time
  a = setTimeToZero(a);
  b = setTimeToZero(b);
  return a.getTime() === b.getTime();
};

const setTimeToZero = (dateTime) => {
  dateTime.setHours(0);
  dateTime.setMinutes(0);
  dateTime.setSeconds(0, 0);
  return dateTime;
};

//temp solution should not depend on client end values
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const xmlToJson = (xml) => {
  // Create the return object
  var obj = {};

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof obj[nodeName] == 'undefined') {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push == 'undefined') {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
};

export const getTextFromMessageNode = (msgNode) => {
  return Strophe.getText(msgNode);
};
