import { borderColor, textDark } from '../colors';

const guestHeaderStyle = (theme) => ({
  root: {
    height: '120px',
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    color: textDark,
    fontSize: '22px',
    '& a': {
      color: textDark,
      textDecoration: 'none',
    },
  },
  logo: {
    height: `100%`,
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    flex: 'none',
    justifyContent: 'center',
    borderRight: `1px solid ${borderColor}`,
    marginRight: theme.spacing(1),
  },
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: theme.spacing(1),
    '& a': {
      margin: theme.spacing(1),
    },
  },
  leftGroup: {
    minWidth: '560px',
    '& a': {
      '&:first-child': {
        marginRight: theme.spacing(3),
      },
    },
  },
  rightGroup: {
    maxWidth: '320px',
    minWidth: '254px'
  },
})

export default guestHeaderStyle;