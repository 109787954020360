export const ACTIVITY_TYPES = {
  sessions: 'sessions',
  threats: 'threats',
  mails: 'mails',
  groups: 'groups',
  invitations: 'invitations',
  video_mails: 'video_mails',
};

export const PAGE_SIZE_OPTIONS = [25, 50, 75, 100];

export const DEFAULT_PARAMS = {
  orderBy: 'update_date',
  order: 'desc',
  search_key: '',
  page_number: 0,
  page_size: PAGE_SIZE_OPTIONS[0],
};

export const DEFAULT_PARAMS_MEMBERS = {
  orderBy: 'create_date',
  order: 'desc',
  search_key: '',
  page_number: 0,
  page_size: PAGE_SIZE_OPTIONS[0],
};
