import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import MemberAutocomplete from '../../../../components/MemberAutocomplete';
import GroupAutocomplete from '../../../../components/GroupAutocomplete';
import SelectedFileList from '../SelectedFileList';
import { makeStyles } from '@material-ui/core/styles';
import { set, flatten, includes } from 'lodash';

import Modal from '../../../../components/Modal/modal';

import { shareFile } from '../../../../redux/actions/fileshare';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles((theme) => ({
  actionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '25%',
    color: 'white',
  },
}));

const ShareProcess = (props) => {
  const {
    dispatch,
    show,
    close,
    files,
    // getMembers,
  } = props;

  const [fileList, setFileList] = useState([]);
  const [targetMembers, setTargetMembers] = useState([]);
  const [targetGroups, setTargetGroups] = useState([]);
  // const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const prevState = usePrevious({ loading });
  const prevShow = usePrevious(show);
  const [toogleButtonValue, setToogleButtonValue] = useState('group');

  const title = 'Drive';
  const classes = useStyles();

  const closeModal = useCallback(() => {
    setTargetMembers([]);
    setTargetGroups([]);
    setFileList([]);
    close();
  }, [close]);

  useEffect(() => {
    // Load only upon opening the modal, ignore updates that happen to file selection afterwards
    console.log('prevShow', prevShow);
    console.log('show', show);
    if (!prevShow && show) {
      // console.log('Will set files');
      setFileList(files);
    }
  }, [files, prevShow, show]);

  useEffect(() => {
    if (prevState && loading === false && prevState.loading === true) {
      closeModal();
    }
  }, [loading, closeModal, prevState]);

  const handleToggleClick = (e, v) => {
    if (v) {
      setToogleButtonValue(v);
    }
  };

  const handleSubmitClick = async () => {
    setLoading(true);
    let payload = {};
    set(payload, 'targetType', toogleButtonValue);
    set(
      payload,
      'targets',
      toogleButtonValue === 'group'
        ? targetGroups.map((t) => t.group_id)
        : targetMembers.map((t) => t.contact_member_id)
    );
    set(payload, 'fileList', fileList);

    console.log('share payload', payload);
    await dispatch(shareFile(payload));
    setLoading(false);
  };

  const handleUnlistItem = (e, fileId) => {
    const newFileList = fileList.filter((f) => f.id !== fileId);
    setFileList(newFileList);
    if (newFileList.length === 0) {
      closeModal();
    }
  };

  const handleGroupsSelect = (e, v) => {
    setTargetGroups(v);
  };
  const handleMembersSelect = (e, v) => {
    setTargetMembers(v);
  };

  let membersAlreadyHavingShares;
  if (fileList.find((f) => f.sharedWithMembers)) {
    membersAlreadyHavingShares = flatten(
      fileList.map((f) => f.sharedWithMembers)
    ).map((c) => c.consumer_id);
  } else {
    membersAlreadyHavingShares = [];
  }

  const isAlreadySharedWithMember = useMemo(() => {
    return (option) =>
      includes(membersAlreadyHavingShares, option.contact_member_id);
  }, [membersAlreadyHavingShares]);

  let groupsAlreadyHavingShares;
  if (fileList.find((f) => f.sharedWithGroups)) {
    groupsAlreadyHavingShares = flatten(
      fileList.map((f) => f.sharedWithGroups)
    ).map((g) => g.group_id);
  } else {
    groupsAlreadyHavingShares = [];
  }

  const isAlreadySharedWithGroup = useMemo(() => {
    return (option) => includes(groupsAlreadyHavingShares, option.group_id);
  }, [groupsAlreadyHavingShares]);

  const isCanSubmit =
    fileList.length > 0 &&
    (toogleButtonValue === 'group'
      ? targetGroups.length > 0
      : targetMembers.length > 0);

  const ModalContent = () => (
    <>
      <div className="toggle-button-group">
        <ToggleButtonGroup
          value={toogleButtonValue}
          exclusive
          onChange={handleToggleClick}
          aria-label="text alignment"
        >
          <ToggleButton value="group" aria-label="group" variant="secondary">
            Group
          </ToggleButton>
          <ToggleButton value="member" aria-label="member" variant="secondary">
            Member
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Typography variant="body1">Will share the following</Typography>
      <SelectedFileList
        fileList={fileList}
        handleUnlistItem={handleUnlistItem}
      />

      {toogleButtonValue === 'group' ? (
        <GroupAutocomplete
          handleChange={handleGroupsSelect}
          disablerFunction={isAlreadySharedWithGroup}
          label="Share with groups"
          values={targetGroups}
        />
      ) : (
        <MemberAutocomplete
          handleChange={handleMembersSelect}
          disablerFunction={isAlreadySharedWithMember}
          label="Share with individuals"
          values={targetMembers}
        />
      )}
    </>
  );

  const ModalAction = () => {
    return (
      <div className={classes.actionWrapper}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.actionBtn}
          onClick={() => close()}
          disabled={loading}
        >
          Cancel
        </Button>
        {loading ? (
          <Button variant="contained" className={classes.actionBtn} disabled>
            Sharing...
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.actionBtn}
            disableRipple
            onClick={handleSubmitClick}
            disabled={!isCanSubmit}
          >
            Share
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onClose={close}
        title={title}
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="share-modal"
      ></Modal>
    </>
  );
};

export default connect()(ShareProcess);
