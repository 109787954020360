import {
  SAVE_SIGN_UP_SESSION,
  CLEAR_SIGN_UP_SESSION,
  MEMBER_LOGIN_SUCCEEDED,
  MEMBER_LOGIN_FAILED,
  MEMBER_LOGIN_STARTED,
  MEMBER_LOGIN_COMPLETED,
  GET_MEMBER_DATA_START,
  GET_MEMBER_DATA_SUCCEEDED,
  GET_MEMBER_DATA_FAILED,
  MEMBER_LOGOUT_SUCCEEDED,
  SEND_MEMBER_RESET_EMAIL_START,
  SEND_MEMBER_RESET_EMAIL_SUCCEEDED,
  SEND_MEMBER_RESET_EMAIL_FAILED,
  GET_MEMBER_LOCATION_INFO_START,
  GET_MEMBER_LOCATION_INFO_SUCCEEDED,
  GET_MEMBER_LOCATION_INFO_FAILED,
  MEMBERS_LOADING,
  MEMBERS_LOAD_SUCCESS,
  SIGNUP_SUCCESS,
  VALID_INVITE,
  MEMBER_SAVE_CREDENTIALS,
  FETCH_REQUEST_START,
  FETCH_REQUEST_SUCCESS,
  FETCH_REQUEST_FAILED,
  UPDATE_REQUEST_START,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_FAILED,
  // Registration
  SEND_REGISTER_START,
  SEND_REGISTER_SUCCEEDED,
  SEND_REGISTER_FAILED,
  // Job title list
  GET_TITLE_LIST_START,
  GET_TITLE_LIST_SUCCEEDED,
  GET_TITLE_LIST_FAILED,
  GET_DEPS_LIST_START,
  GET_DEPS_LIST_SUCCEEDED,
  GET_DEPS_LIST_FAILED,
  // Skillset
  GET_SKILLS_START,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_FAILED,
  // Industries
  GET_INDUSTRY_LIST,
  GET_INDUSTRY_SUCCESS,
  GET_INDUSTRY_ERROR,
  // POTP
  CELL_VERIFICATION_INIT,
  CELL_VERIFICATION_ATTEMPT,
  CELL_VERIFICATION_RESPONSE,
  CELL_VERIFICATION_ERROR,
  // Terms and conditions
  GET_TERMS_AND_CONDITIONS_START,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAILED,
  // Country list
  // GET_COUNTRY_LIST_START,
  GET_COUNTRY_LIST_SUCCESS,
  // GET_COUNTRY_LIST_FAILED,

  // tz list
  // GET_TZLIST_START,
  GET_TZLIST_SUCCESS,
  // GET_TZLIST_FAILED,

  //member default page settings
  GET_PAGE_SETTINGS_SUCCESS,
  PUT_PAGE_SETTINGS_SUCCESS,
  INSERT_PAGE_SETTINGS_SUCCESS,

  // Member information
  // GET_MEMBER_INFO_START,
  GET_MEMBER_INFO_SUCCESS,
  // GET_MEMBER_INFO_FAILED,
  PUT_MEMBER_INFO_START,
  PUT_MEMBER_INFO_SUCCESS,
  // PUT_MEMBER_INFO_FAILED,

  // Avatar update
  // PUT_AVATAR_UPDATE_START,
  GET_MEMBER_SETTING_SUCCESS,
  PUT_MEMBER_SETTING_SUCCESS,
  PUT_AVATAR_UPDATE_SUCCESS,
  // PUT_AVATAR_UPDATE_FAILED,
  FETCH_ROLES_START,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  SET_MEMBER_ALERT,

  //Notifications setting
  FETCH_NOTIFICATIONS_SETTING_START,
  FETCH_NOTIFICATIONS_SETTING_SUCCESS,
  FETCH_NOTIFICATIONS_SETTING_FAILED,
  PUT_NOTIFICATIONS_SETTING_START,
  PUT_NOTIFICATIONS_SETTING_SUCCESS,
  PUT_NOTIFICATIONS_SETTING_FAILED,
  SHOW_COUNTDOWN,
  SET_COUNTDOWN_TIMER,
  AUTO_LOGOUT,
  CLEAR_AUTO_LOGOUT,
  GET_ALL_MEMBERS,
  FACE_REGISTERED,
  FACE_MATCHED,
  RESET_FACE_MATCH,
  LOAD_SUCCESS_VIDEO_MAILS,
  READ_VIDEO_MAIL,
  DELETE_VIDEO_MAIL,
  REPLY_MESSAGE_SENT_SUCCESS,
  REPLY_MESSAGE_SENT_FAIL,
  OUTGOING_PHONE_VERIFICATION_STATUS,
  UPDATE_LOCATIONS_START,
  UPDATE_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS_FAIL,
  MEMBER_LOGOUT_FAILED,
  DELETE_LOCATION_SUCCESS,
  UPDATE_USERNAME_SUCCESS,
} from '../actionTypes/member.js';

import { APP_RESET, APP_START } from '../actionTypes/app';
import { MY_LOCATION_SECTIONS } from '../../utils/calendar';
import history from '../../utils/history';

export const initialState = {
  // member: { member_id: 1 },
  member: null,
  memberInfo: {},
  session_id: '',
  signUpSession: '',
  locationInfo: {},
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  isUpdated: false,
  isSubmittingRegisterForm: false,
  isLoadingVerification: false,
  error: '',
  members: [],
  loginErr: false,
  loginAttempt: false,
  loginCompleted: false,
  loadingMembers: false,
  redirectingToDashboard: false,
  invite: {},
  credentials: null,
  jobTitleList: [],
  departmentsList: [],
  countryList: [],
  timezoneList: [],
  skillsSet: [],
  industries: [],
  roles: [],
  pageSettings: [],
  termsConditions: {},
  notificationsSetting: {},
  memberAlert: {
    open: false,
    severity: 'success',
    message: '',
  },
  showCountdown: false,
  countdownTimer: null,
  autoLogout: false,
  memberSetting: {},
  ameraMembers: [],
  faceRegistered: false,
  faceMatched: false,
  videoMails: [],
  streamVideos: [],
  isLoadingLocations: false,
  isLoadingHomeLocations: false,
  isLoadingWorkLocations: false,
  isLoadingOtherLocations: false,
  isLoadingEventsLocations: false,
  loadingSession: false,
};

let newVideoMails = [];

const member = (state = initialState, action) => {
  if (!state.memberInfo) {
    state.memberInfo = {};
  }
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case APP_START:
      return {
        ...state,
        loginAttempt: false,
        loginCompleted: false,
      };
    case GET_MEMBER_DATA_START:
      return {
        ...state,
        loadingSession: true,
      };
    case GET_MEMBER_DATA_SUCCEEDED:
      return {
        ...state,
        member: action.payload || {},
        session_id: action.payload.session_id,
        autoLogout: false,
        loadingSession: false,
      };
    case GET_MEMBER_DATA_FAILED:
      return {
        ...state,
        member: {},
        session_id: '',
        loginAttempt: false,
        loadingSession: false,
        loginCompleted: false,
        redirectingToDashboard: false,
      };
    case SAVE_SIGN_UP_SESSION:
      return {
        ...state,
        signUpSession: action.payload,
      };
    case CLEAR_SIGN_UP_SESSION:
      return {
        ...state,
        signUpSession: '',
      };
    case MEMBER_LOGIN_STARTED:
      return {
        ...state,
        loadingSession: false,
        loginCompleted: false,
        loginAttempt: true,
      };
    case MEMBER_LOGIN_COMPLETED:
      return {
        ...state,
        loginCompleted: true,
        redirectingToDashboard: false,
        loginAttempt: false,
      };
    case MEMBER_LOGIN_SUCCEEDED:
      history.push('/dashboard');
      return {
        ...state,
        loginCompleted: true,
        loginAttempt: false,
        redirectingToDashboard: true,
        session_id: action.payload.session_id,
      };
    case MEMBER_LOGIN_FAILED:
      return {
        ...state,
        loginErr: action.payload.loginErr,
        loginAttempt: false,
        loginCompleted: false,
        faceMatched: false,
      };
    case MEMBER_LOGOUT_SUCCEEDED:
      return {
        ...state,
        member: {},
        memberInfo: {},
        session_id: '',
        loginAttempt: false,
        loginCompleted: false,
        redirectingToDashboard: false,
      };
    case FETCH_REQUEST_START:
      return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
      };
    case FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        isSuccess: true,
      };
    case FETCH_REQUEST_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        isSuccess: false,
      };
    case PUT_MEMBER_INFO_START:
    case UPDATE_REQUEST_START:
      return {
        ...state,
        isUpdated: false,
        error: null,
        isLoading: true,
        isSuccess: false,
      };
    case UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        error: '',
        isLoading: false,
        isSuccess: false,
      };
    case UPDATE_REQUEST_FAILED:
      return {
        ...state,
        isUpdated: false,
        isLoading: false,
        error: action.payload,
      };
    case SEND_MEMBER_RESET_EMAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_MEMBER_RESET_EMAIL_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case SEND_MEMBER_RESET_EMAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        error: action.payload,
      };
    case GET_MEMBER_LOCATION_INFO_START:
      return {
        ...state,
        locationInfo: action.payload,
        isFetching: true,
      };
    case GET_MEMBER_LOCATION_INFO_SUCCEEDED:
      return {
        ...state,
        locationInfo: action.payload,
        isFetching: false,
      };
    case GET_MEMBER_LOCATION_INFO_FAILED:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case MEMBERS_LOADING:
      return {
        ...state,
        loadingMembers: true,
      };
    case MEMBERS_LOAD_SUCCESS:
      return {
        ...state,
        loadingMembers: false,
        members: action.payload,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        member: {},
        session_id: {},
      };
    case VALID_INVITE:
      return {
        ...state,
        invite: action.payload,
      };
    case MEMBER_SAVE_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };

    case GET_DEPS_LIST_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_DEPS_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        departmentsList: [
          ...action.payload,
          {
            department_id: 'not_applicable',
            department_name: 'Not Applicable',
          },
        ],
      };

    case GET_DEPS_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_TITLE_LIST_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_TITLE_LIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        jobTitleList: [
          ...action.payload,
          { job_title_id: 'not_applicable', job_title: 'Not Applicable' },
        ],
      };
    case GET_TITLE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_TERMS_AND_CONDITIONS_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        termsConditions: action.payload,
      };
    case GET_TERMS_AND_CONDITIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_SKILLS_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_SKILLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        skillsSet: action.payload,
      };

    case GET_SKILLS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_INDUSTRY_LIST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        industries: action.payload,
      };

    case GET_INDUSTRY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    // Phone verification
    case CELL_VERIFICATION_INIT:
      return {
        ...state,
        isLoadingVerification: false,
      };
    case CELL_VERIFICATION_ATTEMPT:
      return {
        ...state,
        isLoadingVerification: true,
      };
    case CELL_VERIFICATION_RESPONSE:
      return {
        ...state,
        isLoadingVerification: false,
      };
    case CELL_VERIFICATION_ERROR:
      return {
        ...state,
        isLoadingVerification: false,
      };
    case SEND_REGISTER_START:
      return {
        ...state,
        isSubmittingRegisterForm: true,
      };
    // case SEND_REGISTER_SUCCEEDED:
    //   return {
    //     ...state,
    //     isSubmittingRegisterForm: false,
    //     memberAlert: {
    //       open: true,
    //       severity: 'success',
    //       message:
    //         'Member registered successfully! Redirecting to Login page...',
    //     },
    //   };

    case SEND_REGISTER_SUCCEEDED:
    case SEND_REGISTER_FAILED:
      return {
        ...state,
        isSubmittingRegisterForm: false,
      };

    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
      };

    case GET_TZLIST_SUCCESS:
      return {
        ...state,
        timezoneList: action.payload,
      };
    case GET_PAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        pageSettings: action.payload,
      };
    case INSERT_PAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        pageSettings: [...state.pageSettings, action.payload],
      };
    case PUT_PAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        pageSettings: state.pageSettings.map((obj) => {
          if (obj.id === action.payload.id) {
            return action.payload;
          }
          return obj;
        }),
      };
    case GET_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
      };

    // After sumbitting the Profile Edit for we receive the new memberInfo and write
    case PUT_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
      };

    case GET_MEMBER_SETTING_SUCCESS:
      return {
        ...state,
        memberSetting: action.payload || {},
      };

    case PUT_MEMBER_SETTING_SUCCESS:
      return {
        ...state,
        memberSetting: action.payload || {},
      };
    case PUT_AVATAR_UPDATE_SUCCESS:
      return {
        ...state,
        member: { ...state.member, amera_avatar_url: action.payload },
        memberInfo: { ...state.memberInfo, amera_avatar_url: action.payload },
      };
    case FETCH_ROLES_START:
    case FETCH_NOTIFICATIONS_SETTING_START:
    case PUT_NOTIFICATIONS_SETTING_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        isLoading: false,
      };
    case FETCH_ROLES_FAILED:
    case FETCH_NOTIFICATIONS_SETTING_FAILED:
    case PUT_NOTIFICATIONS_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case FETCH_NOTIFICATIONS_SETTING_SUCCESS:
      return {
        ...state,
        notificationsSetting: action.payload,
        isLoading: false,
      };
    case PUT_NOTIFICATIONS_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case SET_MEMBER_ALERT:
      return {
        ...state,
        memberAlert: action.payload,
        isLoading: false,
      };

    case SHOW_COUNTDOWN:
      return {
        ...state,
        showCountdown: action.payload,
      };
    case SET_COUNTDOWN_TIMER:
      if (state.countdownTimer) {
        clearTimeout(state.countdownTimer);
      }
      return {
        ...state,
        countdownTimer: action.payload,
      };
    case AUTO_LOGOUT:
      return {
        ...state,
        autoLogout: true,
      };
    case CLEAR_AUTO_LOGOUT:
      return {
        ...state,
        autoLogout: false,
      };
    case GET_ALL_MEMBERS:
      return {
        ...state,
        ameraMembers: action.payload,
      };
    case FACE_REGISTERED:
      return {
        ...state,
        faceRegistered: true,
      };
    case FACE_MATCHED:
      return {
        ...state,
        faceMatched: true,
      };
    case RESET_FACE_MATCH:
      return {
        ...state,
        faceMatched: false,
      };
    case LOAD_SUCCESS_VIDEO_MAILS:
      return {
        ...state,
        videoMails: action.payload,
      };
    case READ_VIDEO_MAIL:
      newVideoMails = state.videoMails.map((mail) => {
        if (mail.id === action.payload) {
          return {
            ...mail,
            status: 'read',
          };
        }
        return mail;
      });
      return {
        ...state,
        videoMails: newVideoMails,
      };
    case DELETE_VIDEO_MAIL:
      newVideoMails = state.videoMails.filter(
        (mail) => mail.id !== action.payload
      );
      return {
        ...state,
        videoMails: newVideoMails,
      };
    case REPLY_MESSAGE_SENT_SUCCESS:
      return {
        ...state,
        memberAlert: {
          show: true,
          variant: 'success',
          message: 'Mail was sent successfully!',
        },
      };
    case REPLY_MESSAGE_SENT_FAIL:
      return {
        ...state,
        memberAlert: {
          show: true,
          variant: 'error',
          message: 'Mail was not sent. try later!',
        },
      };
    case OUTGOING_PHONE_VERIFICATION_STATUS:
      const newMemberInfo = getNewMemberInfoBasedOnTwilioVerification(
        state.memberInfo,
        action.payload.contact_id,
        action.payload.status
      );
      return {
        ...state,
        memberInfo: newMemberInfo,
      };
    case UPDATE_LOCATIONS_START:
      return {
        ...state,
        isLoadingLocations: true,
        isLoadingHomeLocations: action.payload === MY_LOCATION_SECTIONS.home,
        isLoadingWorkLocations: action.payload === MY_LOCATION_SECTIONS.work,
        isLoadingEventsLocations:
          action.payload === MY_LOCATION_SECTIONS.events,
        isLoadingOtherLocations: action.payload === MY_LOCATION_SECTIONS.other,
      };
    case DELETE_LOCATION_SUCCESS:
    case UPDATE_LOCATIONS_SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
        isLoadingLocations: false,
        isLoadingHomeLocations: false,
        isLoadingWorkLocations: false,
        isLoadingEventsLocations: false,
        isLoadingOtherLocations: false,
      };
    case UPDATE_LOCATIONS_FAIL:
      return {
        ...state,
        isLoadingLocations: false,
        isLoadingHomeLocations: false,
        isLoadingWorkLocations: false,
        isLoadingEventsLocations: false,
        isLoadingOtherLocations: false,
      };

    case MEMBER_LOGOUT_FAILED: {
      return {
        ...state,
        member: {},
        memberInfo: {},
        session_id: '',
      };
    }

    case UPDATE_USERNAME_SUCCESS: {
      return {
        ...state,
        member: {
          ...state.member,
          username: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

const getNewMemberInfoBasedOnTwilioVerification = (
  memberInfo,
  contact_id,
  status
) => {
  const contact_information = memberInfo.contact_information.map((ci) => {
    if (ci.id !== contact_id) return ci;
    return {
      ...ci,
      outgoing_caller_verified: status === 'success' ? true : false,
    };
  });
  return {
    ...memberInfo,
    contact_information,
  };
};

export default member;
