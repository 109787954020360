import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ADDRESS_TYPES, addressTypeText } from '../../../../../utils/calendar';

const LocationTypeSelector = (props) => {
  const { location_type, handleChange, full = true } = props;

  const types = full
    ? Object.values(ADDRESS_TYPES)
    : [ADDRESS_TYPES.billing, ADDRESS_TYPES.home, ADDRESS_TYPES.work];

  return (
    <FormControl className="location-type-selector" fullWidth>
      <InputLabel id="location-type-input-label">Location type</InputLabel>
      <Select
        labelId="location-type"
        name="location_type"
        value={location_type}
        onChange={handleChange}
      >
        {types.sort().map((v) => (
          <MenuItem key={v} value={v}>
            {addressTypeText(v)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocationTypeSelector;
