import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { apiUrl } from '../config/api';
import ImageDialog from './ImageDialog';

const Thumb = (props) => {
  const { src } = props;
  const [isOpen, setOpen] = useState(false);

  const imageUrl = `${apiUrl}/${src}`;
  return (
    <>
      <Avatar
        src={imageUrl}
        variant="rounded"
        onClick={() => setOpen(true)}
        style={{ cursor: 'pointer' }}
      />
      {isOpen && (
        <ImageDialog
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          imageUrl={imageUrl}
        />
      )}
    </>
  );
};

export default Thumb;
