import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { formatISO } from 'date-fns';

import FileShareSpinner from '../FileShare/FileShareSpinner';
import LeftbgContainer from './LeftbgContainer';
import RegisterForm from './RegisterForm';
import Footer from '../Sections/Footer/GuestFooter';
import ErrorBoundary from '../../components/ErrorBoundary';

import { setMemberAlert } from '../../utils/alert';
import {
  ValidInvite,
  setReduxMemberAlert,
  getTitleList,
  getDepartmentList,
  getCountryList,
  // getRoleList,
} from '../../redux/actions/member';
import { loadCompanies } from '../../redux/actions/company';

const Registration = (props) => {
  const { isSubmittingRegisterForm, dispatch } = props;
  const [invite, setInvite] = useState(null);

  // TEST VALUES
  // const [values, setValues] = useState({
  //   country: 392,
  //   job_title_id: 5,
  //   department_id: 7,
  //   state: null,
  //   city: null,
  //   company: { id: null, name: 'Los Pollo Hermanos' },
  //   first_name: 'Jessie',
  //   last_name: 'Pinkman',
  //   email: 'andrey.vanyakin@gmail.com',
  //   password: 'password',
  //   confirm_password: 'password',
  //   postal_code: '22162',
  //   pin: 78787878,
  //   confirmPin: 78787878,
  //   dob: '1986-11-24',
  //   isApplicable: true,
  //   isAcceptedTerms: true,
  //   isOpenedTerms: true,
  //   isCellConfirmed: true,
  //   cellConfirmationTS: formatISO(new Date()),
  //   isEmailConfirmed: true,
  //   emailConfirmationTS: formatISO(new Date()),
  //   cell: '79035031963',
  //   activatedPromoCode: null,
  // });
  const [values, setValues] = useState({
    country: 840,
    postal_code: '',
    dob: null,
    cell: '',
    isCellConfirmed: false,
    state: null,
    city: null,
    isAcceptedTerms: false,
    isOpenedTerms: false,
    cellConfirmationTS: null,
    isEmailConfirmed: false,
    isApplicable: true,
    job_title_id: '',
    department_id: '',
    company: null,
    // cellConfirmationTS: null,
    emailConfirmationTS: null,
    signed_toc_id: null,
    activatedPromoCode: null,
  });

  const { inviteKey } = useParams();

  useEffect(() => {
    document.title = 'AmeraShare - Registration';
  }, []);

  // // Load roles list
  // useEffect(()=>dispatch(getRoleList()), [dispatch]);

  // Load the job title list to from back-end
  useEffect(() => dispatch(getTitleList()), [dispatch]);

  // Load the departments list
  useEffect(() => dispatch(getDepartmentList()), [dispatch]);

  useEffect(() => {
    async function fetchData() {
      const companyList = await dispatch(loadCompanies());
      const countryList = await dispatch(getCountryList());
      if (inviteKey) {
        console.log('validating invite');
        ValidInvite(inviteKey)
          .then((invite) => {
            if (invite) {
              setInvite(invite);
              if (invite.registered_member_id) {
                const message = 'Member already exists!';
                const payload = setMemberAlert(
                  null,
                  false,
                  message,
                  'top',
                  true
                );
                dispatch(setReduxMemberAlert(payload));
              } else {
                let inviteCountry = 840,
                  company = null;
                if (invite.country) {
                  inviteCountry = invite.country;
                }
                if (invite.company_id) {
                  company = companyList.find((c) => c.id === invite.company_id);
                } else if (invite.company_name) {
                  company = {
                    id: null,
                    name: invite.company_name,
                  };
                }
                setValues((prevState) => ({
                  ...prevState,
                  email: invite.email,
                  isEmailConfirmed: true,
                  emailConfirmationTS: formatISO(new Date()),
                  first_name: invite.first_name,
                  last_name: invite.last_name,
                  country: inviteCountry,
                  confirmPhoneRequired: invite.confirm_phone_required,
                  cell: invite.phone_number
                    ? `${
                        countryList.find((clo) => clo.id === inviteCountry)
                          ?.phone
                      }${invite.phone_number}`
                    : '',
                  company,
                }));
              }
            } else {
              const payload = setMemberAlert(null, false, null, 'top', true);
              dispatch(setReduxMemberAlert(payload));
            }
          })
          .catch((error) => {
            console.error(error);
            const message =
              'Invite is no longer valid, please request a new invite';
            const payload = setMemberAlert(error, false, message, 'top', true);
            dispatch(setReduxMemberAlert(payload));
          });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteKey]);

  return (
    <>
      <div className="registration_page">
        <Grid container spacing={0}>
          <Hidden only={['xs', 'sm']}>
            <Grid item xs={4}>
              <LeftbgContainer />
            </Grid>
          </Hidden>
          <Grid item sm={12} md={8}>
            <ErrorBoundary>
              <RegisterForm
                invite={invite}
                values={values}
                setValues={setValues}
                isShouldShowPromo={inviteKey ? false : true}
                isSubmittingRegisterForm={isSubmittingRegisterForm}
              />
              <FileShareSpinner isLoading={isSubmittingRegisterForm} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  invite: state.member.invite,
  isSubmittingRegisterForm: state.member.isSubmittingRegisterForm,
});

export default connect(mapStateToProps)(Registration);
