import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import GetAppIcon from '@material-ui/icons/GetApp';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { apiUrl } from '../../../config/api';

const PictureInput = (props) => {
  const { changed, propSrc, hidePinPicture, disabled, className } = props;
  const [src, setSrc] = useState(null);

  let content;
  if ((src || propSrc) && !hidePinPicture) {
    content = src ? (
      <CardMedia image={src} component="img" />
    ) : (
      <CardMedia image={`${apiUrl}${propSrc}`} component="img" />
    );
  } else {
    content = (
      <Button
        startIcon={<GetAppIcon />}
        variant="contained"
        color="primary"
        component="span"
        className="select-file"
        disabled={disabled || hidePinPicture}
      >
        Select a File
      </Button>
    );
  }

  const onPicChange = (e) => {
    e.preventDefault();
    if (hidePinPicture) {
      return;
    }
    const file = e.target.files[0];

    const reader = new FileReader();
    if (file) {
      changed(file);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSrc(reader.result);
      };
    }
  };

  return (
    <Card className={`picture-container ${className}`}>
      {!hidePinPicture && (
        <label>
          <input
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            name="profilePic"
            onChange={onPicChange}
            disabled={disabled}
          />
          <CardContent className="content-container">{content}</CardContent>
        </label>
      )}
    </Card>
  );
};

export default PictureInput;
