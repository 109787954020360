import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  // Link,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { unescape } from 'lodash';
import parse from 'html-react-parser';
import classNames from 'classnames';
import { format } from 'date-fns';
import Gallery from 'react-grid-gallery';

import MaterialAvatar from '../../../../components/MaterialAvatar';
// import CircularProgressWithLabel from '../../../../components/CircularProgressWithLabel';
import { defaultDateTimeZone } from '../../../../utils/calendar';
import { displayableFullName } from '../../../../utils/contact';
import { isTodayMessage } from '../../../../utils/strophe/chat';
// import { STROPHE_CHUNK_SIZE } from '../../../../utils/file';
import { EmptyFileIcon } from '../../../../assets/svg';

const MessageText = ({ message }) => (
  <div className="message-text-wrapper">
    <div className="message-text">
      <div>{parse(unescape(message))}</div>
    </div>
  </div>
);

const MessageFiles = ({ isMyMessage, messageFiles }) => {
  const images = messageFiles.filter((f) =>
      f?.['mime']?.match(/gif|png|jpg|jpeg/)
    ),
    otherFiles = messageFiles.filter(
      (f) => !f?.['mime']?.match(/gif|png|jpg|jpeg/)
    ),
    imagesCount = images?.length;

  // TODO: further update it based on files loading with sequence no
  // so then we can get proper progress (whether large or small files)
  let imagesLoadng;
  if (isMyMessage) {
    imagesLoadng = images.filter((img) => !img.loading)?.length;
  } else {
    imagesLoadng = images.filter((img) => img.src || img.loading)?.length;
  }
  const imagesProgressValue = Math.ceil((imagesLoadng / imagesCount) * 100);

  return (
    <div className="message-files">
      <div className="grid-gallery-wrapper">
        {imagesProgressValue >= 0 && imagesProgressValue < 100 && (
          <div className="images-circular-progress">
            <CircularProgress
              variant="determinate"
              value={imagesProgressValue}
            />
          </div>
        )}
        <Gallery
          maxRows={2}
          rowHeight={120}
          images={images.map((image, i) => {
            if (i === 3 && images.length > 4) {
              const tagValue = `${images.length - (i + 1)}+ more`;
              image.tags = [{ value: tagValue, title: tagValue }];
            }
            image.thumbnail = image.src || EmptyFileIcon;
            image.thumbnailWidth = 160;
            image.thumbnailHeight = 120;
            return image;
          })}
          tagStyle={{
            wordWrap: 'break-word',
            display: 'inline-block',
            backgroundColor: 'white',
            height: 'auto',
            fontSize: '75%',
            fontWeight: '600',
            lineHeight: '1',
            padding: '.2em .6em .3em',
            borderRadius: '.25em',
            color: 'black',
            verticalAlign: 'baseline',
            margin: '2px',
          }}
        />
      </div>
      {otherFiles.map((f, i) => {
        // const sequenceEnd = Math.ceil(f.size / STROPHE_CHUNK_SIZE);
        // const currentSequence = window.filesP2P?.[f.id]?.['seq'];
        // const fileProgressValue = (currentSequence / sequenceEnd) * 100;
        return (
          <div className="message-text-wrapper" key={i}>
            <div className="message-text message-text-file">
              <div className="file-name">{f.name}</div>
              <div className="file-progress-icon">
                {f?.loading && <CircularProgress />}
                {!f?.loading && f?.error?.message && (
                  <Tooltip title={f.error.message}>
                    <ErrorIcon />
                  </Tooltip>
                )}
              </div>
              {/* <div className="download-wrapper">
                <div className="download-link-wrapper">
                  {!f.loading && !isMyMessage && (
                    <Link download={f.name} href={f.src}>
                      Download
                    </Link>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

function MessageContent({
  isMyMessage,
  archived,
  firstName,
  lastName,
  stamp,
  messageText,
  messageType,
  messageFiles,
}) {
  return (
    <div className="message-content">
      <div className="message-time">
        {(!isMyMessage &&
          firstName &&
          `${displayableFullName({
            first_name: firstName,
            last_name: lastName,
          })}, `) ||
          ''}
        {stamp &&
          (isTodayMessage(stamp)
            ? format(defaultDateTimeZone(stamp, '', false), 'hh:mm a')
            : defaultDateTimeZone(stamp))}
      </div>
      {archived ? (
        <div className="message-text-wrapper">
          <div className="message-text">
            <div>{parse(unescape(messageText))}</div>
            {messageFiles?.length > 0 && (
              <div>
                <ul className="message-files-info">
                  {messageFiles.map((f, i) => (
                    <li key={i}>{f.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {messageType === 'file' && (
            <MessageFiles
              isMyMessage={isMyMessage}
              messageFiles={messageFiles}
            />
          )}
          {messageText && <MessageText message={messageText} />}
        </>
      )}
    </div>
  );
}

function Message({
  id,
  member_id,
  isMyMessage,
  archived,
  firstName,
  lastName,
  stamp,
  messageText,
  messageType,
  messageFiles,
}) {
  const messageClass = classNames('chat-message', {
    'my-message': isMyMessage,
    'other-message': !isMyMessage,
  });
  return (
    <ListItem key={id} className={messageClass}>
      <ListItemAvatar className="chat-message-avatar-div">
        <MaterialAvatar
          classes="chat-message-avatar"
          member_id={member_id}
          firstName={firstName}
          lastName={lastName}
        />
      </ListItemAvatar>
      <MessageContent
        key={id}
        isMyMessage={isMyMessage}
        archived={archived}
        firstName={firstName}
        lastName={lastName}
        stamp={stamp}
        messageText={messageText}
        messageType={messageType}
        messageFiles={messageFiles}
      />
    </ListItem>
  );
}

export default Message;
