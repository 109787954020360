/* eslint-disable no-fallthrough */
import React, { useState, useReducer } from 'react';
import { connect } from 'react-redux';
import drawerProfileStyles from '../../jss/views/drawerProfileStyles';
import { locationReducer, PROFILE_EDIT_ACTIONS } from '../../utils/drawer';
import { ADDRESS_TYPES, MY_LOCATION_SECTIONS } from '../../utils/calendar';
import { postLocations } from '../../redux/actions/member';
import Section from './LocationManager/Section';
import { includes, set, keys } from 'lodash';

const LocationManager = ({ locationData, memberCountry, dispatch }) => {
  const [errors, setErrors] = useState(null);

  const [locations, setLocations] = useReducer(
    locationReducer,
    // While we move away from prev structure, country_code_id may be null
    locationData.map((lio) => ({
      ...lio,
      name: '',
      country_code_id: !!lio.country_code_id
        ? lio.country_code_id
        : memberCountry.id,
    }))
  );

  const handleAddClick = (type) => {
    console.debug('will create location of type', type);
    setLocations({
      type: PROFILE_EDIT_ACTIONS.locationAdd,
      payload: {
        country_code_id: memberCountry.id,
        country: memberCountry.name,
        location_type: type,
      },
    });
  };

  const validateForm = (id) => {
    let allErrors = {};
    if (locations?.length > 0) {
      const errors = {};
      locations
        .filter((l) => l.editable_by_member && l.id === id)
        .forEach((l) => {
          if (
            !l.street_address_1 ||
            !l.postal_code ||
            !l.admin_area_1 ||
            !l.admin_area_2 ||
            !l.locality
          )
            set(errors, l.id, {
              postal_code: !l.postal_code ? 'is required' : '',
              street_address_1: !l.street_address_1 ? 'is required' : '',
              locality: !l.locality ? 'is required' : '',
              admin_area_1: !l.admin_area_1 ? 'is required' : '',
              admin_area_2: !l.admin_area_2 ? 'is required' : '',
            });
        });
      if (keys(errors).length > 0) set(allErrors, 'locationErrors', errors);
    }
    return keys(allErrors).length > 0 ? allErrors : null;
  };

  const handleSaveClick = async (id) => {
    const errors = validateForm(id);
    // console.debug('errors', errors);
    if (!!errors) {
      console.debug(errors);
      setErrors(errors);
    } else {
      setErrors({});
      const changeID = (newId) => {
        setLocations({
          type: PROFILE_EDIT_ACTIONS.locationIdChange,
          payload: {
            oldId: id,
            newId: newId,
          },
        });
      };

      const result = await dispatch(
        postLocations(
          JSON.stringify({ locations: locations.filter((l) => l.id === id) })
        )
      );
      changeID(result.id);

      console.debug('we cool');
    }
  };

  const handleDiscardUnsubmitted = (id) => {
    console.debug('Running discard unsubmitted');
    setLocations({
      type: PROFILE_EDIT_ACTIONS.locationClearInput,
      payload: {
        id,
      },
    });
  };

  const filterLocations = (types) =>
    locations?.filter((l) => includes(types, l.location_type))?.length > 0
      ? locations?.filter((l) => includes(types, l.location_type))
      : null;

  const classes = drawerProfileStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <h3 className={classes.title}>My Locations</h3>
        </div>
      </div>
      <div className={classes.body}>
        <Section
          label={MY_LOCATION_SECTIONS.home}
          locations={filterLocations([ADDRESS_TYPES.home])}
          onAddClick={handleAddClick}
          onSaveClick={handleSaveClick}
          onSetLocation={setLocations}
          onDiscardInput={handleDiscardUnsubmitted}
          errors={!!errors?.locationErrors && errors?.locationErrors}
        />
        <Section
          label={MY_LOCATION_SECTIONS.work}
          locations={filterLocations([ADDRESS_TYPES.work])}
          onAddClick={handleAddClick}
          onSaveClick={handleSaveClick}
          onSetLocation={setLocations}
          onDiscardInput={handleDiscardUnsubmitted}
          errors={!!errors?.locationErrors && errors?.locationErrors}
        />
        <Section
          label={MY_LOCATION_SECTIONS.events}
          locations={filterLocations([
            ADDRESS_TYPES.barsRestraunts,
            ADDRESS_TYPES.coffeeShops,
            ADDRESS_TYPES.meetingPlace,
          ])}
          onAddClick={handleAddClick}
          onSaveClick={handleSaveClick}
          onSetLocation={setLocations}
          onDiscardInput={handleDiscardUnsubmitted}
          errors={!!errors?.locationErrors && errors?.locationErrors}
          isHasFilter
        />
        <Section
          label={MY_LOCATION_SECTIONS.other}
          locations={filterLocations([
            ADDRESS_TYPES.other,
            ADDRESS_TYPES.bank,
            ADDRESS_TYPES.city_hall,
            ADDRESS_TYPES.embassy,
            ADDRESS_TYPES.park,
          ])}
          onAddClick={handleAddClick}
          onSaveClick={handleSaveClick}
          onSetLocation={setLocations}
          onDiscardInput={handleDiscardUnsubmitted}
          errors={!!errors?.locationErrors && errors?.locationErrors}
          isHasFilter
        />
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  locationData: state.member.memberInfo.location_information?.filter(
    (l) => l.location_type !== ADDRESS_TYPES.billing
  ),
  memberCountry: state.member.memberInfo.country_code[0],
});

export default connect(mapStatetoProps)(LocationManager);
