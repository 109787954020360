import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { PopoverWithIcon } from '../../../../components/Modal/PopoverWithIcon';
import { mailButtonGroup } from '../../../../utils/email';
import { MailTopButtonGroup } from './EmailButtonGroups/MailTopButtonGroup';
import { useEmailPageStyles } from './EmailPageStyles';

export const EmailControls = ({
  section,
  selectedEmail,
  sortControls,
  filterControls,
  topButtonsHandlers,
  handlePrint,
  showFolders,
  createFolder,
  selectedFolder,
}) => {
  const classes = useEmailPageStyles();

  return (
    (sortControls || filterControls || topButtonsHandlers) && (
      <Grid container xs={12} item className={classes.filterContainer}>
        {!sortControls ? null : (
          <Grid item xs={showFolders ? 3 : 4}>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.ddControl}
            >
              <Select
                displayEmpty
                aria-label="sort-selection"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sortControls.value}
                onChange={(e) => {
                  sortControls.changeValue(e);
                }}
              >
                <MenuItem value={0} disabled>
                  {section}
                </MenuItem>
                <MenuItem value={1}>Date</MenuItem>
                {section === 'Sent' ? null : (
                  <MenuItem value={2}>Read</MenuItem>
                )}
                {section === 'Sent' ? null : (
                  <MenuItem value={3}>Unread</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid
          item
          container
          xs={showFolders ? 6 : 8}
          className={classes.filter}
        >
          {!filterControls ? null : (
            <Grid item xs={4}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.ddControl}
              >
                <Select
                  displayEmpty
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={filterControls.value}
                  onChange={(e) => {
                    filterControls.changeValue(e);
                  }}
                >
                  <MenuItem value={0} disabled>
                    Filter
                  </MenuItem>
                  <MenuItem value={1}>Option 1</MenuItem>
                  <MenuItem value={2}>Option 2</MenuItem>
                  <MenuItem value={3}>Option 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {selectedEmail && topButtonsHandlers && (
            <Grid item xs={filterControls ? 8 : 12} container direction="row">
              <MailTopButtonGroup
                selectedEmail={selectedEmail}
                handlers={topButtonsHandlers}
                executeFrom={mailButtonGroup.mailStarred.name}
                handlePrint={handlePrint}
                selectedFolder={selectedFolder}
                section={section}
              />
            </Grid>
          )}
        </Grid>
        {showFolders ? (
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}
            className={classes.filter}
          >
            <PopoverWithIcon
              icon="addControl"
              handleSubmit={createFolder}
              tooltip="Add new folder"
            />
          </Grid>
        ) : null}
      </Grid>
    )
  );
};
