import React from 'react';
import IOSSwitch from '../../../components/IOSSwitch';

const TimeFormat = ({ value, onChange }) => {
  return (
    <div className="page_section-row align-center">
      <div className="section-row_label--small">12Hr / 24Hr</div>
      <IOSSwitch
        name="time_format"
        checked={value !== 'AM/PM'}
        onChange={onChange}
      />
    </div>
  );
};

export default TimeFormat;
