export const validateAddMemberForm = (values) => {
  let errors = {};

  if (!values.groupMemberEmail) {
    errors.groupMemberEmail = 'Email address is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.groupMemberEmail)) {
    errors.groupMemberEmail = 'Email address is invalid';
  }

  return errors;
};

export const validateNewGroupForm = (values, optionValue) => {
  let errors = {};

  if (!values.group_name) {
    errors.group_name = 'Group name is required';
  }

  if (optionValue !== 'NO_ENCRYPTION') {
    if (!values.pin) {
      errors.pin = 'PIN is required';
    } else if (
      isNaN(values.pin) ||
      values.pin.length < 6 ||
      values.pin.length > 12
    ) {
      errors.pin = 'PIN is invalid';
    }

    // if (!values.verify_pin_code) {
    //   errors.verify_pin_code = 'PIN verification is required';
    // } else if (values.verify_pin_code !== values.pin) {
    //   errors.verify_pin_code = 'PIN is not matched';
    // }
  }

  return errors;
};

export const validateInviteMemberForm = (values, cellRegExp) => {
  let errors = {};

  const regExp = new RegExp(cellRegExp.slice(1, -1));

  if (!values.groupMemberEmail) {
    errors.groupMemberEmail = 'Email address is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.groupMemberEmail)) {
    errors.groupMemberEmail = 'Email address is invalid';
  }

  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }

  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }

  if (!values.country) {
    errors.country = 'Country is required';
  }

  // if (values.isApplicable && !values?.company) {
  //   errors.companyName = 'Company is required or not applicable';
  // }

  if (values.role === '') {
    errors.role = 'Role is required';
  }

  if (values.confirmPhoneRequired) {
    if (!values.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!regExp.test(values.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
    }
  }

  return errors;
};

export const validateOutsideMemberForm = (values, cellRegExp) => {
  let errors = {};

  const regExp = new RegExp(cellRegExp.slice(1, -1));

  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }

  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }

  if (!values.country) {
    errors.country = 'Country is required';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone number is required';
  } else if (!regExp.test(values.phoneNumber)) {
    errors.phoneNumber = 'Phone number is invalid';
  }

  return errors;
};

// function validPhoneNumber(phoneNember) {
//   var phoneRe = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
//   if (phoneRe.test(phoneNember)) {
// return true
// }
// return false;}
