import React from 'react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    textDecoration: 'none',
  },
}));

const CreateButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      startIcon={<RecentActorsIcon />}
      variant="contained"
      color="primary"
      classes={classes}
      onClick={onClick}
    >
      Create new project
    </Button>
  );
};

export default CreateButton;
