import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const ImageDialog = ({ isOpen, onClose, imageUrl }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} onBackdropClick={onClose}>
      <DialogContent style={{ padding: '0px', lineHeight: '0' }}>
        <img
          src={imageUrl}
          alt=""
          style={{ maxHeight: '90vh', maxWidth: '100%' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
