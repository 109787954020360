import React, { useState } from 'react';
import styles from '../ProjectManagement.module.scss';
import { displayableFullName } from '../../../utils/contact';
import AmeraModal from '../../../components/Modal/modal';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RateInput from '../../../components/RateInput';

import { ProjectsApi } from '../../../redux/actions/project';

// const ModalDetails = ({
//   project_id,
//   price,
//   name,
//   currency_id,
//   contract_id,
//   handleClose,
//   handleSubmit,
// }) => {
//   const [prePrice, setPrice] = useState(price ? price : 0);
//   const [currencyId, setCurrencyId] = useState(currency_id ? currency_id : 0);

//   const form = {
//     project_id: Number(project_id),
//     pay_rate: Number(prePrice),
//     currency_code_id: Number(currencyId),
//   };

//   const submitHandler = () => {
//     handleSubmit(form, contract_id);
//     setPrice(price);
//     setCurrencyId(currency_id);
//   };

//   return (
//     <>
//       <h5 className={styles.hourlyRateHeading}>
//         Propose new hourly rate for {name}
//       </h5>
//       <Grid container className={styles.gridContainer}>
//         <Grid item xs={5} className={styles.gridContainer1}>
//           <RateInput
//             rate={prePrice}
//             onRateChange={(e) => setPrice(e.target.value)}
//             currencyId={currencyId}
//             label="Total Amount"
//             onCurrencyChange={(e) => setCurrencyId(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             required={true}
//           />
//         </Grid>
//         <Grid item xs={2} className={styles.gridContainer2}>
//           <Button onClick={submitHandler} variant="contained" color="primary">
//             Update
//           </Button>
//         </Grid>
//         <Grid item xs={2} className={styles.gridContainer3}>
//           <Button onClick={handleClose} variant="outlined" color="primary">
//             Cancel
//           </Button>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

export const Details = ({ projectId, id, contact }) => {
  const dispatch = useDispatch();

  const [prePrice, setPrice] = useState(
    contact.default_hourly_rate ? contact.default_hourly_rate : 0
  );
  const [currencyId, setCurrencyId] = useState(
    contact.default_rate_currency_id ? contact.default_rate_currency_id : 0
  );

  const [showPrice, setShowPrice] = useState(contact.default_hourly_rate);
  const [showCurrency, setCurrency] = useState(
    contact.default_rate_currency_id
  );

  const [openHourlyRateModal, setOpenHourlyRateModal] = useState(false);
  const handleOpenHourlyRateModal = () => {
    setOpenHourlyRateModal(true);
  };
  const handleCloseHourlyRateModal = () => {
    setOpenHourlyRateModal(false);
  };

  const billing = useSelector((state) => {
    return state?.billing.currencyList;
  });

  /*const currency = billing?.filter(
    (i) => i.currency_code_id === contact.default_rate_currency_id
  )[0]?.currency_code;
*/
  const form = {
    project_id: Number(projectId),
    pay_rate: Number(prePrice),
    currency_code_id: Number(currencyId),
  };

  const handleSubmit = () => {
    dispatch(ProjectsApi.updateRate(form, contact.member_id));
    setOpenHourlyRateModal(false);
    setShowPrice(prePrice);
    setCurrency(currencyId);
  };

  const currencyAfter = billing?.filter(
    (i) => i.currency_code_id === showCurrency
  )[0]?.currency_code;

  const ModalDetails = ({ handleClose, handleSubmit }) => {
    return (
      <>
        <h5 className={styles.hourlyRateHeading}>
          Propose new hourly rate for {contact.first_name}
        </h5>
        <Grid container className={styles.gridContainer}>
          <Grid item xs={5} className={styles.gridContainer1}>
            <RateInput
              rate={prePrice}
              onRateChange={(e) => setPrice(e.target.value)}
              currencyId={currencyId}
              label="Total Amount"
              onCurrencyChange={(e) => setCurrencyId(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
            />
          </Grid>
          <Grid item xs={2} className={styles.gridContainer2}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Update
            </Button>
          </Grid>
          <Grid item xs={2} className={styles.gridContainer3}>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const ModalContent = () => (
    <ModalDetails
      project_id={projectId}
      name={contact.first_name}
      price={contact.default_hourly_rate}
      currency_id={contact.default_rate_currency_id}
      contract_id={contact.project_member_id}
      handleSubmit={handleSubmit}
      handleClose={handleCloseHourlyRateModal}
    />
  );

  return (
    <>
      <div className="primary-title-team">
        <div className="title" style={{ width: '100%' }}>
          {displayableFullName(contact)}
        </div>
        <div className="primary-header">{contact.company}</div>
        <div className="second-header">{contact.title}</div>
        <div style={{ marginTop: '5px' }}>
          <span className="primary-header">Rate: </span>
          <span className="second-header">
            {currencyAfter} {showPrice} /hour
            {/* {currency} {contact.default_hourly_rate} /hour */}
          </span>
          <span onClick={handleOpenHourlyRateModal}>
            <EditIcon className={styles.EditIcon} />
          </span>
        </div>

        <div id={id}>
          <AmeraModal
            open={openHourlyRateModal}
            onClose={handleCloseHourlyRateModal}
            ModalContent={ModalContent}
            title="Propose Hourly Rate"
            maxWidth="sm"
          />
        </div>
      </div>
    </>
  );
};
