import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Modal from '../modal';
import { ADDRESS_TYPES } from '../../../utils/calendar';
import LocationTypeSelect from '../../../pages/Profile/ProfileEdit/LocationsList/LocationListItem/LocationTypeSelector';

const EventLocationModal = (props) => {
  const { onClose, open, onSave, locationName = undefined } = props;

  const [locationType, setLocationType] = useState(ADDRESS_TYPES.home);
  const [name, setName] = useState(locationName);
  const changeLocationType = (e) => setLocationType(e.target.value);
  const changeName = (e) => setName(e.target.value);

  const ModalContent = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LocationTypeSelect
          location_type={locationType}
          handleChange={changeLocationType}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Description"
          value={name}
          fullWidth
          InputLabelProps={{ shrink: true }}
          onChange={changeName}
        />
      </Grid>
    </Grid>
  );

  const handleSave = () => {
    onSave(locationType, name);
    onClose();
  };

  const ModalAction = () => {
    return (
      <>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        title="Save to my locations"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        className="save-location-modal"
      ></Modal>
    </div>
  );
};

export default EventLocationModal;
