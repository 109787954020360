import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import EventDetailInvitation from './EventDetailInvitation';
import EventDetailLocation from './EventDetailLocation';

// import EventConflictImage from '../../assets/img/alert.png';
import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addScheduleEvent } from '../../redux/actions/schedule_event';

// import CircularProgress from '@material-ui/core/CircularProgress';
import usePrevious from '../../hooks/usePrevious';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  BtnWithCircular: {
    margin: theme.spacing(1),
    '&:hover .MuiCircularProgress-circle': {
      color: '#ff00ff',
    },
    '& .MuiCircularProgress-circle': {
      color: '#ff0000',
    },
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: theme.spacing(1),
    width: '140px',
  },
}));

const EventDetail = (props) => {
  const classes = useStyles();

  const { member_id, isCallingApi } = props;
  const prevCallingApi = usePrevious(isCallingApi);
  const [closedBtnClicked, setClosedBtnClicked] = React.useState(false);

  const dateFormat = props.dateFormatSetting
    .replace(/Y/gi, 'y')
    .replace(/D/gi, 'd');

  const timeFormat = props.timeFormatSetting === 'AM/PM' ? 'hh:mm a' : 'HH:mm';
  const displayDatePickerFormat = dateFormat + ' ' + timeFormat;
  const displayTimeFormat = props.dateFormatSetting + ' ' + timeFormat;

  const [eventDetail_Name, setEventDetail_Name] = React.useState(
    props.eventDetailItem.eventDetail_Name
  );
  const [eventDetail_Location, setEventDetail_Location] = React.useState({
    Address: '',
    Zip: '',
  });

  const [eventDetail_StartTime, setEventDetail_StartTime] = React.useState(
    props.eventDetailItem.eventDetail_StartTime
  );
  const [eventDetail_EndTime, setEventDetail_EndTime] = React.useState(
    props.eventDetailItem.eventDetail_EndTime
  );

  const [eventDetail_Type, setEventDetail_Type] = React.useState(
    props.eventDetailItem.eventDetail_Type
  );

  const [eventDetail_Recurrence, setEventDetail_Recurrence] = React.useState(
    props.eventDetailItem.eventDetail_Recurrence
  );

  const [eventDetail_ImageFile, setEventDetail_ImageFile] = React.useState(
    null
  );
  const [eventDetail_Image, setEventDetail_Image] = React.useState(null);

  const [eventDetail_ContactList, setEventDetail_ContactList] = React.useState(
    []
  );
  const [eventDetail_Conflicts, setEventDetail_Conflicts] = React.useState([]);

  const [inputStatus, setInputStatus] = React.useState({
    Validate_Name: true,
    Validate_StartTime: true,
    Validate_EndTime: true,
  });

  useEffect(() => {
    if (prevCallingApi === true && isCallingApi === false && closedBtnClicked) {
      props.setEventModalShow(false);
    }
  }, [prevCallingApi, isCallingApi, closedBtnClicked, props]);

  const validateInputs = () => {
    if (eventDetail_Name === '' || eventDetail_Name === undefined) {
      setInputStatus({ ...inputStatus, Validate_Name: false });
      return false;
    }

    if (eventDetail_StartTime === undefined) {
      setInputStatus({ ...inputStatus, Validate_StartTime: false });
      return false;
    }

    if (eventDetail_EndTime === undefined) {
      setInputStatus({ ...inputStatus, Validate_EndTime: false });
      return false;
    }

    if (eventDetail_StartTime.getTime() >= eventDetail_EndTime.getTime()) {
      setInputStatus({ ...inputStatus, Validate_EndTime: false });
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateInputs()) {
      return;
    }

    let eventDetailItem = {
      eventDetail_Name: '',
      eventDetail_Location: {},
      eventDetail_Contacts: [],
      eventDetail_StartTime: new Date(),
      eventDetail_EndTime: new Date(),
      eventDetail_Type: 'Video',
      eventDetail_Recurrence: 'None',
      eventDetail_Image: null,
    };

    eventDetailItem.eventDetail_Name = eventDetail_Name;
    eventDetailItem.eventDetail_Location = eventDetail_Location;
    eventDetailItem.eventDetail_Contacts = eventDetail_ContactList;

    eventDetailItem.eventDetail_StartTime = eventDetail_StartTime;
    eventDetailItem.eventDetail_EndTime = eventDetail_EndTime;

    eventDetailItem.eventDetail_Type = eventDetail_Type;
    eventDetailItem.eventDetail_Recurrence = eventDetail_Recurrence;

    eventDetailItem.eventDetail_Image = eventDetail_ImageFile;

    let conflictData = props.addEventDetailItem(eventDetailItem);
    setEventDetail_Conflicts(conflictData);

    if (conflictData.length !== 0) {
      return;
    }

    setClosedBtnClicked(true);

    const formData = new FormData();
    formData.set('event_name', eventDetail_Name);
    formData.set('event_host_member_id', member_id);
    formData.set('event_type', eventDetail_Type);
    formData.set('event_datetime_start', eventDetail_StartTime.toUTCString());
    formData.set('event_datetime_end', eventDetail_EndTime.toUTCString());
    formData.set('event_location_address', eventDetail_Location.Address);
    formData.set('event_location_postal', eventDetail_Location.Zip);
    formData.set('event_recurrence', eventDetail_Recurrence);
    if (eventDetail_ImageFile !== null) {
      formData.set('event_image', eventDetail_ImageFile);
    }
    formData.set(
      'event_invite_to_list',
      JSON.stringify(eventDetail_ContactList)
    );
    props.addScheduleEvent(formData);
  };

  const setUploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setEventDetail_ImageFile(event.target.files[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setEventDetail_Image(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div>
      <div className="modal-content">
        <Grid container>
          <Grid item xs={7} className="div_ModalItem">
            <TextField
              required
              className="width_100"
              value={eventDetail_Name}
              label="Name of Event"
              variant="outlined"
              onChange={(event) => {
                setEventDetail_Name(event.target.value);
                setInputStatus({ ...inputStatus, Validate_Name: true });
              }}
              error={!inputStatus.Validate_Name}
              helperText={inputStatus.Validate_Name ? '' : 'Cannot be empty.'}
            />
          </Grid>
          <Grid item xs={5} className="div_ModalItem">
            <div className="uploadPanel">
              <img
                alt=""
                id="target"
                style={{
                  display: { eventDetail_Image } === null ? 'none' : '',
                }}
                src={eventDetail_Image}
              />
              <Button
                variant="outlined"
                component="label"
                className="btn-danger"
              >
                Select Image
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    setUploadImage(event);
                    setInputStatus({ ...inputStatus, Validate_Image: true });
                  }}
                />
              </Button>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="div_ModalItem">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  autoOk
                  required
                  className="width_50"
                  label="Start Time"
                  value={eventDetail_StartTime}
                  format={displayDatePickerFormat}
                  onChange={(strTime) => {
                    setEventDetail_StartTime(strTime);
                    setInputStatus({
                      ...inputStatus,
                      Validate_StartTime: true,
                    });
                    setEventDetail_Conflicts([]);
                  }}
                  variant="inline"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: 'start' }}
                  error={!inputStatus.Validate_StartTime}
                  helperText={
                    inputStatus.Validate_StartTime ? '' : 'Cannot be empty.'
                  }
                />
                <KeyboardDateTimePicker
                  autoOk
                  required
                  className="width_50"
                  label="End Time"
                  value={eventDetail_EndTime}
                  format={displayDatePickerFormat}
                  onChange={(strTime) => {
                    setEventDetail_EndTime(strTime);
                    setInputStatus({ ...inputStatus, Validate_EndTime: true });
                    setEventDetail_Conflicts([]);
                  }}
                  variant="inline"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: 'start' }}
                  error={!inputStatus.Validate_EndTime}
                  helperText={
                    inputStatus.Validate_EndTime
                      ? ''
                      : 'Cannot be empty or prior to StartTime.'
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="div_ModalItem">
              <FormControl className="width_50">
                <InputLabel id="label-eventtype">Event Type</InputLabel>
                <Select
                  labelId="label-eventtype"
                  id="eventtype-select"
                  value={eventDetail_Type}
                  onChange={(event) => setEventDetail_Type(event.target.value)}
                  // style={{width:"300px"}}
                >
                  <MenuItem value={'Video'}>Video</MenuItem>
                  <MenuItem value={'Audio'}>Audio</MenuItem>
                  <MenuItem value={'Chat'}>Chat</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="width_50">
                <InputLabel id="label-eventrecurrence">Recurrence</InputLabel>
                <Select
                  labelId="label-eventrecurrence"
                  id="eventrecurrence-select"
                  value={eventDetail_Recurrence}
                  onChange={(event) =>
                    setEventDetail_Recurrence(event.target.value)
                  }
                  // style={{width:"300px"}}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'Weekly'}>Weekly</MenuItem>
                  <MenuItem value={'Bi-Weekly'}>Bi-Weekly</MenuItem>
                  <MenuItem value={'Monthly'}>Monthly</MenuItem>
                  <MenuItem value={'Yearly'}>Yearly</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <EventDetailInvitation
                className="width_100"
                eventDetail_ContactList={eventDetail_ContactList}
                setEventDetail_ContactList={setEventDetail_ContactList}
              />
            </div>
            <div className="div_ModalItem">
              <span>Conflicts Detected</span>
              <div className="ConflictsList">
                {eventDetail_Conflicts.map((item, index) => (
                  <div className="ConflictItem" key={index}>
                    {/* <img alt="conflictEventImg" src={EventConflictImage} /> */}
                    <img alt="conflictEventImg" src={item.EventImage} />
                    <span>
                      {' '}
                      {`${moment(item.From).format(displayTimeFormat)}`} ~{' '}
                      {`${moment(item.To).format(displayTimeFormat)}`}: You have
                      "{`${item.Title}`}".{' '}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={5} className="div_ModalItem">
            <EventDetailLocation
              className="width_100"
              eventDetail_Location={eventDetail_Location}
              setEventDetail_Location={setEventDetail_Location}
            />
          </Grid>
        </Grid>
      </div>
      <div className="modal-footer" style={{ justifyContent: 'flex-end' }}>
        <div className={classes.actionsWrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.actionBtn}
            onClick={() => props.setEventModalShow(false)}
          >
            Cancel
          </Button>
          {isCallingApi ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.actionBtn}
              disableRipple
              disabled
            >
              adding ...
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.actionBtn}
              disableRipple
              onClick={handleSubmit}
            >
              OK
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member_id: state.member.member.member_id,
  isCallingApi: state.scheduleEvents.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addScheduleEvent }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);
