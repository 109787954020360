import React from 'react';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';
import GroupSecurityRating from './GroupSecurityRating';

const GroupPreview = (props) => {
  const { groupData } = props;
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body1">
          {groupData.group_role === 'group_leader' ? (
            <StarIcon fontSize="inherit" color="primary" />
          ) : null}{' '}
          {`${groupData.group_name}  |  ${groupData.total_member} members`}
        </Typography>
      </Grid>
      <Grid item container direction="row">
        <Typography variant="body2">Exchange sercurity</Typography>
        <GroupSecurityRating
          groupExchangeOption={groupData.group_exchange_option}
        />
      </Grid>
    </Grid>
  );
};

export default GroupPreview;
