import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import PINInput from './PINInput';
import PictureInput from './PictureInput';
import ExchangeInput from './ExchangeInput';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const PicturePin = (props) => {
  const { isExchange, onChange, security, hidePinPicture, disabled } = props;

  const [values, setValues] = useState({
    pin: '',
    confirmPin: '',
    picture: null, // filelist item
    exchangeOption: isExchange ? 'MOST_SECURE' : null,
    isValidated: false,
  });

  useEffect(() => {
    if (security) {
      setValues({
        pin: security.pin ? security.pin : '',
        confirmPin: security.pin ? security.pin : '',
        isValidated: true,
        exchangeOption: security.exchange_option,
        security_picture: security.security_picture,
      });
    }
  }, [security]);

  const [pinError, setPinError] = useState(false);
  const theme = useTheme();
  const isMdPlus = useMediaQuery(theme.breakpoints.up('md'));

  const onPINInput = (e) => {
    if (hidePinPicture || disabled) return;
    const pinType = e.target.name;
    const pinValue = e.target.value;

    if (pinValue.length <= 12) {
      const anotherPinType = pinType === 'pin' ? 'confirmPin' : 'pin';

      let newState, pinError;
      if (
        values[anotherPinType] === pinValue &&
        values[anotherPinType].length >= 6 &&
        pinValue.length >= 6
      ) {
        newState = { ...values, [pinType]: pinValue, isValidated: true };
        pinError = false;
      } else {
        newState = { ...values, [pinType]: pinValue, isValidated: false };
        pinError = true;
      }
      setValues(newState);
      setPinError(pinError);
      onChange(newState);
    }
  };

  const onExchangeSelect = (e) => {
    if (disabled) return;
    const newState = { ...values, exchangeOption: e.target.value };
    setValues(newState);
    onChange(newState);
  };

  const onPicChange = (picFile) => {
    const newState = { ...values, picture: picFile };
    setValues(newState);
    onChange(newState);
  };

  return (
    <Grid className="picture-pin-container" container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={isMdPlus && isExchange ? 6 : 12}>
          <PictureInput
            changed={onPicChange}
            propSrc={values.security_picture}
            hidePinPicture={hidePinPicture}
            disabled={disabled}
          />
        </Grid>
        {isMdPlus && isExchange && (
          <Grid item xs={6}>
            <ExchangeInput
              selected={values.exchangeOption}
              onChange={onExchangeSelect}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <PINInput
          PIN={values.pin}
          isConfirm={false}
          onPINChange={onPINInput}
          errors={pinError}
          hidePinPicture={hidePinPicture}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        {!disabled && (
          <PINInput
            PIN={values.confirmPin}
            isConfirm
            onPINChange={onPINInput}
            errors={pinError}
            hidePinPicture={hidePinPicture}
            disabled={disabled}
          />
        )}
      </Grid>
      {!isMdPlus && isExchange ? (
        <Grid item xs={12}>
          <ExchangeInput
            selected={values.exchangeOption}
            onChange={onExchangeSelect}
            disabled={disabled}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default PicturePin;
