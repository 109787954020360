import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Banner } from 'material-ui-banner';
import { useTheme, withStyles } from '@material-ui/core/styles';

import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';

import {
  removeNotification,
  removeStaticNotification,
} from '../../redux/actions/snackbar';

const SlimBanner = withStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
    paddingBottom: 0,
    '.static-banner &': {
      paddingBottom: 0,
    },
  },
})(Banner);

const AmeraBanner = ({
  id = undefined,
  type,
  message,
  appBar = false,
  buttonLabel,
  buttonOnClick,
  showDismissButton = true,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const color = useMemo(() => {
    if (appBar) return '#fff';

    switch (type) {
      case 'general-info':
        return theme.palette.info.main;
      case 'general-warning':
        return theme.palette.warning.main;
      case 'general-success':
        return theme.palette.success.main;
      case 'general-error':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  }, [type, theme, appBar]);

  const Icon = useMemo(() => {
    switch (type) {
      case 'general-info':
        return <InfoIcon style={{ color }} />;
      case 'general-warning':
        return <WarningIcon style={{ color }} />;
      case 'general-success':
        return <CheckCircleIcon style={{ color }} />;
      case 'general-error':
        return <ErrorIcon style={{ color }} />;
      default:
        return undefined;
    }
  }, [type, color]);

  const onClose = () => {
    if (appBar) dispatch(removeStaticNotification());
    else dispatch(removeNotification(id));
  };

  return (
    <SlimBanner
      icon={Icon}
      open={true}
      label={message}
      onClose={onClose}
      paperProps={{ style: { borderLeftColor: color } }}
      iconProps={{ style: { background: 'transparent' } }}
      appBar={appBar}
      buttonLabel={buttonLabel}
      buttonOnClick={buttonOnClick}
      showDismissButton={showDismissButton}
    />
  );
};

export default AmeraBanner;
