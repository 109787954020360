import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    borderRadius: '10px',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    outline: 'none',
    width: '550px',
    backgroundColor: 'white',
    margin: 'auto',
    marginTop: '8%',
    padding: '20px',
  },
  modalTop: {
    display: 'flex',
  },
  modalTopText: {
    width: '200px',
  },
  modalTopButton: {
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    width: '25px',
    height: '25px',
    marginLeft: '280px',
    cursor: 'pointer',
  },
  field: {
    width: '100%',
    marginTop: '10px',
  },
  inputDescription: {
    padding: '5px 10px',
    borderRadius: '4px',
    border: '1px solid #C4C4C4',
    fontFamily: 'sans-serif',
    fontSize: '16px',
  },
  button: {
    padding: '10px 15px',
    height: '38px',
    marginTop: '20px',
    marginLeft: '20px',
  },
  button1: {
    padding: '10px 15px',
    height: '38px',
    marginTop: '20px',
    marginLeft: '275px',
  },
  button2: {
    marginTop: '20px',
    marginLeft: '235px',
  },
  buttonText: {
    width: '110px',
  },
}));
