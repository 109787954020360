export const FETCH_TOPIC_START = 'FETCH_TOPIC_START';
export const FETCH_TOPIC_SUCCESS = 'FETCH_TOPIC_SUCCESS';
export const FETCH_TOPIC_FAILED = 'FETCH_TOPIC_FAILED';

export const SEND_TOPIC_START = 'SEND_TOPIC_START';
export const SEND_TOPIC_SUCCESS = 'SEND_TOPIC_SUCCESS';
export const SEND_TOPIC_FAILED = 'SEND_TOPIC_FAILED';

export const FETCH_TOPIC_LIST_SUCCESS = 'FETCH_TOPIC_LIST_SUCCESS';

export const SEND_POST_SUCCESS = 'SEND_POST_SUCCESS';
export const SEND_LIKE_SUCCESS = 'SEND_LIKE_SUCCESS';
