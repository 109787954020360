import React from 'react';

const PortraitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.944"
    height="54.896"
    viewBox="0 0 29.944 54.896"
  >
    <path
      data-name="Union 2"
      d="M-3976.761,54.9a6.245,6.245,0,0,1-6.24-6.238V6.239A6.245,6.245,0,0,1-3976.761,0h17.467a6.244,6.244,0,0,1,6.238,6.239v42.42a6.244,6.244,0,0,1-6.238,6.238Zm-3.744-6.238a3.748,3.748,0,0,0,3.744,3.743h17.467a3.747,3.747,0,0,0,3.742-3.743V44.915H-3980.5Zm9.391-27.3a1.245,1.245,0,0,0-.658,1.1v9.981a1.245,1.245,0,0,0,.658,1.1,1.242,1.242,0,0,0,.59.149,1.242,1.242,0,0,0,.692-.21l7.486-4.991a1.245,1.245,0,0,0,.556-1.038,1.252,1.252,0,0,0-.556-1.039l-7.486-4.99a1.248,1.248,0,0,0-.693-.209A1.242,1.242,0,0,0-3971.113,21.358Zm-9.391-15.12V9.981h24.953V6.239a3.748,3.748,0,0,0-3.742-3.744h-17.467A3.748,3.748,0,0,0-3980.5,6.239Zm11.229,43.668a1.246,1.246,0,0,1-1.247-1.248,1.246,1.246,0,0,1,1.247-1.248h2.5a1.245,1.245,0,0,1,1.247,1.248,1.246,1.246,0,0,1-1.247,1.248Zm0-19.8V24.789l3.989,2.659-3.986,2.658h0Zm-2.495-22.62a1.247,1.247,0,0,1-1.248-1.248,1.247,1.247,0,0,1,1.248-1.249h7.486a1.247,1.247,0,0,1,1.247,1.249,1.246,1.246,0,0,1-1.247,1.248Z"
      transform="translate(3983)"
    />
  </svg>
);

export default PortraitIcon;
