import {
  FETCH_TOPIC_SUCCESS,
  FETCH_TOPIC_LIST_SUCCESS,
  SEND_TOPIC_SUCCESS,
  SEND_POST_SUCCESS,
  SEND_LIKE_SUCCESS,
} from '../actionTypes/forum';

import {
  APP_RESET
} from '../actionTypes/app';

// The initial state of the chats
const initialState = {
  topics: [],
  topic: {
    attachments: [],
  },
  posts: [],
};

let found = false,
  newPosts;

const forum = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case FETCH_TOPIC_LIST_SUCCESS:
      return {
        ...state,
        topics: action.payload,
      };
    case FETCH_TOPIC_SUCCESS:
      newPosts = makePostsTree(action.payload.posts, null);

      return {
        ...state,
        topic: action.payload.topic || initialState.topic,
        posts: newPosts,
      };
    case SEND_TOPIC_SUCCESS:
      return {
        ...state,
        topics: [action.payload, ...state.topics],
      };
    case SEND_POST_SUCCESS:
      found = false;
      newPosts = makeNewPosts(state.posts, action.payload);
      return {
        ...state,
        posts: newPosts,
      };
    case SEND_LIKE_SUCCESS:
      found = false;
      newPosts = increaseLikes(
        state.posts,
        action.payload.post_id,
        action.payload.likes
      );
      return {
        ...state,
        posts: newPosts,
      };
    default:
      return state;
  }
};

const makeNewPosts = (posts, post) => {
  if (found) return posts;
  if (post.parent_post_id === null) {
    found = true;
    return [...posts, post];
  }

  const newPosts = [...posts];

  for (let i = 0; i < newPosts.length; i++) {
    if (newPosts[i].post_id === post.parent_post_id) {
      if (newPosts[i].posts === undefined) newPosts[i].posts = [];
      newPosts[i].posts = [...newPosts[i].posts, post];
      found = true;
    } else {
      newPosts[i].posts = makeNewPosts(newPosts[i].posts, post);
    }
  }
  return newPosts;
};

const makePostsTree = (posts, parent_post_id) => {
  let children = [];
  for (let i = 0; i < posts.length; i++) {
    if (posts[i].parent_post_id === parent_post_id) {
      const newPost = { ...posts[i] };
      const ps = makePostsTree(posts, posts[i].post_id);
      newPost.posts = ps;
      children.push(newPost);
    }
  }
  return children;
};

const increaseLikes = (posts, post_id, likes) => {
  if (found) return posts;

  const children = [];
  for (let i = 0; i < posts.length; i++) {
    const newPost = { ...posts[i] };
    if (posts[i].post_id === post_id) {
      newPost.likes = likes;
      found = true;
    } else {
      newPost.posts = increaseLikes(newPost.posts, post_id, likes);
    }
    children.push(newPost);
  }

  return children;
};

export default forum;
