import {
  CREATE_COMPANY_START,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_SUCCESS,
  // DELETE_COMPANY,
  // ADD_COMPANY_MEMBER,
  // REMOVE_COMPANY_MEMBER,
  LOAD_COMPANIES_START,
  LOAD_COMPANIES,
  LOAD_COMPANIES_FAILED,
  LOAD_COMPANY,
  CLEAR_COMPANY,
  LOAD_UNREGISTERED_COMPANIES,
  UPDATE_UNREGISTERED_COMPANY,
  DELETE_UNREGISTERED_COMPANY,
  CREATE_COMPANY_FROM_NAME,
  SET_COMPANY_ALERT,
  // Picture
  UPDATE_COMPANY_PIC_START,
  UPDATE_COMPANY_PIC_SUCCESS,
  UPDATE_COMPANY_PIC_ERROR,
  // Details
  UPDATE_COMPANY_DETAILS_START,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_ERROR,
  // Members
  UPDATE_COMPANY_MEMBERS_START,
  UPDATE_COMPANY_MEMBERS_SUCCESS,
  UPDATE_COMPANY_MEMBERS_ERROR,
} from '../actionTypes/company';

import {
  APP_RESET
} from '../actionTypes/app';

// The initial state of the App
export const initialState = {
  companyList: [],
  companyData: null,
  // companyData: {
  //   company_members: [],
  //   departments: [],
  //   company_industries: [],
  //   child_companies: [],
  // },
  unregisteredCompanies: [],
  registeredCount: 0,
  unregisteredCount: 0,
  isLoadingPicture: false,
  isLoadingDetails: false,
  isLoadingMembers: false,
  loading: false,
  companyAlert: {
    show: false,
    variant: 'success',
    message: '',
  },
};

let newUnregisteredCompanies;

const company = (state = initialState, action) => {
  if (!state.companyAlert) {
    state.companyAlert = initialState.companyAlert;
  }
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case LOAD_COMPANIES_START:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COMPANIES:
      return {
        ...state,
        loading: false,
        companyList: action.payload.data,
        registeredCount: action.payload.count,
      };
    case LOAD_COMPANIES_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CREATE_COMPANY_START:
    case UPDATE_COMPANY_START:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        companyList: [action.payload, ...state.companyList],
      };
    case LOAD_COMPANY:
      return {
        ...state,
        companyData: action.payload,
      };
    case CLEAR_COMPANY:
      return {
        ...state,
        companyData: {},
      };
    case UPDATE_COMPANY_SUCCESS:
      const newCompanyList = state.companyList.map((company) => {
        if (company.id === action.payload.id) {
          return action.payload;
        } else return company;
      });
      let newCompanyData = {};
      if (state.companyData && state.companyData.id === action.payload.id) {
        newCompanyData = { companyData: action.payload };
      }

      return {
        ...state,
        loading: false,
        companyList: newCompanyList,
        ...newCompanyData,
      };
    case LOAD_UNREGISTERED_COMPANIES:
      return {
        ...state,
        unregisteredCompanies: action.payload.data,
        unregisteredCount: action.payload.count,
      };
    case UPDATE_UNREGISTERED_COMPANY:
      newUnregisteredCompanies = state.unregisteredCompanies.map((company) => {
        if (company.company_name === action.payload.companyName) {
          return {
            company_name: action.payload.newCompanyName,
            total_members: company.total_members,
          };
        }
        return company;
      });

      return {
        ...state,
        unregisteredCompanies: newUnregisteredCompanies,
      };
    case DELETE_UNREGISTERED_COMPANY:
      newUnregisteredCompanies = state.unregisteredCompanies.filter(
        (company) => company.company_name !== action.payload
      );
      return {
        ...state,
        unregisteredCompanies: newUnregisteredCompanies,
      };
    case CREATE_COMPANY_FROM_NAME:
      newUnregisteredCompanies = state.unregisteredCompanies.filter(
        (company) => company.company_name !== action.payload.companyName
      );

      return {
        ...state,
        unregisteredCompanies: newUnregisteredCompanies,
        companyList: [action.payload.company, ...state.companyList],
      };
    case SET_COMPANY_ALERT:
      return {
        ...state,
        companyAlert: action.payload,
        loading: false,
      };
    case UPDATE_COMPANY_PIC_START:
      return {
        ...state,
        isLoadingPicture: true,
      };
    case UPDATE_COMPANY_PIC_SUCCESS:
      return {
        ...state,
        isLoadingPicture: false,
        companyData: action.payload.companyData,
        companyAlert: {
          show: true,
          variant: 'success',
          message: action.payload.message,
        },
      };
    case UPDATE_COMPANY_PIC_ERROR:
      return {
        ...state,
        isLoadingPicture: false,
        companyAlert: {
          show: true,
          variant: 'error',
          message: action.payload,
        },
      };
    case UPDATE_COMPANY_DETAILS_START:
      return {
        ...state,
        isLoadingDetails: true,
      };
    case UPDATE_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        companyData: action.payload.companyData,
        companyAlert: {
          show: true,
          variant: 'success',
          message: action.payload.message,
        },
      };
    case UPDATE_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        companyAlert: {
          show: true,
          variant: 'error',
          message: action.payload,
        },
      };
    case UPDATE_COMPANY_MEMBERS_START:
      return {
        ...state,
        isLoadingMembers: true,
      };
    case UPDATE_COMPANY_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoadingMembers: false,
        companyData: action.payload.companyData,
        companyAlert: {
          show: true,
          variant: 'success',
          message: action.payload.message,
        },
      };
    case UPDATE_COMPANY_MEMBERS_ERROR:
      return {
        ...state,
        isLoadingMembers: false,
        companyAlert: {
          show: true,
          variant: 'error',
          message: action.payload,
        },
      };
    default:
      return state;
  }
};

export default company;
