import React from 'react';

const ComposeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="Icon" transform="translate(0 0)">
      <path id="XMLID_95_" d="M6.906,27.724a.925.925,0,0,1,0-1.331l1.3-1.265a.984.984,0,0,1,1.366,0,.925.925,0,0,1,0,1.331l-1.3,1.265a.983.983,0,0,1-1.366,0Zm8.45-5.166-.065-.066L5.12,23.141a.969.969,0,0,1-.943-.551.922.922,0,0,1,.2-1.053l4.971-4.846-4.26-4.153c-1.226-1.2-1.944-2.137-1.726-3.129C3.6,8.318,4.73,7.9,6.444,7.26q.342-.128.723-.272L23.306.848c2.306-.877,3.535-1.164,4.287-.425.774.76.325,1.855-.354,3.512-.09.223-.187.457-.286.7l-6.3,15.731c-.9,2.235-1.549,3.41-2.635,3.626a1.736,1.736,0,0,1-.338.035C16.831,24.03,16.17,23.37,15.356,22.558ZM.283,21.268a.925.925,0,0,1,0-1.331l4.323-4.214a.983.983,0,0,1,1.365,0,.925.925,0,0,1,0,1.331L1.649,21.268a.983.983,0,0,1-1.366,0Z" transform="translate(0 0)" fill="#fff"/>
    </g>
  </svg>

);

export default ComposeIcon;
