import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialAvatar from '../../components/MaterialAvatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import GroupDetailModal from './GroupDetailModal';
import MemberShipDetailModal from './MemberShipDetailModal';
import SaveData from '../../components/Modal/SaveData';
import AmeraPagination from '../../components/AmeraPagination';
import { openDrawer } from '../../redux/actions/drawer';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';

import {
  getGroupDetail,
  setShowGroupDetailModal,
  deleteGroups,
  removeGroupMember,
  setGroupData,
} from '../../redux/actions/group';
import { defaultDateTimeZone } from '../../utils/calendar';

const headCells = [
  {
    id: 'group_id',
    label: '',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'group_name',
    label: 'Group Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'total_member',
    label: 'Total Member',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'total_files',
    label: 'Total Files',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'create_date',
    label: 'Created Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ListContainer = (props) => {
  const {
    type,
    memberId,
    dispatch,
    // ameraGroupSecurity,
    showGroupDetailModal,
    openDetailsFor,

    groups,
    totalCount,
    orderBy,
    order,
    pageSize,
    pageNumber,
    setOrderBy,
    setOrder,
    setPageSize,
    setPageNumber,
    loadSortedGroups,
  } = props;
  let history = useHistory();
  const [showSaveDataModal, setShowSaveDataModal] = useState(false);

  const classes = useStyles();

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [membershipDetail, setMembershipDetail] = useState({});

  const viewGroup = (group) => {
    if (type === 'group') dispatch(getGroupDetail(group.group_id));
    if (type === 'membership') dispatch(setGroupData(group));
    dispatch(openDrawer(DRAWER_CONTENT_TYPE.groupProfile, {}));
  };

  const showGroupDetail = useCallback(
    (group) => {
      if (type === 'group') dispatch(getGroupDetail(group.group_id));
      if (type === 'membership') setMembershipDetail(group);
      dispatch(setShowGroupDetailModal(true));

      // const isGroupSecurity = filterAmeraGroupSecurity(group.group_id);
      // if (!isGroupSecurity) {
      //   setShowSaveDataModal(true);
      // } else {
      //   dispatch(setShowGroupDetailModal(true));
      // }
    },
    [dispatch, type]
  );

  useEffect(() => {
    if (!!openDetailsFor) {
      showGroupDetail(groups.find((go) => go.group_id === openDetailsFor));
    }
  }, [openDetailsFor, groups, showGroupDetail]);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedGroups(property, newOrder);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = groups.map((n) => n.group_id);
      setSelectedGroups(newSelecteds);
      return;
    }
    setSelectedGroups([]);
  };

  const handleSelect = (event, name) => {
    const selectedIndex = selectedGroups.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGroups, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGroups.slice(1));
    } else if (selectedIndex === selectedGroups.length - 1) {
      newSelected = newSelected.concat(selectedGroups.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGroups.slice(0, selectedIndex),
        selectedGroups.slice(selectedIndex + 1)
      );
    }

    setSelectedGroups(newSelected);
  };

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedGroups(null, null, null, null, newPage - 1, null);
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const newPageSize = e.target.value;
    setPageSize(parseInt(newPageSize, 10));
    setPageNumber(1);
    loadSortedGroups(null, null, null, null, 0, newPageSize);
  };

  const isSelected = (name) => selectedGroups.indexOf(name) !== -1;

  // const filterAmeraGroupSecurity = useCallback(
  //   (groupId) => {
  //     for (var i = 0; i < ameraGroupSecurity.length; i++) {
  //       if (ameraGroupSecurity[i].groupId === groupId) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   },
  //   [ameraGroupSecurity]
  // );

  const deleteGroupsFunc = async (group_id) => {
    let formData = new FormData();
    if (group_id) {
      let groupIds = [];
      groupIds.push(group_id);
      formData.set('groupIds', groupIds);
      props.deleteGroups(formData);
    } else {
      formData.set('groupIds', selectedGroups);
      let success = await props.deleteGroups(formData);
      if (success) {
        setSelectedGroups([]);
      }
    }
  };

  const deleteMembershipsFunc = (group_id) => {
    let formData = new FormData();
    formData.set('groupMemberId', memberId);
    if (group_id) {
      formData.set('groupIds', group_id);
    } else {
      formData.set('groupIds', selectedGroups);
    }
    dispatch(removeGroupMember(formData, 'membership'));
  };

  return (
    <div className="list-view-section">
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          title="Groups"
          type={type}
          numSelected={selectedGroups.length}
          deleteGroupsFunc={deleteGroupsFunc}
          deleteFunc={
            type === 'membership' ? deleteMembershipsFunc : deleteGroupsFunc
          }
        />
        <TableContainer>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedGroups.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAll}
              onRequestSort={handleRequestSort}
              rowCount={groups.length}
            />
            <TableBody>
              {/* {stableSort(groups, getComparator(order, orderBy)) */}
              {/*TODO: groups and memberships server side pagination required*/}
              {groups.map((group, index) => {
                const isItemSelected = isSelected(group.group_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleSelect(event, group.group_id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={group.group_id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        color="primary"
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div>
                        <MaterialAvatar firstName={group.group_name} />
                      </div>
                    </TableCell>
                    <TableCell align="left">{group.group_name}</TableCell>
                    <TableCell align="left">{group.total_member}</TableCell>
                    <TableCell align="left">{group.total_files || 0}</TableCell>
                    <TableCell align="left">
                      {(group.group_create_date &&
                        defaultDateTimeZone(group.group_create_date, 'date')) ||
                        ''}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          viewGroup(group);
                        }}
                      >
                        view group
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <AmeraPagination
          itemTypeLabel="Items"
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          page={pageNumber}
          handleChangePage={handleChangePage}
        />
      </Paper>
      <SaveData
        show={showSaveDataModal}
        close={() => setShowSaveDataModal(false)}
      ></SaveData>
      {type === 'group' && (
        <GroupDetailModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/my-groups`);
          }}
        ></GroupDetailModal>
      )}
      {type === 'membership' && (
        <MemberShipDetailModal
          show={showGroupDetailModal}
          close={() => {
            dispatch(setShowGroupDetailModal(false));
            history.push(`${process.env.PUBLIC_URL}/groups/membership`);
          }}
          membershipDetail={membershipDetail}
        ></MemberShipDetailModal>
      )}
    </div>
  );
};

ListContainer.propTypes = {
  dispatch: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.object),
  // ameraGroupSecurity: PropTypes.arrayOf(PropTypes.object),
  groupData: PropTypes.object,
  showGroupDetailModal: PropTypes.bool,
  type: PropTypes.string,
};

ListContainer.defaultProps = {
  dispatch: null,
  groupList: [],
  // ameraGroupSecurity: [],
  groupData: {},
  showGroupDetailModal: false,
  type: '',
};

const mapStateToProps = (state) => ({
  // ameraGroupSecurity: state.group.ameraGroupSecurity,
  memberId: state.member.member.member_id,
  groupData: state.group.groupData,
  showGroupDetailModal: state.group.showGroupDetailModal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        deleteGroups,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
