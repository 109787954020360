import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import Modal from '../modal';
import LocationLine from './LocationLine';

const EventLocationModal = (props) => {
  const {
    onClose,
    open,
    meetingType,
    locationMode,
    selectedPlace,
    onModeChange,
    onSavedSelect,
    error,
    memberLocation,
    onSelectedPlace,
    drawerWidth,
  } = props;

  const [place, setPlace] = useState(selectedPlace);
  const isBrowser = typeof window !== 'undefined';
  const minWidth = 600;
  const marginSize = 20;
  const maxWidth =
    isBrowser && drawerWidth
      ? window.innerWidth > drawerWidth + minWidth
        ? window.innerWidth - drawerWidth - marginSize * 2
        : '100%'
      : '100%';

  const ModalContent = () => (
    <LocationLine
      meetingType={meetingType}
      locationMode={locationMode}
      selectedPlace={place}
      onModeChange={onModeChange}
      onSavedSelect={onSavedSelect}
      error={error}
      memberLocation={memberLocation}
      onSelectedPlace={setPlace}
    />
  );

  const handleSave = () => {
    onSelectedPlace(place);
    onClose();
  };

  const ModalAction = () => {
    return (
      <>
        <Button variant="contained" color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          // props.scheduleEventHideError();
        }}
        title="Location"
        ModalContent={ModalContent}
        ModalAction={ModalAction}
        fullScreen
        style={{ maxWidth: maxWidth, margin: marginSize }}
        className="create-location-modal"
      ></Modal>
    </div>
  );
};

export default EventLocationModal;
