import React from 'react';
// import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Popover } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  AttachmentIcon,
  LinkIcon,
  AtIcon,
  EmojiIcon,
  SendIcon,
} from '../assets/svg';
import { fileSize } from '../utils/file';
import SmileysPanel from './Conference/chat/SmileysPanel';
import Modal from './Modal/modal';
import ContentEditable from 'react-contenteditable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  editorWrapper: {
    flex: 1,
    borderRadius: theme.spacing(1),
    border: '1px solid #D8E0F0',
    background: 'white',
    padding: '13px',
    display: 'flex',
    width: '100%',
  },
  avatar: {
    width: '40px',
    height: '40px',
  },
  left: {
    display: '-webkit-box',
    '& span': {
      padding: theme.spacing(1),
      '& svg': {
        fill: '#8DA3C5 !important',
      },
    },
    cursor: 'pointer',
  },
  textarea: {
    border: 'none',
    outline: 'none',
    flex: 1,
    fontSize: '16px',
    resize: 'none',
  },
  right: {
    '& .MuiButtonBase-root': {
      width: '56px',
      height: '44px',
      borderRadius: '14px',
    },
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '44px',
    '& .MuiButtonBase-root': {
      minWidth: '55px',
      marginLeft: theme.spacing(2),
    },
  },
  attachmentsWrapper: {},
  attachment: {
    display: 'flex',
    background: 'transparent',
    padding: '13px',
    borderRadius: '14px',
  },
  attachmentIconWrapper: {
    width: '44px',
    height: '44px',
    borderRadius: '14px',
    backgroundColor: '#f1effb',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fill: '#6D5DD3 !important',
    },
  },
  attachmentIconInEditor: {
    '& svg': {
      fill: '#8DA3C5 !important',
    },
    cursor: 'pointer',
  },
  fileInfo: {
    marginLeft: '16px',
    maxWidth: 'calc(100% - 136px)',
    width: 'calc(100% - 136px)',
  },
  fileName: {
    fontSize: '14px',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileSize: {
    color: '#91929E',
    fontSize: '12px',
    fontWeight: 400,
  },
  fileInput: {
    display: 'none',
  },
  fileInputWrapper: {
    marginRight: '8px',
  },
  iconWrapper: {
    width: '44px',
    height: '44px',
    borderRadius: '14px',
    backgroundColor: '#F4F9FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '13px',
    cursor: 'pointer',
  },
  editLink: {},
  smiles: {
    width: '350px',
    background: 'rgba(22, 38, 55, 0.8)',
    borderRadius: '5px',
    padding: '5px',
  },
}));

const CommentEditor = (props) => {
  const {
    content,
    setContent,
    attachments,
    handleAttachments,
    deleteAttachment,
    allowAt,
    allowLink,
    allowEmoji,
    handleSubmit,
  } = props;

  // const dispatch = useDispatch();
  const classes = useStyles();

  const editorRef = React.useRef();
  const [showLinkEditor, setShowLinkEditor] = React.useState(false);
  const [link, setLink] = React.useState('');
  const [href, setHref] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onOk = () => {
    setShowLinkEditor(false);
    setContent(
      (prev) =>
        `${prev}<a href='${href}' class='comment-link' target='_blank'>${link}</a>`
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContent = (event) => {
    setContent(event.target.value);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleLink = (event) => {
    setLink(event.target.value);
  };

  const handleHref = (event) => {
    setHref(event.target.value);
  };

  const cancelLinkEdit = () => {
    setShowLinkEditor(false);
  };

  const open = Boolean(anchorEl);
  const time = new Date().getTime();

  return (
    <div className={classes.root}>
      <div className={classes.editorWrapper}>
        <div className={classes.left}>
          <div className={classes.fileInputWrapper}>
            <input
              className={classes.fileInput}
              id={`post_attachments${time}`}
              multiple
              onChange={handleAttachments}
              type="file"
            />
            <label
              htmlFor={`post_attachments${time}`}
              className={classes.attachmentIconInEditor}
            >
              <AttachmentIcon />
            </label>
          </div>
          {allowLink && (
            <span
              onClick={() => {
                setShowLinkEditor(true);
              }}
            >
              <LinkIcon />
            </span>
          )}
          {allowAt && (
            <span onClick={() => {}}>
              <AtIcon />
            </span>
          )}
        </div>
        <ContentEditable
          innerRef={editorRef}
          className={classes.textarea}
          html={content} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={handleContent} // handle innerHTML change
          onKeyDown={stopPropagation}
          onKeyUp={stopPropagation}
          tagName="article" // Use a custom HTML tag (uses a div by default)
        />
        <div className={classes.right}>
          <div className={classes.flexAlignCenter}>
            {allowEmoji && (
              <span onClick={handleClick}>
                <EmojiIcon />
              </span>
            )}
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              <SendIcon />
            </Button>
          </div>
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={classes.smiles}>
          <SmileysPanel
            onSmileySelect={(event) => {}}
            putEmoji={(emoji) => {
              setContent((prev) => `${prev}${emoji}`);
            }}
          />
        </div>
      </Popover>
      <Modal
        title="Edit Link"
        open={showLinkEditor}
        ModalContent={() => {
          return (
            <div className={classes.editLink}>
              <TextField
                label="Text to display"
                fullWidth
                className="mb-3"
                onChange={handleLink}
              />
              <TextField
                label="To what URL should this link go?"
                fullWidth
                onChange={handleHref}
              />
            </div>
          );
        }}
        ModalAction={() => {
          return (
            <>
              <Button onClick={cancelLinkEdit} variant="contained">
                Cancel
              </Button>

              <Button variant="contained" color="primary" onClick={onOk}>
                Ok
              </Button>
            </>
          );
        }}
      />
      <div className={classes.attachmentsWrapper}>
        {attachments &&
          attachments.length > 0 &&
          attachments.map((attachment, index) => (
            <div key={index} className={classes.attachment}>
              <div className={classes.attachmentIconWrapper}>
                <AttachmentIcon />
              </div>
              <div className={classes.fileInfo}>
                <h5 className={classes.fileName}>{attachment.name}</h5>
                <h5 className={classes.fileSize}>
                  {fileSize(attachment.size)}
                </h5>
              </div>
              <div
                className={classes.iconWrapper}
                onClick={() => {
                  deleteAttachment(index);
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

CommentEditor.defaultProps = {
  type: 'comment',
};

export default CommentEditor;
