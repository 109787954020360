import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECT_ROLES_START,
  FETCH_PROJECT_ROLES_SUCCESS,
  FETCH_PROJECT_ROLES_ERROR,
  SEND_CREATE_PROJECT_FORM_START,
  SEND_CREATE_PROJECT_FORM_SUCCESS,
  SEND_CREATE_PROJECT_FORM_ERROR,
  SEND_PROJECT_MEMBERS_START,
  SEND_PROJECT_MEMBERS_SUCCESS,
  SEND_PROJECT_MEMBERS_ERROR,
  SEND_PROJECT_MEMBER_ROLES_START,
  SEND_PROJECT_MEMBER_ROLES_SUCCESS,
  SEND_PROJECT_MEMBER_ROLES_ERROR,
  SEND_PROJECT_MEMBER_PRIVILEGES_START,
  SEND_PROJECT_MEMBER_PRIVILEGES_SUCCESS,
  SEND_PROJECT_MEMBER_PRIVILEGES_ERROR,
  PROJECT_CREATION_START,
  UPDATE_PROJECT_DEFINITION,
  SUBMIT_PROJECT_DEFINITION,
  SET_CURRENT_PROJECT_ID,
} from '../actionTypes/project';

import {
  APP_RESET
} from '../actionTypes/app';

export const projectCreationInitialState = {
  currentProjectId: null,
  currentStep: {
    preStep: null,
    nextStep: 'selectTeam',
    key: 'projectDefinition',
    displayName: 'Project Definition',
    data: null,
  },
  steps: [
    {
      key: 'projectDefinition',
      displayName: 'Project Definition',
      data: null,
    },
    {
      key: 'selectTeam',
      displayName: 'Select your Team',
      data: null,
    },
  ],
  stepIndex: 0,
};

export const initialState = {
  isLoading: false,
  projects: [],
  projectRoles: [],
  error: null,
  projectCreation: projectCreationInitialState,
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case APP_RESET:
      return initialState;
    case FETCH_PROJECTS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        projects: action.payload,
        error: null,
      };
    case FETCH_PROJECTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_PROJECT_ROLES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_PROJECT_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        projectRoles: action.payload,
        error: null,
      };
    case FETCH_PROJECT_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEND_CREATE_PROJECT_FORM_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SEND_CREATE_PROJECT_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case SEND_CREATE_PROJECT_FORM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEND_PROJECT_MEMBERS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SEND_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case SEND_PROJECT_MEMBERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEND_PROJECT_MEMBER_ROLES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SEND_PROJECT_MEMBER_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case SEND_PROJECT_MEMBER_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SEND_PROJECT_MEMBER_PRIVILEGES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SEND_PROJECT_MEMBER_PRIVILEGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case SEND_PROJECT_MEMBER_PRIVILEGES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PROJECT_CREATION_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        projectCreation: projectCreationInitialState,
      };
    case UPDATE_PROJECT_DEFINITION:
      return {
        ...state,
        isLoading: true,
        error: null,
        projectCreation: {
          ...state.projectCreation,
          currentStep: {
            ...state.projectCreation.currentStep,
            data: action.payload,
          },
        },
      };
    case SUBMIT_PROJECT_DEFINITION:
      return {
        ...state,
        isLoading: false,
        error: null,
        projectCreation: {
          ...action.payload,
          currentStep: {
            ...action.payload.currentStep,
            data: null,
          },
          steps: [
            {
              key: 'projectDefinition',
              displayName: 'Project Definition',
              data: action.payload.currentStep.data,
            },
            ...state.projectCreation.steps.slice(1),
          ],
          currentProjectId: state.projectCreation.currentProjectId,
        },
      };
    case SET_CURRENT_PROJECT_ID:
      return {
        ...state,
        projectCreation: {
          ...state.projectCreation,
          currentProjectId: action.payload.projectId,
        },
      };
    default:
      return state;
  }
};

export default project;
