export const smileys = {
  smiley1: ':)',
  smiley2: ':(',
  smiley3: ':D',
  smiley4: ':+1:',
  smiley5: ':P',
  smiley6: ':wave:',
  smiley7: ':blush:',
  smiley8: ':slightly_smiling_face:',
  smiley9: ':scream:',
  smiley10: ':*',
  smiley11: ':-1:',
  smiley12: ':mag:',
  smiley13: ':heart:',
  smiley14: ':innocent:',
  smiley15: ':angry:',
  smiley16: ':angel:',
  smiley17: ';(',
  smiley18: ':clap:',
  smiley19: ';)',
  smiley20: ':beer:',
};
