import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  // isAfter,
  isBefore,
  isEqual,
  add,
  sub,
  getDay,
  isSameDay,
  set,
  startOfDay,
  differenceInMinutes,
  endOfDay,
  isValid,
} from 'date-fns';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';

import Modal from '../modal';

import usePrevious from '../../../hooks/usePrevious';

import {
  addScheduleEvent,
  postEventAttachment,
} from '../../../redux/actions/schedule_event';

import MemberAutocomplete from '../../MemberAutocomplete';
import GroupAutocomplete from '../../GroupAutocomplete';
import GroupMembersChips from './GroupMembersChips';
import AllDayCheckbox from './AllDayCheckbox';
import MultiDayCheckbox from './MultiDayCheckbox';
import DateTimeLine from './DateTimeLine';
import EventName from './EventName';
import EventTypeSelect from './EventTypeSelect';
import LocationLine from './LocationLine';
import ColorVerticalSelect from './ColorVerticalSelect';
import AttachButton from './AttachButton';
import { ProgressBar } from '../../ProgressBar/index';
import AttachmentList from './AttachmentList';
import {
  RecurringEventCheckbox,
  RecurringEventDetail,
} from './RecurringEventCheckbox';
import RecurrenceDialog from './RecurrenceDialog';
import CustomComposeEditor from '../../../pages/Email/SharedComponents/Compose/CustomComposeEditor/';
import EventURLInput from './EventURLInput';
import EventModalActions from './EventModalActions';
import EventModalSnack from './EventModalSnack';
import InviteModeSwitch from './InviteModeSwitch';

import {
  INVITE_MODE,
  LOCATION_TYPES,
  EVENT_TYPES,
  RECURRENCE_FREQ,
  RECURRENCE_END,
  ADDRESS_TYPES,
  SNACK_TIMEOUT,
  recurrenceText,
  prepareForm,
  roundToNextHour,
  parseGroupToState,
  defaultEventName,
  eventTitle,
  getDateByTime,
  defaultSetting,
} from '../../../utils/calendar';

import { validateScheduleEvent } from '../../../utils/validator/ScheduleEvent';
import OneTimeEventCheckbox from './OneTimeEventCheckbox';

const FileType = require('file-type/browser');
const defaultSnack = { isShow: false, variant: 'success', message: '' };
const CLOSE_TIMEOUT = SNACK_TIMEOUT + 500;
const MOVE_HOURS = 1;

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '0.7em',
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 400,
    marginRight: 40,
    '& button': {
      padding: 8,
    },
  },
});

const EventCreateModal = (props) => {
  const {
    show,
    onClose,
    inputs,
    locationObjects,
    // colors,
    // FIXME: remove this once TZ logic complete
    displayTz = Intl.DateTimeFormat().resolvedOptions().timeZone,
    isCallingApi,
    error,
    groupList,
    memberId,
    dispatch,
    handleShowEventSettingModal,
    calendarSettings,
  } = props;

  const classes = useStyles();

  const timeFormat = useSelector(({ member: { memberSetting } }) => {
    return memberSetting && memberSetting.time_format;
  });

  // Uploading files
  const [snackState, setSnackState] = useState(defaultSnack);
  const [errors, setErrors] = useState({});
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressShow, setUploadProgressShow] = useState(false);
  const [changeName, setChangeName] = useState(false);

  // Recurrence dialog
  const [isShowRecurrence, setShowRecurrence] = useState(false);

  const defaultState = {
    name: '',
    colorId: 'no-color',
    description: '',
    isShowInviteModeSelector: true,
    inviteMode: INVITE_MODE.contacts,
    invitedMembers: [],
    invitedGroup: null,
    eventTimeZone: displayTz,
    start: roundToNextHour(new Date()),
    end: add(roundToNextHour(new Date()), { minutes: 30 }),
    isFullDay: false,
    type: EVENT_TYPES.meeting,
    recurrence: RECURRENCE_FREQ.none,
    endCondition: RECURRENCE_END.date,
    repeatWeekDays: [getDay(new Date())],
    endDate: add(new Date(), { years: 1 }),
    repeatTimes: 2,
    locationMode: LOCATION_TYPES.find,
    selectedPlace: {},
    memberLocation: null,
    eventUrl: '',
    coverAttachmentId: null,
  };

  const [eventState, setEventState] = useState(defaultState);

  const prevCallingApi = usePrevious(isCallingApi);

  useEffect(() => {
    setEventState((ps) => ({
      ...ps,
      name: defaultEventName({
        type: eventState.type,
        inviteMode: eventState.inviteMode,
        invitedMembers: eventState.invitedMembers,
        invitedGroup: eventState.invitedGroup,
      }),
    }));
  }, [
    eventState.type,
    eventState.inviteMode,
    eventState.invitedMembers,
    eventState.invitedGroup,
  ]);

  // Fill state when passed props
  useEffect(() => {
    const startDateTime = inputs?.startDateTime;
    const endDateTime = inputs?.endDateTime;
    const contacts = inputs?.contacts;
    const group = inputs?.group;
    if (!!startDateTime && !!endDateTime) {
      setEventState((ps) => ({
        ...ps,
        start: startDateTime,
        end: endDateTime,
      }));
    }

    if (!!contacts) {
      setEventState((ps) => ({
        ...ps,
        inviteMode: INVITE_MODE.contacts,
        isShowInviteModeSelector: contacts.length === 0,
        invitedMembers: contacts,
      }));
    }

    if (!!group) {
      setEventState((ps) => ({
        ...ps,
        inviteMode: INVITE_MODE.group,
        isShowInviteModeSelector: false,
        invitedGroup: parseGroupToState(group, memberId),
      }));
    }
  }, [groupList, inputs, memberId]);

  const handleClose = useCallback(() => {
    setEventState(defaultState);
    setAttachedFiles([]);
    setUploadProgressShow(false);
    setUploadProgress(0);
    setShowRecurrence(false);
    setErrors({});
    setChangeName(false);
    onClose();
  }, [onClose, defaultState]);

  useEffect(() => {
    if (prevCallingApi === true && isCallingApi === false) {
      if (!error) {
        setSnackState({
          isShow: true,
          variant: 'success',
          message: 'Event added successfully',
        });
        setTimeout(() => handleClose(), [CLOSE_TIMEOUT]);
      } else {
        console.log('error from redux', error);
        setSnackState({
          isShow: true,
          variant: 'error',
          message: error.description,
        });
      }
    }
  }, [handleClose, error, prevCallingApi, isCallingApi]);

  const handleSubmit = () => {
    //TODO: validate event name here
    const formErrors = validateScheduleEvent(eventState);
    console.log('form errors', formErrors);

    if (Object.keys(formErrors).length === 0) {
      setErrors({});
      const preparedForm = prepareForm(eventState, attachedFiles, changeName);
      dispatch(addScheduleEvent(preparedForm));
    } else {
      setErrors(formErrors);
    }
  };

  const handleFullDayClick = (e) => {
    e.persist();
    if (e.target.checked) {
      // Is full day
      setEventState((ps) => ({
        ...ps,
        isFullDay: true,
        start: startOfDay(ps.start),
        end: endOfDay(ps.end),
      }));
    } else {
      setEventState((ps) => ({
        ...ps,
        isFullDay: false,
        // end: add(ps.start, { hours: MOVE_HOURS }),
      }));
    }

    // if (e.target.checked) {
    //   setEventState((ps) => ({
    //     ...ps,
    //     isFullDay: true,
    //     start: ps.start,
    //     end: ps.end,
    //   }));
    // } else {
    //   setEventState((ps) => ({ ...ps, isFullDay: false }));
    // }
  };

  const handleLocationModeChange = (e) => {
    const mode = e.target.value;

    // Set the mode, if OWN, then make the first one selected
    setEventState((ps) => {
      if (mode === LOCATION_TYPES.own) {
        return {
          ...ps,
          locationMode: mode,
          memberLocation:
            locationObjects?.length > 0 ? locationObjects[0] : null,
        };
      } else {
        return { ...ps, locationMode: mode };
      }
    });
  };

  const handleEventTypeChange = (e) => {
    const eventType = e.target.value;

    const memberLocation =
      locationObjects?.length > 0 ? locationObjects[0] : null;
    let locationMode = LOCATION_TYPES.lookup;
    const current = eventState.start || new Date();
    let start = roundToNextHour(current);
    let end = add(start, { minutes: 30 });

    if (
      eventType === EVENT_TYPES.breakfast ||
      eventType === EVENT_TYPES.lunch ||
      eventType === EVENT_TYPES.dinner ||
      eventType === EVENT_TYPES.party
    ) {
      let from, to;

      if (
        calendarSettings &&
        calendarSettings['extra_settings'] &&
        calendarSettings['extra_settings'][eventType] &&
        calendarSettings['extra_settings'][eventType]['start'] &&
        calendarSettings['extra_settings'][eventType]['end']
      ) {
        from = calendarSettings['extra_settings'][eventType]['start'];
        to = calendarSettings['extra_settings'][eventType]['end'];
      } else {
        from = defaultSetting[eventType]['start'];
        to = defaultSetting[eventType]['end'];
      }

      start = getDateByTime(from, current);
      end = getDateByTime(to, current);
    }

    setChangeName(false);

    setEventState((ps) => ({
      ...ps,
      type: eventType,
      memberLocation,
      selectedPlace: null,
      locationMode,
      start,
      end,
    }));
  };

  const handleDateInput = (date, type, isFreeSolo = false) => {
    // if user input manually
    if (isFreeSolo && date && date.target && date.target.value) {
      const input = date.target.value;
      // #:## am/AM/pm/PM only
      const matches = input
        .toLowerCase()
        .match(/(\d{1,2}):(\d{2})(\s)*([ap]m)?/);
      console.log('matches', matches);
      if (!matches || !matches.length) return;
      if (!matches[4] && !timeFormat.includes('24')) matches[4] = 'pm';

      const input_hours =
        (parseInt(matches[1], 10) % 12) + (matches[4] === 'pm' ? 12 : 0);
      const input_minites = matches[2];
      date = set(eventState.start || new Date(), {
        hours: input_hours,
        minutes: input_minites,
        seconds: 0,
        milliseconds: 0,
      });
    }

    if (isValid(date)) {
      setEventState((ps) => {
        // Moving start date ALWAYS maintains the initial duration (by means of moving the end date)
        if (type === 'start') {
          const prevDifferenceMinutes = differenceInMinutes(ps.end, ps.start);
          console.log('The hours difference was', prevDifferenceMinutes);
          return {
            ...ps,
            start: date,
            end: add(date, { minutes: prevDifferenceMinutes }),
            weekDays: [getDay(date)],
          };
        } else if (
          type === 'end' &&
          (isBefore(date, ps.start) || isEqual(date, ps.start))
        ) {
          // We need to move the start date
          const movedStart = sub(date, { hours: MOVE_HOURS });
          return {
            ...ps,
            start: movedStart,
            end: date,
            weekDays: [getDay(movedStart)],
          };
        } else {
          return {
            ...ps,
            [type]: date,
            weekDays: [getDay(ps.start)],
          };
        }
      });
    }
  };

  const handleClearClick = (fileObject) => {
    console.log(fileObject);
    //TODO: Delete the uploaded file to don't leave orphans
    // Clear from state
    setAttachedFiles((ps) =>
      ps.filter((afo) => afo.file_id !== fileObject.fileId)
    );

    // If we delete what is meant to be cover
    if (eventState.coverAttachmentId === fileObject.member_file_id) {
      setEventState((ps) => ({ ...ps, coverAttachmentId: null }));
    }
  };

  const handleSelectFileToAttach = async (event) => {
    // event.persist();
    // One file at a time for now
    const file = Array.from(event.target.files)[0];

    const result = await FileType.fromBlob(file);

    console.debug('File read', file, result);
    let formData = new FormData();
    formData.set('file', file);
    formData.set('fileName', file.name);
    formData.set('size', file.size);
    formData.set('mime', !!result ? result.mime : null);
    setUploadProgressShow(true);
    const fileData = await dispatch(
      postEventAttachment(formData, setUploadProgress)
    );
    setUploadProgressShow(false);
    console.debug('fileData', fileData);
    setAttachedFiles((ps) => [...ps, fileData]);
  };

  const handleRecurrenceClick = () => {
    setShowRecurrence(true);
  };

  const handleRecurrenceCheck = (e) => {
    if (e.target.checked) {
      handleRecurrenceClick();
    } else {
      setEventState((ps) => ({ ...ps, recurrence: RECURRENCE_FREQ.none }));
    }
  };

  const handleOneTimeCheck = (e) => {
    if (e.target.checked) {
      setEventState((ps) => ({ ...ps, recurrence: RECURRENCE_FREQ.none }));
    } else {
      e.target.checked = true;
    }
  };

  const handleWeekDayClick = (e, selected) => {
    // We will set the repeat times = x2 selected days
    setEventState((ps) => {
      return {
        ...ps,
        repeatWeekDays: selected,
        repeatTimes:
          ps.repeatTimes < selected.length
            ? 2 * selected.length
            : ps.repeatTimes,
      };
    });
  };

  const handleChangeRepeatTimes = (e) => {
    e.persist();

    const inputTimes = e.target.value;
    let writeTimes;
    setEventState((ps) => {
      if (ps.recurrence === RECURRENCE_FREQ.weekdays) {
        // if the mode is specific days - don't let have less events then days selected
        writeTimes =
          inputTimes < ps.repeatWeekDays.length
            ? ps.repeatWeekDays.length
            : inputTimes;
      } else {
        writeTimes = inputTimes;
      }
      return { ...ps, repeatTimes: writeTimes };
    });
  };

  const handleDurationSelect = (v) => {
    setEventState((ps) => ({
      ...ps,
      end: v,
      isFullDay: false,
    }));
  };

  const handleMultiDayClick = (e) => {
    e.persist();
    if (e.target.checked) {
      setEventState((ps) => ({ ...ps, end: add(ps.end, { days: 1 }) }));
    } else {
      setEventState((ps) => {
        if (ps.isFullDay) {
          return {
            ...ps,
            start: startOfDay(ps.start),
            end: endOfDay(ps.start),
          };
        } else {
          return {
            ...ps,
            end: add(ps.start, { hours: MOVE_HOURS }),
          };
        }
      });
    }
  };

  const handleSelectGroupToInvite = (e, v) => {
    setEventState((ps) => ({
      ...ps,
      invitedGroup: parseGroupToState(v, memberId),
    }));
  };

  const handleGroupMemberClick = (contactMemberId) => {
    console.log('chip clicked', contactMemberId);
    setEventState((ps) => {
      return {
        ...ps,
        invitedGroup: {
          ...ps.invitedGroup,
          members: ps.invitedGroup.members.map((mo) =>
            mo.contact_member_id === contactMemberId
              ? { ...mo, isShouldInvite: !mo.isShouldInvite }
              : mo
          ),
        },
      };
    });
  };

  const handleSelectedPlace = async (v) => {
    setEventState((ps) => ({ ...ps, selectedPlace: v }));
  };

  const modalTitle = (
    <>
      <div className={classes.row}>
        <div>{eventTitle(eventState, memberId)}</div>
        <div className={classes.setting}>
          <IconButton onClick={handleShowEventSettingModal}>
            <SettingsIcon />
          </IconButton>
          <span>SETTINGS</span>
        </div>
      </div>
      <div className={classes.row}>
        <OneTimeEventCheckbox
          checked={eventState.recurrence === RECURRENCE_FREQ.none}
          onChange={handleOneTimeCheck}
          className={classes.label}
        />

        <AllDayCheckbox
          checked={eventState.isFullDay}
          onChange={handleFullDayClick}
          className={classes.label}
        />

        <MultiDayCheckbox
          checked={!isSameDay(eventState.start, eventState.end)}
          onChange={handleMultiDayClick}
          className={classes.label}
        />

        <RecurringEventCheckbox
          checked={eventState.recurrence !== RECURRENCE_FREQ.none}
          onChange={handleRecurrenceCheck}
          className={classes.label}
        />
      </div>
    </>
  );

  const ModalContent = () => (
    <>
      <EventModalSnack
        snackState={snackState}
        timeout={SNACK_TIMEOUT}
        onClose={() => setSnackState((ps) => ({ ...ps, isShow: false }))}
      />

      <div className="create-event-modal-content">
        <Grid container spacing={2} justify="space-between">
          {/* Recurrence Details */}
          {eventState.recurrence !== RECURRENCE_FREQ.none && (
            <Grid item xs={12}>
              <RecurringEventDetail
                recurrenceText={recurrenceText(
                  eventState.recurrence,
                  eventState.endCondition,
                  eventState.endDate,
                  eventState.repeatTimes,
                  eventState.repeatWeekDays
                )}
                onClick={handleRecurrenceClick}
              />
            </Grid>
          )}

          {eventState.type !== EVENT_TYPES.personal ? (
            <>
              {eventState.isShowInviteModeSelector && (
                <>
                  <Grid item md={4} xs={12}>
                    <InviteModeSwitch
                      inviteMode={eventState.inviteMode}
                      handleChange={(e, v) =>
                        setEventState((ps) => ({
                          ...ps,
                          inviteMode: v,
                        }))
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                md={eventState.isShowInviteModeSelector ? 4 : 6}
                xs={12}
              >
                <EventTypeSelect
                  value={eventState.type}
                  handleChange={handleEventTypeChange}
                />
              </Grid>
              <Grid
                item
                md={eventState.isShowInviteModeSelector ? 4 : 6}
                xs={12}
              >
                <ColorVerticalSelect
                  colorId={eventState.colorId}
                  onChange={(e) =>
                    setEventState((ps) => ({
                      ...ps,
                      colorId: e.target.value,
                    }))
                  }
                />
              </Grid>
              {/* Invited members */}
              {eventState.inviteMode === INVITE_MODE.contacts && (
                <Grid item xs={12}>
                  <MemberAutocomplete
                    handleChange={(e, v) => {
                      console.log('event create modal', v);
                      setEventState((ps) => ({
                        ...ps,
                        invitedMembers: v,
                      }));
                    }}
                    isAddMember={false}
                    label="Invite contacts to event"
                    values={eventState.invitedMembers}
                  />
                </Grid>
              )}
              {eventState.inviteMode === INVITE_MODE.group && (
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <GroupAutocomplete
                      handleChange={handleSelectGroupToInvite}
                      label="Invite Group members"
                      isShowOnlyLeader
                      multiple={false}
                      disabled={!eventState.isShowInviteModeSelector}
                      values={eventState.invitedGroup}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <GroupMembersChips
                      invitedGroup={eventState.invitedGroup}
                      handleChipClick={handleGroupMemberClick}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item md={6} xs={12}>
                <EventTypeSelect
                  value={eventState.type}
                  handleChange={handleEventTypeChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ColorVerticalSelect
                  colorId={eventState.colorId}
                  onChange={(e) =>
                    setEventState((ps) => ({ ...ps, colorId: e.target.value }))
                  }
                />
              </Grid>
            </>
          )}
          {/* END Invited members */}
          {/* Date time */}
          <Grid item xs={12}>
            <DateTimeLine
              start={eventState.start}
              end={eventState.end}
              isFullDay={eventState.isFullDay}
              onDateChange={handleDateInput}
              onDurationChange={handleDurationSelect}
              error={errors.datetime}
              isFreeSolo={true}
            />
          </Grid>
          {/* END Date time*/}

          {/*Location*/}
          {(eventState.type === EVENT_TYPES.meeting ||
            eventState.type === EVENT_TYPES.personal ||
            eventState.type === EVENT_TYPES.breakfast ||
            eventState.type === EVENT_TYPES.lunch ||
            eventState.type === EVENT_TYPES.dinner ||
            eventState.type === EVENT_TYPES.party) && (
            <Grid item xs={12}>
              <LocationLine
                meetingType={eventState.type}
                locationMode={eventState.locationMode}
                selectedPlace={eventState.selectedPlace}
                onModeChange={handleLocationModeChange}
                onSavedSelect={(v) => {
                  setEventState((ps) => ({ ...ps, memberLocation: v }));
                }}
                error={errors.location}
                memberLocation={eventState.memberLocation}
                onSelectedPlace={handleSelectedPlace}
              />
            </Grid>
          )}

          {(eventState.type === EVENT_TYPES.video ||
            eventState.type === EVENT_TYPES.webinar) && (
            <Grid item xs={12}>
              <EventURLInput
                meetingType={eventState.type}
                locationData={eventState.event_url}
                onInputChange={(v) =>
                  setEventState((ps) => ({ ...ps, event_url: v }))
                }
                error={errors.location}
              />
            </Grid>
          )}
          {/*END Location*/}

          {/*Event Title*/}
          <Grid item xs={12}>
            <EventName
              value={eventState.name}
              onChange={(input) => {
                setChangeName(true);
                setEventState((ps) => ({ ...ps, name: input }));
              }}
              defaultName={defaultEventName(eventState)}
              error={errors.name}
            />
          </Grid>
          {/*END Event Title*/}

          <Grid item xs={12}>
            <CustomComposeEditor
              multiline
              rowsMax={20}
              value={eventState.description}
              handleChangebd={(v) => {
                setEventState((ps) => ({
                  ...ps,
                  description: v,
                }));
              }}
              placeholder={'Your event details...'}
            />
          </Grid>

          {/* Files */}
          {uploadProgressShow && (
            <Grid item xs={12}>
              <ProgressBar value={uploadProgress} />
            </Grid>
          )}
          <Grid item xs={12}>
            <AttachButton onChange={handleSelectFileToAttach} />
          </Grid>
          {attachedFiles.length > 0 && (
            <Grid item xs={12}>
              <AttachmentList
                attachedFiles={attachedFiles}
                onClearClick={handleClearClick}
                cover={eventState.coverAttachmentId}
                onSelectCover={(v) =>
                  setEventState((ps) => ({
                    ...ps,
                    coverAttachmentId: ps.coverAttachmentId === v ? null : v,
                  }))
                }
              />
            </Grid>
          )}
          {/* Files end*/}
        </Grid>
      </div>
    </>
  );

  console.debug('state', eventState);
  console.debug('attachments state', attachedFiles);

  return (
    <div>
      {isShowRecurrence && (
        <RecurrenceDialog
          start={eventState.start}
          isOpen={isShowRecurrence}
          handleClose={() => setShowRecurrence(false)}
          recurrenceOption={eventState.recurrence}
          handleOptionChange={(e) =>
            setEventState((ps) => ({ ...ps, recurrence: e.target.value }))
          }
          endOption={eventState.endCondition}
          handleChangeMode={(e) =>
            setEventState((ps) => ({ ...ps, endCondition: e.target.value }))
          }
          repeatTimes={eventState.repeatTimes}
          handleChangeRepeatTimes={handleChangeRepeatTimes}
          endDate={eventState.endDate}
          handleChangeEndDate={(v) =>
            setEventState((ps) => ({ ...ps, endDate: v }))
          }
          weekDays={eventState.repeatWeekDays}
          handleWeekdayClick={handleWeekDayClick}
        />
      )}
      <Modal
        open={show}
        onClose={handleClose}
        title={modalTitle}
        ModalContent={ModalContent}
        ModalAction={() => (
          <EventModalActions
            closeLabel="Cancel"
            onClose={handleClose}
            isLoading={isCallingApi}
            loadingLabel="Creating.."
            actionLabel="Create"
            onAction={handleSubmit}
          />
        )}
        className="create-event-modal"
      ></Modal>
    </div>
  );
};

EventCreateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  imputs: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  locationObjects: PropTypes.array,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCallingApi: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

EventCreateModal.defaultProps = {
  show: false,
  close: null,
};

const mapStateToProps = (state) => ({
  colors: state.scheduleEvents.colors,
  locationObjects: state.member.memberInfo.location_information.filter(
    (lo) => lo.location_type !== ADDRESS_TYPES.billing
  ),
  schedulerSetting: state.schedulerSetting.data,
  isCallingApi: state.scheduleEvents.isCreating,
  memberId: state.member.member.member_id,
  error: state.scheduleEvents.error,
});

export default connect(mapStateToProps)(EventCreateModal);
