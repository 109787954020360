import { useState } from 'react';

export const useToolbar = () => {
  const [searchKey, setSearchKey] = useState('');
  const [searchMemberKey, setSearchMemberkey] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [sortDir, setSortDir] = useState('descending');

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  const handleSearchMemberKey = (event) => {
    event.persist();
    setSearchMemberkey(event.target.value);
  };

  const handleSortDir = (event) => {
    event.persist();
    setSortDir(event.target.value);
  };

  const handleSelectMember = (member_id) => {
    setSelectedMembers((prev) => {
      let newMembers = [];
      if (prev.includes(member_id))
        newMembers = prev.filter((member) => member !== member_id);
      else newMembers = [...prev, member_id];
      return newMembers;
    });
  };

  return {
    searchKey,
    handleSearch,
    searchMemberKey,
    handleSearchMemberKey,
    selectedMembers,
    handleSelectMember,
    sortDir,
    handleSortDir,
  };
};

export default useToolbar;
