import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from '../Sections/Layout';

// import UpcomingCalls from './UpcomingCalls';
// import UpcomingEvents from './UpcomingEvents';
// import GroupConferences from './GroupConferences';
// import PreviousEvents from './PreviousEvents';
// import CollaborativeCalendar from './CollaborativeCalendar';
import NewCalendar from './NewCalendar';

const Calendar = (props) => {
  return (
    <Layout {...props}>
      <NewCalendar {...props} />
      {/* <Switch>
        <Redirect
          from={`${process.env.PUBLIC_URL}/calendar`}
          to={`${process.env.PUBLIC_URL}/calendar/upcoming-events`}
          exact
        />
        <Route
          path={`${process.env.PUBLIC_URL}/calendar/upcoming-events`}
          component={UpcomingEvents}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/calendar/group-conference`}
          component={GroupConferences}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/calendar/prev-events`}
          component={PreviousEvents}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/calendar/callaborative-calendar`}
          component={CollaborativeCalendar}
        />
      </Switch> */}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
