import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      width: '170px',
      height: '37px',
      background: 'white',
      borderRadius: '7px',
      border: '1px solid #E0E0E0',
      textTransform: 'none',
      color: '#999999',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  filterLabel: {
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    color: '#999999',
  },
  panel: {
    padding: '10px 16px',
    width: '236px',
  },
  feed: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    height: '40px',
    color: '#5F5F5F',
    fontSize: '13px',
    fontWeight: 'normal',
  },
}));

const options = [
  'All',
  'Banks',
  'Blogs and Forums',
  'Company',
  'Credit Card',
  `Crypto Currency's`,
  'Financial Services',
  'Loyalty',
  'Other',
  'Social Media',
];

const Filter = (props) => {
  const { selectedOption, handleOption } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const openFilter = Boolean(anchorEl);

  return (
    <div className={classes.filter}>
      <Button onClick={handleFilterClick}>
        <span className={classes.filterLabel}>
          {selectedOption ? selectedOption : 'Select'}
        </span>
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={openFilter}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.panel}>
          {options
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .map((option) => (
              <div
                className={classes.feed}
                key={option}
                onClick={() => {
                  if (selectedOption === option) handleOption(null);
                  else handleOption(option);
                }}
              >
                <span>{option}</span>
                {option === selectedOption && <CheckIcon />}
              </div>
            ))}
        </div>
      </Popover>
    </div>
  );
};

export default Filter;
