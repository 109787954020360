import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from './serviceWorker';
import AppWrapper from './AppWrapper';

import { persistor, store } from './utils/store';
import { ClearCacheProvider } from 'react-clear-cache';

ReactDOM.render(
  <ClearCacheProvider duration={5000}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppWrapper/>
      </PersistGate>
    </Provider>
  </ClearCacheProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
