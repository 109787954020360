import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  IconButton,
  Button,
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ClearIcon from '@material-ui/icons/Clear';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import {
  removeGroupMember,
  removeGroupOutsideMember,
} from '../../redux/actions/group';
import ConfirmDialog from '../ConfirmDialog';
import AddMemberModal from './AddMemberModal';
import ContactPreview from '../ContactPreview';
import MemberInviteModal from '../Modal/MemberInviteModal';
import AddOutsideMemberModal from '../Modal/AddOutsideMember';

const useStyles = makeStyles((theme) => ({
  addMember: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  addMemberBtn: {
    textTransform: 'capitalize',
    margin: '8px',
  },
}));

const GroupMembers = (props) => {
  const { isLeader, groupData, isPagination = false } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const maxTableRowCount = 3;

  const currentMemberId = useSelector((state) => state.member.member.member_id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(maxTableRowCount);

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [deleteMemberId, setDeleteMemberId] = useState(0);
  const [memberType, setMemberType] = useState('secure');
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showOutsideMemberModal, setShowOutsideMemberModal] = useState(false);
  const [outsideMember, setOutsideMember] = useState(undefined);

  const handleDeleteConfirmDialogClose = () => {
    setDeleteConfirmDialogOpen(false);
  };

  const handleDeleteConfirmDialogOpen = (
    memberId,
    deleteMemberType = 'secure'
  ) => {
    setDeleteConfirmDialogOpen(true);
    setDeleteMemberId(memberId);
    setMemberType(deleteMemberType);
  };

  const handleAddMemberModalClose = () => {
    setAddMemberModalOpen(false);
  };

  const handleAddMemberModalOpen = () => {
    setAddMemberModalOpen(true);
  };

  const handleInviteMemberModalClose = () => {
    setShowInviteModal(false);
  };

  const handleInviteMemberModalOpen = () => {
    setShowInviteModal(true);
  };

  const handleAddOutsideMemberModalClose = () => {
    setShowOutsideMemberModal(false);
  };

  const handleAddOutsideMemberModalOpen = (data) => {
    setShowOutsideMemberModal(true);
    if (data) {
      setOutsideMember(data);
    } else {
      setOutsideMember(undefined);
    }
  };

  const removeMemberFromGroup = () => {
    let formData = new FormData();
    formData.set('groupMemberId', deleteMemberId + '');
    formData.set('groupIds', groupData.group_id);
    if (memberType === 'secure') {
      dispatch(removeGroupMember(formData, 'group'));
    } else {
      dispatch(removeGroupOutsideMember(deleteMemberId));
    }
    handleDeleteConfirmDialogClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const members = groupData.members || [];
  const groupMembers = useMemo(() => {
    return groupData.members || [];
  }, [groupData.members]);

  const groupUnsecuredMembers = useMemo(() => {
    return groupData.unsecured_members || [];
  }, [groupData.unsecured_members]);

  const members = useMemo(() => {
    if (isLeader) {
      return [...groupMembers, ...groupUnsecuredMembers];
    }
    return groupMembers;
  }, [groupMembers, groupUnsecuredMembers, isLeader]);

  return (
    <Grid container>
      <Grid container alignItems="center">
        {isLeader && (
          <Grid item xs={12} className={styles.addMember}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.addMemberBtn}
              onClick={handleAddMemberModalOpen}
              startIcon={<PersonAddIcon />}
            >
              Add Contact
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.addMemberBtn}
              onClick={handleInviteMemberModalOpen}
            >
              Invite Outside Member
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.addMemberBtn}
              onClick={handleAddOutsideMemberModalOpen}
            >
              Add Outside Member
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TableContainer
        // className={styles.tableContainer}
        >
          <Table
            // className={styles.table}
            aria-label="customized table"
            stickyHeader
          >
            <TableBody>
              {members.length > 0 &&
                (!isPagination
                  ? members
                  : members.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                ).map((row, index) => {
                  if (index < groupData.members.length) {
                    return (
                      <TableRow key={index}>
                        <TableCell id="name_email">
                          {
                            <ContactPreview
                              contactData={row}
                              isShowAvatar={true}
                            />
                          }
                        </TableCell>
                        <TableCell id="delete" align="right">
                          {isLeader && row.member_id !== currentMemberId && (
                            <IconButton
                              aria-label="Delete Member"
                              // className={styles.deleteMemberIcon}
                              onClick={() =>
                                handleDeleteConfirmDialogOpen(
                                  row.member_id,
                                  'secure'
                                )
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow key={index}>
                        <TableCell id="name_email">
                          {
                            <ContactPreview
                              contactData={{
                                ...row,
                                member_id: '',
                                extra: `+${row.phone_code}${row.phone_number}`,
                                unsecured: true,
                              }}
                              isShowAvatar={true}
                            />
                          }
                        </TableCell>
                        <TableCell id="delete" align="center">
                          <div className="d-flex">
                            {isLeader && (
                              <IconButton
                                aria-label="Update Member"
                                // className={styles.deleteMemberIcon}
                                onClick={() =>
                                  handleAddOutsideMemberModalOpen(row)
                                }
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                            )}
                            {isLeader && (
                              <IconButton
                                aria-label="Delete Member"
                                // className={styles.deleteMemberIcon}
                                onClick={() =>
                                  handleDeleteConfirmDialogOpen(
                                    row.member_id,
                                    'unsecured'
                                  )
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {isPagination && (
          <TablePagination
            rowsPerPageOptions={[maxTableRowCount]}
            component="div"
            count={members.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Grid>
      <AddMemberModal
        title="Add Member"
        open={addMemberModalOpen}
        groupId={groupData.group_id}
        onClose={handleAddMemberModalClose}
      />
      <ConfirmDialog
        open={deleteConfirmDialogOpen}
        title="Confirm Delete"
        onOk={removeMemberFromGroup}
        onCancel={handleDeleteConfirmDialogClose}
      />
      <MemberInviteModal
        open={showInviteModal}
        group_id={groupData.group_id}
        onClose={handleInviteMemberModalClose}
      ></MemberInviteModal>
      <AddOutsideMemberModal
        outsideMember={outsideMember}
        open={showOutsideMemberModal}
        group_id={groupData.group_id}
        onClose={handleAddOutsideMemberModalClose}
        update={!!outsideMember}
      />
    </Grid>
  );
};

export default GroupMembers;
