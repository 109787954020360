import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  BtnWithCircular: {
    margin: theme.spacing(1),
    '&:hover .MuiCircularProgress-circle': {
      color: '#ff00ff',
    },
    '& .MuiCircularProgress-circle': {
      color: '#ff0000',
    },
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 16px',
  },
  Btn: {
    margin: '12px 16px',
    fontSize: 15,
    borderRadius: 7,
    height: 40,
    fontFamily: 'lato,Helvetica Neue,sans-serif',
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  cancelBtn: {
    padding: '11px 40px',
    background: '#FFFFFF',
    border: '1px solid #8DA3C5',
    color: '#8DA3C5',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  okBtn: {
    padding: '11px 30px',
  },
}));

export default useStyles;
