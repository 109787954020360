import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path d="M22.125 11.438L24 13.313l-8 8-8-8 1.875-1.875L16 17.563z"></path>
    </svg>
  );
}
export default Icon;
