import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Paper,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import DenseTabs from '../../components/DenseTabs/DenseTabs';
import DenseTab from '../../components/DenseTabs/DenseTab';
import UnregisteredCompanyTable from './UnregisteredCompanyTable';
import AmeraPagination from '../../components/AmeraPagination';
import TimeZoneAutocomplete from '../../components/Modal/EventModals/TimeZoneAutocomplete';
import { openDrawer } from '../../redux/actions/drawer';
import { getCompanyDetail } from '../../redux/actions/company';
import { DRAWER_CONTENT_TYPE } from '../../utils/drawer';
// import CompanyDetailModal from '../../components/Company/CompanyDetailModal';
import {
  loadCompanies,
  clearCompanyDetail,
  setReduxCompanyAlert,
} from '../../redux/actions/company';

import { getAllMembers } from '../../redux/actions/member';
import { defaultDateTimeZone } from '../../utils/calendar';

const headCells = [
  {
    id: 'company_name',
    label: 'Company Name',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'address_1',
    label: 'Address',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'main_phone',
    label: 'Phone',
    minWidth: 170,
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'create_date',
    label: 'Created Date',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'center',
    sortDisabled: true,
    format: (value) => value.toLocaleString('en-US'),
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {!headCell.sortDisabled ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {},
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
  },
  createCompany: {
    borderRadius: '25px',
    margin: theme.spacing(2),
  },
  tabWrapper: {
    margin: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  actions: {
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(2),
    },
  },
  skinnyTable: {
    width: 480,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const GlobalCompanyAdmin = (props) => {
  const { isAdmin, timeZone, setTimeZone } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [orderBy, setOrderBy] = useState('company_name');
  const [order, setOrder] = useState('asc');
  // const [showCompanyModal, setShowCompanyModal] = useState(false);
  // const [companyId, setCompanyId] = useState(null);
  // const [isEdit, setIsEdit] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [tab, setTab] = useState('registered');
  const dispatch = useDispatch();

  const { companyAlert, companies, totalCount } = useSelector((state) => ({
    companyAlert: state.company.companyAlert,
    companies: state.company.companyList,
    totalCount: state.company.registeredCount,
  }));

  const loadSortedCompanies = (property, value, page_number, page_size) => {
    let sort = '';
    if (!property) {
      property = orderBy;
    }
    if (!value) {
      value = order;
    }

    sort = value === 'desc' ? `-${property}` : `${property}`;

    let params = {};

    params.sort = sort;
    params.page_number = page_number >= 0 ? page_number : pageNumber - 1;
    params.page_size = page_size ? page_size : pageSize;

    dispatch(loadCompanies(params));
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    loadSortedCompanies(property, newOrder);
  };

  useEffect(() => {
    dispatch(loadCompanies({ page_number: 0, page_size: 25 }));
    dispatch(getAllMembers());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (companyAlert.show) {
      // setShowCompanyModal(false);
      setTimeout(() => {
        const alertOption = {
          show: false,
          variant: companyAlert.variant ? companyAlert.variant : 'success',
          message: '',
        };
        dispatch(setReduxCompanyAlert(alertOption));
      }, 5000);
    }
  }, [dispatch, companyAlert]);

  const handleSelect = (event, id) => {
    const selectedIndex = selectedCompanies.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCompanies, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCompanies.slice(1));
    } else if (selectedIndex === selectedCompanies.length - 1) {
      newSelected = newSelected.concat(selectedCompanies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCompanies.slice(0, selectedIndex),
        selectedCompanies.slice(selectedIndex + 1)
      );
    }

    setSelectedCompanies(newSelected);
  };

  const handleChangePage = (e, newPage) => {
    if (newPage !== pageNumber) {
      loadSortedCompanies(null, null, newPage - 1);
      setPageNumber(newPage);
    }
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = e.target.value;
    setPageSize(parseInt(pageSize, 10));
    setPageNumber(1);
    loadSortedCompanies(null, null, 0, pageSize);
  };

  const showCompanyDetail = async (companyId) => {
    // setShowCompanyModal(true);
    await dispatch(getCompanyDetail(companyId));
    await dispatch(openDrawer(DRAWER_CONTENT_TYPE.companyProfile));
    // setIsEdit(false);
    // setCompanyId(companyId);
  };

  const createCompany = async () => {
    await dispatch(clearCompanyDetail());
    await dispatch(openDrawer(DRAWER_CONTENT_TYPE.companyCreate));
    // setShowCompanyModal(true);
    // setCompanyId(null);
    // setIsEdit(true);
  };

  const handleSnackbarClose = () => {
    if (companyAlert.show) {
      const alertOption = {
        show: false,
        variant: companyAlert.variant,
        message: '',
      };
      setReduxCompanyAlert(alertOption);
    }
  };

  const isSelected = (id) => selectedCompanies.indexOf(id) !== -1;

  return (
    <Paper className={classes.paper}>
      <div className={classes.settingsWrapper}>
        <Button
          color="primary"
          variant="contained"
          className={classes.createCompany}
          onClick={createCompany}
        >
          Create Company
        </Button>
        <TimeZoneAutocomplete
          variant="outlined"
          value={timeZone}
          onChange={(e, value) => setTimeZone(value)}
        />
      </div>
      {/* <EnhancedTableToolbar
        title="Company"
        numSelected={selectedCompanies.length}
        deleteCompany={() => {
          const formData = new FormData();
          formData.set('companyIds', selectedCompanies)
          dispatch(deleteCompanies(formData));
        }}
      /> */}
      <div className={classes.tabWrapper}>
        <DenseTabs
          value={tab}
          onChange={(event, value) => {
            setTab(value);
          }}
          indicatorColor="primary"
        >
          <DenseTab value="registered" label="Registered" />
          <DenseTab value="unregistered" label="Unregistered" />
        </DenseTabs>
      </div>
      {tab === 'registered' ? (
        <>
          <TableContainer className={classes.container}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="activityTable"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {companies &&
                  companies.map((company) => {
                    const isItemSelected = isSelected(company.id);
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleSelect(event, company.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={company.id}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">{company.name}</TableCell>
                        <TableCell align="left">
                          {company.address_1 === 'null'
                            ? ''
                            : company.address_1}
                        </TableCell>
                        <TableCell align="left">
                          {company.main_phone === 'null'
                            ? ''
                            : company.main_phone}
                        </TableCell>
                        <TableCell align="left">
                          {company.create_date &&
                            defaultDateTimeZone(
                              company.create_date,
                              'date',
                              true,
                              timeZone
                            )}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              showCompanyDetail(company.id);
                            }}
                          >
                            view company
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {totalCount > 25 && (
            <AmeraPagination
              itemTypeLabel="Items"
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={pageSize}
              totalCount={totalCount}
              page={pageNumber}
              handleChangePage={handleChangePage}
            />
          )}
        </>
      ) : (
        <UnregisteredCompanyTable classes={classes} />
      )}
      {/* <CompanyDetailModal
        open={showCompanyModal}
        isEdit={isEdit}
        isAdmin={isAdmin}
        onClose={() => {
          setShowCompanyModal(false);
        }}
        companyId={companyId}
        switchMode={() => {
          setIsEdit(!isEdit);
        }}
      /> */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={companyAlert.show}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={companyAlert.variant}>{companyAlert.message}</Alert>
      </Snackbar>
    </Paper>
  );
};

export default GlobalCompanyAdmin;
