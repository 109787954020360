import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbarData } from '../../redux/actions/snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export const SnackBarMessage = (props) => {
  const dispatch = useDispatch();
  const { onClick, autoHideDuration } = props;
  const snackbar = useSelector((state) => state.snackbar.snackbarData);

  const closeSnack = (e) => {
    dispatch(
      setSnackbarData({
        ...snackbar,
        open: false,
      })
    );
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={autoHideDuration}
      onClose={closeSnack}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        severity={snackbar.type}
        action={
          <>
            {snackbar.action}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={closeSnack}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

SnackBarMessage.defaultProps = {
  autoHideDuration: 2000,
};
