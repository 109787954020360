import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header, { messages as headerMessages } from '../Header';
import Sidebar, { messages as sidebarMessages } from '../Sidebar';
import Content from '../Content';
import AmeraStaticBanner from '../../../components/Banner/AmeraStaticBanner';

import { setBadgeCount } from '../../../redux/actions/mail';

export const messages = { ...headerMessages, ...sidebarMessages };

const Layout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    intl: { formatMessage },
  } = props;

  const mailBadgeCountLoading = useSelector(
    (state) => state.mail.mailBadgeCountLoading
  );

  useEffect(() => {
    let title = 'AmeraShare';
    if (props.title) {
      title += ` - ${formatMessage(props.title)}`;
    }
    document.title = title;
  }, [props, formatMessage]);

  useEffect(() => {
    const unregister = history.listen(() => {
      if (mailBadgeCountLoading === false) {
        dispatch(setBadgeCount());
      }
    });
    return () => {
      unregister();
    };
  }, [dispatch, history, mailBadgeCountLoading]);

  return (
    <div>
      <Header />
      <AmeraStaticBanner/>
      <div className="main">
        <Sidebar {...props} />
        <Content {...props} />
      </div>
    </div>
  );
};

export default injectIntl(Layout);
