import * as api from '../../config/api';
import {
  // REMOVE_COMPANY_MEMBER,
  // ADD_COMPANY_MEMBER,
  CREATE_COMPANY_START,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY,
  LOAD_COMPANY,
  CLEAR_COMPANY,
  LOAD_COMPANIES_START,
  LOAD_COMPANIES,
  LOAD_COMPANIES_FAILED,
  LOAD_UNREGISTERED_COMPANIES,
  DELETE_UNREGISTERED_COMPANY,
  UPDATE_UNREGISTERED_COMPANY,
  CREATE_COMPANY_FROM_NAME,
  SET_COMPANY_ALERT,
  // Picture
  UPDATE_COMPANY_PIC_START,
  UPDATE_COMPANY_PIC_SUCCESS,
  UPDATE_COMPANY_PIC_ERROR,
  // Details
  UPDATE_COMPANY_DETAILS_START,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_ERROR,
  // Members
  UPDATE_COMPANY_MEMBERS_START,
  UPDATE_COMPANY_MEMBERS_SUCCESS,
  UPDATE_COMPANY_MEMBERS_ERROR,
} from '../actionTypes/company';
import { setCompanyAlert } from '../../utils/alert';

const apiUrl = api.apiUrl;

export const getUnregisteredCompanies = (params = {}) => {
  return function (dispatch) {
    const url = `${apiUrl}/company/unregistered?${new URLSearchParams(
      params
    ).toString()}`;
    return api
      .GET(url)
      .then((res) => {
        return dispatch({
          type: LOAD_UNREGISTERED_COMPANIES,
          payload: res.data,
        });
      })
      .catch((error) => {});
  };
};

export const getCompanyDetail = (companyId) => {
  return function (dispatch) {
    const url = `${apiUrl}/company/${companyId}`;
    return api.GET(url).then((res) => {
      return dispatch({
        type: LOAD_COMPANY,
        payload: res.data,
      });
    });
  };
};

export const clearCompanyDetail = () => (dispatch) => {
  return dispatch({ type: CLEAR_COMPANY });
};

export const createCompany = (formData) => {
  return function (dispatch) {
    dispatch(createCompanyStart());
    const url = `${apiUrl}/company`;
    api
      .POST(url, formData, {})
      .then((res) => {
        if (res.success) {
          const payload = setCompanyAlert(res, true);
          dispatch(setReduxCompanyAlert(payload));
          dispatch(createCompanySuccess(res.data));
        } else {
          const payload = setCompanyAlert(null, false);
          dispatch(setReduxCompanyAlert(payload));
        }
      })
      .catch((error) => {
        const payload = setCompanyAlert(error, false);
        dispatch(setReduxCompanyAlert(payload));
      });
  };
};

export const deleteCompanies = (data) => (dispatch) => {
  const url = `${apiUrl}/company`;
  return api
    .DELETE(url, data, {})
    .then((res) => {
      if (res.data) {
        return dispatch({
          type: DELETE_COMPANY,
          payload: res.data,
        });
      }
    })
    .catch((error) => {});
};

export const updateCompany = (companyId, formData) => {
  return function (dispatch) {
    dispatch(updateCompanyStart());
    const url = `${apiUrl}/company/${companyId}`;
    return api
      .PUT(url, formData, {})
      .then((res) => {
        const payload = setCompanyAlert(res, true);
        dispatch(setReduxCompanyAlert(payload));
        return dispatch(updateCompanySuccess(res.data));
      })
      .catch((error) => {
        const payload = setCompanyAlert(error, false);
        dispatch(setReduxCompanyAlert(payload));
      });
  };
};

// // remove member from company
export const removeCompanyMember = (formData) => {
  const url = `${apiUrl}/company/member`;
  return function (dispatch) {
    return api
      .DELETE(url, formData, {})
      .then((res) => {
        return dispatch(updateCompanySuccess(res.data));
      })
      .catch((error) => {});
  };
};

// add member to company
export const addCompanyMember = (formData) => async (dispatch) => {
  const url = `${apiUrl}/company/member`;
  try {
    const res = await api.POST(url, formData, {});
    if (res.success) {
      return dispatch(updateCompanySuccess(res.data));
    }
  } catch (error) {}
};

export const loadCompanies = (params = {}) => (dispatch) => {
  const url = `${apiUrl}/company?${new URLSearchParams(params).toString()}`;

  return new Promise((resolve) => {
    dispatch({ type: LOAD_COMPANIES_START });
    api
      .GET(url)
      .then((res) => {
        if (res.success) {
          dispatch({ type: LOAD_COMPANIES, payload: res.data });
          resolve((res.data && res.data.data) || null);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({ type: LOAD_COMPANIES_FAILED });
        resolve(null);
      });
  });
};

export const deleteUnregisteredCompany = (companyName) => async (dispatch) => {
  const url = `${apiUrl}/company/unregistered`;
  const formData = new FormData();
  formData.append('company_name', companyName);
  try {
    const res = await api.DELETE(url, formData, {});
    if (res.success) {
      const payload = setCompanyAlert(res, true);
      dispatch(setReduxCompanyAlert(payload));
      return dispatch({
        type: DELETE_UNREGISTERED_COMPANY,
        payload: companyName,
      });
    }
    const payload = setCompanyAlert(null, false);
    dispatch(setReduxCompanyAlert(payload));
  } catch (error) {
    const payload = setCompanyAlert(error, false);
    dispatch(setReduxCompanyAlert(payload));
  }
};

export const updateUnregisteredCompany = (
  companyName,
  newCompanyName
) => async (dispatch) => {
  const url = `${apiUrl}/company/unregistered`;
  const formData = new FormData();
  formData.append('company_name', companyName);
  formData.append('new_company_name', newCompanyName);
  try {
    const res = await api.PUT(url, formData, {});
    if (res.success) {
      const payload = setCompanyAlert(res, true);
      dispatch(setReduxCompanyAlert(payload));
      return dispatch({
        type: UPDATE_UNREGISTERED_COMPANY,
        payload: {
          companyName,
          newCompanyName,
        },
      });
    }
    const payload = setCompanyAlert(null, false);
    dispatch(setReduxCompanyAlert(payload));
  } catch (error) {
    const payload = setCompanyAlert(error, false);
    dispatch(setReduxCompanyAlert(payload));
  }
};

export const createCompanyFromName = (companyName) => async (dispatch) => {
  const url = `${apiUrl}/company/unregistered`;
  const formData = new FormData();
  formData.append('company_name', companyName);
  try {
    const res = await api.POST(url, formData, {});
    if (res.success) {
      const payload = setCompanyAlert(res, true);
      dispatch(setReduxCompanyAlert(payload));
      return dispatch({
        type: CREATE_COMPANY_FROM_NAME,
        payload: {
          company: res.data,
          companyName,
        },
      });
    }
    const payload = setCompanyAlert(null, false);
    dispatch(setReduxCompanyAlert(payload));
  } catch (error) {
    const payload = setCompanyAlert(error, false);
    dispatch(setReduxCompanyAlert(payload));
  }
};

export const updateCompanyPicture = (companyId, formData) => async (
  dispatch
) => {
  const url = `${apiUrl}/company/picture/${Number(companyId)}`;
  try {
    dispatch({ type: UPDATE_COMPANY_PIC_START });
    const res = await api.POST(url, formData, {});
    if (res.success) {
      dispatch({
        type: UPDATE_COMPANY_PIC_SUCCESS,
        payload: { message: res.description, companyData: res.data },
      });
    } else {
      dispatch({ type: UPDATE_COMPANY_PIC_ERROR, payload: res.description });
    }
  } catch (error) {
    dispatch({ type: UPDATE_COMPANY_PIC_ERROR, payload: error.description });
  }
};

export const updateCompanyDetails = (companyId, data) => async (dispatch) => {
  const url = `${apiUrl}/company/details/${Number(companyId)}`;
  try {
    dispatch({ type: UPDATE_COMPANY_DETAILS_START });
    const res = await api.POST(url, JSON.stringify(data), {
      'Content-Type': 'application/json',
    });
    if (res.success) {
      dispatch({
        type: UPDATE_COMPANY_DETAILS_SUCCESS,
        payload: { message: res.description, companyData: res.data },
      });
    } else {
      dispatch({
        type: UPDATE_COMPANY_DETAILS_ERROR,
        payload: res.description,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_COMPANY_DETAILS_ERROR,
      payload: error.message,
    });
  }
};

export const updateCompanyMembers = (companyId, data) => async (dispatch) => {
  const url = `${apiUrl}/company/members/${Number(companyId)}`;
  try {
    dispatch({ type: UPDATE_COMPANY_MEMBERS_START });
    const res = await api.POST(url, JSON.stringify(data), {
      'Content-Type': 'application/json',
    });
    if (res.success) {
      dispatch({
        type: UPDATE_COMPANY_MEMBERS_SUCCESS,
        payload: { message: res.description, companyData: res.data },
      });
    } else {
      dispatch({
        type: UPDATE_COMPANY_MEMBERS_ERROR,
        payload: res.description,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_COMPANY_MEMBERS_ERROR,
      payload: error.message,
    });
  }
};

export const createCompanyStart = () => ({
  type: CREATE_COMPANY_START,
});
export const createCompanySuccess = (data) => ({
  type: CREATE_COMPANY_SUCCESS,
  payload: data,
});
export const updateCompanyStart = () => ({
  type: UPDATE_COMPANY_START,
});
export const updateCompanySuccess = (data) => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: data,
});

export const setReduxCompanyAlert = (payload) => ({
  type: SET_COMPANY_ALERT,
  payload,
});
