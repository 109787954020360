import React, { useState } from 'react';
import styles from '../ProjectDetails/ProjectDetails.module.scss';
import EditMileStoneModal from './EditMileStoneModal';
import Drawer from '@material-ui/core/Drawer';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MaterialAvatar from '../../../../components/MaterialAvatar';
import { ProjectsApi } from '../../../../redux/actions/project';
import { useDispatch } from 'react-redux';
import { defaultDateTimeZone } from '../../../../utils/calendar';

import { format } from 'date-fns';

const ViewMileStonePane = ({
  projectId,
  parent_id,
  element_id,
  state,
  handleClose,
  milestone_title,
  milestone_description,
  member_id,
  members,
  due_date,
  rate,
  currencyId,
  comments,
  element_status,
}) => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const handleOpenEditMileStone = () => {
    setOpen(true);
  };
  const handleCloseEditMileStone = () => {
    setOpen(false);
  };

  const [note, setNote] = useState('');
  const formNote = {
    element_id: element_id,
    element_note: note,
  };
  const submitHandlerNote = () => {
    dispatch(ProjectsApi.postNote(formNote));
    setNote('');
  };

  const handleSubmit = (form, element_id) => {
    dispatch(ProjectsApi.updateProjectMilestone(form, element_id));
    setOpen(false);
  };

  const lastItem = element_status && element_status[element_status.length - 1];
  const status = lastItem && lastItem.element_status;
  const mark = status === 'complete' ? 'in progress' : 'complete';

  const formCompleted = {
    // project_id: projectId,
    // project_member_id: Number(member_id) === 0 ? null : Number(member_id),
    // parent_id: parent_id,
    // element_type: 'milestone',
    // title: milestone_title,
    // description: milestone_description,
    // due_date: due_date,
    // element_status: mark,
    // contract_id: member_id,

    project_id: Number(projectId),
    project_member_id: Number(member_id),
    parent_id: parent_id,
    element_type: 'milestone',
    title: milestone_title && milestone_title,
    description: milestone_description && milestone_description,
    pay_rate: Number(rate),
    currency_code_id: Number(currencyId),
    due_date: due_date,
    status: mark,
    // moment(due_date).format('YYYY-MM-DD')
  };
  const markComplete = () => {
    //console.log('ViewMilestoneSidePane.js > complete milestone', formCompleted, element_id)
    dispatch(ProjectsApi.updateProjectMilestone(formCompleted, element_id));
  };

  return (
    <>
      <Drawer anchor="right" open={state}>
        <div className={styles.sidePanel}>
          <div className={styles.sidePanelTop}>
            <div onClick={handleClose}>
              <IconButton
                className={styles.sidePanelTopButton}
                aria-label="close"
              >
                <ArrowForwardIcon />
              </IconButton>
            </div>
            <div className={styles.sidePanelTopHeading}>
              <div>
                <h6>MileStone: {milestone_title}</h6>
              </div>
              <div>
                <Button
                  onClick={handleOpenEditMileStone}
                  variant="contained"
                  color="primary"
                >
                  EDIT MileStone
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.middle}>
            <Grid container>
              <Grid item xs={8}>
                <div className={styles.gridLeft}>
                  <section className={styles.gridSection}>
                    <strong className={styles.gridStrong}>Assigned to: </strong>
                    {members.map((member, index) => (
                      <div key={index} value={index}>
                        {member.project_member_id === member_id && (
                          <p>{member.first_name}</p>
                        )}
                      </div>
                    ))}
                  </section>
                  <section className={styles.gridSection}>
                    <strong className={styles.gridStrong}>Due: </strong>
                    <p>
                      {due_date ? (
                        defaultDateTimeZone(due_date, 'date')
                      ) : (
                        <span>DD_MM_YY</span>
                      )}
                    </p>
                  </section>
                  <section className={styles.gridSection}>
                    <strong className={styles.gridStrong}>Price: </strong>
                    <p>{rate ? rate : <span>00</span>}</p>
                  </section>
                </div>
              </Grid>
              <Grid item xs={4}>
                <section className={styles.markButton}>
                  <Button
                    onClick={markComplete}
                    variant="outlined"
                    color="primary"
                  >
                    {status === 'complete' ? (
                      <span>Completed</span>
                    ) : (
                      <span>Mark Complete</span>
                    )}
                  </Button>
                </section>
              </Grid>
            </Grid>
            <hr />
            <Grid item xs={12}>
              <div className={styles.gridRight}>
                <p>{milestone_description}</p>
              </div>
            </Grid>
            <hr />

            <div className={styles.QNADiv}>
              <Accordion
                className={styles.accor}
                expanded={expanded === 'panel'}
                onChange={handleChange('panel')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={styles.accorHeading}>
                    Comments and Questions
                  </Typography>
                </AccordionSummary>
                <hr className={styles.timeTrackerHr1} />

                {comments ? (
                  <>
                    {comments.map((comment, index) => (
                      <div key={index} id={index} className={styles.QNASection}>
                        <div className={styles.QNASection1}>
                          <Grid container>
                            <Grid item xs={5}>
                              {members.map((member, index) => (
                                <div key={index} id={index}>
                                  {comment.create_by ===
                                    member.project_member_id && (
                                    <>
                                      <MaterialAvatar
                                        member_id={member.contact_member_id}
                                        src={member.amera_avatar_url}
                                        classes="task-row"
                                        firstName={member.first_name}
                                        lastName={member.last_name}
                                      />
                                      <strong
                                        className={styles.QNASectionStrong}
                                      >
                                        {member.first_name} {member.last_name}
                                      </strong>
                                    </>
                                  )}
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={3}>
                              <p className={styles.QNASectionPara1}>
                                {comment.create_date &&
                                  format(
                                    defaultDateTimeZone(
                                      comment.create_date,
                                      '',
                                      false
                                    ),
                                    'hh:mm a MMM dd'
                                  )}
                              </p>
                            </Grid>
                          </Grid>
                        </div>
                        <p className={styles.QNASectionPara2}>
                          {comment.element_note}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <h6 className={styles.QNASectionPara2}>No Comments</h6>
                )}
              </Accordion>
            </div>

            <Grid item xs={12}>
              <FormControl variant="outlined" className={styles.field}>
                <TextareaAutosize
                  className={styles.inputDescription}
                  type="text"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  rowsMin={5}
                  placeholder="Add a Comment"
                />
              </FormControl>
            </Grid>

            <Button
              onClick={submitHandlerNote}
              className={styles.button}
              variant="contained"
              color="primary"
            >
              Add Comment
            </Button>
          </div>
        </div>
      </Drawer>

      <div>
        <EditMileStoneModal
          open={open}
          handleClose={handleCloseEditMileStone}
          project_id={projectId}
          parent_id={parent_id}
          element_id={element_id}
          type="milestone"
          name={milestone_title}
          text={milestone_description}
          members={members}
          member_id={member_id}
          preDate={due_date}
          preRate={rate}
          preCurrencyId={currencyId}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default ViewMileStonePane;
