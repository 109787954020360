import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ApiEmailService } from '../../../../config/apiService';
import { setSnackbarData } from '../../../../redux/actions/snackbar';

export const AttachedFiles = ({ selectedFiles, setSelectedFiles, mail_id }) => {
  const dispatch = useDispatch();

  const removeAttach = async (attachment_id) => {
    const res = await ApiEmailService.deleteAttachment(mail_id, attachment_id);
    if (res.status !== 200) {
      dispatch(
        setSnackbarData({
          open: true,
          message: res.statusText,
          type: 'error',
        })
      );
      return;
    }
    setSelectedFiles(selectedFiles.filter((x) => x.file_id !== attachment_id));
  };

  return (
    <List
      style={{
        width: 500,
      }}
    >
      {selectedFiles.map((item, index) => {
        const { name } = item[0];
        return (
          <ListItem dense key={index}>
            <ListItemText primary={name} />
            <ListItemSecondaryAction>
              <Tooltip title="Remove" placement="top">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  size="small"
                  onClick={(e) => {
                    removeAttach(item.file_id);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};
